import React, { useState } from 'react';
import { useSubscription } from 'observable-hooks';
import { UserView } from '../../components';
import GroupView from '../../components/GroupView/GroupView';
import { GroupPulseService } from '../../utilities/api/groupPulseService';
import ServiceSettings from '../../utilities/api/serviceSettings';
import NoDataView from '../../../common/components/NoDataView/NoDataView';

const TeamPulse = (props) => {
    const { teamPulseDataControl, teamPulseDataHandler, teamPulseDataPoller } = props;

    const [reportMode, setReportMode] = useState();
    const [apiConfig, setApiConfig] = useState();
    const [filter, setFilter] = useState();
    const [sort, setSort] = useState();
    const [pollingPaused, setPollingPaused] = useState();

    useSubscription(teamPulseDataControl.reportMode, setReportMode);
    useSubscription(teamPulseDataControl.filter, setFilter);
    useSubscription(teamPulseDataControl.sort, setSort);
    useSubscription(teamPulseDataControl.pollingPaused, setPollingPaused);

    const handleApiConfigChange = (newConfig) => {
        teamPulseDataPoller.setApiConfig(newConfig);
        setApiConfig(newConfig);
    };
    useSubscription(teamPulseDataControl.apiConfig, handleApiConfigChange);

    const noDataView = () => <NoDataView hasResults={false} />;

    var serviceSettings = new ServiceSettings({
        pollingPaused,
        url: apiConfig && apiConfig.url,
        reportingUrl: apiConfig && apiConfig.reportingUrl,
        websocketHttpsUrl: apiConfig && apiConfig.websocketHttpsUrl,
        isWidget: apiConfig && apiConfig.isWidget,
        params: apiConfig && apiConfig.parameters
    });

    teamPulseDataPoller.pollingPaused(pollingPaused);

    if (apiConfig) {
        if (reportMode === teamPulseDataControl.reportModes.users) {
            return (
                <UserView
                    serviceSettings={serviceSettings}
                    teamPulseDataHandler={teamPulseDataHandler}
                    teamPulseDataControl={teamPulseDataControl}
                    teamPulseDataPoller={teamPulseDataPoller}
                />
            );
        } else if (reportMode === teamPulseDataControl.reportModes.groups) {
            GroupPulseService.setSkipDelay();
            return (
                <GroupView
                    serviceSettings={serviceSettings}
                    filter={filter}
                    sort={sort}
                    teamPulseDataControl={teamPulseDataControl}
                    teamPulseDataPoller={teamPulseDataPoller}
                />
            );
        }
    }

    return noDataView();
};

export default TeamPulse;
