'use strict';

angular.module('app').service('widgetsApiService', WidgetsApiService);

WidgetsApiService.$inject = ['$http', 'envConfig'];
var reportingServiceExports = [
    'activitylog',
    'topapplications',
    'topgroups',
    'topusers',
    'topwebsites',
    'workinghours',
    'alarmlog',
    'screenshots'
];

function WidgetsApiService($http, envConfig) {
    this.getRealtimeCount = function () {
        return $http.get('envConfig.apiUrl() + /api/realtime/count', {
            ignoreLoadingBar: true
        });
    };

    this.getAdrotator = function () {
        return $http.get(envConfig.apiUrl() + '/api/adrotator', {
            ignoreLoadingBar: true
        });
    };

    this.addCategory = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/categories', payload);
    };

    this.deleteCategory = function (id) {
        return $http.delete(envConfig.apiUrl() + '/api/settings/categories/' + id);
    };

    this.getExportStatus = function (reportName, id) {
        var url =
            reportName === 'screenshots'
                ? envConfig.reportingServiceUrl() + '/reports/v1/screenshots/' + id + '/export'
                : envConfig.websocketHttpsUrl() + '/api/export/' + reportName + '/status/' + id;
        return $http.get(url, { ignoreLoadingBar: true });
    };

    this.getReport = function (reportName, args, data) {
        data.ignoreLoadingBar = true;
        return $http.get(envConfig.apiUrl() + '/api/export/' + reportName + '?' + args, data);
    };

    this.getReportV2 = function (reportName, args, data) {
        data.ignoreLoadingBar = true;
        var path = this.resolveReportName(reportName, data.params.exportMode);
        return $http.get(envConfig.reportingServiceUrl() + '/reports/v1/' + path + '/export?' + args, data);
    };

    this.getReportV2Google = function (reportName, args, data) {
        data.ignoreLoadingBar = true;
        var path = this.resolveReportName(reportName, data.exportMode);
        return $http.post(envConfig.reportingServiceUrl() + '/reports/v1/' + path + '/export', data);
    };

    this.cancelExport = function (reportName, id) {
        return $http.post(envConfig.apiUrl() + '/api/export/' + reportName + '/cancel/' + id, {
            ignoreLoadingBar: true
        });
    };

    this.getCompany = function () {
        return $http.get(envConfig.apiUrl() + '/api/company', {
            ignoreLoadingBar: true
        });
    };

    this.getRealtime = function (parameters) {
        return $http.get(envConfig.websocketHttpsUrl() + '/api/realtime?' + parameters, {
            ignoreLoadingBar: true
        });
    };

    this.deleteApplication = function (id) {
        return $http.delete(envConfig.apiUrl() + '/api/settings/application/' + id);
    };

    this.deleteApplications = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/application/removelist', payload);
    };

    this.deleteWebsite = function (id) {
        return $http.delete(envConfig.apiUrl() + '/api/settings/website/' + id);
    };

    this.deleteWebsites = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/website/removelist', payload);
    };

    this.addDomain = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/domain', payload);
    };

    this.isReportingExport = function (serviceName) {
        var isOverriden = window?.localStorage?.getItem('activTrak.exportV2Override');
        return isOverriden || reportingServiceExports.includes(serviceName);
    };

    /**
     * If the service should call the FEAPI for the Export
     * @param serviceName
     * @returns {boolean}
     */
    this.isFEAPIExport = function (serviceName) {
        return !this.isReportingExport(serviceName);
    };

    this.resolveReportName = function (reportName, exportMode) {
        var topRegex = new RegExp('^top+((web)+|(app)+)');
        var path = exportMode?.trim();
        if (topRegex.test(reportName) && path !== 'only') {
            return reportName.concat('and', path);
        }

        return reportName;
    };
}
