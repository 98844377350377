import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  Typography
} from '@mui/material';
import { customSelectClasses } from './CustomSelect.styles.js';

export default function CustomSelect(props) {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (props.error) {
      setErrorMessage('');
    }
  }, [props.error]);

  const validateRequired = (event) => {
    setErrorMessage('');

    let error = false;

    if (props.required && !event.target.value) {
      error = true;
      setErrorMessage('This field is required');
    }
    props.onBlur(event, error);
  };

  const items = props.items || [];

  return (
    <FormControl
      sx={customSelectClasses.formControl}
      onBlur={validateRequired}
      error={props.error || errorMessage}
      disabled={items && items.length < 1}
    >
      <InputLabel>{props.label}</InputLabel>
      {props.required && (
        <Typography
          component="span"
          className={props.error ? 'required-error' : ''}
          sx={customSelectClasses.required}
        ></Typography>
      )}
      <Select
        {...props}
        classes={{
          select: customSelectClasses.select
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
      >
        {items.map((o) => (
          <MenuItem key={o.isoCode} value={o.name}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
      {(props.error || errorMessage) && (
        <FormHelperText sx={customSelectClasses.error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
