import { GroupTypeBasic, GroupMix } from '../../common/enums';

export const typeMenuOptions = [
  { label: GroupTypeBasic.ActivTrak, filterBase: 'ActivTrak' },
  { label: GroupTypeBasic.ActiveDirectory, filterBase: 'ActiveDirectory' },
  { label: GroupTypeBasic.AzureAD, filterBase: 'AzureAd' },
  { label: GroupTypeBasic.HRDC, filterBase: 'HrDataConnector' }
];

export const mixMenuOptions = [
  GroupMix.Computer,
  GroupMix.User,
  GroupMix.Mixed,
  GroupMix.None
];
