import React, { useState } from 'react';
import { IChromebookUser } from '../models';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  CircularProgress
} from '@mui/material';

type CancelChromebookUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onCancel: (users: string[], isCancel: boolean) => Promise<void>;
  user: IChromebookUser;
};

export default function CancelChromebookUserDialog(
  props: CancelChromebookUserDialogProps
) {
  const { open, user, onClose, onCancel } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    onClose();
    setIsDeleting(false);
  };

  const handleCancel = async (): Promise<void> => {
    setIsDeleting(true);
    try {
      await onCancel([user.user], true);
    } catch (error) {
      //error handled by state management
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClose();
      }}
    >
      <DialogTitle>Cancel Chromebook User Invitation</DialogTitle>
      <DialogContent>
        <Box>Do you want to delete the selected ActivTrak ID?</Box>
      </DialogContent>
      <DialogActions>
        {isDeleting ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
              autoFocus
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCancel}
              color="primary"
              variant="contained"
              disabled={isDeleting}
            >
              Delete
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
