import { newEvent } from '../../common/analytics/eventHeap';
import { DashboardType, dashboardOptions } from '../../common/enums';

export const submitDashboardEvent = (
  eventMessage: string,
  event: any,
  dashboardSelection: DashboardType
) => {
  try {
    newEvent(eventMessage, event);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot send Dashboard heap event for ${dashboardSelection}: ${eventMessage}: ${event} Error: ${error}`
    );
  }
};

export const getDashboardAccessHeapType = (dashboard: DashboardType) => {
  //unless it's the legacy dashboard...
  if (dashboard === DashboardType.default) return 'Dashboard';

  //grab the dashboard value from its option name
  const selectedDashboardObj = dashboardOptions.find(
    (item) => item.id === dashboard
  );
  return selectedDashboardObj?.name || 'Dashboard';
};
