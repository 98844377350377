import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';

angular.module('app').service('websocketService', WebsocketService);

WebsocketService.$inject = [
    'envConfig',
    '$rootScope',
    '$q',
    '$websocket',
    '$interval',
    '$timeout',
    'AccountSettingsService',
    'atHelperFunctions'
];

function WebsocketService(
    envConfig,
    $rootScope,
    $q,
    $websocket,
    $interval,
    $timeout,
    AccountSettingsService,
    atHelperFunctions
) {
    var _connection = null;
    var service = this;
    var _closeCalled;
    var pingInterval;
    var normalCloseCode;
    var keepAliveInterval;
    var reconnectDelay;
    var reconnectTimeout;
    var maxReconnectDelay;
    var _initialized = false;

    var messageListeners = [];
    var disconnectListeners = [];

    function initialize() {
        // console.log('Initialize Websocket');
        _initialized = true;
        _closeCalled = false;
        pingInterval =
            (AccountSettingsService.websocketSettings && AccountSettingsService.websocketSettings.pingInterval) ||
            120000;
        normalCloseCode = 1000;
        keepAliveInterval = null;
        reconnectDelay =
            (AccountSettingsService.websocketSettings &&
                AccountSettingsService.websocketSettings.reconnectDelayStart) ||
            1000;
        maxReconnectDelay =
            (AccountSettingsService.websocketSettings && AccountSettingsService.websocketSettings.reconnectDelayMax) ||
            300000;
        // console.log('Websocket Settings Initialized', AccountSettingsService.websocketSettings);
    }

    // Websocket message received, send to matching message listener
    function processMessage(message) {
        var data;

        try {
            data = JSON.parse(message.data);
        } catch (ex) {
            console.error('Error parsing Websocket message JSON string.', ex);
        }

        // var computer = data && data.Result && data.Result.AgentId && data.Result.AgentId.Computer;
        // console.log('Websocket Message Received', messageListeners.length, computer, data || message.data);
        if (data.Error === 'null') {
            data.Error = null;
        }

        if (data.Error && data.Error !== 'Could not find Agent.') {
            console.error('Error processing websocket message.', data);
        }

        var messageListenerFound = false;
        messageListeners.forEach(function (listener) {
            if (listener.id === data.Id && typeof listener.listener === 'function') {
                listener.listener(data);
                _.remove(messageListeners, function (l) {
                    return l.id === listener.id;
                });
                messageListenerFound = true;
            }
        });
        if (!messageListenerFound) {
            console.warn('No message listener found for incoming websocket message', data);
        }
    }

    // Websocket close event received, cancel ping, remove connection,
    // and send to all disconnect listeners if not a normal disconnect
    function processClose(event) {
        // console.log('Process Close', _closeCalled, event);
        if (keepAliveInterval) {
            $interval.cancel(keepAliveInterval);
        }

        _connection = null;
        $timeout.cancel(reconnectTimeout);

        if (event.code !== normalCloseCode) {
            // console.error('Websocket connection closed abnormally. Reconnect attempt in ' + (reconnectDelay * 0.001) + ' seconds.', event);

            reconnectTimeout = $timeout(function () {
                reconnectDelay = Math.min(reconnectDelay * 2, maxReconnectDelay);
                // console.log('Websocket Reconnect Call.', reconnectDelay);
                service.connect('Reconnect');
            }, reconnectDelay);

            disconnectListeners.forEach(function (listener) {
                if (typeof listener === 'function') {
                    listener(event);
                }
            });
            return;
        }

        if (!_closeCalled) {
            // console.warn('Websocket connection closed by server.', event);
            service.connect('Server Close');
            return;
        }
    }

    function addToList(newItem, list) {
        if (newItem) {
            var found = false;
            list.forEach(function (item) {
                if (item === newItem) {
                    found = true;
                }
            });

            if (!found) {
                list.push(newItem);
            }
        }
    }

    service.isConnected = function () {
        return _connection !== null;
    };

    // Connect to websocket service
    service.connect = function (caller, disconnectListener) {
        // console.log('Websocket Connect', caller);
        if (!_initialized || _closeCalled) {
            initialize();
        }

        _connection = $websocket(envConfig.websocketUrl(), null, {
            maxTimeout: 5000
        });

        keepAliveInterval = $interval(service.keepAlive, pingInterval);

        addToList(disconnectListener, disconnectListeners);

        _connection.onClose(processClose);
        _connection.onMessage(processMessage);

        var authenticateParams = {
            Type: 'ClientAuthenticationEvent',
            Parameters: {
                Token: userServiceFunctions.getEncodedUserToken()
            }
        };

        var deferred = $q.defer();
        _connection
            .send(JSON.stringify(authenticateParams))
            .then(function (result) {
                reconnectDelay =
                    (AccountSettingsService.websocketSettings &&
                        AccountSettingsService.websocketSettings.reconnectDelayStart) ||
                    1000;
                // console.log('Websocket Connected');
                deferred.resolve(result);
            })
            .catch(function (error) {
                console.error('Websocket error authenticating', error);
                deferred.reject(error);
            });

        return deferred.promise;
    };

    // Send command
    service.sendCommand = function (type, parameters, messageListener) {
        // console.log('Send Command', type, retryCount, parameters.AgentId.ComputerName);
        var deferred = $q.defer();

        if (!service.isConnected()) {
            deferred.reject('Cannot send command, websocket not connected.');
        } else {
            var callbackId = atHelperFunctions.generateGuid();

            var params = {
                Type: type,
                Parameters: parameters,
                CallbackId: callbackId
            };

            addToList(
                {
                    id: callbackId,
                    listener: messageListener
                },
                messageListeners
            );

            _connection
                .send(JSON.stringify(params))
                .then(function () {
                    deferred.resolve(callbackId);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise;
    };

    // Keep connection alive
    service.keepAlive = function () {
        // console.log('Keep Websocket Alive');
        if (!service.isConnected()) {
            console.error('Cannot send keep alive command, service is not connected.');
            $interval.cancel(keepAliveInterval);
            return;
        }

        var callbackId = atHelperFunctions.generateGuid();

        addToList(
            {
                id: callbackId,
                listener: angular.noop
            },
            messageListeners
        );

        return _connection.send(
            JSON.stringify({
                Type: 'GetPingCommand',
                Parameters: null,
                CallbackId: callbackId
            })
        );
    };

    // Close websocket connection
    service.close = function () {
        _closeCalled = true;
        // console.log('Websocket Close Called');
        if (service.isConnected()) {
            _connection.close();
        }
    };

    $rootScope.$on('WebsocketSettingsSet', function (e, settings) {
        pingInterval = (settings && settings.pingInterval) || 120000;
        reconnectDelay = (settings && settings.reconnectDelayStart) || 1000;
        maxReconnectDelay = (settings && settings.reconnectDelayMax) || 300000;
        // console.log('Websocket Settings Set', settings);
    });
}
