'use strict';

angular.module('app')
.directive('atColspan', ['$compile', AtColspan]);

function AtColspan() {
  return {
    restrict: 'A',
    template: '',
    link: function(scope, elem, attr) {
            function setColspan() {
              var colsToSpan = scope.$eval(attr.atColspan);
              if (!colsToSpan || isNaN(colsToSpan)) {
                  colsToSpan = 1;
              }
            //   console.log('Column Span', angular.copy(colsToSpan));
              if(angular.isObject(colsToSpan)) {
                angular.forEach(colsToSpan, function(condition, key) {
                  if(condition && isFinite(key)) {
                    colsToSpan = key;
                  }
                });
              }

              elem.attr('colspan', parseInt(colsToSpan, 10));
            }
            setColspan();
            scope.$watch(attr.atColspan, setColspan);
          }
  };
}
