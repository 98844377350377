import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { doNotTrackTemplate as angularJsTemplate } from '../../templates';

export const appSettingsDoNotTrack: IRoute = {
  name: 'app.settings.doNotTrack',
  stateDefinition: {
    url: '/settings/doNotTrack',
    template: angularJsTemplate,
    controller: 'doNotTrackCtrl',
    data: { pageTitle: 'Do Not Track' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsDoNotTrack;
