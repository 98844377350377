import theme from '../../../../common/theme/theme';

export const currentActivityClasses = {
    root: {
        paddingBottom: theme.spacing(1),
        '@keyframes highlight': {
            '0%': {
                backgroundColor: 'transparent'
            },
            '30%': {
                backgroundColor: 'rgba(177,222,252,1)'
            },
            '100%': {
                backgroundColor: 'transparent'
            }
        }
    },
    content: {},
    none: {
        color: theme.palette.grey.A100
    },
    highlight: {
        transitionProperty: 'background-color',
        backgroundColor: 'transparent',
        animation: '$highlight 1s forwards'
    }
};
