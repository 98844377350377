import React, { useEffect } from 'react';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';
import {
  getDateTimeFormat,
  getParsedLocalStorageItem
} from '../../common/helpers';
import Grid from '@mui/material/Grid';
import { localStorageItems, Routes } from '../../common/constants';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useNotifications } from '../../common/services/Notifications';
import { DetailEmails } from '../components/DetailEmails';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { IAccountSettings } from '../../common/models';
import { useIdentityCommonState } from '../hooks/useIdentityCommonState';
import { useIdentityDetailState } from '../hooks/useIdentityDetailState';
import { DetailNavHeader } from '../components/DetailNavHeader';
import { DetailUserInfo } from '../components/DetailUserInfo';
import { AgentsAssigned } from '../components/AgentsAssigned';
import { useUserSearchState } from '../hooks/useUserSearchState';
import SnackbarWithUrl from '../components/SnackbarUrl';
import { useAuthorization } from '../../common/services/Authorization';
import { PermissionLevel } from '../../common/enums';

export function UserDetail() {
  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const dateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);
  const commonState = useIdentityCommonState({
    dateFormat: accountSettings?.dateFormat?.toUpperCase(),
    dateTimeFormat
  });
  const detailState = useIdentityDetailState({ commonState });
  const userSearchState = useUserSearchState({
    dateTimeFormat,
    useSessionStorage: true
  });

  const authorization = useAuthorization();
  const permission = authorization.pageAuthorization(Routes.Identities);
  const isReadOnly = permission === null || permission === PermissionLevel.Read;

  const { identities, initializeUserIdentities } = userSearchState;

  const {
    setSelectedIdentityId,
    identityNotification,
    setIdentityNotification,
    snackBarHyperlinkText,
    snackBarMessage,
    snackBarOpen,
    snackBarUrl,
    onSnackBarClose
  } = commonState;

  const { getUserDetail, detailNotification, setDetailNotification } =
    detailState;

  const notifications = useNotifications();

  useEffect(() => {
    //we get notifications from detail and common states
    let currentNotification = null;
    let setCurrentNotification = null;
    if (identityNotification?.msg && identityNotification?.type) {
      currentNotification = identityNotification;
      setCurrentNotification = setIdentityNotification;
    }
    if (detailNotification?.msg && detailNotification?.type) {
      currentNotification = detailNotification;
      setCurrentNotification = setDetailNotification;
    }
    if (currentNotification) {
      notifications[currentNotification.type](currentNotification.msg);
      setCurrentNotification(undefined);
    }
  }, [
    notifications,
    identityNotification,
    setIdentityNotification,
    detailNotification,
    setDetailNotification
  ]);

  const { id: identityId }: { id: number } = useUIRouterParams();

  useEffect(() => {
    if (identityId != 0) {
      setSelectedIdentityId(identityId);
      getUserDetail(identityId);
      if (!identities || identities.length == 0) {
        initializeUserIdentities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityId]);

  return (
    <Box>
      <Grid container>
        <DetailNavHeader
          userSearchState={userSearchState}
          identityDetailState={detailState}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <DetailUserInfo
              identityDetailState={detailState}
              newUserMode={false}
              isReadOnly={isReadOnly}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AgentsAssigned
                  detailState={detailState}
                  isReadOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Card elevation={3}>
                  <DetailEmails
                    identityState={commonState}
                    identityDetailState={detailState}
                    isReadOnly={isReadOnly}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackbarWithUrl
        open={snackBarOpen}
        message={snackBarMessage}
        hyperlinkText={snackBarHyperlinkText}
        url={snackBarUrl}
        onClose={onSnackBarClose}
      />
    </Box>
  );
}

export const UserIdentityDetailComponent = () => (
  <ReactivTrakComponentWrapper>
    <UserDetail />
  </ReactivTrakComponentWrapper>
);
