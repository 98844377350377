'use strict';

angular
    .module('app')
    .directive('alarmDetailsPopupMessage', AlarmDetailsPopupMessage);

function AlarmDetailsPopupMessage() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsPopupMessage.html'),
        controller: AlarmDetailsPopupMessageCtrl
    };
}

AlarmDetailsPopupMessageCtrl.$inject = [
    '$scope',
    '$timeout',
    'messagesService'
];

function AlarmDetailsPopupMessageCtrl($scope, $timeout, msg) {
    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    var hideMacrosTimeout = null;
    var buttonBlurTimeout = null;
    var focusOnMacros = false;

    $scope.macroButtonFocus = function() {
        // console.log('Popup Macro Focus');
        $scope.macroButtonPressed = true;
    };

    $scope.appendMacro = function(field, fieldId, source) {
        // console.log('Popup Append Macro');
        // Cancel hiding macro buttons if a macro button is pressed before timeout period ends.
        if (hideMacrosTimeout !== null) {
            $timeout.cancel(hideMacrosTimeout);
            hideMacrosTimeout = null;
        }
        $scope.alarm.popuptext = $scope.options.appendMacro(
            field,
            '#' + fieldId,
            source
        );

        // If button blur timeout is set cancel it and prep to start again
        if (buttonBlurTimeout !== null) {
            $timeout.cancel(buttonBlurTimeout);
            buttonBlurTimeout = null;
        }

        // Start button blur timeout. Used to determine if a macro button was the reason for a text field to loose focus.
        buttonBlurTimeout = $timeout(function() {
            $scope.macroButtonPressed = false;
            buttonBlurTimeout = null;
        }, 50);
    };

    $scope.showMacros = function() {
        // console.log('Popup Show Macros');
        $scope.showMacroButtons = true;
        focusOnMacros = true;
    };

    $scope.hideMacros = function() {
        // console.log('Popup Hide Macros');
        // Wait 10 ms to allow macro button function to set variables.
        $timeout(function() {
            if (!$scope.macroButtonPressed) {
                focusOnMacros = false;

                // Wait 100 ms to ensure lost focus was intentional.
                hideMacrosTimeout = $timeout(function() {
                    if (!focusOnMacros) {
                        $scope.showMacroButtons = false;
                    }
                }, 100);
            }
        }, 10);
    };

    function checkPopupMessageRestrictions() {
        if (alarmObjectVerifierFunctions.isGroupAlarm($scope.alarm)) {
            $scope.disablePopupMessage = true;
            $scope.alarm.popup = false;
            $scope.popupTooltip = msg.get('popupEnabledWithGroup');
        } else {
            $scope.disablePopupMessage = false;
            $scope.popupTooltip = null;
        }
    }

    function isComponentValid() {
        // console.log('Popup Messages Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload() {
        // console.log('Popup Messages Update Payload', payload);
        return true;
    }

    function alarmUpdated() {
        // console.log('Popup Messages Alarm Updated', $scope.alarm);
        checkPopupMessageRestrictions();
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.popupMessage,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);
}
