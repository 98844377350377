import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';
import { analyticEventServiceFunctions } from '_app/serviceFunctions/analyticEventServiceFunctions';

angular.module('app').controller('addATIdModalController', AddATIdModalController);

AddATIdModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'modalData',
    'accessApiService',
    'localStorageService',
    'notificationService',
    'messagesService',
    'AccountSettingsService',
    'authorizationService',
    'atHelperFunctions'
];

function AddATIdModalController(
    $scope,
    $uibModalInstance,
    $timeout,
    modalData,
    accessApiService,
    localStorageService,
    notificationService,
    msg,
    AccountSettingsService,
    authorizationService,
    atHelperFunctions
) {
    var accessDataSource = modalData.accessDataSource;
    var { screenshotsAllowed = false } = getPrivacySettings();

    $scope.newUserRole = '1';
    $scope.error = null;
    $scope.IsCCUser = authorizationService.hasRole([authorizationService.roles.ccAccess]);
    $scope.adminRoleLabel = $scope.IsCCUser ? msg.get('ccAdminRoleLabel') : msg.get('adminRoleLabel');
    $scope.powerUserRoleLabel = screenshotsAllowed
        ? msg.get('powerUserRoleLabel')
        : msg.get('powerUserRoleLabelPrivate');
    $scope.viewerRoleLabel = $scope.IsCCUser ? msg.get('ccUserRoleLabel') : msg.get('viewerRoleLabel');
    $scope.configuratorRoleLabel = msg.get('configuratorRoleLabel');

    $scope.applicationRoles = authorizationService.applicationRoles;

    $scope.dismiss = function (data) {
        if (data) {
            $uibModalInstance.close({
                users: data,
                newFirstName: $scope.newUserFirstName,
                newLastName: $scope.newUserLastName,
                newUserName: $scope.newUserName,
                newUserRole: $scope.newUserRole
            });
        } else {
            $uibModalInstance.dismiss();
        }
    };

    $scope.submitNewUser = function () {
        if ($scope.error) {
            return;
        }
        $scope.isBusy = true;

        var payload = {
            users: [
                {
                    firstName: $scope.newUserFirstName,
                    lastName: $scope.newUserLastName,
                    userName: $scope.newUserName,
                    userRole: $scope.newUserRole
                }
            ]
        };

        var createHeapEventMetadata = function (payload) {
            var metaData = {};
            switch (payload.users[0].userRole) {
                case '1':
                    metaData['Invited_User_Role'] = 'Admin';
                    break;
                case '2':
                    metaData['Invited_User_Role'] = 'Power User';
                    break;
                case '3':
                    metaData['Invited_User_Role'] = 'Viewer';
                    break;
                case '5':
                    metaData['Invited_User_Role'] = 'Configurator';
                    break;
            }
            metaData['Invited Email'] = payload.users[0].userName;
            return metaData;
        };

        accessApiService
            .createUsers(payload)
            .success(function () {
                localStorageService.set('teamNotSet_' + AccountSettingsService.account, false);
                accessDataSource.read().then(function () {
                    $scope.dismiss(accessDataSource.data());
                });
                var metaData = createHeapEventMetadata(payload);

                analyticEventServiceFunctions.newEvent('Invited Team Member', metaData);
            })
            .error(function (result) {
                if (result.users) {
                    result.users.forEach(function (user) {
                        if (user.error) {
                            notificationService.showNotification(
                                msg.get('errorAddingProfile', user.userName, user.error || user.error.Message),
                                'danger'
                            );
                        }
                    });
                } else if (result.error) {
                    notificationService.showNotification(result.error, 'danger');
                } else {
                    notificationService.showNotification(msg.get('creatingUserError'), 'danger');
                }
                $scope.isBusy = false;
            });
    };

    function checkValid() {
        if (authorizationService.hasRole(authorizationService.roles.supportBasic)) {
            return;
        }

        if (atHelperFunctions.isEmpty($scope.newUserName)) {
            $scope.validating = false;
            $scope.error = null;
        } else {
            if ($scope.newUserName.toLowerCase() === AccountSettingsService.username.toLowerCase()) {
                $scope.validating = false;
                $scope.error = msg.get('signupErrorMessage_isMember');
            } else {
                accessApiService
                    .validateMember($scope.newUserName)
                    .then(function (result) {
                        $scope.validating = false;
                        if (result && result.data && result.data.message) {
                            if (result.data.message === 'exists on account') {
                                $scope.error = msg.get('signupErrorMessage_memberExists');
                            } else {
                                $scope.error = msg.get('signupErrorMessage_memberExistsOnAnother');
                            }
                        } else {
                            $scope.error = null;
                        }
                    })
                    .catch(function (result) {
                        $scope.validating = false;
                        if (result.data && result.data.error.indexOf('We need your real email address') >= 0) {
                            $scope.error = msg.get('signupErrorMessage_temporaryEmailService');
                        } else if (result.data && result.data.error.indexOf('Enter a valid email') >= 0) {
                            $scope.error = msg.get('signupErrorMessage_invalidEmail');
                        } else {
                            console.error('Unable to check if email exists', result);
                        }
                    });
            }
        }
    }

    var validateTimer;
    $scope.validateMember = function () {
        $timeout.cancel(validateTimer);
        $scope.validating = true;
        validateTimer = $timeout(checkValid, 500, false);
    };
}
