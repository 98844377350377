import React from 'react';
import { ChartCheckboxLegendColumnsProps } from '../models/ChartCheckboxLegend.props';
import { CheckboxLegendContainerSC } from '../styles/ChartCheckboxLegend.styles';
import { ChartCheckboxLegend } from './ChartCheckboxLegend';

export const ChartCheckboxLegendHorizontal = (
  props: ChartCheckboxLegendColumnsProps
) => {
  const {
    labels,
    colors,
    currentSelections,
    onClick,
    disabled,
    truncateNeeded
  } = props;

  return (
    <CheckboxLegendContainerSC
      aria-labelledby="checkbox-legend-list"
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <ChartCheckboxLegend
        labels={labels}
        colors={colors}
        currentSelections={currentSelections}
        onClick={onClick}
        disabled={disabled}
        truncateNeeded={truncateNeeded}
      />
    </CheckboxLegendContainerSC>
  );
};
