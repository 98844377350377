import { IManagerSchedule } from '../models';
export interface ISubscriptionManagerViewState {
  managerSchedules: IManagerSchedule[];
  dropDownButtonOptions: any[];
  searchTerm: string;
  searchBarFilterParams: any[];
  viewIsLoading: boolean;
}

export enum dropDownConstants {
  View = 'View',
  Reassign = 'Reassign',
  Delete = 'Delete'
}

export const INITAL_VIEW_STATE: ISubscriptionManagerViewState = {
  managerSchedules: [],
  dropDownButtonOptions: [
    { label: dropDownConstants.View },
    { label: dropDownConstants.Reassign },
    { label: dropDownConstants.Delete }
  ],
  searchTerm: '',
  searchBarFilterParams: ['ownerName', 'dashboardId', 'subscriptionName'],
  viewIsLoading: true
};

export const filterManagerScheduleDataBySpecificParamsAndQuery = (
  data: any,
  searchParams: string[],
  query?: string
) => {
  //Map data for less iterations on "forEach" cycles
  const filteredDataMap = {};

  //If the Query is empty or the array of params to search is empty
  //return the same data
  if (!query || !searchParams.length) {
    return data;
  }

  searchParams.forEach((keyParam) => {
    data.forEach((dataItem) => {
      const { subscriptionId } = dataItem;
      const param = dataItem[keyParam];
      if (param) {
        //Case if type of data is string
        if (typeof param === 'string') {
          if (query) {
            if (
              param.toLowerCase().includes(query.toLowerCase()) &&
              !filteredDataMap[subscriptionId]
            ) {
              filteredDataMap[subscriptionId] = dataItem;
            }
          } else {
            if (!filteredDataMap[subscriptionId]) {
              filteredDataMap[subscriptionId] = dataItem;
            }
          }
        }
        //Could add other cases (bool , number , date )
      }
    });
  });

  //Transform object of objects to list of object
  return Object.values(filteredDataMap);
};

export const filterAndRepeatManagerScheduleDataBySpecificParamsAndQuery = (
  data: any,
  dataStructure: any[],
  searchParams: string[],
  query?: string
) => {
  const filteredData = [];

  if (!query && !searchParams.length) {
    return filteredData;
  }

  searchParams.forEach((keyParam) => {
    const typeName = dataStructure.find((el) => el.field === keyParam);
    if (typeName) {
      const { fieldName, headerName } = typeName;
      const name = fieldName ? fieldName : headerName;

      data.forEach((dataItem) => {
        const { subscriptionId } = dataItem;
        const param = dataItem[keyParam];
        if (param) {
          //Case if type of data is string
          if (typeof param === 'string') {
            if (param.toLowerCase().includes(query.toLowerCase())) {
              filteredData.push({
                id: subscriptionId,
                type: keyParam,
                typeName: name,
                label: dataItem[keyParam]
              });
            }
          }
          //Could add other cases (bool , number , date )
        }
      });
    }
  });

  return filteredData;
};
