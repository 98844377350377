import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';

angular.module('app').controller('RealtimeCtrl', RealtimeCtrl);

RealtimeCtrl.$inject = ['$scope', '$state', 'authorizationService'];

function RealtimeCtrl($scope, $state, authorizationService) {
    if (authorizationService.hasFeature('isTeamPulseEnabled')) {
        $state.go('app.teampulse', $state.params);
    }

    $scope.isReport = true;
    $scope.pollPaused = false;

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.realtime');
    };

    $scope.bindReport = function (filters) {
        $scope.parameters = generateParameters(filters, { includeRealtime: true });
        $scope.bindRealtimeActivity();
    };
}
