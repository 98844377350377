import { GroupMix, GroupType, GroupTypeBasic, GroupTypeConstBasic } from '../../common/enums';
import { IGroupMember, IGroupWithMembersDto, IMemberAdded } from '../models';
import { MemberType } from '../enums';
import { IGroupSummaryDto } from '../../common/models/IGroupSummaryDto';

export const mapGroupAdminType = (obj: IGroupSummaryDto): GroupTypeBasic => {
  const { groupType } = obj;

  return GroupTypeConstBasic[groupType];
};

export const mapGroupWithMembersType = (
  obj: IGroupWithMembersDto
): GroupTypeBasic => {
  const { groupType } = obj;

  return GroupTypeConstBasic[groupType];
};



export const mapGroupAdminMix = (obj: IGroupSummaryDto): GroupMix => {
  const { mix } = obj;

  switch (mix) {
    case GroupMix.Client:
      return GroupMix.User;
    case GroupMix.Device:
      return GroupMix.Computer;
    case GroupMix.None:
      return GroupMix.None;
    default:
      return GroupMix.Mixed;
  }
};

export const mapGroupWithMembersMix = (obj: IGroupWithMembersDto): GroupMix => {
  const { mix } = obj;

  switch (mix) {
    case GroupMix.Client:
      return GroupMix.User;
    case GroupMix.Device:
      return GroupMix.Computer;
    case GroupMix.None:
      return GroupMix.Empty;
    default:
      return GroupMix.Mixed;
  }
};

export const mapGroupAdminFilter = (obj: IGroupSummaryDto): GroupType => {
  const { groupTypeName, mix } = obj;
  return GroupType[`${groupTypeName}${mix}Group`];
};

export const mapGroupWithMembersFilter = (
  obj: IGroupWithMembersDto
): GroupType => {
  const { groupTypeName, mix } = obj;
  return GroupType[`${groupTypeName}${mix}Group`];
};

export const mapMembersToAddMembers = (
  members: IGroupMember[]
): IMemberAdded[] => {
  return members.map((m) => {
    return {
      selected: true,
      member: {
        alias: m.alias,
        id: m.id,
        uniqueId: m.uniqueId,
        name: m.name,
        selected: true,
        type: m.type,
        windowsdomain: m.domain
      }
    };
  });
};

export const rowDisableConditionAllMembers = (rowItem: IGroupMember) => {
  return rowItem.alreadySelected;
};

export const selectedActivTrakGroups = (members): IGroupMember[] => {
  return members?.filter((m) => m.selected);
};

  export const filterAllGroupMembers = (
    filter: MemberType,
    groupMembers: IGroupMember[]
  ): IGroupMember[] => {
    return groupMembers.filter((member) => member.type === filter);
  };
