import React from 'react';
import {
  DashboardActivityDiff,
  DashboardInfoText,
  ZeroDataMsgSC
} from '../../styles/templates.styles';
import { ISubheaderProps } from '../../models';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import { SubheaderErrorDisplay } from './SubheaderErrorDisplay';
import { MessageDisplay } from './SubheaderMessageDisplay';
import WidgetLink from '../../../common/components/WidgetLink';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {
  formatPreviousValueString,
  isPreviousValueNegative
} from '../../utils';

export default function DashboardSubheader(props: Readonly<ISubheaderProps>) {
  const {
    topLineSubheader,
    productiveHours,
    historicalChange,
    url,
    params,
    isLoading,
    id,
    infoIconText,
    hasTileError,
    zeroDataMsg,
    zeroDataLink
  } = props;

  return (
    <Grid item xs={12} mb={1}>
      <WidgetLinkWrapper direction="row" display="inline-block">
        {url ? (
          <Box sx={{ display: 'inline' }}>
            <WidgetLink
              text={topLineSubheader}
              infoIconText={infoIconText}
              redirectTo={url}
              redirectParams={params}
              id={id}
            />
          </Box>
        ) : (
          <WidgetLinkTypography>{topLineSubheader}</WidgetLinkTypography>
        )}
      </WidgetLinkWrapper>
      {isLoading && (
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
      )}
      {!isLoading && (
        <Box display="flex" mt={1} sx={{ position: 'relative' }}>
          {!hasTileError && (
            <Box>
              <DashboardInfoText>
                {productiveHours ?? '0'}&nbsp;
              </DashboardInfoText>
              {Boolean(historicalChange) && (
                <DashboardActivityDiff
                  negative={isPreviousValueNegative(historicalChange)}
                >
                  ({formatPreviousValueString(historicalChange)}hrs)&nbsp;
                </DashboardActivityDiff>
              )}
              <DashboardInfoText>average productive hours</DashboardInfoText>
              {!productiveHours && zeroDataMsg && zeroDataLink && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: -15,
                    width: '100%'
                  }}
                >
                  <ZeroDataMsgSC>
                    <MessageDisplay message={zeroDataMsg} link={zeroDataLink} />
                  </ZeroDataMsgSC>
                </Box>
              )}
            </Box>
          )}
          {hasTileError && <SubheaderErrorDisplay />}
        </Box>
      )}
    </Grid>
  );
}
