import React from 'react';
import { getClasses } from './TeamPulseCard.style';
import { Card } from '@mui/material';

const TeamPulseCard = (props) => {
    let { isReport, active } = props;

    const classes = getClasses(active);
    const elevation = isReport ? 3 : 0;
    const variant = isReport ? 'elevation' : 'outlined';

    return (
        <Card elevation={elevation} variant={variant} sx={classes.root}>
            {props.children}
        </Card>
    );
};

export default TeamPulseCard;
