import { useMemo, useState } from 'react';
import { SortDirection } from '../../common/enums';

import { IUserAgent, IUserIdentity } from '../models/IUserIdentity';

import { orderBy } from 'lodash';

export interface IAgentAssignedState {
  onSortAgentOrder: (newOrderDirection: string, newOrderBy: string) => void;
  menuAgent: IUserAgent;
  setMenuAgent: (value: IUserAgent) => void;
  sortedAgentDetails: IUserAgent[];
}

export const useAgentAssignedState = (
  selectedIdentity: IUserIdentity
): IAgentAssignedState => {
  const [agentGridOrderBy, setAgentGridOrderBy] = useState<string>('lastLog');
  const [agentGridOrderDirection, setAgentGridOrderDirection] =
    useState<SortDirection>(SortDirection.Descending);
  const [menuAgent, setMenuAgent] = useState<IUserAgent>(null);

  const onSortAgentOrder = (
    newOrderDirection: SortDirection,
    newOrderBy: string
  ) => {
    setAgentGridOrderBy(newOrderBy);
    setAgentGridOrderDirection(newOrderDirection);
  };

  const sortedAgentDetails = useMemo<IUserAgent[]>((): IUserAgent[] => {
    const sortedData = orderBy(
      selectedIdentity?.agents,
      [agentGridOrderBy],
      [agentGridOrderDirection === SortDirection.Ascending ? 'asc' : 'desc']
    );
    return sortedData;
  }, [selectedIdentity, agentGridOrderBy, agentGridOrderDirection]);

  return {
    onSortAgentOrder,
    menuAgent,
    setMenuAgent,
    sortedAgentDetails
  };
};
