import React, { useState, useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { GroupCard } from '..';
import { useBreakpoint } from '../../../common/hooks';
import { FixedSizeList } from 'react-window';
import { isEmpty } from '../../utilities/helpers';
import { tmGroupViewClasses } from './GroupView.style';
import { useSubscription } from 'observable-hooks';
import { GroupPulseService } from '../../utilities/api/groupPulseService';
import NoDataView from '../../../common/components/NoDataView/NoDataView';
import { groupPulseFilter } from '../../utilities/groupPulseFilter';
import { groupPulseSort } from '../../utilities/groupPulseSort';
import TimeMode from '../../models/TimeMode';
import GroupPulse from '../../models/GroupPulse';

const GroupView = (props) => {
    const { serviceSettings, filter, sort, teamPulseDataControl, teamPulseDataPoller } = props;

    const [isReport, setIsReport] = useState();
    useSubscription(teamPulseDataControl.isReport, setIsReport);
    const [timeFormat, setTimeFormat] = useState();
    useSubscription(teamPulseDataControl.timeFormat, setTimeFormat);
    const [containerHeight, setContainerHeight] = useState(200);
    useSubscription(teamPulseDataControl.containerHeight, setContainerHeight);
    const [timeMode, setTimeMode] = useState();
    useSubscription(teamPulseDataControl.timeMode, setTimeMode);
    const [filterMode, setFilterMode] = useState();
    useSubscription(teamPulseDataControl.filterMode, setFilterMode);
    const [refreshData, setRefreshData] = useState();
    useSubscription(teamPulseDataControl.refreshData, setRefreshData);
    const [selectedCardIds, setSelectedCardIs] = useState();
    useSubscription(teamPulseDataControl.selectedCardIds, setSelectedCardIs);
    const [groupPulseData, setGroupPulseData] = useState({
        data: [new GroupPulse()]
    });

    const handleGroupPulseUpdate = (data) => {
        if (!GroupPulseService.isPaused()) {
            setShowLoading(false);
            setGroupPulseData({ data });
        }
    };
    useSubscription(GroupPulseService.pollGroupPulses(serviceSettings), handleGroupPulseUpdate, (error) => {
        setGroupPulseData({ data: [] });
        setRefreshData(false);
        setShowLoading(false);
    });
    const [hideEmptyGroups, setHideEmptyGroups] = useState();
    useSubscription(teamPulseDataControl.hideEmptyGroups, setHideEmptyGroups);
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        if (refreshData) {
            setShowLoading(true);
            GroupPulseService.setRefreshData();
            setRefreshData(false);
        }
    }, [refreshData]);

    let groupPulses = groupPulseData.data;
    if (!groupPulseData.fakeData && typeof groupPulseData.data.filter === 'function') {
        groupPulses = groupPulseFilter(groupPulseData.data, filter, hideEmptyGroups);
        groupPulses = groupPulseSort(groupPulses, sort, timeMode === TimeMode.Total);
    }

    teamPulseDataPoller.stopPolling();

    const breakPoint = useBreakpoint();
    const itemSize = () => (isReport ? 448 : 137); // Height of card plus 15px bottom padding
    const listRef = useRef();
    const scrollToSelected = () => {
        // Wait for listRef to populate
        setTimeout(() => {
            if (listRef.current && typeof listRef.current.scrollToItem === 'function') {
                // Find index of selected card
                let selectedCardIndex = _.findIndex(groupPulses, (card) => card.id.toString() === selectedCardIds);

                // Find row card is on
                let rowIndex = Math.floor(selectedCardIndex / getColumnCount());

                // If rowIndex >= 0 then scroll to row and clear selectedItem
                // Else, wait for valid match before clearing selectedItem
                if (rowIndex >= 0 && rowIndex <= getRowCount()) {
                    listRef.current.scrollTo(itemSize() * rowIndex);
                    teamPulseDataControl.selectedCardIds = null;
                }
            }
        });
    };

    // TODO: move all card generation to its own file
    const generateGroupCard = (index) => {
        let groupPulse = groupPulses[index];
        if (!groupPulse) {
            return;
        }

        return (
            <Grid key={groupPulse.id} item xs={12} sm={6} md={4} lg={2}>
                <GroupCard
                    isReport={isReport}
                    teamPulseDataControl={teamPulseDataControl}
                    teamPulseDataPoller={teamPulseDataPoller}
                    groupPulse={groupPulse}
                    loading={showLoading || refreshData}
                    timeFormat={timeFormat}
                    timeMode={timeMode}
                    filterMode={filterMode}
                    filterModes={teamPulseDataControl.filterModes}
                />
            </Grid>
        );
    };

    useEffect(() => {
        if (refreshData) {
            setShowLoading(true);
            setRefreshData(false);
        }
    }, [refreshData]);

    const generateRow = (rowIndex) => {
        let row = [];
        const start = rowIndex * getColumnCount();
        const end = start + getColumnCount() - 1;
        for (let index = start; index <= end; index = index + 1) {
            let card = generateGroupCard(index);
            if (card) {
                row.push(card);
            }
        }

        return (
            <Grid container spacing={1} direction="row">
                {row}
            </Grid>
        );
    };

    const getColumnCount = () => {
        switch (breakPoint) {
            case 'xs':
                return 1;
            case 'sm':
                return 2;
            case 'md':
                return 3;
            default:
                return 6;
        }
    };

    const getRowCount = () => {
        return Math.ceil(groupPulses.length / getColumnCount());
    };

    const Row = ({ index, style }) => (
        <div id={`groupRow-${index}`} style={style}>
            <div style={tmGroupViewClasses.row}>{generateRow(index)}</div>
        </div>
    );

    if (showLoading && (!groupPulses || isEmpty(groupPulses))) {
        // Create fake data to fill rows with empty cards while loading
        let data = [];
        for (let i = 0; i < getColumnCount() * 3; i = i + 1) {
            data.push(
                new GroupPulse({
                    id: i
                })
            );
        }
        setGroupPulseData({ data, fakeData: true });
    } else if (!showLoading && groupPulses && groupPulseData.fakeData) {
        // Clear fake card data if not loading.
        setGroupPulseData({ data: [new GroupPulse()] });
    }

    const hasData = Boolean(groupPulses && !groupPulseData.fakeData && groupPulses.length);

    // Use list items rendering as trigger for scrolling to selected
    const handleItemsRendered = () => {
        if (selectedCardIds) {
            scrollToSelected();
        }
    };

    const hasResults = Boolean(groupPulses && groupPulses.length);

    return refreshData || showLoading || hasData ? (
        <FixedSizeList
            height={containerHeight - 10} // Container height minus root padding
            ref={listRef}
            itemCount={getRowCount()}
            itemSize={itemSize()}
            onItemsRendered={handleItemsRendered}
            width={'100%'}
        >
            {Row}
        </FixedSizeList>
    ) : (
        <NoDataView hasResults={hasResults} />
    );
};

export default GroupView;
