import React, { Fragment } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import { Lens, PanoramaFishEye, Person } from '@mui/icons-material';
import { getClasses } from './CardTitle.style';
import { isEmpty } from '../../../utilities';
import InfoTip from '../../InfoTip/InfoTip';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { getRatio } from '../../../utilities/helpers';
import { Tooltip } from '../../';
import { TeamPulseGroupIcon } from '../../GroupCard';
import TimeMode from '../../../models/TimeMode';
import { GroupPulseService } from '../../../utilities/api/groupPulseService';
import ReportMode from '../../../models/ReportMode';
import GroupProperties from '../../../models/GroupProperties';

// TODO: Update this card to better handle the difference between the user type and the group type
const CardTitle = (props) => {
    let {
        id,
        name,
        groupMix,
        groupType,
        rootClass = {},
        contentClass = {},
        teamPulseDataControl,
        teamPulseDataPoller,
        activeStatus,
        firstSeen,
        lastSeen,
        groups,
        groupProperties,
        users = [],
        productiveTotalRatio,
        productiveActiveRatio,
        isReport,
        timeFormat,
        timeMode,
        loading,
        reportMode,
        filterMode,
        filterModes,
        type,
        isViewer
    } = props;

    // If name is empty, set name = to a space
    name = isEmpty(name) ? <span>&nbsp;</span> : name;

    const classes = getClasses(activeStatus, type);
    const isActive = activeStatus !== 'inactive';

    const handleGroupClick = (group) => {
        var gp = new GroupProperties(groupType, groupMix);

        teamPulseDataControl.reportMode = ReportMode.Groups;
        teamPulseDataControl.userFilter = {
            groupType: gp.generateMixLabel(),
            userId: group.groupid,
            userName: group.name,
            userType: gp.generateTypeLabel() === 'ActivTrak' ? 'Group' : 'AdGroup'
        };
    };

    const generateGroupElements = () => {
        let subItems = [];

        if (groups) {
            subItems = groups.map((group) => ({
                label: group.name,
                heapId: 'id_eefdea32-8f2e-47b9-880a-a5e943f96edb',
                onClick: () => handleGroupClick(group)
            }));
        }

        return {
            label: 'Groups',
            subItems
        };
    };

    const infoTipContent = [
        {
            label: 'Active Time',
            subItems: [
                {
                    heapId: 'id_e3273f6b-6b53-48fd-bfad-ca61c050d585',
                    label: 'First Seen',
                    value: firstSeen ? moment(firstSeen).format(timeFormat) : ''
                },
                {
                    heapId: 'id_8d3d95ad-91bf-4251-a2b8-99f4baec4730',
                    label: 'Last Seen',
                    value: lastSeen ? moment(lastSeen).format(timeFormat) : ''
                }
            ]
        }
    ];

    infoTipContent.push(generateGroupElements());

    const tooltipMap = {
        active: 'Active',
        passive: 'Passive',
        inactive: 'Inactive Now'
    };

    const generateLink = () => {
        if (type === 'user') {
            let modeLabel = filterMode === filterModes.users ? 'user' : 'computer';
            return isReport
                ? `/#/app/reports/activitylog?${modeLabel}=${encodeURIComponent(name)}`
                : `/#/app/teampulse?card=${id}`;
        } else {
            return id && !isReport ? `/#/app/teampulse` : null;
        }
    };

    const generateNameNode = () => {
        const onClick = () => {
            if (type !== 'group' || !isReport) {
                return;
            }

            teamPulseDataControl.userFilter = {
                groupType: groupProperties.generateMixLabel(),
                userId: id,
                userName: name,
                userType: groupProperties.generateTypeLabel() === 'ActivTrak' ? 'Group' : 'AdGroup'
            };
            teamPulseDataControl.reportMode = ReportMode.Users;
        };

        return (
            <span
                id={
                    isReport
                        ? type === 'user'
                            ? 'id_1c98e165-bea4-41f7-bef3-b7cd1e5d6adb'
                            : 'id_f9dad084-1cc8-4223-8798-e0a125f504ec'
                        : type === 'user'
                        ? 'id_b2e4d77a-3992-44a5-a9cb-a1ee5b4c69fd'
                        : 'id_6bfd4b7a-4080-4724-99b9-f5c6fff0e99c'
                }
            >
                {!isViewer && (
                    <a href={generateLink()} onClick={onClick}>
                        {name}
                    </a>
                )}
                {isViewer && name}
            </span>
        );
    };

    const generateTopUsersLink = () => {
        if (reportMode === ReportMode.Groups) {
            return `/#/app/reports/topusers?groupId=${id}`;
        } else {
            let modeLabel = filterMode === filterModes.users ? 'user' : 'computer';
            return `/#/app/reports/topusers?${modeLabel}=${encodeURIComponent(name)}`;
        }
    };

    const generatePercentage = () => {
        const link = generateTopUsersLink();
        let ratio = getRatio(timeMode === TimeMode.Total ? productiveTotalRatio : productiveActiveRatio, 0);

        return <a href={link}>{`${ratio}%`}</a>;
    };

    const handleInfoTipOpen = () => {
        GroupPulseService.setPausePolling(true);
        teamPulseDataPoller.pollingPaused(true);
    };

    const handleInfoTipClose = () => {
        GroupPulseService.setPausePolling(teamPulseDataControl.pollingPaused.value);
        teamPulseDataPoller.pollingPaused(teamPulseDataControl.pollingPaused.value);
    };

    return (
        <div style={{ ...rootClass, ...classes.root }}>
            <div style={{ ...contentClass, ...classes.content }}>
                <Fragment>
                    {type === 'user' && (
                        <SkeletonLoader
                            loading={loading}
                            className={classes.skeletonIcon}
                            width="20px"
                            variant="circular"
                        >
                            <Tooltip title={tooltipMap[activeStatus]}>
                                <div>
                                    {isActive && <Lens sx={classes.icon} />}
                                    {!isActive && <PanoramaFishEye sx={classes.icon} />}
                                </div>
                            </Tooltip>
                        </SkeletonLoader>
                    )}
                    <Typography sx={classes.name} noWrap={true}>
                        <SkeletonLoader loading={loading} width="40%">
                            {generateNameNode()}
                        </SkeletonLoader>
                    </Typography>
                    <div style={classes.grow} />
                    {isReport ? (
                        <SkeletonLoader loading={loading} className={classes.ratio} width="20px">
                            {type === 'user' ? (
                                <InfoTip
                                    onEnter={handleInfoTipOpen}
                                    onClose={handleInfoTipClose}
                                    buttonContent={
                                        <Person id="id_1750bea4-2075-490b-addf-bc1eb3f47d06" sx={classes.buttonIcon} />
                                    }
                                    infoTipContent={infoTipContent}
                                />
                            ) : (
                                <TeamPulseGroupIcon
                                    onEnter={handleInfoTipOpen}
                                    onClose={handleInfoTipClose}
                                    groupProperties={groupProperties}
                                    users={users}
                                    teamPulseDataControl={teamPulseDataControl}
                                    filterMode={filterMode}
                                />
                            )}
                        </SkeletonLoader>
                    ) : (
                        <Tooltip title={'Productive %'}>
                            <Typography sx={classes.ratio}>
                                <SkeletonLoader loading={loading} width="20px">
                                    {generatePercentage()}
                                </SkeletonLoader>
                            </Typography>
                        </Tooltip>
                    )}
                </Fragment>
            </div>
        </div>
    );
};

export default CardTitle;
