import { defaultActiveTimeSettings } from '../constants';
import { HttpResponse } from '../../common/models';
import { IDuration } from '../models/IDuration';
import { IActiveTimeSettings } from '../models';

export const processDurationResponse = (
  response: HttpResponse<IDuration>
): IActiveTimeSettings => {
  let dbSettings: IActiveTimeSettings = null;
  switch (response.status) {
    case 204:
      //no setting was found, this means it's active indefinitely
      dbSettings = {
        activeMaxTime: defaultActiveTimeSettings.activeMaxTime,
        activeIndefinitely: true
      };
      break;
    case 200:
      if (response?.data && response.data?.duration) {
        const duration = response.data.duration;
        dbSettings = {
          activeMaxTime: duration,
          activeIndefinitely: false
        };
      } else {
        throw new Error('No Data');
      }
      break;
    default:
      throw new Error('Unknown response');
  }
  return dbSettings;
};
