'use strict';

angular.module('app').service('activTrakAcademyService', [
    '$window',
    'envConfig',
    'authorizationService',
    '$http',
    'notificationService',
    '$state',
    function ($window, envConfig, authorizationService, $http, notificationService, $state) {
        const openAcademyLink = function (redirectUrl, redirectToHome, params = {}) {
            let attributes = '_blank';
            const { direct, original_uri } = params;

            // If the direct query parameter is set, open the academy in the same tab
            // and replace /app/help/academy with / in history
            if (direct) {
                attributes = '_self';
                $window.history.replaceState({}, '', '/');
                redirectToHome = false;
            }

            // Append the course path if it is provided
            if (original_uri) {
                redirectUrl = `${redirectUrl}&redirect_uri=${encodeURIComponent(original_uri)}`;
            }

            // Open the academy link
            $window.open(redirectUrl, attributes, 'noreferrer=true');

            // Redirect to the home page if needed
            if (redirectToHome) {
                authorizationService.redirectToAuthorizedHome($state);
            }
        };

        this.redirectToAcademy = function (params, redirectToHome) {
            if (authorizationService.hasRouteByName('app.help.academy')) {
                $http
                    .get(envConfig.adminApiUrl() + '/admin/v1/accountsettings/academy')
                    .then(function (response) {
                        const redirectUrl = response?.data;
                        if (typeof redirectUrl !== 'string' || response.data.length === 0) {
                            throw new Error('Invalid Academy URL');
                        }

                        openAcademyLink(redirectUrl, redirectToHome, params);
                    })
                    .catch(function (e) {
                        notificationService.showNotification(
                            'Unable to access ActivTrak Academy. Please email academy@activtrak.com for assistance.',
                            'danger'
                        );
                        console.error(`ActivTrak Error: Cannot resolve Academy URL: ${JSON.stringify(e)}`);
                    });
            } else {
                openAcademyLink('https://www.activtrak.com/academy/', redirectToHome, params);
            }
        };
    }
]);
