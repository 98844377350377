import { IProductivityGoalSummaryDto, IReportModalListItem } from './';

export interface IProductivityGoalDto {
  achieved: number;
  missed: number;
  previous: number;
  date: string;
  teamsModalCount: number;
  teams: IProductivityGoalSummaryDto[];
}

export enum ProductivityGoalLevel {
  Achieving = 'achieving',
  Missing = 'missing'
}

export enum ProductivityGoalLabel {
  Achieving = 'Achieving',
  Missing = 'Missing'
}

export const PRODUCTIVITY_GOAL_LABELS: ProductivityGoalLabel[] = [
  ProductivityGoalLabel.Achieving,
  ProductivityGoalLabel.Missing
];

export interface IProductivityGoalLabelsModel {
  name: string;
  value: number;
  label: ProductivityGoalLabel;
  groupId: number;
}

export interface ProductivityGoalLabelsModelType {
  missing: IProductivityGoalLabelsModel[];
  achieving: IProductivityGoalLabelsModel[];
}

export interface IProductivityGoalState {
  init: () => Promise<void>;
  goalData: IProductivityGoalDto | null;
  isLoading: boolean;
  isTileError: boolean;
  goalTeams: IReportModalListItem[];
}

export type IProductivityGoalChartDataSets = {
  data: any;
  backgroundColor: (() => string) | string[];
  hoverBackgroundColor: (() => string) | string[];
  noResults: boolean | number[];
  borderWidth: number;
};

export type IProductivityGoalChartProps = {
  labels: string[];
  datasets: IProductivityGoalChartDataSets[];
};
