import React from 'react';
import { ThemeProvider } from '@mui/material';
import { getTheme } from '../../../styles/theme';
import authorization from '../../../helpers/authorization';
import LinkButton from '../../Button/LinkButton';
import { primaryAqua } from '../../../constants';
import { Role } from '../../../enums';
import { BundleFlag } from '../../../enums/BundleFlag';
import SchoolIcon from '@mui/icons-material/School';

export const AcademyButton = () => {
  const theme = getTheme();

  const hasRoleAccess = authorization.hasAnyRole([
    Role.Admin,
    Role.Viewer,
    Role.Configurator,
    Role.User
  ]);
  const isPaid = authorization.hasFeature(BundleFlag.PaidFunctionality);

  if (!hasRoleAccess || !isPaid) return <></>;

  // TODO: remove theme provider when this button is only used in React
  return (
    <ThemeProvider theme={theme}>
      <LinkButton
        id="3e9c1721-82e5-43f5-b841-f406cd24b1c1"
        to="app.help.academy"
        color="secondary"
        startIcon={<SchoolIcon sx={{ color: primaryAqua }} />}
        sx={{
          letterSpacing: 0.25
        }}
      >
        ActivTrak Academy
      </LinkButton>
    </ThemeProvider>
  );
};
