import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const NoAPIKeysIcon: React.FC<SvgIconProps> = (props) => {
  const width = props.width ?? '62px';
  const height = props.height ?? '62px';
  const title = props.title ?? 'No API Keys';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props.viewBox ?? '0 0 62 62';
  const fillColor = props.fillColor ?? 'currentColor';

  let classList = 'at-icon-{{{name}}}';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <g clipPath="url(#clip0_399_14643)">
        <path
          d="M54.25 18.0834L31 5.16675L7.75 18.0834V43.9167L31 56.8334L54.25 43.9167V18.0834ZM31 11.0826L46.2675 19.5559L38.4917 23.8701C36.6058 21.9067 33.945 20.6667 31 20.6667C28.055 20.6667 25.3942 21.9067 23.5083 23.8701L15.7325 19.5559L31 11.0826ZM28.4167 49.4967L12.9167 40.8942V23.9217L21.0025 28.4167C20.77 29.2176 20.6667 30.0959 20.6667 31.0001C20.6667 35.8051 23.9475 39.8609 28.4167 40.9976V49.4967ZM25.8333 31.0001C25.8333 28.1584 28.1583 25.8334 31 25.8334C33.8417 25.8334 36.1667 28.1584 36.1667 31.0001C36.1667 33.8417 33.8417 36.1667 31 36.1667C28.1583 36.1667 25.8333 33.8417 25.8333 31.0001ZM33.5833 49.4967V41.0234C38.0525 39.8867 41.3333 35.8309 41.3333 31.0259C41.3333 30.1217 41.23 29.2434 40.9975 28.4167L49.0833 23.9217V40.8942L33.5833 49.4967Z"
          fill="black"
          fillOpacity="0.54"
          style={style}
        />
      </g>
      <defs>
        <clipPath id="clip0_399_14643">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </SvgIconWrapper>
  );
};
