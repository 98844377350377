import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const WindowsIconColor = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 26 25';
  const iconHeight = height ?? '25px';
  const iconWidth = width ?? '26px';
  const title = props.title ?? 'Windows Icon';
  const className = props.className;

  let classList = 'at-windows-icon';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
    >
      <>
        <path fill="#f3f3f3" d="M0 0h23v23H0z" />
        <path fill="#f35325" d="M1 1h10v10H1z" />
        <path fill="#81bc06" d="M12 1h10v10H12z" />
        <path fill="#05a6f0" d="M1 12h10v10H1z" />
        <path fill="#ffba08" d="M12 12h10v10H12z" />
      </>
    </SvgIconWrapper>
  );
};
