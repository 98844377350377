import { DashboardWidgetId } from '../enums';

export const availableWidgetPaths = {
  [DashboardWidgetId.QuickActions]: 'QuickActions/QuickActions',
  [DashboardWidgetId.OrganizationTrendsSubheader]:
    'Subheaders/OrganizationTrendsSubheader',
  [DashboardWidgetId.PendingClassifications]:
    'Classifications/PendingClassifications',
  [DashboardWidgetId.ProductivityGoal]: 'ProductivityGoal/ProductivityGoal',
  [DashboardWidgetId.TodaysUserActivity]: 'TodaysActivity/TodaysUserActivity',
  [DashboardWidgetId.TodaysActivityChart]: 'TodaysActivity/TodaysActivityChart',
  [DashboardWidgetId.TodaysProductivity]:
    'TodaysProductivity/TodaysProductivity',
  [DashboardWidgetId.TodaysProductivityRatios]:
    'TodaysProductivity/TodaysProductivityRatios',
  [DashboardWidgetId.TopUsersGroups]: 'TopUsersGroups/TopUsersGroups',
  [DashboardWidgetId.UserHealth]: 'UserHealth/UserHealth',
  [DashboardWidgetId.VirtualOfficeVisibilitySubheader]:
    'Subheaders/VirtualOfficeVisibilitySubheader',
  [DashboardWidgetId.VirtualTeamVisibilitySubheader]:
    'Subheaders/VirtualTeamVisibilitySubheader',
  [DashboardWidgetId.Workload]: 'Workload/Workload',
  [DashboardWidgetId.WorkloadBalanceBarChart]: 'Workload/WorkloadBarChart',
  [DashboardWidgetId.TeamTrendsSubheader]: 'Subheaders/TeamTrendsSubheader',
  [DashboardWidgetId.TeamProductivityGoal]:
    'ProductivityGoal/ProductivityGoalBarChart',
  [DashboardWidgetId.TodaysTopCategories]: 'TopCategories/TopCategories',
  [DashboardWidgetId.TodaysProductivityOutlook]:
    'TodaysProductivityOutlook/TodaysProductivityOutlook'
};
