import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const MixedGroupIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const title = props.title ?? 'Mixed Group';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const style = props.style ?? {};

  let classList = 'at-icon-mixed-group';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      fillColor={fillColor}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M15.8749 3.07946C16.558 3.07946 17.1257 3.59842 17.2034 4.26154L17.2124 4.41695L17.2131 6.8045C16.819 6.45421 16.368 6.16649 15.8757 5.95687L15.8749 5.0857C15.8749 4.75876 15.6371 4.48465 15.326 4.42778L15.2062 4.41695H4.50624C4.17929 4.41695 3.90519 4.65473 3.84832 4.96589L3.83749 5.0857V11.7732C3.83749 12.1001 4.07527 12.3742 4.38643 12.4311L4.50624 12.4419L9.45563 12.4418C9.79659 13.2206 10.3339 13.8938 11.005 14.3987C9.72947 14.7615 8.38531 15.3064 7.21339 15.9852C7.19214 15.9233 7.18122 15.8557 7.18122 15.7856C7.18122 15.4587 7.419 15.1846 7.73016 15.1277L7.84996 15.1169H8.51871V13.7794H3.83749C3.15442 13.7794 2.58666 13.2604 2.50904 12.5973L2.5 12.4419V4.41695C2.5 3.73388 3.01896 3.16612 3.68208 3.0885L3.83749 3.07946H15.8749Z"
        style={style}
      />
      <path
        d="M13.9536 14.2475C16.0383 14.2475 17.7267 12.559 17.7267 10.4743C17.7267 8.38966 16.0383 6.70117 13.9536 6.70117C11.8689 6.70117 10.1804 8.38966 10.1804 10.4743C10.1804 12.559 11.8689 14.2475 13.9536 14.2475Z"
        style={style}
      />
      <path
        d="M13.9538 15.2608C11.4352 15.2608 6.40747 16.5248 6.40747 19.034V19.9773C6.40747 20.4961 6.83195 20.9205 7.35076 20.9205H20.5568C21.0756 20.9205 21.5001 20.4961 21.5001 19.9773V19.034C21.5001 16.5248 16.4724 15.2608 13.9538 15.2608Z"
        style={style}
      />
    </SvgIconWrapper>
  );
};
