import React from 'react';
import { IActivityIcon } from '../../models';
import { ActivityType } from '../../enums';
import { htmlEscape } from '../../../common/helpers';
import { ComputerIcon, WorldIcon } from '../../../common/assets/Icons';

const ActivityIcon = (props: IActivityIcon) => {
  const { activity, isDomain } = props;

  const classes = {
    activityIcon: {
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  };

  const getFavicon = () => {
    const domain = activity.name;

    const escapedField = htmlEscape(domain);
    const fullUrl = isDomain ? 'http://' + escapedField : escapedField;

    if (
      !domain ||
      !(
        fullUrl &&
        fullUrl.indexOf('://', fullUrl.length - 3) === -1 &&
        fullUrl.indexOf('http') === 0
      )
    ) {
      return <WorldIcon />;
    }

    const url = fullUrl.split(':')[1];
    return (
      <img src={`https://www.google.com/s2/favicons?domain=${url}`} alt={url} />
    );
  };
  switch (activity.type) {
    case ActivityType.Website:
      return getFavicon();
    case ActivityType.Application:
      return <ComputerIcon style={classes.activityIcon} title="Application" />;
    default:
      return <></>;
  }
};

export default ActivityIcon;
