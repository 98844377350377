import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { ccHeaderClasses } from './Header.styles.js';
import { CC_ROUTES } from '../../utils/constants.js';

const Header = ({ children }) => {
  const [headerTitle, setHeaderTitle] = React.useState(null);

  const location = useLocation();

  useEffect(() => {
    const url = location?.pathname;
    for (const key in CC_ROUTES) {
      const route = CC_ROUTES[key].url;
      if (url?.match(route)) {
        setHeaderTitle(CC_ROUTES[key].title);
      }
    }
  }, [location]);

  return (
    <AppBar position="fixed" sx={ccHeaderClasses.appBar} color="inherit">
      <Toolbar sx={ccHeaderClasses.toolbar} variant="dense">
        <Typography sx={ccHeaderClasses.title} variant="subtitle2">
          {headerTitle}
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
