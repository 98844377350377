import React, { useState, useEffect } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import {
  TemplateShadow,
  BaseLoadingView,
  OverallStatus,
  ErrorMsgDisplay,
  WidgetModal,
  WidgetModalList,
  WidgetDialog
} from '../templates';
import {
  PIE_BASE_CONFIG,
  checkAllValuesAreZero,
  formatPreviousValueString,
  getChartColors,
  isPreviousValueNegative
} from '../../utils';
import { useProductivityGoalState, useLastUpdate } from '../../hooks';
import {
  IProductivityGoalChartProps,
  PRODUCTIVITY_GOAL_LABELS,
  ProductivityGoalLabel
} from '../../models/IProductivityGoalTypes';
import {
  PRODUCTIVITY_PIE_CHART_COLORS,
  ZERO_PIE_CHART_COLORS
} from '../../constants/chartColorSets';
import {
  errorMsg,
  lastUpdateText,
  modalButtonLabel,
  productivityPieChartLegendItems,
  orgWidgetSubheader,
  widgetTitle,
  noTeamsMeetingCriteria,
  modalTitle
} from '../../constants';
import {
  ChartLegendContainer,
  ChartLegendItem,
  ChartLegendRoundBox,
  LastUpdatedSC,
  ModalHeader,
  PercentageAverage,
  PreviousTypography
} from '../../styles/templates.styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function ProductivityGoal() {
  const [noResults, setNoResults] = useState<boolean>(true);
  const { init, goalData, isLoading, isTileError, goalTeams } =
    useProductivityGoalState();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (goalData) {
      const hasNoResults = checkAllValuesAreZero([
        goalData?.achieved,
        goalData?.missed
      ]);
      setNoResults(hasNoResults);
    }
  }, [goalData]);

  const chartData: IProductivityGoalChartProps = {
    labels: PRODUCTIVITY_GOAL_LABELS,
    datasets: [
      {
        data: noResults ? [86, 14] : [goalData?.achieved, goalData?.missed],
        backgroundColor: getChartColors(
          noResults,
          PRODUCTIVITY_PIE_CHART_COLORS
        ),
        hoverBackgroundColor: getChartColors(
          noResults,
          PRODUCTIVITY_PIE_CHART_COLORS
        ),
        borderWidth: 0,
        noResults: [86, 14]
      }
    ]
  };

  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const legend = chartData.datasets[0].data.map((_, i) => ({
    text: chartData.labels[i],
    color: chartData.datasets[0].backgroundColor[i]
  }));

  const options = {
    maintainAspectRatio: true,
    layout: {
      ...PIE_BASE_CONFIG.layout
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        ...PIE_BASE_CONFIG.datalabels,
        formatter: (value: IProductivityGoalChartProps) => {
          return value ? value + '%' : null;
        },
        display: !noResults
      },
      tooltip: {
        enabled: false
      }
    },
    onHover: (event, chartElement) => {
      if (noResults) {
        event.native.target.style.cursor = 'not-allowed';
      } else {
        event.native.target.style.cursor = chartElement[0]
          ? 'pointer'
          : 'default';
      }
    },
    onClick: (evt, element) => {
      if (noResults) {
        return;
      }
      if (element.length > 0) {
        const { index } = element[0];
        const label = chartData.labels[index];
        setSelectedStatus(label);
        setOpen(true);
      }
    }
  };

  const lastUpdate = useLastUpdate(goalData?.date);
  const selectedList = goalTeams
    ?.filter((team) =>
      selectedStatus === ProductivityGoalLabel.Missing ||
      selectedStatus === ProductivityGoalLabel.Achieving
        ? team.label === selectedStatus
        : true
    )
    .map((team) => ({ ...team, status: selectedStatus, value: team.value }));

  return (
    <TemplateShadow
      sx={{
        height: { xs: '100%', sm: '430px' }
      }}
    >
      <WidgetLinkWrapper>
        <WidgetLinkTypography variant="h4">
          {widgetTitle.productivity}
        </WidgetLinkTypography>
      </WidgetLinkWrapper>
      {!isLoading && chartData && !isTileError && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'center', sm: 'left' }}
            whiteSpace="nowrap"
          >
            <Box
              sx={{
                paddingLeft: { xs: 0, sm: '68px', md: '24px' },
                textAlign: { xs: 'center', sm: 'left' },
                width: { xs: 'auto', sm: '45%' }
              }}
            >
              <OverallStatus
                header={`${goalData?.achieved}%`}
                subheader={orgWidgetSubheader.productivityGoal}
                noResults={noResults}
                tooltipLegend={{
                  items: productivityPieChartLegendItems,
                  colors: ZERO_PIE_CHART_COLORS
                }}
              />
              {!noResults && (
                <Box sx={{ mb: 1 }}>
                  {goalData?.previous === 0 ? (
                    <PreviousTypography variant="body1">0%</PreviousTypography>
                  ) : (
                    <PercentageAverage
                      variant="body1"
                      negative={isPreviousValueNegative(goalData?.previous)}
                    >
                      {formatPreviousValueString(goalData?.previous)}%
                    </PercentageAverage>
                  )}
                  <PreviousTypography
                    variant="body1"
                    style={{ paddingLeft: '5px' }}
                  >
                    vs previous 30 days
                  </PreviousTypography>
                </Box>
              )}
              {/* do not refactor WidgetModal usage into another common component */}
              <WidgetModal
                buttonLabel={modalButtonLabel.trendsProductivity}
                id="38843ddc-f40c-4cf8-8e2f-3548d588f75d"
                isDisabled={noResults}
              >
                <ModalHeader variant="h6">
                  {modalTitle.orgProductivity}
                </ModalHeader>
                {!goalTeams || goalTeams.length === 0 ? (
                  <Typography>{noTeamsMeetingCriteria}</Typography>
                ) : (
                  <WidgetModalList data={goalTeams} />
                )}
              </WidgetModal>
            </Box>
            <Box
              sx={{
                paddingLeft: '8px',
                textAlign: 'center',
                width: '300px'
              }}
            >
              {!chartData && options && <div>Loading Chart</div>}
              {chartData ? (
                <Pie
                  data={chartData}
                  options={options as any}
                  plugins={[ChartDataLabels as any]}
                  id="4897b568-bbed-46a1-8993-e3e823218d63"
                />
              ) : (
                <></>
              )}
              <ChartLegendContainer>
                {legend.map((item) => (
                  <ChartLegendItem
                    key={`legend-${item.text?.replace(/\s+/g, '')}`}
                  >
                    <ChartLegendRoundBox
                      style={{ backgroundColor: item.color }}
                    />
                    {item.text}
                  </ChartLegendItem>
                ))}
              </ChartLegendContainer>
            </Box>
          </Stack>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
          {/* do not refactor WidgetDialog usage into another common component */}
          <WidgetDialog isOpen={open} onClose={() => setOpen(false)}>
            <Box sx={{ minWidth: '400px' }}>
              <ModalHeader variant="h6">
                Team Workloads - {selectedStatus}
              </ModalHeader>
              {!goalTeams || goalTeams.length === 0 ? (
                <Typography>{noTeamsMeetingCriteria}</Typography>
              ) : (
                <WidgetModalList data={selectedList} />
              )}
            </Box>
          </WidgetDialog>
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
