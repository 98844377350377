import React from 'react';
import { truncate } from 'lodash';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box
} from '@mui/material';
import { IGroupMember } from '../../models';
import MemberTypeIcon from '../MemberTypeIcon';
import { gray4, mediumFontWeight } from '../../../common/constants';

const classes = {
  table: {
    border: `1px solid ${gray4}`
  },
  tableContainer: {
    maxHeight: '55vh',
    width: '100%'
  },
  tableHeader: {
    height: 56,
    fontWeight: mediumFontWeight
  },
  memberName: {
    paddingLeft: '0.5em'
  },
  floatRight: {
    float: 'right'
  }
};

type ReviewGroupMembersProps = {
  members: IGroupMember[];
};

export default function ReviewGroupMembers(props: ReviewGroupMembersProps) {
  const { members } = props;

  return (
    <>
      <Box width="100%" textAlign="left" paddingBottom="1em">
        Review the group members below:{' '}
        <Box sx={classes.floatRight}>Total Members: {members.length}</Box>
      </Box>
      <TableContainer sx={classes.tableContainer} color="primary">
        <Table stickyHeader size="small" sx={classes.table}>
          <TableHead>
            <TableRow sx={classes.tableHeader}>
              <TableCell>Logon Domain</TableCell>
              <TableCell>Member Name (Alias)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member: IGroupMember) => (
              <TableRow key={member.id}>
                <TableCell>
                  <Box>{member.domain}</Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <MemberTypeIcon type={member.type} />
                    <Box sx={classes.memberName}>
                      {member.name}{' '}
                      {member.alias && member.alias !== '' ? (
                        `(${truncate(member.alias, { length: 18 })})`
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
