'use strict';

angular
    .module('app')
    .directive('websitesAndApplications', websitesAndApplications);

function websitesAndApplications() {
    return {
        restrict: 'E',
        scope: {
            filterTitle: '@',
            filterResults: '=',
            selectedFilterOptionId: '=',
            webSiteDataSource: '=',
            applicationDataSource: '=',
            isAppsTabActive: '=',
            manageCategories: '=',
            stateParams: '=',
            categoriesDataSource: '<'
        },
        template: require('views/widgets/websitesAndApplications.html'),
        controller: websitesAndApplicationsController
    };
}

websitesAndApplicationsController.$inject = [
    '$scope',
    '$timeout',
    '$rootScope',
    'messagesService',
    'widgetsApiService',
    'categoriesService',
    'productivityApiService',
    'atComparatorService',
    'pageSizeService',
    'gridPropertiesService',
    'templateServiceFunctions'
];

var categoryServiceFunctions = require('_app/classification/categoryServiceFunctions').categoryServiceFunctions;
function websitesAndApplicationsController(
    $scope,
    $timeout,
    $rootScope,
    msg,
    widgetsApiService,
    categoriesService,
    productivityApiService,
    atComparatorService,
    pageSizeService,
    gridPropertiesService,
    templateServiceFunctions
) {
    $scope.lastCheckedIdx = undefined;
    $scope.allItems = {
        selected: false
    };
    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.grid-tooltip'
    });

    $scope.clearLastCheckedIdx = function() {
        $scope.lastCheckedIdx = undefined;
        $scope.allItems = {
            selected: false
        };
    };

    $scope.webSiteDataSource.columnReorder = $scope.clearLastCheckedIdx;
    $scope.webSiteDataSource.change = $scope.clearLastCheckedIdx;
    $scope.applicationDataSource.columnReorder = $scope.clearLastCheckedIdx;
    $scope.applicationDataSource.change = $scope.clearLastCheckedIdx;
    $scope.totalRows = 0;
    $scope.selectedItems = {
        websites: [],
        applications: []
    };

    var webSiteGrid;
    var applicationGrid;

    $scope.isIgnoreCategory = function(id) {
        return categoryServiceFunctions.isIgnoreCategory(id);
    };

    var setTotalRows = function() {
        $scope.totalRows = $scope.isAppsTabActive
            ? $scope.applicationDataSource.pageSize()
            : $scope.webSiteDataSource.pageSize();
        $rootScope.$broadcast('grid-updated', {
            totalRows: $scope.totalRows
        });
    };

    var setDropdownHandler = function() {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler(
                'categoriesProductivitySettings-websitesAndApplications'
            );
        }
    };

    var updateCategoryFilter = function() {
        var filter = angular
            .element('.k-autocomplete input[data-text-field="category"]')
            .parent();
        if (
            $scope.selectedFilterOptionId !==
                categoryServiceFunctions.categoryGuid.all &&
            $scope.selectedFilterOptionId !==
                categoryServiceFunctions.categoryGuid.categorized &&
            $scope.manageCategories
        ) {
            filter.hide();
        } else {
            filter.show();
        }
    };

    function usageTemplate(item) {
        return templateServiceFunctions.friendlyViewTimeFormat(item.usage);
    }

    $scope.productivityDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function(options) {
                var selection = [
                    {
                        name: msg.get('productive'),
                        id: 1
                    },
                    {
                        name: msg.get('unproductive'),
                        id: -1
                    },
                    {
                        name: msg.get('undefined'),
                        id: -2
                    }
                ];
                options.success(selection);
            }
        }
    });
    $scope.productivityDataSource.read();

    $scope.categoriesDataSourceReload = function() {
        if ($scope.categoriesDataSource) {
            $scope.categoriesDataSource.read({
                force: true
            });
        }
    };

    $scope.categoriesDataSourceReload();

    $scope.switchTabs = function(isAppsActive) {
        $scope.deselectAll();
        $scope.isAppsTabActive = isAppsActive;
        $scope.clearLastCheckedIdx();

        if ($scope.totalRows) {
            var newDataSource = isAppsActive
                ? $scope.applicationDataSource
                : $scope.webSiteDataSource;
            newDataSource.pageSize($scope.totalRows);
        }

        $timeout(function() {
            updateCategoryFilter();
        });
    };

    $scope.deleteApplication = function(dataItem) {
        if (dataItem.deleteBlocked) {
            return;
        }
        dataItem.deleteBlocked = true;
        widgetsApiService
            .deleteApplication(dataItem.id)
            .success(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('applicationDeleted'),
                    color: 'success'
                });
                $scope.applicationDataSource.read();
                applicationGrid.refresh();
            })
            .error(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('cannotRemoveApplication'),
                    color: 'danger'
                });
                dataItem.deleteBlocked = false;
            });
    };

    $scope.deleteWebsite = function(dataItem) {
        if (dataItem.deleteBlocked) {
            return;
        }
        dataItem.deleteBlocked = true;
        widgetsApiService
            .deleteWebsite(dataItem.id)
            .success(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('websiteDeleted'),
                    color: 'success'
                });
                $scope.webSiteDataSource.read();
                webSiteGrid.refresh();
            })
            .error(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('cannotRemoveWebsite'),
                    color: 'danger'
                });
                dataItem.deleteBlocked = false;
            });
    };

    $scope.webSiteGridOptions = {
        dataSource: $scope.webSiteDataSource,
        filterable: {
            mode: 'row'
        },
        columns: [
            {
                field: 'selected',
                title: ' ',
                width: '44px',
                headerTemplate:
                    '<div class="checkbox"><input type="checkbox" ng-click="selectAllClicked($event)" id="selectbox-website-header" ng-model="allItems.selected"/><label class="m-l-6-i m-b-m5-i" for="selectbox-website-header"></label></div>',
                filterable: false,
                template:
                    '<div class="checkbox"><input type="checkbox" at-multi-select-checkbox at-data-source="webSiteDataSource" at-item="dataItem" at-model-field="\'selected\'" at-callback="processMultiSelect" at-skip-data-reload="true" id="selectbox-website-{{dataItem.uid}}" ng-click="checkBoxClicked($event, dataItem)" ng-model="dataItem.selected"/><label class="m-l-m4-i m-b-m5-i" for="selectbox-website-{{dataItem.uid}}"></label></div>',
                sortable: false,
                headerAttributes: {
                    class: 'text-center'
                },
                attributes: {
                    style: 'overflow: visible;'
                }
            },
            {
                field: 'domain',
                title: msg.get('domain'),
                template: templateServiceFunctions.getFaviconTemplate('domain'),
                filterable: templateServiceFunctions.createFilter(
                    'domain',
                    $scope.webSiteDataSource
                ),
                attributes: {
                    class: 'grid-tooltip text-nowrap'
                },
                sortable: {
                    compare: function(a, b, descending) {
                        return atComparatorService.urlComparator(
                            a,
                            b,
                            descending,
                            'domain'
                        );
                    }
                }
            },
            {
                field: 'category',
                title: msg.get('category'),
                filterable: templateServiceFunctions.createFilter(
                    'category',
                    $scope.webSiteDataSource
                ),
                attributes: {
                    style: 'padding: 5px'
                }
            },
            {
                field: 'productivity',
                title: msg.get('productivity'),
                filterable: false,
                template:
                    '<grid-column-dropdown-control ' +
                    'options="{type:\'productivity\', rowItem: dataItem, selectedId: dataItem.productivity, rowCount: totalRows, dropdownDataSource: productivityDataSource, selectionUpdateCallback: productivityUpdate}"' +
                    '</grid-column-dropdown-control>',
                headerAttributes: {
                    style: 'padding: 5px'
                },
                attributes: {
                    style: 'padding: 5px'
                },
                width: '150px'
            },
            {
                field: 'usage',
                title: msg.get('usage'),
                filterable: false,
                template: kendo.template(usageTemplate),
                sortable: {
                    compare: atComparatorService.usageComparator
                },
                attributes: {
                    class: 'text-center',
                    style: 'padding: 5px'
                },
                width: '120px'
            },
            {
                command: {
                    template:
                        '<button ng-if="dataItem.allowDelete" class="btn btn-danger btn-animated from-top fa fa-trash" ng-click="deleteWebsite(dataItem)" ng-disabled="dataItem.deleteBlocked">' +
                        '<span>' +
                        msg.get('delete') +
                        '</span>' +
                        '</button>'
                },
                attributes: {
                    class: 'text-center',
                    style: 'padding: 5px'
                },
                width: '95px'
            }
        ],
        sortable: true,
        pageable: {
            refresh: true,
            pageSizes: [10, 25, 50, 100, 200],
            buttonCount: 5,
            messages: {
                itemsPerPage: msg.get('itemsPerPage', 'websites'),
                display: msg.get('itemsDisplay', 'websites'),
                empty: msg.get('noItemsToDisplay', 'websites')
            },
            change: function() {
                setTotalRows();
                $scope.deselectAll();
            }
        },
        dataBound: function(e) {
            $timeout(function() {
                if ($scope.isAppsTabActive) {
                    return;
                }
                setTotalRows();
                if (
                    $scope.stateParams &&
                    ($scope.stateParams.app || $scope.stateParams.site)
                ) {
                    angular.forEach($scope.webSiteDataSource.view(), function(
                        item
                    ) {
                        item.selected = true;
                    });
                    $scope.stateParams = undefined;
                } else {
                    $scope.deselectAll();
                }

                gridPropertiesService.bindFilterAutoComplete(e, true);

                setDropdownHandler();

                webSiteGrid = $scope.webSiteGrid;
                updateCategoryFilter();

                $scope.setSelected();
            });
        }
    };

    $scope.applicationGridOptions = {
        dataSource: $scope.applicationDataSource,
        filterable: {
            mode: 'row'
        },
        columns: [
            {
                field: 'selected',
                title: ' ',
                headerTemplate:
                    '<div class="checkbox"><input type="checkbox" id="selectbox-applications-header" ng-click="selectAllClicked($event)" ng-model="allItems.selected"/><label class="m-l-6-i m-b-m5-i" for="selectbox-applications-header"></label></div>',
                width: '44px',
                template:
                    '<div class="checkbox"><input type="checkbox" at-multi-select-checkbox at-data-source="applicationDataSource" at-item="dataItem" at-model-field="\'selected\'" at-callback="processMultiSelect" id="selectbox-applications-{{dataItem.uid}}" at-skip-data-reload="true" ng-click="checkBoxClicked($event, dataItem)" ng-model="dataItem.selected"/><label class="m-l-m4-i m-b-m5-i" for="selectbox-applications-{{dataItem.uid}}"></label></div>',
                filterable: false,
                sortable: false,
                headerAttributes: {
                    class: 'text-center'
                },
                attributes: {
                    style: 'overflow: visible;'
                }
            },
            {
                field: 'application',
                title: msg.get('application'),
                filterable: templateServiceFunctions.createFilter(
                    'application',
                    $scope.applicationDataSource
                ),
                attributes: {
                    class: 'grid-tooltip'
                }
            },
            {
                field: 'executable',
                title: msg.get('executable'),
                filterable: templateServiceFunctions.createFilter(
                    'executable',
                    $scope.applicationDataSource
                ),
                attributes: {
                    class: 'grid-tooltip'
                }
            },
            {
                field: 'category',
                title: msg.get('category'),
                filterable: templateServiceFunctions.createFilter(
                    'category',
                    $scope.applicationDataSource
                ),
                attributes: {
                    style: 'padding: 5px'
                }
            },
            {
                field: 'productivity',
                title: msg.get('productivity'),
                filterable: false,
                template:
                    '<grid-column-dropdown-control ' +
                    'options="{type:\'productivity\', rowItem: dataItem, selectedId: dataItem.productivity, rowCount: totalRows, dropdownDataSource: productivityDataSource, selectionUpdateCallback: productivityUpdate}"' +
                    '</grid-column-dropdown-control>',
                headerAttributes: {
                    style: 'padding: 5px'
                },
                attributes: {
                    style: 'padding: 5px'
                },
                width: '150px'
            },
            {
                field: 'usage',
                title: msg.get('usage'),
                filterable: false,
                template: kendo.template(usageTemplate),
                sortable: {
                    compare: atComparatorService.usageComparator
                },
                attributes: {
                    class: 'text-center',
                    style: 'padding: 5px'
                },
                width: '120px'
            },
            {
                command: {
                    template:
                        '<button ng-if="dataItem.allowDelete" class="btn btn-danger btn-animated from-top fa fa-trash" ng-click="deleteApplication(dataItem)" ng-disabled="dataItem.deleteBlocked">' +
                        '<span>' +
                        msg.get('delete') +
                        '</span>' +
                        '</button>'
                },
                attributes: {
                    class: 'text-center',
                    style: 'padding: 5px'
                },
                width: '95px'
            }
        ],
        sortable: true,
        pageable: {
            refresh: true,
            pageSizes: [10, 25, 50, 100, 200],
            buttonCount: 5,
            messages: {
                itemsPerPage: msg.get('itemsPerPage', 'applications'),
                display: msg.get('itemsDisplay', 'applications'),
                empty: msg.get('noItemsToDisplay', 'applications')
            },
            change: function() {
                setTotalRows();
                $scope.deselectAll();
            }
        },
        dataBound: function(e) {
            $timeout(function() {
                if (!$scope.isAppsTabActive) {
                    return;
                }
                setTotalRows();
                if (
                    $scope.stateParams &&
                    ($scope.stateParams.app || $scope.stateParams.site)
                ) {
                    angular.forEach(
                        $scope.applicationDataSource.view(),
                        function(item) {
                            item.selected = true;
                        }
                    );
                    $scope.stateParams = undefined;
                } else {
                    $scope.deselectAll();
                }

                gridPropertiesService.bindFilterAutoComplete(e, true);

                setDropdownHandler();

                applicationGrid = $scope.applicationGrid;

                $scope.setSelected();
            });
        }
    };

    function handleCheckboxItem(dataItem) {
        var array =
            $scope.selectedItems[
                $scope.isAppsTabActive ? 'applications' : 'websites'
            ];
        if (array.indexOf(dataItem.id) === -1) {
            if (dataItem.selected) {
                array.push(dataItem.id);
            }
        } else if (!dataItem.selected) {
            array.splice(array.indexOf(dataItem.id), 1);
        }
    }

    $scope.deselectAll = function() {
        var dataItems = $scope.isAppsTabActive
            ? $scope.applicationDataSource.view()
            : $scope.webSiteDataSource.view();

        angular.forEach(dataItems, function(item) {
            item.selected = false;
            $scope.allItems.selected = false;
        });
    };

    $scope.setSelected = function() {
        var array =
            $scope.selectedItems[
                $scope.isAppsTabActive ? 'applications' : 'websites'
            ];
        var dataItems = $scope.isAppsTabActive
            ? $scope.applicationDataSource.view()
            : $scope.webSiteDataSource.view();
        angular.forEach(dataItems, function(item) {
            if (array.indexOf(item.id) !== -1) {
                item.selected = true;
            }
        });
    };

    $scope.selectAllClicked = function(e) {
        var dataItems = $scope.isAppsTabActive
            ? $scope.applicationDataSource.view()
            : $scope.webSiteDataSource.view();

        angular.forEach(dataItems, function(item) {
            item.selected = e.target.checked;
            handleCheckboxItem(item);
        });
    };

    $scope.processMultiSelect = function(updatedDataItems) {
        updatedDataItems.forEach(function(item) {
            handleCheckboxItem(item);
        });
    };

    $scope.checkBoxClicked = function(e, item) {
        item.selected = e.target.checked;
        handleCheckboxItem(item);
    };

    $scope.productivityUpdate = function(item, productivityId) {
        var payload = [];
        payload.push(item.id);
        productivityApiService
            .updateWebsiteOrApplication(
                $scope.isAppsTabActive,
                productivityId,
                payload
            )
            .success(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('updateSuccessful'),
                    color: 'success'
                });
                item.productivity = productivityId;
            })
            .error(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('itemUpdatingError'),
                    color: 'danger'
                });
            });
    };

    $scope.categoryUpdate = function(item, categoryId) {
        var payload = [];
        payload.push(item.id);
        categoriesService
            .updateWebsiteOrApplication(
                $scope.isAppsTabActive,
                categoryId,
                payload
            )
            .success(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('updateSuccessful'),
                    color: 'success'
                });
                item.catid = categoryId;
            })
            .error(function() {
                $scope.$emit('showNotification', {
                    message: msg.get('itemUpdatingError'),
                    color: 'danger'
                });
            });
    };

    $scope.selectFilterOption = function(id, event) {
        if (event) {
            event.preventDefault();
        }

        var dataSource = $scope.isAppsTabActive
            ? $scope.applicationDataSource
            : $scope.webSiteDataSource;
        var filter = dataSource.filter();

        if (filter && filter.filters && $scope.manageCategories) {
            filter.filters = filter.filters.map(function(item) {
                if (item.field !== 'category') {
                    return item;
                } else {
                    return {
                        operator: 'contains',
                        value: '',
                        field: 'category'
                    };
                }
            });
        }

        dataSource.filter(filter);
        $scope.selectedFilterOptionId = id;
        updateCategoryFilter();

        $timeout(function() {
            pageSizeService.dataSourceReload([
                $scope.webSiteDataSource,
                $scope.applicationDataSource
            ]);
            $scope.clearLastCheckedIdx();
        });
    };

    $scope.$on('clearSelection', function() {
        $scope.clearLastCheckedIdx();
        $scope.selectedItems = {
            websites: [],
            applications: []
        };
    });

    $scope.$watch('filterResults', function(newValue) {
        $scope.filterOptions = newValue;
    });
}
