'use strict';

angular
    .module('app')
    .service('trackEventService', TrackEventService)
    .directive('trackEvent', TrackEvent)
    .directive('trackEventBarren', TrackEventBarren);

TrackEvent.$inject = ['trackEventService'];
TrackEventBarren.$inject = ['trackEventService'];
TrackEventService.$inject = ['$window', 'AccountSettingsService'];

function TrackEventService($window, accountSettingsService) {
    var analyticEventServiceFunctions = require('_app/serviceFunctions/analyticEventServiceFunctions')
        .analyticEventServiceFunctions;
    this.track = function(eventTitle, parameters) {
        if (!eventTitle) {
            return;
        }

        parameters = parameters || {
            category: 'All',
            account: accountSettingsService.account,
            label: accountSettingsService.account
        };

        analyticEventServiceFunctions.newEvent(eventTitle, parameters);
    };
}

function TrackEvent(trackEventService) {
    return {
        restrict: 'A',
        scope: {
            eventTitle: '@trackEvent'
        },
        link: function(scope, element) {
            element.on('click', function() {
                trackEventService.track(scope.eventTitle);
            });
        }
    };
}

function TrackEventBarren(trackEventService) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.on('click', function() {
                trackEventService.track(attrs.trackEvent);
            });
        }
    };
}
