import { styled } from '@mui/material/styles';
import { MUIStylesContainer } from './app.component.mui.styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import {
  secondaryFontSize,
  mediumFontWeight,
  primaryColor
} from '../constants';
import { InfoOutlined } from '@mui/icons-material';

export const AppContainer = styled(MUIStylesContainer)`
  padding: 10px 30px 30px;
`;

export const TextOverflow = styled('div')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LegacyHeader = styled(Typography)`
  font-size: ${secondaryFontSize} !important;
  font-weight: ${mediumFontWeight} !important;
  text-transform: uppercase;
`;

export const GridActionButton = styled(Button)`
  color: ${primaryColor} !important;
  &:hover {
    background-color: rgba(68, 68, 68, 0.04) !important;
  }
`;

export const InfoOutlinedIcon = styled(InfoOutlined)`
  font-size: 14px !important;
`;

export const BaseAutoCompleteSC = styled(Autocomplete)`
  && .MuiAutocomplete-listbox {
    font-weight: 400;
  }
`;

export const ReportHeaderInline = styled(Box)`
  display: inline-block;
  margin: 4px 8px 4px 0;
`;

export const ReportHeaderLeftColumn = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'inline-block'
  }
}));

export const ReportHeaderRightColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    float: 'right'
  }
}));
