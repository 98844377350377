import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import { StyledBox } from '../StyledBoxes';
import { useAccountWizardContext } from '../../services';
import { useWizardContext } from '../../../common/components/Wizard/WizardProvider';
import { SimpleMediumSubtitle, SimpleStepWizardContainer } from '../../styles';
import stepsData from '../../constants/stepsData.json';
import { wizardClasses } from '../../../common/components/Wizard/Wizard.styles';

const classes = {
  modal: {
    background: '#FFFFFF',
    boxShadow:
      '0px 6px 10p rgba(0,0,0,0.14), 0px 1px 18px rgba(0,0,0,0.12), 0px 3px 5px rgba(0,0,0, 0.2)',
    borderRadius: '8px',
    '& .MuiDialogContent-root:first-of-type': {
      padding: '15vh 10vh 10vh 10vh'
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'space-between',
      padding: '0vh 10vh 5vh 10vh'
    }
  }
};

export const AccountWizardStep2Modal = () => {
  const modalId = ['1B:0:2:none', '1B:1:3:specifics'];
  const textData = stepsData.step2B;
  const { formValues, dispatchForm, wizardName } = useAccountWizardContext();

  const { modalState, activeStep, setModalState, setActiveStep } =
    useWizardContext();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setModalState(false);
  };

  const handleClose = () => {
    if (formValues.PageSelection.join(',').includes('1B')) {
      dispatchForm({
        type: 'pageSelectionCleanup',
        payload: formValues.PageSelection.filter(
          (sel) => !sel.substring(0, 2).includes('1B')
        )
      });
    }
    setModalState(false);
  };

  return (
    <Box>
      <Dialog
        open={modalState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-modal"
        aria-describedby="alert-dialog-account-wizard-modal"
        sx={classes.modal}
        maxWidth="md"
      >
        <DialogContent>
          <Box>
            <SimpleMediumSubtitle noPadding>
              {textData.subtitle}
            </SimpleMediumSubtitle>
          </Box>
          <SimpleStepWizardContainer>
            <StyledBox
              noStyle={true}
              textArea={textData.radios}
              inputId={modalId}
              type="radio"
              name="step2B"
              defaultRadio={0}
              bypassControlFlow={true}
            />
          </SimpleStepWizardContainer>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              mr: 1,
              ...wizardClasses.wizardSecondaryColor,
              ...wizardClasses.wizardBtn
            }}
            onClick={handleClose}
            id={`click-back-step-modal-${wizardName}`}
          >
            ← Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{
              ...wizardClasses.wizardBtn,
              ...wizardClasses.wizardPrimaryColor
            }}
            onClick={handleNext}
            autoFocus
            id={`click-forward-step-modal-${wizardName}`}
          >
            Continue →
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
