import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { AuditLogViewComponent } from '../../../../_reactivtrak/src/reports/audit/views/AuditLog.view.tsx';
import { auditLogTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedAuditLog', {
    template: auditLogTemplate,
    controller: 'AuditLogCtrl'
});

angular.module('app').component('auditLog', react2angular(AuditLogViewComponent, []));
