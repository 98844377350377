import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export const ErrorMessage = ({ msg, sx }) => {
  return (
    <Box sx={sx}>
      <Typography>{msg}</Typography>
      <Typography sx={{ paddingTop: '10px' }}>
        If this error persists&nbsp;
        <Link href="mailto:support@activtrak.com">contact Support.</Link>
      </Typography>
    </Box>
  );
};
