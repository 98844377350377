import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const IntegrationsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Integrations';
  const className = props.className;
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path d="M24 6L19.2 0V4.5H1.20001V7.5H19.2V12L24 6Z"/>
      <path d="M0 18L4.8 24V19.5H22.8V16.5H4.8V12L0 18Z"/>
    </SvgIconWrapper>
  );
};
