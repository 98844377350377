import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { classificationClasses } from '../styles/Classifications.styles';
import { ThumbsUpGuy } from '../../common/assets/Icons/ThumbsUpGuy';

export default function CompletedClassifications() {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box sx={classificationClasses.classifiedTextBox}>
          <Typography variant="h2" sx={classificationClasses.classifiedHeader}>
            Great job!
          </Typography>
          <Typography
            variant="body1"
            sx={classificationClasses.classifiedSubtitle}
          >
            There are no more pending Classifications.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={classificationClasses.classifiedImageBox}>
          <ThumbsUpGuy />
        </Box>
      </Grid>
    </Grid>
  );
}
