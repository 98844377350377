import React, { Fragment } from 'react';
import { Avatar, ListItem, ListItemText } from '@mui/material';
import { userActionsClasses } from './UserActions.styles.js';
import { handleLogOut } from './../../../utils/api/logout.js';
import CustomMenu from '../../CustomMenu/CustomMenu.jsx';

const UserActions = ({ firstName }) => {
  const [avatarEl, setAvatar] = React.useState(null);

  const avatarClicked = (event) => {
    setAvatar(avatarEl ? null : event.currentTarget);
  };

  const avatarClosed = () => {
    setAvatar(null);
  };

  const open = Boolean(avatarEl);

  const handleLogout = (e) => {
    e.preventDefault();
    handleLogOut();
  };

  return (
    <Fragment>
      <Avatar sx={userActionsClasses.avatar} onClick={avatarClicked}>
        {(firstName || ' ').charAt(0)}
      </Avatar>
      <CustomMenu
        anchorEl={avatarEl}
        keepMounted
        open={open}
        onClose={avatarClosed}
      >
        <ListItem component="a" href={'#'} onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </CustomMenu>
    </Fragment>
  );
};

export default UserActions;
