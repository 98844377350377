import React, { useCallback, useEffect, useRef } from 'react';
import { SvgIcon } from '@mui/material';
import { useAccountWizardContext } from '../services';
import { useWizardContext } from '../../common/components/Wizard/WizardProvider';
import { AccountWizardIcons } from '../constants';
import {
  AccountWizardRadioText,
  SimpleLabel,
  SimpleRadio,
  StyledDiv,
  StyledInput,
  StyledLabel
} from '../styles';

export const StyledBox = (props: StyledBoxProps): any => {
  const [checked, setChecked] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<string>('');
  const {
    type,
    textArea,
    backgroundColors,
    inputId,
    name,
    noStyle,
    defaultRadio,
    bypassControlFlow,
    icons,
    radioTextWeight
  } = props;
  const [targetBox, setTargetBox] = React.useState<string>('');
  const [activeRadio, setActiveRadio] = React.useState<number>(null);
  const { formValues, dispatchForm, controlFlowHandler } =
    useAccountWizardContext();
  const isMounted = useRef(false);
  const selections: string[] = formValues.PageSelection;
  const { modalState, activeStep } = useWizardContext();

  if (!noStyle && !backgroundColors) {
    throw new Error('Styled Boxes Configuration Error');
  }

  useEffect(() => {
    if (isMounted.current) {
      if (target && targetBox) caseHandler(checked, target, targetBox);
    } else {
      if (typeof defaultRadio === 'number' && type === 'radio') {
        const returningPage = selections.find(
          (i) => i.charAt(0) === inputId[defaultRadio].charAt(0)
        );

        // sets default radio including modal radio (step 2B)
        if (!returningPage || modalState) {
          const defaultRadioBox = `${inputId[defaultRadio]}`;
          setActiveRadio(defaultRadio);
          setTargetBox(defaultRadioBox);
          addBox(defaultRadioBox, 'RadioBtn');
        }
      }
      // if coming back to page and selected, ensure checked state is also true.
      if (type === 'checkbox' && selections.includes(`${inputId}:${name}`)) {
        setChecked(true);
      }
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, activeRadio]);

  const addBox = useCallback(
    (box, boxType) => {
      dispatchForm({ type: `add${boxType}`, payload: box });
    },
    [dispatchForm]
  );

  const removeBox = useCallback(
    (box) => {
      dispatchForm({ type: `removeBox`, payload: box });
    },
    [dispatchForm]
  );

  const caseHandler = (checked: boolean, targetType, box: string) => {
    let radioBoxActiveRadio: number;
    if (targetType === 'radio') {
      radioBoxActiveRadio = parseInt(box.split(':')[1]);
    }
    switch (true) {
      case targetType === 'checkbox' && checked:
        return addBox(box, 'Checkbox');
      case targetType === 'radio' && radioBoxActiveRadio === activeRadio:
        return addBox(box, 'RadioBtn');
      case targetType === 'checkbox' && !checked:
        return removeBox(box);
      case targetType === 'radio' && radioBoxActiveRadio !== activeRadio:
        return removeBox(box);
      default:
        console.error('Unknown Case');
    }
  };

  const isRadioAlreadySelected = (id, targetType): boolean => {
    return targetType === 'radio' && selections.includes(id);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | { target: any }
  ) => {
    if (!e.target.type) return;

    // build request box shape
    const box =
      e.target.type === 'checkbox'
        ? `${e.target.id}:${e.target.name}`
        : `${e.target.id}`;
    setTarget(e.target.type);

    if (e.target.type === 'radio') {
      return handleRadioChange(e, box);
    }

    setTargetBox(box);
    setChecked(!checked);
  };

  const handleRadioChange = (
    e: React.ChangeEvent<HTMLInputElement> | { target: any },
    box
  ) => {
    if (isRadioAlreadySelected(e.target.id, e.target.type)) return;
    if (
      typeof activeRadio === 'number' &&
      activeRadio !== parseInt(e.target.value)
    ) {
      removeBox(targetBox);
      setTargetBox(box);
      return setActiveRadio(parseInt(e.target.value));
    }

    // edge case when coming back to page and state is reset.
    const returningPage = selections.find(
      (i) => i.charAt(0) === e.target.id.charAt(0)
    );

    if (returningPage) {
      removeBox(returningPage);
    }

    setActiveRadio(parseInt(e.target.value));
    setTargetBox(box);
  };

  const handleBoxClick = (e) => {
    let currentTarget: HTMLInputElement;

    if (e.target.querySelector('input') instanceof HTMLInputElement) {
      currentTarget = e.target.querySelector('input');
      // radios can't be unchecked by clicking so return if click
      if (isRadioAlreadySelected(currentTarget.id, currentTarget.type)) return;

      handleChange({
        target: {
          type: currentTarget.type,
          id: currentTarget.id,
          name: currentTarget.name,
          value: currentTarget.value
        }
      });
    } else if (
      e.target.nextElementSibling instanceof HTMLInputElement ||
      e.target.parentElement.nextElementSibling instanceof HTMLInputElement
    ) {
      currentTarget =
        e.target.nextElementSibling ??
        e.target.parentElement.nextElementSibling;

      if (isRadioAlreadySelected(currentTarget.id, currentTarget.type)) return;

      handleChange({
        target: {
          type: currentTarget.type,
          id: currentTarget.id,
          name: currentTarget.name,
          value: currentTarget.value
        }
      });
    } else {
      handleChange(e);
    }
  };

  const showBox = (id) => {
    if (bypassControlFlow) {
      return true;
    }
    return controlFlowHandler(activeStep, id);
  };

  switch (true) {
    case type === 'checkbox':
      return (
        <StyledDiv
          selected={checked || selections.includes(`${inputId}:${name}`)}
          borderColor={backgroundColors[0]}
          onClick={handleBoxClick}
        >
          {typeof icons === 'object' && (
            <SvgIcon
              sx={{ fill: (icons['fill'] as string) ?? 'none' }}
              component={AccountWizardIcons[`${icons['iconName']}`]}
              inheritViewBox
            />
          )}
          <StyledInput
            id={Array.isArray(inputId) ? inputId.join('-') : inputId}
            type={type}
            checked={checked || selections.includes(`${inputId}:${name}`)}
            name={name}
            readOnly
          />
          <StyledLabel
            htmlFor={Array.isArray(inputId) ? inputId.join('-') : inputId}
            dangerouslySetInnerHTML={{
              __html: Array.isArray(textArea) ? textArea.join('') : textArea
            }}
          ></StyledLabel>
        </StyledDiv>
      );
    case type === 'radio' && noStyle:
      return (inputId as string[]).map((id, i) => {
        return (
          !!showBox(inputId[i]) && (
            <SimpleLabel
              htmlFor={inputId[i]}
              key={`guided-setup-radio-no-style-${id}`}
            >
              <SimpleRadio
                type="radio"
                id={inputId[i]}
                name={name}
                value={i}
                checked={
                  activeRadio === i || selections.includes(`${inputId[i]}`)
                }
                onChange={handleChange}
              />
              {textArea[i]['boldedOption'] ? (
                <>
                  <AccountWizardRadioText
                    dangerouslySetInnerHTML={{
                      __html: textArea[i]['boldedOption']
                    }}
                    $radioTextWeight={radioTextWeight}
                  />
                  :
                  <AccountWizardRadioText
                    dangerouslySetInnerHTML={{ __html: textArea[i]['text'] }}
                  />
                </>
              ) : (
                <AccountWizardRadioText
                  dangerouslySetInnerHTML={{ __html: textArea[i]['text'] }}
                  $radioTextWeight={radioTextWeight}
                />
              )}
            </SimpleLabel>
          )
        );
      });
    case type === 'radio':
      return backgroundColors.map((color, i) => {
        return (
          !!showBox(inputId[i]) && (
            <StyledDiv
              selected={
                activeRadio === i || selections.includes(`${inputId[i]}`)
              }
              borderColor={color}
              onClick={handleBoxClick}
              key={`guided-setup-radio-${i}`}
            >
              {Array.isArray(icons) && (
                <SvgIcon
                  sx={{ fill: (icons[i]['fill'] as string) ?? 'none' }}
                  component={AccountWizardIcons[`${icons[i]['iconName']}`]}
                  inheritViewBox
                />
              )}
              <StyledInput
                id={inputId[i]}
                type={type}
                value={i}
                checked={
                  activeRadio === i || selections.includes(`${inputId[i]}`)
                }
                name={name}
                readOnly
              />
              <StyledLabel
                htmlFor={inputId[i]}
                dangerouslySetInnerHTML={{ __html: textArea[i]['text'] }}
              ></StyledLabel>
            </StyledDiv>
          )
        );
      });
  }
};
interface StyledBoxProps {
  type: string;
  backgroundColors?: string[];
  textArea: string | object[];
  inputId: string | string[];
  name?: string;
  defaultRadio?: number;
  noStyle?: boolean;
  radioTextWeight?: string;
  bypassControlFlow?: boolean;
  icons?:
    | { iconName: string; fill?: string }
    | { iconName: string; fill?: string }[];
}
