'use strict';

angular.module('app').directive('atTag', atTag);

function atTag() {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            options: '=atOptions'
        },
        template: require('views/widgets/atTag.html'),
        controller: atTagCtrl
    };
}

atTagCtrl.$inject = [];

function atTagCtrl() {}
