'use strict';

angular.module('app')
    .controller('blockedDomainModalController', BlockedDomainModalController);

BlockedDomainModalController.$inject = ['$scope', '$rootScope', '$window', '$uibModalInstance', 'modalData', '$timeout', 'messagesService', 'atHelperFunctions', 'templateServiceFunctions'];

function BlockedDomainModalController($scope, $rootScope, $window, $uibModalInstance, modalData, $timeout,  msg, atHelperFunctions, templateServiceFunctions) {


    $scope.selected = modalData.selectedGroup;
    var newBlockedDomains = [];
    var newUnblockedDomains = [];

    function blockedByAllComputers(domain) {
        return _.find(modalData.allComputerDomains, function (d) {
            return d.domain === domain;
        });
    }

    var columnTemplate = function (item) {
        var disable = blockedByAllComputers(item.domain);
        return '<div class="checkbox"' + (disable ? ' at-tooltip="This domain is being blocked by the &quot;All Computers&quot; group<br/> and cannot be added to this group."' : '') + '><input type="checkbox" id="selectbox-domain-column-{{dataItem.uid}}" ng-model="dataItem.blockedModel"' + (disable ? ' ng-disabled="true"' : '') + '></input>' +
            '<label for="selectbox-domain-column-{{dataItem.uid}}">' + templateServiceFunctions.getFaviconTemplateAsString(item.domain, true) + '</label></div>';
    };

    var updateBlocked = function (domain, isBlocked) {
        var index = newBlockedDomains.indexOf(domain);
        var unBIndex = newUnblockedDomains.indexOf(domain);
        if (unBIndex >= 0 && isBlocked) {
            newUnblockedDomains.splice(unBIndex, 1);
        } else if (isBlocked) {
            if (index >= 0) {
                return;
            }
            newBlockedDomains.push(domain);
        } else {
            if (index >= 0) {
                newBlockedDomains.splice(index, 1);
            } else {
                newUnblockedDomains.push(domain);
            }
        }
        $scope.isModalDirty = newBlockedDomains.length > 0 || newUnblockedDomains.length > 0;
    };

    var gridDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                modalData.gridDataSource({
                        params: options.data
                    })
                    .success(function (result) {
                        var ret = [];
                        ret.data = [];
                        ret.total = result.total;
                        var isNotActivTrakDomain = function( domainName ){ return !domainName.toLowerCase().includes('activtrak'); };
                        result.data.filter(isNotActivTrakDomain).forEach(function (domainName) {
                            var dom = {
                                domain: domainName,
                                blocked: false
                            };
                            modalData.selectedGroup.blockedDomains.forEach(function (blockedDomain) {
                                if (domainName === blockedDomain.domain) {
                                    dom.blocked = true;
                                }
                                if (!!modalData.domainChanges) {
                                    var changedDomain = modalData.domainChanges[domainName];
                                    if (!!changedDomain) {
                                        var originalGroupNames = changedDomain.originalGroups.map(function (group) {
                                            return group.name;
                                        });
                                        var groupNames = changedDomain.groups.map(function (group) {
                                            return group.name;
                                        });
                                        var ogIdx = originalGroupNames.indexOf(modalData.selectedGroup.name);
                                        var gIdx = groupNames.indexOf(modalData.selectedGroup.name);
                                        if (ogIdx > -1 && gIdx === -1 && blockedDomain.domain === domainName) {
                                            dom.blocked = false;
                                        }
                                    }
                                }
                                dom.blockedModel = dom.blocked;
                            });
                            ret.data.push(dom);
                        });
                        options.success(ret);
                    });
            }
        },
        pageSize: 40,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    $scope.blockedDomainRowClicked = function (e, row) {
        if (blockedByAllComputers(row.domain)) {
            return;
        }

        row.blocked = !row.blocked;
        updateBlocked(row.domain, row.blocked);

        $timeout(function () {
            row.blockedModel = row.blocked;
            e.sender.element.find('.k-state-selected').toggleClass('k-state-selected');
        }, 100);
    };

    $scope.blockedDomainGridOptions = {
        dataSource: gridDataSource,
        columns: [{
            field: 'domain',
            title: msg.get('domain'),
            filterable: {
                cell: {
                    operator: 'contains',
                    showOperators: false,
                    template: function (args) {
                        $(args.element)
                            .addClass('k-textbox')
                            .attr('style', 'width:100%')
                            .attr('placeholder', 'Filter Domains…')
                            .attr('ng-change', 'updateFilter(this)')
                            .attr('ng-model', 'filter');
                    },
                }
            },
            template: columnTemplate,
            attributes: {
                class: 'text-nowrap grid-tooltip'
            }
        }],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        height: atHelperFunctions.getGridHeight(),
        scrollable: {
            virtual: true
        },
        selectable: 'row'
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

    $scope.applyBlockedDomains = function () {
        $uibModalInstance.close({
            blockedDomains: newBlockedDomains,
            unblockedDomains: newUnblockedDomains
        });
    };

    $scope.updateFilter = function (e) {
        gridDataSource.filter({
            field: 'domain',
            operator: 'contains',
            value: e.filter
        });
    };
}
