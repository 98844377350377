import moment from 'moment';
import { IReportFilters } from '../models/IReportFilters';

type GenerateParametersOptions = {
  showProductivity: boolean;
  period: string;
  rangeOverride: string;
  modeOverride: string;
};

export const generateParameters = (
  reportFilters: Partial<IReportFilters>,
  options: Partial<GenerateParametersOptions>
) => {
  const { showProductivity, period, rangeOverride, modeOverride } = options;

  const { users, dates, productivity } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const user =
    users?.length > 0 ? users[0] : { userId: '', userType: '', filterMode: '' };
  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  let result =
    'from=' +
    encodeURIComponent(momentFromDate.format('YYYY-MM-DD')) +
    '&to=' +
    encodeURIComponent(momentToDate.format('YYYY-MM-DD')) +
    '&userId=' +
    user.userId +
    '&userType=' +
    user.userType +
    '&mode=' +
    (modeOverride || user.filterMode || 'users');

  if (showProductivity) {
    result += '&productivity=' + productivity;
  }

  if (period) {
    result += '&period=' + period;
  }

  if (dates.range || rangeOverride) {
    result += '&range=' + (rangeOverride || dates.range);
  }

  return result;
};
