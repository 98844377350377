import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ProfileSettingsForm } from '../components/ProfileSettingsForm';
import { useProfileState } from '../../common/services/Profile';
import Paper from '@mui/material/Paper';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

export const ProfileSettingsView = () => {
  const {
    profile,
    isLoading,
    savingInProgress,
    onFormSubmit,
    onPasswordChange
  } = useProfileState();

  return (
    <>
      {isLoading && <CircularProgress color="primary" />}
      {!isLoading && (
        <Paper
          elevation={3}
          sx={{ padding: '20px', minWidth: '400px', maxWidth: '600px' }}
        >
          <ProfileSettingsForm
            onSubmit={onFormSubmit}
            profile={profile}
            onPasswordChange={onPasswordChange}
            savingInProgress={savingInProgress}
          />
        </Paper>
      )}
    </>
  );
};

export const ProfileSettingsComponent = (props) => (
  <ReactivTrakComponentWrapper>
    <ProfileSettingsView {...props} />
  </ReactivTrakComponentWrapper>
);
