'use strict';

angular
    .module('app')
    .controller('videoViewerCtrl', VideoViewerCtrl)
    .directive('videoViewer', VideoViewer);

function VideoViewer() {
    return {
        restrict: 'E',
        scope: {
            data: '='
        },
        template: require('views/widgets/videoViewer.html'),
        controller: 'videoViewerCtrl'
    };
}

VideoViewerCtrl.$inject = [
    '$scope',
    '$timeout',
    '$filter',
    'localStorageService',
    'AccountSettingsService',
    'atHelperFunctions'
];

function VideoViewerCtrl(
    $scope,
    $timeout,
    $filter,
    localStorageService,
    AccountSettingsService,
    atHelperFunctions
) {
    $scope.showDetailTitle = localStorageService.get(
        'screenshotDetailTitle-' + AccountSettingsService.username
    );
    if ($scope.showDetailTitle === null) {
        $scope.showDetailTitle = true;
    }

    $scope.filterTime = function (t) {
        return atHelperFunctions.filterTime(t, $filter);
    };

    $scope.showDetailTitleChange = function () {
        $scope.showDetailTitle = !$scope.showDetailTitle;
        localStorageService.set(
            'screenshotDetailTitle-' + AccountSettingsService.username,
            $scope.showDetailTitle
        );
    };

    $scope.titleTooltipCondition = function (element) {
        var elementCopy = element
            .clone()
            .css({
                display: 'inline',
                width: 'auto',
                visibility: 'hidden'
            })
            .appendTo('body');
        var elementCopyWidth = elementCopy.width();
        elementCopy.remove();

        var elementCssWidth = element.css('width');
        var elementMaxWidth = 0;

        if (elementCssWidth.indexOf('%') > 0) {
            elementMaxWidth = Math.round(
                (window.innerWidth / 100) * parseInt(elementCssWidth) - 10
            );
        } else {
            elementMaxWidth = parseInt(elementCssWidth);
        }

        return elementCopyWidth > elementMaxWidth;
    };

    $scope.config = {
        plugins: {
            controls: {
                autoHide: true,
                autoHideTime: 3000
            },
            playback: {
                speeds: ['0.25', '0.5', '1', '2']
            }
        }
    };

    $scope.playerReady = function (api) {
        $scope.playerApi = api;
        $scope.playerApi.setVolume(0);
        $timeout(function () {
            $scope.playerApi.play();
        });
    };

    function createInfo(data) {
        var time = moment(data.time).format(
            AccountSettingsService.datetimeMomentFormat()
        );
        $scope.videoTitle = time + ' | ' + data.user + ' | ' + data.titleBar;
        $scope.videoInfo = [
            {
                title: 'Time',
                value: time
            },
            {
                title: 'Computer',
                value: data.computer
            },
            {
                title: 'User',
                value: data.user
            },
            {
                title: 'Title',
                value: data.titleBar
            },
            {
                title: 'URL',
                value: data.url
            },
            {
                title: 'Executable',
                value: data.executable
            },
            {
                title: 'Description',
                value: data.description
            }
        ];
        $scope.showingVideo = true;
    }

    $scope.$watch('data', function (data) {
        if (data) {
            createInfo(data);
        }
    });
}
