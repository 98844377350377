import React, { Fragment, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { IntegrationCard } from '../components/IntegrationCard';
import { useIntegrationContextProvider } from './IntegrationProvider';

export const IntegrationCards = () => {
  const { integrationState } = useIntegrationContextProvider();

  const { integrationData, init, integrationsNotification } = integrationState;
  useEffect(() => {
    init();
  }, [init, integrationsNotification]);

  return (
    <Grid container spacing={4}>
      {!integrationData && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {integrationData?.map((integration) => (
        <Fragment key={integration.integrationcode}>
          <IntegrationCard {...integration} />
        </Fragment>
      ))}
    </Grid>
  );
};
