import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement
} from 'chart.js';

import { DashboardType } from '../../common/enums';
import { BasicDashboardView } from './BasicDashboard.view';
import { DashboardWidgetId } from '../enums';
import { IWidget } from '../models';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DashboardProvider } from '../services';

export const OrganizationDashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    PointElement,
    LineElement
  );

  const organizationWidgetList: IWidget[] = [
    {
      id: DashboardWidgetId.VirtualOfficeVisibilitySubheader,
      widths: { xs: 12 }
    },
    {
      id: DashboardWidgetId.TodaysActivityChart,
      widths: { xs: 6 }
    },
    {
      id: DashboardWidgetId.TodaysProductivity,
      widths: { xs: 6 }
    },
    {
      id: DashboardWidgetId.OrganizationTrendsSubheader,
      widths: { xs: 12 }
    },
    {
      id: DashboardWidgetId.Workload,
      widths: { xs: 6 }
    },
    {
      id: DashboardWidgetId.ProductivityGoal,
      widths: { xs: 6 }
    }
  ];

  //this memoizes array without infinite looping
  const widgets = useRef(organizationWidgetList);

  return (
    <BasicDashboardView
      dashboardType={DashboardType.organization}
      showGreeting={false}
      showRefreshBtn={true}
      widgets={widgets.current}
    />
  );
};

export const OrganizationDashboardComponent = () => {
  return (
    <ReactivTrakComponentWrapper>
      <DashboardProvider>
        <OrganizationDashboard />
      </DashboardProvider>
    </ReactivTrakComponentWrapper>
  );
};
