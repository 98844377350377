import React from 'react';
import TotalCountProps from './TotalCount.props';
import Box from '@mui/material/Box';
import { mediumFontWeight } from '../../constants';

export const TotalCount: React.FC<TotalCountProps> = ({ ...props }) => {
  const { label, count, testId } = props;

  return (
    <Box
      data-testid={testId}
      sx={{
        display: 'inline-flex',
        alignItems: 'center'
      }}
    >
      <span
        style={{
          fontWeight: mediumFontWeight,
          textTransform: 'uppercase',
          paddingRight: 3
        }}
      >
        {label}:
      </span>{' '}
      {count}
    </Box>
  );
};
