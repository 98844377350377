import { useMemo } from "react";

export const useUIRouterQueryString = () => {
  const queryString = useMemo(() => {
    const $injector = window.angular
      .element(document.getElementById('core-app'))
      .injector();

    const $location: any = $injector.get('$location');

    return {
      get: (param: string) => $location.search()[param]
    };
  },[]);

  return queryString;
};
