'use strict';

angular.module('app').directive('atSelectOnClick', AtSelectOnClick);

AtSelectOnClick.$inject = ['$window'];

function AtSelectOnClick($window) {
    return {
        restrict: 'A',
        link: function (scope, element) {
            element.on('click', function () {
                if (!$window.getSelection().toString()) {
                    // Required for mobile Safari
                    this.setSelectionRange(0, this.value.length);
                }
            });
        }
    };
}
