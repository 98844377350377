import React, { useState } from 'react';
import { ICategory } from '../../common/models';
import Button from '@mui/material/Button';
import DeleteCategoriesDialog from './DeleteCategoriesDialog';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

type CategoryActivityDetailProps = {
  selectedCategories: ICategory[];
  isDisabled: boolean;
};

export default function DeleteCategoryButton(
  props: CategoryActivityDetailProps
) {
  const { isDisabled = false, selectedCategories } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        color="secondary"
        startIcon={<DeleteIcon />}
      >
        {isDisabled ? (
          <Tooltip
            title="Select one or more categories to enable delete"
            placement="bottom"
          >
            <>Delete</>
          </Tooltip>
        ) : (
          <>Delete</>
        )}
      </Button>

      {selectedCategories?.length > 1 ? (
        <DeleteCategoriesDialog
          open={openDialog}
          onClose={() => handleClose()}
          onDelete={handleDeleteClick}
          selectedCategories={selectedCategories}
        />
      ) : (
        <DeleteCategoryDialog
          open={openDialog}
          onClose={() => handleClose()}
          categoryActivityDetail={selectedCategories && selectedCategories[0]}
        />
      )}
    </>
  );
}
