import { react2angular } from 'react2angular';
import { WebhookLogReportViewComponent } from '../../../../_reactivtrak/src/reports/web-hooks/views/WebhookLogReport.view';
import { webhookLogTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedWebhookLogReport', {
    template: webhookLogTemplate,
    controller: 'WebhookLogCtrl'
});

angular.module('app').component('webhookLogReport', react2angular(WebhookLogReportViewComponent, []));
