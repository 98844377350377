import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import availableMenus from '../../../../../_app/navigation/navigation-files/availableMenus.json';

/*

!!!ATENTION!!!

Zustand and this code is experimental - please don't copy/paste without reaching out

*/

const sideBarPinnedStoreDef = (set) => ({
  isSideBarPinned: true as boolean,

  setIsSideBarPinned: (pinned: boolean) => {
    return set({ isSideBarPinned: pinned });
  }
});

const menuItemsStore = createStore(() => ({
  menuItems: []
}));

const sideBarPinnedStore = createStore(sideBarPinnedStoreDef);
const sideBarResetFunctionStore = createStore(() => ({
  sidebarReset: null
}));

const routeStore = createStore(() => ({
  route: {} as any
}));

const useMenuItemStore = (s) => useStore<any, any>(menuItemsStore, s);
const useSideBarPinnedStore = (s) => useStore<any, any>(sideBarPinnedStore, s);
const useRouteStore = (s) => useStore<any, any>(routeStore, s);

const { setState: setNavigationItems_angular } = menuItemsStore;
const { setState: setNavigationRoute_angular } = routeStore;
const { setState: setIsSideBarPinned_angular } = sideBarPinnedStore;
const { setState: setSidebarResetFunction_angular } = sideBarResetFunctionStore;

const sidebarReset = () => {
  const { sidebarReset } = sideBarResetFunctionStore.getState();
  if (sidebarReset) {
    sidebarReset();
  }
};

const find = (items: any[], predicate: (item: any) => boolean) => {
  return items.reduce((current, item) => {
    if (current) return current;
    if (predicate(item)) return item;
    if (item.submenus) return find(item.submenus, predicate);
  }, null);
};

const findItemByName = (name: string) => {
  const items = menuItemsStore.getState().menuItems;
  return find(items, (item) => item.action?.options?.ref === name);
};

const findItemById = (id: string) => {
  const items = menuItemsStore.getState().menuItems;
  return find(items, (item) => item.id == id);
};

//List containing items excluded from fav navigation
// TODO: Move definitions to new router state definitions (TBD)
const EXCLUDED_ITEM_FILTER = [
  'ActivTrak Profile',
  'Logout',
  'Activation',
  'Upgrade Plan',
  'Home',
  'Admin Panel',
  'Organization Overview',
  'Team Management',
  'Activity Dashboard'
];

const isExcludedPage = (label: string): boolean => {
  return EXCLUDED_ITEM_FILTER.includes(label);
};

// TODO: Move definitions to new router state definitions (TBD)
const EXCLUDED_ROUTES = [
  'app.dashboard',
  'app.dashboardCurrent.admin',
  'app.dashboardCurrent.organization',
  'app.dashboardCurrent.management',
  'app.settings.classification.id',
  'app.settings.groups_id',
  'app.settings.useragents_id',
  'app.settings.computeragents_id',
  'app.settings.identity_id'
];

const isExcludedRoute = (routeName: string): boolean => {
  if (EXCLUDED_ROUTES.includes(routeName)) {
    return true;
  }

  const availableMenu = Object.values(availableMenus).find((item: any) => {
    return item.action?.options?.ref === routeName;
  });

  return isExcludedPage(availableMenu?.label);
};

const pageTitleStore = createStore(() => ({
  pageTitle: '' as string
}));

const usePageTitleStore = (s: any) => useStore<any, any>(pageTitleStore, s);
const { setState: setPageTitle_angular } = pageTitleStore;
const { getState: getPageTitle_angular } = pageTitleStore;

export {
  useMenuItemStore,
  useRouteStore,
  useSideBarPinnedStore,
  findItemByName,
  findItemById,
  isExcludedPage,
  isExcludedRoute,
  find,
  setNavigationItems_angular,
  setNavigationRoute_angular,
  setIsSideBarPinned_angular,
  setSidebarResetFunction_angular,
  usePageTitleStore,
  setPageTitle_angular,
  getPageTitle_angular,
  sidebarReset
};
