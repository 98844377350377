import React from 'react';
import { cardTotalTimeClasses } from './CardTotalTime.style';
import { InfoPanel, InfoPanelVariant } from '..';
import { convertSecondsToHoursString } from '../../../utilities/helpers';
import TimeMode from '../../../models/TimeMode';
import ReportMode from '../../../models/ReportMode';
import { hasRole } from '../../../utilities/userRoles';

const CardTotalTime = (props) => {
    const {
        id,
        rootClass,
        contentClass,
        totalTime,
        activeTime,
        name,
        timeMode,
        filterMode,
        filterModes,
        loading,
        reportMode,
        align
    } = props;
    const time = timeMode === TimeMode.Total ? totalTime : activeTime;
    const link =
        reportMode === ReportMode.Users
            ? `/#/app/reports/topusers?${filterMode === filterModes.users ? 'user' : 'computer'}=${encodeURIComponent(
                  name
              )}`
            : `/#/app/reports/topusers?groupId=${id}`;

    const isSupportAdvanced = hasRole('SupportAdvanced');

    const generateHeading = () => {
        return isSupportAdvanced ? (
            <span style={cardTotalTimeClasses.disabled}>
                <span style={cardTotalTimeClasses.bold}>{convertSecondsToHoursString(time, 'Hours')}</span>
                {reportMode === ReportMode.Users ? '  ' : ' Average '}
                {timeMode === TimeMode.Total ? 'Including ' : 'Excluding '}
                Passive
            </span>
        ) : (
            <a href={link}>
                <span>
                    <span style={cardTotalTimeClasses.bold}>{convertSecondsToHoursString(time, 'Hours')}</span>
                    {reportMode === ReportMode.Users ? '  ' : ' Average '}
                    {timeMode === TimeMode.Total ? 'Including ' : 'Excluding '}
                    Passive
                </span>
            </a>
        );
    };

    return (
        <div id="id_1e8b5dc2-f06b-4143-81e3-a4db2edead94">
            <InfoPanel
                heading={generateHeading()}
                rootClass={rootClass}
                headingClass={contentClass}
                loading={loading}
                align={align}
                variant={InfoPanelVariant.Inline}
            />
        </div>
    );
};

export default CardTotalTime;
