export enum ChartDemoItem {
  Timeline = 'timeline',
  ReportFilters = 'reportFilters',
  CheckboxLegendPie = 'checkboxLegendPie',
  CheckboxLegendBar = 'checkboxLegendBar',
  BarChart = 'bar',
  CustomLabelBarChart = 'customLabelBarChart',
  CustomLabelPluginBarChart = 'customLabelPluginBarChart',
  PieChart = 'pie',
  CustomLabelPieChart = 'customLabelPieChart'
}
