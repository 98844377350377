'use strict';

angular.module('app')
    .controller('changePasswordModalController', ChangePasswordModalController);

    ChangePasswordModalController.$inject = ['$scope', '$uibModalInstance', 'modalData', 'accessApiService', 'notificationService', 'messagesService'];

function ChangePasswordModalController($scope, $uibModalInstance, modalData, accessApiService, notificationService, msg) {
    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.currentUserName = modalData.currentUserName;
    $scope.currentPassword = '';
    $scope.currentPasswordConfirm = '';

    $scope.submitChangePassword = function() {
        if ($scope.changePasswordForm.$invalid) {
            return;
        }

        var payload = {
            userName: $scope.currentUserName,
            password: $scope.currentPassword
        };

        accessApiService.savePassword(payload).success(function() {
            $scope.accessState = 'main';
            notificationService.showNotification(
                msg.get('passwordChanged'),
                'success'
            );

            $scope.dismiss();
        });
    };
}
