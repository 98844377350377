import { react2angular } from 'react2angular';
import { ChromebookUsersComponent } from '../../_reactivtrak/src/chromebook-users/views/ChromebookUsers.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('chromebookUsers', react2angular(ChromebookUsersComponent, []));

}
