'use strict';
const lodash = require('lodash');

const htmlEscape = (text) => {
    if (!text || !lodash.isString(text)) {
        return text;
    }
    return text.replace(/["&'\/<>]/g, function (a) {
        return {
            '"': '&quot;',
            '&': '&amp;',
            '\'': '&#39;',
            '/': '&#47;',
            '<': '&lt;',
            '>': '&gt;'
        } [a];
    });
};

const htmlDecode = (text) => {
    if (!text || !lodash.isString(text)) {
        return text;
    }
    return text.replace(/&#([0-9]{1,3});/g, function (a) {
        return {
            '&quot;': '"&quot;"',
            '&amp;': '&',
            '&#39;': '\'',
            '&#47;': '/',
            '&lt;': '<',
            '&gt;': '>'
        } [a];
    });
};

const isMobileAgent = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const isEdgeBrowser = () => {
    return window.navigator.userAgent.indexOf('Edge/') > 0;
};

const isIeBrowser = () => {
    let ua = window.navigator.userAgent;
    let ie = 0;

    if (window.navigator.appName === 'Microsoft Internet Explorer') {
        ie = 1;
    } else if (window.navigator.appName === 'Netscape') {
        ie = ua.indexOf('Trident/');
    }
    return ie > 0;
};

const isIeOrEdgeBrowser = () => {
    return isEdgeBrowser() || isIeBrowser();
};

const isFirefoxBrowser = () => {
    return window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

const browserCheckers = {
    ie11: isIeBrowser,
    edge: isEdgeBrowser,
    ieOrEdge: isIeOrEdgeBrowser,
    mobile: isMobileAgent,
    firefox: isFirefoxBrowser
};

const runIf = (browserParam, callback) => {
    if (browserCheckers[browserParam] && browserCheckers[browserParam]()) {
        callback();
    }
};

export const browserServiceFunctions = {
    htmlEscape: htmlEscape,
    htmlDecode: htmlDecode,
    isMobileAgent: isMobileAgent,
    isEdgeBrowser: isEdgeBrowser,
    isIeBrowser: isIeBrowser,
    isIeOrEdgeBrowser: isIeOrEdgeBrowser,
    isFirefoxBrowser: isFirefoxBrowser,
    runIf: runIf
};
