import React from 'react';
import { primaryPurple } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const TechInvestIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 37;
  const width = props.width ?? 36;
  const title = props.title ?? 'TechInvest';
  const className = props.className;
  const fillColor = props.fillColor ?? primaryPurple;

  let classList = 'at-icon at-icon-techinvest';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 37`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M26.0001 32.5H24.2601C23.8477 32.2083 23.5011 31.8333 23.2427 31.3992C22.9843 30.9651 22.8199 30.4816 22.7601 29.98V28.63H21.2401V30C21.2702 30.9123 21.5967 31.7899 22.1701 32.5H13.8301C14.4076 31.7845 14.7343 30.8992 14.7601 29.98V28.63H13.2401V30C13.1777 30.4981 13.012 30.9777 12.7537 31.4082C12.4954 31.8386 12.1502 32.2105 11.7401 32.5H9.94012C9.67491 32.5 9.42055 32.6054 9.23302 32.7929C9.04548 32.9804 8.94012 33.2348 8.94012 33.5C8.94012 33.7652 9.04548 34.0196 9.23302 34.2071C9.42055 34.3947 9.67491 34.5 9.94012 34.5H26.0601C26.1945 34.5123 26.33 34.4949 26.4569 34.449C26.5838 34.4031 26.6991 34.3298 26.7945 34.2344C26.8899 34.1389 26.9632 34.0237 27.0091 33.8968C27.055 33.7699 27.0724 33.6344 27.0601 33.5C27.0604 33.3635 27.0327 33.2283 26.9787 33.1029C26.9247 32.9775 26.8457 32.8645 26.7463 32.7708C26.647 32.6771 26.5296 32.6048 26.4013 32.5582C26.2729 32.5116 26.1364 32.4918 26.0001 32.5Z"
        fill={fillColor}
      />
      <path
        d="M33.68 15.9H32V25.5H4V5.5H21.87L23 3.5H3.5C3.10218 3.5 2.72064 3.65804 2.43934 3.93934C2.15804 4.22064 2 4.60218 2 5V26C2 26.3978 2.15804 26.7794 2.43934 27.0607C2.72064 27.342 3.10218 27.5 3.5 27.5H32.5C32.8978 27.5 33.2794 27.342 33.5607 27.0607C33.842 26.7794 34 26.3978 34 26V15.88L33.68 15.9Z"
        fill={fillColor}
      />
      <path
        d="M7.7001 23.5V9.25997H19.7001L20.6201 7.65997H6.1001V23.5H7.7001V23.5Z"
        fill={fillColor}
      />
      <path
        d="M26.8499 1.64L21.1299 11.5C20.9969 11.6953 20.9205 11.9237 20.9092 12.1598C20.898 12.3959 20.9523 12.6305 21.0662 12.8375C21.1801 13.0446 21.3491 13.2162 21.5545 13.3331C21.76 13.45 21.9937 13.5078 22.2299 13.5H33.6799C33.9162 13.5078 34.1499 13.45 34.3553 13.3331C34.5608 13.2162 34.7298 13.0446 34.8437 12.8375C34.9576 12.6305 35.0119 12.3959 35.0007 12.1598C34.9894 11.9237 34.913 11.6953 34.7799 11.5L29.0599 1.64C28.9471 1.44705 28.7858 1.28701 28.5919 1.17579C28.3981 1.06456 28.1785 1.00604 27.9549 1.00604C27.7314 1.00604 27.5118 1.06456 27.318 1.17579C27.1241 1.28701 26.9628 1.44705 26.8499 1.64V1.64Z"
        fill={fillColor}
      />
    </svg>
  );
};
