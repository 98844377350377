import React from 'react';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { Box, Typography } from '@mui/material';

export default function PlaygroundHowToUse() {
  return (
    <ReactivTrakComponentWrapper>
      <Box>
        <Typography variant="h4">Playground</Typography>
        <Typography variant="body1">
          The playground is a place to experiment with different components and
          features of the ReactivTrak application. You can use the playground to
          test out new ideas, learn how to use the application, or just have
          some fun.
        </Typography>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Selecting a playground
        </Typography>
        <ul>
          <li>
            To get started, select a playground from the dropdown menu in the
            top-right corner of the page.
          </li>
          <li>
            Once you have selected a playground, the JSX defined in the constant
            will be displayed in the playground.
          </li>
        </ul>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Adding a new playground
        </Typography>
        <ul>
          <li>
            Add a new playground definition to the `playgrounds` array in the
            file `src/playgrounds/constants/playgrounds.tsx`. It should follow
            the following format
            <Box sx={{ backgroundColor: '#e0e0e0', p: 2, mt: 1 }}>
              {`{
                id: 'exampleId',
                label: 'Example Playground',
                jsx: <Typography>Playground Example 1</Typography>
              }`}
            </Box>
          </li>
          <li>
            The new playground should be available in the dropdown menu upon
            save.
          </li>
          <li>
            When selected, the JSX defined in the constant will be displayed in
            the playground.
          </li>
          <li>
            The URL path will be updated to reflect the new playground id
            defined in the constant.
          </li>
          <li>
            This URL can be used to navigate directly to the specific playground
            including deep linking.
          </li>
        </ul>
      </Box>
    </ReactivTrakComponentWrapper>
  );
}

export const PlaygroundHowToUseComponent = () => <PlaygroundHowToUse />;
