import { useCallback, useRef, useState } from 'react';
import {
  IChromebookUser,
  IChromebookUserDto,
  IChromebookUsersService,
  IChromebookUsersState
} from '../models';
import { useAuthorization } from '../../common/services/Authorization';
import { NotificationType, Role, SortDirection } from '../../common/enums';
import { mapToChromebookUser } from '../utils';
import { sorter } from '../../common/helpers';

type ChromebookStateProps = {
  service: IChromebookUsersService;
};

export const useChromebookUsersState = ({
  service
}: ChromebookStateProps): IChromebookUsersState => {
  const {
    fetchChromebookUsers,
    addCbromebookUser,
    inviteCbromebookUser,
    deleteCbromebookUsers,
    confirmChromebookUser
  } = service;

  const [cbUsers, setCbUsers] = useState<IChromebookUser[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cbNotification, setCbNotification] = useState<NotificationType>();

  const authorizationService = useAuthorization();
  const isDisabled = useRef<boolean>(
    authorizationService.hasAnyRole([Role.SupportBasic])
  );

  const getChromebookUsers = useCallback(
    async (): Promise<void> => {
      setIsLoading(true);
      try {
        const usersDto: IChromebookUserDto[] = await fetchChromebookUsers();
        const users = usersDto.map((user) => mapToChromebookUser(user));
        setCbUsers(users);
      } catch (error) {
        setCbNotification({
          msg: 'Unable to load list of Chromebook Users',
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to load list of Chromebook Users'
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const init = useCallback(async (): Promise<void> => {
    if (!cbUsers) {
      getChromebookUsers();
    }
  }, [cbUsers, getChromebookUsers]);

  const addCbUser = useCallback(
    async (user: string): Promise<void> => {
      try {
        await addCbromebookUser({ user });
        setCbNotification({
          msg: 'New ChromeBook user added!',
          type: 'success'
        });
        getChromebookUsers();
      } catch (error) {
        const msg = error?.error || 'Unable to add a Chromebook User';
        setCbNotification({
          msg: msg,
          type: 'error'
        });
        console.error(`ActivTrak Error: ${msg}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const inviteCbUser = useCallback(
    async (user: string): Promise<void> => {
      try {
        await inviteCbromebookUser({ user });
        setCbNotification({
          msg: 'Invite email for this ChromeBook user was sent again',
          type: 'success'
        });
        getChromebookUsers();
      } catch (error) {
        const msg =
          error?.error ||
          'There was an error while inviting this ChromeBook user.';
        setCbNotification({
          msg: msg,
          type: 'error'
        });
        console.error(`ActivTrak Error: ${msg}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteCbUsers = useCallback(
    async (payload: string[], isCancel: false): Promise<void> => {
      try {
        await deleteCbromebookUsers(payload);
        setCbNotification({
          msg: isCancel
            ? 'Invitation for this ChromeBook user was cancelled'
            : 'User deleted!',
          type: 'success'
        });
        getChromebookUsers();
      } catch (error) {
        setCbNotification({
          msg: 'Unable to delete Chromebook User',
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to delete Chromebook User');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const confirmCbUser = useCallback(
    async (token: string, email: string): Promise<any> => {
      return confirmChromebookUser({ token, email });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setSelectedChromebookUsers = useCallback(
    (selectedItems: IChromebookUser[]): void => {
      setCbUsers((prevState) => {
        const resetData = prevState.map((item) => {
          return { ...item, selected: false };
        });
        selectedItems.forEach((u) => {
          const i = resetData.findIndex((cbu) => u.user === cbu.user);
          if (i === -1) {
            return;
          }
          resetData[i] = u;
        });
        return resetData;
      });
    },
    []
  );

  const setSortedChromebookUsers = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(cbUsers, sortDirection, sortOrderBy);
      setCbUsers(newOrder);
    },
    [cbUsers]
  );

  return {
    cbUsers,
    isLoading,
    isDisabled,
    cbNotification,
    setCbNotification,
    init,
    addCbUser,
    inviteCbUser,
    deleteCbUsers,
    confirmCbUser,
    setSelectedChromebookUsers,
    setSortedChromebookUsers
  };
};
