import angular from 'angular';

let $injector: angular.auto.IInjectorService = null;

export const useInjector = () => {
  if ($injector !== null) {
    return $injector;
  }

  $injector = window.angular
    .element(document.getElementById('core-app'))
    .injector();

  return $injector;
};
