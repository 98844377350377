import React, { useCallback, useState } from 'react';
import { ICategory } from '../../common/models';
import {
  Container,
  IconButton,
  List,
  ListItemButton,
  Popover
} from '@mui/material';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type CategoryActivityDetailProps = {
  categoryActivityDetail: ICategory;
  isDisabled?: boolean;
};

export default function DeleteCategoryButton(
  props: CategoryActivityDetailProps
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'delete-category-popover' : undefined;
  const [openDialog, setOpenDialog] = useState(false);
  const { categoryActivityDetail, isDisabled } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDialog(false);
    setAnchorEl(null);
  };

  const TriDotIcon = useCallback(() => {
    if (isDisabled) {
      return (
        <span style={{ cursor: 'not-allowed' }}>
          <IconButton disabled color="secondary">
            <MoreVertIcon />
          </IconButton>
        </span>
      );
    }

    return (
      <IconButton onClick={handleClick} color="secondary">
        <MoreVertIcon />
      </IconButton>
    );
  }, [isDisabled]);

  return (
    <Container>
      <TriDotIcon />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <List component="nav">
          <ListItemButton onClick={handleDeleteClick}>Delete</ListItemButton>
          <DeleteCategoryDialog
            open={openDialog}
            onClose={handleDeleteClose}
            categoryActivityDetail={categoryActivityDetail}
          />
        </List>
      </Popover>
    </Container>
  );
}
