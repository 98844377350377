/*
BAR CHART
https://react-chartjs-2.js.org/components/bar
https://www.chartjs.org/docs/latest/charts/bar.html
https://www.chartjs.org/docs/3.5.0/samples/bar/stacked.html

CUSTOM LEGENDS
https://www.chartjs.org/docs/3.3.2/samples/legend/html.html
https://stackoverflow.com/questions/37005297/custom-legend-with-chartjs-v2-0
*/
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartsDemoService from '../services/ChartDemoServices';
import { processBarData } from '../utils';

export default function GoogleBarChart() {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const apiParams = {
          from: '2022-06-07',
          to: '2022-06-12',
          userId: '-1',
          userType: 'AllUsers',
          mode: 'users',
          range: 'Custom_Date_Range',
          type: 'Total'
        };

        const chartsDemoService = new ChartsDemoService();
        const response: any = await chartsDemoService.getProductivity(
          apiParams
        );

        if (response) {
          const filteredData = processBarData(response);
          setChartData(filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    //retrigger api when params are updated
    if (!chartData) getData();
  }, [chartData]);

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };

  return (
    <>
      {!chartData && <div>Loading Chart</div>}
      {!chartData?.length && <div>No data available</div>}
      {chartData?.length && (
        <>
          <Bar data={chartData} options={options} />
        </>
      )}
    </>
  );
}
