import theme from '../../../common/theme/theme';

export const userCardClasses = {
    sidePaddings: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    topPaddings: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    titleTopMargins: {
        marginTop: 0,
        marginBottom: 0
    },
    hr: {
        marginTop: 0,
        marginBottom: 0
    }
};
