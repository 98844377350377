import {
  chromeBrowser,
  defaultBrowserConfig,
  edgeBrowser,
  firefoxBrowser,
  operaBrowser,
  windowsPlatform
} from '../constants/extension.const';
import {
  BrowserConfiguration,
  ExtensionConfiguration
} from '../models/ExtensionConfiguration';
import _ from 'lodash';

export const getBrowserConfigByName = (
  browser: string,
  platformConfig: ExtensionConfiguration
): BrowserConfiguration => {
  let browserConfig: BrowserConfiguration = null;
  switch (browser) {
    case operaBrowser:
      platformConfig.opera =
        platformConfig.opera ?? _.cloneDeep(defaultBrowserConfig);
      browserConfig = platformConfig.opera;
      break;
    case chromeBrowser:
      platformConfig.chrome =
        platformConfig.chrome ?? _.cloneDeep(defaultBrowserConfig);
      browserConfig = platformConfig.chrome;
      break;
    case edgeBrowser:
      platformConfig.edge =
        platformConfig.edge ?? _.cloneDeep(defaultBrowserConfig);
      browserConfig = platformConfig.edge;
      break;
    case firefoxBrowser:
      platformConfig.firefox =
        platformConfig.firefox ?? _.cloneDeep(defaultBrowserConfig);
      browserConfig = platformConfig.firefox;
      break;
  }
  return browserConfig;
};

export const setExtensionEnabled = (
  browserConfig: BrowserConfiguration,
  enabled: boolean
) => {
  browserConfig.extensionEnabled = enabled;
  if (
    !browserConfig.extensionEnabled &&
    browserConfig.installationConfiguration
  ) {
    browserConfig.installationConfiguration.install = false;
    browserConfig.installationConfiguration.useGpo = false;
  }
};

export const initializeAllBrowsers = (
  platformConfig: ExtensionConfiguration
) => {
  platformConfig.opera = platformConfig.opera ?? {
    ...defaultBrowserConfig
  };
  platformConfig.chrome = platformConfig.chrome ?? {
    ...defaultBrowserConfig
  };
  platformConfig.edge = platformConfig.edge ?? {
    ...defaultBrowserConfig
  };
  platformConfig.firefox = platformConfig.firefox ?? {
    ...defaultBrowserConfig
  };
};

export const isPlatformAllEnabled = (
  platformConfig: ExtensionConfiguration
): boolean => {
  return (
    (platformConfig?.chrome?.extensionEnabled ?? false) &&
    (platformConfig?.edge?.extensionEnabled ?? false) &&
    (platformConfig?.firefox?.extensionEnabled ?? false) &&
    (platformConfig?.opera?.extensionEnabled ?? false)
  );
};

export const setExtensionInstall = (
  selectedBrowserConfig: BrowserConfiguration,
  platform: string,
  install: boolean
) => {
  if (!selectedBrowserConfig) return;

  if (!install) {
    selectedBrowserConfig.installationConfiguration = null;
  } else {
    selectedBrowserConfig.installationConfiguration = {
      install: true,
      useGpo: platform == windowsPlatform
    };
  }
};

export const updatePlatformConfig = (
  platformConfig: ExtensionConfiguration,
  browser: string,
  platform: string,
  install: boolean
): ExtensionConfiguration => {
  //get the browser for this OS
  const selectedBrowserConfig = getBrowserConfigByName(browser, platformConfig);

  //set the install flag, this will update the platformConfig via the selectedBrowserConfig reference
  setExtensionInstall(selectedBrowserConfig, platform, install);

  return platformConfig;
};
