import { apiService } from '../../helpers';
import { getDefaultConfig } from '../../config';
import { IFavoritesService } from './IFavoritesService';

const FAVORITES_VERSION_TAG = 1;

export const favoritesService = (): IFavoritesService => {
  return {
    getFavorites: async (
    ): Promise<any> => {
      const defaultConfig = getDefaultConfig();
      const response = await apiService.get<any>(
        `/api/settings/favorites`,
        {
          ...defaultConfig
        }
      );

      if (Array.isArray(response?.data)) {
        return response?.data;
      }

      return response?.data?.favorites ?? [];
    },
    saveFavorites: async (
      favorites: any
    ): Promise<any> => {
      const defaultConfig = getDefaultConfig();
      const payload = {
        version: FAVORITES_VERSION_TAG,
        favorites
      };

      const response = await apiService.post<any>(
        `/api/settings/favorites`,
        {
          ...defaultConfig,
          data: payload
        }
      );
      return response?.data;
    }
  };
};
