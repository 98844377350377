'use strict';

angular.module('app')
    .controller('includedGroupsModalController', IncludedGroupModalController);

IncludedGroupModalController.$inject = ['$scope', '$rootScope', '$window', '$uibModalInstance', 'modalData', '$timeout', 'messagesService', 'atHelperFunctions', 'templateServiceFunctions'];

function IncludedGroupModalController($scope, $rootScope, $window, $uibModalInstance, modalData, $timeout,  msg, atHelperFunctions, templateServiceFunctions) {


    $scope.selected = modalData.selectedDomain;
    var includedGroups = [];
    var removedGroups = [];

    var columnTemplate = function (item) {
        return '<div class="checkbox"><input type="checkbox" id="selectbox-inc-group-column-{{dataItem.uid}}" ng-model="dataItem.blockedModel"></input>' +
            '<label for="selectbox-inc-group-column-{{dataItem.uid}}">' + templateServiceFunctions.groupTypeIconAndNameTemplateAsString(item) + '</label></div>';
    };

    var updateIncluded = function (group, isBlocked) {
        if (isBlocked) {
            if (group.name === 'All Computers') {
                includedGroups.forEach(function (incGroup) {
                    if (incGroup.name !== 'All Computers') {
                        var groupFound = false;
                        removedGroups.forEach(function (removedGroup) {
                            if (removedGroup.name === incGroup.name) {
                                groupFound = true;
                            }
                        });
                        if (!groupFound) {
                            incGroup.isUpdated = !!incGroup.beganChecked;
                            removedGroups.push(incGroup);
                        }
                    }
                });
                includedGroups = [];
                group.isUpdated = !group.beganChecked;
                includedGroups.push(group);
            } else {
                var dupCheck = false;
                var allCompIdx;
                angular.forEach(includedGroups, function (incGroup, idx) {
                    if (incGroup.name === 'All Computers') {
                        allCompIdx = idx;
                    } else if (group.name === incGroup.name) {
                        dupCheck = true;
                    }
                });

                if (allCompIdx >= 0) {
                    var allComp = includedGroups.splice(allCompIdx, 1)[0];
                    allComp.isUpdated = !!allComp.beganChecked;
                    removedGroups.push(allComp);
                }

                if (!dupCheck) {
                    group.isUpdated = !group.beganChecked;
                    includedGroups.push(group);
                }

                removedGroups.forEach(function (removedGroup, idx) {
                    if (removedGroup.name === group.name) {
                        removedGroups.splice(idx, 1);
                    }
                });

            }
        } else {
            angular.forEach(includedGroups, function (incGroup, idx) {
                if (group.name === incGroup.name) {
                    includedGroups.splice(idx, 1);
                    group.isUpdated = !!group.beganChecked;
                    removedGroups.push(group);
                }
            });
        }
        $scope.isModalDirty = includedGroups.length > 0 || removedGroups.length > 0;
        updateDataSource();
    };

    var updateDataSource = function () {
        gridDataSource.data().forEach(function (item) {
            item.blocked = false;
            includedGroups.forEach(function (group) {
                if (group.name === item.name) {
                    item.blocked = true;
                }
            });
            item.blockedModel = item.blocked
        });
    };

    var gridDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                var groups = angular.copy(modalData.gridDataSource.data());
                groups.forEach(function (group) {
                    group.blockedDomains.forEach(function (domain) {
                        if (domain.domain === $scope.selected.domain) {
                            group.blocked = true;
                        }
                    });
                    if (!!modalData.domainChanges && !!modalData.domainChanges[$scope.selected.domain]) {
                        var isBlocked = false;
                        modalData.domainChanges[$scope.selected.domain].groups.forEach(function (grp) {
                            if (grp.name === group.name) {
                                isBlocked = true;
                            }
                        });
                        group.blocked = isBlocked;
                    }
                    if (group.blocked) {
                        group.isUpdated = false;
                        group.beganChecked = true;
                        includedGroups.push(group);
                    }
                    group.blockedModel = group.blocked;
                });
                $timeout(function () {
                    options.success(groups);
                });
            }
        },
        pageSize: 150,
    });

    $scope.includedGroupsRowClicked = function (e, row) {
        row.blocked = !row.blocked;
        updateIncluded(row, row.blocked);

        $timeout(function () {
            row.blockedModel = row.blocked;
            e.sender.element.find('.k-state-selected').toggleClass('k-state-selected');
        }, 100);
    };

    $scope.includedGroupsGridOptions = {
        dataSource: gridDataSource,
        columns: [{
            field: 'name',
            title: msg.get('group'),
            filterable: {
                cell: {
                    operator: 'contains',
                    showOperators: false,
                    template: function (args) {
                        $(args.element)
                            .addClass('k-textbox')
                            .attr('style', 'width:100%')
                            .attr('placeholder', 'Filter Groups…')
                            .attr('ng-change', 'updateFilter(this)')
                            .attr('ng-model', 'filter');
                    },
                }
            },
            template: columnTemplate,
            attributes: {
                class: 'text-nowrap grid-tooltip'
            }
        }],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        height: atHelperFunctions.getGridHeight(),
        scrollable: {
            virtual: true
        },
        selectable: 'row'
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

    $scope.updateFilter = function (e) {
        gridDataSource.filter({
            field: 'name',
            operator: 'contains',
            value: e.filter
        });
    };


    $scope.applyIncludedGroups = function () {
        var incGroups = [];
        var remGroups = [];
        includedGroups.forEach(function (newGroup) {
            gridDataSource.data().forEach(function (group) {
                if (group.name === newGroup.name && newGroup.isUpdated) {
                    incGroups.push(group);
                }
            });
        });
        removedGroups.forEach(function (newGroup) {
            gridDataSource.data().forEach(function (group) {
                if (group.name === newGroup.name && newGroup.isUpdated) {
                    remGroups.push(group);
                }
            });
        });
        $uibModalInstance.close({
            includedGroups: incGroups,
            removedGroups: remGroups
        });
    };
}
