import React, { useEffect } from 'react';
import { List, Collapse, ListItemIcon } from '@mui/material';
import { NestedFilterItem } from './NestedFilterItem';
import { NestedFilterSectionProps } from './NestedCheckboxFilter.props';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ToggleIconSC } from './NesterCheckboxFilter.styles';

export const NestedFilterSection = (props: NestedFilterSectionProps) => {
  const { item, subMenuItems, isParentOpen = false, onSelected } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isParentOpen);
  }, [isParentOpen]);

  return (
    <>
      <ToggleIconSC onClick={() => setOpen(!open)}>
        {item}
        <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
      </ToggleIconSC>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {subMenuItems.map((subItem, index: number) => {
            return (
              <NestedFilterItem
                key={`nested-filter-item-${index}`}
                label={subItem?.label}
                selected={subItem?.selected}
                onClick={() => onSelected(subItem?.filterValue)}
                sx={{ paddingLeft: '35px' }}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
