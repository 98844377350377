import { WorkloadLabel, WorkloadLevelsLabel } from '../models';

export const WORKLOAD_LABELS: WorkloadLabel[] = [
  WorkloadLabel.Optimal,
  WorkloadLabel.High,
  WorkloadLabel.Low,
  WorkloadLabel.Varied
];

export const WORKLOAD_USERS_LABELS: WorkloadLevelsLabel[] = [
  WorkloadLevelsLabel.UnderUtilized,
  WorkloadLevelsLabel.Healthy,
  WorkloadLevelsLabel.OverUtilized
];

export const WorkloadLevels = {
  1: WorkloadLevelsLabel.Healthy,
  2: WorkloadLevelsLabel.OverUtilized,
  3: WorkloadLevelsLabel.UnderUtilized
};

export const workloadTopLineCopy = {
  great: 'Way to go!',
  isUnhealthy: 'Workload balances are unhealthy.',
  isVaried: 'Workload balances are varied.',
  remainUnhealthy: 'Workload balances remain unhealthy.',
  remainVaried: 'Workload balances remain varied.'
};

export const workloadBottomCopy = {
  great: 'Great job maintaining healthy workload balances.',
  healthy:
    'More team members have a healthy workload balance than in the previous time period.',
  overUtilized:
    'More team members are overutilized compared to the previous period.',
  underUtilized:
    'More team members are underutilized compared to the previous period',
  previousOptimal: 'In the previous period they were optimal.',
  continueOverUtilized: 'Team members continue to be overutilized',
  continueUnderUtilized: 'Team members continue to be underutilized',
  overAndUnderUtilized:
    'Some team members are overutilized and some are underutilized.'
};
