'use strict';

angular.module('app').directive('alarmDetailsControls', AlarmDetailsControls);

function AlarmDetailsControls() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsControls.html'),
        controller: AlarmDetailsControlsCtrl
    };
}

AlarmDetailsControlsCtrl.$inject = [
    '$scope',
    '$state',
    'messagesService',
    'atHelperFunctions'
];

function AlarmDetailsControlsCtrl($scope, $state, msg, atHelperFunctions) {
    $scope.isNewAlarm = true;

    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    function disableSaveUpdated(reasons) {
        $scope.disableSaveTooltip = msg.get(
            'alarmConfigDisableSaveTooltip',
            reasons,
            $scope.isNewAlarm
        );
    }

    function isComponentValid() {
        // console.log('Controls Validation');
        if (!$scope.options.master) {
            return;
        }

        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload(payload) {
        // console.log('Email Messages Update Payload', payload);
        if (!$scope.options.master) {
            return true;
        }

        if (
            atHelperFunctions.isEmpty(payload.name) &&
            !$scope.alarm.editor.allowBlankName
        ) {
            $scope.$emit('showNotification', {
                message: msg.get('missingAlarmName'),
                color: 'danger'
            });
            return false;
        }

        return true;
    }

    // eslint-disable-next-line
    function alarmUpdated(oldAlarm) {
        // console.log('Email Messages Alarm Updated', oldAlarm);
        if (!$scope.options.master) {
            return;
        }

        $scope.isNewAlarm = $scope.isNewAlarm && $scope.alarm.alarmid <= 0;
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.controls,
        master: $scope.options.master,
        disableSaveUpdated: disableSaveUpdated
    };

    if ($scope.options.master) {
        $scope.component.isValid = isComponentValid;
        $scope.component.alarmUpdated = alarmUpdated;
        $scope.component.updatePayload = updatePayload;

        var duplicateName = $scope.options.getDuplicateName();
        if (!!duplicateName) {
            $scope.alarm.name = duplicateName;
            $scope.alarm.editor.allowBlankName = false;
        }
    }

    $scope.options.register($scope.component);
    $scope.isNewAlarm = $scope.isNewAlarm && $scope.alarm.alarmid <= 0;

    $scope.toggleActivate = function() {
        $scope.alarm.active = !$scope.alarm.active;
    };

    $scope.viewLog = function(alarm) {
        $state.go('app.reports.alarmlog', {
            alarm: alarm.name
        });
    };
}
