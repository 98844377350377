import { IRoute } from '../../../../../models/IRoute';

export const appSettingsProductivity: IRoute = {
  name: 'app.settings.productivity',
  stateDefinition: {
    url: '/settings/productivity',
    redirectTo: 'app.settings.classification.tab'
  }
};

export default appSettingsProductivity;
