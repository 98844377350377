import { inMemoryStore } from 'activtrak-ui-utilities';

const getOptimize = (testName) => {
    if (window.google_optimize) {
        return window.google_optimize.get(testName);
    } else {
        return;
    }
};

const getTest = (testName) => {
    const abTests = inMemoryStore.get('abTests');
    return abTests ? abTests[testName] : getOptimize(testName);
};

const triggerDataLayerEvent = (eventName) => {
    window.dataLayer.push({ event: eventName });
};

export { getTest, triggerDataLayerEvent };
