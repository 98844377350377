import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  gray4,
  primaryColor,
  notifError,
  primaryAqua,
  gray3,
  secondaryFontSize
} from '../../common/constants';

export const bulkGroupClasses = {
  dialogContainer: {
    width: '447px',
    maxheight: '377px',
    padding: '0px 0px 0px',
    '& $div': {
      paddingTop: '0px'
    }
  },
  selectPath: {
    display: 'flex',
    padding: '0px 0px'
  },
  pathSelection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'
  },
  pathTitle: {
    color: primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px'
  },
  pathIcon: {
    fontSize: '17px',
    width: '17px',
    height: '17px'
  },
  pathsContent: {
    padding: '0 15px',
    textAlign: 'center'
  },
  pathDivisionGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 0px',
    '& :nth-of-type(2)': {
      padding: '10px 0px'
    }
  },
  pathDivision: {
    border: `1px solid ${gray3}`,
    height: '80px',
    width: '1px'
  },
  paragraphTitle: {
    margin: '0px 0px 0px 5px'
  },
  pathButtonCta: {
    margin: '35px auto',
    padding: '5px 15px'
  },
  uploadFileContainer: {
    height: '129px',
    margin: 'auto',
    borderRadius: '3px',
    border: `1px dashed ${primaryAqua}`,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '24px',
    padding: '0px'
  },
  dragAndDropArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as const, //this fixes a wonky CSSType library issue
    justifyContent: 'center',
    cursor: 'pointer'
  },
  uploadFileTitleHighlight: {
    color: primaryAqua
  },
  uploadFileTitle2: {
    color: gray4
  },
  uploadFileInstructions: {
    fontSize: secondaryFontSize,
    color: primaryColor,
    marginTop: '10px',
    '&.error': {
      color: notifError
    }
  },
  uploadFileDescription: {
    margin: '0px 0px 10px 0px'
  },
  uploadFileProgressContainer: {
    width: '100%',
    borderRadius: '3px',
    background: '#ffffff',
    padding: '20px 0px 25px'
  },
  uploadFileProgressBar: {
    marginTop: '10px !important',
    height: '100%',
    background: primaryAqua
  },
  divDoneButton: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    marginBottom: '10px'
  },
  doneButton: {
    display: 'flex'
  },
  dialogWide: {
    '& .MuiDialog-paper': {
      minWidth: '55vw',
      minHeight: '55vh'
    }
  },
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: '40vw'
    }
  }
};

export const SectionHeaders = styled(Box)`
  font-weight: 500;
  margin-left: 5px;
`;
