import { react2angular } from 'react2angular';
import { AdminDashboardComponent } from '../../_reactivtrak/src/dashboard/views/AdminDashboard.view';
import { OrganizationDashboardComponent } from '../../_reactivtrak/src/dashboard/views/OrganizationDashboard.view';
import { TeamManagementComponent } from '../../_reactivtrak/src/dashboard/views/TeamManagement.view';


export const connect = (angular) => {

  angular
    .module('app')
    .component('homepageAdmin', react2angular(AdminDashboardComponent, []));

  angular
    .module('app')
    .component('homepageOrganizationOverview', react2angular(OrganizationDashboardComponent, []));

  angular
    .module('app')
    .component('homepageManagement', react2angular(TeamManagementComponent, []));

}