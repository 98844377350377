export enum RoleAccessKeys {
  Dashboards = 'Dashboards',
  DashboardsActivity = 'Dashboards-Activity',
  DashboardsAdmin = 'Dashboards-Admin',
  DashboardsOrgOverview = 'Dashboards-OrgOverview',
  DashboardsTeamMgmt = 'Dashboards-TeamMgmt',
  TeamPulse = 'TeamPulse',
  Coaching = 'Coaching',
  Insights = 'Insights',
  ExecutiveSummary = 'Insights-ExecutiveSummary',
  TeamComparison = 'Insights-TeamComparison',
  Location = 'Insights-Location',
  WorkEfficiency = 'Insights-WorkEfficiency',
  ActivityBreakdown = 'Insights-ActivityBreakdown',
  TimeOnTask = 'Insights-TimeOnTask',
  WorkloadBalance = 'Insights-WorkloadBalance',
  TechnologyUsage = 'Insights-TechUsage',
  BenchmarkAndGoals = 'Insights-BenchmarksAndGoals',
  PersonalInsights = 'Insights-PersonalInsights',
  Subscriptions = 'Insights-Subscriptions',
  Configuration = 'Insights-Configuration',
  TopUsers = 'TopUsers',
  Productivity = 'Productivity',
  WorkingHours = 'WorkingHours',
  TopWebsites = 'TopWebsites',
  TopApplications = 'TopApplications',
  TopCategories = 'TopCategories',
  ActivityLog = 'ActivityLog',
  Integrations = 'Integrations',
  Alarms = 'Alarms',
  AlarmsLog = 'Alarms-Log',
  AlarmsConfiguration = 'Alarms-Configuration',
  AlarmsNotifications = 'Alarms-Notifications',
  AlarmsRiskLevel = 'Alarms-RiskLevel',
  AlarmsScreenshotsRedaction = 'Alarms-ScreenshotsRedaction',
  AlarmsScreenshotsFlagging = 'Alarms-ScreenshotsFlagging',
  AlarmsScreenshotsReports = 'Alarms-ScreenshotsReports',
  AlarmsVideos = 'Alarms-Videos',
  Settings = 'Settings',
  Impact = 'Impact',
  CommandCenterAccountUsersLink = 'CCACCOUNTUSERSLINK',
  CommandCenterParentAccountLink = 'CCPARENTACCOUNTLINK'
}
