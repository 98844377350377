import { react2angular } from 'react2angular';
import { ProfileSettingsComponent } from '../../_reactivtrak/src/profile-settings/views/ProfileSettings.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('accountProfile', react2angular(ProfileSettingsComponent, []));

}
