import theme from '../../theme/theme';

export const customInputClasses = {
  error: {
    position: 'absolute',
    top: 'calc(100% - 4px)',
    margin: '3px 0 0'
  },
  phoneInput: {
    padding: '18.5px 14px',
    border: `1px solid ${theme.palette.text.disabled}`
  },
  required: {
    position: 'absolute',
    top: `calc(50% - ${theme.spacing()})`,
    right: theme.spacing(),
    color: theme.palette.text.disabled,
    '&.required-error': {
      color: theme.palette.error.main
    }
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      height: theme.spacing(7)
    },
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '18.5px 14px'
    }
  },
  visibilityButton: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2)
  }
};
