'use strict';

angular.module('app')
    .controller('agentVersionModal', AgentVersionModal);

    AgentVersionModal.$inject = ['$scope', '$uibModalInstance', 'agentInstallApiService', 'notificationService', 'latestGA', 'accountAgentTargets'];

function AgentVersionModal($scope, $uibModalInstance, agentInstallApiService, notificationService, latestGA, accountAgentTargets) {

    $scope.gaWindowsVersion = "";
    $scope.selectedWindowsVersion = "";
    $scope.originalWindowsVersion = "";
    $scope.gaMacVersion = "";
    $scope.selectedMacVersion = "";
    $scope.originalMacVersion = "";
    $scope.windowsWarning = "";
    $scope.macWarning = "";

    setGAVersion();
    setDefaultDropdownValues();

    $scope.windowsTargetsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                agentInstallApiService
                    .getAgentTargets()
                    .then(function (result) {
                        var winVersions = filterTargets(result.data, "Win");
                        options.success(winVersions);
                    });
            }
        }
    });         
    $scope.macTargetsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                agentInstallApiService
                    .getAgentTargets()
                    .then(function (result) {
                        var macTargets = filterTargets(result.data, "Mac");
                        options.success(macTargets);
                    });
            }
        }
    });     
    
    function filterTargets(apiData, operatingSystem){
        var osVersions = apiData.filter(function(version){ return version.os === operatingSystem && version.visible});
                            
        osVersions.forEach(function (winVersion) {
            winVersion.title = winVersion.version.version
        })
        osVersions.unshift(
            {version:{version: "GA"}, 
            title: "Production (auto-update)",
            subtitle: "includes major features"
        });
        osVersions.unshift(
            {version:{version: "Beta"}, 
            title: "Early Access (auto-update)",
            subtitle: "includes every new feature"
        });
        return osVersions;
    }

    $scope.agentModalTemplate = function(item) {
        if (!item) {
            return '';
        }
    
        return (
            '<span class="agent-version-title">'+ item.title + '</span> ' + (item.subtitle ? 
            '<span class="agent-version-subtitle">' + item.subtitle + '</span>' : '')
        );
    };

    function setGAVersion(){
        latestGA.forEach(function (ga) {
            switch (ga.os){
                case "Win":
                    $scope.gaWindowsVersion = ga.version.version;
                    break;
                case "Mac":
                    $scope.gaMacVersion = ga.version.version;
                    break;
            }
        });
    }

    function setDefaultDropdownValues(){
        accountAgentTargets.forEach(function (ga) {
            switch (ga.os){
                case "Win":
                    $scope.selectedWindowsVersion = ga.pinned ? ga.version.version : ga.type_id;
                    $scope.originalWindowsVersion = $scope.selectedWindowsVersion;
                break;
                case "Mac":
                    $scope.selectedMacVersion = ga.pinned ? ga.version.version : ga.type_id;
                    $scope.originalMacVersion = $scope.selectedMacVersion;
                    break;
            }
        });
    }

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.windows_dropdownlist_change = function () {
        var windowsSwitchedToAutoUpdate = switchedToAutoUpdate($scope.originalWindowsVersion, $scope.selectedWindowsVersion);
        var windowsSwitchedToManual = switchedToManual($scope.originalWindowsVersion, $scope.selectedWindowsVersion);
        $scope.windowsWarning = (windowsSwitchedToAutoUpdate) ? switchAutoUpdateMessage("windows", $scope.selectedWindowsVersion) : 
             (windowsSwitchedToManual) ? switchManualMessage("windows", $scope.selectedWindowsVersion) : "";
    }

    $scope.mac_dropdownlist_change = function () {
        var macSwitchedToAutoUpdate = switchedToAutoUpdate($scope.originalMacVersion, $scope.selectedMacVersion);
        var macSwitchedToManual = switchedToManual($scope.originalMacVersion, $scope.selectedMacVersion);
        $scope.macWarning = (macSwitchedToAutoUpdate) ? switchAutoUpdateMessage("mac", $scope.selectedMacVersion) : 
             (macSwitchedToManual) ? switchManualMessage("mac", $scope.selectedMacVersion) : "";
    }

    function switchedToAutoUpdate(original, selected)
    {
        return original !== "GA" && original !== "Beta" &&
        (selected == "GA" || selected == "Beta");
    }

    function switchedToManual(original, selected)
    {
        return (original == "GA" || original == "Beta") &&
        selected !== "GA" && selected !== "Beta";
    }

    function switchAutoUpdateMessage(osName, selectedAgentVersion){
        return "By selecting this " + osName + " agent version, you are opting into auto-updates" +
            (selectedAgentVersion == "Beta" ? " that will include beta and preview features." : ".") +
            " Once you proceed, your agents will begin updating immediately.";
    }

    function switchManualMessage(osName)
    {
        return "Targeting a specific version will pause automatic updates for " + osName + ". You will have to " +
            "manually update this setting for agents to update to newer versions going forward. Once you proceed, your agents will begin " +
            "updating immediately.";
    }

    $scope.update = function () {
        var type = ($scope.selectedWindowsVersion == "GA" || $scope.selectedWindowsVersion == "Beta") ? 
            $scope.selectedWindowsVersion : null;
        var version = type == null ? $scope.selectedWindowsVersion : null;
        agentInstallApiService.setAgentVersion('Win', version, type).then(function() {
            type = ($scope.selectedMacVersion == "GA" || $scope.selectedMacVersion == "Beta") ? 
                $scope.selectedMacVersion : null;
            version = type == null ? $scope.selectedMacVersion : null;
            agentInstallApiService.setAgentVersion('Mac', version, type).then(function() {                    
                notificationService.showNotification(
                    'Agent version saved',
                    'success'
                )})
                .catch(function() {
                    notificationService.showNotification(
                        'Failed to save agent type',
                        'danger'
                    );
                    $uibModalInstance.close();
                });
            $uibModalInstance.close('agentVersionUpdated');
        })
        .catch(function() {
            notificationService.showNotification(
                'Failed to save agent type',
                'danger'
            );
            $uibModalInstance.close();
        });
    }
}
