import React from 'react';
import { Header } from '../components/Header';
import { AuthorizationProvider } from '../../../../services/Authorization';
import { ThemeProvider } from '@mui/material';
import { getTheme } from '../../../../styles/theme';
import { MUIStylesContainer } from '../../../../styles/app.component.mui.styles';

export const HeaderView = (props: any) => {
  const theme = getTheme();
  return (
    <ThemeProvider theme={theme}>
      <MUIStylesContainer>
        <AuthorizationProvider>
          <Header {...props}></Header>
        </AuthorizationProvider>
      </MUIStylesContainer>
    </ThemeProvider>
  );
};
