import React, { useState } from 'react';
import { Create } from '@mui/icons-material';
import { cardHeadClasses } from './CardHead.styles';
import { Typography, CircularProgress, InputBase, Box } from '@mui/material';

const CardHead = ({ companyName, accountId, updateCompanyName }) => {
  const [isShown, setIsShown] = useState(false);
  const [isInput, setIsInput] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyPress = (event) => {
    if (event) {
      if (event.key === 'Escape') {
        setIsInput(false);
      }
      if (
        event.key === 'Enter' ||
        event.type === 'blur' ||
        event.key === 'Tab'
      ) {
        let { value } = event.target;
        value = value.trim();
        if (!value) {
          setIsInput(false);
          return;
        }
        if (value && value !== companyName) {
          setIsSubmitting(true);
          setIsLoading(true);
          updateCompanyName(accountId, value).finally(() => {
            setIsSubmitting(false);
            setIsInput(false);
            setIsLoading(false);
          });
        } else {
          setIsInput(false);
        }
      }
    }
  };

  return (
    <Box sx={cardHeadClasses.divBorder}>
      <Box
        sx={cardHeadClasses.container}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {isInput ? (
          <InputBase
            id="companyName"
            placeholder="Company Name"
            defaultValue={companyName}
            sx={cardHeadClasses.textHolder}
            autoComplete="off"
            classes={{
              input: cardHeadClasses.input
            }}
            inputProps={{
              maxLength: '45'
            }}
            autoFocus
            disabled={isSubmitting}
            onKeyDown={handleKeyPress}
            onBlur={handleKeyPress}
          />
        ) : (
          <Box
            sx={companyName ? cardHeadClasses.title : cardHeadClasses.noName}
            className="company-name"
            onClick={() => setIsInput(false)}
          >
            {companyName || 'Name Unavailable'}
          </Box>
        )}
        {!isLoading ? (
          <Box sx={{ visibility: isShown ? 'visible' : 'hidden' }}>
            <Create
              sx={cardHeadClasses.textCreate}
              onClick={() => setIsInput(!isInput)}
            />
          </Box>
        ) : (
          <CircularProgress size="12px" />
        )}
      </Box>
      <Typography sx={cardHeadClasses.accountId} variant="caption">
        Acct#{accountId}
      </Typography>
    </Box>
  );
};

export default CardHead;
