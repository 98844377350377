import theme from '../../theme/theme';

export const ccHeaderClasses = {
  appBar: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: theme.drawerWidth,
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default
  },
  title: {
    textTransform: 'uppercase'
  },
  toolbar: {
    justifyContent: 'space-between'
  }
};
