import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const GoalIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '18px';
  const height = props.height ?? '18px';
  const title = props.title ?? 'Create a Goal';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const viewBox = props?.viewBox ?? '0 0 18 18';

  let classList = 'at-icon-goal';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      width={width}
      height={height}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M8.25 1.5V3.0525C6.93008 3.22014 5.70329 3.82165 4.76247 4.76247C3.82165 5.70329 3.22014 6.93008 3.0525 8.25H1.5V9.75H3.0525C3.22014 11.0699 3.82165 12.2967 4.76247 13.2375C5.70329 14.1784 6.93008 14.7799 8.25 14.9475V16.5H9.75V14.9475C11.0699 14.7799 12.2967 14.1784 13.2375 13.2375C14.1784 12.2967 14.7799 11.0699 14.9475 9.75H16.5V8.25H14.9475C14.7799 6.93008 14.1784 5.70329 13.2375 4.76247C12.2967 3.82165 11.0699 3.22014 9.75 3.0525V1.5M8.25 4.56V6H9.75V4.5675C11.625 4.875 13.125 6.375 13.44 8.25H12V9.75H13.4325C13.125 11.625 11.625 13.125 9.75 13.44V12H8.25V13.4325C6.375 13.125 4.875 11.625 4.56 9.75H6V8.25H4.5675C4.875 6.375 6.375 4.875 8.25 4.56ZM9 8.25C8.80109 8.25 8.61032 8.32902 8.46967 8.46967C8.32902 8.61032 8.25 8.80109 8.25 9C8.25 9.19891 8.32902 9.38968 8.46967 9.53033C8.61032 9.67098 8.80109 9.75 9 9.75C9.19891 9.75 9.38968 9.67098 9.53033 9.53033C9.67098 9.38968 9.75 9.19891 9.75 9C9.75 8.80109 9.67098 8.61032 9.53033 8.46967C9.38968 8.32902 9.19891 8.25 9 8.25Z"
        fill={fillColor}
      />
    </SvgIconWrapper>
  );
};
