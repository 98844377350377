'use strict';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';

const getRequiredFields = alarm => {
    let requiredFields = [];

    alarm.editor.fields.forEach(field => {
        if (!isRestrictedFieldByItself(field.value)) {
            requiredFields.push(field.name);
        }
    });

    return requiredFields;
};

const isRestrictedFieldByItself = (field, alarmType) => {
    return (
        (field === 'IpAddress' ||
            field === 'User' ||
            field === 'Computer' ||
            field === 'LoginDomain' ||
            field === 'PrimaryDomain') &&
        alarmType !== 'Audit'
    );
};

const isUnderDefinedConditions = (conditions, alarmType) => {
    let underDefined = true;

    conditions.forEach(condition => {
        underDefined =
            underDefined &&
            isRestrictedFieldByItself(condition.field, alarmType);
    });

    return underDefined && alarmType !== 'Usb';
};

const createRequiredFieldsMessage = requiredFields => {
    let msg = 'Alarm should have at least one condition on';
    let lines = 1;
    if (requiredFields.length === 1) {
        msg = "'" + requiredFields[0] + "',";
    } else {
        requiredFields.forEach((field, key) => {
            msg +=
                (key === requiredFields.length - 1 ? ' or ' : ' ') +
                "'" +
                field +
                "',";
            if (msg.length > 80 * lines) {
                msg += '<br/>';
                lines++;
            }
        });
    }
    return msg + " so that it isn't triggered for every new entry.";
};

const _moduleTypes = {
    controls: 'Controls',
    groups: 'Groups',
    conditions: 'Conditions',
    actionSeparator: 'ActionSeparator',
    screenCaptures: 'ScreenCaptures',
    popupMessage: 'PopupMessage',
    emailNotification: 'EmailNotification',
    webhookNotification: 'WebhookNotification',
    terminate: 'Terminate',
    riskLevel: 'RiskLevel'
};

const verifyAlarmModule = (alarm, moduleType) => {
    if (!alarm) {
        return;
    }

    if (
        !moduleType ||
        !_.find(_moduleTypes, type => {
            return type === moduleType;
        })
    ) {
        console.error(
            'ActivTrak Error: Cannot verify alarm module due to module type missing or not defined.',
            moduleType
        );
        return;
    }

    let invalidReasons = [];

    switch (moduleType) {
        case _moduleTypes.controls:
            if (
                !alarm.editor.allowBlankName &&
                atHelperFunctions.isEmpty(alarm.name)
            ) {
                invalidReasons.push(
                    'A valid name for the alarm must be provided.'
                );
            }
            break;

        case _moduleTypes.groups:
            // No Validations
            break;

        case _moduleTypes.conditions:
            if (
                !(!alarm.conditions || alarm.conditions.length === 0) &&
                alarm.type !== 'Usb' &&
                isUnderDefinedConditions(alarm.conditions, alarm.type)
            ) {
                invalidReasons.push(
                    createRequiredFieldsMessage(getRequiredFields(alarm))
                );
            }

            if (alarm.type === 'SafeSearch') {
                if (alarm.editor.safeSearchConditions.length < 1) {
                    invalidReasons.push(
                        'There must be at least one Screenshot Flagging category configured in the conditions.'
                    );
                }
            }

            if (
                (!alarm.conditions || alarm.conditions.length === 0) &&
                alarm.type !== 'Usb'
            ) {
                invalidReasons.push(
                    'There must be at least one condition defined.'
                );
            }

            if (alarm.editor.isEditingCondition) {
                invalidReasons.push(
                    'You have unsaved changes in conditions. Save or cancel them before saving the alarm.'
                );
            }

            if (
                alarm.type === 'Usb' &&
                alarm.editor.usbAlarm &&
                !alarm.editor.usbAlarm.inserted &&
                !alarm.editor.usbAlarm.written
            ) {
                invalidReasons.push(
                    'Select the conditions that will trigger the alarm.'
                );
            }
            break;

        case _moduleTypes.actionSeparator:
            // No Validations
            break;

        case _moduleTypes.screenCaptures:
            if (
                alarm.screenshot &&
                alarm.screenshotmultiple &&
                alarm.screenshotsec < 10
            ) {
                invalidReasons.push(
                    'The time between multiple screenshots must be 10 seconds or more.'
                );
            }
            break;

        case _moduleTypes.popupMessage:
            if (alarm.popup && atHelperFunctions.isEmpty(alarm.popuptext)) {
                invalidReasons.push(
                    'A valid message text must be provided in order to use pop-up messages.'
                );
            }
            if (alarm.popup && isGroupAlarm(alarm)) {
                invalidReasons.push(
                    'Pop-up messages can only be used when "All Users and Computers"<br/>has been selected under the "Group Alarm" section.'
                );
            }
            break;

        case _moduleTypes.emailNotification:
            if (alarm.email && atHelperFunctions.isEmpty(alarm.emailto)) {
                invalidReasons.push(
                    'A valid email address must be provided in order to use email notifications.'
                );
            }
            if (alarm.email && atHelperFunctions.isEmpty(alarm.emailsubject)) {
                invalidReasons.push(
                    'A valid email subject must be provided in order to use email notifications.'
                );
            }
            if (alarm.email && atHelperFunctions.isEmpty(alarm.emailbody)) {
                invalidReasons.push(
                    'A valid email body must be provided in order to use email notifications.'
                );
            }
            break;

        case _moduleTypes.webhookNotification:
            if (
                alarm.webhook &&
                atHelperFunctions.isEmpty(alarm.webhookprovider)
            ) {
                invalidReasons.push(
                    'A destination must be selected in order to use external notifications.'
                );
            }
            if (alarm.webhook && atHelperFunctions.isEmpty(alarm.webhookurl)) {
                invalidReasons.push(
                    'A valid URL must be provided in order to use external notifications.'
                );
            }
            break;

        case _moduleTypes.terminate:
            if (alarm.terminate && isGroupAlarm(alarm)) {
                invalidReasons.push(
                    'Terminate client applications can only be used when "All Users and Computers"<br/>has been selected under the "Group Alarm" section.'
                );
            }
            break;

        case _moduleTypes.RiskLevel:
            // No Validations
            break;
    }

    return invalidReasons;
};

const verifyActiveActions = alarm => {
    if (!alarm) {
        return false;
    }

    // Add weightEnabled if missing
    if (alarm.weightEnabled === undefined || alarm.weightEnabled === null) {
        alarm.weightEnabled = !!alarm.weight;
    }

    // Skip action check for Activity type alarms
    if (alarm.type === 'Activity') {
        return true;
    }

    return Boolean(
        alarm.email ||
            alarm.popup ||
            alarm.weightEnabled ||
            alarm.video ||
            alarm.screenshot ||
            alarm.screenshot ||
            alarm.terminate ||
            alarm.webhook
    );
};

const verifyAlarm = alarm => {
    let invalidReasons = [];

    for (let moduleType in _moduleTypes) {
        let reasons = verifyAlarmModule(alarm, _moduleTypes[moduleType]);
        if (!atHelperFunctions.isEmpty(reasons)) {
            invalidReasons.push(reasons);
        }
    }

    if (!verifyActiveActions(alarm)) {
        invalidReasons.push('There must be at least one action selected.');
    }

    return invalidReasons;
};

const isGroupAlarm = alarm => {
    return !!(alarm.groups && alarm.groups[0] !== '-1');
};

export const alarmObjectVerifierFunctions = {
    moduleTypes: _moduleTypes,
    verifyAlarmModule: verifyAlarmModule,
    verifyActiveActions: verifyActiveActions,
    verifyAlarm: verifyAlarm,
    isGroupAlarm: isGroupAlarm
};
