import React, { useEffect } from 'react';
import { useVirtualOfficeVisibilityState } from '../../hooks';
import { noTeamsReportedToday } from '../../constants';
import { DashboardSubheader } from '../templates';
import { WidgetProps } from '../../models';
import authorizationService from '../../../common/helpers/authorization';

export default function VirtualOfficeVisibilitySubheader(props: WidgetProps) {
  const { refreshTimestamp } = props;

  const { virtualOfficeHours, isTileError, isLoading, init } =
    useVirtualOfficeVisibilityState();

  useEffect(() => {
    init();
  }, [init, refreshTimestamp]);

  const hasTeamPulseAccess =
    authorizationService.hasRouteByName('app.teampulse');

  return (
    <DashboardSubheader
      {...virtualOfficeHours}
      topLineSubheader="Virtual Office Visibility (Today)"
      hasTileError={isTileError}
      isLoading={isLoading}
      url={hasTeamPulseAccess ? 'app.teampulse' : ''}
      zeroDataMsg={noTeamsReportedToday}
      zeroDataLink="https://support.activtrak.com/hc/en-us/articles/18821721976475"
    />
  );
}
