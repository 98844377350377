import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const HRDataConnector = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 20 20';
  const iconHeight = height ?? '24px'; 
  const iconWidth = width ?? '24px'; 
  const title = props.title ?? 'HR Data Connector';
  const className = props.className;
  
  let classList = 'at-icon-hr-connector';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
      fillColor="none"
    >
      <path
        d="M18.5 11 17 12.5 11.5 7 13 5.5c.75-.75 3.5-2 5.5 0s.75 4.75 0 5.5Z"
        fill="#F67C43"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m21 3-2.5 2.5"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 13 7 11.5l5.5 5.5-1.5 1.5c-.75.75-3.5 2-5.5 0s-.75-4.75 0-5.5Z"
        fill="#47B1F7"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m11.5 16 2-2M3 21l2.5-2.5M8 12.5l2-2"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIconWrapper>
  );
};
