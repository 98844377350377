class ServiceSettings {
    constructor(obj) {
        this.pollingPaused = obj.pollingPaused;
        this.params = obj.params;
        this.url = obj.url;
        this.reportingUrl = obj.reportingUrl;
        this.websocketHttpsUrl = obj.websocketHttpsUrl;
        this.isWidget = obj.isWidget;
    }
}

export default ServiceSettings;
