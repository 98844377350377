import React from 'react';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ScreenshotsHeaderProps } from '../models/ScreenshotsTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const ScreenshotsLatestReportHeader = (
  props: ScreenshotsHeaderProps
) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { users } = reportFilters;

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: 0 }}>
          <AcademyButton />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
