import { Role, RoleAccessKeys } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';

export const appIntegrate: IRoute = {
  name: 'app.Integrate',
  stateDefinition: {
    url: '/integrations/integratev2',
    template: '<integrations></integrations>',
    data: { pageTitle: 'Integrate' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Integrations,
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportBasic, Role.SupportIntermediate]
  }
};

export default appIntegrate;
