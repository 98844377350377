import React, { useEffect } from 'react';
import { IDialogVerifyDeleteProps } from '../models/IDialogVerifyDeleteProps';
import { typeDisplayName } from '../utils/DetailEmailEmployeeIdUtils';

import { setLocalStorageItem } from '../../common/helpers';
import { localStorageItems } from '../../common/constants';
import { detailTypeEmail } from '../constants/DetailTypeNames';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const DialogVerifyDeleteEmailId: React.FC<IDialogVerifyDeleteProps> = (
  verifyDeleteProps
) => {
  const [neverShowAgain, setNeverShowAgain] = React.useState<boolean>(false);
  const { onDelete, closeDialog, dialogOpen, itemToDelete } = verifyDeleteProps;
  const confirmDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNeverShowAgain(e.target.checked);
    setLocalStorageItem(
      localStorageItems.deleteUserEmailModal,
      e.target.checked
    );
  };
  const [deleteTypeDisplayName, setDeleteTypeDisplayName] =
    React.useState<string>('');

  const deleteItem = async () => {
    onDelete(itemToDelete, neverShowAgain);
  };

  useEffect(() => {
    setDeleteTypeDisplayName(typeDisplayName(detailTypeEmail));
  }, [itemToDelete]);

  return (
    <Dialog
      maxWidth="lg"
      open={dialogOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        closeDialog();
      }}
    >
      <DialogTitle>Delete {deleteTypeDisplayName}?</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: '20px' }}>
          Are you sure you want to delete the {deleteTypeDisplayName}:{' '}
          {itemToDelete?.value}? Deleting will disassociate it from this user.
        </Typography>
        <label htmlFor="never-show-delete-email">
          <Checkbox
            id="never-show-delete-email"
            checked={neverShowAgain}
            onChange={confirmDelete}
            sx={{ border: '1px' }}
          />
          Never show this message again.
        </label>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" autoFocus onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={deleteItem} variant="contained" color="primary">
          Delete {deleteTypeDisplayName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
