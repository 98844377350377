'use strict';

angular.module('utils', ['config']);

angular.module('utils').service('url', [
    '$window',
    '$location',
    function($window, $location) {
        var service = this;
        // TODO: Experimental: Url Parsing...
        this.Query = function(query) {
            query = query.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
            var expr = '[\\?&]' + query + '=([^&#]*)';
            var regex = new RegExp(expr);
            var results = regex.exec($window.location.href);
            if (results !== null) {
                return results[1];
            } else {
                return false;
            }
        };

        this.GetUtm = function() {
            // Experimental: Passing url without pre-parsing...
            if ($window.location.search.indexOf('utm') > 0) {
                return removeEmail($window.location.search.slice(1));
            }
            return '';
        };

        // Implement this: remove email from string...
        function removeEmail(query) {
            return query;
        }

        this.GetPageWithUtm = function() {
            // var utm = service.GetUtm();
            // return $location.url() + '?' + utm;
            // TODO: Remove email from url if present
            return removeEmail($window.location.href);
        };

        this.GetLocationWithUtm = function() {
            var utm = service.GetUtm();
            return $location.url() + '?' + utm;
        };

        this.getDomain = function(url) {
            var domain;
            //find & remove protocol (http, ftp, etc.) and get hostname

            if (url.indexOf('://') > -1) {
                domain = url.split('/')[2];
            } else {
                domain = url.split('/')[0];
            }

            //find & remove port number
            domain = domain.split(':')[0];
            //find & remove "?"
            domain = domain.split('?')[0];

            return domain;
        };
    }
]);
