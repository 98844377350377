'use strict';

let _localStorageService;

// Creates an object that stores all need settings for each column
// Ignores all other settings since they are not used
function createSaveObject(gridOptions) {
    // Return if there is nothing to process
    if (!gridOptions || !gridOptions.columns) {
        return;
    }

    let saveObject = [];
    let i = 0;

    // Add to save object for each column
    gridOptions.columns.forEach(function (column) {
        // Field name is used as key
        let saveField = {
            field: column.field,
            hidden: !!column.hidden,
            width: column.width,
            columnOrder: i++
        };
        saveObject.push(saveField);
    });

    return saveObject;
}

// Create column display order if new columns are added to the default columns.
// Saved column display order takes precedence over default columns.
function updateDefaultColumns(defaultColumns, savedColumns) {
    // Return default columns if there is nothing to compare
    if (!defaultColumns || !savedColumns) {
        return defaultColumns;
    }

    let index = 0;
    defaultColumns.forEach(function (defaultColumn, columnIndex) {
        // Look for a saved column with the same field name as the default column
        let savedColumn = _.find(savedColumns, function (c) {
            return c.field === defaultColumn.field;
        });

        // If a saved column is found, set overrides
        if (savedColumn) {
            // Override visibility
            defaultColumn.hidden =
                typeof savedColumn.hidden === 'undefined' ? defaultColumn.hidden : savedColumn.hidden;

            // Override column width
            defaultColumn.width = (!defaultColumn.ignoreSavedWidth && savedColumn.width) || defaultColumn.width;

            // Override column display order
            defaultColumn.columnOrder = savedColumn.columnOrder >= 0 ? savedColumn.columnOrder : columnIndex;
        } else {
            // Column does not exist in the saved columns,
            // Inserted into object at it's default index.
            // 0.1 is added to ensure no duplicate index is used.
            defaultColumn.columnOrder = index + 0.1;
        }

        // Increase local index
        index = defaultColumn.columnOrder;
    });

    // Return sorted columns by set column order
    return _.sortBy(defaultColumns, [(c) => c.columnOrder]);
}

function createSaveOptions(gridName) {
    return function (e) {
        setTimeout(() => {
            // Find grid
            let grid = e.sender;

            // Load grid options and create save object
            let saveObject = createSaveObject(grid.getOptions());

            // Save object to local storage if it exists
            if (saveObject && _localStorageService) {
                _localStorageService.set(gridName, JSON.stringify(saveObject));
            }
        }, 1);
    };
}

// Setup external dependencies
const initialize = (localStorageService) => {
    _localStorageService = localStorageService;
};

// Override default columns with saved values
const loadSavedGridOptions = (defaultColumns, gridName) => {
    // Load saved columns from local storage
    let savedColumns = _localStorageService && JSON.parse(_localStorageService.get(gridName));

    // Convert old save format to new save format
    // Older versions saved the full kendo grid object
    if (savedColumns && savedColumns.columns) {
        savedColumns = createSaveObject(savedColumns);
    }

    // Return modified columns
    return updateDefaultColumns(defaultColumns, savedColumns);
};

// Reset grid to default columns
const resetSavedGridOptions = (grid, gridName, getGridOptions) => {
    // Remove saved columns from local storage
    if (_localStorageService) {
        _localStorageService.remove(gridName);
    }

    // Update grid with default options
    grid.setOptions(getGridOptions());

    // Bind grid save events
    bindGridSave(grid, gridName);
};

// Bind change events to local storage save object
const bindGridSave = (grid, gridName) => {
    let saveOptions = createSaveOptions(gridName);
    grid.bind('columnReorder', saveOptions);
    grid.bind('columnResize', saveOptions);
    grid.bind('columnHide', saveOptions);
    grid.bind('columnShow', saveOptions);
};

const checkLocal = () => {
    return {
        localStorageService: _localStorageService
    };
};

export const gridServiceFunctions = {
    initialize: initialize,
    loadSavedGridOptions: loadSavedGridOptions,
    resetSavedGridOptions: resetSavedGridOptions,
    bindGridSave: bindGridSave,
    checkLocal: checkLocal
};
