import React, { useState, useRef } from 'react';
import {
  ClickAwayListener,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  Box
} from '@mui/material';

import { ButtonSC, PopperSC } from './DropdownOptionsButton.styles';

type ComponentProps = {
  id: string;
  children?: React.ReactNode;
  className?: any;
  icon: any;
  dropDownOptions: any[];
  onChangeOption?: (
    event: React.MouseEvent<HTMLLIElement>,
    option?: any,
    index?: number
  ) => void;
  rightIconPosition?: boolean;
  hideSelectedOption?: boolean;
  disableSelectedItem?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  indexOption?: number;
  setIndexOption?: (index: number) => void;
  sx?: object;
};

export const DropdownOptionsButton = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: ComponentProps, ref) => {
    const {
      id,
      children,
      className,
      icon,
      dropDownOptions,
      onChangeOption,
      rightIconPosition,
      hideSelectedOption,
      disableSelectedItem,
      disablePortal,
      indexOption = 0,
      sx = {},
      setIndexOption = () => {},
      ...remainingProps
    } = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number
    ) => {
      event.stopPropagation();
      setIndexOption(index);
      onChangeOption(event, dropDownOptions[index], index);
      setOpen(false);
    };

    const getMenuLabel = (option) => {
      const { label, template } = option;
      return template ? template(label) : label;
    };

    const getPrimaryButtonLabel = () => {
      if (children) {
        return children;
      } else {
        return hideSelectedOption
          ? null
          : getMenuLabel(dropDownOptions[indexOption]);
      }
    };

    const handleToggle = (e) => {
      e.stopPropagation();
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };

    return (
      dropDownOptions && (
        <Box className={className}>
          <div ref={anchorRef}>
            <ButtonSC
              id={id}
              onClick={handleToggle}
              startIcon={rightIconPosition ? null : icon}
              endIcon={rightIconPosition ? icon : null}
              hideselectedoption={hideSelectedOption ? 1 : 0}
              color="secondary"
              sx={sx}
              {...remainingProps}
            >
              {getPrimaryButtonLabel()}
            </ButtonSC>
          </div>
          <PopperSC
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal={disablePortal}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {dropDownOptions.map((option, index) => {
                        return (
                          <MenuItem
                            key={index}
                            disabled={option.disabled}
                            selected={
                              disableSelectedItem
                                ? false
                                : index === indexOption
                            }
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {getMenuLabel(option)}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </PopperSC>
        </Box>
      )
    );
  }
);

DropdownOptionsButton.defaultProps = {
  dropDownOptions: [],
  onChangeOption: () => {},
  rightIconPosition: false,
  hideSelectedOption: false,
  disableSelectedItem: false
};
