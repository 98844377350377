'use strict';

angular.module('app')
    .directive('alarmDetailsComponentSelector', AlarmDetailsComponentSelector);

    AlarmDetailsComponentSelector.$inject = ['$compile', '$parse'];

function AlarmDetailsComponentSelector($compile, $parse) {

    return {
        priority: 999,
        terminal: true,
        restrict: 'E',
        compile: function attributesCompile() {
            return function attributesLink($scope, element, attributes) {
                function parseAttr(key, value) {
                    function convertToDashes(match) {
                        return match[0] + '-' + match[1].toLowerCase();
                    }

                    attributes.$set(key.replace(/([a-z][A-Z])/g, convertToDashes), value !== undefined && value !== null ? value : '');
                }

                var passedAttributes = $parse(attributes.attributes)($scope);

                if (passedAttributes !== null && passedAttributes !== undefined) {
                    if (typeof passedAttributes === 'object') {
                        for (var subkey in passedAttributes) {
                            parseAttr(subkey, passedAttributes[subkey]);
                        }
                    } else if (typeof passedAttributes === 'string') {
                        parseAttr(passedAttributes, null);
                    }
                }

                $compile(element, null, 999)($scope);
            };
        }
    };
}
