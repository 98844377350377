import {
  deleteAdminData,
  fetchAdminData,
  postAdminData,
  putAdminData
} from '../../common/helpers';
import { IntegrationTypeCodes } from '../constants/IntegrationCodes';
import { IntegrationRoutes } from '../constants/routesApi';
import {
  IIntegrationConfigurationDto,
  IIntegrationDetailDto,
  IIntegrationPlanDto,
  IIntegrationStateDto,
  ISolutionDto,
  ITrayUserDto
} from '../models/IIntegrationTypes';

export const createIntegrationInstance = async (
  integrationCode: string
): Promise<string> => {
  const response = await postAdminData<string>({
    path: IntegrationRoutes.createInstance.replace(
      ':integrationCode',
      integrationCode
    )
  });
  return Promise.resolve(response);
};

export const enableIntegrationInstance = async (
  integrationCode: string,
  instanceId: string
): Promise<string> => {
  const response = await updateIntegrationConfiguration({
    integrationcode: integrationCode,
    instanceid: instanceId,
    name: 'enabled',
    value: 'true',
    typecode: IntegrationTypeCodes.Boolean
  });
  return Promise.resolve(response);
};

export const disableIntegrationInstance = async (
  integrationCode: string,
  instanceId: string
): Promise<string> => {
  const response = await updateIntegrationConfiguration({
    integrationcode: integrationCode,
    instanceid: instanceId,
    name: 'enabled',
    value: 'false',
    typecode: IntegrationTypeCodes.Boolean
  });
  return Promise.resolve(response);
};

export const syncIntegrationInstance = async (
  instanceId: string
): Promise<string> => {
  const response = await postAdminData<null>({
    path: IntegrationRoutes.syncInstance.replace(':instanceId', instanceId)
  });
  return Promise.resolve(response);
};

export const deleteIntegrationInstance = async (
  instanceId: string
): Promise<string> => {
  const response = await deleteAdminData<null>({
    path: IntegrationRoutes.deleteInstance.replace(':instanceId', instanceId)
  });
  return Promise.resolve(response);
};

export const refreshTrayIntegrationInstance = async (
  instanceId: string,
  integrationCode: string
): Promise<string> => {
  const response = await putAdminData<null>({
    path: IntegrationRoutes.refreshTrayInstance
      .replace(':instanceId', instanceId)
      .replace(':integrationCode', integrationCode)
  });
  return Promise.resolve(response);
};

export const resumeTrayIntegrationInstance = async (
  instanceId: string,
  integrationCode: string
): Promise<string> => {
  const response = await putAdminData<null>({
    path: IntegrationRoutes.resumeTrayInstance
      .replace(':instanceId', instanceId)
      .replace(':integrationCode', integrationCode)
  });
  return Promise.resolve(response);
};

export const pauseTrayIntegrationInstance = async (
  instanceId: string,
  integrationCode: string
): Promise<string> => {
  const response = await putAdminData<null>({
    path: IntegrationRoutes.pauseTrayInstance
      .replace(':instanceId', instanceId)
      .replace(':integrationCode', integrationCode)
  });
  return Promise.resolve(response);
};

export const deleteTrayIntegrationInstance = async (
  instanceId: string,
  integrationCode: string
): Promise<string> => {
  const response = await deleteAdminData<null>({
    path: IntegrationRoutes.deleteTrayInstance
      .replace(':instanceId', instanceId)
      .replace(':integrationCode', integrationCode)
  });
  return Promise.resolve(response);
};

export const addIntegrationConfiguration = async (
  payload: IIntegrationConfigurationDto
): Promise<string> => {
  try {
    if (payload !== undefined) {
      const response = await postAdminData<null>({
        path: IntegrationRoutes.configurations,
        args: payload
      });

      return Promise.resolve(response);
    }
  } catch (error) {
    console.error(
      'ActivTrak Error: Unable to add Integration Configuration',
      error
    );
  }
};

export const updateIntegrationConfiguration = async (
  payload: IIntegrationConfigurationDto
): Promise<string> => {
  const response = await putAdminData<null>({
    path: IntegrationRoutes.configurations,
    args: payload
  });

  return Promise.resolve(response);
};

export const fetchTrayUser = async (): Promise<ITrayUserDto> => {
  try {
    const response = await fetchAdminData<ITrayUserDto>({
      path: IntegrationRoutes.trayuser
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error('ActivTrak Error: Unable to retrieve Tray User', error);
  }
};

export const fetchDetail = async (): Promise<Array<IIntegrationDetailDto>> => {
  try {
    const response = await fetchAdminData<Array<IIntegrationDetailDto>>({
      path: IntegrationRoutes.details
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Unable to retrieve Integration Details',
      error
    );
  }
};

export const fetchPlan = async (): Promise<Array<IIntegrationPlanDto>> => {
  try {
    const response = await fetchAdminData<Array<IIntegrationPlanDto>>({
      path: IntegrationRoutes.plans
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Unable to retrieve Integration Plans',
      error
    );
  }
};

export const fetchState = async (): Promise<Array<IIntegrationStateDto>> => {
  try {
    const response = await fetchAdminData<Array<IIntegrationStateDto>>({
      path: IntegrationRoutes.states
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Unable to retrieve Integration States',
      error
    );
  }
};

export const fetchConfiguration = async (): Promise<
  Array<IIntegrationConfigurationDto>
> => {
  try {
    const response = await fetchAdminData<Array<IIntegrationConfigurationDto>>({
      path: IntegrationRoutes.configurations
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Unable to retrieve Integration Configurations',
      error
    );
  }
};

export const fetchTraySolution = async (
  userId: string
): Promise<Array<ISolutionDto>> => {
  try {
    const response = await fetchAdminData<Array<ISolutionDto>>({
      path: IntegrationRoutes.solutions.replace(':userId', userId)
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error('ActivTrak Error: Unable to retrieve Tray Solution', error);
  }
};
