import { apiService } from '../../../common/helpers';
// Implementing envService since using default config imports connects to localhost, which does not generate the correct link - might need to refactor later
import envService from '../../../../../_app/environmentService/environmentService';
import {
  HttpResponse,
  IAgentDownloadService,
  IAgentDownloadSettingsPayload,
  IAgentLink
} from '../../models';

export const AgentDownloadService = (): IAgentDownloadService => {
  return {
    // setting default value of minutes to 0 if no value initially passed
    fetchAgentLink: async (
      os,
      minutes = 0
    ): Promise<HttpResponse<IAgentLink>> => {
      return await apiService.get<IAgentLink>(
        `/api/download/${os}agent?expireInMinutes=${minutes}`,
        {
          authorizationRequired: true,
          baseURL: envService.getApiUrl()
        }
      );
    },
    updateNonAdminDirectDownload: async (
      payload
    ): Promise<HttpResponse<IAgentDownloadSettingsPayload>> => {
      return await apiService.put('/api/settings/agent/download', {
        data: payload,
        authorizationRequired: true,
        baseURL: envService.getApiUrl()
      });
    }
  };
};
