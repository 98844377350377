import React, { createContext, useContext } from 'react';
import { IUsersService } from '../models';
import UsersService from './UsersService';
import { useAuthorization } from "../../common/services/Authorization";
import { BundleFlag } from "../../common/enums/BundleFlag";
const UsersContext = createContext<IUsersProvider>(null);

interface IUsersProvider {
  usersService: IUsersService;
}

type Props = {
  children: React.ReactNode;
  usersService?: IUsersService;
};

export const UsersProvider = (props: Props) => {
  const { children, usersService } = props;
  const authorization = useAuthorization();
  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  const value = {
    usersService: usersService || new UsersService(softDeletesEnabled)
  };

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export const UseUsersContext = (): IUsersProvider => {
  return useContext(UsersContext);
};
