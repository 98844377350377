import { apiService as apiServiceDefault } from '../helpers/apis';
import { IApiService } from '../models';

class BaseClient {
  protected apiService: IApiService;

  constructor(apiService?: IApiService) {
    this.apiService = apiService ?? apiServiceDefault;
  }
}

export default BaseClient;
