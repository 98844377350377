import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsRiskLevel: IRoute = {
  name: 'app.reports.riskLevel',
  stateDefinition: {
    url: '/risklevel',
    params: {
      user: null,
      computer: null,
      alarm: null,
      filter: null,
      secondaryFilter: null
    },
    template: '<risk-level></risk-level>',
    data: { pageTitle: 'Risk Level Report', filter: {} }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsRiskLevel,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User],
    bundleFlags: [BundleFlag.RiskLevelReport]
  }
};

export default appReportsRiskLevel;
