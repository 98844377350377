import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsTopcategories: IRoute = {
  name: 'app.reports.topcategories',
  stateDefinition: {
    url: '/topcategories?category&tab',
    params: { category: null, tab: null },
    template: '<top-categories-report></top-categories-report>',
    data: { pageTitle: 'Top Categories', filter: {} }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TopCategories,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.TopCategoriesReport]
  }
};

export default appReportsTopcategories;
