import React from 'react';
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Box,
  Typography
} from '@mui/material';
import { listViewClasses } from './ListView.styles.js';
import ArrowIcon from '../../common/components/ArrowIcon/ArrowIcon.jsx';
import Notification from '../Notification/Notification.jsx';
import Numeral from 'numeral';
import { impersonate } from '../../../../_reactivtrak/src/common/hooks/useImpersonate';

const ListView = ({ customers = [], activeUsers, onError }) => {
  const renderTableHead = () => {
    return (
      <TableRow>
        <TableCell sx={listViewClasses.notificationCell} />
        <TableCell
          sx={{ ...listViewClasses.tableCell, ...listViewClasses.columnLarge }}
        >
          <b>NAME</b>
        </TableCell>
        <TableCell
          sx={{ ...listViewClasses.tableCell, ...listViewClasses.columnSmall }}
        >
          <b>ACCT #</b>
        </TableCell>
        <TableCell
          sx={{ ...listViewClasses.tableCell, ...listViewClasses.columnMedium }}
        >
          <b>ACTIVE USERS</b> (
          <Typography component="span" sx={listViewClasses.tableCellCounter}>
            {Numeral(activeUsers).format('0,0', Math.floor)}
          </Typography>
          )
        </TableCell>
        <TableCell
          sx={{ ...listViewClasses.tableCell, ...listViewClasses.columnMedium }}
        >
          <b>ALARMS</b> (Last 7 days)
        </TableCell>
        <TableCell
          sx={{ ...listViewClasses.tableCell, ...listViewClasses.columnLarge }}
        >
          <b>EMAIL</b>
        </TableCell>
        <TableCell sx={listViewClasses.linkCell} />
      </TableRow>
    );
  };

  const renderTableContent = () => {
    return customers.map((customer) => {
      return (
        <TableRow key={customer.accountId} sx={listViewClasses.tableRow}>
          <TableCell sx={listViewClasses.notificationCell}>
            {customer.agentNotReportedCount > 0 && (
              <Notification
                notReported={customer.agentNotReportedCount}
                accountId={customer.accountId}
                ownerEmail={customer.ownerEmail}
                onError={onError}
                isExpired={customer.isExpired}
              />
            )}
          </TableCell>
          <TableCell sx={listViewClasses.tableCell}>
            <Box
              sx={listViewClasses.companyName}
              className={customer.companyName ? '' : 'empty'}
            >
              {customer.companyName || 'Name Unavailable'}
            </Box>
          </TableCell>
          <TableCell sx={listViewClasses.tableCell}>
            {customer.accountId}
          </TableCell>
          <TableCell sx={listViewClasses.tableCell}>
            <Typography component="span" sx={listViewClasses.tableCellCounter}>
              {Numeral(customer.userCount).format('0,0', Math.floor)}
            </Typography>
          </TableCell>
          <TableCell sx={listViewClasses.tableCell}>
            <Typography component="span" sx={listViewClasses.tableCellCounter}>
              {Numeral(customer.alarmCount).format('0[.]0a', Math.floor)}
            </Typography>
          </TableCell>
          <TableCell sx={listViewClasses.tableCell}>
            {customer.ownerEmail}
          </TableCell>
          <TableCell size="small" padding="none" sx={listViewClasses.linkCell}>
            <Link
              href={'#'}
              onClick={(e) =>{
                e.preventDefault();

                setTimeout(async () => {
                  try {
                    await impersonate(customer.accountId)();
                    window.location.href = '/#/app/home';
                  } catch (ex) {
                    console.error(ex);
                    onError(ex);
                  }
                });
                
              }}
              target="_blank"
            >
              <Icon size="small" color="primary">
                <ArrowIcon />
              </Icon>
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Box className="list-view">
      <TableContainer>
        <Table sx={listViewClasses.table} size="small">
          <TableHead>{renderTableHead()}</TableHead>
          <TableBody sx={listViewClasses.tableBody}>
            {renderTableContent()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListView;
