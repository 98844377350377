'use strict';

angular.module('app')
    .directive('atHighlightOnChange', ['$timeout', function ($timeout) {
        return {
        restrict: 'A',
        scope: {
            model: '=atHighlightOnChange',
            delay: '=atDelay'
        },
        link: function(scope, element, attrs) {
            scope.$watch('model', function (nv, ov) {
                function setTimeout() {
                    attrs.timeout = $timeout(function () {
                        element.removeClass('at-highlight');
                        element.removeClass('at-highlight-up');
                        element.removeClass('at-highlight-down');
                        attrs.timeout = null;
                    }, 500);
                }
                if (nv !== ov) {
                    if (attrs.timeout) {
                        //highlight already set.. reset timeout
                        $timeout.cancel(attrs.timeout);
                        setTimeout();
                    } else {
                        element.addClass('at-highlight');
                        if (attrs['at-compare-values']) {
                            if (nv > ov) {
                                element.addClass('at-highlight-up');
                            } else {
                                element.addClass('at-highlight-down');
                            }
                        }
                        setTimeout();
                    }
                }
            });
        }
    };
}]);
