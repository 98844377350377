import React, { useEffect, useState } from 'react';
import { useLastUpdate, useTodaysProductivityState } from '../../hooks';
import {
  TemplateShadow,
  BaseLoadingView,
  ErrorMsgDisplay,
  WidgetModal,
  WidgetModalList,
  OverallStatus,
  DashboardActivityDiff
} from '../templates';
import { TodaysProductivityChart } from './TodaysProductivityChart';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { checkAllValuesAreZero } from '../../utils';
import { WidgetProps } from '../../models';
import {
  errorMsg,
  lastUpdateText,
  modalButtonLabel,
  modalTitle,
  noUsersMeetingCriteria,
  orgWidgetSubheader,
  widgetTitle
} from '../../constants';
import { LastUpdatedSC, ModalHeader } from '../../styles/templates.styles';

export default function TodaysProductivity(props: Readonly<WidgetProps>) {
  const { refreshTimestamp } = props;

  const [noResults, setNoResults] = useState<boolean>(true);

  const { isLoading, isTileError, todaysProductivity, init } =
    useTodaysProductivityState();

  useEffect(() => {
    init();
  }, [init, refreshTimestamp]);

  useEffect(() => {
    if (todaysProductivity?.days) {
      const dayGoals = todaysProductivity.days.map((item) => item.goal);
      const hasNoResults = checkAllValuesAreZero(dayGoals);
      setNoResults(hasNoResults);
    }
  }, [todaysProductivity?.days]);

  const lastUpdate = useLastUpdate(todaysProductivity?.date);

  return (
    <TemplateShadow
      sx={{
        height: { xs: '470px', md: '450px' }
      }}
    >
      <WidgetLinkWrapper>
        <WidgetLinkTypography variant="h4">
          {widgetTitle.productivity}
        </WidgetLinkTypography>
      </WidgetLinkWrapper>
      {!isLoading && !isTileError && (
        <>
          <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <Box
              sx={{
                marginBottom: { xs: '20px' }
              }}
            >
              <OverallStatus
                header={`${todaysProductivity?.goal}%`}
                subheader={orgWidgetSubheader.todaysProductivity}
                responsive
                noResults={noResults}
              />
              {!noResults && (
                <DashboardActivityDiff
                  diff={todaysProductivity?.previous}
                  title={`vs average ${todaysProductivity?.todaysLabel} at this time`}
                />
              )}
              <Box marginTop={1}>
                <WidgetModal
                  buttonLabel={modalButtonLabel.todaysProductivity}
                  id="a6bb178-11e4-11ee-be56-0242ac120002"
                  isDisabled={noResults}
                >
                  <ModalHeader variant="h6">
                    {modalTitle.todaysProductivity}
                  </ModalHeader>
                  {!todaysProductivity ||
                  todaysProductivity?.teams?.length === 0 ? (
                    <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
                  ) : (
                    <WidgetModalList data={todaysProductivity?.teams} />
                  )}
                </WidgetModal>
              </Box>
            </Box>
            <Box
              sx={{
                paddingBottom: '10px',
                minHeight: { xs: '285px', md: '220px' },
                maxHeight: { xs: '285px', md: '220px' }
              }}
            >
              <TodaysProductivityChart
                data={todaysProductivity}
                noResults={noResults}
              />
            </Box>
          </Box>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
