import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsTopapplications: IRoute = {
  name: 'app.reports.topapplications',
  stateDefinition: {
    url: '/topapplications?app&tab',
    params: { app: null, tab: null },
    template: '<top-applications-report></top-applications-report>',
    data: {
      pageTitle: 'Top Applications',
      filter: { productivity: true, export: true },
      exportText: 'export',
      reportName: 'topApplicationsReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TopApplications,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.TopApplicationsReport]
  }
};

export default appReportsTopapplications;
