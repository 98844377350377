import React from 'react';
import {
  SkeletonLoaderRowsContainer,
  SkeletonLoaderCheckboxContainer,
  SkeletonLoaderContainer,
  SkeletonSC
} from './CustomGrid.styles';

export const getCellContent = (rowItem, columnRowItem) => {
  const { field, template } = columnRowItem;
  if (template) {
    return template(rowItem);
  } else {
    return rowItem[field];
  }
};

export const useDefaultSkeletonLoader = (
  columnsNumber: number,
  hasBulkSelection: boolean
) => {
  if (columnsNumber) {
    const rowsNumber = Math.round(window.innerHeight / 54);
    const rows = [];

    const skeletonRowGridLoader = (rowIndex) => {
      const columnElements = [];
      for (let i = 1; i <= columnsNumber; i++) {
        columnElements.push(
          <SkeletonLoaderContainer key={`skeleton-column-${i}`}>
            <SkeletonSC />
          </SkeletonLoaderContainer>
        );
      }

      return (
        <SkeletonLoaderRowsContainer
          key={`skeleton-row-${rowIndex}`}
          data-testid="grid-skeleton-loader"
        >
          {hasBulkSelection && (
            <SkeletonLoaderCheckboxContainer>
              <SkeletonSC />
            </SkeletonLoaderCheckboxContainer>
          )}
          {columnElements}
        </SkeletonLoaderRowsContainer>
      );
    };

    for (let i = 1; i <= rowsNumber; i++) {
      rows.push(skeletonRowGridLoader(i));
    }

    return rows;
  }

  return false;
};

export const useCustomRowsSkeletonLoader = (
  rowsNumber: number,
  columnsNumber: number,
  hasBulkSelection: boolean
) => {
  if (columnsNumber) {
    const rows = [];

    const skeletonRowGridLoader = (rowIndex) => {
      const columnElements = [];
      for (let i = 1; i <= columnsNumber; i++) {
        columnElements.push(
          <SkeletonLoaderContainer key={`skeleton-column-${i}`}>
            <SkeletonSC />
          </SkeletonLoaderContainer>
        );
      }

      return (
        <SkeletonLoaderRowsContainer key={`skeleton-row-${rowIndex}`}>
          {hasBulkSelection && (
            <SkeletonLoaderCheckboxContainer>
              <SkeletonSC />
            </SkeletonLoaderCheckboxContainer>
          )}
          {columnElements}
        </SkeletonLoaderRowsContainer>
      );
    };

    for (let i = 1; i <= rowsNumber; i++) {
      rows.push(skeletonRowGridLoader(i));
    }

    return rows;
  }

  return false;
};
