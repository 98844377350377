'use strict';

angular.module('app').service('categoriesService', CategoriesService);

CategoriesService.$inject = ['$http', '$q', 'envConfig'];

function CategoriesService($http, $q, envConfig) {
    var categoryServiceFunctions = require('_app/classification/categoryServiceFunctions')
        .categoryServiceFunctions;
    this.getCategories = function () {
        var deferred = $q.defer();
        $http
            .get(envConfig.apiUrl() + '/api/settings/categories', {
                ignoreLoadingBar: true
            })
            .success(function (result) {
                deferred.resolve(
                    categoryServiceFunctions.sortCategories(result)
                );
            });
        deferred.promise.success = deferred.promise.then;
        return deferred.promise;
    };

    this.getWebsites = function (selectedFilterOptionId) {
        return $http.get(
            envConfig.apiUrl() +
                '/api/settings/categories/' +
                selectedFilterOptionId +
                '/websites'
        );
    };

    this.getApplications = function (selectedFilterOptionId) {
        return $http.get(
            envConfig.apiUrl() +
                '/api/settings/categories/' +
                selectedFilterOptionId +
                '/applications'
        );
    };

    this.updateWebsiteOrApplication = function (isApp, id, payload) {
        if (isApp) {
            return $http.put(
                envConfig.apiUrl() +
                    '/api/settings/categories/' +
                    id +
                    '/applications',
                payload
            );
        } else {
            return $http.put(
                envConfig.apiUrl() +
                    '/api/settings/categories/' +
                    id +
                    '/websites',
                payload
            );
        }
    };
}
