import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const UserIndividualIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const { height, width, viewBox } = props;

  const title = props.title ?? 'Individual User';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const style = props.style ?? {};

  let classList = 'at-icon-individual-user';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      fillColor={fillColor}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M12 12a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5ZM12 12.938c-2.503 0-7.5 1.256-7.5 3.75v1.875a.94.94 0 0 0 .938.937h13.125a.94.94 0 0 0 .937-.938v-1.875c0-2.493-4.997-3.75-7.5-3.75Z"
        style={style}
      />
    </SvgIconWrapper>
  );
};
