import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { gray7, secondaryFontSize } from '../../common/constants';

export default function ChartsJsPieChartDataLines() {
  const data = {
    labels: ['Optimal', 'High', 'Low', 'Varied'],
    datasets: [
      {
        data: [190, 25, 60, 35],
        backgroundColor: ['#893ff2', '#41caa8', '#f8a601', '#70D6FF'],
        hoverBackgroundColor: ['#893ff2', '#41caa8', '#f8a601', '#70D6FF'],
        borderWidth: 0 // no space between pie slices
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 32
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 30,
          font: {
            size: secondaryFontSize
          },
          padding: 40
        },
        onClick: () => ''
      },
      datalabels: {
        display: true,
        color: gray7,
        align: 'end',
        anchor: 'end',
        offset: 6,
        borderWidth: 1,
        // OPTION 2 labels with border (uncomment):
        borderColor: gray7,
        borderRadius: 25,
        font: {
          weight: 'normal',
          size: '12'
        },
        formatter: (value) => {
          const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed() + '%';
          return percentage;
        }
      },
      tooltip: {
        enabled: false
      }
    }
  };
  //   In plugins array can change to ChartDataLabels for using datalabels showing only labels (no lines)
  return (
    <>
      {!data && <div>Loading Chart</div>}
      {data && !Object.values(data).length && <div>No data available</div>}
      {Object.values(data).length && (
        <Pie
          data={data}
          options={options as any}
          plugins={[ChartDataLabels as any]}
        />
      )}
    </>
  );
}
