import React, { useEffect, useState } from 'react';
import { ICategory } from '../../common/models';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { useClassification } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { classificationClasses } from '../styles/Classifications.styles';

type DeleteCategoriesModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  selectedCategories: ICategory[];
};

export default function DeleteCategoryDialog(props: DeleteCategoriesModalProps) {
  const { open, onClose, selectedCategories, onDelete = () => {} } = props;

  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [listedCategories, setListedCategories] = useState<string>('');
  const [activitiesCount, setActivitiesCount] = useState<number>(0);

  const { categoryService } = useClassification();
  const notificationService = useNotifications();

  useEffect(() => {
    if (selectedCategories?.length) {
      //make sure these are in alphabetical order before pulling the first 5 for display
      const sortedSelected = selectedCategories.sort();
      const firstFive = sortedSelected.slice(0, 5).map((item) => item.name);
      const selectedLength: number = sortedSelected?.length || 0;
      const andMore =
        selectedLength > 5 ? ` and ${selectedLength - 5} more ` : '';
      const list = firstFive.join(', ') + andMore;
      setListedCategories(list);

      let activitiesTotal = 0;
      const catIds: string[] = [];
      sortedSelected.forEach((cat) => {
        activitiesTotal += cat.websiteCount;
        activitiesTotal += cat.appCount;

        catIds.push(cat.id);
      });
      setActivitiesCount(activitiesTotal);

      setSelectedIds(catIds);
    }
  }, [selectedCategories]);

  const handleDelete = async (): Promise<void> => {
    try {
      setDeleting(true);
      //do fire-n-forget until delete process is faster
      categoryService.delete(selectedIds);
      // NOTE: This will fire off a success message regardless of result. If it throws an error later, that will appear as well.
      notificationService.success(`Success! Categories deleted.`);
      onDelete();
    } catch (response) {
      //error is handled thru global notification
    } finally {
      close();
      setDeleting(false);
    }
  };

  const close = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        close();
      }}
    >
      <DialogContent sx={classificationClasses.deleteCategoryCopy}>
        <div>Delete Categories</div>
        <p>Are you sure you want to delete these categories?</p>
        <p>
          Deleting <strong>{listedCategories}</strong> will remove them from{' '}
          <strong>{activitiesCount} activities</strong> and prevent any
          activities from being auto-classified with the selected categories
          going forward.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus disabled={deleting}>
          Cancel
        </Button>
        <Button onClick={handleDelete} disabled={deleting} variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
