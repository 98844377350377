import { useCallback, useEffect, useState } from 'react';
import { SortDirection } from '../../common/enums';
import { PageDirection } from '../models/PageDirection';
import { IIdentityResults, IUserIdentity } from '../models/IUserIdentity';
import { mapToIdentityResults } from '../utils/IdentityMappers';
import { localStorageItems } from '../../common/constants';
import { UserIdentityGetProps } from '../models/UserIdentityGetProps';
import { defaultSearch } from '../constants/IdentitySearchTypes';
import {
  fetchIdentityData,
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem
} from '../../common/helpers';
import { IIdentityResultsDto } from '../models/IUserIdentityDto';
import { IUserSearchState } from '../models/IUserSearchState';

export type UserSearchProps = {
  dateTimeFormat: string;
  useSessionStorage: boolean;
};

export const useUserSearchState = (
  props: UserSearchProps
): IUserSearchState => {
  let sessionSearchParams = null;

  const { dateTimeFormat, useSessionStorage } = props;
  if (useSessionStorage) {
    const searchParamsStringified = getSessionStorageItem(
      localStorageItems.identitySearchParam
    );
    sessionSearchParams = searchParamsStringified
      ? JSON.parse(searchParamsStringified)
      : null;
  }

  const [identities, setIdentities] = useState<IUserIdentity[]>([]);
  const [isLoadingIdentities, setIsLoadingIdentities] =
    useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>(
    sessionSearchParams?.searchTerm ?? defaultSearch.searchTerm
  );
  const [searchType, setSearchType] = useState<string>(
    defaultSearch.searchType
  );
  const [gridOrderBy, setGridOrderBy] = useState<string>(
    sessionSearchParams?.sortBy ?? defaultSearch.sortBy
  );
  const [gridOrderDirection, setGridOrderDirection] = useState<SortDirection>(
    sessionSearchParams?.sortDirection ?? defaultSearch.sortDirection
  );
  const [gridFilter, setGridFilter] = useState<string>(
    sessionSearchParams?.filter ?? defaultSearch.filter
  );
  const [pageSize, setPageSize] = useState<string>(
    sessionSearchParams?.pageSize ?? defaultSearch.pageSize
  );
  const [currentCursor, setCurrentCursor] = useState<string>(
    sessionSearchParams?.cursor ?? defaultSearch.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>([]);
  const [triggerIdentitiesGet, setTriggerIdentitiesGet] =
    useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(false);

  const removeSessionStorage = () => {
    if (useSessionStorage) {
      removeSessionStorageItem(localStorageItems.identitySearchParam);
    }
  };

  const onSortOrder = (newOrderDirection, newOrderBy) => {
    setGridOrderBy(newOrderBy);
    setGridOrderDirection(newOrderDirection);
    setCursorStack([]);
    setCurrentCursor(null);
    removeSessionStorage();
    setTriggerIdentitiesGet(!triggerIdentitiesGet);
  };

  const onSetSearchTerm = (newSearchParam: string, newSearchType: string) => {
    if (searchTerm !== newSearchParam) {
      setSearchTerm(newSearchParam);
      setSearchType(newSearchType);
      setCursorStack([]);
      setCurrentCursor(null);
      removeSessionStorage();
      setTriggerIdentitiesGet(!triggerIdentitiesGet);
    }
  };

  const onSetGridFilter = (newFilter: string) => {
    setGridFilter(newFilter);
    setCursorStack([]);
    setCurrentCursor(null);
    removeSessionStorage();
    setTriggerIdentitiesGet(!triggerIdentitiesGet);
  };

  const setSearchTermCheckEmpty = (searchParam) => {
    const oldSearchTerm = searchTerm;
    setSearchTerm(searchParam);
    //if the clear button was clicked, or all characters manually removed, get identities
    if ((!searchParam || searchParam == '') && oldSearchTerm?.length > 0) {
      setTriggerIdentitiesGet(!triggerIdentitiesGet);
    }
  };

  const onChangePageSize = (newPageSize: string) => {
    setPageSize(newPageSize);
    setCursorStack([]);
    setCurrentCursor(null);
    removeSessionStorage();
    setTriggerIdentitiesGet(!triggerIdentitiesGet);
  };

  const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    removeSessionStorage();
    setSearchTermCheckEmpty(value);
  };

  const onChangePage = (nextPage) => {
    if (nextPage == PageDirection.Previous && cursorStack.length > 0) {
      //clear the next page cursor
      cursorStack.pop();
      if (cursorStack.length > 0) {
        //clear current cursor, as it will get added on with api call
        cursorStack.pop();
      }
      setCursorStack(cursorStack);
    }
    if (cursorStack.length > 0) {
      setCurrentCursor(cursorStack[cursorStack.length - 1]);
    } else {
      setCurrentCursor(null);
    }
    setTriggerIdentitiesGet(!triggerIdentitiesGet);
  };

  const getIdentityListProps = (): UserIdentityGetProps => {
    const identityListProps: UserIdentityGetProps = {
      sortBy: gridOrderBy,
      sortDirection: gridOrderDirection,
      pageSize: pageSize,
      searchTerm: searchTerm,
      searchType: searchType,
      cursor: currentCursor,
      filter: gridFilter
    };

    const identityListPropsStringified = JSON.stringify(identityListProps);
    if (useSessionStorage) {
      setSessionStorageItem(
        localStorageItems.identitySearchParam,
        identityListPropsStringified
      );
    }
    return identityListProps;
  };

  const getIdentityListQueryString = (props: UserIdentityGetProps): string => {
    let orderDir = null;
    if (props.sortBy) {
      orderDir =
        props.sortDirection == SortDirection.Ascending ? 'asc' : 'desc';
    }
    const querystring =
      `pagesize=${props.pageSize}` +
      `${props.searchTerm ? `&type=${props.searchType}` : ''}` +
      `${props.sortDirection && props.sortBy ? '&sort=' + orderDir : ''}` +
      `${props.cursor ? '&cursor=' + encodeURIComponent(props.cursor) : ''}` +
      `${props.sortBy ? '&sortOn=' + props.sortBy : ''}` +
      `${
        props.searchTerm ? '&term=' + encodeURIComponent(props.searchTerm) : ''
      }` +
      `${props.filter ? '&filter=' + props.filter : ''}`;
    return querystring;
  };

  const getIdentityListAndSetIdentities = async (): Promise<
    [identities: IUserIdentity[], exception: any]
  > => {
    if (initialized) {
      try {
        setIsLoadingIdentities(true);
        const identityResults = await getIdentityList(getIdentityListProps());
        if (
          identityResults.results &&
          identityResults?.results.length > 0 &&
          identityResults?.cursor
        ) {
          insertCursorIntoStack(identityResults.cursor);
        }
        setIdentities(identityResults.results);
        return [identityResults.results, null];
      } catch (error) {
        console.error('ActivTrak Error: Unable to load Identities', error);
        return [null, error];
      } finally {
        setIsLoadingIdentities(false);
      }
    }
  };

  useEffect(
    () => {
      getIdentityListAndSetIdentities();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerIdentitiesGet]
  );

  const insertCursorIntoStack = (nextCursor: string) => {
    if (cursorStack.length > 0) {
      //don't add the same cursor twice
      if (cursorStack[cursorStack.length - 1] !== nextCursor) {
        const tmpCursorStack = Object.assign([], cursorStack);
        tmpCursorStack.push(nextCursor);
        setCursorStack(tmpCursorStack);
      }
    } else {
      setCursorStack([nextCursor]);
    }
  };

  const getIdentityList = useCallback(
    async (props: UserIdentityGetProps): Promise<IIdentityResults> => {
      const queryString = getIdentityListQueryString(props);
      const fullPath = `/identity/v1/entities?${queryString}`;
      const dtoIdentities = await fetchIdentityData<IIdentityResultsDto>({
        path: fullPath
      });
      const identityResults = mapToIdentityResults(dtoIdentities);
      return identityResults;
    },
    []
  );

  const initializeUserIdentities = () => {
    setInitialized(true);
    setTriggerIdentitiesGet(!triggerIdentitiesGet);
  };

  useEffect(() => {
    const pageSizeInt = parseInt(pageSize);
    const disableNextPage =
      cursorStack?.length == 0 || identities.length < pageSizeInt;
    setNextPageDisabled(disableNextPage);
  }, [currentCursor, cursorStack, pageSize, identities]);

  return {
    isLoadingIdentities,
    identities,
    setIdentities,
    onSortOrder,
    searchTerm,
    pageSize,
    onChangePageSize,
    onChangePage,
    currentCursor,
    cursorStack,
    setSearchTermCheckEmpty,
    onSearchChanged,
    onSetSearchTerm,
    getIdentityList,
    gridOrderBy,
    gridOrderDirection,
    initializeUserIdentities,
    gridFilter,
    onSetGridFilter,
    dateTimeFormat,
    nextPageDisabled,
    getIdentityListProps
  };
};
