'use strict';

angular
    .module('app')
    .directive('atDateRangePicker', [
        'AccountSettingsService',
        AtDateRangePicker
    ]);

function AtDateRangePicker(AccountSettingsService) {
    return {
        restrict: 'AE',
        template: require('views/widgets/atDateRangePicker.html'),
        replace: true,
        scope: {
            dateRange: '=?',
            changeCallback: '=atChange',
            autoFocus: '='
        },
        link: function($scope, element) {
            var picker = element.find('input[name="datepicker"]');
            var dateFormat = AccountSettingsService.dateFormat.toUpperCase();
            var creationDateObject = moment.unix(AccountSettingsService.unixCreationTime);
            var maxDate_ianaTZ = moment().tz(AccountSettingsService.currentIanaTimeZone).format(dateFormat);
            var maxDate = moment().format(dateFormat);
            if (maxDate_ianaTZ > maxDate)
                maxDate = maxDate_ianaTZ;

            angular.element('div.daterangepicker.show-calendar').remove();

            picker.daterangepicker(
                {
                    showDropdowns: true,
                    startDate: moment($scope.dateRange.from),
                    endDate: moment($scope.dateRange.to),
                    opens: 'right',
                    minDate: creationDateObject ? creationDateObject.format(dateFormat) : undefined,
                    maxDate: maxDate,
                    locale: {
                        separator: ' to ',
                        format: dateFormat
                    },
                    applyButtonClasses: 'btn-success at-date-range-picker-btn',
                    cancelButtonClasses: 'btn-danger at-date-range-picker-btn'
                },
                function (start, end) {
                  //TODO: THIS IS A TEMPORARY PATCH TO COMPENSATE FOR LOCAL TZ OFFSET - THIS WILL BE REFACTORED
                  // !! DO NOT REPLICATE !!
                  var start_patch = moment.tz(`${start.format('YYYY-MM-DD')} 00:00:00`, AccountSettingsService.currentIanaTimeZone);
                  var end_patch = moment.tz(`${end.format('YYYY-MM-DD')} 00:00:00`, AccountSettingsService.currentIanaTimeZone);

                  $scope.changeCallback({ start: start_patch, end: end_patch });
                }
            );

            if ($scope.autoFocus) {
                picker.focus();
                picker.blur();

                $scope.autoFocus = false;
            }
        }
    };
}
