import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const AdvancedFilterButton = styled(IconButton)`
  padding: 3px 7px 4px !important;
  left: -20px;
  &:hover {
    background-color: rgba(68, 68, 68, 0.04) !important;
  }
`;

export const PopoverContainer = styled('div')<{ popoverwidth?: string }>`
  max-width: 760px;
  width: ${(props) => (props?.popoverwidth ? props.popoverwidth : '600px')};
`;
