import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { IApiKeyDto } from '../models';
import DeleteIcon from '@mui/icons-material/Delete';

interface IDeleteApiKeyButton {
  apikey: IApiKeyDto;
  onDelete: (apikeyId: number) => Promise<void>;
  successMessage?: string;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const DeleteApiKeyButton: React.FC<IDeleteApiKeyButton> = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setConfirmed(false);
  };

  const confirmDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const deleteApiKey = async () => {
    props.onDelete(props.apikey.id);
    closeDialog();
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Delete API Key">
        <IconButton onClick={openDialog} disabled={props.isDisabled}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="lg"
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          closeDialog();
        }}
      >
        <DialogTitle sx={{ paddingLeft: '200px' }}>Delete API Key</DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: '20px' }}>
            Are you sure you want to delete the API key:{' '}
            {props.apikey.description}?
          </Typography>
          <Typography sx={{ marginBottom: '10px' }}>
            This API key will be deleted immediately. Once deleted, it can no
            longer be used to make API requests.
          </Typography>
          <label htmlFor="confirm-delete">
            <Checkbox
              id="confirm-delete"
              checked={confirmed}
              onChange={confirmDelete}
              sx={{ border: '1px' }}
            />
            I confirm to delete the API Key.
          </label>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button variant="text" autoFocus onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            onClick={deleteApiKey}
            disabled={!confirmed}
            variant="contained"
            color="primary"
          >
            Delete API Key
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
