import { styled } from '@mui/material/styles';
import { List, ListItem, Box } from '@mui/material';

export const ToggleIconSC = styled(ListItem)`
  &.MuiListItem-root {
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;

    .MuiListItemIcon-root {
      min-width: auto;
    }
  }
`;

export const CheckboxItemSC = styled(ListItem)`
  &.MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItemIcon-root {
      min-width: auto;
    }
  }
`;

type CheckboxFilterContainerProps = { component?: string };
export const CheckboxFilterContainerSC = styled(
  List
)<CheckboxFilterContainerProps>`
  max-height: 400px;
  overflow-y: auto;
`;

export const CheckboxFilterSubmitSC = styled(Box)`
  text-align: right;
  padding: 15px;
`;
