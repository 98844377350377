import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { primaryAqua } from '../../constants';

export const DndContainer = styled(Box)`
  height: 130px;
  border-radius: 3px;
  border: 1px dashed ${primaryAqua};
`;

export const DndArea = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;
