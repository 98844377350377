const _defaultValueMap = {
    productive_hrs_day: 6.0,
    focused_time_day: 5.0
};

class Metric {
    constructor(data) {
        this.text = data.displayName;
        this.value = data.name.replace(/_/g, '^_');
        this.id = data.id;
        this.defaultValue = _defaultValueMap[data.name] || 4.0;
    }
}

export default Metric;
