import { useCallback, useState } from 'react';
import { IAutoUpdateSettingsService } from '../models/IAutoUpdateSettingsService';
import {
  IAutoUpdateSettingDto,
  IAgent,
  IAgentDto,
  IAutoUpdateSettingsState
} from '../models';
import {
  filterAgents,
  getAutoUpdateWarning,
  getManualWarning,
  getSelectedAgentVersion,
  hasSwitchedToAuto,
  hasSwitchedToManual,
  mapToAgents
} from '../utils/agent.utils';
import {
  IAgentSelection,
  IAgentSelectionPayload
} from '../models/IAgentSelection';
import {
  AgentVersionType,
  OperationalSystemsTypeShort
} from '../../common/enums';
import { INotificationService } from '../../common/services/Notifications';
import {
  newVersionAvailable,
  newVersionAvailableWillNotResume,
  newVersionAvailableWillResume
} from '../constants/autoUpdate.const';
import { fetchData } from '../../common/helpers';

type AutoUpdateSettingsStateProps = {
  service: IAutoUpdateSettingsService;
  notificationService: INotificationService;
};

export const useAutoUpdateSettingsState = ({
  service,
  notificationService
}: AutoUpdateSettingsStateProps): IAutoUpdateSettingsState => {
  const { fetchAgentTargets, setAgentVersion, fetchAllTargets } = service;

  const [agentVersions, setAgentVersions] = useState<IAgent[]>();
  const [winVersions, setWinVersions] = useState<IAgentSelection[]>();
  const [macVersions, setMacVersions] = useState<IAgentSelection[]>();
  const [filter, setFilter] = useState<string>('0');
  const [isLoading, setLoading] = useState<boolean>(false);

  const [originalWinVersion, setOriginalWinVersion] = useState<
    AgentVersionType | string
  >('');
  const [newWinVersion, setNewWinVersion] = useState<AgentVersionType | string>(
    ''
  );
  const [originalMacVersion, setOriginalMacVersion] = useState<
    AgentVersionType | string
  >('');
  const [newMacVersion, setNewMacVersion] = useState<AgentVersionType | string>(
    ''
  );
  const [winWarning, setWinWarning] = useState<string>('');
  const [macWarning, setMacWarning] = useState<string>('');
  const [winError, setWinError] = useState<string>('');
  const [macError, setMacError] = useState<string>('');

  const getAgentPinnedReason = async (agents: IAgent[]) => {
    let lookupPinnedReason = false;
    let permanentPin = false;
    if (agents) {
      for (const agent of agents) {
        if (agent.statusText == newVersionAvailable) {
          lookupPinnedReason = true;
        }
      }
    }
    if (lookupPinnedReason) {
      permanentPin = await fetchAgentPermanentPin();
      for (const agent of agents) {
        if (agent.statusText == newVersionAvailable) {
          agent.statusText = permanentPin
            ? newVersionAvailableWillNotResume
            : newVersionAvailableWillResume;
          agent.statusColor = permanentPin ? 'text-danger' : 'text-warning';
        }
      }
    }
  };

  const fetchAgentPermanentPin = async (): Promise<boolean> => {
    const url = `api/settings/noupdatelist`;
    const results = await fetchData<boolean>({
      path: url
    });
    return results;
  };

  const getTargets = useCallback(async (val: string) => {
    setLoading(true);
    try {
      const data: IAutoUpdateSettingDto = await fetchAgentTargets(val);
      if (data?.targets && data?.platforms) {
        const agents = mapToAgents(data.targets, data.platforms);
        await getAgentPinnedReason(agents);
        setAgentVersions(agents);

        const origWinVersion = getSelectedAgentVersion(
          agents,
          OperationalSystemsTypeShort.win
        );
        setOriginalWinVersion(origWinVersion);
        setNewWinVersion(origWinVersion);

        const origMacVersion = getSelectedAgentVersion(
          agents,
          OperationalSystemsTypeShort.mac
        );
        setOriginalMacVersion(origMacVersion);
        setNewMacVersion(origMacVersion);
      }
    } catch (error) {
      notificationService.error('Unable to load Agent Versions');
      console.error('ActivTrak Error: Unable to load Agent Versions', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    if (!agentVersions?.length) getTargets(filter);
  }, [agentVersions?.length, filter, getTargets]);

  const resetData = useCallback(() => {
    setNewWinVersion(originalWinVersion);
    setNewMacVersion(originalMacVersion);
    setMacWarning('');
    setWinWarning('');
    setMacError('');
    setWinError('');
  }, [originalMacVersion, originalWinVersion]);

  const getAllTargets = useCallback(async (): Promise<void> => {
    try {
      const data: IAgentDto[] = await fetchAllTargets();
      const win = filterAgents(data, 'Win');
      setWinVersions(win);
      const mac = filterAgents(data, 'Mac');
      setMacVersions(mac);
    } catch (error) {
      notificationService.error('Unable to load All Agent Versions');
      console.error(
        'ActivTrak Error: Unable to load All Agent Versions',
        error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTargets = useCallback(
    async (
      os: OperationalSystemsTypeShort,
      version: IAgentSelectionPayload
    ): Promise<void> => {
      try {
        await setAgentVersion(os, version);
        notificationService.success('Agent version saved');
        getTargets(filter);
      } catch (error) {
        notificationService.error('Failed to save agent type');
        console.error('ActivTrak Error: Failed to save agent type', error);
      } finally {
        setMacWarning('');
        setWinWarning('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, getTargets]
  );

  const handleVersionChange = useCallback(
    (
      value: AgentVersionType | string,
      os: OperationalSystemsTypeShort
    ): void => {
      if (os === OperationalSystemsTypeShort.win) {
        if (value !== AgentVersionType.beta) {
          setWinError('');
        }
        setNewWinVersion(value);
        let tempWinWarning = '';
        if (value !== originalWinVersion) {
          if (hasSwitchedToAuto(originalWinVersion, value)) {
            tempWinWarning = getAutoUpdateWarning(os, value);
          } else if (hasSwitchedToManual(originalWinVersion, value)) {
            tempWinWarning = getManualWarning(os);
          }
        }
        setWinWarning(tempWinWarning);
      } else if (os === OperationalSystemsTypeShort.mac) {
        if (value !== AgentVersionType.beta) {
          setMacError('');
        }
        setNewMacVersion(value);
        let tempMacWarning = '';
        if (value !== originalMacVersion) {
          if (hasSwitchedToAuto(originalMacVersion, value)) {
            tempMacWarning = getAutoUpdateWarning(os, value);
          } else if (hasSwitchedToManual(originalMacVersion, value)) {
            tempMacWarning = getManualWarning(os);
          }
        }
        setMacWarning(tempMacWarning);
      }
    },
    [originalMacVersion, originalWinVersion]
  );

  const handleDaysFilter = (days: string): void => {
    setFilter(days);
    getTargets(days);
  };

  const validateAgentUpdate = (): boolean => {
    let validationPassed = true;
    if (
      newWinVersion !== originalWinVersion &&
      newWinVersion == AgentVersionType.beta
    ) {
      setWinError(
        'Early access setting is not supported.  Please reach out to engineering to enable this.'
      );
      validationPassed = false;
    }
    if (
      newMacVersion !== originalMacVersion &&
      newMacVersion == AgentVersionType.beta
    ) {
      setMacError(
        'Early access setting is not supported.  Please reach out to engineering to enable this.'
      );
      validationPassed = false;
    }
    return validationPassed;
  };

  const handleAgentUpdate = useCallback((): void => {
    const newWin: IAgentSelectionPayload =
      newWinVersion === AgentVersionType.ga
        ? { Version: null, Type: newWinVersion }
        : { Version: newWinVersion, Type: null };
    setTargets(OperationalSystemsTypeShort.win, newWin);

    const newMac: IAgentSelectionPayload =
      newMacVersion === AgentVersionType.ga ||
      newWinVersion === AgentVersionType.beta
        ? { Version: null, Type: newMacVersion }
        : { Version: newMacVersion, Type: null };
    setTargets(OperationalSystemsTypeShort.mac, newMac);
  }, [newMacVersion, newWinVersion, setTargets]);

  return {
    agentVersions,
    winVersions,
    winWarning,
    newWinVersion,
    macVersions,
    macWarning,
    newMacVersion,
    filter,
    isLoading,
    init,
    handleDaysFilter,
    getAllTargets,
    handleAgentUpdate,
    resetData,
    handleVersionChange,
    validateAgentUpdate,
    winError,
    macError
  };
};
