import { Dispatch, SetStateAction } from 'react';

export enum AvailableDays {
  ThreeDays = 3,
  SevenDays = 7,
  FourteenDays = 14
}

export type ActiveSinceDays = AvailableDays;

export type UserHealthDataSets = {
  data: number[];
  backgroundColor: string[];
  borderWidth: number;
};

export type UserHealthDonut = {
  labels: string | string[];
  datasets: UserHealthDataSets[];
};

export interface IUserHealthDto {
  activeUserCount: number;
  inactiveUserCount: number;
}

export type RawResponses = {
  threeDays: IUserHealthDto;
  sevenDays: IUserHealthDto;
  fourteenDays: IUserHealthDto;
};

export type UserHealthChartProps = {
  chartData: UserHealthDonut;
  activeSinceDays: ActiveSinceDays;
  dataPercentages: number[] | string[];
};

export type UserHealthWidgetInitConfig = {
  activeSinceDays: ActiveSinceDays;
};

export type UserHealthModalProps = {
  activeSinceDays: ActiveSinceDays;
  setActiveSinceDays: Dispatch<SetStateAction<ActiveSinceDays>>;
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
};

export interface IUserHealthState {
  chartData: UserHealthDonut;
  activeSinceDays: ActiveSinceDays;
  dataPercentages: number[] | string[];
  rawResponses: RawResponses;
  modalState: boolean;
  isWidgetStateLoading: boolean;
  widgetError: boolean;
  init: (initConfig: UserHealthWidgetInitConfig) => void;
  getData: (initConfig: UserHealthWidgetInitConfig) => Promise<void>;
  setModalState: Dispatch<SetStateAction<boolean>>;
  setActiveSinceDays: Dispatch<SetStateAction<ActiveSinceDays>>;
}
