'use strict';

angular.module('app').controller('locationConfirmDeleteModal', LocationConfirmDeleteModal);

LocationConfirmDeleteModal.$inject = [
    '$scope',
    'modalData',
    '$uibModalInstance'
];

function LocationConfirmDeleteModal(
    $scope,
    modalData,
    $uibModalInstance
) {
    $scope.location = modalData.location;

    $scope.confirmDelete = function() {
        modalData.deleteLocation($scope.location);
        $uibModalInstance.dismiss();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

}
