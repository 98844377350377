import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { getPrivacySettings } from '../../../../_reactivtrak/src/common/hooks/privacySettingsStore';
import deleteUserMessageModalControllerTemplate from 'views/modals/deleteUserMessageModal.html?raw';
import updatePasswordModalControllerTemplate from 'views/modals/updatePasswordModal.html?raw';
import changePasswordModalControllerTemplate from 'views/modals/changePasswordModal.html?raw';
import changeGroupsModalControllerTemplate from 'views/modals/changeGroupsModal.html?raw';
import changeViewableAccountsModalControllerTemplate from 'views/modals/changeViewableAccountsModal.html?raw';
import addATIdModalControllerTemplate from 'views/modals/addATIdModal.html?raw';

angular.module('app').controller('SettingsAccessCtrl', SettingsAccessCtrl);

SettingsAccessCtrl.$inject = [
    '$scope',
    '$state',
    'customUibModal',
    'accountSettings',
    'messagesService',
    'accessApiService',
    'notificationService',
    'authorizationService',
    'templateServiceFunctions',
    'pageSizeService',
    // Async Payload is an aggregate of the resolved api call
    // results needed to render this page
    'asyncPayload'
];

function SettingsAccessCtrl(
    $scope,
    $state,
    customUibModal,
    accountSettings,
    msg,
    accessApiService,
    notificationService,
    authorizationService,
    templateServiceFunctions,
    pageSizeService,
    asyncPayload
) {
    var { screenshotsAllowed = false } = getPrivacySettings();

    $scope.isVerified = accountSettings.isVerified;
    $scope.ssoEnabled = false;
    $scope.ssoEligible = false;
    $scope.isSupportAdvancedOrSuperAdmin = authorizationService.hasRole([
        authorizationService.roles.superAdmin,
        authorizationService.roles.supportAdvanced
    ]);
    $scope.isSupportAdvanced = authorizationService.hasRole([authorizationService.roles.supportAdvanced]);
    $scope.isActivInsights = authorizationService.hasFeature(FeatureFlag.InsightsAvailable);
    $scope.menuDirection = 'bottom';

    $scope.ssoEligible = asyncPayload.ssoEligible;

    var allGroupsCurrent = {
        entityid: 0,
        groupid: -3,
        icon: '<group-type-label icon-source="Mixed" tooltip-content="Mixed Group" tooltip-child="Mixed Group"></group-type-label>',
        isEmpty: false,
        mix: 'User',
        name: 'All Groups (current)',
        type: 'AllUsers'
    };

    var allUsersAndGroups = {
        entityid: 0,
        groupid: -1,
        isEmpty: false,
        mix: 'User',
        name: 'All Users',
        type: 'AllUsers'
    };

    var firstElemIndex = asyncPayload.groups.findIndex(function (item) {
        return item.groupid > 0;
    });

    var getGroupUserIconWithName = function (item) {
        if (item.groupid === -1) {
            item.name = 'All Users and Groups (current and future)';
        }
        return templateServiceFunctions.groupTypeIconAndNameTemplateAsString(item, true);
    };

    asyncPayload.groups.splice(firstElemIndex, 0, allUsersAndGroups);
    asyncPayload.groups.splice(firstElemIndex + 1, 0, allGroupsCurrent);

    angular.forEach(asyncPayload.groups, function (item) {
        item.icon = getGroupUserIconWithName(item);
    });

    $scope.availableGroups = asyncPayload.groups;

    $scope.availableAccounts = asyncPayload.accounts;
    var allChildAccountsOpt = {
        accountId: -1,
        companyName: 'All Child Accounts (current & future)'
    };
    var selectAllOpt = {
        accountId: -2,
        companyName: 'Select All (current)'
    };

    $scope.availableAccounts.unshift(allChildAccountsOpt, selectAllOpt);

    $scope.ssoEnabled = asyncPayload.ssoEnabled;

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.account.access');
    };

    $scope.hasFeature = function (featureName) {
        return authorizationService.hasFeature(featureName);
    };

    var accessDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                accessApiService.getUsers().success(function (result) {
                    result.map(function (r) {
                        if (
                            $scope.showViewableAccountsColumn() &&
                            (r.userRole == 2 || r.userRole == 4 || r.userRole == 5)
                        ) {
                            r.userRole = 3;
                        }
                        return r;
                    });
                    options.success(result);

                    if (options && options.data && typeof options.data.callback === 'function') {
                        options.data.callback(result);
                    }
                });
            }
        },
        pageSize: pageSizeService.loadPageSize('access-page', 50),
        schema: {
            model: {
                id: 'user',
                fields: {
                    user: {
                        editable: false,
                        nullable: false
                    },
                    userRole: {
                        editable: false,
                        nullable: false
                    },
                    viewableGroups: {
                        editable: false,
                        nullable: false
                    },
                    viewableAccounts: {
                        allChildAccounts: false,
                        accountId: []
                    },
                    setGoalGroups: {
                        editable: false,
                        nullable: false
                    },
                    selected: {
                        editable: false,
                        nullable: false
                    }
                }
            }
        }
    });

    $scope.flattenOutGroups = function (item) {
        var allUsersAndGroups =
            templateServiceFunctions.groupTypeIconTemplateAsString(
                {
                    type: 2,
                    mix: 'Both'
                },
                true
            ) + msg.get('allUsersAndGroups');
        var allGroups =
            templateServiceFunctions.groupTypeIconTemplateAsString(
                {
                    type: 2,
                    mix: 'Both'
                },
                true
            ) + msg.get('allGroups');
        var availableGroupsLength = $scope.availableGroups.filter(function (group) {
            return group.groupid !== -1 && group.groupid !== -2 && group.groupid !== -3;
        }).length;
        var isAllGroupsSelected = item.viewableGroups.length === availableGroupsLength;
        var userRole = authorizationService.getRole(item.userRole);
        if (
            userRole &&
            authorizationService.applicationRoles &&
            (userRole.key === authorizationService.applicationRoles.admin.key ||
                userRole.key === authorizationService.applicationRoles.configurator.key)
        ) {
            return allUsersAndGroups;
        }
        if (isAllGroupsSelected) {
            return allGroups;
        }
        if (!item.viewableGroups.length) {
            return 'No Users or Groups';
        }

        var groups = item.viewableGroups.map(function (group) {
            return group.name === 'All Users'
                ? allUsersAndGroups
                : templateServiceFunctions.groupTypeIconAndNameTemplateAsString(group, true);
        });
        var firstGroup = groups.shift();
        if (groups.length) {
            return firstGroup + ' + ' + groups.length;
        }
        return firstGroup;
    };

    $scope.flattenOutChildAccounts = function (user) {
        var item = user.viewableAccounts;
        var chidAccounts = $scope.availableAccounts;

        if (user.admin || item.allChildAccounts) {
            return 'All Child Accounts';
        } else if (!item.accountId || !item.accountId.length || !chidAccounts || !chidAccounts.length) {
            return 'No Accounts';
        }

        var availableAssigned = [];
        //Get list of accounts to check
        angular.forEach(chidAccounts, function (child) {
            if (item.accountId.indexOf(parseInt(child.accountId)) > -1) {
                availableAssigned.push(child);
            }
        });

        if (availableAssigned.length < 1) {
            return 'No Accounts';
        }
        var firstChildAccount = availableAssigned[0];

        if (firstChildAccount.accountId.toString() === '-1') {
            return 'All Child Accounts';
        }

        return (
            firstChildAccount.companyName + (availableAssigned.length > 1 ? ' + ' + (availableAssigned.length - 1) : '')
        );
    };

    $scope.isCurrentUser = function (item) {
        return item.user === accountSettings.username;
    };

    $scope.roleExplanationTemplate = function () {
        var adminRoleLabel = $scope.showViewableAccountsColumn()
            ? msg.get('ccAdminRoleLabelAccess')
            : msg.get('adminRoleLabelAccess');
        var powerUserRoleLabel = '';
        var viewerRoleLabel = '';
        var configuratorRoleLabel = '';
        var ccUserRoleLabel = '';
        var ccLearnMore = '';
        if (!$scope.showViewableAccountsColumn()) {
            powerUserRoleLabel =
                '<br />' +
                (screenshotsAllowed ? msg.get('powerUserRoleLabelAccess') : msg.get('powerUserRoleLabelPrivateAccess'));
            viewerRoleLabel =
                '<br />' +
                msg.get('viewerRoleLabelAccess') +
                '</p></div><br/><div style="text-align:left"><p style="font-size: 12px"><span>To configure role based access go to the </span><strong><span style="font-size: 12px"><a class="text-productive" href="/#/app/settings/roleaccess">Role Access </a></span></strong><span>page</span></p></div></div>';
            configuratorRoleLabel = '<br />' + msg.get('configuratorRoleLabelAccess');
        } else {
            ccUserRoleLabel = msg.get('ccUserRoleLabelAccess');
            ccLearnMore = msg.get('ccLearnMoreLabelAccess');
        }

        return (
            '<div style="width: 370px, height: 229px"><div style="text-align:left"><p style="font-size: 12px">' +
            adminRoleLabel +
            configuratorRoleLabel +
            powerUserRoleLabel +
            viewerRoleLabel +
            ccUserRoleLabel +
            ccLearnMore +
            '</p></div></div>'
        );
    };

    $scope.checkDataItemValuesId = function (id) {
        var newId = id;
        if ($scope.showViewableAccountsColumn() && (id == 2 || id == 4 || id == 5)) {
            newId = 3;
        }
        return newId;
    };
    /* eslint-disable */
    var getGridColumns = function () {
        var roleOptions = !$scope.showViewableAccountsColumn()
            ? [
                  {
                      index: 1,
                      name: 'Admin',
                      position: 1
                  },
                  {
                      index: 5,
                      name: 'Configurator',
                      position: 2
                  },
                  {
                      index: 2,
                      name: 'Power User',
                      position: 3
                  },
                  {
                      index: 3,
                      name: 'Viewer',
                      position: 4
                  }
              ]
            : [
                  {
                      index: 1,
                      name: 'CC Admin',
                      position: 1
                  },
                  {
                      index: 3,
                      name: 'CC User',
                      position: 2
                  }
              ];

        let accessRoleSelectItems = _.sortBy(roleOptions, ['position']).map(function (roleOption) {
            return `<option value="${roleOption.index}">${roleOption.name}</option>`;
        });

        var verifyUserRole = function (roleValue) {
            var foundRoleOption = _.inRange(roleValue, 1, 6)
                ? roleOptions.find(function (roleOption) {
                      return roleOption.index === roleValue;
                  })
                : {
                      index: 0,
                      name: '',
                      position: 0
                  };

            return foundRoleOption && foundRoleOption.name;
        };

        return [
            {
                field: 'user',
                title: msg.get('atUser'),
                width: '350px',
                filterable: templateServiceFunctions.createFilter('user', accessDataSource)
            },
            {
                field: 'admin',
                filterable: false,
                headerTemplate:
                    msg.get('role') +
                    `<i-tooltip
            placement="bottom"
            top-offset="4px"
            template="roleExplanationTemplate">
          </i-tooltip>`,
                template:
                    `<select
            kendo-drop-down-list
            class="access-role-select"
            id="admin-selectbox-{{dataItem.uid}}"
            ng-model="dataItem.userRole"
            ng-class="{ disabled: !hasViewLevel('edit')}"
            ng-disabled="isCurrentUser(dataItem) || isSupportAdvancedOrSuperAdmin || !hasViewLevel('edit')"
            ng-change="setUserRole(dataItem)"
          >` +
                    accessRoleSelectItems +
                    `</select>`,
                attributes: {
                    class: 'text-center',
                    style: 'overflow: visible'
                },

                sortable: {
                    compare: function (a, b) {
                        var roleNameA = verifyUserRole(parseInt(a.userRole));
                        var roleNameB = verifyUserRole(parseInt(b.userRole));
                        return roleNameA.localeCompare(roleNameB);
                    }
                },
                width: '250px'
            },
            {
                field: 'viewableGroups',
                filterable: false,
                title: msg.get('viewableGroups'),
                template: kendo.template(function (dataItem) {
                    return `<button class="option-button" ng-click="changeGroups(dataItem)">${$scope.flattenOutGroups(
                        dataItem
                    )}</button>`;
                }),
                sortable: {
                    compare: function (a, b) {
                        var string1 = $scope.flattenOutGroups(a);
                        var string2 = $scope.flattenOutGroups(b);
                        return string1.localeCompare(string2);
                    }
                },
                attributes: {
                    class: 'text-center'
                },
                width: '350px',
                hidden: $scope.showViewableAccountsColumn()
            },
            {
                field: 'viewableAccounts',
                filterable: false,
                title: msg.get('viewableAccounts'),
                template: kendo.template(function () {
                    return '<button class="option-button" ng-click="changeAccounts(dataItem)" ng-bind-html="flattenOutChildAccounts(dataItem)"></button>';
                }),
                sortable: {
                    compare: function (a, b) {
                        var string1 = $scope.flattenOutChildAccounts(a);
                        var string2 = $scope.flattenOutChildAccounts(b);
                        return string1.localeCompare(string2);
                    }
                },
                attributes: {
                    class: 'text-center'
                },
                width: '350px',
                hidden: !$scope.showViewableAccountsColumn()
            },
            {
                field: 'sso',
                filterable: false,
                title: 'Use SSO',
                template: `
          <div class="checkbox">
            <input type="checkbox" ng-model="dataItem.ssoEnabled" id="sso-selectbox-{{dataItem.uid}}" ng-disabled="(isCurrentUser(dataItem) && !isSupportAdvancedOrSuperAdmin) || !hasPasswordControls() || !ssoEnabled"  ng-change="setSsoEnabled(dataItem)"/>
            <label for="sso-selectbox-{{dataItem.uid}}" class="m-l-8-i m-t-2-i">
            </label>
          </div>`,
                attributes: {
                    class: 'text-center'
                },
                width: '90px',
                hidden: !$scope.ssoEnabled
            },
            {
                command: [
                    {
                        template: `
              <span class="dropdown {{menuDirection}} pmd-dropdown clearfix">
                <button class="menu-3dots" type="button" id="dropdownMenuTopRight" data-toggle="dropdown" aria-expanded="true">
                  <i class="material-icons pmd-sm">more_vert</i>
                </button>
                <ul aria-labelledby="dropdownMenu3" role="menu" class="dropdown-menu menu-area">
                  <li ng-click="deleteUser(dataItem)"
                  ng-show="allowDelete(dataItem)">
                      Delete
                  </li>
                  <li ng-click="changePassword(dataItem)" ng-show="hasPasswordControls()" ng-disabled="!isVerified || (dataItem.ssoEnabled && ssoEnabled)" ng-class="{ 'at-not-allowed': !isVerified, 'at-pointer': isVerified }">
                    Change Password
                  </li>
                </ul>
              </span>
            `
                    }
                ],
                filterable: false,
                attributes: {
                    class: 'text-center',
                    style: 'overflow: visible;'
                },
                width: '150px'
            }
        ];
    };

    /* eslint-enable */
    var hasCCAccessFlag = authorizationService.hasFeature(FeatureFlag.CommandCenterAppAccessSettings);

    $scope.showViewableAccountsColumn = function () {
        return authorizationService.hasRole([authorizationService.roles.ccAccess]);
    };

    $scope.allowDelete = function (item) {
        if (!$scope.isCurrentUser(item) && $scope.hasViewLevel('edit')) {
            if ($scope.isSupportAdvanced) {
                return false;
            }
            //Non ccAccess can't delete a ccAccess admin
            if (!item.ccAccess || hasCCAccessFlag) {
                return true;
            }
        }
        return false;
    };

    $scope.pagerOptions = {
        autoBind: false,
        dataSource: accessDataSource,
        refresh: true,
        pageSizes: [25, 50, 100],
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'users'),
            display: msg.get('itemsDisplay', 'users'),
            empty: msg.get('noItemsToDisplay', 'users')
        }
    };

    $scope.setGridOptions = function () {
        $scope.accessUserGridOptions = {
            kendoGridBinding: 'gridView',
            dataSource: accessDataSource,
            sortable: true,
            filterable: {
                mode: 'row'
            },
            resizable: true,
            scrollable: true,
            columns: getGridColumns(),
            dataBound: function (e) {
                var gridContent = e.sender.element.find('.k-grid-content');
                gridContent.css('overflow-x', 'auto');
            }
        };
    };

    var isInsightsEnabled = authorizationService.hasFeature('isInsightsEnabled');

    $scope.deleteUser = function (item) {
        if (!$scope.allowDelete(item)) {
            notificationService.showNotification(msg.get('cantDeleteATID'), 'danger');
            return false;
        }

        var modal = customUibModal.open({
            animation: false,
            template: deleteUserMessageModalControllerTemplate,
            controller: 'deleteUserMessageModalController',
            windowClass: 'centered-modal delete-user-modal',

            resolve: {
                messageData: {
                    messageTitle: msg.get('deleteUser', item.user),
                    subscriptionsBody: msg.get('subscriptionsBody'),
                    isInsightsEnabled: isInsightsEnabled,
                    confirmLabel: msg.get('deleteUserButton'),
                    twoStepConfirm: true,
                    twoStepConfirmLabel: isInsightsEnabled
                        ? msg.get('userDeletionAcknowledgementPremium')
                        : msg.get('userDeletionAcknowledgement'),
                    linkLabel: msg.get('goToSubsLabel'),
                    linkTarget: msg.get('goToSubsTarget')
                }
            }
        });

        modal.result.then(function () {
            accessApiService
                .deleteUser([item.user])
                .success(function () {
                    accessDataSource.read();
                    notificationService.showNotification(msg.get('userDeleted'), 'success');
                })
                .catch(function () {
                    notificationService.showNotification(msg.get('errorDeletingUser'), 'danger');
                });
        });
    };

    $scope.hasPasswordControls = function () {
        return authorizationService.hasRole([
            authorizationService.roles.admin,
            authorizationService.roles.supportAdvanced,
            authorizationService.roles.superAdmin
        ]);
    };

    $scope.changePassword = function (item) {
        if (item.ssoEnabled && $scope.ssoEnabled) {
            notificationService.showNotification(
                'Cannot change password when SSO is turned on for ' + item.user + '.',
                'danger'
            );
            return;
        }

        if (
            $scope.isCurrentUser(item) &&
            !authorizationService.hasRole([
                authorizationService.roles.supportAdvanced,
                authorizationService.roles.superAdmin
            ])
        ) {
            customUibModal.open({
                animation: false,
                template: updatePasswordModalControllerTemplate,
                controller: 'updatePasswordModalController',
                windowClass: 'centered-modal change-password-modal'
            });
            return;
        }

        if (!$scope.isVerified) {
            return;
        }

        customUibModal.open({
            animation: false,
            template: changePasswordModalControllerTemplate,
            controller: 'changePasswordModalController',
            windowClass: 'centered-modal change-password-modal',
            resolve: {
                modalData: {
                    currentUserName: item.user
                }
            }
        });
    };

    $scope.setUserRole = function (item) {
        if ($scope.isCurrentUser(item)) {
            return;
        }

        var payload = {
            userName: item.user,
            userRole: item.userRole
        };

        accessApiService.setUserRole(payload).success(function () {
            accessDataSource.read();
        });
    };

    $scope.setSsoEnabled = function (user) {
        accessApiService.setUserSsoEnabled(user.userKey, user.ssoEnabled).catch(function () {
            user.ssoEnabled = !user.ssoEnabled;

            notificationService.showNotification('Failure to set SSO enabled for ' + user.user + '.', 'danger');
        });
    };

    $scope.showChangeGroup = function (item) {
        return item.userRole !== '1';
    };

    $scope.changeGroups = function (item, isNewUser) {
        angular.forEach($scope.availableGroups, function (group) {
            group.selected = false;
            group.goalSelected = false;
            // don't show all users / all computers
            group.display = group.groupid !== -2;

            if (group.groupid === -1) {
                group.name = 'All Users and Computers';
            }
        });

        angular.forEach(item.viewableGroups, function (selected) {
            angular.forEach($scope.availableGroups, function (group) {
                if (selected.groupid === group.groupid) {
                    group.selected = true;
                }
            });
        });

        angular.forEach(item.setGoalGroups, function (selected) {
            angular.forEach($scope.availableGroups, function (group) {
                if (selected.groupid === group.groupid) {
                    group.goalSelected = true;
                }
            });
        });

        customUibModal.open({
            animation: false,
            template: changeGroupsModalControllerTemplate,
            controller: 'changeGroupsModalController',
            windowClass: 'centered-modal change-groups-modal',
            resolve: {
                modalData: {
                    hasViewLevel: $scope.hasViewLevel,
                    currentUser: item,
                    isNewUser,
                    currentUserName: item.user,
                    accessDataSource: accessDataSource,
                    availableGroups: $scope.availableGroups,
                    isActivInsights: $scope.isActivInsights
                }
            }
        });
    };

    $scope.changeAccounts = function (item) {
        var childAccounts = $scope.availableAccounts;

        customUibModal
            .open({
                animation: false,
                template: changeViewableAccountsModalControllerTemplate,
                controller: 'changeViewableAccountsModalController',
                windowClass: 'centered-modal change-groups-modal',
                resolve: {
                    modalData: {
                        hasViewLevel: $scope.hasViewLevel,
                        currentUser: item,
                        availableAccounts: childAccounts
                    }
                }
            })
            .closed.then(function () {
                accessDataSource.read();
            });
    };

    $scope.goToSecuritySettings = function () {
        $state.go('app.settings.security');
    };

    $scope.newUser = function () {
        var modal = customUibModal.open({
            animation: false,
            template: addATIdModalControllerTemplate,
            controller: 'addATIdModalController',
            windowClass: 'centered-modal add-at-id-modal',
            resolve: {
                modalData: {
                    accessDataSource: accessDataSource
                }
            }
        });

        modal.result.then(
            function (data) {
                var users = data.users;
                var newUserName = data.newUserName;
                var newUserRole = authorizationService.getRole(data.newUserRole);

                if (newUserRole !== authorizationService.applicationRoles.admin.key) {
                    // Frontend Api saves ActivTrakIds in lowercase form
                    var user = users.find(function (o) {
                        return o.user === newUserName.toLowerCase();
                    });
                    if (user) {
                        if (hasCCAccessFlag) {
                            $scope.changeAccounts(user);
                        } else {
                            $scope.changeGroups(user);
                        }
                    }
                }

                notificationService.showNotification(msg.get('newUserCreated'), 'success');
            },
            function () {
                //Ignore rejection...
            }
        );
    };

    if ($state.params.newmember) {
        $scope.newUser();
    }

    $scope.setGridOptions();
}
