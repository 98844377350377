import {
  gray5,
  gray9,
  primaryAqua,
  primaryPurple,
  productivityColors,
  secondaryAqua,
  secondaryPurple
} from './styleGuide';

export const ProductivityColors = {
  0: gray9,
  1: primaryAqua,
  2: secondaryAqua,
  '-1': primaryPurple,
  '-2': secondaryPurple,
  '-3': gray5,
  '-4': gray9,
  9: productivityColors.offlineMeetings
};
