import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { NewGroupClassificationsButton } from '../styles/Classifications.styles';
import { classificationGroupButtonCopy } from '../constants';

type AddGroupClassificationButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export default function AddGroupClassificationButton(
  props: AddGroupClassificationButtonProps
) {
  const { onClick, disabled } = props;

  return (
    <NewGroupClassificationsButton>
      <Button
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        startIcon={<AddIcon />}
      >
        {classificationGroupButtonCopy}
      </Button>
    </NewGroupClassificationsButton>
  );
}
