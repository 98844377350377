import { Role, RoleAccessKeys } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';
import { sqlDataTemplate as angularJsTemplate } from '../templates';

export const appActivConnect: IRoute = {
  name: 'app.activConnect',
  stateDefinition: {
    url: '/integrations/connect',
    template: angularJsTemplate,
    controller: 'SqlDataCtrl',
    data: {
      pageTitle: 'API & Integrations - ActivConnect API',
      hideNotifications: true
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Integrations,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin, Role.User],
    read: []
  }
};

export default appActivConnect;
