import { PlanType } from '../../common/enums/PlanType';
import { PrivacyLevels } from '../enums/privacyLevels';
import { IPrivacyLevel } from '../models/IPrivacyLevelSettings';

export const PRIVACY_LEVEL_OPTIONS: IPrivacyLevel[] = [
  {
    value: PrivacyLevels.Basic,
    id: 'a5ae8cac-a771-48ba-a75e-9167c8295f43',
    title: 'Basic Details',
    description:
      'Includes application name, website domains (e.g., activtrak.com), time spent, category, and productivity status for each activity',
    allowed: [
      PlanType.Advanced,
      PlanType.Premium,
      PlanType.EssentialsPlus,
      PlanType.Professional
    ]
  },
  {
    value: PrivacyLevels.Advanced,
    id: 'ad56f031-19b1-4bc1-a1f9-2d1d6f543896',
    title: 'Advanced Details',
    description:
      'Includes all Basic Details plus activity details - full website URLs (e.g. activtrak.com/support/#signature-support) and title bars ("ActivTrak Support")',
    allowed: [PlanType.EssentialsPlus, PlanType.Professional]
  },
  {
    value: PrivacyLevels.Full,
    id: 'f5e104c5-fad3-443b-a3cd-dc743ae76d3f',
    title: 'Full Details',
    description:
      'Includes all Advanced Details plus screenshots, screen views, pop-up alarms, and application termination',
    allowed: [PlanType.Advanced, PlanType.Premium]
  }
];

export const LOAD_SAVED_SETTING_ERROR: string =
  'Unable to load Sensitive Data Visibility setting value';
export const SAVE_SETTING_SUCCESS: string =
  'Success: Sensitive Data Visibility setting has been updated!';
export const SAVE_SETTING_ERROR: string =
  'Unable to save Sensitive Data Visibility setting value';
