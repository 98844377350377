import React, { useState, useEffect } from 'react';
import { useGroups } from '../../common/services/Groups';
import { IGroupSettings } from '../models';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { IconButton, List, ListItemButton, Popover } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ForceUpdateGroupsSettingsDialog from './ForceUpdateGroupsSettingsDialog';

export default function GroupsSettings() {
  const [saving, setSaving] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { groupsState } = useGroups();
  const {
    groupsSettings,
    getGroupsSettings,
    updateGroupsSettings,
    forceUpdateGroupsSettings,
    resetForceUpdateGroupsSettings
  } = groupsState;

  const authorizationService = useAuthorization();
  const [isAuthorized] = useState<boolean>(
    authorizationService.hasAnyRole([
      Role.SuperAdmin,
      Role.Admin,
      Role.SupportAdvanced,
      Role.SupportIntermediate
    ])
  );

  useEffect(() => {
    if (isAuthorized) {
      getGroupsSettings();
    }
  }, [getGroupsSettings, isAuthorized]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const toggleAdGroupsVisibleClick = () => {
    handleUpdate(false);
  };

  const handleUpdate = async (forceUpdate: boolean): Promise<void> => {
    setSaving(true);
    const settingsNew: IGroupSettings = { ...groupsSettings };
    settingsNew.adGroupsVisible = !settingsNew.adGroupsVisible;
    await updateGroupsSettings(settingsNew, forceUpdate);
    setSaving(false);
  };

  const handleForceUpdateClose = () => {
    resetForceUpdateGroupsSettings();
  };

  return (
    isAuthorized && (
      <>
        <IconButton
          onClick={handleClick}
          disabled={groupsSettings === undefined}
        >
          <SettingsIcon />
        </IconButton>
        {groupsSettings === undefined ? (
          <></>
        ) : (
          <>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <List component="nav">
                <ListItemButton
                  onClick={toggleAdGroupsVisibleClick}
                  disabled={saving}
                >
                  {groupsSettings?.adGroupsVisible ? 'Disable' : 'Enable'}{' '}
                  Active Directory Groups
                </ListItemButton>
              </List>
            </Popover>
            <ForceUpdateGroupsSettingsDialog
              open={forceUpdateGroupsSettings}
              onClose={handleForceUpdateClose}
              onUpdate={handleUpdate}
            />
          </>
        )}
      </>
    )
  );
}
