import { styled } from '@mui/material/styles';
import { gray4, primaryFontSize } from '../../common/constants';
import FormControlLabel from '@mui/material/FormControlLabel';

export const FormControlLabelSC = styled(FormControlLabel)`
  margin: 10px 0px;
`;

export const dntStyles = {
  doNotTrackWarning: {
    paddingTop: '15px'
  },
  tableContainer: {
    padding: '10px 0 10px 0'
  },
  cellSource: {
    fontWeight: '350' as any
  },
  cellDest: {},
  table: {},
  tableHeader: {
    fontSize: primaryFontSize as any,
    fontWeight: 'normal'
  },
  mergeTimingLabel: {
    fontSize: '13px',
    paddingTop: '10px'
  },
  scheduledMergesModalHeader: {
    paddingLeft: '24px',
    paddingTop: '15px',
    verticalAlign: 'middle',
    display: 'flex',
    textAlign: 'center',
    fontWeight: 'bolder',
    lineHeight: '24px',
    paddingBottom: '5px'
  },
  cellSourceIndent: {
    fontWeight: '350' as any,
    paddingLeft: '30px'
  },
  deleteButton: {
    border: `1px solid ${gray4}`
  },
  EmptyCell: {
    textAlign: 'center',
    padding: '15px 0 15px 0'
  }
};
