import theme from '../../theme/theme';

export const customSelectClasses = {
  error: {
    position: 'absolute',
    top: 'calc(100% - 4px)',
    margin: '3px 0 0'
  },
  required: {
    position: 'absolute',
    top: `calc(50% - ${theme.spacing()})`,
    right: theme.spacing(),
    color: theme.palette.text.disabled,
    '&.required-error': {
      color: theme.palette.error.main
    }
  },
  formControl: {
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      height: theme.spacing(7)
    },
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '18.5px 14px'
    },
    '& .MuiFormLabel-root': {
      color: '#adadad',
      fontWeight: 300
    }
  },
  select: {
    color: 'black',
    '&:not([multiple]) option': {},
    backgroundColor: 'white !important'
  }
};
