import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appAccountWebhook: IRoute = {
  name: 'app.account.webhook',
  stateDefinition: {
    url: '/account/webhooks',
    // template: angularJsTemplate,
    // controller: 'WebhookLogCtrl'
    template: '<webhook-log-report></webhook-log-report>',
    data: {
      pageTitle: 'External Notifications Log',
      filter: {
        paidFunctionalityEnabled: true
      }
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsNotifications,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User],
    bundleFlags: [BundleFlag.NotificationsLog]
  }
};

export default appAccountWebhook;
