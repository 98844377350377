import { SaveExportType } from '../../_global/modals/enums/SaveExportType';
import { ISaveExportTypeModalContent } from '../../_global/modals/models/ISaveExportModalContent';

export const SAVE_EXPORT_MODAL_CONTENT: ISaveExportTypeModalContent[] = [
  {
    key: SaveExportType.Local,
    description:
      'Your data has been successfully exported! Complete your export by downloading the file to your device.',
    primaryBtnText: 'Download file to device'
  },
  {
    key: SaveExportType.Google,
    description: 'Your data has been successfully exported to Google Drive!',
    primaryBtnText: 'View in Google Drive'
  }
];
