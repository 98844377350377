import { styled } from '@mui/material/styles';
import { mediumFontWeight } from '../../common/constants';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export const ViewHeaderNameContainer = styled(Box)`
  font-weight: ${mediumFontWeight};
`;

export const GroupHeaderTitleContainer = styled(Box)`
  font-size: 16px;
  font-weight: ${mediumFontWeight};
  margin-bottom: 20px;
`;

export const ParamContainer = styled(Box)`
  margin: 10px 0px;
`;

export const GroupContainer = styled(Card)`
  max-width: 34%;
  margin: 15px;
  margin-left: 0px;
  padding: 16px;
`;
