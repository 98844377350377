import React, { useEffect, useState } from 'react';
import { SuccessIcon } from '../../../common/assets/Icons/SuccessIcon';
import { Box, Button } from '@mui/material';
import { newEvent } from '../../../common/analytics/eventHeap';
import authorizationService from '../../../common/helpers/authorization';
import { useWizardContext } from '../../../common/components/Wizard/WizardProvider';
import { wizardClasses } from '../../../common/components/Wizard/Wizard.styles';
import { INSIGHTS_UPGRADE_PLAN_LABEL } from '../../../common/constants';
import { PRIVACY_SETTINGS_BY_LEVEL } from '../../../common/constants/privacySettings';
import { IPrivacySettings } from '../../../common/models/IPrivacySettings';
import { useAccountWizardContext } from '../../services';
import Link from '../../../common/components/Link';
import {
  AccountWizardTextContainer,
  SimpleHeader,
  SimpleMediumSubtitle
} from '../../styles';

import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';
import {
  getPrivacySettings,
  setPrivacySettings
} from '../../../common/hooks/privacySettingsStore';

type AccountWizardHandOff = {
  wizardStep?: boolean;
  noPadding?: boolean;
};
interface eventProperties {
  type?: 'individual' | 'grouped';
  'Total Use Cases': number;
  'Access Level': 'Team Level' | 'Individual Level' | 'Activity Level';
  'Privacy Mode': IPrivacySettings;
  'Configuration Screen': 'Signup' | 'Role Access Page';
  'Use-Case 1'?: 'Hybrid Work' | '';
  'Use-Case 2'?: 'Workforce Optimization' | '';
  'Use-Case 3'?: 'Employee Experience' | '';
  'Use-Case 4'?: 'Productivity Monitoring' | '';
  'Use-Case 5'?: 'Workplace Efficiency' | '';
  'Use-Cases'?: string;
}

const classes = {
  firstVisitSuccess: { maxWidth: '900px', margin: '0 auto' }
};

export function AccountWizardHandOff(props: AccountWizardHandOff) {
  const { noPadding } = props;

  const history = useUIRouterHistory();
  const {
    firstVisit,
    formValues,
    accountWizardService,
    wizardName,
    accountWizardReferrer
  } = useAccountWizardContext();
  const { wizardDone, setWizardDone } = useWizardContext();
  const [apiCallDone, setApiCallDone] = useState(false);

  const submitHeapEvent = (
    type: eventProperties['type'],
    event: Partial<eventProperties>
  ) => {
    const eventMessage = `Onboarding Wizard Submitted ${
      type === 'grouped' ? '(Comma Separated Use Case Report)' : ''
    }`;

    try {
      newEvent(eventMessage, event);
    } catch (error) {
      console.error(
        `Cannot send heap event: ${eventMessage}: ${event} Error: ${error}`
      );
    }
  };

  const sendWizardHeapEvents = (filterSteps: (step: string) => string[]) => {
    const namedWizardSelections = {
      UseCase: {
        '0:0:remote': 'Hybrid Work',
        '0:1:optimizeworkforce': 'Workforce Optimization',
        '0:2:wellbeing': 'Employee Experience',
        '0:3:monitor': 'Productivity Monitoring',
        '0:4:techusageroi': 'Workplace Efficiency'
      }
    };
    const parseUseCases: () => string[] = () => {
      const result: string[] = [];
      filterSteps('0').map((useCase) => {
        if (namedWizardSelections['UseCase'][useCase]) {
          result.push(namedWizardSelections['UseCase'][useCase]);
        }
      });
      return result;
    };

    const groupedUseCases = parseUseCases();

    const totalUseCases: eventProperties['Total Use Cases'] =
      groupedUseCases.length;

    const accessLevel: () =>
      | 'Team Level'
      | 'Individual Level'
      | 'Activity Level' = () => {
      const stepTwoSelections: string[] = filterSteps('1');

      if (stepTwoSelections.includes('1B:1:3:specifics')) {
        return 'Activity Level';
      } else if (stepTwoSelections.includes('1B:0:2:none')) {
        return 'Individual Level';
      } else {
        return 'Team Level';
      }
    };

    const baseEvent: Partial<eventProperties> = {
      'Total Use Cases': totalUseCases,
      'Access Level': accessLevel(),
      'Privacy Mode': privacySettingsResult,
      'Configuration Screen': accountWizardReferrer
    };

    const individualEvent: Partial<eventProperties> = {
      ...baseEvent,
      'Use-Case 1':
        (groupedUseCases.includes('Hybrid Work') && 'Hybrid Work') || '',
      'Use-Case 2':
        (groupedUseCases.includes('Workforce Optimization') &&
          'Workforce Optimization') ||
        '',
      'Use-Case 3':
        (groupedUseCases.includes('Employee Experience') &&
          'Employee Experience') ||
        '',
      'Use-Case 4':
        (groupedUseCases.includes('Productivity Monitoring') &&
          'Productivity Monitoring') ||
        '',
      'Use-Case 5':
        (groupedUseCases.includes('Workplace Efficiency') &&
          'Workplace Efficiency') ||
        ''
    };

    const groupedEvent: Partial<eventProperties> = {
      ...baseEvent,
      'Use-Cases': groupedUseCases.join()
    };

    submitHeapEvent('grouped', groupedEvent);
    submitHeapEvent('individual', individualEvent);
  };

  // CHECKS FOR STORED PRIVACY SETTINGS
  const storedPrivacySettings = getPrivacySettings();

  const getChosenPrivacyLevel = (arr: string[]): string => {
    return arr.find((value) => value.includes('details'));
  };

  const selectedPrivacyLevel: string = getChosenPrivacyLevel(
    formValues.PageSelection
  );

  // FALLBACK TO STORED PRIVACY SETTINGS IF NO PRIVACY LEVELS ARE CHOSEN OR IF STEP 3 IS BYPASSED
  const privacySettingsResult: IPrivacySettings =
    PRIVACY_SETTINGS_BY_LEVEL[selectedPrivacyLevel] || storedPrivacySettings;

  useEffect(() => {
    if (formValues.PageSelection.length > 0 && !wizardDone) {
      const currentSelections = formValues.PageSelection.join(',');
      if (
        currentSelections.includes('0:0:remote') ||
        (currentSelections.includes('0:3:monitor') &&
          !currentSelections.includes('1B:1:3:specifics'))
      ) {
        const specificsFormValues = {
          PageSelection: [...formValues.PageSelection, '1B:1:3:specifics']
        };
        accountWizardService
          .submitWizard(specificsFormValues, firstVisit)
          .finally(() => {
            setApiCallDone(true);
          });
      } else {
        accountWizardService
          .submitWizard(formValues, firstVisit)
          .finally(() => {
            setApiCallDone(true);
          });
      }

      setWizardDone(true);
      const filterSteps: (step: string) => string[] = (step: string) =>
        formValues.PageSelection.filter((selection) =>
          selection.split(':')[0].includes(step)
        );

      setPrivacySettings(privacySettingsResult);
      sendWizardHeapEvents(filterSteps);
    }
  });

  const handleFinish = () => {
    if (firstVisit) {
      //Using location for now to force a refresh
      window.location.href = '/#/app/agentactivation';
    } else {
      history.push('app.settings.roleaccess');
    }
  };

  return (
    <Box>
      <AccountWizardTextContainer padding={noPadding ? 'trim' : ''}>
        <Box sx={firstVisit ? classes.firstVisitSuccess : {}}>
          <SimpleHeader style={{ textAlign: firstVisit ? 'left' : 'center' }}>
            {' '}
            <SuccessIcon />
            {firstVisit
              ? `Success! Your trial of ActivTrak ${INSIGHTS_UPGRADE_PLAN_LABEL} begins now.`
              : 'Success! Your account has been customized.'}
          </SimpleHeader>
          <SimpleMediumSubtitle
            style={{ textAlign: firstVisit ? 'left' : 'center' }}
            noPadding
          >
            {firstVisit ? (
              <>
                Enjoy access to {INSIGHTS_UPGRADE_PLAN_LABEL} features and 100
                user licenses for 14 days. To relaunch Guided Account Setup
                during your trial, visit the Role Access page in Settings
                <br></br>
                <br></br>
                Here are a couple of resources to help you get started and learn
                your way around:
                <ul>
                  <li>
                    <a
                      href="https://support.activtrak.com/hc/en-us/articles/360034996271-Essential-Steps-for-Getting-Started-with-ActivTrak"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Getting Started Guide
                    </a>
                  </li>
                  <li>
                    {authorizationService.hasRouteByName('app.help.academy') ? (
                      <Link to="app.help.academy">ActivTrak Academy</Link>
                    ) : (
                      <a
                        href="https://www.activtrak.com/academy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ActivTrak Academy
                      </a>
                    )}
                  </li>
                </ul>
              </>
            ) : (
              'To relaunch this guided account setup, visit the Role Access page in Settings'
            )}
          </SimpleMediumSubtitle>
          <br></br>
          {apiCallDone || !formValues.PageSelection.length ? (
            <Button
              onClick={handleFinish}
              color="primary"
              variant="contained"
              sx={{
                ...wizardClasses.wizardBtn,
                ...wizardClasses.wizardPrimaryColor
              }}
              id={`click-success-${wizardName}`}
            >
              {firstVisit ? 'Continue →' : 'Return to Role Access →'}
            </Button>
          ) : (
            'Saving your selections...'
          )}
        </Box>
      </AccountWizardTextContainer>
    </Box>
  );
}
