// New react component that creates a full width box with a title and paragraph text

import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Link from '../../common/components/Link';
import { formatOnboardingTimeDateStrings } from '../utils/OnboardingMessage.utils';
import { IOnboardingTimeDate } from '../models/IOnboardingTimeDate';
import { IInsightsSetting } from '../models/IInsightsSetting';
import { ReactivTrakComponentWrapperNoPadding } from '../../ReactivTrakComponentWrapperNoPadding';

interface OnboardingMessageProps {
  insightsSetting: IInsightsSetting;
  showDownloadAgent: boolean;
}

export const OnboardingMessage = (props: OnboardingMessageProps) => {
  const {
    insightsSetting = {
      onBoardRequestTime: null,
      inProgress: false,
      schedulingDisabled: false
    },
    showDownloadAgent
  } = props;
  const { onBoardRequestTime, inProgress: isSyncInProgress } = insightsSetting;
  const [onBoardRequestStrings, setOnBoardRequestStrings] =
    React.useState<IOnboardingTimeDate>({});

  useEffect(() => {
    setOnBoardRequestStrings(
      formatOnboardingTimeDateStrings(onBoardRequestTime)
    );
  }, [onBoardRequestTime]);

  return (
    <ReactivTrakComponentWrapperNoPadding>
      {isSyncInProgress && !showDownloadAgent && (
        <Alert
          severity="info"
          icon={false}
          sx={{
            justifyContent: 'center',
            '> div': { textAlign: 'center' },
            mb: 3
          }}
        >
          <Typography>
            Congratulations on unlocking additional data-informed productivity
            insights! We are currently processing your data. This process
            {onBoardRequestStrings.timeString &&
              onBoardRequestStrings.dateString &&
              ` started at ${onBoardRequestStrings.timeString} on ${onBoardRequestStrings.dateString} and`}{' '}
            typically takes 12-24 hours to complete. We will notify your account
            creator once it’s ready.
          </Typography>
        </Alert>
      )}
      {showDownloadAgent && (
        <Alert
          severity="info"
          icon={false}
          sx={{
            justifyContent: 'center',
            '> div': { textAlign: 'center' },
            mb: 3
          }}
        >
          <Typography>
            Congratulations on signing up for an ActivTrak Trial. To make the
            best use of your trial,{' '}
            <Link to="app.agentactivation">download and install agents</Link> on
            the machines you want to collect data and unlock new insights in
            12-24 hours.
          </Typography>
        </Alert>
      )}
    </ReactivTrakComponentWrapperNoPadding>
  );
};
