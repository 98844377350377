import {
  IPrivacySettings,
  IPrivacySettingsDto
} from '../models/IPrivacySettings';

export const mapPrivacySettingsDtoToPrivacySettings = (
  privacySettingsDto: IPrivacySettingsDto
): IPrivacySettings => ({
  screenshotsAllowed: privacySettingsDto.screenshots?.enabled ?? false,
  activityAllowed: privacySettingsDto.activity?.enabled ?? false,
  alarmActionsAllowed: privacySettingsDto.alarms?.enabled ?? false
});

export const mapPrivateModeToPrivacySettings = (
  privateMode: boolean = true
): IPrivacySettings => ({
  screenshotsAllowed: privateMode === false,
  activityAllowed: privateMode === false,
  alarmActionsAllowed: privateMode === false
});
