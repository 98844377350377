'use strict';

angular.module('app')
    .directive('adRotator', adRotator);

function adRotator() {
    return {
        restrict: 'E',
        scope: false,
        template: require('views/widgets/adRotator.html'),
        controller: adRotatorCtrl
    };
}

adRotatorCtrl.$inject = ['$scope', 'widgetsApiService'];

function adRotatorCtrl($scope, widgetsApiService) {
    widgetsApiService.getAdrotator().success(function (result) {
        $scope.image = result.image;
        $scope.url = result.url;
    });
}
