import React, { useCallback, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useLastUpdate, useTopCategoriesState } from '../../hooks';
import { ITopCategory, WidgetProps } from '../../models';
import { ChartCheckboxLegendColumns } from '../../../common/components/Chart';
import { LinkWithIcon } from '../../../common/components/Link/LinkWithIcon';
import { TemplateShadow, BaseLoadingView, ErrorMsgDisplay } from '../templates';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import {
  DONUT_BASE_CONFIG,
  PIE_BASE_CONFIG,
  checkAllValuesAreZero,
  updateLegendSelections
} from '../../utils';
import { errorMsg, lastUpdateText, widgetTitle } from '../../constants';
import { TopCategoriesModal } from './TopCategoriesModal';
import {
  CheckboxColors,
  CheckboxLabel
} from '../../../common/components/Chart/models/ChartCheckboxLegend.props';
import {
  aqua9,
  blue1,
  gray0,
  gray7,
  purple2,
  red2
} from '../../../common/constants';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { convertObjectToQs, getPieChartTooltip } from '../../../common/helpers';
import { LastUpdatedSC } from '../../styles/templates.styles';

export default function TopCategories(props: Readonly<WidgetProps>) {
  const { refreshTimestamp, reportFilters } = props;

  const [currentCategory, setCurrentCategory] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasZeroValues, setHasZeroValues] = useState<boolean>(false);

  const {
    topCategories,
    currentCategoryActivities,
    currentSelections,
    isLoading,
    isActivitiesLoading,
    isTileError,
    init,
    getCategoryActivities,
    updateChart,
    setCurrentSelections,
    isModalError
  } = useTopCategoriesState();

  const lastUpdate = useLastUpdate(topCategories?.lastUpdated);
  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery, refreshTimestamp]
  );

  useEffect(() => {
    if (
      topCategories?.chartData?.datasets?.length &&
      topCategories.chartData.datasets[0].data
    ) {
      const values = topCategories.chartData.datasets[0].data.map(
        (item) => item.percent
      );
      if (!values?.length) {
        setHasZeroValues(true);
      } else if (values?.length) {
        const hasNoData = checkAllValuesAreZero(values);
        setHasZeroValues(hasNoData);
      }
    }
  }, [topCategories?.chartData.datasets]);

  const handleGetCategory = useCallback(
    () => {
      getCategoryActivities(reportFilters, currentCategory);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCategory, getCategoryActivities, primaryFiltersQuery]
  );

  const handleLegendClick = useCallback(
    (value) => {
      const newSelectionList = updateLegendSelections(value, currentSelections);
      setCurrentSelections(newSelectionList);
      updateChart(newSelectionList);
    },
    [currentSelections, setCurrentSelections, updateChart]
  );

  const handleClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const getTooltipTitle = useCallback(() => {
    let str = 'Top Sites and Applications';
    if (!isActivitiesLoading) {
      if (currentCategoryActivities?.category)
        str += ` - ${currentCategoryActivities.category}`;
      if (currentCategoryActivities?.totalDuration)
        str += ` (${currentCategoryActivities.totalDuration})`;
    }
    return str;
  }, [
    currentCategoryActivities?.category,
    currentCategoryActivities?.totalDuration,
    isActivitiesLoading
  ]);

  const pieChartColorChange = (context) => {
    const darkColors = [blue1, purple2, aqua9, red2];
    const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
    return darkColors.includes(backgroundColor) ? gray0 : gray7;
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        ...PIE_BASE_CONFIG.datalabels,
        color: pieChartColorChange,
        align: 'center',
        anchor: 'center',
        formatter: (item: ITopCategory) => {
          const percent = item?.percent;
          return percent && percent > 7 ? percent.toFixed() + '%' : null;
        },
        display: !hasZeroValues
      },
      tooltip: {
        ...DONUT_BASE_CONFIG.tooltip,
        borderWidth: 0,
        cornerRadius: 4,
        displayColors: false,
        bodyColor: '#fff',
        backgroundColor: (tooltipItem) => {
          return tooltipItem.tooltip.labelColors[0].backgroundColor;
        },
        callbacks: {
          label: function (context) {
            let label = context.label;
            const itemRaw = context.raw;

            if (!label) label = 'Unknown';

            return `${itemRaw?.duration} - ${label}`;
          },
          labelTextColor: pieChartColorChange
        },
        enabled: false,
        external: function (context) {
          const tooltipId = 'category-tooltip';
          let tooltipEl = document.getElementById(tooltipId);
          const tooltipModel = context.tooltip;

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl = getPieChartTooltip(context, tooltipId);
        }
      }
    },
    responsive: true,
    onClick: (event, elements, chart) => {
      if (elements[0]) {
        const i = elements[0].index;
        setCurrentCategory(chart.data.datasets[0].data[i].id);
        setIsOpen(!isOpen);
      }
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? 'pointer'
        : 'default';
    },
    //this allows custom properties to be included in the dataset
    //https://www.chartjs.org/docs/latest/general/data-structures.html#object-using-custom-properties
    parsing: {
      key: 'percent'
    }
  };

  const legendLabels: CheckboxLabel[] = topCategories?.chartData?.labels?.map(
    (item: string, i) => {
      return {
        name: item,
        value: i,
        id: `6215ac1d-217d-456e-87f0-c6b569281a67-${item?.replaceAll(' ', '-')}`
      };
    }
  );

  const checkboxColors: CheckboxColors[] =
    topCategories?.chartData?.datasets[0]?.backgroundColor?.map((item, i) => {
      return {
        checkboxColor: item,
        checkmarkColor: topCategories?.chartData?.datasets[0].checkmarkColor[i]
      };
    });

  return (
    <TemplateShadow
      sx={{
        height: { xs: '100%', sm: '450px' }
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <WidgetLinkWrapper>
          <WidgetLinkTypography variant="h4">
            {widgetTitle.topCategories}
          </WidgetLinkTypography>
        </WidgetLinkWrapper>
        <Box>
          <LinkWithIcon
            text="Top Categories"
            redirectTo={'app.reports.topcategories'}
            redirectParams={{ range: 'Today' }}
            disabled={!topCategories?.chartData?.labels?.length}
            id="4ec7e415-6fef-4009-bd70-5b448784223f"
          />
        </Box>
      </Stack>
      {!isLoading && !isTileError && (
        <>
          <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <Box
              sx={{
                margin: '16px auto 8px',
                width: { xs: '210px', md: '130px', lg: '190px' }
              }}
            >
              <Chart
                type="pie"
                data={topCategories?.chartData}
                options={chartOptions as any}
                plugins={[ChartDataLabels]}
                id="df26093d-3d47-4f73-a401-025e9d4a5250"
              />
            </Box>
            <TopCategoriesModal
              height="450px"
              loaderHeight="90%"
              data={currentCategoryActivities?.activities}
              title={getTooltipTitle()}
              isOpen={isOpen}
              isLoading={isActivitiesLoading}
              onInit={handleGetCategory}
              onClose={handleClose}
              isModalError={isModalError}
              id="c1d4617f-e042-4fb5-99fd-e270eacb358d"
            />
            {topCategories?.chartData?.labels?.length > 0 &&
              topCategories?.chartData?.datasets?.length > 0 && (
                <Box
                  sx={{
                    margin: '0 auto',
                    width: { xs: '300px', md: '200px', lg: '300px' }
                  }}
                >
                  <ChartCheckboxLegendColumns
                    labels={legendLabels}
                    colors={checkboxColors}
                    currentSelections={currentSelections}
                    onClick={handleLegendClick}
                    columnWidth="50%"
                  />
                </Box>
              )}
          </Box>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
