'use strict';

angular.module('app').service('constantsService', ConstantsService);

function ConstantsService() {
    var constantsServiceFunctions = require('_app/serviceFunctions/constantsServiceFunctions.js');
    var colors = [
        { name: 'COLOR-MASTER-DARKEST', hex: '#121212', rgb: 'rgb(18,18,18)' },
        { name: 'COLOR-MASTER-DARKER', hex: '#1A1A1A', rgb: 'rgb(26,26,26)' },
        { name: 'COLOR-MASTER-DARK', hex: '#2C2C2C', rgb: 'rgb(44,44,44)' },
        { name: 'COLOR-MASTER', hex: '#404242', rgb: 'rgb(64,66,66)' },
        { name: 'COLOR-MASTER-LIGHT', hex: '#E6E6E6', rgb: 'rgb(230,230,230)' },
        {
            name: 'COLOR-PRIMARY-LIGHTER',
            hex: '#F0F0F0',
            rgb: 'rgb(240,240,240)'
        },
        {
            name: 'COLOR-PRIMARY-LIGHTEST',
            hex: '#FAFAFA',
            rgb: 'rgb(250,250,250)'
        },
        { name: 'COLOR-PRIMARY-DARKER', hex: '#413768', rgb: 'rgb(65,55,104)' },
        { name: 'COLOR-PRIMARY-DARK', hex: '#584B8D', rgb: 'rgb(88,75,141)' },
        { name: 'COLOR-PRIMARY', hex: '#6D5CAE', rgb: 'rgb(109,92,174)' },
        {
            name: 'COLOR-PRIMARY-LIGHT',
            hex: '#8A7DBE',
            rgb: 'rgb(138,125,190)'
        },
        {
            name: 'COLOR-PRIMARY-LIGHTER',
            hex: '#E2DEEF',
            rgb: 'rgb(226,222,239)'
        },
        {
            name: 'COLOR-COMPLETE-DARKER',
            hex: '#2B6A94',
            rgb: 'rgb(43,106,148)'
        },
        { name: 'COLOR-COMPLETE-DARK', hex: '#3A8FC8', rgb: 'rgb(58,143,200)' },
        { name: 'COLOR-COMPLETE', hex: '#48B0F7', rgb: 'rgb(72,176,247)' },
        {
            name: 'COLOR-COMPLETE-LIGHT',
            hex: '#6DC0F9',
            rgb: 'rgb(109,192,249)'
        },
        {
            name: 'COLOR-COMPLETE-LIGHTER',
            hex: '#DAEFFD',
            rgb: 'rgb(218,239,253)'
        },
        {
            name: 'COLOR-SUCCESS-DARKER',
            hex: '#0A7C71',
            rgb: 'rgb(10,124,113)'
        },
        { name: 'COLOR-SUCCESS-DARK', hex: '#0DA899', rgb: 'rgb(13,168,153)' },
        { name: 'COLOR-SUCCESS', hex: '#10CFBD', rgb: 'rgb(16,207,189)' },
        { name: 'COLOR-SUCCESS-LIGHT', hex: '#40D9CA', rgb: 'rgb(64,217,202)' },
        {
            name: 'COLOR-SUCCESS-LIGHTER',
            hex: '#CFF5F2',
            rgb: 'rgb(207,245,242)'
        },
        {
            name: 'COLOR-WARNING-DARKER',
            hex: '#957D32',
            rgb: 'rgb(149,125,50)'
        },
        { name: 'COLOR-WARNING-DARK', hex: '#C9A843', rgb: 'rgb(201,168,67)' },
        { name: 'COLOR-WARNING', hex: '#F8D053', rgb: 'rgb(248,208,83)' },
        {
            name: 'COLOR-WARNING-LIGHT',
            hex: '#F9D975',
            rgb: 'rgb(249,217,117)'
        },
        {
            name: 'COLOR-WARNING-LIGHTER',
            hex: '#FEF6DD',
            rgb: 'rgb(254,246,221)'
        },
        { name: 'COLOR-DANGER-DARKER', hex: '#933432', rgb: 'rgb(147,52,50)' },
        { name: 'COLOR-DANGER-DARK', hex: '#C64643', rgb: 'rgb(198,70,67)' },
        { name: 'COLOR-DANGER', hex: '#F55753', rgb: 'rgb(245,87,83)' },
        { name: 'COLOR-DANGER-LIGHT', hex: '#F77975', rgb: 'rgb(247,121,117)' },
        {
            name: 'COLOR-DANGER-LIGHTER',
            hex: '#FDDDDD',
            rgb: 'rgb(253,221,221)'
        },
        { name: 'AT-DANGER-BRIGHT', hex: '#ff433f', rgb: 'rgb(255, 67, 63)' },
        { name: 'COLOR-INFO-DARKER', hex: '#232B31', rgb: 'rgb(35,43,49)' },
        { name: 'COLOR-INFO-DARK', hex: '#303A42', rgb: 'rgb(48,58,66)' },
        { name: 'COLOR-INFO', hex: '#3B4752', rgb: 'rgb(59,71,82)' },
        { name: 'COLOR-INFO-LIGHT', hex: '#626C75', rgb: 'rgb(98,108,117)' },
        { name: 'COLOR-INFO-LIGHTER', hex: '#D8DADC', rgb: 'rgb(216,218,220)' },
        { name: 'COLOR-MENU-DARK', hex: '#21252D', rgb: 'rgb(33,37,45)' },
        { name: 'COLOR-MENU', hex: '#2B303B', rgb: 'rgb(43,48,59)' },
        { name: 'COLOR-MENU-LIGHT', hex: '#788195', rgb: 'rgb(120,129,149)' },
        { name: 'AT-STANDARD-WHITE', hex: '#FFFFFF', rgb: 'rgb(255,255,255)' }
    ];

    var colorTypes = [
        { type: 'Menu Options', color: 'COLOR-MASTER-DARKEST' },
        { type: 'Menu', color: 'COLOR-MASTER-DARKER' },
        { type: 'Text heading', color: 'COLOR-MASTER-DARK' },
        { type: 'Default text', color: 'COLOR-MASTER' },
        { type: 'Dividers', color: 'COLOR-MASTER-LIGHT' },
        { type: 'Cover page', color: 'COLOR-MASTER-LIGHT' },
        { type: 'Background', color: 'COLOR-MASTER-LIGHTEST' },
        { type: 'Menu open color', color: 'COLOR-MENU-DARK' },
        { type: 'Menu base color', color: 'COLOR-MENU' },
        { type: 'Menu text/icon color', color: 'COLOR-MENU-LIGHT' },

        { type: 'Notifications text', colorSuffix: '-DARKER' },
        { type: 'Text color', colorSuffix: '-DARK' },
        { type: 'Base color', colorSuffix: '' },
        { type: 'Button overlay', colorSuffix: '-LIGHT' },
        { type: 'Background opt.', colorSuffix: '-LIGHTER' }
    ];

    var fonts = [
        { type: 'body', name: 'Arial' },
        { type: 'heading', name: 'Segeo UI' },
        { type: 'other', name: 'MONTSERRAT' }
    ];

    var fontSizes = [
        { name: 'xs', size: '12' },
        { name: 'sm', size: '14' },
        { name: 'md', size: '16' },
        { name: 'lg', size: '20' },
        { name: 'xl', size: '24' }
    ];

    var getFontName = function (fontType) {
        var font = _.find(fonts, function (f) {
            return f.type === fontType;
        });
        return (!font || !font.name ? fonts[0] : font).name;
    };

    var getFontSize = function (fontSize) {
        var fs;

        if (typeof fontSize === 'string') {
            fs = _.find(fontSizes, function (f) {
                return f.name === fontSize;
            });
        } else if (typeof fontSize === 'number') {
            fs = fontSize;
        }

        return !fs ? fontSizes[2].size : !fs.size ? fs : fs.size;
    };

    var constants = {
        colorArray: colors,
        color: function (colorName, format, colorType) {
            var type = _.find(colorTypes, function (t) {
                return t.type === colorType;
            });
            if (!!type) {
                colorName = !type.colorSuffix
                    ? type.color
                    : colorName + type.colorSuffix;
            }
            var color = _.find(colors, function (c) {
                return c.name === colorName;
            });
            format = format || 'hex';

            if (!color) {
                return;
            }

            return color[format];
        },
        font: function (fontType) {
            return getFontName(fontType);
        },
        fontSize: function (fontSize) {
            return getFontSize(fontSize);
        },
        fontString: function (fontType, fontSize) {
            return getFontSize(fontSize) + 'px ' + getFontName(fontType);
        },
        countries: constantsServiceFunctions.countries,
        states: constantsServiceFunctions.states
    };

    this.get = function (constantName) {
        var constant = constants[constantName];
        return typeof constant === 'function'
            ? constant.apply(this, Array.prototype.slice.call(arguments, 1))
            : constant;
    };
}
