import { apiService } from '../../common/helpers';
import { getDefaultConfig, getWebApiConfig } from '../../common/config';
import {
  IActivityDtoApiResponse,
  IMultipleResultsResponse
} from '../../common/models';
import { MemberType } from '../enums';
import {
  IGroupService,
  IGroupMember,
  IGroupMembersDtoApiResponse,
  IMemberDtoApiResponse
} from '../models';

export const groupService = (): IGroupService => {
  return {
    fetchGroup: async (
      groupId: number,
      params?: object
    ): Promise<IGroupMembersDtoApiResponse> => {
      try {
        const response = await apiService.get<IGroupMembersDtoApiResponse>(
          `/admin/v1/groups/${groupId}/members`,
          {
            ...getWebApiConfig(),
            params: params
          }
        );

        return response?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    fetchGroupActivities: async (
      groupId: number = null
    ): Promise<IActivityDtoApiResponse[]> => {
      try {
        const response = await apiService.get<IActivityDtoApiResponse[]>(
          `/api/classification/group-activity/${groupId}`,
          {
            ...getDefaultConfig()
          }
        );
        return response?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    fetchClientMembers: async (): Promise<IMemberDtoApiResponse[]> => {
      try {
        return (
          await apiService.get<IMemberDtoApiResponse[]>(`/admin/v1/clients`, {
            ...getWebApiConfig()
          })
        )?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    fetchDeviceMembers: async (): Promise<IMemberDtoApiResponse[]> => {
      try {
        return (
          await apiService.get<IMemberDtoApiResponse[]>(`/admin/v1/devices`, {
            ...getWebApiConfig()
          })
        )?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    addClientMembers: async (
      groupId: number,
      members: number[]
    ): Promise<any> => {
      try {
        return await apiService.post<IMultipleResultsResponse<number>>(
          `/admin/v1/groups/${groupId}/clients`,
          {
            ...getWebApiConfig(),
            data: { clientIds: members }
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    addDeviceMembers: async (
      groupId: number,
      members: number[]
    ): Promise<any> => {
      try {
        return await apiService.post<IMultipleResultsResponse<number>>(
          `/admin/v1/groups/${groupId}/devices`,
          {
            ...getWebApiConfig(),
            data: { deviceIds: members }
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    deleteMembers: async (
      groupId: number,
      members: IGroupMember[]
    ): Promise<void> => {
      const clientIdsToRemove = members
        .filter((x) => x.type === MemberType.User)
        .map((x) => x.id);
      const deviceIdsToRemove = members
        .filter((x) => x.type === MemberType.Computer)
        .map((x) => x.id);

      if (!clientIdsToRemove.length && !deviceIdsToRemove.length) {
        throw new Error('No members to remove');
      }

      const removeClients = async () => {
        return await apiService.delete<IMultipleResultsResponse<number>>(
          `/admin/v1/groups/${groupId}/clients`,
          {
            ...getWebApiConfig(),
            data: { clientIds: clientIdsToRemove }
          }
        );
      };
      const removeDevices = async () => {
        return await apiService.delete<IMultipleResultsResponse<number>>(
          `/admin/v1/groups/${groupId}/devices`,
          {
            ...getWebApiConfig(),
            data: { deviceIds: deviceIdsToRemove }
          }
        );
      };

      const promises = [];
      if (clientIdsToRemove.length) {
        promises.push(removeClients());
      }
      if (deviceIdsToRemove.length) {
        promises.push(removeDevices());
      }

      try {
        await Promise.all(promises);
      } catch (error) {
        throw new Error(error);
      }
    }
  };
};
