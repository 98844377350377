import { useCallback, useEffect, useState } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { ExtensionConfiguration } from '../models/ExtensionConfiguration';
import { IExtensionSettingsState } from '../models/IExtensionSettingsState';
import {
  defaultMacConfig,
  defaultWindowsConfig,
  extensionApi,
  macPlatform,
  windowsPlatform
} from '../constants/extension.const';
import _ from 'lodash';
import { fetchData, putData } from '../../common/helpers';
import {
  getBrowserConfigByName,
  initializeAllBrowsers,
  setExtensionEnabled,
  updatePlatformConfig
} from '../utils/browserextensions.utils';

export const useExtensionSettingsState = (): IExtensionSettingsState => {
  const notificationService = useNotifications();

  const [originalWindowsConfig, setOriginalWindowsConfig] =
    useState<ExtensionConfiguration>(null);
  const [originalMacConfig, setOriginalMacConfig] =
    useState<ExtensionConfiguration>(null);
  const [newWindowsConfig, setNewWindowsConfig] =
    useState<ExtensionConfiguration>(null);
  const [newMacConfig, setNewMacConfig] =
    useState<ExtensionConfiguration>(null);

  useEffect(() => {
    setNewWindowsConfig(originalWindowsConfig);
  }, [originalWindowsConfig]);

  useEffect(() => {
    setNewMacConfig(originalMacConfig);
  }, [originalMacConfig]);

  const setConfigs = (configs: ExtensionConfiguration[]) => {
    const winConfig = configs?.find(
      (config) => config.platform === windowsPlatform
    );
    if (winConfig) {
      setOriginalWindowsConfig(winConfig);
    }
    const macConfig = configs?.find(
      (config) => config.platform === macPlatform
    );
    if (macConfig) {
      setOriginalMacConfig(macConfig);
    }
  };

  const initState = useCallback(
    async () => {
      try {
        const response = await fetchData<Array<ExtensionConfiguration>>({
          path: extensionApi.fetchUrl
        });
        setConfigs(response);
      } catch (error) {
        notificationService.error('Unable to load Extension Settings');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpdate = async () => {
    try {
      if (JSON.stringify(originalMacConfig) !== JSON.stringify(newMacConfig)) {
        await putData<object>({
          path: extensionApi.putUrl,
          args: newMacConfig
        });
        setOriginalMacConfig(newMacConfig);
      }
      if (
        JSON.stringify(originalWindowsConfig) !==
        JSON.stringify(newWindowsConfig)
      ) {
        await putData<object>({
          path: 'api/agent/extensions/0',
          args: newWindowsConfig
        });
        setOriginalWindowsConfig(newWindowsConfig);
      }
      notificationService.success('Success:  Extension Settings were saved!');
    } catch (error) {
      notificationService.error('Unable to save Extension Settings');
    }
  };

  const toggleExtensionEnabled = (event, browser: string, platform: string) => {
    const platformConfig = getPlatformConfigOrDefault(platform);
    const newBrowserConfig = getBrowserConfigByName(browser, platformConfig);
    setExtensionEnabled(newBrowserConfig, event.target.checked);

    if (platform == windowsPlatform) {
      setNewWindowsConfig(platformConfig);
    } else {
      setNewMacConfig(platformConfig);
    }
  };

  const getPlatformConfigOrDefault = (platform: string) => {
    let platformConfig =
      platform == windowsPlatform
        ? _.cloneDeep(newWindowsConfig)
        : _.cloneDeep(newMacConfig);
    if (!platformConfig) {
      platformConfig =
        platform == windowsPlatform
          ? { ...defaultWindowsConfig }
          : { ...defaultMacConfig };
    }
    return platformConfig;
  };

  const toggleExtensionInstall = (event, browser: string, platform: string) => {
    //get windows or mac
    const platformConfig = getPlatformConfigOrDefault(platform);

    const updatedPlatformConfig = updatePlatformConfig(
      platformConfig,
      browser,
      platform,
      event.target.checked
    );

    if (platform == windowsPlatform) {
      setNewWindowsConfig(updatedPlatformConfig);
    } else {
      setNewMacConfig(updatedPlatformConfig);
    }
  };

  const toggleAllEnabled = (event, platform: string) => {
    const selectedPlatformConfig = getPlatformConfigOrDefault(platform);
    initializeAllBrowsers(selectedPlatformConfig);
    const enabled = event.target.checked;
    setExtensionEnabled(selectedPlatformConfig.opera, enabled);
    setExtensionEnabled(selectedPlatformConfig.chrome, enabled);
    setExtensionEnabled(selectedPlatformConfig.edge, enabled);
    setExtensionEnabled(selectedPlatformConfig.firefox, enabled);

    if (platform == windowsPlatform) {
      setNewWindowsConfig(selectedPlatformConfig);
    } else {
      setNewMacConfig(selectedPlatformConfig);
    }
  };

  return {
    originalWindowsConfig,
    originalMacConfig,
    initState,
    newWindowsConfig,
    newMacConfig,
    handleUpdate,
    toggleExtensionEnabled,
    toggleExtensionInstall,
    toggleAllEnabled
  };
};
