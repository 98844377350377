import React, {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProfileFormProps } from '../models';

import { primaryBlue } from '../../common/constants';

import {
  DEPARTMENT_OPTIONS,
  INFO_ICON_TEXT,
  ROLE_OPTIONS
} from '../constants/profileSettingsFormConstants';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';

import {
  Button,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  Box
} from '@mui/material';
import { IProfile } from '../../common/models';
import { ProfileSettingsModal } from './ProfileSettingsModal';

interface IBetterTextField extends HTMLInputElement {
  hasErrors?: () => boolean;
}

const BetterTextField = forwardRef<IBetterTextField, TextFieldProps>(
  (props, ref) => {
    const [error, setError] = useState(false);

    const inputRef = useRef<HTMLInputElement>();

    const checkForErrors = useCallback(() => {
      return props.required && inputRef.current.value === '';
    }, [props.required]);

    useImperativeHandle<IBetterTextField, any>(
      ref,
      () => {
        const mergedRef: IBetterTextField = inputRef.current;
        mergedRef.hasErrors = () => {
          const errors = checkForErrors();
          return errors;
        };
        return mergedRef;
      },
      [checkForErrors]
    );

    return (
      <TextField
        inputRef={inputRef}
        {...props}
        error={error || props.error}
        onChange={(e) => {
          const requiredError = checkForErrors();
          setError(requiredError);

          if (props.onChange) {
            props.onChange(e);
          }
        }}
        helperText={props.required && error && '* Required'}
      />
    );
  }
);

export const ProfileSettingsForm: React.FC<ProfileFormProps> = ({
  onSubmit,
  profile,
  onPasswordChange,
  savingInProgress
}) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);

  const authorizationService = useAuthorization();

  const firstNameRef = useRef<IBetterTextField>();
  const lastNameRef = useRef<IBetterTextField>();
  const phoneRef = useRef<HTMLInputElement>();
  const roleRef = useRef<HTMLInputElement>();
  const departmentRef = useRef<HTMLInputElement>();
  const digestRef = useRef<HTMLInputElement>();

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const payload: IProfile = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      phone: phoneRef.current.value,
      title: roleRef.current.value,
      industry: departmentRef.current.value,

      settingsDigest: {
        digest: digestRef.current.checked
      }
    };

    onSubmit(payload);
  };

  const isReadOnly = useMemo(() => {
    const hasSupportRoles: boolean = authorizationService?.hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced
    ]);
    return hasSupportRoles;
  }, [authorizationService]);

  const updateErrors = () => {
    const hasErrors =
      firstNameRef.current.hasErrors() || lastNameRef.current.hasErrors();
    setFormHasErrors(hasErrors);
  };

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="h6">Profile settings</Typography>
        <BetterTextField
          required
          variant="outlined"
          name="firstName"
          label="First Name"
          disabled={isReadOnly}
          ref={firstNameRef}
          defaultValue={profile.firstName}
          inputProps={{ maxLength: 50 }}
          onChange={updateErrors}
        />
        <BetterTextField
          required
          variant="outlined"
          name="lastName"
          label="Last Name"
          disabled={isReadOnly}
          ref={lastNameRef}
          defaultValue={profile.lastName}
          inputProps={{ maxLength: 50 }}
          onChange={updateErrors}
        />

        <TextField
          name="phone"
          variant="outlined"
          label="Phone Number"
          disabled={isReadOnly}
          inputRef={phoneRef}
          defaultValue={profile.phone}
          inputProps={{ maxLength: 15 }}
          onChange={(e) => {
            phoneRef.current.value = e.target.value?.replace(/\D/g, '');
          }}
        />

        <FormControl>
          <TextField
            select
            label="Role"
            disabled={isReadOnly}
            //TODO: this ref is can't change the value only read
            inputRef={roleRef}
            defaultValue={profile.title || ''}
          >
            <MenuItem value="">{'[ No role ]'}</MenuItem>
            {ROLE_OPTIONS.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl>
          <TextField
            select
            label="Department"
            disabled={isReadOnly}
            //TODO: this ref is can't change the value only read
            inputRef={departmentRef}
            defaultValue={profile.industry}
          >
            <MenuItem value="">{'[ No department ]'}</MenuItem>
            {DEPARTMENT_OPTIONS.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              name="settingsDigest"
              color="primary"
              disabled={isReadOnly}
              inputRef={digestRef}
              defaultChecked={profile.settingsDigest.digest}
            />
          }
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Enable weekly digest</span>
              <Tooltip title={INFO_ICON_TEXT}>
                <IconButton size="small">
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ color: primaryBlue }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          }
        />

        <Box display="flex" justifyContent="space-between" gap="16px">
          {!profile.ssoEnabled && (
            <Button
              variant="outlined"
              color="primary"
              disabled={isReadOnly}
              onClick={() => setPasswordModalOpen(true)}
            >
              Change password
            </Button>
          )}

          <Button
            variant={savingInProgress ? 'outlined' : 'contained'}
            color="primary"
            disabled={isReadOnly || savingInProgress || formHasErrors}
            onClick={(e) => handleFormSubmit(e)}
            style={{ width: '140px' }}
          >
            {savingInProgress ? 'Saving...' : '  Update profile'}
          </Button>
        </Box>
      </Stack>

      <ProfileSettingsModal
        modalOpen={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
        onPasswordChange={onPasswordChange}
        credentialsSettings={
          profile?.credentialsSettings ?? {
            password: { advancedSecurity: false }
          }
        }
      />
    </div>
  );
};
