import { useState, useEffect } from 'react';
import { getParsedLocalStorageItem } from '../helpers';

// This hook reduces the number of times 'getParsedLocalStorageItem' is called during render vs just using 'getParsedLocalStorageItem'
// Hooks only work within functional components
export const useParsedLocalStorage = (storageItem: string): any => {
  const [storageItems, setStorageItems] = useState<any>({});

  const getStorageItems = (storageItem: string) => {
    try {
      const storageValue = getParsedLocalStorageItem(storageItem);
      setStorageItems(storageValue);
    } catch (error) {
      setStorageItems({});
    }
  };

  useEffect(() => {
    getStorageItems(storageItem);
  }, [storageItem]);

  return { storageItems };
};
