import { Role } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appHelpTickets: IRoute = {
  name: 'app.help.tickets',
  stateDefinition: {
    url: '/help/tickets?id&subject',
    template: '<support-tickets></support-tickets>',
    params: { id: null, subject: null, description: null },
    data: { pageTitle: 'Support Tickets' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin, Role.User],
    read: [],
    featureFlags: [FeatureFlag.SupportTickets]
  }
};

export default appHelpTickets;
