import React from 'react';
import { ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { NestedFilterItemProps } from './NestedCheckboxFilter.props';
import { CheckboxItemSC } from './NesterCheckboxFilter.styles';

export const NestedFilterItem = (props: NestedFilterItemProps) => {
  const {
    label,
    selected,
    isIndeterminate,
    sx = {},
    onClick = () => {
      /* do nothing */
    }
  } = props;

  return (
    <CheckboxItemSC onClick={() => onClick()} sx={sx}>
      <ListItemIcon>
        <Checkbox
          color="primary"
          edge="start"
          checked={selected}
          tabIndex={-1}
          disableRipple
          indeterminate={isIndeterminate}
          inputProps={{
            'aria-labelledby': label
          }}
        />
      </ListItemIcon>
      <ListItemText primary={label} />
    </CheckboxItemSC>
  );
};
