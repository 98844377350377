import React from 'react';
import { ButtonsPlayground } from '../views/ButtonsPlayground';
import { InputFieldsPlayground } from '../views/InputFieldsPlayground';
import { AlertsPlayground } from '../views/AlertsPlayground';
import { IDevelopmentPages } from '../../common/models/IDevelopmentPages';
import IconsDemo from '../../common/assets/Icons/IconsDemo.view';

export const DESIGN_EXAMPLES: IDevelopmentPages[] = [
  {
    id: 'buttons',
    label: 'Button Demo',
    jsx: <ButtonsPlayground />
  },
  {
    id: 'inputs',
    label: 'Input Fields Demo',
    jsx: <InputFieldsPlayground />
  },
  {
    id: 'alerts',
    label: 'Alerts Demo',
    jsx: <AlertsPlayground />
  },
  {
    id: 'icons',
    label: 'Icons Demos',
    jsx: <IconsDemo />
  }
];
