import { useCallback, useState } from 'react';
import {
  IPassiveTimeSettings,
  IPassiveTimeSettingsState,
  PassiveTimeSettingsStateProps
} from '../models';

import { defaultPassiveTimeSettings } from '../constants';

export const usePassiveTimeSettingsState = ({
  service,
  notificationService
}: PassiveTimeSettingsStateProps): IPassiveTimeSettingsState => {
  const { fetchPassiveSettings, postPassiveSettings } = service;

  const [passiveTimeSettings, setPassiveTimeSetting] =
    useState<IPassiveTimeSettings>(defaultPassiveTimeSettings);
  const [isLoading, setLoading] = useState<boolean>(false);

  

  const getPassiveSettings = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchPassiveSettings();
      if (res?.data) {
        const data = res.data;
        if (JSON.stringify(data) !== JSON.stringify(passiveTimeSettings)) {
          setPassiveTimeSetting(data);
        }
      } else {
        throw new Error('No Data');
      }
    } catch (error) {
      notificationService.error('Unable to load Passive Time Settings');
      console.error(
        'ActivTrak Error: Unable to load Passive Time Settings',
        error
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passiveTimeSettings]);

  const init = useCallback(() => {
    getPassiveSettings();
  }, [getPassiveSettings]);

  const setPassiveSettings = async (payload: IPassiveTimeSettings) => {
    setLoading(true);
    try {
      await postPassiveSettings(payload);
      notificationService.success('Success! Passive Time Setting is updated!');
      setPassiveTimeSetting(payload);
    } catch (error) {
      notificationService.error('Unable to update Passive Time Settings');
      console.error(
        'ActivTrak Error: Unable to update Passive Time Settings',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return { passiveTimeSettings, isLoading, init, setPassiveSettings };
};
