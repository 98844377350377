import { IntegrationStateErrorCodes } from '../models/IIntegrationTypes';

export const IntegrationErrors: {
  [key in IntegrationStateErrorCodes]?: {
    errorMessage: string;
    showContactSupport: boolean;
  };
} = {
  [IntegrationStateErrorCodes.APIAuthenticationError]: {
    errorMessage:
      'Data was not received because required credentials were invalid.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.GetUserFailed]: {
    errorMessage:
      'An error occurred while retrieving the user from the source of record.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.CreateUserFailed]: {
    errorMessage:
      'An error occurred while creating the user from the source of record.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.DeleteIntegrationFailed]: {
    errorMessage: 'An error occurred while deleting the integration.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.EnableIntegrationFailed]: {
    errorMessage: 'An error occurred while enabling the integration.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.DisableIntegrationFailed]: {
    errorMessage: 'An error occurred while disabling the integration.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.CreateIntegrationFailed]: {
    errorMessage: 'An error occurred while creating the integration.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.GetIntegrationFailed]: {
    errorMessage:
      'An error occurred while retrieving the integration information from the source of record.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.GetIntegrationListFailed]: {
    errorMessage:
      'An error occurred while retrieving the list of integrations information from the source of record.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.GetAccountFailed]: {
    errorMessage:
      'An error occurred while retrieving the account from the source of record.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.AzureFetch]: {
    errorMessage: 'An error occurred during the Azure AD integration.',
    showContactSupport: true
  },

  [IntegrationStateErrorCodes.GCalFetch]: {
    errorMessage:
      'An error occurred while retrieving your Google calendar data.',
    showContactSupport: true
  },
  [IntegrationStateErrorCodes.OCalFetch]: {
    errorMessage:
      'An error occurred while retrieving your Outlook calendar data.',
    showContactSupport: true
  },
  [IntegrationStateErrorCodes.CalendarTokenExpired]: {
    errorMessage:
      'The calendar token has expired. Please refresh your credentials.',
    showContactSupport: false
  },
  [IntegrationStateErrorCodes.CalendarPermissionDenied]: {
    errorMessage:
      'Data was not received. Please verify credentials and assigned role, then delete and re-enable the integration.',
    showContactSupport: false
  },
  [IntegrationStateErrorCodes.NoFetchWarning48HoursSync]: {
    errorMessage: 'Integration has not fetched for more than 48 hours.',
    showContactSupport: true
  },
  [IntegrationStateErrorCodes.ConfigurationAuthorizationFailed]: {
    errorMessage: 'An error occurred while authorizing the configuration.',
    showContactSupport: true
  },
  [IntegrationStateErrorCodes.MissingField]: {
    errorMessage:
      'Data was not received because a required field was missing. Resend the CSV.',
    showContactSupport: false
  },
  [IntegrationStateErrorCodes.GroupBucketFailed]: {
    errorMessage:
      'Data was not received because top level group name was invalid or not provided. Delete, correct the configuration, and re-enable the integration.',
    showContactSupport: false
  },
  [IntegrationStateErrorCodes.Uncategorized]: {
    errorMessage: 'Something went wrong.',
    showContactSupport: true
  },
  [IntegrationStateErrorCodes.TwoDaySyncTimeout]: {
    errorMessage: 'Integration has not successfully initiated.',
    showContactSupport: true
  }
};

export enum IntegrationMessages {
  ProcessingIntegration = 'Your data is being processed. This may take up to 24 hours to complete.',
  ProcessingIntegration1Hr = 'Your data is being retrieved. This may take up to an hour to complete.',
  IdleMessage = 'An error occurred while configuring the integration. Please delete integration and try again.',
  UncategorizedMessage = 'Data was not received because  credentials were invalid. Please verify credentials, then delete and re-enable the integration.'
}
