import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const NoAPIKeysBox = styled(Box)`
  margin: auto;
  padding-top: 65px;
  width: 420px;
  height: 380px;
  border-radius: 4px;
  background: var(--light-background-paper, #fff);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
`;
