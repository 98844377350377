import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Routes } from '../../../common/constants';
import { durationFormat } from '../../../common/helpers';
import { getStringDurationFormat, BARCHART_BASE_CONFIG } from '../../utils';
import { TopUsersGroupsChartProps } from '../../models/ITopUsersGroupsTypes';
import { TopUsersContainer } from '../../styles/TopUsersGroups.styles';

export default function TopUsersGroupsChart(props: TopUsersGroupsChartProps) {
  const { chartData, widgetMode, filterMode } = props;

  const [lastStackIndex, setLastStackIndex] = useState<number>(null);
  const [chartOptions, setChartOptions] = useState<any>();

  const chartRef = useRef<Chart>();

  useEffect(() => {
    setLastStackIndex(filterMode?.length - 1);
  }, [filterMode]);

  const getPassiveTime = useCallback((context): string => {
    const passiveTime = context[0].dataset.passiveTimes[context[0].dataIndex];
    return getStringDurationFormat(passiveTime, false);
  }, []);

  const getBarPercentage = useCallback((context): string | 0 => {
    const totalTime = context.dataset.totals[context.dataIndex];
    const totalPercent = (value) =>
      value > 0 ? ((value / totalTime) * 100).toFixed(1) : 0;

    return totalPercent(context.raw);
  }, []);

  const handleBarClick = useCallback(
    (e, activeEls) => {
      if (e.type !== 'click') return;
      if (activeEls.length) {
        const user = chartData.labels[activeEls[0].index];
        if (widgetMode === 'groups') {
          window.location.href = `/#/app${Routes.TopUserByGroup(
            user
          )}&dateFilter=today&groupBy=groups&userFilter=allusers`;
        } else {
          window.location.href = `/#/app${Routes.TopUser(
            user
          )}&dateFilter=today&userFilter=allusers`;
        }
      }
    },
    [chartData.labels, widgetMode]
  );

  useEffect(() => {
    const options = {
      ...BARCHART_BASE_CONFIG.options,
      maintainAspectRatio: true,
      // use barPercentage/categoryPercentage AND (dynamic)canvas height to handle bar widths responsively
      barPercentage: 0.95,
      categoryPercentage: 0.95,
      layout: {
        padding: {
          right: 55,
          left: 6
        }
      },
      plugins: {
        legend: { ...BARCHART_BASE_CONFIG.legend },
        tooltip: {
          ...BARCHART_BASE_CONFIG.tooltip,
          callbacks: {
            title: () => '',
            label: (context) => {
              const percentage = getBarPercentage(context);
              const endLabel = `${context.dataset.label} ${durationFormat(
                context.raw,
                true
              )} `;
              return `${percentage}% ${endLabel}`;
            },
            footer: (context) => {
              const passiveTime = getPassiveTime(context);
              const footerText = `Includes ${passiveTime} of ${context[0].dataset.label} (Passive)`;
              return footerText;
            }
          }
        },
        datalabels: {
          ...BARCHART_BASE_CONFIG.dataLabels,
          formatter: (value, context) => {
            if (context.datasetIndex === lastStackIndex) {
              return getStringDurationFormat(
                context.dataset.totals[context.dataIndex],
                false
              );
            } else {
              return '';
            }
          }
        }
      },
      scales: {
        ...BARCHART_BASE_CONFIG.scales
      },
      onHover: BARCHART_BASE_CONFIG.onHover,
      onClick: (e, activeEls) => {
        return handleBarClick(e, activeEls);
      }
    };
    setChartOptions(options);
  }, [getBarPercentage, getPassiveTime, handleBarClick, lastStackIndex]);

  return (
    <>
      {chartData && (
        <TopUsersContainer>
          <Bar
            ref={chartRef as any}
            data={chartData as any}
            options={chartOptions}
            plugins={[ChartDataLabels]}
          />
        </TopUsersContainer>
      )}
    </>
  );
}
