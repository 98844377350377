import { useCallback, useState } from 'react';
import _ from 'lodash';
import {
  buildReportsApiRequest,
  checkIfNoResults,
  mapProdUsersTrendsData,
  mapUserProductivityAchievementModal
} from '../utils';
import {
  IProdUsersTrends,
  IProdUsersGoalState,
  IReportProductivityGoalModalListItem,
  IProdUsersGoalDto,
  IWeeklyUsers
} from '../models';
import { ApiRoutes } from '../constants';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useProductivityGoalUsersState = (): IProdUsersGoalState => {
  const [originalChartData, setOriginalChartData] =
    useState<IProdUsersTrends>();
  const [usersTrendsData, setUsersTrendsData] = useState<IProdUsersTrends>({
    chartData: {
      labels: [],
      datasets: [],
      weeklyUsers: []
    }
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [usersList, setUsersList] =
    useState<IReportProductivityGoalModalListItem[]>();
  const [allUsersByWeek, setAllUsersByWeek] = useState<IWeeklyUsers[]>();
  const [header, setHeader] = useState<number>(0);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);

  const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
    setIsLoading(true);
    setIsTileError(false);

    try {
      const response = await buildReportsApiRequest<IProdUsersGoalDto>({
        path: ApiRoutes.reports.fetchProductivityGoalUsersTrends,
        params: { groupId: reportFilters.groupId[0] }
      });
      const mapped = mapProdUsersTrendsData(response);

      setOriginalChartData(mapped);
      setUsersTrendsData(mapped);
      setAllUsersByWeek(mapped.chartData.weeklyUsers);

      if (response.users?.length) {
        const mappedUsers = mapUserProductivityAchievementModal(response.users);
        setUsersList(mappedUsers);
      }

      const noResults = checkIfNoResults(mapped.chartData.datasets);
      setHasNoResults(noResults);
      setHeader(mapped.percentageHeader);
    } catch (error) {
      console.error(`ActivTrak Error: Productivity Goal Tile: ${error}`, error);
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateChart = useCallback(
    (newSelections: (string | number)[]) => {
      //start with the original set of data
      //need to deep clone, otherwise the original set of data will also be updated
      const newData = _.cloneDeep(originalChartData);
      if (newData) {
        //if the data index is not in array, set data to null
        newData.chartData.datasets?.forEach((item, i) => {
          if (!newSelections.includes(i))
            newData.chartData.datasets[i].data = null;
        });

        setUsersTrendsData(newData);
      }
    },
    [originalChartData]
  );

  return {
    init,
    header,
    hasNoResults,
    usersTrendsData,
    isLoading,
    isTileError,
    usersList,
    allUsersByWeek,
    updateChart
  };
};
