'use strict';

angular.module('app')
    .directive('focusIf', ['$timeout', focusIf]);

function focusIf($timeout) {
    return function(scope, elem, attr) {
        scope.$watch(attr.focusIf, function(newValue) {
            if (newValue) {
                $timeout(function() {
                    elem.focus();
                });
            } else {
                elem.blur();
            }
        });
    };
}