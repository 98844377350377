import { useCallback, useRef, useState } from 'react';
import { AuthLevel, NotificationType } from '../../common/enums';
import {
  IAccountTimezone,
  ITimezoneDto,
  ITimezoneService
} from '../../common/services';
import { ITimezoneState } from '../models';
import { useAuthorization } from '../../common/services/Authorization';
import { mapAccountTimezone } from '../utils';
import { LoadApplication } from '../../common/services/Application/AppLoad';

type TimezoneStateProps = {
  service: ITimezoneService;
};

export const useTimezoneState = ({
  service
}: TimezoneStateProps): ITimezoneState => {
  const {
    fetchAllTimezones,
    fetchAccountTimezone,
    fetchShowLocalTimezone,
    postAccountTimezone,
    postShowLocalTimezone
  } = service;

  const [allTimezones, setAllTimezones] = useState<ITimezoneDto[]>();
  const [accountTimezone, setAccountTimezone] = useState<IAccountTimezone>();
  const [showLocalTimezone, setShowLocalTimezone] = useState<boolean>(false);
  const [timezoneNotification, setTimezoneNotification] =
    useState<NotificationType>();

  const authorizationService = useAuthorization();
  const isFormDisabled = useRef<boolean>(
    !authorizationService.hasAuthorizationLevel(
      [AuthLevel.Edit],
      'app.settings.timezone'
    )
  );

  const getTimezones = useCallback(
    async (): Promise<void> => {
      try {
        const timezones: ITimezoneDto[] = await fetchAllTimezones();
        setAllTimezones(timezones);
      } catch (error) {
        setTimezoneNotification({
          msg: 'Unable to load list of timezones',
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to load list of timezones');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getAccountTimezone = useCallback(
    async (): Promise<void> => {
      try {
        const accountTzDto = await fetchAccountTimezone();
        const accountTz: IAccountTimezone = mapAccountTimezone(accountTzDto);
        setAccountTimezone(accountTz);
      } catch (error) {
        console.error(
          'ActivTrak Error: Unable to load Account Timezone',
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateAccountTimezone = useCallback(
    async (payload): Promise<void> => {
      try {
        const timeZoneChanged = payload?.timeZone !== accountTimezone?.timeZone;
        payload = { ...payload, timeZoneChanged: timeZoneChanged };
        await postAccountTimezone(payload);
        setAccountTimezone((prevState) => ({ ...prevState, ...payload }));

        setTimezoneNotification({
          msg: 'Time zone settings saved!',
          type: 'success'
        });

        //reload the application to get the new account settings
        LoadApplication();
      } catch (error) {
        const msg = error?.message || 'Unable to update Account Timezone';
        setTimezoneNotification({
          msg: msg,
          type: 'error'
        });
        console.error(`ActivTrak Error: ${msg}`, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountTimezone]
  );

  const getShowLocalTimezone = useCallback(
    async (): Promise<void> => {
      try {
        const showLocalTz = await fetchShowLocalTimezone();
        setShowLocalTimezone(showLocalTz.showLocalTimezone);
      } catch (error) {
        console.error(
          'ActivTrak Error: Unable to load Account Timezone',
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateShowLocalTimezone = useCallback(
    async (value): Promise<void> => {
      try {
        value = value == 'true' ? true : false;
        const updatedActivityTz = await postShowLocalTimezone({
          showLocalTimezone: value
        });
        if (updatedActivityTz.success) {
          setShowLocalTimezone(value);
          setTimezoneNotification({
            msg: 'Successfully saved activity time and time zone preference!',
            type: 'success'
          });
        }
      } catch (error) {
        setTimezoneNotification({
          msg: 'Unable to save activity time and time zone preference. Please try again later.',
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to update Account Timezone',
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const init = useCallback(async (): Promise<void> => {
    Promise.all([getTimezones(), getAccountTimezone(), getShowLocalTimezone()]);
  }, [getAccountTimezone, getShowLocalTimezone, getTimezones]);

  return {
    allTimezones,
    accountTimezone,
    showLocalTimezone,
    timezoneNotification,
    isFormDisabled,
    init,
    getAccountTimezone,
    setTimezoneNotification,
    updateAccountTimezone,
    updateShowLocalTimezone
  };
};
