import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { isInputError } from '../utils';
import {
  FORM_LABELS,
  INITIAL_FORM_VALUES,
  INITIAL_FORM_ERRORS_STATE,
  ADD_BUTTONS
} from '../constants';
import { IAddAccountModalProps } from '../models/IComponentProps';
import {
  IButtonMouseEvent,
  IFormErrorsState,
  IFormValues
} from '../models/IRandomTypes';

export const AddAccountModal = (props: IAddAccountModalProps) => {
  const { open, onClose, getPayload } = props;

  const authorizationService = useAuthorization();
  const isSuperAdmin: boolean = authorizationService.hasRole(Role.SuperAdmin);

  const [values, setValues] = useState<IFormValues>(INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState<IFormErrorsState>(
    INITIAL_FORM_ERRORS_STATE
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setValues({
      ...values,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isInputError(value, name)
    }));
  };

  const resetForm = () => {
    setValues(INITIAL_FORM_VALUES);
    setErrors(INITIAL_FORM_ERRORS_STATE);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = (event: IButtonMouseEvent, action: string) => {
    event.preventDefault();
    getPayload(values, action);
    handleClose();
  };

  const renderErrorMsg = (field: string, errorMsg: string) => {
    return (
      <span
        style={{
          visibility: errors[field] ? 'visible' : 'hidden'
        }}
      >
        {errorMsg}
      </span>
    );
  };

  const noErrors = Object.values(errors).every((value) => value === false);
  const allFieldsValid =
    !isInputError(values.accountSelector, 'accountSelector') &&
    !isInputError(values.reason) &&
    !isInputError(values.tickets);

  const canSubmit = noErrors && allFieldsValid;

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
      PaperProps={{ style: { width: '40%' } }}
    >
      <DialogTitle textAlign="center">Add Account to Dashboard</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1}>
          {FORM_LABELS.map((input) => (
            <TextField
              key={input.id}
              name={input.id}
              label={input.label}
              value={values[input.id]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors[input.id]}
              helperText={renderErrorMsg(
                input.id,
                input.errorValidationMessage
              )}
              required
              fullWidth
            />
          ))}
          {isSuperAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  name="isImpersonating"
                  color="primary"
                  checked={values.isImpersonating || false}
                  onChange={handleChange}
                />
              }
              label="Impersonate User"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        {ADD_BUTTONS.map((button) => (
          <Button
            key={button.label}
            variant="contained"
            disabled={!canSubmit}
            onClick={(event) => handleSubmit(event, button.actionType)}
          >
            {button.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
