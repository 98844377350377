import { SortDirection } from '../../common/enums';
import {
  filterAndRepeatDataBySpecificParamsAndQuery,
  filterDataBySpecificParamsAndQuery,
  sorter
} from '../../common/helpers';
import { IComputerAlias } from '../models/IComputerAlias';
import {
  IComputerAliasDto,
  IComputerAliasDtoSave
} from '../models/IComputerAliasDto';
import { IUserAlias } from '../models/IUserAlias';
import { IUserAliasDto } from '../models/IUserAliasDto';

export function mapToUserAlias(aliasDto: IUserAliasDto): IUserAlias {
  return {
    userid: aliasDto.userid,
    logon_Domain: aliasDto.logon_Domain,
    alternativeLogonDomain: aliasDto.alternativeLogonDomain,
    user: aliasDto.user,
    alias: aliasDto.alias,
    alternativeUser: aliasDto.alternativeUser,
    lastLog: aliasDto.lastLog,
    logCount: aliasDto.logCount,
    id: aliasDto.userid
  };
}

export function mapToUserAliasDto(alias: IUserAlias): IUserAliasDto {
  if (!alias) return alias;
  return {
    userid: alias.userid,
    logon_Domain: alias.logon_Domain,
    alternativeLogonDomain: alias.alternativeLogonDomain,
    user: alias.user,
    alias: alias.alias,
    alternativeUser: alias.alternativeUser,
    lastLog: alias.lastLog,
    logCount: alias.logCount
  };
}

export function mapToComputerAliasDtoSave(
  alias: IComputerAlias
): IComputerAliasDtoSave {
  return {
    compId: alias.compId,
    acknowledged: alias.acknowledged,
    alias: alias.alias
  };
}

export function mapToComputerAlias(
  aliasDto: IComputerAliasDto
): IComputerAlias {
  return {
    compId: aliasDto.compId,
    primaryDomain: aliasDto.primaryDomain,
    computerName: aliasDto.computerName,
    os: aliasDto.os,
    acknowledged: aliasDto.acknowledged,
    alias: aliasDto.alias,
    version: aliasDto.version,
    lastLog: aliasDto.lastLog,
    lastReported: aliasDto.lastReported,
    firstReported: aliasDto.firstReported,
    id: aliasDto.compId
  };
}

export function filterAndSortAliases(
  aliases: any[],
  searchTerm: string,
  gridOrderBy: string,
  gridOrderDirection: SortDirection,
  searchCriteria: any[],
  searchBarFilterParams: string[]
) {
  let newFilteredAliases = aliases;
  if (searchCriteria.length) {
    searchCriteria.forEach((searchItem) => {
      const { field, fieldValue } = searchItem;
      newFilteredAliases = filterDataBySpecificParamsAndQuery(
        newFilteredAliases,
        [field],
        fieldValue
      );
    });
  } else {
    if (aliases?.length && searchTerm) {
      newFilteredAliases = filterDataBySpecificParamsAndQuery(
        aliases,
        searchBarFilterParams,
        searchTerm
      );
    }
  }
  const sortedData = sorter(
    newFilteredAliases,
    gridOrderDirection,
    gridOrderBy
  );
  return sortedData;
}

export function getAutoCompleteFilteredAliases(
  dataStructure: any[],
  aliases: any[],
  searchBarFilterParams: string[],
  searchTerm: string
): any[] {
  if (!aliases) {
    return [];
  }
  const filteredData = filterAndRepeatDataBySpecificParamsAndQuery(
    aliases,
    dataStructure,
    searchBarFilterParams,
    searchTerm
  );

  const newDataMap = {};
  const uniqueGroups = {};
  for (let i = 0; filteredData.length > i; i++) {
    const { id, type, label } = filteredData[i];
    if (type !== searchBarFilterParams[0]) {
      newDataMap[id + type] = filteredData[i];
    } else {
      if (!uniqueGroups[label]) {
        newDataMap[id + type] = filteredData[i];
        uniqueGroups[label] = true;
      }
    }
  }

  return Object.keys(newDataMap).map(function (key) {
    return newDataMap[key];
  });
}
