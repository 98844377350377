import { StoreCacheName } from '../enums/StoreCacheName';
import { StoreCache } from '../models/StoreCache';
import { fetchAccountPlanStore } from '../stores/accountPlanStore';
import { ACCOUNT_PLAN_CACHE_TIMEOUT } from './accountPlanStoreDefault';

export const STORE_CACHES: StoreCache[] = [
  {
    storeName: StoreCacheName.AccountPlanStore,
    fetchData: fetchAccountPlanStore,
    lifetime: ACCOUNT_PLAN_CACHE_TIMEOUT
  }
];
