import moment from 'moment';
import {
  ProductivityIntervals,
  ProductivityColors,
  TIME_FORMAT,
  primaryColor
} from '../constants';
import { getName, isPassive, getClass } from './';
import { ITimelineData } from '../../dashboard/models';
import { ProductivityStatus } from '../enums';

const DEFAULT_TIMELINE_INTERVAL = '30';

const decodeColor = (value) => {
  return {
    prod: value,
    level: undefined,
    color: ProductivityColors[value] || 'white'
  };
};

const timelineTooltipTemplate = (
  user: string,
  time: string,
  duration: number,
  productivity: ProductivityStatus,
  activity: string,
  showActiveLabel = false
) => {
  if (duration === 0 || typeof productivity === 'undefined') {
    return '';
  }

  const productivityName = getName(productivity, showActiveLabel, true);
  const activityLabel = isPassive(productivity)
    ? 'Prior Activity'
    : 'Longest Activity';
  const productivityClass = getClass(productivity);

  //TODO: classes need to be converted over to React side
  const nameHtml = `<div class="at-timeline-tooltip-row text-medium">${user}</div>`;
  const productivityHtml = `<div class="at-timeline-tooltip-row">${productivityName}</div>`;
  const durationHtml = `<div class="at-timeline-tooltip-row">${duration}</div>`;
  const timeHtml = `<div class="at-timeline-tooltip-row">${time}</div>`;
  const activityHtml = activity
    ? `<div class="at-timeline-tooltip-row">${activityLabel}: ${activity}</div>`
    : '';

  return `<div class="at-timeline-tooltip left-border-${productivityClass}" style="color: ${primaryColor}">${nameHtml}${productivityHtml}${durationHtml}${timeHtml}${activityHtml}</div>`;
};

const customizeProductivityTooltip = (userInfo) => {
  const startTime = moment(userInfo.startTime);
  const endTime = moment(userInfo.endTime);
  const time =
    startTime.format(TIME_FORMAT) + ' - ' + endTime.format(TIME_FORMAT);

  const realInterval: any =
    moment(userInfo.startTime).diff(endTime) / 1000 / 60;
  const duration =
    ProductivityIntervals[realInterval] ||
    ProductivityIntervals[DEFAULT_TIMELINE_INTERVAL];

  return timelineTooltipTemplate(
    userInfo.user,
    time,
    duration,
    userInfo.productivityStatus,
    userInfo.activity,
    true
  );
};

export const getProductivity = (item) => {
  switch (item) {
    case 1:
      return ProductivityStatus.Productive;
    case -1:
      return ProductivityStatus.Unproductive;
    default:
      return ProductivityStatus.Undefined;
  }
};

export const processProductivityTimelineData = (
  ganttData: any,
  timezone: string
): ITimelineData => {
  const dataTable = [
    [
      { type: 'string', id: 'user' },
      { type: 'string', id: 'prod' },
      {
        type: 'string',
        role: 'tooltip'
      },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' }
    ]
  ];
  const colorMap = [];
  let colors = [];
  ganttData?.forEach((userInfo) => {
    const start = moment(userInfo.startTime).tz(timezone).toDate();
    const end = moment(userInfo.endTime).tz(timezone).toDate();

    dataTable.push([
      userInfo.user,
      String(userInfo.productivityStatus),
      customizeProductivityTooltip(userInfo),
      start,
      end
    ] as any);

    // Make sure Google timeline assigns the right colors
    const color = decodeColor(userInfo.productivityStatus);

    let contains = false;
    for (let j = 0; j < colorMap.length; j++) {
      if (color['prod'] === colorMap[j]['prod']) {
        contains = true;
      }
    }
    if (!contains) {
      colorMap.push(color);
    }

    colors = colorMap.map((item) => item.color);
  });

  return {
    dataTable,
    colors
  };
};
