'use strict';

angular.module('app').directive('passwordField', PasswordField);

function PasswordField() {
    return {
        restrict: 'E',
        scope: {
            password: '=ngModel',
            required: '=?atRequired',
            name: '<?atName',
            tabindex: '<?atTabindex',
            placeholder: '<?atPlaceholder',
            showTooltipText: '<?atShowTooltipText',
            fieldClass: '=?atFieldClass',
            maxlength: '<?atMaxlength',
            minlength: '<?atMinlength',
            minspecial: '<?atMinspecial',
            minupper: '<?atMinupper',
            removeDefaultField: '<?atRemoveDefaultField'
        },
        template: require('views/widgets/passwordField.html'),
        replace: true,
        controller: PasswordFieldCtrl
    };
}

PasswordFieldCtrl.$inject = ['$scope'];

function PasswordFieldCtrl($scope) {
    $scope.showPasswordIconClass = 'icon-at-eye';
    $scope.name = $scope.name || 'password';
    $scope.placeholder = $scope.placeholder || 'Password';
    $scope.showTooltipText = $scope.showTooltipText || $scope.placeholder;
    $scope.showPasswordTooltip = 'Show ' + $scope.showTooltipText;
    $scope.ngClass = [];

    if ($scope.fieldClass && Array.isArray($scope.fieldClass)) {
        $scope.ngClass = $scope.fieldClass;
    } else if (typeof $scope.fieldClass === 'string') {
        $scope.ngClass.push($scope.fieldClass);
    }

    if (!$scope.removeDefaultField) {
        $scope.ngClass.push('default-field');
    }

    function updateIcon() {
        $scope.showPasswordIconClass = $scope.fieldClass
            ? $scope.showPasswordIconClass + ' ' + $scope.fieldClass
            : $scope.showPasswordIconClass;
    }
    updateIcon();

    $scope.toggleShowPassword = function () {
        $scope.showPassword = !$scope.showPassword;
        $scope.showPasswordTooltip =
            ($scope.showPassword ? 'Hide ' : 'Show ') + $scope.showTooltipText;
        $scope.showPasswordIconClass = $scope.showPassword
            ? 'icon-at-eye-slash'
            : 'icon-at-eye';
        updateIcon();
    };
}
