import { react2angular } from 'react2angular';
import { UserAliasComponent } from '../../_reactivtrak/src/alias/views/Users.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsAliases', react2angular(UserAliasComponent, []));

}
