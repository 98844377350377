import React, { useEffect, useMemo } from 'react';
import { useInjector } from '../../../common/third-party/angular2react/useInjector';
import { ActivityLogProps } from '../models/ActivityLogTypes';

export const ActivityLogWrapper = (props: ActivityLogProps) => {
  const { reportFilters, refreshTimestamp, activityTimeSettings } = props;

  const $injector = useInjector();

  const scope: any = useMemo(
    () =>
      Object.assign($injector.get('$rootScope').$new(true), {
        activityTimeSettings
      }),
    [$injector, activityTimeSettings]
  );

  useEffect(() => {
    return () => {
      scope?.$destroy();
    };
  }, [scope]);

  useEffect(() => {
    if (scope?.$$childHead?.bindReport) {
      scope.$$childHead?.bindReport(reportFilters);
    }
  }, [reportFilters, refreshTimestamp, scope.$$childHead]);

  const element = useMemo(() => {
    const compile = (element: HTMLElement) => {
      if (!scope) {
        return;
      }

      const $compile = $injector.get('$compile');
      const template = $compile(element);
      template(scope);

      try {
        scope?.$digest();
      } catch (e) {
        /* */
      }
    };

    /// This component was defined in activitylog.component.js
    /// The component name "wrapperActivityLog" will be instantiated using
    /// it's kebbab case element syntax equivalent:
    /// <wrapped-activity-log />
    const element = React.createElement('wrapped-activity-log', {
      ref: compile
    });

    return element;
  }, [$injector, scope]);

  return element;
};
