import React, {
  ChangeEvent,
  createRef,
  MutableRefObject,
  useEffect,
  useState
} from 'react';
import { DialogActions, Dialog, Typography, DialogTitle } from '@mui/material';
import { ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import {
  CommonPaddingBox,
  DialogContentStyled,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import RadioGroupBox from './RadioGroupBox';
import DialogActionsBox from './DialogActionsBox';
import TextFieldContainer from './TextFieldContainer';
import { groupsStore } from '../../../common/hooks';
import { IGroupFilterDto } from '../../../common/models';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onGoToStep2: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
  selectedGroupIds: string[];
};

export const Step1Dialog = (props: ComponentProps) => {
  const { open, onClose, onGoToStep2, coreCategoryRef, selectedGroupIds } =
    props;

  const groups = groupsStore.getState().insightsFilters;

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<IGroupFilterDto[]>();
  const textInputRef = createRef<HTMLInputElement>();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (open && !filtered) {
      setFiltered(groups);
    }
  }, [open, filtered, groups]);

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.toLowerCase();
    const matches = groups.filter(
      (x) => x.name.toLowerCase().indexOf(input) > -1
    );
    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const close = () => {
    setSelected(null);
    setShowSearch(false);
    setFiltered(null);
    onClose();
  };

  const goToStep2 = () => {
    setSelected(null);
    onGoToStep2();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSelected(value);
    coreCategoryRef.current.groupId = value;
    coreCategoryRef.current.groupName = name;
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <CommonPaddingBox width={570} maxHeight={455}>
          <DialogTitle>Step 1 of 3: Select a Team</DialogTitle>
          <DialogContentStyled>
            <Typography sx={TotCss.instructions}>
              Pick the team whose performance you want to evaluate.
            </Typography>
            <TextFieldContainer
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              textValue={'Teams'}
              isHideable={true}
            />
            <StyledBoxContainer hidden={!showSearch}>
              <StyledSearchBarContainer>
                <SearchBar
                  placeholder={'Search Teams'}
                  fieldRef={textInputRef}
                  onClick={(e) => e.stopPropagation()}
                  handleDropDownSearchChange={(e) =>
                    handleDropDownSearchChange(e)
                  }
                />
              </StyledSearchBarContainer>
              <RadioGroupBox
                selected={selected}
                handleChange={handleChange}
                filtered={filtered}
                selectedGroupIds={selectedGroupIds}
              />
            </StyledBoxContainer>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={goToStep2}
              disabled={!selected}
              confirmText={'Go to step 2 of 3'}
            />
          </DialogActions>
        </CommonPaddingBox>
      </Dialog>
    )
  );
};
