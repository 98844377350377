import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsIdentity_id: IRoute = {
  name: 'app.settings.identity_id',
  stateDefinition: {
    url: '/settings/users/{id}',
    template: '<settings-users-detail></settings-users-detail>',
    data: { pageTitle: 'User Details' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsIdentity_id;
