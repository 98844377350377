import React from 'react';
import { Badge } from '@mui/material';

const NotificationIcon = ({ mouseClicked }) => {
  return (
    <>
      <Badge
        badgeContent=" "
        color="error"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClick={mouseClicked}
        sx={(theme) => ({
          '&.MuiBadge-root': {
            position: 'absolute',
            left: 0
          },
          '& .MuiBadge-badge': {
            height: 12,
            width: 12,
            minWidth: 12,
            cursor: 'pointer',
            position: 'absolute',
            [theme.breakpoints.down('xs')]: {
              height: theme.spacing(2),
              width: theme.spacing(2)
            }
          }
        })}
      />
    </>
  );
};

export default NotificationIcon;
