import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { TextOverflow } from '../../../common/styles/app.component.styles';
import { ErrorMsgDisplay, ModalLoadingView } from '../templates';
import { errorMsg, viewButtonLabel } from '../../constants';
import { ICategoryActivity } from '../../models';
import { ViewMoreButton } from '../../../common/components/Button/ViewMoreButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  ListItemBox,
  ModalContentBox,
  ModalHeader
} from '../../styles/templates.styles';

type CustomChartModalProps = {
  data: any[];
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  onInit: () => void;
  onClose: () => void;
  isModalError: boolean;
  height?: string;
  loaderHeight?: string;
  id?: string;
};

//NOTE: parent must have 'position: relative' set
export const TopCategoriesModal = (props: CustomChartModalProps) => {
  const {
    data,
    title,
    isOpen,
    isLoading,
    onInit,
    onClose,
    isModalError,
    height,
    loaderHeight,
    id
  } = props;

  const [buttonName, setButtonName] = useState<string>(viewButtonLabel.more);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [originalCategoriesList, setOriginalCategoriesList] =
    useState<ICategoryActivity[]>(null);
  const [categoriesListDisplay, setCategoriesListsDisplay] =
    useState<ICategoryActivity[]>(null);
  const [viewMore, setViewMore] = useState<boolean>(false);

  useEffect(() => {
    //don't call api unless tooltip is opened
    if (isOpen && onInit) {
      onInit();
    }
  }, [isOpen, onInit]);

  useEffect(() => {
    if (data?.length) {
      setOriginalCategoriesList(data);

      if (data.length > 20) {
        setShowButton(true);
        setCategoriesListsDisplay(data?.slice(0, 20));
      } else {
        setShowButton(false);
        setCategoriesListsDisplay(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  const handleViewClick = () => {
    if (viewMore) {
      setButtonName(viewButtonLabel.more);
      setCategoriesListsDisplay(originalCategoriesList.slice(0, 20));
      setViewMore(false);
    } else {
      setButtonName(viewButtonLabel.less);
      setCategoriesListsDisplay(originalCategoriesList);
      setViewMore(true);
    }
  };

  return (
    <Box maxHeight={height}>
      <Dialog
        id={id}
        open={isOpen}
        onClose={onClose}
        disablePortal
        PaperProps={{
          elevation: 3,
          style: {
            margin: '0',
            padding: '16px',
            borderRadius: '10px'
          }
        }}
        BackdropProps={{
          style: { backgroundColor: 'transparent' }
        }}
        sx={{
          position: 'absolute'
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        {!isLoading && !isModalError && data?.length === 0 && (
          <Typography>No teams meeting criteria</Typography>
        )}
        {!isLoading && !isModalError && data?.length > 0 && (
          <>
            <ModalContentBox>
              {categoriesListDisplay?.map((team, i) => (
                <ListItemBox
                  key={`activity-${team.name?.replace(/\s+/g, '')}-${i}`}
                >
                  <TextOverflow>{team.name}</TextOverflow>
                  <Typography
                    sx={{
                      pl: 1
                    }}
                  >
                    {team.value}
                  </Typography>
                </ListItemBox>
              ))}
            </ModalContentBox>
            {showButton && (
              <Box textAlign="center">
                <ViewMoreButton
                  onClick={handleViewClick}
                  endIcon={
                    viewMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                >
                  {buttonName}
                </ViewMoreButton>
              </Box>
            )}
          </>
        )}
        <ModalLoadingView
          isLoading={isLoading}
          isModalError={isModalError}
          errorMsg={<ErrorMsgDisplay msg={errorMsg} notWidget={true} />}
          loaderHeight={loaderHeight}
        />
      </Dialog>
    </Box>
  );
};
