import { apiService, fetchData } from '../../helpers';
import { getDefaultConfig } from '../../config';
import { IAccountSettingsDto } from '../../models';
import {
  ILiveReportSettingsDto,
  ITotalRealtimeSettingsDto
} from '../../models';

export const getAccountForSupport = async (
  token: string
): Promise<IAccountSettingsDto> => {
  const response = await apiService.get<IAccountSettingsDto>(
    '/api/v2/post_login',
    {
      ...getDefaultConfig(),
      headers: { Authorization: token },
      authorizationRequired: false
    }
  );
  return response?.data;
};

export const getRealtimeSettingsForSupport =
  async (): Promise<ITotalRealtimeSettingsDto> => {
    return await fetchData<ITotalRealtimeSettingsDto>({
      path: '/api/realtime/settings'
    });
  };

export const getLiveReportSettingsForSupport =
  async (): Promise<ILiveReportSettingsDto> => {
    return await fetchData<ILiveReportSettingsDto>({
      path: '/api/settings/live_report_settings'
    });
  };
