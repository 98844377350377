import { react2angular } from 'react2angular';

// React root page connections to Angular
// https://github.com/coatue-oss/react2angular#2-expose-it-to-angular
import TeamPulse from '../teamPulse/Root';
import { GroupTypeLabel } from '../../_reactivtrak/src/common/components/GroupType/GroupTypeLabel';
import { HeaderView } from '../../_reactivtrak/src/common/components/Navigation/Header/views/Header.view';
import { GroupIconSource } from '../../_reactivtrak/src/common/assets/Icons/GroupIconSource';
import { AcademyButton } from '../../_reactivtrak/src/common/components/ReportFilters/components/AcademyButton';
import { AgentActivation } from '../../_reactivtrak/src/agent-activation/views/activation.component';
import { RoleAccessComponent } from '../../_reactivtrak/src/role-access/views/RoleAccess.view';
import { SupportPortal } from '../../_reactivtrak/src/support-portal/views/SupportPortal.view';
import { OnboardingMessage } from '../../_reactivtrak/src/insights/components/OnboardingMessage';

export const connect = (angular) => {
    angular.module('app').component('teamPulse', react2angular(TeamPulse));
    angular
        .module('app')
        .component(
            'groupTypeLabel',
            react2angular(GroupTypeLabel, ['iconSource', 'iconType', 'label', 'tooltipChild', 'tooltipContent'])
        );
    angular.module('app').component('groupIconSource', react2angular(GroupIconSource, ['iconSource', 'iconType']));

    angular.module('app').component('atAcademyButton', react2angular(AcademyButton));

    //Agent Activation Page
    angular.module('app').component('agentActivation', react2angular(AgentActivation));

    //Header Component
    angular
        .module('app')
        .component('headernew', react2angular(HeaderView, ['logout', 'openSupportModal', 'hideNavigation']));

    //Role Access Component (Temp)
    angular.module('app').component('settingsRoleAccess', react2angular(RoleAccessComponent));

    // Support Portal
    angular.module('app').component('supportPortal', react2angular(SupportPortal));

    // Insights Onboarding Message
    angular
        .module('app')
        .component(
            'insightsOnboardingMessage',
            react2angular(OnboardingMessage, ['insightsSetting', 'showDownloadAgent'])
        );
};
