import { useCallback, useState } from 'react';

export const useRefreshState = () => {
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>();

  const handleRefresh = useCallback(async () => {
    setRefreshTimestamp(Date.now());
  }, []);

  return { refreshTimestamp, handleRefresh };
};
