import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, List, ListItemButton, Popover } from '@mui/material';
import GetApp from '@mui/icons-material/GetApp';

type GroupTypeProps = {
  onProgressBarStart: () => void;
  onEndProgbarDwnld: () => void;
  onSubmit: () => Promise<AxiosResponse>;
};

export default function GroupTypeFilter({
  onProgressBarStart,
  onEndProgbarDwnld,
  onSubmit
}: GroupTypeProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadComplete = () => {
    setAnchorEl(null);
    onEndProgbarDwnld();
  };

  const open = Boolean(anchorEl);

  const exportCSV = async () => {
    const response = await onSubmit();

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(
      new Blob([response.data], { type: 'text/csv;charset=utf-8' })
    );
    reader.onloadend = () => {
      const result = reader.result as string;
      const downloadLink = document.createElement('a');
      downloadLink.href = decodeURIComponent(result);
      downloadLink.download = 'userGroupAssignments.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    handleDownloadComplete();
  };

  const handleDwnlClick = () => {
    onProgressBarStart();
    exportCSV();
  };

  return (
    <>
      <Button onClick={handleClick} color="secondary" startIcon={<GetApp />}>
        Download
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List component="nav">
          <ListItemButton onClick={handleDwnlClick}>
            Download CSV
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}
