'use strict';

angular.module('app').directive('embeddedVideoPlayer', function () {
    return {
        restrict: 'E',
        scope: {
            videoId: '=atVideoId',
            videoName: '=atVideoName'
        },
        template: require('./embeddedVideoPlayer.html'),
        controller: EmbeddedVideoPlayerCtrl
    };
});

EmbeddedVideoPlayerCtrl.$inject = ['$scope'];

function EmbeddedVideoPlayerCtrl($scope) {
    var EmbeddedVideoPlayer = require('_app/serviceFunctions/embeddedVideoPlayerService')
        .default;
    var embeddedVideoPlayer = new EmbeddedVideoPlayer(
        $scope.videoId,
        $scope.videoName
    );

    // Create video player HTML
    $scope.videoHtml = embeddedVideoPlayer.videoHtml;

    $scope.$on('$destroy', function () {
        embeddedVideoPlayer.createEvent();
    });
}
