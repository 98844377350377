import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { ModalTooltip } from './ModalTooltip';
import { TodaysUserActivityModal } from '../TodaysActivity/TodaysUserActivityModal';
import { IModalButtonProps } from '../../models';
import { DetailsListModal } from './DetailsListModal';
import Button from '@mui/material/Button';

export const ModalButton = (props: IModalButtonProps) => {
  const {
    buttonLabel,
    data,
    includeTMLinks,
    modalTitle,
    isListLoading,
    onInit,
    id,
    noResults,
    listDisplayed,
    placement,
    minWidth,
    maxHeight,
    modalBlurb,
    isDisabled,
    isModalError,
    height,
    loaderHeight,
    timelineData,
    onSubmitFilters,
    timezone,
    date
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = (e) => {
    e.target.localName === 'body' ? null : setOpen(false); // This is to prevent the modal from closing when the user clicks on the hour selectors in timeline modal
  };
  const handleOpen = () => setOpen(true);

  return (
    <>
      {!noResults && listDisplayed !== 0 && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <ModalTooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement={placement || 'top'}
              minWidth={minWidth || '400px'}
              maxHeight={maxHeight || 'auto'}
              title={
                timelineData ? (
                  <TodaysUserActivityModal
                    loaderHeight="90%"
                    chartId="todays-user-activity-timeline"
                    open={open}
                    timelineData={timelineData}
                    timezone={timezone}
                    date={date}
                    isLoading={isListLoading}
                    onInit={onInit}
                    onSubmitFilters={onSubmitFilters}
                    isChartError={isModalError}
                  />
                ) : (
                  <DetailsListModal
                    open={open}
                    data={data}
                    includeTMLinks={includeTMLinks}
                    modalTitle={modalTitle}
                    modalBlurb={modalBlurb}
                    isLoading={isListLoading}
                    onInit={onInit}
                    isModalError={isModalError}
                    height={height}
                    loaderHeight={loaderHeight}
                  />
                )
              }
            >
              <div>
                <Button
                  onClick={handleOpen}
                  disabled={isDisabled}
                  id={id || undefined}
                  variant="outlined"
                  size="small"
                >
                  {buttonLabel}
                </Button>
              </div>
            </ModalTooltip>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
