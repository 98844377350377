import React, { useEffect } from 'react';
import { IAgent, IAgentSelection } from '../models';
import {
  AgentVersionType,
  OperationalSystemsTypeShort
} from '../../common/enums';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormLabel,
  Box,
  Select
} from '@mui/material';
import { ListItemSubtitleSC } from '../styles/accountConfiguration.styles';
import { notifError } from '../../common/constants';

type AutoUpdateSettingsModalProps = {
  agentVersions: IAgent[];
  winVersions: IAgentSelection[];
  winWarning: string;
  newWinVersion: AgentVersionType | string;
  macVersions: IAgentSelection[];
  macWarning: string;
  newMacVersion: AgentVersionType | string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (value, os) => void;
  onLoad: () => void;
  resetData: () => void;
  validateAgentUpdate: () => boolean;
  winError: string;
  macError: string;
};

export const AutoUpdateSettingsModal = (
  props: AutoUpdateSettingsModalProps
) => {
  const {
    isOpen,
    winVersions,
    winWarning,
    newWinVersion,
    macVersions,
    macWarning,
    newMacVersion,
    onSubmit,
    onClose,
    onChange,
    onLoad,
    resetData,
    validateAgentUpdate,
    winError,
    macError
  } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleChangeWin = (event) => {
    const value = event.target?.value;

    onChange(value, OperationalSystemsTypeShort.win);
  };

  const handleChangeMac = (event) => {
    const value = event.target?.value;

    onChange(value, OperationalSystemsTypeShort.mac);
  };

  const handleClose = () => {
    resetData();
    onClose();
  };

  const handleSubmit = () => {
    if (!validateAgentUpdate()) {
      return;
    }
    onSubmit();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClose();
      }}
      aria-labelledby="update-agent-title"
      aria-describedby="update-agent-description"
      maxWidth="sm"
    >
      <DialogTitle id="update-agent-title">Update Agent Versions</DialogTitle>
      <DialogContent id="update-agent-description">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>Choose Windows Agent Version</FormLabel>
            <Select value={newWinVersion} onChange={handleChangeWin} fullWidth>
              {winVersions?.map((item) => (
                <MenuItem key={`win-version-${item.value}`} value={item.value}>
                  {item.title}
                  {item.subtitle && (
                    <ListItemSubtitleSC>{item.subtitle}</ListItemSubtitleSC>
                  )}
                </MenuItem>
              ))}
            </Select>
            {winWarning && <Box sx={{ marginTop: '10px' }}>{winWarning}</Box>}
            {winError && (
              <Box sx={{ marginTop: '10px', color: notifError }}>
                {winError}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Choose Mac Agent Version</FormLabel>
            <Select value={newMacVersion} onChange={handleChangeMac} fullWidth>
              {macVersions?.map((item) => (
                <MenuItem key={`mac-version-${item.value}`} value={item.value}>
                  {item.title}
                  {item.subtitle && (
                    <ListItemSubtitleSC>{item.subtitle}</ListItemSubtitleSC>
                  )}
                </MenuItem>
              ))}
            </Select>
            {macWarning && <Box sx={{ marginTop: '10px' }}>{macWarning}</Box>}
            {macError && (
              <Box sx={{ marginTop: '10px', color: notifError }}>
                {macError}
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
