import {
  IAgentSearch,
  IAgentSearchDto,
  IAgentSearchResults,
  IAgentSearchResultsDto
} from '../models/IAgentSearch';

export function mapToAgentResults(
  agentResultsDto: IAgentSearchResultsDto
): IAgentSearchResults {
  return {
    cursor: agentResultsDto.cursor,
    results: agentResultsDto.results.map(mapToAgent)
  };
}

export function mapToAgent(agentDto: IAgentSearchDto): IAgentSearch {
  if (agentDto) {
    return {
      email: agentDto.email,
      userId: agentDto.userId,
      entityId: agentDto.entityId,
      revision: agentDto.revision,
      displayName: agentDto.displayName,
      firstLog: new Date(agentDto.firstLog),
      lastLog: new Date(agentDto.lastLog),
      userName: agentDto.userName,
      logonDomain: agentDto.logonDomain,
      selected: false,
      singleDescription:
        agentDto.displayName ?? agentDto.userName ?? agentDto.userId.toString(),
      tracked: agentDto.tracked
    };
  }
  return null;
}
