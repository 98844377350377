'use strict';

angular.module('app').service('supportConsoleService', SupportConsoleService);

SupportConsoleService.$inject = ['$http', 'envConfig'];

function SupportConsoleService($http, envConfig) {
    this.verifyAccount = function(accountId) {
        return $http.post(
            envConfig.apiUrl() + '/api/supportconsole/verifyaccount',
            {
                accountId: accountId
            }
        );
    };

    this.unlockUser = function(username) {
        return $http.post(
            envConfig.apiUrl() + '/api/supportconsole/unlockUser',
            {
                userName: username
            }
        );
    };

    this.setDataCollection = function(accountId, dataCollection) {
        return $http.post(
            envConfig.apiUrl() + '/api/supportconsole/setdatacollection',
            {
                accountId: accountId,
                dataCollection: dataCollection
            }
        );
    };
    
    this.deleteStorage = function(accountId) {
        return $http.post(
            envConfig.apiUrl() + '/api/supportconsole/deletestorage',
            {
                accountId: accountId
            }
        );
    };

    this.deleteAccount = function(accountId) {
        return $http.post(
            envConfig.apiUrl() + '/api/supportconsole/deleteaccount',
            {
                accountId: accountId
            }
        );
    };
}
