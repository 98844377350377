import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  IconContainer,
  LongLabel,
  LongTextToolTip
} from '../styles/supportPortal.styles';
import { IEmailDisplayProps } from '../models/IComponentProps';

export const EmailDisplay = (props: IEmailDisplayProps) => {
  const { impersonating, email } = props;

  return (
    <Box>
      {impersonating && (
        <LongTextToolTip arrow title={`Impersonating ${email}`}>
          <Stack direction="row" justifyContent="center">
            <IconContainer component="span">
              <i className={`fa fa-user-secret`}></i>
            </IconContainer>
            <LongLabel>{email}</LongLabel>
          </Stack>
        </LongTextToolTip>
      )}
      {!impersonating && email.length > 20 && (
        <LongTextToolTip arrow title={email}>
          <LongLabel>{email}</LongLabel>
        </LongTextToolTip>
      )}
      {!impersonating && email.length <= 20 && <Typography>{email}</Typography>}
    </Box>
  );
};
