import React from 'react';
import jwtDecode from 'jwt-decode';
import { AuthorizationService } from '../../services/Authorization';
import { authenticatedUserService } from 'activtrak-ui-utilities';
import { IToken, IAuthorizationService } from '../../models';

const { createContext, useContext } = React;

export const AuthorizationContext = createContext<IAuthorizationService>(null);

type Props = {
  children: React.ReactNode;
  authorizationService?: IAuthorizationService;
};

export const AuthorizationProvider = (props: Props) => {
  const getAuthService = (): IAuthorizationService => {
    const token = jwtDecode<IToken>(authenticatedUserService.getUserToken());
    return new AuthorizationService(token);
  };

  const authService = props.authorizationService || getAuthService();

  return (
    <AuthorizationContext.Provider value={authService}>
      {props.children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthorization = (): IAuthorizationService => {
  return useContext(AuthorizationContext);
};

export default AuthorizationContext;
