'use strict';

const _lodash = require('lodash');

let _widgets = [];
let _bindCalled = false;

const initialize = function (widgets, bindCalled) {
    _widgets = widgets;
    _bindCalled = bindCalled;
};

const registerWidget = (widgetName, bindOrder, bindFunction) => {
    if (!bindFunction) {
        console.error(
            'ActivTrak Error: Cannot register the widget due to the "bindFunction" isn\'t defined.'
        );
        return;
    }

    if (typeof bindFunction !== 'function') {
        return;
    }

    let widget = _lodash.find(_widgets, (w) => w.name === widgetName);
    if (!widget) {
        // If new registration, add to widgets.
        _widgets.push({
            name: widgetName,
            bindOrder: bindOrder,
            bindFunction: bindFunction
        });
    } else {
        // Else update widget
        widget.bindOrder = bindOrder;
        widget.bindFunction = bindFunction;
    }

    // If dashboard binding already called, bind registering widget
    if (_bindCalled) {
        bindFunction();
    }
};

// Bind registered widgets
const callBindFunctions = () => {
    _bindCalled = true;
    _widgets.sort((a, b) => a.bindOrder - b.bindOrder);
    _widgets.forEach((widget) => {
        widget.bindFunction();
    });
};

const checkLocal = function () {
    return {
        widgets: _widgets,
        bindCalled: _bindCalled
    };
};

export const atDashboardWidgetServiceFunctions = {
    initialize: initialize,
    registerWidget: registerWidget,
    callBindFunctions: callBindFunctions,
    checkLocal: checkLocal
};
