import {
  styled,
  Box,
  FormControlLabel,
  Checkbox,
  TextField
} from '@mui/material';
import { gray4 } from '../../common/constants';

export const ListItemSubtitleSC = styled(Box)`
  display: inline-block;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 5px;
`;

export const InlineTextFieldSC = styled(TextField)`
  min-width: 50px;
  max-width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -5px;
  padding-top: ${(props) => (props.error ? '10px' : 0)};
  & .MuiInputBase-input {
    padding: 0;
  }
`;

export const StatusBox = styled(Box)`
  width: 100%;
`;

export const ExtensionBox = styled(Box)`
  border-style: solid;
  border-color: ${gray4} !important;
  padding: 16px;
`;

export const ExtensionBoxTop = styled(ExtensionBox)`
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 4px 0 0;
`;

export const ExtensionBoxMiddle = styled(ExtensionBox)`
  border-width: 0px 1px 1px 1px;
`;

export const ExtensionBoxBottom = styled(ExtensionBox)`
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 4px 4px;
`;

export const FormControlLabelSlim = styled(FormControlLabel)`
  margin-bottom: 2px !important;
`;

export const CheckboxSlim = styled(Checkbox)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;
