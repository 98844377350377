'use strict';

angular.module('app').service('accountApiService', AccountApiService);

AccountApiService.$inject = ['$http', 'envConfig', 'loginServiceUrl'];

function AccountApiService($http, envConfig, loginServiceUrl) {
    this.createAccount = function (payload) {
        return $http.post(loginServiceUrl.apiUrl() + '/account', payload);
    };
    this.createTrial = function (payload) {
        return $http.post(loginServiceUrl.apiUrl() + '/account/plan/trial', payload);
    };
    this.createGoogleAccount = function (payload) {
        return $http.post(loginServiceUrl.apiUrl() + '/account/google/signup', payload);
    };
    this.downloadAgent = function (account, os) {
        return $http.get(envConfig.apiUrl() + '/api/download/' + os + 'agent', {
            ignoreLoadingBar: true
        });
    };
    this.registerChromeUser = function (force) {
        var payload =
            force === true
                ? {
                      force: true
                  }
                : {};
        return $http.post(envConfig.apiUrl() + '/api/settings/chromebook/users/register', payload);
    };
}
