import { apiService } from '../../common/helpers';
import { IStorageInformationDto, IStorageInformationService } from '../models';
import { getDefaultConfig } from '../../common/config';

export const StorageInformationService = (): IStorageInformationService => {
  return {
    fetchStorageInformation: async () => {
      const response = await apiService.get<IStorageInformationDto>(
        '/api/settings/storage/total',
        {
          ...getDefaultConfig(),
          params: { ignoreLoadingBar: true }
        }
      );
      return response?.data;
    }
  };
};
