import React from 'react';
import { Box, Alert } from '@mui/material';
import { groupViewsClasses } from '../styles/Group.views.styles';

export const DownloadComplete = (props) => {
  const { onClose } = props;

  return (
    <Box sx={groupViewsClasses.dispResultBar}>
      <Alert
        variant="filled"
        severity="info"
        sx={groupViewsClasses.confAlert}
        onClose={() => {
          onClose();
        }}
      >
        <p style={{ color: 'black', margin: '0px 0px 0px 0px' }}>
          Download Complete{' '}
        </p>
      </Alert>
    </Box>
  );
};
