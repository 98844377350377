import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
angular.module('app').controller('TrialReminderModalCtrl', TrialReminderModalCtrl);

TrialReminderModalCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$state',
    'localStorageService',
    'AccountSettingsService'
];

function TrialReminderModalCtrl($scope, $uibModalInstance, $state, localStorageService, AccountSettingsService) {
    $scope.done = function () {
        $uibModalInstance.close();
    };

    $scope.goToUpgrade = function () {
        $uibModalInstance.close();
        $state.go('app.account.upgrade');
    };

    $scope.doNotShowChanged = function () {
        // Keep local storage setting as a backup to the last seen system
        localStorageService.set('activtrak-ui-do-not-show-expired_' + AccountSettingsService.account, $scope.doNotShow);
    };

    $scope.$on('$destroy', function () {
        if ($scope.doNotShow) {
            // Update expired_trial_do_not_show do not show
            userServiceFunctions.setUserLastSeen('expired_trial_do_not_show');
        }
    });
}
