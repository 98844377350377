'use strict';

angular.module('app').service('accountCreationService', AccountCreationService);

AccountCreationService.$inject = ['$location', 'messagesService', 'accountApiService', 'cookieDataService'];

function AccountCreationService($location, msg, accountApiService, cookieDataService) {
    function parseEmail() {
        var email = cookieDataService.getEmail();
        return email;
    }

    Object.defineProperty(this, 'displayAccountName', {
        get: function () {
            var email = parseEmail();
            if (email && email !== 'SingleClick' && email !== 'OneClickCreate') {
                return email;
            }
            return msg.get('newAdministrator');
        }
    });

    Object.defineProperty(this, 'displayAccountNumber', {
        get: function () {
            return '200844';
        }
    });

    Object.defineProperty(this, 'newAccountEmail', {
        get: function () {
            var email = parseEmail();
            if (email !== 'SingleClick' && email !== 'OneClickCreate') {
                return email;
            }
            return null;
        }
    });

    function getCjEvent() {
        var cjEvent = cookieDataService.getCjEvent();
        console.log('cjEvent =>', cjEvent);
        return cjEvent;
    }

    this.createAccount = function (payload) {
        payload.cjEvent = getCjEvent();
        return accountApiService.createAccount(payload);
    };

    // this.createTrial = function(payload) {
    //     return accountApiService.createTrial(payload);
    // };

    this.createGoogleAccount = function (payload) {
        payload.cjEvent = getCjEvent();
        return accountApiService.createGoogleAccount(payload);
    };
}
