import React from 'react';
import { Button } from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { notificationMessage } from '../constants';
import { ISupportControlProps } from '../models/ISupportControl';
import { IUnlockUserPayloadDto } from '../models/IPayloadDto';

export const UnlockUserButton = (props: Partial<ISupportControlProps>) => {
  const { username, setDetailsModalClose, changeSetting } = props;

  const notificationService = useNotifications();

  const payload: IUnlockUserPayloadDto = {
    username: username
  };

  const handleClick = () => {
    try {
      changeSetting(payload);
      notificationService.success(
        notificationMessage.userUnlockedSuccess(username)
      );
    } catch (error) {
      notificationService.error(notificationMessage.unlockUserAlert);
      console.error(notificationMessage.unlockUserError, error);
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <Button
      variant="contained"
      onClick={() => handleClick()}
      sx={{ maxWidth: '200px' }}
    >
      Unlock User
    </Button>
  );
};
