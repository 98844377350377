import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export default function AddGroupIcon(props: SvgIconProps) {
  const height = props.height ?? '24px';
  const width = props.width ?? '24px';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 20 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="at-icon at-icon-add-group"
    >
      <title>Add Group</title>
      <g id="MVP-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="groups_start" transform="translate(-278.000000, -87.000000)">
          <g id="ADDGroup" transform="translate(278.000000, 83.789896)">
            <g id="addGroup" transform="translate(0.418567, 3.500000)">
              <path
                d="M9.60179942,5.59441153 C10.4412675,6.16426944 11.0295079,6.936335 11.0295079,7.96575574 L11.0295079,9.80400706 L12.8677593,9.80400706 C13.204772,9.80400706 13.4805097,9.52826936 13.4805097,9.19125662 L13.4805097,7.96575574 C13.4805097,6.62995978 11.2929906,5.83951171 9.60179942,5.59441153 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
              <circle
                id="Oval"
                fill={fillColor}
                fillRule="nonzero"
                cx="4.90200353"
                cy="2.45100177"
                r="2.45100177"
              ></circle>
              <path
                d="M8.57850618,4.90200353 C9.93268466,4.90200353 11.0295079,3.80518024 11.0295079,2.45100177 C11.0295079,1.09682329 9.93268466,-1.15463195e-14 8.57850618,-1.15463195e-14 C8.29051347,-1.15463195e-14 8.02090328,0.0612750441 7.76354809,0.147060106 C8.27213096,0.778193061 8.57850618,1.58089614 8.57850618,2.45100177 C8.57850618,3.32110739 8.27213096,4.12381047 7.76354809,4.75494343 C8.02090328,4.84072849 8.29051347,4.90200353 8.57850618,4.90200353 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
              <path
                d="M4.90200353,5.51475397 C3.26595985,5.51475397 -2.13162821e-14,6.33583956 -2.13162821e-14,7.96575574 L-2.13162821e-14,9.19125662 C-2.13162821e-14,9.52826936 0.275737699,9.80400706 0.612750441,9.80400706 L9.19125662,9.80400706 C9.52826936,9.80400706 9.80400706,9.52826936 9.80400706,9.19125662 L9.80400706,7.96575574 C9.80400706,6.33583956 6.53804721,5.51475397 4.90200353,5.51475397 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
              <rect
                id="Rectangle"
                x="10.413404"
                y="1"
                width="8.51040237"
                height="8.51040237"
              ></rect>
              <path
                d="M16.7962057,5.60980128 L15.0232053,5.60980128 L15.0232053,7.38280177 C15.0232053,7.57783183 14.8636352,7.73740187 14.6686052,7.73740187 L14.6686052,7.73740187 C14.4735751,7.73740187 14.3140051,7.57783183 14.3140051,7.38280177 L14.3140051,5.60980128 L12.5410046,5.60980128 C12.3459745,5.60980128 12.1864045,5.45023124 12.1864045,5.25520118 L12.1864045,5.25520118 C12.1864045,5.06017113 12.3459745,4.90060108 12.5410046,4.90060108 L14.3140051,4.90060108 L14.3140051,3.12760059 C14.3140051,2.93257054 14.4735751,2.77300049 14.6686052,2.77300049 L14.6686052,2.77300049 C14.8636352,2.77300049 15.0232053,2.93257054 15.0232053,3.12760059 L15.0232053,4.90060108 L16.7962057,4.90060108 C16.9912358,4.90060108 17.1508058,5.06017113 17.1508058,5.25520118 L17.1508058,5.25520118 C17.1508058,5.45023124 16.9912358,5.60980128 16.7962057,5.60980128 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
