'use strict';

angular.module('app').directive('classificationsWrapper', ClassificationsWrapper);

function ClassificationsWrapper() {
    return {
        restrict: 'E',
        template: require('views/widgets/dashboard/classificationsWrapper.html'),
        controller: ClassificationsWrapperCtrl
    };
}

ClassificationsWrapperCtrl.$inject = ['$scope', '$timeout'];

function ClassificationsWrapperCtrl($scope, $timeout) {
    $('#classifications-app').removeClass('hide');
    $timeout(function () {
        $('#classifications-app').appendTo('#classifications-app-wrapper');
    });

    $scope.$on('$destroy', function () {
        $('#classifications-app').appendTo('#classifications-app-holder');
        $('#classifications-app').addClass('hide');
    });
}
