import theme from '../../../theme/theme';

export const cardAlarmClasses = {
  cardAlarm: {
    padding: theme.spacing()
  },
  root: {
    '&.MuiTypography-h4': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      maxWidth: '100%'
    }
  },
  alerts: {
    fontWeight: 'bold'
  },
  dayRange: {
    color: '#888888',
    fontWeight: 500
  },
  loader: {
    marginLeft: '45px',
    marginRight: '45px'
  }
};
