import React, { useCallback, useEffect, useState } from 'react';
import { useAuthorization } from '../../../common/services/Authorization';
import {
  submitDashboardEvent,
  usePopulateQuickActionsOptions
} from '../../utils';
import { IQuickAction } from '../../../common/models/IQuickAction';
import {
  getDashboardSettings,
  getParsedLocalStorageItem,
  setDashboardSettings
} from '../../../common/helpers';
import { EditQuickActionsModal } from './EditQuickActionsModal';
import { QuickActionsButton } from './QuickActionsButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { quickActionsWidgetStyles as classes } from '../../styles/QuickActions.styles';
import { IQuickActionsModalMetadata } from '../../models/IHeapMetadataTypes';
import { PopoverDotMenu, TemplateShadow } from '../templates';
import { IAccountSettings } from '../../../common/models';
import { localStorageItems } from '../../../common/constants';
import { DashboardType } from '../../../common/enums';
import { LegacyHeader } from '../../../common/styles/app.component.styles';

export default function QuickActions() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [quickActions, setQuickActions] = useState<IQuickAction[]>();

  const authorizationService = useAuthorization();
  const defaultOptions = usePopulateQuickActionsOptions();

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const setDefaultActionsList = useCallback(() => {
    if (defaultOptions) {
      let actions = defaultOptions;

      const stored = getDashboardSettings();
      const storedActions = stored?.quickActionsSelections;

      /*if action button settings exist in local storage, then merge them with default values first*/
      if (storedActions) {
        for (let i = 0; i < actions.length; i++) {
          for (let j = 0; j < storedActions.length; j++) {
            if (actions[i].id === storedActions[j].id) {
              actions[i].selected = storedActions[j].selected;
            }
          }
        }
      }

      /*then check which ones are actually allowed for this user*/
      actions = actions.filter((item) => {
        /*primary role access check*/
        if (
          accountSettings?.roleAccess.includes(item.roleAccess) ||
          authorizationService.hasAnyRole(item.supportRoles)
        ) {
          /*secondary feature access check*/
          if (
            !item.featureAccess ||
            authorizationService?.hasFeature(item.featureAccess)
          ) {
            return item;
          }
        }
      });

      setQuickActions(actions);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [defaultOptions]);

  useEffect(() => {
    setDefaultActionsList();
  }, [setDefaultActionsList]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const getHeapEventMetadata = (
    quickActions: IQuickAction[]
  ): IQuickActionsModalMetadata => {
    const metaData = {};
    quickActions.map((quickAction) => {
      metaData[`${quickAction.name}`] = quickAction.selected.toString();
    });

    return metaData as IQuickActionsModalMetadata;
  };

  const handleSubmit = (quickActions: IQuickAction[]) => {
    setQuickActions(quickActions);

    //update localStorage with changes
    const condensedChecklist = quickActions.map((action) => ({
      id: action.id,
      selected: action.selected
    }));
    setDashboardSettings({ quickActionsSelections: condensedChecklist });

    const heapEventMetadata = getHeapEventMetadata(quickActions);

    submitDashboardEvent(
      'Quick Actions Modified',
      heapEventMetadata,
      DashboardType.admin
    );

    handleClose();
  };

  const handleCancel = () => {
    //use the default list
    setDefaultActionsList();
    handleClose();
  };

  return (
    <TemplateShadow>
      <Stack direction="row" alignItems="center" mb={1}>
        <LegacyHeader>Quick Actions</LegacyHeader>
        <PopoverDotMenu
          id="89886082-bd2c-11ed-afa1-0242ac120002"
          popoverText="Edit quick actions"
          handlePopoverItemClick={handleOpen}
        />
      </Stack>
      <Box sx={classes.btnDiv}>
        {quickActions?.map((action) => {
          if (action.selected) {
            const { name, icon, clickAction, clickParams, id } = action;

            return (
              <QuickActionsButton
                id={id}
                key={id}
                name={name}
                icon={icon}
                clickAction={clickAction}
                clickParams={clickParams}
              />
            );
          }
        })}
      </Box>
      <EditQuickActionsModal
        open={openModal}
        quickActionContent={quickActions}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </TemplateShadow>
  );
}
