import React from 'react';
import AccountWizardForm from '../components/AccountWizardForm';
import { AccountWizardWelcome } from '../components/Steps/AccountWizardWelcome';
import { AccountWizardProvider, useAccountWizardContext } from '../services';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { WizardProvider } from '../../common/components/Wizard/WizardProvider';

export default function AccountWizardView() {
  const { firstVisit } = useAccountWizardContext();
  return <>{firstVisit ? <AccountWizardWelcome /> : <AccountWizardForm />}</>;
}

export const AccountWizardComponent = () => (
  <ReactivTrakComponentWrapper>
    <AccountWizardProvider>
      <WizardProvider>
        <AccountWizardView />
      </WizardProvider>
    </AccountWizardProvider>
  </ReactivTrakComponentWrapper>
);
