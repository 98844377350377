import React from 'react';
import { TagBarContainer, ChipContainer, ChipSC } from './TagBar.styles';

type ComponentProps = {
  tabBarData: any[];
};

export const TagBar = (props: ComponentProps) => {
  const { tabBarData } = props;

  return (
    <TagBarContainer>
      <ChipContainer>
        {tabBarData.map((data, index) => {
          const { fieldName, fieldValue } = data;
          let icon;
          return (
            <li key={index}>
              <ChipSC icon={icon} label={`${fieldName}: ${fieldValue}`} />
            </li>
          );
        })}
      </ChipContainer>
    </TagBarContainer>
  );
};
