import React from 'react';
import Grid from '@mui/material/Grid';
import { ITopActivity } from '../../models';
import { SortDirection } from '../../../common/enums';
import CustomGrid from '../../../common/components/Grid/CustomGrid';
import { useCustomRowsSkeletonLoader } from '../../../common/components/Grid/CustomGrid.utils';
import { useActivityColumns } from '../../utils';

type ActivityTopSubpagesProps = {
  subpages: ITopActivity[];
  isActivityDetailLoading: boolean;
};

export default function ActivityTopSubpages({
  subpages,
  isActivityDetailLoading
}: ActivityTopSubpagesProps) {
  const subpageColumn = {
    field: 'subpage',
    fieldName: 'Subpage',
    headerName: 'SubPage',
    type: 'string',
    isSortable: true,
    width: '75%',
    template: (dataItem: ITopActivity) => {
      return <div className="truncate">{dataItem.name}</div>;
    }
  };

  const columns = useActivityColumns();
  const gridColumns = [subpageColumn, ...columns];
  const takeThree = subpages ? subpages.slice(0, 3) : [];

  const activitySkeletonLoader = useCustomRowsSkeletonLoader(3, 2, false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomGrid
          data={[...takeThree]}
          columns={gridColumns}
          initialSortField="duration"
          initialSortDirection={SortDirection.Descending}
          uniqueRowProp="uniqueId"
          isLoading={isActivityDetailLoading}
          skeletonLoaderTemplate={activitySkeletonLoader}
          height="127px"
        />
      </Grid>
    </Grid>
  );
}
