//DEPRECATED
import React from 'react';
import { MUIStylesContainer } from '../common/styles/app.component.mui.styles';
import { GroupClassificationsProvider } from '../common/services/Classifications';
import {
  ClassificationProvider,
  ClassificationStoreProvider
} from './services';
import ClassificationsWidget from '../dashboard/components/Classifications/ClassificationsTable';
import { ReactivTrakComponentWrapperNoPadding } from '../ReactivTrakComponentWrapperNoPadding';

export const ClassificationsViewWidget = () => (
  <ReactivTrakComponentWrapperNoPadding>
    <MUIStylesContainer>
      <ClassificationProvider>
        <ClassificationStoreProvider>
          <GroupClassificationsProvider>
            <ClassificationsWidget tableHeight="400px" />
          </GroupClassificationsProvider>
        </ClassificationStoreProvider>
      </ClassificationProvider>
    </MUIStylesContainer>
  </ReactivTrakComponentWrapperNoPadding>
);
