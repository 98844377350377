import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { PrivacySetting } from '../../../../../enums/PrivacySetting';
import { IRoute } from '../../../../../models/IRoute';
import { screenshotsRedactionTemplate as angularJsTemplate } from '../../templates';

export const appScreenshotsRedaction: IRoute = {
  name: 'app.screenshots.redaction',
  stateDefinition: {
    url: '/screenshots/redaction',
    template: angularJsTemplate,
    controller: 'ScreenshotsRedactionCtrl',
    data: { pageTitle: 'Screenshot Redaction' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsScreenshotsRedaction,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic],
    bundleFlags: [BundleFlag.ScreenshotRedaction],
    privacySettings: [PrivacySetting.Screenshots]
  }
};

export default appScreenshotsRedaction;
