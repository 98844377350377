'use strict';
//  The category system uses GUIDs to identify unique categories or category requests.
//  For received and updated activities, the category id can be null.
//  For category requests from the API, defined GUIDs are used to inform the API of the purpose of the request.

// Static GUIDs generated for the categories system.
// These must match the GUIDs defined in the API
const _categoryGuid = {
    all: 'f2efd84f-43ac-11ea-ae67-42010ad82004', // migrated 0
    categorized: 'fccd5884-43ac-11ea-ae67-42010ad82004', // migrated -1
    uncategorized: '07a56705-43ad-11ea-ae67-42010ad82004', // migrated -2
    totalUncategorized: '73de7dca-4451-11ea-ae67-42010ad82004', // migrated 0, "uncategorized" categories
    ignore: 'e9cd4abf-43a7-11ea-ae67-42010ad82004' // migrated 10
};

// Is valid when
// Category Id is defined and
// Id is not part of defined static ids or
// Id is the ignore category
// Used to replace all category int checks such as (id > 0)
const isValidCategory = categoryId => {
    return Boolean(
        categoryId &&
            isNaN(categoryId) &&
            (!_.find(_categoryGuid, id => id === categoryId) ||
                isIgnoreCategory(categoryId))
    );
};

// Is ignore when category id matches ignore id
const isIgnoreCategory = categoryId => {
    return categoryId === _categoryGuid.ignore;
};

// Is ignore when category id matches ignore id
const isNotIgnoreCategory = categoryId => {
    return categoryId !== _categoryGuid.ignore;
};

// Used to replace all null/undefined category id checks
// Can be updated in single location to redefine an undefined category
const isUndefinedCategory = categoryId => {
    return !categoryId;
};

const isProtectedCategory = categoryId => {
    return Boolean(_.find(_categoryGuid, id => id === categoryId));
};

const sortCategories = categories => {
    if (!categories || typeof categories.sort !== 'function') {
        return categories;
    }

    categories.sort(function(a, b) {
        var list = [
            categoryServiceFunctions.categoryGuid.categorized,
            categoryServiceFunctions.categoryGuid.uncategorized,
            categoryServiceFunctions.categoryGuid.ignore
        ];

        // If category is all, move to top
        if (a.id === categoryServiceFunctions.categoryGuid.all) {
            return -1;
        }
        if (b.id === categoryServiceFunctions.categoryGuid.all) {
            return 1;
        }

        // If category is in list of special categories, move to top by order in array
        var aIndex = list.indexOf(a.id);
        var bIndex = list.indexOf(b.id);
        if (aIndex >= 0 && bIndex >= 0) {
            return aIndex - bIndex;
        }
        if (aIndex >= 0) {
            return -1;
        }
        if (bIndex >= 0) {
            return 1;
        }

        // Else sort by name
        return a.name.localeCompare(b.name);
    });

    return categories;
};

export const categoryServiceFunctions = {
    categoryGuid: _categoryGuid,
    isValidCategory: isValidCategory,
    isIgnoreCategory: isIgnoreCategory,
    isNotIgnoreCategory: isNotIgnoreCategory,
    isUndefinedCategory: isUndefinedCategory,
    isProtectedCategory: isProtectedCategory,
    sortCategories: sortCategories
};
