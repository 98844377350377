import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { ModalTooltip } from './ModalTooltip';
import { IWidgetModalProps } from '../../models';
import Button from '@mui/material/Button';

export const WidgetModal = (props: IWidgetModalProps) => {
  const {
    children,
    buttonLabel,
    id,
    placement,
    minWidth,
    maxHeight,
    isDisabled,
    onClick
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);
    const handleOpen = () => {
      setOpen(true);
      if (onClick) onClick();
    };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <ModalTooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={placement || 'top'}
            minWidth={minWidth || '400px'}
            maxHeight={maxHeight || 'auto'}
            // passing the modal content via the 'children' prop means you can pass any kind of React content into this modal
            title={children}
          >
            <div>
              <Button
                onClick={handleOpen}
                disabled={isDisabled}
                id={id || undefined}
                variant="outlined"
                size="small"
              >
                {buttonLabel}
              </Button>
            </div>
          </ModalTooltip>
        </Box>
      </ClickAwayListener>
    </>
  );
};
