import React from 'react';
import { Link, Typography, Skeleton, Box } from '@mui/material';
import Numeral from 'numeral';
import { cardActiveClasses } from './CardActive.styles.js';
import { setStorageTab, storageModes } from '../../../utils/globalFilters.js';
import { useImpersonate } from '../../../../../_reactivtrak/src/common/hooks/useImpersonate';

const CardActiveAgent = ({
  userCount,
  accountId,
  ownerEmail,
  onError,
  extLoaded
}) => {
  const [, impersonate] = useImpersonate(accountId);

  return (
    <Box sx={cardActiveClasses.activeAgent}>
      <Link
        sx={cardActiveClasses.link}
        href={'#'}
        onClick={(e) => {
          e.preventDefault();

          setTimeout(async () => {
            try {
              await impersonate();
              setStorageTab(storageModes.users, ownerEmail);

              window.location.href = '/#/app/settings/useragents';
            } catch (ex) {
              onError(ex);
            }
          });
        }}
        target="_blank"
        color="inherit"
        underline="none"
      >
        <Typography
          variant="subtitle2"
          className="card-active-agent--no-padding"
        >
          Active Users
        </Typography>
        <Typography sx={cardActiveClasses.h6} variant="h6">
          {extLoaded && <>{Numeral(userCount).format('0,0', Math.floor)}</>}
          {!extLoaded && (
            <Skeleton
              variant="rect"
              animation="wave"
              sx={cardActiveClasses.loader}
            />
          )}
        </Typography>
      </Link>
    </Box>
  );
};

export default CardActiveAgent;
