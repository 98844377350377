import theme from '../theme/theme';

export const snackbarMsgClasses = {
  root: {
    '&.MuiSnackbar-root': {
      left: theme.drawerWidth,
      top: theme.spacing(6),
      right: 0,
      transform: 'none'
    }
  },
  rootAlert: {
    borderRadius: 0,
    flexGrow: 1,
    '& .MuiAlert-message': {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .MuiAlert-action': {
      margin: 0,
      padding: 0,
      paddingLeft: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',

      '& .MuiLink-root': {
        marginRight: 1,
        textDecoration: 'underline',
        color: theme.palette.common.black
      }
    }
  }
};
