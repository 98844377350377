import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  DialogActions,
  Button,
  Checkbox,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type DeleteChromebookUsersDialogProps = {
  isDisabled: boolean;
  onDelete: (payload: string[], isCancel?: boolean) => Promise<void>;
  users: string[];
};

export default function DeleteChromebookUsersButton(
  props: DeleteChromebookUsersDialogProps
) {
  const { users, isDisabled, onDelete } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIsDeleting(false);
    setChecked(false);
  };

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true);
    try {
      await onDelete(users);
    } catch (error) {
      //error handled by state management
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => setOpenDialog(true)}
        disabled={isDisabled}
        color="secondary"
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <Dialog
        open={openDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          handleClose();
        }}
      >
        <DialogTitle>Confirm Chromebook User Deletion</DialogTitle>
        <DialogContent>
          <Box>Do you want to delete the selected ActivTrak IDs?</Box>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChecked}
                disabled={isDeleting}
              />
            }
            label="I acknowledge the selected agents will stop gathering data"
          />
        </DialogContent>
        <DialogActions>
          {isDeleting ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleClose} autoFocus disabled={isDeleting}>
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                variant="contained"
                disabled={isDeleting || !checked}
              >
                Delete
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
