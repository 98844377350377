'use strict';

angular.module('app').directive('unverifiedNotification', [
    'AccountSettingsService',
    function (AccountSettingsService) {
        return {
            restrict: 'E',
            scope: false,
            template: require('views/widgets/unverifiedNotification.html'),

            controller: [
                '$scope',
                '$rootScope',
                'loginService',
                'customUibModal',
                function ($scope, $rootScope, loginService, customUibModal) {
                    var isCreator =
                        AccountSettingsService.username ===
                        AccountSettingsService.creator;
                    var isVerified = AccountSettingsService.isVerified;

                    function getIsFirstLogin() {
                        return (
                            loginService.getGlobals().currentUser
                                .isFirstLogin || false
                        );
                    }

                    $scope.isUnverified =
                        AccountSettingsService.isVerified !== undefined &&
                        isCreator &&
                        !isVerified &&
                        !getIsFirstLogin();

                    $scope.$on('AccountSettingsInitialized', function () {
                        var isVerified = AccountSettingsService.isVerified;

                        $scope.isUnverified =
                            AccountSettingsService.isVerified !== undefined &&
                            isCreator &&
                            !isVerified &&
                            !getIsFirstLogin();
                    });

                    $scope.email = AccountSettingsService.username;

                    $scope.showOptions = function (e) {
                        e.preventDefault();
                        var modal = customUibModal.open({
                            animation: false,
                            template:
                                '<div class="verification-modal"><verification-options is-modal="true" owner="' +
                                $scope.email +
                                '"></verification-options></div>',
                            windowClass: 'centered-modal'
                        });

                        $rootScope.$on(
                            'dismiss-verification-modal',
                            function () {
                                modal.close();
                            }
                        );
                    };
                }
            ]
        };
    }
]);
