import { gridServiceFunctions } from '_app/serviceFunctions/gridServiceFunctions';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import lodash from 'lodash';
import template from 'views/widgets/realtime.html?raw';
import gridColumnSelectorModalCtrlTemplate from 'views/widgets/gridColumnSelectorModal.html?raw';
import { getReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';

angular
    .module('app')
    .directive('realtime', function () {
        return {
            restrict: 'E',
            template,
            controller: 'RealtimeWidgetCtrl'
        };
    })
    .controller('RealtimeWidgetCtrl', RealtimeWidgetCtrl);

RealtimeWidgetCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$window',
    'AccountSettingsService',
    'messagesService',
    'widgetsApiService',
    '$state',
    'customUibModal',
    'atDashboardWidgetService',
    'pageSizeService',
    'realtimeScreenshotService',
    'localStorageService',
    'authorizationService',
    'gridPropertiesService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions'
];

function RealtimeWidgetCtrl(
    $scope,
    $rootScope,
    $timeout,
    $window,
    AccountSettingsService,
    msg,
    widgetsApiService,
    $state,
    customUibModal,
    atDashboardWidgetService,
    pageSizeService,
    realtimeScreenshotService,
    localStorageService,
    authorizationService,
    gridPropertiesService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions
) {
    let pollTimer;
    let isDestroyed = false;
    let pollDelay =
        (AccountSettingsService.realtimeSettings && AccountSettingsService.realtimeSettings.realtimePollDelay) || 2000;
    let pollDelayAfterError =
        (AccountSettingsService.realtimeSettings && AccountSettingsService.realtimeSettings.realtimePollAfterError) ||
        10000;
    const max400tries =
        (AccountSettingsService.realtimeSettings && AccountSettingsService.realtimeSettings.realtimeMax400tries) || 10;
    let showingScreenshot = false;

    $scope.filter = {};
    $scope.idleTitle = 'passive';
    $scope.idleLockedTitle = 'passive - locked';
    $scope.totalActiveAgents = -1;
    $scope.totalReportingAgents = -1;
    $scope.agentCountNumber = -1;
    $scope.agentCountLabel = 'Reporting';
    $scope.isReport = !!$scope.isReport;
    $scope.disableAutoSize = !$scope.isReport;
    $scope.allowThumbnails = authorizationService.hasFeature(FeatureFlag.RealtimeThumbnails) && $scope.isReport;
    $scope.pollInBackground = false; // Forced to never poll in background.
    $scope.realtimeLoading = true;

    let showThumbnails =
        $scope.allowThumbnails &&
        localStorageService.get('showRealtimeThumbnails-' + AccountSettingsService.username) &&
        !atHelperFunctions.isSmallWindow();
    let showTiles =
        $scope.allowThumbnails &&
        localStorageService.get('showRealtimeTiles-' + AccountSettingsService.username) &&
        !atHelperFunctions.isSmallWindow();
    $scope.realtimeSettingsLoaded = !!AccountSettingsService.realtimeSettings;
    $scope.realtimeTileList = {};
    $scope.tryCounter = 0;

    $scope.isSignup = $state.current.name === 'signup.modal' || $state.current.name === 'signup.google';

    if ($scope.isReport) {
        realtimeScreenshotService.initialize();
    }

    $scope.mainGridHeight = function () {
        return 220;
    };

    function setReportingAgents() {
        const agents = realTimeReportSource.view();
        const totalFilteredAgents = agents ? agents.length : $scope.totalReportingAgents;
        const isSmallWindow = atHelperFunctions.isSmallWindow();
        const filters = realTimeReportSource.filter();
        const isFiltered = !!(filters && filters.filters.length > 0);
        $scope.agentCountNumber =
            totalFilteredAgents === $scope.totalReportingAgents
                ? $scope.showPassive
                    ? $scope.totalReportingAgents
                    : $scope.totalActiveAgents
                : totalFilteredAgents;
        const agentLabel = $scope.agentCountNumber === 1 ? ' Agent' : ' Agents';
        $scope.agentCountLabel =
            isFiltered && $scope.showPassive
                ? 'Filtered'
                : $scope.showPassive
                ? 'Reporting' + (isSmallWindow ? '' : agentLabel)
                : 'Active';
    }

    function toggleScrollbar(e) {
        if (!showTiles) {
            const gridDataTable = e.sender && e.sender.table;
            const gridDataArea = gridDataTable && gridDataTable.closest('.k-grid-content');
            const gridVerticalScroll = e.sender && e.sender.element.find('.k-scrollbar-vertical');
            if (gridVerticalScroll && gridDataTable && gridDataTable[0] && gridDataArea && gridDataArea[0]) {
                gridVerticalScroll.toggleClass('hide', gridDataTable[0].offsetHeight < gridDataArea[0].offsetHeight);
            }
            if ($scope.realTimeGrid) {
                kendo.resize($scope.realTimeGrid);
            }
        }
    }

    function initializeRealtimeThumbnails() {
        if ($scope.isReport) {
            realtimeScreenshotService.reset();
        }
    }
    initializeRealtimeThumbnails();

    $scope.toggleShowThumbnails = function () {
        $scope.togglePollPause(false);

        showThumbnails = !showThumbnails;
        if (!showThumbnails && showTiles) {
            $scope.toggleDataView();
        }

        initializeRealtimeThumbnails();
        $timeout.cancel(pollTimer);
        setGridOptions();
        if (!showTiles) {
            configureGrid();
        }
        poll();

        localStorageService.set('showRealtimeThumbnails-' + AccountSettingsService.username, showThumbnails);
    };

    $scope.toggleDataView = function () {
        $scope.togglePollPause(false);

        showTiles = !showTiles;

        if (!showThumbnails && showTiles) {
            $scope.toggleShowThumbnails();
        }

        $scope.realTimeGrid = null;
        $scope.showPassive = false;
        $state.current.data.filter.columns = !showTiles;
        initializeRealtimeThumbnails();
        setGridOptions();
        if (!showTiles) {
            configureGrid();
        }

        if (showTiles) {
            $scope.filter = {};
            $scope.applyFilter({});
        }

        localStorageService.set('showRealtimeTiles-' + AccountSettingsService.username, showTiles);
    };

    $scope.isShowingThumbnails = function () {
        return showThumbnails;
    };

    $scope.isShowingGrid = function () {
        return !showTiles && !$scope.overLimitOrOverStorage && $scope.realtimeSettingsLoaded;
    };

    $scope.isShowingTiles = function () {
        return showTiles && !$scope.overLimitOrOverStorage && $scope.realtimeSettingsLoaded;
    };

    $state.current.data.filter.columns = !showTiles && $scope.isReport;

    let cachedData;

    function handleRealtimeResult(options, result) {
        result.data = lodash.sortBy(result.data, function (i) {
            return i.user.toLowerCase();
        });
        if (!showingScreenshot) {
            setPollTimeout(pollDelay);
            let idleCount = 0;
            angular.forEach(result.data, function (row) {
                row.isIdle =
                    typeof row.title === 'string' &&
                    (row.title.toLowerCase() === $scope.idleTitle || row.title.toLowerCase() === $scope.idleLockedTitle)
                        ? '1'
                        : '0';
                idleCount += row.isIdle === '1' ? 1 : 0;
            });
            $scope.totalActiveAgents = result.total - idleCount;
            $scope.totalReportingAgents = result.total;
            $rootScope.$broadcast('realtimeUpdated', result);
            setReportingAgents();
            options.success(result);
        } else {
            result.data = [];
            result.total = 0;
            options.success(result);
        }
    }

    const realTimeReportSource = new $window.kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if ($scope.isSignup) {
                    $scope.realtimeLoading = false;
                    options.success({ data: [], total: 0 });
                    return;
                }

                if (cachedData && $scope.pollPaused) {
                    handleRealtimeResult(options, cachedData);
                } else {
                    widgetsApiService
                        .getRealtime($scope.parameters)
                        .then(function (result) {
                            result = result.data;
                            cachedData = result;
                            $scope.realtimeLoading = false;
                            handleRealtimeResult(options, result);
                        })
                        .catch(function (result) {
                            $scope.realtimeLoading = false;
                            if (result.status > 399 && result.status < 500) {
                                poll400ErrorHandler();
                            } else {
                                setPollTimeout(pollDelayAfterError);
                            }
                            options.error(result);
                        });
                }
            }
        },
        schema: {
            data: 'data',
            total: 'total'
        },
        pageSize: 150
    });

    $scope.realTimeReportSource = realTimeReportSource;

    function isPassive(item) {
        return (
            item &&
            item.title &&
            (item.title.toLowerCase() === $scope.idleTitle || item.title.toLowerCase() === $scope.idleLockedTitle)
        );
    }

    function isSystemEvent(item) {
        return item && item.exec && item.exec.toLowerCase() === 'system event';
    }

    function isSystemUser(item) {
        return item && item.user && item.user.toLowerCase() === 'system';
    }

    function createColumns() {
        const columns = [
            {
                field: 'time',
                title: msg.get('date'),
                width: 200,
                filterable: false,
                hidden: true
            },
            {
                field: 'os',
                title: msg.get('os'),
                filterable: false,
                attributes: {
                    class: 'text-center'
                },
                template:
                    '#switch (os) {' +
                    'case "Win":# <i class="fa fa-windows" title="Windows"></i> #break;' +
                    'case "Mac OS":# <i class="fa fa-apple" title="macOS"></i> #break;' +
                    'case "Cros":# <i class="fa fa-chrome" title="Chrome OS"></i> #break;' +
                    'default: # <i class="fa fa-question" title="#: os #"></i> #break;' +
                    '}#',
                width: 40
            },
            {
                field: 'device',
                title: msg.get('computer'),
                filterable: templateServiceFunctions.createFilter('device'),
                width: 200,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'devicealias',
                title: msg.get('computerAlias'),
                filterable: templateServiceFunctions.createFilter('devicealias'),
                width: 200,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'user',
                title: msg.get('user'),
                filterable: templateServiceFunctions.createFilter('user'),
                template: kendo.template(function (item) {
                    if (isSystemUser(item)) {
                        return (
                            '<span class="at-deemphasize">' +
                            '<a class="at-pointer" ng-click="itemClicked(dataItem, \'user\')">' +
                            item.user.toLowerCase() +
                            '</a>' +
                            '</span>'
                        );
                    } else {
                        return '<a class="at-pointer" ng-click="itemClicked(dataItem, \'user\')">{{dataItem.user}}</a>';
                    }
                }),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 120
            },
            {
                field: 'ldom',
                title: msg.get('logonDomain'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('ldom'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 120
            },
            {
                field: 'pdom',
                title: msg.get('primaryDomain'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('pdom'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'title',
                title: msg.get('title'),
                filterable: templateServiceFunctions.createFilter('title'),
                template: kendo.template(function (item) {
                    if (isPassive(item) || isSystemEvent(item)) {
                        return (
                            '<span class="at-deemphasize">' +
                            '<a class="at-pointer" ng-click="itemClicked(dataItem, \'title\')">' +
                            item.title.toLowerCase() +
                            '</a>' +
                            '</a></span>'
                        );
                    } else {
                        return '<a class="at-pointer" ng-click="itemClicked(dataItem, \'title\')">{{dataItem.title}}</a>';
                    }
                }),
                attributes: {
                    class: 'text-nowrap'
                },
                width: $scope.isReport ? 300 : null
            },
            {
                field: 'desc',
                title: msg.get('description'),
                hidden: true,
                filterable: false,
                width: 250,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'exec',
                title: msg.get('executable'),
                template: kendo.template(function (item) {
                    if (isSystemEvent(item)) {
                        return '<span class="at-deemphasize"' + item.exec.toLowerCase() + '</span>';
                    } else {
                        return item.exec || '';
                    }
                }),
                filterable: templateServiceFunctions.createFilter('exec'),
                width: 150,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'osvn',
                title: msg.get('osVersion'),
                hidden: true,
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'ip',
                title: msg.get('privateIp'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('ip'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'publicip',
                title: msg.get('publicIp'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('publicip'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'snid',
                title: msg.get('snid'),
                hidden: true,
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                width: 100
            },
            {
                field: 'url',
                title: msg.get('url'),
                filterable: templateServiceFunctions.createFilter('url'),
                template: function (item) {
                    item.urlLabel = item.url;
                    item.urlOptions = {
                        internalLink: {
                            visible: true,
                            stateName: 'app.reports.activitylog',
                            stateParams: {
                                user: item.user,
                                site: item.url
                            },
                            icon: 'fa fa-info-circle'
                        },
                        externalLink: {
                            visible: true,
                            url: item.url,
                            icon: 'fa fa-external-link'
                        },
                        copyLink: {
                            visible: true,
                            icon: 'fa fa-copy'
                        }
                    };

                    return (
                        '<a class="at-pointer" ng-click="itemClicked(dataItem, \'url\')">' +
                        templateServiceFunctions.getFaviconTemplateAsString(item.url) +
                        '</a>'
                    ); //at-url url-options="dataItem.urlOptions" url-label="dataItem.urlLabel"
                },
                attributes: {
                    class: 'text-nowrap'
                },
                width: $scope.isReport ? 350 : null
            }
        ];

        if (authorizationService.hasFeature(FeatureFlag.RealtimeScreenShots)) {
            columns.splice(0, 0, {
                title: msg.get('screenshot'),
                filterable: false,
                headerTemplate: '<i class="icon-at-screenshot text-master" title="' + msg.get('screenshot') + '"></i>',
                template: kendo.template(function () {
                    if (showThumbnails && $scope.allowThumbnails) {
                        return '<realtime-thumbnail row="dataItem" on-click="showScreenshot" fixed-width="225"></realtime-thumbnail>';
                    }

                    if ($scope.pollInBackground) {
                        return '<realtime-thumbnail class="hide" row="dataItem" on-click="showScreenshot"></realtime-thumbnail><a ng-click="showScreenshot(dataItem)"><i class="icon-at-screenshot realtime-ss-button text-master"></i></a>';
                    }

                    return '<a ng-click="showScreenshot(dataItem)"><i class="icon-at-screenshot realtime-ss-button text-master"></i></a>';
                }),
                attributes: {
                    class: 'text-center'
                },
                width: showThumbnails && $scope.allowThumbnails ? 240 : 40
            });
        } else {
            removeScreenshotColumn('realtime-grid');
            removeScreenshotColumn('realtime-grid-with-thumbs');
        }

        return columns;
    }

    function removeScreenshotColumn(gridName) {
        const grid = localStorageService.get(gridName);
        if (grid) {
            try {
                const gridOptions = JSON.parse(grid);
                lodash.remove(gridOptions.columns, function (c) {
                    return c.title === msg.get('screenshot');
                });
                localStorageService.set(gridName, JSON.stringify(gridOptions));
            } catch (err) {
                console.error('ActivTrak Error: Invalid JSON for removeScreenshotColumn', grid);
            }
        }
    }

    function columnsToDisplay() {
        const columns = createColumns();

        if (!$scope.isReport) {
            const cols = lodash.filter(columns, function (c) {
                return c.field === 'user' || c.field === 'title' || c.field === 'url';
            });
            return cols;
        }

        return Object.keys(columns).map(function (key) {
            // turn object into array of its properties
            return columns[key];
        });
    }

    function createGridOptions() {
        return {
            autoBind: false,
            dataSource: realTimeReportSource,
            sortable: true,
            // resizable: true,
            reorderable: !(browserServiceFunctions.isMobileAgent() && $window.innerWidth < 768),
            dataBinding: onDataBinding,
            dataBound: onDataBound,
            columnReorder: function () {
                $timeout(function () {
                    gridPropertiesService.hideLeftBorder();
                });
            },
            filterable: $scope.isReport
                ? {
                      mode: 'row'
                  }
                : false,
            rowClass: showThumbnails ? ' realtime-row-with-thumbnails' : '',
            columns: gridServiceFunctions.loadSavedGridOptions(columnsToDisplay(), $scope.realTimeGridName),
            scrollable: {
                virtual: true
            },
            height: $scope.isReport ? atHelperFunctions.getGridHeight($scope.mainGridHeight()) : 244
        };
    }

    $scope.realTimeGridOptions = createGridOptions();

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap',
        onShow: function () {
            destroyTimer();
        },
        onHide: function () {
            destroyTimer();
            isDestroyed = false;
            poll();
        }
    });

    function screenshotViewerClosed() {
        showingScreenshot = false;
        poll();
    }

    $scope.screenshotViewerConfig = {
        isRealtime: true,
        closeViewer: screenshotViewerClosed
    };

    function poll() {
        const { users } = getReportFilters();
        const user = users.length ? users[0] : {};
        const filter = {
            userId: user.userId,
            userType: user.userType
        };

        if (!showingScreenshot && !$scope.pollPaused && !$scope.isSignup) {
            realTimeReportSource.read(filter).then(function () {
                createRealtimeTileList(realTimeReportSource.view());
            });
        } else {
            $timeout.cancel(pollTimer);
        }
    }

    function createRealtimeTileList(data) {
        $scope.realtimeTileList = {};
        data.forEach(function (row) {
            $scope.realtimeTileList[row.userId] = row;
        });
    }

    function setPollTimeout(delay, keepTryCounter) {
        if (!keepTryCounter) {
            $scope.tryCounter = 0;
        }

        if (!isDestroyed) {
            $timeout.cancel(pollTimer);
            pollTimer = $timeout(poll, delay, false);
        }
    }

    function poll400ErrorHandler() {
        if ($scope.tryCounter < max400tries - 1) {
            setPollTimeout(pollDelayAfterError, true);
        } else {
            destroyTimer();
            $scope.$emit('showNotification', {
                message: msg.get('realtimeRetrievingError'),
                color: 'danger',
                timeout: 0
            });
        }

        $scope.tryCounter += 1;
    }

    function destroyTimer() {
        if (typeof pollTimer !== 'undefined') {
            $timeout.cancel(pollTimer);
        }
        isDestroyed = true;
    }

    $scope.$on('$destroy', function () {
        destroyTimer();

        if ($scope.isReport) {
            realtimeScreenshotService.destroy();
        }
    });

    function configureGrid(grid, gridName) {
        if (!showTiles) {
            $scope.realTimeGrid = grid || $scope.realTimeGrid;
            $scope.realTimeGridName = gridName || $scope.realTimeGridName;

            if ($scope.realTimeGrid && $scope.realTimeGridName && $scope.isReport) {
                setGridOptions();
            }
        }
    }

    const bindWidget = function () {
        $timeout(function () {
            if (!$scope.isAccountCreationMode) {
                $timeout.cancel(pollTimer);
                poll();
            }
        });
    };
    $scope.bindRealtimeActivity = bindWidget;

    atDashboardWidgetService.registerWidget('realtimeActivity', 1, bindWidget);

    $scope.$on('restoreColumns', function () {
        if (!showThumbnails) {
            gridServiceFunctions.resetSavedGridOptions($scope.realTimeGrid, 'realtime-grid', createGridOptions);
        } else {
            gridServiceFunctions.resetSavedGridOptions(
                $scope.realTimeGrid,
                'realtime-grid-with-thumbs',
                createGridOptions
            );
        }

        if (!showTiles) {
            configureGrid();
        }
        $scope.realTimeGrid.isBound = false;
        gridPropertiesService.hideLeftBorder();
    });

    $scope.getGrid = function () {
        return $scope.realTimeGrid;
    };

    $scope.$on('selectColumns', function () {
        const grid = $scope.realTimeGrid;

        customUibModal.open({
            animation: false,
            template: gridColumnSelectorModalCtrlTemplate,
            controller: 'gridColumnSelectorModalCtrl',
            size: 'md',
            resolve: {
                grid: grid
            }
        });
    });

    $scope.showScreenshot = function (dataItem) {
        showingScreenshot = true;
        $scope.$broadcast('showRealtimeScreenshotEvent', {
            item: dataItem,
            callback: poll
        });
    };

    $scope.itemClicked = function (item, type) {
        if (type === 'user') {
            $state.go('app.reports.activitylog', {
                user: item.user
            });
        } else if (type === 'title') {
            $state.go('app.reports.activitylog', {
                title: item.title
            });
        } else if (type === 'url') {
            $state.go('app.reports.activitylog', {
                site: item.url
            });
        }
    };

    $scope.toggleIdleFilter = function () {
        $scope.showPassive = !$scope.showPassive;
        $scope.updateIdleFilter();
    };

    $scope.updateIdleFilter = function () {
        const isSmallWindow = atHelperFunctions.isSmallWindow();
        $scope.agentCountNumber = $scope.showPassive ? $scope.totalReportingAgents : $scope.totalActiveAgents;
        $scope.agentCountLabel = ($scope.showPassive ? 'Reporting' : 'Active') + (isSmallWindow ? '' : ' Agents');
        if ($scope.showPassive) {
            $scope.filter = {};
            const currentFilters = realTimeReportSource.filter();
            if (currentFilters) {
                lodash.remove(currentFilters.filters, function (f) {
                    return f.field === 'isIdle';
                });
                $scope.applyFilter(currentFilters.filters);
            }
        } else {
            $scope.applyFilter({
                isIdle: false
            });
        }
        realTimeReportSource.read().then(function () {
            createRealtimeTileList();
        });
    };

    function setGridOptions() {
        $scope.realTimeGridOptions = createGridOptions();
        if ($scope.realTimeGrid && $scope.realTimeGridOptions) {
            $scope.realTimeGrid.setOptions($scope.realTimeGridOptions);
        }
    }

    $scope.$on('RealtimeSettingsSet', function (e, settings) {
        pollDelay = (settings && settings.realtimePollDelay) || 2000;
        pollDelayAfterError = (settings && settings.realtimePollAfterError) || 10000;
        $scope.allowThumbnails = authorizationService.hasFeature(FeatureFlag.RealtimeThumbnails) && $scope.isReport;
        $scope.pollInBackground = false; // Forced to never poll in background.

        const shouldShowTiles =
            $scope.allowThumbnails &&
            localStorageService.get('showRealtimeTiles-' + AccountSettingsService.username) &&
            !atHelperFunctions.isSmallWindow();
        if (shouldShowTiles !== showTiles) {
            $scope.toggleDataView();
        }

        const shouldShowThumbnails =
            $scope.allowThumbnails &&
            localStorageService.get('showRealtimeThumbnails-' + AccountSettingsService.username) &&
            !atHelperFunctions.isSmallWindow();
        if (shouldShowThumbnails !== showThumbnails) {
            $scope.toggleShowThumbnails();
        }

        setGridOptions();
        if (!showTiles) {
            configureGrid();
        }
        pageSizeService.dataSourceReload(realTimeReportSource);
        $scope.realtimeSettingsLoaded = !!AccountSettingsService.realtimeSettings;
    });

    $timeout(function () {
        if ($state.current.name === 'app.realtime') {
            $scope.$emit('bindReport');
        }
    });

    $scope.$on('atWindowResized', function () {
        if ($state.current.name !== 'app.dashboard' && $state.current.name !== 'app.realtime') {
            return;
        }

        showThumbnails =
            $scope.allowThumbnails &&
            localStorageService.get('showRealtimeThumbnails-' + AccountSettingsService.username) &&
            !atHelperFunctions.isSmallWindow();
        showTiles =
            $scope.allowThumbnails &&
            localStorageService.get('showRealtimeTiles-' + AccountSettingsService.username) &&
            !atHelperFunctions.isSmallWindow();
        setGridOptions();
        if (!showTiles) {
            configureGrid();
        }
        pageSizeService.dataSourceReload(realTimeReportSource);
    });

    $scope.applyFilter = function (filter, shouldReplaceFilter) {
        if (shouldReplaceFilter) {
            $scope.filter = filter;
        }

        if (filter) {
            const processedFilters = {
                logic: 'and',
                filters: []
            };
            for (const field in filter) {
                if (!!filter[field] && typeof filter[field] === 'string') {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'contains',
                        value: filter[field]
                    });
                } else if (typeof filter[field] === 'boolean') {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'eq',
                        value: filter[field]
                    });
                }
            }
            $scope.filterData.appliedFilter = {};
            angular.copy(filter, $scope.filterData.appliedFilter);
            realTimeReportSource.filter(processedFilters);
            realTimeReportSource.read(filter).then(function () {
                $scope.realtimeList = realTimeReportSource.view();
            });
        }
    };

    $scope.filterData = {
        panelTitle: msg.get('filterScreenshots'),
        fields: [
            {
                label: msg.get('computer'),
                name: 'device'
            },
            {
                label: msg.get('user'),
                name: 'user'
            },
            {
                label: msg.get('title'),
                name: 'title'
            },
            {
                label: msg.get('executable'),
                name: 'exec'
            },
            {
                label: msg.get('url'),
                name: 'url'
            },
            {
                label: msg.get('agentFilter'),
                name: 'isIdle',
                type: 'buttons',
                filterOptions: [
                    {
                        buttonLabel: msg.get('showIdle'),
                        value: true
                    },
                    {
                        buttonLabel: msg.get('hideIdle'),
                        value: false
                    }
                ]
            }
        ],
        appliedFilter: $scope.filter
    };
    $scope.updateIdleFilter();

    /* START Highlight new rows */
    let newItemIds;
    let latestTime = moment('1970-01-01');

    function onDataBinding(arg) {
        // Find new rows - compare using time
        newItemIds = [];

        angular.forEach(arg.items, function (item) {
            const t = moment(item.time, 'M/D/YYYY h:mm:ss A');
            item.isNew = false;
            if (t.isAfter(latestTime)) {
                // arg.sender._data - current items - will be empty in the first pass
                if (arg.sender._data) {
                    newItemIds.push(item.uid);
                    item.isNew = true;
                }
                latestTime = t;
            }
        });

        if ($scope.realTimeGrid) {
            gridServiceFunctions.bindGridSave($scope.realTimeGrid, $scope.realTimeGridName);
        }
    }

    function onDataBound(e) {
        if (!newItemIds) {
            return;
        }
        toggleScrollbar(e);

        const grid = $('.k-grid');

        angular.forEach(newItemIds, function (id) {
            grid.find('tr[data-uid="' + id + '"]').addClass('at-highlight');
        });

        gridPropertiesService.bindFilterAutoComplete(e, true);
    }
    /* END Highlight new rows */

    $scope.togglePollPause = function (state) {
        $scope.pollPaused = state === undefined ? !$scope.pollPaused : state;

        if ($scope.pollPaused) {
            destroyTimer();
        } else {
            destroyTimer();
            isDestroyed = false;
            poll();
        }
    };

    $scope.$watch(
        function () {
            return $scope.realTimeGridNoThumbs;
        },
        function (grid) {
            if (grid) {
                $scope.realTimeGridWithThumbs = null;
                configureGrid(grid, 'realtime-grid');
            }
        }
    );

    $scope.$watch(
        function () {
            return $scope.realTimeGridWithThumbs;
        },
        function (grid) {
            if (grid) {
                $scope.realTimeGridNoThumbs = null;
                configureGrid(grid, 'realtime-grid-with-thumbs');
            }
        }
    );

    $scope.$watch(
        function () {
            return AccountSettingsService.overLimitOrOverStorage;
        },
        function (newValue) {
            $scope.overLimitOrOverStorage = newValue;
        }
    );

    $rootScope.$on('updateIdleFilter', function (e, showPassive) {
        $scope.showPassive = Boolean(showPassive);
        $scope.updateIdleFilter();
    });
}
