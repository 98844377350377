import { IRoute } from '../../../../../../models/IRoute';

export const appDevelopmentDesign: IRoute = {
  name: 'app.development.design',
  stateDefinition: {
    url: '/design',
    abstract: true,
    template: '<design-examples></design-examples>'
  }
};

export default appDevelopmentDesign;
