import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';

export const SearchBarContainer = styled('div')`
  display: flex;
  align-items: right;
  width: 100%;
`;

export const InfoContainer = styled('div')`
  margin: auto;
`;

export const HeaderBarActionButtons = styled(Box)`
  display: flex;
  width: 100%;
  padding-bottom: 4px;
`;

export const HeaderBarLeftActionButtons = styled(Box)`
  display: flex;
  flex: 2;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderBarRightActionButtons = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const AdvancedFilterButtonContainer = styled('div')`
  display: flex;
  position: relative;
  left: 15px;
  span {
    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;

export const AdvancedFilterClearButton = styled(IconButton)`
  left: -10px;
`;

export const ProgressContainer = styled('div')`
  height: 10px;
`;
