import React, { useState } from 'react';
import { IManagerSchedule } from '../models';
import {
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  Box,
  Dialog,
  Typography,
  DialogTitle
} from '@mui/material';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onReassign: (subscriptionId: string, newOwnerId: string) => any;
  managerSchedule: IManagerSchedule;
  users: IManagerSchedule[];
};

export const ReassignSubscriptionModal = (props: ComponentProps) => {
  const {
    open,
    onClose,
    onReassign = () => {
      /**/
    },
    managerSchedule,
    users
  } = props;

  const [selected, setSelected] = useState<string>('');

  const close = () => {
    onClose();
  };

  const handleReassign = async (): Promise<any> => {
    onReassign(managerSchedule.subscriptionId, selected);
    close();
  };
  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <DialogTitle>Reassign Subscription</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '480px' }}>
            <Box sx={{ fontSize: '16px', marginBottom: '16px' }}>
              {managerSchedule.subscriptionName}
            </Box>
            <Typography sx={{ fontWeight: 700, marginBottom: '8px' }}>
              Current Owner
            </Typography>
            <Box>
              <TextField
                value={managerSchedule.ownerName}
                disabled={true}
                fullWidth
                sx={{ '& legend': { display: 'none' } }}
              />
            </Box>
            <Typography sx={{ fontWeight: 700, marginTop: '16px' }}>
              New Owner
            </Typography>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="choose-owner-label">Choose an Owner</InputLabel>
                <Select
                  labelId="choose-owner-label"
                  label="Choose an Owner"
                  value={selected ? selected : ''}
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '175px'
                      }
                    }
                  }}
                  fullWidth
                >
                  {open &&
                    users?.map((option) => (
                      <MenuItem key={option.ownerId} value={option.ownerId}>
                        {option.ownerName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={close} autoFocus>
              Cancel
            </Button>
            <Button
              id="id_0a1e12bc-5ba1-4875-9f5c-8a3fd715b401"
              onClick={handleReassign}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  );
};
