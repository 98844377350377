export enum OperationalSystemsType {
  Apple = 'Apple',
  Chrome = 'Chrome',
  Windows = 'Windows'
}

export enum OperationalSystemsTypeShort {
  win = 'Win',
  mac = 'Mac'
}

export enum AgentVersionType {
  ga = 'GA',
  beta = 'Beta',
  unknown = 'Unknown'
}
