import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledBox } from '../StyledBoxes';
import { useAccountWizardContext } from '../../services';
import {
  AccountWizardContainer,
  StyledHeader,
  StyledSubtitle
} from '../../styles';

import stepsData from '../../constants/stepsData.json';
import {
  notifError,
  notifWarn,
  primaryAqua,
  primaryBlue,
  primaryPurple
} from '../../../common/constants';
import { useUIRouterQueryString } from '../../../common/hooks/useUIRouterQueryString';

export const AccountWizardStep1 = () => {
  const textData = stepsData.step1;
  const { firstVisit, setAccountWizardReferrer } = useAccountWizardContext();

  const isComingFromRoleAccess =
    useUIRouterQueryString().get('roleAccess') ?? false;

  useEffect(() => {
    if (
      isComingFromRoleAccess ||
      !window.location.hash.includes('firstVisit')
    ) {
      setAccountWizardReferrer('Role Access Page');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <StyledHeader>
        {firstVisit ? textData.titleA : textData.titleB}
      </StyledHeader>
      <StyledSubtitle>
        {firstVisit ? textData.subtitleA : textData.subtitleB}
      </StyledSubtitle>
      <AccountWizardContainer id="checkboxes">
        <StyledBox
          backgroundColors={[primaryAqua]}
          textArea={textData.styledBoxes[0].text}
          inputId="0:0"
          type="checkbox"
          name="remote"
          icons={{
            iconName: 'HybridIcon'
          }}
        />
        <StyledBox
          backgroundColors={[notifWarn]}
          textArea={textData.styledBoxes[1].text}
          inputId="0:1"
          type="checkbox"
          name="optimizeworkforce"
          icons={{
            iconName: 'OptimizeIcon'
          }}
        />
        <StyledBox
          backgroundColors={[primaryBlue]}
          textArea={textData.styledBoxes[2].text}
          inputId="0:2"
          type="checkbox"
          name="wellbeing"
          icons={{
            iconName: 'EmployeeExpIcon'
          }}
        />
        <StyledBox
          backgroundColors={[notifError]}
          textArea={textData.styledBoxes[3].text}
          inputId="0:3"
          type="checkbox"
          name="monitor"
          icons={{
            iconName: 'MonitorIcon'
          }}
        />
        <StyledBox
          backgroundColors={[primaryPurple]}
          textArea={textData.styledBoxes[4].text}
          inputId="0:4"
          type="checkbox"
          name="techusageroi"
          icons={{
            iconName: 'TechInvestIcon'
          }}
        />
      </AccountWizardContainer>
    </Box>
  );
};
