export const orgWidgetSubheader = {
  todaysProductivity: {
    top: 'of organization goal',
    bottom: 'has been met today'
  },
  workload: {
    top: 'Optimal',
    bottom: 'Workload'
  },
  productivityGoal: {
    top: 'Achieving',
    bottom: 'goal'
  }
};

export const teamWidgetSubheader = {
  todaysActivity: {
    top: 'Team members',
    bottom: 'active today'
  },
  todaysProductivityGoal: {
    top: ' of team goal has',
    bottom: 'been met today'
  },
  todaysProductivityUsers: {
    top: 'Team members met',
    bottom: 'their goal today'
  },
  productivityGoal: {
    top: 'Team',
    bottom: 'goal achievement'
  },
  workload: {
    top: 'Team',
    bottom: 'workload'
  }
};
