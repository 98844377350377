import React, { useState } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { useRoleAccessInitialData } from '../utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';

type ResetRoleAccessDlgProps = {
  open: boolean;
  onClose: () => void;
  setResetDefaultFilters: any;
};

export default function ResetRoleAccessDialog(props: ResetRoleAccessDlgProps) {
  const { open, onClose, setResetDefaultFilters } = props;

  const notificationService = useNotifications();

  const [saving, setSaving] = useState(false);

  const { resetDefault } = useRoleAccessInitialData();

  const handleSave = async (): Promise<void> => {
    try {
      setSaving(true);
      await resetDefault();
      setResetDefaultFilters(true);
      notificationService.success(`Success! Reset to Default values.`);
    } catch (response) {
      notificationService.error(
        response?.data?.error ?? 'Error Resetting Role Access'
      );
    } finally {
      onClose();
      setSaving(false);
    }
  };

  const close = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Reset to Default</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to reset all the role access permissions?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
