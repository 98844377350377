import { rowSelection, IRoleAccessViewModel } from '../../common/models';
import { rowType } from '../../common/enums';
import { RoleFilterName } from '../enums';
import { createRow } from './';

const noFilter: rowSelection = {
  disabled: false,
  checked: false,
  showReadOnly: false
};

export function GetFilterDataInitialData(
  term: number | null
): IRoleAccessViewModel[] {
  const rows: IRoleAccessViewModel[] = [];

  rows.push(
    createRow(
      RoleFilterName.OneDay,
      noFilter,
      noFilter,
      noFilter,
      noFilter,
      rowType.Item,
      ' Includes Today and Yesterday'
    ),
    createRow(
      RoleFilterName.OneWeek,
      noFilter,
      noFilter,
      noFilter,
      noFilter,
      rowType.Item,
      ' Includes Last 7 Days, This Week and Last Week'
    ),
    createRow(
      RoleFilterName.OneMonth,
      noFilter,
      noFilter,
      noFilter,
      noFilter,
      rowType.Item,
      !term || term > 1
        ? ' Includes Last 30 Days, This Month and Last Month'
        : ' Includes Last 30 Days and This Month'
    )
  );

  if (!term || term >= 12) {
    rows.push(
      createRow(
        RoleFilterName.OneYear,
        noFilter,
        noFilter,
        noFilter,
        noFilter,
        rowType.Item,
        ' Includes This Year and Last Year'
      )
    );
  }

  rows.push(
    createRow(
      RoleFilterName.CustomRange,
      noFilter,
      noFilter,
      noFilter,
      noFilter,
      rowType.Item,
      ''
    )
  );

  return rows;
}
