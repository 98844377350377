import { createDatesObject } from '../../../_reactivtrak/src/common/components/ReportFilters/utils/dateRange.utils';
import { ProductivityName } from '../../../_reactivtrak/src/common/components/ReportFilters/constants/productivityName';
import { setReportFilters } from '../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';

const storagePageKey = 'activTrak.storage-view-mode-';
const dashboardViewKey = 'activTrak.cc-dashboard-view-';

export const dateRanges = {
  last7Days: 'Last_7_Days'
};
export const storageModes = {
  users: 'users',
  computers: 'computers'
};

export function setDateRange(range) {
  setReportFilters({
    users: [
      {
        userId: -1,
        userType: 'AllUsers',
        name: 'All Users'
      }
    ],
    dates: createDatesObject(range),
    productivity: ProductivityName.All
  });
}

export function setStorageTab(tab, user) {
  const key = `${storagePageKey}${user}`;
  window.localStorage.setItem(key, JSON.stringify(tab));
}

export function setDashboardView(tab, user) {
  const key = `${dashboardViewKey}${user}`;
  window.localStorage.setItem(key, JSON.stringify(tab));
}

export function getDashboardView(user) {
  const key = `${dashboardViewKey}${user}`;
  return JSON.parse(window.localStorage.getItem(key)) || 0;
}
