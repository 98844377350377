import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import { MoreVert } from '@mui/icons-material';
import { PopoverDotMenuProps } from './PopoverDotMenu.props';

const PopoverDotMenu: React.FC<PopoverDotMenuProps> = ({
  id,
  popoverText,
  handlePopoverItemClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (e: React.MouseEvent<HTMLElement>) => {
    handlePopoverItemClick(e);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        id={id ?? ''}
        sx={{ padding: '2px 4px 4px 4px' }}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      {anchorEl && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List component="nav">
            <ListItem button onClick={handleItemClick}>
              {popoverText}
            </ListItem>
          </List>
        </Popover>
      )}
    </>
  );
};

export default PopoverDotMenu;
