import { Role, RoleAccessKeys } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';
import { teamPulsePromoTemplate as angularJsTemplate } from '../templates';

export const appTeampulsepromo: IRoute = {
  name: 'app.teampulsepromo',
  stateDefinition: {
    url: '/teampulsepromo',
    template: angularJsTemplate,
    controller: 'TeamPulsePromoCtrl',
    data: { pageTitle: 'Team Pulse', filter: null }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TeamPulse,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    excludedBundleFlags: [BundleFlag.TeamPulseReport]
  }
};

export default appTeampulsepromo;
