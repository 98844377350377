import { react2angular } from 'react2angular';
import { ComputerViewComponent } from '../../_reactivtrak/src/agents/views/Computer.view';
import { ComputersViewComponent } from '../../_reactivtrak/src/agents/views/Computers.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsComputerAgents', react2angular(ComputersViewComponent, []));

  angular
    .module('app')
    .component('settingsComputerAgentsDetail', react2angular(ComputerViewComponent, []));

}
