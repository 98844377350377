import { IFormLabel } from '../models/IConstants';
import { IFormErrorsState, IFormValues } from '../models/IRandomTypes';

export const FORM_LABELS: IFormLabel[] = [
  {
    id: 'accountSelector',
    label: 'Account Id or Email',
    errorValidationMessage: 'Please enter a valid account ID or email'
  },
  {
    id: 'reason',
    label: "Why are you signing into the user's account?",
    errorValidationMessage: 'Please enter a valid reason'
  },
  {
    id: 'tickets',
    label: 'List any tickets associated with this action',
    errorValidationMessage: 'Please enter a valid ticket number'
  }
];

export const INITIAL_FORM_VALUES: IFormValues = {
  accountSelector: '',
  reason: '',
  tickets: '',
  isImpersonating: false
};

export const INITIAL_FORM_ERRORS_STATE: IFormErrorsState = {
  accountSelector: false,
  reason: false,
  tickets: false
};
