import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { scheduleTemplate as angularJsTemplate } from '../../templates';

export const appSettingsSchedule: IRoute = {
  name: 'app.settings.schedule',
  stateDefinition: {
    url: '/settings/schedule/{id}',
    template: angularJsTemplate,
    params: { id: null, schedule: null },
    controller: 'ScheduleCtrl',
    data: { pageTitle: 'Schedule' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: []
  }
};

export default appSettingsSchedule;
