import React from 'react';
import { TeamFilter } from './TeamFilter';
import Stack from '@mui/material/Stack';
import { IReportFilters } from '../models/IReportFilters';
import { setReportFilters } from '../hooks/reportFiltersStore';
import { useTeamFilterState } from '../hooks/useTeamFilterState';

type ReportFiltersProps = {
  reportFilters: Partial<IReportFilters>;
};

//TODO: move this to TeamManagement dashboard as its own header component
const ReportFilters = (props: ReportFiltersProps) => {
  const { reportFilters } = props;
  const { groupId } = reportFilters;
  const { allTeamSuggestions } = useTeamFilterState();

  return (
    <Stack direction="row" spacing={2}>
      {allTeamSuggestions?.length > 0 && (
        <TeamFilter
          groupId={groupId}
          onSubmitFilter={setReportFilters}
          allTeamSuggestions={allTeamSuggestions}
        />
      )}
      {/* <div>Filter 2</div>
      <div>Filter 3</div> */}
    </Stack>
  );
};

export default ReportFilters;
