import _ from 'lodash';
import { setPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { mapPrivacySettingsDtoToPrivacySettings } from '../../common/utils/privacySettingsStore.utils';
import { fetchAdminData, putAdminData } from '../../common/helpers';
import {
  BASIC_PRIVACY_SETTINGS,
  ADVANCED_PRIVACY_SETTINGS,
  FULL_PRIVACY_SETTINGS
} from '../../common/constants/privacySettings';
import {
  IPrivacyFeatureSettings,
  IPrivacySettingsDto
} from '../../common/models/IPrivacySettings';
import { PrivacyLevels } from '../enums/privacyLevels';
import { ApiRoutes } from '../constants/routesApi';
import { PRIVACY_LEVEL_OPTIONS } from '../constants/privacyLevelSettings.const';
import { IPrivacyLevel } from '../models/IPrivacyLevelSettings';
import { PlanType } from '../../common/enums/PlanType';

export const getPrivacyLevelFromSettings = (
  settings: IPrivacyFeatureSettings
): string => {
  if (_.isEqual(settings, ADVANCED_PRIVACY_SETTINGS)) {
    return PrivacyLevels.Advanced;
  } else if (_.isEqual(settings, FULL_PRIVACY_SETTINGS)) {
    return PrivacyLevels.Full;
  } else if (_.isEqual(settings, BASIC_PRIVACY_SETTINGS)) {
    return PrivacyLevels.Basic;
  } else {
    console.error(
      `ActivTrak Error: Unknown privacy settings mapping, ${settings}. Defaulting to basic level.`
    );
    return PrivacyLevels.Basic;
  }
};

export const getSettingsFromPrivacyLevel = (
  level: string
): IPrivacyFeatureSettings => {
  switch (level) {
    case PrivacyLevels.Advanced:
      return ADVANCED_PRIVACY_SETTINGS;
    case PrivacyLevels.Full:
      return FULL_PRIVACY_SETTINGS;
    case PrivacyLevels.Basic:
      return BASIC_PRIVACY_SETTINGS;
    default:
      console.error(
        `ActivTrak Error: Unknown privacy level mapping, ${level}. Defaulting to basic privacy settings.`
      );
      return BASIC_PRIVACY_SETTINGS;
  }
};

export const canSeePrivacyLevelSettingsCard = (
  currentPlan: string,
  isTrialAccount: boolean,
  hasScreenDetailsEnabled: boolean
): boolean => {
  const allowedPlans = [
    PlanType.Advanced.toLowerCase(),
    PlanType.Premium.toLowerCase()
  ];
  const isEssentialsPlusOrProWithoutScreenDetails =
    [
      PlanType.EssentialsPlus.toLowerCase(),
      PlanType.Professional.toLowerCase()
    ].includes(currentPlan) && !hasScreenDetailsEnabled;
  const isProTrial =
    isTrialAccount && currentPlan === PlanType.Professional.toLowerCase();

  return (
    allowedPlans.includes(currentPlan) ||
    isEssentialsPlusOrProWithoutScreenDetails ||
    isProTrial
  );
};

export const filterPrivacyLevelOptions = (
  currentPlan: string,
  isTrial: boolean
): IPrivacyLevel[] => {
  const visibleOptions = PRIVACY_LEVEL_OPTIONS.filter((option) => {
    const isProTrial = isTrial && currentPlan === PlanType.Professional;
    const allowedPlans = option.allowed.map((x) => x.toLowerCase());

    const isAllowedPlan = allowedPlans.includes(currentPlan) || isProTrial;

    return isAllowedPlan;
  });

  return visibleOptions;
};

export const fetchPrivacyLevelSettings = async () => {
  const res = await fetchAdminData<IPrivacySettingsDto>({
    path: ApiRoutes.admin.privacySettingsUrl
  });

  const formattedSettings = mapPrivacySettingsDtoToPrivacySettings(res);
  setPrivacySettings(formattedSettings);

  const storedPrivacyLevel: string = getPrivacyLevelFromSettings(res);
  return storedPrivacyLevel;
};

export const putPrivacyLevelSettings = async (selectedLevel: string) => {
  const selectedSettings = getSettingsFromPrivacyLevel(selectedLevel);

  const formattedSettings =
    mapPrivacySettingsDtoToPrivacySettings(selectedSettings);
  setPrivacySettings(formattedSettings);

  await putAdminData<IPrivacyFeatureSettings>({
    path: ApiRoutes.admin.privacySettingsUrl,
    args: selectedSettings
  });
};
