export enum BundleFlag {
  DateFilterLimit = 'isDateFilterLimitEnabled',
  TopUsersReport = 'isTopUsersEnabled',
  ProductivityReport = 'isProductivityEnabled',
  WorkingHoursReport = 'isWorkingHoursEnabled',
  ActivityLog = 'isActivityLogEnabled',
  TopApplicationsReport = 'isTopAppsEnabled',
  TopWebsitesReport = 'isTopWebsitesEnabled',
  TopCategoriesReport = 'isTopCategoriesEnabled',
  TeamPulseReport = 'isTeamPulseEnabled',
  RealtimeActivity = 'isRealtimeActivityEnabled',
  ActivityAlarmType = 'isActivityAlarmEnabled',
  UsbAlarmType = 'isUSBAlarmEnabled',
  AuditAlarmType = 'isAuditAlarmEnabled',
  RiskLevelReport = 'isRiskLevelReportEnabled',
  AlarmLog = 'isAlertLogEnabled',
  AlarmConfiguration = 'isConfigurationEnabled',
  NotificationsLog = 'isNotificationsEnabled',
  ScreenshotsActions = 'isScreenshotsActionsEnabled',
  ScreenshotsLatestPerUserReport = 'isScreenshotsLatestPerUserEnabled',
  ScreenshotsHistoryReport = 'isScreenshotsHistoryEnabled',
  ApplicationTerminationAlarmActions = 'isAppTerminationActionsEnabled',
  PopupAlarmActions = 'isPopupActionsEnabled',
  WebsiteBlocking = 'isWebsiteBlockingEnabled',
  DetailedUrls = 'isDetailedURLsEnabled',
  TitleBars = 'isTitlebarsEnabled',
  RealtimeScreenViews = 'isRealtimeScreenViewsEnabled',
  EmbeddedWidgets = 'isEmbeddedWidgetsEnabled',
  BenchmarksGoalsInsightsReport = 'isBenchmarksGoalsEnabled',
  ActivityBreakdownInsightsReport = 'isActivityBreakdownEnabled',
  TimeOnTaskInsightsReport = 'isTimeOnTaskEnabled',
  ExecSummaryInsightsReport = 'isExecSummaryEnabled',
  TeamComparisonInsightsReport = 'isTeamComparisonEnabled',
  WorkEfficiencyInsightsReport = 'isWorkEfficiencyEnabled',
  WorkloadBalanceInsightsReport = 'isWorkloadBalanceEnabled',
  TechnologyUsageInsightsReport = 'isTechnologyUsageEnabled',
  PersonalInsightsReport = 'isPersonalInsightsEnabled',
  LocationAnalysisInsightsReport = 'isLocationAnalysisEnabled',
  CoachInsightsReport = 'isCoachEnabled',
  EmailSubscriptionsInsightsConfiguration = 'isEmailSubscriptionsEnabled',
  WeeklyDigestConfiguration = 'isWeeklyDigestEnabled',
  ActivityAutoClassification = 'isActivityAutoClassificationEnabled',
  GlobalClassification = 'isGlobalClassificationEnabled',
  ClassificationByGroup = 'isClassificationByGroupEnabled',
  ExportReportData = 'isExportDataEnabled',
  SsoMfaConfiguration = 'isSSOMFAEnabled',
  RoleBasedAccessConfiguration = 'isRoleBasedAccessEnabled',
  SecurityAuditNotifications = 'isSecurityAuditNotificationsEnabled',
  AutomatedAzureAD = 'isAutomatedAzureADEnabled',
  CalendarIntegration = 'isCalendarIntegrationEnabled',
  ConfigurableDataRetention = 'isConfigurableDataRetentionEnabled',
  CustomUI = 'isCustomUIEnabled',
  LicenseCountLimit = 'isLicenseCountLimitEnabled',
  DataHistoryLimit = 'isDataHistoryLimitEnabled',
  ActivConnect = 'isActivConnectEnabled',
  AdditionalIntegrations = 'isAdditionalIntegrationsEnabled',
  TechUsageInsightsReport = 'isTechUsageEnabled',
  ImpactAnalysisInsightsReport = 'isImpactAnalysisEnabled',
  DataRetention = 'isDataRetentionEnabled',
  Monitor = 'isMonitorEnabled',
  ScreenshotRedaction = 'isScreenshotRedactionEnabled',
  ScreenshotFlagging = 'isScreenshotFlaggingEnabled',
  Video = 'isVideoEnabled',
  AccountConfiguration = 'isAccountConfigurationEnabled',
  ClassificationConfiguration = 'isClassificationEnabled',
  AppAccessConfiguration = 'isAppAccessEnabled',
  RoleAccessConfiguration = 'isRoleAccessEnabled',
  ActivTrakProfile = 'isActivTrakProfileEnabled',
  WeeklyDigestSettings = 'isWeeklyDigestSettingsEnabled',
  GroupsConfiguration = 'isGroupsEnabled',
  UserAgentsConfiguration = 'isUserAgentsEnabled',
  ComputerAgentsConfiguration = 'isComputerAgentsEnabled',
  UserAliasesConfiguration = 'isUserAliasesEnabled',
  ComputerAliasesConfiguration = 'isComputerAliasesEnabled',
  DoNotTrackConfiguration = 'isDoNotTrackEnabled',
  SchedulingConfiguration = 'isSchedulingEnabled',
  TimeZoneConfiguration = 'isTimeZoneEnabled',
  LatestUpdatedConfiguration = 'isLatestUpdatedEnabled',
  ActivTrakAcademy = 'isActivTrakAcademyEnabled',
  HelpCenter = 'isHelpCenterEnabled',
  SupportTickets = 'isSupportTicketsEnabled',
  SupportType = 'isSupportTypeEnabled',
  AlarmGroups = 'isAlarmGroupsEnabled',
  PaidFunctionality = 'isPaidFunctionalityEnabled',
  PrivateModeControl = 'isPrivateModeControlEnabled',
  ScreenDetails = 'isScreenDetailsEnabled',
  AdminPanelHomepage = 'isAdminPanelEnabled',
  OrganizationOverviewHomepage = 'isOrganizationOverviewEnabled',
  TeamManagementHomepage = 'isTeamManagementEnabled',
  FavoriteNavigation = 'isFavoriteNavigationEnabled',
  ApiKeysGenerationConfiguration = 'isApiKeysEnabled',
  UserIdentityConfiguration = 'isUserIdentityEnabled',
  DataDogRumSessionReplay = 'isRumSessionReplayEnabled',
  SoftDeletes = 'isSoftDeletesEnabled',
  OfflineMeetingsData = 'isOfflineMeetingsDataEnabled',
  LocationData = 'isLocationDataEnabled',
  OfflineMeetingsPromo = 'isOfflineMeetingsPromoEnabled',
  LocationPromo = 'isLocationPromoEnabled',
  TopUsersReportOfflineMeetingsData = 'isTopUsersReportOfflineMeetingsDataEnabled',
  ProdReportAggChartOfflineMeetings = 'isProdReportAggChartOfflineMeetDataEnabled',
  ProdReportSingleDayChartOfflineMeetings = 'isProdReportSingleDayChartOfflineMeetDataEnabled',
  TodaysProductivityOutlook = 'isAiSummaryEnabled',
  LargeExport = 'isLargeExportEnabled',
  InsightsConfigMetrics = 'isInsightsConfigMetricsEnabled',
  InsightsConfigPrivacy = 'isInsightsConfigPrivacyEnabled',
  InsightsConfigLocation = 'isInsightsConfigLocationEnabled',
  InsightsConfigSubscriptions = 'isInsightsConfigSubscriptionsEnabled',
  InsightsConfigMetricsAverages = 'isInsightsConfigMetricsAveragesEnabled',
  InsightsConfigMetricsAcctGoals = 'isInsightsConfigMetricsAcctGoalsEnabled',
  InsightsConfigMetricsUtilization = 'isInsightsConfigMetricsUtilizationEnabled',
  InsightsConfigMetricsAttentionShift = 'isInsightsConfigMetricsAttentionShiftEnabled',
  InsightsConfigMetricsCollab = 'isInsightsConfigMetricsCollabEnabled',
  InsightsConfigMetricsProductiveHrs = 'isInsightsConfigMetricsProductiveHrsEnabled',
  InsightsConfigMetricsFocusHrs = 'isInsightsConfigMetricsFocusHrsEnabled',
  InsightsConfigMetricsCollabHrs = 'isInsightsConfigMetricsCollabHrsEnabled',
  InsightsReports = 'isInsightsEnabled'
}
