//Helper functions to create a basic callback loop

const subscribe = (subs) => (func) => {
  subs.push(func);
}

const notify = (subs) =>  (event, args) => {
  for(let i = 0; i < subs.length; i++) {
    subs[i](event, args);
  }
}


const withEvents = () => {
  const _subs = [];
  return {
    subscribe: subscribe(_subs),
    notify: notify(_subs),

    //This is a basic unsubscribe all
    unsubscribe: () => {
      _subs.length = 0;
    }
  }
}

export { withEvents }