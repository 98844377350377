import React, { useEffect } from 'react';
import { useExtensionSettingsState } from '../hooks/useExtensionSettingsState';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import { Grid, Typography, Button, Tooltip, Link, Box } from '@mui/material';
import {
  CheckboxSlim,
  ExtensionBoxBottom,
  ExtensionBoxMiddle,
  ExtensionBoxTop,
  FormControlLabelSlim
} from '../styles/accountConfiguration.styles';
import {
  chromeBrowser,
  edgeBrowser,
  firefoxBrowser,
  macPlatform,
  operaBrowser,
  windowsPlatform
} from '../constants/extension.const';
import { isPlatformAllEnabled } from '../utils/browserextensions.utils';

type BrowserExtensionsCardProps = {
  isSuperAdmin: boolean;
};

export const BrowserExtensionsCard = (props: BrowserExtensionsCardProps) => {
  const { isSuperAdmin } = props;

  const {
    initState,
    originalMacConfig,
    originalWindowsConfig,
    newWindowsConfig,
    newMacConfig,
    handleUpdate,
    toggleExtensionEnabled,
    toggleExtensionInstall,
    toggleAllEnabled
  } = useExtensionSettingsState();

  useEffect(() => {
    initState();
  }, [initState]);

  return (
    <ConfigurationCardWrapper title="ActivTrak Assist - Browser Extensions">
      <Box>
        <Typography sx={{ marginBottom: '16px' }}>
          ActivTrak Assist extensions improve URL detection in some scenarios
          and can be enabled locally via the agent installer or account-wide via
          the settings below (
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://support.activtrak.com/hc/en-us/articles/23374178820123-The-ActivTrak-Assist-Browser-Extension-For-Improved-Website-Activity-Capture"
          >
            read more
          </Link>
          ). Remote enablement below requires users have the{' '}
          <span style={{ fontWeight: 500 }}>
            Windows or macOS agent 8.4 or higher
          </span>
          , except where noted.
        </Typography>
        <Typography sx={{ marginBottom: '16px' }}>
          Extensions must both be enabled and installed. We recommend installing
          extensions using your browser group policies (GPO), rather than force
          installing the agent, due to possible policy conflicts.
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <ExtensionBoxTop>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  OS
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="This grants the browser extension the right to pass data (URLs) to the agent. Disabling blocks the extension’s functionality.">
                    <span>Enable</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="The agent can attempt to force install select extensions locally, but this may have unintended interactions with other browser policies.">
                    <span>Force Install</span>
                  </Tooltip>
                </Grid>
              </Grid>
            </ExtensionBoxTop>
          </Grid>
          <Grid item xs={12}>
            <ExtensionBoxMiddle>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <i className="fa fa-windows" title="Windows" />
                </Grid>
                <Grid container spacing={0} item xs={4}>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={isPlatformAllEnabled(newWindowsConfig)}
                          onChange={(event) => {
                            toggleAllEnabled(event, windowsPlatform);
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="All"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.chrome?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              chromeBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Chrome"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.edge?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              edgeBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Edge"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.firefox?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              firefoxBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Firefox"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.opera?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              operaBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Opera"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.chrome?.installationConfiguration
                              ?.install ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionInstall(
                              event,
                              chromeBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={
                            !isSuperAdmin ||
                            !(
                              newWindowsConfig?.chrome?.extensionEnabled ??
                              false
                            )
                          }
                        />
                      }
                      label="Chrome"
                    />
                    <br />
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.edge?.installationConfiguration
                              ?.install ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionInstall(
                              event,
                              edgeBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={
                            !isSuperAdmin ||
                            !(newWindowsConfig?.edge?.extensionEnabled ?? false)
                          }
                        />
                      }
                      label="Edge"
                    />
                    <br />
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newWindowsConfig?.firefox?.installationConfiguration
                              ?.install ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionInstall(
                              event,
                              firefoxBrowser,
                              windowsPlatform
                            );
                          }}
                          disabled={
                            !isSuperAdmin ||
                            !(
                              newWindowsConfig?.firefox?.extensionEnabled ??
                              false
                            )
                          }
                        />
                      }
                      label="Firefox (requires agent 8.5)"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ExtensionBoxMiddle>
          </Grid>
          <Grid item xs={12}>
            <ExtensionBoxBottom>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <i className="fa fa-apple" title="MacOS" />
                </Grid>
                <Grid item xs={4} container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={isPlatformAllEnabled(newMacConfig)}
                          onChange={(event) => {
                            toggleAllEnabled(event, macPlatform);
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="All"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newMacConfig?.chrome?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              chromeBrowser,
                              macPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Chrome"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newMacConfig?.edge?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              edgeBrowser,
                              macPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Edge"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="The ‘ActivTrak Assist’ Firefox extension is required for URL capture and is auto-installed.">
                      <FormControlLabelSlim
                        control={
                          <CheckboxSlim checked={true} disabled={true} />
                        }
                        label="Firefox"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newMacConfig?.opera?.extensionEnabled ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionEnabled(
                              event,
                              operaBrowser,
                              macPlatform
                            );
                          }}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Opera"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} container spacing={0}>
                  <Grid item xs={12}>
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newMacConfig?.chrome?.installationConfiguration
                              ?.install ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionInstall(
                              event,
                              chromeBrowser,
                              macPlatform
                            );
                          }}
                          disabled={
                            !isSuperAdmin ||
                            !(newMacConfig?.chrome?.extensionEnabled ?? false)
                          }
                        />
                      }
                      label="Chrome"
                    />
                    <br />
                    <FormControlLabelSlim
                      control={
                        <CheckboxSlim
                          checked={
                            newMacConfig?.edge?.installationConfiguration
                              ?.install ?? false
                          }
                          onChange={(event) => {
                            return toggleExtensionInstall(
                              event,
                              edgeBrowser,
                              macPlatform
                            );
                          }}
                          disabled={
                            !isSuperAdmin ||
                            !(newMacConfig?.edge?.extensionEnabled ?? false)
                          }
                        />
                      }
                      label="Edge"
                    />
                    <br />
                    <Tooltip title="The ‘ActivTrak Assist’ Firefox extension is required for URL capture and is auto-installed.">
                      <FormControlLabelSlim
                        control={
                          <CheckboxSlim checked={true} disabled={true} />
                        }
                        label="Firefox"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </ExtensionBoxBottom>
          </Grid>
        </Grid>
      </Box>
      {isSuperAdmin && (
        <Box sx={{ marginTop: '16px' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              JSON.stringify(originalMacConfig) ==
                JSON.stringify(newMacConfig) &&
              JSON.stringify(originalWindowsConfig) ==
                JSON.stringify(newWindowsConfig)
            }
            onClick={handleUpdate}
          >
            Save
          </Button>
        </Box>
      )}
    </ConfigurationCardWrapper>
  );
};
