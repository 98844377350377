'use strict';

angular
    .module('app')
    .controller('deleteModalController', DeleteModalController);

DeleteModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'warningMessage',
    'messagesService'
];

function DeleteModalController($scope, $uibModalInstance, warningMessage, msg) {
    $scope.message = warningMessage.message;
    $scope.deleteLabel = warningMessage.deleteLabel || msg.get('confirmAction');
    $scope.specificationLabel = warningMessage.specificationLabel;
    $scope.confirmTrackEvent = warningMessage.confirmTrackEvent || '';

    $scope.delete = function () {
        $uibModalInstance.close();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.isDeleteValid = function () {
        return $scope.deleteAcknowledgeChecked;
    };
}
