import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const TeamsComparisonCanvasBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingTop: '15px',
    height: '285px'
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: '25px',
    height: '315px'
  }
}));

export const UserIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '&.MuiBox-root p': {
    textWrap: 'nowrap',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '15px',
      '&.userIconPercentage': {
        fontWeight: 700
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
      '&.userIconPercentage': {
        fontSize: '18px'
      }
    }
  },
  '& svg': {
    marginLeft: '-10px'
  }
}));

export const UserIconContainerTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  fontSize: '16px !important',
  [theme.breakpoints.up('xs')]: {
    textAlign: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    textAlign: 'left'
  }
}));

export const UserIconButton = styled(IconButton)(() => ({
  transition: 'none !important',
  '&:hover': {
    borderRadius: '4px'
  },
  '&:disabled': {
    cursor: 'not-allowed !important',
    pointerEvents: 'auto !important'
  }
}));
