import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { schedulingTemplate as angularJsTemplate } from '../../templates';

export const appSettingsScheduling: IRoute = {
  name: 'app.settings.scheduling',
  stateDefinition: {
    url: '/settings/scheduling?selectedScheduleId',
    template: angularJsTemplate,
    params: { selectedScheduleId: null },
    controller: 'SchedulingCtrl',
    data: { pageTitle: 'Scheduling' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsScheduling;
