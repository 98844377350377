import { IExportStore } from '../models/IExport';

export const STATUS_POLLING_INTERVAL = 5000;

const EXPORT_STATUSES = {
  inProgress: [],
  completed: [],
  inError: []
};

export const EXPORT_STORE_DEFAULT: IExportStore = {
  exports: EXPORT_STATUSES,
  exportStatusInterval: null
};
