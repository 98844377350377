import { BehaviorSubject, Observable } from 'rxjs';

export interface INotificationService {
  success(message: string): void;
  error(message: string): void;
  getNotification(): Observable<INotification>;
}

type Severity = 'success' | 'info' | 'warning' | 'error';

export interface INotification {
  message: string;
  severity: Severity;
  duration: number;
  timestamp: number;
}

export class NotificationService implements INotificationService {
  private notification: BehaviorSubject<INotification>;

  constructor() {
    this.notification = new BehaviorSubject<INotification>(null);
  }

  public getNotification(): Observable<INotification> {
    return this.notification.asObservable();
  }

  public success(message: string): void {
    this.setNotification({
      message: message,
      severity: 'success',
      duration: 5000,
      timestamp: Date.now()
    });
  }

  public error(message: string): void {
    this.setNotification({
      message: message,
      severity: 'error',
      duration: 5000,
      timestamp: Date.now()
    });
  }

  private setNotification(notification: INotification) {
    this.notification.next(notification);

    setTimeout(() => {
      const n = this.notification.value;
      if (n && n.timestamp === notification.timestamp) {
        this.notification.next(null);
      }
    }, notification.duration);
  }
}
