import React, { useCallback, useEffect, useState } from 'react';
import { ChartOptions } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import { primaryColor, gray5 } from '../../../common/constants';
import { IRedirectParams, IWeeklyUsers, WidgetProps } from '../../models';
import {
  BarChartCopyDisplay,
  BaseLoadingView,
  ErrorMsgDisplay,
  OverallStatus,
  TemplateShadow,
  WidgetDialog,
  WidgetModal,
  WidgetModalList
} from '../templates';
import { LinkWithIcon } from '../../../common/components/Link/LinkWithIcon';
import { InfoButton } from '../../../common/components/Button/InfoButton';
import { BarChartLegend } from '../templates/BarChartLegend';
import { useLastUpdate, useProductivityGoalUsersState } from '../../hooks';
import {
  errorMsg,
  lastUpdateTooltipText,
  lastUpdateText,
  modalButtonLabel,
  modalTitle,
  productivityBarChartLegendItems,
  PRODUCTIVITY_BAR_CHART_COLORS,
  widgetTitle,
  noUsersMeetingCriteria,
  teamWidgetSubheader
} from '../../constants';
import { displayModalSubtitle, displayWidgetCopy } from '../../utils';
import {
  ChartTitle,
  LastUpdatedSC,
  ModalHeader,
  ModalSubtitle
} from '../../styles/templates.styles';
import { convertObjectToQs } from '../../../common/helpers';

export default function ProductivityGoalBarChart(props: Readonly<WidgetProps>) {
  const { reportFilters } = props;

  const [currentWeekUsers, setCurrentWeekUsers] = useState<IWeeklyUsers>();
  const [isCurrentWeekUsersOpen, setIsCurrentWeekUsersOpen] =
    useState<boolean>(false);

  const {
    init,
    header,
    hasNoResults,
    usersTrendsData,
    isLoading,
    isTileError,
    usersList,
    allUsersByWeek,
    updateChart
  } = useProductivityGoalUsersState();

  const lastUpdate = useLastUpdate(usersTrendsData?.lastUpdated);
  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery]
  );

  const getCurrentWeeklyUsers = useCallback(
    (index) => {
      const currentWeeklyUsers = allUsersByWeek[index];
      setCurrentWeekUsers(currentWeeklyUsers);
    },
    [allUsersByWeek]
  );

  const handleClose = useCallback(() => {
    setIsCurrentWeekUsersOpen(!isCurrentWeekUsersOpen);
  }, [isCurrentWeekUsersOpen]);

  const chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    datasets: {
      bar: {
        barPercentage: 0.9,
        categoryPercentage: 0.9
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        align: 'center',
        anchor: 'center',
        color: function (context) {
          return context.dataset.label === 'Missing' ? 'white' : primaryColor;
        },
        font: {
          size: 10,
          weight: 'bold'
        },
        formatter: (value) => {
          return value && value > 5 ? value.toFixed() + '%' : null;
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          minRotation: 45,
          padding: -2
        }
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false
        },
        suggestedMax: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
          padding: 5
        }
      }
    },
    responsive: true,
    onClick: (event, elements) => {
      if (elements[0]) {
        const i = elements[0].index;
        getCurrentWeeklyUsers(i);
        setIsCurrentWeekUsersOpen(!isCurrentWeekUsersOpen);
      }
    },
    onHover: (event, chartElement) => {
      if (event.native.target instanceof HTMLElement) {
        event.native.target.style.cursor = chartElement[0]
          ? 'pointer'
          : 'default';
      }
    }
  };

  const widgetCopy = displayWidgetCopy(header);
  const modalSubtitle = displayModalSubtitle(header);

  const params: IRedirectParams = {
    modelId: 'activinsights',
    pageId: 'activity_breakdown',
    subPageId: 'users',
    insightsRange: '30 day'
  };

  const zeroStateTooltipColors = [gray5, gray5];

  const tooltipColors = hasNoResults
    ? zeroStateTooltipColors
    : PRODUCTIVITY_BAR_CHART_COLORS;

  return (
    <TemplateShadow
      sx={{
        height: { xs: '100%', sm: '430px' }
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <WidgetLinkWrapper>
          <WidgetLinkTypography variant="h4">
            {widgetTitle.productivity}
          </WidgetLinkTypography>
        </WidgetLinkWrapper>
        <Box>
          <LinkWithIcon
            text="Activity Breakdown"
            redirectTo={'app.reports.insightsActivityBreakdown'}
            redirectParams={params}
            disabled={hasNoResults}
            id="0cafc491-940f-423b-800e-77c0f99095cf"
          />
        </Box>
      </Stack>
      {!isLoading && !isTileError && usersTrendsData && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'center', sm: 'left' }}
          >
            <Box
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
                width: { xs: 'auto', sm: '45%' }
              }}
            >
              <OverallStatus
                header={`${header}%`}
                subheader={teamWidgetSubheader.productivityGoal}
                tooltipLegend={{
                  items: productivityBarChartLegendItems,
                  colors: tooltipColors
                }}
                noResults={hasNoResults}
              />
              {!hasNoResults && <BarChartCopyDisplay widgetCopy={widgetCopy} />}
              {/* do not refactor WidgetModal usage into another common component */}
              <WidgetModal
                buttonLabel={modalButtonLabel.trendsProductivity}
                id="e0524d0b-2c06-4bf4-bcb6-5446285ae68c"
                isDisabled={hasNoResults}
              >
                <ModalHeader variant="h6">
                  {modalTitle.productivity}
                </ModalHeader>
                {modalSubtitle && (
                  <ModalSubtitle>{modalSubtitle}</ModalSubtitle>
                )}
                {!usersList || usersList.length === 0 ? (
                  <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
                ) : (
                  <WidgetModalList data={usersList} />
                )}
              </WidgetModal>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                width: { xs: '100%', sm: '55%' }
              }}
            >
              <ChartTitle variant="h5" sx={{ margin: '8px 0 0' }}>
                Goal achievement trends
              </ChartTitle>
              <Box sx={{ width: '100%', height: 280 }}>
                <Chart
                  type="bar"
                  data={usersTrendsData?.chartData}
                  options={chartOptions}
                  plugins={[ChartDataLabels as any]}
                  id="ec1bc9be-dd49-4318-a147-3e64d29606c9"
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <BarChartLegend
                  hasNoResults={hasNoResults}
                  id="3ccec363-9418-487b-9dcb-726c99482380"
                  updateChart={updateChart}
                  widgetData={usersTrendsData}
                />
              </Box>
            </Box>
          </Stack>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
              <InfoButton tooltipText={lastUpdateTooltipText} />
            </LastUpdatedSC>
          )}
          {/* do not refactor WidgetDialog usage into another common component */}
          <WidgetDialog isOpen={isCurrentWeekUsersOpen} onClose={handleClose}>
            <ModalHeader variant="h6">{modalTitle.productivity}</ModalHeader>
            {currentWeekUsers?.weekOf && (
              <ModalSubtitle>{currentWeekUsers.weekOf}</ModalSubtitle>
            )}
            <Box sx={{ minWidth: '400px' }}>
              {!currentWeekUsers || currentWeekUsers.users?.length === 0 ? (
                <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
              ) : (
                <WidgetModalList data={currentWeekUsers.users} />
              )}
            </Box>
          </WidgetDialog>
        </>
      )}

      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
