'use strict';

angular.module('app').controller('insightsSubscriptionModal', InsightsSubscriptionModal);

InsightsSubscriptionModal.$inject = [
    '$scope',
    '$rootScope',
    '$window',
    '$uibModalInstance',
    'modalData',
    'templateServiceFunctions',
    'notificationService',
    'AccountSettingsService',
    'atHelperFunctions',
    'envConfig'
];

function InsightsSubscriptionModal(
    $scope,
    $rootScope,
    $window,
    $uibModalInstance,
    modalData,
    templateServiceFunctions,
    notificationService,
    AccountSettingsService,
    atHelperFunctions,
    envConfig
) {
    var insightsService = require('_app/insights/insightsServiceFunctions.js');
    var { ScheduleControl } = require('_app/insights/models');
    var maxWidth = 523;

    $scope.dashboardID = modalData.dashboardID;
    $scope.dashboard = modalData.dashboard;
    $scope.showSubscriptions = true;
    $scope.showProgress = true;
    $scope.showNew = false;
    $scope.showConfirmDelete = false;
    $scope.showSettings = true;
    $scope.tab = 'scheduleSettings';
    $scope.selectAll = false;
    $scope.deleteCount = 0;
    $scope.singleEditableEmails =
        $scope.dashboardID === 'activinsights::weekly_user_dashboard' ||
        $scope.dashboardID === 'activinsights::personal_insights';
    $scope.subscribeOthers = false;
    $scope.selectedEmailValid = true;
    $scope.textWidth = maxWidth;
    $scope.isEditingName = false;
    $scope.filtersCollapsed = true;
    $scope.filtersChevronDirection = 'expand_more';
    $scope.emailCollapsed = true;
    $scope.emailChevronDirection = 'expand_more';

    $scope.formatFilters = function (key, value) {
        if (key.toLowerCase() === 'team') {
            if (value.trim().length === 0) {
                return `${key}: Any Value`;
            }
            var teams = value.split(',');
            if (teams.length > 4) {
                var size = teams.length
                return `${key}: ${teams.splice(0,4).join(', ')}..+${size - 4}`
            }
            else {
                return `${key}: ${teams.join(', ')}`;
            }
        }
        else {
            return `${key}: ${value || 'Any Value'}`;
        }
    };

    $scope.toggleFiltersCollapse = function () {
        $scope.filtersCollapsed = !$scope.filtersCollapsed;
        if ($scope.filtersCollapsed) {
            $scope.filtersChevronDirection = 'expand_more';
        }
        else {
            $scope.filtersChevronDirection = 'expand_less';
        }
        $scope._addShadowClasses();
        $scope._scrollToBottom();
    };

    $scope.toggleEmailCollapse = function () {
        $scope.emailCollapsed = !$scope.emailCollapsed;
        if ($scope.emailCollapsed) {
            $scope.emailChevronDirection = 'expand_more';
        }
        else {
            $scope.emailChevronDirection = 'expand_less';
        }
        $scope._addShadowClasses();
        $scope._scrollToBottom();
    };

    $scope._addShadowClasses = function () {
        if (!$scope.filtersCollapsed || !$scope.emailCollapsed) {
            $('#shadow-container-top').addClass('top-shadow');
            $('#shadow-container-bottom').addClass('bottom-shadow');
        }
        else {
            $('#shadow-container-top').removeClass('top-shadow');
            $('#shadow-container-bottom').removeClass('bottom-shadow');
        }
    };

    $scope._scrollToBottom = function () {
        $('#scrollable-section').animate({
            scrollTop: 600
        }, 500);
    };

    $scope.control = new ScheduleControl(
        envConfig,
        $window,
        insightsService,
        notificationService,
        AccountSettingsService,
        $scope.dashboard,
        $scope.singleEditableEmails
    );

    $scope.noDataTemplate = `<div class="subscription-empty-list">Only application users can be added as recipients</div>`;
    $scope.itemTemplate = `<div class="subscription-emails"><label>#:data#</label></div>`;

    $scope.styleHelper = function () {
        var items = this.ul.find('li');
        setTimeout(function () {
            items.each(function () {
                var element = $(this);
                element.addClass('subscription-emails');
            });
        });
        this.noData.addClass('subscription-no-data');
    };

    $scope.menuOptions = {
        direction: 'left',
        openOnClick: 'true'
    };

    var getGridColumns = function () {
        return [
            {
                field: 'selected',
                title: 'Delete',
                template:
                    '<div class="checkbox"><input type="checkbox" ng-model="dataItem.selected" id="sso-selectbox-{{dataItem.id}}" ng-click="setDeleteEnabled($event)"/><label for="sso-selectbox-{{dataItem.id}}" class="m-l-8-i m-t-2-i"></label></div>',
                attributes: {
                    class: 'text-center'
                },
                width: '40px'
            },
            {
                field: 'name',
                title: 'Schedule',
                width: '350px',
                template: '<div class="schedule-name">{{dataItem.name}}</div>'
            },
            {
                command: [
                    {
                        template: `
                        <ul kendo-menu
                            k-options="menuOptions"
                            k-rebind="menuOptions"
                            k-on-select="menuHelper(kendoEvent)"
                            k-on-deactivate="menuHelper(kendoEvent)"
                            class="insights-table-options-menu"
                        >
                            <li>
                                <i class="material-icons">more_vert</i>
                                <ul>
                                    <li ng-click="sendNow(dataItem, true)">
                                        Send
                                    </li>
                                    <li ng-click="editSchedule(dataItem)">
                                        Edit
                                    </li>
                                    <li ng-click="showDeleteConfirm(dataItem)">
                                        Delete
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    `
                    }
                ],
                attributes: {
                    class: 'text-center schedule-commands '
                },
                width: '40px'
            },
        ];
    };

    $scope.validateEmail = function () {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($scope.control.selectedEmail)) {
            $scope.selectedEmailValid = true;
            $scope.control.selectedEmails[0] = $scope.control.selectedEmail;
        } else {
            $scope.selectedEmailValid = false;
        }
    };

    $scope.clearEmail = function () {
        $scope.control.selectedEmail = '';
        $scope.selectedEmailValid = false;
    };

    $scope.editSchedule = function (schedule) {
        $scope.showProgress = true;
        $scope.control
            .getScheduleById(schedule.id)
            .then(function (hydratedSchedule) {
                $scope.control.setScheduleInfo(hydratedSchedule);
                $scope.calculateTitleWidth();
                $scope.openNewSubscription();
                $scope.showProgress = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.showProgress = false;
            });
    };

    $scope.confirmDelete = function () {
        $scope.showConfirmDelete = false;
        $scope.showSubscriptions = true;
        $scope.deleteSelected();
    };

    $scope.sendNow = function (schedule, useId) {
        $scope.showProgress = true;
        if (useId) {
            $scope.control
                .getScheduleById(schedule.id)
                .then(function (hydratedSchedule) {
                    $scope.control.sendNow(hydratedSchedule, useId).then(function () {
                        $scope.showProgress = false;
                        atHelperFunctions.safeApply($rootScope);
                    });
                })
                .catch(function () {
                    $scope.showProgress = false;
                });
        }
        else {
            $scope.control.sendNow(schedule, useId).then(function () {
                $scope.showProgress = false;
                atHelperFunctions.safeApply($rootScope);
            });
        }
    };

    $scope.showDeleteConfirm = function (schedule) {
        $scope.showConfirmDelete = true;
        $scope.showSubscriptions = false;
        $scope.schedulesToDelete = [];
        if (schedule) {
            $scope.schedulesToDelete.push({ id: schedule.id, dashboard: schedule.dashboard });
        } else {
            var scheduleData = schedulesDataSource.data();
            scheduleData.forEach(function (schedule) {
                if (schedule.selected) {
                    $scope.schedulesToDelete.push({ id: schedule.id, dashboard: schedule.dashboard });
                }
            });
        }
    };

    $scope.deleteSelected = function () {
        $scope.showProgress = true;
        $scope.control
            .deleteSchedules($scope.schedulesToDelete)
            .then(function () {
                schedulesDataSource.read();
                if (schedulesDataSource.data() > 0) {
                    $scope.showSubscriptions = true;
                    $scope.showNew = false;
                }
                $scope.showProgress = false;
            })
            .catch(function () {
                $scope.showProgress = false;
            });
    };

    $scope.saveSchedule = function () {
        $scope.showProgress = true;
        $scope.control.saveSchedule().then(function () {
            $scope.showProgress = false;
            $scope.dismiss();
            atHelperFunctions.safeApply($rootScope);
        });
    };

    $scope.menuHelper = function (e) {
        var elemClicked = $(e.item).children('.k-link').text();
        if (e.type === 'deactivate' || elemClicked !== 'more_vert') {
            e.sender.close(e.item);
            return;
        }

        var rowHeight;
        var itemOffsetTop = e.item.offsetTop - 2; //2 is the padding or margin of container

        if (schedulesDataSource.data().length === 1) {
            e.item.parentElement.classList.add('menu-top-short');
            return;
        }
        if (schedulesDataSource.data().length === 2) {
            rowHeight = 35;
            if (itemOffsetTop < rowHeight) {//first item; menu top is default
                e.item.parentElement.classList.add('menu-top-short');
                e.item.parentElement.classList.remove('menu-middle-short');
            }
            else {
                e.item.parentElement.classList.add('menu-middle-short');
                e.item.parentElement.classList.remove('menu-top-short');
            }
            return;
        }

        rowHeight = 45;
        var lastItemOffsetTop = schedulesDataSource.data().length * rowHeight - rowHeight;//Each row has 45 height;
        if (itemOffsetTop < rowHeight) {//first item; menu top is default
            e.item.parentElement.classList.add('menu-top');
            e.item.parentElement.classList.remove('menu-bottom');
            e.item.parentElement.classList.remove('menu-middle');
        }
        else if (itemOffsetTop >= lastItemOffsetTop){
            e.item.parentElement.classList.add('menu-bottom');
            e.item.parentElement.classList.remove('menu-top');
            e.item.parentElement.classList.remove('menu-middle');
        }
        else {
            e.item.parentElement.classList.add('menu-middle');
            e.item.parentElement.classList.remove('menu-top');
            e.item.parentElement.classList.remove('menu-bottom');
        }
    };

    $scope.emailsDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                $scope.control.getShareableEmails().then(function (result) {
                    options.success(result);
                });
            }
        }
    });

    var pageSize = 200;
    var schedulesDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                var subscribeOthersPromise = insightsService.getAccountSettings(envConfig.apiUrl(), notificationService);
                var schedulesPromise = insightsService.getInsightsSchedules(
                    envConfig.apiUrl(),
                    $scope.dashboardID,
                    notificationService
                );
                var metricsPromise = insightsService.getMetricValues(envConfig.apiUrl(), notificationService);

                Promise.all([subscribeOthersPromise, schedulesPromise, metricsPromise]).then(function (data) {
                    $scope.subscribeOthers = data[0].accountSettings.allowSubscribeOthers;
                    $scope.control.setMetricValues(data[2].metricValues);

                    if (!data[1].results.data || data[1].results.data.length === 0) {
                        $scope.showSubscriptions = false;
                        $scope.showNew = true;
                    }
                    else {
                        $scope.results = data[1].results.data.sort(function (a, b) {
                            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                        });
                        options.success($scope.results);

                        if ($scope.results.length > pageSize) {
                            $scope.showPager = true;
                        }
                    }
                    $scope.showProgress = false;
                    atHelperFunctions.safeApply($rootScope);
                });
            }
        },
        pageSize: pageSize,
        serverPaging: false,
        total: Math.ceil($scope.results/pageSize),
        schema: {
            model: {
                id: 'id',
                selected: false,
                fields: {
                    id: {
                        editable: false,
                        nullable: false
                    },
                    name: {
                        editable: false,
                        nullable: false
                    },
                    selected: {
                        defaultValue: false
                    }
                }
            }
        }
    });

    $scope.scheduleGridOptionsWithPager = {
        dataSource: schedulesDataSource,
        scrollable: true,
        pageable: true,
        headerTemplate: `
            <div class="checkbox">
                <input type="checkbox" ng-model="selectAll" id="selectbox-all" ng-click="setDeleteAll($event)"/>
                    <label for="selectbox-all" class="m-l-8-i m-t-2-i"></label>
                <button class="btn mat basic" ng-click="deleteSelected()" ng-disabled="deleteCount==0">DELETE</button>
            </div>
        `,
        columns: getGridColumns()
    };

    $scope.scheduleGridOptions = {
        dataSource: schedulesDataSource,
        scrollable: true,
        headerTemplate: `
            <div class="checkbox">
                <input type="checkbox" ng-model="selectAll" id="selectbox-all" ng-click="setDeleteAll($event)"/>
                    <label for="selectbox-all" class="m-l-8-i m-t-2-i"></label>
                <button class="btn mat basic" ng-click="deleteSelected()" ng-disabled="deleteCount==0">DELETE</button>
            </div>
        `,
        columns: getGridColumns()
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.openNewSubscription = function () {
        $scope.showSubscriptions = false;
        $scope.showNew = true;
    };

    $scope.setDeleteAll = function (event) {
        var scheduleData = schedulesDataSource.data();
        scheduleData.forEach(function (schedule) {
            schedule.selected = event.target.checked;
        });
        if (event.target.checked) {
            $scope.deleteCount = scheduleData.length;
        } else {
            $scope.deleteCount = 0;
        }
    };

    $scope.setDeleteEnabled = function (event) {
        if (event.target.checked) {
            $scope.deleteCount++;
        } else {
            $scope.deleteCount--;
            $scope.selectAll = false;
        }
    };

    $scope.calculateTitleWidth = function () {
        $scope.textWidth =
            templateServiceFunctions.getTextWidth($scope.control.subscriptionName, '14px "Roboto", sans-serif') + 27;
        if ($scope.textWidth > maxWidth) {
            $scope.textWidth = maxWidth;
        }
    };

    $scope.calculateTitleWidth();

    $scope.setIsEditingName = function (editing) {
        $scope.isEditingName = editing;
        if ($scope.textWidth > maxWidth) {
             $scope.textWidth = maxWidth;
        }
    };

    $scope.editNameKeyPressed = function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key === 13) {
            e.target.blur();
        }
    };

    $scope.$watch(
        function () {
            return $scope.dashboard.embeddedFilters;
        },
        function () {
            $scope.control.dashboard = $scope.dashboard;
        }
    );

    $scope.$watch(
        function () {
            return $scope.control.subscriptionName;
        },
        function () {
            $scope.control.dashboard = $scope.dashboard;
        }
    );
}
