import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { useOauthState } from '../hooks/useOauthState';
import { ConfigModalProps, IIntegrationConfigurationDto } from '../models';
import { IntegrationNotifications } from '../constants/IntegrationNotifications';
import {
  IntegrationCodes,
  IntegrationTypeCodes
} from '../constants/IntegrationCodes';
import {
  addIntegrationConfiguration,
  deleteIntegrationInstance
} from '../utils/integrationInstance.utils';
import {
  ACTIVTRAK_DELETE_GROUP,
  ACTIVTRAK_DNT_GROUP,
  ACTIVTRAK_GROUP
} from '../constants/configuration';
import { SettingsIcon, WindowsIconColor } from '../../common/assets/Icons';
import { clientOAuthConfig } from '../constants/Oauth';
import { mediumFontWeight } from '../../common/constants';
import { AzureADValidationMessages } from '../constants/IntegrationConfigurationValidationMessages';

export const ConfigureAzureADModal = ({
  setOpenConfigDialog,
  configState
}: ConfigModalProps) => {
  const { isLoading, authorized, startOauthFlow, focusPopup } = useOauthState({
    clientOAuthConfig: clientOAuthConfig[IntegrationCodes.AzureAD]
  });

  const [group, setGroup] = useState('');
  const [dntGroup, setDntGroup] = useState('');
  const [deleteGroup, setDeleteGroup] = useState('');

  const handleOauth = useCallback(async () => {
    await configState.initiateOauth(
      IntegrationCodes.AzureAD,
      startOauthFlow,
      configState.traySolutionInstanceId,
      configState.instanceId
    );
  }, [
    startOauthFlow,
    configState.traySolutionInstanceId,
    configState.instanceId
  ]);

  const onGroupChange = useCallback((event) => {
    setGroup(event.target?.value);
  }, []);

  const onDntGroupChange = useCallback((event) => {
    setDntGroup(event.target?.value);
  }, []);

  const onDeleteGroupChange = useCallback((event) => {
    setDeleteGroup(event.target?.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (!group && !dntGroup && !deleteGroup) {
        const errorMessage = !authorized
          ? `${AzureADValidationMessages.AuthorizationRequired} ${AzureADValidationMessages.AtLeastOneFieldRequired}`
          : AzureADValidationMessages.AtLeastOneFieldRequired;
        configState.setIntegrationConfigurationNotification({
          msg: errorMessage,
          type: 'error'
        });
        return;
      }

      if (!authorized) {
        configState.setIntegrationConfigurationNotification({
          msg: AzureADValidationMessages.AuthorizationRequired,
          type: 'error'
        });
        return;
      }

      const configurations: IIntegrationConfigurationDto[] = [];

      if (group) {
        const configuration: IIntegrationConfigurationDto = {
          instanceid: configState.instanceId,
          integrationcode: IntegrationCodes.AzureAD,
          name: ACTIVTRAK_GROUP,
          value: group,
          typecode: IntegrationTypeCodes.String
        };
        configurations.push(configuration);
      }

      if (dntGroup) {
        const configuration: IIntegrationConfigurationDto = {
          instanceid: configState.instanceId,
          integrationcode: IntegrationCodes.AzureAD,
          name: ACTIVTRAK_DNT_GROUP,
          value: dntGroup,
          typecode: IntegrationTypeCodes.String
        };
        configurations.push(configuration);
      }

      if (deleteGroup) {
        const configuration: IIntegrationConfigurationDto = {
          instanceid: configState.instanceId,
          integrationcode: IntegrationCodes.AzureAD,
          name: ACTIVTRAK_DELETE_GROUP,
          value: deleteGroup,
          typecode: IntegrationTypeCodes.String
        };
        configurations.push(configuration);
      }

      await Promise.all(
        configurations.map((config) => addIntegrationConfiguration(config))
      );

      await configState.enableIntegration(
        configState.instanceId,
        IntegrationNotifications.Initiated
      );
    } catch (error) {
      configState.setIntegrationConfigurationNotification({
        msg: IntegrationNotifications.GenericError,
        type: 'error'
      });
      console.error(
        `ActivTrak Error: ${IntegrationNotifications.GenericError} - ${error?.message}`
      );
    }
    if (configState.trayMigrationInProgress)
      configState.setTrayMigrationInProgress(false);
    setOpenConfigDialog(false);
  }, [
    authorized,
    configState,
    group,
    deleteGroup,
    dntGroup,
    setOpenConfigDialog
  ]);

  const handleCancel = useCallback(() => {
    if (authorized || configState.instanceId) {
      deleteIntegrationInstance(configState.instanceId);
    }

    configState.trayMigrationInProgress
      ? configState.setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.MigrationCancelled,
          type: 'success'
        })
      : configState.setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Cancelled,
          type: 'success'
        });

    if (configState.trayMigrationInProgress)
      configState.setTrayMigrationInProgress(false);
    configState.setInstanceId(null);
    setOpenConfigDialog(false);
  }, [authorized, configState, setOpenConfigDialog]);

  return (
    <>
      <DialogContent onClick={isLoading ? focusPopup : undefined}>
        {!authorized && (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ paddingBottom: '10px', fontWeight: mediumFontWeight }}
            >
              Please gather all required information before proceeding.
              Incorrect or missing information may cause integration error.
            </Typography>
            <Button
              variant="outlined"
              sx={{ height: '36px', marginRight: '20px' }}
              onClick={handleOauth}
              disabled={isLoading || configState.isDeletingTrayInstance}
              startIcon={<WindowsIconColor />}
            >
              <span style={{ padding: '16px' }}>
                Authenticate with Microsoft
              </span>
            </Button>
            {(isLoading || configState.isDeletingTrayInstance) && (
              <CircularProgress size={20} />
            )}
          </Box>
        )}
        {authorized && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1">
                Azure AD Integration authorized.
              </Typography>
            </Grid>
            <Grid item>
              <a
                href="https://myapplications.microsoft.com/"
                target="_blank"
                rel="noreferrer"
              >
                <SettingsIcon />
              </a>
            </Grid>
          </Grid>
        )}
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1">
            Identify at least 1 group for use by the integration. Must be exact
            group name.
          </Typography>
          <Stack direction="column" spacing={3} marginTop={3}>
            <TextField
              label="ActivTrak Group"
              variant="outlined"
              onChange={onGroupChange}
              value={group}
              fullWidth
              sx={{ mb: 3 }}
              color="primary"
              inputProps={{ maxLength: 1000 }}
            />
          </Stack>
          <Stack direction="column" spacing={3} marginTop={3}>
            <TextField
              label="ActivTrak Do Not Track Group"
              variant="outlined"
              onChange={onDntGroupChange}
              value={dntGroup}
              fullWidth
              sx={{ mb: 3 }}
              color="primary"
              inputProps={{ maxLength: 1000 }}
            />
          </Stack>
          <Stack direction="column" spacing={3} marginTop={3}>
            <TextField
              label="ActivTrak Delete Group"
              variant="outlined"
              onChange={onDeleteGroupChange}
              value={deleteGroup}
              fullWidth
              sx={{ mb: 3 }}
              color="primary"
              inputProps={{ maxLength: 1000 }}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Finish
        </Button>
      </DialogActions>
    </>
  );
};
