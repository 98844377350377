import { IRoute } from '../../../../models/IRoute';

export const verify: IRoute = {
  name: 'verify',
  stateDefinition: {
    url: '/verify?vtoken&email',
    controller: 'VerifyCtrl',
    resolve: {
      accountSettings: [
        'accountApiHelper',
        'loginService',
        function (accountApiHelper, loginService) {
          if (!loginService.isLoggedIn()) {
            return {};
          }

          const settings = accountApiHelper.loadSettings();
          return settings;
        }
      ]
    }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default verify;
