import { ExtensionConfiguration } from '../models/ExtensionConfiguration';

export const windowsPlatform = 'WINDOWS';
export const macPlatform = 'MAC';

export const chromeBrowser = 'chrome';
export const edgeBrowser = 'edge';
export const operaBrowser = 'opera';
export const firefoxBrowser = 'firefox';

export const defaultMacConfig: ExtensionConfiguration = {
  opera: null,
  chrome: null,
  edge: null,
  firefox: null,
  platform: macPlatform
};

export const defaultWindowsConfig: ExtensionConfiguration = {
  opera: null,
  chrome: null,
  edge: null,
  firefox: null,
  platform: windowsPlatform
};

export const defaultBrowserConfig = {
  extensionEnabled: false,
  installationConfiguration: null
};

export const extensionApi = {
  fetchUrl: '/api/agent/extensions',
  putUrl: 'api/agent/extensions/0'
};
