import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  baseURL,
  defaultStatus,
  getStatus,
  pollingDuration
} from './statusPageConstants';
import { IStatusResponse } from './IStatusResponse';

const { createContext, useContext } = React;

const apiClient = axios.create({
  baseURL: baseURL
});

interface IStatuspageProvider {
  status: string;
  isLoading: boolean;
}

const StatuspageContext = createContext<IStatuspageProvider>(null);

type Props = {
  children: React.ReactNode;
};

export const StatuspageProvider = (props: Props) => {
  const { children } = props;
  const [status, setStatus] = useState<string>(defaultStatus);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let timeoutId = null;
    const updateStatus = async () => {
      setIsLoading(true);
      clearTimeout(timeoutId);
      try {
        const { data } = (await apiClient.get(getStatus)) as IStatusResponse;
        const newStatus = data?.status?.indicator;

        if (newStatus !== status) {
          setStatus(newStatus || defaultStatus);
        }
      } finally {
        setIsLoading(false);
      }
      timeoutId = setTimeout(updateStatus, pollingDuration);
    };

    updateStatus();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [status]);

  const value = { status, isLoading };

  return (
    <StatuspageContext.Provider value={value}>
      {children}
    </StatuspageContext.Provider>
  );
};

export const useStatuspage = (): IStatuspageProvider => {
  return useContext(StatuspageContext);
};
