import { apiService } from '../../helpers';
import { getDefaultConfig, getWebApiConfig } from '../../config';
import {
  IAccountTimezoneDto,
  IPostAccountTimezoneDto,
  ITimezoneDto,
  ILocalTimezoneDto,
  IPostLocalTimezoneDto,
  ITimezoneService,
  IAccountTimezonePost
} from './';

export const timezoneService = (): ITimezoneService => {
  return {
    fetchAllTimezones: async (): Promise<ITimezoneDto[]> => {
      const response = await apiService.get<ITimezoneDto[]>(
        '/api/settings/timezones',
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },

    fetchAccountTimezone: async (): Promise<IAccountTimezoneDto> => {
      const response = await apiService.get<IAccountTimezoneDto>(
        '/api/settings/timezone',
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },

    postAccountTimezone: async (
      payload: IAccountTimezonePost
    ): Promise<IPostAccountTimezoneDto> => {
      const response = await apiService.post<IPostAccountTimezoneDto>(
        '/api/settings/timezone',
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
      return response?.data;
    },

    fetchShowLocalTimezone: async (): Promise<ILocalTimezoneDto> => {
      const response = await apiService.get<ILocalTimezoneDto>(
        '/admin/v1/accountsettings/showlocaltimezone',
        {
          ...getWebApiConfig()
        }
      );
      return response?.data;
    },

    postShowLocalTimezone: async (
      payload: ILocalTimezoneDto
    ): Promise<IPostLocalTimezoneDto> => {
      const response = await apiService.post<IPostLocalTimezoneDto>(
        '/admin/v1/accountsettings/showlocaltimezone',
        {
          ...getWebApiConfig(),
          data: payload
        }
      );
      return response?.data;
    }
  };
};
