import { apiService } from '../../common/helpers';
import { getAccountApiConfig } from '../../common/config';
import { IApiKeyService, IApiKeyPayload, IApiKeysDto, INewApiKeyDto } from '../models';

export const ApikeyService = (): IApiKeyService => {
  return {
    createPublicApiKey: async (payload: IApiKeyPayload): Promise<INewApiKeyDto> => {
      const response =  await apiService.post<INewApiKeyDto>(`accountsadmin/v1/key`, {
        ...getAccountApiConfig(),
        data: payload
      });
      return response?.data;
    },
    getPublicApiKeys: async (): Promise<IApiKeysDto> => {
      const response = await apiService.get<IApiKeysDto>(
        `accountsadmin/v1/key`,
        {
          ...getAccountApiConfig()
        }
      );
      return response?.data;
    },
    deletePublicApiKey: async (keyId: number): Promise<string> => {
      const response = await apiService.delete<string>(
        `accountsadmin/v1/key/${keyId}`,
        {
          ...getAccountApiConfig()
        }
      );
      return response?.data;
    },
    updatePublicApiKey: async (
      keyId: number,
      description: string
    ): Promise<any> => {
      const response = await apiService.put<any>(
        `accountsadmin/v1/key`,
        {
          ...getAccountApiConfig(),
          ...{params: { keyId, description } }
        }
      );
      return response?.data;
    }
    
  };
};
