import { styled } from '@mui/material/styles';
import { gray1, gray4 } from '../../common/constants';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const AgentDetailHeader = styled(Typography)`
  padding: 17px 5px 17px 32px;
  font-weight: 700 !important;
  background: ${gray1};
  margin-bottom: 0px !important;
  line-height: 22px;
`;

export const EmailDetailHeader = styled(Typography)`
  padding: 16px 5px 16px 32px;
  font-weight: 700 !important;
  background: ${gray1};
  margin-bottom: 32px !important;
  border-bottom: 1px solid ${gray4};
`;

export const RightNavigationButton = styled(Button)`
  color: '#ffffff';
  float: 'right';
  margin-right: '10px';
  &.MuiButton-label span {
    display: 'inline-block';
    margin-right: '5px';
  }
`;

export const DetailEditBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const LeftNavigationButton = styled(Button)`
  color: '#ffffff';
  margin-left: '10px';
  &.MuiButton-label span {
    display: 'inline-block';
    margin-left: '5px';
  }
  width: 32px !important;
`;

export const BoxAddEmail = styled(Box)`
  padding-left: 24px;
  padding-bottom: 24px;
`;

export const BoxAddAgent = styled(Box)`
  padding-left: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
`;

export const ButtonDelete = styled(Button)`
  margin-top: 5px !important;
  min-width: 24px !important;
`;

export const ButtonMakePrimary = styled(Button)`
  padding-top: 10px !important;
`;

export const AddDetailsButton = styled(Button)`
  padding: 4px 8px 4px 8px;
`;

export const DetailNavButton = styled(Button)`
  min-width: 16px !important;
  padding: 0px, 8px, 0px, 8px !important;
`;

export const DetailItem = styled('div')`
  margin: 5px 5px 5px 5px;
  padding-bottom: 16px;
`;

export const DetailItemEmployeeId = styled('div')`
  margin: 5px 5px 5px 5px;
  padding-bottom: 24px;
`;

export const DetailItemAgentCount = styled('div')`
  margin: 5px 5px 5px 5px;
`;

export const DetailLabel = styled(Typography)`
  margin: 5px 5px 5px 5px;
  font-weight: 500 !important;
`;

export const EditIconName = styled(EditIcon)`
  font-size: 14px !important;
`;

export const NavSpan = styled('span')`
  padding-left: 5px;
  padding-right: 5px;
`;

export const NavStatus = styled(Box)`
  padding: 10px 16px 10px 10px;
  display: inline-block;
`;

export const EmailOrIdBox = styled(Box)`
  padding-left: 32px !important;
  div {
    p.Mui-error {
      font-size: 12px;
    }
  }
`;

export const EmailPrimaryHeaderBox = styled(Box)`
  padding-left: 32px !important;
  padding-bottom: 24px;
`;

export const DetailAgentGridWrapper = styled(Box)`
  div[role='table'] {
    border-radius: 0px !important;
    border-bottom: 0px !important;
  }
`;
