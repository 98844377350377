import { Role } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';

export const appLoading: IRoute = {
  name: 'app.loading',
  stateDefinition: {
    url: '/loading',
    template: '',
    controller: 'AppLoadingCtrl',
    params: { toState: null, toParams: null, next: null }
  },
  access: {
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.User,
      Role.Viewer
    ],
    read: [Role.SupportBasic]
  }
};

export default appLoading;
