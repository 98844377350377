import { createTheme } from '@mui/material/styles';
import {
    buttonTextColor,
    primaryColor,
    productivityColors,
    tooltipFontSize
} from '../../../_reactivtrak/src/common/constants';

export default createTheme({
    palette: {
        primary: {
            main: '#6D5CAE'
        },
        secondary: {
            main: '#00A1FF',
            lightest: '#94D8FF'
        },
        error: {
            main: '#F55753'
        },
        warning: {
            main: '#F8D053'
        },
        info: {
            main: '#3B4752',
            lightest: '#D8DADC'
        },
        success: {
            main: '#10CFBD',
            lightest: '#CFF5F2',
            gradientStart: '#1AB5A7',
            gradientEnd: '#13CFBD'
        },
        grey: {
            A100: '#D5D5D5'
        },
        activTrak: {
            loading: '#F0F0F0'
        },
        productive: { main: productivityColors.productive },
        productivePassive: { main: productivityColors.productivePassive },
        passive: { main: productivityColors.passive },
        unproductivePassive: { main: productivityColors.unproductivePassive },
        unproductive: { main: productivityColors.unproductive },
        undefined: { main: productivityColors.undefined }
    },
    atCustom: {
        fontSize: {
            titleIcon: 18,
            infoIcon: 22,
            title: 14,
            heading: 12,
            subheading: 10,
            userIcon: 20,
            topUserDetails: 16
        }
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: tooltipFontSize
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: primaryColor
                }
            }
        }
    }
});
