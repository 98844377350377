import { inMemoryStore } from '../../_ui-utils/src';
import { activTrakConfig } from '../../_reactivtrak/src/common/helpers/activTrakConfig';

if (!activTrakConfig || Object.values(activTrakConfig).length === 0) {
    console.error('ActivTrak Error: Unable to load environment configuration.');
}

const _buildWss = (url) => {
    if (!_.startsWith(url, 'wss://')) {
        url = 'wss://' + url;
    }
    return url + '/ws/client/v1';
};

const _buildHttps = (url) => {
    if (!_.startsWith(url, 'https://') && !_.startsWith(url, 'http://')) {
        return 'https://' + url;
    }
    return url;
};

const _buildRedirectHttps = (newUrl, currentUrl, defaultUrl) => {
    if (!defaultUrl) {
        console.error('ActivTrak Error: Missing default URL.');
    }

    let builtUrl = (activTrakConfig.disableRegionRedirect ? currentUrl : newUrl) ?? defaultUrl;

    // TODO: Need to review why we need to add HTTPS to some API values
    if (builtUrl && !_.startsWith(builtUrl, 'https://') && !_.startsWith(builtUrl, 'http://')) {
        builtUrl = `https://${builtUrl}`;
    }

    return builtUrl ?? defaultUrl;
};

// TODO: Update service to a state-store that is functional based and typescript (moved into _reactivtrak)
// TODO: Update API naming to match existing API server naming (remove confusion on which one to use)
// TODO: Add unit tests to built config to ensure fully defined config for each environment
class EnvService {
    constructor() {
        // Create URL build functions and define default URL
        // defaultUrl is used as the default URL for all Apigee endpoints unless specified in the configuration file (activTrak-config.json)
        this.defaultUrl = activTrakConfig.defaultUrl;

        // Apigee API URLs
        this.apiUrl = _buildRedirectHttps(activTrakConfig.apiUrl, activTrakConfig.apiUrl, this.defaultUrl); // FrontEnd API
        this.authorizationServiceUrl = _buildRedirectHttps(
            activTrakConfig.authorizationServiceUrl,
            activTrakConfig.authorizationServiceUrl,
            this.defaultUrl
        ); // Authorization Service API
        this.ssoAuthorizationUrl = _buildRedirectHttps(
            activTrakConfig.ssoAuthorizationUrl,
            activTrakConfig.ssoAuthorizationUrl,
            this.defaultUrl
        ); // FrontEnd API Auth
        this.reportingServiceUrl = _buildRedirectHttps(
            activTrakConfig.reportingServiceUrl,
            activTrakConfig.reportingServiceUrl,
            this.defaultUrl
        ); // Reporting Service API
        this.accountsApiUrl = _buildRedirectHttps(
            activTrakConfig.accountsApiUrl,
            activTrakConfig.accountsApiUrl,
            this.defaultUrl
        ); // Admin API Accounts
        this.AdminApiUrl = _buildRedirectHttps(
            activTrakConfig.adminApiUrl,
            activTrakConfig.adminApiUrl,
            this.defaultUrl
        );
        this.identityServiceUrl = _buildRedirectHttps(
            activTrakConfig.identityServiceUrl,
            activTrakConfig.identityServiceUrl,
            this.defaultUrl
        ); // Identity Service API

        this.AccountApiKeyPageUrl = _buildHttps(activTrakConfig.defaultUrl);

        // Non-Apigee API URLs
        this.websocketUrl = _buildWss(activTrakConfig.websocketUrl);
        this.websocketHttpsUrl = _buildHttps(activTrakConfig.websocketUrl);

        // External App URLs
        this.signUpAppUrl = activTrakConfig.signUpAppUrl;
        this.lookerUrl = activTrakConfig.lookerUrl;

        // Environment Variables
        this.environmentName = activTrakConfig.name;
        this.marketoConfig = activTrakConfig.marketo;
    }

    setApiUrl(url) {
        this.apiUrl = _buildRedirectHttps(url, this.apiUrl, this.defaultUrl);
    }

    setWebSocketsUrl(url) {
        this.websocketUrl = _buildWss(url);
    }

    setWebSocketsHttpsUrl(url) {
        this.websocketHttpsUrl = _buildHttps(url);
    }

    setReportingServiceUrl(url) {
        this.reportingServiceUrl = _buildRedirectHttps(url, this.reportingServiceUrl, this.defaultUrl);
    }

    setIdentityServiceUrl(url) {
        this.identityServiceUrl = _buildRedirectHttps(url, this.identityServiceUrl, this.defaultUrl);
    }

    setWebApiUrl(url) {
        this.AdminApiUrl = _buildRedirectHttps(url, this.AdminApiUrl, this.defaultUrl);
    }

    setWebAccountApiUrl(url) {
        this.AccountApiKeyPageUrl = _buildRedirectHttps(url, this.AccountApiKeyPageUrl, this.defaultUrl);
    }

    getApiUrl() {
        return this.apiUrl;
    }

    getAuthorizationServiceUrl() {
        return this.authorizationServiceUrl;
    }

    getReportingServiceUrl() {
        return this.reportingServiceUrl;
    }

    getAccountsApiUrl() {
        return this.accountsApiUrl;
    }

    getWebsocketUrl() {
        return this.websocketUrl;
    }

    getWebsocketHttpsUrl() {
        return this.websocketHttpsUrl;
    }

    getSignUpAppUrl() {
        return this.signUpAppUrl;
    }

    getEnvironmentName() {
        return this.environmentName;
    }

    getMarketoConfig() {
        return this.marketoConfig;
    }

    getSsoAuthorizationUrl() {
        return this.ssoAuthorizationUrl;
    }

    getLookerUrl() {
        return this.lookerUrl;
    }

    getAdminApiUrl() {
        return this.AdminApiUrl;
    }

    getAccountApiUrl() {
        return this.AccountApiKeyPageUrl;
    }

    getIdentityServiceUrl() {
        return this.identityServiceUrl;
    }

    getDefaultUrl() {
        return this.defaultUrl;
    }
}

const envService = inMemoryStore.get('envService') || new EnvService();
inMemoryStore.set('envService', envService);

export default envService;
