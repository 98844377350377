import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appDashboardCurrentOrganization: IRoute = {
  name: 'app.dashboardCurrent.organization',
  stateDefinition: {
    url: '/homepage/organization',
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      updated: false,
      errorMessages: null,
      fromNavigation: false
    },
    template:
      '<homepage-organization-overview></homepage-organization-overview>',
    data: { pageTitle: 'Home - Organization Overview' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.DashboardsOrgOverview,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.OrganizationOverviewHomepage],
    excludedFeatureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appDashboardCurrentOrganization;
