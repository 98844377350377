'use strict';

angular
    .module('app')
    .controller('messageModalController', MessageModalController);

MessageModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'messageData',
    '$sce',
];

function MessageModalController($scope, $uibModalInstance, messageData, $sce) {
    // Message Title and Body accept either a string or a trusted html object.
    $scope.messageTitle =
        typeof messageData.messageTitle === 'string'
            ? $sce.trustAsHtml(messageData.messageTitle)
            : messageData.messageTitle;
    $scope.textAlignTitle =
        messageData.textAlignTitle === 'left' ||
        messageData.textAlignTitle === 'center' ||
        messageData.textAlignTitle === 'right'
            ? messageData.textAlignTitle
            : 'center';
    $scope.messageBody =
        typeof messageData.messageBody === 'string'
            ? $sce.trustAsHtml(messageData.messageBody)
            : messageData.messageBody;

    // Labels are used for confirm and cancel buttons
    $scope.confirmLabel = messageData.confirmLabel || 'Ok';
    $scope.confirmHeapId = messageData.confirmHeapId || 'message-modal-confirm';
    $scope.confirmClass = messageData.confirmClass || 'btn-danger';
    $scope.cancelLabel = messageData.cancelLabel || 'Cancel';
    $scope.cancelHeapId = messageData.cancelHeapId || 'message-modal-cancel';
    $scope.twoStepConfirmLabel =
        messageData.twoStepConfirmLabel || 'Confirm Action';
    $scope.secondActionLabel = messageData.secondActionLabel;
    $scope.secondActionHeapId =
        messageData.secondActionHeapId || 'message-modal-secondAction';
    $scope.secondActionClass = messageData.secondActionClass || 'btn-danger';
    $scope.hideCancelButton = messageData.hideCancelButton;
    $scope.specificationLabel = messageData.specificationLabel;

    // Modal type
    $scope.twoStepConfirm = messageData.twoStepConfirm;
    $scope.alert = messageData.alert;

    $scope.confirmTrackEvent = messageData.confirmTrackEvent || '';

    $scope.confirmClicked = function () {
        $uibModalInstance.close(false);

        if (
            messageData.callbacks &&
            typeof messageData.callbacks.confirm === 'function'
        ) {
            messageData.callbacks.confirm(false);
        }
    };

    $scope.confirmSecondActionClicked = function () {
        $uibModalInstance.close(true);

        if (
            messageData.callbacks &&
            typeof messageData.callbacks.confirm === 'function'
        ) {
            messageData.callbacks.confirm(true);
        }
    };

    $scope.cancelClicked = function () {
        $uibModalInstance.dismiss();
    };

    $scope.isDeleteValid = function () {
        return (
            !!$scope.deleteInputText &&
            $scope.deleteInputText.toLowerCase() === 'delete'
        );
    };
}