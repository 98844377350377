import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  DialogTitle
} from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { useClassification } from '../services';

type AddCategoryModalProps = {
  open: boolean;
  onClose: (e: any) => void;
  newCategoryName: string;
  setNewCategoryName: (v: string) => any;
};

export default function AddCategoryDialog(props: AddCategoryModalProps) {
  const { open, onClose, setNewCategoryName, newCategoryName } = props;

  const { categoryService } = useClassification();
  const notificationService = useNotifications();
  const [adding, setAdding] = useState(false);

  const handleTextChange = (e) => {
    setNewCategoryName(e.target.value);
  };

  const handleClose = (event) => {
    onClose(event);
    setNewCategoryName('');
    event.stopPropagation();
  };

  const handleAdd = async (e: any): Promise<void> => {
    try {
      setAdding(true);
      if (newCategoryName.length > 45) {
        notificationService.error(
          'Category name exceeds maximum length. Please enter a name less than 46 characters'
        );
      } else {
        await categoryService.create(newCategoryName);
        notificationService.success(`Success! ${newCategoryName} added.`);
      }
    } catch (response) {
      const errorMessage =
        response.data?.error === 'There is already a category with this name.'
          ? 'There is already a category with this name.'
          : 'An error has occurred while creating the new category';
      notificationService.error(errorMessage);
    } finally {
      handleClose(e);
      setAdding(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClose(event);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>New Category</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          autoFocus
          value={newCategoryName}
          onChange={handleTextChange}
          placeholder={'Category Name'}
          inputProps={{ maxLength: 45 }}
          onClick={(e) => e.stopPropagation()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus disabled={adding}>
          Cancel
        </Button>
        <Button
          onClick={(e) => handleAdd(e)}
          disabled={
            adding || newCategoryName.trim().length === 0 ? true : false
          }
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
