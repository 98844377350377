'use strict';

var atMultiSelectCheckboxServiceFunctions = require('_app/serviceFunctions/atMultiSelectCheckboxServiceFunctions')
    .atMultiSelectCheckboxServiceFunctions;

angular
    .module('app')
    .service('atMultiSelectCheckboxService', MultiSelectCheckboxService);

MultiSelectCheckboxService.$inject = [];

function MultiSelectCheckboxService() {
    var service = this;

    angular.extend(service, atMultiSelectCheckboxServiceFunctions);
}
