'use strict';

angular.module('app')
    .directive('alarmDetailsActionSeparator', AlarmDetailsActionSeparator);

function AlarmDetailsActionSeparator() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsActionSeparator.html'),
        controller: AlarmDetailsActionSeparatorCtrl
    };
}

AlarmDetailsActionSeparatorCtrl.$inject = [];


function AlarmDetailsActionSeparatorCtrl() {
}
