'use strict';

angular.module('app').directive('atToggle', atToggle);

function atToggle() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions',
            model: '=atModel',
            disabled: '=atDisabled'
        },
        template: require('views/widgets/atToggle.html'),
        controller: atToggleCtrl
    };
}

atToggleCtrl.$inject = ['$scope'];

function atToggleCtrl($scope) {
    $scope.handleChange = function (label) {
        // Do nothing if active toggle is selected again.
        if (label.text === $scope.model || $scope.disabled) {
            return;
        }

        $scope.model = label.text;
        if (typeof $scope.options.onChange === 'function') {
            $scope.options.onChange(label.text);
        }
    };

    $scope.$watch('options', function (options) {
        if (options && options.labels && options.labels.length > 0 && !options.labels[0].text) {
            options.labels = _.map(options.labels, function (label) {
                return { text: label, value: label };
            });
        }
    });
}
