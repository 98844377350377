import { Role } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsUseragents: IRoute = {
  name: 'app.settings.useragents',
  stateDefinition: {
    url: '/settings/useragents?{limit}',
    template: '<settings-user-agents></settings-user-agents>',
    params: { limit: null },
    data: { pageTitle: 'User Agents' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsUseragents;
