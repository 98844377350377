import React from 'react';
import Select from '@mui/material/Select';
import SelectProps from './Select.props';

export const SelectNoOutline: React.FC<SelectProps> = (props) => {
    const { sx, ...rest } = props;
    const styles = {
      '& .MuiSelect-select': {
        padding: '10px 24px 10px 16px'
      },
      '&.MuiInputBase-sizeSmall': {
        '& .MuiSelect-select': {
          paddingTop: '6px',
          paddingBottom: '6px'
        }
      },
      ...sx
    };
    return <Select {...rest} variant="standard" disableUnderline sx={styles} />;
};
