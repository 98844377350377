import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { TopWebsitesReportViewComponent } from '../../../../_reactivtrak/src/reports/top-websites-report/views/TopWebsitesReport.view.tsx';
import { topwebsitesTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedTopWebsitesReport', {
    template: topwebsitesTemplate,
    controller: 'TopWebsitesCtrl'
});

angular.module('app').component('topWebsitesReport', react2angular(TopWebsitesReportViewComponent, []));
