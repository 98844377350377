import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
interface IGlobalModalContentProps {
  children: ReactNode;
  style?: object;
}

export const GlobalModalContent = ({
  children,
  style
}: IGlobalModalContentProps) => {
  const defaultStyle = {
    width: 650,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid grey',
    boxShadow: 24,
    p: 6,
    borderRadius: '10px'
  };

  const appliedStyle = { ...defaultStyle, ...style };

  return <Box sx={appliedStyle}>{children}</Box>;
};
