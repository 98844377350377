import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OperationalSystemsType } from '../../common/enums';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ActionsContainer,
  FormElementContainer,
  FormElementTitle
} from '../../common/components/AdvancedFilter/AdvancedFilter.styles';
import { computerTextFields } from '../enums/AdvancedSearch';

type ComponentProps = {
  modalData: any[];
  onSubmit: (formData) => void;
};

const oSOptions = [
  {
    label: '',
    template: () => (
      <div>
        <p> </p>
      </div>
    )
  },
  ...Object.keys(OperationalSystemsType).map((os) => {
    return { label: os, template: () => getOSIcon(os) };
  })
];

export const ComputerAdvancedSearchForm = (props: ComponentProps) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [osOption, setOSOption] = useState(0);
  const { modalData, onSubmit } = props;

  const optionsRef = useRef();

  const inputRefs = {
    windowsDomain: useRef(null),
    computer: useRef(null),
    alias: useRef(null),
    agentVersion: useRef(null)
  };

  useEffect(() => {
    const initialValue = {};
    modalData.forEach((input) => {
      if (inputRefs[input.field]) {
        initialValue[input.field] = input.fieldValue;
        inputRefs[input.field].current.value = input.fieldValue;
      } else if (input.field === 'os') {
        let osSelection = oSOptions.findIndex(
          (element) => element.label === input.fieldValue
        );
        if (osSelection == -1) {
          osSelection = 0;
        }
        setOSOption(osSelection);
      }
    });
    reset(initialValue);
    // inputRefs should be added, but needs to be properly memoized before added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, reset]);

  const resetForm = () => {
    const resetValue = { os: '' };
    setOSOption(0);
    computerTextFields.forEach((field) => {
      const { name } = field;
      inputRefs[name].current.value = '';
      resetValue[name] = '';
    });

    reset(resetValue);
  };

  return (
    <>
      {computerTextFields.map((field, index) => {
        const { name, label } = field;
        return (
          <FormElementContainer key={index}>
            <FormElementTitle>{label}</FormElementTitle>
            <TextField
              inputRef={inputRefs[name]}
              {...register(name)}
              variant="standard"
            />
          </FormElementContainer>
        );
      })}
      <FormElementContainer>
        <FormElementTitle>Operating System</FormElementTitle>
        <DropdownOptionsButton
          {...register('os')}
          id="ea4b1013-1db1-4dbc-bb2d-97d55384f76a"
          ref={optionsRef}
          icon={<ArrowDropDownIcon />}
          rightIconPosition
          disablePortal
          indexOption={osOption}
          setIndexOption={setOSOption}
          dropDownOptions={oSOptions}
          onChangeOption={(e, option) => {
            if (Object.values(OperationalSystemsType).includes(option.label)) {
              setValue('os', option.label);
            } else {
              setValue('os', '');
            }
          }}
          disableSelectedItem
        />
      </FormElementContainer>
      <ActionsContainer>
        <Button onClick={() => resetForm()}>Clear</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </Button>
      </ActionsContainer>
    </>
  );
};
