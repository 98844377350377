import React, { useCallback, useEffect, useState } from 'react';
import { useGroupsStore } from '../../../hooks';
import { IReportFilters } from '../models/IReportFilters';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TeamsTooltipSC } from '../styles';
import AutocompleteWithIcons from '../../Autocomplete/AutocompleteWithIcons';
import { IAutocompleteSuggestion } from '../../Autocomplete/IAutocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextOverflow } from '../../../styles/app.component.styles';

type TeamFilterProps = {
  groupId: string[];
  allTeamSuggestions: IAutocompleteSuggestion[];
  onSubmitFilter: (filters: Partial<IReportFilters>) => void;
};

export const TeamFilter = (props: TeamFilterProps) => {
  const { groupId, allTeamSuggestions, onSubmitFilter } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonValue, setButtonValue] = useState<string>();
  const [defaultGroup, setDefaultGroup] = useState<string>();
  const [currentEntity, setCurrentEntity] =
    useState<IAutocompleteSuggestion | null>(null);

  const getGroupFilterById = useGroupsStore((s) => s.getGroupFilterById);

  useEffect(() => {
    let finalStr = '';
    //wait until the groups are loaded
    if (allTeamSuggestions?.length > 0) {
      const doesExistInList = groupId?.length
        ? allTeamSuggestions?.find((item) => item.value == groupId[0])
        : false;

      let defaultGrp = '';
      if (doesExistInList) {
        defaultGrp = groupId[0];
      } else if (allTeamSuggestions?.length && allTeamSuggestions[0].value) {
        //otherwise, find first value in groups list if exists
        defaultGrp = allTeamSuggestions[0].value;
        const group = getGroupFilterById(defaultGrp);
        const groupName = group?.name;

        onSubmitFilter({
          groupId: [defaultGrp],
          users: [
            {
              userId: defaultGrp,
              userType: 'Group',
              name: groupName,
              filterMode: 'users',
              groupType: group?.mix
            }
          ]
        });
      }
      setDefaultGroup(defaultGrp);
      //update the button's value
      if (defaultGrp) {
        groupId?.forEach((item, i) => {
          if (i !== 0) {
            finalStr += ' or ';
          }
          const currentSelection = allTeamSuggestions.find(
            (suggestion) => suggestion.value == item
          );

          finalStr += currentSelection?.label;
        });
      }
    } else {
      finalStr = 'is any value';
    }

    setButtonValue(finalStr);
  }, [groupId, allTeamSuggestions, onSubmitFilter, getGroupFilterById]);

  useEffect(() => {
    if (allTeamSuggestions.length && groupId.length) {
      const currentEntity = allTeamSuggestions.find(
        (entity) => entity.value === groupId[0]
      );
      setCurrentEntity(currentEntity);
    }
  }, [allTeamSuggestions, groupId]);

  const handleChange = useCallback(
    (option: IAutocompleteSuggestion) => {
      const groupId = option.value;
      const group = getGroupFilterById(groupId);
      const groupName = group?.name;

      onSubmitFilter({
        groupId: [option.value],
        users: [
          {
            userId: groupId,
            userType: 'Group',
            name: groupName,
            filterMode: 'users',
            groupType: group?.mix
          }
        ]
      });
      handleClose();
    },
    [getGroupFilterById, onSubmitFilter]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  return allTeamSuggestions?.length > 0 ? (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <TeamsTooltipSC
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={
            <Box sx={{ margin: '0 16px' }}>
              <AutocompleteWithIcons
                suggestions={allTeamSuggestions}
                onChange={handleChange}
                currentValue={defaultGroup}
              />
            </Box>
          }
        >
          <Button
            variant="outlined"
            onClick={() => setIsOpen(!isOpen)}
            sx={{ maxWidth: '235px' }}
            id="f2001841-1850-4a32-88c4-b4b30b7b0ca5"
            startIcon={currentEntity?.icon || <></>}
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            <TextOverflow>{buttonValue}</TextOverflow>
          </Button>
        </TeamsTooltipSC>
      </div>
    </ClickAwayListener>
  ) : (
    <></>
  );
};
