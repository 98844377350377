'use strict';

angular.module('app').directive('atTabs', atTabs);

function atTabs() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions',
            model: '=atModel',
            disabled: '<atDisabled'
        },
        template: require('views/widgets/atTabs.html'),
        controller: atTabsCtrl
    };
}

atTabsCtrl.$inject = ['$scope'];

function atTabsCtrl($scope) {
    $scope.handleTabChange = function (tab) {
        // Do nothing if active tab is selected or disabled
        if (tab.text === $scope.model || $scope.disabled) {
            return;
        }

        $scope.model = tab.text;
        if (typeof $scope.options.onChange === 'function') {
            $scope.options.onChange($scope.model);
        }
    };

    $scope.showTab = function (tab) {
        // If tab.show is a function, use it
        // Used for dynamic showing of a tab
        if (typeof tab.show === 'function') {
            return tab.show(tab);
        }
        // Else if tab.show is not defined
        // Default to true
        else if (tab.show === undefined) {
            return true;
        }
        // Else return tab.show (should be a boolean)
        // Used for static showing of a tab
        else {
            return tab.show;
        }
    };

    function getActiveTab(options) {
        return _.find(options.tabs, function (tab) {
            return tab.text === $scope.model;
        });
    }

    function validateActiveTab(options) {
        // Only validate active tab when tabs exist
        if (!options || !options.tabs || options.tabs.length <= 0) {
            return;
        }

        // If $scope.modal does not exist in tab list, set to first tab option
        var activeTab = getActiveTab(options);

        if (!activeTab) {
            $scope.handleTabChange(options.tabs[0]);
            activeTab = getActiveTab(options);
        }

        // Check to see if active tab is hidden on options update
        // Is so, change tab to first non-hidden tab in list.
        if (!$scope.showTab(activeTab)) {
            var firstNonHiddenTab = _.find(options.tabs, function (tab) {
                return $scope.showTab(tab);
            });
            $scope.handleTabChange(firstNonHiddenTab);
        }
    }

    $scope.$watch('options', function (options) {
        validateActiveTab(options);
    });
}
