import React from 'react';
import {
  Box,
  TableCell,
  TableRow,
  IconButton,
  Link,
  Tooltip
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { enterpriseClasses } from './Enterprise.styles.js';
import { impersonate } from '../../../../_reactivtrak/src/common/hooks/useImpersonate';


export const EnterpriseRow = ({ account, isChild = false, onError }) => {
  const [open, setOpen] = React.useState(false);

  const isLicenseOverage = () => account.usedLicenses > account.licenseQuantity;

  const displayCollapsedRows = () => {
    if (open) {
      return (
        <>
          {account.children?.length > 0 &&
            account.children?.map((acct) => (
              <React.Fragment key={acct.accountNumber}>
                <EnterpriseRow account={acct} isChild={true} />
              </React.Fragment>
            ))}
        </>
      );
    }
  };

  const renderThirdColumn = () => {
    if (isChild) {
      return (
        <TableCell>
          <Box flexDirection="row-reverse" display="flex" alignItems="center">
            <Link
              href={'#'}
              onClick={(e) =>{
                e.preventDefault();

                setTimeout(async () => {
                  try {
                    await impersonate(account.accountNumber)();
                    window.location.href = '/#/app/home';
                  } catch (ex) {
                    console.error(ex);
                    onError(ex);
                  }
                });
                
              }}
              target="_blank"
            >
              <IconButton size="small" color="primary">
                <LaunchIcon sx={enterpriseClasses.launchIcon} />
              </IconButton>
            </Link>
          </Box>
        </TableCell>
      );
    } else if (account.children?.length > 0) {
      return (
        <TableCell>
          <Box flexDirection="row-reverse" display="flex" alignItems="center">
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell></TableCell>;
  };

  const renderLicenseCell = () => {
    if (isChild) {
      return <>{account.usedLicenses}</>;
    } else {
      if (isLicenseOverage()) {
        return (
          <Tooltip
            title="Your used licenses total is more than your purchased licenses for this plan."
            arrow
          >
            <span style={{ color: 'red' }}>
              {account.usedLicenses}/{account.licenseQuantity}
            </span>
          </Tooltip>
        );
      } else {
        return (
          <>
            {account.usedLicenses}/{account.licenseQuantity}
          </>
        );
      }
    }
  };

  return (
    <>
      <TableRow sx={enterpriseClasses.tableRow}>
        <TableCell colSpan={2}>
          <Box>
            {isChild && (
              <i
                className="fa fa-circle"
                style={{
                  fontSize: '8px',
                  marginLeft: '5px',
                  verticalAlign: 'middle'
                }}
              ></i>
            )}
            <span
              style={
                isChild
                  ? {
                      fontWeight: 'normal',
                      marginLeft: '5px',
                      verticalAlign: 'middle'
                    }
                  : { fontWeight: 'bold' }
              }
            >
              {account.name}
            </span>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box>{!isChild && <>{account.children?.length}</>}</Box>
        </TableCell>

        <TableCell align="center">
          <Box>{renderLicenseCell()}</Box>
        </TableCell>
        {renderThirdColumn()}
      </TableRow>
      {displayCollapsedRows()}
    </>
  );
};
