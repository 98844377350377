import { IAccountPlan, IAccountPlanDto, IPlanDto } from '../models';
import { atHelperFunctions } from '../../../../_app/serviceFunctions/atHelperFunctions';
import moment from 'moment';

const createPlanInfoLabels = (planInfo: IPlanDto) => {
  const planType = planInfo.type || '';
  const planTypeLabel = atHelperFunctions.capitalizeFirstLetterEachWord(
    planType.replace(' trial', '')
  );

  // Plan is a trial if the plan type has trial in the name or if the period is trial
  const isTrial = planType.indexOf('trial') >= 0 || planInfo.period === 'trial';
  const planTypeSuffix = isTrial ? ' Trial' : ' Plan';
  let planPeriodLabel = atHelperFunctions.capitalizeFirstLetterEachWord(
    planInfo.period
  );

  if (isTrial) {
    planPeriodLabel = 'Trial';
  } else if (planInfo.period === 'none') {
    planPeriodLabel = '';
  }

  const planTypePeriodLabel =
    (planTypeLabel ? planTypeLabel : '') +
    (planPeriodLabel ? ' ' + planPeriodLabel : '');

  return { planTypeLabel: planTypeLabel + planTypeSuffix, planTypePeriodLabel };
};

const createRemainingDays = (renewDate: string) => {
  let remainingDays = 0;
  const now = moment.utc();
  const renewDateMoment = moment.utc(renewDate);
  const remainingMinutes = moment.duration(
    renewDateMoment.diff(now, 'minutes'),
    'minutes'
  );

  if (remainingMinutes.asMinutes() !== 0) {
    remainingDays = remainingMinutes.asDays();
  }

  return remainingDays;
};

export const mapAccountPlanDtoToAccountPlan = (
  response: IAccountPlanDto
): IAccountPlan => {
  const { planInfo, previousPlanInfo, renewDate, isDowngrade } = response;
  const { planTypeLabel, planTypePeriodLabel } = createPlanInfoLabels(planInfo);
  let accountPlanAge = -1;

  if (previousPlanInfo?.endDate) {
    const given = moment(previousPlanInfo.endDate);
    const current = moment().startOf('day');
    accountPlanAge = moment.duration(current.diff(given)).asDays();
  }

  return {
    planCode: planInfo.code,
    planType: planInfo.type,
    planTypeLabel,
    planTypePeriodLabel,
    planPeriod: planInfo.period,
    planAge: accountPlanAge,
    planRenewDate: renewDate,
    planRemainingDays: createRemainingDays(renewDate),
    isPlanDowngraded: isDowngrade
  };
};
