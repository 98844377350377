'use strict';

angular.module('app')
    .controller('contentBlockModalController', ContentBlockModalController);

ContentBlockModalController.$inject = ['$scope', '$uibModalInstance', 'data'];

function ContentBlockModalController($scope, $uibModalInstance, data) {
    $scope.content = data.content;
    $scope.description = data.description;
    $scope.title = data.title;

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };
}
