import React from 'react';
import { IApiKeyInformativeParameters } from '../models';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CreateApiKeyButton from '../components/CreateApiKeyButton';
import { ApiKeysLinks } from '../enums';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';

const InformativeTopGrid = (props: IApiKeyInformativeParameters) => {
  const { onSubmit, isDisabled, onCloseSuccessDialog } = props;

  const authorizationService = useAuthorization();

  const isActiveConnectEnabled: boolean = authorizationService?.hasFeature(
    BundleFlag.ActivConnect
  );

  return (
    <Box>
      <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ marginBottom: '10px', paddingBottom: '10px' }}>
            <Typography aria-multiline="true">
              {' '}
              Create API keys to access your enabled APIs.{' '}
              <a
                href={ApiKeysLinks.Learn_More}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Learn More{' '}
              </a>
            </Typography>
          </Box>
        </Grid>
        <Box sx={{ marginBottom: '10px', paddingBottom: '10px' }}>
          <CreateApiKeyButton
            onSubmit={onSubmit}
            isDisabled={isDisabled}
            onCloseSuccessDialog={onCloseSuccessDialog}
          />
        </Box>

        <Typography sx={{ lineHeight: '24px', paddingBottom: '4px' }}>
          The following available APIs can be accessed using the key(s) below.
        </Typography>
        {isActiveConnectEnabled ? (
          <Typography>
            Available:{' '}
            <a
              href={ApiKeysLinks.Administration}
              target="_blank"
              rel="noreferrer"
            >
              Administration
            </a>
            ,{' '}
            <a href={ApiKeysLinks.Bulk_Import} target="_blank" rel="noreferrer">
              Bulk Import
            </a>
            ,{' '}
            <a
              href={ApiKeysLinks.Live_Data_API}
              target="_blank"
              rel="noreferrer"
            >
              Reports (Live Data API)
            </a>
          </Typography>
        ) : (
          <Typography>
            Available:{' '}
            <a
              href={ApiKeysLinks.Administration}
              target="_blank"
              rel="noreferrer"
            >
              Administration
            </a>
            ,{' '}
            <a href={ApiKeysLinks.Bulk_Import} target="_blank" rel="noreferrer">
              Bulk Import
            </a>
            &emsp; Unavailable:
            <a
              href={ApiKeysLinks.Live_Data_API}
              target="_blank"
              rel="noreferrer"
            >
              Reports (Live Data API)
            </a>
          </Typography>
        )}

        <Box sx={{ paddingBottom: '24px' }}></Box>
      </Grid>
    </Box>
  );
};

export default InformativeTopGrid;
