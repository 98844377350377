import theme from '../../../theme/theme';

export const cardActiveClasses = {
  activeAgent: {
    boxShadow: theme.shadows[2],
    padding: theme.spacing()
  },
  h6: {
    lineHeight: 0.9,
    textTransform: 'uppercase'
  },
  loader: {
    marginLeft: '45px',
    marginRight: '45px'
  }
};
