import React from 'react';
import { baseLayoutClasses } from './Layout.style';

const Layout = (props) => {
    const { children } = props;

    return (
        <div style={baseLayoutClasses.root}>
            <main style={baseLayoutClasses.content}>{children}</main>
        </div>
    );
};

export default Layout;
