import React, {
  createContext,
  Dispatch,
  useContext,
  useReducer,
  useState
} from 'react';
import { IAccountWizardService } from '../models';
import { AccountWizardService } from './';
import { apiService } from '../../common/helpers';
import { GetRolesService } from '../../common/services';
const AccountWizardContext = createContext<IAccountWizardProvider>(null);

interface IAccountWizardProvider {
  firstVisit: boolean;
  formValues: { PageSelection: string[] };
  dispatchForm: Dispatch<any>;
  accountWizardService: IAccountWizardService;
  controlFlow: {
    '0:0': { '1:0': boolean; '1:1': boolean; '1:2': boolean };
    '0:1': { '1:0': boolean; '1:1': boolean; '1:2': boolean };
    '0:2': { '1:0': boolean; '1:1': boolean };
    '0:3': { '1:1': boolean; '1:2': boolean };
    '0:4': { '1:0': boolean; '1:1': boolean };
  };
  controlFlowHandler: (activeStep: number, id?: string) => boolean;
  accountWizardReferrer: 'Signup' | 'Role Access Page';
  setAccountWizardReferrer: React.Dispatch<
    React.SetStateAction<'Signup' | 'Role Access Page'>
  >;
  wizardName: string;
  skipStepTwo: boolean;
  setSkipStepTwo: React.Dispatch<boolean>;
}

type AccountWizardProviderProps = {
  children: React.ReactNode;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'addCheckbox':
      return {
        ...state,
        PageSelection: [...state.PageSelection, action.payload]
      };
    case 'removeBox':
      return {
        ...state,
        PageSelection: state.PageSelection.filter(
          (item) => item !== action.payload
        )
      };
    case 'addRadioBtn':
      if (!state.PageSelection?.includes(action.payload)) {
        return {
          ...state,
          PageSelection: [...state.PageSelection, action.payload]
        };
      } else {
        return state;
      }
    case 'pageSelectionCleanup':
      return {
        ...state,
        PageSelection: action.payload
      };
  }
};

export const AccountWizardProvider = (props: AccountWizardProviderProps) => {
  const { children } = props;
  const firstVisit = location.hash.includes('firstVisit');
  const initialFormValues = {
    PageSelection: [],
    TriggeredFromSignUp: firstVisit
  };
  const [formState, formDispatch] = useReducer(reducer, initialFormValues);
  const controlFlow = {
    '0:0': { '1:0': false, '1:1': true, '1:2': true },
    '0:1': { '1:0': false, '1:1': true, '1:2': true },
    '0:2': { '1:0': false, '1:1': true },
    '0:3': { '1:1': true, '1:2': true },
    '0:4': { '1:0': false, '1:1': true }
  };
  const [accountWizardReferrer, setAccountWizardReferrer] =
    useState<IAccountWizardProvider['accountWizardReferrer']>('Signup');

  const [skipStepTwo, setSkipStepTwo] = useState<boolean>(false);

  const controlFlowHandler = (activeStep: number, id?: string) => {
    let showBox = false;
    const stepTwoFlow = new Set<object>();
    // Filter controlFlow object by creating set of step 1 selections
    formState.PageSelection.map((sel) => {
      if (controlFlow[sel.substring(0, 3)]) {
        stepTwoFlow.add(controlFlow[sel.substring(0, 3)]);
      }
    });
    if (activeStep === 1) {
      // iterate over filtered selections, if flow[id] showbox true.
      stepTwoFlow.forEach((flow) => {
        if (Object.prototype.hasOwnProperty.call(flow, id.substring(0, 3)))
          showBox = true;
      });
    } else if (activeStep === 2) {
      const step2Selection: string = formState.PageSelection.filter((sel) =>
        sel.substring(0, 3).includes('1:')
      )
        .join()
        .substring(0, 3);

      stepTwoFlow.forEach((flow) => {
        if (Object.prototype.hasOwnProperty.call(flow, step2Selection)) {
          showBox = flow[step2Selection] || showBox;
        }
      });
    }
    return showBox;
  };

  const value = {
    firstVisit,
    formValues: formState,
    dispatchForm: formDispatch,
    controlFlow,
    controlFlowHandler,
    accountWizardService: new AccountWizardService(
      apiService,
      new GetRolesService()
    ),
    accountWizardReferrer,
    setAccountWizardReferrer,
    wizardName: 'onboarding-wizard-e79f85dd-b9cb-4061-9ea3-2d01fdc8e7fa',
    skipStepTwo,
    setSkipStepTwo
  };

  return (
    <AccountWizardContext.Provider value={value}>
      {children}
    </AccountWizardContext.Provider>
  );
};

export const useAccountWizardContext = (): IAccountWizardProvider => {
  return useContext(AccountWizardContext);
};
