import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement
} from 'chart.js';
import { getParsedLocalStorageItem } from '../../common/helpers';
import { IAccountSettings } from '../../common/models';
import { localStorageItems } from '../../common/constants';
import authorizationService from '../../common/helpers/authorization';
import { DashboardType, Role } from '../../common/enums';
import { BasicDashboardView } from './BasicDashboard.view';
import { DashboardWidgetId } from '../enums';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DashboardProvider } from '../services';
import {
  ClassificationProvider,
  ClassificationStoreProvider
} from '../../classifications/services';

export const AdminDashboard = () => {
  ChartJS.register(CategoryScale, LinearScale, ArcElement, BarElement);

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const isAdvancedSupport: boolean = authorizationService?.hasAnyRole([
    Role.SupportAdvanced
  ]);
  const hasTopUsersGroupsAccess = () => {
    const hasTopUsers: boolean = authorizationService?.hasRouteByName(
      'app.reports.topusers'
    );
    const overLimitOrOverStorage = accountSettings?.overLimitOrOverStorage;

    return hasTopUsers && !isAdvancedSupport && !overLimitOrOverStorage;
  };

  const adminWidgetList = [
    {
      id: DashboardWidgetId.QuickActions,
      widths: { xs: 12 }
    },
    { id: DashboardWidgetId.UserHealth, widths: { xs: 12, sm: 4, lg: 3 } },
    {
      id: DashboardWidgetId.PendingClassifications,
      widths: { xs: 12, sm: 8, lg: 9 }
    },
    {
      id: DashboardWidgetId.TopUsersGroups,
      widths: { xs: 12 },
      shouldExclude: !hasTopUsersGroupsAccess()
    }
  ];

  //this memoizes array without infinite looping
  const widgets = useRef(adminWidgetList);

  return (
    <BasicDashboardView
      dashboardType={DashboardType.admin}
      showGreeting={true}
      showQuotes={true}
      widgets={widgets.current}
    />
  );
};

export const AdminDashboardComponent = () => {
  return (
    <ReactivTrakComponentWrapper>
      <ClassificationProvider>
        <ClassificationStoreProvider>
          <DashboardProvider>
            <AdminDashboard />
          </DashboardProvider>
        </ClassificationStoreProvider>
      </ClassificationProvider>
    </ReactivTrakComponentWrapper>
  );
};
