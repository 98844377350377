export const mergeArrayOfObjects = (original, newdata, selector = 'id') => {
  const cloned = [...original];

  newdata.forEach((dat) => {
    const foundIndex = original.findIndex(
      (ori) => ori[selector] == dat[selector]
    );
    if (foundIndex >= 0) cloned.splice(foundIndex, 1, dat);
    else cloned.push(dat);
  });

  return cloned;
};
