import { getDefaultConfig } from '../../config';
import { apiService } from '../../helpers';
import {
  IDigestSettingDto,
  IDigestSettingPost,
  IDigestService
} from '../../models';

export const digestService = (): IDigestService => {
  return {
    fetchDigestSettings: async (): Promise<IDigestSettingDto[]> => {
      const response = await apiService.get<IDigestSettingDto[]>(
        '/api/settings/admin/digest',
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },
    postDigestSettings: async (payload: IDigestSettingPost[]): Promise<any> => {
      return await apiService.post<IDigestSettingPost>(
        '/api/settings/admin/digest',
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
    }
  };
};
