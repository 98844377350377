import React, { useState } from 'react';
import { useAuthorization } from '../../common/services/Authorization';
import { IGroup } from '../../common/models';
import { ISettings } from '../../common/components/SettingsMenu/ISettings';
import { isNotATGroupType } from '../utils';
import SettingsMenu from '../../common/components/SettingsMenu/SettingsMenu';
import DeleteGroupsDialog from './DeleteGroupsDialog';
import { useGroups } from '../../common/services/Groups';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

type GroupMemberSettingsProps = {
  group: IGroup;
  onDelete: (groups: IGroup[], forceDelete: boolean) => Promise<boolean>;
};

const GroupMembersSettings = (props: GroupMemberSettingsProps) => {
  const { group, onDelete } = props;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { groupsState } = useGroups();
  const { forceDelete, resetForceDelete } = groupsState;

  const authorizationService = useAuthorization();
  const history = useUIRouterHistory();

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async (groups): Promise<boolean> => {
    const isDeleted = await onDelete(groups, forceDelete);
    if (isDeleted) {
      resetForceDelete();
      history.push('app.settings.groups');
    }
    return isDeleted;
  };

  const isDeleteDisabled =
    (group && isNotATGroupType(group.filter)) ||
    !authorizationService.canDeleteGroup();

  const menuItems: ISettings[] = [
    {
      id: 'menu-delete-group',
      onClick: handleDeleteDialogOpen,
      isDisabled: isDeleteDisabled,
      title: 'Delete Group'
    }
  ];

  if (!group) return <></>;

  return (
    <>
      <SettingsMenu menuItems={menuItems} />
      <DeleteGroupsDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onDelete={handleDelete}
        groups={[group]}
      />
    </>
  );
};

export default GroupMembersSettings;
