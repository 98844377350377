import React from 'react';
import { useClassificationsStore } from './ClassificationStore';
import { IActivityStoreState, IClassificationStore } from '../models';

const { createContext, useContext } = React;
export const ClassificationStoreContext = createContext<
  IClassificationStoreProvider
>(null);
export interface IClassificationStoreProvider {
  classificationStore: IClassificationStore;
}

type Props = {
  children: React.ReactNode;
  activityStateProps?: IActivityStoreState;
};

export const ClassificationStoreProvider = (props: Props) => {
  const { children, activityStateProps } = props;

  let classificationStore = useClassificationsStore();

  if (activityStateProps && Object.keys(activityStateProps).length) {
    classificationStore = {
      ...classificationStore,
      ...{ activitiesState: activityStateProps }
    };
  }

  const value = {
    classificationStore
  };

  return (
    <ClassificationStoreContext.Provider value={value}>
      {children}
    </ClassificationStoreContext.Provider>
  );
};

export const useClassificationStores = (): IClassificationStoreProvider => {
  return useContext(ClassificationStoreContext);
};
