import { STORE_CACHES } from '../constants/storeCaches';
import { StoreCache } from '../models/StoreCache';

const storeCaches = STORE_CACHES;

const createPollingFunction = (storeCache: StoreCache) => {
  storeCache.timeoutId = setTimeout(() => {
    storeCache.fetchData();
    createPollingFunction(storeCache);
  }, storeCache.lifetime);
};

export const initializeStoreCacheHandler = async (): Promise<void> => {
  const promises = storeCaches.map((storeCache) => storeCache.fetchData());
  await Promise.all(promises);
  storeCaches.forEach((storeCache) => createPollingFunction(storeCache));
};

export const stopStoreCachePolling = (storeName: string) => {
  const storeCache = storeCaches.find(
    (storeCache) => storeCache.storeName === storeName
  );
  if (storeCache) {
    clearTimeout(storeCache.timeoutId);
  }
};

export const startStoreCachePolling = (storeName: string) => {
  const storeCache = storeCaches.find(
    (storeCache) => storeCache.storeName === storeName
  );
  if (storeCache) {
    clearTimeout(storeCache.timeoutId);
    storeCache.fetchData();
    createPollingFunction(storeCache);
  }
};

export const resetStoreCache = () => {
  storeCaches.forEach((storeCache) => {
    clearTimeout(storeCache.timeoutId);
  });
};
