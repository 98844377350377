import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsWorkloadBalance: IRoute = {
  name: 'app.reports.insightsWorkloadBalance',
  stateDefinition: {
    url: '/insights/workloadbalance?modelId&pageId&subPageId&tempFilters',
    params: {
      modelId: null,
      pageId: null,
      subPageId: null,
      tempFilters: null,
      insightsRange: null
    },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'Insights - Workload Balance' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.WorkloadBalance,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.WorkloadBalanceInsightsReport]
  }
};

export default appReportsInsightsWorkloadBalance;
