'use strict';

angular.module('app').controller('deleteSelectedScreenshotsModalController', DeleteSelectedScreenshotsModalController);

DeleteSelectedScreenshotsModalController.$inject = ['$scope', '$uibModalInstance', 'warningMessage'];

function DeleteSelectedScreenshotsModalController($scope, $uibModalInstance, warningMessage) {
    $scope.screenshotCount = warningMessage.screenshotCount;
    $scope.confirmTrackEvent = warningMessage.confirmTrackEvent || '';

    $scope.delete = function () {
        $uibModalInstance.close();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.isDeleteValid = function () {
        return $scope.deleteAcknowledgeChecked;
    };
}
