import Root from "../../_command-center/src/Root";
import { restore } from "../../_reactivtrak/src/common/hooks/useImpersonate";
import { postLoginStore } from "../../_reactivtrak/src/common/stores/postLoginStore";
import { react2angular } from "../../_reactivtrak/src/common/third-party/react2angular";

angular.module('cc', ['ui.router']);
angular.module('cc').config(ccConfig);

angular.module('cc').component('commandCenter', react2angular(Root, ['accountSettings', 'route']));

ccConfig.$inject = ['$stateProvider'];

function ccConfig($stateProvider) {

  $stateProvider.state('cc', {
    abstract: true,
    template: '<ui-view/>',
    accountSettings: [
      async () => {
        await postLoginStore.getState().fetch();
      }
    ]
  });


  $stateProvider.state('cc.dashboard', {
    url: '/cc/{route:.*}',
    component: 'commandCenter',
    resolve: {
      restore: [
        async () => {
          const backup = window.localStorage.getItem('activTrak.msp.backup');
          if (backup && backup !== '') {
            await restore();
            return;
          }
        }
      ],
      route: [
        '$state',
        ($state) => $state.params
      ]

    }
  });
}
