import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React, { useEffect, useState } from 'react';
import { DetailNavButton, NavStatus } from '../styles/UserDetail.view.styles';
import { backToUsersButton } from '../constants/ButtonLabels';
import { PageDirection } from '../models/PageDirection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IUserSearchState } from '../models/IUserSearchState';
import { GetNextUserId } from '../utils/DetailEmailEmployeeIdUtils';

type ComponentProps = {
  userSearchState: IUserSearchState;
  identityDetailState: IIdentityDetailState;
};

export const DetailNavHeader = (props: ComponentProps) => {
  const { isLoadingDetail, selectedIdentity } = props.identityDetailState;
  const { identities } = props.userSearchState;
  const history = useUIRouterHistory();

  const handleIdentityBackClick = () => {
    const backRoute = 'app.settings.identity';
    history.push(backRoute);
  };

  const navigateToIdentity = (id: number) => {
    history.push('app.settings.identity_id', { id });
  };

  const [userIndex, setUserIndex] = useState<number>(0);

  useEffect(() => {
    setUserIndex(
      1 + identities.findIndex((val) => val.id == selectedIdentity?.id)
    );
  }, [identities, selectedIdentity]);

  return (
    <Grid
      container
      spacing={3}
      sx={{ marginBottom: '12px' }}
      justifyContent="space-between"
    >
      <Grid item>
        <Button
          onClick={handleIdentityBackClick}
          startIcon={<KeyboardBackspaceIcon />}
        >
          {backToUsersButton}
        </Button>
      </Grid>
      <Grid item>
        {!isLoadingDetail && (
          <NavStatus>
            <strong>#{userIndex}</strong> of{' '}
            <strong>{identities?.length}</strong> Users
          </NavStatus>
        )}
        <DetailNavButton
          color="secondary"
          onClick={() => {
            navigateToIdentity(
              GetNextUserId(
                PageDirection.Previous,
                identities,
                selectedIdentity?.id
              )
            );
          }}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </DetailNavButton>
        <DetailNavButton
          color="secondary"
          onClick={() => {
            navigateToIdentity(
              GetNextUserId(
                PageDirection.Next,
                identities,
                selectedIdentity?.id
              )
            );
          }}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </DetailNavButton>
      </Grid>
    </Grid>
  );
};
