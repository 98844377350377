import React, { useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { snackbarMsgClasses } from './SnackbarMessageStyles.js';

const SnackbarMessage = (props) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    props.close();
  };

  return (
    <Snackbar
      sx={snackbarMsgClasses.root}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={snackbarMsgClasses.rootAlert}
        elevation={6}
        variant="filled"
        action={props.children ?? <></>}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
