import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { RiskLevelHeader } from '../components/RiskLevelHeader';
import { RiskLevelWrapper } from '../utils/riskLevelWrapper';

const RiskLevelView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <RiskLevelHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
      />
      <RiskLevelWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
      />
    </>
  );
};

export default RiskLevelView;

export const RiskLevelViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <RiskLevelView />
  </ReactivTrakComponentWrapper>
);
