import theme from '../../../../../common/theme/theme';

const getSkeletonStyles = (align) => {
    let margin;
    switch (align) {
        case 'left':
            margin = 0;
            break;
        case 'center':
            margin = '0 auto';
            break;
        case 'right':
            margin = ' 0 0 0 auto';
    }

    return {
        margin
    };
};

export const getClasses = (align) => ({
    content: {
        '& a': {
            color: 'inherit !important'
        },
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    },
    bold: {
        fontWeight: 600
    },
    skeleton: getSkeletonStyles(align)
});
