import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsExecutiveSummary: IRoute = {
  name: 'app.reports.insightsExecutiveSummary',
  stateDefinition: {
    url: '/insights/executivesummary?modelId&pageId&subPageId&tempFilters',
    params: { modelId: null, pageId: null, subPageId: null, tempFilters: null },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'Insights - Executive Summary' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.ExecutiveSummary,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.ExecSummaryInsightsReport]
  }
};

export default appReportsInsightsExecutiveSummary;
