'use strict';

angular.module('app')
    .directive('safeSearchConditionEdit', SafeSearchConditionEdit);

function SafeSearchConditionEdit() {
    return {
        restrict: 'E',
        scope: {
            condition: '=',
            availableCategories: '<',
            completeCondition: '='
        },
        template: require('views/widgets/alarms/safeSearchConditionEdit.html'),
        controller: SafeSearchConditionEditCtrl
    };
}

SafeSearchConditionEditCtrl.$inject = ['$scope'];

function SafeSearchConditionEditCtrl($scope) {

    $scope.oldCategory = $scope.condition.category;

    var safeSearchLikelihood = new kendo.data.DataSource({
        transport: {
            read: function(options) {
                var likelihoods = [
                    {
                        id: 3,
                        value: 'Possibly'
                    },
                    {
                        id: 4,
                        value: 'Likely'
                    },
                    {
                        id: 5,
                        value: 'Very Likely'
                    }
                ];
                options.success(likelihoods);
            }
        }
    });

    var safeSearchCategories = new kendo.data.DataSource({
        transport: {
            read: function(options) {
                var availableCategories = angular.copy($scope.availableCategories);
                var index = availableCategories.indexOf($scope.condition.category);
                if (index === -1) {
                    availableCategories.push($scope.condition.category);
                }

                options.success(availableCategories);
            }
        }
    });

    $scope.likelihoodDropdownOptions = {
        dataTextField: 'value',
        dataValueField: 'id',
        dataSource: safeSearchLikelihood,
        valuePrimitive: true
    };

    $scope.categoryDropdownOptions = {
        dataSource: safeSearchCategories,
        valuePrimitive: true
    };

    $scope.showCancelButton = function() {
        return $scope.availableCategories.length !== 4;
    };
}
