import React from 'react';
import { Box, Typography } from '@mui/material';

import { AboutIntegrationRequirement } from './AboutIntegrationRequirement';
import { AboutIntegrationTemplates } from './AboutIntegrationTemplates';
import { AboutIntegrationGetStarted } from './AboutIntegrationGetStarted';
import { AboutModalProps } from '../models';

export const AboutAzureADModal = (props: AboutModalProps) => {
  const { description, exampleImage, exampleAltText } = props;
  return (
    <>
      {description && <Typography sx={{ mb: 2 }}>{description}</Typography>}
      {exampleImage &&
        <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
          <img height="200px" src={exampleImage} alt={exampleAltText} />
        </Box>}
      <AboutIntegrationRequirement>
        Available for ActivTrak paid plans. Add users to an &apos;ActivTrak Do
        Not Track&apos; Azure AD user group to instruct ActivTrak to add the
        user to the Do Not Track list. After setting up this group, click
        &apos;Integrate&apos; to set up your Azure AD integration.
      </AboutIntegrationRequirement>
      <AboutIntegrationTemplates>None</AboutIntegrationTemplates>
      <AboutIntegrationGetStarted>
        <ul>
          <li>
            Learn more about{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/360027278492"
              target="_blank"
              rel="noopener noreferrer"
            >
              Do Not Track
            </a>
            , which suspends user activity tracking and frees up ActivTrak
            licenses.
          </li>
          <li>
            View the Automated User Management via Azure AD{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/8327818605211"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setup Guide
            </a>
            .
          </li>
          <li>
            <a
              href="https://www.activtrak.com/product/integrations/activconnect/?integration"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact sales
            </a>{' '}
            to see the integration in action.
          </li>
        </ul>
      </AboutIntegrationGetStarted>
    </>
  );
};
