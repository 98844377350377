import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

export const SelectInlineLabelSC = styled(Select)`
  & legend {
    display: none;
  }
  & fieldset {
    top: 0;
  }
  & .MuiInputLabel-root {
    top: -2px;
  }
`;
