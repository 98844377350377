import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsConfiguration: IRoute = {
  name: 'app.settings.configuration',
  stateDefinition: {
    url: '/settings/configuration',
    template: '<account-configuration></account-configuration>',
    data: { pageTitle: 'Account Configuration' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsConfiguration;
