export const baseLayoutClasses = {
    root: {
        display: 'flex'
    },
    content: {
        flex: '1 1 100%',
        backgroundColor: 'transparent',
        padding: 0
    }
};
