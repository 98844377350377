import { IAuthenticatedUserService, IGlobals, ILastLogin } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { inMemoryStore } from '../inMemoryStore';

/**
 * Parses JSON string until an object is created.
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {T} - Generic Object
 */
const _parseJson = <T>(stringToParse: string | null): T => {
    if (!stringToParse) {
        return {} as T;
    }

    try {
        const parsedString = JSON.parse(stringToParse);
        return typeof parsedString === 'string'
            ? (_parseJson(parsedString) as T)
            : (parsedString as T);
    } catch (error) {
        console.error(
            `ActivTrak Error: Cannot load object due to invalid JSON.\nObject String: ${stringToParse}\nError: `,
            error
        );
        return {} as T;
    }
};

/**
 * Parses JSON as Globals
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {IGlobals} - Globals Object
 */
export const parseGlobalsJson = (stringToParse: string | null): IGlobals => {
    return _parseJson<IGlobals>(stringToParse);
};

/**
 * Parses JSON as LastLogin
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {ILastLogin} - LastLogin Object
 */
const _parseLastLoginJson = (stringToParse: string | null): ILastLogin => {
    return _parseJson<ILastLogin>(stringToParse);
};

/**
 * Retrieves stringified globals object from local storage.
 *
 * @returns {IGlobals} - Globals Object
 */
const _getGlobalsFromStorage = (): IGlobals => {
    const globalsString = window.localStorage.getItem('activTrak.globals');
    return parseGlobalsJson(globalsString);
};

/**
 * Updates global object loaded in memory with global object stored in local storage.
 * Used to keep object in memory in-sync with object in local storage as other modules
 * could potentially update the stored object. Returns an RxJS observable that can be
 * subscribed to for monitoring for changes to the globals object.
 *
 * _Notifies all observable subscribers of the change._
 *
 * _Use `.subscribe(callback)` to subscribe to changes to the globals object_
 *
 * @returns {BehaviorSubject<IGlobals>} - Observable Globals Object
 */
const _checkLoadedGlobals = (): BehaviorSubject<IGlobals> => {
    const globals = _getGlobalsFromStorage();
    const storedUserToken = globals?.currentUser?.token;
    const cachedUserToken = _globals?.value?.currentUser?.token;

    if (storedUserToken !== cachedUserToken) {
        _globals.next(globals);
        clearLastLogin();
    }

    return _globals;
};

/**
 * Load globals object from window if defined or as an RxJS observable
 */
const _globals: BehaviorSubject<IGlobals> =
    inMemoryStore.get('utilities.globals') ||
    new BehaviorSubject(_getGlobalsFromStorage());

inMemoryStore.set('utilities.globals', _globals);

/**
 * Returns stored user token from loaded globals object
 *
 * @returns {string | undefined} Encoded User Token
 */
const getUserToken = (): string | undefined => {
    const globals = _checkLoadedGlobals();

    // User not logged in anymore return.
    if (!globals.value.currentUser) {
        return;
    }

    return globals.value.currentUser.token;
};

/**
 * Sets the user token in the loaded global object and stores it in local storage
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {string} token - Encode User Token
 * @returns {void} Void
 */
const setUserToken = (token: string): void => {
    if (!token) {
        return;
    }

    const globals = _getGlobalsFromStorage();

    // User not logged in anymore return.
    if (!globals || !globals.currentUser) {
        return;
    }

    globals.currentUser.token = token;
    setGlobals(globals);
};

/**
 * Retrieves loaded global object as an RxJS observable.
 *
 * _Use `.subscribe(callback)` to subscribe to changes to the globals object_
 *
 * @returns {BehaviorSubject<IGlobals>} - Observable Globals Object
 */
const getGlobals = (): BehaviorSubject<IGlobals> => {
    return _checkLoadedGlobals();
};

/**
 * Sets the loaded globals object and saves it in local storage.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {IGlobals} globals - Globals Object
 * @returns {void} Void
 */
const setGlobals = (globals: IGlobals): void => {
    if (!globals) {
        return;
    }

    try {
        // Convert object into JSON string
        const stringifiedGlobals = JSON.stringify(globals);
        // Stringify again to match previous behavior
        // TODO: Remove double stringify once all UI components us the same library for authentication
        const doubleStringifiedGlobals = JSON.stringify(stringifiedGlobals);
        window.localStorage.setItem(
            'activTrak.globals',
            doubleStringifiedGlobals
        );
        _globals.next(globals);
    } catch (error) {
        console.error(
            'ActivTrak Error: Cannot set globals due to JSON stringify error.\nGlobals:',
            globals,
            '\nError:',
            error
        );
    }
};

/**
 * Removes the stored globals object from local storage and sets the loaded global object to an empty object.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @returns {void} Void
 */
const clearGlobals = (): void => {
    window.localStorage.removeItem('activTrak.globals');
    window.localStorage.removeItem('activTrak.msp.backup');
    clearLastLogin();
    _globals.next({});
};

const _showNewLoginNotification: BehaviorSubject<boolean> =
    inMemoryStore.get('utilities.showNewLoginNotification') ||
    new BehaviorSubject(false);

inMemoryStore.set(
    'utilities.showNewLoginNotification',
    _showNewLoginNotification
);

/**
 * Updates the value if subscribers have to show New Login Notification.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {boolean} showNewLoginNotification - Boolean
 * @returns {void} Void
 */
const setShowNewLoginNotification = (
    showNewLoginNotification: boolean
): void => {
    if (typeof showNewLoginNotification !== 'boolean') {
        console.error(`ActivTrak Error: Passed value is not a boolean.`);
        return;
    }

    _showNewLoginNotification.next(showNewLoginNotification);
};

/**
 * Retrieves showNewLoginNotification object as an RxJS observable.
 *
 * _Use `.subscribe(callback)` to subscribe to changes to the globals object_
 *
 * @returns {BehaviorSubject<boolean>} - Observable showNewLoginNotification Object
 */
const getShowNewLoginNotification = (): BehaviorSubject<boolean> => {
    return _showNewLoginNotification;
};

/**
 * Retrieves stringified lastLogin object from local storage.
 *
 * @returns {ILastLogin} - LastLogin Object
 */
const _getLastLoginFromStorage = (): ILastLogin => {
    const lastLoginString = window.localStorage.getItem('activTrak.lastLogin');
    return _parseLastLoginJson(lastLoginString);
};

/**
 * Load lastLogin object from window if defined or as an RxJS observable
 */
const _lastLogin: BehaviorSubject<ILastLogin> =
    inMemoryStore.get('utilities.lastLogin') ||
    new BehaviorSubject(_getLastLoginFromStorage());

inMemoryStore.set('utilities.lastLogin', _lastLogin);

/**
 * Save lastLogin object with the properties of date and IP.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {ILastLogin} lastLogin - lastLogin Object
 * @returns {void} Void
 */
const setLastLogin = (lastLogin: ILastLogin): void => {
    if (!lastLogin) {
        return;
    }

    try {
        // Convert object into JSON string
        const stringifiedLastLogin = JSON.stringify(lastLogin);
        window.localStorage.setItem(
            'activTrak.lastLogin',
            stringifiedLastLogin
        );
        _lastLogin.next(lastLogin);
    } catch (error) {
        console.error(
            'ActivTrak Error: Cannot set lastLogin due to JSON stringify error.\nlastLogin:',
            lastLogin,
            '\nError:',
            error
        );
    }
};

/**
 * Checks the last login date matches the saved last login date.
 *
 * @param {ILastLogin} lastLogin - lastLogin Object
 * @returns {void} Void
 */
const checkLastLogin = (lastLogin: ILastLogin): void => {
    if (!lastLogin) {
        console.error('ActivTrak Error: Last login is not provided.');
        return;
    }

    if (!lastLogin.date || !lastLogin.ip) {
        console.info(
            'ActivTrak Info: Cannot check last login due to a missing one of last login parameters.\nLastLogin:',
            lastLogin
        );
        return;
    }
    _checkLoadedGlobals();

    if (!_lastLogin.value.ip || !_lastLogin.value.date) {
        setLastLogin(lastLogin);
        return;
    }

    if (
        _lastLogin.value.ip !== lastLogin.ip ||
        _lastLogin.value.date !== lastLogin.date
    ) {
        setLastLogin(lastLogin);
        setShowNewLoginNotification(true);
    }
};

/**
 * Removes the stored lastLogin object from local storage and sets the loaded lastLogin object to an empty object.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @returns {void} Void
 */
const clearLastLogin = (): void => {
    window.localStorage.removeItem('activTrak.lastLogin');
    _lastLogin.next({
        ip: undefined,
        date: undefined
    });
};

export const authenticatedUserService: IAuthenticatedUserService = {
    getUserToken,
    setUserToken,
    getGlobals,
    setGlobals,
    clearGlobals,
    setShowNewLoginNotification,
    getShowNewLoginNotification,
    setLastLogin,
    checkLastLogin,
    clearLastLogin
};
