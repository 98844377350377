import React, { useEffect } from 'react';
import { LinearWithLabel } from '../../common/components/Progress';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';

type ConfirmCancelTicketProps = {
  isOpen: boolean;
  percentUploaded: number;
  onContinue: () => void;
  onCancelChanges: () => void;
};

export const ConfirmCancelTicket = (props: ConfirmCancelTicketProps) => {
  const { isOpen, onContinue, onCancelChanges, percentUploaded } = props;

  //show the progress bar while saving the ticket and close modal when complete
  useEffect(() => {
    if (percentUploaded === 100) onCancelChanges();
  }, [onCancelChanges, percentUploaded]);

  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onCancelChanges();
      }}
      aria-labelledby="confirm-cancel-title"
      aria-describedby="confirm-cancel-description"
    >
      <DialogTitle id="confirm-cancel-title">Cancel ticket?</DialogTitle>
      <DialogContent id="confirm-cancel-description">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Your changes have not been submitted. Changes will be lost when
              closing the window. How would you like to proceed?
            </Typography>
          </Grid>
          {percentUploaded > 0 && (
            <Grid item xs={12} sx={{ marginTop: '15px' }}>
              <LinearWithLabel value={percentUploaded} />{' '}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button onClick={onCancelChanges} variant="contained" color="primary">
          Yes, cancel ticket
        </Button>
        <Button variant="text" onClick={onContinue}>
          No, continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
