import React from 'react';
import { List, ListItem, Divider, Typography } from '@mui/material';
import { tmTopUsersClasses } from './TopUsers.style';
import { Tooltip, SkeletonLoader } from '../../';
import { isEmpty, convertSecondsToHoursString, getRatio } from '../../../utilities';
import User from '../../../models/User';

const TopUsers = (props) => {
    let { users, loading, rootClass, contentClass, useTotalTime } = props;

    // Create user array if missing
    // Else sort array
    if (isEmpty(users) || !Array.isArray(users)) {
        users = [];
    } else {
        users.sort((a, b) => b.getDuration(useTotalTime) - a.getDuration(useTotalTime));
    }

    // Fill user array to 3 if less than 3
    if (users.length < 3) {
        const fillStart = users && users.length ? users.length : 0;
        for (let i = fillStart; i < 3; i++) {
            users.push(new User());
        }
    } else if (users.length > 3) {
        // Remove extra users if they exist
        const popEnd = users.length - 3;
        for (let i = 0; i < popEnd; i++) {
            users.pop();
        }
    }

    const renderTopUsersList = () => {
        return (
            <List sx={tmTopUsersClasses.list}>
                {users.map((user) => {
                    return (
                        <React.Fragment key={user.id}>
                            <ListItem key={user.id} sx={tmTopUsersClasses.row}>
                                <SkeletonLoader loading={loading} width="100%">
                                    <Tooltip title={user.name}>
                                        <Typography
                                            sx={
                                                user.name
                                                    ? tmTopUsersClasses.left
                                                    : { ...tmTopUsersClasses.left, ...tmTopUsersClasses.noData }
                                            }
                                            noWrap={true}
                                        >
                                            {user.name || 'N/A'}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        noWrap={true}
                                        sx={
                                            user.getDuration(useTotalTime) > 0
                                                ? tmTopUsersClasses.center
                                                : { ...tmTopUsersClasses.center, ...tmTopUsersClasses.noData }
                                        }
                                    >
                                        {user.getDuration(useTotalTime) > 0
                                            ? `${getRatio(user.getRatio(useTotalTime), 0)}%`
                                            : user.name
                                            ? 'N/A'
                                            : ''}
                                    </Typography>
                                    <Typography
                                        noWrap={true}
                                        sx={
                                            user.getDuration(useTotalTime) > 0
                                                ? tmTopUsersClasses.right
                                                : { ...tmTopUsersClasses.right, ...tmTopUsersClasses.noData }
                                        }
                                    >
                                        {user.getDuration(useTotalTime) > 0
                                            ? convertSecondsToHoursString(user.getDuration(useTotalTime))
                                            : ''}
                                    </Typography>
                                </SkeletonLoader>
                            </ListItem>
                            {users.indexOf(user) < users.length - 1 && <Divider variant="middle" />}
                        </React.Fragment>
                    );
                })}
            </List>
        );
    };

    return (
        <div style={rootClass}>
            <div style={contentClass}>
                <div style={tmTopUsersClasses.title}>
                    <SkeletonLoader className={tmTopUsersClasses.skeleton} loading={loading} width="20%">
                        Top Users
                    </SkeletonLoader>
                </div>
                {renderTopUsersList()}
            </div>
        </div>
    );
};

export default TopUsers;
