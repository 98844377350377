'use strict';

angular.module('app').directive('dataconnectTemplate', dataConnectTemplate);
dataConnectTemplate.$inject = [];
function dataConnectTemplate() {
    return {
        restrict: 'EA',
        template: require('views/widgets/dataConnectTemplate.html'),
        scope: {
            templates: '=',
            twoColumns: '='
        },
        controller: [
            '$scope',
            '$sce',
            '$window',

            function($scope, $sce, $window) {
                $scope.loadImage = $scope.$root.loadImage;

                $scope.trustAsHtml = function (template) {
                    return $sce.trustAsHtml(template);
                }
            
                $scope.loadImage = $scope.$root.loadImage;

                $scope.templateClick = function(template) {
                    template.opened = !template.opened;

                    if ($window.heap) {
                        
                        $window.heap.track('Click - Data Connect Tab - ' + template.name, {
                            opened: template.opened
                        });
                    }
                };
            }
        ]
    };
}
