import { ProductivityColors } from '../../_reactivtrak/src/common/constants';

const ProductivityStatus = Object.freeze({
    UndefinedPassive: -3,
    UnproductivePassive: -2,
    Unproductive: -1,
    Undefined: 0,
    Productive: 1,
    ProductivePassive: 2,
    OfflineMeetings: 9,
    All: null,

    getStatuses() {
        return [
            this.Productive,
            this.ProductivePassive,
            this.Unproductive,
            this.UnproductivePassive,
            this.Undefined,
            this.UndefinedPassive,
            this.OfflineMeetings
        ];
    },

    getField(status) {
        switch (status) {
            case null:
                return 'all';
            case this.Productive:
                return 'productive';
            case this.ProductivePassive:
                return 'productivePassive';
            case this.Undefined:
                return 'undefined';
            case this.UndefinedPassive:
                return 'passive';
            case this.Unproductive:
                return 'unproductive';
            case this.UnproductivePassive:
                return 'unproductivePassive';
            case this.OfflineMeetings:
                return 'offlineMeetings';
            default:
                return;
        }
    },

    getClass(status) {
        return this.getField(status)
            .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
            .toLowerCase();
    },

    getName(status, showActiveLabel, useParentheses) {
        let activeLabel = showActiveLabel ? ' Active' : '';
        let passiveLabel = ' Passive';

        const addParentheses = (string) => {
            return ` (${string.trim()})`;
        };

        if (useParentheses && showActiveLabel) {
            activeLabel = addParentheses(activeLabel);
            passiveLabel = addParentheses(passiveLabel);
        }

        switch (status) {
            case null:
                return 'All';
            case this.Productive:
                return `Productive${activeLabel}`;
            case this.ProductivePassive:
                return `Productive${passiveLabel}`;
            case this.Undefined:
                return `Undefined${activeLabel}`;
            case this.UndefinedPassive:
                return showActiveLabel ? `Undefined${passiveLabel}` : 'Passive';
            case this.Unproductive:
                return `Unproductive${activeLabel}`;
            case this.UnproductivePassive:
                return `Unproductive${passiveLabel}`;
            case this.Offline:
            case this.OfflineMeetings:
                return 'Offline Meetings';
            default:
                return '';
        }
    },

    getStatus(productivity) {
        if (typeof productivity !== 'string') {
            return ProductivityStatus.Undefined;
        }

        switch (productivity.toLowerCase()) {
            case 'productive':
            case 'productiveactive':
                return ProductivityStatus.Productive;
            case 'unproductive':
            case 'unproductiveactive':
                return ProductivityStatus.Unproductive;
            case 'passive':
            case 'idle':
            case 'undefinedpassive':
                return ProductivityStatus.UndefinedPassive;
            case 'productivepassive':
                return ProductivityStatus.ProductivePassive;
            case 'unproductivepassive':
                return ProductivityStatus.UnproductivePassive;
            case 'offline':
            case 'offlineMeetings':
                return ProductivityStatus.OfflineMeetings;
            default:
                return ProductivityStatus.Undefined;
        }
    },

    isPassive(status) {
        return (
            status == this.UnproductivePassive || status == this.ProductivePassive || status == this.UndefinedPassive
        );
    },

    getColor(status) {
        return ProductivityColors[status];
    },

    getProductivityFamily(status) {
        switch (status) {
            case this.Productive:
            case this.ProductivePassive:
            case this.OfflineMeetings:
                return {
                    active: this.Productive,
                    passive: this.ProductivePassive
                };
            case this.Unproductive:
            case this.UnproductivePassive:
                return {
                    active: this.Unproductive,
                    passive: this.UnproductivePassive
                };
            case this.Undefined:
            case this.UndefinedPassive:
            default:
                return {
                    active: this.Undefined,
                    passive: this.UndefinedPassive
                };
        }
    }
});

export default ProductivityStatus;
