import React, { useEffect, useRef, useState } from 'react';
import { SortDirection, Role } from '../../common/enums';
import { ApikeyService, useApiKeyState } from '../services';
import { useNotifications } from '../../common/services/Notifications';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { NoResultsContainerSC } from '../../common/components/NoResults/BaseNoResults.styles';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import CreateApiKeyButton from '../components/CreateApiKeyButton';
import InformativeTopGrid from '../components/InformativeTopGrid';
import ApiKeySuccessDialog from '../components/ApiKeySuccessDialog';
import { useApiKeyColumns } from '../utils/useApiKeyColumns';
import { useAuthorization } from '../../common/services/Authorization';
import { NoAPIKeysIcon } from '../../common/assets/Icons/NoAPIKeysIcon';
import { NoAPIKeysBox } from '../styles/NoAPIKeysBox.styles';
import { ApiKeysLinks } from '../enums';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

const ApiKey = () => {
  const service = ApikeyService();
  const apiKeyState = useApiKeyState({ service });
  const notificationService = useNotifications();
  
  const authorizationService = useAuthorization();
  const isDisabled = useRef<boolean>(
    !authorizationService.hasAnyRole([Role.Admin, Role.SuperAdmin])
  );

  const {
    allApiKeyMembers,
    apiNotification,
    isApiKeyMembersLoading,
    init,
    addApiKeyMembers,
    setApiNotification,
    deleteApiKeyMember,
    setSortedApiKeys
  } = apiKeyState;

  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [newApiKeyName, setNewApiKeyName] = useState<string>('');
  const [newApiKey, setNewApiKey] = useState<string>('');

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (apiNotification?.msg && apiNotification?.type) {
      notificationService[apiNotification.type](apiNotification.msg);
      setApiNotification(undefined);
    }
  }, [
    notificationService,
    apiNotification?.msg,
    apiNotification?.type,
    setApiNotification
  ]);

  const openSuccessDialog = (newKey: string, newName: string) => {
    setNewApiKey(newKey);
    setNewApiKeyName(newName);
    setSuccessDialogOpen(true);
  };
  const columns = useApiKeyColumns({
    deleteApiKeyMember,
    isDisabled: isDisabled.current
  });

  const onCloseSuccessDialog = () => {
    setNewApiKeyName('');
    setNewApiKey('');
    setSuccessDialogOpen(false);
  };

  const onSubmit = async (payload) => {
    const newKey = await addApiKeyMembers(payload);
    openSuccessDialog(newKey, payload.description);
    return newKey;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ApiKeySuccessDialog
          open={successDialogOpen}
          onClose={() => setSuccessDialogOpen(false)}
          newApiKeyName={newApiKeyName}
          newApiKey={newApiKey}
        />
        {!allApiKeyMembers?.length ? (
          <NoResultsContainerSC>
            <NoAPIKeysBox>
              <NoAPIKeysIcon />
              <Typography variant="subtitle1" style={{ marginTop: '36px' }}>
                No API Keys
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '32px' }}>
                Add your first API key to get started
              </Typography>
              <CreateApiKeyButton
                onSubmit={onSubmit}
                isDisabled={isDisabled.current}
                onCloseSuccessDialog={onCloseSuccessDialog}
              />
            </NoAPIKeysBox>
            <Box sx={{ paddingTop: '54px' }}>
              <Link href={ApiKeysLinks.Learn_More}>Learn More</Link> about API
              Keys
            </Box>
          </NoResultsContainerSC>
        ) : (
          <Box>
            <Grid>
              <InformativeTopGrid
                onSubmit={onSubmit}
                isDisabled={isDisabled.current}
                onCloseSuccessDialog={onCloseSuccessDialog}
              ></InformativeTopGrid>
            </Grid>
            <Grid>
              <CustomGrid
                height={'65vh'}
                isLoading={isApiKeyMembersLoading}
                data={allApiKeyMembers}
                columns={columns}
                onSortOrder={setSortedApiKeys}
                initialSortDirection={SortDirection.Descending}
              />
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ApiKey;

export const ApiKeyComponent = () => (
  <ReactivTrakComponentWrapper>
    <ApiKey />
  </ReactivTrakComponentWrapper>
);
