'use strict';

angular.module('app')
    .directive('busyModal', BusyModal);

function BusyModal() {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            content: '@'
        },
        template: require('views/modals/busyModal.html'),
        controller: BusyModalCtrl
    };
}

BusyModalCtrl.$inject = ['$scope', 'browserServiceFunctions'];

function BusyModalCtrl($scope, browserServiceFunctions) {
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();
}
