import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ExpiringIcon } from '../../common/assets/Icons';
import { red4 } from '../../common/constants/styleGuide';

interface IExpiringStatusIconProps {
  dateExpires: string;
}

export const ExpiringStatusIcon: React.FC<IExpiringStatusIconProps> = (
  props
) => {
  const dateExp = new Date(props.dateExpires);
  const today = new Date();
  const msDay = 86400000;
  const dayDiff = (dateExp.valueOf() - today.valueOf()) / msDay;

  return dayDiff > 0 && dayDiff <= 30 ? (
    <Tooltip title="Your key is about to expire. Create a new key to minimize disruption.">
      <IconButton>
        <ExpiringIcon />
      </IconButton>
    </Tooltip>
  ) : dayDiff <= 0 ? (
    <Tooltip title="Your key is expired. You will need to create a new key.">
      <IconButton>
        <WarningRoundedIcon htmlColor={red4} />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
};
