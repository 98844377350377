import axios from 'axios';
import { authenticatedUserService } from '../authModule';
import apiInterceptors from './apiInterceptors';
import { IActivTrakRequestConfig, IHeaders, IApiService } from './interfaces';
import version from '../../../version.json';

/**
 * Retrieves child account token from local storage using available URL parameter.
 *
 * @returns {string | undefined} Encoded Child Account Token
 */
const _getChildAccountToken = (): string | undefined => {
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get('accountId');

    // Return undefined if no account id found in URL
    if (!accountId) {
        return;
    }

    const child = window.localStorage.getItem('activTrak.child-' + accountId);

    // Return undefined if no child object found in local storage
    if (!child) {
        return;
    }

    try {
        // Return encoded child account token
        return JSON.parse(child).token;
    } catch (e) {
        console.error(
            `ActivTrak Error: Unable to parse child token from storage object.\nAccound Id: ${accountId}\nJSON String: ${child}\nError:`,
            e
        );
        // Return undefined if an error occurred parsing JSON string.
        return;
    }
};

/**
 * Sends an Axios API request and returns a promise.
 *
 * @param {string} path
 * @param {IActivTrakRequestConfig} config
 */
const apiRequest = async (
    path: string,
    {
        method,
        baseURL,
        params,
        data,
        headers = {},
        timeout,
        responseType = 'json',
        authorizationRequired = false
    }: IActivTrakRequestConfig | undefined = {}
): Promise<unknown> => {
    const defaultHeaders: IHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-AT-Version': version?.version || 'x.x.x'
    };

    if (authorizationRequired) {
        const childToken = _getChildAccountToken();
        defaultHeaders.Authorization =
            childToken || authenticatedUserService.getUserToken();
    }

    const enableWithCredentials = path.indexOf('/api') >= 0;
    const combinedHeaders = { ...defaultHeaders, ...headers };

    try {
        const response = await axios({
            method,
            baseURL,
            url: path,
            params,
            data,
            headers: combinedHeaders,
            timeout,
            responseType,
            withCredentials: enableWithCredentials
        });
        if (!response.status || response.status >= 400) {
            throw new Error(JSON.stringify(response));
        }
        return response;
    } catch (error) {
        console.error(
            `ActivTrak Error: An error occurred during a network request.\nError: ${error}\nUrl: ${path}\nHeaders:`,
            combinedHeaders
        );
        throw error;
    }
};

apiInterceptors();

export const apiService: IApiService = {
    get: (
        path: string,
        config: IActivTrakRequestConfig | undefined
    ): Promise<unknown> => apiRequest(path, { ...config, method: 'get' }),
    post: (path: string, config: IActivTrakRequestConfig): Promise<unknown> =>
        apiRequest(path, { ...config, method: 'post' }),
    put: (path: string, config: IActivTrakRequestConfig): Promise<unknown> =>
        apiRequest(path, { ...config, method: 'put' }),
    delete: (
        path: string,
        config: IActivTrakRequestConfig | undefined
    ): Promise<unknown> => apiRequest(path, { ...config, method: 'delete' })
};
