'use strict';

// This widget extends the existing Kendo Pager widget in order to:
// 1. Add the "refresh" parameter when a data source is refreshed.
// 2. Limit number of pages.
// 3. Display total items count in the slider mode.
// It's registered as "kendo-custom-pager" directive.

var plg = (function (init) {
    return kendo.ui.Pager.extend({
        options: {
            name: 'CustomPager'
        },
        init: function (_element, _options) {
            init.call(this, _element, _options);
        },
        _refreshClick: function(e) {
            e.preventDefault();
            this.dataSource.read({refresh: true});
        },
        totalPages: function() {
            var total = this.dataSource.pagerTotal && this.dataSource.pagerTotal();
            if (total === undefined) {
                total = this.dataSource.total() || 0;
            }
            if (this.dataSource.entryLimit) {
                total = Math.min(total, this.dataSource.entryLimit);
            }
            return Math.ceil(total / (this.pageSize() || 1));
        },
    });

})(kendo.ui.Pager.fn.init);
kendo.ui.plugin(plg);