import {
  IUser,
  IUserDto,
  IUserDetail,
  IUserMerge,
  IScheduledAction,
  IClientV1Dto
} from '../models';

export function mapToUsers(users: IUserDto[]): IUser[] {
  return users?.map((row) => {
    const {
      userid,
      firstlog,
      lastlog,
      logondomain,
      alias,
      lastss,
      storage,
      user,
      scheduledActionId,
      scheduledActionName
    } = row;

    return {
      id: userid,
      firstLog: firstlog,
      lastLog: lastlog,
      logonDomain: logondomain,
      alias,
      lastss,
      storage,
      user,
      scheduledActionId,
      scheduledActionName,
      selected: false,
      mergePending: false
    };
  });
}

export function mapClientV1ToUsers(users: IClientV1Dto[]): IUser[] {
  return users?.map((row) => {
    const {
      id,
      domain,
      name,
      alias,
      firstActivity,
      lastActivity,
      lastScreenshot,
      deleteDate,
      mergePending
    } = row;

    return {
      id: id,
      firstLog: firstActivity,
      lastLog: lastActivity,
      logonDomain: domain,
      alias: alias,
      lastss: lastScreenshot,
      storage: null,
      user: name,
      scheduledActionId: deleteDate ? 1 : 0,
      scheduledActionName: deleteDate ? 'delete' : null,
      selected: false,
      mergePending: mergePending
    };
  });
}

export function mapToDoNotTrackDtos(users: IUser[]): any[] {
  return users?.map((row) => {
    const { id, alias, logonDomain, user } = row;

    const dntDtoUser = {
      userName: user,
      userId: id.toString(),
      userType: 'User',
      groupType: 'User',
      tag: null,
      domain: logonDomain,
      alias: alias,
      userCount: 0,
      computerCount: 0,
      userMemberList: null,
      computerMemberList: null,
      skipMemberCount: false
    };
    return dntDtoUser;
  });
}

export function mapToDoNotTrackV1Dtos(users: IUser[]): any[] {
  return users?.map((row) => {
    const { id, alias, logonDomain, user } = row;

    return {
      userId: id,
      userName: user,
      logonDomain: logonDomain,
      alias: alias
    };
  });
}

export function mapToMergeDto(users: IUser[]): any[] {
  return users?.map((row) => {
    const { id, alias, logonDomain, user } = row;

    const mergeDtoUser = {
      user: user,
      userId: id.toString(),
      logonDomain: logonDomain,
      alias: alias
    };
    return mergeDtoUser;
  });
}

export function mapToScheduledMergeDto(merges: any[]): IUserMerge[] {
  return merges?.map((row) => {
    const { logondomain, user, alias, record, lastLog, userid, primaryuserid } =
      row;

    const mergeDto = {
      logondomain: logondomain,
      user: user,
      alias: alias,
      record: record,
      lastLog: lastLog,
      userid: userid,
      primaryuserid: primaryuserid
    };
    return mergeDto;
  });
}

export function mapToScheduledActionDto(actions: any[]): IScheduledAction[] {
  return actions?.map((row) => {
    const {
      id,
      typeid,
      typename,
      actionname,
      description,
      scheduledtime,
      completedtime,
      filter,
      primary,
      secondary,
      dntid
    } = row;

    const scheduledActionDto = {
      id: id,
      typeid: typeid,
      typename: typename,
      actionname: actionname,
      description: description,
      scheduledtime: scheduledtime,
      completedtime: completedtime,
      filter: filter,
      primary: primary,
      secondary: secondary,
      dntid: dntid
    };
    return scheduledActionDto;
  });
}

export function mapClientV1ToScheduledActionDto(
  actions: IClientV1Dto[]
): IScheduledAction[] {
  return actions
    ?.filter((c) => c.deleteDate)
    .map((row) => {
      const { id, domain, name, doNotTrack } = row;

      return {
        id: id,
        typeid: id,
        typename: 'user',
        actionname: null,
        description: null,
        scheduledtime: null,
        completedtime: null,
        filter: null,
        primary: domain,
        secondary: name,
        dntid: doNotTrack ? 1 : null
      };
    });
}

export const mapToUserDetail = (userDetail: any): IUserDetail => {
  const {
    compid,
    lcount,
    sstorage,
    vstorage,
    scount,
    vcount,
    user,
    logondomain,
    alias,
    firstlog,
    lastlog
  } = userDetail;

  return {
    id: compid,
    videoStorage: vstorage,
    screenShotStorage: sstorage,
    logCount: lcount,
    screenShotCount: scount,
    videoCount: vcount,

    userName: user,
    logonDomain: logondomain,
    alias: alias,
    firstLog: firstlog,
    lastLog: lastlog
  };
};
