import {
    getAllCategories,
    getLocations,
    getLocationExhaustive,
    getAccountSettings,
    setContextSwitchSettings,
    toggleCollaborationNextRefresh,
    setActiveDayProductiveHrsThreshold,
    setAccountLevelGoal,
    setUtilizationThreshold,
    setExcludeNonBizActivity,
    setHideUserActivityData,
    setLocationThreshold,
    setInsightsSubscribeOthers
} from '_app/insights/insightsServiceFunctions.js';
import { FeatureFlag } from '_reactivtrak/src/common/enums/FeatureFlag';
import { BundleFlag } from '_reactivtrak/src/common/enums/BundleFlag';

angular.module('app').controller('insightsSettingsCtrl', InsightsSettingsCtrl);

InsightsSettingsCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'notificationService',
    'authorizationService',
    'envConfig',
    'atHelperFunctions'
];

var _contextSwitchMin = 1;
var _contextSwitchMax = 50;

var _locationThresholdMin = 51;
var _locationThresholdMax = 99;

var _utilizationThresholdMin = 10;
var _utilizationThresholdMax = 80;

function InsightsSettingsCtrl(
    $scope,
    $rootScope,
    $state,
    notificationService,
    authorizationService,
    envConfig,
    atHelperFunctions
) {
    if (!authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
        $state.go('app.reports.insightsPromo');
    }

    var isInsightsConfigMetricsEnabled = authorizationService.hasFeature(BundleFlag.InsightsConfigMetrics);
    var isInsightsConfigPrivacyEnabled = authorizationService.hasFeature(BundleFlag.InsightsConfigPrivacy);
    var isInsightsConfigLocationEnabled = authorizationService.hasFeature(BundleFlag.InsightsConfigLocation);
    var isInsightsConfigSubscriptionsEnabled = authorizationService.hasFeature(BundleFlag.InsightsConfigSubscriptions);

    $scope.isInsightsConfigMetricsAveragesEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsAverages
    );
    $scope.isInsightsConfigMetricsAcctGoalsEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsAcctGoals
    );
    $scope.isInsightsConfigMetricsUtilizationEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsUtilization
    );
    $scope.isInsightsConfigMetricsAttentionShiftEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsAttentionShift
    );
    $scope.isInsightsConfigMetricsCollabEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsCollab
    );

    var isInsightsConfigMetricsProductiveHrsEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsProductiveHrs
    );
    var isInsightsConfigMetricsFocusHrsEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsFocusHrs
    );
    var isInsightsConfigMetricsCollabHrsEnabled = authorizationService.hasFeature(
        BundleFlag.InsightsConfigMetricsCollabHrs
    );

    var getTabOptions = function () {
        var tabs = [];
        if (isInsightsConfigMetricsEnabled) {
            tabs.push({
                heapId: 'id_a339d5ae-c82d-11ed-afa1-0242ac120002',
                text: 'metrics',
                value: 'METRICS'
            });
        }
        if (isInsightsConfigPrivacyEnabled) {
            tabs.push({
                heapId: 'id_a339d7d4-c82d-11ed-afa1-0242ac120002',
                text: 'privacy',
                value: 'PRIVACY'
            });
        }
        if (isInsightsConfigLocationEnabled) {
            tabs.push({
                heapId: 'id_a339d8e2-c82d-11ed-afa1-0242ac120002',
                text: 'location',
                value: 'LOCATION'
            });
        }
        if (isInsightsConfigSubscriptionsEnabled) {
            tabs.push({
                heapId: 'id_a339d9d2-c82d-11ed-afa1-0242ac120002',
                text: 'subscriptions',
                value: 'SUBSCRIPTIONS'
            });
        }
        return { tabs };
    };

    var getMetricOptions = function () {
        var opts = [];
        if (isInsightsConfigMetricsProductiveHrsEnabled) {
            opts.push({ value: '1', name: 'Productive Hrs / Day' });
        }
        if (isInsightsConfigMetricsFocusHrsEnabled) {
            opts.push({ value: '4', name: 'Focus Hrs / Day' });
        }
        if (isInsightsConfigMetricsCollabHrsEnabled) {
            opts.push({ value: '8', name: 'Collaboration Hrs / Day' });
        }
        return opts;
    };

    $scope.metricOptions = {
        dataTextField: 'name',
        dataValueField: 'value',
        dataSource: getMetricOptions(),
        valuePrimitive: true
    };

    $scope.canEdit = authorizationService.getAuthorizationLevel('app.reports.insightsSettings') === 'edit';
    $scope.schedulingEnabled = authorizationService.hasFeature(FeatureFlag.InsightsScheduling);
    $scope.setAppFullHeightHere();
    $scope.loading = true;
    $scope.tabOptions = getTabOptions();
    $scope.tab = 'metrics'; //default selected tab

    var allCategoriesPromise = getAllCategories(envConfig.apiUrl(), notificationService);
    var locationsPromise = getLocations(envConfig.apiUrl(), notificationService);
    var locationExhaustivePromise = getLocationExhaustive(envConfig.apiUrl(), notificationService);
    var accountSettingsPromise = getAccountSettings(envConfig.apiUrl(), notificationService);

    Promise.all([allCategoriesPromise, locationsPromise, locationExhaustivePromise, accountSettingsPromise]).then(
        function ([allCategoriesResponse, locationsResponse, locationExhaustiveResponse, accountSettingsResponse]) {
            $scope.accountSettings = accountSettingsResponse.accountSettings;

            if (!$scope.accountSettings.applications) {
                notificationService.showNotification(
                    'Error getting ATTENTION SHIFTING setting: applications/sites',
                    'danger'
                );
                console.error('ActivTrak Error: Error getting ATTENTION SHIFTING setting: applications/sites');
            }
            if (!$scope.accountSettings.screens) {
                notificationService.showNotification(
                    'Error getting ATTENTION SHIFTING setting: screen views',
                    'danger'
                );
                console.error('ActivTrak Error: Error getting ATTENTION SHIFTING setting: screen views');
            }
            if ($scope.accountSettings.allowSubscribeOthers == null) {
                notificationService.showNotification('Error getting SUBSCRIPTIONS & SHARING setting', 'danger');
                console.error('ActivTrak Error: Error getting SUBSCRIPTIONS & SHARING setting');
            }
            if ($scope.accountSettings.activeDayProductiveHrsThreshold == null) {
                notificationService.showNotification('Error getting THRESHOLD TO COUNT ACTIVE DAYS setting', 'danger');
                console.error('ActivTrak Error: Error getting THRESHOLD TO COUNT ACTIVE DAYS setting');
            }

            var metricIdToValue = {
                1: $scope.accountSettings.productiveHrsDayDefaultTarget,
                4: $scope.accountSettings.focusedHrsDayDefaultTarget,
                8: $scope.accountSettings.collaborationHrsDayDefaultTarget
            };
            $scope.accountSettings['accountLevelMetrics'] = metricIdToValue;
            $scope.accountGoalValue = $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric];
            $scope.applicationsSaved = $scope.accountSettings.applications;
            $scope.screensSaved = $scope.accountSettings.screens;
            $scope.activeDayProductiveHrsThresholdSaved = $scope.accountSettings.activeDayProductiveHrsThreshold;
            $scope.locationThresholdSaved = $scope.accountSettings.locationThreshold;
            $scope.locationThresholdFormatted = $scope.accountSettings.locationThreshold + '%';
            $scope.utilizationThresholdSaved = $scope.accountSettings.utilizationThreshold;
            $scope.utilizationThresholdFormatted = $scope.accountSettings.utilizationThreshold + '%';
            $scope.excludeNonBizActivity = $scope.accountSettings.excludeNonBizActivity === 'ON';
            $scope.hideUserActivityData = $scope.accountSettings.hideUserActivityData === 'ON';
            $scope.cats = allCategoriesResponse;
            $scope.categories = angular.copy($scope.cats);
            $scope.locations = locationsResponse;
            $scope.locationExhaustive = locationExhaustiveResponse;
            $scope.loading = false;
            atHelperFunctions.safeApply($rootScope);
        }
    );

    // -----------------------------------------------------------------------
    // --------------------------- METRICS TAB -------------------------------
    // -----------------------------------------------------------------------

    // Attention shift start -------------------------------------------------
    $scope.saveDisabled = true;
    $scope.saveContextSettings = function () {
        if (!$scope.saveDisabled || !$scope.canEdit) {
            $scope.loading = true;
            $scope.accountSettings.applications =
                $scope.accountSettings.applications &&
                Math.min(_contextSwitchMax, Math.max(_contextSwitchMin, $scope.accountSettings.applications));
            $scope.accountSettings.screens =
                $scope.accountSettings.screens &&
                Math.min(_contextSwitchMax, Math.max(_contextSwitchMin, $scope.accountSettings.screens));

            setContextSwitchSettings(
                envConfig.apiUrl(),
                {
                    applications: $scope.accountSettings.applications,
                    screens: $scope.accountSettings.screens
                },
                notificationService
            )
                .then(function () {
                    $scope.loading = false;
                    $scope.applicationsSaved = $scope.accountSettings.applications;
                    $scope.screensSaved = $scope.accountSettings.screens;
                    $scope.saveDisabled = true;
                    atHelperFunctions.safeApply($rootScope);
                })
                .catch(function () {
                    $scope.loading = false;
                    atHelperFunctions.safeApply($rootScope);
                });
        }
    };

    $scope.contextInputKeyPressed = function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        if (key === 13) {
            $scope.saveContextSettings();
        } else if (
            !(
                [8, 9, 46, 110].indexOf(key) !== -1 ||
                (key == 65 && (e.ctrlKey || e.metaKey)) ||
                (key >= 35 && key <= 40) ||
                (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
                (key >= 96 && key <= 105)
            )
        ) {
            e.preventDefault();
        }
    };

    $scope.checkChange = function () {
        if (
            !$scope.accountSettings.screens ||
            !$scope.accountSettings.applications ||
            ($scope.accountSettings.screens == $scope.screensSaved &&
                $scope.accountSettings.applications == $scope.applicationsSaved)
        ) {
            $scope.saveDisabled = true;
        } else {
            $scope.saveDisabled = false;
        }
    };
    // Attention shift end -------------------------------------------------

    // Collaboration time start -------------------------------------------------
    $scope.categoriesChanged = false;
    $scope.saveCategories = function () {
        if (!$scope.categoriesChanged) {
            return;
        }

        $scope.loading = true;
        var changeList = [];
        for (var i = 0; i < $scope.categories.length; i++) {
            if ($scope.categories[i].isCollaborationNextRefresh !== $scope.cats[i].isCollaborationNextRefresh) {
                changeList.push($scope.categories[i]);
            }
        }
        toggleCollaborationNextRefresh(envConfig.apiUrl(), changeList, notificationService)
            .then(function () {
                $scope.cats = angular.copy($scope.categories);
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.categories = angular.copy($scope.cats);
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
        $scope.categoriesChanged = false;
    };

    $scope.categoryChange = function () {
        $scope.categoriesChanged = !angular.equals($scope.cats, $scope.categories);
    };
    // Collaboration time end -------------------------------------------------

    // Configuration to compute averages start -----------------------------------
    $scope.activeDayProductiveHrsThresholdChanged = false;
    $scope.activeDayChange = function () {
        if (
            null === $scope.accountSettings.activeDayProductiveHrsThreshold ||
            undefined === $scope.accountSettings.activeDayProductiveHrsThreshold
        ) {
            $scope.activeDayProductiveHrsThresholdChanged = false;
            $scope.accountSettings.activeDayProductiveHrsThreshold = $scope.activeDayProductiveHrsThresholdSaved;
            return;
        }

        if ($scope.accountSettings.activeDayProductiveHrsThreshold % 1 !== 0) {
            //is float value, truncate to 1 place after decimal
            $scope.accountSettings.activeDayProductiveHrsThreshold = parseFloat(
                parseFloat($scope.accountSettings.activeDayProductiveHrsThreshold).toFixed(1)
            );
        }

        $scope.activeDayProductiveHrsThresholdChanged =
            $scope.activeDayProductiveHrsThresholdSaved !== $scope.accountSettings.activeDayProductiveHrsThreshold;
    };

    $scope.saveActiveDayProductiveHrsThreshold = function () {
        if (!$scope.activeDayProductiveHrsThresholdChanged) {
            return;
        }

        $scope.loading = true;
        var val = parseFloat($scope.accountSettings.activeDayProductiveHrsThreshold).toFixed(1);
        setActiveDayProductiveHrsThreshold(
            envConfig.apiUrl(),
            { activeDayProductiveHrsThreshold: val },
            notificationService
        )
            .then(function () {
                $scope.loading = false;
                $scope.activeDayProductiveHrsThresholdSaved = $scope.accountSettings.activeDayProductiveHrsThreshold;
                $scope.activeDayProductiveHrsThresholdChanged = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };
    // Configuration to compute averages end -------------------------------------

    // Account-level goals start -------------------------------------------------
    $scope.accountGoalMetric = '1'; //Default to 'Productive Hrs / Day'
    $scope.saveAccountGoalButtonEnabled = false;

    $scope.onAccountGoalMetricChange = function (accountGoalMetric) {
        $scope.accountGoalMetric = accountGoalMetric;
        $scope.accountGoalValue = $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric];
        $('#accountGoalValue').val($scope.accountGoalValue);
        $scope.saveAccountGoalButtonEnabled = false;
    };

    $scope.onAccountGoalValueChange = function (value) {
        if (!value) {
            $scope.accountGoalValue = $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric];
            $('#accountGoalValue').val($scope.accountGoalValue);
            return;
        }

        if (value % 1 !== 0) {
            //is float value, truncate to 1 place after decimal
            $scope.accountGoalValue = parseFloat(parseFloat(value).toFixed(1));
        } else {
            $scope.accountGoalValue = value;
        }
        $('#accountGoalValue').val($scope.accountGoalValue);

        $scope.saveAccountGoalButtonEnabled =
            $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric] !== $scope.accountGoalValue;
    };

    $scope.saveAccountGoal = function () {
        if (!$scope.saveAccountGoalButtonEnabled || $scope.loading) {
            return;
        }

        $scope.loading = true;
        setAccountLevelGoal(
            envConfig.apiUrl(),
            {
                metricId: $scope.accountGoalMetric,
                previousValue: $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric],
                targetValue: $scope.accountGoalValue
            },
            notificationService
        )
            .then(function (result) {
                $scope.loading = false;
                $scope.saveAccountGoalButtonEnabled = false;
                if (result.success) {
                    // api returns true/false
                    //update map
                    $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric] = $scope.accountGoalValue;
                } else {
                    $scope.accountGoalValue = $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric];
                    $('#accountGoalValue').val($scope.accountGoalValue);
                }
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                $scope.saveAccountGoalButtonEnabled = false;
                $scope.accountGoalValue = $scope.accountSettings.accountLevelMetrics[$scope.accountGoalMetric];
                $('#accountGoalValue').val($scope.accountGoalValue);
                atHelperFunctions.safeApply($rootScope);
            });
    };
    // Account-level goals end -------------------------------------------------

    // Utilization Level Threshold start -------------------------------------------------
    $scope.utilizationThresholdChanged = false;
    $scope.saveUtilizationThreshold = function () {
        $scope.loading = true;
        setUtilizationThreshold(envConfig.apiUrl(), $scope.accountSettings.utilizationThreshold, notificationService)
            .then(function () {
                $scope.loading = false;
                $scope.utilizationThresholdSaved = $scope.accountSettings.utilizationThreshold;
                $scope.utilizationThresholdChanged = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.utilizationThresholdChange = function (e) {
        $scope.utilizationThresholdFormatted = e.target.value;
        if (
            null === $scope.utilizationThresholdFormatted ||
            undefined === $scope.utilizationThresholdFormatted ||
            !$scope.utilizationThresholdFormatted.match(/^\d+(\.\d+){0,1}%{0,1}$/gi)
        ) {
            $scope.utilizationThresholdChanged = false;
            $scope.accountSettings.utilizationThreshold = $scope.utilizationThresholdSaved;
            $scope.utilizationThresholdFormatted = $scope.accountSettings.utilizationThreshold + '%';
            return;
        }

        $scope.utilizationThresholdFormatted = $scope.utilizationThresholdFormatted.replace(/[^0-9\.]/gi, '');
        $scope.utilizationThresholdFormatted = Math.round($scope.utilizationThresholdFormatted);
        $scope.utilizationRangeAdjust =
            $scope.utilizationThresholdFormatted &&
            Math.min(
                _utilizationThresholdMax,
                Math.max(_utilizationThresholdMin, $scope.utilizationThresholdFormatted)
            );

        if ($scope.utilizationThresholdFormatted != $scope.utilizationRangeAdjust) {
            notificationService.showNotification('Select a value from 10% to 80%', 'danger');
            $scope.utilizationThresholdFormatted = $scope.utilizationRangeAdjust;
            e.target.value = $scope.utilizationThresholdFormatted;
        }

        $scope.accountSettings.utilizationThreshold = $scope.utilizationThresholdFormatted;
        $scope.utilizationThresholdFormatted = $scope.utilizationThresholdFormatted + '%';
        e.target.value = $scope.utilizationThresholdFormatted;

        $scope.utilizationThresholdChanged =
            $scope.utilizationThresholdSaved !== $scope.accountSettings.utilizationThreshold;
    };
    // Utilization Level Threshold end ----------------------------------------------------

    // -----------------------------------------------------------------------
    // --------------------------- PRIVACY TAB -------------------------------
    // -----------------------------------------------------------------------

    // Exclude non business details start -------------------------------------------------
    $scope.toggleExcludeNonBizActivity = function (excludeNonBizActivitySetting) {
        if ($scope.loading) {
            return;
        }

        $scope.loading = true;
        setExcludeNonBizActivity(envConfig.apiUrl(), excludeNonBizActivitySetting ? 'ON' : 'OFF', notificationService)
            .then(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.excludeNonBizActivityOptions = {
        label: 'Exclude details of non-business apps / sites in Insights dashboards.',
        onChange: $scope.toggleExcludeNonBizActivity,
        disabled: !$scope.canEdit
    };
    // Exclude non business details end -------------------------------------------------

    // Hide user activity data start -------------------------------------------------
    $scope.toggleHideUserActivityData = function (hideUserActivityData) {
        if ($scope.loading) {
            return;
        }

        $scope.loading = true;
        setHideUserActivityData(envConfig.apiUrl(), hideUserActivityData ? 'ON' : 'OFF', notificationService)
            .then(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.hideUserActivityDataOptions = {
        label: 'Hide User Activity Data in Insights.',
        onChange: $scope.toggleHideUserActivityData,
        disabled: !$scope.canEdit
    };
    // Hide user activity data end ---------------------------------------------------

    // -----------------------------------------------------------------------
    // --------------------------- LOCATION TAB ------------------------------
    // -----------------------------------------------------------------------

    // Threshold for office/remote work days start -------------------------------------------------
    $scope.locationThresholdChanged = false;
    $scope.saveLocationThreshold = function () {
        $scope.loading = true;
        var val = parseFloat($scope.accountSettings.locationThreshold).toFixed(1);
        setLocationThreshold(envConfig.apiUrl(), { locationThreshold: val }, notificationService)
            .then(function () {
                $scope.loading = false;
                $scope.locationThresholdSaved = $scope.accountSettings.locationThreshold;
                $scope.locationThresholdChanged = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.locationThresholdChange = function () {
        if (
            null === $scope.locationThresholdFormatted ||
            undefined === $scope.locationThresholdFormatted ||
            !$scope.locationThresholdFormatted.match(/^\d+(\.\d+){0,1}%{0,1}$/gi)
        ) {
            $scope.locationThresholdChanged = false;
            $scope.accountSettings.locationThreshold = $scope.locationThresholdSaved;
            $scope.locationThresholdFormatted = $scope.accountSettings.locationThreshold + '%';
            return;
        }

        $scope.locationThresholdFormatted = $scope.locationThresholdFormatted.replace(/[^0-9\.]/gi, '');
        $scope.locationThresholdFormatted = Math.round($scope.locationThresholdFormatted);
        $scope.locationRangeAdjust =
            $scope.locationThresholdFormatted &&
            Math.min(_locationThresholdMax, Math.max(_locationThresholdMin, $scope.locationThresholdFormatted));

        if ($scope.locationThresholdFormatted != $scope.locationRangeAdjust) {
            notificationService.showNotification('Select a value from 51% to 99%', 'danger');
            $scope.locationThresholdFormatted = $scope.locationRangeAdjust;
        }

        $scope.accountSettings.locationThreshold = $scope.locationThresholdFormatted;
        $scope.locationThresholdFormatted = $scope.locationThresholdFormatted + '%';

        if ($scope.accountSettings.locationThreshold % 1 !== 0) {
            //is float value, truncate to 1 place after decimal
            $scope.accountSettings.locationThreshold = parseFloat(
                parseFloat($scope.accountSettings.locationThreshold).toFixed(1)
            );
        }

        $scope.locationThresholdChanged = $scope.locationThresholdSaved !== $scope.accountSettings.locationThreshold;
    };
    // Threshold for office/remote work days end -------------------------------------------------

    // -----------------------------------------------------------------------
    // --------------------------- SUBSCRIPTIONS TAB -------------------------
    // -----------------------------------------------------------------------

    // Subscriptions and sharing start -------------------------------------------------
    $scope.toggleAllowUsersToSubscribeOthers = function (allowUsersToSubscribeOthers) {
        $scope.loading = true;
        setInsightsSubscribeOthers(envConfig.apiUrl(), allowUsersToSubscribeOthers ? 1 : 0, notificationService)
            .then(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(function () {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.allowUsersToSubscribeOthersOptions = {
        label: 'Allow Users to Share Personal Insights with direct reports or subscribe other application users.',
        onChange: $scope.toggleAllowUsersToSubscribeOthers,
        disabled: !$scope.canEdit
    };
    // Subscriptions and sharing end-------------------------------------------------
}
