import { styled } from '@mui/material/styles';
import {
  gray1,
  primaryColor,
  primaryAqua,
  secondaryFontSize,
  regularFontWeight,
  red2,
  aqua1,
  gray5,
  breakpoints,
  blue9,
  gray17
} from '../../common/constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { SelectNoOutline } from '../../common/components/Select/SelectNoOutline';

export const useWidgetTemplateStyles = {
  spacedContentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px'
  },
  singleContentHeader: {
    display: 'flex',
    paddingBottom: '10px'
  }
};

export const ColorBackground = styled(Box)<{
  bkgdColor?: string;
}>`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    left: -20px;
    width: 4000px;
    z-index: -1;
    background-color: ${(props) =>
      props?.bkgdColor ? props.bkgdColor : gray1};
  }
`;

export const BasicDashboardContainer = styled(Grid)`
  &.MuiGrid-container {
    max-width: 1200px;
    min-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
`;

export const DashboardScrollbar = styled(Box)`
  overflow-x: auto;
  padding-right: 10px;
`;

export const DashboardMenuLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: ${primaryColor};
    font-size: ${secondaryFontSize} !important;
  }
`;

export const DashboardSelectMenu = styled(SelectNoOutline)`
  font-size: ${secondaryFontSize} !important;
`;

export const DashboardSelectMenuItem = styled(MenuItem)`
  font-size: ${secondaryFontSize} !important;
`;

export const DashboardMenuLabelContainer = styled(Box)`
  display: inline-block;
  text-align: right;
  vertical-align: bottom;
  padding-right: 10px;
`;

export const NoResultsWidgetBox = styled(Box)<{
  width?: string;
  height?: string;
}>`
  width: ${(props) => (props.width ? props.width : '1150px')};
  height: ${(props) => (props.height ? props.height : '314px')};
  padding-left: 10px;
`;

export const ColorCheckbox = styled(Checkbox)<{
  boxcolor?: string;
}>`
  color: ${(props) =>
    props.boxcolor ? props.boxcolor : primaryAqua} !important;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
`;

export const DashboardInfoText = styled(Typography)<{
  $paddingBottom?: string;
  $fontSize?: number;
  $fontWeight?: number;
  $fontColor?: string;
  $h2LineHeight?: string;
}>`
  display: inline-block;
  text-align: 'center';
  padding-bottom: ${({ $paddingBottom }) =>
    $paddingBottom ? $paddingBottom : 'inherit'};
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}px!important;`};
  ${({ $fontWeight }) => $fontWeight && `font-weight: ${$fontWeight};`};
  ${({ $fontColor }) => $fontColor && `color: ${$fontColor};`};

  &&.MuiTypography-root.MuiTypography-h2 {
    ${({ $h2LineHeight }) => $h2LineHeight && `line-height: ${$h2LineHeight};`};
  }

  @media (min-width: ${breakpoints.md}px) {
    text-align: left;
  }
`;

export const DashboardActivityDiff = styled(DashboardInfoText)<{
  negative?: number;
}>`
  && {
    color: ${({ negative }) => (negative === 1 ? red2 : aqua1)};
    font-weight: 700;
  }
`;

export const LastUpdatedSC = styled(Typography)`
  text-align: center;

  &.MuiTypography-root {
    color: ${gray5};
  }

  @media (min-width: ${breakpoints.sm}px) {
    text-align: right;
    position: absolute;
    right: 16px;
    bottom: 10px;
  }
`;

export const ChartContainer = styled(Box)<{
  $tileError?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ $tileError }) => ($tileError ? 'left' : 'center')};
  justify-content: ${({ $tileError }) => ($tileError ? 'left' : 'center')};

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
  }
`;

export const ChartTitle = styled(Typography)`
  font-size: 16px !important;
  font-weight: 400 !important;
`;

export const InfoOutlinedIcon = styled(InfoOutlined)`
  font-size: 14px !important;
  margin-left: -5px !important;
`;

export const InfoPanelTooltip = styled(IconButton)`
  top: 15px;
  height: 30px;
  width: 30px;

  &.MuiButtonBase-root {
    position: absolute;
  }
`;

export const PreviousTypography = styled(Typography)`
  display: inline-block;
`;

export const PercentageAverage = styled(PreviousTypography)<{
  negative?: number;
}>`
  && {
    color: ${({ negative }) => (negative === 1 ? red2 : aqua1)};
    font-weight: 700;
  }
`;

export const ChartLegendContainer = styled('ul')`
  font-size: 10px;
  white-space: nowrap;
  display: flex;
  padding: 0;
  justify-content: center;
`;

export const ChartLegendItem = styled('li')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 2px;
`;

export const ChartLegendRoundBox = styled('div')`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
`;

export const ZeroDataMsgSC = styled(Typography)`
  background-color: ${blue9};
  color: ${gray17} !important;
  padding: 16px;
  border-radius: 4px;
  text-align: center;
  z-index: 1;
  position: absolute;

  @media (min-width: ${breakpoints.sm}px) {
    position: relative;
  }
`;

export const ModalHeader = styled(Typography)`
  text-transform: uppercase;
  font-size: 14px !important;
`;

export const ModalSubtitle = styled(Typography)`
  margin-top: 8px !important;
`;

export const ModalContentBox = styled(Box)`
  max-height: 180px;
  overflow: auto;
  text-align: left;
  margin-top: 16px;
`;

export const ListItemBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 3px 3px 3px 0;
  font-weight: ${regularFontWeight};
`;

export const StyledPaper = styled(Paper)`
  padding: 16px;
  position: relative;
`;

//This is a CSS hack to turn off the half-hour time labels in Google Timeline because it has limited configuration options
export const TimelineContainer = styled(Box)`
  & .google-timeline {
    font-weight: 400;
    text[text-anchor='middle']:not([font-weight]) {
      display: none;
    }
  }
`;
