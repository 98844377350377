import { Role } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';

export const appCharts: IRoute = {
  name: 'app.charts',
  stateDefinition: {
    url: '/charts',
    template: '<demo-charts></demo-charts>',
    data: { pageTitle: 'Charts Demo' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [],
    read: [Role.SuperAdmin, Role.SupportBasic, Role.SupportIntermediate]
  }
};

export default appCharts;
