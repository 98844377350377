import { BehaviorSubject } from 'rxjs';
import ProductivityStatus from '../constants/productivityStatus';
import { templateServiceFunctions } from '../serviceFunctions/templateServiceFunctions';
import ReportChart from './ReportChart';
import ReportDetail from './ReportDetail';
import ReportGrid from './ReportGrid';

const _getLocalStorageValue = (key, reportType, localStorageService, username, defaultValue) => {
    return localStorageService.get(`Top${reportType}${key}-${username}`) || defaultValue;
};

const _createUrlButtonOptions = (templateType, item, report, offsetVertical) => {
    let url;
    let urlOptions = {
        hideLabel: true,
        useChildHorizontal: true,
        useChildVertical: true,
        offsetHorizontal: report.offsetHorizontal,
        offsetVertical,
        messageAlign: 'left',
        internalLink: {
            visible: !report.isTeamViewerRole,
            stateName: 'app.reports.activitylog',
            icon: 'fa fa-info-circle'
        }
    };

    switch (templateType) {
        case 'apps':
        case 'Applications':
            urlOptions.internalLink.stateParams = {
                description: item.description || item.title
            };
            urlOptions.externalLink = {
                visible: false
            };
            urlOptions.copyLink = {
                visible: true,
                icon: 'fa fa-copy',
                copyText: item.title,
                tooltip: 'Copy Name'
            };
            break;
        case 'sites':
        case 'Websites':
            urlOptions.internalLink.stateParams = {
                site: item.title
            };
            urlOptions.externalLink = {
                visible: item.title.indexOf('.') > 0,
                url: item.title,
                icon: 'fa fa-external-link'
            };
            urlOptions.copyLink = {
                visible: true,
                icon: 'fa fa-copy'
            };
            break;
        case 'titles':
            urlOptions.internalLink.stateParams = {
                app: item.title
            };
            urlOptions.externalLink = {
                visible: false
            };
            urlOptions.copyLink = {
                visible: true,
                icon: 'fa fa-copy',
                copyText: item.title,
                tooltip: 'Copy Title'
            };
            break;
        case 'subpages':
            url = report.selectedItem.value.title.replace(/#.*$/, '').replace(/\/$/, '') + item.title;
            urlOptions.internalLink.stateParams = {
                site: report.selectedItem.value.title + item.title
            };
            urlOptions.externalLink = {
                visible: url.indexOf('.') > 0,
                url: url,
                icon: 'fa fa-external-link'
            };
            urlOptions.copyLink = {
                visible: true,
                icon: 'fa fa-copy'
            };
            break;
        default:
            console.error(
                `ActivTrak Error: Cannot create URL button options due to unknown button type. Button Type: ${templateType}`
            );
            return {};
    }

    return urlOptions;
};

class Report {
    constructor(
        reportType,
        {
            localStorageService,
            username,
            timeFormat,
            headerHeight,
            detailsHeaderHeightOffset,
            detailsControlHeightOffset,
            detailsMode,
            offsetHorizontal,
            isTeamViewerRole
        }
    ) {
        this.reportType = reportType;
        this.localStorageService = localStorageService;
        this.tooltipTimeFormat = timeFormat.replace(':ss', '').replace('tt', 'a').replace('hh', 'h').replace(' ', '');
        this.headerHeight = headerHeight;
        this.detailsHeaderHeightOffset = detailsHeaderHeightOffset;
        this.detailsControlHeightOffset = detailsControlHeightOffset;
        this.defaultHeaderHeight = headerHeight;
        this.defaultDetailsHeaderHeightOffset = detailsHeaderHeightOffset;
        this.defaultDetailsControlHeightOffset = detailsControlHeightOffset;
        this.detailsMode = new BehaviorSubject(
            _getLocalStorageValue('DetailsMode', reportType, localStorageService, username, detailsMode)
        );
        this.selectedItem = new BehaviorSubject();
        this.isLoading = new BehaviorSubject(true);
        this.offsetHorizontal = offsetHorizontal;
        this.isTeamViewerRole = isTeamViewerRole;
        this.viewMode = new BehaviorSubject(
            _getLocalStorageValue('ViewMode', reportType, localStorageService, username, 'summaryView')
        );

        this.detailsModeSubscription = this.detailsMode.subscribe((mode) => {
            this.refreshActiveTabData(mode);
        });

        this.selectedItemSubscription = this.selectedItem.subscribe(() => {
            this.refreshActiveTabData();
        });
    }

    setDetailsMode(mode) {
        this.detailsMode.next(mode);
    }

    setSelectedItem(item) {
        if (!item) {
            this.selectedItem.next(null);
            return;
        }

        if (item.id === this.selectedItemId() && item.title === this.selectedItemTitle()) {
            return;
        }

        this.selectedItem.next(item);
    }

    isItemSelected() {
        return Boolean(this.selectedItem.value);
    }

    selectedItemId() {
        return (this.isItemSelected() && this.selectedItem.value.id) || undefined;
    }

    selectedItemTitle() {
        return (this.isItemSelected() && this.selectedItem.value.title) || undefined;
    }

    itemClicked(row) {
        this.setSelectedItem(null);
        if (row.urlOptions) {
            row.urlOptions.e = null;
        }
        let selectedItem = row;
        selectedItem.urlId = 'selected-id-' + selectedItem.id;

        if (this.reportType === 'Websites') {
            selectedItem.domain = selectedItem.title.replace(/http(s)?:/, '');
        }

        if (this.reportType !== 'Categories') {
            selectedItem.urlOptions = _createUrlButtonOptions(this.reportType, selectedItem, this, 1);
        }

        this.lastRowUid = row.uid;
        this.setSelectedItem(selectedItem);
    }

    setDataSource(dataFunction) {
        this.dataSource = new kendo.data.DataSource({
            transport: {
                read: dataFunction
            }
        });
    }

    addDetail(mode, settings) {
        this[mode] = new ReportDetail(mode, settings);
    }

    removeDetail(mode) {
        delete this[mode];
    }

    refreshActiveTabData(mode = this.detailsMode.value) {
        if (this[mode]) {
            this[mode].refresh();
        }
    }

    getProductivityTemplate(canEdit, iconResolverServiceFunctions) {
        return kendo.template(function (data) {
            return (
                '<a ' +
                (canEdit && data.title !== 'Other'
                    ? 'ui-sref="app.settings.productivity({app: dataItem.parsedTitle})"'
                    : '') +
                '>' +
                iconResolverServiceFunctions.getProductivityIcon(data.productivity) +
                '</a>'
            );
        });
    }

    getDurationTemplate(field) {
        return kendo.template(function (item) {
            return templateServiceFunctions.friendlyViewTimeFormat(item[field]);
        });
    }

    getRowTemplate(type) {
        const report = this;
        return kendo.template(function (item) {
            item.urlId = `at-${type}-grid-${item.uid}`;
            const isSitesAndApps = type === 'sitesAndApps';

            let template = item.title;
            let offsetVertical = 1;
            let templateType = isSitesAndApps ? (item.isApp ? 'apps' : 'sites') : type;

            switch (templateType) {
                case 'apps':
                    template = isSitesAndApps
                        ? '<div><span class="at-ellipsis" id="{{dataItem.urlId}}" ng-mouseover="showUrlButtons($event, dataItem)">' +
                          (item.title !== 'Other'
                              ? '<a href="" ng-click="changeState(dataItem.urlOptions.internalLink.stateName, dataItem.urlOptions.internalLink.stateParams)">{{dataItem.title}}</a>'
                              : '{{dataItem.title}}') +
                          '</span></div>'
                        : '<div class="inline at-ellipsis full-width" ng-mouseover="showUrlButtons($event, dataItem)" ng-class="{\'at-pointer\': dataItem.title !== \'Other\'}"><span id="{{dataItem.urlId}}">{{dataItem.title}}</span></div>';
                    break;
                case 'sites':
                    template = isSitesAndApps
                        ? '<div><span class="at-ellipsis" id="{{dataItem.urlId}}" ng-mouseover="showUrlButtons($event, dataItem)">' +
                          (item.title !== 'Other'
                              ? '<a href="" ng-click="changeState(dataItem.urlOptions.internalLink.stateName, dataItem.urlOptions.internalLink.stateParams)">{{dataItem.title}}</a>'
                              : '{{dataItem.title}}') +
                          '</span></div>'
                        : '<div class="inline at-row-item at-ellipsis full-width" ng-mouseover="showUrlButtons($event, dataItem, true)" ng-class="{\'at-pointer\': dataItem.title !== \'Other\'}"><span ng-if="dataItem.title.indexOf(\'.\') < 1" class="spacer"></span><img ng-if="dataItem.title.indexOf(\'.\') > 0" src="https://www.google.com/s2/favicons?domain=' +
                          item.title +
                          '/" class="favicon m-r-5" alt=""><span class="at-ellipsis" id="{{dataItem.urlId}}">{{dataItem.title}}</span></div>';
                    break;
                case 'titles':
                    template =
                        '<div class="inline at-ellipsis full-width" ng-mouseover="showUrlButtons($event, dataItem)" ng-class="{\'at-pointer\': dataItem.title !== \'Other\'}"><span id="{{dataItem.urlId}}">{{dataItem.title}}</span></div>';
                    offsetVertical = 3;
                    break;
                case 'subpages':
                    template =
                        '<div class="at-ellipsis full-width" ng-mouseover="showUrlButtons($event, dataItem)"><span id="{{dataItem.urlId}}">' +
                        (item.title !== 'Other'
                            ? '<a href="" ng-click="state.go(dataItem.urlOptions.internalLink.stateName, dataItem.urlOptions.internalLink.stateParams)">...{{dataItem.title}}</a>'
                            : '{{dataItem.title}}') +
                        '</span></div>';
                    offsetVertical = 3;
                    break;
                default:
                    console.error(
                        `ActivTrak Error: Cannot get main template due to unknown report type. Report Type: ${this.reportType}`
                    );
            }

            item.urlOptions = _createUrlButtonOptions(templateType, item, report, offsetVertical);

            return template;
        });
    }

    createGrid(field, settings) {
        this[field] = new ReportGrid(this, settings);

        return this[field];
    }

    createChart({ chartService, fullscreenChart, height, chartReadyCallback, isMobile }) {
        return new ReportChart({ chartService }).createChart({
            fullscreenChart,
            height,
            chartReadyCallback,
            isMobile,
            data: this.stats.dataSource.data()[0]
        });
    }

    createChartData(result, showLegacy) {
        var productivityFamily = ProductivityStatus.getProductivityFamily(result.productivity);
        var showLabelDetails = !showLegacy && this.viewMode.value === 'detailedView';
        var activeName = ProductivityStatus.getName(productivityFamily.active, showLabelDetails, showLabelDetails);
        var activeColor = ProductivityStatus.getColor(productivityFamily.active);
        var passiveName = ProductivityStatus.getName(productivityFamily.passive, showLabelDetails, showLabelDetails);
        var passiveColor = ProductivityStatus.getColor(productivityFamily.passive);

        if (!showLabelDetails) {
            return [
                {
                    productivity: productivityFamily.active,
                    name: activeName,
                    color: activeColor,
                    totals: result.data
                }
            ];
        } else {
            return [
                {
                    productivity: productivityFamily.passive,
                    name: passiveName,
                    color: passiveColor,
                    totals: result.passiveDuration
                },
                {
                    productivity: productivityFamily.active,
                    name: activeName,
                    color: activeColor,
                    totals: result.activeDuration
                }
            ];
        }
    }

    createPagerOptions(dataSource, messages) {
        return {
            dataSource: dataSource,
            pageSizes: ['5', '10', '20'],
            buttonCount: 3,
            messages
        };
    }

    showUrlButtons(e, item, isSecondElement, fullscreenChart) {
        if (item.title !== 'Other') {
            var options = item.urlOptions;
            if (options) {
                options.e = e;
                options.id = fullscreenChart ? 'fullscreenUrl' : item.urlId;

                if (e.currentTarget && e.currentTarget.children.length > 0) {
                    var divRect = e.currentTarget.getBoundingClientRect();
                    var spanRect = e.currentTarget.children[isSecondElement ? 1 : 0].getBoundingClientRect();

                    if (spanRect.width > divRect.width) {
                        options.fixedLeftOffset = divRect.left + divRect.width;
                    }
                }

                return options;
            }
        }

        return;
    }

    createViewToggleOptions(summaryId, detailedId) {
        const service = this;
        return {
            labels: [
                {
                    heapId: `id_${summaryId}`,
                    text: 'summaryView',
                    value: 'Summary View'
                },
                {
                    heapId: `id_${detailedId}`,
                    text: 'detailedView',
                    value: 'Detailed View'
                }
            ],
            onChange: (viewMode) => service.viewMode.next(viewMode)
        };
    }

    createQuickStat(duration, productivity, showActiveLabel) {
        return {
            duration: templateServiceFunctions.friendlyViewTimeFormat(duration, true),
            name: ProductivityStatus.getName(productivity, showActiveLabel, showActiveLabel),
            style: {
                color: ProductivityStatus.getColor(productivity)
            }
        };
    }

    destroy() {
        this.detailsModeSubscription.unsubscribe();
        this.selectedItemSubscription.unsubscribe();
    }
}

export default Report;
