import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { StatusSubheader } from '.';
import { IOverallStatusProps } from '../../models';
import { ZeroStateIcon } from '../../../common/assets/Icons';

export const OverallStatus = (props: IOverallStatusProps) => {
  const {
    header,
    subheader,
    noResults,
    headerFontSize,
    tooltipLegend,
    responsive,
    reverseResponsive
  } = props;

  const theme = useTheme();
  const isMedScreenOrBelow = useMediaQuery(theme.breakpoints.down('lg'));

  const numberHeader = noResults ? (
    <ZeroStateIcon height="33px" width="33px" />
  ) : (
    header
  );
  const isDirectionColumn = reverseResponsive && !isMedScreenOrBelow;
  const isResponsiveInMediumOrSmallerScreen = responsive && isMedScreenOrBelow;
  const inColumnDirection =
    isResponsiveInMediumOrSmallerScreen || isDirectionColumn;

  return (
    <Box
      textAlign={inColumnDirection ? 'center' : 'left'}
      display="inline-block"
    >
      <Stack
        direction={inColumnDirection ? 'column' : 'row'}
        alignItems="center"
        display={{
          md: 'block',
          lg: 'inline-flex'
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: `${headerFontSize || '48px'} !important` }}
        >
          {numberHeader}
        </Typography>
        <StatusSubheader
          subheader={subheader}
          tooltipLegend={tooltipLegend}
          responsive={responsive}
          reverseResponsive={reverseResponsive}
          noResults={noResults}
          isMedScreenOrBelow={isMedScreenOrBelow}
        />
      </Stack>
    </Box>
  );
};
