import React from 'react';
import _ from 'lodash';
import {
  IAccountWizardService,
  IWizardFormDTO,
  IWizardFormPageSelection
} from '../models';
import { IActivTrakRequestConfig, IApiService } from '../../common/models';
import { getDefaultConfig, BaseClient } from '../../common/config';
import { apiService } from '../../common/helpers';
import {
  GetRolesService,
  IGetRolesService
} from '../../common/services/GetRoles';
import {
  getPrivacySettings,
  setPrivacySettings
} from '../../common/hooks/privacySettingsStore';

export default class AccountWizardService
  extends BaseClient
  implements IAccountWizardService
{
  protected defaultConfig: () => IActivTrakRequestConfig;
  private getRolesService: IGetRolesService;

  constructor(
    apiService: IApiService,
    getRolesService: IGetRolesService,
    defaultConfig?: () => IActivTrakRequestConfig
  ) {
    super();
    this.apiService = apiService;
    this.defaultConfig = defaultConfig ?? getDefaultConfig;
    this.getRolesService = getRolesService;
  }
  public submitWizard = async (
    form: {
      PageSelection: string[];
    },
    firstVisit: boolean
  ): Promise<void> => {
    // check if modal values then clean up to match what the API expects
    const parseFormValues = () => {
      return {
        PageSelection: form.PageSelection.map((e) =>
          e.includes('1B') ? `1:${e.split(':')[2]}:${e.split(':')[3]}` : e
        )
      };
    };
    const accountWizardResult = this.validateAndConfigureDTO(parseFormValues());
    accountWizardResult.FirstVisit = firstVisit;
    await this.apiService
      .post('api/settings/accountwizard', {
        ...this.defaultConfig(),
        data: accountWizardResult
      })
      .catch((reason) => {
        alert('Failed to save!');
        console.error(reason);
      });

    const roles = await this.getRolesService.getAll();
    //Admin is required for the wizard
    const roleAccess = roles
      ?.filter((d) => d.roles.some((r) => r == 'Admin'))
      .map((d) => d.resource) as string[];

    if (roleAccess !== undefined)
      window.dispatchEvent(
        new CustomEvent('roleAccessUpdated', {
          detail: { roleAccess: roleAccess }
        })
      );
  };

  public validateAndConfigureDTO = (form: {
    PageSelection: string[];
  }): IWizardFormDTO => {
    if (
      form.PageSelection.filter((r) => !r.match(new RegExp('[0-2]:[0-9]:')))
        .length > 0
    )
      throw new Error(
        'Account Setup Wizard result contains unrecognized selections.'
      );

    const page0 = form.PageSelection.filter((r) =>
      r.match(new RegExp('0:[0-9]:'))
    );
    const page1 = form.PageSelection.filter((r) =>
      r.match(new RegExp('1:[0-9]:'))
    );
    const page2 = form.PageSelection.filter((r) =>
      r.match(new RegExp('2:[0-9]:'))
    );

    if (page0.length == 0 || page1.length == 0)
      throw new Error(
        'Account Setup Wizard result is formatted incorrectly or contains incorrect selections.'
      );

    const result = {
      PageSelection: {
        0: _.map(page0, (p) => p.substring(p.lastIndexOf(':') + 1)),
        1: _.map(page1, (p) => p.substring(p.lastIndexOf(':') + 1)),
        2:
          page2.length > 0
            ? _.map(page2, (p) => p.substring(p.lastIndexOf(':') + 1))
            : ['']
      } as IWizardFormPageSelection
    } as IWizardFormDTO;

    return result;
  };
  public async cancelWizard(useCasePageViewed: boolean, firstVisit: boolean) {
    const storedPrivacySettings = getPrivacySettings();
    setPrivacySettings(storedPrivacySettings);

    await this.apiService
      .post('api/settings/accountwizard/cancel', {
        ...this.defaultConfig(),
        data: { UseCasePageViewed: useCasePageViewed, FirstVisit: firstVisit }
      })
      .catch((reason) => {
        console.error(reason);
      });
  }
}

type Props = {
  accountWizardService?: IAccountWizardService;
};

export const useAccountWizard = (props?: Props) => {
  return React.useMemo(
    () =>
      props?.accountWizardService ||
      new AccountWizardService(apiService, new GetRolesService()),
    [props]
  );
};
