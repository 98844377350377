import React, { useRef, useState } from 'react';
import { EditDescriptionTextFieldProps } from '../models';
import { useNotifications } from '../../common/services/Notifications';
import { DescriptionTextField } from '../../common/components/TextField/EditTextField.style';
import { ApikeyService } from '../services';
import { successMessage, genericErrorMessage } from '../constants';

export default function EditDescriptionTextField(
  props: EditDescriptionTextFieldProps
) {
  const apiKeyDescriptionChanged = { ...props };

  const inputRef = useRef<any>(null);
  const [newDescription, setNewDescription] = useState<string>(
    apiKeyDescriptionChanged?.description
  );

  const { updatePublicApiKey } = ApikeyService();
  const notificationService = useNotifications();

  const handleEditDescriptionClick = () => {
    if (newDescription) {
      setNewDescription(undefined);
    }
  };

  const handleDescriptionChange = (event) => {
    setNewDescription(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const prospectiveDescription = event.target.value;
      auditDescription(event, prospectiveDescription);
    }
  };

  const auditDescription = (event, newAuditDescription) => {
    // This line prevents an API call if there is no change to the input after cancelling changes to that input
    if (event.target.value === apiKeyDescriptionChanged.description) {
      return;
    }

    if (newAuditDescription.length > 45) {
      inputRef.current.value = apiKeyDescriptionChanged.description;
      return;
    } else if (newAuditDescription.length === 0) {
      inputRef.current.value = apiKeyDescriptionChanged.description;
      notificationService.error('');
      return;
    }

    updateDescription();
  };
  const updateDescription = async () => {
    const oldDescription = apiKeyDescriptionChanged.description;
    apiKeyDescriptionChanged.description = newDescription;
    try {
      await updatePublicApiKey(
        apiKeyDescriptionChanged.id,
        apiKeyDescriptionChanged.description
      );
      notificationService.success(successMessage);
    } catch (e) {
      apiKeyDescriptionChanged.description = oldDescription;
      inputRef.current.value = apiKeyDescriptionChanged.description;
      notificationService.error(genericErrorMessage);
    }
  };

  return (
    <DescriptionTextField
      inputRef={inputRef}
      variant="outlined"
      defaultValue={apiKeyDescriptionChanged.description}
      onChange={handleDescriptionChange}
      InputProps={{
        onClick: handleEditDescriptionClick,
        onBlur: (event) => auditDescription(event, newDescription),
        onKeyPressCapture: handleKeyPress
      }}
    />
  );
}
