import { ActivityType } from '../../enums';
import {
  IClassificationGroup,
  IGroupClassificationService
} from '../../models';
import { getDefaultConfig, BaseClient } from '../../config';

export default class GroupClassificationService
  extends BaseClient
  implements IGroupClassificationService
{
  public getActivityGroupClassifications = async (
    activityId: number,
    activityType: ActivityType
  ): Promise<IClassificationGroup[]> => {
    const url =
      activityType === ActivityType.Website
        ? `/api/classification/websitegroup/${activityId}`
        : `/api/classification/applicationgroup/${activityId}`;

    const response = await this.apiService.get<IClassificationGroup[]>(url, {
      ...getDefaultConfig()
    });

    //This line is needed because response is considered an Array-Like Object
    //Sort is only available on Array.
    //So while it does work both ways on the UI,
    //it causes an error to be thrown in the console and tests to fail
    //See more: https://stackoverflow.com/questions/30931365/sort-not-a-function-error-in-angularjs-only-in-google-chrome
    return Array.from(response.data);
  };

  public updateActivityGroupClassifications = async (
    payload: IClassificationGroup[],
    activityType: ActivityType
  ): Promise<any> => {
    const url =
      activityType === ActivityType.Website
        ? '/api/classification/websitegroup/activities'
        : '/api/classification/applicationgroup/activities';

    const response = await this.apiService.post<IClassificationGroup>(url, {
      ...getDefaultConfig(),
      ...{ data: payload }
    });
    //Only a 200 OK is returned
    return response;
  };

  public deleteActivityGroupClassifications = async (
    payload: IClassificationGroup[],
    activityType: ActivityType
  ): Promise<any> => {
    const url =
      activityType === ActivityType.Website
        ? '/api/classification/websitegroup/delete/activities'
        : '/api/classification/applicationgroup/delete/activities';

    const response = await this.apiService.post<IClassificationGroup>(url, {
      ...getDefaultConfig(),
      ...{ data: payload }
    });
    //Only a 200 OK is returned
    return response;
  };
}
