export const childArrayMap = (childStats) => {
  if (childStats) {
    return childStats.map((childStat) => {
      return {
        accountId: childStat.accountId,
        companyName: childStat.companyName,
        userCount: childStat.userCount,
        alarmCount: childStat.alarmCount,
        agentNotReportedCount: childStat.agentNotReportedCount,
        auditAlarmCount: childStat.auditAlarmCount,
        ownerEmail: childStat.ownerEmail,
        expirationDate: childStat.expirationDate,
        isExpired: childStat.isExpired,
        isPaid: childStat.isPaid,
        isActiveTrial: childStat.isActiveTrial,
        isExpiredTrial: childStat.isExpiredTrial
      };
    });
  }
  return [];
};
