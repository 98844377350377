import React, { useEffect, useState, ChangeEvent } from 'react';
import { LandingPageSettingsService } from '../services';
import { useLandingPageSettingsState } from '../hooks';
import { useNotifications } from '../../common/services/Notifications';
import { DefaultWidgetProps } from '../models';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box
} from '@mui/material';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import { landingPageOptions } from '../constants';

export const LandingPageSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const [newValue, setNewValue] = useState('app.dashboard');

  const service = LandingPageSettingsService();
  const notificationService = useNotifications();
  const landingPagesState = useLandingPageSettingsState({
    service,
    notificationService
  });
  const { setting, isLoading, init, updateSetting } = landingPagesState;

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setNewValue(setting);
  }, [setting]);

  const handleUpdate = () => {
    if (setting !== newValue) {
      updateSetting(newValue);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  return (
    <ConfigurationCardWrapper
      title="Landing Page Settings"
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={isSupport || isLoading || newValue === setting}
          onClick={handleUpdate}
        >
          Save
        </Button>
      }
    >
      Choose a default landing page for users to see when they log in. This
      change will apply to Admin, Configurator and Power User roles. Please
      note: Team Viewer will always have Team Pulse as the default landing page.
      <Box>
        <FormControl>
          <RadioGroup value={newValue} onChange={handleChange}>
            {landingPageOptions?.map((item, i) => (
              <FormControlLabel
                key={`landing-page-${i}`}
                value={item.value}
                control={
                  <Radio
                    disabled={isSupport || isLoading}
                    size="small"
                    color="primary"
                  />
                }
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </ConfigurationCardWrapper>
  );
};
