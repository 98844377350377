import { rowSelection, IRoleAccessViewModel } from '../../common/models';
import { Role, rowType } from '../../common/enums';
import { PlanType } from '../../common/enums/PlanType';

export const getRoleUpperCaseKey = (roleValue): string =>
  (Object.keys(Role) as Role[]).filter((x) => Role[x] === roleValue).toString();

export function createRow(
  resource: string,
  adminCheck: rowSelection,
  configCheck: rowSelection,
  powerCheck: rowSelection,
  viewerCheck: rowSelection,
  itemType: rowType,
  secondaryText: string,
  premiumFeature?: PlanType[]
) {
  if (premiumFeature?.length === 0) {
    throw new Error('premiumFeature must be array with one or more Plan[]');
  }

  const result: IRoleAccessViewModel = {
    resource,
    adminCheck,
    configCheck,
    powerCheck,
    viewerCheck,
    itemType,
    secondaryText,
    premiumFeature
  };
  return result;
}

export const formatPremiumHoverText = (premiumFeature: PlanType[]): string => {
  if (premiumFeature?.length === 1) {
    return ` (Available w/ ${premiumFeature[0]})`;
  }

  const lastFeatureIndex = premiumFeature?.length - 1;
  const lastFeature = premiumFeature[lastFeatureIndex];

  const formattedFeatures =
    premiumFeature?.slice(0, lastFeatureIndex).join(', ') + ` & ${lastFeature}`;

  return ` (Available w/ ${formattedFeatures})`;
};
