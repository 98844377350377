export const activationViewClasses = {
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center'
  },
  cardContainer: {
    marginTop: '8em !important',
    marginBottom: '2em !important',
    maxHeight: '60%'
  },

  header: {
    marginTop: '1em !important',
    marginBottom: '15px !important',
    letterSpacing: '0.15px !important',
    lineHeight: '52px !important',
    color: '#2c2c2c !important',
    fontWeight: 500,
    fontSize: '48px'
  },
  subHeading: {
    fontSize: '20px !important',
    letterSpacing: '0.15px',
    color: '#626262 !important'
  }
};
