import { getAccountSettings } from '../../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { generateParameters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';

// Register report component
import './riskLevel.component.js';

angular.module('app').controller('RiskLevelCtrl', RiskLevelCtrl);

RiskLevelCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    '$state',
    '$window',
    'messagesService',
    'riskLevelService',
    'localStorageService',
    'url',
    'notificationService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'authorizationService',
    'browserServiceFunctions'
];

function RiskLevelCtrl(
    $rootScope,
    $scope,
    $timeout,
    $state,
    $window,
    msg,
    riskLevelService,
    localStorageService,
    url,
    notificationService,
    atHelperFunctions,
    templateServiceFunctions,
    authorizationService,
    browserServiceFunctions
) {
    const accountSettings = getAccountSettings();
    $scope.isLoading = true;
    $scope.title = msg.get('user');
    $scope.pageMode = localStorageService.get('RiskReportPageMode-' + accountSettings.username) || 'users';
    $scope.usedLicenses = accountSettings.usedLicenses;
    $scope.download = $rootScope.download;

    $scope.state = $state;

    let previousMode;
    $scope.riskDataCache = [];
    const riskScorePrecision = 4;
    let smallScreen = $window.innerWidth < 1200;
    $scope.reportFilters = {};

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.reports.riskLevel');
    };

    const headerHeight = 200;
    const detailsHeaderHeightOffset = 38;
    const detailsControlHeightOffset = 43;

    function getTooltipWidth() {
        return atHelperFunctions.isSmallWindow() ? 260 : 420;
    }

    $scope.scoreAndPointsExplanationTemplate = function () {
        return msg.get('riskScoreAndPointsExplanation', getTooltipWidth());
    };

    $scope.scoreExplanationTemplate = function () {
        return msg.get('riskScoreExplanation', getTooltipWidth());
    };

    $scope.pointsExplanationTemplate = function () {
        return msg.get('riskPointsExplanation', getTooltipWidth());
    };

    $scope.mainGridHeight = function () {
        return headerHeight;
    };

    $scope.detailsGridHeight = function () {
        return headerHeight + detailsHeaderHeightOffset + ($scope.stackControls ? detailsControlHeightOffset : 0);
    };

    $scope.htmlEscape = function (string) {
        return browserServiceFunctions.htmlEscape(string);
    };

    const userRiskDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                if (!$scope.riskDataCache.user) {
                    $scope.isLoading = true;
                    riskLevelService
                        .getRiskLevelByUser(generateParameters($scope.reportFilters, {}), {
                            params: options.data
                        })
                        .then(
                            function (result) {
                                $scope.riskDataCache.user = result.data.data;
                                ret.data = $scope.riskDataCache.user;
                                ret.total = $scope.riskDataCache.user.length;
                                $scope.noResults = ret.total === 0 && !$scope.filterApplied;
                                options.success(ret);
                            },
                            function () {
                                notificationService.showNotification(msg.get('reportLoadingError'), 'danger');
                                options.error({});
                            }
                        );
                } else {
                    ret.data = $scope.riskDataCache.user;
                    ret.total = $scope.riskDataCache.user.length;
                    options.success(ret);
                }
            }
        },
        pageSize: 50,
        serverPaging: true,
        serverFiltering: true,
        sort: [
            {
                field: 'riskPoints',
                dir: 'desc'
            }
        ],
        schema: {
            data: 'data',
            total: 'total'
        }
    });
    $scope.userRiskDataSource = userRiskDataSource;

    const alarmRiskDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                if (!$scope.riskDataCache.alarm) {
                    $scope.isLoading = true;
                    riskLevelService
                        .getRiskLevelByAlarm(generateParameters($scope.reportFilters, {}), {
                            params: options.data
                        })
                        .then(
                            function (result) {
                                $scope.riskDataCache.alarm = result.data.data;
                                ret.data = $scope.riskDataCache.alarm;
                                ret.total = $scope.riskDataCache.alarm.length;
                                $scope.noResults = ret.total === 0 && !$scope.filterApplied;
                                options.success(ret);
                            },
                            function () {
                                notificationService.showNotification(msg.get('reportLoadingError'), 'danger');
                                options.error({});
                            }
                        );
                } else {
                    ret.data = $scope.riskDataCache.alarm;
                    ret.total = $scope.riskDataCache.alarm.length;
                    options.success(ret);
                }
            }
        },
        pageSize: 50,
        serverPaging: true,
        serverFiltering: true,
        sort: [
            {
                field: 'riskPoints',
                dir: 'desc'
            }
        ],
        schema: {
            data: 'data',
            total: 'total'
        }
    });
    $scope.alarmRiskDataSource = alarmRiskDataSource;

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap'
    });

    $scope.goToAlarmBoth = function (item) {
        const param = [];
        param[$scope.filterMode === 'users' ? 'user' : 'computer'] = item.name || $scope.selectedUser.name;
        param.alarm = item.alarm || $scope.selectedAlarm.alarm;
        $state.go('app.reports.alarmlog', param);
    };

    $scope.goToAlarmName = function (item) {
        const param = [];
        param[$scope.filterMode === 'users' ? 'user' : 'computer'] = item.name;
        $state.go('app.reports.alarmlog', param);
    };

    $scope.goToAlarm = function (item) {
        $state.go('app.reports.alarmlog', {
            alarm: item.alarm
        });
    };

    $scope.userRiskGridBound = function (e) {
        const grid = e.sender;
        const data = grid._data;
        let firstRow;
        let selectedRow;
        data.forEach(function (row, key) {
            if (key === 0) {
                firstRow = row;
            } else if (!!$scope.selectedUser && $scope.selectedUser.name === row.name) {
                selectedRow = row;
            }
        });

        if (!(!!selectedRow && !!selectedRow.uid)) {
            selectedRow = firstRow;
            $scope.selectedUser = selectedRow;
        }

        if (!!selectedRow && !!selectedRow.uid && !!grid.select) {
            grid.select('tr[data-uid="' + selectedRow.uid + '"]');
        }

        $scope.isLoading = false;
        $timeout(function () {
            alarmDetailsDataSource.read();
            $scope.alarmDetailsGrid.refresh();
        });
    };

    $scope.userRiskGridOptions = {
        autoBind: false,
        dataSource: userRiskDataSource,
        rowTemplate: null,
        columns: [
            {
                field: 'name',
                headerTemplate: function () {
                    return '{{gridTitle}}';
                },
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter {{usersLabel}}…')
                                .attr('ng-change', "updateFilter(this, 'userMain')")
                                .attr('ng-model', 'userMainFilter');
                        }
                    }
                },
                attributes: {
                    class: 'text-nowrap'
                },
                template: '<a ng-click="goToAlarmName(dataItem)">{{dataItem.name}}</a>'
            },
            {
                field: 'alarmCount',
                title: msg.get('alarmCount'),
                filterable: false,
                width: '100px',
                attributes: {
                    class: 'text-center'
                }
            },
            {
                field: 'riskPoints',
                title: msg.get('riskPoints'),
                filterable: false,
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                headerTemplate:
                    '<span at-tooltip tooltip-content="pointsExplanationTemplate" tooltip-placement="top">' +
                    msg.get('riskPoints') +
                    '</span>'
            },
            {
                field: 'riskScore',
                title: msg.get('riskScore'),
                filterable: false,
                template: function (item) {
                    return atHelperFunctions.fixPrecision(item.riskScore, riskScorePrecision);
                },
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                headerTemplate:
                    '<span at-tooltip tooltip-content="scoreExplanationTemplate" tooltip-placement="top">' +
                    msg.get('riskScore') +
                    '</span>'
            }
        ],
        filterable: {
            mode: 'row'
        },
        selectable: 'row',
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.mainGridHeight()),
        dataBound: $scope.userRiskGridBound
    };

    $scope.alarmRiskGridBound = function (e) {
        const grid = e.sender;
        const data = grid._data;
        let firstRow;
        let selectedRow;
        data.forEach(function (row, key) {
            if (key === 0) {
                firstRow = row;
            } else if (!!$scope.selectedAlarm && $scope.selectedAlarm.alarm === row.alarm) {
                selectedRow = row;
            }
        });

        if (!(!!selectedRow && !!selectedRow.uid)) {
            selectedRow = firstRow;
            $scope.selectedAlarm = selectedRow;
        }

        if (!!selectedRow && !!selectedRow.uid && grid.select) {
            grid.select('tr[data-uid="' + selectedRow.uid + '"]');
        }

        $scope.isLoading = false;
        $timeout(function () {
            userDetailsDataSource.read();
            $scope.userDetailsGrid.refresh();
        });
    };

    const alarmRiskGridOptions = function () {
        return {
            autoBind: false,
            dataSource: alarmRiskDataSource,
            columns: [
                {
                    field: 'type',
                    title: "<div align='center'>" + msg.get('type') + '</div>',
                    sortable: {
                        initialDirection: 'desc',
                        compare: function (a, b) {
                            const aValue = a.isUsb ? 1 : 0;
                            const bValue = b.isUsb ? 1 : 0;
                            return aValue - bValue;
                        }
                    },
                    filterable: false,
                    attributes: {
                        class: 'text-center'
                    },
                    width: '45px',
                    template:
                        '#if (isUsb === true ) {#' +
                        '<i class="fa fa-usb" at-tooltip="' +
                        msg.get('usbAlarm').replace(' ', '&nbsp;') +
                        '" tooltip-placement="top"></i>' +
                        '#} else {#' +
                        '<i class="fa fa-bell" at-tooltip="' +
                        msg.get('alarm').replace(' ', '&nbsp;') +
                        '" tooltip-placement="top"></i>' +
                        '#}#'
                },
                {
                    field: 'alarm',
                    headerTemplate: msg.get('alarm'),
                    filterable: {
                        cell: {
                            operator: 'contains',
                            showOperators: false,
                            template: function (args) {
                                $(args.element)
                                    .addClass('k-textbox')
                                    .attr('style', 'width:100%')
                                    .attr('placeholder', 'Filter Alarms…')
                                    .attr('ng-change', "updateFilter(this, 'alarmMain')")
                                    .attr('ng-model', 'alarmMainFilter');
                            }
                        }
                    },
                    attributes: {
                        class: 'text-nowrap'
                    },
                    template:
                        '#if (alarm !== "No Name") {#' +
                        '<a ng-click="goToAlarm(dataItem)">{{htmlEscape(dataItem.alarm)}}</a>' +
                        '#} else {#' +
                        '{{htmlEscape(dataItem.alarm)}}' +
                        '#}#'
                },
                {
                    field: 'riskLevel',
                    title: msg.get('riskLevel'),
                    filterable: false,
                    width: '90px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    field: 'userCount',
                    title: msg.get('userCount'),
                    filterable: false,
                    width: '90px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    field: 'riskPoints',
                    title: msg.get('riskPointsTotal'),
                    filterable: false,
                    width: '130px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    filterable: false,
                    width: '120px',
                    template:
                        '<div>Risk Level: {{dataItem.riskLevel}}</div><div>Count: {{dataItem.userCount}}</div><div>Risk Points: {{dataItem.riskPoints}}</div>',
                    attributes: {
                        class: 'text-center'
                    },
                    hideFull: true,
                    hidden: !smallScreen
                },
                {
                    template:
                        "#if (name !== 'Other') {#" +
                        '<a ng-if="hasViewLevel(\'edit\') && !dataItem.isDeleted" ng-click="state.go(\'app.alarms.details\', {id: dataItem.alarmId, riskReportParams: {alarm: selectedAlarm.alarm, filter: alarmRiskDataSource.filter(), secondaryFilter: userDetailsDataSource.filter()}})" at-tooltip="Edit Alarm" tooltip-placement="left"><i class="fa fa-pencil-square-o"></i></a>' +
                        '<a class="m-r-2" ng-if="hasViewLevel(\'edit\') && dataItem.isDeleted" at-tooltip="Alarm Deleted" tooltip-placement="left"><i class="fa fa-ban"></i></a>' +
                        '#}#',
                    width: '35px',
                    attributes: {
                        class: 'text-center'
                    }
                }
            ],
            filterable: {
                mode: 'row'
            },
            selectable: 'row',
            sortable: true,
            scrollable: {
                virtual: true
            },
            height: atHelperFunctions.getGridHeight($scope.mainGridHeight()),
            dataBound: $scope.alarmRiskGridBound
        };
    };
    $scope.alarmRiskGridOptions = alarmRiskGridOptions();

    const alarmDetailsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (!!$scope.selectedUser && !!$scope.selectedUser.details) {
                    options.success($scope.selectedUser.details);
                } else {
                    options.success([]);
                }
            }
        },
        sort: [
            {
                field: 'riskPoints',
                dir: 'desc'
            }
        ]
    });
    $scope.alarmDetailsDataSource = alarmDetailsDataSource;

    const updateColumns = function (grid, smallScreen) {
        grid.columns.forEach(function (column) {
            if ((smallScreen && column.hideSmall) || (!smallScreen && column.hideFull)) {
                grid.hideColumn(column);
            } else {
                grid.showColumn(column);
            }
        });
    };

    $scope.$on('atWindowResizing', function (e, windowSize) {
        smallScreen = windowSize.newWidth < 1200;

        if ($scope.alarmDetailsGrid) {
            updateColumns($scope.alarmDetailsGrid, smallScreen);
        }

        if ($scope.alarmRiskGrid) {
            updateColumns($scope.alarmRiskGrid, smallScreen);
        }
    });

    const alarmDetailsGridOptions = function () {
        return {
            autoBind: false,
            dataSource: alarmDetailsDataSource,
            columns: [
                {
                    field: 'type',
                    title: "<div align='center'>" + msg.get('type') + '</div>',
                    sortable: {
                        initialDirection: 'desc',
                        compare: function (a, b) {
                            const aValue = a.isUsb ? 1 : 0;
                            const bValue = b.isUsb ? 1 : 0;
                            return aValue - bValue;
                        }
                    },
                    filterable: false,
                    attributes: {
                        class: 'text-center'
                    },
                    width: '45px',
                    template:
                        '#if (isUsb === true ) {#' +
                        '<i class="fa fa-usb" at-tooltip="' +
                        msg.get('usbAlarm').replace(' ', '&nbsp;') +
                        '" tooltip-placement="top"></i>' +
                        '#} else {#' +
                        '<i class="fa fa-bell" at-tooltip="' +
                        msg.get('alarm').replace(' ', '&nbsp;') +
                        '" tooltip-placement="top"></i>' +
                        '#}#'
                },
                {
                    field: 'alarm',
                    title: msg.get('alarm'),
                    attributes: {
                        class: 'text-nowrap'
                    },
                    filterable: {
                        cell: {
                            operator: 'contains',
                            showOperators: false,
                            template: function (args) {
                                $(args.element)
                                    .addClass('k-textbox')
                                    .attr('style', 'width:100%')
                                    .attr('placeholder', 'Filter Alarms…')
                                    .attr('ng-change', "updateFilter(this, 'alarm')")
                                    .attr('ng-model', 'alarmFilter');
                            }
                        }
                    },
                    template:
                        '#if (alarm !== "No Name") {#' +
                        '<a ng-click="goToAlarm(dataItem)">{{htmlEscape(dataItem.alarm)}}</a>' +
                        '#} else {#' +
                        '{{htmlEscape(dataItem.alarm)}}' +
                        '#}#'
                },
                {
                    field: 'riskLevel',
                    title: msg.get('riskLevel'),
                    filterable: false,
                    width: '90px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    field: 'triggerCount',
                    title: msg.get('count'),
                    filterable: false,
                    width: '90px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    field: 'riskPoints',
                    title: msg.get('riskPoints'),
                    filterable: false,
                    width: '90px',
                    attributes: {
                        class: 'text-center'
                    },
                    hideSmall: true,
                    hidden: smallScreen
                },
                {
                    filterable: false,
                    width: '120px',
                    template:
                        '<div>Risk Level: {{dataItem.riskLevel}}</div><div>Count: {{dataItem.userCount}}</div><div>Risk Points: {{dataItem.riskPoints}}</div>',
                    attributes: {
                        class: 'text-center'
                    },
                    hideFull: true,
                    hidden: !smallScreen
                },
                {
                    template:
                        "#if (name !== 'Other') {#" +
                        '<a class="at-pointer" ng-click="goToAlarmBoth(dataItem)"  at-tooltip="Alarm History" tooltip-placement="left" ng-class="{\'m-r-10\': hasViewLevel(\'edit\')}"><i class="fa fa-bell-o"></i></a>' +
                        '<a ng-if="hasViewLevel(\'edit\') && !dataItem.isDeleted" ng-click="state.go(\'app.alarms.details\', {id: dataItem.alarmId, riskReportParams: {user: selectedUser.name, filter: userRiskDataSource.filter(), secondaryFilter: alarmDetailsDataSource.filter()}})" at-tooltip="Edit Alarm" tooltip-placement="left"><i class="fa fa-pencil-square-o"></i></a>' +
                        '<a class="m-r-2" ng-if="hasViewLevel(\'edit\') && dataItem.isDeleted" at-tooltip="Alarm Deleted" tooltip-placement="left"><i class="fa fa-ban"></i></a>' +
                        '#}#',
                    width: '65px',
                    attributes: {
                        class: 'text-center text-nowrap'
                    }
                }
            ],
            filterable: {
                mode: 'row'
            },
            sortable: true,
            scrollable: {
                virtual: true
            },
            height: atHelperFunctions.getGridHeight($scope.detailsGridHeight())
        };
    };
    $scope.alarmDetailsGridOptions = alarmDetailsGridOptions();

    const userDetailsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (!!$scope.selectedAlarm && !!$scope.selectedAlarm.details) {
                    options.success($scope.selectedAlarm.details);
                } else {
                    options.success([]);
                }
            }
        },
        sort: [
            {
                field: 'riskPoints',
                dir: 'desc'
            }
        ]
    });
    $scope.userDetailsDataSource = userDetailsDataSource;

    $scope.userDetailsGridOptions = {
        autoBind: false,
        dataSource: userDetailsDataSource,
        columns: [
            {
                field: 'name',
                headerTemplate: function () {
                    return '{{gridTitle}}';
                },
                attributes: {
                    class: 'text-nowrap'
                },
                template: '<a ng-click="goToAlarmName(dataItem)">{{dataItem.name}}</a>',
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter {{usersLabel}}…')
                                .attr('ng-change', "updateFilter(this, 'user')")
                                .attr('ng-model', 'userFilter');
                        }
                    }
                }
            },
            {
                field: 'triggerCount',
                title: msg.get('count'),
                filterable: false,
                width: '90px',
                attributes: {
                    class: 'text-center'
                }
            },
            {
                field: 'riskPoints',
                title: msg.get('riskPoints'),
                filterable: false,
                width: '90px',
                attributes: {
                    class: 'text-center'
                }
            },
            {
                field: 'riskScore',
                title: msg.get('riskScore'),
                filterable: false,
                template: function (item) {
                    return atHelperFunctions.fixPrecision(item.riskScore, riskScorePrecision);
                },
                width: '90px',
                attributes: {
                    class: 'text-center'
                }
            },
            {
                template:
                    "#if (name !== 'Other') {#" +
                    '<a class="at-pointer" ng-click="goToAlarmBoth(dataItem)" at-tooltip="Alarm History" tooltip-placement="left"><i class="fa fa-bell-o"></i></a>' +
                    '#}#',
                width: '35px',
                attributes: {
                    class: 'text-center'
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.detailsGridHeight())
    };

    let filterDelay;
    $scope.updateFilter = function (e, type) {
        $timeout.cancel(filterDelay);
        $scope.filterApplied = true;
        filterDelay = $timeout(function () {
            $scope.riskDataCache = [];
            if (type === 'userMain') {
                userRiskDataSource.filter({
                    field: 'name',
                    operator: 'contains',
                    value: e.userMainFilter
                });
            } else if (type === 'alarmMain' || type === 'riskLevel') {
                const filter = [];
                if (e.alarmMainFilter) {
                    filter.push({
                        field: 'alarm',
                        operator: 'contains',
                        value: e.alarmMainFilter
                    });
                }
                alarmRiskDataSource.filter(filter);
            } else if (type === 'user') {
                userDetailsDataSource.filter({
                    field: 'name',
                    operator: 'contains',
                    value: e.userFilter
                });
            } else if (type === 'alarm') {
                alarmDetailsDataSource.filter({
                    field: 'alarm',
                    operator: 'contains',
                    value: e.alarmFilter
                });
            }
        }, 800);
    };

    $scope.changePageMode = function (mode) {
        if ($scope.isLoading) {
            return;
        }

        $scope.pageMode = mode;
        if (mode !== previousMode) {
            previousMode = $scope.pageMode;
            if (accountSettings.username) {
                localStorageService.set('RiskReportPageMode-' + accountSettings.username, $scope.pageMode);
            }
            if ($scope.pageMode === 'users') {
                userRiskDataSource.read();
            } else {
                alarmRiskDataSource.read();
            }
        }
    };

    $scope.updateDetailsData = function () {
        if (!!$scope.selectedUser && $scope.pageMode === 'users') {
            if ($state.params.secondaryFilter && $state.params.secondaryFilter !== null) {
                alarmDetailsDataSource.filter($state.params.secondaryFilter);
                $state.params.filter = null;
                $state.params.secondaryFilter = null;
            } else {
                alarmDetailsDataSource.read();
            }
        }
        if (!!$scope.selectedAlarm && $scope.pageMode === 'alarms') {
            if ($state.params.secondaryFilter && $state.params.secondaryFilter !== null) {
                userDetailsDataSource.filter($state.params.secondaryFilter);
                $state.params.filter = null;
                $state.params.secondaryFilter = null;
            } else {
                userDetailsDataSource.read();
            }
        }
    };

    let lastUserRowUid;
    $scope.userClicked = function (row) {
        $scope.selectedUser = angular.copy(row);

        if (lastUserRowUid !== row.uid) {
            $scope.updateDetailsData();
        }

        lastUserRowUid = row.uid;
    };

    let lastAlarmRowUid;
    $scope.alarmClicked = function (row) {
        $scope.selectedAlarm = angular.copy(row);

        if (lastAlarmRowUid !== row.uid) {
            $scope.updateDetailsData();
        }

        lastAlarmRowUid = row.uid;
    };

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        const users = $scope.reportFilters?.users || [];
        const filterMode = users[0]?.filterMode || 'users';
        $scope.filterMode = filterMode;
        $scope.riskDataCache = [];
        $scope.gridTitle = $scope.filterMode === 'users' ? msg.get('user') : msg.get('computer');
        $scope.usersLabel = $scope.filterMode === 'users' ? msg.get('users') : msg.get('computersTitle');
        if ($scope.pageMode === 'users') {
            userRiskDataSource.filter($state.params.filter);
        } else {
            alarmRiskDataSource.filter($state.params.filter);
        }
    };

    $scope.setInitialParams = function (params) {
        if (params && params.user) {
            $scope.pageMode = 'users';
            if (accountSettings.username) {
                localStorageService.set('RiskReportPageMode-' + accountSettings.username, $scope.pageMode);
            }
            $scope.selectedUser = [];
            $scope.selectedUser.name = params.user;
        }
        if (params && params.alarm) {
            $scope.pageMode = 'alarms';
            if (accountSettings.username) {
                localStorageService.set('RiskReportPageMode-' + accountSettings.username, $scope.pageMode);
            }
            $scope.selectedAlarm = [];
            $scope.selectedAlarm.alarm = params.alarm;
        }
    };

    $timeout(function () {
        const queryUser = url.Query('user');
        const queryAlarm = url.Query('alarm');

        $scope.setInitialParams({
            user: (queryUser && decodeURIComponent(queryUser)) || $state.params.user,
            alarm: (queryAlarm && decodeURIComponent(queryAlarm)) || $state.params.alarm
        });
    });
}

angular.module('app').service('riskLevelService', RiskLevelService);

RiskLevelService.$inject = ['$http', 'envConfig'];

function RiskLevelService($http, envConfig) {
    this.getRiskLevelByUser = function (parameters, data) {
        return $http.get(envConfig.reportingServiceUrl() + '/reports/v1/risklevel/users?' + parameters, data);
    };
    this.getRiskLevelByAlarm = function (parameters, data) {
        return $http.get(envConfig.reportingServiceUrl() + '/reports/v1/risklevel/alarms?' + parameters, data);
    };
}
