import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const ActivTrakIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '19px';
  const height = props.height ?? '20px';
  const title = props.title ?? 'ActivTrak';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 21 20';

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor="none"
    >
      <path
        d="m5.975 10.064 9.196-1.5-3.307 8.709-5.89-7.209Z"
        fill="#333F48"
        style={style}
      />
      <path
        d="M5.975 10.064 2 10.697l8.437 10.334 1.428-3.758-5.89-7.209Z"
        fill="#00BAB3"
        style={style}
      />
      <path
        d="m15.17 8.565-9.195 1.5-3.216-3.957L22 3l-6.92 18.211-3.216-3.938 3.307-8.708Z"
        fill="#147BD1"
        style={style}
      />
    </SvgIconWrapper>
  );
};
