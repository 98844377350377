import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { IWidgetModalListProps, IReportModalListItem } from '../../models';
import { ExpandableList } from '../../../common/components/List/ExpandableList';
import Link from '../../../common/components/Link';
import { ListItemBox, ModalContentBox } from '../../styles/templates.styles';
import { TextOverflow } from '../../../common/styles/app.component.styles';
import authorizationService from '../../../common/helpers/authorization';

export const WidgetModalList = (props: IWidgetModalListProps) => {
  const { data } = props;

  const [showButton, setShowButton] = useState<boolean>(false);
  const [OriginalList, setOriginalList] =
    useState<IReportModalListItem[]>(null);
  const [listDisplay, setListsDisplay] = useState<IReportModalListItem[]>(null);
  const [isFullList, setIsFullList] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setOriginalList(data);
      if (data.length > 20) {
        setShowButton(true);
        setIsFullList(false);
      } else {
        setShowButton(false);
        setIsFullList(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (OriginalList?.length) {
      const list = [...OriginalList];
      if (isFullList) {
        setListsDisplay(list);
      } else {
        setListsDisplay(list.slice(0, 20));
      }
    }
  }, [isFullList, OriginalList]);

  const handleViewToggleState = useCallback((val) => {
    if (val) {
      setIsFullList(true);
    } else {
      setIsFullList(false);
    }
  }, []);

  const createList = (list) => {
    return list?.map((item) => {
      const hasAccess =
        item.url && authorizationService.hasRouteByName(item.url);
      return (
        <ListItemBox key={`${item.value}-${item.name?.replace(/\s+/g, '')}`}>
          {hasAccess ? (
            <Link
              to={item.url}
              params={{
                fromNavigation: true,
                ...item.urlParams
              }}
            >
              <TextOverflow>{item.name}</TextOverflow>
            </Link>
          ) : (
            <TextOverflow>{item.name}</TextOverflow>
          )}

          <Typography
            sx={{
              color: `${item.valueColor ? item.valueColor : 'inherit'}`,
              pl: 1
            }}
          >
            {item.value}
          </Typography>
        </ListItemBox>
      );
    });
  };

  return (
    <>
      {listDisplay?.length > 0 && (
        <ExpandableList showButton={showButton} onClick={handleViewToggleState}>
          <ModalContentBox>{createList(listDisplay)}</ModalContentBox>
        </ExpandableList>
      )}
    </>
  );
};
