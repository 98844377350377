const pollSettings = {
  teamPulse: {
    pollDelay: 300000
  },
  realtime: {
    pollDelay: 5000,
    pollDelayAfterError: 10000,
    currentDelay: 5000
  }
};

export { pollSettings }