import { BehaviorSubject } from 'rxjs';

class sidebar {
    constructor({ menuType, currentState, localStorage }) {
        this.menuType = menuType;
        this.menu = new BehaviorSubject();
        this.restrictedMenu = new BehaviorSubject();
        this.openMenuIdSubject = new BehaviorSubject();
        this.currentStateSubject = new BehaviorSubject(currentState);
        this.timeout = null;
        this.mouseOver = {
            toggleButton: false,
            menuContainer: false
        };
        this.localStorage = localStorage;
        this.isOpen = false;
        this.setIsMobile();
        this.setIsPinned();
    }

    /**
     * Hides sidebar if applicable
     */

    hideSidebar(urlToHideSidebar) {
        var sidebar = document.querySelector('.at-sidebar-menu-wrapper ');

        if (location.hash.includes(urlToHideSidebar)) {
            $(window.document.body).removeClass('menu-pin');
            this.close();
            if (sidebar) sidebar.classList.add('hide-sidebar');
        }
    }

    showSidebar() {
        var sidebar = document.querySelector('.at-sidebar-menu-wrapper ');
        if (sidebar && sidebar.classList.contains('hide-sidebar')) {
            sidebar.classList.remove('hide-sidebar');
            this.setIsPinned();
        }
    }

    /**
     * Sets the is pinned value based on local storage
     */
    setIsPinned() {
        this.isPinned = !this.isMobile && this.getPin();
        this.isOpen = this.isPinned;

        // If pinned then open the menu else close the menu.
        if (this.isPinned) {
            $(window.document.body).addClass('menu-pin');
            this.open();
        } else {
            $(window.document.body).removeClass('menu-pin');
            this.close();
        }
    }

    /**
     * Sets the is mobile value based on current screen size;
     */
    setIsMobile() {
        this.isMobile = window.innerWidth < 980;
    }

    /**
     * Toggles the menu if on small screen
     */
    toggleMobileMenu() {
        if (this.isMobile) {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        }
    }

    /**
     * Sets the pinned local storage variable with the given state.
     *
     * @param {boolean} state
     */
    setPin(state) {
        this.localStorage.service.set(`sidebarPinned-${this.localStorage.username}`, state);
    }

    /**
     * Gets the pinned local storage variable
     * It should default the pin to true if not found
     * in local storage meeting the requirement that new
     * accounts have the menu default to open.
     */
    getPin() {
        const isPinned = this.localStorage.service.get(`sidebarPinned-${this.localStorage.username}`);

        return isPinned === null ? true : isPinned;
    }

    /**
     * Open sidebar menu via CSS animations by adding
     * a class to the body
     */
    open() {
        this.isOpen = true;
        $(window.document.body).addClass('menu-open');
    }

    /**
     * Close sidebar menu via CSS animations by removing
     * a class to the body
     */
    close() {
        this.isOpen = false;
        $(window.document.body).removeClass('menu-open');
    }

    /**
     * Toggle menu pin, store in local storage, and open/close
     * menu based on the pin state
     */
    togglePin() {
        this.isPinned = !this.isPinned;
        this.setPin(this.isPinned);

        if (this.isPinned) {
            $(window.document.body).addClass('menu-pin');
            this.open();
        } else {
            $(window.document.body).removeClass('menu-pin');
            this.close();
        }
    }

    /**
     * Calls menu open after a given delay if the
     * menu is not already open and the mouse
     * is not hovering over the toggle pin button.
     * Used on mouse enter of the menu container
     *
     * @param {number} delay
     */
    openDelay(delay = 300) {
        this.mouseOver.menuContainer = true;
        if (this.isOpen || this.mouseOver.toggleButton || this.isMobile) {
            return;
        }

        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.open, delay);
    }

    /**
     * Calls menu close after a given delay if the menu
     * is not pinned. Used on mouse exit of the menu
     * container
     *
     * @param {number} delay
     */
    closeDelay(delay = 100) {
        this.mouseOver.menuContainer = false;
        if (this.isPinned || this.isMobile) {
            return;
        }

        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.close, delay);
    }

    /**
     * Cancel delay if mouse enters toggle pin button.
     * This keeps the button from moving on the user
     * while trying to click it.
     */
    onToggleButtonEnter() {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.mouseOver.toggleButton = true;
    }

    /**
     * Calls menu openDelay if the mouse leaves the toggle
     * pin button and stays in the menu container.
     */
    onToggleButtonExit() {
        this.mouseOver.toggleButton = false;

        if (this.mouseOver.menuContainer) {
            this.openDelay();
        }
    }

    getActiveMenu(menuItems = this.menu.value) {
        let activeMenu;
        _.forEach(menuItems, (menu) => {
            if (menu.hasSubmenus()) {
                activeMenu = this.getActiveMenu(menu.submenus);
            } else if (menu.active && menu.isInternalAction()) {
                activeMenu = menu;
            }

            return !activeMenu;
        });

        return activeMenu;
    }

    getMenuItem(route, params, menuItems = this.menu.value) {
        let menuItem;
        _.forEach(menuItems, (menu) => {
            if (menu.hasSubmenus()) {
                menuItem = this.getMenuItem(route, params, menu.submenus);
            } else if (menu.isInternalAction()) {
                if (
                    menu.action.isActiveState({
                        stateName: route,
                        modelId: params.modelId,
                        pageId: params.pageId
                    })
                ) {
                    menuItem = menu;
                }
            }

            return !menuItem;
        });

        return menuItem;
    }

    getMenuItemById(id, menuItems = this.menu.value) {
        let menuItem;
        _.forEach(menuItems, (menu) => {
            if (menu.id === id) {
                menuItem = menu;
            } else if (menu.hasSubmenus()) {
                menuItem = this.getMenuItemById(id, menu.submenus);
            }

            return !menuItem;
        });

        return menuItem;
    }

    fallbackDashboardParams() {
        const menuItems = this.menu.value;
        if (!menuItems) {
            console.error('ActivTrak Error: Fallback dashboard cannot be retrieved due to no menu items.');
            return {};
        }

        let onClick;
        const findFallback = (items) => {
            _.forEach(items, (menu) => {
                if (menu.isFallbackDashboard()) {
                    onClick = menu.onClick();
                } else if (menu.hasSubmenus()) {
                    findFallback(menu.submenus);
                }

                return !onClick;
            });
        };
        findFallback(menuItems);

        if (!onClick || onClick === {}) {
            console.error(
                'ActivTrak Error: Fallback dashboard cannot be retrieved due to fallback dashboard improperly set.'
            );
            return {};
        }

        return {
            modelId: onClick.modelId,
            pageId: onClick.pageId,
            subPageId: onClick.subPageId
        };
    }

    getCurrentTag() {
        const activeMenu = this.getActiveMenu();
        let tag = activeMenu && activeMenu.getTag();

        if (!tag && activeMenu) {
            const parentMenu = this.getMenuItemById(activeMenu.parentId);
            tag = parentMenu && parentMenu.getTag();
        }

        return tag;
    }
}

export default sidebar;
