import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadProps } from './FileUpload.props';
import { primaryBlue } from '../../constants';
import { DndArea, DndContainer } from './FileUpload.styles';
import { Box, Button, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

export const FileUpload = (props: FileUploadProps) => {
  const { onSetFiles, customOptions, additionalInstructions } = props;

  //onDrop + currentAttachments is needed so current state of acceptedFiles can be listened to when removing files
  const onDrop = useCallback(
    (acceptedFiles) => {
      onSetFiles((prevState) => [...prevState, ...acceptedFiles]);
    },
    [onSetFiles]
  );

  const dndOptions = { ...{ onDrop }, ...customOptions };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections
  } = useDropzone(dndOptions);

  const removeAttachment = (file: File) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    onSetFiles([]);
  };

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.name}>
        {file.name} - {file.size} bytes{' '}
        <Button onClick={() => removeAttachment(file)} startIcon={<Delete />}>
          Remove file
        </Button>
      </li>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.name}>
        {file.name} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <>
      <DndContainer>
        <DndArea {...getRootProps()}>
          <>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here...</Typography>
            ) : (
              <>
                <Typography>
                  Drag and Drop or{' '}
                  <span style={{ color: primaryBlue }}>Browse Files</span>
                </Typography>
              </>
            )}
            {additionalInstructions}
          </>
        </DndArea>
      </DndContainer>
      {acceptedFileItems?.length > 0 && (
        <Box sx={{ marginTop: '5px' }}>
          <strong>Accepted files</strong>
          <ul>{acceptedFileItems}</ul>
        </Box>
      )}
      {fileRejectionItems?.length > 0 && (
        <Box sx={{ marginTop: '5px' }}>
          <strong>Rejected files</strong>
          <ul>{fileRejectionItems}</ul>
        </Box>
      )}
    </>
  );
};
