import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

type ComponentProps = {
  isOpen: boolean;
  onCancel: () => void;
  onAgree: () => void;
  agentDescription: string;
  userDescription: string;
};

export const DialogVerifyAgentMove = (props: ComponentProps) => {
  const { isOpen, onCancel, onAgree } = props;

  return (
    <>
      <Dialog maxWidth="xs" open={isOpen} onClose={onCancel} fullWidth={true}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <Box sx={{ marginBottom: '20px' }}>
            All of {props.agentDescription} agent&apos;s historical and future
            activity data will be associated with {props.userDescription}.
          </Box>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button variant="text" autoFocus onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onAgree} variant="contained" color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
