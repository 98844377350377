'use strict';

var templateServiceFunctions = require('_app/serviceFunctions/templateServiceFunctions')
    .templateServiceFunctions;

angular
    .module('app')
    .service('templateServiceFunctions', TemplateServiceFunctions);

TemplateServiceFunctions.$inject = [];

function TemplateServiceFunctions() {
    var service = this;

    angular.extend(service, templateServiceFunctions);
}
