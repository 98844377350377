import 'typeface-roboto';
import { createTheme } from '@mui/material/styles';
import {
  aqua3,
  aqua4,
  aqua5,
  aqua6,
  blue2,
  buttonFontSize,
  buttonTextColor,
  disabledTextColor,
  gray12,
  gray13,
  gray14,
  gray15,
  gray4,
  gray5,
  gray6,
  headerColor,
  heading1FontSize,
  heading2FontSize,
  heading3FontSize,
  heading4FontSize,
  heading5FontSize,
  heading6FontSize,
  linkHoverColor,
  mediumFontWeight,
  primaryAqua,
  primaryBlue,
  primaryColor,
  primaryFontFamily,
  primaryFontSize,
  primaryRed,
  primaryYellow,
  regularFontWeight,
  secondaryAqua,
  tooltipFontSize
} from './styleGuide';

const baseStyles = {
  fontFamily: primaryFontFamily,
  fontWeightRegular: regularFontWeight,
  fontSize: parseInt(primaryFontSize),
  lineHeight: 1.5
};

const breakpoints = {
  xs: 0,
  sm: 720,
  md: 1024,
  lg: 1152,
  xl: 1488
};

export default createTheme({
  cardMinWidth: 145,
  cardMaxWidth: 240,
  drawerWidth: 300,
  palette: {
    primary: {
      main: primaryAqua,
      light: secondaryAqua,
      dark: primaryAqua
    },
    secondary: {
      main: gray12
    },
    error: {
      main: primaryRed
    },
    warning: {
      main: primaryYellow
    },
    info: {
      main: primaryBlue
    },
    success: {
      main: primaryAqua
    },
    link: {
      main: blue2,
      hover: linkHoverColor
    },
    text: {
      primary: primaryColor,
      secondary: gray6
    }
  },
  typography: baseStyles,
  breakpoints: {
    values: breakpoints
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: primaryAqua,
          backgroundColor: '#ffffff',
          borderColor: primaryAqua,
          '&:hover': {
            backgroundColor: aqua5
          },
          '&:focus': {
            backgroundColor: aqua6
          },
          '&.Mui-disabled': {
            color: primaryColor,
            borderColor: gray14,
            backgroundColor: gray15,
            opacity: 1
          }
        },
        textPrimary: {
          color: primaryBlue,
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
          '&:hover': {
            color: linkHoverColor,
            backgroundColor: '#ffffff'
          },
          '&:focus': {
            backgroundColor: '#ffffff'
          },
          '&.Mui-disabled': {
            color: gray13,
            opacity: 1
          }
        },
        containedPrimary: {
          color: buttonTextColor,
          backgroundColor: primaryAqua,
          borderColor: primaryAqua,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: aqua3,
            boxShadow: 'none'
          },
          '&:focus': {
            backgroundColor: aqua4
          },
          '&.Mui-disabled': {
            color: disabledTextColor,
            borderColor: gray14,
            backgroundColor: gray14,
            opacity: 1
          }
        },
        root: {
          fontSize: buttonFontSize,
          fontWeight: mediumFontWeight,
          padding: '8px 16px',
          lineHeight: '18px',
          letterSpacing: '1.25px',
          textTransform: 'none',
          color: buttonTextColor,
          '&.Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            opacity: 0.4,
            color: disabledTextColor
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: primaryColor,
          fontSize: primaryFontSize
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: primaryColor,
          fontSize: primaryFontSize,
          '&.MuiDialogTitle-root': {
            fontSize: heading6FontSize,
            paddingTop: '30px',
            paddingBottom: 0,
            paddingRight: '15px',
            paddingLeft: '15px'
          },
          '&.MuiLink-root': {
            color: primaryBlue,
            textDecoration: 'none',
            cursor: 'pointer',
            '&[disabled]': {
              pointerEvents: 'none',
              color: gray13
            },
            '&:hover': {
              color: linkHoverColor
            }
          }
        },
        body1: {
          fontSize: primaryFontSize
        },
        h1: {
          color: headerColor,
          fontSize: heading1FontSize,
          fontWeight: mediumFontWeight,
          letterSpacing: '-1.5px',
          lineHeight: 1.2
        },
        h2: {
          color: headerColor,
          fontSize: heading2FontSize,
          fontWeight: mediumFontWeight,
          letterSpacing: '-0.5px',
          lineHeight: 1.2
        },
        h3: {
          color: headerColor,
          fontSize: heading3FontSize,
          fontWeight: mediumFontWeight,
          lineHeight: 1.2
        },
        h4: {
          color: headerColor,
          fontSize: heading4FontSize,
          fontWeight: mediumFontWeight,
          lineHeight: 1.2
        },
        h5: {
          color: headerColor,
          fontSize: heading5FontSize,
          fontWeight: mediumFontWeight,
          lineHeight: 1.2
        },
        h6: {
          color: headerColor,
          fontSize: heading6FontSize,
          fontWeight: mediumFontWeight,
          letterSpacing: '0.5px',
          lineHeight: 1.2
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          fontFamily: primaryFontFamily,
          color: primaryColor,
          fontSize: primaryFontSize
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '+.MuiDialogContent-root': {
            paddingTop: '25px !important'
          },
          paddingRight: '15px',
          paddingLeft: '15px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '25px',
          paddingBottom: '40px',
          paddingRight: '15px',
          paddingLeft: '15px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: '15px',
          paddingRight: '20px',
          paddingLeft: '20px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: primaryColor,
          fontSize: primaryFontSize,
          fontWeight: regularFontWeight
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: primaryFontSize,
          color: gray6
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: primaryColor
          },
          '&:hover': {
            backgroundColor: 'rgba(68, 68, 68, 0.04)'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: primaryFontSize,
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
            }
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: buttonTextColor
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: `${gray5} !important`,
          fontSize: primaryFontSize,
          '&.Mui-selected': {
            color: `${primaryColor} !important`
          },
          '&:hover': {
            color: `${primaryColor} !important`,
            opacity: 1
          },
          '&:focus': {
            color: `${primaryColor} !important`
          },
          '&.MuiTab-textColorInherit.Mui-disabled': {
            opacity: 1
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: primaryColor,
          minWidth: 'auto',
          bottom: 'auto',
          transition: 'none'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: primaryColor,
          '&.Mui-disabled': {
            color: primaryColor,
            opacity: 0.4
          }
        },
        colorPrimary: {
          color: primaryColor,
          '&.Mui-disabled': {
            color: primaryColor,
            opacity: 0.4
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: gray4
          }
        },
        notchedOutline: {
          borderColor: gray4
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: tooltipFontSize
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: primaryColor
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: primaryColor
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&.Mui-disabled': {
            cursor: 'not-allowed'
          },
          '&.Mui-disabled > .MuiInputBase-root >.MuiSelect-root': {
            cursor: 'not-allowed'
          },
          '&.Mui-disabled > .MuiListItemIcon-root': {
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: 'transparent',

            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },

            '&.Mui-focused': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '5px 0px'
        },
        content: {
          width: '100%'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    }
  }
});
