import { IRoute } from '../../../../models/IRoute';
import { appTemplate as angularJsTemplate } from '../templates';

export const app: IRoute = {
  name: 'app',
  stateDefinition: {
    abstract: true,
    url: '/app',
    template: angularJsTemplate,
    controller: 'MainCtrl',
    resolve: {
      accountSettings: [
        'accountApiHelper',
        function (accountApiHelper) {
          const settings = accountApiHelper.loadSettings();
          return settings;
        }
      ]
    }
  }
};

export default app;
