import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { EditableTextFieldProps } from './EditableTextField.props';

const MAX_TRUNCATION_LENGTH = 22;

export const EditableTextField: React.FC<EditableTextFieldProps> = (props) => {
  const { defaultValue } = props;

  const isCategoryNameShort = defaultValue?.length < MAX_TRUNCATION_LENGTH;

  return (
    <>
      <Tooltip
        disableHoverListener={isCategoryNameShort}
        disableFocusListener={isCategoryNameShort}
        title={defaultValue}
      >
        <TextField {...props} variant="outlined" />
      </Tooltip>
    </>
  );
};
