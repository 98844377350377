import React, { useEffect } from 'react';
import { useAuthorization } from '../../../common/services/Authorization';
import { Role } from '../../../common/enums';
import { useHasDataEmit } from '../../common/hooks/useHasDataEmit';
import { shouldEnableTimeSlider } from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { AlarmLogHeaderProps } from '../models/AlarmLogTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { ColumnFilter } from '../../../common/components/ReportFilters/components/ColumnFilter';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const AlarmLogHeader = (props: AlarmLogHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const hasData: boolean = useHasDataEmit();

  const authorizationService = useAuthorization();

  const enableTimeSlider = shouldEnableTimeSlider(fromDate, toDate) && hasData;

  useEffect(() => {
    if (!enableTimeSlider) {
      setReportFilters({ fromTimestamp: null });
    }
  }, [enableTimeSlider]);

  const ignoreDateRestrictions: boolean = authorizationService.hasAnyRole([
    Role.SuperAdmin,
    Role.SupportAdvanced
  ]);

  return (
    <>
      <Box>
        <ReportHeaderLeftColumn>
          <ReportHeaderInline>
            <DateRangeFilter
              dates={dates}
              onSubmitFilter={setReportFilters}
              ignoreRestrictions={ignoreDateRestrictions}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <UserComputerFilter
              onSubmitFilter={setReportFilters}
              users={users}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
          </ReportHeaderInline>
        </ReportHeaderLeftColumn>
        <ReportHeaderRightColumn>
          <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
            <AcademyButton />
          </ReportHeaderInline>
          <ReportHeaderInline
            sx={{ mr: 1, display: { xs: 'none', md: 'block' } }}
          >
            <ColumnFilter />
          </ReportHeaderInline>
          <ReportHeaderInline
            sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
          >
            <ExportButtonContainer />
          </ReportHeaderInline>
        </ReportHeaderRightColumn>
      </Box>
    </>
  );
};
