'use strict';

var atDashboardWidgetServiceFunctions = require('_app/serviceFunctions/atDashboardWidgetServiceFunctions')
    .atDashboardWidgetServiceFunctions;

angular
    .module('app')
    .service('atDashboardWidgetService', AtDashboardWidgetService);

AtDashboardWidgetService.$inject = ['$transitions'];

function AtDashboardWidgetService($transitions) {
    var service = this;

    angular.extend(service, atDashboardWidgetServiceFunctions);

    // Clear widgets on transition changes.
    $transitions.onStart({}, function() {
        service.initialize([], false);
    });
}
