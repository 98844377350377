import { useState } from 'react';

//hook version of dom-helper's scrollbarSize.ts
export const useScrollbarSize = (recalc?: boolean): number => {
  const [scrollbarSize, setScrollbarSize] = useState<number>(null);

  if ((!scrollbarSize && scrollbarSize !== 0) || recalc) {
    const scrollDiv = document.createElement('div');

    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    setScrollbarSize(scrollDiv.offsetWidth - scrollDiv.clientWidth);
    document.body.removeChild(scrollDiv);
  }

  return scrollbarSize;
};
