import theme from '../../../common/theme/theme';

export const getClasses = (isActive) => {
    return {
        sidePaddings: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        topPaddings: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        smallTopPaddings: {
            paddingTop: 2,
            paddingBottom: 2
        },
        titleTop: {
            marginTop: 0,
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        titleTopMargins: {},
        topRightWrapper: {
            marginLeft: 'auto',
            fontSize: '14px',
            '& p': {
                fontSize: '14px'
            }
        },
        hr: {
            marginTop: 0,
            marginBottom: 0
        },
        statusCounts: {
            display: 'flex',
            margin: 0,
            padding: 0,
            justifyContent: 'space-around'
        },
        root: {
            borderColor: isActive ? theme.palette.grey.A200 : 'rgba(0,0,0,0.12)',
            marginBottom: 10
        },
        skeletonBar: {
            background:
                'linear-gradient(left, rgba(0,0,0,0.08), rgba(0,0,0,0.08) 90%, rgba(0,0,0,0.04) 1%, rgba(0,0,0,0.04) 10%)'
        }
    };
};
