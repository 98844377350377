import { react2angular } from 'react2angular';
import { AccountConfigurationComponent } from '../../_reactivtrak/src/account-configuration/views/AccountConfiguration.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('accountConfiguration', react2angular(AccountConfigurationComponent, []));
}
