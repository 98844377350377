class Team {
    
    constructor(data) {
        //Looker puts the filter element between quotes if it contains one of the following
        var lookerSpecialChars = ['"', '%', '_', '^', ',' , "'" ];
        this.text = data.name;
        this.value = lookerSpecialChars.some(el => data.name.includes(el)) ?
            '"'+data.name.toString()+'"'
            :
            data.name.toString();
        this.id = data.id;
    }
}

export default Team;
