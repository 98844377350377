import React from 'react';
import { MenuItem, Select } from '@mui/material';
import {
  defaultProductivityStatus,
  DropdownElement,
  productivityStatuses
} from '../../common/constants';
import { classificationClasses } from '../styles/Classifications.styles';
import { isIgnoreCategoryId } from '../../common/helpers';
import { ProductivityStatus } from '../../common/enums';
import { SelectNoOutline } from '../../common/components/Select/SelectNoOutline';

interface ProductivityProps {
  productivityStatus?: number;
  title?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  className?: object;
  categoryId?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  onChange?: (event: any) => void;
}

const ProductivityDropdown = (props: ProductivityProps) => {
  const {
    productivityStatus,
    title,
    variant,
    className,
    categoryId,
    isVisible,
    isDisabled,
    onChange
  } = props;

  const displayDropdown = isVisible ?? true;

  if (!displayDropdown || isIgnoreCategoryId(categoryId)) {
    return <></>;
  }

  const dropdownTitle = title || defaultProductivityStatus.name;
  const selectClassName = className ? className : {};
  let menuItemClassName = className ? className : {};
  menuItemClassName = { ...menuItemClassName, ...{ width: 'auto' } };

  const setStyle = (element: DropdownElement, id?: ProductivityStatus) => {
    if (element === DropdownElement.Select) {
      switch (productivityStatus) {
        case ProductivityStatus.Productive:
          return {
            ...selectClassName,
            ...classificationClasses.productivitySelect,
            ...classificationClasses.productiveColor
          };
        case ProductivityStatus.Unproductive:
          return {
            ...selectClassName,
            ...classificationClasses.productivitySelect,
            ...classificationClasses.unproductiveColor
          };
        default:
          return {
            ...selectClassName,
            ...classificationClasses.productivitySelect
          };
      }
    } else if (element === DropdownElement.Menu) {
      switch (id) {
        case ProductivityStatus.Productive:
          return {
            ...menuItemClassName,
            ...classificationClasses.productiveColor
          };
        case ProductivityStatus.Unproductive:
          return {
            ...menuItemClassName,
            ...classificationClasses.unproductiveColor
          };
        default:
          return menuItemClassName;
      }
    }
  };

  const styledMenuItems = productivityStatuses.map((x) => ({
    id: x.id,
    name: x.name,
    sx: setStyle(DropdownElement.Menu, x.id)
  }));

  const SelectComponent = variant === 'standard' ? SelectNoOutline : Select;

  return (
    <SelectComponent
      id="select-status"
      value={productivityStatus || defaultProductivityStatus.id}
      onChange={(e) => onChange(e)}
      onClick={(e) => e.stopPropagation()}
      inputProps={{
        name: 'localProductivity',
        id: 'select-status'
      }}
      sx={setStyle(DropdownElement.Select)}
      variant={variant}
      disabled={isDisabled}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
    >
      {/* If there is a productivity value already assigned to this,
            don't show this option to the user so they do not click it */}
      {!productivityStatus ? (
        <MenuItem
          aria-label={dropdownTitle}
          value={defaultProductivityStatus.id}
          sx={menuItemClassName}
        >
          {dropdownTitle}
        </MenuItem>
      ) : null}
      {styledMenuItems &&
        styledMenuItems.map((option) => (
          <MenuItem sx={option.sx} key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </SelectComponent>
  );
};

export default ProductivityDropdown;
