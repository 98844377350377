import { useCallback, useState } from 'react';
import {
  ITodaysProductivity,
  ITodaysProductivityDto,
  ITodaysProductivityState
} from '../models';
import { mapToTodaysProductivity } from '../utils';
import {
  fetchReportingData,
  getParsedLocalStorageItem
} from '../../common/helpers';
import { ApiRoutes } from '../constants';
import { IAccountSettings } from '../../common/models';
import { localStorageItems } from '../../common/constants';

export const useTodaysProductivityState = (): ITodaysProductivityState => {
  const [todaysProductivity, setTodaysProductivity] =
    useState<ITodaysProductivity>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const timezone = accountSettings?.currentIanaTimeZone;

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchReportingData<ITodaysProductivityDto>({
        path: ApiRoutes.reports.fetchTodaysProductivity
      });
      const mapped: ITodaysProductivity = mapToTodaysProductivity(
        response,
        timezone
      );
      setTodaysProductivity(mapped);
    } catch (error) {
      console.error(
        `ActivTrak Error: Unable to fetch today's productivity: ${error}`,
        error
      );
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, [timezone]);

  return {
    isLoading,
    isTileError,
    todaysProductivity,
    init
  };
};
