import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { FormContainer, TextSection, SubmitContainer } from '../styles';
import { gray4 } from '../../common/constants';

type ActivityTimezoneFormProps = {
  showLocalTimezone: boolean;
  isFormDisabled: boolean;
  onSubmit: (newShowLocalTimezone: boolean) => Promise<void>;
};

export const ActivityTimezoneForm = (props: ActivityTimezoneFormProps) => {
  const { showLocalTimezone, isFormDisabled, onSubmit } = props;

  const [newShowLocalTimezone, setNewShowLocalTimezone] =
    useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setNewShowLocalTimezone(showLocalTimezone);
    setIsSaveDisabled(true);
  }, [showLocalTimezone]);

  const handleLocalTimezoneChange = useCallback(
    (event) => {
      //enable button if value is different from original
      const value = event.target.value;
      if (value !== showLocalTimezone) {
        setIsSaveDisabled(false);
        setNewShowLocalTimezone(value);
      } else {
        setIsSaveDisabled(true);
      }
    },
    [showLocalTimezone]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(newShowLocalTimezone);
  }, [newShowLocalTimezone, onSubmit]);

  return (
    <FormContainer elevation={3}>
      <Typography variant="h6">Activity Time and Time Zone</Typography>
      <TextSection>
        Select your display preference for activity time and time zone. In
        addition to the account time zone, the activity time will show the time
        and time zone configured by the user or the computer.
      </TextSection>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="activity-time-zone"
            value={newShowLocalTimezone}
            name="activity-time-zone-buttons"
            onChange={handleLocalTimezoneChange}
          >
            <Box sx={{ border: `1px solid ${gray4}`, borderRadius: '5px' }}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="DISPLAY activity time and time zone for each website or application the user or computer uses throughout the day."
                disabled={isFormDisabled}
              />
            </Box>
            <Box sx={{ border: `1px solid ${gray4}`, borderRadius: '5px' }}>
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="DO NOT DISPLAY activity time zone for each website or application the user or computer uses throughout the day. Only display the account time and time zone."
                disabled={isFormDisabled}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      <SubmitContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </SubmitContainer>
    </FormContainer>
  );
};
