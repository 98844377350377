'use strict';

angular.module('app')
    .controller('firstTimeLoginModal', FirstTimeLoginModal);

FirstTimeLoginModal.$inject = ['$scope', '$uibModalInstance', 'profileService', 'pageStart', 'atHelperFunctions'];

function FirstTimeLoginModal($scope, $uibModalInstance, profileService, pageStart, atHelperFunctions) {

    $scope.profile = angular.copy(profileService.profileInfo);
    $scope.page = pageStart || 1;


    // If profile is more than 7 days old, assume not a new user.
    var dateCheck = moment().add(-7, 'days');
    $scope.isNewUser = dateCheck.isBefore(moment(profileService.profileInfo.creationDate));

    $scope.next = function () {
        if ($scope.disableNext()) {
            return;
        }

        profileService.updateProfile($scope.profile);

        if (!$scope.isNewUser || ++$scope.page > 2) {
            $uibModalInstance.dismiss();
        }
    };

    $scope.disableNext = function () {
        return $scope.page === 1 && ($scope.firstTimeLoginForm.$invalid || atHelperFunctions.isEmpty($scope.profile.firstName) || atHelperFunctions.isEmpty($scope.profile.lastName));
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };


    var roleDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                options.success(profileService.profileRoles);
            }
        }
    });

    $scope.roleDropdownOptions = {
        dataSource: roleDataSource,
        valuePrimitive: true,
        optionLabel: 'Role'
    };

    var industryDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                options.success(profileService.profileIndustries);
            }
        }
    });

    $scope.industryDropdownOptions = {
        dataSource: industryDataSource,
        valuePrimitive: true,
        optionLabel: 'Department'
    };

    $scope.$watch(function () {
        return $scope.profile.title;
    }, function (newValue) {
        if (newValue === 'Role') {
            $scope.profile.title = null;
        }
    });

    $scope.$watch(function () {
        return $scope.profile.industry;
    }, function (newValue) {
        if (newValue === 'Department') {
            $scope.profile.industry = null;
        }
    });
}
