import { IRoute } from '../../../../models/IRoute';
import { loginTemplate as angularJsTemplate } from '../templates';

export const login: IRoute = {
  name: 'login',
  stateDefinition: {
    url: '/login?tempcode&setid&resetid&email&next&sso',
    template: angularJsTemplate,
    controller: 'LoginController as vm',
    resolve: {
      checkForUtm: [
        'atHelperFunctions',
        'loginService',
        '$state',
        function (atHelperFunctions, loginService, $state) {
          if (
            window.location.href.indexOf('tempcode=') < 0 &&
            loginService.isLoggedIn()
          ) {
            $state.go('app.dashboard');
          }
          return atHelperFunctions.removeUtmFromUrl();
        }
      ]
    },
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      apiFailure: false,
      maintenanceModeReason: null,
      showLockoutError: false,
      logoutReason: null,
      sso: null
    },
    data: { pageTitle: 'Login' }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default login;
