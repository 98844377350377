import React, { createContext, useContext } from 'react';
import { IDownloadService } from './IDownloadService';
import DownloadService from './DownloadService';
const DownloadContext = createContext<IDownloadProvider>(null);

interface IDownloadProvider {
  downloadService: IDownloadService;
}

type Props = {
  children: React.ReactNode;
  downloadService?: IDownloadService;
};

export const DownloadProvider = (props: Props) => {
  const { children, downloadService } = props;
  const value = {
    downloadService: downloadService || new DownloadService()
  };

  return (
    <DownloadContext.Provider value={value}>
      {children}
    </DownloadContext.Provider>
  );
};

export const UseDownloadContext = (): IDownloadProvider => {
  return useContext(DownloadContext);
};
