import moment from 'moment';
import 'moment-timezone';
import {
  DATE_FORMAT,
  DATE_HOUR_LOCAL_FORMAT,
  DATE_HOUR_MIN_FORMAT,
  ABBREVIATED_WEEKDAYS,
  FULL_WEEKDAYS,
  DATE_HOUR_FORMAT
} from '../constants';
import { IAccountSettings } from '../models';

export const getDateTimeFormat = (dateFormat: string) =>
  dateFormat?.toUpperCase() + ' HH:mm:ss';

export const getDateTimeLocalFormat = (dateFormat: string) =>
  dateFormat?.toUpperCase() + '[T]HH:mm:ss';

export const getDateByIanaTimeZone = (ianaTimeZone) => {
  return moment.tz(ianaTimeZone).format(DATE_HOUR_LOCAL_FORMAT);
};

export const getFutureExpireDate = (futureExpireDateInMinutes: number) => {
  return moment()
    .add(futureExpireDateInMinutes - 1, 'minute')
    .format(DATE_HOUR_MIN_FORMAT);
};

export const formatDate = (date, dateFormat = DATE_FORMAT) => {
  let dateMoment;
  if (date) {
    dateMoment = moment(date);
  } else {
    dateMoment = moment();
  }

  if (dateMoment.isValid()) {
    return dateMoment.format(dateFormat);
  } else {
    return null;
  }
};

export const getWeekEnd = (
  date,
  accountSettings: Partial<IAccountSettings>,
  dateFormat = DATE_FORMAT
): string => {
  const endDate = moment(date).add(6, 'days').toDate();
  return formatDateWithTimezone(endDate, accountSettings, dateFormat);
};

export const formatDateWithTimezone = (
  date,
  accountSettings: Partial<IAccountSettings>,
  dateFormat = DATE_FORMAT
): string => {
  try {
    const currentIanaTimeZone = accountSettings?.currentIanaTimeZone;

    if (currentIanaTimeZone && date) {
      const parsedDate = new Date(date);
      if (parsedDate && parsedDate > new Date(1, 1, 1)) {
        const dateMoment = moment.utc(parsedDate).tz(currentIanaTimeZone);
        if (dateMoment.isValid()) return dateMoment.format(dateFormat);
      }
    }
  } catch {
    return '';
  }
  return '';
};

export const formatDateOrNull = (date, dateFormat = DATE_FORMAT) => {
  let dateMoment;
  if (date) {
    dateMoment = moment(date);
    if (dateMoment.isValid()) {
      return dateMoment.format(dateFormat);
    }
  }
  return null;
};

export const getTimeAgoDifference = (
  dateMoment: moment.Moment,
  nowDate: moment.Moment
) => {
  //a date more than 1 hour in the future will say future
  const futureBuffer = -1 * 1000 * 60 * 60; //60 minutes
  const nowDiff = nowDate?.diff(dateMoment);
  if (nowDiff < futureBuffer) {
    return 'Future';
  }
  //a date up to an hour in future will say now
  if (nowDiff > futureBuffer && nowDiff < 0) {
    return 'Now';
  }
  if (dateMoment?.isValid()) {
    const timeAgotext = dateMoment.from(nowDate);
    if (timeAgotext.startsWith('a few seconds')) {
      return `1 second ago`;
    } else if (timeAgotext.startsWith('an')) {
      return `1${timeAgotext.substring(2)}`;
    } else if (timeAgotext.startsWith('a')) {
      return `1${timeAgotext.substring(1)}`;
    } else {
      return timeAgotext;
    }
  }
  return null;
};

export const getMomentByTimeZone = (
  date: Date,
  accountSettings: IAccountSettings
): moment.Moment => {
  const tmpMoment = moment(date);
  if (accountSettings?.currentIanaTimeZone)
    return tmpMoment.tz(accountSettings.currentIanaTimeZone, true);
  return tmpMoment;
};

export const getTimeAgo = (date: Date): string => {
  if (date) {
    //identity dates come in utc, no need to apply any time zone offsets
    const momentDate = moment(date);
    const timeAgo = getTimeAgoByMoment(momentDate);
    return timeAgo;
  }
  return null;
};

export const getTimeAgoByTimeZone = (
  date: Date,
  accountSettings: IAccountSettings
): string => {
  if (date) {
    // FEAPI dates are already formatted in account time zone, so set the correct time zone
    const momentDate = getMomentByTimeZone(date, accountSettings);
    const timeAgo = getTimeAgoByMoment(momentDate);
    return timeAgo;
  }
  return null;
};

const getTimeAgoByMoment = (pastMoment: moment.Moment): string => {
  try {
    const currentDate = moment();
    const timeAgo = getTimeAgoDifference(pastMoment, currentDate);
    return timeAgo;
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot get timeAgo item\nItem: ${pastMoment.format(
        DATE_HOUR_FORMAT
      )}\nError: `,
      error
    );
    return null;
  }
};

export const getDefaultDateRanges = () => [
  {
    label: 'Yesterday',
    startDate: moment().subtract(1, 'days').startOf('day').toDate(),
    endDate: moment().subtract(1, 'days').endOf('day').toDate()
  },
  {
    label: 'Last 14 Days',
    startDate: moment().subtract(14, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate()
  },
  {
    label: 'Last 30 Days',
    startDate: moment().subtract(30, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate()
  },
  {
    label: 'Last 90 Days',
    startDate: moment().subtract(90, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate()
  }
];

export const getAbbreviatedWeekday = (date: Date) => {
  return ABBREVIATED_WEEKDAYS[date?.getUTCDay()];
};

export const getFullWeekday = (date: Date, timezone: string) => {
  const formattedDate = moment.utc(date).tz(timezone);
  return FULL_WEEKDAYS[formattedDate?.toDate().getDay()];
};

export const convertHourToMoment = (
  hours: number,
  date: string = moment().format(DATE_HOUR_LOCAL_FORMAT)
): moment.Moment => {
  const minute = hours === 24 ? 59 : 0;
  const hour = hours === 24 ? 23 : hours;

  return moment(date).hour(hour).minute(minute).second(0).millisecond(0);
};

export const getHour = (date: moment.Moment): number => {
  const hour = date.hour();
  if (hour === 23 && date.minute() === 59) {
    return 24;
  }

  return hour;
};

export const isTodayByTz = (
  dateToCompare: string,
  currentIanaTimeZone: string
) => {
  const compareDate = moment(dateToCompare).tz(currentIanaTimeZone);
  const today = moment().tz(currentIanaTimeZone);

  return compareDate.isSame(today, 'day');
};
