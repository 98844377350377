import React from 'react';
import { SvgIcon } from '@mui/material';

const ArrowIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="-1 -3 16 16">
      <path d="M9.7241755,2.21918488 L9.7241755,0 L14.5420625,3.52082216 L9.8125771,7.16967423 L9.7462759,5.52662388 C6.85112365,5.09985756 2.47524467,6.55086306 1.77635684e-15,9.30350584 C1.23762234,5.2919024 3.44766222,2.09115498 9.7241755,2.21918488 Z" />
    </SvgIcon>
  );
}

export default ArrowIcon;
