import { IReportFilters } from '../models/IReportFilters';
import { DateRanges } from './dateRanges';
import { ProductivityName } from './productivityName';
import { TimelineSort } from './timelineSort';

export const DEFAULT_REPORT_FILTERS: IReportFilters = {
  groupId: ['-1'],
  users: [],
  dates: {
    range: DateRanges.Custom,
    getDates: () => ({ toDate: null, fromDate: null })
  },
  productivity: ProductivityName.All,
  timelineFromHour: 8,
  timelineToHour: 17,
  timelineStrict: false,
  timelineInterval: 30,
  timelineSort: TimelineSort.TotalActivity
};
