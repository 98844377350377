import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const ButtonSC = styled(Button)<{ hideselectedoption: number }>(
  ({ hideselectedoption }) => ({
    color: 'inherit',
    '&:hover': {
      color: 'inherit'
    },
    '&.MuiButton-startIcon': {
      margin: hideselectedoption === 1 ? '0' : '0 8px 0 -4px'
    }
  })
);
