import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { isMSPAccount } from '../../../../../stores/accountPlanStore';
import { insightsPromoReportTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsPromo: IRoute = {
  name: 'app.reports.insightsPromo',
  stateDefinition: {
    url: '/tryinsights',
    template: angularJsTemplate,
    controller: 'insightsPromoReportCtrl',
    data: { pageTitle: 'Insights' }
  },
  access: {
    redirectTo: 'app.reports.insights',
    roleAccessKey: RoleAccessKeys.Configuration,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    excludedBundleFlags: [BundleFlag.InsightsReports],
    customFunction: () => !isMSPAccount()
  }
};

export default appReportsInsightsPromo;
