import { styled } from '@mui/material/styles';
import { Grid, IconButton, FormControlLabel, Card, Box } from '@mui/material';
import {
  gray1,
  gray6,
  mediumFontWeight,
  primaryAqua,
  primaryPurple,
  smallFontSize,
  heading6FontSize
} from '../../common/constants';
import { EditableTextField } from '../../common/components/TextField/EditableTextField';

export const classificationClasses = {
  searchInput: {
    backgroundColor: '#FFF !important'
  },
  header: {
    marginBottom: '15px',
    marginTop: '15px',
    alignItems: 'center'
  },
  activityColsHeaderRight: {
    textAlign: 'right',
    display: 'inline-flex',
    justifyContent: 'flex-end'
  },
  primaryButton: {},
  addCategoryInput: {
    width: '328px',
    height: '48px'
  },
  addCategoryMargins: {
    marginRight: '20px',
    marginBottom: '10px'
  },
  categoryMenuItem: {
    height: 'auto'
  },
  activityCardHeader: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0.15px',
    textAlign: 'left' as const,
    marginBottom: '16px'
  },
  classifiedHeader: {
    textAlign: 'center',
    lineHeight: '48px',
    letterSpacing: '-0.5px'
  },
  classifiedSubtitle: {
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.15px',
    fontSize: `${heading6FontSize} !important`
  },
  classifiedTextBox: {
    paddingTop: '70px',
    paddingBottom: '20px',
    '@media(min-width: 960px)': {
      float: 'right',
      paddingRight: '43px'
    }
  },
  classifiedImageBox: {
    textAlign: 'center',
    '@media(min-width: 960px)': {
      float: 'left',
      paddingLeft: '43px'
    }
  },
  groupClassificationHeader: {
    paddingTop: '20px',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0.15px',
    textAlign: 'left' as const
  },
  navigationButton: {
    transition: 'none'
  },
  footerContainer: {
    marginTop: '45px'
  },
  rightNavigationButton: {
    float: 'right',
    marginRight: '10px'
  },
  leftNavigationButton: {
    marginLeft: '10px'
  },
  hyperlink: {
    textDecoration: 'underline'
  },
  categorySelect: {
    overflow: 'hidden',
    '& .Mui-disabled': {
      cursor: 'not-allowed'
    }
  },
  cardMarginTop: {
    marginTop: '19px'
  },
  activityDetailsText: {
    fontStyle: 'normal',
    lineHeight: '18px',
    textAlign: 'left' as const,
    marginBottom: '16px'
  },
  extendedDropdown: {
    width: '100%',
    '&.categorySearchInput': {
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#ffffff'
      }
    }
  },
  groupClassificationsText: {
    marginTop: '12px'
  },
  textField: {
    height: '48px',
    borderRadius: '4px',
    borderColor: gray6
  },
  root: {
    paddingLeft: '0px'
  },
  draggableList: {
    item: {
      display: 'inline',
      padding: '0 10px 0 0'
    },
    itemIcon: {
      minWidth: '0px'
    },
    selectWidth: {
      width: '28%'
    },
    trashButton: {
      position: 'absolute',
      top: '-5px',
      left: '-10px'
    }
  },
  orderableDropdown: {
    '& div:focus': {
      backgroundColor: 'initial'
    }
  },
  iconAlignment: {
    paddingTop: '10px'
  },
  editableCategoryName: {
    paddingRight: '1em',
    fontStyle: 'normal',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  deleteModalText: {
    '& span': {
      fontWeight: mediumFontWeight
    }
  },
  deleteCategoryCopy: {
    '& div': {
      fontWeight: mediumFontWeight,
      marginBottom: '20px'
    }
  },
  pressEnterCTA: {
    width: '100%'
  },
  productivitySelect: {
    '& .Mui-disabled': {
      cursor: 'not-allowed'
    }
  },
  productivityBtn: {
    '& .Mui-disabled': {
      cursor: 'not-allowed'
    }
  },
  productiveColor: {
    color: primaryAqua
  },
  unproductiveColor: {
    color: primaryPurple
  },
  centeredImage: {
    display: 'block',
    margin: 'auto'
  },
  groupClassificationsContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '10px'
  },
  groupClassificationsCopy: {
    background: gray1,
    paddingBottom: '10px'
  },
  draggableListContainer: {
    marginTop: '10px',
    maxHeight: '150px',
    overflowY: 'scroll' as const
  },
  resetButton: {
    float: 'right'
  },
  isDisabled: {
    cursor: 'not-allowed'
  },
  cancelChangesBtn: {
    marginLeft: '35%'
  }
};

export const ClassificationsContainer = styled('div')`
  margin-left: 32px;
`;

export const RightAlignContainer = styled('div')`
  padding-left: 35px;
`;

export const AddNewCategoryButtonDiv = styled('div')`
  margin-top: 20px;
`;

export const NoResultsReturnedContainer = styled('div')`
  margin-left: 15px;
  margin-top: 15px;
`;

export const SearchBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const AdvancedFilterButtonContainer = styled('div')`
  position: relative;
  bottom: 42px;
  right: 10px;

  span {
    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;

export const NewGroupClassificationsButton = styled('div')`
  margin-top: 20px;
`;

export const ClassificationsSettingsContainer = styled(Grid)`
  & .row-disabled {
    button {
      svg {
        pointer-events: auto;
      }
    }
  }
`;

export const ClassificationsReportsPanel = styled(Card)`
  & [role='table'] {
    border: 0;
    border-radius: 0;
  }
`;

export const FormControlCheckboxSC = styled(FormControlLabel)`
  margin-bottom: 29px;
  position: absolute;
  left: 20px;

  & .MuiTypography-body1 {
    font-size: ${smallFontSize};
  }
`;

export const AdvancedFilterClearButton = styled(IconButton)`
  left: -10px;
  padding: 0 8px;
`;

export const CategoryTextField = styled(EditableTextField)`
  width: 247px !important;
`;
