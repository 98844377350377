import { IGNORE_CATEGORY_ID } from '../constants';
import { ActivityType, ClassificationType, ProductivityStatus } from '../enums';
import {
  IActivity,
  IClassificationActivityDto,
  IClassificationActivity,
  IActivityDto,
  ICategory,
  ICategoryDto
} from '../models';

export function getActivityUniqueId(id: number, type: ActivityType): string {
    if (!id || !type) return '';
  return `${id}-${type.toLowerCase()}`;
}

export function mapToClassificationActivity(
  classificationActivityDto: IClassificationActivityDto
): IClassificationActivity {
  const type = mapToActivityType(classificationActivityDto.type);
  return {
    id: classificationActivityDto.id,
    uniqueId: getActivityUniqueId(classificationActivityDto.id, type),
    name: classificationActivityDto.name,
    type: type,
    productivity: classificationActivityDto.productivity,
    productivityName: mapToProductivityName(
      classificationActivityDto.productivity
    ),
    categoryId: classificationActivityDto.categoryId,
    duration: classificationActivityDto.duration,
    status: classificationActivityDto.status,
    statusDisplay: mapToActivityStatus(classificationActivityDto),
    groupOverride: classificationActivityDto.groupOverride,
    localCategoryId: classificationActivityDto.localCategoryId,
    localProductivity: classificationActivityDto.localProductivity,
    defaultCategoryId: classificationActivityDto.defaultCategoryId,
    defaultProductivity: classificationActivityDto.defaultProductivity,
    selected: false,
    disabled: false,
    description: classificationActivityDto.description,
    executable: classificationActivityDto.executable,
    categoryName: classificationActivityDto.categoryName
  };
}

export function mapToActivity(activityDto: IActivityDto): IActivity {
  const type = mapToActivityType(activityDto.type);

  return {
    id: activityDto.id,
    uniqueId: getActivityUniqueId(activityDto.id, type),
    name: activityDto.name,
    type: type,
    description: activityDto.description,
    executable: activityDto.executable
  };
}

export function mapToActivityType(type: string): ActivityType {
  switch (type) {
    case 'Website':
      return ActivityType.Website;
    case 'App':
      return ActivityType.Application;
    default:
      return ActivityType.Unknown;
  }
}

export function mapToProductivityName(type: number): string {
  switch (type) {
    case ProductivityStatus.Productive:
      return 'Productive';
    case ProductivityStatus.Unproductive:
      return 'Unproductive';
    default:
      return '';
  }
}

export function mapToActivityStatus(
  item: IClassificationActivityDto
): ClassificationType {
  if (isIgnoreCategoryId(item.categoryId)) {
    return ClassificationType.Ignored;
  } else if (!item.categoryId && !item.productivity) {
    return ClassificationType.Pending;
  } else if (!item.categoryId) {
    return ClassificationType.PendingCategorization;
  } else if (!item.productivity) {
    return ClassificationType.PendingProductivity;
  } else if (
    item?.defaultCategoryId === item.categoryId &&
    !item.localCategoryId &&
    item?.defaultProductivity === item.productivity &&
    !item.localProductivity
  ) {
    return ClassificationType.AutoClassified;
  } else {
    return ClassificationType.ManuallyClassified;
  }
}

export const withCategoryNames = (
  activities: IClassificationActivity[],
  categories: ICategory[]
): IClassificationActivity[] => {
  if (categories?.length > 0 && activities?.length > 0) {
    return activities.map((activity) => {
      const name =
        categories.find((c) => c.id === activity.categoryId)?.name ?? '';
      return { ...activity, categoryName: name };
    });
  }
  return activities;
};

export function mapToCategory(categoryDto: ICategoryDto): ICategory {
  return {
    id: categoryDto.id,
    name: categoryDto.name,
    appCount: categoryDto.appCount,
    websiteCount: categoryDto.websiteCount,
    selected: false,
    disabled: false
  };
}

export const isIgnoreCategoryId = (categoryId) => {
  return categoryId === IGNORE_CATEGORY_ID;
};
