import * as React from 'react';
import {
  Step,
  StepLabel,
  Stepper,
  Box,
  Typography,
  Button
} from '@mui/material';
import { WizardContentsContainer, wizardClasses } from './Wizard.styles';
import { useWizardContext } from './WizardProvider';
import Link from '../Link';

export type validateStepsOptions = 'isDisabled' | 'skipped' | 'modal';

export type WizardProps = {
  children: React.ReactNode[];
  stepsLabels?: string[];
  steps: number;
  skippableSteps?: number[];
  formWizard: boolean;
  buttonLabels?: {
    previousStep?: string;
    nextStep?: string;
    finishStep?: string;
    altLabel?: string;
  };
  stepValidator?: (step, caseType: validateStepsOptions) => any;
  addSkipButton?: boolean;
  modal?: { trigger: number };
  cancelBtn?: {
    trigger?: number;
    label?: string;
    redirect: string;
    color: 'wizardPrimaryColor' | 'wizardSecondaryColor';
    onClick?: (event: any) => void;
  };
  wizardName: string; // recommend use UUID attached to name as this will be used to identify UI elements. E.g. for heap events.
  styleOverrides?: { maxWidth?: string; centered?: boolean };
};

export const Wizard = (props: WizardProps) => {
  const {
    children,
    stepsLabels,
    steps,
    skippableSteps,
    buttonLabels,
    formWizard,
    stepValidator,
    addSkipButton,
    modal,
    cancelBtn,
    wizardName,
    styleOverrides
  } = props;
  const { activeStep, modalState, hideNavigation, setActiveStep } =
    useWizardContext();
  const [skipped, setSkipped] = React.useState<Set<number>>(new Set<number>());
  const componentHandoff = children[children.length - 1];
  const stepContents = children.slice(0, -1);

  const cancelBtnTrigger = cancelBtn
    ? cancelBtn.trigger
      ? cancelBtn.trigger
      : 0
    : null;

  if (steps !== stepContents.length) {
    throw new Error('Unable to Render Wizard! (Configuration Error)');
  }

  if (formWizard && !stepValidator) {
    throw new Error('Wizard Form Configuration Error!');
  }

  const optionalSteps = React.useMemo(
    () => skippableSteps || [],
    [skippableSteps]
  );

  const skipButton = addSkipButton ?? true;

  const labels = stepsLabels || new Array(steps).fill('');

  const isStepOptional = (step: number) => {
    return optionalSteps.includes(step);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (modal?.trigger === activeStep) {
      return stepValidator(activeStep, 'modal');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    const isRequired = !isStepOptional(activeStep);
    if (isRequired) {
      console.error("You can't skip required steps");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });

    if (!isRequired && formWizard) {
      return stepValidator(activeStep, 'skipped');
    }
  };

  const isDisabled = React.useMemo(() => {
    if (formWizard && !optionalSteps.includes(activeStep)) {
      return stepValidator(activeStep, 'isDisabled');
    }

    return false;
  }, [formWizard, optionalSteps, activeStep, stepValidator]);

  return (
    <Box
      style={{
        maxWidth: `${styleOverrides?.maxWidth || 'none'}`,
        margin: `${styleOverrides?.centered ? '0 auto' : 'initial'}`
      }}
    >
      {activeStep !== labels.length && !modalState && (
        <Stepper activeStep={activeStep} sx={{ ...wizardClasses.stepper }}>
          {labels.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">
                  <span style={{ color: 'lightgray' }}>Optional</span>
                </Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps} sx={{ ...wizardClasses.stepLabel }}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep === labels.length ? (
        <>{componentHandoff}</>
      ) : (
        <>
          <WizardContentsContainer>
            {stepContents[activeStep]}
          </WizardContentsContainer>
          {!modalState && !hideNavigation && (
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingRight: '10px'
                }}
              >
                {activeStep > 0 && (
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      mr: 1,
                      ...wizardClasses.wizardBtn,
                      ...wizardClasses.wizardSecondaryColor
                    }}
                    id={`click-back-step-${activeStep + 1}-${wizardName}`}
                  >
                    {buttonLabels?.previousStep || 'Back'}
                  </Button>
                )}
                <Box style={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && skipButton && (
                  <Button
                    onClick={handleSkip}
                    color="secondary"
                    variant="contained"
                    sx={{
                      mr: 1,
                      ...wizardClasses.wizardBtn,
                      ...wizardClasses.wizardPrimaryColor
                    }}
                  >
                    Skip
                  </Button>
                )}{' '}
                &nbsp;
                <Button
                  onClick={handleNext}
                  disabled={isDisabled}
                  color="primary"
                  variant="contained"
                  sx={{
                    ...wizardClasses.wizardBtn,
                    ...wizardClasses.wizardPrimaryColor
                  }}
                  id={`click-forward-step-${activeStep + 1}-${wizardName}`}
                >
                  {activeStep === labels.length - 1
                    ? buttonLabels?.finishStep || 'Done'
                    : buttonLabels?.nextStep || 'Next'}
                </Button>
              </Box>
              {cancelBtn && activeStep === cancelBtnTrigger && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    margin: '10px'
                  }}
                >
                  <Link
                    to={cancelBtn.redirect}
                    onClick={cancelBtn.onClick}
                    style={{ paddingRight: '0px' }}
                    sx={{
                      ...wizardClasses.wizardBtn,
                      ...wizardClasses[`${cancelBtn.color}`]
                    }}
                    id={`click-cancel-step-${activeStep + 1}-${wizardName}`}
                  >
                    {cancelBtn?.label || 'Cancel'}
                  </Link>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
