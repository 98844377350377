import React from 'react';
import { notifError } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const MonitorIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 37;
  const width = props.width ?? 36;
  const title = props.title ?? 'Monitor';
  const className = props.className;
  const fillColor = props.fillColor ?? notifError;

  let classList = 'at-icon at-icon-monitor';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 37`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M33.1241 17.343C29.7913 10.3223 24.7534 6.78906 17.9999 6.78906C11.2429 6.78906 6.20849 10.3223 2.87568 17.3465C2.742 17.6296 2.67267 17.9387 2.67267 18.2518C2.67267 18.5648 2.742 18.874 2.87568 19.157C6.20849 26.1777 11.2464 29.7109 17.9999 29.7109C24.7569 29.7109 29.7913 26.1777 33.1241 19.1535C33.3948 18.584 33.3948 17.923 33.1241 17.343V17.343ZM17.9999 27.1797C12.3292 27.1797 8.17724 24.3039 5.24873 18.25C8.17724 12.1961 12.3292 9.32031 17.9999 9.32031C23.6706 9.32031 27.8226 12.1961 30.7511 18.25C27.8261 24.3039 23.6741 27.1797 17.9999 27.1797ZM17.8593 12.0625C14.4421 12.0625 11.6718 14.8328 11.6718 18.25C11.6718 21.6672 14.4421 24.4375 17.8593 24.4375C21.2765 24.4375 24.0468 21.6672 24.0468 18.25C24.0468 14.8328 21.2765 12.0625 17.8593 12.0625ZM17.8593 22.1875C15.6831 22.1875 13.9218 20.4262 13.9218 18.25C13.9218 16.0738 15.6831 14.3125 17.8593 14.3125C20.0354 14.3125 21.7968 16.0738 21.7968 18.25C21.7968 20.4262 20.0354 22.1875 17.8593 22.1875Z"
        fill={fillColor}
      />
    </svg>
  );
};
