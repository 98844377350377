var httpService = require('_app/http').http;

const delay = 30000;

const startAgentActivationMonitor = async (url) => {
  const result = await httpService.get(url);

  var agents = result?.data?.usedLicenses;

  if (agents > 0) {
    window.location.href = '/';
    return;
  }

  setTimeout(() => {
    startAgentActivationMonitor(url);
  }, delay);
}

export default startAgentActivationMonitor;