import { styled } from '@mui/material/styles';
import {
  mediumFontWeight,
  heading6FontSize,
  primaryFontSize
} from '../../common/constants';

export const OSVersionContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;

  svg {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const ParamContainerBold = styled('div')`
  margin: 10px 0px;
  font-weight: ${mediumFontWeight} !important;
`;

export const ParamTitle = styled('span')`
  font-weight: ${mediumFontWeight} !important;
`;
export const AgentHealthTitle = styled('span')`
  padding-top: 5px;
  font-weight: ${mediumFontWeight} !important;
  font-size: ${heading6FontSize} !important;
`;
export const AgentSystemChecks = styled('div')`
  width: 50%;
  align-items: center;
`;

export const UserSystemChecks = styled('div')`
  width: 50%;
  align-items: center;
  ul {
    li {
      ul {
        padding-left: 5px;
        li {
          padding-left: 10px;
        }
      }
    }
  }
`;

export const ChecksContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  ul {
    padding-left: 5px;
    li {
      list-style-type: none;
      padding-left: 5px;
    }
  }
  svg {
    padding-top: 5px;
  }
  min-height: 250px;
`;

export const CommandsContainer = styled('div')`
  width: 100%;
  align-items: center;
  min-height: 250px;
`;

export const AgentHealthModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
`;
export const HeaderLeft = styled('div')`
  margin: 10px 0px;
`;
export const HeaderRight = styled('div')`
  margin: 10px 10px;
  .MuiCheckbox-root {
    padding-right: 0px;
  }
`;

export const HealthStatusCircle = styled('span')`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  display: inline-block;
  background-color: ${(props) => props?.color || 'purple'};
`;

export const agentHealthStyles = {
  ModalContainer: {
    maxWidth: '100%'
  },
  uploadFileProgressContainer: {
    height: '6px',
    width: '100%',
    borderRadius: '3px',
    background: '#ffffff',
    padding: '20px 0px 10px'
  },
  tableContainer: {
    padding: '10px 0 10px 0'
  },
  cellSource: {
    fontWeight: '350' as any
  },
  cellDest: {},
  table: {},
  tableHeader: {
    fontSize: primaryFontSize as any,
    fontWeight: 'normal'
  },
  mergeTimingLabel: {
    fontSize: '13px',
    paddingTop: '10px'
  },
  cellSourceIndent: {
    fontWeight: '350' as any,
    paddingLeft: '30px'
  },
  EmptyCell: {
    textAlign: 'center',
    padding: '15px 0 15px 0'
  }
};
