import React, { useState, useEffect, useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid } from '@mui/material';
import { nextActivityButton, previousActivityButton } from '../constants';
import { ActivityNavigationFooterProps } from '../models';
import { ActivityNavigationDirection } from '../enums';
import { classificationClasses } from '../styles/Classifications.styles';
import { useClassificationStores } from '../services';
import { IClassificationActivity } from '../../common/models';
import { findIndex } from 'lodash';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

export default function ActivityNavigationFooter(
  props: ActivityNavigationFooterProps
) {
  const { currentActivity, categories } = props;

  const { classificationStore } = useClassificationStores();
  const { activitiesState, getRecentActivities } = classificationStore;
  const [activities, setActivities] = useState<IClassificationActivity[]>(
    activitiesState.activities
  );
  const history = useUIRouterHistory();

  useEffect(() => {
    const get = async () => {
      if (!categories?.length || !currentActivity) {
        return;
      }
      if (activities?.length) {
        //if activities are pre-loaded, but the current activity does not exist in that array, add it to the front of the array and start the prev/next flow from there
        const currActivity = activities.filter(
          (activity: IClassificationActivity) =>
            activity.type === currentActivity.type &&
            activity.id === currentActivity.id
        )[0];
        if (!currActivity) {
          const tmpActivities = [...activities];
          tmpActivities.unshift(currentActivity);
          setActivities(tmpActivities);
        }
      } else {
        //if there are no currentActivities, get ONLY the first 20 Pending ones to save time
        //this also populates the Store data to avoid this call again during prev/next flow
        const activs = await getRecentActivities(categories, {
          classified: false,
          limit: 20
        });

        //if there are now 20 Pending ones, set the currentActivity using this array
        if (activs?.length) {
          //BUT flag to not use this local data when going back to the Macro page because user should see all recent activities

          const tmpActivities = [...activs];
          const currActiv = tmpActivities.filter(
            (activity: IClassificationActivity) =>
              activity.type === currentActivity.type &&
              activity.id === currentActivity.id
          )[0];
          if (!currActiv) {
            //the currentActivity does not exist in the new array, so add it to the front of the array and start the prev/next flow from there
            tmpActivities.unshift(currentActivity);
          }
          //set currentActivities with the new array
          setActivities(tmpActivities);
        }
      }
    };
    get().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivity, categories]);

  const index = useMemo(() => {
    return findIndex(
      activities,
      (a) => currentActivity?.id === a.id && currentActivity.type === a.type
    );
  }, [currentActivity, activities]);

  const handleFooterNavigationClick = (
    direction: ActivityNavigationDirection
  ) => {
    let newIndex: number;

    if (direction === ActivityNavigationDirection.Previous) {
      newIndex = index - 1;
    }
    if (direction === ActivityNavigationDirection.Next) {
      newIndex = index + 1;
    }

    if (newIndex > -1) {
      const newActivity = activities[newIndex];
      history.push('app.settings.classification.id', {
        type: newActivity.type,
        id: newActivity.id
      });
    }
  };

  const hasPreviousActivity = currentActivity && index != 0;
  const hasNextActivity = currentActivity && index != activities?.length - 1;

  return !currentActivity || !activities?.length ? (
    <></>
  ) : (
    <Grid
      container
      data-testid="activity-navigation-footer"
      sx={classificationClasses.footerContainer}
    >
      <Grid item xs={3}>
        {hasPreviousActivity ? (
          <Button
            disableRipple
            variant="contained"
            onClick={() =>
              handleFooterNavigationClick(ActivityNavigationDirection.Previous)
            }
            sx={classificationClasses.leftNavigationButton}
            startIcon={<ArrowBackIcon />}
          >
            {previousActivityButton}
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        {hasNextActivity ? (
          <Button
            disableRipple
            variant="contained"
            onClick={() =>
              handleFooterNavigationClick(ActivityNavigationDirection.Next)
            }
            sx={classificationClasses.rightNavigationButton}
            endIcon={<ArrowForwardIcon />}
          >
            {nextActivityButton}
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}
