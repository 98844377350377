import React, { useEffect, useMemo, useState } from 'react';
import { FormErrorProps, ITicketCommentPayload } from '../models';
import { LinearWithLabel } from '../../common/components/Progress/LinearWithLabel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField
} from '@mui/material';
import { defaultCommentState } from '../constants';
import { UploadAttachments } from './UploadAttachments';

type CreateCommentModalProps = {
  ticketId: number;
  isOpen: boolean;
  percentUploaded: number;
  onClose: () => void;
  onSubmit: (id: number, payload: ITicketCommentPayload) => void;
};

export const CreateCommentModal = (props: CreateCommentModalProps) => {
  const { ticketId, onSubmit, onClose, isOpen, percentUploaded } = props;

  const [formData, setFormData] =
    useState<ITicketCommentPayload>(defaultCommentState);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<FormErrorProps>({
    body: null
  });

  //show the progress bar while saving the comment and close modal when complete
  useEffect(() => {
    if (percentUploaded === 100) onClose();
  }, [onClose, percentUploaded]);

  const additionalInstructions = useMemo(() => {
    return (
      <Typography sx={{ marginTop: '10px' }}>
        <em>
          Only 1 file with a maximum size of 7MB can be uploaded per comment
        </em>
      </Typography>
    );
  }, []);

  const checkFormStatus = () => {
    if (formData.body) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const handleChange = (event) => {
    const name = event.target?.name;
    const value = event.target?.value;

    if (value === '') {
      setErrors((prevState) => ({
        ...prevState,
        ...{ [name]: 'This field is required' }
      }));
      setFormData((prevState) => ({
        ...prevState,
        ...{ [name]: '' }
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        ...{ [name]: null }
      }));
      setFormData((prevState) => ({
        ...prevState,
        ...{ [name]: value }
      }));
    }

    checkFormStatus();
  };

  const handleSubmit = () => {
    const finalData = { ...formData, attachments: currentAttachments[0] };
    setIsSaveDisabled(true);
    onSubmit(ticketId, finalData);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
      aria-labelledby="create-comment-title"
      aria-describedby="create-comment-description"
    >
      <DialogTitle id="create-comment-title">Add a comment</DialogTitle>
      <DialogContent id="create-comment-description">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="body"
              label="New Comment"
              value={formData.body}
              multiline
              maxRows={4}
              onChange={(e) => handleChange(e)}
              error={Boolean(errors.body)}
              helperText={errors.body}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <UploadAttachments
              onAttach={setCurrentAttachments}
              additionalInstructions={additionalInstructions}
            />
          </Grid>
          {percentUploaded > 0 && (
            <Grid item xs={12}>
              <LinearWithLabel value={percentUploaded} />{' '}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSaveDisabled}
          variant="contained"
          color="primary"
        >
          Add comment
        </Button>
      </DialogActions>
    </Dialog>
  );
};
