import { useCallback, useState } from 'react';
import {
  IProductivityGoalDto,
  IProductivityGoalState,
  IReportModalListItem,
  ProductivityGoalLabel
} from '../models';
import { mapProductivityTeam } from '../utils';
import { fetchReportingData } from '../../common/helpers';
import { ApiRoutes } from '../constants';

export const useProductivityGoalState = (): IProductivityGoalState => {
  const [goalData, setGoalData] = useState<IProductivityGoalDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [goalTeams, setGoalTeams] = useState<IReportModalListItem[]>();

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchReportingData<IProductivityGoalDto>({
        path: ApiRoutes.reports.fetchProductivityGoal
      });

      if (response.teams?.length) {
        let selectedStatus: ProductivityGoalLabel;
        const goalTeams = mapProductivityTeam(response.teams, selectedStatus);
        setGoalTeams(goalTeams);
      }

      setGoalData(response);
    } catch (error) {
      console.error(`ActivTrak Error: Productivity Goal Tile: ${error}`, error);
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    init,
    goalData,
    isLoading,
    isTileError,
    goalTeams
  };
};
