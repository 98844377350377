import { quotes } from '../constants';

export const getRandomQuote = (dashboard) => {
    const currentQuotes = quotes[dashboard] || quotes.default;
    if (currentQuotes)
      return currentQuotes[Math.floor(Math.random() * currentQuotes.length)];
};

export const formatQuote = (quote: string, prepend: string, append: string) => {
  let tmp = quote;
  if (prepend) {
    tmp = `${prepend}${tmp}`;
  }
  if (append) {
    tmp = `${tmp}${append}`;
  }

  return tmp;
};
