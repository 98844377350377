import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper'; // defaults for root of SVG
import { SvgIconProps } from './SvgIconProps'; //review these for explanation

export const NewIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props?.height ?? '24px'; //only change if different from default
  const width = props?.width ?? '24px'; //only change if different from default
  const viewBox = props.viewBox ?? '0 0 38 20'; //only change if different from default
  const title = props.title ?? '';
  const className = props.className;

  let classList = 'at-icon-expiring';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      //NOTE: fillcolor should not be overidden on multi-color images
      fillColor="none"
    >
      <rect width="38" height="20" rx="10" fill="#1976D2" />
      <path
        d="M14.1348 5.46875V14H12.6641L8.83789 7.88867V14H7.36719V5.46875H8.83789L12.6758 11.5918V5.46875H14.1348ZM18.681 14.1172C18.2123 14.1172 17.7884 14.041 17.4095 13.8887C17.0345 13.7324 16.7142 13.5156 16.4486 13.2383C16.1869 12.9609 15.9857 12.6348 15.8451 12.2598C15.7045 11.8848 15.6341 11.4805 15.6341 11.0469V10.8125C15.6341 10.3164 15.7064 9.86719 15.8509 9.46484C15.9955 9.0625 16.1966 8.71875 16.4545 8.43359C16.7123 8.14453 17.017 7.92383 17.3685 7.77148C17.7201 7.61914 18.1009 7.54297 18.5111 7.54297C18.9642 7.54297 19.3607 7.61914 19.7005 7.77148C20.0404 7.92383 20.3216 8.13867 20.5443 8.41602C20.7709 8.68945 20.9388 9.01562 21.0482 9.39453C21.1615 9.77344 21.2181 10.1914 21.2181 10.6484V11.252H16.3197V10.2383H19.8236V10.127C19.8158 9.87305 19.765 9.63477 19.6713 9.41211C19.5814 9.18945 19.4427 9.00977 19.2552 8.87305C19.0677 8.73633 18.8177 8.66797 18.5052 8.66797C18.2709 8.66797 18.0619 8.71875 17.8783 8.82031C17.6986 8.91797 17.5482 9.06055 17.4271 9.24805C17.306 9.43555 17.2123 9.66211 17.1459 9.92773C17.0834 10.1895 17.0521 10.4844 17.0521 10.8125V11.0469C17.0521 11.3242 17.0892 11.582 17.1634 11.8203C17.2416 12.0547 17.3548 12.2598 17.5033 12.4355C17.6517 12.6113 17.8314 12.75 18.0423 12.8516C18.2533 12.9492 18.4935 12.998 18.763 12.998C19.1029 12.998 19.4056 12.9297 19.6713 12.793C19.9369 12.6562 20.1673 12.4629 20.3627 12.2129L21.1068 12.9336C20.9701 13.1328 20.7923 13.3242 20.5736 13.5078C20.3548 13.6875 20.0873 13.834 19.7709 13.9473C19.4584 14.0605 19.0951 14.1172 18.681 14.1172ZM24.1823 12.5938L25.6472 7.66016H26.5495L26.3034 9.13672L24.8269 14H24.0183L24.1823 12.5938ZM23.321 7.66016L24.4636 12.6172L24.5573 14H23.655L21.9382 7.66016H23.321ZM27.9206 12.5586L29.028 7.66016H30.405L28.6941 14H27.7917L27.9206 12.5586ZM26.7019 7.66016L28.1491 12.5352L28.3308 14H27.5222L26.028 9.13086L25.782 7.66016H26.7019Z"
        fill="white"
      />
    </SvgIconWrapper>
  );
};
