'use strict';

angular
    .module('app')
    .controller('updatePasswordModalController', UpdatePasswordModalController);

UpdatePasswordModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'messagesService',
    'accessApiService',
    'notificationService',
    'loginService'
];

function UpdatePasswordModalController(
    $scope,
    $uibModalInstance,
    msg,
    accessApiService,
    notificationService,
    loginService
) {
    $scope.payload = {
        current: '',
        new: ''
    };
    $scope.confirmPassword = '';

    $scope.delete = function () {
        $uibModalInstance.close();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.submitChangePassword = function () {
        $scope.showPasswordErrorMessage = false;
        if ($scope.payload.current.trim() === $scope.payload.new.trim()) {
            $scope.showPasswordErrorMessage = true;
            return;
        }

        if ($scope.changePasswordForm.$invalid) {
            return;
        }

        accessApiService
            .changePassword($scope.payload)
            .then(function () {
                $scope.dismiss();
                notificationService.showNotification(
                    msg.get('passwordChanged'),
                    'success'
                );
            })
            .catch(function (result) {
                $scope.dismiss();
                if (
                    result.data &&
                    result.data.code &&
                    _.includes(result.data.code, 'lockout')
                ) {
                    loginService.logout({
                        showLockoutError: true
                    });
                } else {
                    notificationService.showNotification(
                        result.data.error,
                        'danger'
                    );
                }
            });
    };
}
