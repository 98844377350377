import React from 'react';
import { Box } from '@mui/material';
import { mediumFontWeight } from '../../common/constants';

const classes = {
  total: {
    fontWeight: mediumFontWeight
  },
  container: {
    display: 'inline'
  }
};

type TotalGroupProps = {
  count: number;
};

export default function TotalGroups(props: TotalGroupProps) {
  return (
    <Box sx={classes.container}>
      <span style={classes.total}>TOTAL GROUPS:</span> {props.count}
    </Box>
  );
}
