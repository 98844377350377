export const formatTotalStorage = (totalStorage: number): string => {
  const numOfMegabytesInTerabyte = 1000000;
  const numOfBytesInGigabyte = 1000;
  if (totalStorage > numOfMegabytesInTerabyte) {
    return `${totalStorage / numOfMegabytesInTerabyte} TB`;
  } else if (
    totalStorage > numOfBytesInGigabyte &&
    totalStorage < numOfMegabytesInTerabyte
  ) {
    return `${totalStorage / numOfBytesInGigabyte} GB`;
  } else {
    return `${totalStorage} MB`;
  }
};
