import { IClientOAuthConfig } from '../models/IClientOAuthConfig';
import { IntegrationCodes } from './IntegrationCodes';

export const OAUTH_RESPONSE_TYPE = 'code';
export const OAUTH_ACCESS_TYPE = 'offline';
export const OAUTH_PROMPT = 'consent';
export const OAUTH_POPUP_HEIGHT = 900;
export const OAUTH_POPUP_WIDTH = 960;
export const OAUTH_PROCESSED_STATE = 'activTrak.integration-:instanceId';

export const clientOAuthConfig = {
  [IntegrationCodes.AzureAD]: {
    clientId: import.meta.env.VITE_OAUTH_AZURE_AD_CLIENT_ID,
    redirectUri: import.meta.env.VITE_OAUTH_AZURE_AD_REDIRECT_URI,
    authorizeUrl:
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    scope: 'Group.Read.All User.Read Directory.Read.All offline_access'
  } as IClientOAuthConfig,
  [IntegrationCodes.GoogleCalendar]: {
    clientId: import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID,
    redirectUri: import.meta.env.VITE_OAUTH_GOOGLE_REDIRECT_URI,
    authorizeUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    scope:
      'https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
  } as IClientOAuthConfig
};
