import { ProductivityOptionFilterProps } from '../models/IProductivityFilter';
import { productivityColors } from '../../../constants';
import { ProductivityIconProps } from '../models/IProductivityFilter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HelpIcon from '@mui/icons-material/Help';

export enum ProductivityName {
  All = 'All',
  Productive = 'Productive',
  Unproductive = 'Unproductive',
  Undefined = 'Undefined'
}

export const productivityStatuses: ProductivityOptionFilterProps[] = [
  {
    label: 'All Classifications',
    value: ProductivityName.All
  },
  {
    label: 'Productive',
    value: ProductivityName.Productive
  },
  {
    label: 'Unproductive',
    value: ProductivityName.Unproductive
  },
  {
    label: 'Undefined',
    value: ProductivityName.Undefined
  }
];

export const ProductivityIcons: ProductivityIconProps = {
  [ProductivityName.All]: { icon: HelpIcon, color: 'transparent' },
  [ProductivityName.Productive]: {
    icon: CheckCircleIcon,
    color: productivityColors.productive
  },
  [ProductivityName.Unproductive]: {
    icon: RemoveCircleIcon,
    color: productivityColors.unproductive
  },
  [ProductivityName.Undefined]: {
    icon: HelpIcon,
    color: productivityColors.undefined
  }
};
