import React from 'react';
import { AgentDownloadService } from '../../common/services';
import { useNotifications } from '../../common/services/Notifications';
import { getFutureExpireDate } from '../../common/helpers';
import { DefaultWidgetProps } from '../models';
import { MINUTES_IN_72_HOURS } from '../../common/constants/minutes';
import { useAgentInstallSettingsState } from '../hooks';
import { OperationalSystemsTypeShort } from '../../common/enums';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Switch,
  Tooltip,
  Typography,
  TextField
} from '@mui/material';

export const AgentInstallCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const service = AgentDownloadService();
  const notificationService = useNotifications();

  const {
    winLink,
    macLink,
    linkLoading,
    nonAdminDirectDownloadSetting,
    getWinAgentLink,
    getMacAgentLink,
    updateNonAdminDirectDownloadSetting
  } = useAgentInstallSettingsState({ service, notificationService });

  const handleDirectDownloadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCheckedValue = event.target.checked;
    updateNonAdminDirectDownloadSetting(newCheckedValue);
  };

  return (
    <ConfigurationCardWrapper title="Agent Install">
      <Box sx={{ marginBottom: '24px' }}>
        <Typography sx={{ marginBottom: '16px' }}>
          Generate a secure link to share agent installer packages to allow
          employees to install directly on their computers. These links allow
          for a fast and easy way to share the latest agent versions associated
          with your account and ensure a clean deployment.
        </Typography>
        <Typography>
          Please note these links expire 72 hours after creation.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography>
            <span>
              Create Secure Link for Windows Agent
              <Tooltip
                title="The name of ActivTrak's Windows Agent installer cannot be
              modified. Modifying the name of this installer will prevent the
              agent from being properly installed on any computer. For
              assistance, please contact Support."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </span>
          </Typography>
          {winLink && (
            <Typography color="error">
              Expires: {getFutureExpireDate(MINUTES_IN_72_HOURS)}
            </Typography>
          )}
        </Box>
        <TextField
          fullWidth
          size="small"
          value={!winLink ? '' : winLink}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  style={{ marginRight: '-14px' }}
                  variant="contained"
                  onClick={getWinAgentLink}
                  disableElevation
                  disabled={isSupport}
                >
                  {linkLoading === OperationalSystemsTypeShort.win
                    ? 'Loading'
                    : winLink
                    ? 'Copy'
                    : 'Create'}
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography>
            <span>
              Create Secure Link for Mac Agent
              <Tooltip
                title="The name of ActivTrak's Mac Agent installer cannot be modified.
              Modifying the name of this installer will prevent the agent from
              being properly installed on any computer. For assistance, please
              contact Support."
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </span>
          </Typography>
          {macLink && (
            <Typography color="error">
              Expires: {getFutureExpireDate(MINUTES_IN_72_HOURS)}
            </Typography>
          )}
        </Box>
        <TextField
          fullWidth
          size="small"
          value={!macLink ? '' : macLink}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  style={{ marginRight: '-14px' }}
                  variant="contained"
                  disableElevation
                  onClick={getMacAgentLink}
                  disabled={isSupport}
                >
                  {linkLoading === OperationalSystemsTypeShort.mac
                    ? 'Loading'
                    : macLink
                    ? 'Copy'
                    : 'Create'}
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={nonAdminDirectDownloadSetting}
              disabled={isSupport || linkLoading !== ''}
              onChange={handleDirectDownloadChange}
            />
          }
          label={
            <Typography>
              Allow non-admins to directly download the agent
            </Typography>
          }
        />
      </FormGroup>
    </ConfigurationCardWrapper>
  );
};
