import React from 'react';
import { IGroupFilter, IGroupFilterDto } from '../../../models';
import { IAutocompleteSuggestion } from '../../Autocomplete/IAutocomplete';
import { GroupMix, GroupTypeBasic } from '../../../enums';
import { GroupIconSource } from '../../../assets/Icons';

export const mapGroupMix = (mode: number): GroupMix => {
  switch (mode) {
    case 0:
      return GroupMix.None;
    case 1:
      return GroupMix.User;
    case 2:
      return GroupMix.Computer;
    default:
      return GroupMix.Mixed;
  }
};

export const mapGroupType = (type: number): GroupTypeBasic => {
  switch (type) {
    case 0:
      return GroupTypeBasic.ActiveDirectory;
    case 1:
      return GroupTypeBasic.ActivTrak;
    case 2:
      return GroupTypeBasic.AzureAD;
    case 3:
    case 4:
      return GroupTypeBasic.HRDC;
    default:
      return GroupTypeBasic.None;
  }
};

// KEEPING THIS FUNCTION HERE IN CASE PRODUCT DECIDES TO SHOW NON-AT ICONS AS WELL
// const getGroupIcon = (group: IGroupFilter) => {
//   const mixType = group?.mix;
//   const groupType = group?.type;

//   if (groupType !== GroupTypeBasic.ActivTrak) return groupType;
//   return mixType;
// };

export const mapToTeamFilter = (item: IGroupFilterDto): IGroupFilter => {
  return {
    id: item.id?.toString(),
    name: item.name,
    type: mapGroupType(item.type),
    mix: mapGroupMix(item.mode)
  };
};

export const mapToTeamSuggestion = (
  item: IGroupFilter
): IAutocompleteSuggestion => {
  return {
    label: item.name,
    value: item.id?.toString(),
    icon: <GroupIconSource iconSource={item?.mix} />,
    id: `26ed4e71-f823-4c01-bad4-213ee274646d-${item.name?.replaceAll(
      ' ',
      '-'
    )}`
  };
};
