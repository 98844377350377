import React from 'react';
import InfoTip from '../../InfoTip/InfoTip';
import { GroupIconSource } from '../../../../../_reactivtrak/src/common/assets/Icons';

const TeamPulseGroupIcon = (props) => {
    let {
      groupProperties,
      users,
      onEnter,
      onClose,
      teamPulseDataControl,
      filterMode
    } = props;

    const groupTypeIconMix = groupProperties.generateMixLabel();

    const getGroupTypeIcon = () => {
        return <GroupIconSource iconSource={groupTypeIconMix} id="id_638865d4-228e-4ac4-9e92-3c1c41fa926d" />;
    };

  const generateTitle = () => {
    return `${groupProperties.generateTypeLabel()} ${groupProperties.generateMixLabel()} Group Members`;
  };

  const handleUserClick = (user) => {
    // If on report page, set variables
    // Else navigate to report page with parameters
    if (teamPulseDataControl.isReport) {
      teamPulseDataControl.selectedCardIds = user.id.toString();
      teamPulseDataControl.reportMode = teamPulseDataControl.reportModes.users;
    } else {
      window.location.href = `/#/app/teampulse?reportMode=Users&card=${user.id}`;
    }
  };

  const generateUsers = () => {
    return users.map((user) => {

      const generateLabel = (user) => {
        if (filterMode === teamPulseDataControl.filterModes.users && user.username) {
          return user.username;
        } else if (filterMode == teamPulseDataControl.filterModes.computers && user.computerName) {
          return user.computerName;
        }
        return user.name;
      }

      const generateSubLabel = (user) => {
        if (filterMode === teamPulseDataControl.filterModes.users && user.computerName) {
          return `(${user.computerName})`;
        } else if (filterMode === teamPulseDataControl.filterModes.computers && user.username) {
          return `(${user.username})`;
        }
        return '';
      };

      return {
        heapId: 'id_ef1b4473-850b-4d66-addd-022859a7f0c6',
        label: generateLabel(user),
        subLabel: generateSubLabel(user),
        onClick: () => handleUserClick(user)
      }
    });
  };

  const infoTipContent = [
    {
      label: generateTitle(),
      subItems: generateUsers()
    }
  ];

  return (
    <div>
      <InfoTip
        onEnter={onEnter}
        onClose={onClose}
        buttonContent={getGroupTypeIcon()}
        infoTipContent={infoTipContent}
        variant="search"
      />
    </div>
  );
};

export default TeamPulseGroupIcon;
