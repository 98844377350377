import theme from '../../../common/theme/theme';

export const getClasses = (active) => {
    return {
        root: {
            borderColor: active ? theme.palette.grey.A200 : 'rgba(0,0,0,0.12)',
            marginBottom: 10
        }
    };
};
