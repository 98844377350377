import React, { useRef, useState } from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';
import { TagBar } from '../../common/components/TagBar/TagBar';
import { AdvancedSearchFilter } from '../../common/components/AdvancedFilter/AdvancedSearchFilter';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar/AutocompleteSearchBar';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton/DropdownOptionsButton';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import {
  AdvancedFilterButtonContainer,
  AdvancedFilterClearButton,
  SearchBarContainer
} from '../styles/Users.view.styles';
import ClearIcon from '@mui/icons-material/Clear';
import { IComputerAliasState } from '../models/IComputerAliasState';
import { AliasSearchProps } from '../models/AliasSearchProps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OperationalSystemsType } from '../../common/enums';

const AliasSearch = (props: AliasSearchProps<IComputerAliasState>) => {
  const { gridColumns, aliasState } = props;

  const {
    setSearchTerm,
    searchTerm,
    searchCriteria,
    setSearchCriteria,
    getFilteredComputerAliasesForAutocomplete
  } = aliasState;

  const [terms, setTerms] = useState([]);
  const [domainSearch, setDomainSearch] = useState<string>();
  const [computerSearch, setComputerSearch] = useState<string>();
  const [aliasSearch, setAliasSearch] = useState<string>();
  const [agentVersionSearch, setAgentVersionSearch] = useState<string>();
  const [osSearch, setOSSearch] = useState<string>();
  const [osOption, setOSOption] = useState(0);
  const optionsRef = useRef();

  const handleOnKeyPress = (event: any) => {
    if (searchTerm && event.key === 'Enter') {
      const updatedTerms = [...terms, searchTerm];
      setTerms(updatedTerms);
    }
    const value = event.target.value;
    const term = value && value.trim().toLowerCase();
    if (term && event.key === 'Enter') {
      setSearchTerm(term);
    } else {
      setSearchTerm('');
    }
  };

  const handleChangeAutocompleteSearchBar = (value) => {
    setSearchTerm(value);
  };

  const handleAdvancedSearchClear = () => {
    setDomainSearch(undefined);
    setComputerSearch(undefined);
    setAliasSearch(undefined);
    setAgentVersionSearch(undefined);
    setOSOption(0);
    setOSSearch(undefined);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    handleAdvancedSearchClear();
  };

  const handleDomainChange = (e) => {
    setDomainSearch(e.target.value);
  };

  const handleComputerChange = (e) => {
    setComputerSearch(e.target.value);
  };

  const handleAliasSearchChange = (e) => {
    setAliasSearch(e.target.value);
  };

  const handleAgentVersionSearchChange = (e) => {
    setAgentVersionSearch(e.target.value);
  };

  const oSOptions = [
    {
      label: '',
      template: () => (
        <div>
          <p> </p>
        </div>
      )
    },
    ...Object.keys(OperationalSystemsType).map((os) => {
      return { label: os, template: () => getOSIcon(os) };
    })
  ];

  const handleAdvancedSearch = () => {
    const tabData = [];
    if (domainSearch) {
      tabData.push({
        fieldName: 'Primary Domain',
        field: 'primaryDomain',
        fieldValue: domainSearch
      });
    }
    if (computerSearch) {
      tabData.push({
        fieldName: 'Computer Name',
        field: 'computerName',
        fieldValue: computerSearch
      });
    }
    if (aliasSearch) {
      tabData.push({
        fieldName: 'Alias',
        field: 'alias',
        fieldValue: aliasSearch
      });
    }
    if (agentVersionSearch) {
      tabData.push({
        fieldName: 'Agent Version',
        field: 'version',
        fieldValue: agentVersionSearch
      });
    }
    if (osOption > 0 && osSearch) {
      tabData.push({
        fieldName: 'Operating System',
        field: 'os',
        fieldValue: osSearch
      });
    }
    setSearchCriteria(tabData);
    handleAdvancedSearchClear();
  };

  return (
    <>
      <SearchBarContainer>
        {searchCriteria.length ? (
          <TagBar tabBarData={searchCriteria} />
        ) : (
          <AutocompleteSearchBar
            autocompleteOptions={getFilteredComputerAliasesForAutocomplete(
              gridColumns
            )}
            searchLabelPlaceholder="Search for domain, computer, or alias"
            onChange={handleChangeAutocompleteSearchBar}
            onKeyPress={handleOnKeyPress}
          />
        )}
        {searchTerm ? null : (
          <AdvancedFilterButtonContainer>
            {searchCriteria.length ? (
              <AdvancedFilterClearButton
                onClick={() => {
                  setSearchCriteria([]);
                  handleSearchClear();
                }}
              >
                <ClearIcon />
              </AdvancedFilterClearButton>
            ) : null}
            <AdvancedSearchFilter
              onSubmit={() => {
                handleAdvancedSearch();
              }}
              onClear={() => {
                setSearchCriteria([]);
                setTerms([]);
                handleAdvancedSearchClear();
              }}
              popoverWidth="420px"
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="domainAdvancedSearch"
                    style={{ marginTop: 3 }}
                  >
                    Domain
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="domainAdvancedSearch"
                    variant="standard"
                    onChange={handleDomainChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="userAdvancedSearch"
                    style={{ marginTop: 3 }}
                  >
                    Computer
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="userAdvancedSearch"
                    variant="standard"
                    onChange={handleComputerChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="userAliasSearch"
                    style={{ marginTop: 3 }}
                  >
                    Alias
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="userAliasSearch"
                    variant="standard"
                    onChange={handleAliasSearchChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="userAgentVersionSearch"
                    style={{ marginTop: 3 }}
                  >
                    Agent Version
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="userAgentVersionSearch"
                    variant="standard"
                    onChange={handleAgentVersionSearchChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="userAgentVersionSearch"
                    style={{ marginTop: 3 }}
                  >
                    Operating System
                  </InputLabel>
                </Grid>
                <Grid item xs={2}>
                  <DropdownOptionsButton
                    id="fb0b627d-86af-4d9d-b03b-b027cd2c722a"
                    ref={optionsRef}
                    icon={<ArrowDropDownIcon />}
                    rightIconPosition
                    disablePortal
                    indexOption={osOption}
                    setIndexOption={setOSOption}
                    dropDownOptions={oSOptions}
                    onChangeOption={(e, option) => {
                      if (
                        Object.values(OperationalSystemsType).includes(
                          option.label
                        )
                      ) {
                        setOSSearch(option.label);
                      } else {
                        setOSSearch(undefined);
                      }
                    }}
                    disableSelectedItem
                  />
                </Grid>
              </Grid>
            </AdvancedSearchFilter>
          </AdvancedFilterButtonContainer>
        )}
      </SearchBarContainer>
    </>
  );
};

export default AliasSearch;
