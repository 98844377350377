import { IAlarm } from './';
import { getDefaultConfig, BaseClient } from '../../config';
import { mapToAlarm } from './AlarmUtils';
import { IAlarmDto } from './IAlarmDto';

export interface IAlarmsService {
  getAllAlarms(groupId: number): Promise<IAlarm[]>;
}

export default class AlarmsService
  extends BaseClient
  implements IAlarmsService
{
  public getAllAlarms = async (groupId: number = null): Promise<IAlarm[]> => {
    const response = await this.apiService.get<IAlarmDto[]>(`/api/alarms`, {
      ...getDefaultConfig(),
      params: { groupId: groupId }
    });
    return response.data.map(mapToAlarm);
  };
}
