export const getPieChartTooltipPosition = (tooltipModel) => {
  const chart = tooltipModel.chart;
  const chartArea = chart.chartArea;
  const size = tooltipModel._size;

  let xAlign = tooltipModel.xAlign ? tooltipModel.xAlign : 'center';
  let yAlign = tooltipModel.yAlign ? tooltipModel.yAlign : 'center';

  //let's use clean numbers
  tooltipModel.x = Math.round(tooltipModel.caretX);
  tooltipModel.y = Math.round(tooltipModel.caretY);

  //calculate the left and right areas of the chart
  const midX = (chartArea.left + chartArea.right) / 2;

  // set caret position to top or bottom if tooltip y position will extend outside the chart top/bottom
  if (tooltipModel.y < size.height) {
    yAlign = 'top';
  } else if (tooltipModel.y > chart.height - size.height) {
    yAlign = 'bottom';
  }

  let getIsLeftAlign, getIsRightAlign; // functions to determine left, right alignment
  if (yAlign === 'center') {
    getIsLeftAlign = (x: number) => {
      return x >= midX;
    };
    getIsRightAlign = (x: number) => {
      return x < midX;
    };
  } else {
    getIsLeftAlign = (x: number) => {
      return x <= size.width / 2;
    };
    getIsRightAlign = (x: number) => {
      return x >= chart.width - size.width / 2;
    };
  }

  if (getIsLeftAlign(tooltipModel.x)) {
    xAlign = 'left';
  } else if (getIsRightAlign(tooltipModel.x)) {
    xAlign = 'right';
  }

  const leftPosition =
    tooltipModel.xAlign === 'right'
      ? Math.round(tooltipModel.x - size.width)
      : Math.round(tooltipModel.x);

  return {
    xAlign,
    yAlign,
    leftPosition,
    topPosition: Math.round(tooltipModel.y - 20)
  };
};

const getAdjustedTooltipPositions = (context, tooltipEl) => {
  const tooltipModel = context.tooltip;
  const position = context.chart.canvas.getBoundingClientRect();
  const tooltipPositions = getPieChartTooltipPosition(tooltipModel);
  const top: number =
    position.top + window.scrollY + tooltipPositions.topPosition;
  let left: number =
    position.left + window.scrollX + tooltipPositions.leftPosition;
  // calculate final left position based on new tooltip offset
  const adjustedLeftPosition =
    tooltipPositions.xAlign === 'right'
      ? Math.round(tooltipPositions.leftPosition - tooltipEl.offsetWidth)
      : tooltipPositions.leftPosition;
  left = position.left + window.scrollX + adjustedLeftPosition;

  const tooltipRect = tooltipEl.getBoundingClientRect();
  if (left < 0) {
    //adjust 'left' to left side of window if positioned offscreen left
    left = 0;
  } else if (tooltipRect.x + tooltipRect.width > window.outerWidth) {
    //adjust 'left' to right side of window if positioned offscreen right
    left += window.outerWidth - (tooltipRect.x + tooltipRect.width);
  }

  return { top, left };
};

//Starter code: https://stackoverflow.com/questions/43211497/change-tooltip-positioning-in-doughnut-chart-using-chart-js
export const getPieChartTooltip = (context, tooltipId) => {
  let tooltipEl = document.getElementById(tooltipId);
  const tooltipModel = context.tooltip;

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = tooltipId;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.color = tooltipModel.labelTextColors;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.zIndex = '2000';

    const adjustedTooltipPositions = getAdjustedTooltipPositions(
      context,
      tooltipEl
    );
    tooltipEl.style.left = adjustedTooltipPositions.left + 'px';
    tooltipEl.style.top = adjustedTooltipPositions.top + 'px';
    document.body.appendChild(tooltipEl);
  }

  const getBody = (bodyItem) => bodyItem.lines;

  // Set Text
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '';

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; padding: 8px 16px';
      style += '; border-radius: 4px';
      style += '; max-width: 300px';
      style += '; white-space: nowrap';
      style += '; overflow: hidden';
      style += '; text-overflow: ellipsis';
      const content = '<div style="' + style + '">' + body + '</div>';

      innerHtml += content;
    });
    tooltipEl.innerHTML = innerHtml;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.color = tooltipModel.labelTextColors;
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.zIndex = '2000';

  const adjustedTooltipPositions = getAdjustedTooltipPositions(
    context,
    tooltipEl
  );
  tooltipEl.style.left = adjustedTooltipPositions.left + 'px';
  tooltipEl.style.top = adjustedTooltipPositions.top + 'px';

  return tooltipEl;
};
