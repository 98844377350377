import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { PrivacySetting } from '../../../../../enums/PrivacySetting';
import { IRoute } from '../../../../../models/IRoute';
import { screenshotsSafeSearchTemplate as angularJsTemplate } from '../../templates';

export const appScreenshotsFlagging: IRoute = {
  name: 'app.screenshots.flagging',
  stateDefinition: {
    url: '/screenshots/flagging',
    template: angularJsTemplate,
    controller: 'ScreenshotsSafeSearchCtrl',
    data: { pageTitle: 'Screenshot Flagging' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsScreenshotsFlagging,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic],
    bundleFlags: [BundleFlag.ScreenshotFlagging],
    privacySettings: [PrivacySetting.Screenshots]
  }
};

export default appScreenshotsFlagging;
