import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export const ZeroStateIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? '16px';
  const width = props.width ?? '16px';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.543 0.371155L4.51953 31.836H1.25781L20.3203 0.371155H23.543ZM23.8945 15.0001V16.5626C23.8945 18.711 23.6146 20.6381 23.0547 22.3438C22.4948 24.0495 21.694 25.5014 20.6523 26.6993C19.6107 27.8972 18.3672 28.8152 16.9219 29.4532C15.4766 30.0782 13.875 30.3907 12.1172 30.3907C10.724 30.3907 9.42188 30.1889 8.21094 29.7852C7.01302 29.3686 5.92578 28.7761 4.94922 28.0079C3.97266 27.2266 3.13281 26.2761 2.42969 25.1563C1.73958 24.0235 1.20573 22.7409 0.828125 21.3087C0.463542 19.8633 0.28125 18.2813 0.28125 16.5626V15.0001C0.28125 12.8516 0.567708 10.9311 1.14062 9.23834C1.71354 7.53261 2.52083 6.08079 3.5625 4.88287C4.61719 3.67194 5.86068 2.75397 7.29297 2.12897C8.73828 1.49095 10.3333 1.17194 12.0781 1.17194C13.4844 1.17194 14.793 1.38027 16.0039 1.79694C17.2148 2.20058 18.3021 2.79303 19.2656 3.57428C20.2422 4.35553 21.069 5.30605 21.7461 6.42584C22.4362 7.54563 22.9635 8.82819 23.3281 10.2735C23.7057 11.7058 23.8945 13.2813 23.8945 15.0001ZM18.9727 16.5626V14.961C18.9727 13.698 18.875 12.5521 18.6797 11.5235C18.4844 10.4818 18.1914 9.57037 17.8008 8.78912C17.4232 7.99485 16.9544 7.33079 16.3945 6.79694C15.8346 6.26308 15.1901 5.85944 14.4609 5.586C13.7448 5.31256 12.9505 5.17584 12.0781 5.17584C10.9974 5.17584 10.0339 5.39069 9.1875 5.82037C8.34115 6.23704 7.61849 6.86204 7.01953 7.69537C6.43359 8.51569 5.98438 9.53782 5.67188 10.7618C5.35938 11.9727 5.20312 13.3725 5.20312 14.961V16.5626C5.20312 17.8386 5.30078 18.9975 5.49609 20.0391C5.70443 21.0678 6.00391 21.9792 6.39453 22.7735C6.78516 23.5678 7.26042 24.2383 7.82031 24.7852C8.39323 25.3191 9.03776 25.7227 9.75391 25.9962C10.4701 26.2696 11.2578 26.4063 12.1172 26.4063C13.2109 26.4063 14.181 26.198 15.0273 25.7813C15.8737 25.3516 16.5898 24.7201 17.1758 23.8868C17.7617 23.0404 18.2044 22.0053 18.5039 20.7813C18.8164 19.5574 18.9727 18.1511 18.9727 16.5626Z"
        fill={fillColor}
      />
    </svg>
  );
};
