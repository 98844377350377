import { IChromebookUser, IChromebookUserDto } from '../models';

export const mapToChromebookUser = (
  cbUsersDto: IChromebookUserDto
): IChromebookUser => {
  return {
    user: cbUsersDto.user,
    status: cbUsersDto.status,
    selected: false
  };
};
