'use strict';

angular.module('app').service('auditLogSampleService', [function() {
    function buildPromise(data) {
        return {
            success: function (callback) {
                callback(data);
                return this;
            },
            error: function () {
            }
        };
    }

    function getAlarmLogEntry(user, time, eventName, actionType, status, description, actionObject, ip) {
        return {
            user: user,
            time: time,
            eventname: eventName,
            actiontype: actionType,
            status: status,
            description: description,
            actionobject: actionObject,
            ip: ip
        };
    }

    function getData() {
        return [
            getAlarmLogEntry('mary@example.com', '2019-01-01T10:39:55', 'CreateNewUser', 'Create', 1, 'New User Successfully Created', '{"UserName":"lisa@example.com"}', '99.27.210.6'),
            getAlarmLogEntry('carl@example.com', '2019-01-01T10:53:49', 'CreateNewUser', 'Create', 1, 'New User Successfully Created', '{"UserName":"luke@example.com"}', '176.76.131.70'),
            getAlarmLogEntry('john@example.com', '2019-01-01T10:56:57', 'CreateNewUser', 'Create', 0, 'User already exists.', '{"UserName":"luke@example.com"}', '10.64.33.166'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T11:21:47', 'DeleteUsers', 'Delete', 1, 'Users Successfully Deleted', '{"Users":"matt@example.com"}', '99.27.210.6'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T12:36:07', 'DeleteUsers', 'Delete', 1, 'Users Successfully Deleted', '{"Users":"paul@example.com"}', '99.27.210.6'),
            getAlarmLogEntry('john@example.com', '2019-01-01T12:44:57', 'AddUsersToDoNotTrack', 'Update', 1, 'Users Successfully Added To DoNotTrack', '{"Users":[{"UserId":"404","Domain":"COMPUTER-4","UserName":"Admin"}]}', '10.64.33.166'),
            getAlarmLogEntry('john@example.com', '2019-01-01T13:07:38', 'AddUsersToDoNotTrack', 'Update', 1, 'Users Successfully Added To DoNotTrack', '{"Users":[{"UserId":"404","Domain":"COMPUTER-5","UserName":"Admin"}]}', '10.64.33.166'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T13:36:59', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2439"}', '99.27.210.6'),
            getAlarmLogEntry('john@example.com', '2019-01-01T13:45:18', 'DeleteAlarm', 'Delete', 1, 'Alarm Successfully Deleted', '{"AlarmId":"2439"}', '10.64.33.166'),
            getAlarmLogEntry('john@example.com', '2019-01-01T14:03:57', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2440"}', '10.64.33.166'),
            getAlarmLogEntry('carl@example.com', '2019-01-01T14:09:36', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2441"}', '176.76.131.70'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T15:48:58', 'DeleteSomeUsers', 'Delete', 1, 'Successfully Deleted Some Users', '{"UserNames":"user-337"}', '99.27.210.6'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T15:49:34', 'DeleteUsers', 'Delete', 1, 'Users Successfully Deleted', '{"Users":"paul@example.com"}', '99.27.210.6'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T15:50:42', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2442"}', '99.27.210.6'),
            getAlarmLogEntry('john@example.com', '2019-01-01T16:43:30', 'UpdateUserPrivilege', 'Update', 1, 'User Role Successfully Updated', '{"UserName":"lisa@example.com","IsAdmin":true}', '10.64.33.166'),
            getAlarmLogEntry('john@example.com', '2019-01-01T16:51:06', 'DeleteAlarm', 'Delete', 1, 'Alarm Successfully Deleted', '{"AlarmId":"2440"}', '10.64.33.166'),
            getAlarmLogEntry('carl@example.com', '2019-01-01T17:32:55', 'DeleteAllScreenShots', 'Delete', 1, 'All Screenshots Successfully Deleted', null, '176.76.131.70'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T17:39:27', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2443"}', '99.27.210.6'),
            getAlarmLogEntry('carl@example.com', '2019-01-01T17:43:33', 'UpdateUserPrivilege', 'Update', 0, 'User is already in role.', '{"UserName":"lisa@example.com","IsAdmin":true}', '176.76.131.70'),
            getAlarmLogEntry('mary@example.com', '2019-01-01T18:22:35', 'SaveAlarm', 'Create', 1, 'Alarm Successfully Saved', '{"AlarmId":"2444"}', '99.27.210.6')
        ];
    }

    this.getLog = function() {
        var data = getData();
        return buildPromise({
            data: data,
            total: data.length
        });
    };
}]);
