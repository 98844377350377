'use strict';

angular.module('app')
    .service('cookieDataService', CookieDataService);

CookieDataService.$inject = ['$cookies'];

function CookieDataService($cookies) {

    this.getUtm = function () {

        var utm = {};

        utm.source = $cookies.get('utm_source');
        utm.medium = $cookies.get('utm_medium');
        utm.term = $cookies.get('utm_term');
        utm.campaign = $cookies.get('utm_campaign');
        utm.content = $cookies.get('utm_content');
        utm.landing = $cookies.get('utm_landing');
        utm.referrer = $cookies.get('utm_referrer');
        utm.landing  = $cookies.get('utm_landing');

        utm.gclid = $cookies.get('gclid');

        $cookies.remove('gclid');

        return utm;
    };

    this.getCjEvent = function () {
        var cjEvent = $cookies.get('cjevent');
        $cookies.remove('cjevent');
        return cjEvent;
    };

    this.getEmail = function() {
        var email = $cookies.get('atk_signup');
        return email ? atob(email) : null;
    };
}
