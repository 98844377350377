import React, { useState } from 'react';
import {
  getDateTimeFormat,
  getParsedLocalStorageItem,
  getTimeAgo
} from '../../common/helpers';
import { TimeAgo } from '../../common/components/TimeAgo';
import { localStorageItems } from '../../common/constants';

import {
  DetailItem,
  DetailLabel,
  EditIconName,
  DetailItemEmployeeId,
  DetailItemAgentCount,
  DetailEditBox
} from '../styles/UserDetail.view.styles';
import { EditOrInsertUserModal } from '../components/EditOrInsertUserModal';
import { TextOverflow } from '../../common/styles/app.component.styles';
import { IAccountSettings } from '../../common/models';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { useAgentSearchState } from '../hooks/useAgentSearchState';
import { SkeletonLoader } from '../../common/components/SkeletonLoader';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

type ComponentProps = {
  identityDetailState: IIdentityDetailState;
  newUserMode: boolean;
  isReadOnly: boolean;
};

export const DetailUserInfo = (props: ComponentProps) => {
  const { identityDetailState, isReadOnly } = props;
  const { isLoadingDetail, selectedIdentity, commonState } =
    identityDetailState;
  const [openEditNameModal, setOpenEditNameModal] = useState<boolean>(false);
  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const dateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);
  const agentSearchState = useAgentSearchState();

  const onOpenEditNameModal = () => {
    agentSearchState.resetAgentsSelected();
    setOpenEditNameModal(true);
  };

  return (
    <>
      <Card elevation={3}>
        <CardContent sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          {!isLoadingDetail && (
            <>
              <DetailEditBox>
                <Button
                  onClick={onOpenEditNameModal}
                  startIcon={<EditIconName />}
                  color="secondary"
                  disabled={isReadOnly}
                >
                  Edit Info
                </Button>
              </DetailEditBox>
              <DetailLabel>Name:</DetailLabel>
              <DetailItem>
                <Tooltip
                  title={selectedIdentity?.nameColumnDisplay ?? ''}
                  placement="bottom-start"
                >
                  <TextOverflow>
                    {selectedIdentity?.nameColumnDisplay}
                  </TextOverflow>
                </Tooltip>
                <Chip
                  label={selectedIdentity?.tracked ? 'Tracked' : 'Untracked'}
                  size="small"
                  color="info"
                  sx={{
                    fontSize: '10px',
                    borderRadius: '8px',
                    color: 'white'
                  }}
                />
              </DetailItem>
              <DetailLabel>Display Name:</DetailLabel>
              <DetailItem>
                <Tooltip
                  title={selectedIdentity?.displayName?.value ?? ''}
                  placement="bottom-start"
                >
                  <TextOverflow>
                    {selectedIdentity?.displayName?.value}
                  </TextOverflow>
                </Tooltip>
              </DetailItem>
              <DetailLabel>Employee Id:</DetailLabel>
              <DetailItemEmployeeId sx={{ paddingBottom: '32px' }}>
                <Tooltip
                  title={selectedIdentity?.employeeId?.value ?? ''}
                  placement="bottom-start"
                >
                  <TextOverflow>
                    {selectedIdentity?.employeeId?.value}
                  </TextOverflow>
                </Tooltip>
              </DetailItemEmployeeId>
              <DetailItemAgentCount>
                # of Agents: {selectedIdentity?.agents?.length ?? 0}
              </DetailItemAgentCount>
              <DetailItem>
                Last Activity:&nbsp;
                <TimeAgo
                  lastLog={new Date(selectedIdentity?.maxLastLog)}
                  learnMoreLink="https://support.activtrak.com/"
                  timeAgo={getTimeAgo(selectedIdentity?.maxLastLog)}
                  localDateTimeFormat={dateTimeFormat}
                />
              </DetailItem>
            </>
          )}
          {isLoadingDetail && <SkeletonLoader height={140}></SkeletonLoader>}
        </CardContent>
      </Card>
      <EditOrInsertUserModal
        isOpen={openEditNameModal}
        selectedIdentity={selectedIdentity}
        onClose={() => setOpenEditNameModal(false)}
        title="Edit Info"
        saveButtonText="Save Changes"
        newUserMode={false}
        agentSearchState={agentSearchState}
        commonState={commonState}
        detailState={props.identityDetailState}
      />
    </>
  );
};
