import { IDownloadService, BaseDownloadCSVData } from './';
import { getDefaultConfig, BaseClient } from '../../config';
export class DownloadService extends BaseClient implements IDownloadService {
  public downloadAgent = (os: string) =>
    this.apiService
      .get(`/api/download/${os}agent`, getDefaultConfig())
      .then((response) => response.data);

  public downloadCSV = (reportType: string, args: any) =>
    this.apiService
      .get(`/api/export/${reportType}?${args}`, {
        ...getDefaultConfig(),
        params: { ...BaseDownloadCSVData, exportMode: reportType }
      })
      .then((response) => response.data);
}

export default DownloadService;
