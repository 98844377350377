import React from 'react';
import { PageDirection } from '../models/PageDirection';
import { FooterButton } from '../styles/UserList.view.styles';
import { SelectNoOutline } from '../../common/components/Select/SelectNoOutline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

type ComponentProps = {
  cursorStack: string[];
  onChangePageSize: (newPageSize: string) => void;
  onChangePage: (page: PageDirection) => void;
  currentCursor: string;
  pageSize: string;
  nextPageDisabled: boolean;
  idPrefix: string;
};

export const GridPaging = (props: ComponentProps) => {
  const {
    cursorStack,
    onChangePage,
    onChangePageSize,
    pageSize,
    nextPageDisabled,
    idPrefix
  } = props;

  return (
    <Grid container spacing="3" justifyContent={'center'}>
      <Grid item>
        <FooterButton
          color="primary"
          disabled={cursorStack.length < 2}
          onClick={() => onChangePage(PageDirection.Previous)}
        >
          Previous
        </FooterButton>
      </Grid>
      <Grid item>
        <Box
          id={idPrefix + '-page-size-select-label1'}
          sx={{ display: 'inline' }}
        >
          Show:{' '}
        </Box>
        <SelectNoOutline
          value={pageSize}
          labelId={idPrefix + '-page-size-select-label1'}
          id={idPrefix + '-page-size-select'}
          onChange={(event) =>
            onChangePageSize(event.target?.value?.toString())
          }
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </SelectNoOutline>
        <Box sx={{ display: 'inline' }}> rows</Box>
      </Grid>
      <Grid item>
        <FooterButton
          color="primary"
          onClick={() => onChangePage(PageDirection.Next)}
          disabled={nextPageDisabled}
        >
          Next Page
        </FooterButton>
      </Grid>
    </Grid>
  );
};
