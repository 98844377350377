import React, { useRef, useState } from 'react';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { useClassification } from '../services';
import { EditCategoryTextFieldProps } from '../models';
import { useNotifications } from '../../common/services/Notifications';
import {
  classificationClasses,
  CategoryTextField
} from '../styles/Classifications.styles';
import {
  maxLengthErrorMessage,
  successMessage,
  duplicateErrorMessage,
  genericErrorMessage,
  CantBeEmptyMessage
} from '../constants';
import ClearIcon from '@mui/icons-material/Clear';

export default function EditableCategoryName(
  props: EditCategoryTextFieldProps
) {
  const category = { ...props };
  const inputRef = useRef<any>(null);

  const [editing, setEditing] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(category?.name);

  const { categoryService } = useClassification();
  const notificationService = useNotifications();

  const handleEditNameClick = () => {
    if (newName) {
      setNewName(undefined);
    }
    setEditing(true);
  };

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const prospectiveCategoryName = event.target.value;
      auditCategory(event, prospectiveCategoryName);
    }
  };

  const auditCategory = (event, newName) => {
    setEditing(false);

    // This line prevents an API call if there is no change to the input after cancelling changes to that input
    if (event.target.value === category.name) {
      return;
    }

    if (newName.length > 45) {
      inputRef.current.value = category.name;
      notificationService.error(maxLengthErrorMessage);
      return;
    } else if (newName.length === 0) {
      inputRef.current.value = category.name;
      notificationService.error(CantBeEmptyMessage);
      return;
    }

    updateCategoryName();
  };

  const updateCategoryName = async () => {
    const oldName = category.name;
    category.name = newName;

    try {
      await categoryService.update(category.id, category.name);
      notificationService.success(successMessage);
    } catch (e) {
      category.name = oldName;
      const errorMessage =
        e.data?.error === duplicateErrorMessage
          ? duplicateErrorMessage
          : genericErrorMessage;
      inputRef.current.value = category.name;
      notificationService.error(errorMessage);
    }
  };

  const handleCancelClick = () => {
    inputRef.current.value = category.name;
    return;
  };

  const handleClearIconMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <CategoryTextField
      inputRef={inputRef}
      variant="outlined"
      disabled={category.isDisabled}
      defaultValue={category.name}
      onChange={handleNameChange}
      InputProps={{
        onClick: handleEditNameClick,
        onBlur: (event) => auditCategory(event, newName),
        onKeyPressCapture: handleKeyPress,
        endAdornment: editing && (
          <InputAdornment position="end">
            <Tooltip title="Cancel">
              <IconButton
                sx={classificationClasses.cancelChangesBtn}
                onMouseDown={handleClearIconMouseDown}
                onClick={handleCancelClick}
                color="secondary"
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
  );
}
