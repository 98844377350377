import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { UseUsersContext } from '../services';
import { IUserMerge } from '../models';
import { useNotifications } from '../../common/services/Notifications';

import { dntStyles as classes } from '../styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onCancelMerge: () => void;
};

export const ScheduledUserMergeModal = forwardRef(
  (props: ComponentProps, ref) => {
    useImperativeHandle(ref, () => ({
      loadScheduledMerges() {
        reloadScheduledMerges();
      }
    }));

    const reloadScheduledMerges = () => {
      setUserMerges([]);
      setViewIsLoading(true);
      usersService
        .fetchScheduledUserMerges()
        .then((response: IUserMerge[]) => {
          response.sort(function (a, b) {
            if (a.primaryuserid === b.primaryuserid) {
              return a.primaryuserid == a.userid ? -1 : 1;
            }
            return a.primaryuserid > b.primaryuserid ? 1 : -1;
          });
          setUserMerges(response);
        })
        .catch(() => {
          //what should happen here?
        })
        .finally(() => {
          setViewIsLoading(false);
        });
    };
    const { open, onClose, onCancelMerge } = props;
    const { usersService } = UseUsersContext();
    const [userMerges, setUserMerges] = useState<IUserMerge[]>();
    const notificationService = useNotifications();
    const [viewIsLoading, setViewIsLoading] = useState(false);

    const close = () => {
      onClose();
    };

    const onDeleteClick = async (primaryuserid: number) => {
      userMerges.forEach(async (usermerge) => {
        if (
          primaryuserid == usermerge.primaryuserid &&
          primaryuserid == usermerge.userid
        ) {
          try {
            await usersService.deleteUserMerge(usermerge);
            notificationService.success(`Success! merge was deleted.`);
            reloadScheduledMerges();
            onCancelMerge();
          } catch (response) {
            notificationService.error(response.data.error);
          }
        }
      });
    };

    return (
      <Dialog open={open} onClose={close} maxWidth={'md'}>
        <DialogTitle id="scheduled-merges-dialog-title">
          Scheduled Merges
        </DialogTitle>
        <DialogContent id="scheduled-users-dialog-content">
          <TableContainer sx={classes.tableContainer}>
            <Table stickyHeader size="small" sx={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell sx={classes.tableHeader}>Logon Domain</TableCell>
                  <TableCell sx={classes.tableHeader}>User</TableCell>
                  <TableCell sx={classes.tableHeader}>Alias</TableCell>
                  <TableCell sx={classes.tableHeader}>Record</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userMerges?.map((userMerge: IUserMerge) => (
                  <TableRow key={userMerge.userid}>
                    <TableCell
                      sx={
                        userMerge.primaryuserid === userMerge.userid
                          ? classes.cellDest
                          : classes.cellSourceIndent
                      }
                    >
                      {userMerge.logondomain}
                    </TableCell>
                    <TableCell
                      sx={
                        userMerge.primaryuserid === userMerge.userid
                          ? classes.cellDest
                          : classes.cellSource
                      }
                    >
                      {userMerge.user}
                    </TableCell>
                    <TableCell
                      sx={
                        userMerge.primaryuserid === userMerge.userid
                          ? classes.cellDest
                          : classes.cellSource
                      }
                    >
                      {userMerge.alias}
                    </TableCell>
                    <TableCell
                      sx={
                        userMerge.primaryuserid === userMerge.userid
                          ? classes.cellDest
                          : classes.cellSource
                      }
                    >
                      {userMerge.primaryuserid === userMerge.userid
                        ? 'Destination'
                        : 'Source'}
                    </TableCell>
                    <TableCell>
                      {userMerge.primaryuserid === userMerge.userid ? (
                        <Button
                          onClick={() => onDeleteClick(userMerge.primaryuserid)}
                          variant="outlined"
                          color="secondary"
                        >
                          Cancel Merge
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
                {(userMerges === undefined ||
                  userMerges?.length === 0 ||
                  viewIsLoading) && (
                  <TableRow>
                    <TableCell colSpan={4} sx={classes.EmptyCell}>
                      {viewIsLoading ? 'Loading ...' : 'No merges found'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <label
              htmlFor={'scheduled-users-dialog-content'}
              style={classes.mergeTimingLabel}
            >
              *Scheduled merges will process overnight at 12am Central Standard
              Time. Data collection will be paused during that time. Once the
              merge is complete the log will be updated and resume data
              collection.
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
