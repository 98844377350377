import React, { ChangeEvent } from 'react';
import { Box, Radio, RadioGroup } from '@mui/material';
import {
  StyledFormControlLabel,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import { IGroupFilterDto } from "../../../common/models";

type RadioGroupBoxProps = {
  selected: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filtered: IGroupFilterDto[];
  selectedGroupIds: string[];
};

const RadioGroupBox = ({
  selected,
  handleChange,
  filtered,
  selectedGroupIds
}: RadioGroupBoxProps) => (
  <Box sx={TotCss.scrollableBox}>
    <RadioGroup value={selected} onChange={handleChange}>
      {filtered?.map((g) => (
        <StyledFormControlLabel
          disabled={selectedGroupIds.includes(String(g.id))}
          key={g.id}
          value={g.id}
          name={g.name}
          control={<Radio size="medium" color="primary" />}
          label={g.name}
        />
      ))}
    </RadioGroup>
  </Box>
);

export default RadioGroupBox;
