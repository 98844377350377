import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { Popover, Button, DialogActions, DialogContent } from '@mui/material';
import {
  AdvancedFilterButton,
  PopoverContainer
} from './AdvancedSearchFilter.styles';
import { AdvancedSearchFilterProps } from './AdvancedSearchFilter.props';

export const AdvancedSearchFilter = (props: AdvancedSearchFilterProps) => {
  const { children, onSubmit, onClear, popoverWidth } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'advanced-search' : undefined;

  const handleSubmit = () => {
    onSubmit();
    handleClose();
  };

  return (
    <>
      <AdvancedFilterButton onClick={handleClick} color="secondary">
        <TuneIcon />
      </AdvancedFilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <DialogContent>
          <PopoverContainer popoverwidth={popoverWidth}>
            {children}
          </PopoverContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={onClear}>
            Clear
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Search
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};
