import { react2angular } from 'react2angular';
import { IconsDemoComponent } from '../../_reactivtrak/src/common/assets/Icons/IconsDemo.view';
import { ChartsDemoComponent } from '../../_reactivtrak/src/react-charts-demo/views/DemoPage.view';
import { PlaygroundComponent } from '../../_reactivtrak/src/playground/views/Playground.view';
import { DesignExamplesComponent } from '../../_reactivtrak/src/designExamples/views/DesignExamples.view';

export const connect = (angular) => {
    angular.module('app').component('demoIcons', react2angular(IconsDemoComponent, []));

    angular.module('app').component('demoCharts', react2angular(ChartsDemoComponent, []));

    angular.module('app').component('developerPlayground', react2angular(PlaygroundComponent, []));

    angular.module('app').component('designExamples', react2angular(DesignExamplesComponent, []));
};
