import { useCallback, useState } from 'react';
import { newEvent } from '../../common/analytics/eventHeap';
import { useNotifications } from '../../common/services/Notifications';
import {
  fetchPrivacyLevelSettings,
  putPrivacyLevelSettings
} from '../utils/privacyLevelSettings.utils';
import { PrivacyLevels } from '../enums/privacyLevels';
import { IPrivacyLevelSettingsState } from '../models/IPrivacyLevelSettingsState';
import {
  LOAD_SAVED_SETTING_ERROR,
  SAVE_SETTING_ERROR,
  SAVE_SETTING_SUCCESS
} from '../constants/privacyLevelSettings.const';

export const usePrivacyLevelSettingsState = (): IPrivacyLevelSettingsState => {
  const notificationService = useNotifications();

  const [savedPrivacyLevel, setSavedPrivacyLevel] = useState<string>(
    PrivacyLevels.Basic
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPrivacyLevelSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const storedLevel = await fetchPrivacyLevelSettings();
      setSavedPrivacyLevel(storedLevel);
    } catch (error) {
      notificationService.error(LOAD_SAVED_SETTING_ERROR);
      console.error(`ActivTrak Error: ${LOAD_SAVED_SETTING_ERROR}`, error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePrivacyLevelSettings = useCallback(
    async (selectedLevel: string) => {
      setIsLoading(true);
      try {
        await putPrivacyLevelSettings(selectedLevel);
        notificationService.success(SAVE_SETTING_SUCCESS);
        newEvent('Sensitive Data Visibility Setting Updated', {
          selectedDetails: selectedLevel
        });
        setSavedPrivacyLevel(selectedLevel);
      } catch (error) {
        notificationService.error(SAVE_SETTING_ERROR);
        console.error(`ActivTrak Error: ${SAVE_SETTING_ERROR}`, error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    savedPrivacyLevel,
    getPrivacyLevelSettings,
    isLoading,
    updatePrivacyLevelSettings
  };
};
