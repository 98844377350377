import React, { useState, useEffect, useCallback } from 'react';
import { Routes } from '../../common/constants';
import { GroupType, PermissionLevel, SortDirection } from '../../common/enums';
import { IGroup } from '../../common/models';
import { getFlattenedObjectArray } from '../../common/helpers';

import { useAuthorization } from '../../common/services/Authorization';
import { useNotifications } from '../../common/services/Notifications';
import { isNotATGroupType, useGroupsColumns } from '../utils';
import { useGroupTypesMenu } from '../../groups/utils';
import { GroupsProvider, useGroups } from '../../common/services/Groups';
import { BaseNoResults } from '../../common/components/NoResults/BaseNoResults';
import { NestedCheckboxFilter } from '../../common/components/Filter/NestedCheckboxFilter';
import { Box, Button, Container, Grid } from '@mui/material';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import GroupsSettings from '../components/GroupsSettings';
import GroupsDownload from '../components/GroupsDownload';
import CreateGroupButton from '../components/CreateGroupWizard/CreateGroupButton';
import TotalGroups from '../components/TotalGroups';
import GroupsSearch from '../components/GroupsSearch';
import { UploadLoader } from '../components/CreateGroupWizard/UploadLoader';
import { DownloadComplete } from '../components/DownloadComplete';
import { groupsTableClasses } from '../styles/GroupsTable.styles';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { GroupProvider } from '../services';
import { GroupClassificationsProvider } from '../../common/services/Classifications';
import { AlarmsProvider } from '../../common/services/Alarms';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import DeleteGroupsDialog from '../components/DeleteGroupsDialog';
import DeleteIcon from '@mui/icons-material/Delete';

export function GroupsView() {
  const [showProgbarDwnld, setShowProgbarDwnld] = useState<boolean>(false);
  const [endProgbarDwnld, setEndProgbarDwnld] = useState<boolean>(false);
  const [bulkFilterMenu, setBulkFilterMenu] = useState<any[]>([]);
  const [searchFilterMenu, setSearchFilterMenu] = useState<any[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState<IGroup>();

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDeleteGroup(null);
    setOpenDialog(false);
  }, []);

  const { groupsState } = useGroups();
  const {
    groups,
    originalGroups,
    isLoading,
    init,
    getGroups,
    filterGroups,
    searchGroups,
    setMemberCount,
    setSelectedGroups,
    setSortedGroups,
    deleteGroups,
    downloadCSV,
    createGroup,
    groupsNotification,
    setGroupsNotification
  } = groupsState;

  const history = useUIRouterHistory();

  const { getGroupTypeMenu } = useGroupTypesMenu();
  const notificationService = useNotifications();
  const authorizationService = useAuthorization();

  const permission = authorizationService.pageAuthorization(Routes.Groups);
  const readonly = permission === PermissionLevel.Read;
  const columns = useGroupsColumns({
    readonly,
    authorizationService,
    handleOpenDialog,
    setDeleteGroup
  });

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (groupsNotification?.msg && groupsNotification?.type) {
      notificationService[groupsNotification.type](groupsNotification.msg);
      setGroupsNotification(undefined);
    }
  }, [
    notificationService,
    groupsNotification?.msg,
    groupsNotification?.type,
    setGroupsNotification
  ]);

  //update allowed search filters based on available group types/mixes
  useEffect(() => {
    if (originalGroups?.length) {
      let searchTypeMenu = getGroupTypeMenu(originalGroups);

      //set 'All' to selected=true as the default
      searchTypeMenu = searchTypeMenu.map((item) => {
        if (item?.filterValue === 'All') {
          item.selected = true;
        }
        return item;
      });

      setSearchFilterMenu(searchTypeMenu);
    }
  }, [getGroupTypeMenu, originalGroups]);

  //update allowed bulk selection filters based on filtered group types/mixes
  useEffect(() => {
    //not a way to turn this into a re-usable recursive hook
    const setNewMenuSelections = (prevMenu: any[], currentMenu: any[]) => {
      const currentMenuSelections = currentMenu;

      for (let i = 0; i < currentMenuSelections.length; i++) {
        for (let j = 0; j < prevMenu.length; j++) {
          const currentItem = currentMenuSelections[i];
          const prevItem = prevMenu[j];

          if (currentItem.children?.length && prevItem.children?.length) {
            //children use recursive check
            setNewMenuSelections(prevItem.children, currentItem.children);
          } else {
            //base check
            if (currentItem.filterValue === prevItem.filterValue) {
              //assign previous checked state to new menu instance
              currentItem.selected = prevItem.selected;
            }
          }
        }
      }
      return currentMenuSelections;
    };

    if (groups?.length) {
      const currentBulkFilterMenu = getGroupTypeMenu(groups);

      //make sure current menu selections are still applied
      setBulkFilterMenu((prevState) => {
        if (!currentBulkFilterMenu.length) return [];
        if (!prevState.length) return currentBulkFilterMenu;

        return setNewMenuSelections(prevState, currentBulkFilterMenu);
      });
    }
  }, [groups, getGroupTypeMenu]);

  const handleSearchFilters = useCallback(
    (menu) => {
      const flattenedMenu = getFlattenedObjectArray(menu);

      const selectedTypes: GroupType[] = [];
      flattenedMenu.forEach((item) => {
        if (item?.filterValue === GroupType.All && item?.handleNone) {
          //in this case, we want no results returned when 'All" filter is unchecked
          selectedTypes.push(GroupType.NonExistent);
        } else if (item?.selected) {
          selectedTypes.push(item.filterValue);
        }
      });
      filterGroups(selectedTypes);

      //make sure the bulkSearchMenu has the latest selections when re-opening popover
      setSearchFilterMenu(menu);
    },
    [filterGroups]
  );

  const handleBulkFilterSubmit = useCallback(
    (menu) => {
      const flattenedMenu = getFlattenedObjectArray(menu);
      const selectedTypes = [];
      flattenedMenu.forEach((item) => {
        if (item?.selected) {
          selectedTypes.push(item.filterValue);
        }
      });

      const fGroups = [];
      //using originalGroups instead of filteredGroups because if user removes search filters, then all items in table should be checked based on current bulk filter
      groups.forEach((g) => {
        selectedTypes.forEach((type) => {
          if (g.filter === type || type === GroupType.All) {
            g.selected = true;
            fGroups.push(g);
          }
        });
      });

      setSelectedGroups(fGroups);

      //make sure the bulkMenu has the latest selections when re-opening popover
      setBulkFilterMenu(menu);
    },
    [setSelectedGroups, groups]
  );

  const handleTableRowClick = useCallback(
    (group: IGroup) => {
      history.push('app.settings.groups_id', { groupId: group.id });
    },
    [history]
  );
  const selectedActivTrakGroups = useCallback((): IGroup[] => {
    return groups?.filter((g) => g.selected && !isNotATGroupType(g.filter));
  }, [groups]);

  const handleOnProgBarStart = () => {
    setShowProgbarDwnld(true);
  };

  const handleOnProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(true);
  };

  const resetProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(false);
  };

  return (
    <Box>
      {showProgbarDwnld && <UploadLoader />}
      {endProgbarDwnld && <DownloadComplete onClose={resetProgBarEnd} />}

      <Container sx={groupsTableClasses.groupsContainer}>
        <Grid container sx={groupsTableClasses.header}>
          <Grid item xs={6}>
            {!readonly ? (
              <CreateGroupButton
                onProgressBarStart={handleOnProgBarStart}
                onEndProgbarDwnld={handleOnProgBarEnd}
                onSubmit={createGroup}
                getGroups={getGroups}
                setMemberCount={setMemberCount}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6}>
            <GroupsSearch onSubmit={searchGroups} groups={groups} />
          </Grid>
          <Grid item xs={12} sx={groupsTableClasses.headerRight}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={4}
                lg={5}
                sx={groupsTableClasses.bulkDeleteButtonContainer}
              >
                {authorizationService.canDeleteGroup() && !readonly && (
                  <Button
                    onClick={handleOpenDialog}
                    disabled={
                      (selectedActivTrakGroups()?.length ?? 0) === 0
                        ? true
                        : false
                    }
                    color="secondary"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
                lg={7}
                sx={groupsTableClasses.gridFilterControls}
              >
                <TotalGroups count={groups?.length} />
                <NestedCheckboxFilter
                  filterOptions={searchFilterMenu}
                  handleNone={true}
                  onSubmitFilters={handleSearchFilters}
                />
                <GroupsDownload
                  onProgressBarStart={handleOnProgBarStart}
                  onEndProgbarDwnld={handleOnProgBarEnd}
                  onSubmit={downloadCSV}
                />
                {!readonly ? <GroupsSettings /> : <></>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && !groups?.length ? (
          <BaseNoResults />
        ) : (
          <>
            <CustomGrid
              height={'65vh'}
              isVirtualized={true}
              isLoading={isLoading}
              data={groups}
              columns={columns}
              initialSortField="name"
              initialSortDirection={SortDirection.Ascending}
              hasBulkSelection
              bulkSelectFilterExpandedOptions={bulkFilterMenu}
              onSubmitBulkFilter={handleBulkFilterSubmit}
              onSelectAllClick={setSelectedGroups}
              onClickRow={handleTableRowClick}
              onSortOrder={setSortedGroups}
            />
            <DeleteGroupsDialog
              open={openDialog}
              groups={deleteGroup ? [deleteGroup] : selectedActivTrakGroups()}
              onClose={handleCloseDialog}
              onDelete={deleteGroups}
            />
          </>
        )}
      </Container>
    </Box>
  );
}

export const GroupsComponent = () => (
  <ReactivTrakComponentWrapper>
    <GroupsProvider>
      <GroupProvider>
        <GroupClassificationsProvider>
          <AlarmsProvider>
            <GroupsView />
          </AlarmsProvider>
        </GroupClassificationsProvider>
      </GroupProvider>
    </GroupsProvider>
  </ReactivTrakComponentWrapper>
);
