import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const LiveReportsIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'LiveReports';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M21.3333 0C22.8 0 24 1.2 24 2.66667V21.3333C24 22.8 22.8 24 21.3333 24H2.66667C1.2 24 0 22.8 0 21.3333V2.66667C0 1.2 1.2 0 2.66667 0H21.3333ZM9.33333 16H6.66667C5.93333 16 5.33333 16.6 5.33333 17.3333C5.33333 18.0667 5.93333 18.6667 6.66667 18.6667H9.33333C10.0667 18.6667 10.6667 18.0667 10.6667 17.3333C10.6667 16.6 10.0667 16 9.33333 16ZM17.3333 10.6667H6.66667C5.93333 10.6667 5.33333 11.2667 5.33333 12C5.33333 12.7333 5.93333 13.3333 6.66667 13.3333H17.3333C18.0667 13.3333 18.6667 12.7333 18.6667 12C18.6667 11.2667 18.0667 10.6667 17.3333 10.6667ZM13.3333 5.33333H6.66667C5.93333 5.33333 5.33333 5.93333 5.33333 6.66667C5.33333 7.4 5.93333 8 6.66667 8H13.3333C14.0667 8 14.6667 7.4 14.6667 6.66667C14.6667 5.93333 14.0667 5.33333 13.3333 5.33333Z"
      />
    </SvgIconWrapper>
  );
};
