import { IEnvConfig, IActivTrakRequestConfig } from '../models';
import envService from '../../../../_app/environmentService/environmentService';

//TODO: The purpose of this file and the _reactivtrak/.../apiService.ts need to be investigated and transitioned to a central api service

export let envConfig: IEnvConfig = {
  baseUrl: '',
  environmentService: envService
};

export const setEnvConfig = (newEnvConfig: IEnvConfig) => {
  envConfig = {
    baseUrl: newEnvConfig.baseUrl,
    environmentService: envService
  };
};

export const getDefaultConfig = () => {
  const defaultConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getApiUrl()
  };
  return defaultConfig;
};

export const getWebApiConfig = () => {
  const webApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getAdminApiUrl()
  };
  return webApiConfig;
};

export const getAccountApiConfig = () => {
  const webApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getAccountApiUrl()
  };
  return webApiConfig;
};

export const getReportingServiceUrl = () => {
  const reportingApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getReportingServiceUrl()
  };
  return reportingApiConfig;
};

export const getWebsocketHttpsUrl = () => {
  const websocketConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getWebsocketHttpsUrl()
  };
  return websocketConfig;
};

export const getIdentityUrl = () => {
  const identityConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: envConfig.environmentService.getIdentityServiceUrl()
  };
  return identityConfig;
};

type ApiConfigsProps = {
  default: () => IActivTrakRequestConfig;
  reporting: () => IActivTrakRequestConfig;
  admin: () => IActivTrakRequestConfig;
  account: () => IActivTrakRequestConfig;
  identity: () => IActivTrakRequestConfig;
  websocket: () => IActivTrakRequestConfig;
};

export const apiConfigs: ApiConfigsProps = {
  default: getDefaultConfig,
  reporting: getReportingServiceUrl,
  admin: getWebApiConfig,
  account: getAccountApiConfig,
  identity: getIdentityUrl,
  websocket: getWebsocketHttpsUrl
};
