'use strict';

angular.module('app')
    .controller('dropdownModal', DropdownModalController);

DropdownModalController.$inject = ['$scope', '$uibModalInstance', 'options'];

function DropdownModalController($scope, $uibModalInstance, options) {
    $scope.options = options;

    var modelSelection ={};

    angular.forEach($scope.options.dropdownDataSource.data(), function(value) {
        if ($scope.options.selectedId === value.id) {
            modelSelection = value;
        }
    });

    if ($scope.options.type === 'category' && $scope.options.selectedId === -2) {
        modelSelection.name = '';
    }

    $scope.msgTitle = options.type === 'category' ? 'Select a category ': 'Set productivity ';

    $scope.save = function () {
        $uibModalInstance.close();
        options.dropdownUpdate($scope.options.rowItem, $scope.modelSelection);
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };
}
