import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import { gray4, gray6 } from '../../common/constants';

export const RefreshTooltipSC = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200
  }
});

export const CommentsContainerSC = styled(Box)`
  border: 1px solid ${gray4};
  max-height: 200px;
  overflow: auto;
`;

export const CommentContainerSC = styled(Box)`
  border: 1px solid ${gray4};
  padding: 15px;
`;

export const CommentsLoaderContainerSC = styled(Box)`
  border: 1px solid ${gray4};
  padding: 20px;
  text-align: center;
`;

export const CommentDateSC = styled(Typography)`
  display: inline-block;
  color: ${gray6};
`;
