import { Box, Typography } from '@mui/material';
import * as React from 'react';

export const AboutIntegrationTemplates = ({ children }) => {
  return (
    <Box pb={2}>
      <Typography variant="h6">Templates</Typography>
      <Box>{children}</Box>
    </Box>
  );
};
