import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { ApiKeysLinks } from '../enums';

type ApiKeySuccessDialogProps = {
  open: boolean;
  onClose: () => void;
  newApiKeyName: string;
  newApiKey: string;
};

const ApiKeySuccessDialog: React.FC<ApiKeySuccessDialogProps> = ({
  open,
  onClose,
  newApiKeyName,
  newApiKey
}) => {
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
    >
      <DialogTitle>Successfully generated a new API key!</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: '40px' }}>
          Copy this API key and store it in a secure place. All successfully
          generated API keys can be copied from this page anytime.
        </Typography>
        <TextField label="API Key Name" value={newApiKeyName} fullWidth />
        <Typography sx={{ margin: '0 0 40px 15px', fontSize: '12px' }}>
          You can change your API key name at any time.
        </Typography>
        <TextField
          label="API Key"
          value={newApiKey}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboardButton
                  text={newApiKey}
                  successMessage="API Key copied"
                  errorMessage="This browser does not support copy to clipboard"
                />
              </InputAdornment>
            )
          }}
        />
        <Typography sx={{ margin: '0 0 40px 15px', fontSize: '12px' }}>
          Your API key has been created. No change can be made to this key.
        </Typography>
        <Typography sx={{ marginBottom: '20px' }}>
          <a href={ApiKeysLinks.Learn_More} target="_blank" rel="noreferrer">
            Learn More
          </a>{' '}
          about authenticating using API keys.
        </Typography>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiKeySuccessDialog;
