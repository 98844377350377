import React from 'react';
import { CopyAgentLinkCard } from '../components/CopyAgentLinkCard';
import { DownloadAgentCard } from '../components/DownloadAgentCard';
import { ActivationHeader } from '../components/Header';
import { SupportFooter } from '../components/SupportFooter';
import { activationViewClasses } from '../styles/AgentActivationView.styles';
import { Box, Container, Grid } from '@mui/material';

export const AgentActivationView = () => {
  return (
    <Container sx={activationViewClasses.container}>
      <ActivationHeader />
      <Box sx={{ height: '80vh' }}>
        <Grid container sx={activationViewClasses.cardContainer}>
          <DownloadAgentCard />
          <CopyAgentLinkCard />
        </Grid>
      </Box>
      <SupportFooter />
    </Container>
  );
};
