import React from 'react';

const NoDataView = props => {
  const { hasResults } = props;
  return (
      <div>
        No data to display
        {hasResults && (
          <span>, try adjusting the filter</span>
        )}
      </div>
  );
};

export default NoDataView;
