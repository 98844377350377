import React from 'react';
import { useAuthorization } from '../../../common/services/Authorization';
import { Role } from '../../../common/enums';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ProductivityFilter } from '../../../common/components/ReportFilters/components/ProductivityFilter';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { TopApplicationsHeaderProps } from '../models/TopApplicationsTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const TopApplicationsReportHeader = (
  props: TopApplicationsHeaderProps
) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users, productivity } = reportFilters;

  const authorizationService = useAuthorization();
  const ignoreDateRestrictions: boolean = authorizationService.hasRole(
    Role.SuperAdmin
  );

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <ProductivityFilter
            productivity={productivity}
            onSubmitFilter={setReportFilters}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline
          sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
        >
          <ExportButtonContainer />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
