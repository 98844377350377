import _ from 'lodash';
import moment from 'moment';
import { isEmpty } from '../../utilities/helpers';
import PassiveLabels from '../../models/PassiveLabels';
import { userPulseFilter } from '../../utilities/userPulseFilter';
import { userPulseSort } from '../../utilities/userPulseSort';

function constructor() {
  let _teamPulse = {};
  let _realtime = {};
  let _processedData;
  let _cardList;
  let _sort;
  let _filter;
  let _reportMode;
  let _timeMode;
  let _previousUpdates = [];
  let _previousContentUpdates = [];
  let _realtimeUpdateSubscribers = [];

  const errorPrefix = 'ActivTrak Error:';

  const _hasItemUpdated = (item) => {
    if (!item || !item.id) {
      return false;
    }

    let currentUpdate = moment(item.time, 'M/D/YYYY h:mm:ss A');
    let previousUpdate = _previousUpdates[item.id];
    _previousUpdates[item.id] = currentUpdate;

    return previousUpdate && currentUpdate.isAfter(previousUpdate);
  };

  const _hasItemContentUpdated = (item) => {
    if (!item || !item.id) {
      return false;
    }

    let currentUpdate = `${item.description}${item.url}${item.executable}${item.title}`.toLowerCase();
    let previousUpdate = _previousContentUpdates[item.id];
    _previousContentUpdates[item.id] = currentUpdate;

    return currentUpdate !== previousUpdate;
  };

  const _isPassive = (item) => {
    return (
      item &&
      item.title &&
      (item.title.toLowerCase() === PassiveLabels.title ||
        item.title.toLowerCase() === PassiveLabels.lockedTitle)
    );
  };

  const _mergeData = () => {
    let mergedUsers = [];

    if (!isEmpty(_teamPulse)) {
      // console.log('merge data started');
      try {
        mergedUsers = _.map(_teamPulse, (teamPulseItem) => {
          let realtime = _.find(_realtime, (realtimeItem) =>
            _.includes(teamPulseItem.id.split(','), realtimeItem.id.toString())
          );
          if (realtime) {
            realtime.isPassive = _isPassive(realtime);
            // Has content updated uses the values that are used in the current activity render
            // Has updated uses the time field which updates even if the user is in the same activity
            realtime.hasContentUpdated = _hasItemContentUpdated(realtime);
            realtime.hasUpdated = _hasItemUpdated(realtime);

            if (realtime.hasUpdated && !teamPulseItem.firstSeen) {
              teamPulseItem.firstSeen = realtime.time;
            }
          }

          teamPulseItem.realtime = realtime || {};
          teamPulseItem.activeStatus = realtime
            ? realtime.isPassive
              ? 'passive'
              : 'active'
            : 'inactive';
          teamPulseItem.activeSort =
            teamPulseItem.activeStatus === 'inactive' ? 0 : 1;
          teamPulseItem.productiveTotalRatio =
            teamPulseItem.productiveTotalRatio || 0;
          teamPulseItem.productiveActiveRatio =
            teamPulseItem.productiveActiveRatio || 0;

          return teamPulseItem;
        });
      } catch (e) {
        console.error(`${errorPrefix} _mergeData`, e);
      }
    }

    return mergedUsers;
  };

  const isRefreshRequired = (newData = {}, oldData = {}) => {
    const getIdMap = (data) => {
      if (!data) {
        return [];
      }

      return data
        .map((item) => {
          return item.id;
        })
        .sort();
    };

    const newDataIds = getIdMap(newData.data);
    const oldDataIds = getIdMap(oldData.data);

    // console.log('isEqual', _.isEqual(newDataIds, oldDataIds), newData, oldData);

    return Boolean(
      (!oldData.time && newData.time) ||
        (newData.time && newData.time.isAfter(oldData.time)) ||
        !_.isEqual(newDataIds, oldDataIds)
    );
  };

  const _processData = (
    teamPulseData,
    realtimeData,
    reportMode, 
    timeMode,
    time
  ) => {
    _teamPulse = teamPulseData || _teamPulse;
    _realtime = realtimeData || _realtime;
    _reportMode = reportMode || _reportMode;
    _timeMode = timeMode || _timeMode;

    // console.log(
    //   'processData started',
    //   _teamPulse,
    //   _realtime,
    //   _sort,
    //   _filter
    // );

    if (!_teamPulse) {
      // console.log('no team pulse data found');
      _processedData = [];
      _cardList = {
        time: moment.utc(),
        data: _processData
      };

      return _processedData;
    }

    _processedData = _mergeData();
    _processedData = userPulseFilter(_processedData, _filter);
    _processedData = userPulseSort(_processedData, _sort, _timeMode);

    // console.log('_processedData', _processedData);
    const newList = {
      time: time || _cardList.time,
      data: _processedData
    };

    const refreshRequired = isRefreshRequired(newList, _cardList);

    _cardList = {
      time: refreshRequired ? newList.time : _cardList.time,
      data: newList.data,
      refreshRequired
    };

    return _cardList;
  };

  const processData = (
    teamPulseData,
    realtimeData,
    reportMode, 
    timeMode,
    time
  ) => {
    const cardList = _processData(
      teamPulseData,
      realtimeData,
      reportMode, 
      timeMode,
      time
    );

    if (realtimeData) {
      _.forEach(_realtimeUpdateSubscribers, (subscriber) => {
        let cardData = getCardData(subscriber.id);
        if (
          typeof subscriber.callback === 'function' &&
          cardData &&
          cardData.realtime &&
          cardData.realtime.hasContentUpdated
        ) {
          subscriber.callback({
            time: cardList.time,
            data: cardData
          });
        }
      });
    }

    return cardList;
  };

  const hasResults = () => {
    return _teamPulse && _teamPulse.length > 0;
  };

  const getCardList = () => {
    return _cardList;
  };

  const getCardData = (id) => {
    if (isEmpty(_processedData)) {
      return {};
    }

    let foundItem = _.find(_processedData, (item) => item.id === id);
    if (!foundItem) {
      return {};
    }

    return foundItem;
  };

  const subscribeToRealtimeUpdate = ({ id, callback }) => {
    let subscriber = _.find(_realtimeUpdateSubscribers, (s) => s.id === id);
    if (subscriber) {
      subscriber.callback = callback;
    } else {
      _realtimeUpdateSubscribers.push({ id, callback });
    }
  };

  const clearRealtimeSubscribers = () => {
    _realtimeUpdateSubscribers = [];
    _previousUpdates = [];
    _previousContentUpdates = [];
  };

  const setSort = (value) => {
    _sort = value;
  };

  const setFilter = (value) => {
    _filter = value;
  };

  return {
    processData,
    hasResults,
    getCardList,
    getCardData,
    subscribeToRealtimeUpdate,
    clearRealtimeSubscribers,
    setSort,
    setFilter
  };
}

export { constructor };
