import {
  apiService,
  setSessionStorageItem,
  getSessionStorageItem,
  parseJson
} from '../../helpers';
import { getDefaultConfig } from '../../config';
import { IActivTrakRequestConfig } from '../../models';
import { COACHING_OPPORTUNITIES, COACHING_OPPORTUNITIES_LAST_SEEN } from "../../constants/coachOpps";

const withConfig = () => async (apiCall: (config: IActivTrakRequestConfig) => Promise<any>) => {
  const config = getDefaultConfig();
  return await apiCall(config);
};

const buildSimpleGet = (url: string) => {
  return withConfig()(
    async (config: IActivTrakRequestConfig) => {
      const response = await apiService.get<any>(url, { ...config });
      return response?.data;
    }
  );
}

const getCoachingOpportunities = async () => {
  return buildSimpleGet(`/api/insights/coaching/opportunities`);
};

const getCoachingOpportunitiesStartWeek = async () => {
  return buildSimpleGet(`/api/insights/coaching/startweek`);
}

export const setCoachingOpportunitiesStartWeek = async (startWeek: string) => {
  return withConfig()(
    async (config: IActivTrakRequestConfig) => {
      const response = await apiService.post<any>(
        `/api/insights/coaching/startweek/${startWeek}`,
        {
          ...config
        }
      );

      return response?.data;
    }
  )
}

export const hasCoachingOpportunities = async (): Promise<boolean> => {
  let coachingOpportunities;
  const cachedItem = getSessionStorageItem(COACHING_OPPORTUNITIES);
  if (cachedItem) {
    coachingOpportunities = parseJson(cachedItem);
  } else {
    coachingOpportunities = await getCoachingOpportunities();
    setSessionStorageItem(COACHING_OPPORTUNITIES, JSON.stringify(coachingOpportunities));
  }

  const { totalOpportunities, newOpportunities, startWeekDate } =
    coachingOpportunities;

  if (startWeekDate && totalOpportunities > 0) {
    let lastSeenStartWeek = sessionStorage.getItem(COACHING_OPPORTUNITIES_LAST_SEEN);
    if (!lastSeenStartWeek) {
      lastSeenStartWeek = await getCoachingOpportunitiesStartWeek();
      if (lastSeenStartWeek) {
        sessionStorage.setItem(COACHING_OPPORTUNITIES_LAST_SEEN, lastSeenStartWeek);
      }
    }
    //existing entry in account_settings
    if (lastSeenStartWeek) {
      //If user hasn't already seen current week's coach opps and there are new opps to show
      if (lastSeenStartWeek !== startWeekDate && newOpportunities > 0) {
        return true;
      }
    } else {//First time user encounters coach opps
      return true;
    }
  }
  return false;
};

export const setCoachingNotificationLastSeenWeek = () => {
    const savedCoachingOps: any = parseJson(
      getSessionStorageItem(COACHING_OPPORTUNITIES)
    );
    const { startWeekDate } = savedCoachingOps;
  setSessionStorageItem(COACHING_OPPORTUNITIES_LAST_SEEN, startWeekDate);
  setCoachingOpportunitiesStartWeek(startWeekDate);
};
