import React from 'react';
import {
  ButtonGroup,
  Dialog,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { Launch } from '@mui/icons-material';
import { useRedirect } from '../hooks';
import { QuickLinksButton, RedirectingModal } from '.';
import { getAccount } from '../utils';
import { IRedirectButtonGroupProps } from '../models/IComponentProps';
import { IButtonMouseEvent } from '../models/IRandomTypes';

export const RedirectButtonGroup = (props: IRedirectButtonGroupProps) => {
  const { accountId } = props;

  const { impersonateAndRedirect, isRedirecting } = useRedirect();

  const handleViewClick = async (event: IButtonMouseEvent, path?: string) => {
    event.preventDefault();
    const account = getAccount(accountId);

    impersonateAndRedirect(account, path);
  };

  return (
    <>
      <ButtonGroup variant="contained">
        <Tooltip title="View" arrow>
          <IconButton onClick={(e) => handleViewClick(e)}>
            <Launch />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <QuickLinksButton handleViewClick={handleViewClick} />
      </ButtonGroup>
      <Dialog open={isRedirecting}>
        <RedirectingModal />
      </Dialog>
    </>
  );
};
