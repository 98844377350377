import { Role, RoleAccessKeys } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';

export const appApikey: IRoute = {
  name: 'app.apikey',
  stateDefinition: {
    url: '/integrations/apikeys',
    template: '<integrations-apikeys></integrations-apikeys>',
    data: { pageTitle: 'API Keys' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Integrations,
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportBasic, Role.SupportIntermediate],
    bundleFlags: [BundleFlag.ApiKeysGenerationConfiguration]
  }
};

export default appApikey;
