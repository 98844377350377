const utmMedium = 'MSP';
const googleAuth = 'No';
const formCampaignID__c = '7010h000001BDNU';

export const setMarketingData = (props) => {
  const { campaign, source, country, state } = props;

  try {
    return {
      source,
      medium: utmMedium,
      campaign,
      marketing_Consent_Opt_In__c: 'Yes',
      google_Auth__c: googleAuth,
      term: 'not_provided',
      content: 'not_provided',
      referrer: 'not_provided',
      landing: 'not_provided',
      gclid: 'not_provided',
      uuid: 'not_provided',
      formCampaignID__c,
      country,
      region: state
    };
  } catch (error) {
    console.error(
      `ActivTrak Error: An error occurred while setting marketing data: \n${error}`
    );
    return;
  }
};
