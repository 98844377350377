'use strict';

angular.module('app')
    .service('atBlockingDataTracker', BlockingDataTracker);

    BlockingDataTracker.$inject = [];

function BlockingDataTracker() {
    var that = this;

    this.changedDomains = [];
    this.domainData = [];
    this.groupData = [];
    this.keyedGroups = [];

    this.setDomainData = function(data) {
        that.domainData = data;
    };

    this.getDomainData = function() {
        return that.domainData;
    };

    this.setGroupData = function(data) {
        that.groupData = data;
    };

    this.getGroupData = function() {
        return that.groupData;
    };

    this.addToGroupData = function(group) {
        that.groupData.push(group);
    };

    this.setKeyedGroups = function(data) {
        that.keyedGroups = data;
    };

    this.getKeyedGroups = function() {
        return that.keyedGroups;
    };

    this.addKeyedGroup = function(group) {
        that.keyedGroups[group.name] = group;
    };

    this.getKeyedGroup = function(groupName) {
        return that.keyedGroups[groupName];
    };

    this.getChangedDomains = function() {
        var changes = [];
        for (var change in that.changedDomains) {
            var domain = angular.copy(that.changedDomains[change]);
            domain.groups = that.getDomainGroups(that.changedDomains[change]);
            changes[change] = domain;
        }
        return changes;
    };

    this.updateChanges = function (domain, blocked, changedGroups) {
        // Check if domain has already been changed.
        if (!!that.changedDomains[domain.domain]) {
            // If already changed, update changedDomain object and check if it is still changed
            if (!that.domainChanged(domain, blocked, changedGroups)) {
                // If not still changed, remove from changedDomains
                that.removeDomain(domain);
            }
        }
        else {
            // If not in changedDomains, add to changedDomains
            that.addDomain(domain, blocked, changedGroups);
        }
    };

    this.stillChanged = function (domain) {
        // If original groups and changed groups do not have same count, assume domain has changed
        if (domain.groups.length === domain.originalGroups.length) {
            // Create list of original groups
            var groups = _.map(domain.originalGroups, function(g) { return g.name; });
            // Create list of changed groups
            var changedGroups = _.map(domain.groups, function(g) { return g.name; });
            // Check lists for differences
            var groupChangesFound = _.difference(groups, changedGroups, function (g) { return g; });
            return groupChangesFound.length > 0;
        }
        return true;
    };

    this.addDomain = function (domain, blocked, changedGroups, clearGroups) {
        var blockedDomain = angular.copy(_.find(that.domainData, function(d) { return d.domain === domain.domain; }));
        if (!blockedDomain) {
            blockedDomain = {
                domain: domain.domain,
                originalGroups: [],
                blocked: true
            };
            that.domainData.push(angular.copy(blockedDomain));
        }

        blockedDomain.originalGroups = [];
        if (!!blockedDomain.groups) {
            blockedDomain.groups.forEach(function(group) {
                var grp = {
                    name: group.name,
                    isBlocked: true,
                    isDirty: false,
                    id: group.id
                };
                blockedDomain.originalGroups.push(grp);
            });
        }
        if (clearGroups) {
            blockedDomain.groups = [];
        }
        blockedDomain = that.updateChangedGroups(blockedDomain, blocked, changedGroups);

        // Verify domain has changed
        if (that.stillChanged(blockedDomain)) {
            that.changedDomains[domain.domain] = blockedDomain;
            that.changedDomains.length++;
        }
    };

    this.domainChanged = function (domain, blocked, changedGroups) {
        return that.stillChanged(that.updateChangedGroups(that.changedDomains[domain.domain], blocked, changedGroups));
    };

    this.removeDomain = function (domain) {
        delete that.changedDomains[domain.domain];
        that.changedDomains.length--;
    };

    this.updateChangedGroups = function(domain, blocked, changedGroups) {
        var hadGroups = false;
        changedGroups.forEach(function (group) {
            var grp = {
                name: group.name,
                isBlocked: blocked,
                isDirty: group.isDirty,
                id: group.id
            };
            if (!domain.groups) {
                domain.groups = [];
            }
            var groupNames = domain.groups.map(function(i) {
                return i.name;
            });
            hadGroups = groupNames.length > 0;
            var groupIdx = groupNames.indexOf(group.name);

            if (blocked && groupIdx === -1) {
                domain.groups.push(grp);
            }
            else if (!blocked && groupIdx >= 0) {
                domain.groups.splice(groupIdx, 1);
            }
        });

        if (hadGroups && domain.groups.length > 0) {
            domain.blocked = true;
        }
        else if (hadGroups && domain.groups.length === 0) {
            domain.blocked = false;
        }
        else {
            domain.blocked = blocked;
        }

        return domain;
    };

    this.isDomainDirty = function (domainName) {
        return !!that.changedDomains[domainName];
    };

    this.isDomainDirtyForGroup = function (domainName, groupName) {
        var domain = that.changedDomains[domainName];
        if (!domain) {
            return false;
        }
        var groupChanged = false;
        // Check if group is part of changed groups, but not part of original groups.
        var changedGroupFound = that.groupExists(domain.groups, groupName);
        if (changedGroupFound) {
            groupChanged = groupChanged || !that.groupExists(domain.original, groupName);
        }

        // Check if group was apart of the original groups, but not part of the changed groups.
        if (!groupChanged) {
            var groupFound = that.groupExists(domain.originalGroups, groupName);
            if (groupFound) {
                groupChanged = groupChanged || !that.groupExists(domain.groups, groupName);
            }
        }
        return groupChanged;
    };

    this.groupExists = function (groups, groupName) {
        return !!_.find(groups, function(g) {
            return g.name === groupName;
        });
    };

    this.groupIsBlocked = function (groups, groupName) {
        return !!(_.find(groups, function(g) { return g.name === groupName; })).isBlocked;
    };

    this.createPayload = function () {
        var payload = [];
        for (var d in that.changedDomains) {
            var domain = {
                domain: that.changedDomains[d].domain,
                groups: []
            };
            var allComputersFound = false;
            for (var g in that.changedDomains[d].groups) {
                allComputersFound = allComputersFound || g === 'All Computers';
                var group = {
                    name: that.changedDomains[d].groups[g].name,
                    id: that.changedDomains[d].groups[g].id,
                    blocked: that.changedDomains[d].groups[g].isBlocked
                };
                domain.groups.push(group);
            }
            if (allComputersFound) {
                domain.groups = null;
                domain.groupIds = '';
            }
            domain.blocked = domain.groups.length > 0;
            payload.push(domain);
        }
        return payload;
    };

    this.setGroupCounts = function(group) {
        group.plusCount = 0;
        group.minusCount = 0;
        for (var d in that.changedDomains) {
            var groups = that.changedDomains[d].groups;
            var originalGroups = that.changedDomains[d].originalGroups;
            var isChanged = that.groupExists(groups, group.name);
            var isOriginal = that.groupExists(originalGroups, group.name);
            if (!isOriginal && isChanged && that.groupIsBlocked(groups, group.name)) {
                group.plusCount++;
            }
            else if (isOriginal && !isChanged) {
                group.minusCount++;
            }
        }
    };

    this.setDomainCounts = function(domain) {
        domain.plusCount = 0;
        domain.minusCount = 0;
        var d = that.changedDomains[domain.domain];
        if (!!d) {
            var groups = d.groups;
            var originalGroups = d.originalGroups;
            var g;
            var isChanged;
            var isOriginal;
            for (var i in groups) {
                g = groups[i];
                isChanged = that.groupExists(groups, g.name);
                isOriginal = that.groupExists(originalGroups, g.name);
                if (!isOriginal && isChanged && that.groupIsBlocked(groups, g.name)) {
                    domain.plusCount++;
                }
            }
            for (var idx in originalGroups) {
                g = originalGroups[idx];
                isChanged = that.groupExists(groups, g.name);
                isOriginal = that.groupExists(originalGroups, g.name);
                if (isOriginal && !isChanged) {
                    domain.minusCount++;
                }
            }
        }
    };

    this.getGroupDomains = function (group) {
        var domains = {};
        that.getGroupData().forEach(function (data) {
            if (group.name === data.name) {
                domains = data.blockedDomains;
            }
        });
        return domains;
    };

    this.getDomainGroups = function (domain) {
        var groups = [];
        domain.groups.forEach(function(group) {
            var grp = angular.copy(that.keyedGroups[group.name]);
            if (typeof group.isBlocked === 'undefined') {
                group.isBlocked = true;
            }
            if (typeof group.isDirty === 'undefined') {
                group.isDirty = false;
            }
            grp.isBlocked = group.isBlocked;
            grp.isDirty = group.isDirty;
            groups.push(grp);
        });
        return groups;
    };

    this.findDomain = function(domainName) {
        return _.find(that.domainData, function (d) { return d.domain === domainName; });
    };
}
