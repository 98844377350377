'use strict';

angular.module('app').directive('atMouseWheelUp', AtMouseWheelUp);

AtMouseWheelUp.$inject = ['$rootScope', '$parse', 'atHelperFunctions'];

function AtMouseWheelUp($rootScope, $parse, atHelperFunctions) {
    return function (scope, element, attrs) {
        var updateCallback = $parse(attrs.atMouseWheelUp);
        element.bind('DOMMouseScroll mousewheel onmousewheel', function (event) {
            // cross-browser wheel delta
            var windowEvent = window.event || event; // old IE support
            var delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));

            if (delta > 0) {
                if (typeof updateCallback === 'function') {
                    atHelperFunctions.safeApply($rootScope, function () {
                        updateCallback(scope);
                    });
                }

                // for IE
                windowEvent.returnValue = false;
                // for Chrome and Firefox
                if (event.preventDefault) {
                    event.preventDefault();
                }
            }
        });
    };
}
