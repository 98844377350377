import { PresetGroups } from '../models/IDateRangePicker';

export enum DateRanges {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last_7_Days',
  ThisWeek = 'This_Week',
  LastWeek = 'Last_Week',
  Last30Days = 'Last_30_Days',
  ThisMonth = 'This_Month',
  LastMonth = 'Last_Month',
  ThisYear = 'Current_CY',
  LastYear = 'Previous_CY',
  Custom = 'Custom_Date_Range'
}

export enum DateRangeLabel {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 Days',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  Last30Days = 'Last 30 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
  Custom = 'Custom Date Range',
  Error = 'Select Date Range'
}

export const presetGroups: PresetGroups = {
  OneDay: [
    {
      label: DateRangeLabel.Today,
      value: DateRanges.Today,
      sortOrder: 0,
      canExcludeToday: false,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 0
    },
    {
      label: DateRangeLabel.Yesterday,
      value: DateRanges.Yesterday,
      sortOrder: 1,
      canExcludeToday: false,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 0
    }
  ],
  OneWeek: [
    {
      label: DateRangeLabel.Last7Days,
      value: DateRanges.Last7Days,
      sortOrder: 2,
      canExcludeToday: true,
      canAddPreviousDay: true,
      dataRetentionMinMonths: 0
    },
    {
      label: DateRangeLabel.ThisWeek,
      value: DateRanges.ThisWeek,
      sortOrder: 4,
      canExcludeToday: true,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 0
    },
    {
      label: DateRangeLabel.LastWeek,
      value: DateRanges.LastWeek,
      sortOrder: 5,
      canExcludeToday: false,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 0
    }
  ],
  OneMonth: [
    {
      label: DateRangeLabel.Last30Days,
      value: DateRanges.Last30Days,
      sortOrder: 3,
      canExcludeToday: true,
      canAddPreviousDay: true,
      dataRetentionMinMonths: 1
    },
    {
      label: DateRangeLabel.ThisMonth,
      value: DateRanges.ThisMonth,
      sortOrder: 6,
      canExcludeToday: true,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 1
    },
    {
      label: DateRangeLabel.LastMonth,
      value: DateRanges.LastMonth,
      sortOrder: 7,
      canExcludeToday: false,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 2
    }
  ],
  OneYear: [
    {
      label: DateRangeLabel.ThisYear,
      value: DateRanges.ThisYear,
      sortOrder: 8,
      canExcludeToday: true,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 12
    },
    {
      label: DateRangeLabel.LastYear,
      value: DateRanges.LastYear,
      sortOrder: 9,
      canExcludeToday: false,
      canAddPreviousDay: false,
      dataRetentionMinMonths: 12
    }
  ],
  CustomRange: []
};
