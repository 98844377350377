import {
  convertObjectToQs,
  durationFormat,
  fetchReportingData
} from '../../common/helpers';
import { ZERO_PIE_CHART_COLORS } from '../constants';
import { IBarChartDataset } from '../models';

export const getStringDurationFormat = (time: number, singleDigits = true) => {
  if (time === 0) return '0m';

  return durationFormat(time, true, {
    spaceBetweenDigits: true,
    singleDigitLeadingZero: singleDigits
  });
};

export const checkAllValuesAreZero = (dataArray: number[]): boolean => {
  return dataArray?.every(
    (value) => value === 0 || value == undefined || value == null
  );
};

export const isPreviousValueNegative = (previous: number): number =>
  previous && previous < 0 ? 1 : 0;

export const formatPreviousValueString = (previous: number) =>
  previous ? (previous > 0 ? `+${previous}` : previous) : '0';

export const getChartColors = (noResults: boolean, chartColors: string[]) =>
  noResults ? ZERO_PIE_CHART_COLORS : chartColors;

export const updateLegendSelections = (
  value: string | number,
  currentSelections: (string | number)[]
) => {
  let newSelectionList = [...currentSelections];
  if (newSelectionList.includes(value)) {
    newSelectionList = newSelectionList.filter((item) => item !== value);
  } else {
    newSelectionList.push(value);
  }

  return newSelectionList;
};

export const checkIfNoResults = (data: IBarChartDataset[]): boolean => {
  const isZeroStateArr: boolean[] = data.map((item) =>
    checkAllValuesAreZero(item.data)
  );

  return isZeroStateArr.every((item) => {
    return item === true;
  });
};

export const buildReportsApiRequest = <T>({ path, params }) =>
  fetchReportingData<T>({
    path,
    params,
    paramsSerializer: function (params) {
      /* this ensures params that include arrays are properly serialized */
      return convertObjectToQs(params, { arrayFormat: 'repeat' });
    }
  });
