'use strict';

angular
    .module('app')
    .controller('userMergerModalController', UserMergerModalController);

UserMergerModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'mergeData'
];

function UserMergerModalController($scope, $uibModalInstance, mergeData) {
    $scope.potentialMergeUsers = mergeData;

    $scope.merge = function () {
        $uibModalInstance.close();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.isMergeValid = function () {
        return $scope.acknowledgeChecked;
    };

    $scope.acknowledgeChecked = false;

    $scope.DestinationUserName = 'invalid';
    if (
        mergeData != null &&
        mergeData.selectedIds != null &&
        mergeData.selectedIds.length > 0
    ) {
        $scope.DestinationUserName = mergeData.selectedIds[0].user;
    }

    $scope.mergeUserGridOptions = {
        dataSource: $scope.potentialMergeUsers.selectedIds,
        sortable: false,
        resizable: false,
        scrollable: false,
        columns: [
            {
                field: 'logondomain',
                title: 'Logon Domain'
            },
            {
                field: 'user',
                title: 'User'
            },
            {
                field: 'alias',
                title: 'User Alias'
            },
            {
                field: 'record',
                title: 'Record'
            }
        ]
    };
}
