import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import {
  ChartCheckboxContainerProps,
  ChartCheckboxProps
} from '../models/ChartCheckboxLegend.props';

export const CheckboxLegendContainerSC = styled(List)`
  display: flex;
  flex-wrap: wrap;

  .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 10px;
    width: auto;

    .MuiListItemIcon-root {
      min-width: auto;
    }

    .MuiListItemText-root {
      margin: 0;
    }

    .MuiTypography-root {
      line-height: 1.2;
      font-size: 12px;
    }
  }
`;

export const CheckboxLegendColumnsSC = styled(CheckboxLegendContainerSC)`
  .MuiListItem-root {
    min-width: 0;
    //https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-flex-basis
    flex: ${(props: ChartCheckboxContainerProps) =>
      props.columnwidth ? `1 0 ${props.columnwidth}` : '1 0 100%'};
  }
`;

export const ChartCheckboxSC = styled(Checkbox)`
  padding: 0 1px 0 3px;

  &.Mui-checked {
    color: ${(props: ChartCheckboxProps) =>
      props.checkboxcolor ? props.checkboxcolor : 'inherit'};
    background-color: ${(props: ChartCheckboxProps) =>
      props.checkmarkcolor ? props.checkmarkcolor : 'inherit'};
    margin: 4px 6px 4px -5px;
    width: 15px;
    height: 16px;
    border-radius: 3px;
  }
`;
