import httpModule, { apiService } from './httpModule';
import authModule, { authenticatedUserService } from './authModule';
import { inMemoryStore } from './inMemoryStore';

export {
    httpModule,
    apiService,
    authModule,
    authenticatedUserService,
    inMemoryStore
};

export default {
    httpModule,
    authModule,
    inMemoryStore
};
