import React, { useEffect, useState } from 'react';
import { useOfflineMeetingSettingsState } from '../hooks';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import { DefaultWidgetProps, IOfflineMeetingSettings } from '../models';
import { OfflineMeetingInput } from './';
import {
  OFFLINE_MEETING_ATTENDEES_MAX,
  OFFLINE_MEETING_ATTENDEES_MIN,
  OFFLINE_MEETING_DURATION_MAX,
  OFFLINE_MEETING_DURATION_MIN
} from '../constants';
import { Box, Tooltip, Button, IconButton, Divider } from '@mui/material';

export const OfflineMeetingSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const [hasError, setHasError] = useState<boolean>(false);
  const [newOfflineMeetingSettings, setNewOfflineMeetingSetting] =
    useState<IOfflineMeetingSettings>();
  const {
    offlineMeetingSettings,
    init,
    isLoading,
    setOfflineMeetingAttendees,
    setOfflineMeetingDuration
  } = useOfflineMeetingSettingsState();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setNewOfflineMeetingSetting(offlineMeetingSettings);
  }, [offlineMeetingSettings]);

  const handleUpdateAttendees = () => {
    if (
      offlineMeetingSettings.meetingMinAttendees !==
      newOfflineMeetingSettings.meetingMinAttendees
    ) {
      setOfflineMeetingAttendees(newOfflineMeetingSettings);
    }
  };

  const handleUpdateDuration = () => {
    if (
      offlineMeetingSettings.meetingMaxLength !==
      newOfflineMeetingSettings.meetingMaxLength
    ) {
      setOfflineMeetingDuration(newOfflineMeetingSettings);
    }
  };

  const handleChange = (name, value) => {
    if (name)
      setNewOfflineMeetingSetting((prevState) => ({
        ...prevState,
        [name]: value
      }));
  };

  return (
    <ConfigurationCardWrapper
      title={<span>Offline Meeting Settings</span>}
      actions={null}
    >
      <Box>
        <span style={{ fontSize: '16px' }}>Minimum Number of Invitees</span>
        <Tooltip
          title={
            <span>
              Only meetings that fit this criteria will be considered to
              calculate Offline Meeting Time.
            </span>
          }
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        Meetings must have a minimum of
        <OfflineMeetingInput
          name="meetingMinAttendees"
          min={OFFLINE_MEETING_ATTENDEES_MIN}
          max={OFFLINE_MEETING_ATTENDEES_MAX}
          initialValue={newOfflineMeetingSettings?.meetingMinAttendees}
          onChange={handleChange}
          isSupport={isSupport}
          setErrorStatus={setHasError}
          isDisabled={isLoading}
          endingText="invitees that have not declined an invite."
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          disabled={
            hasError ||
            isSupport ||
            isLoading ||
            offlineMeetingSettings?.meetingMinAttendees ===
              newOfflineMeetingSettings?.meetingMinAttendees
          }
          onClick={handleUpdateAttendees}
        >
          Save
        </Button>
      </Box>
      <Divider sx={{ margin: '20px 0' }} />
      <Box>
        <span style={{ fontSize: '16px' }}>Maximum Meeting Length</span>
        <Tooltip
          title={
            <span>
              Only meetings that fit this criteria will be considered to
              calculate Offline Meeting Time.
            </span>
          }
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        Meetings must be a maximum of
        <OfflineMeetingInput
          name="meetingMaxLength"
          min={OFFLINE_MEETING_DURATION_MIN}
          max={OFFLINE_MEETING_DURATION_MAX}
          initialValue={newOfflineMeetingSettings?.meetingMaxLength}
          onChange={handleChange}
          isSupport={isSupport}
          setErrorStatus={setHasError}
          isDisabled={isLoading}
          endingText="hours."
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          disabled={
            hasError ||
            isSupport ||
            isLoading ||
            offlineMeetingSettings?.meetingMaxLength ===
              newOfflineMeetingSettings?.meetingMaxLength
          }
          onClick={handleUpdateDuration}
        >
          Save
        </Button>
      </Box>
    </ConfigurationCardWrapper>
  );
};
