import React from 'react';
import { Refresh } from '@mui/icons-material';
import Button from '@mui/material/Button';

type RefreshButtonProps = {
  onRefresh: () => void;
  isRefreshing?: boolean;
};

export const RefreshButton = (props: RefreshButtonProps) => {
  const { onRefresh, isRefreshing = false } = props;

  return (
    <Button
      onClick={onRefresh}
      variant="outlined"
      disabled={isRefreshing}
      startIcon={<Refresh />}
      id="e588c77e-5668-457f-bf87-512db555f94e"
    >
      {isRefreshing ? 'Refreshing...' : 'Refresh'}
    </Button>
  );
};
