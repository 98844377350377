'use strict';

angular.module('app').controller('impactEventsConfirmDeleteModal', ImpactEventsConfirmDeleteModal);

ImpactEventsConfirmDeleteModal.$inject = [
    '$scope',
    'modalData',
    '$uibModalInstance'
];

function ImpactEventsConfirmDeleteModal(
    $scope,
    modalData,
    $uibModalInstance
) {
    $scope.selections = modalData.selections;

    $scope.confirmDelete = function() {
        modalData.deleteImpactEvents($scope.selections);
        $uibModalInstance.dismiss();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

}
