import {
  EMAIL_ALREADY_ASSOCIATED,
  API_EMAIL_NOT_UNIQUE,
  API_EMPLOYEE_ID_NOT_UNIQUE,
  API_DISPLAY_NAME_NOT_UNIQUE,
  DISPLAY_IN_USE,
  EMPLOYEE_ID_IN_USE,
  REVISION_NO_MATCH,
  DATA_STALE
} from '../constants/ErrorMessages';
import { IFieldUpdateDto } from '../models/IFieldEditDto';
import { IValueValidation, IUserIdentity } from '../models/IUserIdentity';

export function CreateDetailErrorMessage(error: any): string {
  const errorData = error?.data?.value;
  if (errorData == API_EMAIL_NOT_UNIQUE) {
    SetErrorEmail(
      EMAIL_ALREADY_ASSOCIATED,
      error?.data?.userIdentity,
      error?.data?.fieldEdit
    );
    return EMAIL_ALREADY_ASSOCIATED;
  }
  if (errorData == API_EMPLOYEE_ID_NOT_UNIQUE) {
    return EMPLOYEE_ID_IN_USE;
  }
  if (errorData == API_DISPLAY_NAME_NOT_UNIQUE) {
    return DISPLAY_IN_USE;
  }
  if (errorData.indexOf(REVISION_NO_MATCH) >= 0) {
    return DATA_STALE;
  }
  return 'Unable to save:' + errorData;
}

export function SetUserIdentityErrorMessage(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IFieldUpdateDto
): boolean {
  let setValidation = false;
  ClearEditErrors(userIdentity);
  if (errorMessage && userIdentity && fieldEdit) {
    switch (errorMessage) {
      case EMAIL_ALREADY_ASSOCIATED:
        setValidation = SetErrorEmail(errorMessage, userIdentity, fieldEdit);
        break;
      case DISPLAY_IN_USE:
        if (userIdentity.displayName) {
          userIdentity.displayName.validationFailed = true;
          userIdentity.displayName.failedExplanation = errorMessage;
          setValidation = true;
        }
        break;
      case EMPLOYEE_ID_IN_USE:
        if (userIdentity.employeeId) {
          userIdentity.employeeId.validationFailed = true;
          userIdentity.employeeId.failedExplanation = errorMessage;
          setValidation = true;
        }
        break;
    }
  }
  return setValidation;
}

function SetErrorEmail(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IFieldUpdateDto
): boolean {
  if (userIdentity?.emails) {
    let idToChange: IValueValidation = null;
    if (fieldEdit.email) {
      idToChange = userIdentity.emails.find(
        (emailId) => emailId.value == fieldEdit.email.value
      );
    }
    if (idToChange) {
      idToChange.validationFailed = true;
      idToChange.failedExplanation = errorMessage;
      return true;
    }
  }
  return false;
}

export function ClearEditErrors(userIdentity: IUserIdentity) {
  if (userIdentity?.employeeId) {
    userIdentity.employeeId.validationFailed = false;
    userIdentity.employeeId.failedExplanation = ' ';
  }
  if (userIdentity?.displayName) {
    userIdentity.displayName.validationFailed = false;
    userIdentity.displayName.failedExplanation = ' ';
  }
}
