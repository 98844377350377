import React, { useState } from 'react';
import { IGroup } from '../../common/models';
import {
  Dialog,
  DialogContent,
  FormControlLabel,
  Typography,
  DialogActions,
  Button,
  Checkbox,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import { useGroups } from '../../common/services/Groups';

// TODO: how can set up buttons to use this global style
const classes = {
  label: {
    paddingBottom: '1em',
    fontSize: '1.1em',
    paddingLeft: 0
  }
};

type DeleteGroupModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete: (groups: IGroup[], forceDelete: boolean) => Promise<boolean>;
  groups: IGroup[];
};

export default function DeleteGroupsDialog(props: DeleteGroupModalProps) {
  const { open, groups, onClose, onDelete } = props;

  const { groupsState } = useGroups();
  const { forceDelete, forceDeleteMessage, resetForceDelete } = groupsState;

  const [checked, setChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const groupsPlural = `group${groups?.length > 1 ? 's' : ''}`;
  const groupsPhrase =
    groups?.length === 1 ? groups[0]?.name ?? '' : groups?.length;

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleDelete = async (): Promise<void> => {
    try {
      setIsDeleting(true);
      const isDeleted = await onDelete(groups, forceDelete);
      if (isDeleted) {
        close();
      } else {
        setChecked(false);
        setIsDeleting(false);
      }
    } catch (error) {
      //groups state handles this
    }
  };

  const close = () => {
    onClose();
    setIsDeleting(false);
    resetForceDelete();
    setChecked(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        close();
      }}
    >
      <DialogTitle>Confirm Group Deletion</DialogTitle>
      <DialogContent>
        {forceDelete ? (
          <Typography sx={classes.label}>{forceDeleteMessage}</Typography>
        ) : (
          <></>
        )}
        <Typography sx={classes.label}>
          {'Do you want to' + (forceDelete ? ' force' : '') + ' delete '}
          <strong>{groupsPhrase}</strong>
          {` ${groupsPlural}`}?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChecked}
              disabled={isDeleting}
            />
          }
          label={
            'I acknowledge this action will' +
            (forceDelete
              ? ' delete all group-based associations (group alarms, group classifications, and blocking) and'
              : '') +
            ' delete the selected groups. This action cannot be undone.'
          }
        />
      </DialogContent>
      <DialogActions>
        {isDeleting ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="text"
              color="primary"
              onClick={close}
              autoFocus
              className="cancel-btn"
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              color="primary"
              variant="contained"
              disabled={isDeleting || !checked}
            >
              Delete
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
