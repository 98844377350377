'use strict';

export const credentialAPIService = (httpService, envConfig) => {
    const getCredentialSettings = () => {
        return httpService.get(
            envConfig.apiUrl() + '/api/settings/account/credentials'
        );
    };

    return {
        getCredentialSettings: getCredentialSettings
    };
};
