'use strict';

angular.module('app')
.directive('compileHtml', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        replace: true,
        link: function (scope, ele, attrs) {
            scope.$watch(attrs.compileHtml, function(html) {
                ele.html(html);
                $compile(ele.contents())(scope);
            });
        }
    };
}]);
