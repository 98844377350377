import { DepartmentSelection, RoleSelection } from "../models";

export const ROLE_OPTIONS: RoleSelection[] = [
    'Analyst',
    'Architect',
    'C-Level',
    'CEO/President',
    'Chancellor/Dean/Provost',
    'Consultant/Systems Integrator',
    'Coordinator/Specialist',
    'Developer/Engineer',
    'Director',
    'Manager',
    'Professor/Teacher',
    'Student',
    'VP'
  ];

  export const DEPARTMENT_OPTIONS: DepartmentSelection[] = [
    'Administration (Management)',
    'Analytics',
    'Channel Partner',
    'Engineering/R&D',
    'Finance',
    'Human Resources',
    'IT',
    'Legal',
    'Manufacturing',
    'Marketing',
    'Product Management',
    'Purchasing',
    'Sales',
    'Supply Chain & Distribution',
    'Support/Service'
  ];



  export const INFO_ICON_TEXT =
    'Subscribe to a weekly email digest summarizing the activity in your account';
