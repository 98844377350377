import React, { useState } from 'react';
import { IconButton, List, ListItemButton, Popover } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { ISettings } from './ISettings';
import SettingsProps from './SettingsMenu.props';

const Settings = (props: SettingsProps) => {
  const { menuItems, testId } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isAuthorized =
    props.isAuthorized != undefined ? props.isAuthorized : true;

    if (!(isAuthorized && menuItems && menuItems.length)) return <></>;

    return (
      <>
        <IconButton
          onClick={handleClick}
          data-testid={testId}
          color="secondary"
        >
          <SettingsIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <List component="nav">
            {menuItems.map((item: ISettings) => (
              <ListItemButton
                key={item.id}
                disabled={Boolean(item.isDisabled)}
                onClick={item.onClick}
              >
                {item.title}
              </ListItemButton>
            ))}
          </List>
        </Popover>
      </>
    );
};

export default Settings;
