import { ProductivityColors } from '../../common/constants';

export const processBarData = (barData) => {
  const labels = [];
  const productiveActivities = [];
  const unproductiveActivities = [];
  const undefinedActivities = [];

  barData.forEach((item) => {
    labels.push(item.user);
    productiveActivities.push(item.productive);
    unproductiveActivities.push(item.unproductive);
    undefinedActivities.push(item.undefined);
  });

  const dataSets = [
    {
      axis: 'y',
      label: 'Productive',
      data: productiveActivities,
      backgroundColor: ProductivityColors[1]
    },
    {
      axis: 'y',
      label: 'Unproductive',
      data: unproductiveActivities,
      backgroundColor: ProductivityColors[-1]
    },
    {
      axis: 'y',
      label: 'Undefined',
      data: undefinedActivities,
      backgroundColor: ProductivityColors[0]
    }
  ];

  const columnsConfig = {
    labels: labels,
    datasets: dataSets
  };
  return columnsConfig;
};
