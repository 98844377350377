export const noUsersReportedToday =
  'No users have reported today. Data will display when one or more users have reported for at least one minute during the work day.';

export const noUsersReportedLast30days =
  'No users have reported in the last 30 days. Data will display when one or more users have reported for at least one minute over the last 30 days.';

export const noTeamsReportedToday =
  'No users have reported today or there are no groups setup. Data will display when one or more users have reported for at least one minute during the work day and at least one group is created.';

export const noTeamsReportedLast30days =
  'No users have reported in the last 30 days or there are no groups setup. Data will display when one or more users have reported for at least one minute over the last 30 days and at least one group is created.';
