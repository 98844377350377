import React from 'react';
import { TabPanelSC } from './TabPanel.styles';
import TabPanelProps from './TabPanel.props';

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, id, testId, ...other } = props;

  return (
    <TabPanelSC
      id={id}
      role="tabpanel"
      hidden={value !== index}
      data-testid={testId}
      {...other}
    >
      {value === index && <>{children}</>}
    </TabPanelSC>
  );
};
