import React, { useEffect, useRef, useState } from 'react';
import { Container, Draggable } from '../../common/components/SmoothDnd';
import { arrayMoveImmutable } from 'array-move';
import { debounce } from 'lodash';
import { List, ListItem, ListItemIcon } from '@mui/material';
import { ICategory, IClassificationGroup } from '../../common/models';

import { useClassification } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { classificationClasses } from '../styles/Classifications.styles';
import AddGroupClassificationButton from './AddGroupClassificationButton';
import DragHandleIcon from '../../common/assets/Icons/DragHandleIcon';
import GroupDropdown from './GroupDropdown';
import CategoryDropdown from './CategoryDropdown';
import ProductivityDropdown from './ProductivityDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGroupClassifications } from '../../common/services/Classifications';
import { filterGroupsByGroupClassifications } from '../utils';
import { ActivityType } from '../../common/enums';
import {
  groupClassificationsHeader,
  classificationsCanVaryCopy,
  forExampleCopy,
  learnMoreTag
} from '../constants';
import { useCustomRowsSkeletonLoader } from '../../common/components/Grid/CustomGrid.utils';
import { IGNORE_CATEGORY_ID } from '../../common/constants';

type ClassificationGroupContainerProps = {
  id: number;
  type: ActivityType;
  categories: ICategory[];
  isDisabled?: boolean;
};

export default function ClassificationGroupContainer(
  props: ClassificationGroupContainerProps
) {
  const { id, type, categories, isDisabled } = props;

  const [classificationGroups, setClassificationGroups] =
    useState<IClassificationGroup[]>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { groupClassificationService } = useGroupClassifications();
  const { groupsState } = useClassification();
  const { groups, init } = groupsState;

  

  const notificationService = useNotifications();

  const debounceUpdate = useRef(
    debounce(
      (debouncedClassificationGroups: IClassificationGroup[]) =>
        handleUpdate(debouncedClassificationGroups),
      3000
    )
  ).current;

  const activitySkeletonLoader = useCustomRowsSkeletonLoader(4, 3, false);

  useEffect(() => {
    const getClassificationGroups = async () => {
      setClassificationGroups([]);
      setIsLoading(true);
      try {
        const response =
          await groupClassificationService.getActivityGroupClassifications(
            id,
            type
          );

        if (response) {
          if (response.length === 0) {
            addEmptyClassificationGroup();
          } else {
            //Sort by ascending priority, 1 being the most prioritized
            const priorityOrderedClassificationGroups = response.sort(
              (a, b) => a.priority - b.priority
            );
            setClassificationGroups(priorityOrderedClassificationGroups);
          }

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        notificationService.error(
          'Unable to retrieve Classification Activity Details.'
        );
      }
    };
    if (id && type) {
      getClassificationGroups();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    init(); //groups list
  }, [init]);

  const addEmptyClassificationGroup = () => {
    const emptyClassificationGroup: IClassificationGroup = {
      id: id,
      type: type,
      groupId: 0,
      priority: classificationGroups?.length
        ? classificationGroups.length + 1
        : 0,
      categoryId: null,
      productivity: 0
    };
    setClassificationGroups((prevState) => [
      ...prevState,
      emptyClassificationGroup
    ]);
    //Set to disable until new row is filled out
    setIsAddButtonDisabled(true);
  };

  const onDropdownChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    classificationGroup: IClassificationGroup,
    dropdownType: 'Group' | 'Category' | 'Productivity'
  ) => {
    const value = event.target.value;
    const index = classificationGroups.indexOf(classificationGroup);

    switch (dropdownType) {
      case 'Group': {
        classificationGroups[index].groupId = Number.parseInt(value);
        break;
      }
      case 'Category': {
        classificationGroups[index].categoryId = value;
        break;
      }
      case 'Productivity': {
        classificationGroups[index].productivity = Number.parseInt(value);
        break;
      }
      default: {
        break;
      }
    }

    setClassificationGroups([...classificationGroups]);
    handleUpdate();
  };

  const onReorderDrop = ({ removedIndex, addedIndex }) => {
    //Reorder the array
    const reorderedArray = arrayMoveImmutable(
      classificationGroups,
      removedIndex,
      addedIndex
    );

    //Prioritize by new order
    reorderedArray.forEach((cg, index) => {
      cg.priority = index + 1;
    });

    //Set new array
    setClassificationGroups([...reorderedArray]);

    //Update order in db after a certain amount of time not reordering
    debounceUpdate([...classificationGroups]);
  };

  //Handle delete
  const removeDraggableListItem = async (index: number) => {
    try {
      const deleted = classificationGroups[index];
      const cg = [...classificationGroups];

      if (rowComplete(deleted) && isAddButtonDisabled) return;

      setIsAddButtonDisabled(true);
      cg.splice(index, 1);

      setClassificationGroups(cg);
      await groupClassificationService.deleteActivityGroupClassifications(
        [deleted],
        type
      );
    } catch (error) {
      notificationService.error('Unable to delete group classification.');
    }
    setIsAddButtonDisabled(false);
  };

  //Handle adding and updating
  const handleUpdate = async (
    debouncedClassificationGroups?: IClassificationGroup[]
  ) => {
    const dataSource = classificationGroups
      ? classificationGroups
      : debouncedClassificationGroups;

    if (rowsComplete(dataSource)) {
      try {
        groupClassificationService.updateActivityGroupClassifications(
          dataSource,
          type
        );
      } catch (error) {
        notificationService.error('Unable to update group classifications');
      } finally {
        handleButtonDisabled();
      }
    }
  };

  const handleButtonDisabled = () => {
    //If any of the dropdowns still need a value, prevent adding another list row
    rowsComplete(classificationGroups)
      ? setIsAddButtonDisabled(false)
      : setIsAddButtonDisabled(true);
  };

  //TODO: Refactor to use filter/every/some. It was causing issues
  const rowsComplete = (classificationGroupsData: IClassificationGroup[]) => {
    for (let i = 0; i < classificationGroupsData.length; i++) {
      const cg = classificationGroupsData[i];
      if (!rowComplete(cg)) return false;
    }
    return true;
  };

  const rowComplete = (cg: IClassificationGroup) => {
    let complete = true;
    //categoryId is not required for some accounts
    if (cg.groupId === 0) {
      complete = false;
    }
    if (cg.productivity === 0 && cg.categoryId !== IGNORE_CATEGORY_ID) {
      complete = false;
    }
    return complete;
  };

  return (
    <>
      <div style={classificationClasses.groupClassificationsCopy}>
        <div style={classificationClasses.groupClassificationsContainer}>
          <div style={classificationClasses.groupClassificationHeader}>
            {groupClassificationsHeader}
          </div>
          <div
            data-testid={'classifications-can-vary-copy'}
            style={classificationClasses.groupClassificationsText}
          >
            {classificationsCanVaryCopy}
            {learnMoreTag}
          </div>
          <div style={classificationClasses.groupClassificationsText}>
            {forExampleCopy}
          </div>
        </div>
      </div>

      <div style={classificationClasses.groupClassificationsContainer}>
        {isLoading ? (
          activitySkeletonLoader
        ) : (
          <div style={classificationClasses.draggableListContainer}>
            <List>
              <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={onReorderDrop}
                shouldAcceptDrop={() => !isDisabled}
              >
                {classificationGroups &&
                  groups?.length > 0 &&
                  categories?.length > 0 &&
                  classificationGroups.map((classificationGroup, index) => {
                    return (
                      <Draggable key={classificationGroup.priority}>
                        <ListItem
                          disabled={isDisabled}
                          sx={classificationClasses.draggableList.item}
                        >
                          <ListItemIcon
                            className="drag-handle"
                            sx={classificationClasses.draggableList.itemIcon}
                          >
                            <DragHandleIcon
                              style={classificationClasses.iconAlignment}
                            />
                          </ListItemIcon>
                        </ListItem>
                        <ListItem
                          disabled={isDisabled}
                          sx={classificationClasses.draggableList.item}
                        >
                          <GroupDropdown
                            variant="outlined"
                            groups={
                              groups &&
                              filterGroupsByGroupClassifications(
                                classificationGroups,
                                groups,
                                classificationGroup
                              )
                            }
                            groupId={classificationGroup.groupId.toString()}
                            className={{
                              ...classificationClasses.orderableDropdown,
                              ...classificationClasses.draggableList.selectWidth
                            }}
                            onChange={(e) =>
                              onDropdownChange(e, classificationGroup, 'Group')
                            }
                            isDisabled={isDisabled}
                          />
                        </ListItem>
                        <ListItem
                          disabled={isDisabled}
                          sx={classificationClasses.draggableList.item}
                        >
                          <CategoryDropdown
                            isVisible={classificationGroup.groupId != 0}
                            variant="outlined"
                            categories={categories}
                            categoryId={classificationGroup.categoryId}
                            className={{
                              ...classificationClasses.orderableDropdown,
                              ...classificationClasses.draggableList.selectWidth
                            }}
                            onChange={(e) =>
                              onDropdownChange(
                                e,
                                classificationGroup,
                                'Category'
                              )
                            }
                            isDisabled={isDisabled}
                          />
                        </ListItem>
                        <ListItem
                          disabled={isDisabled}
                          sx={classificationClasses.draggableList.item}
                        >
                          <ProductivityDropdown
                            isVisible={classificationGroup.groupId != 0}
                            variant="outlined"
                            productivityStatus={
                              classificationGroup.productivity
                            }
                            className={{
                              ...classificationClasses.orderableDropdown,
                              ...classificationClasses.draggableList.selectWidth
                            }}
                            onChange={(e) =>
                              onDropdownChange(
                                e,
                                classificationGroup,
                                'Productivity'
                              )
                            }
                            categoryId={classificationGroup.categoryId}
                            isDisabled={isDisabled}
                          />
                        </ListItem>
                        <ListItem
                          disabled={
                            (rowComplete(classificationGroup) &&
                              isAddButtonDisabled) ||
                            isDisabled
                          }
                          sx={classificationClasses.draggableList.item}
                        >
                          <ListItemIcon
                            onClick={() => removeDraggableListItem(index)}
                            sx={{
                              ...classificationClasses.draggableList.itemIcon,
                              ...classificationClasses.draggableList.trashButton
                            }}
                          >
                            <DeleteIcon />
                          </ListItemIcon>
                        </ListItem>
                      </Draggable>
                    );
                  })}
              </Container>
            </List>
          </div>
        )}
        <AddGroupClassificationButton
          disabled={isAddButtonDisabled || isDisabled}
          onClick={addEmptyClassificationGroup}
        />
      </div>
    </>
  );
}
