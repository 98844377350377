import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import { IRoleAccessViewModel } from '../../../common/models';
import { AuthorizationContext } from '../../../common/services/Authorization';
import { Routes } from '../../../common/constants';
import { PermissionLevel } from '../../../common/enums';
import { roleTableClasses } from '../../styles/RolesTable.styles';

interface rowsProps {
  cellType: string;
  row: IRoleAccessViewModel;
  showLimitedDesc: boolean;
  handleChange: any;
  busy: boolean;
}

export const RolesTableCell: React.FC<rowsProps> = ({
  cellType,
  row,
  showLimitedDesc,
  handleChange,
  busy
}) => {
  const authService = React.useContext(AuthorizationContext);
  const permission = authService.pageAuthorization(Routes.RoleAccess);
  const populateToolTipTitle = () => {
    let title = '';
    switch (cellType) {
      case 'adminCheck':
        title = row[cellType].disabled
          ? showLimitedDesc
            ? 'Always Access'
            : 'Always Selected'
          : '';
        break;
      default:
        title = row[cellType].disabled ? 'Access Not Available' : '';
        break;
    }
    return title;
  };

  return (
    <React.Fragment>
      <TableCell colSpan={1} width="16%" align="center">
        <Box display="flex" p={0} bgcolor="background.paper" height={20}>
          <Box p={0} width="46%"></Box>
          <Tooltip title={populateToolTipTitle()} placement="right">
            <Box
              p={0}
              flexShrink={1}
              width="8%"
              display="flex"
              justifyContent="center"
              m={0}
              alignItems="center"
            >
              {cellType === 'adminCheck' && row.itemType !== 3 ? (
                <Checkbox
                  sx={roleTableClasses.checkBoxes}
                  checked={row.adminCheck.disabled || row.adminCheck.checked}
                  color="primary"
                  id={`${row.resource}_adminCheck`}
                  name={'adminCheck'}
                  disabled={
                    row.adminCheck.disabled ||
                    busy ||
                    permission === PermissionLevel.Read
                  }
                  onChange={handleChange}
                />
              ) : (
                ''
              )}
              {cellType === 'configCheck' && row.itemType !== 3 ? (
                <Checkbox
                  sx={roleTableClasses.checkBoxes}
                  checked={row.configCheck.checked}
                  color="primary"
                  id={`${row.resource}_configCheck`}
                  name={'configCheck'}
                  disabled={
                    row.configCheck.disabled ||
                    busy ||
                    permission === PermissionLevel.Read
                  }
                  onChange={handleChange}
                />
              ) : (
                ''
              )}
              {cellType === 'powerCheck' && row.itemType !== 3 ? (
                <Checkbox
                  sx={roleTableClasses.checkBoxes}
                  checked={row.powerCheck.checked}
                  color="primary"
                  disabled={
                    row.powerCheck.disabled ||
                    busy ||
                    permission === PermissionLevel.Read
                  }
                  name={'powerCheck'}
                  id={`${row.resource}_powerCheck`}
                  onChange={handleChange}
                />
              ) : (
                ''
              )}
              {cellType === 'viewerCheck' && row.itemType !== 3 ? (
                <Checkbox
                  sx={roleTableClasses.checkBoxes}
                  checked={row.viewerCheck.checked}
                  color="primary"
                  disabled={
                    row.viewerCheck.disabled ||
                    busy ||
                    permission === PermissionLevel.Read
                  }
                  name={'viewerCheck'}
                  id={`${row.resource}_viewerCheck`}
                  onChange={handleChange}
                />
              ) : (
                ''
              )}
            </Box>
          </Tooltip>
          <Box
            fontWeight="fontWeightBold"
            display="flex"
            sx={roleTableClasses.readOnly}
            width="46%"
            p={1}
            m={0}
            alignItems="center"
          >
            {row[cellType].showReadOnly ? 'Read Only' : ''}
          </Box>
        </Box>
      </TableCell>
    </React.Fragment>
  );
};
