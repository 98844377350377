import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccountWizardContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 33px;
`;

export const StyledHeader = styled('h3')`
  align-items: center;
`;

interface StyledSubtitleProps {
  $paddingBottom?: string;
  noPadding?: boolean;
}

export const StyledSubtitle = styled('h6', {
  shouldForwardProp: (prop) => prop !== '$paddingBottom' && prop !== 'noPadding'
})<StyledSubtitleProps>(({ $paddingBottom, noPadding }) => ({
  alignItems: 'center',
  paddingBottom: $paddingBottom ? $paddingBottom : noPadding ? 'none' : '33px'
}));

export const AccountWizardTextContainer = styled(Box)`
  text-align: center;
  display: flex;
  justify-content: center;
  padding: ${(props) =>
    props.padding === 'trim' ? '0vh' : '25vh 10vh 25vh 10vh'};
`;

export const SimpleHeader = styled(StyledHeader)`
  letter-spacing: -1.5px;
  flex: 1;
`;

export const SimpleSubtitle = styled(StyledSubtitle)`
  flex: 1;
`;
interface SimpleMediumSubtitleProps {
  $fontSize?: string;
  $noMarginBottom?: boolean;
}

export const SimpleMediumSubtitle = styled(
  StyledSubtitle
)<SimpleMediumSubtitleProps>(({ $fontSize, $noMarginBottom }) => ({
  fontSize: $fontSize ? `${$fontSize}px !important` : '20px',
  marginBottom: $noMarginBottom ? '0' : '10px',
  flex: 1
}));

interface SimpleStepWizardContainerProps {
  gap?: string;
  paddingTop?: string;
}

export const SimpleStepWizardContainer = styled(
  Box
)<SimpleStepWizardContainerProps>(({ gap, paddingTop }) => ({
  display: 'flex',
  justifyContent: 'left',
  flexWrap: 'wrap',
  gap: gap ? gap : '20px',
  paddingTop: paddingTop ? paddingTop : '5vh',
  paddingBottom: '5vh',
  flexDirection: 'column'
}));
export const AccountWizardStepContainer = styled(Box)`
  margin-left: 0px;
`;

interface AccountWizardRadioTextProps {
  $radioTextWeight?: string;
}

export const AccountWizardRadioText = styled(
  'span'
)<AccountWizardRadioTextProps>(({ $radioTextWeight }) => ({
  fontWeight: $radioTextWeight ? $radioTextWeight : 'normal'
}));
