import { GroupIconType } from '../components/GroupType';
import { GroupMix } from '../enums';

export const filterIconsMap = {
  AllUsers: {
    User: GroupIconType.AllUsers,
    Computer: GroupMix.Computer,
    ['All Users']: GroupIconType.AllUsers,
    ['All Computers']: GroupMix.Computer
  },
  Group: {
    User: GroupMix.User,
    Both: GroupMix.Mixed,
    Computer: GroupMix.Computer
  },
  User: GroupIconType.IndividualUser,
  Alias: GroupIconType.IndividualUser,
  Computer: GroupIconType.IndividualComputer
};
