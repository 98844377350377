import theme from '../../../../common/theme/theme';

export const cardTopActivityClasses = {
    root: {},
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    none: {
        color: theme.palette.grey.A100
    },
    flexChild: {
        minWidth: 0,
        flex: '0 1 100%',
        marginRight: theme.spacing(1)
    },
    grow: {
        minWidth: 0,
        flex: '1 1 auto'
    },
    flexFixed: {
        flex: '0 0 auto'
    },
    buttonIcon: {
        fontSize: `${theme.atCustom.fontSize.titleIcon}px !important`
    },
    activityLink: { color: 'inherit !important' },
    bold: {
        fontWeight: 600
    },
    disabled: {
        color: '#bdbdbd !important',
        cursor: 'not-allowed !important'
    }
};
