import React, { useCallback, useEffect, useState } from 'react';
import { IQuickAction } from '../../../common/models/IQuickAction';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { quickActionsWidgetStyles as classes } from '../../styles/QuickActions.styles';

type EditQuickActionsModalProps = {
  quickActionContent: IQuickAction[];
  open: boolean;
  onClose: () => void;
  onSubmit: (selected: any[]) => void;
  onCancel: () => void;
};

export const EditQuickActionsModal = (props: EditQuickActionsModalProps) => {
  const { open, onClose, quickActionContent, onSubmit, onCancel } = props;

  const [checklist, setChecklist] = useState<IQuickAction[]>();
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (quickActionContent) {
      setChecklist(quickActionContent);
    }
  }, [quickActionContent]);

  const handleChange = useCallback((itemId) => {
    setChecklist((prevState) => {
      const newState = prevState.map((menuItem) => {
        const updatedMenuItem = menuItem;
        if (updatedMenuItem?.id === itemId) {
          updatedMenuItem.selected = !updatedMenuItem.selected;
        }

        return updatedMenuItem;
      });

      const checked = newState.filter((action) => action.selected);

      if (checked.length == 0) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }

      return newState;
    });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
      sx={classes.modalContainer}
    >
      <Box sx={classes.editModal}>
        <DialogTitle>Edit Quick Actions</DialogTitle>
        <DialogContent>
          <Stack>
            {checklist?.map((item) => {
              return (
                <FormControlLabel
                  sx={classes.listItem}
                  key={item.id}
                  control={
                    <Checkbox
                      color="primary"
                      checked={item.selected}
                      name={item.name}
                      value={item.id}
                      onChange={() => handleChange(item.id)}
                    />
                  }
                  label={item.name}
                />
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant="text" color="primary" autoFocus>
            Cancel
          </Button>
          <Tooltip
            title="At least one quick action must be selected"
            placement="top"
            disableHoverListener={!isSaveDisabled}
          >
            <span>
              <Button
                onClick={() => onSubmit(checklist)}
                disabled={isSaveDisabled}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
