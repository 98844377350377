import PassiveLabels from "./PassiveLabels";

class RealTime {
  constructor(data) {
    this.computerId = data.computerId;
    this.desc = data.desc;
    this.device = data.device;
    this.devicealias = data.devicealias;
    this.exec = data.exec;
    this.ip = data.ip;
    this.ldom = data.ldom;
    this.os = data.os;
    this.osvn = data.osvn;
    this.pdom = data.pdom;
    this.publicip = data.publicip;
    this.snid = data.snid;
    this.time = data.time;
    this.title = data.title;
    this.url = data.url;
    this.user = data.user;
    this.userId = data.userId;
    this.username = data.username;
  }

  isActive() {
      return !this.isPassive();
  }

  isPassive() {
    return (
        this &&
        this.title &&
        (this.title.toLowerCase() === PassiveLabels.title ||
        this.title.toLowerCase() === PassiveLabels.lockedTitle)
      );
  }

  // Not Active is defined by the absence of a RealTime resposne
}

export default RealTime;