import { IRoute } from '../../../../../models/IRoute';

export const appAccountStorage: IRoute = {
  name: 'app.account.storage',
  stateDefinition: {
    url: '/account/storage',
    redirectTo: 'app.settings.useragents'
  }
};

export default appAccountStorage;
