import React, { useEffect, useState } from 'react';
import {
  useReportFilterStore,
  setReportFilters
} from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { getAccountSetting } from '../../common/helpers/accountSettings';
import { useTeamFilterState } from '../../common/components/ReportFilters/hooks/useTeamFilterState';
import {
  getEndOfDayTimestamp,
  getStartOfDayTimestamp,
  shouldEnableTimeSlider
} from '../../common/components/ReportFilters/utils/dateRange.utils';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { UserComputerFilter } from '../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../common/components/ReportFilters/components/DateRangeFilter';
import { ProductivityFilter } from '../../common/components/ReportFilters/components/ProductivityFilter';
import { TeamFilter } from '../../common/components/ReportFilters/components/TeamFilter';
import { RefreshButton } from '../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../common/components/ReportFilters/components/AcademyButton';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import { DownloadProvider } from '../../common/services';
import { useRefreshState } from '../../common/components/ReportFilters/hooks';
import { DateTimeSlider } from '../../common/components/ReportFilters/components/DateTimeSlider';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderRightColumn
} from '../../common/styles/app.component.styles';

export const ReportFiltersPlayground = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const { groupId, dates, users, productivity, fromTimestamp } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const { allTeamSuggestions } = useTeamFilterState();
  const { refreshTimestamp, handleRefresh } = useRefreshState();

  const enableTimeSlider = shouldEnableTimeSlider(fromDate, toDate);
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  useEffect(() => {
    if (!enableTimeSlider) {
      setReportFilters({ fromTimestamp: null });
    }
  }, [enableTimeSlider]);

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: { xs: 'block', sm: 'inline-block' } }}>
          <ReportHeaderInline>
            <DateRangeFilter dates={dates} onSubmitFilter={setReportFilters} />
          </ReportHeaderInline>
          {allTeamSuggestions?.length > 0 && (
            <ReportHeaderInline>
              <TeamFilter
                groupId={groupId}
                onSubmitFilter={setReportFilters}
                allTeamSuggestions={allTeamSuggestions}
              />
            </ReportHeaderInline>
          )}
          <ReportHeaderInline>
            <UserComputerFilter
              onSubmitFilter={setReportFilters}
              users={users}
            />
          </ReportHeaderInline>

          <ReportHeaderInline>
            <ProductivityFilter
              productivity={productivity}
              onSubmitFilter={setReportFilters}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <RefreshButton
              onRefresh={handleRefresh}
              isRefreshing={isRefreshing}
            />
          </ReportHeaderInline>
        </Box>
        <ReportHeaderRightColumn sx={{ float: { xs: 'none', md: 'right' } }}>
          <ReportHeaderInline>
            <AcademyButton />
          </ReportHeaderInline>
          <ReportHeaderInline sx={{ mr: 0 }}>
            <DownloadProvider>
              <DownloadAgentButton />
            </DownloadProvider>
          </ReportHeaderInline>
        </ReportHeaderRightColumn>
      </Box>
      <Box sx={{ mt: 4, display: { xs: 'none', md: 'block' } }}>
        <DateTimeSlider
          min={getStartOfDayTimestamp(fromDate, currentIanaTimeZone)}
          max={getEndOfDayTimestamp(toDate, currentIanaTimeZone)}
          fromTimestamp={fromTimestamp}
          isDisabled={!enableTimeSlider}
          setFilterValues={setReportFilters}
        />
      </Box>
    </>
  );
};
