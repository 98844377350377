import { styled } from '@mui/material/styles';
import { Button, Popper } from '@mui/material';

export const ButtonSC = styled(Button)<{ hideselectedoption: number }>(
  ({ hideselectedoption }) => ({
    '.MuiButton-startIcon': {
      margin: hideselectedoption === 1 ? '0' : '0 8px 0 -4px'
    }
  })
);

export const PopperSC = styled(Popper)`
  z-index: 9;
`;
