import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DownloadProvider } from '../../common/services';
import {
  ComputersProvider,
  UseComputersContext,
  UsersProvider
} from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { Role, RoleAccessKeys, SortDirection } from '../../common/enums';
import { localStorageItems } from '../../common/constants';
import {
  IAllAgentHealth,
  IComputer,
  IComputerAgentHealth,
  IFetchAllComputers
} from '../models';
import {
  defaultComparer,
  filterAndRepeatDataBySpecificParamsAndQuery,
  filterDataBySpecificParamsAndQuery,
  formatDate,
  getDateTimeFormat,
  getParsedLocalStorageItem,
  getTimeAgo,
  getTimeAgoByTimeZone,
  periodDelimitedNumberComparer,
  sorter
} from '../../common/helpers';
import { IAccountSettings, IGridColumn } from '../../common/models';
import { COMPUTERS_INITIAL_VIEW_STATE } from '../utils/Computers.view.utils';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar';
import { TagBar } from '../../common/components/TagBar';
import { AdvancedFilter } from '../../common/components/AdvancedFilter';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import { DeleteComputersConfirmationModal } from '../components/DeleteComputersConfirmationModal';
import { UninstallComputersConfirmationModal } from '../components/UninstallComputersConfirmationModal';
import { ScheduledDeletesModal } from '../components/ScheduledDeletesModal';
import { ComputerAdvancedSearchForm } from '../components/ComputerAdvancedSearchForm';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { useAuthorization } from '../../common/services/Authorization';
import { AgentHealthModal } from '../components/AgentHealthModal';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { TimeAgo } from '../../common/components/TimeAgo';
import authHelper from '../../common/helpers/authorization';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import GetAppIcon from '@mui/icons-material/GetApp';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AdvancedFilterButtonContainer,
  AgentHealthStatusCircle,
  AgentVersionHeaderTableCell,
  ComputerHeaderBarRightActionButtons,
  ComputersGridContainer,
  computersViewStyles as classes,
  GridRowActionButtonContainer,
  HeaderBarActionButtons,
  HeaderBarContainer,
  HeaderBarLeftActionButtons,
  HeaderBarTopComponentsButtons,
  HeaderBarTopComponentsContainer,
  SearchBarContainer,
  TotalsGrouper
} from '../styles';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { ScheduledDeleteModes } from '../enums';
import moment from 'moment';
import { computerTextFields } from '../enums/AdvancedSearch';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

export function ComputersView() {
  const history = useUIRouterHistory();
  const { computersService } = UseComputersContext();

  const { screenshotsAllowed = false } = getPrivacySettings();

  const notificationService = useNotifications();
  const scheduledDeleteModalRef = useRef(null);
  const { dropDownButtonOptions: dropDownFilterButtonOptions } =
    COMPUTERS_INITIAL_VIEW_STATE;
  const authorizationService = useAuthorization();
  const userIsSuperAdminOrAdmin = authorizationService.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin
  ]);
  const userIsSuperAdminOrSupportBasic = authorizationService.hasAnyRole([
    Role.SuperAdmin,
    Role.SupportBasic
  ]);
  const userIsAdminOrAdvancedSupport = authorizationService.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin,
    Role.SupportAdvanced
  ]);

  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );

  const softDeletesEnabled = authorizationService.hasFeature(
    BundleFlag.SoftDeletes
  );

  const onStatusClick = async (dataItem, e) => {
    const event = e || window.event;
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    agentHealthComputerRef.current = dataItem;
    setOpenAgentHealthModal(true);
  };

  const getComputerGridColumns = (): IGridColumn[] => {
    if (!userIsSuperAdminOrSupportBasic) {
      return computerGridColumnsBasic;
    }
    const columnAgentStatus: IGridColumn = {
      field: 'globalStatus',
      fieldName: 'globalStatus',
      headerName: 'Status',
      type: 'string',
      isSortable: true,
      width: '5%',
      template: (dataItem) => {
        return (
          <>
            {!healthIsLoading && (
              <Box onClick={(e) => onStatusClick(dataItem, e)}>
                <AgentHealthStatusCircle
                  color={computersService.getHealthStatusColor(
                    dataItem.globalStatus
                  )}
                />
              </Box>
            )}
            {healthIsLoading && (
              <Box>
                <CircularProgress size="25px" />
              </Box>
            )}
          </>
        );
      },
      hidden: null
    };
    const newColumns = [...computerGridColumnsBasic];
    newColumns.splice(6, 0, columnAgentStatus);
    return newColumns;
  };

  const computerGridColumnsBasic: IGridColumn[] = [
    {
      field: 'id',
      fieldName: 'id',
      headerName: 'ID',
      type: 'string',
      isSortable: true,
      width: '7%'
    },
    // {
    //   field: 'windowsDomain',
    //   headerName: 'Primary Domain',
    //   hidden: true,
    //   type: 'string',
    //   isSortable: true,
    //   width: '20%'
    // },
    {
      field: 'domainComputerAlias',
      fieldName: 'domainComputerAlias',
      headerName: `Primary Domain${String.fromCharCode(92)}Computer (Alias)`,
      type: 'string',
      isSortable: true,
      width:
        screenshotsAllowed && hasAlarmsScreenshotsReportsRoleAccess
          ? '28%'
          : '43%'
    },
    // {
    //   field: 'os',
    //   headerName: 'OS',
    //   hidden: true,
    //   type: 'string',
    //   isSortable: true,
    //   width: '10%'
    // },
    // {
    //   field: 'alias',
    //   headerName: 'Alias',
    //   hidden: true,
    //   type: 'string',
    //   isSortable: true,
    //   width: '10%'
    // },
    {
      field: 'agentVersion',
      headerName: 'Agent Version',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          <AgentVersionHeaderTableCell>
            {getOSIcon(dataItem.os)} <span>{dataItem.agentVersion}</span>
          </AgentVersionHeaderTableCell>
        );
      }
    },
    {
      field: 'firstLog',
      headerName: 'First Log Record',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        let firstLog = dataItem.firstLog;
        if (softDeletesEnabled) {
          firstLog = moment(dataItem.firstLog).toDate();
        }
        return <>{formatDate(firstLog, localDateTimeFormat)}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log Record',
      isSortable: true,
      type: 'date',
      width: '15%',
      template: (dataItem) => {
        let timeAgo = null;
        let lastLog = dataItem.lastLog;
        if (softDeletesEnabled) {
          lastLog = moment(dataItem.lastLog).toDate();
          timeAgo = getTimeAgo(lastLog);
        } else {
          timeAgo = getTimeAgoByTimeZone(lastLog, accountSettings);
        }
        return (
          <TimeAgo
            lastLog={lastLog}
            learnMoreLink="https://support.activtrak.com/hc/en-us/articles/4407833915803-Managing-Computer-Agents"
            timeAgo={timeAgo}
            localDateTimeFormat={localDateTimeFormat}
          />
        );
      }
    },
    {
      field: 'lastss',
      headerName: 'Last Screenshot',
      isSortable: true,
      hidden:
        (!screenshotsAllowed || !hasAlarmsScreenshotsReportsRoleAccess) ?? true,
      type: 'date',
      width: '15%',
      template: (dataItem) => {
        let timeAgo = null;
        if (softDeletesEnabled && dataItem.lastss) {
          const lastScreenShot = moment(dataItem.lastss).toDate();
          timeAgo = getTimeAgo(lastScreenShot);
        } else {
          timeAgo = getTimeAgoByTimeZone(dataItem.lastss, accountSettings);
        }
        return (
          <Tooltip
            title={formatDate(dataItem.lastss, localDateTimeFormat)}
            placement="bottom"
          >
            <div>{timeAgo}</div>
          </Tooltip>
        );
      }
    },
    {
      type: 'string',
      isSortable: false,
      width: '5%',
      template: (dataItem) => {
        const { id, selected } = dataItem;
        return (
          <GridRowActionButtonContainer>
            <DropdownOptionsButton
              id="78bbb98d-ec86-429b-a4c3-ee6e0e0be52a"
              icon={<MoreVertIcon />}
              dropDownOptions={[
                { label: 'Delete' },
                { label: 'Uninstall Agent' }
              ]}
              onChangeOption={(e, option) => {
                const { label } = option;

                if (label === 'Delete') {
                  deleteComputerCallbackRef.current = async () => {
                    await deleteComputersByIds([id]);
                  };
                  setOpenDeleteComputersConfirmationModal(true);
                } else if (label === 'Uninstall Agent') {
                  uninstallComputerRef.current = dataItem;
                  setOpenUninstallComputersConfirmationModal(true);
                }
              }}
              hideSelectedOption
              disableSelectedItem
              disabled={selected || !userIsSuperAdminOrAdmin}
              sx={{ width: '24px', minWidth: 'auto' }}
            />
          </GridRowActionButtonContainer>
        );
      }
    }
  ];

  const [computers, setComputers] = useState<IComputer[]>(
    COMPUTERS_INITIAL_VIEW_STATE.reportingComputers
  );
  const [selectedComputers, setSelectedComputers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.searchTerm
  );
  const [viewIsLoading, setViewIsLoading] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.viewIsLoading
  );
  const [healthIsLoading, setHealthIsLoading] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.viewIsLoading
  );
  const [filterButtonSelection, setFilterButtonSelection] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.filterButtonSelection
  );
  const [gridOrderBy, setGridOrderBy] = useState();
  const [gridOrderDirection, setGridOrderDirection] = useState();
  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setGridOrderBy(newOrderBy);
    setGridOrderDirection(newOrderDirection);
  }, []);

  const [
    openDeleteComputersConfirmationModal,
    setOpenDeleteComputersConfirmationModal
  ] = useState<boolean>(false);
  const [
    openUninstallComputersConfirmationModal,
    setOpenUninstallComputersConfirmationModal
  ] = useState<boolean>(false);
  const [openAgentHealthModal, setOpenAgentHealthModal] =
    useState<boolean>(false);
  const [tabInputData, setTabInputData] = useState([]);
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const deleteComputerCallbackRef = useRef(() => {});
  const uninstallComputerRef = useRef<IComputer>();
  const agentHealthComputerRef = useRef<IComputer>();

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const [openScheduledDeletesModal, setOpenScheduledDeletesModal] =
    useState<boolean>(false);

  const localDateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);

  const filteredComputers = useMemo(() => {
    let newFilteredComputers;
    if (tabInputData.length) {
      newFilteredComputers = computers;
      tabInputData.forEach((tabInput) => {
        const { field, fieldValue } = tabInput;
        newFilteredComputers = filterDataBySpecificParamsAndQuery(
          newFilteredComputers,
          [field],
          fieldValue
        );
      });
    } else {
      newFilteredComputers = filterDataBySpecificParamsAndQuery(
        computers,
        COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams,
        searchTerm
      );
      if (filterButtonSelection != 0) {
        newFilteredComputers = filterDataBySpecificParamsAndQuery(
          newFilteredComputers,
          ['os'],
          dropDownFilterButtonOptions.map((o) => o.label)[
            filterButtonSelection - 1
          ]
        );
      }
    }
    const sortedData = sorter(
      newFilteredComputers,
      gridOrderDirection,
      gridOrderBy,
      gridOrderBy == 'agentVersion'
        ? periodDelimitedNumberComparer
        : defaultComparer
    );
    return sortedData;
  }, [
    computers,
    searchTerm,
    tabInputData,
    filterButtonSelection,
    dropDownFilterButtonOptions,
    gridOrderDirection,
    gridOrderBy
  ]);

  const getSearchLabelPlaceholder = () => {
    return 'Search by Primary Domain, Computer, or Computer Alias';
  };

  const getFilteredComputersForAutocomplete = () => {
    const filteredData = filterAndRepeatDataBySpecificParamsAndQuery(
      computers,
      getComputerGridColumns(),
      COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams,
      searchTerm
    );

    const newDataMap = {};
    const uniqueGroups = {};
    for (let i = 0; filteredData.length > i; i++) {
      const { id, type, label } = filteredData[i];
      if (type !== COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams[0]) {
        newDataMap[id + type] = filteredData[i];
      } else {
        if (!uniqueGroups[label]) {
          newDataMap[id + type] = filteredData[i];
          uniqueGroups[label] = true;
        }
      }
    }

    return Object.keys(newDataMap).map(function (key) {
      return newDataMap[key];
    });
  };

  const handleChangeAutocompleteSearchBar = (value) => {
    setSearchTerm(value);
  };

  const handleChangeFilterSelector = (event, option, index) => {
    setFilterButtonSelection(index);
  };

  const resetSelectedComputers = () => {
    setSelectedComputers([]);
    setComputers(
      computers.map((item) => {
        return { ...item, selected: false };
      })
    );
  };

  const handleClickRowGrid = useCallback(
    (rowItem) => {
      history.push('app.settings.computeragents_id', { id: rowItem.id });
    },
    [history]
  );

  const handleComputerGridChange = () => {
    //selectedData comes from the viewable list.  use our own filtered computer list instead
    const gridComputersSelected = computers.filter((item) => item.selected);
    setSelectedComputers(gridComputersSelected);
  };

  const onSubmitAdvancedFilter = useCallback(
    (formData) => {
      const tabBarData = [];
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          const computerField = computerTextFields.find(
            (gridColumn) => gridColumn.name == key
          );
          tabBarData.push({
            field: key,
            fieldName: computerField?.label,
            fieldValue: formData[key]
          });
        }
      });
      setTabInputData(tabBarData);
    },
    [setTabInputData]
  );

  const computerGridRowDisableCondition = useCallback((rowItem) => {
    if (rowItem.scheduledActionId === undefined) {
      return false;
    }

    return !(rowItem.scheduledActionId === 0);
  }, []);

  const handleDeleteComputersClick = async () => {
    deleteComputerCallbackRef.current = async () => {
      const computerIds = selectedComputers.map((computer) => {
        const { id } = computer;
        return id;
      });
      try {
        await deleteComputersByIds(computerIds);
        resetSelectedComputers();
      } catch (response) {
        //what should happen here?
      }
    };
    setOpenDeleteComputersConfirmationModal(true);
  };

  const cancelDeletesCallback = async () => {
    refreshComputers();
  };

  const deleteComputersByIds = async (computerIds: number[]) => {
    try {
      await computersService.deleteComputers(computerIds);
      notificationService.success(
        `Success! ${computerIds.length} computers deleted.`
      );

      if (!softDeletesEnabled && !accountSettings.delayedDelete) {
        const newComputers = computers.filter(
          (computer) => !computerIds.includes(computer.id)
        );
        setComputers(newComputers);
        refreshComputers();
      } else {
        const copiedComputers = [...computers];

        copiedComputers.forEach((computer) => {
          if (computerIds.includes(computer.id)) {
            //delayed delete just disables the row
            computer.scheduledActionId = 1;
          }
        });
        setComputers(copiedComputers);
        refreshComputers();
      }
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  const uninstallComputersByIds = async (computersItems: IComputer[]) => {
    try {
      await computersService.uninstallComputers(computersItems);
      notificationService.success(
        `Success! ${computersItems.length} computers uninstalled.`
      );

      const computerIds = computersItems.map((computer) => computer.id);
      if (!softDeletesEnabled && !accountSettings.delayedDelete) {
        const newComputers = computers.filter(
          (computer) => !computerIds.includes(computer.id)
        );
        setComputers(newComputers);
        refreshComputers();
      } else {
        const copiedComputers = [...computers];
        copiedComputers.forEach((computer) => {
          if (computerIds.includes(computer.id)) {
            //delayed delete just disables the row
            computer.scheduledActionId = 1;
          }
        });
        setComputers(copiedComputers);
      }
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  const handleDownload = () => {
    handleOnProgBarStart();
    computersService.downloadCSV().then((response) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(
        new Blob([response.data], { type: 'text/csv;charset=utf-8' })
      );
      reader.onloadend = () => {
        const result = reader.result as string;
        const downloadLink = document.createElement('a');
        downloadLink.href = decodeURIComponent(result);
        downloadLink.download = 'computerAgents.csv';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        handleOnProgBarEnd();
      };
    });
  };

  const pendingComputerDeletionsExist = useMemo(() => {
    let computerDeletionExists = false;
    computers.forEach((computer) => {
      if (
        computer.scheduledActionId !== undefined &&
        computer.scheduledActionId !== 0
      ) {
        computerDeletionExists = true;
      }
    });
    return computerDeletionExists;
  }, [computers]);

  const handleOnProgBarStart = () => {
    setShowProgbarDwnld(true);
  };

  const handleOnProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(true);
  };

  const handleScheduledDeletesClick = async () => {
    setOpenScheduledDeletesModal(true);
    scheduledDeleteModalRef.current.loadScheduledActions();
  };

  const [showProgbarDwnld, setShowProgbarDwnld] = useState<boolean>(false);
  const [endProgbarDwnld, setEndProgbarDwnld] = useState<boolean>(false);

  const retrieveAgentHealth = (apiComputers) => {
    setViewIsLoading(false);
    setComputers(apiComputers);
    setHealthIsLoading(true);
    computersService
      .fetchComputerAgentHealth()
      .then((response: IAllAgentHealth) => {
        appendComputerData(apiComputers, response.computers);
      })
      .catch(() => {
        //what should happen here?
      })
      .finally(() => {
        setComputers(apiComputers);
        setHealthIsLoading(false);
        setViewIsLoading(false);
      });
  };

  const refreshComputers = useCallback(() => {
    setViewIsLoading(true);
    computersService
      .fetchAllComputers()
      .then((response: IFetchAllComputers) => {
        if (userIsSuperAdminOrSupportBasic) {
          retrieveAgentHealth(response.computers);
        } else {
          setComputers(response.computers);
          setViewIsLoading(false);
        }
      })
      .catch(() => {
        //what should happen here?
      });
    // this file requires too much refactoring to add the additional dependencies needed here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computersService]);

  useEffect(() => {
    refreshComputers();
  }, [refreshComputers]);

  function appendComputerData(
    apiComputers: IComputer[],
    healthStatuses: IComputerAgentHealth[]
  ) {
    // Append computer data with health status
    if (healthStatuses) {
      healthStatuses.forEach(function (healthStatus) {
        const nameMatches = apiComputers.filter(
          (computer) => computer.computer === healthStatus.name
        );
        if (nameMatches) {
          nameMatches.forEach(function (computer) {
            computer.healthStatus = healthStatus;
            computer.globalStatus = computersService.getGlobalStatus(
              computer.healthStatus
            );
          });
        }
      });
    }
  }

  const resetProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(false);
  };

  return (
    <Box>
      {showProgbarDwnld && (
        <div style={classes.dispProgress}>
          <div>Generating Export</div>
          <div style={classes.uploadFileProgressContainer}>
            <div style={classes.uploadFileProgressBar} />
            <LinearProgress />
          </div>
        </div>
      )}
      {endProgbarDwnld && (
        <div style={classes.dispResultBar}>
          <Alert
            variant="filled"
            severity="info"
            sx={classes.confAlert}
            onClose={() => {
              resetProgBarEnd();
            }}
          >
            <p style={{ color: 'black', margin: '0px 0px 0px 0px' }}>
              Download Complete{' '}
            </p>
          </Alert>
        </div>
      )}
      <HeaderBarContainer>
        <HeaderBarTopComponentsContainer>
          <HeaderBarTopComponentsButtons />
          <SearchBarContainer>
            {tabInputData.length ? (
              <TagBar tabBarData={tabInputData} />
            ) : (
              <AutocompleteSearchBar
                autocompleteOptions={getFilteredComputersForAutocomplete()}
                searchLabelPlaceholder={getSearchLabelPlaceholder()}
                onChange={handleChangeAutocompleteSearchBar}
              />
            )}
            {searchTerm ? null : (
              <AdvancedFilterButtonContainer>
                {tabInputData.length ? (
                  <IconButton
                    onClick={() => setTabInputData([])}
                    color="secondary"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null}
                <AdvancedFilter
                  showModal={showAdvancedFilterModal}
                  setShowModal={setShowAdvancedFilterModal}
                  form={
                    <ComputerAdvancedSearchForm
                      modalData={tabInputData}
                      onSubmit={(formData) => {
                        setShowAdvancedFilterModal(false);
                        onSubmitAdvancedFilter(formData);
                      }}
                    />
                  }
                />
              </AdvancedFilterButtonContainer>
            )}
          </SearchBarContainer>
        </HeaderBarTopComponentsContainer>
        <HeaderBarActionButtons>
          <HeaderBarLeftActionButtons>
            <Button
              startIcon={<DeleteIcon />}
              onClick={handleDeleteComputersClick}
              disabled={
                !userIsSuperAdminOrAdmin ||
                selectedComputers == null ||
                selectedComputers?.length == 0
              }
              color="secondary"
            >
              Delete
            </Button>
            {pendingComputerDeletionsExist && userIsAdminOrAdvancedSupport ? (
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleScheduledDeletesClick}
                color="secondary"
              >
                Scheduled Deletes
              </Button>
            ) : null}
          </HeaderBarLeftActionButtons>
          <ComputerHeaderBarRightActionButtons>
            <TotalsGrouper>
              <span>{`TOTAL COMPUTERS: `}</span>
              {filteredComputers.length}
            </TotalsGrouper>
            <DropdownOptionsButton
              id="68a49dce-0f04-479e-9512-93ace3333a3d"
              icon={<FilterListIcon />}
              indexOption={filterButtonSelection}
              setIndexOption={setFilterButtonSelection}
              dropDownOptions={[
                { label: 'OS' },
                ...dropDownFilterButtonOptions
              ]}
              onChangeOption={handleChangeFilterSelector}
              disableSelectedItem
            />
            <Button
              startIcon={<GetAppIcon />}
              onClick={handleDownload}
              color="secondary"
            >
              Export
            </Button>
            <DownloadAgentButton />
          </ComputerHeaderBarRightActionButtons>
        </HeaderBarActionButtons>
      </HeaderBarContainer>
      <ComputersGridContainer>
        {(viewIsLoading || filteredComputers.length > 0) && (
          <CustomGrid
            isLoading={viewIsLoading}
            isVirtualized={true}
            data={filteredComputers}
            columns={getComputerGridColumns()}
            onClickRow={handleClickRowGrid}
            uniqueRowProp="id"
            initialSortField="id"
            initialSortDirection={SortDirection.Ascending}
            hasBulkSelection
            featureDisabled={!userIsSuperAdminOrAdmin}
            rowDisableCondition={computerGridRowDisableCondition}
            onSelectAllClick={handleComputerGridChange}
            onSortOrder={onSortOrder}
          />
        )}
        {!viewIsLoading && filteredComputers.length == 0 && (
          <span>{'No computer agents found.'}</span>
        )}
      </ComputersGridContainer>
      <DeleteComputersConfirmationModal
        open={openDeleteComputersConfirmationModal}
        onClose={() => setOpenDeleteComputersConfirmationModal(false)}
        onDelete={deleteComputerCallbackRef.current}
      />
      <UninstallComputersConfirmationModal
        open={openUninstallComputersConfirmationModal}
        onClose={() => setOpenUninstallComputersConfirmationModal(false)}
        computer={uninstallComputerRef.current}
        onDelete={() => uninstallComputersByIds([uninstallComputerRef.current])}
      />
      <AgentHealthModal
        open={openAgentHealthModal}
        selectedComputer={agentHealthComputerRef.current}
        onClose={() => setOpenAgentHealthModal(false)}
        dateTimeFormat={localDateTimeFormat}
      />
      <ScheduledDeletesModal
        mode={ScheduledDeleteModes.Computers}
        open={openScheduledDeletesModal}
        onClose={() => setOpenScheduledDeletesModal(false)}
        onCancel={cancelDeletesCallback}
        ref={scheduledDeleteModalRef}
      />
    </Box>
  );
}

export const ComputersViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <ComputersProvider>
          <ComputersView />
        </ComputersProvider>
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
