import React, { useEffect } from 'react';
import _ from 'lodash';
import { useRoleAccess } from '../services';
import {
  IRoleAccessViewModel,
  IFilterAccess,
  rowSelection
} from '../../common/models';
import { useNotifications } from '../../common/services/Notifications';
import { LoadApplication } from '../../common/services/Application/AppLoad';
import { RolesTable } from './RolesTable/RolesTable';

type rowsProps = {
  rows: IRoleAccessViewModel[];
  showLimitedDesc: boolean;
  resetDefault: boolean;
  setResetDefault: any;
  dataRetentionTerm: number | null;
};

export const RoleAccessFilterTable = (props: rowsProps) => {
  const {
    rows,
    showLimitedDesc,
    resetDefault,
    setResetDefault,
    dataRetentionTerm
  } = props;
  const [rolesAccess, setRolesAccess] = React.useState(rows);
  const [busy, setBusy] = React.useState(false);
  const { roleAccessService } = useRoleAccess();
  const notificationService = useNotifications();

  //TODO: refactor these useEffects which duplicate calling getAllFilters api
  useEffect(() => {
    const getSettings = async () => {
      setBusy(true);
      try {
        const filters = await roleAccessService.getAllFilters();
        setRolesAccess(
          roleAccessService.mapFilterAccess(filters, dataRetentionTerm)
        );
      } catch (error) {
        console.error(`ActivTrak Error: ${error}`);
      } finally {
        setBusy(false);
      }
    };

    getSettings();
  }, [roleAccessService, dataRetentionTerm]);

  useEffect(() => {
    const getDefault = async () => {
      setBusy(true);

      try {
        const allRows = await roleAccessService.getAllFilters();
        const newRows = roleAccessService.mapFilterAccess(
          allRows,
          dataRetentionTerm
        );
        setRolesAccess(newRows);
      } catch (error) {
        console.error(`ActivTrak Error: ${error}`);
      } finally {
        setBusy(false);
      }
    };

    if (resetDefault) {
      getDefault();
      setResetDefault(false);
    }
  }, [resetDefault, roleAccessService, setResetDefault, dataRetentionTerm]);

  const checkSelected = (access: rowSelection): boolean =>
    (access.disabled === false &&
      access.showReadOnly === false &&
      access.checked === true) ||
    access.alwaysAccess;

  const handleChange = async (object) => {
    if (busy) return;
    setBusy(true);

    const meta = object.target.id.split(`_`);
    const resource = meta[0];
    const roleCheck = meta[1];

    const newAccess = _.map(rolesAccess, (access) => {
      if (access.resource === resource) {
        access.adminCheck.checked =
          roleCheck === 'adminCheck'
            ? object.target.checked
            : access.adminCheck.checked;
        access.configCheck.checked =
          roleCheck === 'configCheck'
            ? object.target.checked
            : access.configCheck.checked;
        access.powerCheck.checked =
          roleCheck === 'powerCheck'
            ? object.target.checked
            : access.powerCheck.checked;
        access.viewerCheck.checked =
          roleCheck === 'viewerCheck'
            ? object.target.checked
            : access.viewerCheck.checked;
      }
      return access;
    });

    setRolesAccess(newAccess);

    const data: IFilterAccess[] = _.map(newAccess, (access) => {
      const roles = [
        checkSelected(access.adminCheck) ? 'Admin' : '',
        checkSelected(access.configCheck) ? 'Configurator' : '',
        checkSelected(access.powerCheck) ? 'User' : '',
        checkSelected(access.viewerCheck) ? 'TeamViewer' : ''
      ];

      return {
        filter: access.resource,
        roles: _.filter(roles, (role) => role !== '')
      } as IFilterAccess;
    });

    const response = await roleAccessService.setFilters(data);

    //reload the application to get the new account settings
    await LoadApplication();

    //TODO: delete this event when old report filters are removed
    const roleDateFiltersUpdatedEvent = new CustomEvent(
      'roleDateFiltersUpdated',
      {
        detail: { roleDateFilters: response }
      }
    );
    window.dispatchEvent(roleDateFiltersUpdatedEvent);

    setBusy(false);

    notificationService.success(`Success! Date Filter saved.`);
  };
  return (
    <RolesTable
      busy={busy}
      rows={rolesAccess}
      showLimitedDesc={showLimitedDesc}
      handleChange={handleChange}
    />
  );
};
