import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ModalLoadingView from './ModalLoadingView';
import { ErrorMsgDisplay } from './ErrorMsgDisplay';
import { errorMsg } from '../../constants';
import { IDetailsListModalProps, IReportModalListItem } from '../../models';
import { ExpandableList } from '../../../common/components/List/ExpandableList';
import { ModalContent } from './ModalContent';
import { ModalHeader } from '../../styles/templates.styles';

export const DetailsListModal = (props: IDetailsListModalProps) => {
  const {
    open,
    data,
    includeTMLinks,
    modalTitle,
    modalBlurb,
    isLoading,
    onInit,
    isModalError,
    height,
    loaderHeight
  } = props;

  const [showButton, setShowButton] = useState<boolean>(false);
  const [OriginalList, setOriginalList] =
    useState<IReportModalListItem[]>(null);
  const [listDisplay, setListsDisplay] = useState<IReportModalListItem[]>(null);
  const [isFullList, setIsFullList] = useState<boolean>(false);

  useEffect(() => {
    //don't call api unless tooltip is opened
    if (open && onInit) {
      onInit();
    }
  }, [onInit, open]);

  useEffect(() => {
    if (data) {
      setOriginalList(data);
      if (data.length > 20) {
        setShowButton(true);
        setIsFullList(false);
      } else {
        setShowButton(false);
        setIsFullList(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (OriginalList?.length) {
      const list = [...OriginalList];
      if (isFullList) {
        setListsDisplay(list);
      } else {
        setListsDisplay(list.slice(0, 20));
      }
    }
  }, [isFullList, OriginalList]);

  const handleViewToggleState = useCallback((val) => {
    if (val) {
      setIsFullList(true);
    } else {
      setIsFullList(false);
    }
  }, []);

  return (
    <Box maxHeight={height}>
      <ModalHeader variant="h6">{modalTitle}</ModalHeader>
      {!isLoading && !isModalError && data?.length === 0 && (
        <Typography>
          {includeTMLinks
            ? 'No teams meeting criteria'
            : 'No users meeting criteria'}
        </Typography>
      )}
      {!isLoading && !isModalError && listDisplay?.length > 0 && (
        <ExpandableList showButton={showButton} onClick={handleViewToggleState}>
          {modalBlurb && <Typography>{modalBlurb}</Typography>}
          <ModalContent list={listDisplay} includeTMLinks={includeTMLinks} />
        </ExpandableList>
      )}
      <ModalLoadingView
        isLoading={isLoading}
        isModalError={isModalError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} notWidget={true} />}
        loaderHeight={loaderHeight}
      />
    </Box>
  );
};
