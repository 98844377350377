import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledBox } from '../StyledBoxes';
import {
  AccountWizardContainer,
  StyledHeader,
  StyledSubtitle
} from '../../styles';
import { AccountWizardStep2Modal } from './AccountWizardStep2Modal';

import stepsData from '../../constants/stepsData.json';
import { notifError, notifWarn } from '../../../common/constants';
import { useAccountWizardContext } from '../../services';
import { AccountWizardStep3Content } from './AccountWizardStep3Content';

export const AccountWizardStep2 = () => {
  const textData = stepsData.step2;
  const inputIds = ['1:0:team', '1:1:employee'];
  const { formValues, skipStepTwo, setSkipStepTwo } = useAccountWizardContext();

  useEffect(() => {
    const currentSelections = formValues.PageSelection.join(',');
    if (
      currentSelections.includes('0:0:remote') ||
      currentSelections.includes('0:3:monitor')
    ) {
      setSkipStepTwo(true);
    } else {
      setSkipStepTwo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !skipStepTwo ? (
    <Box style={{ paddingBottom: '60px' }}>
      <StyledHeader>{textData.title}</StyledHeader>
      <StyledSubtitle>{textData.subtitle}</StyledSubtitle>
      <AccountWizardContainer>
        <StyledBox
          backgroundColors={[notifError, notifWarn]}
          textArea={textData.styledBoxes}
          inputId={inputIds}
          type="radio"
          name="step2"
          icons={[
            {
              iconName: 'TeamLevelIcon'
            },
            {
              iconName: 'IndividualIcon'
            }
          ]}
        />
      </AccountWizardContainer>
      <AccountWizardStep2Modal />
    </Box>
  ) : (
    <AccountWizardStep3Content />
  );
};
