import React from 'react';
import { ExportWrapper } from '../utils/exportWrapper';
import { ExportContainerSC } from '../styles';

export const ExportButtonContainer = () => {
  return (
    <ExportContainerSC>
      <ExportWrapper />
    </ExportContainerSC>
  );
};
