import React from 'react';
import { Box, Typography } from '@mui/material';
import { LongLabel, LongTextToolTip } from '../styles/supportPortal.styles';
import { ICompanyNameDisplayProps } from '../models/IComponentProps';

export const CompanyNameDisplay = (props: ICompanyNameDisplayProps) => {
  const { companyName } = props;

  return (
    <Box>
      {companyName ? (
        companyName.length > 15 ? (
          <LongTextToolTip arrow title={companyName} placement="top">
            <LongLabel variant="h6">{companyName}</LongLabel>
          </LongTextToolTip>
        ) : (
          <Typography variant="h6">{companyName}</Typography>
        )
      ) : (
        <p>
          <em>Company N/A</em>
        </p>
      )}
    </Box>
  );
};
