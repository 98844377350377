'use strict';

angular.module('app')
    .service('supportTicketsService', SupportTicketsService);

SupportTicketsService.$inject = ['$http', 'envConfig'];

function SupportTicketsService($http, envConfig) {
    this.getLatestUpdates = function () {
        return $http.get(envConfig.apiUrl() + '/api/support/latestupdates');
    };
}
