import React, { useEffect } from 'react';
import { ActivityLogHeaderProps } from '../models/ActivityLogTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useAuthorization } from '../../../common/services/Authorization';
import { getAccountSetting } from '../../../common/helpers/accountSettings';
import { Role } from '../../../common/enums';
import { useHasDataEmit } from '../../common/hooks/useHasDataEmit';
import {
  getEndOfDayTimestamp,
  getStartOfDayTimestamp,
  shouldEnableTimeSlider
} from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ColumnFilter } from '../../../common/components/ReportFilters/components/ColumnFilter';
import { DateTimeSlider } from '../../../common/components/ReportFilters/components/DateTimeSlider';

import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const ActivityLogHeader = (props: ActivityLogHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users, fromTimestamp } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const hasData: boolean = useHasDataEmit();
  const authorizationService = useAuthorization();

  const enableTimeSlider = shouldEnableTimeSlider(fromDate, toDate) && hasData;

  useEffect(() => {
    if (!enableTimeSlider) {
      setReportFilters({ fromTimestamp: null });
    }
  }, [enableTimeSlider]);

  const ignoreDateRestrictions: boolean = authorizationService.hasAnyRole([
    Role.SuperAdmin,
    Role.SupportAdvanced
  ]);
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');

  return (
    <>
      <Box>
        <ReportHeaderLeftColumn>
          <ReportHeaderInline>
            <DateRangeFilter
              dates={dates}
              onSubmitFilter={setReportFilters}
              ignoreRestrictions={ignoreDateRestrictions}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <UserComputerFilter
              onSubmitFilter={setReportFilters}
              users={users}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
          </ReportHeaderInline>
        </ReportHeaderLeftColumn>
        <ReportHeaderRightColumn>
          <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
            <AcademyButton />
          </ReportHeaderInline>
          <ReportHeaderInline
            sx={{ mr: 1, display: { xs: 'none', md: 'block' } }}
          >
            <ColumnFilter />
          </ReportHeaderInline>
          <ReportHeaderInline
            sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
          >
            <ExportButtonContainer />
          </ReportHeaderInline>
        </ReportHeaderRightColumn>
      </Box>
      <Box
        sx={{
          m: 3,
          display: { xs: 'none', md: 'block' }
        }}
      >
        <DateTimeSlider
          min={getStartOfDayTimestamp(fromDate, currentIanaTimeZone)}
          max={getEndOfDayTimestamp(toDate, currentIanaTimeZone)}
          fromTimestamp={fromTimestamp}
          isDisabled={!enableTimeSlider}
          setFilterValues={setReportFilters}
        />
      </Box>
    </>
  );
};
