import React from 'react';
import { take } from 'lodash';
import { IActivity } from '../../common/models';
import { ActivityUrl } from '../../common/components/ActivityUrl';
import Box from '@mui/material/Box';
import GroupCard from './GroupCard';
import Link from '../../common/components/Link/Link';

type GroupClassificationProps = {
  activities: IActivity[];
};

const MAX_ACTIVITIES = 3;

export default function GroupClassification(props: GroupClassificationProps) {
  const { activities } = props;

  if (activities?.length === 0) {
    return <></>;
  }

  return (
    <GroupCard title={`Group Classifications (${activities?.length})`}>
      <Box display="flex" flexDirection="column">
        {take(activities, MAX_ACTIVITIES).map((activity) => (
          <ActivityUrl
            activity={activity}
            isDomain={true}
            key={activity?.uniqueId}
          />
        ))}
      </Box>
      <div>
        <Link
          to="app.settings.classification.tab"
          params={{
            activeTab: 'classified',
            filter: 'groupClassified'
          }}
        >
          See all
        </Link>
      </div>
    </GroupCard>
  );
}
