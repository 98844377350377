import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import { createDataSource } from '_app/insights/atInsightsGoalBarService';
import { Metric, Team } from '_app/insights/models';

class FilterDropdown {
    constructor(
        { id, label, field },
        initialValue,
        modelId,
        pageId,
        filterUpdatedCallback,
        envConfig,
        showNotification
    ) {
        const path =
            field === 'metric'
                ? `/api/insights/metrics/${modelId}/${pageId}`
                : '/api/insights/groups';
        const mapClass = field === 'metric' ? Metric : Team;
        var self = this;
        this.id = id;
        this.heapId = `id_${id}`;
        this.label = label;
        this.field = field;
        this.multiSelect = field !== 'metric';
        this.canClear = field !== 'metric' && !this.multiSelect;
        this.required = field === 'metric' || field === 'team';
        this.filterUpdatedCallback = filterUpdatedCallback;
        this.initialValue = initialValue;
        this.options = {
            dataSource: createDataSource(
                path,
                mapClass,
                envConfig,
                showNotification
            ),
            optionLabel: 'Select…',
            dataTextField: 'text',
            dataValueField: 'value',
            tagMode: 'single',
            tagTemplate:  
                '<div style="max-width: 250px"><div style="overflow: hidden; text-overflow: ellipsis">is #:data.values.join(" or ")#</div></div>',
            itemTemplate: 
                '<div class="insight-goals-item checkbox"><input type="checkbox" id="item-{{data.text}}" tabindex="6"/> <label for="item-{{data.text}}">#:data.text#</label></div> ',
            optionLabelTemplate:
                '<span class="dropdown-placeholder">Select…</span>',
            change: function (value) {
                var items = this.ul.find("li");
                self.checkInputs(items);
                self.filterUpdated(value.sender.value());
            },
            dataBound: function() {
                var items = this.ul.find("li");
                setTimeout(function() {
                    self.checkInputs(items);
                });
            },
            autoBind: false
        };
        if (this.multiSelect) {
            this.options.value = initialValue ? initialValue.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/): "";
        } else {
            this.model = initialValue ? initialValue.split(',') : "";
        }

        this.options.dataSource.read().then(() => this.filterUpdated(this.options.value , false));
    }

    checkInputs(elements) {
        elements.each(function() {
            var element = $(this);   
            element.addClass("insight-goals-item");
            var input = element.children("div").children("input");
            input.prop("checked", element.hasClass("k-state-selected"));
        });
    };

    filterUpdated(value, displayValue, sendEvent = true) {
        const filterDropdown = this;
        const isMultiSelect = filterDropdown.multiSelect;
        var filterId = null;
        var displayValue = null;
        const filter =
            _.reduce(
                filterDropdown.options.dataSource.data(),
                function (result, option, key) {
                    if(isMultiSelect){
                        if (value.includes(option.value.toString())) {                       
                            result.push(option.value.toString());
                            if(value.length == 1){
                                filterId = option.id;            
                            }
                        }
                    } else {
                        if (option.value.toString() === filterDropdown.model) {      
                            filterId = option.id;          
                            displayValue = option.text;       
                            result.push(option.value);
                        }
                    }
                    return result
                },
                []
            ) || {};

        filterDropdown.filterUpdatedCallback(
            {
                label: filterDropdown.label,
                value: filter.join() || "",
                text: displayValue,
                id: filterId,
                defaultValue: filter.defaultValue
            },
            sendEvent
        );
    }

    restrictRun() {
        return this.required && atHelperFunctions.isEmpty(this.model);
    }

    clearField() {
        this.model = null;
        this.filterUpdated();
    }
}

export default FilterDropdown;
