import { Role, RoleAccessKeys } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsSettingsTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsSettings: IRoute = {
  name: 'app.reports.insightsSettings',
  stateDefinition: {
    url: '/insightssettings',
    template: angularJsTemplate,
    controller: 'insightsSettingsCtrl',
    data: { pageTitle: 'Insights - Configuration' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.Configuration,
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.SupportAdvanced
    ],
    read: [Role.SupportBasic],
    featureFlags: [FeatureFlag.InsightsAvailable]
  }
};

export default appReportsInsightsSettings;
