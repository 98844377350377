class ReportDetail {
    constructor(
        type,
        {
            pageSize,
            serverPaging = false,
            schema,
            pageSizeService,
            dataFunction,
            dataCallback
        }
    ) {
        if (typeof dataFunction !== 'function') {
            console.error(
                `ActivTrak Error: Supplied data function is not a function.\nData Function:`,
                dataFunction
            );
            return;
        }

        const dataSourceSettings = {
            transport: {
                read: dataFunction
            },
            pageSize,
            serverPaging,
            schema
        };

        this.type = type;
        this.dataSource = new kendo.data.DataSource(dataSourceSettings);
        this.pageSizeService = pageSizeService;
        this.dataCallback = dataCallback;
    }

    refresh(options) {
        if (this.pageSizeService) {
            this.pageSizeService
                .dataSourceReload(this.dataSource)
                .then(this.dataCallback);
        } else if (this.dataSource) {
            this.dataSource.read(options).then(this.dataCallback);
        } else {
            console.error(
                `ActivTrak Error: Unable to refresh report detail ${this.type} due to missing datasource.`
            );
        }
    }
}

export default ReportDetail;
