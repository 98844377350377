import React from 'react';
import _ from 'lodash';
import { IActivityUrl } from '../../models';
import { ActivityType } from '../../enums';
import { Box, Button, Tooltip } from '@mui/material';
import ActivityIcon from './ActivityIcon';
import { primaryBlue } from '../../constants';

const classes = {
  activityUrl: {
    fontWeight: 400,
    color: primaryBlue,
    padding: 0,
    justifyContent: 'left',
    letterSpacing: 'auto !important',
    background: 'none',
    '&:hover': {
      background: 'none'
    },
    '&:focus': {
      background: 'none'
    }
  },
  activityLink: {
    textDecoration: 'underline'
  },
  activitySpan: {
    height: '24px',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingRight: '5px'
  }
};

const ActivityUrl = (props: IActivityUrl) => {
  const { activity, isDomain, formattedName, isTruncated } = props;

  //not using a deconstructor here because activity can be null
  const name = activity?.name;
  const type = activity?.type;

  if (!activity || !name) return <></>;

  const displayName = formattedName || name;

  if (!type || type === ActivityType.Unknown) return <>{displayName}</>;

  const txtContainerClass = isTruncated ? 'truncate' : '';

  const createUrlLink = (url) => {
    if (!_.startsWith(url, 'https://') && !_.startsWith(url, 'http://')) {
      return '//' + url;
    }

    return url;
  };

  const href =
    type === ActivityType.Website
      ? createUrlLink(name)
      : 'http://google.com/search?q=' + name + '%20Application';

  return (
    <Tooltip title={displayName} placement="bottom">
      {/*using button, not a, tag to avoid event propagation issue when used with a clickable row*/}
      <Button
        href={href}
        target="_blank"
        rel="noopener"
        sx={classes.activityUrl}
        onClick={(e) => e.stopPropagation()}
      >
        <Box className={txtContainerClass}>
          <Box component="span" sx={classes.activitySpan}>
            <ActivityIcon activity={activity} isDomain={isDomain} />
          </Box>{' '}
          <Box sx={classes.activityLink} component="span">
            {displayName}
          </Box>
        </Box>
      </Button>
    </Tooltip>
  );
};

export default ActivityUrl;
