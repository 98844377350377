import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { alarmDetailsTemplate as angularJsTemplate } from '../../templates';

export const appAlarmsDetails: IRoute = {
  name: 'app.alarms.details',
  stateDefinition: {
    url: '/alarms/:id?url&title&type&executable&description&duplicate&name',
    params: {
      alarm: null,
      url: null,
      title: null,
      executable: null,
      description: null,
      duplicate: null,
      name: null,
      returnFilter: null,
      returnState: null,
      riskReportParams: null,
      fromAuditLog: null
    },
    template: angularJsTemplate,
    controller: 'alarmDetailsCtrl',
    data: { pageTitle: 'Alarms' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsConfiguration,
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.SupportIntermediate
    ],
    read: [Role.SupportBasic, Role.User]
  }
};

export default appAlarmsDetails;
