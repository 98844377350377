import { ApiRoutes } from '../constants/routesApi';
import { getReportingServiceUrl, BaseClient } from '../../common/config';

export default class ChartsDemoService extends BaseClient {
  public getGanttUser = async (queryParams?: any) => {
    let url = ApiRoutes.reports.getUserGantt;

    if (queryParams) {
      const qs = new URLSearchParams(queryParams);
      url += `?${qs}`;
    }

    const response = await this.apiService.get(url, {
      ...getReportingServiceUrl()
    });
    return response?.data;
  };

  public getProductivity = async (queryParams?: any) => {
    let url = ApiRoutes.reports.getProductivity;

    if (queryParams) {
      const qs = new URLSearchParams(queryParams);
      url += `?${qs}`;
    }

    const response = await this.apiService.get(url, {
      ...getReportingServiceUrl()
    });
    return response?.data;
  };

  public getTopApplications = async (queryParams?: any) => {
    let url = ApiRoutes.reports.getApplications;

    if (queryParams) {
      const qs = new URLSearchParams(queryParams);
      url += `?${qs}`;
    }

    const response = await this.apiService.get(url, {
      ...getReportingServiceUrl()
    });
    return response?.data;
  };
}
