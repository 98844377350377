import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import SkeletonLoaderProps from './SkeletonLoader.props';

export const SkeletonLoader = (props: SkeletonLoaderProps) => {
  const {
    className,
    animation = 'wave',
    height,
    width,
    variant = 'rectangular',
    testId
  } = props;

  return (
    <Skeleton
      className={className}
      animation={animation}
      height={height}
      width={width}
      variant={variant as 'text' | 'rectangular' | 'circular'} // Fix: Cast variant to the allowed values
      data-testid={testId}
    />
  );
};
