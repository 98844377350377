// TODO: Move feature flags to the BE post_login call

import { Role } from '../enums';
import { BundleFlag } from '../enums/BundleFlag';
import { FeatureFlag } from '../enums/FeatureFlag';
import authorization from '../helpers/authorization';
import { IAccountSettingsDto, IRealtimeSettingsDto } from '../models';
import { IFeatureFlags } from '../models/IFeatureFlags';
import {
  getAccountPlanStore,
  isAccountPlanInTrial
} from '../stores/accountPlanStore';

const hasRealtimeScreenshotsFeatureFlag = (realtimeSettings) => {
  return (
    !realtimeSettings ||
    realtimeSettings.screenshotFeatureFlag === undefined ||
    Boolean(realtimeSettings.screenshotFeatureFlag)
  );
};

const hasRealtimeThumbnailsFeatureFlag = (realtimeSettings, settings) => {
  return Boolean(
    hasRealtimeScreenshotsFeatureFlag(realtimeSettings) &&
      realtimeSettings?.thumbnailFeatureFlag &&
      settings.bundleFlags.isPaidFunctionalityEnabled &&
      !authorization.hasAnyRole([Role.Viewer, Role.SupportBasic])
  );
};

const hasReactivateFeatureFlag = (settings: IAccountSettingsDto) => {
  return settings.isSubscriptionCancelled && !isAccountPlanInTrial();
};

const hasCommandCenterAppAccessSettingsFeatureFlag = () => {
  return (
    authorization.hasAnyRole([
      Role.CommandCenter,
      Role.CommandCenterEnterprise
    ]) &&
    authorization.hasAnyRole([
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.Admin
    ])
  );
};

const hasInsightsAvailableFeatureFlag = (settings: IAccountSettingsDto) => {
  return (
    settings?.insightsEnabled &&
    !settings?.insightsSetting?.inProgress &&
    Boolean(settings?.insightsSetting?.onBoardRequestTime)
  );
};

const hasInsightsSchedulingFeatureFlag = (settings: IAccountSettingsDto) => {
  return (
    settings.insightsEnabled &&
    hasInsightsAvailableFeatureFlag(settings) &&
    !settings?.insightsSetting?.schedulingDisabled
  );
};

const hasShowActivationFeatureFlag = (settings: IAccountSettingsDto) => {
  const { usedLicenses = 0 } = settings;
  return (
    !authorization.hasAnyRole([
      Role.CommandCenter,
      Role.CommandCenterEnterprise
    ]) && usedLicenses === 0
  );
};

const hasSupportTicketsFeatureFlag = (settings: IAccountSettingsDto) => {
  const { planAge } = getAccountPlanStore();
  return planAge <= 30 || settings.bundleFlags[BundleFlag.PaidFunctionality];
};

// Account based feature flag overrides
// These overrides should be used as a temporary solution during POC/Beta work
// or as a stop gap for a fully developed feature setting.
// Account numbers are given as strings to match the account number in the settings object
const accountFeatureFlagOverrides: Partial<
  Record<FeatureFlag, { false: string[]; true: string[] }>
> = {
  [FeatureFlag.ShowOfflineMeetingData]: {
    false: ['686707'],
    true: []
  },
  [FeatureFlag.ShowLocationData]: {
    false: ['686707'],
    true: []
  }
};

// All accounts have Offline Meeting Data feature flag except for accounts listed in the accountFeatureFlagOverrides
const hasOfflineMeetingDataFeatureFlag = (settings: IAccountSettingsDto) => {
  return !accountFeatureFlagOverrides[
    FeatureFlag.ShowOfflineMeetingData
  ].false.includes(settings.account);
};

// All accounts have Location Data feature flag except for accounts listed in the accountFeatureFlagOverrides
const hasLocationDataFeatureFlag = (settings: IAccountSettingsDto) => {
  return !accountFeatureFlagOverrides[
    FeatureFlag.ShowLocationData
  ].false.includes(settings.account);
};

export const createFeatureFlags = (
  settings: IAccountSettingsDto,
  realtimeSettings: IRealtimeSettingsDto
): IFeatureFlags => {
  return {
    reactivateFeatureFlag: hasReactivateFeatureFlag(settings),
    realtimeScreenshotsFeatureFlag:
      hasRealtimeScreenshotsFeatureFlag(realtimeSettings),
    realtimeThumbnailsFeatureFlag: hasRealtimeThumbnailsFeatureFlag(
      realtimeSettings,
      settings
    ),
    videoAlarmsFeatureFlag: settings.videoAlarmsFeatureFlag,
    commandCenterAppAccessSettingsFeatureFlag:
      hasCommandCenterAppAccessSettingsFeatureFlag(),
    insightsAvailableFeatureFlag: hasInsightsAvailableFeatureFlag(settings),
    insightsEnabledFeatureFlag: settings.insightsEnabled,
    insightsSchedulingFeatureFlag: hasInsightsSchedulingFeatureFlag(settings),
    showTeamPulseScreenViewsFeatureFlag: settings.showTeamPulseScreenViews,
    showActivationFeatureFlag: hasShowActivationFeatureFlag(settings),
    showSupportTicketsFeatureFlag: hasSupportTicketsFeatureFlag(settings),
    excludeTodaysDateInFilterFeatureFlag:
      settings.settings?.isExcludeTodaysDateInFilterEnabled,
    showOfflineMeetingDataFeatureFlag:
      hasOfflineMeetingDataFeatureFlag(settings),
    showLocationDataFeatureFlag: hasLocationDataFeatureFlag(settings)
  };
};
