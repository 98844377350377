import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { primaryAqua, primaryBlue } from '../../common/constants';

export const ComputersGridContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - 240px);
  min-height: 400px;
  margin: 10px 0px;
`;

export const ComputerHeaderBarRightActionButtons = styled(Box)`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
`;

export const AgentVersionHeaderTableCell = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AgentHealthStatusCircle = styled('span')`
  height: 25px;
  width: 25px;
  border-radius: 12px;
  display: inline-block;
  background-color: ${(props) => props?.color || 'purple'};
`;

export const computersViewStyles = {
  uploadFileProgressContainer: {
    height: '6px',
    width: '100%',
    borderRadius: '3px',
    background: '#ffffff',
    padding: '20px 0px 10px'
  },
  dispProgress: {
    height: '40px',
    width: '100%',
    background: '#ffffff',
    marginBottom: '20px'
  },
  uploadFileProgressBar: {
    marginTop: '10px !important',
    height: '100%',
    background: primaryAqua
  },
  dispResultBar: {
    height: '40px',
    width: '100%',
    marginBottom: '20px',
    marginTop: '-15px',
    background: '#ffffff'
  },
  confAlert: {
    '& .MuiAlert-icon': {
      color: 'black'
    },
    backgroundColor: primaryBlue,
    border: `0px ${primaryBlue}`
  }
};
