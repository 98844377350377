import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { gray4, primaryAqua, primaryBlue } from '../../constants';

export const wizardClasses = {
  wizardBtn: {
    textTransform: 'none',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '1.25px',
    borderRadius: '4px',
    padding: '10px 16px',
    boxShadow: 'none'
  },
  wizardPrimaryColor: {
    color: '#FFF',
    '&:hover': {
      backgroundColor: primaryAqua
    }
  },
  wizardSecondaryColor: {
    color: primaryBlue
  },
  stepLabel: {
    '& .MuiStepIcon-text': {
      fill: '#FFF'
    }
  },
  stepper: {
    width: '30%',
    padding: '24px',
    // doesn't seem to apply correctly
    '@media (max-width: 700px)': {
      width: '50%'
    },
    '& .MuiStepIcon-root': {
      color: gray4
    },
    '& .MuiStepLabel-root.Mui-disabled': {
      color: gray4
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: primaryAqua
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: primaryAqua
    },
    '& .MuiStepIcon-text': {
      fontSize: '1rem',
      fontWeight: 'bold'
    }
  }
};

export const WizardContentsContainer = styled(Box)`
  padding: 24px;
  margin-left: 0px;
`;
