'use strict';
angular.module('app').controller('AgentActivation', AgentActivation);

AgentActivation.started = false;

AgentActivation.$inject = ['$scope', 'accountSettings', 'envConfig', '$rootScope'];

function AgentActivation($scope, accountSettings, envConfig, $rootScope) {
    //show sidebar/topbar if hidden
    $scope.showTopBar();

    // TODO: this needs to call the navigation store
    $rootScope.sidebar.showSidebar();

    var monitor = require('_app/serviceFunctions/agentActivationFunctions').default;

    //Start monitoring...
    if (accountSettings.usedLicenses === 0 && !AgentActivation.started) {
        monitor(`${envConfig.apiUrl()}/api/v2/post_login`);
        AgentActivation.started = true;
    }
}
