import React, { useState } from 'react';
import { ICategory } from '../../common/models';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { useClassification } from '../services';

type DeleteCategoryModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  categoryActivityDetail: ICategory;
};

export default function DeleteCategoryDialog(props: DeleteCategoryModalProps) {
  const { open, onClose, categoryActivityDetail } = props;
  const onDelete = props.onDelete ? props.onDelete : () => {};
  const { categoryService } = useClassification();
  const notificationService = useNotifications();
  const [deleting, setDeleting] = useState(false);

  const numberOfActivities =
    categoryActivityDetail?.websiteCount + categoryActivityDetail?.appCount;

  const handleDelete = async (): Promise<void> => {
    try {
      setDeleting(true);
      //do fire-n-forget until delete process is faster
      categoryService.delete([categoryActivityDetail?.id]);
      // NOTE: This will fire off a success message regardless of result. If it throws an error later, that will appear as well.
      notificationService.success(
        `Success! ${categoryActivityDetail?.name} deleted.`
      );
      onDelete();
    } catch (response) {
      //error is handled thru global notification
    } finally {
      close();
      setDeleting(false);
    }
  };

  const close = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        close();
      }}
    >
      <DialogTitle>Delete Category</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete this category?</p>
        <p>
          Deleting <strong>{categoryActivityDetail?.name}</strong> will remove
          it from <strong>{numberOfActivities} activities</strong> and prevent
          any activities from being auto-classified with the selected category
          going forward.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus disabled={deleting}>
          Cancel
        </Button>
        <Button onClick={handleDelete} disabled={deleting} variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
