import { IGridColumn } from '../../common/models';
import { Box } from '@mui/material';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonContainer } from '../styles/TimeOnTask.view.styles';
import { ICoreCategory } from '../models';
import React from 'react';

export const GET_STARTED_DIALOG = 'GetStartedDialog';
export const STEP1_DIALOG = 'Step1Dialog';
export const STEP2_DIALOG = 'Step2Dialog';
export const STEP3_DIALOG = 'Step3Dialog';
export const SUCCESS_DIALOG = 'SuccessDialog';
export const EDIT_DIALOG = 'EditDialog';
export const DELETE_CONFIRM_DIALOG = 'DeleteConfirmDialog';

export enum dropDownConstants {
  Edit = 'Edit',
  Delete = 'Delete'
}

/**
 * -- 'categoryNames' doesn't exist on FEAPI dto: helper attribute for grid display & sort
 */
export const getDefaultCoreCategory = () => {
  const defaultCoreCategory: ICoreCategory = {
    groupId: null,
    groupName: null,
    categories: [],
    categoryNames: null, //Not relevant for create/edit
    targetPercentage: 70,
    type: 1
  };
  return defaultCoreCategory;
};

export const dropDownButtonOptions = [
  { label: dropDownConstants.Edit },
  { label: dropDownConstants.Delete }
];

export const filterCoreCategoriesBySpecificParamsAndQuery = (
  data: ICoreCategory[],
  searchParams: string[],
  query?: string
) => {
  //Map data for less iterations on "forEach" cycles
  const filteredDataMap = {};

  //If the Query is empty or the array of params to search is empty
  //return the same data
  if (!query || !searchParams.length) {
    return data;
  }

  searchParams.forEach((keyParam) => {
    data.forEach((dataItem) => {
      const { groupId } = dataItem;
      const param = dataItem[keyParam];
      if (param) {
        //Case if type of data is string
        if (typeof param === 'string') {
          if (query) {
            if (
              param.toLowerCase().includes(query.toLowerCase()) &&
              !filteredDataMap[groupId]
            ) {
              filteredDataMap[groupId] = dataItem;
            }
          } else {
            if (!filteredDataMap[groupId]) {
              filteredDataMap[groupId] = dataItem;
            }
          }
        }
        //Could add other cases (bool , number , date )
      }
    });
  });

  //Transform object of objects to list of object
  return Object.values(filteredDataMap);
};

const filterAndRepeatCoreCategoriesDataBySpecificParamsAndQuery = (
  data: ICoreCategory[],
  dataStructure: IGridColumn[],
  searchParams: string[],
  query?: string
) => {
  const filteredData = [];

  if (!query && !searchParams.length) {
    return filteredData;
  }

  searchParams.forEach((keyParam) => {
    const typeName = dataStructure.find((el) => el.field === keyParam);
    if (typeName) {
      const { fieldName, headerName } = typeName;
      const name = fieldName ? fieldName : headerName;

      data.forEach((dataItem) => {
        const { groupId } = dataItem;
        const param = dataItem[keyParam];
        if (param) {
          //Case if type of data is string
          if (typeof param === 'string') {
            if (param.toLowerCase().includes(query.toLowerCase())) {
              filteredData.push({
                id: groupId,
                type: keyParam,
                typeName: name,
                label: dataItem[keyParam]
              });
            }
          }
          //Could add other cases (bool , number , date )
        }
      });
    }
  });

  return filteredData;
};

export const getFilteredCoreCategoriesForAutocomplete = (
  coreCategories: ICoreCategory[],
  gridColumns: IGridColumn[],
  searchBarFilterParams: string[],
  searchTerm: string
) => {
  if (!coreCategories) return [];

  const filteredData =
    filterAndRepeatCoreCategoriesDataBySpecificParamsAndQuery(
      coreCategories,
      gridColumns,
      searchBarFilterParams,
      searchTerm
    );

  const newDataMap = {};
  const uniqueGroups = {};
  for (let i = 0; filteredData.length > i; i++) {
    const { id, type, label } = filteredData[i];
    if (type !== searchBarFilterParams[0]) {
      newDataMap[id + type] = filteredData[i];
    } else {
      if (!uniqueGroups[label]) {
        newDataMap[id + type] = filteredData[i];
        uniqueGroups[label] = true;
      }
    }
  }

  return Object.keys(newDataMap).map(function (key) {
    return newDataMap[key];
  });
};

export const getGridColumns = (
  editCoreCategoryCallback: {
    (coreCategoryEdit: ICoreCategory): void;
    (arg: ICoreCategory): void;
  },
  deleteCoreCategoryCallback: {
    (coreCategoryDelete: ICoreCategory): void;
    (arg: ICoreCategory): void;
  }
): IGridColumn[] => {
  return [
    {
      field: 'groupName',
      fieldName: 'Team',
      headerName: 'Team',
      type: 'string',
      isSortable: true,
      width: '25%'
    },
    {
      field: 'categoryNames',
      fieldName: 'Core Categories',
      headerName: 'Core Categories',
      type: 'string',
      isSortable: true,
      width: '60%'
    },
    {
      field: 'targetPercentage',
      fieldName: 'Time Goal',
      headerName: 'Time Goal',
      type: 'number',
      isSortable: true,
      width: '5%',
      template: (dataItem) => {
        return <>{dataItem.targetPercentage}%</>;
      }
    },
    {
      width: '4%',
      type: 'string',
      template: (dataItem) => {
        const { selected } = dataItem;
        return (
          <Box>
            <ButtonContainer>
              <DropdownOptionsButton
                id={dataItem.groupId}
                icon={<MoreVertIcon />}
                dropDownOptions={dropDownButtonOptions}
                onChangeOption={(e, option) => {
                  const { label } = option;
                  switch (label) {
                    case dropDownConstants.Edit: {
                      editCoreCategoryCallback(dataItem);
                      break;
                    }
                    case dropDownConstants.Delete: {
                      deleteCoreCategoryCallback(dataItem);
                    }
                  }
                }}
                hideSelectedOption
                disableSelectedItem
                disabled={selected}
              />
            </ButtonContainer>
          </Box>
        );
      }
    }
  ];
};
