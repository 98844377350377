'use strict';

angular.module('app')
    .directive('accountSuccess', accountSuccess);

function accountSuccess() {
    return {
        restrict: 'E',
        scope: false,
        template: require('views/widgets/accountSuccess.html'),
        controller: AccountSuccessCtrl
    };
}

AccountSuccessCtrl.$inject = ['$scope', 'AccountSettingsService', 'localStorageService', 'widgetsApiService'];

function AccountSuccessCtrl($scope, accountSettings, localStorageService, widgetsApiService) {
    var localStorageKey = 'realtime-accounts-reporting-' + accountSettings.username;
    var storedValue = localStorageService.get(localStorageKey);

    if (storedValue !== null && storedValue !== undefined && new Date() - new Date(storedValue.timestamp) < 2 * 60 * 1000) {
        $scope.accountsReporting = storedValue.value;
    } else {
        widgetsApiService.getRealtimeCount()
        .success(function (result) {
            $scope.accountsReporting = result.total;
            localStorageService.set(localStorageKey, {
                value: result.total,
                timestamp: new Date()
            });
        });
    }
}
