import React, { Fragment } from 'react';
import {
  Card,
  CardActions,
  CardActionArea,
  Skeleton,
  Box
} from '@mui/material';
import Numeral from 'numeral';

import CardActiveAgent from './CardActiveAgent/CardActiveAgent.jsx';
import CardAlarm from './CardAlarm/CardAlarm.jsx';
import CardFooter from './CardFooter/CardFooter.jsx';
import Notification from '../Notification/Notification.jsx';
import CardHead from './CardHead/CardHead.jsx';
import { customerCardClasses } from './CustomerCard.styles.js';

const CustomerCard = ({
  companyName,
  userCount,
  accountId,
  ownerEmail,
  alarmCount,
  agentNotReportedCount,
  isExpired,
  loading,
  onError,
  updateCompanyName,
  extLoaded
}) => {
  return (
    <Box sx={customerCardClasses.customerCard}>
      {agentNotReportedCount > 0 && (
        <Notification
          notReported={agentNotReportedCount}
          accountId={accountId}
          ownerEmail={ownerEmail}
          onError={onError}
          isExpired={isExpired}
        />
      )}
      <Card sx={customerCardClasses.root} raised>
        {loading ? (
          <Skeleton
            variant="rect"
            width="100%"
            height="16rem"
            animation="wave"
          />
        ) : (
          <Fragment>
            <CardHead
              companyName={companyName}
              accountId={accountId}
              sx={customerCardClasses.cardHeader}
              updateCompanyName={updateCompanyName}
            />
            <CardActionArea>
              <CardActiveAgent
                userCount={userCount}
                accountId={accountId}
                ownerEmail={ownerEmail}
                onError={onError}
                extLoaded={extLoaded}
              />
            </CardActionArea>
            <CardActionArea sx={customerCardClasses.activeAlarms}>
              <CardAlarm
                alarmCount={Numeral(alarmCount).format('0[.]0a', Math.floor)}
                accountId={accountId}
                onError={onError}
                extLoaded={extLoaded}
              />
            </CardActionArea>
            <CardActions sx={customerCardClasses.cardActions}>
              <CardFooter accountId={accountId} onError={onError} />
            </CardActions>
          </Fragment>
        )}
      </Card>
    </Box>
  );
};

export default CustomerCard;
