import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { PrivacySetting } from '../../../../../enums/PrivacySetting';
import { IRoute } from '../../../../../models/IRoute';

export const appScreenshotsLatest: IRoute = {
  name: 'app.screenshots.latest',
  stateDefinition: {
    url: '/screenshots/latest',
    params: { user: null },
    template: '<screenshots-latest></screenshots-latest>',
    data: {
      pageTitle: 'Most Recent Screenshots per User',
      shortTitle: { title: 'Latest per User', maxSize: '450' },
      filter: { configure: true, hideDates: true }
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsScreenshotsReports,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User],
    bundleFlags: [BundleFlag.ScreenshotsLatestPerUserReport],
    privacySettings: [PrivacySetting.Screenshots]
  }
};

export default appScreenshotsLatest;
