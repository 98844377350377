import { Role } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../../templates';

export const appReportsInsights: IRoute = {
  name: 'app.reports.insights',
  stateDefinition: {
    url: '/insights?modelId&pageId&subPageId&tempFilters',
    params: { modelId: null, pageId: null, subPageId: null, tempFilters: null },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'ActivInsights' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer]
  }
};

export default appReportsInsights;
