import { Role } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';
import { featureRequestsTemplate as angularJsTemplate } from '../templates';

export const appFeaturerequests: IRoute = {
  name: 'app.featurerequests',
  stateDefinition: {
    url: '/featurerequests',
    template: angularJsTemplate,
    controller: 'FeatureRequestsCtrl',
    data: { pageTitle: 'Feature Requests' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.Configurator],
    read: [],
    bundleFlags: [BundleFlag.PaidFunctionality]
  }
};

export default appFeaturerequests;
