import { Role, RoleAccessKeys } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsGroups: IRoute = {
  name: 'app.settings.groups',
  stateDefinition: {
    url: '/settings/groups?openCreateGroupModal',
    template: '<settings-groups></settings-groups>',
    data: { pageTitle: 'Groups' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsGroups;
