import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../templates';

export const appCoaching: IRoute = {
  name: 'app.coaching',
  stateDefinition: {
    url: '/coaching?modelId&pageId&subPageId&tempFilters',
    params: {
      modelId: 'activinsights',
      pageId: 'coaching_opportunities',
      subPageId: 'summary',
      tempFilters: null
    },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'Coach' }
  },
  access: {
    redirectTo: 'app.coachingpromo',
    roleAccessKey: RoleAccessKeys.Coaching,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.CoachInsightsReport]
  }
};

export default appCoaching;
