import React from 'react';
import { notifWarn } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const OptimizeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 37;
  const width = props.width ?? 36;
  const title = props.title ?? 'Optimize';
  const className = props.className;
  const fillColor = props.fillColor ?? notifWarn;

  let classList = 'at-icon at-icon-optimize';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 37`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M31.5 26.625V30.6593L25.875 25.0343V17.625C25.8749 17.4161 25.8166 17.2114 25.7068 17.0338C25.5969 16.8562 25.4397 16.7126 25.2529 16.6192L19.125 13.5536V7.3065L22.0343 10.2157L23.625 8.625L18 3L12.375 8.625L13.9657 10.2157L16.875 7.3065V13.5547L10.7471 16.6181C10.5601 16.7116 10.4028 16.8553 10.293 17.0331C10.1831 17.211 10.1249 17.4159 10.125 17.625V25.0343L4.5 30.6593V26.625H2.25V34.5H10.125V32.25H6.09075L11.4716 26.8691L17.4971 29.8819C17.6533 29.9598 17.8255 30.0004 18 30.0004C18.1745 30.0004 18.3467 29.9598 18.5029 29.8819L24.5284 26.8691L29.9093 32.25H25.875V34.5H33.75V26.625H31.5ZM16.875 27.0547L12.375 24.8047V19.4453L16.875 21.6953V27.0547ZM18 19.7422L13.7655 17.625L18 15.5078L22.2345 17.625L18 19.7422ZM23.625 24.8047L19.125 27.0547V21.6953L23.625 19.4453V24.8047Z"
        fill={fillColor}
      />
    </svg>
  );
};
