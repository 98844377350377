import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import papaparse from 'papaparse';
import { useGroups } from '../../../common/services/Groups';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  LinearProgress
} from '@mui/material';
import { ActivTrakIcon } from '../../../common/assets/Icons';
import UploadIcon from '../../../common/assets/Icons/UploadIcon';
import {
  SectionHeaders,
  bulkGroupClasses
} from '../../styles/BulkGroupModal.styles';
import { newEvent } from '../../../common/analytics/eventHeap';
interface OwnProps {
  open: boolean;
  onClose: () => void;
  addGroupFn: () => void;
  onProgressBarStart: () => void;
  onEndProgbarDwnld: () => void;
}

export enum BulkGroupsState {
  ACTIONS = 'actions',
  MAINUPLOAD = 'main_upload',
  UPLOADPROCESS = 'upload_process',
  SUMMARY = 'summary'
}

const MAX_FILE_SIZE = 230686720;
const MIN_FILE_SIZE = 10;

export const BulkGroupsModal = ({
  open,
  onClose,
  addGroupFn,
  onProgressBarStart,
  onEndProgbarDwnld
}: OwnProps) => {
  const [error, setError] = useState<string>(null);
  const [uploadFile, setUploadfile] = useState<string>(null);
  const [status, setStatus] = React.useState<BulkGroupsState>(
    BulkGroupsState.ACTIONS
  );
  const [uploadStatusResponse, setGroupsStatusResponse] = useState<number>(0);

  const { groupsState } = useGroups();
  const { uploadCSV, downloadCSV, setGroupsNotification } = groupsState;

  const validateDrag = (file: File) => {
    const splittedFileName = file.name.split('.');
    const extension = splittedFileName[splittedFileName.length - 1];
    setUploadfile(file.name);

    if (file.size > MAX_FILE_SIZE) {
      setError('*File too large. Max file size is 220MB');
      setStatus(BulkGroupsState.MAINUPLOAD);
      return;
    }
    if (file.size <= MIN_FILE_SIZE) {
      setError('*File empty. file size is 0 bytes');
      setStatus(BulkGroupsState.MAINUPLOAD);
      return;
    }
    if (extension.toLowerCase() !== 'csv') {
      setError('*File not recognized. Must be in CSV format.');
      setStatus(BulkGroupsState.MAINUPLOAD);
      return;
    }

    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (event) => {
      const parser = papaparse.parse(event.target.result, { header: true });
      const headers = parser.meta.fields;
      const hasGroupheader = headers.find((h) => h === 'group_name'); //required
      if (!hasGroupheader) {
        setError(
          'Missing column group_name, you need to define at least group_name'
        );
        setStatus(BulkGroupsState.MAINUPLOAD);
        return;
      }

      setStatus(BulkGroupsState.UPLOADPROCESS);
      try {
        const response = await uploadCSV(file);
        //populate summary
        setGroupsStatusResponse(response);
      } catch (error) {
        setUploadfile(file.name);
        setGroupsNotification({
          msg: `Unable to upload groups. ${error}`,
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to upload groups', error);
      } finally {
        setStatus(BulkGroupsState.SUMMARY);
      }
    };
    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
    minSize: MIN_FILE_SIZE,
    validator: validateDrag
  });

  const handleDownload = async () => {
    onProgressBarStart();
    const response = await downloadCSV();
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(
      new Blob([response.data], { type: 'text/csv;charset=utf-8' })
    );
    reader.onloadend = () => {
      const result = reader.result as string;
      const downloadLink = document.createElement('a');
      downloadLink.href = decodeURIComponent(result);
      downloadLink.download = 'userGroupAssignments.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    onEndProgbarDwnld();
  };

  const onDialogClose = () => {
    newEvent('Imported Group', {});
    setStatus(BulkGroupsState.ACTIONS);
    setGroupsStatusResponse(0);
    setError(null);

    open = false;
    onClose();
  };

  return (
    <Dialog
      data-testid="bulk-group-dialog"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          setStatus(BulkGroupsState.ACTIONS);
          onDialogClose();
          open = false;
        }
      }}
      sx={bulkGroupClasses.dialog}
    >
      <DialogTitle>Create Groups</DialogTitle>

      {status == BulkGroupsState.ACTIONS && (
        <DialogContent sx={bulkGroupClasses.selectPath}>
          <Box sx={bulkGroupClasses.pathSelection}>
            <Box sx={bulkGroupClasses.pathTitle}>
              <ActivTrakIcon />
              <SectionHeaders>New Group</SectionHeaders>
            </Box>
            <Box sx={bulkGroupClasses.pathsContent}>
              Create and add members to a new group.
            </Box>
            <Box sx={bulkGroupClasses.pathButtonCta}>
              <Button
                color="primary"
                variant="contained"
                data-testid="add-group"
                onClick={addGroupFn}
              >
                New Group
              </Button>
            </Box>
          </Box>
          <Box sx={bulkGroupClasses.pathDivisionGroup}>
            <Box sx={bulkGroupClasses.pathDivision}></Box>
            <Box>or</Box>
            <Box sx={bulkGroupClasses.pathDivision}></Box>
          </Box>

          <Box sx={bulkGroupClasses.pathSelection}>
            <Box sx={bulkGroupClasses.pathTitle}>
              <UploadIcon />
              <SectionHeaders>Upload Groups</SectionHeaders>
            </Box>
            <Box sx={bulkGroupClasses.pathsContent}>
              Create groups or assign users to existing groups from a csv.
            </Box>
            <Box sx={bulkGroupClasses.pathButtonCta}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setStatus(BulkGroupsState.MAINUPLOAD)}
              >
                Upload Group
              </Button>
            </Box>
          </Box>
        </DialogContent>
      )}

      {status == BulkGroupsState.MAINUPLOAD && (
        <DialogContent>
          <Box sx={bulkGroupClasses.uploadFileContainer}>
            <div style={bulkGroupClasses.dragAndDropArea} {...getRootProps()}>
              <>
                <input {...getInputProps()} />
                <Box>
                  Drag and Drop or{' '}
                  <span style={bulkGroupClasses.uploadFileTitleHighlight}>
                    Browse Files
                  </span>
                </Box>
                {error && (
                  <>
                    <p
                      className={'error'}
                      style={bulkGroupClasses.uploadFileInstructions}
                    >
                      Error: {uploadFile}
                    </p>
                    <p style={bulkGroupClasses.uploadFileInstructions}>
                      {error}
                    </p>
                  </>
                )}
              </>
              {!error && (
                <Box sx={bulkGroupClasses.uploadFileInstructions}>
                  Max file size: 220MB
                </Box>
              )}
            </div>
          </Box>
          <Box sx={bulkGroupClasses.uploadFileDescription}>
            To create Groups, your CSV needs a group_name header.
          </Box>
          <Box sx={bulkGroupClasses.uploadFileDescription}>
            To create Groups and/or assign users, your CSV needs group_name, 
            member_id and member_type headers.
          </Box>
          <Box>
            <Button color="primary" onClick={handleDownload}>
              Download
            </Button>{' '}
            here to view current users and groups.
          </Box>
        </DialogContent>
      )}

      {status == BulkGroupsState.UPLOADPROCESS && (
        <DialogContent sx={bulkGroupClasses.uploadFileContainer}>
          <div style={bulkGroupClasses.dragAndDropArea}>
            {!error && (
              <>
                <p style={bulkGroupClasses.uploadFileTitle2}>
                  Drag and Drop or Browse Files
                </p>
              </>
            )}

            {!error && (
              <p style={bulkGroupClasses.uploadFileInstructions}>
                Creating: {uploadFile}
              </p>
            )}
          </div>
          <div style={bulkGroupClasses.uploadFileProgressContainer}>
            <div>Creating Groups</div>

            <div style={bulkGroupClasses.uploadFileProgressBar} />
            <LinearProgress />
          </div>
        </DialogContent>
      )}

      {status == BulkGroupsState.SUMMARY && (
        <DialogContent sx={bulkGroupClasses.uploadFileContainer}>
          <div style={bulkGroupClasses.dragAndDropArea} {...getRootProps()}>
            {!error && (
              <>
                <input {...getInputProps()} />
                <Box>
                  Drag and Drop or{' '}
                  <span style={bulkGroupClasses.uploadFileTitleHighlight}>
                    Browse Files
                  </span>
                </Box>
              </>
            )}
            {!error && (
              <p style={bulkGroupClasses.uploadFileInstructions}>
                Process Completed
              </p>
            )}
          </div>
          <strong>File Results</strong>

          <div style={bulkGroupClasses.uploadFileInstructions}>
            {uploadStatusResponse == 200 ? (
              <Box sx={bulkGroupClasses.uploadFileDescription}>
                Upload Completed Successfully
              </Box>
            ) : (
              <Box
                sx={{ ...bulkGroupClasses.uploadFileDescription, color: 'red' }}
              >
                There was a problem uploading your file.
              </Box>
            )}
          </div>
          <div style={bulkGroupClasses.divDoneButton}>
            <Button
              color="primary"
              variant="contained"
              sx={bulkGroupClasses.doneButton}
              onClick={onDialogClose}
            >
              Done
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
