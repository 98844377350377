import viewTemplate from 'views/widgets/productivityBarChart.html?raw';
import ProductivityStatus from '../../../../_app/constants/productivityStatus';
import { getReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';

angular.module('app').directive('productivityBarChart', [
    '$window',
    '$timeout',
    '$document',
    'browserServiceFunctions',
    'templateServiceFunctions',
    function ($window, $timeout, $document, browserServiceFunctions, templateServiceFunctions) {
        return {
            restrict: 'E',
            scope: false,
            template: viewTemplate,
            // Link Bar Chart to parent scope
            link: function ($scope) {
                const getIsCompactView = function () {
                    return !$scope.printPage && $window.innerWidth < 1440;
                };

                // Create Bar Height Array
                let isCompactView = getIsCompactView();
                const updateBarHeights = function () {
                    $scope.barHeights = [];
                    for (let i = 14; i >= 0; i--) {
                        $scope.barHeights[i] = Math.min(
                            Math.floor((isCompactView ? ($scope.printPage ? 200 : 300) : 390) / (i + 1)) - 6,
                            isCompactView ? ($scope.printPage ? 20 : 30) : 70
                        );
                    }
                };
                updateBarHeights();

                // Set Initial chart parameters
                $scope.chartLoading = true;
                let useCache = false;
                let dataCache;
                let params;
                let opts;
                let barHeight = $scope.barHeights[14];
                $scope.chartSize = 'at-bar-chart-15';
                $scope.barToggleClass = {
                    1: '',
                    2: '',
                    '-1': '',
                    '-2': '',
                    '-3': '',
                    0: ''
                };
                const showBars = {
                    1: true,
                    2: false,
                    '-1': true,
                    '-2': false,
                    '-3': false,
                    0: true
                };

                function isUser(userType) {
                    return userType === 'User' || userType === 'Computer' || userType === 'Alias';
                }

                // Create Tooltip Template
                const getTooltipTemplate = function (e) {
                    const target = e.target;
                    let duration;
                    const productivity = target.hasClass('bg-productive')
                        ? ProductivityStatus.Productive
                        : target.hasClass('bg-unproductive')
                        ? ProductivityStatus.Unproductive
                        : target.hasClass('bg-passive')
                        ? ProductivityStatus.UndefinedPassive
                        : ProductivityStatus.Undefined;

                    // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
                    const dataItem = e.sender.$angular_scope.dataItem;
                    // jscs:enable requireCamelCaseOrUpperCaseIdentifiers

                    duration =
                        productivity === ProductivityStatus.Productive
                            ? dataItem.productive + dataItem.productivePassive
                            : productivity === ProductivityStatus.Unproductive
                            ? dataItem.unproductive + dataItem.unproductivePassive
                            : productivity === ProductivityStatus.Undefined
                            ? dataItem.undefined + dataItem.undefinedPassive
                            : productivity === ProductivityStatus.UndefinedPassive
                            ? dataItem.undefinedPassive
                            : 0;

                    let passiveTime = 0;

                    switch (productivity) {
                        case ProductivityStatus.Productive:
                            passiveTime = dataItem.productivePassive;
                            break;
                        case ProductivityStatus.Unproductive:
                            passiveTime = dataItem.unproductivePassive;
                            break;
                        case ProductivityStatus.Undefined:
                            passiveTime = dataItem.undefinedPassive;
                            break;
                        default:
                            return 0;
                    }

                    return templateServiceFunctions.chartTooltipTemplate({
                        seriesName: ProductivityStatus.getName(productivity, false, true),
                        activeTime: duration,
                        passiveTime: passiveTime,
                        totalPercent: dataItem.percentString[productivity],
                        color: ProductivityStatus.getColor(productivity)
                    });
                };

                // Set Tooltip Options
                $scope.tooltipOptions = {
                    filter: '.bar-tooltip',
                    content: getTooltipTemplate,
                    background: 'none',
                    position: 'bottom',
                    padding: 0.01,
                    border: {
                        width: 0
                    },
                    callout: false,
                    beforeShow: function (popup) {
                        popup.element.addClass('at-tooltip');
                        const that = this;
                        const tooltips = $document.find('[data-role=tooltip]');
                        // Close other tooltips
                        tooltips.each(function () {
                            const tooltip = $(this).data('kendoTooltip');
                            if (tooltip && tooltip !== that) {
                                tooltip.hide();
                            }
                        });
                    },
                    show: function (target) {
                        if (window.innerWidth < 992) {
                            target.sender.popup.wrapper.css('z-index', '799');
                        }
                    }
                };

                const processData = function (data, isAlreadyEscaped) {
                    if (data.rows) {
                        data = data.rows;
                    }
                    // Check if an external data manipulation function exists and run if it does
                    if (typeof $scope.dataManipulation === 'function') {
                        // Hacky way to find TopGroups response. This is because the dataManipulation function in TopGroups isn't being reached
                        if (data.length > 0 && data[0].unproductiveActiveRatio !== undefined) {
                            const formattedRows = [];
                            data.forEach(function (row) {
                                formattedRows.push({
                                    user: row.user,
                                    productive: row.productiveActive,
                                    unproductive: row.unproductiveActive,
                                    undefined: row.undefinedActive,
                                    productivePassive: row.productivePassive,
                                    unproductivePassive: row.unproductivePassive,
                                    undefinedPassive: row.undefinedPassive,
                                    idle: row.idle,
                                    total: row.total
                                });
                            });

                            data.rows = formattedRows;
                        }

                        if (data.length > 0 && data[0].undefinedPassive === undefined) {
                            data.forEach(function (row) {
                                row.productivePassive = 0;
                                row.unproductivePassive = 0;
                                row.undefinedPassive = row.idle;
                                row.undefined = row.other;
                            });
                        }

                        data = $scope.dataManipulation(data);
                    }

                    // Remove Use Cache flag
                    useCache = false;

                    // Calculate bar percentages
                    $scope.totalActivity = 0;
                    $scope.productivityList = [1, -1, 0];
                    angular.forEach(data, function (row) {
                        if (typeof row.undefined === 'undefined') {
                            row.undefined = row.other;
                        }

                        const total = getTotalProductiveHours(row);

                        if (!isAlreadyEscaped) {
                            row.user = browserServiceFunctions.htmlEscape(row.user);
                        }

                        $scope.totalActivity = total > $scope.totalActivity ? total : $scope.totalActivity;
                    });

                    if (isCompactView && data && data.length > 6) {
                        return data.slice(0, 6);
                    }

                    // Return manipulated data
                    return data;
                };

                const setBarHeights = function (data) {
                    const len = data.length > 15 ? 15 : data.length;
                    $scope.chartSize = 'at-bar-chart' + ($window.innerWidth < 992 ? '-sm' : '-' + len);
                    barHeight = $scope.barHeights[len - 1];
                };

                const chartDataSource = new $window.kendo.data.CustomDataSource({
                    transport: {
                        read: function (options) {
                            if (useCache && !!dataCache) {
                                $scope.chartSize =
                                    'at-bar-chart' + ($window.innerWidth < 992 ? '-sm' : '-' + dataCache.length);
                                const data = processData(dataCache, true);
                                setBarHeights(data);
                                options.success(data);
                            } else {
                                // Set Chart Loading Bool
                                $scope.chartLoading = true;
                                const { users } = getReportFilters();
                                const user = users.length > 0 ? users[0] : {};

                                // Update parameters if new ones are available
                                if ($scope.dataCallParams.params !== undefined) {
                                    params = $scope.dataCallParams.params;
                                }

                                // Update options if new ones are available
                                if ($scope.dataCallParams.options !== undefined) {
                                    opts = $scope.dataCallParams.options;
                                }

                                let skipDataCall = false;
                                if ($scope.dataCallParams.options === 'groups' && isUser(user.userType)) {
                                    skipDataCall = true;
                                }

                                // Get Data
                                if (skipDataCall) {
                                    $scope.noData = true;

                                    // Set Chart Loading Bool
                                    $scope.chartLoading = false;

                                    // Return data to Chart
                                    options.success([]);
                                } else {
                                    $scope
                                        .dataCall(params, opts)
                                        .success(function (result) {
                                            // Save data for caching
                                            dataCache = result;

                                            const data = processData(result);

                                            setBarHeights(data);
                                            $scope.noData = data.length === 0;

                                            // Set Chart Loading Bool
                                            $scope.chartLoading = false;

                                            // Return data to Chart
                                            options.success(data);
                                        })
                                        .error(function (result) {
                                            if (result && result.message) {
                                                $scope.$parent.$emit('showNotification', {
                                                    message: result.message,
                                                    color: 'danger'
                                                });
                                            }
                                            options.error(result);
                                        });
                                }
                            }
                        }
                    }
                });

                // Create HTML element for a bar
                const getBar = function (productivity, item, color) {
                    function roundPercentage(proportion) {
                        if (proportion > 0.99994 && proportion < 1) {
                            return 0.9999;
                        }

                        if (proportion < 0.00005 && proportion > 0) {
                            return 0.0001;
                        }
                        return proportion;
                    }

                    const classMap = {
                        1: 'bg-productive',
                        '-1': 'bg-unproductive',
                        '-3': 'bg-passive',
                        0: 'bg-undefined'
                    };

                    const getDuration = function (item, productivity) {
                        if (productivity == ProductivityStatus.Productive) {
                            return item.productive + item.productivePassive;
                        } else if (productivity == ProductivityStatus.Unproductive) {
                            return item.unproductive + item.unproductivePassive;
                        } else if (productivity == ProductivityStatus.Undefined) {
                            return item.undefined + item.undefinedPassive;
                        }

                        return 0;
                    };

                    const duration = getDuration(item, productivity);

                    if (duration === 0) {
                        return '';
                    }

                    const proportion = (duration * 100) / $scope.totalActivity;
                    const percent = roundPercentage(duration / item.total);
                    item.percentString[productivity] = (percent * 100).toFixed(1);
                    item.productivity = productivity;
                    const ret =
                        '<div style="width: ' +
                        proportion +
                        '%; height: ' +
                        barHeight +
                        'px;" class="' +
                        color +
                        ' inline bar-tooltip min-width-non-zero' +
                        classMap[productivity] +
                        '">&nbsp;</div>';
                    return ret;
                };

                // Create HTML container for stacked bars
                const prodUnprodOtherTemplate = function (data) {
                    data.percentString = [];
                    const prod = showBars[1] ? getBar(1, data, 'bg-productive') : '';
                    const unprod = showBars['-1'] ? getBar(-1, data, 'bg-unproductive') : '';
                    const other = showBars[0] ? getBar(0, data, 'bg-undefined') : '';
                    const idle = showBars[-3] ? getBar(-3, data, 'bg-passive') : '';
                    let ret = '<div kendo-custom-tooltip k-options="tooltipOptions">';
                    ret += '<div class="text-nowrap at-pointer">' + prod + unprod + other + idle + '</div>';
                    ret += '</div>';
                    return ret;
                };

                // Create HTML element for total hours
                const totalTimeTemplate = function (data) {
                    return (
                        '<div class="text-right at-pointer white-space-nowrap">' +
                        createHourTemplate(getTotalProductiveHours(data), false) +
                        '</div>'
                    );
                };

                $scope.calculateRowWidth = function () {};

                const rowTemplate = kendo.template(function (data) {
                    const label = data.user;
                    const bar = prodUnprodOtherTemplate(data);
                    const time = totalTimeTemplate(data);
                    let ret;

                    if (isCompactView) {
                        ret =
                            '<div class="at-bar-group at-mobile"><div class="inline at-bar-label overflow-ellipsis">' +
                            label +
                            '</div>' +
                            '<div class="inline pull-right at-bar-time">' +
                            time +
                            '</div>' +
                            '<div class="at-bar-bar">' +
                            bar +
                            '</div></div>';
                    } else {
                        ret =
                            '<div class="at-bar-group"><div class="inline text-right at-bar-label overflow-ellipsis v-align-bottom" style="height: ' +
                            barHeight +
                            'px; line-height: ' +
                            barHeight +
                            'px;">' +
                            label +
                            '</div>' +
                            '<div class="inline at-bar-bar">' +
                            bar +
                            '</div>' +
                            '<div class="inline at-bar-time v-align-bottom" style="height: ' +
                            barHeight +
                            'px; line-height: ' +
                            barHeight +
                            'px;">' +
                            time +
                            '</div></div>';
                    }
                    return ret;
                });

                // Toggle bar productivity inclusion
                $scope.toggleBar = function (productivity) {
                    showBars[productivity] = !showBars[productivity];
                    $scope.barToggleClass[productivity] = showBars[productivity] ? '' : 'at-disabled-bar-toggle';
                    useCache = true;
                    $scope.bindChart();
                };

                // Create/Update Chart
                $scope.bindChart = function () {
                    $scope.chartLoading = !useCache;
                    $scope.bindWidgetCallNumber = 0;

                    if ($scope.productivityChart) {
                        $scope.productivityChart.dataSource.read();
                        return;
                    }

                    $scope.productivityChartOptions = {
                        autoBind: true,
                        dataSource: chartDataSource,
                        selectable: 'row',
                        sortable: false,
                        resizable: false,
                        filterable: false,
                        pageable: false,
                        columns: [
                            {
                                field: 'total',
                                template: rowTemplate,
                                filterable: false
                            }
                        ],
                        dataBound: function () {
                            $timeout(function () {
                                $scope.$emit('productivityBarChartRendered');
                            });
                        }
                    };
                };

                // Calculate total hours based on visible bars.
                const getTotalProductiveHours = function (data) {
                    let total = 0;
                    if (showBars[1]) {
                        total += data.productive + data.productivePassive;
                    }
                    if (showBars['-1']) {
                        total += data.unproductive + data.unproductivePassive;
                    }
                    if (showBars[-3]) {
                        total += data.undefinedPassive;
                    }
                    if (showBars[0]) {
                        total += data.undefined + data.undefinedPassive;
                    }
                    return total;
                };

                // Format time variable to hour/minute string (00h 00m || 00h 00m 00s)
                const createHourTemplate = function (value, showSec) {
                    if (value === 0) {
                        return '&nbsp;';
                    }

                    const hour = Math.floor(value / 3600);
                    const min = Math.floor((value % 3600) / 60);
                    const sec = value % 60;
                    let ret = hour > 0 ? kendo.toString(hour, '0') + 'h ' : '';
                    ret += min > 0 || hour > 0 ? kendo.toString(min, hour > 0 ? '00' : '0') + 'm ' : '';
                    if (showSec) {
                        ret += kendo.toString(sec, min > 0 ? '00' : '0') + 's';
                    } else if (hour <= 0 && min <= 0) {
                        ret += '<1m';
                    }
                    return ret;
                };

                $scope.$watch('bindWidgetCallNumber', function (value) {
                    if (value > 0) {
                        // Remove Use Cache flag
                        useCache = false;
                        $scope.bindChart();
                    }
                });

                // Bind window resizing
                let prevWidth = $window.innerWidth;
                angular.element($window).bind('resize', function () {
                    if (prevWidth !== $window.innerWidth) {
                        prevWidth = $window.innerWidth;
                        isCompactView = getIsCompactView();
                        updateBarHeights();
                        useCache = true;
                        if ($scope.productivityChart) {
                            $scope.productivityChart.dataSource.read();
                        }
                    }
                });
            }
        };
    }
]);
