import { WorkloadLabel, ProductivityGoalLabel } from '../models';

export const ProductivityPieChartLegendPercentages = {
  Achieving: `${ProductivityGoalLabel.Achieving}: 100% +`,
  Missing: `${ProductivityGoalLabel.Missing}:  <99.99%`
};

export const WorkloadPieChartLegendPercentages = {
  Optimal: `${WorkloadLabel.Optimal}: >=70% of members healthy`,
  High: `${WorkloadLabel.High}: >=50% of members overutilized`,
  Low: `${WorkloadLabel.Low}: >50% of members underutilized`,
  Varied: `${WorkloadLabel.Varied}: Assorted workloads across members`
};

export const ProductivityBarChartLegendPercentages = {
  Achieving: `${ProductivityGoalLabel.Achieving}: 100%`,
  Missing: `${ProductivityGoalLabel.Missing}: <100%`
};

export const WorkloadBarChartLegendPercentages = [
  `${WorkloadLabel.High}: >=50% of members overutilized`,
  `${WorkloadLabel.Optimal}: >=70% of members healthy`,
  `${WorkloadLabel.Low}: >50% of members underutilized`,
  `${WorkloadLabel.Varied}: Assorted workloads across members`
];
