import { useCallback, useState } from 'react';
import {
  IPerformanceOptimizationState,
  PerformanceOptimizationStateProps
} from '../models';


export const usePerformanceOptimizationState = ({
  service,
  notificationService
}: PerformanceOptimizationStateProps): IPerformanceOptimizationState => {
  const { fetchLiveReportSettings, postLiveReportSettings } = service;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optimize, setOptimize] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);



  const getLiveReportsSetting = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchLiveReportSettings();
      if (response) {
        const liveReportExcludeTodaysDate =
          response.liveReportExcludeTodaysDate;
        {
          setOptimize(liveReportExcludeTodaysDate);
          setIsLocked(response.dateControlLocked);
        }
      } else {
        throw new Error('No Data');
      }
    } catch (error) {
      notificationService.error(
        'Unable to load Performance Optimization Settings'
      );
      console.error(
        'ActivTrak Error: Unable to load Performance Optimization Settings',
        error
      );
      setIsLocked(false);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    getLiveReportsSetting();
  }, [getLiveReportsSetting]);

  const updateOptimize = useCallback(
    async (val: string) => {
      const formattedVal = val === 'true';
      if (optimize !== formattedVal) {
        setIsLoading(true);
        try {
          await postLiveReportSettings(formattedVal);
          {
            setOptimize(formattedVal);
          }

          notificationService.success(
            'Success! Performance Optimization Setting is updated!'
          );
        } catch (error) {
          notificationService.error(
            'Unable to update Performance Optimization Settings'
          );
          console.error(
            'ActivTrak Error: Unable to update Performance Optimization Settings',
            error
          );
          setIsLocked(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optimize]
  );

  return {
    optimize,
    isLoading,
    isLocked,
    init,
    updateOptimize
  };
};
