import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export const GroupTypeLabelSC = styled('div')`
  position: relative;
  padding-left: 30px;
  > span {
    position: absolute;
    left: 0;
  }
`;

export const TooltipSC = styled(Tooltip)`
  cursor: pointer;
`;
