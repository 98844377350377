import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const ComputerGroupIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const { height, viewBox } = props;

  const width = props.width ?? '25px';
  const title = props.title ?? 'Computer Group';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const style = props.style ?? {};

  let classList = 'at-icon-computer-group';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      fillColor={fillColor}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M16.502 3c.709 0 1.297.541 1.373 1.231l.008.15v2.806l3.046.001c.71 0 1.298.542 1.373 1.231l.008.15v8.287c0 .71-.541 1.298-1.23 1.373l-.15.009h-4.835v1.38h.69c.38 0 .691.312.691.691a.693.693 0 0 1-.579.682l-.111.009h-4.144c-.38 0-.69-.31-.69-.69 0-.342.251-.628.578-.682l.112-.01h.69v-1.38H8.499a1.386 1.386 0 0 1-1.373-1.232l-.008-.15V14.05H4.07a1.386 1.386 0 0 1-1.373-1.23l-.008-.15V4.38c0-.709.541-1.298 1.23-1.373L4.07 3h12.432Zm3.664 5.518H9.196a.688.688 0 0 0-.685.686v6.856c0 .377.309.685.686.685h10.97a.688.688 0 0 0 .685-.685V9.204a.688.688 0 0 0-.686-.686Zm-4.355-4.137H4.761a.693.693 0 0 0-.681.58l-.01.11v6.907c0 .342.253.627.58.681l.111.01 2.355-.002.001-4.098c0-.709.542-1.297 1.231-1.373l.15-.008h8.003V5.071a.693.693 0 0 0-.578-.682l-.112-.009Z"
        style={style}
      />
    </SvgIconWrapper>
  );
};
