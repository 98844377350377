import { IntegrationDetails } from '../constants/IntegrationDetails';
import {
  IntegrationErrors,
  IntegrationMessages
} from '../constants/IntegrationMessages';
import {
  CallToActionType,
  DispositionType,
  IIntegration,
  IIntegrationConfigurationDto,
  IIntegrationDetailDto,
  IIntegrationError,
  IIntegrationPlanDto,
  IIntegrationStateDto,
  IIntegrationSummaryDto,
  ISolutionDto,
  IntegrationStateErrorCodes,
  IntegrationStateType
} from '../models/IIntegrationTypes';
import { IAccountSettings } from '../../common/models';
import {
  MILLISECONDS_IN_1_DAY,
  localStorageItems
} from '../../common/constants';
import {
  formatDate,
  getDateTimeFormat,
  getParsedLocalStorageItem
} from '../../common/helpers';
import { IntegrationCodes } from '../constants/IntegrationCodes';

const accountSettings: IAccountSettings = getParsedLocalStorageItem(
  localStorageItems.accountSettingsStorage
);
const localDateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);

export const determineCallToAction = (
  dispositionState: string,
  isPlanAccessible: boolean,
  isIntegrationEnabled: boolean,
  trayInstance?: string
) => {
  if (trayInstance) return CallToActionType.none;

  const disposition = DispositionType[dispositionState];
  switch (disposition) {
    case DispositionType.ga:
      return isPlanAccessible && !isIntegrationEnabled
        ? CallToActionType.integrate
        : CallToActionType.none;
    case DispositionType.beta:
      return !isPlanAccessible
        ? CallToActionType.upgrade
        : CallToActionType.learnMore;
    default:
      return CallToActionType.learnMore;
  }
};

export const determineBadge = (dispositionState: string) =>
  dispositionState === 'beta' ? 'Beta' : null;

const isNonScheduledIntegration = (
  summary: Pick<IIntegrationSummaryDto, 'integrationcode'>
) => IntegrationDetails[summary.integrationcode]?.runsOnSchedule === false;

const has24HourIntegrationValidated = (
  summary: Pick<IIntegrationSummaryDto, 'integrationcode' | 'updatedtime'>
) =>
  isNonScheduledIntegration(summary) ? false : has24HourSyncCompleted(summary);

const waitingPeriodFlag = (
  summary: Pick<IIntegrationSummaryDto, 'createddate'>
) => {
  return (
    summary &&
    Date.now() - Date.parse(summary.createddate) < MILLISECONDS_IN_1_DAY
  );
};

export const determineStateMessage = (
  summary: Pick<
    IIntegrationSummaryDto,
    'errorcode' | 'updatedtime' | 'createddate' | 'integrationcode'
  > & {
    integrationState: IntegrationStateType;
  }
) => {
  const error =
    IntegrationErrors[summary.errorcode]?.errorMessage ||
    IntegrationErrors[IntegrationStateErrorCodes.Uncategorized];

  switch (summary.integrationState) {
    case IntegrationStateType.None:
    case IntegrationStateType.Deleted:
    case IntegrationStateType.Paused:
      return '';
    case IntegrationStateType.Idle:
      if (summary.errorcode === IntegrationStateErrorCodes.Uncategorized) {
        return IntegrationMessages.UncategorizedMessage;
      }
      return IntegrationMessages.IdleMessage;
    case IntegrationStateType.Waiting:
      if (waitingPeriodFlag(summary)) {
        return IntegrationMessages.ProcessingIntegration;
      }
      if (summary.updatedtime && !has24HourIntegrationValidated(summary)) {
        return `Last data received: ${formatDate(
          summary.updatedtime,
          localDateTimeFormat
        )}`;
      }
      return '';
    case IntegrationStateType.Error:
    case IntegrationStateType.NoFetchWarning:
      return error;
    default:
      return IntegrationMessages.ProcessingIntegration1Hr;
  }
};

const has24HourSyncCompleted = (
  summary: Pick<IIntegrationSummaryDto, 'updatedtime'>
) => {
  return (
    summary.updatedtime &&
    Date.now() - Date.parse(summary.updatedtime) > MILLISECONDS_IN_1_DAY
  );
};

export const mapIntegrations = (
  detailDtos: IIntegrationDetailDto[],
  planDtos: IIntegrationPlanDto[],
  stateDtos: IIntegrationStateDto[],
  configurationDtos: IIntegrationConfigurationDto[],
  solutionsDtos: ISolutionDto[]
): IIntegration[] => {
  return detailDtos
    ?.filter(
      (x) =>
        x.integrationcode === IntegrationCodes.AzureAD ||
        x.integrationcode === IntegrationCodes.GoogleCalendar ||
        x.integrationcode === IntegrationCodes.OutlookCalendar
    )
    .map((detail) => {
      const { integrationcode, state } = detail;
      const { planNames, isPlanAccessible } = mapPlans(
        integrationcode,
        planDtos
      );
      const { integrationConfigurations, isIntegrationEnabled } =
        mapConfigurations(integrationcode, configurationDtos);
      const { integrationState, stateType, error } = mapStates(
        integrationcode,
        stateDtos
      );
      const { traySolutionInstance, trayEnabled } = mapSolutions(
        integrationcode,
        solutionsDtos
      );
      const callToAction = determineCallToAction(
        state,
        isPlanAccessible,
        isIntegrationEnabled,
        traySolutionInstance
      );
      const badge = determineBadge(state);

      return {
        ...IntegrationDetails[integrationcode],
        integrationcode: mapIntegrationCodes(integrationcode),
        planNames,
        isPlanAccessible,
        stateType,
        error,
        callToAction,
        badge,
        integrationConfigurations,
        hasConfigurations: integrationConfigurations.length > 0,
        isIntegrationEnabled,
        disposition: DispositionType[state],
        integrationState,
        traySolutionInstance,
        trayEnabled
      };
    });
};

const mapSolutions = (
  integrationcode: string,
  solutions: ISolutionDto[]
): { traySolutionInstance: string; trayEnabled: boolean } => {
  const integrationTraySolutions = solutions.filter(
    (solution) => solution.instance && solution.tags.includes(integrationcode)
  );

  const id =
    integrationTraySolutions.length === 0
      ? null
      : integrationTraySolutions[0].instance;

  const trayEnabled =
    integrationTraySolutions.length === 0
      ? null
      : integrationTraySolutions[0].enabled;
  return { traySolutionInstance: id, trayEnabled };
};

const mapConfigurations = (
  integrationcode: string,
  configurations: IIntegrationConfigurationDto[]
): {
  integrationConfigurations: IIntegrationConfigurationDto[];
  isIntegrationEnabled: boolean;
} => {
  const integrationConfigurations = configurations.filter(
    (config) => config.integrationcode === integrationcode
  );

  const enabled = integrationConfigurations.some(
    (config) => config.name === 'enabled' && config.value === 'true'
  );

  return { integrationConfigurations, isIntegrationEnabled: enabled };
};

const mapPlans = (
  integrationcode: string,
  plans: IIntegrationPlanDto[]
): { planNames: string[]; isPlanAccessible: boolean } => {
  const plan = plans.find((plan) => plan.integrationcode === integrationcode);

  if (!plan) {
    return { planNames: [], isPlanAccessible: false };
  }

  return {
    planNames: plan.plannames,
    isPlanAccessible: plan.isPlanAccessible
  };
};
const mapStates = (
  integrationcode: string,
  states: IIntegrationStateDto[]
): {
  integrationState: IIntegrationStateDto;
  stateType: IntegrationStateType;
  error: IIntegrationError;
} => {
  const state = states.find(
    (state) => state.integrationcode === integrationcode
  );

  if (!state) {
    return { integrationState: null, stateType: null, error: null };
  }

  return {
    integrationState: state,
    stateType: mapStateType(state.state),
    error: IntegrationErrors[state.errorcode]
  };
};

const mapStateType = (state: string): IntegrationStateType => {
  switch (state) {
    case 'Deleted':
      return IntegrationStateType.Deleted;
    case 'Error':
      return IntegrationStateType.Error;
    case 'Idle':
      return IntegrationStateType.Idle;
    case 'Paused':
      return IntegrationStateType.Paused;
    case 'Running':
      return IntegrationStateType.Running;
    case 'Waiting':
      return IntegrationStateType.Waiting;
    case 'NoFetchWarning':
      return IntegrationStateType.NoFetchWarning;
    default:
      return IntegrationStateType.None;
  }
};

const mapIntegrationCodes = (integrationcode: string): IntegrationCodes => {
  switch (integrationcode) {
    case 'uid_azad':
      return IntegrationCodes.AzureAD;
    case 'uid_gcal':
      return IntegrationCodes.GoogleCalendar;
    case 'uid_ocal':
      return IntegrationCodes.OutlookCalendar;
    default:
      return null;
  }
};
