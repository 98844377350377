import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { notificationMessage } from '../constants';
import { ISupportControlProps } from '../models/ISupportControl';
import { ISetDataCollectionPayloadDto } from '../models/IPayloadDto';

export const DataCollectionToggle = (props: Partial<ISupportControlProps>) => {
  const { value, accountId, token, setDetailsModalClose, changeSetting } =
    props;

  const notificationService = useNotifications();
  const swapValue = value ? 'off' : 'on';

  const payload: ISetDataCollectionPayloadDto = {
    accountId: accountId,
    dataCollection: !value
  };

  const toggle = async () => {
    try {
      changeSetting(payload, token);
      notificationService.success(
        notificationMessage.changeDataCollectionValueSuccess(swapValue)
      );
    } catch (error) {
      notificationService.error(
        notificationMessage.toggleDataCollectionErrorAlert
      );
      console.error(notificationMessage.setDataCollectionError, error);
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onClick={() => toggle()}
          color="primary"
          name="dataCollection"
        />
      }
      label="Data Collection"
    />
  );
};
