// Utility function to scroll to a specific element
export const scrollToElement = (
  elementRef: React.MutableRefObject<HTMLDivElement | null>,
  behavior: ScrollBehavior,
  offset = 0
) => {
  const elementPosition = elementRef.current?.getBoundingClientRect();

  if (elementPosition) {
    const targetPosition =
      elementRef.current.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: targetPosition + offset,
      behavior: behavior
    });
  } else {
    // fall back in case getBoundingClientRect fails
    elementRef.current.scrollIntoView({
      behavior: behavior,
      block: 'start'
    });
  }
};
