export const IntegrationNotifications = {
  Initiated: 'Integration successfully initiated.',
  Enabled: 'Integration successfully enabled.',
  Paused: 'Integration successfully paused.',
  Refreshed: 'Integration successfully refreshed.',
  Deleted: 'Integration successfully deleted.',
  Cancelled: 'Integration setup cancelled.',
  GenericError: 'Integration configuration error.',
  APIError: `There was a problem with your request.`,
  MigrationCancelled:
    'Integration reconfiguration cancelled. Please click integrate to start process again.',
  OauthError: 'There was a problem with the authentication process.'
};
