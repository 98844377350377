export const filterDataBySpecificParamsAndQuery = (
  data: any,
  searchParams: string[],
  query?: string
) => {
  //Map data for less iterations on "forEach" cycles
  const filteredDataMap = {};

  //If the Query is emty or the array of params to search is empty
  //return the same data
  if (!query && !searchParams.length) {
    return data;
  }

  searchParams.forEach((keyParam) => {
    data.forEach((dataItem) => {
      const { id } = dataItem;
      const param = dataItem[keyParam];
      if (param) {
        //Case if type of data is string
        if (typeof param === 'string') {
          if (query && param) {
            if (
              param.toLowerCase().includes(query.toLowerCase()) &&
              !filteredDataMap[id]
            ) {
              filteredDataMap[id] = dataItem;
            }
          } else {
            if (!filteredDataMap[id]) {
              filteredDataMap[id] = dataItem;
            }
          }
        }
        //Could add other cases (bool , number , date )
      }
    });
  });

  //Transform object of objects to list of object
  return Object.values(filteredDataMap);
};

export const filterAndRepeatDataBySpecificParamsAndQuery = (
  data: any,
  dataStructure: any[],
  searchParams: string[],
  query?: string
) => {
  const filteredData = [];
  if (!data) {
    return data;
  }

  if (!query && !searchParams.length) {
    return filteredData;
  }

  searchParams.forEach((keyParam) => {
    const typeName = dataStructure.find((el) => el.field === keyParam);
    if (typeName) {
      const { fieldName, headerName } = typeName;
      const name = fieldName ? fieldName : headerName;

      data.forEach((dataItem) => {
        const { id } = dataItem;
        const param = dataItem[keyParam];
        if (param) {
          //Case if type of data is string
          if (typeof param === 'string') {
            if (!query || param.toLowerCase().includes(query.toLowerCase())) {
              filteredData.push({
                id,
                type: keyParam,
                typeName: name,
                label: dataItem[keyParam]
              });
            }
          }
          //Could add other cases (bool , number , date )
        }
      });
    }
  });

  return filteredData;
};

export const getDifferencesBetweenObjects = (obj1, obj2, path = []) => {
  try {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    const allKeys = new Set([...keys1, ...keys2]);

    const differences = [];

    for (const key of allKeys) {
      const newPath = path.concat(key);

      if (
        typeof obj1[key] === 'object' &&
        obj1[key] !== null &&
        typeof obj2[key] === 'object' &&
        obj2[key] !== null
      ) {
        const nestedDifferences = getDifferencesBetweenObjects(
          obj1[key],
          obj2[key],
          newPath
        );
        differences.push(...nestedDifferences);
      } else if (obj1[key] !== obj2[key]) {
        differences.push(newPath.join('.'));
      }
    }

    return differences;
  } catch (error) {
    console.error('ActivTrak Error: ', error);
  }
};
