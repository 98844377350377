import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { primaryBlue, smallFontSize } from '../../constants';

export const InfoTooltip = styled(Box)`
  font-size: ${smallFontSize};
`;

export const LearnMoreLink = styled('a')`
  padding-top: 7px;
  display: block;
`;

export const LastLogSpan = styled('span')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LastLogRecordTableCell = styled(Box)`
  display: inline;
  align-items: center;
  svg {
    margin-left: 5px;
    color: ${primaryBlue};
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
