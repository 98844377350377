import React, { useState } from 'react';
import { IGroupMember } from '../models';
import { useGroups } from '../../common/services/Groups';
import { useGroup } from '../services';
import {
  Container,
  Popover,
  List,
  ListItemButton,
  IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type Prop = {
  groupMember: IGroupMember;
  disabled: boolean;
};

export default function DeleteGroupMemberButton(props: Prop) {
  const { groupMember, disabled } = props;

  const [isDeleting, setIsDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { groupsState } = useGroups();
  const { getGroups } = groupsState;
  const { groupState } = useGroup();
  const { deleteGroupMembers } = groupState;

  const open = Boolean(anchorEl);
  const id = open ? 'delete-group-member-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveClick = async () => {
    setIsDeleting(true);
    await deleteGroupMembers([groupMember]);
    getGroups();
    setIsDeleting(false);
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <IconButton disabled={disabled} onClick={handleClick} color="secondary">
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List component="nav">
          <ListItemButton disabled={isDeleting} onClick={handleRemoveClick}>
            Remove Member
          </ListItemButton>
        </List>
      </Popover>
    </Container>
  );
}
