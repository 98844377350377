import React, { useEffect, useState } from 'react';
import { ITicket } from '../models';
import { Box } from '@mui/material';

export const useTicketsColumns = () => {
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const cols = [
      {
        field: 'id',
        fieldName: 'Ticket #',
        headerName: 'Ticket #',
        type: 'string',
        isSortable: true,
        width: '10%',
        template: (dataItem: ITicket) => dataItem.id
      },
      {
        field: 'updatedAt',
        fieldName: 'Updated',
        headerName: 'Updated',
        type: 'date',
        isSortable: true,
        width: '15%',
        template: (dataItem: ITicket) => dataItem.updatedAtDisplay
      },
      {
        field: 'createdAt',
        fieldName: 'Created',
        headerName: 'Created',
        type: 'date',
        isSortable: true,
        width: '15%',
        template: (dataItem: ITicket) => dataItem.createdAtDisplay
      },
      {
        field: 'subject',
        fieldName: 'Subject',
        headerName: 'Subject',
        type: 'string',
        isSortable: true,
        width: '25%',
        template: (dataItem: ITicket) => (
          <Box className="truncate">{dataItem.subject}</Box>
        )
      },
      {
        field: 'description',
        fieldName: 'Description',
        headerName: 'Description',
        type: 'string',
        isSortable: true,
        width: '25%',
        template: (dataItem: ITicket) => (
          <Box className="truncate">{dataItem.description}</Box>
        )
      },
      {
        field: 'status',
        fieldName: 'Status',
        headerName: 'Status',
        type: 'string',
        isSortable: true,
        width: '10%',
        template: (dataItem: ITicket) => dataItem.status
      }
    ];

    setColumns(cols);
  }, []);

  return columns;
};
