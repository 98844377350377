import { IManagerSchedule } from '../models';

export function mapToSchedule(managerSchedules: IManagerSchedule[]): any[] {
  return managerSchedules?.map((managerSchedule) => {
    const { subscriptionId, dashboardId } = managerSchedule;

    return {
      id: subscriptionId,
      dashboard: dashboardId,
      isFromSubscriptionManager: true
    };
  });
}
