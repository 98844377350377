import React from 'react';
import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { AuditLogHeaderProps } from '../models/AuditLogTypes';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const AuditLogHeader = (props: AuditLogHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates } = reportFilters;

  const history = useUIRouterHistory();

  const goToAlarmDetails = () => {
    history.push('app.alarms.details', {
      id: 'new',
      type: 'Audit',
      fromAuditLog: true
    });
  };

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={true}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ display: { xs: 'none', md: 'block' } }}>
          <Button
            id="d13cd294-0788-4b3f-af01-b08f8bcbd153"
            variant="outlined"
            onClick={() => goToAlarmDetails()}
          >
            Create Alarm
          </Button>
        </ReportHeaderInline>
        <ReportHeaderInline
          sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
        >
          <ExportButtonContainer />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
