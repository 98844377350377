import React, { ReactNode, useEffect } from 'react';
import { MenuItem, SelectChangeEvent, FormControl } from '@mui/material';
import { ClockIcon } from '@mui/x-date-pickers';
import { SelectInlineLabelSC } from './SelectInlineLabel.styles';

type HourSelectorProps = {
  id: string;
  value?: number;
  onChange: (event: SelectChangeEvent<{ value: unknown }>) => void;
  startHour?: number;
  endHour?: number;
  defaultValue?: number;
  isTimeFormat24Hour?: boolean;
};

export const HourSelector = (props: HourSelectorProps): JSX.Element => {
  const {
    id,
    value,
    onChange,
    startHour = 0,
    endHour = 24,
    defaultValue,
    isTimeFormat24Hour
  } = props;
  const handleChange = (event) => onChange(event.target.value);
  const getHourLabel = (hour: number, use24HourFormat: boolean) => {
    const label24Hour = `${hour}:00`;
    const label12Hour = `${hour % 12 || 12}:00 ${hour >= 12 ? 'PM' : 'AM'}`;

    return use24HourFormat ? label24Hour : label12Hour;
  };

  const createSelectItems = (
    startHour: number,
    endHour: number,
    use24HourFormat: boolean,
    id: string
  ): ReactNode[] => {
    const items = [];
    for (let i = startHour; i <= endHour; i++) {
      const key = `${id}-${i}`;

      items.push(
        <MenuItem key={key} id={key} value={i}>
          {getHourLabel(i, use24HourFormat)}
        </MenuItem>
      );
    }
    return items;
  };

  useEffect(() => {
    const isWithinRange = (value, startHour, endHour) => {
      return value >= startHour && value <= endHour;
    };

    const verifySelectedHour = (value, startHour, endHour, defaultHour) => {
      if (!isWithinRange(value, startHour, endHour)) {
        onChange(defaultHour);
        return defaultHour;
      }

      return value;
    };

    const defaultHour = isWithinRange(defaultValue, startHour, endHour)
      ? defaultValue
      : startHour;

    verifySelectedHour(value, startHour, endHour, defaultHour);
  }, [value, startHour, endHour, defaultValue, onChange]);

  return (
    <FormControl
      sx={{ verticalAlign: 'middle' }}
      variant="outlined"
      size="small"
    >
      <SelectInlineLabelSC
        id={`${id}-select`}
        value={value?.toString()}
        inputProps={{ sx: { minWidth: '192px' } }}
        onChange={handleChange}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        IconComponent={() => (
          <ClockIcon sx={{ fontSize: '16px', m: '0px 16px 2px -24px' }} />
        )}
        MenuProps={{
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          sx: { zIndex: 1500 }
        }}
      >
        {createSelectItems(startHour, endHour, isTimeFormat24Hour, id)}
      </SelectInlineLabelSC>
    </FormControl>
  );
};
