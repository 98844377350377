import { useCallback, useState } from 'react';
import {
  ITodaysActivity,
  ITodaysActivityDto,
  ITodaysActivityState
} from '../models';
import { mapToTodaysActivity } from '../utils/todaysActivity.utils';
import { ApiRoutes } from '../constants';
import {
  fetchReportingData,
  getParsedLocalStorageItem
} from '../../common/helpers';
import { localStorageItems } from '../../common/constants';
import { IAccountSettings } from '../../common/models';

export const useTodaysActivityState = (): ITodaysActivityState => {
  const [todaysActivity, setTodaysActivity] = useState<ITodaysActivity>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const timezone = accountSettings?.currentIanaTimeZone;

  const init = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchReportingData<ITodaysActivityDto>({
        path: ApiRoutes.reports.fetchTodaysActivity
      });
      setTodaysActivity(mapToTodaysActivity(response, timezone));
    } catch (error) {
      console.error(
        "ActivTrak Error: Unable to load today's activity data =>",
        error
      );
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, [timezone]);

  return {
    isLoading,
    isTileError,
    todaysActivity,
    init
  };
};
