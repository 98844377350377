import theme from '../../../../common/theme/theme';

export const tmTopUsersClasses = {
    list: {
        paddingBottom: 0
    },
    row: {
        display: 'flex',
        lineHeight: theme.atCustom.fontSize.title,
        height: theme.spacing(4),
        '& div': {},
        margin: 0,
        padding: 0
    },
    title: {
        textAlign: 'center',
        fontSize: `${theme.atCustom.fontSize.title}px !important`
    },
    left: {
        flex: '1 1 100%',
        fontWeight: 'bold',
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    },
    center: {
        flex: '0 0 auto',
        textAlign: 'center',
        color: theme.palette.productive.main,
        fontWeight: 'bold',
        fontSize: `${theme.atCustom.fontSize.heading}px !important`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    right: {
        flex: '1 1 100%',
        textAlign: 'right',
        color: theme.palette.productive.main,
        fontWeight: 'bold',
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    },
    noData: {
        color: theme.palette.grey.A100
    },
    skeleton: {
        margin: '0 auto'
    }
};
