class menuRule {
    constructor({ type, value, disableRedirect } = {}) {
        this.type = type;
        this.value = value;
        this.disableRedirect = disableRedirect;
    }

    /**
     * Return if the menu item passes set rule
     *
     * @param {Object} ruleFunctions
     */
    pass({ hasFeature, hasRole, hasRoleAccess, isTrial } = {}) {
        switch (this.type) {
            case 'hasFeature':
                return hasFeature(this.value);
            case 'doesNotHaveFeature':
                return !hasFeature(this.value);
            case 'hasRole':
                return hasRole(this.value);
            case 'doesNotHaveRole':
                return !hasRole(this.value);
            case 'isNotInTrial':
                return !isTrial;
            case 'isTrial':
                return isTrial;
            case 'hasRoleAccess':
                return hasRole(['supportbasic', 'superadmin']) || hasRoleAccess(this.value);
            default:
                console.error('ActivTrak Error: Unknown menu rule', this.type);
                return true;
        }
    }
}

export default menuRule;
