'use strict';

angular.module('app').service('dataCenterService', DataCenterService);

DataCenterService.$inject = ['$http', 'envConfig'];

function DataCenterService($http, envConfig) {
    var _regions = [
        {
            id: 'US',
            name: 'United States',
            countries: ['USA']
        },
        {
            id: 'UK',
            name: 'United Kingdom',
            countries: ['GBR']
        },
        {
            id: 'CA',
            name: 'Canada',
            countries: ['CAN']
        },
        {
            id: 'EU',
            name: 'European Union',
            countries: [
                'AUT',
                'BEL',
                'BGR',
                'HRV',
                'CYP',
                'CZE',
                'DNK',
                'EST',
                'FIN',
                'FRA',
                'DEU',
                'GRC',
                'HUN',
                'IRL',
                'ITA',
                'LVA',
                'LTU',
                'LUX',
                'MLT',
                'NLD',
                'POL',
                'PRT',
                'ROU',
                'SVK',
                'SVN',
                'ESP',
                'SWE'
            ]
        },
        {
            id: 'AU',
            name: 'Australia',
            countries: ['AUS']
        }
    ];

    this.regions = function () {
        return _regions;
    };

    this.region = function () {
        var apiUrl = envConfig.apiUrl();
        var region = _regions[0];

        if (apiUrl.indexOf('-uk') !== -1) {
            region = _regions[1];
        } else if (apiUrl.indexOf('-ca') !== -1) {
            region = _regions[2];
        } else if (apiUrl.indexOf('-eu') !== -1) {
            region = _regions[3];
        } else if (apiUrl.indexOf('-au') !== -1) {
            region = _regions[4];
        }

        return region;
    };

    this.regionType = function () {
        var apiUrl = envConfig.apiUrl();

        return apiUrl.indexOf('gcp') !== -1 ? 'GCP' : 'AWS';
    };
}
