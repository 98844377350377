import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsActivitylog: IRoute = {
  name: 'app.reports.activitylog',
  stateDefinition: {
    url: '/activitylog?user&computer&app&site&title&exec&description',
    params: {
      user: null,
      computer: null,
      app: null,
      site: null,
      title: null,
      exec: null,
      description: null
    },
    component: 'activityLog',
    resolve: {
      activityTimeSettings: [
        'timezoneService',
        async function (timezoneService) {
          try {
            const results = await timezoneService.getShowLocalTimezone();
            return results?.data ?? false;
          } catch (error) {
            console.error(
              'ActivTrak Error: Error retrieving show local timezone setting.',
              error
            );
          }

          return false;
        }
      ]
    },

    data: {
      pageTitle: 'Activity Log',
      filter: { columns: true, export: true },
      exportText: 'export',
      reportName: 'activityLogReportName',
      sHomeRoute: false
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.ActivityLog,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.ActivityLog]
  }
};

export default appReportsActivitylog;
