import React from 'react';
import Notifications from './common/components/Notifications/Notifications';
import { AuthorizationProvider } from './common/services/Authorization';
import { PrivateModeProvider } from './common/services/PrivateMode/PrivateModeProvider';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material';
import { getTheme } from './common/styles/theme';
import { NotificationProvider } from './common/services/Notifications';
import { AppContainer } from './common/styles/app.component.styles';

// TODO: EXPERIMENTAL!
// This is a first pass on wrapping components independentyl
// Please don't replicate without notifiying the team

export const ReactivTrakComponentWrapper = (props) => {
  const theme = getTheme();

  return (
    <ThemeProviderV5 theme={theme}>
      <NotificationProvider>
        <AppContainer>
          <Notifications />
          <AuthorizationProvider>
            <PrivateModeProvider>{props.children}</PrivateModeProvider>
          </AuthorizationProvider>
        </AppContainer>
      </NotificationProvider>
    </ThemeProviderV5>
  );
};
