'use strict';
var analyticEventServiceFunctions = require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;

angular.module('app').controller('SqlDataCtrl', SqlDataCtrl);

SqlDataCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    'messagesService',
    'accountSettings',
    'envService',
    'authorizationService',
    '$window',
    'notificationService',
    'utmService'
];

function SqlDataCtrl(
    $scope,
    $state,
    $timeout,
    msg,
    accountSettings,
    envService,
    authorizationService,
    $window,
    notificationService,
    utmService
) {
    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(
            levels,
            'app.activConnect'
        );
    };

    $scope.isDataConsoleEnabled = accountSettings.isDataConsoleEnabled;
    $scope.analyticEventServiceFunctions = analyticEventServiceFunctions;

    $scope.clickOverview = function() {
        $scope.analyticEventServiceFunctions.newEvent('Connect - Overview',  {name: "Overview Link"});
    };

    this.$onInit = function() {
        $scope.analyticEventServiceFunctions.newEvent('Connect - Loaded',  {name: "Connect Page"});
    };

    $scope.goToUpgrade = function () {
        $state.go('app.account.upgrade', {
            rawdata: 1
        });
    };

    $scope.createTicket = function () {
        $state.go('app.help.tickets', {
            id: 'new',
            subject: msg.get('sqlDataTicketSubject', accountSettings.account),
            description: msg.get(
                'sqlDataTicketDescription',
                accountSettings.account
            )
        });
    };

    $scope.scrollToInfo = function () {
        $('#info-block')[0].scrollIntoView({
            alignToTop: true,
            behavior: 'smooth'
        });
    };

    var updateData = function () {
        var sqlButton = angular.element('#sqlButton');
        var sqlLink = angular.element('#sqlLink');

        sqlButton.hide();
        sqlLink.hide();

        if ($scope.isDataConsoleEnabled) {
            $scope.sqlButtonText = msg.get('gotoConsole');
            $scope.sqlMsg = msg.get('clickButtonSqlGoToConsole');
            $scope.statusIcon = 'fa-circle synced';
            sqlLink.show();
        } else {
            $scope.sqlButtonText = msg.get('enableDataConnect');
            $scope.sqlMsg = msg.get('clickButtonSqlEnable');
            $scope.buttonHandler = $scope.goToUpgrade;
            $scope.statusIcon = 'fa-circle';
            sqlButton.show();
        }
    };

    $scope.preBuiltConnections = [
        {
            title: 'ActivTrak for Power BI',
            image: 'postsale_dataconnect_logos/power-bi.svg',
            showInstall: true,
            showDownload: true,
            showCopy: false,
            installUrl: 'https://appsource.microsoft.com/en-us/product/power-bi/birchgrovesoftwareincdbaactivtrak1607377475506.activtrak-powerbi?exp=ubp8',
            downloadUrl: 'https://storage.googleapis.com/activconnect-templates/ActivTrak%20for%20PBI%20v3.2.pbit',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360059715171',
            heapID: '30dcac8dac7e4e0abc01b824e499ca8d'
        },
        {
            title: 'ActivTrak for MS Teams',
            image: 'postsale_dataconnect_logos/ms-teams.svg',
            showInstall: true,
            showDownload: false,
            showCopy: false,
            installUrl: 'https://appsource.microsoft.com/en-us/product/power-bi/birchgrovesoftwareincdbaactivtrak1607377475506.activtrak-powerbi?exp=ubp8',
            downloadUrl: 'https://storage.googleapis.com/activconnect-templates/ActivTrak-for-PBI-v3.1.pbit',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360059715171',
            heapID: '9f185449315b4094bd03f62b12531579'
        },
        {
            title: 'ActivTrak for Looker Studio',
            subtitle: 'formerly Google Data Studio',
            image: 'postsale_dataconnect_logos/looker-studio.svg',
            showInstall: false,
            showDownload: false,
            showCopy: true,
            starterTemplateUrl: 'https://lookerstudio.google.com/reporting/f2b8445f-a630-4d1c-877a-8ec03d3a6022/page/p_xiflsfb90c',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/4402972794011',
            heapID: '4b29a076dc3d4aec857a3de9ec8ebb50'
        },
        {
            title: 'ActivTrak for Tableau',
            image: 'postsale_dataconnect_logos/tableau.svg',
            showInstall: false,
            showDownload: true,
            showCopy: false,
            downloadUrl: 'https://storage.googleapis.com/activconnect-templates/ActivTrak-for-Tableau-v3.0.twbx',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360039587512',
            heapID: '06539002bde34cf889b326abe190536c'
        }
    ];

    $scope.otherConnections = [
        {
            title: 'Google Sheets',
            image: 'postsale_dataconnect_logos/google-sheets.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/13111175199771',
            heapID: '8c31293d66b840f78ef37ed719480e3d'
        },
        {
            title: 'Qlik',
            image: 'postsale_dataconnect_logos/qlik.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/13111253255835',
            heapID: '238c58b616d44659ace8a8c5e3289df2'
        },
        {
            title: 'SQL Queries',
            image: 'postsale_dataconnect_logos/sql.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/13111290911259',
            heapID: '05a022f4b7814d2da1b5591f677f6283'
        },
        {
            title: 'Looker',
            image: 'postsale_dataconnect_logos/looker.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360039987751',
            heapID: '9de0d8d9ac5e4840989d535ce2c5f629'
        },
        {
            title: 'Sisense / Periscope',
            image: 'postsale_dataconnect_logos/sisense.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360039987751',
            heapID: '82e1ae31ffa748c8909e7f763fc9cd01'
        },
        {
            title: 'Domo',
            image: 'postsale_dataconnect_logos/domo.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360039987751',
            heapID: '60491935b2c4474899640ce283fb2474'
        },
        {
            title: 'Excel',
            image: 'postsale_dataconnect_logos/excel.svg',
            setupGuideUrl: 'https://support.activtrak.com/hc/en-us/articles/360039987751',
            heapID: '4ecfa79788ea44c194d9c17cd3026e10'
        }
    ];

    var marketoConfig = envService.getMarketoConfig();
    $scope.requestQuote = function () {
        $window.MktoForms2.loadForm(
            marketoConfig.formUrl,
            marketoConfig.munchkinId,
            marketoConfig.dataConnect.quoteRequestFormId,
            function (form) {
                var vals = {
                    Phone: accountSettings.phone,
                    FirstName: accountSettings.firstName,
                    LastName: accountSettings.lastName,
                    Email: accountSettings.username,
                    Company: accountSettings.companyName
                };
                utmService.configureForm(form, vals, utmService.getHardcodedMktoHiddenFields(), function () {
                    notificationService.showNotification(
                        'Quote request received. A member of our sales team will reach out to you shortly.',
                        'success'
                    );

                    return false;
                });
                $window.MktoForms2.lightbox(form).show();
            }
        );
    };

    $scope.requestDemo = function () {
        $window.MktoForms2.loadForm(
            marketoConfig.formUrl,
            marketoConfig.munchkinId,
            marketoConfig.dataConnect.demoRequestFormId,
            function (form) {
                var vals = {
                    Phone: accountSettings.phone,
                    FirstName: accountSettings.firstName,
                    LastName: accountSettings.lastName,
                    Email: accountSettings.username,
                    Company: accountSettings.companyName
                };
                utmService.configureForm(form, vals, utmService.getHardcodedMktoHiddenFields(), function () {
                    notificationService.showNotification(
                        'Demo request received. A member of our sales team will reach out to you shortly.',
                        'success'
                    );

                    return false;
                });
                $window.MktoForms2.lightbox(form).show();
            }
        );
    };

    $timeout(updateData);
}
