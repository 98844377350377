export const widgetTitle = {
  activity: 'Activity',
  productivity: 'Productivity',
  topCategories: 'Top Categories',
  workload: 'Workload'
};

export const modalTitle = {
  todaysProductivity: 'Goal Achievement by Team',
  todaysActivity: 'Activity by Team',
  productivity: 'User Goal Achievement',
  workload: 'User Workload Levels',
  orgProductivity: 'Team Goal Achievement',
  orgWorkload: 'Teams with Non-optimal workload'
};
