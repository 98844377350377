export enum ClassificationType {
  Pending = 'Pending',
  PendingCategorization = 'Pending Category',
  PendingProductivity = 'Pending Productivity',
  AutoClassified = 'Auto-Classified',
  GroupClassified = 'Group Classified',
  ManuallyClassified = 'Classified',
  Ignored = 'Ignored',
  All = 'All'
}
