import { useCallback, useState } from 'react';
import _ from 'lodash';
import {
  IReportModalListItem,
  IWeeklyUsers,
  IWorkloadLevels,
  IWorkloadLevelsDto,
  IWorkloadUsersState
} from '../models';
import {
  buildReportsApiRequest,
  checkIfNoResults,
  mapToWorkloadByWeek,
  mapWorkloadUsers
} from '../utils';
import { ApiRoutes } from '../constants';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useWorkloadUsersState = (): IWorkloadUsersState => {
  const [workloadData, setWorkloadData] = useState<IWorkloadLevels>({
    chartData: {
      labels: [],
      datasets: [],
      weeklyUsers: []
    }
  });
  const [originalWorkloadData, setOriginalWorkloadData] =
    useState<IWorkloadLevels>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [workloadUsers, setWorkloadUsers] = useState<IReportModalListItem[]>();
  const [allUsersByWeek, setAllUsersByWeek] = useState<IWeeklyUsers[]>();

  const [hasNoResults, setHasNoResults] = useState<boolean>(false);

  const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
    setIsLoading(true);
    setIsTileError(false);
    try {
      const response = await buildReportsApiRequest<IWorkloadLevelsDto>({
        path: ApiRoutes.reports.fetchWorkloadByWeek,
        params: { groupId: reportFilters.groupId[0] }
      });
      const mapped = mapToWorkloadByWeek(response);

      setOriginalWorkloadData(mapped);
      setWorkloadData(mapped);
      setAllUsersByWeek(mapped.chartData.weeklyUsers);

      if (response.users?.length) {
        const mappedUsers = mapWorkloadUsers(response.users);
        setWorkloadUsers(mappedUsers);
      }

      const noResults = checkIfNoResults(mapped.chartData.datasets);

      setHasNoResults(noResults);
    } catch (error) {
      console.error(`ActivTrak Error: Users Workload Tile: ${error}`, error);
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateChart = useCallback(
    (newSelections: (number | string)[]) => {
      //start with the original set of data
      //need to deep clone, otherwise the original set of data will also be updated
      const newData: IWorkloadLevels = _.cloneDeep(originalWorkloadData);

      if (newData) {
        newData.chartData.datasets.forEach((item, i) => {
          if (!newSelections.includes(i))
            newData.chartData.datasets[i].data = null;
        });

        setWorkloadData(newData);
      }
    },
    [originalWorkloadData]
  );

  return {
    workloadData,
    isLoading,
    isTileError,
    workloadUsers,
    allUsersByWeek,
    init,
    updateChart,
    hasNoResults
  };
};
