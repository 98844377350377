import { localStorageItems } from '../../common/constants';
import {
  getParsedLocalStorageItem,
  setLocalStorageItem
} from '../../common/helpers';
import { IAccountSettings } from '../../common/models';
import { LoadApplication } from '../../common/services/Application/AppLoad';

export const fetchScreenshotFFLocalStorage = (): boolean => {
  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  return accountSettings.realtimeSettings?.screenshotFeatureFlag ? true : false;
};

export const saveScreenshotFFLocalStorage = (
  screenshotFeatureFlag: boolean
) => {
  const updatedAccountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  updatedAccountSettings.realtimeSettings = {
    ...updatedAccountSettings.realtimeSettings,
    screenshotFeatureFlag: screenshotFeatureFlag
  };
  // TODO: Remove double stringify once all UI components use the same library
  const singleStringifiedSettings = JSON.stringify(updatedAccountSettings);
  const doubleStringifiedSettings = JSON.stringify(singleStringifiedSettings);
  setLocalStorageItem(
    localStorageItems.accountSettingsStorage,
    doubleStringifiedSettings
  );

  //reload the application to get the new account settings
  LoadApplication();
};
