import React, { Fragment } from 'react';
import { StatusCount } from './';
import { getClasses } from './GroupCard.style';
import ActiveStatus from '../../models/ActiveStatus';
import TopUsers from './TopUsers/TopUsers';
import TeamPulseCard from '../TeamPulseCard/TeamPulseCard';

import { CardTopActivity, CardTopCategory, CardTotalTime, CardProductiveTime, InfoPanelVariant } from '../Panels';
import { CardTitle } from '../UserCard';
import User from '../../models/User';
import TimeMode from '../../models/TimeMode';
import ReportMode from '../../models/ReportMode';

const GroupCard = (props) => {
    const {
        groupPulse,
        isReport,
        teamPulseDataControl,
        teamPulseDataPoller,
        loading,
        timeFormat,
        timeMode,
        filterMode,
        filterModes
    } = props;
    const isActive = groupPulse.isActiveToday();
    const classes = getClasses(isActive);
    const users = groupPulse.userPulses.map((u) => new User(u));

    return (
        <TeamPulseCard isReport={isReport} active={isActive}>
            <CardTitle
                rootClass={classes.titleTopMargins}
                contentClass={classes.sidePaddings}
                teamPulseDataControl={teamPulseDataControl}
                teamPulseDataPoller={teamPulseDataPoller}
                name={groupPulse.name}
                id={groupPulse.id}
                groupProperties={groupPulse.groupProperties}
                users={users}
                productiveTotalRatio={groupPulse.productiveTotalRatio}
                productiveActiveRatio={groupPulse.productiveActiveRatio}
                isReport={isReport}
                timeFormat={timeFormat}
                isActive={isActive}
                timeMode={timeMode}
                reportMode={ReportMode.Groups}
                filterMode={filterMode}
                filterModes={filterModes}
                type="group"
                loading={loading}
            ></CardTitle>
            <hr style={classes.hr} />
            <div style={classes.topPaddings}>
                <div style={classes.sidePaddings}>
                    <div style={classes.statusCounts}>
                        <StatusCount
                            loading={loading}
                            activeStatus={ActiveStatus.Active}
                            count={groupPulse.activeCount}
                        />
                        <StatusCount
                            loading={loading}
                            activeStatus={ActiveStatus.Passive}
                            count={groupPulse.passiveCount}
                        />
                        <StatusCount
                            loading={loading}
                            activeStatus={ActiveStatus.Inactive}
                            count={groupPulse.inactiveCount}
                        />
                    </div>
                </div>
            </div>
            {isReport && (
                <Fragment>
                    <CardProductiveTime
                        id={groupPulse.id}
                        productiveTotalRatio={groupPulse.productiveTotalRatio}
                        productiveActiveRatio={groupPulse.productiveActiveRatio}
                        timeMode={timeMode}
                        filterMode={filterMode}
                        filterModes={filterModes}
                        type="group"
                        loading={loading}
                        reportMode={ReportMode.Groups}
                    />
                    <CardTotalTime
                        id={groupPulse.id}
                        rootClass={classes.topPaddings}
                        contentClass={classes.sidePaddings}
                        totalTime={groupPulse.avgTotalTime}
                        activeTime={groupPulse.avgActiveTime}
                        timeMode={timeMode}
                        filterMode={filterMode}
                        filterModes={filterModes}
                        reportMode={ReportMode.Groups}
                        loading={loading}
                    />
                    <hr style={classes.hr} />
                    <TopUsers
                        rootClass={classes.topPaddings}
                        contentClass={classes.sidePaddings}
                        useTotalTime={timeMode === TimeMode.Total}
                        users={users}
                        loading={loading}
                    />
                </Fragment>
            )}
            <hr style={classes.hr} />
            {isReport ? (
                <CardTopCategory
                    rootClass={classes.topPaddings}
                    contentClass={classes.sidePaddings}
                    topCategories={groupPulse.topCategory}
                    loading={loading}
                    type="group"
                    variant={InfoPanelVariant.Stacked}
                />
            ) : (
                <CardTotalTime
                    id={groupPulse.id}
                    rootClass={classes.smallTopPaddings}
                    contentClass={classes.sidePaddings}
                    totalTime={groupPulse.avgTotalTime}
                    activeTime={groupPulse.avgActiveTime}
                    timeMode={timeMode}
                    filterMode={filterMode}
                    filterModes={filterModes}
                    align="left"
                    type="group"
                    loading={loading}
                    reportMode={ReportMode.Groups}
                />
            )}
            <hr style={classes.hr} />
            <CardTopActivity
                teamPulseDataPoller={teamPulseDataPoller}
                teamPulseDataControl={teamPulseDataControl}
                rootClass={isReport ? classes.topPaddings : classes.smallTopPaddings}
                contentClass={classes.sidePaddings}
                topActivities={groupPulse.topActivities}
                loading={loading}
                type="group"
                variant={isReport ? InfoPanelVariant.Stacked : InfoPanelVariant.Inline}
            />
        </TeamPulseCard>
    );
};

export default GroupCard;
