import React, { useEffect, useState } from 'react';
import UserHealthChart from './UserHealthChart';
import { useUserHealthState } from '../../hooks';
import { AvailableDays } from '../../models';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import WidgetLink from '../../../common/components/WidgetLink';
import UserHealthModal from './UserHealthModal';
import {
  UserHealthCanvasBox,
  useUserHealthStyles as classes
} from '../../styles/UserHealth.styles';
import { useWidgetTemplateStyles as templateClasses } from '../../styles/templates.styles';
import { TemplateShadow, PopoverDotMenu } from '../templates';
import { getUserHealthConfig, setUserHealthConfigStorage } from '../../utils';

export default function UserHealth() {
  const [userHealthConfig, setUserHealthConfig] = useState<AvailableDays>(
    getUserHealthConfig()
  );

  const {
    init,
    chartData,
    activeSinceDays,
    dataPercentages,
    isWidgetStateLoading,
    setActiveSinceDays,
    setModalState,
    modalState,
    getData,
    widgetError
  } = useUserHealthState();

  useEffect(() => {
    init({ activeSinceDays: userHealthConfig });
  }, [init, userHealthConfig]);

  useEffect(() => {
    if (activeSinceDays && userHealthConfig != activeSinceDays) {
      getData({ activeSinceDays: activeSinceDays });
      setUserHealthConfigStorage(activeSinceDays);
      setUserHealthConfig(activeSinceDays);
    }
  }, [activeSinceDays, getData, userHealthConfig]);

  const handleConfigClick = (e) => {
    e.stopPropagation();
    setModalState(true);
  };

  return (
    <TemplateShadow>
      <Grid item xs={12} sx={templateClasses.spacedContentHeader}>
        <WidgetLink
          text="User Health"
          redirectTo={'app.settings.useragents'}
          redirectParams={{ limit: 30 }}
          id="315d38b6-bd3a-11ed-afa1-0242ac120002"
        />
        <UserHealthModal
          activeSinceDays={activeSinceDays}
          setActiveSinceDays={setActiveSinceDays}
          modalState={modalState}
          setModalState={setModalState}
        />
        {!widgetError && (
          <PopoverDotMenu
            popoverText="Configure chart"
            handlePopoverItemClick={handleConfigClick}
            id="c5977db4-c8e5-11ed-afa1-0242ac120002"
          />
        )}
      </Grid>
      {widgetError && (
        <UserHealthCanvasBox
          sx={{
            padding: '10px'
          }}
        >
          Error retrieving data.
        </UserHealthCanvasBox>
      )}
      {(isWidgetStateLoading || !chartData) && !widgetError && (
        <Box sx={classes.circularBox}>
          <CircularProgress size="40vh" sx={classes.circularProgress} />
        </Box>
      )}
      {chartData && (
        <UserHealthCanvasBox>
          <UserHealthChart
            chartData={chartData}
            activeSinceDays={activeSinceDays}
            dataPercentages={dataPercentages}
          />
        </UserHealthCanvasBox>
      )}
    </TemplateShadow>
  );
}
