import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const SettingsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Settings';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M21.1688 13.176C21.2182 12.792 21.2552 12.408 21.2552 12C21.2552 11.592 21.2182 11.208 21.1688 10.824L23.7719 8.844C24.0063 8.664 24.068 8.34 23.9199 8.076L21.4526 3.924C21.3046 3.66 20.9715 3.564 20.7001 3.66L17.6282 4.86C16.9867 4.38 16.2959 3.984 15.5433 3.684L15.0745 0.504C15.0375 0.216 14.7785 0 14.4701 0H9.5354C9.22698 0 8.96791 0.216 8.9309 0.504L8.46211 3.684C7.70957 3.984 7.01872 4.392 6.37722 4.86L3.30539 3.66C3.02165 3.552 2.7009 3.66 2.55286 3.924L0.0855319 8.076C-0.0748444 8.34 -0.000824607 8.664 0.233572 8.844L2.8366 10.824C2.78726 11.208 2.75025 11.604 2.75025 12C2.75025 12.396 2.78726 12.792 2.8366 13.176L0.233572 15.156C-0.000824607 15.336 -0.0625078 15.66 0.0855319 15.924L2.55286 20.076C2.7009 20.34 3.03399 20.436 3.30539 20.34L6.37722 19.14C7.01872 19.62 7.70957 20.016 8.46211 20.316L8.9309 23.496C8.96791 23.784 9.22698 24 9.5354 24H14.4701C14.7785 24 15.0375 23.784 15.0745 23.496L15.5433 20.316C16.2959 20.016 16.9867 19.608 17.6282 19.14L20.7001 20.34C20.9838 20.448 21.3046 20.34 21.4526 20.076L23.9199 15.924C24.068 15.66 24.0063 15.336 23.7719 15.156L21.1688 13.176ZM12.0027 16.2C9.62175 16.2 7.6849 14.316 7.6849 12C7.6849 9.684 9.62175 7.8 12.0027 7.8C14.3837 7.8 16.3205 9.684 16.3205 12C16.3205 14.316 14.3837 16.2 12.0027 16.2Z"
      />
    </SvgIconWrapper>
  );
};
