'use strict';

angular.module('app').controller('changeViewableAccountsModalController', ChangeViewableAccountsModalController);

ChangeViewableAccountsModalController.$inject = [
    '$scope',
    '$timeout',
    '$uibModalInstance',
    'modalData',
    'accessApiService',
    'notificationService',
    'messagesService',
    'atHelperFunctions'
];

function ChangeViewableAccountsModalController(
    $scope,
    $timeout,
    $uibModalInstance,
    modalData,
    accessApiService,
    notificationService,
    msg,
    atHelperFunctions
) {
    var allCurrentAndFutureAccounts = -1;
    var allCurrentAccounts = -2;

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.viewableAccountsHeight = 650;
    $scope.hasViewLevel = modalData.hasViewLevel;
    $scope.currentUser = modalData.currentUser;
    $scope.isAdmin = $scope.currentUser.admin;
    $scope.entity = initEntity($scope.currentUser.viewableAccounts, modalData.availableAccounts);
    $scope.selectAllIconClass = '';
    $scope.currentUserName = modalData.currentUser.user;

    function initEntity(source, available) {
        if (!source.accountId) {
            source.accountId = [];
        }

        var current = JSON.parse(JSON.stringify(available));

        if ($scope.isAdmin) {
            source.allChildAccounts = true;
        }

        if (source.allChildAccounts) {
            current[0].selected = true;
        } else if (source.accountId.length > 0) {
            current[1].selected = true;
            for (var i = 0; i < source.accountId.length; i++) {
                var id = source.accountId[i];
                var item = current.find(function (x) {
                    x.accountId.toString() === id.toString();
                });

                if (item) {
                    item.selected = true;
                }
            }
        }
        return current;
    }

    $scope.itemStyle = function (item) {
        var paddingLeft = '0';

        if (item.accountId > 0) {
            if (item.tier === 2) {
                paddingLeft = '32px';
            } else {
                paddingLeft = '16px';
            }
        }

        return {
            'padding-left': paddingLeft,
            'margin-bottom': '15px'
        };
    };

    $scope.checkBoxStyle = function (item) {
        var activeBg = false;
        if (item.selected) {
            activeBg = true;
        }
        //childs selected
        else if (item.accountId === allCurrentAccounts) {
            var currentSelection = getItems($scope.entity, true);
            if (currentSelection.length > 0) {
                activeBg = true;
            }
        }
        if (item.accountId < 1 || item.allowSelection) {
            return {
                cursor: $scope.isAdmin ? 'not-allowed' : 'pointer',
                opacity: $scope.isAdmin ? '.38' : '1',
                color: activeBg ? ' #41c7b7' : 'black'
            };
        } else {
            return {
                cursor: 'not-allowed'
            };
        }
    };

    $scope.containerStyle = function () {
        return {
            'overflow-x': 'auto',
            height: atHelperFunctions.getGridHeight(650) + 'px'
        };
    };

    $scope.isAnyViewableAccountSelected = function () {
        return $scope.entity.some(function (account) {
            return account.selected;
        });
    };

    $scope.changeSelection = function (item) {
        if (item.accountId < 1 || item.allowSelection) {
            switch (item.accountId) {
                case allCurrentAndFutureAccounts:
                    $scope.selectAll(item);
                    break;
                case allCurrentAccounts:
                    $scope.selectAll(item);
                    break;
                default:
                    angular.forEach($scope.entity, function (x) {
                        //Clear any previous select all future selection
                        if (x.accountId === allCurrentAndFutureAccounts && x.selected) x.selected = false;
                    });

                    item.selected = !item.selected;
                    break;
            }
        }
    };

    $scope.selectAll = function (selectedAccount) {
        if ($scope.isAdmin) return;

        var selection = false;

        if (selectedAccount.accountId === allCurrentAccounts) {
            selectedAccount.selected = !selectedAccount.selected;
            selection = selectedAccount.selected;
        }

        angular.forEach($scope.entity, function (item) {
            if (
                selectedAccount.accountId === allCurrentAndFutureAccounts &&
                item.accountId === selectedAccount.accountId
            )
                item.selected = !item.selected;
            else if (selectedAccount.accountId === allCurrentAccounts && item.accountId === allCurrentAndFutureAccounts)
                item.selected = false;
            else item.selected = selection;
        });
    };

    $scope.getCheckIcon = function (item) {
        if (item.accountId === allCurrentAccounts) {
            //Determine if there is a partial selection
            var currentSelection = getItems($scope.entity, true);
            var available = getItems(modalData.availableAccounts, false);

            if (currentSelection.length > 0) {
                if (currentSelection.length === available.length) return 'check_box';

                return 'indeterminate_check_box';
            }

            return 'check_box_outline_blank';
        }
        if (item.accountId < 1 || item.allowSelection) {
            return item.selected ? 'check_box' : 'check_box_outline_blank';
        } else {
            return 'stop';
        }
    };

    function getItems(items, selectedOnly) {
        var result = items.filter(function (x) {
            x.accountId > 0 && (!selectedOnly || x.selected);
        });

        result.sort(function (x, y) {
            return x.accountId > y.accountId;
        });

        return result;
    }

    $scope.saveViewableAccounts = function () {
        if ($scope.isAdmin || !$scope.hasViewLevel('edit')) {
            return;
        }

        var id = [];
        var allChildren = false;

        if ($scope.entity) {
            angular.forEach($scope.entity, function (item) {
                if (item.selected) {
                    if (item.accountId === allCurrentAndFutureAccounts) allChildren = true;
                    else if (item.accountId != allCurrentAccounts) id.push(item.accountId);
                }
            });
        }

        var payload = {
            userName: $scope.currentUser.user,
            accountId: id,
            allChildAccounts: allChildren
        };

        accessApiService
            .saveViewableAccounts(payload)
            .success(function () {
                $scope.dismiss();
                notificationService.showNotification(msg.get('userViewableAccountsUpdated'), 'success');
            })
            .error(function () {
                $scope.dismiss();
                notificationService.showNotification(msg.get('userUpdatingError'), 'danger');
            });
    };
}
