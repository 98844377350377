export enum FeatureFlag {
  Reactivate = 'reactivateFeatureFlag',
  RealtimeScreenShots = 'realtimeScreenshotsFeatureFlag',
  RealtimeThumbnails = 'realtimeThumbnailsFeatureFlag',
  VideoPlayback = 'videoAlarmsFeatureFlag',
  CommandCenterAppAccessSettings = 'commandCenterAppAccessSettingsFeatureFlag',
  InsightsAvailable = 'insightsAvailableFeatureFlag',
  InsightsScheduling = 'insightsSchedulingFeatureFlag',
  InsightsEnabled = 'insightsEnabledFeatureFlag',
  ShowTeamPulseScreenViews = 'showTeamPulseScreenViewsFeatureFlag',
  ShowActivation = 'showActivationFeatureFlag',
  SupportTickets = 'showSupportTicketsFeatureFlag',
  ExcludeTodaysDateInFilter = 'excludeTodaysDateInFilterFeatureFlag',
  ShowOfflineMeetingData = 'showOfflineMeetingDataFeatureFlag',
  ShowLocationData = 'showLocationDataFeatureFlag'
}
