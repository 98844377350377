import { IUserIdentity } from '../models/IUserIdentity';

export const DefaultUserIdentity: IUserIdentity = {
  firstUserId: 0,
  csvUsers: '',
  userCount: 0,
  maxLastLog: new Date(),
  emails: [],
  employeeId: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: ''
  },
  agents: [],
  id: 0,
  emailsDisplay: '',
  nameColumnDisplay: '',
  firstName: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: ''
  },
  middleName: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: ''
  },
  lastName: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: ''
  },
  displayName: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: ''
  },
  revision: 0,
  selected: false,
  singleDescription: '',
  tracked: false
};
