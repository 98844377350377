import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { PrivacySetting } from '../../../../../enums/PrivacySetting';
import { IRoute } from '../../../../../models/IRoute';

export const appScreenshotsHistory: IRoute = {
  name: 'app.screenshots.history',
  stateDefinition: {
    url: '/screenshots?computer&user&title&url&executable&description',
    params: {
      computer: null,
      url: null,
      title: null,
      user: null,
      executable: null,
      description: null,
      safeScreenshot: null
    },
    template: '<screenshots-report></screenshots-report>',
    data: {
      pageTitle: 'Screenshots',
      filter: { productivity: true, configure: true, export: true },
      exportText: 'exportScreenshots',
      reportName: 'screenshotsLowercase'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsScreenshotsReports,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User],
    bundleFlags: [BundleFlag.ScreenshotsHistoryReport],
    privacySettings: [PrivacySetting.Screenshots]
  }
};

export default appScreenshotsHistory;
