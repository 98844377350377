import { styled } from '@mui/material/styles';
import {
  secondaryFontSize,
  mediumFontWeight,
  primaryColor
} from '../../constants';
import LaunchIcon from '@mui/icons-material/Launch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '../Link';

export const WidgetLinkStyled = styled(Link)`
  cursor: pointer;
`;

export const WidgetLinkWrapper = styled(Stack)(({ theme }) => ({
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'left'
  }
}));

export const WidgetLinkTypography = styled(Typography)`
  display: inline;
  color: ${primaryColor};
  font-size: ${secondaryFontSize} !important;
  font-weight: ${mediumFontWeight} !important;
  text-transform: uppercase;
`;

export const WidgetLinkIcon = styled(LaunchIcon)`
  position: relative;
  top: 5px;
  left: 5px;
  cursor: pointer;
  font-size: 18px !important;
`;
