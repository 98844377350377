'use strict';

angular.module('app').service('exportProgressService', ExportProgressService);

ExportProgressService.$inject = ['$window', '$timeout', 'tabNotificationService'];

function ExportProgressService($window, $timeout, tabNotificationService) {

    // Parameter Declarations
    var _exportId;
    var _exportName;
    var _exportStatus;
    var _exportProgress = 0;
    var _completeCallback;
    var _completeCallbackType;
    var _showProgressBar = false;
    var _isProgressReal = false;
    var _data;
    var _downloadUrl;
    var _timeoutPromises = [];
    var _cancelInProgress;

    // Get/Set Parameters
    this.exportId = function(id) {
        if (id !== undefined) {
            _exportId = id;
        }

        return _exportId;
    };

    this.exportName = function(name) {
        if (name !== undefined) {
            _exportName = name;
        }

        return _exportName;
    };

    this.exportStatus = function(status) {
        if (status !== undefined) {
            _exportStatus = status;
        }

        return _exportStatus;
    };

    this.exportProgress = function(value) {
        if (value !== undefined) {
            _exportProgress = value;
        }

        return _exportProgress;
    };

    this.exportProgressWidth = function() {
        return { width: _exportProgress + '%' };
    };

    this.completeCallback = function(callback) {
        if (callback !== undefined) {
            _completeCallback = callback;
        }

        return _completeCallback;
    };

    this.completeCallbackType = function(type) {
        if (type !== undefined) {
            _completeCallbackType = type;
        }

        return _completeCallbackType;
    };

    this.showProgressBar = function(show) {
        if (show !== undefined) {
            _showProgressBar = show;
        }

        return _showProgressBar;
    };

    this.realProgress = function(isReal) {
        if (isReal !== undefined) {
            _isProgressReal = isReal;
        }

        return _isProgressReal;
    };

    this.exportData = function (data) {
        if (data !== undefined) {
            _data = data;
        }

        return _data;
    };

    this.downloadUrl = function (data) {
        if (data !== undefined) {
            _downloadUrl = data;
        }
        return _downloadUrl;
    };

    this.timeoutPromises = function (data) {
        if (data !== undefined) {
            _timeoutPromises.push(data);
        }
        return _timeoutPromises;
    }

    this.timeoutPromisesClear = function () {
        _timeoutPromises.forEach(function (timeout) {
            return $timeout.cancel(timeout);
        });
    }

    this.cancelInProgress = function (data) {
        _cancelInProgress = data;
        return _cancelInProgress;
    }

    this.getCancelInProgress = function () {
        return _cancelInProgress;
    }

    // Service Functions
    this.setExportProgress = function(id, name, status, data) {
        _exportId = id;
        _exportName = name;
        _exportStatus = status;
        _data = data;
        _downloadUrl = _.isPlainObject(data) ? data.downloadUrl : undefined;
    };

    this.clearExportProgress = function() {
        this.timeoutPromisesClear();
        this.resetExportProgress();
        tabNotificationService.notify('Export finished');
    };

    this.resetExportProgress = function() {
        _exportId = undefined;
        _exportName = undefined;
        _exportProgress = 0;
        _completeCallback = undefined;
        _completeCallbackType = undefined;
        _showProgressBar = false;
        _data = undefined;
        _downloadUrl = undefined;
        this.timeoutPromisesClear();
    }

    this.completeExport = function(downloadUrl) {
        if (typeof _completeCallback === 'function') {
            _completeCallback(downloadUrl);
        }
        else {
            $window.location = downloadUrl;
        }
        this.clearExportProgress();
    };
}
