import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const CoachIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Coach';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM18.291 8.17857C18.291 9.46 17.292 10.5 16.05 10.5C14.808 10.5 13.8 9.46 13.8 8.17857C13.8 6.89714 14.808 5.85714 16.05 5.85714C17.292 5.85714 18.291 6.89714 18.291 8.17857ZM11.991 6.78571C11.991 8.32714 10.794 9.57143 9.3 9.57143C7.806 9.57143 6.6 8.32714 6.6 6.78571C6.6 5.24429 7.806 4 9.3 4C10.794 4 11.991 5.24429 11.991 6.78571ZM11.1 14.9107C11.1 13.2114 14.403 12.3571 16.05 12.3571C17.697 12.3571 21 13.2114 21 14.9107V17H11.1V14.9107ZM3 14.6786C3 12.515 7.203 11.4286 9.3 11.4286C9.894 11.4286 10.65 11.5214 11.433 11.6886C9.597 12.7379 9.3 14.1214 9.3 14.9107V17H3V14.6786Z"
      />
    </SvgIconWrapper>
  );
};
