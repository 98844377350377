import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { groupViewsClasses } from '../../styles/Group.views.styles';

export const UploadLoader = () => {
  return (
    <Box sx={groupViewsClasses.dispProgress}>
      <Typography variant="h3">Creating Groups</Typography>
      <Box sx={groupViewsClasses.uploadFileProgressContainer}>
        <Box sx={groupViewsClasses.uploadFileProgressBar} />
        <LinearProgress />
      </Box>
    </Box>
  );
};
