import React from 'react';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { setReportFilters } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IGridColumn } from '../../common/models';
import { GridColumnsFilter } from '../../common/components/ReportFilters/components/GridColumnsFilter';
import { UserComputerFilter } from '../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../common/components/ReportFilters/components/AcademyButton';
import { ExportButtonContainer } from '../../common/components/ReportFilters/components/ExportButton';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../common/styles/app.component.styles';

export type WorkingHoursReportHeaderPlaygroundProps = {
  reportFilters: IReportFilters;
  isRefreshing: boolean;
  refreshTimestamp: number;
  selectedColumns: IGridColumn[];
  onSubmitColumns: (columns: IGridColumn[]) => void;
  onRestoreDefaults: () => void;
  onRefresh: () => void;
};

export const WorkingHoursReportHeaderPlayground = (
  props: WorkingHoursReportHeaderPlaygroundProps
) => {
  const {
    reportFilters,
    isRefreshing,
    selectedColumns,
    onSubmitColumns,
    onRestoreDefaults,
    onRefresh
  } = props;
  const { dates, users } = reportFilters;

  const authorizationService = useAuthorization();
  const ignoreDateRestrictions: boolean = authorizationService.hasRole(
    Role.SuperAdmin
  );

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ display: { xs: 'none', md: 'block' } }}>
          <GridColumnsFilter
            columns={selectedColumns}
            onSubmit={onSubmitColumns}
            onRestoreDefaults={onRestoreDefaults}
          />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ display: { xs: 'none', md: 'block' } }}>
          <ExportButtonContainer />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
