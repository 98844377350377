export enum ChannelType {
  Direct = 'Direct',
  VAR = 'VAR',
  OfficialMSPChildNFR = 'MSP Official - Child NFR',
  OfficialMSPChildHistoricNFR = 'MSP Official - Child (Historic NFR)',
  OfficialMSP = 'Official MSP',
  UnofficialMSP = 'Unofficial MSP',
  OfficialMSPParent = 'MSP Official - Parent',
  OfficialMSPChild = 'MSP Official - Child',
  EnterpriseChild = 'Enterprise - Child',
  EnterpriseParent = 'Enterprise - Parent',
  PartnerChild = 'Partner - Child',
  PartnerParent = 'Partner - Parent',
  ServicePartner = 'Service Partner',
  LicensePool = 'License Pool'
}

export enum SubChannelType {
  MSPParent = 'MSP Parent',
  MSPChild = 'MSP Child',
  MSPGrandparent = 'MSP Grandparent',
  ENTParent = 'ENT Parent',
  ENTChild = 'ENT Child',
  ENTGrandparent = 'ENT Grandparent'
}
