import React from 'react';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const AlertsPlayground = () => {
  return (
    <Stack sx={{ width: '100%', alignContent: 'start' }} spacing={2}>
      <Box>
        <Typography component="h3">Default</Typography>
        <Box>
          <Alert severity="success" sx={{ mb: 2 }}>
            This is a success Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="success"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a success Alert with no icon and an action button.
          </Alert>
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => {}}>
            This is a success Alert with a closing 'X'.
          </Alert>
          <Alert severity="info" sx={{ mb: 2 }}>
            This is an info Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="info"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an info Alert with no icon and an action button.
          </Alert>
          <Alert severity="info" sx={{ mb: 2 }} onClose={() => {}}>
            This is an info Alert with a closing 'X'.
          </Alert>
          <Alert severity="warning" sx={{ mb: 2 }}>
            This is a warning Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="warning"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a warning Alert with no icon and an action button.
          </Alert>
          <Alert severity="warning" sx={{ mb: 2 }} onClose={() => {}}>
            This is a warning Alert with a closing 'X'.
          </Alert>
          <Alert severity="error" sx={{ mb: 2 }}>
            This is an error Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="error"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an error Alert with no icon and an action button.
          </Alert>
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => {}}>
            This is an error Alert with a closing 'X'.
          </Alert>
        </Box>
      </Box>
      <Box>
        <Typography component="h3">Filled (Same as Default)</Typography>
        <Box>
          <Alert severity="success" variant="filled" sx={{ mb: 2 }}>
            This is a success Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="success"
            variant="filled"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a success Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="success"
            variant="filled"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is a success Alert with a closing 'X'.
          </Alert>
          <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
            This is an info Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="info"
            variant="filled"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an info Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="info"
            variant="filled"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is an info Alert with a closing 'X'.
          </Alert>
          <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
            This is a warning Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="warning"
            variant="filled"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a warning Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="warning"
            variant="filled"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is a warning Alert with a closing 'X'.
          </Alert>
          <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
            This is an error Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="error"
            variant="filled"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an error Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="error"
            variant="filled"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is an error Alert with a closing 'X'.
          </Alert>
        </Box>
      </Box>
      <Box>
        <Typography component="h3">Outlined</Typography>
        <Box>
          <Alert severity="success" variant="outlined" sx={{ mb: 2 }}>
            This is a success Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="success"
            variant="outlined"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a success Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="success"
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is a success Alert with a closing 'X'.
          </Alert>
          <Alert severity="info" variant="outlined" sx={{ mb: 2 }}>
            This is an info Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="info"
            variant="outlined"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an info Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="info"
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is an info Alert with a closing 'X'.
          </Alert>
          <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
            This is a warning Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="warning"
            variant="outlined"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is a warning Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="warning"
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is a warning Alert with a closing 'X'.
          </Alert>
          <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
            This is an error Alert... with a <Link href="#">link</Link>.
          </Alert>
          <Alert
            severity="error"
            variant="outlined"
            icon={false}
            action={<Button color="inherit">UNDO</Button>}
            sx={{ mb: 2 }}
          >
            This is an error Alert with no icon and an action button.
          </Alert>
          <Alert
            severity="error"
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => {}}
          >
            This is an error Alert with a closing 'X'.
          </Alert>
        </Box>
      </Box>
    </Stack>
  );
};
