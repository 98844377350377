import theme from '../../../../common/theme/theme';

const gradient = {
    start: theme.palette.success.gradientStart,
    end: theme.palette.success.gradientEnd,
    background: theme.palette.grey.A100
};

const getRootStyles = (ratio, loading) => {
    let background =
        ratio > 0
            ? `-webkit-linear-gradient(left, ${gradient.start},  ${gradient.end} ${ratio}%, ${
                  gradient.background
              } 1%, ${gradient.background} ${100 - ratio}%)`
            : gradient.background;

    if (loading) {
        background = theme.palette.activTrak.loading;
    }
    return {
        margin: 0,
        color: loading ? theme.palette.activTrak.loading : theme.palette.common.white,
        background,
        cursor: 'pointer'
    };
};

export const getClasses = (ratio, loading) => ({
    root: getRootStyles(ratio, loading),
    label: {
        paddingTop: theme.spacing(1),
        fontSize: '36px !important',
        fontWeight: 'bold',
        lineHeight: '36px'
    },
    sublabel: {
        paddingBottom: theme.spacing(1),
        fontSize: '14px !important',
        lineHeight: '14px'
    },
    skeletonBar: {
        background:
            'linear-gradient(left, rgba(0,0,0,0.08), rgba(0,0,0,0.08) 90%, rgba(0,0,0,0.04) 1%, rgba(0,0,0,0.04) 10%)'
    },
    disabled: {
        cursor: 'not-allowed !important'
    }
});
