import { primaryFontSize } from '../../common/constants';

export const sdmStyles = {
  tableContainer: {
    padding: '10px 0 10px 0'
  },
  cellSource: {
    fontWeight: '350' as any
  },
  cellDest: {},
  table: {},
  tableHeader: {
    fontSize: primaryFontSize as any,
    fontWeight: 'normal'
  },
  deleteButton: {
    border: '1px solid #D2D2D2'
  },
  emptyCell: {
    textAlign: 'center' as const,
    padding: '15px 0 15px 0'
  },
  timingLabel: {
    fontSize: '13px',
    paddingTop: '10px'
  },
  deleteDiv: {
    width: '100%',
    textAlign: 'center' as const
  }
};
