'use strict';

/*
    Description:    Grid Column Dropdown Control was created to minimize the effects of an active dropdown by putting a placeholder
                    object in place of the dropdown until the dropdown is needed.  This allowed for more dropdown controls to be populated
                    on screen.
    Author:         Andrew Shaw
    Date Created:   8-29-2017
*/

angular.module('app')
    .directive('gridColumnDropdownControl', gridColumnDropdownControl);

function gridColumnDropdownControl () {
    return {
        restrict: 'E',
        scope: {
            options: '<',
        },
        template: require('views/widgets/gridColumnDropdownControl.html'),
        controller: gridColumnDropdownControlCtrl
    };
}

gridColumnDropdownControlCtrl.$inject = ['$scope', '$timeout'];

function gridColumnDropdownControlCtrl($scope, $timeout) {

    $scope.$on('grid-updated', function(event, args) {
            $scope.options.rowCount = args.totalRows;
            $scope.setElement(false);
        });

    // Sets the active element
    $scope.setElement = function (showDropdown) {
        if (!showDropdown) {
            $scope.modelSelection = [];
            angular.forEach($scope.options.dropdownDataSource._data, function(value) {
                if ($scope.options.selectedId === value.id) {
                    $scope.modelSelection = value;
                }
            });

            // Add class for productivity coloring
            var placeholderClass = '';
            if ($scope.options.type === 'productivity') {
                placeholderClass = $scope.options.selectedId === 1 ? ' text-productive' : $scope.options.selectedId === -1 ? ' text-unproductive' : '';
            }

            // Create placeholder HTML string
            $scope.options.rowCount = 0; //Force disable dropdowns for all row counts.
            var activateDropdown = typeof $scope.options.rowCount === 'number' && $scope.options.rowCount > 0 && $scope.options.rowCount <= 25;
            var icon = '';
            var style = '';
            var click = '';
            if (activateDropdown) {
                icon = '<i class="fa fa-caret-down pull-right m-r-15 m-t-5"></i>';
                style = ' style="cursor: pointer; background-color: #f0f0f0; border-radius: 3px; padding: 5px; weight: 600;"';
                click = ' ng-click="setElement(true)"';
            }

            if ($scope.options.type === 'category' && $scope.options.selectedId <= 0) {
                $scope.modelSelection.name = '';
                if (activateDropdown) {
                    $scope.modelSelection.name = 'Uncategorized';
                    style = ' style="cursor: pointer; background-color: #f0f0f0; border-radius: 3px; padding: 5px; weight: 600; color:#ccc;"';
                    icon = '<i class="fa fa-caret-down pull-right m-r-15 m-t-5" style="color:#000;"></i>';
                }
            }

            $scope.activeElement =
                '<div' + click + style + '>' +
                    '<span class="m-l-5' + placeholderClass +'">{{::modelSelection.name}}</span>' +
                    icon +
                '</div>';
        }
        else {
            // Create dropdown HTML string
            $scope.activeElement =
                '<select kendo-drop-down-list="activeDropdown" ' +
                    'k-ng-model="modelSelection" ' +
                    'k-data-text-field="\'name\'" ' +
                    'k-data-value-field="\'id\'" ' +
                    'k-data-source="options.dropdownDataSource" ' +
                    'k-on-change="dropdownUpdate()" ' +
                    'style="width: 100%;"> ' +
                '</select>';
            $timeout(function () {
                $scope.activeDropdown.open();
                $scope.activeDropdown.bind('close', function () {
                    $timeout(function() {
                        $scope.setElement(false);
                    });
                });
            });
        }
    };

    // Save selection and remove dropdown
    $scope.dropdownUpdate = function () {
        $timeout(function() {
            $scope.options.selectedId = $scope.modelSelection.id;
            $scope.options.selectionUpdateCallback($scope.options.rowItem, $scope.modelSelection.id);
            $scope.setElement(false);
        });
    };

    // Sets the placeholder on load as active element
    $scope.setElement(false);
}
