'use strict';

const constructor = (httpService, envConfig) => {
    const saveSettings = (screenshotFeatureFlag) => {
        return new Promise((resolve, reject) => {
            httpService
                .post(envConfig.apiUrl() + '/api/realtime/settings', {
                    data: {
                        screenshotFeatureFlag: screenshotFeatureFlag
                    }
                })
                .then(() => {
                    resolve(screenshotFeatureFlag);
                })
                .catch((e) => {
                    console.error(
                        'ActivTrak Error: An error occurred while saving the Real-time screenshot setting.',
                        e
                    );
                    reject(!screenshotFeatureFlag);
                });
        });
    };

    return {
        saveSettings
    };
};

export { constructor };
