import { IAccountSettingsDto } from '../models';
import { loadPostLogin } from '../services/Application/AppLoad';
import { createCachedStore } from './createCachedStore';

/**
 * Cached store for post_login
 */
export const postLoginStore = createCachedStore<IAccountSettingsDto>(async () => {
  const postLoginDto = await loadPostLogin();
  return postLoginDto;
});
