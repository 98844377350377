import { Role, RoleAccessKeys } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appDashboard: IRoute = {
  name: 'app.dashboard',
  stateDefinition: {
    url: '/home?scrollTo&categorizationTab',
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      updated: false,
      errorMessages: null,
      fromNavigation: false
    },
    template: '<activity-dashboard></activity-dashboard>',
    data: {
      pageTitle: 'Home - Activity Dashboard'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.DashboardsActivity,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.User, Role.Viewer],
    excludedFeatureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appDashboard;
