import { useCallback, useState } from 'react';
import { ISubheaderHoursDto, ITeamTrendsState } from '../models';
import { ApiRoutes } from '../constants';
import { buildReportsApiRequest } from '../utils';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useTeamTrendsState = (): ITeamTrendsState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamTrendsHours, setTeamTrendsHours] = useState<ISubheaderHoursDto>();
  const [isTileError, setIsTileError] = useState<boolean>(false);

  const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
    setIsLoading(true);
    setIsTileError(false);

    try {
      const response = await buildReportsApiRequest<ISubheaderHoursDto>({
        path: ApiRoutes.reports.fetchOrganizationTrendsHours,
        params: { groupId: reportFilters.groupId[0] }
      });
      setTeamTrendsHours(response);
    } catch (error) {
      setIsTileError(true);

      console.error(
        'ActivTrak Error: Unable to load current team trends',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    isTileError,
    teamTrendsHours,
    init
  };
};
