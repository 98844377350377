import React, { MutableRefObject, useEffect, useState } from 'react';
import { IDigestSetting } from '../../common/models';
import { Checkbox, Typography } from '@mui/material';

type DigestColumnsProps = {
  handleDigestSettingToggle: (e, userId: number) => void;
  isDisabled: MutableRefObject<boolean>;
};

export const useDigestColumns = (props: DigestColumnsProps) => {
  const { handleDigestSettingToggle, isDisabled } = props;

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const cols = [
      {
        field: 'digest',
        fieldName: 'Receive Digest',
        headerName: 'Receive Digest',
        type: 'boolean',
        isSortable: true,
        align: 'center',
        width: '20%',
        template: (dSetting: IDigestSetting) => {
          return (
            <Checkbox
              checked={dSetting.digest}
              color="primary"
              disabled={isDisabled?.current}
              onChange={(e) => handleDigestSettingToggle(e, dSetting?.userId)}
            />
          );
        }
      },
      {
        field: 'username',
        fieldName: 'ActivTrak ID',
        headerName: 'ActivTrak ID',
        type: 'string',
        isSortable: true,
        width: '40%',
        template: (dSetting: IDigestSetting) => (
          <Typography noWrap>{dSetting.username}</Typography>
        )
      },
      {
        field: 'name',
        fieldName: 'Name',
        headerName: 'Name',
        type: 'string',
        isSortable: true,
        width: '40%',
        template: (dSetting: IDigestSetting) => (
          <Typography noWrap>{dSetting.name}</Typography>
        )
      }
    ];

    setColumns(cols);
  }, [handleDigestSettingToggle, isDisabled]);

  return columns;
};
