import {
  DialogActions,
  DialogContent,
  Box,
  Dialog,
  Typography,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { CommonPaddingBox, TotCss } from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';

type ComponentProps = {
  open: boolean;
  teamName: string;
  onClose: () => void;
  onConfigureAnother: () => void;
};

export const SuccessDialog = (props: ComponentProps) => {
  const { open, teamName, onClose, onConfigureAnother } = props;

  const close = () => {
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <CommonPaddingBox width={590} maxHeight={300}>
          <DialogTitle>Success!</DialogTitle>
          <DialogContent>
            <Box>
              <Typography sx={{ ...TotCss.instructions, margin: 0 }}>
                Your Time on Task configuration for "{teamName}" has been saved.
                You can now access the dashboards to receive guidance on
                improving team members' performance and role alignment.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={TotCss.success}>
            <DialogActionsBox
              abortText={'Exit'}
              close={close}
              onClick={onConfigureAnother}
              disabled={false}
              confirmText={'Configure another team'}
            />
          </DialogActions>
        </CommonPaddingBox>
      </Dialog>
    )
  );
};
