'use strict';

angular.module('app')
    .controller('integrateResourceModalController', integrateResourceModalController);

    integrateResourceModalController.$inject = ['$scope', 'modalData', '$window'];

function integrateResourceModalController($scope, modalData) {
    $scope.integration = modalData.integration;
    $scope.integrationsTags = modalData.integrationsTags;
}
