'use strict';

angular.module('app').controller('TeamPulsePromoCtrl', TeamPulsePromoCtrl);

TeamPulsePromoCtrl.$inject = [
    '$scope',
    '$state',
    '$window',
    'envService',
    'accountSettings',
    'notificationService',
    'authorizationService',
    'utmService'
];

function TeamPulsePromoCtrl(
    $scope,
    $state,
    $window,
    envService,
    accountSettings,
    notificationService,
    authorizationService,
    utmService
) {
    if (authorizationService.hasFeature('isTeamPulseEnabled')) {
        $state.go('app.teampulse');
    }

    var marketoConfig = envService.getMarketoConfig();
    $scope.requestQuote = function () {
        $window.MktoForms2.loadForm(
            marketoConfig.formUrl,
            marketoConfig.munchkinId,
            marketoConfig.teamPulse,
            function (form) {
                var vals = {
                    Phone: accountSettings.phone,
                    FirstName: accountSettings.firstName,
                    LastName: accountSettings.lastName,
                    Email: accountSettings.username,
                    Company: accountSettings.companyName
                };
                utmService.configureForm(form, vals, utmService.getHardcodedMktoHiddenFields(), function () {
                    notificationService.showNotification(
                        'Quote request received. A member of our sales team will reach out to you shortly.',
                        'success'
                    );

                    return false;
                });
                $window.MktoForms2.lightbox(form).show();
            }
        );
    };
}
