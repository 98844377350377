'use strict';

angular.module('app')
    .directive('eventFocus', eventFocus);

function eventFocus() {
    return function(scope, elem, attr) {
        elem.on(attr.eventFocus, function () {
            var element = angular.element(attr.focusElement);
            if (element) {
                element.focus();
            }
        });

        scope.$on('$destroy', function () {
            elem.off(attr.eventFocus);
        });
    };
}