import InfoPanelVariant from './InfoPanelVariant';

const defaultFontSize = '0.85em';

const getWrapperStyles = (type) => {
    let display = 'flex';
    let flexDirection = 'inherit';
    let textAlign = 'inherit';
    let padding = 'inherit';
    let color = 'inherit';

    if (type === InfoPanelVariant.Header) {
        flexDirection = 'column';
        textAlign = 'center';
        padding = '0.5em 0';
        color = '#505050';
    }

    return {
        display,
        flexDirection,
        textAlign,
        padding
    };
};

const getNameStyles = (type) => {
    let fontSize = defaultFontSize;
    let fontWeight = 'bold';
    let lineHeight = 'inherit';
    let color = 'inherit';

    if (type === InfoPanelVariant.Header) {
        fontSize = '2em';
        color = theme.palette.productive.main;
        lineHeight = '1em';
    }

    return {
        fontSize,
        fontWeight,
        color,
        lineHeight,
        width: '100%'
    };
};

const getDescriptionStyles = (type) => {
    let paddingLeft = '0.5em';
    let fontSize = defaultFontSize;

    switch (type) {
        case InfoPanelVariant.Header:
            fontSize = '1em';
            paddingLeft = 0;
            break;
        case InfoPanelVariant.CondensedDetail:
        case InfoPanelVariant.CondensedCustom:
            paddingLeft = 0;
            break;
        default:
            break;
    }

    return {
        paddingLeft,
        fontSize
    };
};

const getCol1Styles = (type) => {
    let width = '100%';
    let display = 'flex';

    if (type === InfoPanelVariant.Header) {
        width = '100%';
        display = 'flex';
        flexDirection = 'column';
        textAlign = 'center';
        color = '#505050';
    }

    return {
        width,
        display
    };
};

export const getClasses = (type) => ({
    wrapper: getWrapperStyles(type),
    name: getNameStyles(type),
    description: getDescriptionStyles(type),
    metric: {},
    col1: getCol1Styles(type),
    col2: {
        marginLeft: 'auto',
        fontSize: defaultFontSize
    }
});
