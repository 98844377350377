import { getTimeAgo } from '../../common/helpers';
import React from 'react';
import { IGridColumn } from '../../common/models';
import { TimeAgo } from '../../common/components/TimeAgo';
import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';

export const useUserSearchColumns = (dateTimeFormat: string): IGridColumn[] => {
  return [
    {
      field: 'Name',
      fieldName: 'name',
      headerName: 'Name',
      type: 'string',
      isSortable: true,
      width: '25%',
      template: (dataItem) => {
        return (
          <Tooltip
            title={
              dataItem.id
                ? dataItem.nameColumnDisplay ?? ''
                : 'This user is being processed. This may take up to 24 hours to complete.'
            }
            placement="bottom-start"
          >
            <TextOverflow>{dataItem.nameColumnDisplay}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'displayName',
      fieldName: 'displayName',
      headerName: 'Display Name',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return (
          dataItem.displayName?.value && (
            <Tooltip
              title={dataItem.displayName?.value ?? ''}
              placement="bottom-start"
            >
              <TextOverflow>{dataItem.displayName?.value}</TextOverflow>
            </Tooltip>
          )
        );
      }
    },
    {
      field: 'employeeId',
      headerName: 'Employee ID',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          dataItem.employeeId && (
            <Tooltip
              title={dataItem.employeeId?.value ?? ''}
              placement="bottom-start"
            >
              <TextOverflow>{dataItem.employeeId?.value}</TextOverflow>
            </Tooltip>
          )
        );
      }
    },
    {
      field: 'email',
      fieldName: 'email',
      headerName: 'Primary ID',
      type: 'string',
      isSortable: true,
      width: '23%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem.emailsDisplay ?? ''} placement="bottom">
            <TextOverflow>{dataItem.emailsDisplay}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'userCount',
      headerName: 'Agents',
      type: 'string',
      isSortable: true,
      width: '7%',
      align: 'right'
    },
    {
      field: 'lastLog',
      headerName: 'Last Activity',
      type: 'date',
      isSortable: true,
      width: '10%',
      template: (dataItem) => {
        const timeAgo = getTimeAgo(dataItem.maxLastLog);
        return (
          <TimeAgo
            lastLog={dataItem.maxLastLog}
            learnMoreLink="https://support.activtrak.com/"
            timeAgo={timeAgo}
            localDateTimeFormat={dateTimeFormat}
          />
        );
      }
    }
  ];
};
