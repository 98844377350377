import React from 'react';
import { notifError } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const TeamLevelIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 36;
  const width = props.width ?? 36;
  const title = props.title ?? 'Team Level';
  const className = props.className;
  const fillColor = props.fillColor ?? notifError;

  let classList = 'at-icon at-icon-teamlevel';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 36`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4289 14.8863C30.5311 14.8863 33.0466 17.4428 33.0466 20.5982C33.046 20.9808 33.0086 21.3628 32.9351 21.7385L27.3767 21.7386C26.3333 19.8229 24.7622 18.2605 22.8696 17.2659C23.8877 15.8249 25.5518 14.8863 27.4289 14.8863Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8266 7.99094C27.3632 7.1264 29.3269 7.64097 30.2132 9.13997C31.0992 10.639 30.5719 12.5546 29.0353 13.4189C27.4987 14.2833 25.5348 13.7689 24.6487 12.2701C23.7627 10.7711 24.2902 8.85528 25.8266 7.99094Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46046 15.1175C10.3082 15.1175 11.9479 16.0245 12.9718 17.4249C11.1028 18.4515 9.55981 20.0374 8.5481 21.9691L2.95632 21.9698C2.88176 21.594 2.84438 21.2122 2.84479 20.8294C2.84479 17.6741 5.36245 15.1175 8.46046 15.1175Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85809 8.22223C8.3947 7.35769 10.3584 7.87226 11.2447 9.37126C12.1307 10.8703 11.6034 12.7859 10.0668 13.6502C8.53019 14.5146 6.56626 14.0002 5.68022 12.5014C4.79419 11.0024 5.32168 9.08657 6.85809 8.22223Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.956 17.8106C13.0015 17.8106 8.97523 22.0018 8.97523 27.1745C8.97457 27.8021 9.03435 28.428 9.15359 29.044H26.7617C26.8793 28.428 26.9391 27.8018 26.9401 27.1745C26.9401 22.0018 22.9171 17.8106 17.956 17.8106Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4179 7.27716C17.8519 5.87335 20.9625 6.7089 22.3663 9.14291C23.7698 11.5769 22.9346 14.6875 20.5005 16.091C18.0665 17.4945 14.9556 16.6592 13.5522 14.2256C12.1487 11.7915 12.9842 8.68065 15.4179 7.27716Z"
        fill={fillColor}
      />
    </svg>
  );
};
