'use strict';
import ProductivityStatus from '../constants/productivityStatus';

const otherComparatorDecorator = function (a, b, descending, fieldName, comparator) {
    if (a && (a.title === 'Other' || a.url === 'Other')) {
        return descending ? -1 : 1;
    }
    if (b && (b.title === 'Other' || b.url === 'Other')) {
        return descending ? 1 : -1;
    }
    return comparator(a, b, descending, fieldName);
};

const numberComparator = (a, b, descending, fieldName) => {
    return a[fieldName] - b[fieldName];
};

const productivityComparator = (aItem, bItem, descending, fieldName = null, productivityOrder = null) => {
    const a = fieldName == null ? aItem : aItem[fieldName];
    const b = fieldName == null ? bItem : bItem[fieldName];

    // Return 0 if a and b are the same
    if (a === b) {
        return 0;
    }

    if (productivityOrder == null) {
        productivityOrder = [
            ProductivityStatus.OfflineMeetings,
            ProductivityStatus.UndefinedPassive,
            ProductivityStatus.Undefined,
            ProductivityStatus.UnproductivePassive,
            ProductivityStatus.Unproductive,
            ProductivityStatus.ProductivePassive,
            ProductivityStatus.Productive
        ];
    }

    let comparator = 0;
    // Loop through productivity statuses to determine sort order
    productivityOrder.forEach((p) => {
        if (a === p && b !== p) {
            comparator = -1;
        }

        if (a !== p && b === p) {
            comparator = 1;
        }

        // Break out of forEach loop if returned false
        return comparator === 0;
    });

    // Return comparator if found, if not compare based on values
    return comparator === 0 ? a - b : comparator;
};

const stringComparator = (aItem, bItem, descending, fieldName) => {
    let a = aItem[fieldName];
    let b = bItem[fieldName];

    if (descending) {
        [a, b] = [b, a];
    }

    return a < b ? -1 : a > b ? 1 : 0;
};

const usageComparator = function (aItem, bItem, descending, fieldName) {
    let a = aItem[fieldName || 'usage'];
    let b = bItem[fieldName || 'usage'];
    if ((a === null || a === '') && (b === null || b === '')) {
        return 0;
    }
    if (a === null || a === '') {
        return -1;
    }
    if (b === null || b === '') {
        return 1;
    }
    a = a.split(':');
    b = b.split(':');
    for (let i = 0; i < Math.max(a.length, b.length); ++i) {
        let aValue = parseInt(a[i]);
        let bValue = parseInt(b[i]);
        if (isNaN(aValue)) {
            return -1;
        }
        if (isNaN(bValue)) {
            return 1;
        }
        if (aValue < bValue) {
            return -1;
        }
        if (aValue > bValue) {
            return 1;
        }
    }
    return 0;
};

const urlComparator = function (aItem, bItem, descending, fieldName) {
    let a = aItem[fieldName];
    let b = bItem[fieldName];
    if ((a === null || a === '') && (b === null || b === '')) {
        return 0;
    }
    if (a === null || a === '') {
        return -1;
    }
    if (b === null || b === '') {
        return 1;
    }
    const prefix = /^(https?:\/\/)?(www\.)?/i;

    a = a.replace(prefix, '').toLocaleLowerCase();
    b = b.replace(prefix, '').toLocaleLowerCase();

    return a < b ? -1 : a > b ? 1 : 0;
};

export const atComparatorServiceFunctions = {
    otherComparatorDecorator,
    numberComparator,
    stringComparator,
    usageComparator,
    urlComparator,
    productivityComparator
};
