import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsBenchmarksAndGoals: IRoute = {
  name: 'app.reports.insightsBenchmarksAndGoals',
  stateDefinition: {
    url: '/insights/benchmarksandgoals?modelId&pageId&subPageId&tempFilters',
    params: { modelId: null, pageId: null, subPageId: null, tempFilters: null },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'Insights - Benchmarks & Goals' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.BenchmarkAndGoals,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.BenchmarksGoalsInsightsReport]
  }
};

export default appReportsInsightsBenchmarksAndGoals;
