import React from 'react';
import { SearchBar, ISearchBarProps } from '../../common/components/SearchBar';

export default function MemberSearchBar(props: ISearchBarProps) {
  return (
    <SearchBar
      {...props}
      placeholder={props.filter || 'Search by domain, name or alias'}
    />
  );
}
