//Legends are lists so need to use list accessibility features
import React from 'react';
import { ChartCheckboxLegendProps } from '../models/ChartCheckboxLegend.props';
import { checkboxIconSelected, checkboxIconUnselected } from '../../Checkbox';
import { ChartCheckboxSC } from '../styles/ChartCheckboxLegend.styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TextWithTooltip } from '../../Tooltip';
import { aqua1, gray0 } from '../../../constants';

export const ChartCheckboxLegend = (props: ChartCheckboxLegendProps) => {
  const {
    labels,
    colors,
    currentSelections,
    onClick,
    disabled,
    truncateNeeded
  } = props;

  return (
    <>
      {labels?.map((item, i) => {
        return (
          <ListItem
            key={`label-${item.value}`}
            id={item.id}
            onClick={() => onClick(item.value)}
            sx={{
              pointerEvents: disabled ? 'none' : 'auto'
            }}
          >
            <ListItemIcon sx={{ padding: '0 1px 0 3px' }}>
              <ChartCheckboxSC
                icon={checkboxIconUnselected}
                checkedIcon={checkboxIconSelected}
                edge="start"
                checked={currentSelections.includes(item.value)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': item.name
                }}
                checkboxcolor={colors[i].checkboxColor || aqua1}
                checkmarkcolor={colors[i].checkmarkColor || gray0}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <TextWithTooltip
                  text={item.name}
                  truncateNeeded={truncateNeeded}
                />
              }
            />
          </ListItem>
        );
      })}
    </>
  );
};
