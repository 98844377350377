import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IChromebookUsersColumnHeader } from '../models';
import { InviteSettingsButton } from '../components/InviteSettingsButton';

export const useChromebookUsersColumns = (
  props: IChromebookUsersColumnHeader
) => {
  const { handleResendInvite, handleCancelInvite, isDisabled } = props;

  const [columns, setColumns] = useState<any>();

  useEffect(() => {
    const cols = [
      {
        field: 'user',
        fieldName: 'Google ID',
        headerName: 'Google ID',
        type: 'string',
        isSortable: true,
        width: '40%',
        template: (cbUser) => {
          return <Box className="truncate">{cbUser.user}</Box>;
        }
      },
      {
        field: 'status',
        fieldName: 'Status',
        headerName: 'Status',
        type: 'string',
        isSortable: true,
        width: '40%',
        align: 'center',
        template: (cbUser) => cbUser.status
      },
      {
        type: 'any',
        isSortable: false,
        width: '20%',
        align: 'center',
        template: (cbUser) => {
          return cbUser?.status === 'Invited' ? (
            <Box onClick={(event) => event.preventDefault()}>
              <InviteSettingsButton
                user={cbUser}
                isDisabled={isDisabled}
                onResend={handleResendInvite}
                onCancel={handleCancelInvite}
              />
            </Box>
          ) : (
            <></>
          );
        }
      }
    ];
    setColumns(cols);
  }, [handleCancelInvite, handleResendInvite, isDisabled]);

  return columns;
};
