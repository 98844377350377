//TODO: remove this when areas that still use this can just use Roles.ts
export const applicationRoles = {
  user: { id: 2, key: 'user', name: 'Power User', is: 'a Power User' },
  admin: { id: 1, key: 'admin', name: 'Admin', is: 'an Administrator' },
  viewer: { id: 3, key: 'teamviewer', name: 'Viewer', is: 'a Viewer' },
  configurator: {
    id: 5,
    key: 'configurator',
    name: 'Configurator',
    is: 'a Configurator'
  },
  supportBasic: {
    id: 6,
    key: 'supportbasic',
    name: 'Support Basic',
    is: 'a Support User'
  },
  supportIntermediate: {
    id: 7,
    key: 'supportintermediate',
    name: 'Support Intermediate',
    is: 'a Support User'
  },
  supportAdvanced: {
    id: 8,
    key: 'supportadvanced',
    name: 'Support Advanced',
    is: 'a Support User'
  },
  superAdmin: {
    id: 4,
    key: 'superadmin',
    name: 'Super Admin',
    is: 'a Super Administrator'
  },
  multiAccount: {
    id: 9,
    key: 'multiaccount',
    name: 'Multi Account',
    is: 'a Multi Account User'
  },
  ccAccess: {
    id: 10,
    key: 'ccaccess',
    name: 'CC Access',
    is: 'a CC Access User'
  },
  supportPortal: {
    id: 11,
    key: 'supportportal',
    name: 'Support Portal Access',
    is: 'a Support Portal User'
  }
};
