'use strict';
import { apiService } from 'activtrak-ui-utilities';

function request(url, params = {}, method = '') {
    switch (method.toLowerCase()) {
        case 'get':
            return apiService.get(url, {
                headers: params.headers,
                params: params.data,
                authorizationRequired: true
            });
        case 'post':
            return apiService.post(url, {
                headers: params.headers,
                data: params.data,
                authorizationRequired: true
            });
        case 'put':
            return apiService.put(url, {
                headers: params.headers,
                data: params.data,
                authorizationRequired: true
            });
        case 'delete':
            return apiService.delete(url, {
                headers: params.headers,
                params: params.data,
                authorizationRequired: true
            });
        default:
            return new Promise((resolve, reject) =>
                reject(
                    `ActivTrak Error: Unknown method requested.\nMethod: ${method}\nURL: ${url}`
                )
            );
    }
}

export const http = {
    get: (url, params) => request(url, params, 'GET'),
    post: (url, params) => request(url, params, 'POST'),
    put: (url, params) => request(url, params, 'PUT'), // Untested
    delete: (url, params) => request(url, params, 'DELETE') // Untested
};
