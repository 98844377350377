import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { WidgetLinkProps } from '../../common/components/WidgetLink/WidgetLink.props';
import {
  WidgetLinkIcon,
  WidgetLinkStyled
} from '../../common/components/WidgetLink/WidgetLink.styles';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { blue2 } from '../../common/constants';

interface ConfigurationCardWrapperProps {
  children: React.ReactNode;
  title: React.ReactNode;
  actions?: React.ReactNode;
}

export const ConfigurationCardWrapper = ({
  children,
  title,
  actions
}: ConfigurationCardWrapperProps) => {
  return (
    <Paper elevation={3}>
      <CardHeader
        title={<Typography variant="h6">{title}</Typography>}
      ></CardHeader>
      <CardContent>{children}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Paper>
  );
};

export const ConfigurationCardWrapperWithLink: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
  widgetLinkProps: WidgetLinkProps;
}> = ({ children, title, widgetLinkProps }) => {
  const { redirectTo, id, text, infoIconText } = widgetLinkProps;

  return (
    <Paper elevation={3}>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <WidgetLinkStyled to={redirectTo} id={id} underline="none">
              {infoIconText && (
                <Tooltip title={infoIconText} enterTouchDelay={0}>
                  <IconButton
                    sx={{
                      padding: `0px 4px 0px 3px !important`
                    }}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {text && (
                <Typography sx={{ display: 'inline', color: blue2 }}>
                  {text}
                </Typography>
              )}
              <WidgetLinkIcon>
                <LaunchIcon />
              </WidgetLinkIcon>
            </WidgetLinkStyled>
          </Box>
        }
      />
      <CardContent>{children}</CardContent>
    </Paper>
  );
};
