import React from 'react';
import { PlaygroundHowToUseComponent } from '../views/PlaygroundHowToUse.view';
import ChartsDemo from '../../react-charts-demo/views/DemoPage.view';
import { ReportFiltersPlayground } from '../views/ReportFilterPlayground.view';
import { WorkingHoursReportPlaygroundView } from '../views/WorkingHoursReportPlayground.view';
import { IDevelopmentPages } from '../../common/models/IDevelopmentPages';

export const PLAYGROUNDS: IDevelopmentPages[] = [
  {
    id: 'example',
    label: 'How to use the Playground',
    jsx: <PlaygroundHowToUseComponent />
  },
  {
    id: 'reportfilters',
    label: 'Report Filter',
    jsx: <ReportFiltersPlayground />
    },
  {id: 'whr',
    label: 'Working Hours Report',
    jsx: <WorkingHoursReportPlaygroundView />
  },
  {
    id: 'charts',
    label: 'Chart Demos',
    jsx: <ChartsDemo />
  }
];
