'use strict';

angular.module('app').service('topApplicationsService', TopApplicationsService);

TopApplicationsService.$inject = ['$http', '$q', 'envConfig'];

function TopApplicationsService($http, $q, envConfig) {
    this.getApiUrl = function (subpage, id) {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/topapplications';

        if (subpage !== undefined) {
            if (id !== undefined) {
                url += '/' + id;
            }
            url += '/' + subpage;
        }

        return url + '?';
    };

    this.getApplications = function (parameters) {
        return $http.get(this.getApiUrl() + parameters);
    };

    this.getApplicationsAndTotals = function (parameters) {
        return $http.get(this.getApiUrl('appsandtotals') + parameters);
    };

    this.getTitles = function (id, parameters) {
        return $http.get(this.getApiUrl('titles', id) + parameters);
    };

    this.getTotals = function (parameters) {
        return $http.get(this.getApiUrl('totals') + parameters);
    };

    this.getUsage = function (id, parameters) {
        return $http.get(this.getApiUrl('usage', id) + parameters);
    };

    this.getActivityTimeline = function (id, parameters, data) {
        // This fixes a "bug" with a request when Id is undefined.
        // This should be corrected with the caller not requesting this
        if (id === undefined) {
            var q = $q.defer();
            q.resolve({ data: { data: [], total: 0 } });
            return q.promise;
        }

        return $http.get(this.getApiUrl('timeline', id) + parameters, data);
    };
}
