import React, { forwardRef, useState } from 'react';
import Box from '@mui/material/Box';
import loadWidget from '../../utils/loadWidgets';
import { WidgetLoaderProps } from '../../models';

export const WidgetLoader = forwardRef((props: WidgetLoaderProps, ref) => {
  const id = props?.id;

  //this memoizes the component to avoid re-rendering
  const [WidgetComponent] = useState<React.ElementType>(loadWidget(id));

  return (
    <Box ref={ref}>{WidgetComponent && <WidgetComponent {...props} />}</Box>
  );
});
export default WidgetLoader;
