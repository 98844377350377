import { react2angular } from 'react2angular';
import { ComputerAliasComponent } from '../../_reactivtrak/src/alias/views/Computers.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsComputerAliases', react2angular(ComputerAliasComponent, []));

}
