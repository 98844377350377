import React from 'react';
import { ErrorMessage } from './ErrorMessage';

type ModalErrorMsgProps = {
  msg: string;
  notWidget?: boolean;
};

export const ErrorMsgDisplay = (props: ModalErrorMsgProps) => {
  const { msg, notWidget } = props;

  return (
    <ErrorMessage
      msg={msg}
      sx={{
        display: 'block',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: notWidget ? '20px 16px' : '140px 16px'
      }}
    />
  );
};
