import React, { useEffect, useState } from 'react';
import { ClassificationsProps } from '../models';
import { ListFilter } from '../../common/components/Filter/ListFilter';
import { TotalCount } from '../../common/components/Totals/TotalCount';
import { BaseNoResults } from '../../common/components/NoResults/BaseNoResults';
import { Grid, Box } from '@mui/material';
import { useClassificationColumns, bulkClassificationFilters } from '../utils';
import { SortDirection } from '../../common/enums';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import ClassificationsMoreMenu from './ClassificationsMoreMenu';
import ClassificationBulkActivities from './ClassificationBulkActivities';
import { classificationClasses } from '../styles/Classifications.styles';
import { IGNORE_CATEGORY_ID } from '../../common/constants';
import { findIndex } from 'lodash';
import { useClassificationStores } from '../services';
import { useNotifications } from '../../common/services/Notifications';

export default function ClassifiedClassifications(props: ClassificationsProps) {
  const {
    getApiProps,
    defaultFilter,
    categories,
    handleClickRowGrid,
    activitiesState,
    selectedItems,
    onActivitiesFilter,
    onUpdateActivityChange,
    onBulkUpdateActivityChange,
    onSelectedItems,
    onSortOrder,
    isDisabled
  } = props;

  const [successMsg, setSuccessMsg] = useState<string>('');

  const columns = useClassificationColumns({
    categories,
    onChange: onUpdateActivityChange,
    isTruncated: true,
    isDisabled: isDisabled
  });
  const notificationService = useNotifications();

  //CLASSIFICATION STORE SETUP
  const { classificationStore } = useClassificationStores();
  const { getRecentActivities } = classificationStore;

  const { activities, totalActivities, isLoading, isSearching } =
    activitiesState;

  const hasNoResults = !isLoading && isSearching && !activities?.length;

  useEffect(() => {
    const get = async () => {
      await getRecentActivities(categories, getApiProps);

      if (successMsg) {
        notificationService.success(successMsg);
        setSuccessMsg(null);
      }
    };

    if (!categories || categories.length === 0) {
      return;
    }

    if (getApiProps) {
      get();
    }
    //keeping "successMsg" out of the dependencies because it causes an infinite loop when setting/nullifying inside this useEffect. this feature was a quick fix, as other systems would take longer to develop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecentActivities, getApiProps, categories, notificationService]);

  const bulkFilterMenuItems = [
    bulkClassificationFilters.allActivities,
    bulkClassificationFilters.autoClassification,
    bulkClassificationFilters.groupClassified,
    bulkClassificationFilters.ignored
  ];

  const isBulkActivitiesDisabled = !selectedItems || selectedItems.length <= 0;
  const isDisabledProductivity =
    selectedItems &&
    findIndex(selectedItems, (s) => s.categoryId === IGNORE_CATEGORY_ID) >= 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container sx={classificationClasses.header}>
          <Grid item xs={12} sm={2} md={4} lg={5}>
            <ClassificationBulkActivities
              isDisabled={isBulkActivitiesDisabled}
              isDisabledProductivity={isDisabledProductivity}
              onBulkUpdateActivityChange={onBulkUpdateActivityChange}
              categories={categories}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={7}
            sx={classificationClasses.activityColsHeaderRight}
          >
            <TotalCount
              label="total classified"
              count={totalActivities}
              testId="classified-count"
            />
            <ListFilter
              listItems={bulkFilterMenuItems}
              onClick={onActivitiesFilter}
              testId="classified-search-filters"
              defaultSelection={defaultFilter?.label}
            />
            <ClassificationsMoreMenu onChange={setSuccessMsg} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Box height="65vh">
            {/* TODO: what should show if there are no initial classified activities? */}
            {hasNoResults ? (
              <BaseNoResults tips="Have you seen this message more than once? Try searching in the &ldquo;Pending&rdquo; tab instead." />
            ) : (
              <CustomGrid
                height={'58vh'}
                isVirtualized={true}
                isLoading={isLoading}
                data={activities}
                columns={columns}
                uniqueRowProp="uniqueId"
                initialSortField="duration"
                initialSortDirection={SortDirection.Descending}
                hasBulkSelection
                bulkSelectFilterOptions={bulkFilterMenuItems}
                onSelectAllClick={onSelectedItems}
                onClickRow={handleClickRowGrid}
                onSortOrder={onSortOrder}
                featureDisabled={isDisabled}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
