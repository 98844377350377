/**
 * Set window variable
 */
 declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activTrak: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    heap: any;
  }
}

export const set = (key: string, value: unknown) => {
  window.activTrak[key] = value;
}

export const get = (key: string) => {
  return window.activTrak[key];
}

export const clear = (key: string) => {
  if (
      typeof window !== 'undefined' &&
      window.activTrak &&
      typeof window.activTrak === 'object' &&
      Reflect.has(window.activTrak, key)
  ) {
      Reflect.deleteProperty(window.activTrak, key);
  }
}


const init = () => {
  if (!window.activTrak) {
    window.activTrak = {
      utilities: {}
    };
  }
}

//TODO: relocate this:
init();

export const inMemoryStore = {
  get, set, clear, init
}
