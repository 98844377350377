import {
    getLocationExhaustive,
    getLocations,
    deleteLocation,
    upsertLocationExhaustive,
    upsertLocations
} from '_app/insights/insightsServiceFunctions';
import atInsightsLocationConfiguration from '../../../../app/views/reports/insights/atInsightsLocationConfiguration.html?raw';
import locationConfirmDeleteModal from '../../../../app/views/reports/insights/locationConfirmDeleteModal.html?raw';
angular.module('app').directive('atInsightsLocationConfiguration', () => {
    return {
        restrict: 'E',
        scope: {
            locations: '=',
            isExhaustive: '=',
            canEdit: '='
        },
        template: atInsightsLocationConfiguration,
        controller: atInsightsLocationConfigurationCtrl
    };
});

atInsightsLocationConfigurationCtrl.$inject = [
    '$scope',
    '$rootScope',
    'customUibModal',
    'envConfig',
    'notificationService',
    'atHelperFunctions'
];

function atInsightsLocationConfigurationCtrl(
    $scope,
    $rootScope,
    customUibModal,
    envConfig,
    notificationService,
    atHelperFunctions
) {
    let locationsToSubmit = [];
    $scope.disableAdd = false;
    $scope.disableSave = true;
    $scope.loading = true;

    const hasOfficeLocation = (locations) => {
        return (
            locations.filter((loc) => {
                return loc.location?.toLowerCase() === 'office';
            }).length > 0
        );
    };

    const getOptions = (disable) => {
        return {
            label: 'List of IP addresses for "Office" is full/exhaustive. If enabled, any unspecified IP addresses will be labeled as "Remote".',
            onChange: toggleExhaustive,
            disabled: disable || !hasOfficeLocation($scope.locationsCopy) || !$scope.canEdit
        };
    };

    const waitForlocations = () => {
        if ($scope.locations === undefined) {
            setTimeout(waitForlocations);
        } else {
            $scope.locationsCopy = angular.copy($scope.locations);
            if ($scope.locationsCopy.length === 0) {
                $scope.addLocation();
            }
            $scope.exhaustiveIpToggleOptions = getOptions();
            $scope.loading = false;
        }
    };
    waitForlocations(); //For initial load because we ng-repeat the copy

    const refreshIsExhaustiveStatus = () => {
        getLocationExhaustive(envConfig.apiUrl())
            .then((data) => {
                $scope.isExhaustive = data.isExhaustive;
            })
            .catch(() => {
                $scope.loading = false;
            });
    };

    const fetchLocations = () => {
        $scope.loading = true;
        getLocations(envConfig.apiUrl(), notificationService)
            .then((data) => {
                $scope.locations = data;
                $scope.locationsCopy = angular.copy($scope.locations);
                $scope.exhaustiveIpToggleOptions = getOptions();
                if (!hasOfficeLocation($scope.locationsCopy)) {
                    $scope.isExhaustive = false; //quick way w/o api call
                } else {
                    refreshIsExhaustiveStatus();
                }
                $scope.loading = false;
                $scope.disableAdd = false;
                $scope.disableSave = true;
                locationsToSubmit = [];
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(() => {
                $scope.loading = false;
                atHelperFunctions.safeApply($rootScope);
            });
    };

    $scope.showConfirmDeleteLocationModal = (location) => {
        if ($scope.loading) {
            return;
        }

        if (!location.rowId) {
            $scope.locationsCopy = $scope.locationsCopy.filter((loc) => {
                return loc.rowId !== undefined;
            });
            $scope.disableAdd = false;
            locationsToSubmit = removeLocationFromList(location, locationsToSubmit);
            if (locationsToSubmit.length === 0) {
                $scope.disableSave = true;
            }
            return;
        }

        customUibModal.open({
            animation: false,
            template: locationConfirmDeleteModal,
            controller: 'locationConfirmDeleteModal',
            windowClass: 'centered-modal location-modal',
            resolve: {
                modalData: {
                    location: location,
                    deleteLocation: doDeleteLocation
                }
            }
        });
    };

    const doDeleteLocation = (location) => {
        $scope.loading = true;
        $scope.exhaustiveIpToggleOptions = getOptions(true);
        deleteLocation(envConfig.apiUrl(), location, notificationService)
            .then(() => {
                fetchLocations();
                $scope.exhaustiveIpToggleOptions = getOptions();
            })
            .catch(() => {
                $scope.loading = false;
                $scope.exhaustiveIpToggleOptions = getOptions();
                atHelperFunctions.safeApply($rootScope);
            });
    };

    const locationHasChanged = (location, targetList) => {
        if (location.rowId) {
            var original = targetList.find((loc) => {
                return loc.rowId === location.rowId;
            });
            return !angular.equals(location, original);
        }
        return true;
    };

    const removeLocationFromList = (location, targetList) => {
        if (location.rowId) {
            return targetList.filter((loc) => {
                return loc.rowId !== location.rowId;
            });
        }
        return targetList.filter((loc) => {
            return loc.rowId !== undefined;
        });
    };

    const isValidIp = (ip) => {
        if (/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip)) {
            var octets = ip.split('.');
            if (parseInt(octets[0]) > 255 || parseInt(octets[1]) > 255 || parseInt(octets[2]) > 255) return false;

            if (octets[3].indexOf('/') > -1) {
                return true;
            } else {
                return parseInt(octets[3]) <= 255;
            }
        }
    };

    const isDuplicateIp = (location) => {
        return (
            $scope.locationsCopy.filter((loc) => {
                return loc.ip === location.ip;
            }).length > 1
        );
    };

    const locationIsValid = (location) => {
        return (
            isValidIp(location.ip) && location.location !== null && location.ipType !== null && !isDuplicateIp(location)
        );
    };

    const addToSubmissionList = (location) => {
        locationsToSubmit = removeLocationFromList(location, locationsToSubmit);
        locationsToSubmit.push(location);
    };

    $scope.validateLocation = (location) => {
        if (locationIsValid(location)) {
            if (locationHasChanged(location, $scope.locations)) {
                addToSubmissionList(location);
                $scope.disableSave = false;
            } else {
                locationsToSubmit = removeLocationFromList(location, locationsToSubmit);
            }
            if (locationsToSubmit.length === 0) {
                $scope.disableSave = true;
            }
        } else {
            $scope.disableSave = true;
        }
    };

    $scope.addLocation = () => {
        if ($scope.disableAdd || $scope.loading) {
            return;
        }
        $scope.locationsCopy.push({
            ip: null,
            location: null,
            ipType: null
        });
        $scope.disableAdd = true;

        if ($('#scrollable-location').height() === 280) {
            $('#scrollable-location').animate(
                {
                    scrollTop: $('#scrollable-location')[0].scrollHeight
                },
                500
            );
        }
    };

    $scope.saveLocations = () => {
        if ($scope.disableSave || $scope.loading || locationsToSubmit.length === 0) {
            return;
        }

        $scope.loading = true;
        $scope.exhaustiveIpToggleOptions = getOptions(true);
        upsertLocations(envConfig.apiUrl(), locationsToSubmit, notificationService)
            .then(() => {
                fetchLocations();
                $scope.exhaustiveIpToggleOptions = getOptions();
            })
            .catch(() => {
                $scope.loading = false;
                $scope.exhaustiveIpToggleOptions = getOptions();
                atHelperFunctions.safeApply($rootScope);
            });
    };

    const toggleExhaustive = () => {
        $scope.loading = true;
        $scope.isExhaustive = !$scope.isExhaustive;
        $scope.exhaustiveIpToggleOptions = getOptions(true);
        upsertLocationExhaustive(envConfig.apiUrl(), $scope.isExhaustive, notificationService)
            .then(() => {
                $scope.loading = false;
                $scope.exhaustiveIpToggleOptions = getOptions();
                atHelperFunctions.safeApply($rootScope);
            })
            .catch(() => {
                $scope.loading = false;
                $scope.exhaustiveIpToggleOptions = getOptions();
                atHelperFunctions.safeApply($rootScope);
            });
    };
}
