import { GroupMix, GroupType, GroupTypeBasic, GroupTypeDto } from '../enums';
import { IGroupDto, IGroup, INewGroupResponseDto } from '../models';

const mapGroupType = (obj: IGroupDto): GroupTypeBasic => {
  const { type } = obj;

  if (
    type === GroupTypeDto.ActivTrak ||
    type === GroupTypeDto.AllUsers ||
    type === GroupTypeDto.AllComputers
  ) {
    return GroupTypeBasic.ActivTrak;
  } else {
    return GroupTypeBasic[type];
  }
};

const mapGroupMix = (obj: IGroupDto): GroupMix => {
  const { mix, userCount, computerCount } = obj;

  if (userCount + computerCount === 0) {
    return GroupMix.None;
  } else if (mix === GroupMix.Both) {
    return GroupMix.Mixed;
  } else {
    return GroupMix[mix];
  }
};

const mapGroupFilter = (obj: IGroupDto): GroupType => {
  const { type, mix, userCount, computerCount } = obj;

  let currentMix = mix;
  if (userCount + computerCount === 0) {
    currentMix = GroupMix.None;
  } else if (mix === GroupMix.Both) {
    currentMix = GroupMix.Mixed;
  }

  return GroupType[`${type}${currentMix}Group`];
};

export const mapToGroup = (obj: IGroupDto): IGroup => {
  return {
    id: obj.groupid,
    name: obj.name,
    userCount: obj.userCount,
    computerCount: obj.computerCount,
    type: mapGroupType(obj),
    mix: mapGroupMix(obj),
    filter: mapGroupFilter(obj),
    userPreview: obj.userPreview,
    computerPreview: obj.computerPreview,
    selected: false
  };
};

export const mapNewGroupResponse = (obj: INewGroupResponseDto): IGroup => {
    return {
      id: obj.groupid,
      name: obj.groupname,
      userCount: 0,
      computerCount: 0,
      type: GroupTypeBasic.ActivTrak,
      mix: GroupMix.Empty,
      filter: GroupType.ActivTrakEmptyGroup,
      userPreview: '',
      computerPreview: '',
      selected: false
    };
  };
