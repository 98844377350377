'use strict';

angular.module('app').service('webhookLogSampleService', [function() {
    function buildPromise(data) {
        return {
            success: function (callback) {
                callback(data);
                return this;
            },
            error: function () {
            }
        };
    }

    function getWebhookLogEntry(alarmName, time, state, attempts, body, failureResponse, failureCode) {
        return {
            time: time,
            alarmName: alarmName,
            attempts: attempts,
            state: state,
            body: body,
            failureResponse: failureResponse,
            failureCode: failureCode
        };
    }

    function getData() {
        return [
            getWebhookLogEntry('UserLogin', '2019-01-01T10:39:55', 'Delivered', 1, '{"UserName":"lisa@example.com"}'),
            getWebhookLogEntry('CreateSchedule', '2019-01-01T10:53:49', 'Delivered', 1, '{"ScheduleName":"New Schedule 2"}'),
            getWebhookLogEntry('AddWebsite', '2019-01-01T10:56:57', 'Pending', 3, '{"WebsiteName":"https://example.net"}'),
            getWebhookLogEntry('AddWebsite', '2019-01-01T11:21:47', 'Failed', 5, '{"WebsiteName":"https://example.com"}', 'Not found', 404),
            getWebhookLogEntry('AddApplication', '2019-01-01T11:21:47', 'Delivered', 1, '{"ApplicationName":"MyApplication.exe"}'),
            getWebhookLogEntry('UserLogin', '2019-01-02T10:49:51', 'Failed', 5, '{"UserName":"john@example.com"}', 'Internal Server Error', 500),
            getWebhookLogEntry('UpdateSchedule', '2019-01-03T10:53:49', 'Delivered', 1, '{"ScheduleName":"New Schedule 2", "Updated": true}'),
            getWebhookLogEntry('AddWebsite', '2019-01-03T10:56:57', 'Delivered', 2, '{"WebsiteName":"https://example.co.uk"}'),
            getWebhookLogEntry('AddWebsite', '2019-01-03T11:23:41', 'Failed', 5, '{"WebsiteName":"https://example.org"}', 'Not found', 404),
            getWebhookLogEntry('AddApplication', '2019-01-03T11:25:45', 'Delivered', 1, '{"ApplicationName":"Dropbox.exe"}'),
        ];
    }

    this.getLog = function() {
        var data = getData();
        return buildPromise({
            data: data,
            total: data.length
        });
    };
}]);
