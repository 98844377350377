export const GoogleCalendarValidationMessages = {
  DomainRequired: 'The Google Calendar Integration Domain is required.',
  DomainInvalid: 'The Google Calendar Integration Domain is invalid.',
  AuthorizationRequired:
    'Authorization required. Click the "Authenticate with Google" button below to authorize the integration.'
};

export const AzureADValidationMessages = {
  AtLeastOneFieldRequired: 'Please fill out at least one input field.',
  AuthorizationRequired:
    'Authorization required. Click the "Authenticate with Microsoft" button below to authorize the integration.'
};

export const GenericValidationMessages = {
  InvalidGUID: 'Invalid GUID. Please enter a valid GUID format.'
};
