import { AlarmType, IAlarm, IAlarmDto } from './';

export function mapToAlarm(alarmDto: IAlarmDto): IAlarm {
  return {
    id: alarmDto.parentid,
    name: alarmDto.name,
    type: mapToAlarmType(alarmDto.type)
  };
}

export function mapToAlarmType(type: string): AlarmType {
  switch (type) {
    case 'Activity':
      return AlarmType.Activity;
    case 'Audit':
      return AlarmType.Audit;
    case 'Usb':
      return AlarmType.Usb;
    default:
      return AlarmType.Unknown;
  }
}
