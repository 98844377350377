'use strict';

angular.module('app').directive('collapsiblePanel', collapsiblePanel);

function collapsiblePanel() {
    return {
        restrict: 'E',
        scope: {
            panelTitle: '@',
            panelName: '@',
            filter: '<',
            fields: '<',
            clearFilter: '<',
            panelCollapsedCallback: '='
        },
        template: require('views/widgets/collapsiblePanel.html'),
        transclude: true,
        controller: [
            '$scope',
            '$timeout',
            'localStorageService',
            function ($scope, $timeout, localStorageService) {
                if ($scope.panelName) {
                    localStorageService.bind(
                        $scope,
                        'collapseOne',
                        false,
                        'collapsible-panel-state-' + $scope.panelName
                    );
                } else {
                    $scope.collapseOne = false;
                }

                function getField(name) {
                    return $scope.fields.find(function (f) {
                        return f.name === name;
                    });
                }

                $scope.getLabel = function (key) {
                    var field = getField(key);
                    return (field && field.label) || '';
                };

                $scope.getValue = function (key, value) {
                    var field = getField(key);

                    if (!field || (field && !field.filterOptions)) {
                        return value;
                    }

                    var option = field.filterOptions.find(function (o) {
                        return o.value === value;
                    });

                    return option &&
                        (value !== null || typeof value !== 'undefined')
                        ? option.buttonLabel
                        : value;
                };

                $scope.triggerResize = function () {
                    $timeout(function () {
                        angular.element(window).resize();
                    });
                };

                $scope.$watch('collapseOne', function (collapseOne) {
                    if (typeof $scope.panelCollapsedCallback === 'function') {
                        $scope.panelCollapsedCallback(!collapseOne);
                    }
                });
            }
        ]
    };
}
