import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../common/theme/theme';
import Layout from '../common/components/Layout/Layout';
import TeamPulse from './routes/TeamPulse/TeamPulse';
import { constructor as teamPulseDataHandlerConstructor } from './routes/TeamPulse/teamPulseDataHandler';
import { constructor as teamPulseDataPollerConstructor } from './routes/TeamPulse/teamPulseDataPoller';
import { inMemoryStore } from 'activtrak-ui-utilities';

const Root = () => {
    const teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');

    // Create instances
    const teamPulseDataHandler = new teamPulseDataHandlerConstructor();
    const teamPulseDataPoller = new teamPulseDataPollerConstructor(teamPulseDataControl);

    try {
        return (
            <ThemeProvider theme={theme}>
                <div id="teamPulseApp">
                    <Layout>
                        <TeamPulse
                            teamPulseDataControl={teamPulseDataControl}
                            teamPulseDataHandler={teamPulseDataHandler}
                            teamPulseDataPoller={teamPulseDataPoller}
                        />
                    </Layout>
                </div>
            </ThemeProvider>
        );
    } catch (e) {
        // Global Error Catch.
        console.error(e);
    }
};

export default Root;
