import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshState } from '../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { IGridColumn } from '../../common/models';
import { mergeArrayOfObjects } from '../../common/helpers';
import { WorkingHoursReportHeaderPlayground } from '../components/WorkingHoursReportHeaderPlayground';
import { useWorkingHoursReportState } from '../../reports/working-hours/hooks/useWorkingHoursReportState';
import { generateParameters } from '../../common/components/ReportFilters/utils/generateParameters';
import { getColumns } from '../../reports/working-hours/utils/workingHoursReport.utils';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import Box from '@mui/material/Box';

export const WorkingHoursReportPlaygroundView = () => {
  const [selectedColumnFields, setSelectedColumnFields] =
    useState<IGridColumn[]>();

  const { data, isLoading, init } = useWorkingHoursReportState();

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const { refreshTimestamp, handleRefresh } = useRefreshState();
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const reportFiltersStr = generateParameters(reportFilters, {});

  useEffect(() => {
    const columns = getColumns();
    //FUTURE: update 'hidden' statuses based on persisted values
    setSelectedColumnFields(columns);
  }, []);

  useEffect(() => {
    init(reportFiltersStr);
  }, [init, reportFiltersStr]);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  const handleColumnsSubmit = (columns: IGridColumn[]): void => {
    /**
     * Merge the currently selected columns with the current state
     */
    setSelectedColumnFields((prevState) =>
      mergeArrayOfObjects(prevState, columns, 'field')
    );
  };

  const handleRestoreColumns = () => {
    const columns = getColumns();
    setSelectedColumnFields(columns);
  };

  return (
    <>
      <WorkingHoursReportHeaderPlayground
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
        selectedColumns={selectedColumnFields}
        onSubmitColumns={handleColumnsSubmit}
        onRestoreDefaults={handleRestoreColumns}
      />
      <Box sx={{ mt: 2 }}>
        {selectedColumnFields && (
          <CustomGrid
            isVirtualized
            isLoading={isLoading}
            data={data}
            columns={selectedColumnFields}
          />
        )}
      </Box>
    </>
  );
};
