'use strict';

// This data source extends the existing Kendo DataSource in order to:
// 1. Display total items count in the slider mode.
// 2. Limit number of pages by max number of entries.
// 3. Reset page to 1 after sorting.
// 4. Add a public method to reset current page to first for next read.

var REQUESTEND = 'requestEnd';

kendo.data.CustomDataSource = kendo.data.DataSource.extend({
    init: function(options) {
        kendo.data.DataSource.fn.init.call(this, options);
        this._displayTotal = undefined;
        this.entryLimit = options.entryLimit;
    },
    sort: function(val) {
        var that = this;

        if (val !== undefined) {
            that._query({ sort: val, page: 1 });
            return;
        }
        return that._sort;
    },
    success: function (data, stopPending) {
        var that = this;
        var options = that.options;
        that.trigger(REQUESTEND, {
            response: data,
            type: 'read'
        });
        if (that.online()) {
            data = that.reader.parse(data);
            if (that._handleCustomErrors(data)) {
                that._dequeueRequest();
                return;
            }
            that._total = that.reader.total(data);
            if (that.reader.pagerTotal) {
                that._pagerTotal = that.reader.pagerTotal(data);
            }
            if (that._aggregate && options.serverAggregates) {
                that._aggregateResult = that._readAggregates(data);
            }
            data = that._readData(data);
            that._destroyed = [];
        } else {
            data = that._readData(data);
            var items = [];
            var itemIds = {};
            var model = that.reader.model;
            var idField = model ? model.idField : 'id';
            var idx;
            for (idx = 0; idx < this._destroyed.length; idx++) {
                var id = this._destroyed[idx][idField];
                itemIds[id] = id;
            }
            for (idx = 0; idx < data.length; idx++) {
                var item = data[idx];
                var state = item.__state__;
                if (state === 'destroy') {
                    if (!itemIds[item[idField]]) {
                        this._destroyed.push(this._createNewModel(item));
                    }
                } else {
                    items.push(item);
                }
            }
            data = items;
            that._total = data.length;
        }
        that._pristineTotal = that._total;
        that._pristineData = data.slice(0);
        that._detachObservableParents();
        that._data = that._observe(data);
        that._markOfflineUpdatesAsDirty();
        that._storeData();
        that._addRange(that._data);
        that._process(that._data);

        if (stopPending) {
            that._pending = false;
        }

        that._dequeueRequest();
    },
    pagerTotal: function() {
        return this._pagerTotal;
    },
    totalPages: function() {
        var that = this;
        var total = that.pagerTotal();
        if (total === undefined) {
            total = that.total() || 0;
        }
        if (that.entryLimit) {
            total = Math.min(total, that.entryLimit);
        }
        var pageSize = that.pageSize() || total;

        return Math.ceil((total || 0) / pageSize);
    },
    resetPageToFirst: function() {
        this._skip = 0;
    }
});
