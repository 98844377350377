import { apiService } from '../../common/helpers';
import { getDefaultConfig } from '../../common/config';
import { IAliasService } from '../models/IAliasService';
import { IUserAliasDto } from '../models/IUserAliasDto';
import {
  IComputerAliasDto,
  IComputerAliasDtoParent,
  IComputerAliasDtoSave
} from '../models/IComputerAliasDto';

export const AliasService = (): IAliasService => {
  return {
    getUserAliases: async (params?: any): Promise<IUserAliasDto[]> => {
      try {
        const response = await apiService.get<IUserAliasDto[]>(
          '/api/settings/aliases',
          {
            ...getDefaultConfig(),
            params: params
          }
        );
        return response?.data;
      } catch (error) {
        throw new Error(error);
      }
    },

    saveUserAliases: async (aliases: IUserAliasDto[]): Promise<void> => {
        await apiService.post<IUserAliasDto[]>('/api/settings/aliases', {
          ...getDefaultConfig(),
          ...{ data: aliases }
        });
        return;
    },
    getComputerAliases: async (params?: any): Promise<IComputerAliasDto[]> => {
      try {
        const response = await apiService.get<IComputerAliasDtoParent>(
          '/api/ComputerManagement/computers?reportType=All',
          {
            ...getDefaultConfig(),
            params: params
          }
        );
        return response?.data?.data;
      } catch (error) {
        throw new Error(error);
      }
    },

    saveComputerAliases: async (
      aliases: IComputerAliasDtoSave[]
    ): Promise<void> => {
        await apiService.post<IComputerAliasDtoSave[]>(
          '/api/ComputerManagement/computers',
          {
            ...getDefaultConfig(),
            ...{ data: aliases }
          }
        );
        return;
    }
  };
};
