import React from 'react';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import {
  formatDate,
  formatDuration,
  formatTimeStamp,
  htmlEscape,
  isTodayByTz
} from '../../../common/helpers';
import { getAccountSettings } from '../../../common/helpers/accountSettings';
import authorization from '../../../common/helpers/authorization';
import { IWorkingHoursReportUserDto } from '../models/WorkingHoursTypes';
import { IGridColumn } from '../../../common/models';
import {
  stopLightProductivityColors,
  secondaryFontSize
} from '../../../common/constants';
import { LocationHeader } from '../components/LocationHeader';
import { OnlineMeetingsHeader } from '../components/OfflineMeetingsHeader';
import Link from '../../../common/components/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { TextOverflow } from '../../../common/styles/app.component.styles';
import { WhiteBackgroundTooltip } from '../../../common/components/Tooltip/TextWithTooltip.styles';

const getAccessForFeature = (dataBundleFlag, promoBundleFlag, featureFlag) => {
  const hasDataBundleFlag = authorization.hasFeature(dataBundleFlag);
  const hasPromoBundleFlag = authorization.hasFeature(promoBundleFlag);
  const hasFeatureFlag = authorization.hasFeature(featureFlag);
  const canAccessUpgrade = authorization.hasRouteByName('app.account.upgrade');

  const hasColumnBundleFlag =
    hasDataBundleFlag || (hasPromoBundleFlag && canAccessUpgrade);

  return hasColumnBundleFlag && hasFeatureFlag;
};

export const getColumns = (): IGridColumn[] => {
  const accountSettings = getAccountSettings();
  const hasOfflineMeetings = authorization.hasFeature(
    BundleFlag.OfflineMeetingsData
  );
      const hasLocationData = authorization.hasFeature(BundleFlag.LocationData);

      const cols = [
        {
          field: 'date',
          fieldName: 'Date',
          headerName: 'Date',
          type: 'date',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatDate(
              dataItem.date,
              accountSettings.dateFormat.toUpperCase()
            );
          },
          align: 'center',
          width: '100px',
          hidden: false
        },
        {
          field: 'groups',
          fieldName: 'Groups',
          headerName: 'Groups',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return (
              <WhiteBackgroundTooltip
                title={generateListOfUsers(dataItem.groups)}
                placement="bottom"
              >
                <TextOverflow>
                  {generateListOfUsers(dataItem.groups)}
                </TextOverflow>
              </WhiteBackgroundTooltip>
            );
          },
          width: '180px',
          hidden: false
        },
        {
          field: 'computers',
          fieldName: 'Computer',
          headerName: 'Computer',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            const results = generateListOfUsersLinked(
              dataItem.computers,
              'computer',
              dataItem
            );

            return (
              <WhiteBackgroundTooltip
                title={results.usersStr}
                placement="bottom"
              >
                <TextOverflow>{results.usersLinkArray}</TextOverflow>
              </WhiteBackgroundTooltip>
            );
          },
          width: '180px',
          hidden: false
        },
        {
          field: 'users',
          fieldName: 'User',
          headerName: 'User',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            const results = generateListOfUsersLinked(
              dataItem.users,
              'user',
              dataItem
            );

            return (
              <WhiteBackgroundTooltip
                title={results.usersStr}
                placement="bottom"
              >
                <Box>
                  <TextOverflow>{results.usersLinkArray}</TextOverflow>
                </Box>
              </WhiteBackgroundTooltip>
            );
          },
          width: '180px',
          hidden: false
        },
        {
          field: 'location',
          fieldName: 'Location',
          headerName: <LocationHeader hasLocationData={hasLocationData} />,
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            const hasFeatureEnabled = authorization.hasFeature(
              BundleFlag.LocationData
            );
            const isTodayDate = isTodayByTz(
              dataItem.date,
              accountSettings.currentIanaTimeZone
            );
            if (!dataItem.location || !hasFeatureEnabled || isTodayDate)
              return '';

            return dataItem.location;
          },
          width: '120px',
          align: 'center',
          hidden: false,
          hasAccess: getAccessForFeature(
            BundleFlag.LocationData,
            BundleFlag.LocationPromo,
            FeatureFlag.ShowLocationData
          )
        },
        {
          field: 'firstActivity',
          fieldName: 'First Activity',
          headerName: (
            <Typography>
              First
              <br />
              Activity
            </Typography>
          ),
          type: 'date',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatTimeStamp(dataItem.firstActivity, 'hh:mm:ss A');
          },
          align: 'center',
          width: '100px',
          hidden: false
        },
        {
          field: 'lastActivity',
          fieldName: 'Last Activity',
          headerName: (
            <Typography>
              Last
              <br />
              Activity
            </Typography>
          ),
          type: 'date',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatTimeStamp(dataItem.lastActivity, 'hh:mm:ss A');
          },
          align: 'center',
          width: '100px',
          hidden: false
        },
        {
          field: 'lastActivityLog',
          fieldName: 'Last Activity Log',
          headerName: (
            <Typography>
              Last
              <br />
              Activity Log
            </Typography>
          ),
          type: 'date',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatTimeStamp(
              dataItem.lastActivityLog,
              `${accountSettings.dateFormat.toUpperCase()} HH:mm:ss A`
            );
          },
          align: 'center',
          width: '180px',
          hidden: false
        },
        {
          field: 'productiveTime',
          fieldName: 'Productive',
          headerName: 'Productive',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(dataItem.productiveTime, 1);
          },
          align: 'center',
          width: '120px',
          hidden: false
        },
        {
          field: 'productiveActiveTime',
          fieldName: 'Productive Active',
          headerName: (
            <Typography>
              Productive
              <br />
              Active
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(
              dataItem.productiveActiveTime,
              1
            );
          },
          align: 'center',
          width: '120px',
          hidden: true
        },
        {
          field: 'productivePassiveTime',
          fieldName: 'Productive Passive',
          headerName: (
            <Typography>
              Productive
              <br />
              Passive
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(
              dataItem.productivePassiveTime,
              2
            );
          },
          align: 'end',
          width: '120px',
          hidden: true
        },
        {
          field: 'unproductiveTime',
          fieldName: 'Unproductive',
          headerName: 'Unproductive',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(dataItem.unproductiveTime, -1);
          },
          align: 'end',
          width: '120px',
          hidden: false
        },
        {
          field: 'unproductiveActiveTime',
          fieldName: 'Unproductive Active',
          headerName: (
            <Typography>
              Unproductive
              <br />
              Active
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(
              dataItem.unproductiveActiveTime,
              -1
            );
          },
          align: 'end',
          width: '120px',
          hidden: true
        },
        {
          field: 'unproductivePassiveTime',
          fieldName: 'Unproductive Passive',
          headerName: (
            <Typography>
              Unproductive
              <br />
              Passive
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(
              dataItem.unproductivePassiveTime,
              -2
            );
          },
          align: 'end',
          width: '120px',
          hidden: true
        },
        {
          field: 'undefinedTime',
          fieldName: 'Undefined',
          headerName: 'Undefined',
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(dataItem.undefinedTime, 0);
          },
          align: 'end',
          width: '120px',
          hidden: false
        },
        {
          field: 'undefinedActiveTime',
          fieldName: 'Undefined Active',
          headerName: (
            <Typography>
              Undefined
              <br />
              Active
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(dataItem.undefinedActiveTime, 0);
          },
          align: 'end',
          width: '120px',
          hidden: true
        },
        {
          field: 'undefinedPassiveTime',
          fieldName: 'Undefined Passive',
          headerName: (
            <Typography>
              Undefined
              <br />
              Passive
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return getProductivityTimeTemplate(
              dataItem.undefinedPassiveTime,
              -3
            );
          },
          align: 'end',
          width: '120px',
          hidden: true
        },
        {
          field: 'totalTime',
          fieldName: 'Total Time',
          headerName: (
            <Typography>
              Total
              <br />
              Time
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatDuration(dataItem.totalTime);
          },
          align: 'end',
          width: '100px',
          hidden: false
        },
        {
          field: 'activeTime',
          fieldName: 'Active Time',
          headerName: (
            <Typography>
              Active
              <br />
              Time
            </Typography>
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            return formatDuration(dataItem.activeTime);
          },
          align: 'end',
          width: '100px',
          hidden: false
        },
        {
          field: 'totalTimeOffline',
          fieldName: 'Offline Meetings',
          headerName: (
            <OnlineMeetingsHeader hasOfflineMeetings={hasOfflineMeetings} />
          ),
          type: 'string',
          template: (dataItem: IWorkingHoursReportUserDto) => {
            const hasFeatureEnabled = authorization.hasFeature(
              BundleFlag.OfflineMeetingsData
            );
            const isTodayDate = isTodayByTz(
              dataItem.date,
              accountSettings.currentIanaTimeZone
            );
            if (!hasFeatureEnabled || isTodayDate) return '';

            return getProductivityTimeTemplate(
              dataItem.totalTimeOffline || 0,
              -4
            );
          },
          align: 'end',
          width: '120px',
          hidden: false,
          hasAccess: getAccessForFeature(
            BundleFlag.OfflineMeetingsData,
            BundleFlag.OfflineMeetingsPromo,
            FeatureFlag.ShowOfflineMeetingData
          )
        }
      ];

  return cols.filter((col) => col.hasAccess !== false);
};

export const generateListOfUsers = (array: string[]): string => {
  if (!array || typeof array.forEach !== 'function') {
    return '';
  }

  let string = '';
  array.forEach(function (item, index) {
    const separator = index === array.length - 1 ? '' : ', ';

    string += item + separator;
  });
  return string;
};

export const generateListOfUsersLinked = (
  array: string[],
  type: string,
  dataItem: any
): { usersStr: string; usersLinkArray: React.ReactNode } => {
  if (!array || typeof array.forEach !== 'function') {
    return { usersStr: '', usersLinkArray: <></> };
  }

  const userItem = { ...dataItem };
  userItem.escapedNames = userItem.escapedNames || {};
  userItem.escapedNames[type] = [];

  let usersStr = '';
  const usersLinkArray = [];
  array.forEach((item, index) => {
    const escapedName = htmlEscape(item);
    const params = {};
    params[type] = escapedName;

    const separator = index === array.length - 1 ? '' : ', ';

    usersStr += item + separator;
    usersLinkArray.push(
      <span>
        <Link to="app.reports.activitylog" params={params}>
          {item}
        </Link>
        {separator}
      </span>
    );
  });
  return { usersStr, usersLinkArray };
};

const getProductivityTimeTemplate = (duration = 0, productivity) => {
  let color: string;
  switch (productivity) {
    case 1:
      color = stopLightProductivityColors.productive;
      break;
    case -1:
      color = stopLightProductivityColors.unproductive;
      break;
    case -3:
      color = stopLightProductivityColors.undefinedPassive;
      break;
    case 2:
      color = stopLightProductivityColors.productivePassive;
      break;
    case -2:
      color = stopLightProductivityColors.unproductivePassive;
      break;
    case -4:
    case 9:
      color = stopLightProductivityColors.offlineMeetings;
      break;
    default:
      color = stopLightProductivityColors.undefined;
  }
  const productivityBubble = (
    <CircleIcon sx={{ color, fontSize: secondaryFontSize }} />
  );

  return (
    <Typography>
      {formatDuration(duration)} {productivityBubble}
    </Typography>
  );
};
