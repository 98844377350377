import React, { useCallback, useMemo, useState } from 'react';
import { useInjector } from '../../../third-party/angular2react/useInjector';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { ProductivityFilterSC } from '../styles';

export const ColumnFilter = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const $injector = useInjector();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rootScope: any = useMemo(
    () => $injector.get('$rootScope'),
    [$injector]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openSelectColumns = () => {
    rootScope.$broadcast('selectColumns');
  };

  const openRestoreColumns = () => {
    rootScope.$broadcast('restoreColumns');
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <ProductivityFilterSC
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={
            <>
              <MenuItem onClick={() => openSelectColumns()}>
                <ListItemText>Select Columns</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => openRestoreColumns()}>
                <ListItemText>Restore Columns</ListItemText>
              </MenuItem>
            </>
          }
        >
          <Button
            id="99b3b09c-fb5c-40ab-8ad3-92881578eac1"
            variant="outlined"
            onClick={() => setIsOpen(!isOpen)}
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            Columns
          </Button>
        </ProductivityFilterSC>
      </div>
    </ClickAwayListener>
  );
};
