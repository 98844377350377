'use strict';

angular.module('app')
    .directive('faqWidget', FaqWidget);

function FaqWidget() {
    return {
        restrict: 'E',
        scope: {
            question: '=atQuestion',
            answer: '=atAnswer',
            register: '=atRegister',
            show: '=atShow'
        },
        template: require('views/widgets/faqWidget.html'),
        controller: FaqWidgetCtrl
    };
}

FaqWidgetCtrl.$inject = ['$scope', 'atHelperFunctions'];

function FaqWidgetCtrl($scope, atHelperFunctions) {

    function hideAnswer() {
        $scope.isActive = false;
    }

    function showAnswer() {
        $scope.isActive = true;
        $scope.show(faqObject);
    }

    var faqObject = {
        id: atHelperFunctions.generateGuid(),
        hideAnswer: hideAnswer
    };

    $scope.register(faqObject);

    $scope.toggleActive = function () {
        if ($scope.isActive) {
            hideAnswer();
        } else {
            showAnswer();
        }
    };
}
