import { ICategory, IClassificationActivity } from '../../common/models';
import { useClassificationStores } from '../services';

export const useClassificationViewUtils = () => {
  const { classificationStore } = useClassificationStores();
  const { updateActivities, sortActivities, setDisabledActivities } =
    classificationStore;

  const handleUpdateActivityChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    dataItem: IClassificationActivity,
    categories: ICategory[]
  ) => {
    const { value, name } = event.target;
    const params = { [name]: value };
    if (name === 'localCategoryId') {
      params.categoryId = value;
    } else {
      params.productivity = value;
    }

    const categoryId = params.categoryId ?? dataItem.categoryId;
    params.categoryName =
      categories?.find((c) => c.id == categoryId)?.name ?? '';

    setDisabledActivities([dataItem], true);
    if (Object.keys(params).length) {
      const updatedActivity = [{ ...dataItem, ...params }];
      updateActivities(updatedActivity);
    }
    event.stopPropagation();
  };

  const handleSortOrder = (newOrderDirection, orderBy) => {
    sortActivities(newOrderDirection, orderBy);
  };

  return { handleUpdateActivityChange, handleSortOrder };
};
