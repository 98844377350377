import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { IRoleAccessViewModel } from '../../../common/models';
import { RolesTableRows } from './RolesTableRows';
import { roleTableClasses } from '../../styles/RolesTable.styles';

type rowsProps = {
  busy: boolean;
  rows: IRoleAccessViewModel[];
  showLimitedDesc: boolean;
  handleChange: any;
};

export const RolesTable = (props: rowsProps) => {
  const { busy, showLimitedDesc } = props;

  const powerUserHeader = showLimitedDesc ? (
    <>
      Power User
      <br />
      (Limited Viewable Groups)
    </>
  ) : (
    'Power User'
  );
  const viewerHeader = showLimitedDesc ? (
    <>
      Viewer
      <br />
      (Limited Viewable Groups)
    </>
  ) : (
    'Viewer'
  );

  const visibility = busy
    ? roleTableClasses.visible
    : roleTableClasses.invisible;

  return (
    <TableContainer sx={roleTableClasses.table}>
      <CircularProgress
        color="primary"
        sx={{ ...roleTableClasses.loader, ...visibility }}
      />
      <Table
        size="medium"
        sx={busy ? roleTableClasses.disabled : roleTableClasses.enabled}
      >
        <TableHead sx={roleTableClasses.tableHead}>
          <TableRow sx={roleTableClasses.tableRow}>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="center" sx={roleTableClasses.header} colSpan={1}>
              Admin
            </TableCell>
            <TableCell align="center" sx={roleTableClasses.header} colSpan={1}>
              Configurator
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Box sx={roleTableClasses.header}>{powerUserHeader}</Box>
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Box sx={roleTableClasses.header}>{viewerHeader}</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RolesTableRows {...props} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
