import { BehaviorSubject } from 'rxjs';
import { DashboardFilter } from '_app/insights/models';

class DashboardFilterList {
    constructor() {
        this.filters = new BehaviorSubject({});
    }

    getEventFilter() {
        let eventFilter = {};

        _.forEach(this.filters.value, (filter) => {
            Object.assign(eventFilter, filter.getEventFilter());
        });

        return eventFilter;
    }

    getDisplayFilter() {
        let displayFilter = {};

        _.forEach(this.filters.value, (filter) => {
            Object.assign(displayFilter, filter.getDisplayFilter());
        });

        return displayFilter;
    }

    getApiFilter() {
        let apiFilter = {};

        _.forEach(this.filters.value, (filter) => {
            Object.assign(apiFilter, filter.getApiFilter());
        });

        return apiFilter;
    }

    updateFilters(updatedFilter) {
        const filters = this.filters.value;
        filters[updatedFilter.label] = new DashboardFilter(updatedFilter);
        this.filters.next(filters);
    }

    getDefaultValue() {
        const metricFilter = this.filters.value['Metric'];
        return metricFilter && metricFilter.defaultValue;
    }
}

export default DashboardFilterList;
