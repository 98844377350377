class dashboardEvent {
    constructor(event) {
        const data = event.data ? JSON.parse(event.data) : {};
        const types = data.type.split(':');
        this.rawType = data.type;
        this.type = types[0];
        this.subtype = types[1];
        this.status = data.status || types[2];
        this.dashboard = data.dashboard;
        this.title = data.dashboard && data.dashboard.title;
        this.filters = this.getUrlFilters(data);
        this.tile = data.tile;
        this.page = data.page;
        this.url = data.url;
    }

    getUrlFilters(event) {
        //sometimes filters come from dashboard_filters,
        //and sometimes they come from page.url
        if (event && event.dashboard && event.dashboard.dashboard_filters) {
            return JSON.stringify(event.dashboard.dashboard_filters);
        }

        if (event && event.page && event.page.url) {
            let idx = event.page.url.indexOf('&') + 1;
            return event.page.url.substring(idx);
        }

        return '';
    }

    getFilters() {
        if (!this.dashboard) {
            return;
        }

        return this.dashboard.dashboard_filters;
    }

    hasSubtypes(subtypes) {
        return _.includes(subtypes, this.subtype);
    }

    hasStatus(statuses) {
        return _.includes(statuses, this.status);
    }

    isPageEvent(subtypes) {
        return (
            this.type === 'page' && (!subtypes || this.hasSubtypes(subtypes))
        );
    }

    isDashboardEvent(subtypes) {
        return (
            this.type === 'dashboard' &&
            (!subtypes || this.hasSubtypes(subtypes))
        );
    }

    isDrillMenuEvent(subtypes) {
        return (
            this.type === 'drillmenu' &&
            (!subtypes || this.hasSubtypes(subtypes))
        );
    }
}

export default dashboardEvent;
