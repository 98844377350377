import { templateServiceFunctions } from '../serviceFunctions/templateServiceFunctions';
import { atComparatorServiceFunctions } from '../serviceFunctions/atComparatorServiceFunctions';

const _compareProd = (a, b, descending) => {
        return atComparatorServiceFunctions.otherComparatorDecorator(
            a,
            b,
            descending,
            'productivity',
            atComparatorServiceFunctions.productivityComparator
        );
    }

const _compareTitles = (a, b, descending) => {
        return atComparatorServiceFunctions.otherComparatorDecorator(
            a,
            b,
            descending,
            'title',
            atComparatorServiceFunctions.urlComparator
        );
    }

const _compareDurations = (a, b, descending) => {
        return atComparatorServiceFunctions.otherComparatorDecorator(
            a,
            b,
            descending,
            'duration',
            atComparatorServiceFunctions.usageComparator
        );
    }

const _createFilter = (field, dataSource) => {
    return templateServiceFunctions.createFilter(field, dataSource);
};

const _createSort = (sortable) => {
    switch (sortable) {
        case 'productivity':
            return (a, b, descending) =>
                _compareProd(a, b, descending) ||
                _compareTitles(a, b, descending) ||
                _compareDurations(a, b, descending);
        case 'duration':
            return (a, b, descending) =>
                _compareDurations(
                    a,
                    b,
                    descending
                ) ||
                _compareTitles(a, b, descending);
        case 'string':
            return (a, b, descending) =>
                _compareTitles(a, b, descending) ||
                _compareDurations(a, b, descending);
        default:
            return sortable;
    }
};

class GridColumn {
    constructor(
        {
            title,
            field,
            width,
            attributes,
            template,
            filterable,
            sortable,
            isHidden
        } = {},
        dataSource
    ) {
        this.title = title || field;
        this.field = field;
        this.width = width;
        this.attributes = attributes;
        this.template = template;
        this.filterable =
            filterable === true
                ? _createFilter(this.field, dataSource)
                : filterable;
        this.sortable = { compare: _createSort(sortable) };
        this.isHidden = isHidden;
    }

    getColumn() {
        return {
            title: this.title,
            field: this.field,
            width: this.width,
            attributes: this.attributes,
            template: this.template,
            filterable: this.filterable,
            sortable: this.sortable,
            hidden: typeof this.isHidden === 'function' && this.isHidden()
        };
    }
}

export default GridColumn;
