import { useState, useCallback } from 'react';
import { IBaseFormState } from '../models/IBaseFormState';

export const useBaseFormState = (): IBaseFormState => {
  const [tenantId, setTenantId] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const handleTenantIdChange = useCallback((event) => {
    setTenantId(event.target?.value);
  }, []);

  const handleClientIdChange = useCallback((event) => {
    setClientId(event.target?.value);
  }, []);

  const handleClientSecretChange = useCallback((event) => {
    setClientSecret(event.target?.value);
  }, []);

  const clearBaseFormFields = useCallback(() => {
    setTenantId('');
    setClientId('');
    setClientSecret('');
  }, []);

  return {
    tenantId,
    setTenantId,
    clientId,
    setClientId,
    clientSecret,
    setClientSecret,
    handleTenantIdChange,
    handleClientIdChange,
    handleClientSecretChange,
    clearBaseFormFields
  };
};
