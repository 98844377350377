import theme from '../../theme/theme';

export const listViewClasses = {
  table: {
    minWidth: 650
  },
  tableBody: {
    backgroundColor: theme.palette.grey['100']
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  },
  tableCell: {
    border: 'none',
    fontWeight: 'normal',
    paddingRight: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:first-of-type': {
      paddingLeft: theme.spacing(2)
    }
  },
  columnSmall: {
    width: '15%',
    paddingRight: theme.spacing(7)
  },
  columnMedium: {
    width: '20%',
    paddingRight: theme.spacing(7)
  },
  columnLarge: {
    paddingRight: theme.spacing(19)
  },
  companyName: {
    color: theme.palette.common.black,
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&.empty': {
      color: theme.palette.text.disabled
    }
  },
  tableCellCounter: {
    textTransform: 'uppercase'
  },
  linkCell: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    textAlign: 'center',
    width: '30px'
  },
  notificationCell: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    padding: `${theme.spacing(2)} 0`,
    marginLeft: -theme.spacing(1)
  }
};
