import React, { Dispatch, SetStateAction } from 'react';
import { fileValidator } from '../utils';
import { FileUpload } from '../../common/components/FileUpload/FileUpload';

type UploadAttachmentsProps = {
  onAttach: Dispatch<SetStateAction<File[]>>;
  additionalInstructions?: React.ReactNode;
};

export const UploadAttachments = (props: UploadAttachmentsProps) => {
  const { onAttach, additionalInstructions } = props;

  return (
    <FileUpload
      onSetFiles={onAttach}
      customOptions={{ validator: fileValidator, maxFiles: 1 }}
      additionalInstructions={additionalInstructions}
    />
  );
};
