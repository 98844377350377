'use strict';

angular.module('app')
    .directive('condensedFilters', [function () {
        return {
            restrict: 'E',
            template: require('views/condensedFilters.html'),
            scope: {
                filterData: '=',
                isFirefoxOrMsBrowser: '='
            }
        };
    }]);
