import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import {
  primaryOrange,
  secondaryOrange,
  primaryColor,
  statusColors,
  gray12,
  smallFontSize,
  mediumFontWeight,
  yellow2,
  yellow3,
  gray6,
  heavyFontWeight,
  buttonTextColor
} from '../../../../constants';
import { Logout, Star, StarBorder } from '@mui/icons-material';
import {
  LegacyHeader,
  TextOverflow
} from '../../../../styles/app.component.styles';

export const HeaderButtonSC = styled(Button)`
  &.MuiButtonBase-root {
    color: ${buttonTextColor};
    margin-right: 24px;
    &:hover {
      color: ${buttonTextColor};
    }
  }
`;

export const HeaderButtonSecondarySC = styled(HeaderButtonSC)`
  &.MuiButtonBase-root {
    background-color: ${primaryOrange};
    &:hover {
      background-color: ${secondaryOrange};
    }
  }
`;

export const ProfileInfoSC = styled(Box)`
  padding: 20px 16px 0px 16px;
  max-width: 252px;
  height: 76px;
`;

export const TypographyNamesSC = styled(Typography)`
  color: ${primaryColor};
  line-height: 22px !important;
`;

export const TextOverflowSC = styled(TextOverflow)`
  color: ${primaryColor};
  font-size: ${smallFontSize};
`;

export const HeaderSC = styled(Container)`
  height: 72px;
  padding-right: 0px !important;
`;

export const ToolbarSC = styled(Toolbar)`
  padding: 16px 24px 16px 24px;
`;

export const TypographyPlanNameSC = styled(Typography)`
  padding: 0px 24px 0px 16px;
  color: ${primaryColor};
`;

export const TypographyTitleSC = styled(LegacyHeader)`
  color: ${primaryColor};
  letter-spacing: 0.025em;
`;

export const TypographySubHeaderSC = styled(Typography)`
  padding: 16px 16px 8px 16px;
  height: 42px;
  color: ${primaryColor};
  opacity: 0.8;
  text-transform: uppercase;
`;

export const FilledStarSC = styled(Star)`
  color: ${yellow2};
  margin-left: 8px;
  &:hover {
    color: ${yellow3};
  }
`;

export const TypographySC = styled(Typography)`
  padding: 0px 16px 0px 0px;
  cursor: pointer;
  color: ${primaryColor};
  &:hover {
    color: ${gray6};
  }
  &:hover .MuiSvgIcon-root {
    color: ${yellow3};
  }
`;

export const HallowStarSC = styled(StarBorder)`
  margin-left: 8px;
  &:hover {
    color: ${gray6};
  }
`;

export const IconStyles = styled(Icon)`
  margin-top: -4px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 16px;
`;

export const StatusIconStylesSC = styled(Icon)<{ status: string }>`
  margin-top: -4px;
  color: ${(props) => statusColors[props?.status]} !important;
  margin-right: 16px !important;
`;

export const StatusAvatarSC = styled(Avatar)<{ status: string }>`
  border: ${(props) => statusColors[props?.status]} solid 3px;
  background-color: ${gray12} !important;
  font-weight: ${heavyFontWeight};
  font-size: 24px !important;
`;

export const LinkSecondarySC = styled(Link)`
  &.MuiTypography-root.MuiLink-root {
    color: ${primaryColor};
    opacity: 0.8;
  }
`;

export const ListItemSC = styled(ListItem)<{
  component: string;
  target: string;
  href: string;
  rel: string;
}>`
  &.MuiListItem-root {
    color: ${primaryColor};
    height: 36px;
    display: inline-flex;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const LogoutBoxSC = styled(Box)`
  cursor: pointer;
  height: 46px;
  padding: 12px 16px 12px 16px;
  text-transform: uppercase;
  font-weight: ${mediumFontWeight};
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const LogoutSC = styled(Logout)`
  color: rgba(0, 0, 0, 0.54);
  float: right;
`;

export const LinkUserLicensesSC = styled(Link)`
  padding: 0px 16px;
  &.MuiTypography-root.MuiLink-root {
    color: ${primaryColor};
    &:hover {
      color: ${gray6};
    }
  }
`;

export const MenuWrapper = styled('div')`
  @media (max-width: 766px) {
    display: inline-flex;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;
