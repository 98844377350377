import { UsersViewTabs } from '../enums';
import { IUsersViewState } from '../models';

export const USERS_INITIAL_VIEW_STATE: IUsersViewState = {
  reportingUsers: [],
  notReportingUsers: [],
  dropDownSettingsButtonOptions: [{ label: 'Passive Time Settings' }],
  searchTerm: '',
  searchBarFilterParams: ['logonDomain', 'user', 'alias'],
  viewIsLoading: false,
  userViewTab: UsersViewTabs.Reporting,
  usersViewTabOptions: [
    { label: 'REPORTING', type: UsersViewTabs.Reporting },
    { label: 'NOT REPORTING', type: UsersViewTabs.NotReporting }
  ]
};
