import theme from '../../../common/theme/theme';

export const tpTooltipClasses = {
    tooltip: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    },
    tooltipArrow: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    }
};
