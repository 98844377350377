import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormHelperText } from '@mui/material';
import { PassiveTimeInputProps } from '../models/PassiveTimeInputProps';
import { InlineTextFieldSC } from '../styles/accountConfiguration.styles';

export const PassiveTimeInput = ({
  name,
  max,
  min,
  initialValue,
  isSupport = false,
  onChange,
  setErrorStatus,
  endingText,
  isDisabled
}: PassiveTimeInputProps) => {
  const [newValue, setNewValue] = useState(3);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setNewValue(initialValue);
    setErrorStatus(false);
  }, [initialValue, setErrorStatus]);

  const reset = () => {
    setError(undefined);
    setErrorStatus(false);
    setNewValue(initialValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const val = Number(value);
    setError(undefined);
    setErrorStatus(true);

    switch (true) {
      case val < min:
        setError(`Must be ${min} minute${min !== 1 ? 's' : ''} or greater`);
        setNewValue(val);
        break;
      case val > max:
        setError(`Cannot exceed ${max} minutes`);
        setNewValue(max);
        setErrorStatus(false);
        break;
      default:
        onChange(name, val);
        setNewValue(val);
        reset();
    }
  };

  return (
    <>
      <FormControl variant="filled">
        <InlineTextFieldSC
          variant="standard"
          name={name}
          type="number"
          error={error !== undefined}
          value={newValue?.toString()}
          onChange={handleChange}
          disabled={isSupport || isDisabled}
          inputProps={{
            min: 0,
            style: { textAlign: 'center', fontSize: '16px' }
          }}
        />
      </FormControl>
      <span> minutes{endingText ? ` ${endingText}.` : '.'}</span>
      {initialValue !== newValue ? (
        <Button color="primary" onClick={reset} disabled={isSupport}>
          Reset
        </Button>
      ) : null}
      <FormHelperText
        error={error !== undefined}
        sx={{ marginLeft: '0', position: 'relative', top: '-10px' }}
      >
        {error}
      </FormHelperText>
    </>
  );
};
