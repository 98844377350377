import { apiService } from '../../common/helpers';
import { IAutoUpdateSettingsService } from '../models/IAutoUpdateSettingsService';
import {
  IAgentDto,
  IAgentSelectionPayload,
  IAutoUpdateSettingDto
} from '../models';
import { getDefaultConfig } from '../../common/config';
import { OperationalSystemsTypeShort } from '../../common/enums';

export const autoUpdateSettingsService = (): IAutoUpdateSettingsService => {
  return {
    fetchAgentTargets: async (days: string): Promise<IAutoUpdateSettingDto> => {
      const response = await apiService.get<IAutoUpdateSettingDto>(
        '/api/agent/targets/account/status',
        {
          ...getDefaultConfig(),
          params: { computerLastLogDays: days }
        }
      );
      return response?.data;
    },
    fetchAllTargets: async (): Promise<IAgentDto[]> => {
      const response = await apiService.get<IAgentDto[]>('/api/agent/targets', {
        ...getDefaultConfig()
      });
      return response?.data;
    },
    setAgentVersion: async (
      os: OperationalSystemsTypeShort,
      payload: IAgentSelectionPayload
    ) => {
      return apiService.post('/api/agent/targets/account/' + os, {
        ...getDefaultConfig(),
        data: payload
      });
    }
  };
};
