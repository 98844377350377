import { getAccountSettings } from '../../../_reactivtrak/src/common/helpers/accountSettings';
import DashboardType from '../../../_reactivtrak/src/common/enums/DashboardType';
import DashboardUtils from '../../../_reactivtrak/src/common/helpers/dashboardSettings';
import { constructor as authorizationServiceFunctionsConstructor } from '../../../_app/serviceFunctions/authorizationServiceFunctions';

angular.module('app').service('authorizationService', AuthorizationService);

AuthorizationService.$inject = ['$rootScope', '$state'];

function AuthorizationService($rootScope, $state) {
    const service = this;

    function broadcastService(event) {
        $rootScope.$broadcast(event);
    }

    const authorizationServiceFunctions = authorizationServiceFunctionsConstructor(broadcastService);

    angular.extend(service, authorizationServiceFunctions);

    //////
    // Event Listeners
    //////

    // On initial state change, resolve is still finishing.  Need to wait until success.
    // All other state changes should redirect on start.
    $rootScope.$on('$stateChangeStart', function (e, toState, toParams) {
        const getDashboardObj = function (stateName) {
            let dashboardState;
            const redirectFromNavigation = toParams && toParams.fromNavigation;

            if (stateName && DashboardType.DashboardListNg.includes(stateName)) {
                const dashboardNameSplit = toState.name.split('.');
                let dashboardSelection =
                    (dashboardNameSplit && dashboardNameSplit[2]) || DashboardType.DashboardType.default;

                if (!redirectFromNavigation || !dashboardSelection) {
                    dashboardSelection = DashboardUtils.getDashboardRedirect();
                }

                if (dashboardSelection === DashboardType.DashboardType.default) {
                    //use legacy dashboard state obj
                    dashboardState = {
                        name: 'app.dashboard',
                        url: '/home?scrollTo&categorizationTab'
                    };
                } else if (DashboardType.DashboardList.includes(dashboardSelection)) {
                    //use current dashboard state
                    dashboardState = {
                        name: `app.dashboardCurrent.${dashboardSelection}`,
                        url: `/homepage/${dashboardSelection}`
                    };
                }
                //make sure localstorage has the latest value
                DashboardUtils.setDashboardSettings({ dashboardSelection });
            }

            return dashboardState;
        };
        if (getAccountSettings().account && service.getAuthorizations().roles) {
            const stateName = toState && toState.name;
            //update the state obj if it's a dashboard link to check route roles
            const dashboardStateObj = getDashboardObj(stateName);

            const redirect = dashboardStateObj || toState;
            //bypass the route flow for dashboards and go straight to current state url
            if (service.hasRouteByName(redirect.name) && dashboardStateObj && dashboardStateObj.name !== toState.name) {
                e.preventDefault();
                $state.go(dashboardStateObj.name, toParams);
            }
        } else {
            //  Add state change success listener to evaluate after app loads
            // service.setStateChangeSuccessListener(
            $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams) {
                const stateName = toState && toState.name;
                //update the state obj if it's a dashboard link to check route roles
                const dashboardStateObj = getDashboardObj(stateName);

                if (stateName && stateName !== 'supportportal') {
                    const redirect = dashboardStateObj || toState;
                    //bypass the route flow for dashboards and go straight to current state url
                    if (
                        service.hasRouteByName(redirect.name) &&
                        dashboardStateObj &&
                        dashboardStateObj.name !== toState.name
                    ) {
                        e.preventDefault();
                        $state.go(dashboardStateObj.name, toParams);
                    }
                }
            });
            // );
        }
    });
}
