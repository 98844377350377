import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appTeampulse: IRoute = {
  name: 'app.teampulse',
  stateDefinition: {
    url: '/teampulse?reportMode&card',
    params: { reportMode: null, card: null },

    template: '<team-pulse-report></team-pulse-report>',
    data: { pageTitle: "Today's Team Pulse", filter: { hideDates: true } }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TeamPulse,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.TeamPulseReport],
    excludedFeatureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appTeampulse;
