import { parseJson } from '../helpers';
import { getAccountSettings } from './accountSettings';

export const generateUserLocalStorageKey = (
  localStorageKey: string
): string => {
  const { username } = getAccountSettings();
  return `${localStorageKey}${username ?? ''}`;
};

export const getLocalStorageItem = (storageItem: string) => {
  try {
    return window.localStorage?.getItem(storageItem);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot get localStorage item\nItem: ${storageItem}\nError: `,
      error
    );
    return null;
  }
};

export const setLocalStorageItem = (storageItem: string, value: any): void => {
  try {
    window.localStorage?.setItem(storageItem, value);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot set localStorage item\nItem: ${storageItem}\nError: `,
      error
    );
  }
};

export const getParsedLocalStorageItem = <T>(storageItem: string): T => {
  if (!storageItem) {
    return {} as T;
  }

  try {
    const item = getLocalStorageItem(storageItem);
    let value = {};

    if (typeof item === 'string' && item.length) {
      value = parseJson(item);
    }

    return value as T;
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot parse localStorage item\nItem: ${storageItem}\nError: `,
      error
    );
    return {} as T;
  }
};

export const removeLocalStorageItem = (storageItem: string): void => {
  try {
    window.localStorage?.removeItem(storageItem);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot remove localStorage item\nItem: ${storageItem}\nError: `,
      error
    );
  }
};
