import { IRoute } from '../../../../models/IRoute';
import { unsupportedBrowserTemplate as angularJsTemplate } from '../templates';

export const unsupportedBrowser: IRoute = {
  name: 'unsupportedBrowser',
  stateDefinition: {
    url: '/unsupportedBrowser',
    template: angularJsTemplate,
    data: { pageTitle: 'Unsupported Browser' }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default unsupportedBrowser;
