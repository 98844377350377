import lodash from 'lodash';
angular.module('app').controller('insightsImpactEventsCreateModal', InsightsImpactEventsCreateModal);

InsightsImpactEventsCreateModal.$inject = ['$scope', 'modalData', '$uibModalInstance', 'envConfig'];

function InsightsImpactEventsCreateModal($scope, modalData, $uibModalInstance, envConfig) {
    function addSelectedAttribute(days) {
        days.forEach(function (day) {
            day.selected = false;
        });
        return days;
        //So days looks like this for UI selection logic
        // $scope.days = [
        //     {id: 2, value: 'M', selected: false},
        //     {id: 3, value: 'T', selected: false},
        //     {id: 4, value: 'W', selected: false},
        //     {id: 5, value: 'Th', selected: false},
        //     {id: 6, value: 'F', selected: false},
        //     {id: 7, value:'Sa', selected: false},
        //     {id: 1, value: 'Su', selected: false}
        // ];
    }

    function setLocationSelection(impactEvent) {
        if (impactEvent.locationEvent.length === 4) {
            $scope.locationSelection = [allLocations];
        } else {
            impactEvent.locationEvent.forEach(function (le) {
                $scope.locationSelection.push(le);
            });
        }
    }

    function setTeamSelection(impactEvent) {
        impactEvent.groupList.forEach(function (group) {
            $scope.teams.forEach(function (team) {
                if (team.id === group.groupId) {
                    $scope.teamSelection.push(team);
                }
            });
        });
    }

    function setDays(impactEvent) {
        impactEvent.dayOfWeek.forEach(function (inDay) {
            $scope.days.forEach(function (day) {
                if (day.id === inDay) {
                    day.selected = true;
                }
            });
        });
    }

    const allTeams = { id: -1, name: 'All Teams' };
    const allLocations = 'All Locations';
    const addNewCohort = 'Add New Segment';
    const editCohort = 'Edit Segment';
    const viewCohort = 'View Default Segment';

    $scope.cohortHeader = addNewCohort;
    $scope.viewOnly = false;
    $scope.isEdit = false;
    $scope.teams = modalData.teams;
    $scope.teams.forEach(function (t) {
        if (t.name.length > 45) {
            t.name = t.name.slice(0, 41) + '...';
        }
    });
    if ($scope.teams[0].id !== -1) {
        $scope.teams.unshift(allTeams); //prepend 'All Teams' as 1st option
    }
    $scope.days = addSelectedAttribute(modalData.days);
    $scope.location = [allLocations, 'Office', 'Remote', 'Office/Remote', 'Unknown'];

    $scope.eventId = null;
    $scope.cohortName = '';
    $scope.locationSelection = [];
    $scope.startDate;
    $scope.endDate;
    $scope.noEndDate = false;
    $scope.teamSelection = [];
    $scope.cohortNameExists = false;

    if (modalData.impactEvent) {
        //If this exists, then it's an edit or viewOnly
        const item = modalData.impactEvent;
        $scope.eventId = item.eventId;
        $scope.cohortName = item.eventName;
        $scope.startDate = item.startDate;
        if (item.endDate) {
            $scope.endDate = item.endDate;
        } else {
            $scope.noEndDate = true;
        }
        setLocationSelection(item);
        setTeamSelection(item);
        setDays(item);

        if (modalData.viewOnly) {
            $scope.viewOnly = true;
            $scope.cohortHeader = viewCohort;
        }
        if (modalData.edit) {
            $scope.isEdit = true;
            $scope.cohortHeader = editCohort;
        }
    }

    $scope.onDayClick = function (day) {
        day.selected = !day.selected;
    };

    $scope.onNoEndDateClick = function (e) {
        $scope.noEndDate = e.target.checked;
        if (e.target.checked) {
            $scope.endDate = null;
        }
    };

    $scope.onStartDateBlur = function (event) {
        const val = event.target.value;

        if (val !== '') {
            const date = Date.parse(val);
            if (isNaN(date)) {
                const today = new Date();
                const todayStr = moment(today).format('MM/DD/YYYY');
                event.target.value = todayStr;
                $scope.startDate = today;
            } else {
                if ($scope.endDate) {
                    const endDate = new Date($scope.endDate);
                    if (date > Date.parse(endDate)) {
                        $scope.endDate = moment(new Date(event.target.value)).format('MM/DD/YYYY');
                    }
                }
            }
        }
    };

    $scope.onEndDateBlur = function (event) {
        const val = event.target.value;

        if (val !== '') {
            const date = Date.parse(val);
            const startDateStr = moment($scope.startDate).format('MM/DD/YYYY');

            if (isNaN(date)) {
                event.target.value = startDateStr;
                $scope.endDate = $scope.startDate;
            } else {
                if (date < Date.parse($scope.startDate)) {
                    event.target.value = startDateStr;
                    $scope.endDate = startDateStr;
                }
            }
        }
    };

    function scrollToBottom() {
        $('div.scrollable-section').animate(
            {
                scrollTop: 600
            },
            500
        );
    }

    function showShadowBoxIfScroll() {
        const height = $('div.row.impact-modal-body').height();
        if (height > 630) {
            $('div.impact-modal-header').addClass('impact-top-shadow');
            $('div.impact-modal-footer').addClass('impact-bottom-shadow');
            scrollToBottom();
        } else {
            $('div.impact-modal-header').removeClass('impact-top-shadow');
            $('div.impact-modal-footer').removeClass('impact-bottom-shadow');
        }
    }

    $scope.locationChange = function (kendoEvent) {
        if (
            kendoEvent.sender.value().includes(allLocations) ||
            (!kendoEvent.sender.value().includes(allLocations) && $scope.locationSelection.length === 4)
        ) {
            $scope.locationSelection = [allLocations];
            kendoEvent.sender.list[0].children[1].children[0].children.forEach(function (li) {
                li.classList.add('k-state-selected');
            });
        } else if (kendoEvent.sender.value().length === 0) {
            kendoEvent.sender.list[0].children[1].children[0].children.forEach(function (li) {
                li.classList.remove('k-state-selected');
            });
        }
        showShadowBoxIfScroll();
    };

    $scope.teamChange = function (kendoEvent) {
        if (
            kendoEvent.sender.value().includes(allTeams.id) ||
            (!kendoEvent.sender.value().includes(allTeams.id) &&
                $scope.teamSelection.length === $scope.teams.length - 1)
        ) {
            $scope.teamSelection = [allTeams];
            lodash.forEach(kendoEvent.sender.list[0].children[1].children[0].children, function (li) {
                li.classList.add('k-state-selected');
            });
        } else if (kendoEvent.sender.value().length === 0) {
            lodash.forEach(kendoEvent.sender.list[0].children[1].children[0].children, function (li) {
                li.classList.remove('k-state-selected');
            });
        }
        showShadowBoxIfScroll();
    };

    $scope.disabled = function () {
        if ($scope.cohortName.trim().length === 0) {
            return true;
        }
        if (modalData.cohortNameExists($scope.cohortName, $scope.eventId)) {
            $scope.cohortNameExists = true;
            return true;
        } else {
            $scope.cohortNameExists = false;
        }

        return (
            $scope.teamSelection.length === 0 ||
            $scope.locationSelection.length === 0 ||
            !$scope.startDate ||
            (!$scope.endDate && !$scope.noEndDate) ||
            $scope.days.filter(function (day) {
                return day.selected;
            }).length === 0
        );
    };

    $scope.saveCohort = function () {
        const groups = $scope.teamSelection.map(function (group) {
            return { groupId: group.id };
        });
        const days = $scope.days
            .filter(function (day) {
                if (day.selected) return day;
            })
            .map(function (item) {
                return item.id;
            });
        if ($scope.locationSelection.includes(allLocations)) {
            $scope.locationSelection = $scope.location.filter(function (loc) {
                return loc !== allLocations;
            }); //remove the 'All Locations' string
        }

        const impactEvent = {
            eventId: $scope.eventId,
            eventName: $scope.cohortName,
            groupList: groups,
            dayOfWeek: days,
            startDate: $scope.startDate,
            endDate: $scope.endDate,
            locationEvent: $scope.locationSelection
        };
        $scope.showProgress = true;
        modalData
            .saveImpactEvent(envConfig.apiUrl(), impactEvent, modalData.notificationService)
            .then(function () {
                $scope.showProgress = false;
                modalData.refreshCallback();
                $uibModalInstance.dismiss();
            })
            .catch(function () {
                $scope.showProgress = false;
                $uibModalInstance.dismiss();
            });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

    function addDisabledStyle() {
        const lis = $('div.k-multiselect-wrap').find('li');
        if (lis === undefined || lis.length === 0) {
            setTimeout(addDisabledStyle);
        } else {
            $('div.k-multiselect-wrap').find('li').addClass('disabled');
        }
    }
    function checkScrollThingie() {
        const scrollThingie = $('div.row.impact-modal-body');
        if (scrollThingie === undefined || scrollThingie.length === 0) {
            setTimeout(checkScrollThingie);
        } else {
            showShadowBoxIfScroll();
        }
    }
    if ($scope.viewOnly) {
        addDisabledStyle();
        checkScrollThingie();
    }
    if ($scope.isEdit) {
        checkScrollThingie();
    }
}
