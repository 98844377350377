'use strict';

angular.module('app')
.directive('alarms', function () {
    return {
        restrict: 'E',
        template: require('views/widgets/dashboard/alarms.html'),
        controller: ['$scope', 'dashboardApiService', function ($scope, dashboardApiService) {
            $scope.hasRecentActivity = false;

            $scope.bindAlarms = function () {
                dashboardApiService.getAlarms($scope.parameters)
                .success(function (result) {
                    $scope.alarms = {
                        screenshots: result.sscount1,
                        emails: result.emailcount1,
                        popups: result.popupcount1,
                        terminations: result.termcount1
                    };
                });
            };
        }]
    };
});
