import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import lodash from 'lodash';

angular.module('app').controller('ScreenshotsSafeSearchCtrl', ScreenshotsSafeSearchCtrl);

ScreenshotsSafeSearchCtrl.$inject = [
    '$scope',
    'messagesService',
    'screenshotsSafeSearchService',
    'accountSettings',
    'accountApiHelper',
    'chartService',
    '$q',
    'constantsService',
    'authorizationService',
    'notificationService',
    'atHelperFunctions'
];

function ScreenshotsSafeSearchCtrl(
    $scope,
    msg,
    service,
    accountSettings,
    accountApiHelper,
    chartService,
    $q,
    constant,
    authorizationService,
    notificationService,
    atHelperFunctions
) {
    $scope.canBeSaved = false;
    $scope.isDirty = false;
    $scope.annualStorageLimitTooltip = msg.get('annualSafeSearchStorageLimitTooltip');
    $scope.enableSafeSearchTooltip = msg.get('enableSafeSearchTooltip');
    var font = constant.get('fontString', 'body', 13);
    var currentMonth = parseInt(moment().format('MM'));
    var currentYear = parseInt(moment().format('YYYY'));

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(
            levels,
            'app.screenshots.flagging',
            BundleFlag.ScreenshotFlagging
        );
    };

    var monthKey = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    function getSafeSearchPreference() {
        $scope.canBeSaved = false;
        service.getPreference().success(function (data) {
            $scope.preference = data.safeSearch;
            $scope.canBeSaved = accountSettings.safeSearchFeatureFlag;
        });
    }
    getSafeSearchPreference();

    var findMonth = function (data, loopIndex, monthIndex) {
        var month = lodash.find(data, function (r) {
            return r.month === monthIndex && r.year === (loopIndex > 0 ? currentYear : currentYear - 1);
        });

        return month;
    };

    var maxUsage = 0;

    var formatData = function (data) {
        data.forEach(function (item) {
            var timestamp = moment(angular.copy(item.month));
            item.month = parseInt(timestamp.format('MM'));
            item.year = parseInt(timestamp.format('YYYY'));
            maxUsage = Math.max(item.usage, maxUsage);
        });

        var formattedData = [];

        data.sort(function (a, b) {
            var ret = a.year - b.year;
            if (ret !== 0) {
                return ret;
            }
            return a.month - b.month;
        });

        var i;
        var k;

        for (i = currentMonth - 11, k = 0; i < currentMonth + 1; i++, k++) {
            var j = i;

            if (i <= 0) {
                j = 12 + i;
            }

            var d = findMonth(data, i, j);

            if (!d) {
                d = {
                    month: j,
                    year: i > 0 ? currentYear : currentYear - 1,
                    usage: 0
                };
            }

            d.name = monthKey[j - 1];
            d.slot = k;
            formattedData.push(d);
        }

        return formattedData;
    };

    var getDateValue = function (data) {
        var resetTime = moment(angular.copy($scope.safeSearchInfo.renewDate));
        var month = resetTime.format('MMMM');
        var year = parseInt(resetTime.format('YYYY'));
        var slotItem = lodash.find(data, function (d) {
            return d.year === year && d.name === month;
        });

        if (slotItem) {
            var totalDays = resetTime.daysInMonth();
            var resetDay = parseInt(resetTime.format('D'));
            var percent = (resetDay / totalDays) * 0.4;
            return slotItem.slot + percent - 0.2;
        }

        return -1;
    };

    var chartData;
    $scope.dlpUsageChartConfig = {
        getData: function () {
            if (chartData === undefined) {
                chartData = service.getStats(); // createPromise(fakeData);
            }
            return chartData;
        },
        checkData: function (data) {
            return !!data;
        },
        buildChart: function (result) {
            var data = formatData(result);
            return chartService.buildColumnChart({
                seriesDefaults: {
                    type: 'line',
                    opacity: 1
                },
                series: [
                    {
                        field: 'usage',
                        categoryField: 'name',
                        color: constant.get('color', 'COLOR-PRIMARY'),
                        tooltip: {
                            visible: true,
                            template: function (item) {
                                return atHelperFunctions.formatUnits(
                                    item.value,
                                    null,
                                    null,
                                    ' ' + msg.get('screenshotsLowercase'),
                                    null,
                                    999999
                                );
                            },
                            background: constant.get('color', 'COLOR-INFO'),
                            color: '#f0f0f0'
                        }
                    }
                ],
                labelTooltipTemplate: function (item) {
                    return atHelperFunctions.formatUnits(
                        item.value,
                        null,
                        maxUsage,
                        ' ' + msg.get('screenshotsLowercase'),
                        null,
                        999999
                    );
                },
                data: data,
                valueAxisName: 'valueAxis',
                valueAxisMax: maxUsage * 1.1,
                categoryAxisName: 'categoryAxis',
                categoryAxisNotes: {
                    line: {
                        width: 3,
                        length: 327,
                        color: '#48B0F7'
                    },
                    icon: {
                        border: {
                            width: 0
                        }
                    },
                    data: [
                        {
                            value: getDateValue(data)
                        }
                    ]
                },
                colorScheme: 'dlp',
                legendVisible: false,
                font: font,
                rotation: -45
            });
        },
        preventResizeProcessing: false
    };

    $scope.saveSafeSearchPreference = function () {
        var payload = {
            safeSearch: $scope.preference
        };

        $scope.canBeSaved = false;

        service.setPreference(payload).success(function () {
            notificationService.showNotification(msg.get('changesSaved'), 'success');
            accountApiHelper.loadSettings().then(function () {
                $scope.canBeSaved = accountSettings.safeSearchFeatureFlag;
                $scope.isDirty = false;
                accountApiHelper.getSafeSearchInfo();
            });
        });
    };

    $scope.$watch('preference', function (newValue, oldValue) {
        if (newValue && !accountSettings.safeSearchFeatureFlag) {
            var upgradeMessage = authorizationService.hasRouteByName('app.account.upgrade')
                ? '<a href="#/app/account/upgrade"><strong> Click here to upgrade.</strong></a>'
                : '';
            notificationService.showNotification(
                'Screenshot Flagging is available as an Add-On to the Advanced Premium Plan.' + upgradeMessage,
                'info',
                0,
                true
            );
            $scope.preference = false;
        } else if (oldValue !== undefined) {
            $scope.isDirty = true;
        }
    });

    accountApiHelper.getSafeSearchInfo();

    $scope.$on('SafeSearchInfoSet', function (e, info) {
        $scope.safeSearchInfo = info;
        $scope.quotaLimitTooltip = atHelperFunctions.formatUnits(
            info.quotaLimit,
            null,
            null,
            ' ' + msg.get('screenshotsLowercase'),
            null,
            999999999999999
        );
        $scope.quotaUsageTooltip = atHelperFunctions.formatUnits(
            info.quotaUsage,
            null,
            null,
            ' ' + msg.get('screenshotsLowercase'),
            null,
            999999999999999
        );
        $scope.quotaRemainingTooltip = atHelperFunctions.formatUnits(
            info.quotaRemaining,
            null,
            null,
            ' ' + msg.get('screenshotsLowercase'),
            null,
            999999999999999
        );
    });
}

angular.module('app').service('screenshotsSafeSearchService', ScreenshotsSafeSearchService);

ScreenshotsSafeSearchService.$inject = ['$http', 'envConfig'];

function ScreenshotsSafeSearchService($http, envConfig) {
    this.getPreference = function () {
        var isOverriden = window?.localStorage?.getItem('activTrak.screenshotSafeSearchV2Override');
        var url = isOverriden
            ? envConfig.reportingServiceUrl() + '/reports/v1/screenshots/safeSearch'
            : envConfig.apiUrl() + '/api/screenshots/safeSearch';
        return $http.get(url, {
            ignoreLoadingBar: true
        });
    };

    this.getStats = function () {
        var isOverriden = window?.localStorage?.getItem('activTrak.screenshotSafeSearchV2Override');
        var url = isOverriden
            ? envConfig.reportingServiceUrl() + '/reports/v1/screenshots/safeSearch/stats'
            : envConfig.apiUrl() + '/api/screenshots/safeSearch/stats';
        return $http.get(url, {
            ignoreLoadingBar: true
        });
    };

    this.setPreference = function (payload) {
        var isOverriden = window?.localStorage?.getItem('activTrak.screenshotSafeSearchV2Override');
        var url = isOverriden
            ? envConfig.reportingServiceUrl() + '/reports/v1/screenshots/safeSearch'
            : envConfig.apiUrl() + '/api/screenshots/safeSearch';
        return $http.post(url, payload);
    };
}
