import React, { useEffect, useRef } from 'react';
import Litepicker from '../../../third-party/litepicker/index';
import {
  PickerInputElement,
  ReportDatePickerProps
} from '../models/IDateRangePicker';
import { LitepickerContainer } from '../../../third-party/litepicker/styles/litepicker.styles';

export const ReportDatePicker = (props: ReportDatePickerProps) => {
  const { startDate, endDate, customDates, onSubmit } = props;

  const inputRef = useRef<PickerInputElement>(null);

  useEffect(() => {
    const element = inputRef.current;
    if (!element) {
      return;
    }

    const reset = () => {
      element.pickerInstance.destroy();
      element.value = '';
    };

    if (element.pickerInstance) {
      reset();
    }

    const today = new Date();
    const minDate = customDates?.customMinDate;
    const minYear = new Date(minDate).getFullYear();

    element.pickerInstance = new Litepicker({
      element,
      singleMode: false,
      startDate,
      endDate: customDates?.customEndDate,
      minDate,
      maxDate: today,
      firstDay: 0,
      format: 'YYYY/MM/DD',
      inlineMode: true,
      autoApply: false,
      dropdowns: {
        minYear,
        maxYear: null,
        months: true,
        years: true
      },
      showTooltip: true,
      tooltipText: {
        one: 'day',
        other: 'days'
      },
      setup: (picker) => {
        picker.on('button:apply', (date1, date2) => {
          onSubmit(date1, date2);
        });
      }
    });

    //destroy the picker when unmounting
    return () => {
      reset();
    };
  }, [
    customDates?.customEndDate,
    customDates?.customMinDate,
    endDate,
    onSubmit,
    startDate
  ]);

  return (
    <LitepickerContainer
      id="53f5775c-1e25-4a67-8f07-bec54abbaf27"
      ref={inputRef}
    />
  );
};
