import theme from '../../theme/theme';
import { gray18 } from '../../theme/styleGuide';

export const ccSidebarClasses = {
  toolbar: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    height: theme.spacing(10),
    marginBottom: theme.spacing(6)
  },
  image: {
    height: '100%'
  },
  link: {
    color: gray18,
    textDecoration: 'none',
    '&.active': {
      color: gray18
    },
    '&:active, &:focus': {
      color: gray18
    },
    '&:hover': {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.info.hover
    },
    '& .MuiListItemIcon-root': {
      color: 'inherit'
    },
    '& .MuiTypography-root': {
      color: 'inherit'
    },
    '& .MuiListItem-button': {
      '&:hover': {
        backgroundColor: theme.palette.info.hover
      }
    },
    '& .MuiListItemText-multiline': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body2,
      color: 'inherit',
      marginLeft: '4px'
    }
  },
  icon: {
    minWidth: theme.spacing(5),
    paddingLeft: theme.spacing(1)
  }
};
