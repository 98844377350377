import React, { useCallback, useMemo, useState } from 'react';
import { Button, DialogContent, DialogActions } from '@mui/material';
import { ConfigModalProps } from '../models';
import { IntegrationNotifications } from '../constants/IntegrationNotifications';
import { ConfigureBaseForm } from './ConfigureBaseForm';
import { useBaseFormState } from '../hooks/useBaseFormState';
import { IntegrationCodes } from '../constants/IntegrationCodes';
import { deleteTrayIntegrationInstance } from '../utils/integrationInstance.utils';

export const ConfigureOutlookCalendarModal = ({
  setOpenConfigDialog,
  configState
}: ConfigModalProps) => {
  const { createAndEnableIntegration } = configState;

  const {
    tenantId,
    handleTenantIdChange,
    clientId,
    handleClientIdChange,
    clientSecret,
    handleClientSecretChange,
    clearBaseFormFields
  } = useBaseFormState();

  const [tenantIdError, setTenantIdError] = useState(false);
  const [clientIdError, setClientIdError] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.stopPropagation();
      const integrationConfig = {
        tenantId,
        clientId,
        clientSecret
      };
      await createAndEnableIntegration(integrationConfig);
      clearBaseFormFields();

      if (configState.traySolutionInstanceId) {
        deleteTrayIntegrationInstance(
          configState.traySolutionInstanceId,
          IntegrationCodes.OutlookCalendar
        );
      }
      setOpenConfigDialog(false);
    },
    [
      tenantId,
      clientId,
      clientSecret,
      createAndEnableIntegration,
      clearBaseFormFields,
      setOpenConfigDialog,
      configState
    ]
  );

  const handleCancel = useCallback(() => {
    configState.setIntegrationConfigurationNotification({
      msg: IntegrationNotifications.Cancelled,
      type: 'success'
    });
    clearBaseFormFields();
    setOpenConfigDialog(false);
  }, [configState, clearBaseFormFields, setOpenConfigDialog]);

  const isFormValid = useMemo(() => {
    return (
      tenantId && clientId && clientSecret && !tenantIdError && !clientIdError
    );
  }, [tenantId, clientId, clientSecret, tenantIdError, clientIdError]);

  return (
    <>
      <DialogContent>
        <ConfigureBaseForm
          tenantId={tenantId}
          clientId={clientId}
          clientSecret={clientSecret}
          onTenantIdChange={handleTenantIdChange}
          onClientIdChange={handleClientIdChange}
          onClientSecretChange={handleClientSecretChange}
          tenantIdError={tenantIdError}
          setTenantIdError={setTenantIdError}
          clientIdError={clientIdError}
          setClientIdError={setClientIdError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Finish
        </Button>
      </DialogActions>
    </>
  );
};
