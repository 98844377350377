import React from 'react';
import { tpTooltipClasses } from './Tooltip.style';
import { Tooltip as MaterialTooltip } from '@mui/material';
import { isEmpty } from '../../utilities';

const Tooltip = (props) => {
    const {
        arrow = true,
        children,
        enterDelay,
        enterNextDelay,
        enterTouchDelay,
        id,
        interactive,
        leaveDelay,
        leaveTouchDelay,
        onClose,
        onOpen,
        open,
        placement,
        PopperProps,
        title,
        TransitionComponent,
        TransitionProps
    } = props;

    let { disableFocusListener, disableHoverListener, disableTouchListener } = props;

    disableFocusListener = isEmpty(title) ? true : disableFocusListener;
    disableHoverListener = isEmpty(title) ? true : disableHoverListener;
    disableTouchListener = isEmpty(title) ? true : disableFocusListener;

    return (
        <MaterialTooltip
            arrow={arrow}
            children={children}
            classes={{ tooltip: tpTooltipClasses.tooltip, tooltipArrow: tpTooltipClasses.tooltipArrow }}
            disableFocusListener={disableFocusListener}
            disableHoverListener={disableHoverListener}
            disableTouchListener={disableTouchListener}
            enterDelay={enterDelay}
            enterNextDelay={enterNextDelay}
            enterTouchDelay={enterTouchDelay}
            id={id}
            interactive={interactive}
            leaveDelay={leaveDelay}
            leaveTouchDelay={leaveTouchDelay}
            onClose={onClose}
            onOpen={onOpen}
            open={open}
            placement={placement}
            PopperProps={PopperProps}
            title={title || <></>}
            TransitionComponent={TransitionComponent}
            TransitionProps={TransitionProps}
        ></MaterialTooltip>
    );
};;

export default Tooltip;
