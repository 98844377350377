import { IRoute } from '../../../../../models/IRoute';

export const appDevelopment: IRoute = {
  name: 'app.development',
  stateDefinition: {
    url: '/development',
    abstract: true
  }
};

export default appDevelopment;
