import { latestUpdatesServiceFunctions as service } from '_app/serviceFunctions/latestUpdatesServiceFunctions';

angular.module('app').controller('LatestUpdatesCtrl', LatestUpdatesCtrl);

LatestUpdatesCtrl.$inject = ['$scope', 'notificationService', 'supportTicketsService'];

function LatestUpdatesCtrl($scope) {
    // Load user last seen
    service
        .getUserLastSeen(true)
        .then(function () {
            service.loadLatestUpdates(true);
        })
        .catch(angular.noop);

    //TODO: this is causing an infinite digest loop - fix!
    $scope.$watch(
        function () {
            return service.getLatestUpdates();
        },
        function (newValue) {
            $scope.latestUpdates = newValue;
        }
    );

    $scope.$on('$destroy', function () {
        service.updateUserLastSeen();
    });
}
