import GridColumn from './GridColumn';

const _createColumns = (columnDefinitions, dataSource) => {
    let columns = [];
    _.forEach(columnDefinitions, (columnDefinition) => {
        columns.push(
            new GridColumn(
                columnDefinition,
                dataSource
            ).getColumn()
        );
    });

    return columns;
};

const _createAutoSelectDataBoundFunction = (report, dataBoundFunction) => {
    return (e) => {
        var grid = e.sender;
        var data = grid._data;
        var firstRow;
        var selectedRow;
        var selectedItemId = report.selectedItemId();
        var selectedItemTitle = report.selectedItemTitle();
        data.forEach(function (row, key) {
            if (key === 0) {
                firstRow = row;
            } else if (selectedItemId && selectedItemId === row.id) {
                selectedRow = row;
            } else if (selectedItemTitle && selectedItemTitle === row.title) {
                selectedRow = row;
            }
        });

        if (!(selectedRow && selectedRow.uid)) {
            selectedRow = firstRow;
            report.setSelectedItem(selectedRow);
        }

        if (selectedRow && selectedRow.uid) {
            grid.select('tr[data-uid="' + selectedRow.uid + '"]');
        }

        report.isLoading.next(false);

        if (typeof dataBoundFunction === 'function') {
            dataBoundFunction(e);
        }
    };
};

class ReportGrid {
    constructor(
        report,
        {
            autoBind,
            dataSource,
            columnDefinitions,
            filterable,
            selectable,
            sortable,
            scrollable,
            height,
            dataBoundFunction,
            autoSelectOnDataBind
        } = {}
    ) {
        this.report = report;
        this.autoBind = autoBind;
        this.dataSource = dataSource;
        this.columnDefinitions = columnDefinitions;
        this.columns = _createColumns(
            this.columnDefinitions,
            this.dataSource
        );
        this.filterable = filterable;
        this.selectable = selectable;
        this.sortable = sortable;
        this.scrollable = scrollable;
        this.height = height;
        this.dataBoundFunction = autoSelectOnDataBind
            ? _createAutoSelectDataBoundFunction(this.report, dataBoundFunction)
            : dataBoundFunction;
    }

    updateOptions() {
        this.columns = _createColumns(
            this.columnDefinitions,
            this.dataSource
        );
        return this.getOptions();
    }

    getOptions() {
        return {
            autoBind: this.autoBind,
            dataSource: this.dataSource,
            columns: this.columns,
            filterable: this.filterable,
            selectable: this.selectable,
            sortable: this.sortable,
            scrollable: this.scrollable,
            height: this.height,
            dataBound: this.dataBoundFunction
        };
    }
}

export default ReportGrid;
