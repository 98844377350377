'use strict';

angular.module('app').service('timezoneService', TimezoneService);

TimezoneService.$inject = ['$http', 'envConfig'];

function TimezoneService($http, envConfig) {
    this.getShowLocalTimezone = function () {
        return $http.get(envConfig.adminApiUrl() + '/admin/v1/accountsettings/showlocaltimezone');
    };
}
