/*================================================================
Inputs:
    urlLabel: String, Used as the visual link text (Optional)
    urlOptions:
    {
        default: String, Can be either 'internal' or 'external' to override the default setting. Defaults to internal if stateName given (Optional)
        internalLink:
        {
            visible: Bool, Sets internal link visibility
            stateName: String, Internal state name,
            stateParams: Array, State parameters to include,
            icon: String, Icon to use for button
        },
        externalLink:
        {
            visible: Bool, Sets external link visibility
            url: String, External link url.  Is used for urlLabel url Label isn't set
            icon: String, Icon to use for button
        },
        copyLink:
        {
            visible: Bool, Sets copy link visibility
            icon: String, Icon to use for button
        }
    }
===================================================================*/

'use strict';

angular.module('app').directive('atUrl', urlButton);

function urlButton() {
    return {
        restrict: 'EA',
        scope: {
            urlLabel: '=?',
            urlOptions: '=?'
        },
        template: require('views/widgets/urlButtons.html'),
        controller: UrlButtonCtrl
    };
}

UrlButtonCtrl.$inject = [
    '$scope',
    '$state',
    '$window',
    '$document',
    '$timeout',
    'atHelperFunctions'
];

function UrlButtonCtrl(
    $scope,
    $state,
    $window,
    $document,
    $timeout,
    atHelperFunctions
) {
    var lastOn;
    var clipboardConfirmOffset;
    var id;
    var buttonCount;
    $scope.showButtons = false;

    var hideAllTooltips = function () {
        var popups = $document.find('[role=tooltip].k-tooltip.k-popup');

        popups.each(function () {
            var popup = $(this).data('kendoPopup');
            popup.close();
        });
    };

    var updateButtons = function (e, callback) {
        if (!$scope.urlOptions) {
            $scope.urlOptions = [];
        } else {
            // If urlLabel is not defined, set to external link url if defined
            if (!$scope.urlOptions.hideLabel) {
                $scope.urlLabel =
                    typeof $scope.urlLabel !== 'undefined'
                        ? $scope.urlLabel
                        : typeof $scope.urlOptions.externalLink !== 'undefined'
                        ? $scope.urlOptions.externalLink.url
                        : null;
            }
            $scope.doNotLinkLabel =
                !!$scope.urlOptions && !!$scope.urlOptions.doNotLinkLabel;
            $scope.groupPosition = {};

            lastOn = 'url';
            clipboardConfirmOffset = 0;

            // If $scope.default is not set or is invalid set first valid link
            if (
                $scope.urlOptions &&
                ($scope.urlOptions.default === null ||
                    !(
                        $scope.urlOptions.default === 'internal' ||
                        $scope.urlOptions.default === 'external'
                    ))
            ) {
                // Set to internal as default if stateName is set
                if (
                    $scope.urlOptions.internalLink &&
                    typeof $scope.urlOptions.internalLink.stateName === 'string'
                ) {
                    $scope.urlOptions.default = 'internal';
                }
                // Else set to external if external is set
                else if (
                    $scope.urlOptions.externalLink &&
                    typeof $scope.urlOptions.externalLink.url === 'string'
                ) {
                    $scope.urlOptions.default = 'external';
                }
            }

            // Used to determine default behavior based on if any button is visible or not
            // Two or more buttons must be set to visible in order to show buttons
            buttonCount = 0;
            if (
                $scope.urlOptions.internalLink &&
                $scope.urlOptions.internalLink.visible
            ) {
                buttonCount++;
            }
            if (
                $scope.urlOptions.externalLink &&
                $scope.urlOptions.externalLink.visible
            ) {
                buttonCount++;
            }
            if (
                $scope.urlOptions.copyLink &&
                $scope.urlOptions.copyLink.visible
            ) {
                buttonCount++;
            }

            $scope.allowButtons = buttonCount > 1;
        }
        if (typeof callback === 'function') {
            callback(e, true, true);
        }
    };

    updateButtons();

    // Toggle showing buttons
    $scope.showButtons = false;
    var removeDelay;
    $scope.toggleButtons = function (e, state, isUrl) {
        if ($scope.allowButtons) {
            if (state && !isUrl) {
                $timeout.cancel(removeDelay);
                lastOn = 'buttons';
            }

            if (state) {
                $scope.showButtons = $scope.allowButtons && state;
                if (isUrl) {
                    $scope.showCopyMsg = false;
                    var childElement;
                    if (
                        $scope.urlOptions.useChildHorizontal ||
                        $scope.urlOptions.useChildVertical
                    ) {
                        if (!id) {
                            id = $scope.urlOptions.id;
                        }
                        childElement = angular.element('#' + id);
                    }
                    var leftTarget = angular.element(
                        $scope.urlOptions.useParentHorizontal
                            ? e.currentTarget.offsetParent
                            : $scope.urlOptions.useChildHorizontal
                            ? childElement
                            : e.currentTarget
                    );
                    var topTarget = angular.element(
                        $scope.urlOptions.useParentVertical
                            ? e.currentTarget.offsetParent
                            : $scope.urlOptions.useChildVertical
                            ? childElement
                            : e.currentTarget
                    );
                    var leftRect = atHelperFunctions.getElementOffset(
                        leftTarget
                    );
                    var topRect = atHelperFunctions.getElementOffset(topTarget);
                    var leftOffset =
                        $scope.urlOptions.useParentHorizontal ||
                        $scope.urlOptions.useChildHorizontal
                            ? leftRect.right
                            : leftTarget[0].offsetWidth;
                    var topOffset =
                        $scope.urlOptions.useParentVertical ||
                        $scope.urlOptions.useChildVertical
                            ? topRect.top
                            : topTarget[0].offsetTop;

                    if ($scope.urlOptions.fixedLeftOffset) {
                        leftOffset = $scope.urlOptions.fixedLeftOffset;
                    }

                    $scope.groupPosition = {
                        left:
                            leftOffset -
                            ($scope.urlOptions.offsetHorizontal || 0) +
                            'px',
                        top:
                            topOffset -
                            ($scope.urlOptions.offsetVertical || 0) +
                            'px'
                    };

                    var messageOffset =
                        $scope.urlOptions.messageAlign === 'left' ? 0 : 58;
                    clipboardConfirmOffset =
                        leftOffset -
                        ($scope.urlOptions.offsetHorizontal || 0) -
                        messageOffset +
                        'px';
                    $timeout.cancel(removeDelay);
                    removeDelay = $timeout(removeButtons, 2000);
                }
            } else if (!state) {
                removeButtons();
            }
        }
    };

    $scope.urlMouseLeave = function () {
        $timeout.cancel(removeDelay);
        removeDelay = $timeout(removeButtons, 100);
    };

    var removeButtons = function () {
        if (!$scope.showCopyMsg) {
            $timeout.cancel(removeDelay);
            $scope.showButtons = false;
            lastOn = 'url';
        }
        hideAllTooltips();
    };

    // Go to internal page
    $scope.internalLinkClicked = function () {
        $state.go(
            $scope.urlOptions.internalLink.stateName,
            $scope.urlOptions.internalLink.stateParams
        );
    };

    // Copy url to clipboard
    $scope.copyUrl = function () {
        var textToCopy =
            $scope.urlOptions.copyLink.copyText ||
            $scope.urlOptions.externalLink.url;

        // create temp element
        var copyElement = document.createElement('span');
        copyElement.appendChild(document.createTextNode(textToCopy));
        copyElement.id = 'tempCopyToClipboard';
        angular.element($document.find('body').eq(0).append(copyElement));

        // select the text
        var range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        copyElement.remove();

        $scope.groupPosition.left = clipboardConfirmOffset;
        $scope.showCopyMsg = true;
        $timeout(function () {
            $scope.showCopyMsg = false;
            removeButtons();
        }, 1500);
    };

    $scope.linkClicked = function (type) {
        removeButtons();
        if (type === 'internal') {
            $scope.internalLinkClicked();
        } else if (type === 'external') {
            var link = $scope.urlOptions.externalLink.url;
            if (link && link.indexOf('://') === -1) {
                link = 'http://' + link;
            }
            $window.open(link, '_blank');
        }

        $scope.linkBlocked = true;

        $timeout(function () {
            $scope.linkBlocked = false;
        }, 1000);
    };

    // If url is clicked, determine what the default action should be
    $scope.defaultLink = function () {
        if ($scope.linkBlocked) {
            return;
        }

        if (lastOn === 'url') {
            $scope.linkClicked($scope.urlOptions.default);
        }
    };

    $scope.getInternalLinkTooltip = function () {
        return $scope.urlOptions.internalLink.tooltip || 'View Activity';
    };

    $scope.getExternalLinkTooltip = function () {
        return $scope.urlOptions.externalLink.tooltip || 'Open Link';
    };

    $scope.getCopyLinkTooltip = function () {
        return $scope.urlOptions.copyLink.tooltip || 'Copy Link';
    };

    $scope.$on('atUpdateUrlButtons', function (e, options) {
        $scope.showButtons = false;
        if (typeof options.label !== 'undefined') {
            $scope.urlLabel = options.label;
        }
        if (typeof options.externalLink !== 'undefined') {
            $scope.urlOptions.externalLink = options.externalLink;
        }
        if (typeof options.internalLink !== 'undefined') {
            $scope.urlOptions.internalLink = options.internalLink;
        }
        if (typeof options.copyLink !== 'undefined') {
            $scope.urlOptions.copyLink = options.copyLink;
        }
        if (typeof options.doNotLinkLabel !== 'undefined') {
            $scope.urlOptions.doNotLinkLabel = options.doNotLinkLabel;
        }
        if (typeof options.default !== 'undefined') {
            $scope.urlOptions.default = options.default;
        }
        if (typeof options.useParentHorizontal !== 'undefined') {
            $scope.urlOptions.useParentHorizontal = options.useParentHorizontal;
        }
        if (typeof options.useParentVertical !== 'undefined') {
            $scope.urlOptions.useParentVertical = options.useParentVertical;
        }
        if (typeof options.useChildHorizontal !== 'undefined') {
            $scope.urlOptions.useChildHorizontal = options.useChildHorizontal;
        }
        if (typeof options.useChildVertical !== 'undefined') {
            $scope.urlOptions.useChildVertical = options.useChildVertical;
        }
        if (typeof options.messageAlign !== 'undefined') {
            $scope.urlOptions.messageAlign = options.messageAlign;
        }
        if (typeof options.id !== 'undefined') {
            id = options.id;
        }
        if (typeof options.hideLabel !== 'undefined') {
            $scope.urlOptions.hideLabel = options.hideLabel;
        }

        $scope.urlOptions.offsetHorizontal = options.offsetHorizontal;
        $scope.urlOptions.offsetVertical = options.offsetVertical;
        $scope.urlOptions.fixedLeftOffset = options.fixedLeftOffset;

        updateButtons(options.e, $scope.toggleButtons);
    });
}
