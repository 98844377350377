'use strict';

angular.module('app').service('productivityApiService', ProductivityApiService);

ProductivityApiService.$inject = ['$http', 'envConfig'];

function ProductivityApiService($http, envConfig) {
    this.getGanttApiUrl = function () {
        return envConfig.reportingServiceUrl() + '/reports/v1/productivity/gantt?';
    };

    this.getProductivity = function (parameters, data) {
        return $http.get(this.getGanttApiUrl() + parameters, data);
    };

    this.getWebsites = function (id) {
        return $http.get(envConfig.apiUrl() + '/api/settings/productivity/' + id + '/websites');
    };

    this.getApplications = function (id) {
        return $http.get(envConfig.apiUrl() + '/api/settings/productivity/' + id + '/applications');
    };

    this.updateWebsiteOrApplication = function (isApp, productivity, payload) {
        if (isApp) {
            return $http.put(envConfig.apiUrl() + '/api/settings/productivity/applications/' + productivity, payload);
        } else {
            return $http.put(envConfig.apiUrl() + '/api/settings/productivity/websites/' + productivity, payload);
        }
    };
}
