import axios, { AxiosResponse } from 'axios';
import { authenticatedUserService } from 'activtrak-ui-utilities';

/**
 * Sets Axios interceptors for error handling and authorization token refreshing
 *
 * @returns {void} Void
 */
export default (): void => {
  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if (response.headers.Authorization) {
        authenticatedUserService.setUserToken(response.headers.Authorization);
      }

      return response;
    },
    (error: any): any => {
      if (error && error.response && error.response.status === 401) {
        authenticatedUserService.clearGlobals();
      }

      return Promise.reject(error);
    }
  );
};
