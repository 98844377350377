import { useCallback, useState } from 'react';
import { useAuthorization } from '../../common/services/Authorization';
import { useNotifications } from '../../common/services/Notifications';
import { Role } from '../../common/enums';
import { SupportPortalService, useSupportPortalState } from './';
import { redirectToPath } from '../utils';
import { notificationMessage } from '../constants';

export const useRedirect = () => {
  const service = SupportPortalService();
  const { handleImpersonation } = useSupportPortalState({
    service
  });

  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const authorizationService = useAuthorization();
  const notificationService = useNotifications();
  const isSuperAdmin: boolean = authorizationService.hasRole(Role.SuperAdmin);

  const impersonateAndRedirect = useCallback(async (account, path?: string) => {
    const { accountId, payload, tokenData } = account;

    setIsRedirecting(true);

    try {
      await handleImpersonation(tokenData, payload);
      redirectToPath(path, tokenData, isSuperAdmin);
    } catch (error) {
      notificationService.error(
        notificationMessage.rerouteErrorAlert(path, accountId)
      );
      console.error(notificationMessage.impersonationError, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { impersonateAndRedirect, isRedirecting, setIsRedirecting };
};
