import ProductivityLegendItem from './ProductivityLegendItem';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import ProductivityStatus from '../constants/productivityStatus';
import { templateServiceFunctions } from '../serviceFunctions/templateServiceFunctions';

const createProductivityLegendItems = (
    onClick,
    productivityList,
    barToggleClass = {},
    view,
    location
) => {
    return _.map(productivityList, function (productivity) {
        return new ProductivityLegendItem(
            productivity,
            onClick,
            !atHelperFunctions.isEmpty(barToggleClass[productivity]),
            view,
            location
        );
    });
};

const createProductivityColumnDropdown = (args) => {
    var statuses = ProductivityStatus.getStatuses();
    statuses.unshift(null);
    args.element.attr('class', 'at-prod-dropdown').kendoDropDownList({
        dataSource: statuses.map(function (status) {
            if (status === null) {
                return {
                    name: 'All',
                    productivity: null
                };
            }

            return {
                name: ProductivityStatus.getName(
                    status,
                    true,
                    true
                ),
                productivity: status
            };
        }),
        dataTextField: 'name',
        dataValueField: 'productivity',
        valuePrimitive: true,
        height: 'auto',
        template: kendo.template(function (item) {
            return templateServiceFunctions.productivityFilterTemplate(
                item,
                true
            );
        }),
        valueTemplate: kendo.template(function (item) {
            return templateServiceFunctions.productivityFilterTemplate(
                item
            );
        })
    });
};

export { createProductivityLegendItems, createProductivityColumnDropdown };
