import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartCheckboxLegendHorizontal } from '../../../common/components/Chart/components/ChartCheckboxLegendHorizontal';
import { updateLegendSelections } from '../../utils';
import {
  CheckboxColors,
  CheckboxLabel
} from '../../../common/components/Chart/models/ChartCheckboxLegend.props';
import { zeroStateColor } from '../../constants';
import { IBarChartLegendProps } from '../../models';

export const BarChartLegend = (props: IBarChartLegendProps) => {
  const { hasNoResults, id, updateChart, widgetData } = props;

  const [currentSelections, setCurrentSelections] = useState<
    (string | number)[]
  >([]);

  useEffect(() => {
    updateChart(currentSelections);
  }, [currentSelections, updateChart]);

  const legendLabels = useMemo(() => {
    return widgetData?.chartData?.datasets?.map((item, i) => {
      return {
        name: item.label,
        value: i,
        id: `${id}-${item.label?.replaceAll(' ', '-')}`
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData?.chartData?.datasets]);

  useEffect(() => {
    if (legendLabels?.length) {
      const selected = legendLabels.map((label: CheckboxLabel) => label.value);
      setCurrentSelections(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendLabels?.length]);

  const handleLegendClick = useCallback(
    (value) => {
      const newSelectionList = updateLegendSelections(value, currentSelections);
      setCurrentSelections(newSelectionList);
    },
    [currentSelections]
  );

  const legendColors = useMemo(() => {
    return widgetData?.chartData?.datasets?.map((item, i) => {
      return {
        checkboxColor: item.backgroundColor,
        checkmarkColor: widgetData?.chartData?.datasets[i].checkmarkColor
      };
    });
  }, [widgetData?.chartData?.datasets]);

  const zeroStateColors: CheckboxColors[] = [];

  for (let i = 0; i < legendLabels?.length; i++) {
    zeroStateColors.push(zeroStateColor);
  }

  const checkBoxColors = hasNoResults ? zeroStateColors : legendColors;

  return (
    <ChartCheckboxLegendHorizontal
      labels={legendLabels}
      colors={checkBoxColors}
      currentSelections={currentSelections}
      onClick={handleLegendClick}
      disabled={hasNoResults}
    />
  );
};
