import React, { useState } from 'react';
import { IChromebookUser } from '../models';
import { List, ListItem, IconButton, Box, Popover } from '@mui/material';
import CancelChromebookUserDialog from './CancelChromebookUserDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type InviteSettingsButtonProps = {
  user: IChromebookUser;
  isDisabled: boolean;
  onCancel: (users: string[], isCancel: boolean) => Promise<void>;
  onResend: (user: string) => Promise<void>;
};

export function InviteSettingsButton(props: InviteSettingsButtonProps) {
  const { user, isDisabled, onCancel, onResend } = props;

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'invite-settings-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <IconButton onClick={handleClick} disabled={isDisabled}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <List component="nav">
            <ListItem onClick={() => onResend(user.user)}>
              Resend Invite
            </ListItem>
            <ListItem onClick={() => setOpenCancelDialog(true)}>
              Cancel
            </ListItem>
            <CancelChromebookUserDialog
              open={openCancelDialog}
              user={user}
              onClose={handleClose}
              onCancel={onCancel}
            />
          </List>
        </Popover>
      </Box>
    );
}
