import React from 'react';
import { truncate } from 'lodash';
import { MemberType } from '../enums';
import { IMembersColumnHeaderProps } from '../models';
import Box from '@mui/material/Box';
import MemberTypeIcon from '../components/MemberTypeIcon';
import { groupMemberClasses } from '../styles/AddGroupMembers.styles';

export const useMembersColumns = (props: IMembersColumnHeaderProps) => {
  const { typeLabel } = props;

  return [
    {
      field: 'domain',
      fieldName:
        typeLabel === MemberType.Computer ? 'Primary Domain' : 'Logon Domain',
      headerName:
        typeLabel === MemberType.Computer ? 'Primary Domain' : 'Logon Domain',
      type: 'string',
      isSortable: true,
      width: '45%',
      template: (dataItem) => {
        return dataItem.domain;
      }
    },
    {
      field: 'name',
      fieldName: typeLabel === MemberType.Computer ? 'Computer' : 'User',
      headerName: typeLabel === MemberType.Computer ? 'Computer' : 'User',
      type: 'string',
      isSortable: true,
      width: '45%',
      template: (dataItem) => {
        const aliasName =
          dataItem.alias && dataItem.alias !== ''
            ? ` (${truncate(dataItem.alias, { length: 18 })})`
            : '';
        return (
          <Box display="flex" alignItems="center">
            <MemberTypeIcon type={dataItem.type} />
            <Box component="span" sx={groupMemberClasses.memberName}>
              {dataItem.name} {aliasName}
            </Box>
          </Box>
        );
      }
    }
  ];
};
