'use strict';

angular.module('app')
    .directive('atMultiSelectCheckbox', ['$timeout', 'atMultiSelectCheckboxService', function ($timeout, atMultiSelectCheckboxService) {
        return {
            restrict: 'A',
            scope: {
                dataSource: '=atDataSource',
                item: '=atItem',
                modelField: '=atModelField',
                callback: '=atCallback',
                skipDataReload: '=atSkipDataReload'
            },
            link: function ($scope, element) {
                atMultiSelectCheckboxService.initialize($scope.dataSource, $scope.modelField, $scope.callback, $scope.skipDataReload);

                element.on('click', function (e) {
                    atMultiSelectCheckboxService.itemSelected($scope.item.uid, e.target.checked, e.shiftKey);
                });
            }
        };
    }]);
