import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsTimezone: IRoute = {
  name: 'app.settings.timezone',
  stateDefinition: {
    url: '/settings/timezone',
    template: '<settings-timezone></settings-timezone>',
    data: { pageTitle: 'Time Zone' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsTimezone;
