import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import { Metric } from '_app/insights/models';

const _decodeFilters = (filters) => {
    const decodedString = decodeURI(filters)
        .replace(/^&/g, '')
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/%5E/g, '^')
        .replace(/\+/g, ' ')
        .replace(/%2C/g, ',')
        .replace(/%23/g, '#')
        .replace(/%24/g, '$')
        .replace(/%2F/g, '/')
        .replace(/%40/g, '@')
        .replace(/%26/g, '&')
        .replace(/%2B/g, '+')
        .replace(/%3A/g, ':')
        .replace(/>%3D/g, '>=') //greater than or equal to
        .replace(/<%3D/g, '<='); //less than or equal to

    return decodedString && JSON.parse(`{"${decodedString}"}`);
};

const _buildParams = (filters) => {
    var str = [];
    for (var p in filters)
      if (filters.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p]));
      }
    return str.join("&");
};

const _toFilterDateFormat = (date) => {
    var dateString = [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
      ].join('/');
      return dateString;
}

class ScheduleControl {
    constructor(
        envConfig,
        window,
        insightsService,
        notificationService,
        AccountSettingsService,
        dashboard,
        useDefaultMessage
    ) {
        this.recurrenceValues = ["Daily", "Weekly", "Monthly"];
        this.recurrenceSelection = "Weekly";
        this.monthlyOptions = Array.from(Array(31).keys(), x => x + 1); //["1", "2", "3", "4", "5"];
        this.monthlyDefault = 1;
        this.weeklyOptions = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.weeklyDefault = "Monday";
        this.dailyOption = this.weeklyOptions;
        this.dailySelection = this.weeklyDefault;
        this.timeSlots = ["Morning (8am - 12pm)", "Afternoon (12pm - 6pm)", "Evening (6pm -10pm)", "Night (10pm -12am)"];
        this.formatValues = ["PDF", "PNG"];
        this.formatSelection = "PDF";
        this.timeSelection = "Morning (8am - 12pm)";
        this.emailBody = useDefaultMessage ?
            "Your Personal Insights Dashboard is helpful for personal reflection and collaborative problem-solving. As you explore the dashboard, think about how these insights can help you design your day or identify opportunities for professional development. Taking a regular pulse of your work habits empowers you to understand your personal tendencies, perform at your best, and continuously improve."
            :
            "";
        this.linkText = "";
        this.dailyDisabled = false;
        this.dashboard = dashboard;
        this.filters = this.dashboard.embeddedFilters;
        this.displayableFilters = this.getDisplayableFilters();
        this.insightsService = insightsService;
        this.window = window;
        this.envConfig = envConfig;
        this.notificationService = notificationService;
        this.recurrenceTooltip = `
            <div style="text-align:left">
                Delivery time will be based on the account's timezone <br> and not the user/computer local time.
            <div>`
        this.subscribeOthersTooltip = `
            <div style="text-align:left">
                Only one email address per subscription is allowed.
            <div>`
        this.shareInsightsTooltip = `
            <div>
                <div style="text-align:left">
                    <p>Get step-by-step instructions for sharing <br>
                    Personal Insights with your team members.</p>
                </div>
                <div style="width:100%; text-align:right">
                    <span><a class="text-productive" href="https://support.activtrak.com/hc/en-us/articles/360058778932-Share-Personal-Insights-with-Your-Team-Members" target="_blank">LEARN MORE</a></span>
                </div>
            </div>`
        this.emailMessageTooltip = `
            <div style="text-align:left">
                You can customize the message below to share guidance or <br>  additional information with the team member you’re subscribing.
            </div>`
        this.filtersTooltip = `
            <div style="text-align:left;width: 300px;">
                Filters can’t be modified in subscriptions. To change the filters used in a subscription, 
                you must delete the subscription and create a new one with the desired filter selections.
            <div>`
        this.subscriptionName = dashboard.name;
        this.selectedEmail = AccountSettingsService.username;
        this.selectedEmails = [AccountSettingsService.username];
        this.metricValues = {};
        this.scheduleId = "";
    }

    crossFilters = {
        location: ['User'],
        workload_balance: ['User', 'Activity Week', 'Activity Month']
    }
    getDisplayableFilters() {
        let clonedFilters = _.clone(this.dashboard.embeddedFilters);
        const illegalFilters = this.crossFilters[this.dashboard.pageId];
        if (illegalFilters) {
            illegalFilters.forEach( filter => {
                delete clonedFilters[filter];
            })
        }
        return clonedFilters;
    }

    hidePersonalInsightsSubmit() {
        //'User' filter is required for 'Personal Insights'
        if (this.subscriptionName.toLowerCase() === 'personal insights') {
            return this.filters.User.trim().length === 0;
        }
        //For all other pages
        return false;
    }

    getButtonTitle() {
        if (this.hidePersonalInsightsSubmit()) {
            return 'Selection for User is required to save or send now';
        }
        return null;
    }

    displayAdjuster(displayableFilters) {
        Object.keys(displayableFilters).forEach(filter => {
            var value = displayableFilters[filter];
            if (filter == "Metric") {
                var metricValue = this.metricValues.find(element => element.value == value);
                if (metricValue && metricValue.text) {
                    displayableFilters[filter] = metricValue.text;
                }
            }
            else if (value && value.includes(" to ")) {
                var dates = value.split(" to ");
                var fromDate = new Date(dates[0]);
                var toDate = new Date(dates[1]);
                if(!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
                    toDate.setDate(toDate.getDate() - 1);
                    displayableFilters[filter] =  _toFilterDateFormat(fromDate) + " - " + _toFilterDateFormat(toDate);
                }
            }
        });
    }

    setMetricValues(metricValues) {
        this.metricValues =_.map(metricValues, (d) => new Metric(d)) || [];
        this.displayAdjuster(this.displayableFilters);
    }

    buildLinkUrl() {
        var link = window.location.href || "";
        link = link.slice(0, link.lastIndexOf("&tempFilters") != -1 ? link.lastIndexOf("&tempFilters") : link.length)
        link = link.indexOf("&source=insightsemail") == -1 ? link + "&source=insightsemail": link;
        return link;
    }

    setScheduleInfo(schedule) {
        var retrievedEmails = [];
        schedule.emails.forEach( (email) => {
            var destination = JSON.parse(email);
            retrievedEmails.push(destination.address);
            this.formatSelection = destination.format == "wysiwyg_png" ? "PNG" : "PDF";
            this.emailBody = destination.message;
        });
        if (this.emailBody && this.emailBody.indexOf("Access Dashboard in ActivTrak:") != -1) {
            this.includeLink = true;
            this.linkText = this.buildLinkUrl();
            this.emailBody = this.emailBody.replace(/^Access Dashboard in ActivTrak:.*$/m, '').trim();
        }
        this.scheduleId = schedule.id;
        this.subscriptionName = schedule.name;
        this.dashboard.id = schedule.dashboard;
        this.selectedEmail = retrievedEmails.join(', ');
        this.selectedEmails = retrievedEmails;
        this.setTimeFromCron(schedule.crontab);
        this.filters =  _decodeFilters(schedule.filter.substr(1));
        this.displayableFilters =  _decodeFilters(schedule.filter.substr(1));
        this.displayAdjuster(this.displayableFilters);
    }

    setTimeFromCron(crontab) {
        var cronSections = crontab.split(' ');
        switch(true) {
            case cronSections[2] != '*' :
                this.recurrenceSelection = "Monthly"
                this.dailyDisabled = false;
                this.dailyOption = this.monthlyOptions;
                this.dailySelection = cronSections[2];
                break;
            case cronSections[4] != '*' :
                this.recurrenceSelection = "Weekly"
                this.dailyDisabled = false;
                this.dailyOption = this.weeklyOptions;
                this.dailySelection = this.weeklyOptions[cronSections[4]];
                break;
            default:
                this.recurrenceSelection = "Daily"
                this.dailyDisabled = true;
                this.dailyOption = this.weeklyOptions;
                this.dailySelection = this.weeklyDefault;
        }

        switch(cronSections[1]) {
            case "8":
                this.timeSelection = "Morning (8am - 12pm)";
                break;
            case "12":
                this.timeSelection = "Afternoon (12pm - 6pm)";
                break;
            case "18":
                this.timeSelection = "Evening (6pm -10pm)";
                break;
            case "22":
                this.timeSelection = "Night (10pm -12am)";
                break;
            default:
                this.timeSelection = "Morning (8am - 12pm)";
        }
    }

    recurrenceUpdated(event) {
        if(this.recurrenceSelection == "Daily"){
            this.dailyDisabled = true;
        }else {
            this.dailyDisabled = false;
            if(this.recurrenceSelection == "Weekly"){
                this.dailyOption = this.weeklyOptions;
                this.dailySelection = this.weeklyDefault;
            } else {
                this.dailyOption = this.monthlyOptions;
                this.dailySelection = this.monthlyDefault;
            }
        }
    }

    getCronString() {
        var cronString = "";
        switch(this.timeSelection) {
            case "Morning (8am - 12pm)":
                cronString += "0 8 "
                break;
            case "Afternoon (12pm - 6pm)":
                cronString += "0 12 "
                break;
            case "Evening (6pm -10pm)":
                cronString += "0 18 "
                break;
            case "Night (10pm -12am)":
                cronString += "0 22 "
                break;
            default:
                cronString += "* * "
        }

        if (this.recurrenceSelection == "Daily") {
            cronString += "* * *";
        } else if ( this.recurrenceSelection == "Weekly" ) {
            cronString += "* * "+this.weeklyOptions.indexOf(this.dailySelection || this.weeklyDefault);
        } else {
            cronString += (this.dailySelection || this.monthlyDefault) + " * *";
        }
        return cronString;
    }

    saveSchedule() {
        return this.insightsService
        .saveInsightsSchedule(
            this.envConfig.apiUrl(),
            [{
                id: this.scheduleId,
                name: this.subscriptionName,
                dashboard: this.dashboard.id,
                filter: '?'+_buildParams(this.filters),
                emails: this.selectedEmails,
                crontab: this.getCronString(),
                format: this.formatSelection,
                message: this.linkText ? this.emailBody + "\n\n" + "Access Dashboard in ActivTrak: " + this.linkText : this.emailBody
            }],
            this.notificationService
        );
    }

    sendNow(schedule, useId) {
        var retrievedEmails = [];
        var sendNowSchedule = {};
        if(useId){
            schedule.emails.forEach( (email) => {
                var destination = JSON.parse(email);
                retrievedEmails.push(destination.address);
                sendNowSchedule.format = destination.format == "wysiwyg_png" ? "PNG" : "PDF";
                sendNowSchedule.message = destination.message;
            });
            sendNowSchedule.id = schedule.id;
            sendNowSchedule.name = schedule.name;
            sendNowSchedule.dashboard = schedule.dashboard;
            sendNowSchedule.emails = [retrievedEmails.join()];
            sendNowSchedule.crontab = schedule.crontab;
            sendNowSchedule.filter =  schedule.filter;
        } else {
            sendNowSchedule.id = "";
            sendNowSchedule.name = this.subscriptionName;
            sendNowSchedule.dashboard = this.dashboard.id;
            sendNowSchedule.emails = this.selectedEmails;
            sendNowSchedule.crontab = this.getCronString();
            sendNowSchedule.filter =  '?'+_buildParams(this.filters);
            sendNowSchedule.format = this.formatSelection;
            sendNowSchedule.message = this.linkText ? this.emailBody + "\n\n" + "Access Dashboard in ActivTrak: " + this.linkText : this.emailBody;
        }

        return this.insightsService
        .sendNowInsightsSchedule(
            this.envConfig.apiUrl(),
            sendNowSchedule,
            this.notificationService
        );
    }

    getShareableEmails() {
        return this.insightsService
        .getShareableEmails(
            this.envConfig.apiUrl(),
            this.notificationService
        );
    }

    deleteSchedules(schedules) {
        return this.insightsService
        .deleteInsightsSchedules(
            this.envConfig.apiUrl(),
            schedules,
            this.notificationService
        );
    }

    setIncludeLink(event) {
        if (event.target.checked) {
            this.linkText = this.buildLinkUrl();
        } else {
            this.linkText = "";
        }
    }

    getScheduleById(scheduleId) {
        return this.insightsService
            .getInsightsScheduleById(
                this.envConfig.apiUrl(),
                scheduleId,
                this.notificationService
            );
    }

    restrictRun() {
        return this.required && atHelperFunctions.isEmpty(this.model);
    }

    clearField() {
        this.model = null;
        this.filterUpdated();
    }
}

export default ScheduleControl;
