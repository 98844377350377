import { IReportModalListItem } from './';
import { WorkloadLabel } from './IWorkload';
import { IBarChartDataset, IWeeklyUsers } from './IWidget';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export enum WorkloadLevel {
  UnderUtilized = 'underutilized',
  OverUtilized = 'overutilized',
  Healthy = 'healthy'
}

export enum WorkloadLevelsLabel {
  UnderUtilized = 'Underutilized',
  OverUtilized = 'Overutilized',
  Healthy = 'Healthy'
}

export interface IWorkloadLevelDto {
  date?: string;
  underutilized: number;
  overutilized: number;
  healthy: number;
  total: number;
  users: IWorkloadLevelsUserDto[];
}

export interface IWorkloadLevelsDto {
  trends: IWorkloadLevelDto[];
  previous: IWorkloadLevelDto;
  lastUpdated: string;
  users: IWorkloadLevelsUserDto[];
}

export interface IWorkloadLevelsUserDto {
  name: string;
  status: number;
}

export interface IWorkloadUsersStatus {
  last30Days: WorkloadLabel;
  previous30Days: WorkloadLabel;
}

export interface IWorkloadChartData {
  labels: string[];
  datasets: IBarChartDataset[];
  weeklyUsers: IWeeklyUsers[];
}

export interface IWorkloadLevels {
  chartData: IWorkloadChartData;
  previous?: number;
  lastUpdated?: string;
  status?: IWorkloadUsersStatus;
}

export interface IWorkloadUsersState {
  init: (reportFilters: Partial<IReportFilters>) => Promise<void>;
  workloadData: IWorkloadLevels;
  allUsersByWeek: IWeeklyUsers[];
  isLoading: boolean;
  isTileError: boolean;
  workloadUsers: IReportModalListItem[];
  updateChart: (selected: (number | string)[]) => void;
  hasNoResults: boolean;
}
