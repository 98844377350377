export const ABBREVIATED_WEEKDAYS = ['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'];
export const FULL_WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
