import theme from '../../../../common/theme/theme';

export const cardTopCategoryClasses = {
    root: {
        paddingBottom: theme.spacing(1)
    },
    content: {
        display: 'flex'
    },
    none: {
        color: theme.palette.grey.A100
    },
    flexChild: {
        minWidth: 0,
        flex: '0 1 100%',
        marginRight: theme.spacing(1)
    },
    grow: {
        minWidth: 0,
        flex: '1 1 auto'
    },
    flexFixed: {
        flex: '0 0 auto'
    },
    hourTotal: {
        fontSize: `${theme.atCustom.fontSize.title}px !important`,
        fontWeight: 600
    },
    disabled: {
        color: '#bdbdbd !important',
        cursor: 'not-allowed !important'
    }
};
