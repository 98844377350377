import { Role } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';
import { downloadsTemplate as angularJsTemplate } from '../templates';

export const appDownloads: IRoute = {
  name: 'app.downloads',
  stateDefinition: {
    url: '/downloads',
    template: angularJsTemplate,
    controller: 'DownloadsCtrl',
    data: { pageTitle: 'Downloads', PaidFunctionalityRequired: true }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: []
  }
};

export default appDownloads;
