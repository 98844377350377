import Category from './Category';
import Activity from './Activity';

export const teamPulseDataMap = (teamPulseData, filterMode) => {
    const idField = filterMode === 'computers' ? 'computerId' : 'userId';
    const nameField = filterMode === 'computers' ? 'computerName' : 'username';
    return teamPulseData.map((item) => {
        return {
            activeTime: item.activeTime,
            firstSeen: item.firstSeen,
            lastSeen: item.lastSeen,
            groups: item.groups,
            idleItem: item.idleTime,
            productiveActiveRatio: item.productiveActiveRatio,
            productiveTime: item.productiveTime,
            topActivities: item.topActivities?.map((a) => new Activity(a)),
            topCategories: item.topCategories?.map((c) => new Category(c)),
            totalTime: item.totalTime,
            productiveTotalRatio: item.productiveTotalRatio,
            id: item[idField] || '',
            name: item[nameField]
        };
    });
};
