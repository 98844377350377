import React, { useState } from 'react';
import ChartsJsBarChart from '../components/ChartsJsBarChart';
import ChartsJsPieChart from '../components/ChartsJsPieChart';
import TopUsersBarStacked from '../components/TopUsersBarStacked';
import TopUsersBarProgress from '../components/TopUsersBarProgress';
import ChartsJsPieChartDataLines from '../components/ChartsJsPieChartDataLines';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import CheckboxLegendPie from '../components/CheckboxLegendPie';
import { ChartDemoItem } from '../models/IChart';
import { TocItemSC } from '../styles/chart.styles';
import CheckboxLegendBar from '../components/CheckboxLegendBar';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

export default function ChartsDemo() {
  const [currentDemo, setCurrentDemo] = useState<ChartDemoItem>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List component="nav">
          <TocItemSC onClick={() => setCurrentDemo(ChartDemoItem.Timeline)}>
            Timelines (React Google Charts)
          </TocItemSC>
          <TocItemSC
            onClick={() => setCurrentDemo(ChartDemoItem.CheckboxLegendPie)}
          >
            Checkbox Legend - Pie (Charts.js)
          </TocItemSC>
          <TocItemSC
            onClick={() => setCurrentDemo(ChartDemoItem.CheckboxLegendBar)}
          >
            Checkbox Legend - Bar (Charts.js)
          </TocItemSC>
          <TocItemSC onClick={() => setCurrentDemo(ChartDemoItem.BarChart)}>
            Horizontal Stacked Bar Chart (Charts.js)
          </TocItemSC>
          <TocItemSC
            onClick={() => setCurrentDemo(ChartDemoItem.CustomLabelBarChart)}
          >
            Horizontal Stacked Bar Chart (Charts.js + ChartDataLabels)
          </TocItemSC>
          <TocItemSC
            onClick={() =>
              setCurrentDemo(ChartDemoItem.CustomLabelPluginBarChart)
            }
          >
            Horizontal Stacked Bar Chart (Charts.js + ChartDataLabels + Custom
            Plugin)
          </TocItemSC>
          <TocItemSC onClick={() => setCurrentDemo(ChartDemoItem.PieChart)}>
            Pie Chart (Charts.js)
          </TocItemSC>
          <TocItemSC
            onClick={() => setCurrentDemo(ChartDemoItem.CustomLabelPieChart)}
          >
            Pie Chart Outside Labels (Charts.js + Plugins)
          </TocItemSC>
        </List>
      </Grid>
      {currentDemo === ChartDemoItem.CheckboxLegendPie && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Checkbox Legend - Pie (Charts.js)
          </Typography>
          <div style={{ width: 400 }}>
            <CheckboxLegendPie />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.CheckboxLegendBar && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Checkbox Legend - Bar (Charts.js)
          </Typography>
          <div style={{ width: 400 }}>
            <CheckboxLegendBar />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.BarChart && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Horizontal Stacked Bar Chart (Charts.js)
          </Typography>
          <div style={{ width: 600 }}>
            <ChartsJsBarChart />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.CustomLabelBarChart && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Horizontal Stacked Bar Chart (Charts.js + ChartDataLabels)
          </Typography>
          <div style={{ width: 600 }}>
            <TopUsersBarStacked />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.CustomLabelPluginBarChart && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Horizontal Stacked Bar Chart (Charts.js + ChartDataLabels + Custom
            Plugin)
          </Typography>
          <div style={{ width: 600 }}>
            <TopUsersBarProgress />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.PieChart && (
        <Grid item xs={12}>
          <Typography variant="h4">Pie Chart (Charts.js)</Typography>
          <div style={{ width: 800 }}>
            <ChartsJsPieChart />
          </div>
        </Grid>
      )}
      {currentDemo === ChartDemoItem.CustomLabelPieChart && (
        <Grid item xs={12}>
          <Typography variant="h4">
            Pie Chart Outside Labels (Charts.js + Plugins)
          </Typography>
          <div style={{ width: 800 }}>
            <ChartsJsPieChartDataLines />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export const ChartsDemoComponent = () => (
  <ReactivTrakComponentWrapper>
    <ChartsDemo />
  </ReactivTrakComponentWrapper>
);
