import qs from 'qs';
import {
  IClassificationActivity,
  IClassificationActivities,
  IClassificationActivityDto,
  IClassificationActivitiesDto
} from '../../common/models';
import { getDefaultConfig, BaseClient } from '../../common/config';
import { mapToClassificationActivity } from '../../common/helpers';
import { ActivityType, ClassificationType } from '../../common/enums';
import { IClassificationService } from '../models';
import { RecentActivitiesProps } from '../../common/models/RecentActivitiesProps';

export default class ClassificationService
  extends BaseClient
  implements IClassificationService
{
  public getActivityById = async (
    id: number,
    type: ActivityType
  ): Promise<IClassificationActivity> => {
    const url = `/api/classification/activity?id=${id}&type=${type}`;

    const response = await this.apiService.get<IClassificationActivityDto>(
      url,
      {
        ...getDefaultConfig()
      }
    );
    return mapToClassificationActivity(response.data);
  };

  public getRecentActivities = async (
    params?: Partial<RecentActivitiesProps>
  ): Promise<IClassificationActivities> => {
    let url = '/api/classification/activity/recent';
    const { classified, term, categories, status, minDuration, limit } = params;

    const queryParams = {};
    queryParams['classified'] = classified;

    //Need this to trick the serializer
    if (categories?.length) {
      queryParams['categories'] = categories;
    }

    if (params.term?.length) {
      queryParams['term'] = term;
    }

    if (status && status !== ClassificationType.All) {
      const keys = Object.keys(ClassificationType).filter(
        (c) => ClassificationType[c] === status
      );
      if (keys?.length > 0) {
        queryParams['status'] = keys[0];
      }
    }

    if (minDuration || minDuration === 0)
      queryParams['minDuration'] = minDuration;

    if (limit) queryParams['limit'] = limit;

    if (queryParams) {
      const query = qs.stringify(queryParams);
      url += `?${query}`;
    }

    const response = await this.apiService.get<IClassificationActivitiesDto>(
      url,
      {
        ...getDefaultConfig()
      }
    );
    const data = response?.data;
    return {
      activities: data?.activities?.map(mapToClassificationActivity),
      count: data?.count
    };
  };

  public updateActivity = async (
    payload: IClassificationActivity[]
  ): Promise<any> => {
    return await this.apiService.post('/api/classification/activities', {
      ...getDefaultConfig(),
      ...{ data: payload }
    });
  };
}
