import React, { useState } from 'react';
import { AvailableDays, UserHealthModalProps } from '../../models';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import {
  UserHealthModalBody,
  UserHealthModalSubtitle
} from '../../styles/UserHealth.styles';
import { submitDashboardEvent } from '../../utils';
import { DashboardType } from '../../../common/enums';

export default function UserHealthModal(props: UserHealthModalProps) {
  const [modalChanged, setModalChanged] = useState<boolean>(false);
  const { activeSinceDays, setActiveSinceDays, setModalState, modalState } =
    props;
  const [modalValue, setModalValue] = useState<AvailableDays>(activeSinceDays);

  const handleChange = (e) => {
    setModalChanged(true);
    setModalValue(e.target.value);
  };

  const handleCancel = () => {
    setModalState(false);
    setModalChanged(false);
    setModalValue(activeSinceDays);
  };

  const handleSubmit = () => {
    setModalState(false);
    setModalChanged(false);

    if (modalValue !== activeSinceDays) {
      setActiveSinceDays(modalValue);
      const heapEventMetadata = { Term_Value: `${modalValue} days` };
      submitDashboardEvent(
        'Updated Inactive Term',
        heapEventMetadata,
        DashboardType.admin
      );
    }
  };

  const handleClose = () => {
    setModalChanged(false);
    setModalState(false);
  };

  return (
    <Dialog
      open={modalState}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClose();
      }}
      aria-labelledby="alert-dialog-modal"
      aria-describedby="alert-dialog-user-health-modal"
      maxWidth="sm"
    >
      <DialogTitle>Configure User Health</DialogTitle>
      <DialogContent>
        <UserHealthModalBody variant="body1">
          Inactivity is determined by the number of users that{' '}
          <strong>have been active in the last 30 days</strong> but inactive in
          the amount of days selected below.
        </UserHealthModalBody>
        <UserHealthModalSubtitle variant="h6">
          Select Inactive Term
        </UserHealthModalSubtitle>
        <FormControl>
          <Select
            labelId="user-health-config"
            value={modalValue || activeSinceDays}
            variant="outlined"
            onChange={handleChange}
            MenuProps={{ disablePortal: true }}
            // Fix to Breaking Top Border (CORE-2464)
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              width: '360px'
            }}
          >
            <MenuItem value={AvailableDays.ThreeDays}>Last 3 days</MenuItem>
            <MenuItem value={AvailableDays.SevenDays}>Last 7 days</MenuItem>
            <MenuItem value={AvailableDays.FourteenDays}>Last 14 days</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!modalChanged}
        >
          Set Inactive Term
        </Button>
      </DialogActions>
    </Dialog>
  );
}
