import React from 'react';
import { IGroupsState } from '../../models';
import { groupsService, useGroupsState } from './';

const { createContext, useContext } = React;

interface IGroupProvider {
  groupsState: IGroupsState;
}

const GroupsContext = createContext<IGroupProvider>(null);

type Props = {
  children: React.ReactNode;
};

export const GroupsProvider = (props: Props) => {
  const { children } = props;

  const service = groupsService();
  const groupsState = useGroupsState({ service });

  const value = {
    groupsState: groupsState
  };

  return (
    <GroupsContext.Provider value={value}>{children}</GroupsContext.Provider>
  );
};

export const useGroups = (): IGroupProvider => {
  return useContext(GroupsContext);
};
