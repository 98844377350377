import { isValidAcctId, isValidEmail, stringify } from '../../common/helpers';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';

export const selectIdProp = (accountSelector: string) => {
  return isValidEmail(accountSelector)
    ? { username: accountSelector }
    : { accountId: accountSelector };
};

export const createPayload = (
  identifier: { username?: string; accountId?: string },
  reason: string,
  tickets: string,
  isImpersonating: boolean
): Partial<IAddAccountPayloadDto> => {
  return {
    ...identifier,
    reason: stringify({
      description: reason,
      ticket: tickets
    }),
    impersonate: isImpersonating
  };
};

export const isValidAcctEmailOrId = (value: string) => {
  return isValidEmail(value) || isValidAcctId(value);
};

export const isInputError = (value: string, name?: string): boolean => {
  let errorPresent = false;
  if (name === 'accountSelector') {
    if (!isValidAcctEmailOrId(value)) {
      errorPresent = true;
    }
  } else if (value.length < 2) {
    errorPresent = true;
  }

  return errorPresent;
};
