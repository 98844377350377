import React from 'react';
import { Typography, Paper } from '@mui/material';
import { getClasses } from './CardProductiveTime.style';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { getRatio } from '../../../utilities/helpers';
import TimeMode from '../../../models/TimeMode';
import ReportMode from '../../../models/ReportMode';
import { hasRole } from '../../../utilities/userRoles';

const CardProductiveTime = (props) => {
    let {
        id,
        name,
        productiveTotalRatio,
        productiveActiveRatio,
        timeMode,
        loading,
        filterMode,
        filterModes,
        reportMode
    } = props;
    let ratio = getRatio(timeMode === TimeMode.Total ? productiveTotalRatio : productiveActiveRatio, 0);
    const classes = getClasses(ratio, loading);
    const isSupportAdvanced = hasRole('SupportAdvanced');

    const handleClick = () => {
        if (reportMode === ReportMode.Groups) {
            window.location.href = `/#/app/reports/topusers?groupId=${id}`;
        } else {
            let modeLabel = filterMode === filterModes.users ? 'user' : 'computer';
            window.location.href = `/#/app/reports/topusers?${modeLabel}=${encodeURIComponent(name)}`;
        }
    };

    const getTitle = () => {
        return reportMode === ReportMode.Groups ? 'Group Productivity' : 'Productive';
    };

    const setStyle = () => {
        return isSupportAdvanced ? { ...classes.disabled, ...classes.root } : classes.root;
    };


    return (
        <Paper elevation={2}>
            <SkeletonLoader loading={loading} className={classes.skeletonBar} height="66px" width="100%">
                <div
                    id="id_e44eb9b3-1b47-4309-b0c2-492cfd0765be"
                    style={setStyle()}
                    onClick={isSupportAdvanced ? undefined : handleClick}
                >
                    <Typography sx={classes.label} noWrap={true} align="center">{`${ratio}%`}</Typography>
                    <Typography sx={classes.sublabel} noWrap={true} align="center">
                        {getTitle()}
                    </Typography>
                </div>
            </SkeletonLoader>
        </Paper>
    );
};

export default CardProductiveTime;
