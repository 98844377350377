'use strict';
angular
    .module('app')
    .controller(
        'scheduledUsersMergeModalController',
        ScheduledUsersMergeModalController
    );

ScheduledUsersMergeModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'storageService',
    'messagesService',    
    'notificationService',
    'storagePageFunctionality',
];
function ScheduledUsersMergeModalController(
    $scope,
    $uibModalInstance,
    storageService,
    msg,
    notificationService,
    storagePageFunctionality,
) {
    var actualScheduledUserRow = {};

    var scheduledUsersDataSource = new kendo.data.TreeListDataSource({
        transport: {
            read: function (options) {
                storageService
                    .getScheduledMergesStorage()
                    .success(function (data) {
                        var formatedData = data ?  formatedData = formatDataForTree(data) : [];
                        options.success(formatedData);
                    });
            }
        },
        schema: {
            model: { 
                id: 'userid' ,
                expanded: true
            },
        }
    });

    var formatDataForTree = function (data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].primaryuserid == data[i].userid) {
                data[i].parentId = null;
                data[i].record = 'Destination';
            } else {
                data[i].parentId = data[i].primaryuserid;
                data[i].record = 'Source';
            }
        }
        return data;
    };

    $scope.scheduledUsersMerged = scheduledUsersDataSource;

    $scope.merge = function () {
        $uibModalInstance.close();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.scheduledUserSelectedRow = function (item) {
        actualScheduledUserRow = item;
    };
    
    $scope.scheduledUsersTreeOptions = {
        dataSource: $scope.scheduledUsersMerged,
        sortable: false,
        resizable: false,
        scrollable: false,
        columns: [
            {
                field: 'logondomain',
                title: 'Logon Domain'
            },
            {
                field: 'user',
                title: 'User'
            },
            {
                field: 'alias',
                title: 'User Alias'
            },
            {
                field: 'record',
                title: 'Record'
            },
            {
                title: '',
                template: `#if(!parentId) {#
                        <at-dropdown-button at-options="gridSettingsOptions" ng-click="scheduledUserSelectedRow(dataItem)"></at-dropdown-button>
                    #}#`,
                width: '30px'
            }
        ],
        messages: {
            noRows: "No merges scheduled"
          }
    };

    function setGridSettingsOptions() {
        $scope.gridSettingsOptions = {
            type: 'custom',
            iconText: 'more_vert',
            iconClass: 'material-icons',
            heapId: 'id_3741a366-c344-49cf-886b-8afecc02dcbd',
            selectionList: [
                {
                    heapId: 'id_6cc3b708-85d6-4fed-a0cd-0b2b9a7dcb6b',
                    text: 'cancelMerge',
                    value: 'Cancel Merge'
                }
            ],
            onChange: handleGridSettingsSelection
        };
    }
    setGridSettingsOptions();

    function handleGridSettingsSelection(selection) {
        if (selection.text === 'cancelMerge') {
            storageService
            .deleteScheduledMergeStorage(actualScheduledUserRow)
            .success(function () {
                scheduledUsersDataSource.remove(actualScheduledUserRow);
                storagePageFunctionality.refreshGrid();

                notificationService.showNotification(
                    msg.get('removeScheduledMerge'),
                    'success'
                );
            })
            .error(function (result) {
                if (result.error) {
                    notificationService.showNotification(
                        result.error,
                        'error'
                    );
                }
            });
        }
    }
}