'use strict';

angular
    .module('app')
    .directive('websitesAndApplicationsControls', websitesAndAppsControls);

function websitesAndAppsControls() {
    return {
        restrict: 'E',
        scope: {
            webSiteDataSource: '=',
            applicationDataSource: '=',
            selectedFilterOptionId: '=',
            isCategoriesPage: '=',
            disableDeleteSelected: '=',
            deleteSelected: '='
        },
        template: require('views/widgets/websitesAndApplicationsControls.html'),
        transclude: true,
        controller: WebsitesAndAppsControlsCtrl
    };
}

WebsitesAndAppsControlsCtrl.$inject = [
    '$scope',
    '$timeout',
    'Upload',
    'messagesService',
    'widgetsApiService',
    'envConfig',
    'pageSizeService'
];

var categoryServiceFunctions = require('_app/classification/categoryServiceFunctions').categoryServiceFunctions;

function WebsitesAndAppsControlsCtrl(
    $scope,
    $timeout,
    Upload,
    msg,
    widgetsApiService,
    envConfig,
    pageSizeService
) {
    $scope.showAddDomain = false;
    $scope.showAddApplication = false;
    $scope.showControls = true;
    $scope.uploadProgress = null;
    $scope.uploadingFile = null;

    $scope.fileType =
        navigator.platform.toUpperCase().indexOf('WIN') >= 0 ? '.exe' : '';

    $scope.addDomain = function (e) {
        e.preventDefault();
        $scope.showAddDomain = true;
        $scope.showControls = false;
    };

    $scope.addApplication = function (e) {
        e.preventDefault();
        $scope.showAddApplication = true;
        $scope.showControls = false;
    };

    $scope.cancelAddDomain = function () {
        $scope.newDomainName = '';
        $scope.showAddDomain = false;
        $scope.showControls = true;
    };

    $scope.cancelAddApplication = function () {
        $scope.newApplicationFile = null;
        $scope.showAddApplication = false;
        $scope.showControls = true;

        if ($scope.uploadingFile !== null) {
            $scope.uploadingFile.abort();
            $scope.uploadingFile = null;
        }

        $scope.uploadProgress = null;
    };

    $scope.submitAddDomain = function () {
        if (!$scope.newDomainName) {
            return;
        }

        var parsedDomain =
            $scope.newDomainName[$scope.newDomainName.length - 1] === '/'
                ? $scope.newDomainName.substring(
                      0,
                      $scope.newDomainName.length - 1
                  )
                : $scope.newDomainName;
        var categoryId;

        if (
            $scope.isCategoriesPage &&
            categoryServiceFunctions.isValidCategory(
                $scope.selectedFilterOptionId
            )
        ) {
            categoryId = $scope.selectedFilterOptionId;
        }

        var payload = {
            name: parsedDomain,
            categoryId: categoryId
        };

        if (
            !$scope.isCategoriesPage &&
            $scope.selectedFilterOptionId !==
                categoryServiceFunctions.categoryGuid.all
        ) {
            payload.productivity = $scope.selectedFilterOptionId;
        }

        widgetsApiService
            .addDomain(payload)
            .success(function () {
                $scope.$emit('showNotification', {
                    message: msg.get('domainAdded'),
                    color: 'success'
                });

                if (
                    $scope.isCategoriesPage &&
                    !categoryServiceFunctions.isValidCategory(
                        $scope.selectedFilterOptionId
                    )
                ) {
                    $scope.selectedFilterOptionId =
                        categoryServiceFunctions.categoryGuid.all;
                }

                $timeout(function () {
                    pageSizeService.dataSourceReload([
                        $scope.applicationDataSource,
                        $scope.webSiteDataSource
                    ]);
                });

                $scope.newDomainName = '';
                $scope.showAddDomain = false;
                $scope.showControls = true;
            })
            .error(function (result) {
                $scope.$emit('showNotification', {
                    message: result.error,
                    color: 'danger',
                    skipEscape: true
                });
                $scope.webSiteDataSource.filter({
                    filters: [
                        {
                            operator: 'contains',
                            value: payload.name,
                            field: 'domain'
                        }
                    ],
                    logic: 'and'
                });
            });
    };

    $scope.submitAddApplication = function () {
        var categoryId;

        if (
            $scope.isCategoriesPage &&
            categoryServiceFunctions.isValidCategory(
                $scope.selectedFilterOptionId
            )
        ) {
            categoryId = $scope.selectedFilterOptionId;
        }

        var payload = {
            file: $scope.newApplicationFile,
            categoryId: categoryId
        };

        if (
            !$scope.isCategoriesPage &&
            $scope.selectedFilterOptionId !==
                categoryServiceFunctions.categoryGuid.all
        ) {
            payload.productivity = $scope.selectedFilterOptionId;
        }

        $scope.uploadingFile = Upload.upload({
            url: envConfig.apiUrl() + '/api/settings/application',
            data: payload
        });

        $scope.uploadingFile
            .progress(function (evt) {
                if ($scope.uploadingFile !== null) {
                    $scope.uploadProgress = parseInt(
                        (100.0 * evt.loaded) / evt.total
                    );
                }
            })
            .success(function () {
                $scope.newApplicationFile = null;
                $scope.showAddApplication = false;
                $scope.showControls = true;
                $scope.uploadProgress = null;
                $scope.uploadingFile = null;
                $scope.$emit('showNotification', {
                    message: msg.get('applicationAdded'),
                    color: 'success'
                });

                if (
                    $scope.isCategoriesPage &&
                    !categoryServiceFunctions.isValidCategory(
                        $scope.selectedFilterOptionId
                    )
                ) {
                    $scope.selectedFilterOptionId =
                        categoryServiceFunctions.categoryGuid.all;
                }

                $timeout(function () {
                    pageSizeService.dataSourceReload([
                        $scope.applicationDataSource,
                        $scope.webSiteDataSource
                    ]);
                });
            })
            .error(function (result) {
                $scope.newApplicationFile = null;
                $scope.showAddApplication = false;
                $scope.showControls = true;
                $scope.uploadProgress = null;
                $scope.uploadingFile = null;
                if (result !== null) {
                    $scope.$emit('showNotification', {
                        message: result.error,
                        color: 'danger'
                    });
                }
            });
    };

    $scope.disableButton = function () {
        return $scope.deleteButtonTooltip !== '';
    };

    $scope.validateApplicationFile = function ($file) {
        if ($file.size > 50 * 1024 * 1024) {
            $scope.$emit('showNotification', {
                message: msg.get('thisAppFileIsTooLarge'),
                color: 'danger',
                timeout: 6000
            });
            return false;
        }
        return true;
    };

    $scope.getTooltip = {
        get: function () {
            return $scope.deleteButtonTooltip;
        }
    };

    $scope.$watch(
        function () {
            return $scope.disableDeleteSelected;
        },
        function (newValue) {
            $scope.deleteButtonTooltip = newValue;
        }
    );
}
