import { useCallback, useEffect, useState } from 'react';
import {
  fetchScreenshotFFLocalStorage,
  saveScreenshotFFLocalStorage
} from '../utils';
import { IRealTimeSettingsState, RealTimeSettingsStateProps } from '../models';


export const useRealTimeSettingsState = ({
  service,
  notificationService
}: RealTimeSettingsStateProps): IRealTimeSettingsState => {
  const { postRealTimeSettings } = service;

  //initial value comes from localStorage
  const [screenshotFeatureFlag, setScreenshotFeatureFlag] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  

  useEffect(() => {
    const hasFeatureFlag = fetchScreenshotFFLocalStorage();
    setScreenshotFeatureFlag(hasFeatureFlag);
  }, []);

  const updateRealTimeSettings = useCallback(
    async (value: boolean) => {
      setIsLoading(true);
      try {
        await postRealTimeSettings({ screenshotFeatureFlag: value });
        notificationService.success(
          'Success! Your Real Time Setting is Updated!'
        );
        saveScreenshotFFLocalStorage(value);
        {
          setScreenshotFeatureFlag(value);
        }
      } catch (error) {
        notificationService.error('Unable to update Real Time Settings');
        console.error(
          'ActivTrak Error: Unable to update Real Time Settings',
          error
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    screenshotFeatureFlag,
    isLoading,
    updateRealTimeSettings
  };
};
