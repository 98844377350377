'use strict';

angular.module('app').directive('atInsightsGoalBar', function () {
    return {
        restrict: 'E',
        scope: {
            dashboard: '=atDashboard'
        },
        template: require('views/reports/insights/atInsightsGoalBar.html'),
        controller: atInsightsGoalBarCtrl
    };
});

atInsightsGoalBarCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'envConfig',
    'notificationService',
    'atHelperFunctions',
    'authorizationService'
];

function atInsightsGoalBarCtrl(
    $scope,
    $rootScope,
    $state,
    envConfig,
    notificationService,
    atHelperFunctions,
    authorizationService
) {
    /**
     * Import needed classes
     */
    var { GoalControl } = require('_app/insights/models');
    var http = require('../../../../_app/http').http;

    /**
     * Initialize goal classes
     */
    function initializeGoalClasses() {
        // Get current menu item's goal settings from the sidebar
        var menuItem = $rootScope.sidebar.getMenuItem($state.current.name, $state.params);
        $scope.goalSettings = menuItem && menuItem.getGoalSettings() && $scope.dashboard.subPageId === 'team';
        $scope.isAdmin = authorizationService.hasRole([
            authorizationService.roles.admin,
            authorizationService.roles.configurator
        ]);
        $scope.canChangeGoals = $scope.isAdmin;

        // If current page has goal settings, initialize classes
        if ($scope.goalSettings) {
            $scope.getGroups();
            $scope.getMetrics();
            $scope.dashboard.embeddedFilterListener = $scope.embeddedFilterListener;

            $scope.initGoalControl = function () {
                if ($scope.groups === undefined || $scope.metrics === undefined) {
                    setTimeout($scope.initGoalControl);
                } else {
                    $scope.goalControl = new GoalControl(
                        envConfig,
                        notificationService.showNotification,
                        $scope.dashboard,
                        $scope.groups,
                        $scope.metrics,
                        $scope.safeApply
                    );
                }
            };
            $scope.initGoalControl();
        }
    }

    $scope.filters = null;
    $scope.embeddedFilterListener = function (filters) {
        if (!angular.equals($scope.filters, filters)) {
            if ($scope.goalControl) {
                $scope.goalControl.setGoalInput();
                var teamSelection = $scope.goalControl.getFilter('Team');
                var teamSetGoal = $scope.groups.find(function ({ name }) {
                    return name === teamSelection;
                });
                if (teamSetGoal) {
                    $scope.canChangeGoals = teamSetGoal.setGoals || $scope.isAdmin;
                }
                atHelperFunctions.safeApply($rootScope);
                $scope.filters = filters;
            }
        }
    };

    $scope.safeApply = function () {
        atHelperFunctions.safeApply($rootScope);
    };

    $scope.getGroups = function () {
        http.get(`${envConfig.apiUrl()}/api/insights/groups`)
            .then(function (results) {
                $scope.groups = results.data;
            })
            .catch(function (e) {
                console.error('ActiveTrak Error: Error fetching groups', e);
            });
    };

    $scope.getMetrics = function () {
        http.get(`${envConfig.apiUrl()}/api/insights/metrics/activinsights/benchmarks_and_goals`)
            .then(function (results) {
                $scope.metrics = results.data;
            })
            .catch(function (e) {
                console.error('ActiveTrak Error: Error fetching benchmarks_and_goals metrics', e);
            });
    };

    // Update goal dropdowns on navigation change
    var sidebarSubscription = $rootScope.sidebar.menu.subscribe(initializeGoalClasses);

    // Unsubscribe on destroy
    $scope.$on('$destroy', function () {
        sidebarSubscription.unsubscribe();
    });
}
