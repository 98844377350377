'use strict';

angular
    .module('app')
    .controller('userActivityModalController', userActivityModalController);

userActivityModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'activityData',
    'iconResolverServiceFunctions'
];

function userActivityModalController(
    $scope,
    $uibModalInstance,
    $timeout,
    activityData,
    iconResolverServiceFunctions
) {
    $scope.activityData = activityData;

    var startDate = '1900-01-01T' + activityData.start;
    var endDate = '1900-01-01T' + activityData.end;
    $scope.time =
        moment(startDate).format(activityData.timeFormat) +
        ' - ' +
        moment(endDate).format(activityData.timeFormat);
    // var user;
    // user = activityData.user;
    var realInterval = (moment(endDate) - moment(startDate)) / 1000 / 60;
    $scope.duration = activityData.intervalConst[realInterval];

    $scope.productivity = activityData.productivity;

    $scope.urlLabel = activityData.activity;
    $scope.urlOptions = {
        offsetHorizontal: -16,
        offsetVertical: 1,
        messageAlign: 'left',
        internalLink: {
            visible: true,
            stateName: 'app.reports.activitylog',
            icon: 'fa fa-info-circle'
        }
    };

    $scope.productivityTag = iconResolverServiceFunctions.getProductivityTag(
        activityData.productivity,
        false
    );

    var stateParams = {};
    stateParams[
        activityData.timelineType === 'computers' ? 'computer' : 'user'
    ] = activityData.user;

    if (activityData.activity && activityData.isWebsite) {
        stateParams.site = activityData.activity;
        $scope.urlOptions.externalLink = {
            visible: true,
            url: activityData.activity,
            icon: 'fa fa-external-link'
        };
        $scope.urlOptions.copyLink = {
            visible: true,
            icon: 'fa fa-copy'
        };
    } else {
        if (activityData.hasDescription) {
            stateParams.description = activityData.activity;
        } else {
            stateParams.exec = activityData.activity;
        }
    }

    $timeout(function () {
        $scope.urlOptions.fixedLeftOffset =
            angular.element('.longestActivity a>div').innerWidth() + 5;
    });

    $scope.urlOptions.internalLink.stateParams = stateParams;

    $scope.dismiss = function () {
        //$uibModalInstance.dismiss();
        $uibModalInstance.close('Close');
    };
}
