'use strict';

angular.module('app').directive('chart', chart);

function chart() {
    return {
        restrict: 'E',
        scope: {
            config: '=',
            chartId: '@',
            status: '=?'
        },
        template: function (elem, attr) {
            var templates = {
                bar: require('views/widgets/chart-bar.html'),
                column: require('views/widgets/chart-column.html'),
                column2: require('views/widgets/chart-column2.html'),
                pie: require('views/widgets/chart-pie.html'),
                radar: require('views/widgets/chart-radar.html'),
                stacked: require('views/widgets/chart-stacked.html')
            };

            return templates[attr.type];
        },
        controller: ChartCtrl
    };
}

ChartCtrl.$inject = ['$rootScope', '$scope', 'messagesService', '$timeout'];

function ChartCtrl($rootScope, $scope, msg, $timeout) {
    var lastRenderId = 0;

    function configChanged() {
        $scope.hasData = false;
        $scope.status = msg.get('loading');

        if ($scope.config === undefined) {
            return;
        }

        var currentRenderId = ++lastRenderId;
        $scope.config
            .getData()
            .success(function (data) {
                if (currentRenderId !== lastRenderId) {
                    // Don't render the chart if user requested another one while we were loading data.
                    return;
                }
                var result = data ? data.data || data.rows || data.items || data : [];
                result = data && data.hasOwnProperty('totalActivity') && data.totalActivity === 0 ? [] : result;
                $scope.hasData =
                    typeof $scope.config.checkData === 'function' ? $scope.config.checkData(data) : result.length > 0;
                $scope.status = $scope.hasData ? '' : msg.get('noItemsToDisplay', 'data');

                if ($scope.hasData && typeof $scope.config.buildChart === 'function') {
                    $scope.chart = null;
                    var newChart = $scope.config.buildChart(data);
                    if (newChart.delayUpdate) {
                        $timeout(function () {
                            $scope.chart = newChart;
                        });
                    } else {
                        $scope.chart = newChart;
                    }
                }
            })
            .error(function (result) {
                $scope.hasData = false;
                $scope.status = msg.get('noItemsToDisplay', 'data');
                if (result && result.message) {
                    $scope.$emit('showNotification', {
                        message: result.message,
                        color: 'danger'
                    });
                }
            });

        if (!$scope.config.preventResizeProcessing) {
            var chartRedraw = function () {
                var chart = angular.element('#' + $scope.chartId).data('kendoChart');
                if (chart !== undefined) {
                    chart.redraw();
                }
            };
            $scope.$on('sidebarToggled', chartRedraw);

            $rootScope.$on('atWindowResized', chartRedraw);
        }
    }

    $scope.isLoading = function () {
        return $scope.status === msg.get('loading');
    };

    $scope.$watch('config', function () {
        configChanged(false);
    });
}
