import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormGroup } from '@mui/material';
import { IInsightsCategory } from '../../models';
import {
  StyledFormControlLabel,
  TotCss
} from '../../styles/TimeOnTask.view.styles';

type CategoryCheckboxListProps = {
  filtered: IInsightsCategory[];
  checkedItems: string[];
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>;
};

const CategoryCheckboxList: React.FC<CategoryCheckboxListProps> = ({
  filtered,
  checkedItems,
  setCheckedItems
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  return (
    <Box sx={{ ...TotCss.scrollableBox, height: '165px' }}>
      <FormGroup>
        {filtered?.map((c) => (
          <StyledFormControlLabel
            key={c.categoryId}
            control={
              <Checkbox
                color="primary"
                checked={checkedItems.includes(c.categoryId)}
                value={c.categoryId}
                name={c.category}
                onChange={handleChange}
              />
            }
            label={c.category}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default CategoryCheckboxList;
