'use strict';

angular.module('app')
    .directive('computerReportHeader', ComputerReportHeader);

    function ComputerReportHeader() {
    return {
        restrict: 'E',
        scope: {},
        template: require('views/widgets/computerReportHeader.html'),
        controller: ComputerReportHeaderCtrl
    };
}

ComputerReportHeaderCtrl.$inject = ['$scope', '$state', 'computerReportService'];

function ComputerReportHeaderCtrl($scope, $state, computerReportService) {
    var pollDelay = 300000;
    var reportLoadTime;
    $scope.totalReported = '--';
    $scope.reportedLastTwoMonths = '--';
    $scope.reportedLastWeek = '--';
    $scope.reportedLastHour = '--';

    $scope.computerReportTooltip = '';

    function setNumber(number) {
        return (isNaN(number) ? 0 : number);
    }

    function addNewLine(string) {
        return string.length > 0 ? '<br/>' : '';
    }

    function addPlural(number) {
        return number > 1 ? 's' : '';
    }

    function setTooltip() {
        $scope.computerReportTooltip = '';
        if (!isNaN($scope.reportedLastHour)) {
            $scope.computerReportTooltip += addNewLine($scope.computerReportTooltip)  + $scope.reportedLastHour + ' computer' + addPlural($scope.reportedLastHour) + ' reported in the last hour.';
        }
        if (!isNaN($scope.reportedLastWeek)) {
            var computersStoppedReporting = $scope.reportedLastWeek < $scope.reportedLastTwoMonths;
            $scope.computerReportTooltip += addNewLine($scope.computerReportTooltip)  + '<span ' + (computersStoppedReporting ? 'class="text-danger">' : '>') + $scope.reportedLastWeek + '</span>' + ' computer' + addPlural($scope.reportedLastWeek) +
            ' reported last week.' + ( computersStoppedReporting ? '<br/><span class="text-danger">' + ($scope.reportedLastTwoMonths - $scope.reportedLastWeek) + '</span> less than last two months.' : '');
        }
        if (!isNaN($scope.reportedLastTwoMonths)) {
            $scope.computerReportTooltip += addNewLine($scope.computerReportTooltip)  + $scope.reportedLastTwoMonths + ' computer' + addPlural($scope.reportedLastTwoMonths) + ' reported in last two months';
        }
        if (!isNaN($scope.totalReported)) {
            $scope.computerReportTooltip += addNewLine($scope.computerReportTooltip)  + $scope.totalReported + ' computer' + addPlural($scope.totalReported) + ' reported in total';
        }
    }

    function getComputerReport() {
        computerReportService.getComputerReport().then(function(result) {
            $scope.computerReport = result.data;
            $scope.totalReported = setNumber(result.data.totalComputersReportedTillDate);
            $scope.reportedLastTwoMonths = setNumber(result.data.computersReportedLastTwoMonths);
            $scope.reportedLastWeek = setNumber(result.data.computersReportedLastWeek);
            $scope.reportedLastHour = setNumber(result.data.computersReportedInLastHour);
            reportLoadTime = moment();
            setTooltip();
        });
    }

    getComputerReport();

    $scope.$on('$stateChangeStart', function () {
        if (moment().diff(reportLoadTime) > pollDelay) {
            getComputerReport();
        }
    });
}
