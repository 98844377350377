import React, { useState, useEffect, HTMLAttributes } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import { BaseAutoCompleteSC } from '../../common/styles/app.component.styles';
import { FilterOptionsState } from '@mui/material';

const SEARCH_CHARS_MIN = 1;

type ClassificationAutoCompleteProps = {
  options: any[];
  onChange: (event: object, value: string, reason: string) => void;
  onInputChange: (event: object, value: string, reason: string) => void;
  currentValue: string;
  onKeyPress: (event: any) => void;
  filterOptions?: (
    options: any[],
    state: FilterOptionsState<any>,
    term?: string
  ) => any[];
};

export const ClassificationAutoComplete: React.FC<
  ClassificationAutoCompleteProps
> = ({ ...props }) => {
  const {
    options,
    onChange,
    onInputChange,
    onKeyPress,
    currentValue = '',
    filterOptions
  } = props;

  const [fieldValue, setFieldValue] = useState<string>();

  const autocompleteRef = React.createRef();

  useEffect(() => {
    if (typeof currentValue === 'string') setFieldValue(currentValue);
  }, [currentValue]);

  const handleInputChange = (event, value, reason) => {
    setFieldValue(value);
    onInputChange(event, value, reason);
  };

  const handleOnChange = (event, value, reason) => {
    let term = '';
    setFieldValue(value);
    if (value) {
      if (typeof value === 'string') {
        term = value;
      } else {
        term = value['name'];
      }
    }
    if (term) {
      onChange(event, value, reason);
    } else onChange(event, '', reason);
  };

  const currentOptions =
    !fieldValue || fieldValue.length < SEARCH_CHARS_MIN ? [] : options;

  return (
    <BaseAutoCompleteSC
      freeSolo
      value={currentValue}
      options={currentOptions}
      getOptionLabel={(option: any): string => {
        if (typeof option === 'string') {
          return option;
        } else if (option.inputValue) {
          return option.inputValue;
        } else {
          return option['name'];
        }
      }}
      onInputChange={handleInputChange}
      onKeyPress={onKeyPress}
      onChange={handleOnChange}
      selectOnFocus
      clearOnBlur
      filterOptions={filterOptions}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: any) => {
        let item: string;
        if (typeof option === 'string') {
          item = option;
        } else {
          item = option['name'];
        }

        return (
          <ListItem {...props} key={props.id}>
            {item}
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          placeholder="Search by Website or Application"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
      ref={autocompleteRef}
      data-testid="classifications-search"
    />
  );
};
