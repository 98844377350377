import { useEffect, useMemo, useState } from "react";

// FIXME: needs to sync with angular loaded
export const useUIRouterHistory = () => {
  const $injector = useMemo(() => window.angular
    .element(document.getElementById('core-app'))
    .injector(), []);

  const $state: any = useMemo(() => $injector.get('$state'), [$injector]);
  const $rootScope: any = useMemo(() => $injector.get('$rootScope'), [$injector]);
  const $stateParams: any = useMemo(() => $injector.get('$stateParams'), [$injector]);

  const [stateName, setStateName] = useState($state.current.name);
  const [stateParams, setStateParams] = useState<any>($stateParams);

  useEffect(() => {
    const unsubscribe = $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
      setStateName(toState.name);
      setStateParams(toParams);
    });

    return () => {
      unsubscribe();
    }
  }, [$rootScope]);

  return {
    push: (routeName: string, parameters?: any | undefined) => {

      //Ensure state is updated
      setStateName(routeName);
      setStateParams(parameters);

      $state.go(routeName, parameters);      
    },
      

    state: { name: stateName, params: stateParams }
  };
};
