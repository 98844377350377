import React, { useState, useEffect } from 'react';
import ListFilterProps from './ListFilter.props';
import { IListFilter } from '../../models';
import { Popover, List, ListItemButton, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export const ListFilter: React.FC<ListFilterProps> = ({ ...props }) => {
  const { listItems, onClick, defaultSelection, testId } = props;

  const [selected, setSelected] = useState(
    listItems && listItems[0] && listItems[0].label
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'list-filter-popover' : undefined;

  // defaultSelection allows for a filter coming from another location to be set
  useEffect(() => {
    setSelected(defaultSelection || selected);
  }, [defaultSelection, selected]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (item) => {
    setSelected(item.label);
    onClick(item);
    handleClose();
  };

  return listItems?.length ? (
    <>
      <Button
        onClick={handleClick}
        data-testid={testId}
        color="secondary"
        startIcon={<FilterListIcon />}
      >
        {selected}
      </Button>
      <Popover
        id={id}
        data-testid={`${testId}-popover`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List component="nav">
          {listItems.map((item: IListFilter) => (
            <ListItemButton
              key={item.label}
              selected={selected === item.label}
              onClick={() => handleListItemClick(item)}
            >
              {item.label}
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  ) : (
    <></>
  );
};
