import React, { HTMLAttributes } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ListItem from '@mui/material/ListItem';
import {
  AutocompleteSearchBarContainer,
  AutocompleteSC
} from './AutocompleteSearchBar.styles';

type ComponentProps = {
  autocompleteOptions: any[];
  searchLabelPlaceholder: string;
  onChange: (value: string) => void;
  onKeyPress?: (e: any) => void;
};

export const AutocompleteSearchBar = (props: ComponentProps) => {
  const {
    autocompleteOptions,
    searchLabelPlaceholder: placeholder,
    onChange,
    onKeyPress
  } = props;

  const handleOnSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value) {
      onChange(value);
    } else onChange('');
  };

  return (
    <AutocompleteSearchBarContainer>
      <AutocompleteSC
        freeSolo
        options={autocompleteOptions}
        onInputChange={handleOnSearchChange}
        getOptionLabel={(option: any) => {
          return typeof option === 'object' ? option.label : option;
        }}
        groupBy={(option: any) => option.typeName}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: any) => {
          let item: string;
          if (typeof option === 'string') {
            item = option;
          } else {
            item = option.label;
          }

          return (
            <ListItem {...props} key={props.id}>
              {item}
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            onKeyPressCapture={onKeyPress}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </AutocompleteSearchBarContainer>
  );
};
