import React from 'react';
import { groupService, useGroupState } from './';
import { IGroupState } from '../models';

const { createContext, useContext } = React;

interface IGroupProvider {
  groupState: IGroupState;
}

const GroupContext = createContext<IGroupProvider>(null);

type Props = {
  children: React.ReactNode;
};

export const GroupProvider = (props: Props) => {
  const { children } = props;

  const service = groupService();
  const groupState = useGroupState({ service });

  const value = {
    groupState: groupState
  };

  return (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  );
};

export const useGroup = (): IGroupProvider => {
  return useContext(GroupContext);
};
