import { styled } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const CloudDownloadIconSC = styled(CloudDownloadIcon)``;

export const DownloadAgentRowOption = styled('div')`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;
