import { react2angular } from 'react2angular';
import { VideosReportViewComponent } from '../../../../_reactivtrak/src/reports/videos-report/views/VideosReport.view';
import { videosTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedVideosReport', {
    template: videosTemplate,
    controller: 'VideosCtrl'
});

angular.module('app').component('videosReport', react2angular(VideosReportViewComponent, []));
