import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const ImpactIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Impact';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M9.33333 2.18182H2.66667C1.2 2.18182 0 3.16364 0 4.36364V19.6364C0 20.8364 1.2 21.8182 2.66667 21.8182H9.33333V24H12V0H9.33333V2.18182ZM9.33333 18.5455H2.66667L9.33333 12V18.5455ZM21.3333 2.18182H14.6667V4.36364H21.3333V18.5455L14.6667 12V21.8182H21.3333C22.8 21.8182 24 20.8364 24 19.6364V4.36364C24 3.16364 22.8 2.18182 21.3333 2.18182Z"
      />
    </SvgIconWrapper>
  );
};
