import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const TeamPulseIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'TeamPulse';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M1.26316 24H22.7368C23.4316 24 24 23.4 24 22.6667V21.3333C24 20.6 23.4316 20 22.7368 20H1.26316C0.568421 20 0 20.6 0 21.3333V22.6667C0 23.4 0.568421 24 1.26316 24ZM22.7368 6.66667H1.26316C0.568421 6.66667 0 7.26667 0 8V16C0 16.7333 0.568421 17.3333 1.26316 17.3333H22.7368C23.4316 17.3333 24 16.7333 24 16V8C24 7.26667 23.4316 6.66667 22.7368 6.66667ZM0 1.33333V2.66667C0 3.4 0.568421 4 1.26316 4H22.7368C23.4316 4 24 3.4 24 2.66667V1.33333C24 0.6 23.4316 0 22.7368 0H1.26316C0.568421 0 0 0.6 0 1.33333Z"
      />
    </SvgIconWrapper>
  );
};
