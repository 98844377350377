import { DateRanges } from '../../../../../components/ReportFilters/constants/dateRanges';
import { setReportFilters } from '../../../../../components/ReportFilters/hooks/reportFiltersStore';
import { createDatesObject } from '../../../../../components/ReportFilters/utils/dateRange.utils';
import { IRoute } from '../../../../../models/IRoute';

export const appReports: IRoute = {
  name: 'app.reports',
  stateDefinition: {
    abstract: true,
    template: '<ui-view/>',
    url: '/reports?userFilter&dateFilter',
    params: { range: null },
    resolve: {
      filters: [
        '$state',
        function ($state) {

          const { _params: params } =
            $state.transition._targetState;
          if (params.userFilter === 'allusers') {
            setReportFilters({
              users: [
                {
                  userId: -1,
                  userType: 'AllUsers',
                  name: 'All Users',
                  filterMode: 'users',
                  groupType: 'user'
                }
              ]
            });
          }
          // TODO: combine these two query string params into a single dateRange param
          if (params.dateFilter === 'today' || params.range) {
            const range: DateRanges = Object.values(DateRanges).find(
              (dateRange) => dateRange === params.range
            )
              ? params.range
              : DateRanges.Today;
            setReportFilters({
              dates: createDatesObject(range)
            });
          }
        }
      ]
    }
  }
};

export default appReports;
