'use strict';

angular.module('app').directive('exportProgressBar', ExportProgressBar);

function ExportProgressBar() {
    return {
        restrict: 'E',
        scope: false,
        template: require('views/widgets/exportProgressBar.html'),
        controller: ExportProgressBarCtrl
    };
}

ExportProgressBarCtrl.$inject = [
    '$scope',
    '$timeout',
    'exportProgressService',
    'tabNotificationService',
    'widgetsApiService',
    'messagesService'
];

function ExportProgressBarCtrl(
    $scope,
    $timeout,
    exportProgressService,
    tabNotificationService,
    widgetsApiService,
    msg
) {
    function setStatusText() {
        if ($scope.statusText === 'Canceling your export request...') {
            return;
        }

        if ($scope.reportName === 'screenshots') {
            $scope.statusText = 'Exporting your screenshots';
        } else if ($scope.reportName) {
            $scope.statusText = 'Generating your ' + $scope.reportName + ' file';
        } else {
            $scope.statusText = 'Initializing your export request...';
        }

        $scope.exportProgress = exportProgressService.exportProgress();
        $scope.exportProgressWidth = exportProgressService.exportProgressWidth();
    }

    function displayNotification(url) {
        var msgName =
            $scope.reportName === 'screenshots' || $scope.reportName === 'video'
                ? 'exportToGoogleDriveFinished'
                : 'exportToGoogleSheetsFinished';
        $scope.$emit('showNotification', {
            message: msg.get(msgName, url.downloadUrl || url),
            color: 'info',
            timeout: 0,
            skipEscape: true
        });
    }

    var pollExportProgress = function (pollExportId) {
        if (widgetsApiService.isFEAPIExport($scope.reportName) || $scope.reportName === 'screenshots') {
            if (pollExportId !== $scope.exportId || exportProgressService.getCancelInProgress() === pollExportId) {
                $scope.showProgressBar = false;
                exportProgressService.clearExportProgress();
                return;
            }
            widgetsApiService
                .getExportStatus($scope.reportName, $scope.exportId)
                .success(function (result) {
                    progressUpdate(result, pollExportId);
                    if (result.progress === 100 && (result.status === 1 || result.status === 'done')) {
                        if (exportProgressService.completeCallbackType() === 'google') {
                            exportProgressService.completeCallback(displayNotification);
                        }
                        exportProgressService.completeExport(result.downloadUrl);
                    } else {
                        $timeout(pollExportProgress, 1000, true, pollExportId);
                    }
                })
                .error(function (result) {
                    var message = result.error || msg.get('exportError');
                    $scope.$emit('showNotification', { message: message, color: 'danger', timeout: 0 });
                    tabNotificationService.notify('Export failed');
                    exportProgressService.clearExportProgress();
                });
        } else {
            if (exportProgressService.getCancelInProgress()) {
                return;
            }
            progressUpdate({ progress: exportProgressService.exportProgress() }, pollExportId);
            var exportData = exportProgressService.exportData();
            if (exportData) {
                if (!exportData.error) {
                    if (exportProgressService.completeCallbackType() === 'google') {
                        exportProgressService.completeCallback(displayNotification);
                        exportProgressService.completeExport(exportProgressService.downloadUrl());
                    } else {
                        var disposition = exportData.headers('content-disposition');
                        var fileName = getFileName(disposition) || $scope.reportName() + '.csv';
                        var a = document.createElement('a');
                        var url = window.URL.createObjectURL(
                            new Blob([exportData.data], { type: 'data:attachment/csv;charset=utf-8' })
                        );
                        Object.assign(a, { href: url, target: '_blank', download: fileName });
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                    }
                } else {
                    $scope.$emit('showNotification', {
                        message: msg.get('exportError'),
                        color: 'danger',
                        timeout: 0
                    });
                }
                exportProgressService.clearExportProgress();
            }
        }
    };

    function getFileName(disposition) {
        var utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
        var asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

        var fileName = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
        } else {
            var filenameStart = disposition.toLowerCase().indexOf('filename=');
            if (filenameStart >= 0) {
                var partialDisposition = disposition.slice(filenameStart);
                var matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }
        return fileName;
    }

    function progressUpdate(result, pollExportId) {
        if (pollExportId !== $scope.exportId) {
            $scope.showProgressBar = false;
        }

        $scope.showProgressBar = true;
        if (result.progress > 1) {
            exportProgressService.realProgress(true);
        }

        var relativeProgress = 75 + Math.round((result.progress / 100) * 25);

        if (exportProgressService.realProgress()) {
            if (relativeProgress > exportProgressService.exportProgress()) {
                $scope.exportProgress = exportProgressService.exportProgress(relativeProgress);
                $scope.exportProgressWidth = exportProgressService.exportProgressWidth();
            }
        } else {
            $scope.exportProgress = exportProgressService.exportProgress();
            $scope.exportProgressWidth = exportProgressService.exportProgressWidth();
        }
    }

    $scope.sendCancelRequest = function () {
        exportProgressService.cancelInProgress($scope.exportId);

        if (widgetsApiService.isFEAPIExport($scope.reportName)) {
            widgetsApiService.cancelExport($scope.reportName, $scope.exportId).then(
                function () {
                    exportProgressService.cancelInProgress(undefined);
                },
                function (result) {
                    var message = result.error || msg.get('exportError');
                    $scope.$emit('showNotification', { message: message, color: 'danger', timeout: 0 });
                    exportProgressService.cancelInProgress(undefined);
                }
            );
        }
        exportProgressService.clearExportProgress();
    };

    $scope.cancelExport = function cancelRetry() {
        if (!$scope.exportId && $scope.showProgressBar) {
            $timeout(cancelRetry, 500);
            $scope.statusText = 'Canceling your export request...';
            return;
        }

        $scope.sendCancelRequest();
    };

    $scope.$watch(
        function () {
            return exportProgressService.exportId();
        },
        function (newExportId) {
            if (newExportId !== $scope.exportId) {
                $scope.exportId = newExportId;
                $scope.reportName = exportProgressService.exportName();
                setStatusText();
                $scope.exportProgress = exportProgressService.exportProgress();
                $scope.exportProgressWidth = exportProgressService.exportProgressWidth();

                if (!!$scope.exportId) {
                    pollExportProgress($scope.exportId);
                }
            }
        }
    );

    $scope.$watch(
        function () {
            return exportProgressService.showProgressBar();
        },
        function (showProgressBar) {
            $scope.statusText = showProgressBar ? $scope.statusText : '';
            setStatusText();
            $scope.showProgressBar = showProgressBar;
        }
    );
}
