import { ProductivityStatus } from '../enums';

export enum DropdownElement {
  Select,
  Menu
}

export const defaultProductivityStatus = {
  id: ProductivityStatus?.Undefined,
  name: 'Select Status'
};

export const productivityStatuses = [
  {
    id: ProductivityStatus?.Productive,
    name: 'Productive'
  },
  {
    id: ProductivityStatus?.Unproductive,
    name: 'Unproductive'
  }
];
