'use strict';

angular.module('app').directive('breadcrumbs', function () {
    return {
        restrict: 'E',
        template: require('views/widgets/breadcrumbs.html'),
        replace: true,
        scope: {
            itemName: '@', // used for individual record pages, e.g. to show a schedule's name
            links: '<'
        },
        link: function (scope, elem, attrs) {
            // observe itemName only on routes that need it
            // (e.g. ScheduleCtrl sets it to empty String therefore the type is not undefined)
            if (typeof attrs.itemName !== 'undefined') {
                attrs.$observe(
                    'itemName',
                    function () {} /* w/o this function Angular throws an error */
                );
            }
        },
        controller: [
            '$rootScope',
            '$state',
            '$scope',
            '$transitions',
            '$window',
            'atHelperFunctions',
            function (
                $rootScope,
                $state,
                $scope,
                $transitions,
                $window,
                atHelperFunctions
            ) {
                var setTitle = function () {
                    if ($state.current && $state.current.data) {
                        if (
                            !!$state.current.data.shortTitle &&
                            $state.current.data.shortTitle.maxSize >
                            $window.innerWidth
                        ) {
                            $scope.title = $state.current.data.shortTitle.title;
                        } else {
                            $scope.title = $state.current.data.pageTitle;
                        }
                        atHelperFunctions.updateTitle($rootScope, $state);
                        $scope.isAlpha = $state.current.data.isAlpha;
                        $scope.isBeta = $state.current.data.isBeta;
                        $scope.isDemo = $state.current.data.isDemo;
                        $scope.tag = $state.current.data.tag;
                        $scope.tagColor = $state.current.data.color;
                        $scope.showTag = !atHelperFunctions.isEmpty($state.title);
                    }
                };
                setTitle();

                $scope.$watch(
                    function () {
                        return $window.innerWidth;
                    },
                    function () {
                        setTitle();
                    }
                );

                $scope.$watch(
                    function () {
                        return $state.current.data;
                    },
                    function () {
                        setTitle();
                    },
                    true
                );

                $transitions.onSuccess({}, function () {
                    setTitle();
                });
            }
        ]
    };
});
