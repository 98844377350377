import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export default function ArrowLeftIcon(props: SvgIconProps) {
  const height = props.height ?? 7;
  const width = props.width ?? 16;
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';

  let classList = 'at-icon-arrow at-icon-arrow-left';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 8"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
    >
      <path
        d="M3.99 4.99992L15 4.99992C15.55 4.99992 16 4.54992 16 3.99992C16 3.44992 15.55 2.99992 15 2.99992L3.99 2.99992V1.20992C3.99 0.759923 3.45 0.539922 3.14 0.859922L0.360001 3.64992C0.170001 3.84992 0.170001 4.15992 0.360001 4.35992L3.14 7.14992C3.45 7.46992 3.99 7.23992 3.99 6.79992V4.99992Z"
        fill={fillColor}
      />
    </svg>
  );
}
