import React from 'react';
import { LinkProps } from './Link.props';
import Link from './Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { LinkIcon, LinkWithIconTitle } from './Link.styles';

export const LinkWithIcon: React.FC<LinkProps> = ({
  redirectTo,
  redirectParams,
  text,
  id,
  disabled,
  sx
}) => {
  return (
    <Link
      to={redirectTo}
      params={redirectParams}
      id={id || ''}
      underline="none"
      disabled={disabled}
      sx={sx || {}}
    >
      <LinkWithIconTitle>{text}</LinkWithIconTitle>
      <LinkIcon>
        <LaunchIcon />
      </LinkIcon>
    </Link>
  );
};
