import React from 'react';
import Box from '@mui/material/Box';
import CategoryDropdown from './CategoryDropdown';
import ProductivityDropdown from './ProductivityDropdown';
import { ICategory } from '../../common/models';
import { classificationClasses } from '../styles/Classifications.styles';

interface IClassificationBulkActivitiesProps {
  onBulkUpdateActivityChange: (event: any) => void;
  categories: ICategory[];
  isDisabled: boolean;
  isDisabledProductivity?: boolean;
}

export default function ClassificationBulkActivities(
  props: IClassificationBulkActivitiesProps
) {
  const {
    onBulkUpdateActivityChange,
    categories,
    isDisabled,
    isDisabledProductivity
  } = props;

  return (
    <>
      {categories && categories.length > 0 && (
        <CategoryDropdown
          onChange={(e) => onBulkUpdateActivityChange(e)}
          categories={categories}
          isDisabled={isDisabled}
          variant="standard"
          title="Assign Category"
        />
      )}
      <Box component="span" marginLeft="20px">
        <ProductivityDropdown
          onChange={(e) => onBulkUpdateActivityChange(e)}
          isDisabled={isDisabled || isDisabledProductivity}
          className={classificationClasses.productivityBtn}
          variant="standard"
          title="Assign Status"
        />
      </Box>
    </>
  );
}
