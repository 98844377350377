import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';

angular.module('signupLogin', ['ui.router', 'ui.router.state.events', 'utils', 'ui.bootstrap']);
angular.module('signupLogin').controller('SignupLoginCtrl', SignupLoginCtrl);

SignupLoginCtrl.$inject = ['$state', '$stateParams', 'loginService', 'localStorageService'];

function SignupLoginCtrl($state, $stateParams, loginService, localStorageService) {
    // Ensure global environment is clean
    loginService.clearScope();

    var token = $stateParams.key;

    if (!token) {
        processError('ActivTrak Error: Unable to login to new account due to missing token.\nToken: ' + token);
        return;
    }

    userServiceFunctions.setUserToken(token);
    var username = userServiceFunctions.getUserName();
    var accountId = userServiceFunctions.getAccountId();

    function processError(errorMessage) {
        console.error(errorMessage);
        loginService.logout({
            logoutReason:
                'Unable to connect to your account. <br/> For help, <a target="_blank" href="#/app/help/tickets">contact support</a>'
        });
    }

    if (!username || !accountId) {
        processError(
            'ActivTrak Error: Unable to login to new account due to missing account id, or username.\nToken: ' +
                '\nAccount Id: ' +
                accountId +
                '\nUsername: ' +
                username
        );
        return;
    }

    var expired = moment().diff(userServiceFunctions.getTokenExpiration(), 'seconds') > 0;
    if (expired) {
        processError('ActivTrak Error: Unable to login to new account due to an expired token');
        return;
    }

    var inError = loginService.setGlobals({
        currentUser: {
            token,
            username,
            isFirstLogin: true
        }
    });

    if (inError) {
        processError('ActivTrak Error: Unable to login to new account due to ' + inError);
        return;
    }

    localStorageService.set('newAccount_' + accountId, true);

    // added to control wizard shown when doing regression tests.
    var showWizardOverride = localStorageService.get('show_onboarding_wizard');

    if (isAccountWizardAccount(showWizardOverride)) {
        loginService.enableAccountWizard();
        window.location = '/#/app/account/wizard?firstVisit=true';
    } else {
        $state.go('app.dashboard');
    }
}

var isAccountWizardAccount = function (showWizardOverride) {
    switch (showWizardOverride) {
        case 'false':
            return false;
        default:
            return true;
    }
};
