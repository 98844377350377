import React, { createContext, useContext } from 'react';
import { IRoleAccessService } from '../../common/models';
import RoleAccessService from './RoleAccessService';
import { GetRolesService, IGetRolesService } from '../../common/services';

const RoleAccessContext = createContext<IRoleAccessProvider>(null);
interface IRoleAccessProvider {
  roleAccessService: IRoleAccessService;
  getRolesService: IGetRolesService;
}

type Props = {
  children: React.ReactNode;
  roleAccessService?: IRoleAccessService;
  getRolesService?: IGetRolesService;
};

export const RoleAccessProvider = (props: Props) => {
  const { children, roleAccessService, getRolesService } = props;

  const value = {
    roleAccessService: roleAccessService || new RoleAccessService(),
    getRolesService: getRolesService || new GetRolesService()
  };

  return (
    <RoleAccessContext.Provider value={value}>
      {children}
    </RoleAccessContext.Provider>
  );
};

export const useRoleAccess = (): IRoleAccessProvider => {
  return useContext(RoleAccessContext);
};
