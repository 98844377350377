import React, { useEffect } from 'react';
import { Dialog, Stack } from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { setLocalStorageItem } from '../../common/helpers';
import {
  SupportPortalService,
  useRedirect,
  useSupportPortalState
} from '../hooks';
import { AccountCard, LoadingModal, RedirectingModal } from '.';
import { notificationMessage, supportPortalLocalStorage } from '../constants';
import { ICardsContainerProps } from '../models/IComponentProps';
import { INewlyAddedAccount } from '../models/IAccountData';
import { isDuplicate, handleDuplicateAccount } from '../utils';

export const CardsContainer = (props: ICardsContainerProps) => {
  const { actionType, payload, savedAccounts, setSavedAccounts } = props;

  const notificationService = useNotifications();
  const service = SupportPortalService();
  const { fetchDetailsForAccountCard, isLoading } = useSupportPortalState({
    service
  });

  const { impersonateAndRedirect, isRedirecting, setIsRedirecting } =
    useRedirect();

  useEffect(() => {
    if (payload) {
      (async () => {
        if (actionType === 'redirect') {
          setIsRedirecting(true);
        }

        try {
          const newAccount: INewlyAddedAccount =
            await fetchDetailsForAccountCard(payload);
          const newAccountId: string = newAccount.accountId;

          if (isDuplicate(savedAccounts, newAccountId)) {
            handleDuplicateAccount(notificationService, newAccountId);
            return;
          } else
            setLocalStorageItem(
              supportPortalLocalStorage.accounts,
              JSON.stringify([...savedAccounts, newAccount])
            );

          if (actionType === 'redirect') {
            impersonateAndRedirect(newAccount);
          } else if (actionType === 'add') {
            addAccountToPortal(newAccount);
          }
        } catch (error) {
          notificationService.error(notificationMessage.accountAddedErrorAlert);
          console.error(notificationMessage.accountAddedErrorAlert, error);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const addAccountToPortal = (newAccount: INewlyAddedAccount) => {
    setSavedAccounts((savedAccounts) => [...savedAccounts, newAccount]);
    notificationService.success(notificationMessage.accountAddedSuccess);
  };

  const removeAccount = (accountId: string) => {
    const updatedAccountsDisplayed = savedAccounts.filter(
      (account) => account.accountId !== accountId
    );
    setLocalStorageItem(
      supportPortalLocalStorage.accounts,
      JSON.stringify(updatedAccountsDisplayed)
    );
    setSavedAccounts(updatedAccountsDisplayed);
  };

  return (
    <>
      {savedAccounts.length > 0 ? (
        <Stack direction="row" flexWrap="wrap" sx={{ width: '70%', gap: 2 }}>
          {savedAccounts.map((account) => (
            <AccountCard
              key={account.accountId}
              account={account}
              onRemove={removeAccount}
            />
          ))}
        </Stack>
      ) : (
        <></>
      )}
      <Dialog open={isLoading}>
        <LoadingModal />
      </Dialog>
      <Dialog open={isRedirecting}>
        <RedirectingModal />
      </Dialog>
    </>
  );
};
