import Category from './Category';
import Activity from './Activity';
import GroupProperties from './GroupProperties';

class GroupPulse {
  constructor(data = {}) {
    this.avgActiveTime = data.avgActiveTime;
    this.avgProductiveTime = data.avgProductiveTime;
    this.avgTotalTime = data.avgTotalTime;
    this.id = data.id;
    this.name = data.name;
    this.productiveTotalRatio = data.productiveTotalRatio;
    this.productiveActiveRatio = data.productiveActiveRatio;
    this.topActivities =
      (data.topActivities && data.topActivities.map((a) => new Activity(a))) ||
      [];
    this.topCategory =
      (data.topCategory && data.topCategory.map((c) => new Category(c))) || [];
    this.topCategoryTotalTimeRatio = data.topCategoryTotalTimeRatio;
    this.topEfficientUsers = data.topEfficientUsers || [];
    this.topProductiveUsers = data.topProductiveUsers || [];
    this.userPulses = data.userPulses || [];
    this.activeCount = 0;
    this.passiveCount = 0;
    this.inactiveCount = 0;
    this.groupProperties = new GroupProperties(data.type, data.mix);
  }

  isActiveToday() {
    return this.avgProductiveTime > 0;
  }

  isInactiveToday() {
    return !this.isActiveToday();
  }

  isEmpty() {
    return this.userPulses.length <= 0;
  }
}

export default GroupPulse;
