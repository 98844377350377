import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { useOauthState } from '../hooks/useOauthState';
import { GoogleWorkspaceIcon, SettingsIcon } from '../../common/assets/Icons';
import { ConfigModalProps, IIntegrationConfigurationDto } from '../models';
import { IntegrationNotifications } from '../constants/IntegrationNotifications';
import {
  IntegrationCodes,
  IntegrationTypeCodes
} from '../constants/IntegrationCodes';
import {
  addIntegrationConfiguration,
  deleteIntegrationInstance
} from '../utils/integrationInstance.utils';
import { DOMAIN } from '../constants/configuration';
import { GoogleCalendarValidationMessages } from '../constants/IntegrationConfigurationValidationMessages';
import { validDomain } from '../../common/helpers';
import { clientOAuthConfig } from '../constants/Oauth';
import { mediumFontWeight } from '../../common/constants';

export const ConfigureGoogleCalendarModal = ({
  setOpenConfigDialog,
  configState
}: ConfigModalProps) => {
  const { isLoading, authorized, startOauthFlow, focusPopup } = useOauthState({
    clientOAuthConfig: clientOAuthConfig[IntegrationCodes.GoogleCalendar]
  });

  const [domain, setDomain] = useState('');

  const onDomainChange = useCallback((event) => {
    setDomain(event.target?.value);
  }, []);

  const handleOauth = useCallback(async () => {
    await configState.initiateOauth(
      IntegrationCodes.GoogleCalendar,
      startOauthFlow,
      configState.traySolutionInstanceId,
      configState.instanceId
    );
  }, [
    startOauthFlow,
    configState.traySolutionInstanceId,
    configState.instanceId
  ]);

  const handleSubmit = useCallback(async () => {
    try {
      const configurations: IIntegrationConfigurationDto[] = [];
      if (!authorized) {
        configState.setIntegrationConfigurationNotification({
          msg: GoogleCalendarValidationMessages.AuthorizationRequired,
          type: 'error'
        });
        return;
      }
      if (!domain) {
        configState.setIntegrationConfigurationNotification({
          msg: GoogleCalendarValidationMessages.DomainRequired,
          type: 'error'
        });
        return;
      }

      if (!validDomain(domain)) {
        configState.setIntegrationConfigurationNotification({
          msg: GoogleCalendarValidationMessages.DomainInvalid,
          type: 'error'
        });
        return;
      }

      const configurationDomain: IIntegrationConfigurationDto = {
        instanceid: configState.instanceId,
        integrationcode: IntegrationCodes.GoogleCalendar,
        name: DOMAIN,
        value: domain,
        typecode: IntegrationTypeCodes.String
      };
      configurations.push(configurationDomain);

      await Promise.all(
        configurations.map((config) => addIntegrationConfiguration(config))
      );

      await configState.enableIntegration(
        configState.instanceId,
        IntegrationNotifications.Initiated
      );
    } catch (error) {
      configState.setIntegrationConfigurationNotification({
        msg: IntegrationNotifications.GenericError,
        type: 'error'
      });
      console.error(
        `ActivTrak Error: ${IntegrationNotifications.GenericError} - ${error?.message}`
      );
    }
    if (configState.trayMigrationInProgress)
      configState.setTrayMigrationInProgress(false);
    setOpenConfigDialog(false);
  }, [authorized, configState, domain, setOpenConfigDialog]);

  const handleCancel = useCallback(() => {
    if (authorized || configState.instanceId) {
      deleteIntegrationInstance(configState.instanceId);
    }

    configState.trayMigrationInProgress
      ? configState.setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.MigrationCancelled,
          type: 'success'
        })
      : configState.setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Cancelled,
          type: 'success'
        });

    if (configState.trayMigrationInProgress)
      configState.setTrayMigrationInProgress(false);
    configState.setInstanceId(null);
    setOpenConfigDialog(false);
  }, [authorized, configState, setOpenConfigDialog]);

  return (
    <>
      <DialogContent onClick={isLoading ? focusPopup : undefined}>
        {!authorized && (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ paddingBottom: '10px', fontWeight: mediumFontWeight }}
            >
              Please gather all required information before proceeding.
              Incorrect or missing information may cause integration error.
            </Typography>
            <Button
              variant="outlined"
              sx={{ marginRight: '20px' }}
              onClick={handleOauth}
              disabled={isLoading || configState.isDeletingTrayInstance}
              startIcon={<GoogleWorkspaceIcon />}
            >
              Authenticate with Google
            </Button>
            {(isLoading || configState.isDeletingTrayInstance) && (
              <CircularProgress size={20} />
            )}
          </Box>
        )}
        {authorized && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1">
                Calendar Integration authorized.
              </Typography>
            </Grid>
            <Grid item>
              <a
                href="https://myaccount.google.com/u/0/permissions"
                target="_blank"
                rel="noreferrer"
              >
                <SettingsIcon />
              </a>
            </Grid>
          </Grid>
        )}
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1">Enter your calendar domain</Typography>
          <Stack direction="column" spacing={3} marginTop={3}>
            <TextField
              label="Domain"
              required
              variant="outlined"
              onChange={onDomainChange}
              value={domain}
              fullWidth
              sx={{ mb: 3 }}
              color="primary"
              inputProps={{ maxLength: 1000 }}
              error={domain?.length > 0 && !validDomain(domain)}
              helperText={
                domain?.length > 0 && !validDomain(domain)
                  ? GoogleCalendarValidationMessages.DomainInvalid
                  : ' '
              }
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Finish
        </Button>
      </DialogActions>
    </>
  );
};
