export const round = (value: number, precision: number): number => {
  const multiplier = Math.pow(10, precision || 0);
  const adjusted = value * multiplier * (1 + Number.EPSILON);
  return Math.round(adjusted) / multiplier;
};

export const convertToPercent = (
  amount: number,
  totalAmount: number
): number => {
  return round(amount / totalAmount, 2) * 100;
};

export const isNegative = (num: number): boolean => {
  return num < 0;
};
