'use strict';

angular.module('app').directive('alarmDetailsTerminate', AlarmDetailsTerminate);

function AlarmDetailsTerminate() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsTerminate.html'),
        controller: AlarmDetailsTerminateCtrl
    };
}

AlarmDetailsTerminateCtrl.$inject = ['$scope', 'messagesService'];

function AlarmDetailsTerminateCtrl($scope, msg) {
    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    function checkTerminateRestrictions() {
        if (alarmObjectVerifierFunctions.isGroupAlarm($scope.alarm)) {
            $scope.disableTerminate = true;
            $scope.alarm.terminate = false;
            $scope.terminateTooltip = msg.get('terminateEnabledWithGroup');
        } else {
            $scope.disableTerminate = false;
            $scope.terminateTooltip = null;
        }
    }

    function isComponentValid() {
        // console.log('Terminate Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload() {
        // console.log('Terminate Update Payload', payload);
        return true;
    }

    function alarmUpdated() {
        // console.log('Terminate Alarm Updated');
        checkTerminateRestrictions();
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.terminate,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);
}
