import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartOptions, ChartData } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { ChartCheckboxLegendColumns } from '../../common/components/Chart/components/ChartCheckboxLegendColumns';
import { Box } from '@mui/material';
import {
  CheckboxColors,
  CheckboxLabel
} from '../../common/components/Chart/models/ChartCheckboxLegend.props';

export default function CheckboxLegendPie() {
  const [originalChartData, setOriginalChartData] = useState<ChartData>({
    labels: [],
    datasets: []
  });
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: []
  });
  const [currentSelections, setCurrentSelections] = useState<
    (string | number)[]
  >([]);

  useEffect(() => {
    const data = {
      labels: [
        'Red',
        'Blue is a very long category',
        'Yellow',
        'Green',
        'Purple is also a long category',
        'Orange'
      ],
      datasets: [
        {
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            '#c0032c',
            '#0a7ac4',
            '#f4e405',
            '#016b46',
            '#260566',
            '#be6308'
          ],
          borderWidth: 0
        }
      ]
    };
    setChartData(data);
    setOriginalChartData(data);
  }, []);

  const legendLabels = useMemo(() => {
    return chartData?.datasets?.map((item, i) => {
      return { name: item.label, value: i };
    });
  }, [chartData?.datasets]);

  useEffect(() => {
    if (!currentSelections?.length && legendLabels?.length) {
      //set initial selected pie pieces
      const selected = legendLabels.map((item: CheckboxLabel) => item.value);
      setCurrentSelections(selected);
    }
  }, [currentSelections?.length, legendLabels]);

  const updateChart = useCallback(
    (newSelections) => {
      //start with the original set of data
      //need to deep clone, otherwise the original set of data will also be updated
      const newData = _.cloneDeep(originalChartData);

      //if the data index is not in array, set data to null
      newData.datasets[0].data.forEach((item, i) => {
        if (!newSelections.includes(i)) newData.datasets[0].data[i] = null;
      });
      setChartData(newData);
    },
    [originalChartData]
  );

  const chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    responsive: true
  };

  const checkboxColors: CheckboxColors[] = useMemo(() => {
    const colors = chartData?.datasets[0]?.backgroundColor as string[];
    return colors?.map((item) => {
      return {
        checkboxColor: item
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Chart type="pie" data={chartData} options={chartOptions} />
      {chartData?.labels?.length && chartData?.datasets?.length && (
        <Box>
          <ChartCheckboxLegendColumns
            labels={legendLabels}
            colors={checkboxColors}
            currentSelections={currentSelections}
            onClick={updateChart}
            columnWidth="50%"
          />
        </Box>
      )}
    </>
  );
}
