import React, { createContext, useContext, useEffect } from 'react';
import RoleAccessService from '../../../role-access/services/RoleAccessService';
import { Role } from '../../enums';
import { useAuthorization } from '../Authorization';

type Props = {
  children: React.ReactNode;
};
export interface IPrivateModeProvisions {
  isPrivateMode: boolean;
  setPrivateMode: (p: boolean) => void;
}
export const PrivateModeContext = createContext<IPrivateModeProvisions>(null);
export const PrivateModeProvider = (props: Props) => {
  const { children } = props;
  const [isPrivateMode, setPrivateMode] = React.useState<boolean>(false);
  const authorizationService = useAuthorization();

  const allowedRoles = authorizationService.hasAnyRole([
    Role.Admin,
    Role.SuperAdmin,
    Role.SupportAdvanced
  ]);

  useEffect(() => {
    if (allowedRoles) {
      new RoleAccessService().getPrivateMode().then((value) => {
        setPrivateMode(value);
      });
      // no CATCH block here since it is managed by RoleAccessService.ts
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    isPrivateMode,
    setPrivateMode
  };
  return (
    <PrivateModeContext.Provider value={value}>
      {children}
    </PrivateModeContext.Provider>
  );
};
export const usePrivateModeContext = (): IPrivateModeProvisions => {
  return useContext(PrivateModeContext);
};
