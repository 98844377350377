import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

type WidgetDialogProps = {
  children: React.ReactNode;
  isOpen: boolean;
  height?: string;
  width?: string;
  onClose: () => void;
};

//NOTE: parent must have 'position: relative' set
export const WidgetDialog = (props: WidgetDialogProps) => {
  const { children, onClose, isOpen, height, width } = props;

  return (
    <Box maxHeight={height}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        disablePortal
        PaperProps={{
          elevation: 3,
          style: {
            margin: '0',
            padding: '16px',
            borderRadius: '10px',
            minWidth: width
          }
        }}
        BackdropProps={{
          style: { backgroundColor: 'transparent' }
        }}
        sx={{
          position: 'absolute'
        }}
      >
        {children}
      </Dialog>
    </Box>
  );
};
