import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const ComputerIcon = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const title = props.title ?? 'Computer';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const style = props.style ?? {};

  let classList = 'at-icon-computer';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      fillColor={fillColor}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M20.277 2.803H3.723c-1.012 0-1.84.828-1.84 1.84v11.036c0 1.011.828 1.84 1.84 1.84h6.438v1.839h-.92a.922.922 0 0 0-.92.92c0 .505.414.919.92.919h5.518c.506 0 .92-.414.92-.92a.922.922 0 0 0-.92-.92h-.92v-1.839h6.438c1.012 0 1.84-.828 1.84-1.84V4.643c0-1.011-.828-1.839-1.84-1.839Zm-.92 12.876H4.642a.922.922 0 0 1-.92-.92V5.562c0-.506.415-.92.92-.92h14.715c.506 0 .92.414.92.92v9.197c0 .506-.414.92-.92.92Z"
        style={style}
      />
    </SvgIconWrapper>
  );
};
