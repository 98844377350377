export const notificationMessage = {
  duplicateAccountAlert: 'Account already displayed on dashboard.',
  duplicateAccountError: (accountId: string) =>
    `ActivTrak Error: Unable to load account #${accountId} because account already exists on the dashboard.`,
  accountAddedSuccess: 'Account successfully added to dashboard!',
  accountAddedErrorAlert:
    'Unable to add account at this time. Please try again later.',
  impersonationError: (accountId) =>
    `ActivTrak Error: Unable to set impersonation for account #${accountId}.`,
  rerouteErrorAlert: (path: string, accountId: string) =>
    `Unable to reroute to '${path}' for account ${accountId}.`,
  fetchTokenError: (payload) =>
    `ActivTrak Error: Unable to fetch token for account ${
      payload.accountId || payload.username
    }.`,
  fetchDetailsForCardError:
    'ActivTrak Error: Unable to fetch account details for account card.',
  fetchSnapshotError:
    'ActivTrak Error: Unable to fetch snapshot details for modal.',
  fetchDetailsForModalError:
    'ActivTrak Error: Unable to get account details for the modal.',
  refreshTokenError: 'ActivTrak Error: Unable to refresh token for modal.',
  changeDataCollectionValueSuccess: (swapValue: string) =>
    `Data Collection successfully turned ${swapValue}!`,
  userUnlockedSuccess: (username: string) =>
    `User ${username} successfully unlocked!`,
  accountVerifiedSuccess: (accountId: string) =>
    `Acct${accountId} successfully verified!`,
  setDataCollectionError:
    'ActivTrak Error: Cannot update Data Collection Setting at this time.',
  toggleDataCollectionErrorAlert: `Unable to toggle Data Collection setting at this time. Please try again later.`,
  unlockUserError: 'ActivTrak Error: Cannot unlock user at this time.',
  unlockUserAlert:
    'Unable to unlock user at this time. Please try again later.',
  verifyAccountError: 'ActivTrak Error: Cannot verify account at this time.',
  verifyAccountAlert:
    'Unable to verify account at this time. Please try again later',
  detailsErrorAlert:
    'ActivTrak Error: Unable to load full account details for modal.'
};
