import { useState, useEffect } from 'react';
import _ from 'lodash';

const getDeviceConfig = (width) => {
  if (width < 600) {
    return 'xs';
  } else if (width >= 600 && width < 960) {
    return 'sm';
  } else if (width >= 960 && width < 1280) {
    return 'md';
  } else if (width >= 1280 && width < 1920) {
    return 'lg';
  } else if (width >= 1920) {
    return 'xl';
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = _.throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
