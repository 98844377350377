import React from 'react';
import { notifWarn } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const IndividualIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 36;
  const width = props.width ?? 36;
  const title = props.title ?? 'Individual';
  const className = props.className;
  const fillColor = props.fillColor ?? notifWarn;

  let classList = 'at-icon at-icon-individual';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 36`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M17.9999 18C21.1065 18 23.6249 15.4816 23.6249 12.375C23.6249 9.26839 21.1065 6.75 17.9999 6.75C14.8933 6.75 12.3749 9.26839 12.3749 12.375C12.3749 15.4816 14.8933 18 17.9999 18Z"
        fill={fillColor}
      />
      <path
        d="M18.0003 19.4063C14.2457 19.4063 6.75037 21.2907 6.75037 25.0313V27.8438C6.75037 28.6172 7.38318 29.25 8.15661 29.25H27.8441C28.6175 29.25 29.2503 28.6172 29.2503 27.8438V25.0313C29.2503 21.2907 21.755 19.4063 18.0003 19.4063Z"
        fill={fillColor}
      />
    </svg>
  );
};
