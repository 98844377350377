export const commonApis = {
  groups: {
    allGroupsFilters: '/reports/v1/teams'
  },
  entities: {
    allEntityFilters: '/api/filter/all',
    userEntityFilters: '/api/filter/user',
    computerEntityFilters: '/api/filter/computer'
  },
  insights: {
    groups: '/api/insights/groups'
  }
};
