import React from 'react';
import { CheckboxMemoized } from '../../../common/components/Checkbox';
import { IndeterminateCheckBoxIconSC } from './BulkSelection.styles';

type BulkCheckboxHeaderProps = {
  data: any;
  onSelectAllClick: (e) => void;
  disabled: boolean;
};

export const BulkCheckboxHeader = (props: BulkCheckboxHeaderProps) => {
  const { data, onSelectAllClick, disabled } = props;

  const isHeaderCheckboxIndeterminate = (currentData) => {
    if (currentData?.length) {
      if (currentData.every((item) => item.selected)) {
        return false;
      } else if (currentData.some((item) => item.selected)) {
        return true;
      }
    }
    return false;
  };

  const isHeaderCheckboxChecked = (currentData) => {
    if (currentData?.length && currentData?.every((item) => item.selected)) {
      return true;
    }
    return false;
  };

  return (
    <CheckboxMemoized
      color="primary"
      indeterminate={isHeaderCheckboxIndeterminate(data)}
      checked={isHeaderCheckboxChecked(data)}
      onClick={(e) => onSelectAllClick(e)}
      indeterminateIcon={<IndeterminateCheckBoxIconSC />}
      disabled={disabled}
    />
  );
};

type BulkCheckboxBodyProps = {
  rowItem: any;
  rowDisableCondition: (rowItem) => boolean;
  onSelectSingleClick: (rowItem) => void;
  disabled: boolean;
};

export const BulkCheckboxBody = (props: BulkCheckboxBodyProps) => {
  const { rowItem, onSelectSingleClick, disabled, rowDisableCondition } = props;

  return (
    <CheckboxMemoized
      color="primary"
      checked={rowItem?.selected}
      disabled={disabled || rowDisableCondition(rowItem)}
      onClick={(e) => {
        onSelectSingleClick([rowItem]);

        //stop everything else to avoid issue with clickable row
        e.stopPropagation();
      }}
      sx={{ marginLeft: '-16px' }}
    />
  );
};
