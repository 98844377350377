import {
  blue1,
  purple1,
  purple2,
  red2,
  red1,
  orange1,
  orange2,
  yellow1,
  green1,
  blue5,
  blue6,
  aqua7,
  aqua8,
  aqua1,
  purple3
} from './styleGuide';

export const GraphColors = [
  blue1,
  purple1,
  purple2,
  red1,
  red2,
  orange1,
  orange2,
  yellow1,
  green1,
  blue5,
  blue6,
  aqua7,
  aqua8,
  aqua1,
  purple3
];
