import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper'; // defaults for root of SVG
import { SvgIconProps } from './SvgIconProps'; //review these for explanation
import { gray7, yellow2 } from '../../constants/styleGuide';

export const ExpiringIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const height = props?.height ?? '24px'; //only change if different from default
  const width = props?.width ?? '24px'; //only change if different from default
  const viewBox = props.viewBox ?? '0 0 24 24'; //only change if different from default
  const title = props.title ?? 'Default Title';
  const className = props.className;

  let classList = 'at-icon-expiring';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      //NOTE: fillcolor should not be overidden on multi-color images
      fillColor="none"
    >
      <rect x="10" y="9" width="4" height="10" fill={gray7}/>
      <path d="M4.47 21H19.53C21.07 21 22.03 19.33 21.26 18L13.73 4.98999C12.96 3.65999 11.04 3.65999 10.27 4.98999L2.74 18C1.97 19.33 2.93 21 4.47 21ZM12 14C11.45 14 11 13.55 11 13V11C11 10.45 11.45 9.99999 12 9.99999C12.55 9.99999 13 10.45 13 11V13C13 13.55 12.55 14 12 14ZM13 18H11V16H13V18Z" fill={yellow2}/>
    </SvgIconWrapper>
  );
};
