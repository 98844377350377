import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext
} from 'react';

export interface IWizardProvider {
  activeStep: number;
  modalState: boolean;
  hideNavigation: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  setHideNavigation: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  resetWizard: () => void;
  wizardDone?: boolean;
  setWizardDone?: Dispatch<SetStateAction<boolean>>;
}

export const WizardContext = createContext<IWizardProvider>(null);

type Props = {
  children: React.ReactNode;
};
export const WizardProvider = (props: Props) => {
  const { children } = props;

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [modalState, setModalState] = React.useState<boolean>(false);
  const [hideNavigation, setHideNavigation] = React.useState<boolean>(false);
  const [wizardDone, setWizardDone] = React.useState<boolean>(false);
  const resetWizard = () => {
    setActiveStep(0);
    setHideNavigation(false);
    setModalState(false);
    setWizardDone(false);
  };

  const value = {
    activeStep,
    modalState,
    hideNavigation,
    setModalState,
    setActiveStep,
    setHideNavigation,
    resetWizard,
    wizardDone,
    setWizardDone
  };

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};

export const useWizardContext = (): IWizardProvider => {
  return useContext(WizardContext);
};
