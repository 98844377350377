import {
  gray1,
  gray11,
  secondaryFontSize,
  heavyFontWeight
} from '../../common/constants';

export const activationClasses = {
  card: {
    textAlign: 'center',
    background: '#fff',
    margin: '0 auto',
    float: 'none',
    marginBottom: '10px',
    padding: '2em 1em',
    width: '75%',
    boxShadow: 'none !important',
    transition: 'all 0.6s !important',
    '&:hover': {
      transform: 'scale(1.05) !important',
      boxShadow:
        '0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06) !important',
      transformStyle: 'preserve-3d',
      '& .cardBlurb': {
        transition: 'all 0.6s ease-in !important',
        opacity: '1',
        height: '85px'
      },
      '& .selectOsDiv': {
        transition: 'all 0.6s ease-in !important',
        opacity: '1',
        height: '120px'
      }
    }
  },
  img: {
    marginBottom: '-30px',
    marginLeft: '-5%'
  },
  cardTitle: {
    fontSize: '25px !important',
    marginBottom: '1em !important',
    lineHeight: '32px !important'
  },
  cardBlurb: {
    textAlign: 'left',
    marginRight: '5px',
    paddingBottom: '1em',
    fontSize: '14px !important',
    letterSpacing: '0px !important',
    color: `${gray11} !important`
  },
  selectOsDiv: {
    marginBottom: '10px'
  },
  selectOsStatement: {
    fontSize: '16px',
    letterSpacing: '0.5px',
    marginBottom: '10px !important'
  },
  hidden: {
    transition: 'all 0.3s ease-out',
    opacity: '0',
    height: '0px'
  },
  osBtn: {
    color: gray11,
    fontWeight: heavyFontWeight,
    lineHeight: '16px',
    fontSize: secondaryFontSize,
    border: '1px solid #f0f0f0 !important',
    padding: '4px 17px !important',
    borderRadius: '3px !important',
    '&:hover': {
      backgroundColor: gray1,
      border: '1px solid rgba(98, 98, 98, 0.27) !important',
      color: '#333'
    },
    '&:active, &:focus': {
      backgroundColor: '#000 !important',
      color: '#FFF !important',
      borderStyle: 'none !important'
    }
  },
  macOsBtn: {
    marginRight: '6px !important'
  },
  lowercase: {
    textTransform: 'lowercase' as const
  },
  osIcon: {
    height: '25px',
    width: '25px',
    marginLeft: '4px',
    marginBottom: '6px'
  },
  expirationNote: {
    fontSize: '14px !important',
    color: `${gray11} !important`
  },
  btn: {
    margin: '0 auto !important',
    height: '40px'
  }
};
