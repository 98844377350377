import React from 'react';
import { Typography, Link, Skeleton } from '@mui/material';
import { cardAlarmClasses } from './CardAlarm.styles';
import { setDateRange, dateRanges } from '../../../utils/globalFilters.js';
import { useImpersonate } from '../../../../../_reactivtrak/src/common/hooks/useImpersonate';

const CardAlarm = ({ alarmCount, accountId, onError, extLoaded }) => {
  const [, impersonate] = useImpersonate(accountId);

  return (
    <Link
      href={'#'}
      onClick={(e) => {
        e.preventDefault();

        setTimeout(async () => {
          try {
            await impersonate();
            setDateRange(dateRanges.last7Days);

            window.location.href = '/#/app/reports/alarmlog';
          } catch (ex) {
            onError(ex);
          }
        });
      }}
      color="inherit"
      underline="none"
      target="_blank"
      sx={cardAlarmClasses.cardAlarm}
    >
      <Typography variant="subtitle2" sx={cardAlarmClasses.alerts}>
        Alarms
      </Typography>
      <Typography variant="caption" sx={cardAlarmClasses.dayRange}>
        Last 7 Days
      </Typography>
      <Typography variant="h4" sx={cardAlarmClasses.root}>
        {extLoaded && <>{alarmCount}</>}
        {!extLoaded && (
          <Skeleton
            variant="rect"
            animation="wave"
            sx={cardAlarmClasses.loader}
          />
        )}
      </Typography>
    </Link>
  );
};

export default CardAlarm;
