import { Role } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';

export const appIcons: IRoute = {
  name: 'app.icons',
  stateDefinition: {
    url: '/icons',
    template: '<demo-icons></demo-icons>',
    data: { pageTitle: 'Icons List' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [],
    read: [
      Role.Admin,
      Role.SuperAdmin,
      Role.SupportBasic,
      Role.SupportIntermediate
    ]
  }
};

export default appIcons;
