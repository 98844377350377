'use strict';

let _envConfig;
let _notificationService;
let _userServiceFunctions;
let _httpService;
let _authorizationServiceFunctions;
let _latestUpdates;
let _userLastSeen;
let _loadingLatestUpdates;
let _callback;

let isArticleNew = (article) => {
    let lastSeenDifference = _userLastSeen.diff(
        moment.utc(article.createdAt),
        'hours'
    );

    // Article is new if the user has not viewed latest updates page
    // since it was released
    return lastSeenDifference < 0;
};

let setHasNewUpdates = () => {
    let newUpdateCount = null;
    if (!_latestUpdates) {
        if (!_loadingLatestUpdates) {
            loadLatestUpdates();
        }
        newUpdateCount = null;
    } else if (!_userLastSeen) {
        newUpdateCount = null;
    } else {
        let newArticles = _.filter(_latestUpdates, (a) => isArticleNew(a));
        newUpdateCount = newArticles.length;
    }

    if (_callback && typeof _callback === 'function') {
        _callback(newUpdateCount);
    }
};

const initialize = (
    envConfig,
    notificationService,
    userServiceFunctions,
    httpService,
    authorizationServiceFunctions
) => {
    _envConfig = envConfig;
    _notificationService = notificationService;
    _userServiceFunctions = userServiceFunctions;
    _httpService = httpService;
    _authorizationServiceFunctions = authorizationServiceFunctions;
};

const clearLocals = () => {
    _latestUpdates = undefined;
    _userLastSeen = undefined;
    _loadingLatestUpdates = undefined;
    _callback = undefined;
};

const loadLatestUpdates = (showError) => {
    // console.log('loadLatestUpdates', _userLastSeen);
    return new Promise((resolve, reject) => {
        if (_userLastSeen) {
            _loadingLatestUpdates = true;
            if (
                _authorizationServiceFunctions.hasRole(
                    _authorizationServiceFunctions.roles.supportBasic
                )
            ) {
                _latestUpdates = [];
                _loadingLatestUpdates = false;
                resolve(_latestUpdates);
            } else {
                _httpService
                    .get(_envConfig.apiUrl() + '/api/support/latestupdates')
                    .then(function (result) {
                        var articles = result.data;

                        articles.forEach(function (article) {
                            article.isNew = isArticleNew(article);
                        });

                        _latestUpdates = articles;
                        _loadingLatestUpdates = false;
                        resolve(_latestUpdates);
                        setHasNewUpdates();
                    })
                    .catch((e) => {
                        _loadingLatestUpdates = false;
                        if (showError) {
                            _notificationService.showNotification(
                                'An error occurred retrieving latest update articles.',
                                'danger'
                            );
                        }
                        reject(e);
                    });
            }
        } else {
            resolve([]);
        }
    });
};

const getLatestUpdates = () => {
    return _latestUpdates;
};

const getUserLastSeen = (viewedPage) => {
    return new Promise((resolve, reject) => {
        let tagName = 'latestupdates';
        _userServiceFunctions
            .getUserLastSeen(tagName)
            .then((userLastSeen) => {
                _userLastSeen = userLastSeen
                    ? moment.utc(userLastSeen)
                    : moment.utc().add(-7, 'd');
                setHasNewUpdates();
                resolve(_userLastSeen);
                if (viewedPage) {
                    _userServiceFunctions.setUserLastSeen(tagName);
                }
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const updateUserLastSeen = () => {
    let tagName = 'latestupdates';
    _userServiceFunctions
        .updateUserLastSeen(tagName)
        .then((userLastSeen) => {
            _userLastSeen = userLastSeen
                ? moment.utc(userLastSeen)
                : moment.utc().add(-7, 'd');
            setHasNewUpdates();
        })
        .catch((e) => {
            throw e;
        });
};

const setLatestUpdatesCallback = (callback) => {
    _callback = callback;
};

const localCheck = () => {
    return {
        envConfig: _envConfig,
        notificationService: _notificationService,
        userServiceFunctions: _userServiceFunctions,
        httpService: _httpService,
        latestUpdates: _latestUpdates,
        userLastSeen: _userLastSeen,
        loadingLatestUpdates: _loadingLatestUpdates,
        callback: _callback
    };
};

export const latestUpdatesServiceFunctions = {
    initialize: initialize,
    clearLocals: clearLocals,
    loadLatestUpdates: loadLatestUpdates,
    getLatestUpdates: getLatestUpdates,
    getUserLastSeen: getUserLastSeen,
    updateUserLastSeen: updateUserLastSeen,
    setLatestUpdatesCallback: setLatestUpdatesCallback,
    localCheck: localCheck
};
