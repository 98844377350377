import React, { createContext, useContext } from 'react';
import { IComputersService } from '../models';
import ComputersService from './ComputersService';
import { BundleFlag } from "../../common/enums/BundleFlag";
import { useAuthorization } from "../../common/services/Authorization";
const ComputersContext = createContext<IComputersProvider>(null);

interface IComputersProvider {
  computersService: IComputersService;
}

type Props = {
  children: React.ReactNode;
  computersService?: IComputersService;
};

export const ComputersProvider = (props: Props) => {
  const { children, computersService } = props;
  const authorization = useAuthorization();
  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  
  const value = {
    computersService: computersService || new ComputersService(softDeletesEnabled)
  };

  return (
    <ComputersContext.Provider value={value}>
      {children}
    </ComputersContext.Provider>
  );
};

export const UseComputersContext = (): IComputersProvider => {
  return useContext(ComputersContext);
};
