import { useEffect, useState } from 'react';
import { useGroupsStore } from '../../../hooks';
import { IAutocompleteSuggestion } from '../../Autocomplete/IAutocomplete';
import { ITeamFilterState } from '../models/ITeamFilterState';
import { IGroupFilter } from '../../../models';
import { mapToTeamSuggestion } from '../utils/teamFilter.utils';

export const useTeamFilterState = (): ITeamFilterState => {
  const [allTeamSuggestions, setAllTeamSuggestions] =
    useState<IAutocompleteSuggestion[]>();

  const allGroupFilters = useGroupsStore((s) => s.groupFilters);

  useEffect(() => {
    if (allGroupFilters?.length) {
      const mapped = allGroupFilters.map((item: IGroupFilter) =>
        mapToTeamSuggestion(item)
      );

      setAllTeamSuggestions(mapped);
    }
  }, [allGroupFilters]);

  return {
    allTeamSuggestions
  };
};
