import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsRoleaccess: IRoute = {
  name: 'app.settings.roleaccess',
  stateDefinition: {
    url: '/settings/roleaccess',
    template: '<div><settings-role-access></settings-role-access></div>',
    data: { pageTitle: 'Role Access' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsRoleaccess;
