import { styled } from '@mui/material/styles';
import {
  buttonFontSize,
  regularFontWeight,
  mediumFontWeight,
  primaryFontSize,
  secondaryFontSize,
  smallFontSize,
  heading1FontSize,
  heading2FontSize,
  heading3FontSize,
  heading4FontSize,
  heading5FontSize,
  heading6FontSize,
  primaryBlue,
  gray2,
  primaryColor,
  linkHoverColor
} from '../constants';

export const MUIStylesContainer = styled('div')`
  .MuiBox-root,
  .MuiGrid-root,
  .MuiPaper-root {
    color: ${primaryColor};
    font-size: ${primaryFontSize};
    letter-spacing: 0.5px;

    h1 {
      font-size: ${heading1FontSize};
      font-weight: ${mediumFontWeight};
      letter-spacing: -1.5px;
    }
    h2 {
      font-size: ${heading2FontSize};
      font-weight: ${mediumFontWeight};
      letter-spacing: -0.5px;
    }
    h3 {
      font-size: ${heading3FontSize};
      font-weight: ${mediumFontWeight};
    }
    h4 {
      font-size: ${heading4FontSize};
      font-weight: ${mediumFontWeight};
    }
    h5 {
      font-size: ${heading5FontSize};
      font-weight: ${mediumFontWeight};
    }
    h6 {
      font-size: ${heading6FontSize};
      font-weight: ${mediumFontWeight};
      letter-spacing: 0.5px;
    }
    small {
      font-size: ${smallFontSize};
      font-weight: ${regularFontWeight};
      letter-spacing: 0.25px;
    }
    a {
      color: ${primaryBlue};
      &:hover {
        color: ${linkHoverColor};
      }
    }
  }

  .MuiDataGrid-root {
    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-colCell,
    .MuiDataGrid-cell {
      &:focus,
      &:focus-within {
        outline: none;
      }
    }
  }

  .MuiTableContainer-root {
    .MuiTableRow-head {
      .MuiTableCell-head {
        color: ${primaryColor};
        font-size: ${primaryFontSize};
      }
      .MuiTableCell-root {
        padding: 0px 16px;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        cursor: pointer;
        height: 52px;

        .MuiTableCell-body {
          padding: 0px 16px;
          color: ${primaryColor};
          font-size: ${primaryFontSize};
          max-width: 235px;
          font-weight: normal;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.Mui-selected {
          background-color: rgba(3, 218, 197, 0.1) !important;
        }

        &:hover {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }

        &.row-disabled {
          background-color: ${gray2};
          cursor: not-allowed;

          td {
            opacity: 0.4;
          }

          &:active,
          button {
            pointer-events: none;
          }
          &:hover {
            box-shadow: initial;
          }
        }
      }
    }
  }

  .MuiButtonBase-root {
    &.MuiListItemButton-root {
      font-weight: ${regularFontWeight};
    }
  }
  .MuiButton-root {
    font-size: ${buttonFontSize};
    font-weight: ${mediumFontWeight};
    line-height: 24px;
    letter-spacing: 1.25px;
    &.cicle-btn {
      min-width: 0px;
      border-radius: 50%;
    }
  }
  .MuiButton-sizeSmall {
    padding: 0 16px;
    line-height: 12px;
    font-size: ${smallFontSize};
    height: 24px;
  }
  .MuiButton-sizeMedium {
    padding: 0 16px;
    line-height: 14px;
    font-size: ${buttonFontSize};
    height: 32px;
  }
  .MuiButton-sizeLarge {
    padding: 0 16px;
    line-height: 16px;
    font-size: ${buttonFontSize};
    height: 40px;
  }

  .truncate {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .at-icon {
    fill: currentColor;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  .cancel-btn {
    background-color: #fff;
    color: ${primaryBlue};
    &:hover {
      color: ${linkHoverColor};
      background-color: #fff;
    }
  }

  @media (print) {
    body {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
      font-size: ${secondaryFontSize};
      /* line-height: 1.5; */
    }

    .hide-on-print {
      display: none !important;
    }
  }
`;
