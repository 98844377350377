import React from 'react';
import { IGroup } from '../../common/models';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Container,
  IconButton,
  List,
  ListItemButton,
  Popover
} from '@mui/material';

type GroupsTableRowProps = {
  group: IGroup;
  readonly: boolean;
  handleOpenDialog: () => void;
  setDeleteGroup: React.Dispatch<React.SetStateAction<IGroup>>;
};

export function DeleteGroupsButton(props: GroupsTableRowProps) {
  const { handleOpenDialog, readonly, group, setDeleteGroup } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'delete-group-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    if (!readonly) {
      handleOpenDialog();
      setDeleteGroup(group);
    }
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List component="nav">
          <ListItemButton onClick={handleDeleteClick} disabled={readonly}>
            Delete
          </ListItemButton>
        </List>
      </Popover>
    </Container>
  );
}
