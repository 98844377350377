import { useCallback, useState } from 'react';
import {
  IStorageInformation,
  IStorageInformationState,
  StorageInformationStateProps
} from '../models';
import { mapToStorageInfo } from '../utils';


export const useStorageInformationState = ({
  service,
  notificationService
}: StorageInformationStateProps): IStorageInformationState => {
  const { fetchStorageInformation } = service;

  const [information, setInformation] = useState<IStorageInformation>({
    totalStorage: '',
    totalLog: 0,
    totalScreenshot: 0,
    totalVideo: 0
  });

  

  const getStorageInfo = useCallback(async () => {
    try {
      const response = await fetchStorageInformation();
      if (response) {
        const mapped = mapToStorageInfo(response);
        setInformation(mapped);
      } else {
        throw Error('No Data');
      }
    } catch (error) {
      notificationService.error('Unable to load Storage Information');
      console.error(
        'ActivTrak Error: Unable to load Storage Information',
        error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    getStorageInfo();
  }, [getStorageInfo]);

  return {
    information,
    init
  };
};
