import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { aqua2, fontColor900, primaryAqua } from '../../../common/constants';

export const OutlinedButton = styled(Button)({
  textTransform: 'capitalize',
  marginRight: '8px',
  padding: '4px 16px',
  borderColor: fontColor900,
  color: fontColor900,
  '&:hover': {
    backgroundColor: 'rgba(64, 66, 66, 0.04)',
    borderColor: fontColor900
  }
});

export const PrimaryButton = styled(Button)({
  padding: '4px 16px',
  textTransform: 'capitalize',

  // REMOVE CUSTOM STYLES WHEN COMPLETED
  borderColor: primaryAqua,
  backgroundColor: primaryAqua,
  color: fontColor900,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: aqua2,
    borderColor: aqua2,
    color: fontColor900,
    boxShadow: 'none'
  }
});
