'use strict';

angular.module('app').service('utmService', UtmService);

UtmService.$inject = ['$window', '$timeout', 'cookieDataService'];

function UtmService($window, $timeout, cookieDataService) {
    var analyticEventServiceFunctions =
        require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;

    function sendEvent(eventName, eventData) {
        try {
            analyticEventServiceFunctions.newEvent(eventName, eventData);
        } catch (error) {
            console.error(
                'ActivTrak Error: An error occurred trying to send the Heap event ' + eventName + './n',
                error
            );
        }
    }

    this.configureForm = function (form, vals, hiddenFields, successCallback) {
        var utm = cookieDataService.getUtm();
        var utmHiddenFields = {
            UTM_Source__c: utm.source,
            UTM_Medium__c: utm.medium,
            UTM_Term__c: utm.term,
            UTM_Content__c: utm.content,
            UTM_Campaign__c: utm.campaign,
            GCLID__c: utm.gclid,
            UTM_Referrer__c: utm.referrer,
            UTM_Landing__c: utm.landing
        };

        var hiddenFieldsWithUtm = hiddenFields ? Object.assign(utmHiddenFields, hiddenFields) : utmHiddenFields;

        if (vals) {
            form.vals(vals);
        }

        form.addHiddenFields(hiddenFieldsWithUtm);
        form.onSuccess(successCallback);
    };

    this.getHardcodedMktoHiddenFields = function () {
        return {
            UTM_Medium__c: 'in_product',
            UTM_Source__c: 'in_product',
            UTM_Content__c: 'not_provided',
            UTM_Term__c: 'not_provided',
            UTM_Campaign__c: 'not_provided',
            UTM_Referrer__c: 'not_provided',
            UTM_Landing__c: 'not_provided'
        };
    };
}
