import { useCallback, useState } from 'react';
import { IWorkloadDto, IWorkloadState, IReportModalListItem } from '../models';
import { mapWorkloadTeams } from '../utils';
import { ApiRoutes } from '../constants';
import { fetchReportingData } from '../../common/helpers';

export const useWorkloadState = (): IWorkloadState => {
  const [workloadData, setWorkloadData] = useState<IWorkloadDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [workloadTeams, setWorkloadTeams] = useState<IReportModalListItem[]>();

  const init = useCallback(async () => {
    setIsLoading(true);
    setIsTileError(false);
    try {
      const response = await fetchReportingData<IWorkloadDto>({
        path: ApiRoutes.reports.fetchWorkload
      });

      if (response.teams?.length) {
        const statusFilter = response.statusFilter?.map(
          (filter) => filter.status
        );
        const mappedTeams = mapWorkloadTeams(response.teams, statusFilter);
        setWorkloadTeams(mappedTeams);
      }

      setWorkloadData(response);
    } catch (error) {
      console.error(`ActivTrak Error: Workload Tile: ${error}`, error);
      setIsTileError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    init,
    workloadData,
    isLoading,
    isTileError,
    workloadTeams
  };
};
