import { useState } from 'react';
import { IDntDeleteState } from '../models/IDntDeleteState';
import { IUserIdentity } from '../models/IUserIdentity';
import { postIdentityData } from '../../common/helpers';
import { mapToIdentity } from '../utils/IdentityMappers';
import { IUserIdentityDto } from '../models/IUserIdentityDto';

export const useDntDeleteState = (): IDntDeleteState => {
  const [openDoNotTrackDeleteModal, setOpenDoNotTrackDeleteModal] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openTrackModal, setOpenTrackModal] = useState<boolean>(false);
  const [userToModify, setUserToModify] = useState<IUserIdentity>(null);

  const postIdentityDataPathArgs = async (
    path: string,
    args?: object
  ): Promise<[any, IUserIdentity]> => {
    try {
      const newIdentityDto = await postIdentityData<IUserIdentityDto>({
        path: path,
        args: args
      });

      const tempSelected = mapToIdentity(newIdentityDto);
      return [null, tempSelected];
    } catch (error) {
      return [error, null];
    }
  };

  const setTracking = async (track: boolean): Promise<[any, IUserIdentity]> => {
    const path = `/identity/v1/entities/${userToModify.id}/revision/${userToModify.revision}/doNotTrack`;
    const [error, newIdentity] = await postIdentityDataPathArgs(path, {
      tracking: track,
      delete: false
    });
    return [error, newIdentity];
  };

  const deleteUser = async (userToDelete: IUserIdentity): Promise<any> => {
    const path = `/identity/v1/entities/${userToDelete.id}/revision/${userToModify.revision}/deleteClients`;
    try {
      const [error, newIdentity] = await postIdentityDataPathArgs(path, null);
      return [error, newIdentity];
    } catch (ex) {
      return [ex, userToDelete];
    }
  };

  const doNotTrackAndDelete = async (): Promise<[any, IUserIdentity]> => {
    const path = `/identity/v1/entities/${userToModify.id}/revision/${userToModify.revision}/doNotTrack`;
    const [error, newIdentity] = await postIdentityDataPathArgs(path, {
      tracking: false,
      delete: true
    });
    return [error, newIdentity];
  };

  return {
    openDoNotTrackDeleteModal,
    setOpenDoNotTrackDeleteModal,
    openDeleteModal,
    setOpenDeleteModal,
    openTrackModal,
    setOpenTrackModal,
    setTracking,
    userToModify,
    setUserToModify,
    deleteUser,
    doNotTrackAndDelete
  };
};
