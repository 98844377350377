import { react2angular } from 'react2angular';
import { AccountWizardComponent } from '../../_reactivtrak/src/account-wizard/views/AccountWizard.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('accountWizard', react2angular(AccountWizardComponent, []));
}
