import { IAccountTimezone, IAccountTimezoneDto } from '../../common/services';

export const mapAccountTimezone = (
  accountTz: IAccountTimezoneDto
): IAccountTimezone => {
  return {
    isDateFormatDDMM: accountTz.isDateFormatDDMM.toString(),
    isTimeFormat24Hour: accountTz.isTimeFormat24Hour.toString(),
    timeZone: accountTz.timeZone
  };
};
