import React, { useEffect, useState } from 'react';
import { IconButton, List, ListItemButton, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAllActivitiesDialog } from '../utils';
import { useClassificationStores } from '../services';
import AllActivitiesDialog from './AllActivitiesDialog';

const allActivitiesTextOptions = {
  show: 'Show all activities',
  hide: 'Limit activities'
};

type ClassificationsMoreMenuProps = {
  onChange: (msg: string) => void;
};

export default function ClassificationsMoreMenu(
  props: ClassificationsMoreMenuProps
) {
  const { onChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [allActivitiesText, setAllActivitiesText] = useState(
    allActivitiesTextOptions.show
  );

  const { classificationStore } = useClassificationStores();
  const { activitiesState, setActivitiesState } = classificationStore;
  const { showAllActivities } = activitiesState;

  const hideAllActivitiesDialog = useAllActivitiesDialog();

  const open = Boolean(anchorEl);
  const id = open ? 'redirect-beta-popover' : undefined;

  useEffect(() => {
    setAllActivitiesText(
      showAllActivities
        ? allActivitiesTextOptions.hide
        : allActivitiesTextOptions.show
    );
  }, [showAllActivities]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllActivities = () => {
    setOpenDialog(false);

    setActivitiesState((prevState) => {
      const newValue = !prevState.showAllActivities;

      //set the success msg for when api completes
      const successMsg = newValue
        ? 'Success! All activities are being shown'
        : 'Success! Activities are now limited';
      onChange(successMsg);

      //update global state to trigger new api call based on minDuration param
      return {
        ...prevState,
        showAllActivities: newValue
      };
    });
  };

  const handleOpenAllActivitiesDialog = () => {
    handleClose();

    if (!hideAllActivitiesDialog && !showAllActivities) {
      //if modal not disabled, open dialog first
      setOpenDialog(true);
    } else {
      handleAllActivities();
    }
  };

  return (
    <div data-testid="classifications-more-menu">
      <IconButton
        onClick={handleClick}
        color="secondary"
        sx={{ height: '32px', padding: '4px' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List component="nav">
          <ListItemButton onClick={handleOpenAllActivitiesDialog}>
            {allActivitiesText}
          </ListItemButton>
        </List>
      </Popover>
      <AllActivitiesDialog
        open={openDialog}
        onSubmit={handleAllActivities}
        onClose={() => setOpenDialog(false)}
      ></AllActivitiesDialog>
    </div>
  );
}
