import { useCallback, useState } from 'react';
import { ISubheaderHoursDto, IOrganizationTrendsState } from '../models';
import { ApiRoutes } from '../constants';
import { fetchReportingData } from '../../common/helpers';

export const useOrganizationTrendsState = (): IOrganizationTrendsState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orgTrendsHours, setOrgTrendsHours] = useState<ISubheaderHoursDto>();
  const [isTileError, setIsTileError] = useState<boolean>(false);

  const init = useCallback(async () => {
    if (!orgTrendsHours) {
      setIsLoading(true);
      setIsTileError(false);

      try {
        const response = await fetchReportingData<ISubheaderHoursDto>({
          path: ApiRoutes.reports.fetchOrganizationTrendsHours
        });
        setOrgTrendsHours(response);
      } catch (error) {
        console.error(
          'ActivTrak Error: Unable to load current organizational trends',
          error
        );
        setIsTileError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [orgTrendsHours]);

  return {
    isLoading,
    isTileError,
    orgTrendsHours,
    init
  };
};
