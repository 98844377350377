import { AxiosRequestHeaders } from 'axios';
import { apiRequest } from './apiService';
import { apiConfigs } from '../../config';
import { IActivTrakRequestConfig } from '../../models';
import { parseGlobalsJson } from '../../../../../_ui-utils/src/authModule/authenticatedUserService';

//THIS IS FOR DEBUGGING THE AXIOS REQUESTS
// axios.interceptors.request.use((config) => {
//   console.log('config=>', config);

//   return config;
// });

type InitialBuildRequestProps = {
  path: string;
  params?: object;
  args?: object;
  headers?: AxiosRequestHeaders;
  paramsSerializer?: (params) => string;
};

type CompleteBuildRequestProps = InitialBuildRequestProps & {
  method: string;
  apiConfig?: () => IActivTrakRequestConfig;
};

// This is a special feature request
// DO NOT REPLICATE
/**
 * Consolidate header building in this function
 * @param callback
 * @returns
 */
const createCommandCenterHeaders = (
  props: InitialBuildRequestProps
): InitialBuildRequestProps => {
  // TODO: Use command center user token if available from stored backup token
  // Conditinally apply required headers when needed.
  const ccToken = parseGlobalsJson(
    window.localStorage.getItem('activTrak.msp.backup')
  );
  if (ccToken?.currentUser) {
    props.headers = props.headers || {};
    props.headers.Authorization = ccToken.currentUser.token;
  }

  return props;
};

const buildRequest = async <TReturn>(
  props: CompleteBuildRequestProps
): Promise<TReturn> => {
  const { path, args, apiConfig } = props;

  const request = apiRequest<TReturn>(path, {
    ...props,
    ...apiConfig(),
    data: args
  });

  const response = await request;
  return response?.data;
};

const generateApiRequestType = <TReturn>(props: InitialBuildRequestProps) => {
  return (method: string) => {
    return (apiConfig: () => IActivTrakRequestConfig): Promise<TReturn> => {
      const finalProps = {
        ...props,
        method,
        apiConfig
      };
      return buildRequest(finalProps);
    };
  };
};

//default
export const fetchData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('get')(apiConfigs.default);
export const postData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('post')(apiConfigs.default);
export const putData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('put')(apiConfigs.default);
export const deleteData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('delete')(apiConfigs.default);

//reporting
export const fetchReportingData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('get')(apiConfigs.reporting);
export const postReportingData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('post')(apiConfigs.reporting);
export const putReportingData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('put')(apiConfigs.reporting);
export const deleteReportingData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('delete')(apiConfigs.reporting);

//admin
export const fetchAdminData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('get')(apiConfigs.admin);
export const postAdminData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('post')(apiConfigs.admin);
export const putAdminData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('put')(apiConfigs.admin);
export const deleteAdminData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('delete')(apiConfigs.admin);

//identity
export const fetchIdentityData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('get')(apiConfigs.identity);
export const postIdentityData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('post')(apiConfigs.identity);
export const putIdentityData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('put')(apiConfigs.identity);
export const deleteIdentityData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('delete')(apiConfigs.identity);
export const patchIdentityData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(props)('patch')(apiConfigs.identity);

//profile
export const fetchProfileData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(createCommandCenterHeaders(props))('get')(
    apiConfigs.default
  );
export const postProfileData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(createCommandCenterHeaders(props))('post')(
    apiConfigs.default
  );
export const putProfileData = <TReturn>(props: InitialBuildRequestProps) =>
  generateApiRequestType<TReturn>(createCommandCenterHeaders(props))('put')(
    apiConfigs.default
  );
