import React from 'react';
import {
  INotificationService,
  NotificationService
} from './NotificationService';

const { createContext, useContext } = React;

const NotificationContext = createContext<INotificationService>(null);

type Props = {
  children: React.ReactNode;
  notificationService?: INotificationService;
};

export const NotificationProvider = (props: Props) => {
  const notificationService: INotificationService =
    props.notificationService || new NotificationService();

  return (
    <NotificationContext.Provider value={notificationService}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (): INotificationService => {
  return useContext(NotificationContext);
};
