import React from 'react';
import { UseDownloadContext } from '../../services/Download';
import { OperationalSystemsType } from '../../enums';
import { DropdownOptionsButton } from '../DropdownOptionsButton/DropdownOptionsButton';
import { getOSIcon } from '../OsIcon/OsIcon';

import {
  CloudDownloadIconSC,
  DownloadAgentRowOption
} from './DownloadAgentButton.styles';

const dropDownAgentButtonOptions = [
  {
    os: 'win',
    template: () => {
      return (
        <DownloadAgentRowOption>
          {getOSIcon(OperationalSystemsType.Windows)} For Windows
        </DownloadAgentRowOption>
      );
    }
  },
  {
    os: 'mac',
    template: () => {
      return (
        <DownloadAgentRowOption>
          {getOSIcon(OperationalSystemsType.Apple)} For Mac
        </DownloadAgentRowOption>
      );
    }
  },
  {
    os: 'chrome',
    template: () => {
      return (
        <DownloadAgentRowOption>
          {getOSIcon(OperationalSystemsType.Chrome)} For Chrome
        </DownloadAgentRowOption>
      );
    }
  }
];

const isEdgeBrowser = () => {
  const edge = window.navigator.userAgent.indexOf('Edge/');
  if (edge > 0) {
    return true;
  }

  // other browser
  return false;
};

const downloadFromUrl = (url) => {
  if (isEdgeBrowser()) {
    window.location.href = url;
  } else {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export function DownloadAgentButton() {
  const { downloadService } = UseDownloadContext();

  const handleChangeOption = (event, option) => {
    const { os } = option;

    downloadService
      .downloadAgent(os)
      .then((response) => {
        const { url } = response;
        downloadFromUrl(url);
      })
      .catch(() => {
        //what should happen here?
      });
  };

  return (
    <DropdownOptionsButton
      id="fb0b627d-86af-4d9d-b03b-b027cd2c722a"
      icon={<CloudDownloadIconSC />}
      dropDownOptions={dropDownAgentButtonOptions}
      onChangeOption={handleChangeOption}
      disableSelectedItem
    >
      Agent
    </DropdownOptionsButton>
  );
}
