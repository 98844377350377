import React, { useEffect, useState } from 'react';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { useReportFilterStore } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshState } from '../../common/components/ReportFilters/hooks';
import { ActivityDashboardHeader } from '../components/ActivityDashboard/ActivityDashboardHeader';
import { ActivityDashboardWrapper } from '../utils/activityDashboardWrapper';

const ActivityDashboardView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <ActivityDashboardHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
      />
      <ActivityDashboardWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
      />
    </>
  );
};

export default ActivityDashboardView;

export const ActivityDashboardViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <ActivityDashboardView />
  </ReactivTrakComponentWrapper>
);
