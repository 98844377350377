'use strict';

angular.module('app').service('computerReportService', ['$http', 'envConfig',
    function($http, envConfig) {
        this.getComputerReport = function () {
            return $http.get(envConfig.apiUrl() + '/api/settings/storage/computer-report');
        };

        this.getComputerManagementReport = function (params) {
            return $http.get(envConfig.apiUrl() + '/api/ComputerManagement/computers' + params);
        };

        this.saveComputerManagementChanges = function (payload) {
            return $http.post(envConfig.apiUrl() + '/api/ComputerManagement/computers', payload);
        };
    }
]);
