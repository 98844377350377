import React, { useEffect } from 'react';
import { DashboardSubheader } from '../templates';
import { useVirtualTeamsVisibilityState } from '../../hooks';
import { WidgetProps } from '../../models';
import authorizationService from '../../../common/helpers/authorization';
import { noUsersReportedToday } from '../../constants';
import { convertObjectToQs } from '../../../common/helpers';

export default function VirtualTeamVisibilitySubheader(props: WidgetProps) {
  const { refreshTimestamp, reportFilters } = props;

  const { virtualTeamHours, isTileError, isLoading, init } =
    useVirtualTeamsVisibilityState();

  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery, refreshTimestamp]
  );

  const hasWorkingHoursAccess = authorizationService.hasRouteByName(
    'app.reports.workingHours'
  );

  return (
    <DashboardSubheader
      {...virtualTeamHours}
      topLineSubheader="Virtual Team Visibility (Today)"
      hasTileError={isTileError}
      isLoading={isLoading}
      url={hasWorkingHoursAccess ? 'app.reports.workingHours' : ''}
      params={{ range: 'Today' }}
      infoIconText="Includes employees with more than 1 minute of activity today."
      id="5c40fd30-a797-498f-a363-0a27db9f72c9"
      zeroDataMsg={noUsersReportedToday}
      zeroDataLink="https://support.activtrak.com/hc/en-us/articles/19321210019483"
    />
  );
}
