import { apiService } from '../../common/helpers';
import { getWebApiConfig } from '../../common/config';
import { HttpResponse } from '../../common/models';
import { IDuration } from '../models/IDuration';
import { IActiveTimeSettingsService } from '../models';

export const ActiveTimeSettingsService = (): IActiveTimeSettingsService => {
  return {
    putDuration: async (duration: number): Promise<HttpResponse<object>> => {
      return await apiService.put<object>(
        `admin/v1/accountsettings/agent/activityduration/${duration}`,
        {
          ...getWebApiConfig()
        }
      );
    },
    postDuration: async (duration: number): Promise<HttpResponse<object>> => {
      return await apiService.post<object>(
        `admin/v1/accountsettings/agent/activityduration/${duration}`,
        {
          ...getWebApiConfig()
        }
      );
    },
    fetchDuration: async (): Promise<HttpResponse<IDuration>> => {
      return await apiService.get<IDuration>(
        'admin/v1/accountsettings/agent/activityduration',
        {
          ...getWebApiConfig()
        }
      );
    },
    deleteDuration: async (): Promise<HttpResponse<void>> => {
      return await apiService.delete(
        'admin/v1/accountsettings/agent/activityduration',
        {
          ...getWebApiConfig()
        }
      );
    }
  };
};
