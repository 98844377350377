import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getPrivacySettings } from '../../../common/hooks/privacySettingsStore';
import { TabPanel } from '../../../common/components/Tabs/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ICategory } from '../../../common/models';
import { useActivityReportPanels } from './useActivityReportPanels';
import ClassificationGroupContainer from '../ClassificationGroupContainer';
import { IActivityDetail } from '../../models';
import TotalProgress from '../TotalProgress';
import { ActivityType } from '../../../common/enums';
import { ClassificationsReportsPanel } from '../../styles/Classifications.styles';

type ActivityReportsProps = {
  activityDetail: IActivityDetail;
  isActivityDetailLoading: boolean;
  currentActivityIndex: number;
  shouldShowGroupClassifications: boolean;
  id: number;
  type: ActivityType;
  categories: ICategory[];
  isDisabled?: boolean;
};

export default function ActivityReports({
  activityDetail,
  isActivityDetailLoading,
  currentActivityIndex,
  shouldShowGroupClassifications,
  id,
  type,
  categories,
  isDisabled
}: ActivityReportsProps) {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTab(0);
  }, [id, type]);

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    newTab: number
  ) => {
    setTab(newTab);
  };

  const { activityAllowed = false } = getPrivacySettings();
  const panels = useActivityReportPanels(
    activityDetail,
    isActivityDetailLoading,
    activityAllowed
  );

  return (
    <>
      <Grid container direction="row-reverse">
        <Grid item xs={12} sm={5}>
          <TotalProgress currentActivityIndex={currentActivityIndex} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Tabs value={tab} onChange={handleTabChange}>
            {panels.map((panel, index) => (
              <Tab label={panel.label} key={`report-tab-${index}`} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <ClassificationsReportsPanel elevation={3}>
        {panels.map((panel, index) => (
          <TabPanel value={tab} index={index} key={`report-tab-panel-${index}`}>
            {panel.element}
          </TabPanel>
        ))}
        {shouldShowGroupClassifications && (
          <ClassificationGroupContainer
            id={id}
            type={type}
            categories={categories}
            isDisabled={isDisabled}
          />
        )}
      </ClassificationsReportsPanel>
    </>
  );
}
