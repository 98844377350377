import { useCallback, useRef, useState } from 'react';
import { cleanup, openPopup } from '../utils/Oauth.utils';
import { IOauthState, IOauthStateProps } from '../models/IOauthState';
import {
  getLocalStorageItem,
  removeLocalStorageItem
} from '../../common/helpers';
import { OAUTH_PROCESSED_STATE } from '../constants/Oauth';
import { getAccountSettings } from '../../common/helpers/accountSettings/accountSettingsStore';

export const useOauthState = (
  props: Partial<IOauthStateProps>
): IOauthState => {
  const { clientOAuthConfig } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const popupRef = useRef<Window | null>();
  const intervalBailRef = useRef<
    string | number | NodeJS.Timeout | undefined
  >();
  const intervalConsentRef = useRef<
    string | number | NodeJS.Timeout | undefined
  >();
  const accountSettings = getAccountSettings();
  const focusPopup = useCallback(() => {
    popupRef.current?.focus();
  }, []);

  const startOauthFlow = useCallback(
    (instanceId) => {
      setIsLoading(true);
      setAuthorized(false);
      removeLocalStorageItem(
        OAUTH_PROCESSED_STATE.replace(':instanceId', instanceId)
      );

      const accountId = accountSettings?.account;
      const state = `${accountId}|${instanceId}`;

      popupRef.current = openPopup(clientOAuthConfig, state);

      // Poll to check if popup was closed before completing oauth flow
      intervalBailRef.current = setInterval(() => {
        const popupClosed =
          !popupRef.current?.window || popupRef.current?.window?.closed;
        if (popupClosed) {
          setIsLoading(false);
          cleanup(intervalBailRef, popupRef);
        }
      }, 500);

      // Poll to check if auth consent was granted
      intervalConsentRef.current = setInterval(() => {
        //check if oauthIdentityToken is now in local storage
        const consentSuccess: boolean =
          getLocalStorageItem(
            OAUTH_PROCESSED_STATE.replace(':instanceId', instanceId)
          )?.toLowerCase() === 'true';
        if (consentSuccess) {
          setAuthorized(consentSuccess);
          setIsLoading(false);
          cleanup(intervalConsentRef, popupRef);
          removeLocalStorageItem(
            OAUTH_PROCESSED_STATE.replace(':instanceId', instanceId)
          );
        }
      }, 500);

      //unmount
      return () => {
        if (intervalBailRef.current) clearInterval(intervalBailRef.current);
        if (intervalConsentRef.current)
          clearInterval(intervalConsentRef.current);
        removeLocalStorageItem(
          OAUTH_PROCESSED_STATE.replace(':instanceId', instanceId)
        );
      };
    },
    [accountSettings?.account, clientOAuthConfig]
  );

  return { isLoading, authorized, startOauthFlow, focusPopup };
};
