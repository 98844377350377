import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appAccountAudit: IRoute = {
  name: 'app.account.audit',
  stateDefinition: {
    url: '/account/audit',
    template: '<audit-log></audit-log>',

    data: {
      pageTitle: 'Security Audit',
      filter: {
        productivity: false,
        export: true,
        auditAlarm: true,
        paidFunctionalityEnabled: true
      },
      exportText: 'export',
      reportName: 'auditReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appAccountAudit;
