import React, { useEffect, useState } from 'react';
import { IGridColumn } from '../../../models';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
  BaseTooltipSC,
  GridColumnsFilterActionsSC
} from '../../ReportFilters/styles';

type GridColumnsFilterProps = {
  columns: IGridColumn[];
  onSubmit: (columns: IGridColumn[]) => void;
  onRestoreDefaults: () => void;
};

export const GridColumnsFilter = (props: GridColumnsFilterProps) => {
  const { columns, onSubmit, onRestoreDefaults } = props;

  const [currentColumnSettings, setCurrentColumnSettings] = useState<
    IGridColumn[]
  >([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (columns?.length > 0) {
      setCurrentColumnSettings(columns);
    }
  }, [columns]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = (field: string) => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) =>
        column.field === field ? { ...column, hidden: !column.hidden } : column
      );
    });
  };

  const handleSubmit = () => {
    onSubmit(currentColumnSettings);
  };

  const handleSelectAll = () => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) => ({ ...column, hidden: false }));
    });
  };

  const hasNoSelections = currentColumnSettings.every(
    (column) => column.hidden === true
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <BaseTooltipSC
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleClose}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            title={
              <>
                <GridColumnsFilterActionsSC>
                  <Button onClick={onRestoreDefaults}>Restore Defaults</Button>
                  <Button variant="contained" onClick={handleSelectAll}>
                    Select All
                  </Button>
                </GridColumnsFilterActionsSC>

                <List sx={{ overflowY: 'auto', height: 320 }}>
                  {currentColumnSettings.map((column) => (
                    <ListItemButton
                      onClick={() => handleSelect(column.field)}
                      key={column.field}
                      sx={{ pt: 0, pb: 0 }}
                    >
                      <Checkbox color="primary" checked={!column.hidden} />
                      <ListItemText>{column.fieldName}</ListItemText>
                    </ListItemButton>
                  ))}
                </List>
                <GridColumnsFilterActionsSC>
                  <Button onClick={handleClose}>Cancel</Button>
                  <ApplyButton
                    isDisabled={hasNoSelections}
                    onSubmit={handleSubmit}
                  />
                </GridColumnsFilterActionsSC>
              </>
            }
          >
            <Button
              variant="outlined"
              onClick={() => setIsOpen(!isOpen)}
              endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            >
              Columns
            </Button>
          </BaseTooltipSC>
        </div>
      </ClickAwayListener>
    </>
  );
};

type ApplyButtonProps = { isDisabled: boolean; onSubmit: () => void };

const ApplyButton = (props: ApplyButtonProps) => {
  const { isDisabled, onSubmit } = props;

  if (isDisabled) {
    return (
      <Tooltip title="There must be at least 1 column selected">
        <span>
          <Button variant="contained" disabled={isDisabled}>
            Apply
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button variant="contained" onClick={onSubmit}>
      Apply
    </Button>
  );
};
