import React from 'react';
import {
  DialogActions,
  Button,
  DialogContent,
  Box,
  Dialog,
  DialogTitle
} from '@mui/material';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  subscriptionsCount: number;
};

export const DeleteSubscriptionsConfirmationModal = (props: ComponentProps) => {
  const {
    open,
    onClose,
    onDelete = () => {
      /**/
    },
    subscriptionsCount
  } = props;

  const close = () => {
    onClose();
  };

  const handleDelete = async (): Promise<void> => {
    onDelete();
    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md">
      <DialogTitle>Delete Subscription</DialogTitle>
      <DialogContent>
        <Box>
          Are you sure that you’d like to delete{' '}
          <strong>{subscriptionsCount}</strong> subscription(s)? This action
          cannot be undone.
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={close} autoFocus>
            Cancel
          </Button>
          <Button
            id="id_2230ea39-fd45-42bd-85f6-6b97e20e8bed"
            onClick={handleDelete}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
