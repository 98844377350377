'use strict';

angular.module('app').directive('atSwitch', atSwitch);

function atSwitch() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions',
            model: '=atModel'
        },
        template: require('views/widgets/atSwitch.html'),
        controller: atSwitchCtrl
    };
}

atSwitchCtrl.$inject = ['$scope'];

function atSwitchCtrl($scope) {
    $scope.handleChange = function () {
        if ($scope.disabled) {
            return;
        }

        $scope.model = !$scope.model;
        if (typeof $scope.options.onChange === 'function') {
            $scope.options.onChange($scope.model);
        }
    };

    $scope.$watch('options', function (options) {
        if (
            options &&
            options.labels &&
            options.labels.length > 0 &&
            !options.labels[0].text
        ) {
            options.labels = _.map(options.labels, function (label) {
                return { text: label, value: label };
            });
        }

        if (options) {
            $scope.disabled = options.disabled === true;
        }
    });
}
