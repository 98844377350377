import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const NoResultsContainerSC = styled(Box)`
  margin-top: 50px;
  text-align: center;
`;

export const NoResultsLabelSC = styled(Typography)`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
`;
