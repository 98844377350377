'use strict';

const { inMemoryStore } = require('activtrak-ui-utilities');
const teamPulseDataControl = require('_app/serviceFunctions/teamPulseDataControl');

// // Start app (in WEB build)
angular.element(document).ready(function () {
  
  var tpdcInstance = teamPulseDataControl.constructor();
  inMemoryStore.set('teamPulseDataControl', tpdcInstance);

  angular.bootstrap(document, ['app', 'login', 'cc']);

  if (window.location.hash === '') {
    history.pushState({}, 'init', '/#/app/home');
  }
});
