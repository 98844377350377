import React from 'react';
import Typography from '@mui/material/Typography';
import { aqua1, primaryColor, red2 } from '../../../common/constants';
import { isNegative } from '../../../common/helpers';
import { IDashboardActivityDiff } from '../../models';

export const DashboardActivityDiff = (props: IDashboardActivityDiff) => {
  const { diff, title } = props;

  let style = { fontWeight: 400, fontColor: primaryColor };
  let formattedDiff = diff?.toString();

  if (diff > 0) {
    style = { fontWeight: 700, fontColor: aqua1 };
    formattedDiff = `+${formattedDiff}`;
  } else if (isNegative(diff)) {
    style = { fontWeight: 700, fontColor: red2 };
  }

  return (
    <Typography fontWeight={style.fontWeight} color={style.fontColor}>
      {`${formattedDiff}%`}&nbsp;
      <Typography component="span" color={primaryColor}>
        {title}
      </Typography>
    </Typography>
  );
};
