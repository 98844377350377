import { getRouteDefinitions, getRoutes, getRouteByUrl } from '../../_reactivtrak/src/common/config/routing/routes';

export const connect = async (angular) => {
    angular.module('app').config([
        '$stateProvider',
        '$urlRouterProvider',
        function ($stateProvider, $urlRouterProvider) {
            // Create routes from state definitions
            getRouteDefinitions()?.forEach((route) => {
                $stateProvider.state(route.name, route.stateDefinition);
                $urlRouterProvider.otherwise('/app/loading');
            });
        }
    ]);
    angular.module('app').service('routesService', RoutesService);
    function RoutesService() {
        this.getRoutes = getRoutes;
        this.getRouteByUrl = getRouteByUrl;
    }
};
