import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Typography
} from '@mui/material';

type UpdateGroupModalProps = {
  open: boolean;
  onClose: () => void;
  onUpdate: (forceDUpdate: boolean) => Promise<void>;
};

export default function ForceUpdateGroupsSettingsDialog(
  props: UpdateGroupModalProps
) {
  const { open, onClose, onUpdate } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdate = async (): Promise<void> => {
    try {
      setIsUpdating(true);
      onUpdate(true);
    } catch (error) {
      //groups state handles this
    } finally {
      close();
      setIsUpdating(false);
    }
  };

  const close = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        close();
      }}
    >
      <DialogTitle>
        Are you sure you want to disable Active Directory Groups?
      </DialogTitle>
      <DialogContent>
        <Typography>
          This will permanently delete all associated viewable groups, group
          alarms, and blocked websites. This action can not be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={close}
          autoFocus
          className="cancel-btn"
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          disabled={isUpdating}
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
}
