import React from 'react';
import { ChartCheckboxLegendColumnsProps } from '../models/ChartCheckboxLegend.props';
import { CheckboxLegendColumnsSC } from '../styles/ChartCheckboxLegend.styles';
import { ChartCheckboxLegend } from './ChartCheckboxLegend';

export const ChartCheckboxLegendColumns = (
  props: ChartCheckboxLegendColumnsProps
) => {
  const { labels, colors, columnWidth, currentSelections, onClick } = props;

  return (
    <CheckboxLegendColumnsSC
      aria-labelledby="checkbox-legend-list"
      columnwidth={columnWidth}
    >
      <ChartCheckboxLegend
        labels={labels}
        colors={colors}
        currentSelections={currentSelections}
        onClick={onClick}
      />
    </CheckboxLegendColumnsSC>
  );
};
