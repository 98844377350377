import React from 'react';
import { useAuthorization } from '../../../common/services/Authorization';
import { Role } from '../../../common/enums';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { RiskLevelHeaderProps } from '../models/RiskLevelTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const RiskLevelHeader = (props: RiskLevelHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users } = reportFilters;

  const authorizationService = useAuthorization();
  const ignoreDateRestrictions: boolean = authorizationService.hasRole(
    Role.SuperAdmin
  );

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: 0 }}>
          <AcademyButton />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
