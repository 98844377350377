import { find, head } from 'lodash';
import { IActivityDetail, IActivityDetailDto } from '../models';
import { getDefaultConfig, BaseClient } from '../../common/config';
import { ActivityType } from '../../common/enums';
import { mapToActivityDetail } from '../utils';

export interface IActivityDetailService {
  getById(activityId: number, type: ActivityType): Promise<IActivityDetail>;
}

export default class ActivityDetailService
  extends BaseClient
  implements IActivityDetailService
{
  private activityDetails: IActivityDetail[];

  constructor() {
    super();
    this.activityDetails = undefined;
  }

  private getAllRecent = async (
    id?: number,
    type?: ActivityType
  ): Promise<IActivityDetail[]> => {
    let url = 'api/classification/activitydetails/recent';

    if (id && type) {
      url += `?activityId=${id}&type=${type}`;
    }

    const response = await this.apiService.get<IActivityDetailDto[]>(url, {
      ...getDefaultConfig()
    });
    return response.data.map(mapToActivityDetail);
  };

  public async getById(
    id: number,
    type: ActivityType
  ): Promise<IActivityDetail> {
    if (this.activityDetails === undefined) {
      const allRecent = await this.getAllRecent();
      this.activityDetails = allRecent;
    }
    let actvityDetailsById = find(this.activityDetails, {
      id: id,
      type: type
    });

    if (!actvityDetailsById) {
      actvityDetailsById = head(await this.getAllRecent(id, type));
    }

    return actvityDetailsById;
  }
}
