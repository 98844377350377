import { http as httpService } from '_app/http';
import { constructor } from '_app/serviceFunctions/realtimeServiceFunctions';

angular.module('app').service('realtimeService', RealtimeService);

RealtimeService.$inject = [
    'envConfig',
    'messagesService',
    'templateServiceFunctions',
    'localStorageService',
    'authorizationService'
];

function RealtimeService(envConfig, msg, templateServiceFunctions, localStorageService, authorizationService) {
    const realtimeServiceFunctions = constructor(
        httpService,
        envConfig,
        msg,
        templateServiceFunctions,
        localStorageService,
        authorizationService
    );

    angular.extend(this, realtimeServiceFunctions);
}
