import React from 'react';
import { ActivityUrl } from '../../common/components/ActivityUrl';
import { Card, CardContent } from '@mui/material';
import { ActivityType, ClassificationType } from '../../common/enums';
import { IClassificationActivity } from '../../common/models';
import { classificationClasses } from '../styles/Classifications.styles';
import { durationFormat } from '../../common/helpers/time';
import { SkeletonLoader } from '../../common/components/SkeletonLoader';

type ActivityDetailProps = {
  activity: IClassificationActivity;
  dateAdded: string;
  totalUsers: number;
  classificationType: ClassificationType;
};

export default function ActivityDetails(props: ActivityDetailProps) {
  const { activity, dateAdded, totalUsers, classificationType } = props;

  const duration = durationFormat(activity?.duration, true);
  const isDomain = activity?.type === ActivityType.Website;
  const className = isDomain ? classificationClasses.hyperlink : {};

  return (
    <Card elevation={3} data-testid="activity-details-card">
      <CardContent sx={{ mb: 2 }}>
        <div style={classificationClasses.activityCardHeader}>
          Activity Details
        </div>
        <div>
          {activity ? (
            <div style={{ marginBottom: '16px' }}>
              <ActivityUrl
                sx={className}
                activity={activity}
                isDomain={isDomain}
              />
            </div>
          ) : (
            <SkeletonLoader testId="activity-skeleton-loader" />
          )}
          <p style={classificationClasses.activityDetailsText}>
            First Seen: {dateAdded ? dateAdded : <SkeletonLoader />}
          </p>
          <p style={classificationClasses.activityDetailsText}>
            Classification Type:{' '}
            {classificationType ? classificationType : <SkeletonLoader />}
          </p>
          <p style={classificationClasses.activityDetailsText}>
            Total Duration: {duration ? duration : <SkeletonLoader />}
          </p>
          <p
            style={{
              ...classificationClasses.activityDetailsText,
              marginBottom: 0
            }}
          >
            Total Users: {totalUsers ? totalUsers : <SkeletonLoader />}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
