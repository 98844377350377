import { IStorageInformationDto } from '../models';

export const formatTotalStorage = (storage: number) => {
  let totalStorage = '';
  if (storage > 1000000) {
    totalStorage = storage / 1000000 + ' TB';
  } else {
    if (storage > 1000) {
      totalStorage = storage / 1000 + ' GB';
    } else {
      totalStorage = storage + ' MB';
    }
  }
  return totalStorage;
};

export const mapToStorageInfo = (response: IStorageInformationDto) => {
  return {
    totalStorage: formatTotalStorage(response?.storage),
    totalLog: response.lcount,
    totalScreenshot: response.scount,
    totalVideo: response.vcount
  };
};

