import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import contentBlockModalControllerTemplate from 'views/modals/contentBlockModal.html?raw';

// Register report component
import './webhookLog.component.js';

angular.module('app').controller('WebhookLogCtrl', WebhookLogCtrl);

WebhookLogCtrl.$inject = [
    '$scope',
    '$timeout',
    'customUibModal',
    'messagesService',
    'webhookLogService',
    'pageSizeService',
    'webhookLogSampleService',
    '$document',
    'gridPropertiesService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'authorizationService'
];

function WebhookLogCtrl(
    $scope,
    $timeout,
    customUibModal,
    msg,
    webhookLogService,
    pageSizeService,
    webhookLogSampleService,
    $document,
    gridPropertiesService,
    atHelperFunctions,
    templateServiceFunctions,
    authorizationService
) {
    $scope.isSmallWindow = window.innerWidth < 992;
    let oldStateValue = -1;
    $scope.reportFilters = {};

    $scope.isNotificationsEnabled = authorizationService.hasFeature('isNotificationsEnabled');

    $scope.canUpgrade = authorizationService.hasRouteByName('app.account.upgrade');

    $scope.isLoading = true;

    $scope.formatDate = function (date) {
        return atHelperFunctions.formatDate(date);
    };

    $scope.webhookGridHeight = function () {
        return 150;
    };

    $scope.webhookDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const args = generateParameters($scope.reportFilters, {});
                const service = $scope.isNotificationsEnabled ? webhookLogService : webhookLogSampleService;

                $scope.isLoading = true;

                service
                    .getLog(args, {
                        params: options.data
                    })
                    .success(function (results) {
                        $scope.isLoading = false;

                        options.success(results);
                    })
                    .error(function (result) {
                        $scope.isLoading = false;

                        options.error(result);
                        if (result && (result.message || result.error)) {
                            $scope.$emit('showNotification', {
                                message: result.message || result.error,
                                color: 'danger'
                            });
                        }
                    });
            }
        },
        pageSize: 50,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        sort: {
            field: 'timestamp',
            dir: 'asc'
        },
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    $scope.showWebhookFailure = function (webhook) {
        if (!webhook.failureCode && !webhook.failureResponse) {
            return;
        }

        customUibModal.open({
            animation: false,
            windowClass: 'centered-modal',
            template: contentBlockModalControllerTemplate,
            controller: 'contentBlockModalController',
            resolve: {
                data: {
                    content: webhook.failureResponse,
                    description: msg.get('response'),
                    title: msg.get('webhookFailure', webhook.failureCode)
                }
            }
        });
    };

    $scope.showWebhookPayload = function (webhook) {
        customUibModal.open({
            animation: false,
            windowClass: 'centered-modal',
            template: contentBlockModalControllerTemplate,
            controller: 'contentBlockModalController',
            resolve: {
                data: {
                    content: webhook.body,
                    description: msg.get('payload'),
                    title: msg.get('webhookPayload')
                }
            }
        });
    };

    function stateTemplate(state) {
        return state.field || 'All';
    }

    $scope.pagerOptions = {
        dataSource: $scope.webhookDataSource,
        refresh: false,
        buttonCount: 5
    };

    $scope.webhookGridOptions = {
        autoBind: false,
        dataSource: $scope.webhookDataSource,
        filterable: {
            mode: 'row'
        },
        sortable: true,
        resizable: true,
        columnMenu: false,
        columns: [
            {
                field: 'timestamp',
                title: msg.get('dateTime'),
                filterable: false,
                sortable: true,
                template: atHelperFunctions.compactDateTemplate,
                attributes: {
                    class: 'text-nowrap text-center'
                },
                width: 180
            },
            {
                field: 'alarmName',
                title: msg.get('alarm'),
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Alarms…')
                                .attr('ng-change', "updateFilter(this, 'alarmName')")
                                .attr('ng-model', 'alarmNameFilter');
                        }
                    }
                },
                sortable: true,
                width: 320,
                attributes: {
                    class: 'text-nowrap'
                },
                template: function (webhook) {
                    return webhook.alarmName ? webhook.alarmName : '(No Name)';
                }
            },
            {
                field: 'attempts',
                title: msg.get('attempts'),
                filterable: false,
                sortable: true,
                width: 140,
                attributes: {
                    class: 'text-center'
                }
            },
            {
                field: 'state',
                title: msg.get('state'),
                width: 160,
                filterable: {
                    cell: {
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                dataSource: [
                                    {
                                        field: 'All',
                                        value: -1
                                    },
                                    {
                                        field: 'Delivered',
                                        value: 2
                                    },
                                    {
                                        field: 'Failed',
                                        value: 0
                                    },
                                    {
                                        field: 'Pending',
                                        value: 1
                                    }
                                ],
                                select: function (e) {
                                    if (e.dataItem.value === oldStateValue) {
                                        e.preventDefault();
                                    }

                                    oldStateValue =
                                        e.dataItem.value === '' || !e.dataItem.value ? '-1' : e.dataItem.value;
                                    $document[0].activeElement.blur();
                                },
                                change: function (e) {
                                    if (e.sender.value() === '-1') {
                                        const filters = $scope.webhookDataSource.filter().filters;
                                        for (let i = 0; i < filters.length; i++) {
                                            if (filters[i].field === 'state') {
                                                filters.splice(i, 1);
                                            }
                                        }
                                        $scope.webhookDataSource.filter(filters);
                                    }
                                },
                                valuePrimitive: true,
                                dataTextField: 'field',
                                dataValueField: 'value',
                                valueTemplate: stateTemplate
                            });
                        }
                    }
                },
                headerAttributes: {
                    style: 'text-align: center;'
                },
                sortable: true,
                template: function (webhook) {
                    if (webhook.state === 'Failed' && (webhook.failureCode || webhook.failureResponse)) {
                        return (
                            '<a ng-click="showWebhookFailure(dataItem)" kendo-tooltip="" k-options="{ content: \'View failure details\', position: \'top\', animation: { open: { effects: \'fade:in\' }, close: { effects: \'fade:out\' } } }" data-role="tooltip" class="m-r-10"><i class="fa fa-eye"></i></a>' +
                            webhook.state
                        );
                    }

                    return '<span class="m-r-10 at-greyed"><i class="fa fa-eye"></i></span>' + webhook.state;
                }
            },
            {
                field: 'body',
                title: msg.get('payload'),
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Payloads…')
                                .attr('ng-change', "updateFilter(this, 'body')")
                                .attr('ng-model', 'bodyFilter');
                        }
                    }
                },
                template: function (webhook) {
                    return (
                        '<a ng-click="showWebhookPayload(dataItem)" kendo-tooltip="" k-options="{ content: \'View payload details\', position: \'top\', animation: { open: { effects: \'fade:in\' }, close: { effects: \'fade:out\' } } }" data-role="tooltip" class="m-r-10"><i class="fa fa-eye"></i></a>' +
                        webhook.body
                    );
                },
                sortable: false,
                attributes: {
                    class: 'text-nowrap no-tooltip'
                }
            }
        ],
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.webhookGridHeight()),
        dataBound: function (e) {
            gridPropertiesService.bindFilterAutoComplete(e, true);
        }
    };

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap:not(.no-tooltip)'
    });

    let filterDelay;
    $scope.updateFilter = function (e, type) {
        $timeout.cancel(filterDelay);
        filterDelay = $timeout(function () {
            if (type === 'alarmName') {
                $scope.webhookDataSource.filter({
                    field: 'alarmName',
                    operator: 'contains',
                    value: e.alarmNameFilter
                });
            }
            if (type === 'body') {
                $scope.webhookDataSource.filter({
                    field: 'body',
                    operator: 'contains',
                    value: e.bodyFilter
                });
            }
        }, 1500);
    };

    $(window).on('resize.doResize', function () {
        $scope.$apply(function () {
            const previousIsSmallWindow = $scope.isSmallWindow;
            $scope.isSmallWindow = window.innerWidth < 992;

            if (previousIsSmallWindow !== $scope.isSmallWindow) {
                $scope.bindReport();
            }
        });
    });

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        pageSizeService.dataSourceReload($scope.webhookDataSource);
    };

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('webhook');
        }
    });
}

angular.module('app').service('webhookLogService', WebhookLogService);

WebhookLogService.$inject = ['$http', 'envConfig'];

function WebhookLogService($http, envConfig) {
    this.getLog = function (parameters, data) {
        return $http.get(envConfig.apiUrl() + '/api/webhooklog?' + parameters, data);
    };
}
