import {
  IReportModalListItem,
  ITopCategories,
  ITopCategoriesDto,
  ITopCategory,
  ITopCategoryActivities,
  ITopCategoryActivitiesDto,
  ITopCategoryActivityDto,
  ITopCategoryDto
} from '../models';
import { getTimeStrFromSeconds, convertToPercent } from '../../common/helpers';
import {
  aqua9,
  blue1,
  blue8,
  gray0,
  gray7,
  green1,
  green3,
  orange2,
  orange5,
  purple2,
  red2,
  yellow1
} from '../../common/constants';

const mapTopCategory = (
  cat: ITopCategoryDto,
  totalDuration: number
): ITopCategory => {
  const catDuration = cat.duration;
  return {
    id: cat.categoryId,
    category: cat.category,
    percent: convertToPercent(catDuration, totalDuration),
    duration: getTimeStrFromSeconds(catDuration)
  };
};

const getTopCategoriesData = (data: ITopCategoriesDto) => {
  const labels = data?.categories?.length
    ? data.categories.map((item) => item.category)
    : [];
  const categories = data?.categories?.length
    ? data.categories.map((item) => mapTopCategory(item, data?.totalDuration))
    : [];
  return {
    labels: labels,
    datasets: [
      {
        data: categories,
        backgroundColor: [
          blue1,
          green3,
          purple2,
          orange2,
          red2,
          yellow1,
          orange5,
          green1,
          aqua9,
          blue8
        ],
        checkmarkColor: [
          gray0,
          gray7,
          gray0,
          gray7,
          gray0,
          gray7,
          gray7,
          gray7,
          gray0,
          gray7
        ],
        borderWidth: 0
      }
    ]
  };
};

export const mapTopCategories = (cats: ITopCategoriesDto): ITopCategories => {
  return {
    chartData: getTopCategoriesData(cats),
    lastUpdated: cats.lastUpdated
  };
};

const mapToCategoryActivity = (
  activity: ITopCategoryActivityDto
): IReportModalListItem => {
  return {
    name: activity.activityName,
    value: getTimeStrFromSeconds(activity.duration)
  };
};

export const mapTopCategoryActivities = (
  activities: ITopCategoryActivitiesDto
): ITopCategoryActivities => {
  return {
    category: activities.categoryName,
    activities: activities.categoryData?.map((item) =>
      mapToCategoryActivity(item)
    ),
    totalDuration: getTimeStrFromSeconds(activities.totalDuration)
  };
};
