import {
    getTeamPulseData as getTeamPulseDataApi,
    getRealtimeData as getRealtimeDataApi
} from '../../utilities/api/teamPulseApi';
import { pollSettings } from '../../utilities/pollSettings';

function constructor(teamPulseDataControl) {
    let _poller = {
        started: false,
        paused: false,
        filterMode: teamPulseDataControl.filterMode.value,
        apiConfig: teamPulseDataControl.apiConfig.value,
        teamPulse: {
            pollTimer: null,
            error: false,
            skipLoading: false
        },
        realtime: {
            pollTimer: null,
            error: false
        }
    };

    let _subscribers = {
        teamPulse: [],
        realtime: []
    };

    const pollingAllowed = () => {
        const pollerPages = ['/app/teampulse', '/app/home'];
        const startIndex = window.location.href.indexOf('#') + 1;
        const endIndex =
            window.location.href.indexOf('?') >= 0 ? window.location.href.indexOf('?') : window.location.href.length;
        const currentPage = window.location.href.substring(startIndex, endIndex);
        const allowedPage = _.filter(pollerPages, (fragment) => fragment === currentPage);

        return (
            _poller.started && // has poller started
            !_poller.paused && // has poller been paused
            _poller.filterMode && // Do we have a filter mode set
            _poller.apiConfig && // Do we have api configuration set
            allowedPage.length > 0 // Are we on a page that should poll
        );
    };

    // poll team pulse data
    const pollTeamPulse = async () => {
        if (_poller.teamPulse.pollTimer) {
            clearTimeout(_poller.teamPulse.pollTimer);
        }

        // Check if we should poll
        if (!pollingAllowed()) {
            return;
        }

        const setPoll = () => {
            _poller.teamPulse.pollTimer = setTimeout(() => {
                _poller.teamPulse.skipLoading = true;
                pollTeamPulse();
            }, pollSettings.teamPulse.pollDelay);
        };

        // if (!_poller.teamPulse.skipLoading) {
        //   _teamPulseDataControl.showLoading = true;
        // }

        try {
            const data = await getTeamPulseDataApi({
                filterMode: _poller.filterMode,
                url: _poller.apiConfig.url,
                reportingUrl: _poller.apiConfig.reportingUrl,
                websocketHttpsUrl: _poller.apiConfig.websocketHttpsUrl,
                isWidget: _poller.apiConfig.isWidget,
                parameters: _poller.apiConfig.parameters
            });
            pollRealtime();
            // console.log('Team Pulse data loaded', _subscribers.teamPulse, data);
            _.forEach(_subscribers.teamPulse, (subscriber) => {
                if (typeof subscriber.callback === 'function') {
                    subscriber.callback(data);
                }
            });
            setPoll();
        } catch (e) {
            if (e === 'params updated') {
                return;
            }

            console.error(e);
            setPoll();
        }
    };

    // subscribe to team pulse update
    const teamPulseSubscribe = ({ id, callback } = {}) => {
        // console.log('teamPulseSubscribe', id, callback);
        // If first subscriber, add to list and kick off polling
        if (_subscribers.teamPulse.length === 0) {
            _subscribers.teamPulse.push({ id, callback });
            _poller.started = true;
            pollTeamPulse();
        } else {
            // Else check if already a subscriber
            let existingSubscriber = _.find(_subscribers.teamPulse, (subscriber) => subscriber.id === id);

            // Update callback if already a subscriber
            // Else add to subscriber list
            if (existingSubscriber) {
                existingSubscriber.callback = callback;
            } else {
                _subscribers.teamPulse.push({ id, callback });
            }
        }
    };

    // poll team pulse data
    const pollRealtime = async () => {
        if (_poller.realtime.pollTimer) {
            clearTimeout(_poller.realtime.pollTimer);
        }

        // Check if we should poll
        if (!pollingAllowed()) {
            return;
        }

        // console.log('Polling Realtime Data');

        const setPoll = () => {
            _poller.realtime.pollTimer = setTimeout(() => {
                pollRealtime();
            }, pollSettings.realtime.pollDelay);
        };

        try {
            const data = await getRealtimeDataApi({
                filterMode: _poller.filterMode,
                url: _poller.apiConfig.websocketHttpsUrl,
                parameters: _poller.apiConfig.parameters
            });
            // console.log('Realtime data loaded', _subscribers.realtime, data);
            _.forEach(_subscribers.realtime, (subscriber) => {
                if (typeof subscriber.callback === 'function') {
                    subscriber.callback(data);
                }
            });
            setPoll();
        } catch (e) {
            console.error(e);
            setPoll();
        }
    };

    // subscribe to realtime update
    const realtimeSubscribe = ({ id, callback } = {}) => {
        // console.log('realtimeSubscribe', id, callback);
        // Check if already a subscriber
        let existingSubscriber = _.find(_subscribers.realtime, (subscriber) => subscriber.id === id);

        // Update callback if already a subscriber
        // Else add to subscriber list
        if (existingSubscriber) {
            existingSubscriber.callback = callback;
        } else {
            _subscribers.realtime.push({ id, callback });
        }
    };

    // get real-time data
    // poll real-time data
    // start polling
    const startPolling = () => {
        _poller.started = true;
        _poller.paused = false;
        pollTeamPulse();
    };

    const stopPolling = () => {
        _poller.started = false;
    };

    // pause polling
    const pollingPaused = (value) => {
        _poller.paused = value;

        if (!_poller.paused) {
            pollTeamPulse();
        }
    };

    // set filterMode
    const setFilterMode = (filterMode) => {
        // If poller has started and no filter mode was set,
        // start poll again
        if (filterMode && !_poller.filterMode && _poller.started) {
            _poller.filterMode = filterMode;
            pollTeamPulse();
        } else {
            _poller.filterMode = filterMode || _poller.filterMode;
        }
    };

    const setApiConfig = (apiConfig) => {
        // If poller has started and no parameters were set,
        // start poll again
        if (apiConfig && !_poller.apiConfig && _poller.started) {
            _poller.apiConfig = apiConfig;
            pollTeamPulse();
        } else {
            _poller.apiConfig = apiConfig || _poller.apiConfig;
        }
    };

    return {
        pollTeamPulse,
        teamPulseSubscribe,
        realtimeSubscribe,
        startPolling,
        stopPolling,
        pollingPaused,
        setFilterMode,
        setApiConfig
    };
}

export { constructor };
