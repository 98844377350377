import atHelperFunctions from '../../../../_app/serviceFunctions/atHelperFunctions';

export const formatOnboardingTimeDateStrings = (
  dateTime: string
): {
  timeString?: string;
  dateString?: string;
} => {
  if (!dateTime) {
    return {};
  }

  const timeString = atHelperFunctions.formatDate(dateTime, {
    onlyTime: true,
    span: false,
    noSeconds: true
  });
  const dateString = atHelperFunctions.formatDate(dateTime, {
    onlyDate: true,
    span: false
  });

  return { timeString, dateString };
};
