'use strict';

angular.module('app')
    .directive('validatePhone', validatePhone);

function validatePhone() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.push(function (viewValue) {
                if (!viewValue) {
                    ctrl.$setValidity('phoneLength', true);
                    ctrl.$setValidity('phoneFormat', true);
                    return viewValue;
                }

                var phoneNumber = viewValue.replace(/[- +().x]/g, '');
                if (phoneNumber.length >= 7 && phoneNumber.length <= 20) {
                    ctrl.$setValidity('phoneLength', true);
                } else {
                    ctrl.$setValidity('phoneLength', false);
                }

                var phoneRegex = /^(\+?\d+)?(?:[-().]?\d+)+(?:[x]?\d+)$/i;
                var phone = viewValue.replace(/\s/g, '');
                if (phoneRegex.test(phone)) {
                    ctrl.$setValidity('phoneFormat', true);
                } else {
                    ctrl.$setValidity('phoneFormat', false);
                }

                return viewValue;
            });
        }
    };
}