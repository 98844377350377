import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const AddMembersIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '18px';
  const height = props.height ?? '18px';
  const title = props.title ?? 'Add Members';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const viewBox = props?.viewBox ?? '0 0 18 18';

  let classList = 'at-icon-add-members';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      width={width}
      height={height}
      title={title}
      viewBox={viewBox}
      className={classList}
    >
      <path
        d="M14.25 12.75V14.25H5.25V12.75C5.25 12.75 5.25 9.75 9.75 9.75C14.25 9.75 14.25 12.75 14.25 12.75ZM12 6C12 5.55499 11.868 5.11998 11.6208 4.74997C11.3736 4.37996 11.0222 4.09157 10.611 3.92127C10.1999 3.75097 9.7475 3.70642 9.31105 3.79323C8.87459 3.88005 8.47368 4.09434 8.15901 4.40901C7.84434 4.72368 7.63005 5.12459 7.54323 5.56105C7.45642 5.99751 7.50097 6.4499 7.67127 6.86104C7.84157 7.27217 8.12996 7.62357 8.49997 7.87081C8.86998 8.11804 9.30499 8.25 9.75 8.25C10.3467 8.25 10.919 8.01295 11.341 7.59099C11.7629 7.16903 12 6.59674 12 6ZM14.4 9.795C14.81 10.1732 15.1405 10.6293 15.3723 11.1367C15.6041 11.644 15.7325 12.1925 15.75 12.75V14.25H18V12.75C18 12.75 18 10.1625 14.4 9.795M13.5 3.75C13.2734 3.75014 13.0482 3.78556 12.8325 3.855C13.2713 4.48423 13.5065 5.23289 13.5065 6C13.5065 6.76711 13.2713 7.51577 12.8325 8.145C13.0482 8.21444 13.2734 8.24987 13.5 8.25C14.0967 8.25 14.669 8.01295 15.091 7.59099C15.5129 7.16903 15.75 6.59674 15.75 6C15.75 5.40326 15.5129 4.83097 15.091 4.40901C14.669 3.98705 14.0967 3.75 13.5 3.75ZM6 7.5H3.75V5.25H2.25V7.5H0V9H2.25V11.25H3.75V9H6V7.5Z"
        fill={fillColor}
      />
    </SvgIconWrapper>
  );
};
