import React, { Fragment } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { tmInfoTipClasses } from './InfoTip.style';
import { generateGuid, isEmpty } from '../../utilities/helpers';
import SearchList from '../SearchList/SearchList';

const InfoTip = (props) => {
    const { buttonContent, infoTipContent, anchorOrigin, transformOrigin, variant, onEnter, onClose } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const createItem = ({ label, subItems }) => {
        return (
            <div key={generateGuid()}>
                <Typography
                    sx={
                        isEmpty(subItems)
                            ? tmInfoTipClasses.item
                            : { ...tmInfoTipClasses.bold, ...tmInfoTipClasses.item }
                    }
                >
                    {label}
                </Typography>
            </div>
        );
    };

    const createSubItem = ({ label, subLabel, value, onClick, className, heapId }) => {
        let content = value !== undefined && value !== null ? `${label}: ${value}` : label;

        if (subLabel && subLabel !== '') {
            content += ' ' + subLabel;
        }

        if (typeof onClick === 'function') {
            return (
                <div id={heapId} key={generateGuid()}>
                    <Typography
                        sx={{ ...tmInfoTipClasses.subItem, ...tmInfoTipClasses.clickableItem, ...className }}
                        onClick={onClick}
                    >
                        {content}
                    </Typography>
                </div>
            );
        }
        return (
            <div id={heapId} key={generateGuid()}>
                <Typography sx={{ ...tmInfoTipClasses.subItem, ...className }}>{content}</Typography>
            </div>
        );
    };

    const generateInfoTipContent = () => {
        if (!Array.isArray(infoTipContent)) {
            return infoTipContent;
        }

        return infoTipContent.map((item) => {
            let itemElement = createItem(item);
            if (Array.isArray(item.subItems)) {
                if (item.subItems.length === 0) {
                    return (
                        <div key={generateGuid()}>
                            {itemElement}
                            {createSubItem({ label: 'None', className: tmInfoTipClasses.none })}
                        </div>
                    );
                } else {
                    const subItems = item.subItems.map((subItem) => ({
                        label: subItem.label,
                        node: createSubItem(subItem)
                    }));
                    return (
                        <div key={generateGuid()}>
                            {itemElement}
                            {variant === 'search' ? (
                                <SearchList items={subItems}></SearchList>
                            ) : (
                                subItems.map((subItem) => subItem.node)
                            )}
                        </div>
                    );
                }
            } else {
                return <div key={generateGuid()}>{itemElement}</div>;
            }
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleEnter = () => {
        if (typeof onEnter === 'function') {
            onEnter();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Fragment>
            <Button sx={tmInfoTipClasses.button} onClick={handleClick} variant={'text'} size={'small'}>
                {buttonContent}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={3}
                key={0}
                anchorOrigin={{
                    vertical: (anchorOrigin && anchorOrigin.vertical) || 'bottom',
                    horizontal: (anchorOrigin && anchorOrigin.horizontal) || 'left'
                }}
                transformOrigin={{
                    vertical: (transformOrigin && transformOrigin.vertical) || 'top',
                    horizontal: (transformOrigin && transformOrigin.horizontal) || 'left'
                }}
                TransitionProps={{
                    onEnter: handleEnter
                }}
            >
                <div style={tmInfoTipClasses.infoTipContent} key={1}>
                    {generateInfoTipContent()}
                </div>
            </Popover>
        </Fragment>
    );
};

export default InfoTip;
