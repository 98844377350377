import { constructor as loginServiceFunctionsConstructor } from '_app/serviceFunctions/loginServiceFunctions';
angular.module('app').service('loginService', LoginService);

LoginService.$inject = [
    'analyticsService',
    'localStorageService',
    'AccountSettingsService',
    'customUibModal',
    'notificationService',
    'envConfig',
    '$window',
    '$location',
    'authorizationService'
];

function LoginService(
    analyticsService,
    localStorageService,
    AccountSettingsService,
    customUibModal,
    notificationService,
    envConfig,
    $window,
    $location,
    authorizationService
) {
    const loginServiceFunctions = loginServiceFunctionsConstructor(
        authorizationService,
        localStorageService,
        analyticsService,
        AccountSettingsService,
        customUibModal,
        notificationService,
        envConfig,
        $window,
        $location
    );

    angular.extend(this, loginServiceFunctions);
}
