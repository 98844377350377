'use strict';

angular.module('app').service('schedulingService', SchedulingService);

SchedulingService.$inject = ['$http', 'envConfig'];

function SchedulingService($http, envConfig) {
    this.getAll = function () {
        return $http.get(envConfig.apiUrl() + '/api/schedules');
    };

    this.getSchedule = function (id) {
        return $http.get(envConfig.apiUrl() + '/api/schedule/' + id);
    };

    this.saveSchedule = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/schedule', payload);
    };

    this.deleteSchedule = function (id) {
        return $http.delete(envConfig.apiUrl() + '/api/schedule/' + id);
    };

    this.getUserSchedules = function () {
        return $http.get(envConfig.apiUrl() + '/api/schedules/users');
    };

    this.getUsersForSchedule = function (id) {
        return $http.get(envConfig.apiUrl() + '/api/schedule/' + id + '/users');
    };

    this.addUsersToSchedule = function (users, scheduleId) {
        return $http.put(envConfig.apiUrl() + '/api/schedule/' + scheduleId + '/users', users);
    };
}
