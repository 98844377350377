import React, { useState } from 'react';
import NotificationIcon from './NotificationIcon/NotificationIcon.jsx';
import NotificationMessage from './NotificationMessage/NotificationMessage.jsx';

const Notification = ({
  notReported,
  accountId,
  ownerEmail,
  onError,
  isExpired
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NotificationIcon mouseClicked={handleOpen} />
      <NotificationMessage
        handleClose={handleClose}
        anchorEl={anchorEl}
        notReported={notReported}
        accountId={accountId}
        ownerEmail={ownerEmail}
        onError={onError}
        isExpired={isExpired}
      />
    </>
  );
};

export default Notification;
