import React from 'react';

const href =
  'https://support.activtrak.com/hc/en-us/articles/360041708272-How-to-Classify-Productivity-and-Categories-by-Groups';

export const learnMoreTag = (
  <a href={href} target="_blank" rel="noreferrer">
    Learn more
  </a>
);

export const classificationGroupButtonCopy = 'ADD NEW GROUP CLASSIFICATION';
export const forExampleCopy =
  'For example: Social media sites are listed as unproductive for the organization with the exception of the Marketing team, as social media management is productive and relevant to their work.';
export const classificationsCanVaryCopy = `Classifications can vary by team or roles within an organization. Assign a Group Classification to differentiate the category or productivity of one group compared to the full organization. `;
