import ActiveStatus from '../../../models/ActiveStatus';
import theme from '../../../../common/theme/theme';

const getIconStyles = (activeStatus) => {
    let color;
    switch (activeStatus) {
        case ActiveStatus.Active:
            color = theme.palette.secondary.main;
            break;
        case ActiveStatus.Passive:
            color = theme.palette.secondary.lightest;
            break;
        default:
            color = theme.palette.grey.A100;
    }
    return {
        color
    };
};

export const getClasses = (activeStatus) => ({
    wrapper: {
        display: 'flex',
        cursor: 'pointer'
    },
    icon: getIconStyles(activeStatus),
    count: {
        color: activeStatus == ActiveStatus.Inactive ? theme.palette.grey.A100 : 'inherit',
        fontSize: `${theme.atCustom.fontSize.userIcon}px`,
        fontWeight: 'bold',
        paddingLeft: 3,
        marginTop: -2
    }
});
