import React from 'react';
import { MemberType } from '../enums';
import PersonIcon from '@mui/icons-material/Person';
import { ComputerIcon } from '../../common/assets/Icons';

type Prop = {
  type: MemberType;
};

export default function MemberTypeIcon(props: Prop) {
  const { type } = props;

  return type === MemberType.User ? <PersonIcon /> : <ComputerIcon />;
}
