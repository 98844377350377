import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export default function RemoveMemberIcon(props: SvgIconProps) {
  const height = props.height ?? 24;
  const width = props.width ?? 24;
  const disabled = props.disabled ?? false;
  const style = disabled ? { opacity: 0.25 } : {};
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 21 21`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="at-icon-remove-group"
    >
      <title>Remove Member</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-663.000000, -168.000000)">
          <g transform="translate(622.500000, 161.000000)">
            <g transform="translate(40.561905, 7.847421)">
              <rect fill="none" x="0" y="0" width="20" height="20"></rect>
              <g>
                <rect x="0" y="0" width="17.8454522" height="17.8454522"></rect>
                <path
                  style={style}
                  d="M10.4098471,5.94848407 C10.4098471,4.30521534 9.07887381,2.97424203 7.43560508,2.97424203 C5.79233636,2.97424203 4.46136305,4.30521534 4.46136305,5.94848407 C4.46136305,7.59175279 5.79233636,8.9227261 7.43560508,8.9227261 C9.07887381,8.9227261 10.4098471,7.59175279 10.4098471,5.94848407 Z M12.6405286,7.43560508 L12.6405286,8.9227261 L17.1018917,8.9227261 L17.1018917,7.43560508 L12.6405286,7.43560508 Z M1.48712102,13.3840892 L1.48712102,14.8712102 L13.3840892,14.8712102 L13.3840892,13.3840892 C13.3840892,11.4062182 9.42091164,10.4098471 7.43560508,10.4098471 C5.45029853,10.4098471 1.48712102,11.4062182 1.48712102,13.3840892 Z"
                  id="Shape"
                  fill={fillColor}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
