import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import { EnterpriseRow } from './EnterpriseRow.jsx';
import { enterpriseClasses } from './Enterprise.styles.js';

const Enterprise = ({ accounts, onError }) => {
  document.title = 'Command Center Enterprise | ActivTrak';
  const [loading, setLoading] = useState(true);
  const [parentAccounts, setParentAccounts] = useState([]);

  useEffect(() => {
    if (accounts) {
      setParentAccounts(accounts);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const renderCells = () => {
    if (parentAccounts && parentAccounts.length > 0) {
      return parentAccounts.map((acct) => (
        <React.Fragment key={acct.accountNumber}>
          <EnterpriseRow account={acct} onError={onError} />
        </React.Fragment>
      ));
    }
  };

  const loadingStyle = loading
    ? enterpriseClasses.loader
    : enterpriseClasses.invisible;

  return (
    <TableContainer sx={enterpriseClasses.table}>
      <CircularProgress
        color="primary"
        sx={{ ...enterpriseClasses.loader, ...loadingStyle }}
      />
      <Table
        size="medium"
        sx={loading ? enterpriseClasses.disabled : enterpriseClasses.enabled}
      >
        <TableHead sx={enterpriseClasses.tableHead}>
          <TableRow sx={enterpriseClasses.tableRow}>
            <TableCell colSpan={2}>
              {parentAccounts && parentAccounts.length == 0
                ? 'No Parent Account Exists'
                : ''}
            </TableCell>
            <TableCell align="center" sx={enterpriseClasses.header} colSpan={1}>
              # of Accounts
            </TableCell>
            <TableCell align="center" sx={enterpriseClasses.header} colSpan={1}>
              Licenses
            </TableCell>
            <TableCell sx={enterpriseClasses.header}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderCells()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Enterprise;
