import { apiService } from '../helpers';
import { getDefaultConfig } from '../config';
import { IStorageDto, IStorageService } from '../models';

export const StorageService = (): IStorageService => {
  return {
    getTotalStorage: async (token: string): Promise<IStorageDto> => {
      const response = await apiService.get<IStorageDto>(
        'api/settings/storage/total',
        {
          ...getDefaultConfig(),
          headers: { Authorization: token }
        }
      );
      return response?.data;
    }
  };
};
