'use strict';

angular.module('app').service('filterService', ['$http', 'envConfig',
    function ($http, envConfig) {
        this.getEntitiesById = function (filterEntityType, ids) {
            var params = { ids: ids };
            return $http.get(envConfig.apiUrl() + '/api/filter/' + (filterEntityType ? filterEntityType : 'all'), {
                params: params,
                ignoreLoadingBar: true,
            });
        };

        this.getEntities = function (filterEntityType, search, skip, take) {
            var params = { search: search, skip: skip, take: take };
            return $http.get(envConfig.apiUrl() + '/api/filter/' + (filterEntityType ? filterEntityType : 'all'), {
                params: params,
                ignoreLoadingBar: true,
            });
        };
    }
]);