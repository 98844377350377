import { useEffect, useState } from 'react';
import { useInjector } from '../../../common/third-party/angular2react/useInjector';

export const useHasDataEmit = () => {
  const [emitted, setEmitted] = useState<boolean>(false);
  const $injector = useInjector();
  useEffect(() => {
    const rootScope = $injector.get('$rootScope');
    const eventUnsubscribe = rootScope.$on(
      'hasDataCheck',
      (event, value: { hasData: boolean }) => {
        setEmitted(value.hasData);
      }
    );

    return () => {
      eventUnsubscribe();
    };
  }, [$injector]);

  return emitted;
};
