import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { latestUpdatesTemplate as angularJsTemplate } from '../../templates';

export const appHelpLatestUpdates: IRoute = {
  name: 'app.help.latestUpdates',
  stateDefinition: {
    url: '/help/latestupdates',
    template: angularJsTemplate,
    controller: 'LatestUpdatesCtrl',
    data: { pageTitle: 'Latest Updates' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Configurator],
    read: [Role.Admin, Role.SuperAdmin, Role.User]
  }
};

export default appHelpLatestUpdates;
