'use strict';

angular.module('app').directive('topApps', function () {
    return {
        restrict: 'E',
        scope: {
            parameters: '=',
            forceTitle: '@',
            productivity: '@',
            shortLabelsFilter: '=',
            printPage: '='
        },
        template: require('views/widgets/dashboard/topApps.html'),
        controller: TopAppsCtrl
    };
});

TopAppsCtrl.$inject = [
    '$scope',
    '$state',
    'chartService',
    'dashboardApiService',
    'atDashboardWidgetService',
    'trackEventService',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'localStorageService',
    'AccountSettingsService'
];

function TopAppsCtrl(
    $scope,
    $state,
    chartService,
    dashboardApiService,
    atDashboardWidgetService,
    trackEventService,
    templateServiceFunctions,
    browserServiceFunctions,
    localStorageService,
    AccountSettingsService
) {
    $scope.topApplicationsOptions = $scope.productivity || 'All';
    $scope.widgetTitle = $scope.forceTitle || 'Top Applications';

    if (!$scope.printPage) {
        localStorageService.set(
            'topAppsDashboardWidgetFilter-' + AccountSettingsService.username,
            $scope.topApplicationsOptions
        );
    }

    var bindWidget = function () {
        $scope.topApplicationsChartConfig = {
            getData: function () {
                return dashboardApiService.getApplications(
                    $scope.parameters,
                    $scope.topApplicationsOptions
                );
            },
            buildChart: function (data) {
                angular.forEach(data, function (item) {
                    item.description = item.description || item.executable;
                });
                return chartService.buildPieChart({
                    hueOption: $scope.topApplicationsOptions,
                    huesTable: {
                        Productive: 'productive',
                        Unproductive: 'unproductive',
                        Undefined: 'undefined',
                        All: 'multiExtended'
                    },
                    field: 'seconds',
                    categoryField: 'description',
                    showTooltip: !browserServiceFunctions.isMobileAgent(),
                    model: {
                        tooltip: function () {
                            return (
                                templateServiceFunctions.friendlyViewTimeFormat(
                                    this.seconds
                                ) +
                                ' — ' +
                                this.description
                            );
                        }
                    },
                    legendTemplate: $scope.shortLabelsFilter(36, 'text'),
                    data: data,
                    seriesClick: function (e) {
                        if (
                            e.category === 'Other' ||
                            browserServiceFunctions.isMobileAgent()
                        ) {
                            return;
                        }

                        trackEventService.track('ta-graph');

                        if ($scope.topApplicationsOptions === 'Undefined') {
                            $state.go('app.settings.productivity', {
                                app: encodeURIComponent(e.category)
                            });
                        } else {
                            $state.go('app.reports.topapplications', {
                                app: e.dataItem.id
                            });
                        }
                    }
                });
            }
        };
    };

    function handleFilterChange(selection) {
        if (!$scope.printPage) {
            localStorageService.set(
                'topAppsDashboardWidgetFilter-' +
                    AccountSettingsService.username,
                selection.text
            );
        }

        $scope.topApplicationsOptions = selection.text;
        bindWidget();
    }

    $scope.filterButtonOptions = {
        type: 'filter',
        dynamicLabel: true,
        heapId: 'id_faee2e93-3edd-4556-9415-80f25e8948c1',
        selectionList: [
            {
                heapId: 'id_3634758a-aac0-45c4-a212-816b12fb1ccc',
                text: 'All',
                value: 'All'
            },
            {
                heapId: 'id_9d850653-593d-4186-a8a8-bac5979b9364',
                text: 'Productive',
                value: 'Productive'
            },
            {
                heapId: 'id_6ac0b1cb-acd4-456d-883f-891e669db14f',
                text: 'Unproductive',
                value: 'Unproductive'
            },
            {
                heapId: 'id_d77117f2-b49e-4c13-a8b6-c319bd9c100f',
                text: 'Undefined',
                value: 'Undefined'
            }
        ],
        onChange: handleFilterChange
    };

    atDashboardWidgetService.registerWidget('topApplications', 5, bindWidget);
}
