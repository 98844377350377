import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsGroups_id: IRoute = {
  name: 'app.settings.groups_id',
  stateDefinition: {
    url: '/settings/groups/{groupId}',
    template: '<settings-groups-detail></settings-groups-detail>',
    data: { pageTitle: 'Groups' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsGroups_id;
