import { react2angular } from 'react2angular';
import { UserViewComponent } from '../../_reactivtrak/src/agents/views/User.view';
import { UsersViewComponent } from '../../_reactivtrak/src/agents/views/Users.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsUserAgents', react2angular(UsersViewComponent, []));

  angular
    .module('app')
    .component('settingsUserAgentsDetail', react2angular(UserViewComponent, []));

}
