const errorCodes = {
  lenght: 'Password must be at least 8 characters long',
  lowercase: 'Password must contain at least 1 lowercase character',
  uppercase: 'Password must contain at least 1 uppercase character',
  special: 'Password must contain at least 1 special character',
  number: 'Password must contain at least 1 number'
};

export const validatePassword = (password = '') => {
  const errorMessages = [];
  const isMinimunlength = password.length >= 8;
  const hasLowerCase = !!password.match(new RegExp('^(?=.*[a-z])'));
  const hasUpperCase = !!password.match(new RegExp('^(?=.*[A-Z])'));
  const hasSpecialCharacter = !!password.match(new RegExp('(?=.*[!@#$%^&*])'));
  const hasNumber = !!password.match(new RegExp('^(?=.*[0-9])'));
  if (!isMinimunlength) {
    errorMessages.push(errorCodes['lenght']);
  }
  if (!hasLowerCase) {
    errorMessages.push(errorCodes['lowercase']);
  }
  if (!hasUpperCase) {
    errorMessages.push(errorCodes['uppercase']);
  }
  if (!hasSpecialCharacter) {
    errorMessages.push(errorCodes['special']);
  }
  if (!hasNumber) {
    errorMessages.push(errorCodes['number']);
  }
  return errorMessages;
};
