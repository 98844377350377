import { Status } from '../enums';
import { ITicketCommentPayload, ITicketPayload } from '../models';
import { ITicketFilter } from '../models/ITicketFilter';

export const MAX_FILE_SIZE = 7340032;

export const statusFilters: ITicketFilter[] = [
  {
    label: Status.All,
    template: () => Status.All,
    filter: 'all',
    filterValue: ''
  },
  {
    label: Status.New,
    template: () => Status.New,
    filter: 'status',
    filterValue: Status.New
  },
  {
    label: Status.Pending,
    template: () => Status.Pending,
    filter: 'status',
    filterValue: Status.Pending
  },
  {
    label: Status.OnHold,
    template: () => Status.OnHold,
    filter: 'status',
    filterValue: Status.OnHold
  },
  {
    label: Status.Solved,
    template: () => Status.Solved,
    filter: 'status',
    filterValue: Status.Solved
  },
  {
    label: Status.Closed,
    template: () => Status.Closed,
    filter: 'status',
    filterValue: Status.Closed
  }
];

export const defaultTicketState: ITicketPayload = {
  subject: '',
  body: '',
  attachments: undefined
};

export const defaultCommentState: ITicketCommentPayload = {
  body: '',
  attachments: undefined
};
