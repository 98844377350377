import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const UserAllIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
    const { height, width, viewBox } = props;
    const title = props.title ?? 'All Users';
    const className = props.className;
    const fillColor = props.fillColor ?? 'currentColor';
    const style = props.style ?? {};

    let classList = 'at-icon-all-users';

    if (className) classList += ` ${className}`;

    return (
        <SvgIconWrapper
            height={height}
            width={width}
            fillColor={fillColor}
            title={title}
            className={classList}
            viewBox={viewBox}
            style={style}
        >
            <path
                d="M18.894 11.772a2.527 2.527 0 1 0 0-5.055 2.527 2.527 0 0 0 0 5.055ZM18.894 12.577c1.374 0 4.116.847 4.116 2.527v1.264c0 .348-.232.632-.515.632h-4.043v-.767c0-1.287-1.026-2.26-2.327-2.92.891-.49 2.029-.736 2.769-.736ZM5.13 11.772a2.527 2.527 0 1 0 0-5.055 2.527 2.527 0 0 0 0 5.055ZM5.13 12.577c.74 0 1.878.246 2.77.736-1.302.66-2.329 1.633-2.329 2.92V17H1.53c-.255 0-.468-.23-.508-.53l-.007-.102v-1.264c0-1.68 2.742-2.527 4.116-2.527ZM12.012 12a3.347 3.347 0 1 0 0-6.694 3.347 3.347 0 0 0 0 6.694ZM12.012 13.246c-1.82 0-5.451 1.121-5.451 3.347v1.674c0 .46.307.837.681.837h9.54c.374 0 .681-.377.681-.837v-1.674c0-2.226-3.632-3.347-5.451-3.347Z"
                style={style}
            />
        </SvgIconWrapper>
    );
};
