import React from 'react';
import { rowType } from '../../../common/enums';
import { IRoleAccessViewModel } from '../../../common/models';
import { RolesTableCheckRow } from './RolesTableCheckRow';
import { RolesTableContainerCollapsible } from './RolesTableContainerCollapsible';

type rowsProps = {
  busy: boolean;
  rows: IRoleAccessViewModel[];
  showLimitedDesc: boolean;
  handleChange: any;
};

export const RolesTableRows: React.FC<rowsProps> = ({
  rows,
  handleChange,
  busy,
  showLimitedDesc
}) => {
  const populateSubItems = () => {
    const subItemsObj = {
      insights: [],
      liveReports: [],
      alarms: [],
      dashboards: []
    };

    //TODO: improve this
    rows.map((row) => {
      switch (true) {
        case row.resource?.includes('Insights') &&
          row.itemType == rowType.SubItem:
          subItemsObj.insights.push(row);
          break;
        case row.resource?.includes('Alarms') &&
          row.itemType == rowType.SubItem:
          subItemsObj.alarms.push(row);
          break;
        case row.resource?.includes('Dashboards') &&
          row.itemType == rowType.SubItem:
          subItemsObj.dashboards.push(row);
          break;
        case row.itemType == rowType.SubItem:
          subItemsObj.liveReports.push(row);
          break;
      }
    });

    return subItemsObj;
  };

  const getSubItems = (row) => {
    const allSubItems = populateSubItems();

    switch (true) {
      case row.resource?.includes('Dashboards'):
        return allSubItems.dashboards;
      case row.resource?.includes('Insights'):
        return allSubItems.insights;
      case row.resource?.includes('Alarms'):
        return allSubItems.alarms;
      case row.resource?.includes('Live Reports'):
        return allSubItems.liveReports;
    }
  };

  return (
    <>
      {rows.map((row) => (
        // using React.Fragment to be able to add key
        <React.Fragment key={row.resource}>
          {row.itemType == rowType.Container && (
            <RolesTableContainerCollapsible
              key={row.resource}
              row={row}
              busy={busy}
              handleChange={handleChange}
              showLimitedDesc={showLimitedDesc}
              subItems={getSubItems(row)}
            />
          )}
          {row.itemType == rowType.Item && (
            <RolesTableCheckRow
              key={row.resource}
              row={row}
              busy={busy}
              handleChange={handleChange}
              showLimitedDesc={showLimitedDesc}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
