import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';
import { gridServiceFunctions } from '../../../../_app/serviceFunctions/gridServiceFunctions.js';
import { createProductivityColumnDropdown } from '../../../../_app/productivity/productivityService';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag.ts';
import gridColumnSelectorModalCtrlTemplate from 'views/widgets/gridColumnSelectorModal.html?raw';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';

// Register report component
import './alarmlog.component.js';

angular.module('app').controller('AlarmLogCtrl', AlarmLogCtrl);

AlarmLogCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$compile',
    'messagesService',
    'alarmLogService',
    '$rootScope',
    'customUibModal',
    'pageSizeService',
    'gridPropertiesService',
    '$sce',
    'exportInfoService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'authorizationService',
    'browserServiceFunctions'
];

function AlarmLogCtrl(
    $scope,
    $state,
    $timeout,
    $compile,
    msg,
    alarmLogService,
    $rootScope,
    customUibModal,
    pageSizeService,
    gridPropertiesService,
    $sce,
    exportInfoService,
    atHelperFunctions,
    templateServiceFunctions,
    authorizationService,
    browserServiceFunctions
) {
    const accountSettings = getAccountSettings();
    const { screenshotsAllowed = false, activityAllowed = false, alarmActionsAllowed = false } = getPrivacySettings();
    const gridName = 'alarm-log-grid';
    const isSupportAdvanced = authorizationService.hasRole('supportadvanced');

    const hasAlarmsScreenshotsReportsRoleAccess = authorizationService.hasRoleAccess('Alarms-ScreenshotsReports');

    $scope.download = $rootScope.download;
    $scope.hasData = true;
    $scope.isSmallWindow = window.innerWidth < 992;
    $scope.alarmLogService = alarmLogService;
    $scope.helpers = atHelperFunctions;
    $scope.accountSettings = accountSettings;
    $scope.usedLicenses = accountSettings.usedLicenses;
    $scope.hasVideoAlarmsFeatureFlag = authorizationService.hasFeature(FeatureFlag.VideoPlayback);
    let cacheKey;
    $scope.reportFilters = {};

    $scope.isLoading = true;

    const headerHeight = 248;
    $scope.loaderHeight = atHelperFunctions.getGridHeight(headerHeight);

    $(window).on('resize.doResize', function () {
        $scope.$evalAsync(function () {
            const previousIsSmallWindow = $scope.isSmallWindow;
            $scope.isSmallWindow = window.innerWidth < 992;
            $scope.loaderHeight = atHelperFunctions.getGridHeight(headerHeight);

            if (previousIsSmallWindow !== $scope.isSmallWindow) {
                $scope.bindReport();
            }
        });
    });

    $scope.$on('$destroy', function () {
        $(window).off('resize.doResize');
    });

    function isPassive(item) {
        return (
            item &&
            item.titleBar &&
            (item.titleBar.toLowerCase() === 'passive' || item.titleBar.toLowerCase() === 'passive - locked')
        );
    }

    function isSystemEvent(item) {
        return item && item.executable && item.executable.toLowerCase() === 'system event';
    }

    function isSystemUser(item) {
        return item && item.user && item.user.toLowerCase() === 'system';
    }

    //TODO: Quick fix for CC
    let alarmsDefaultSort = {
        field: 'time',
        dir: 'asc'
    };
    if (window.localStorage.getItem('activTrak.msp.backup')) {
        alarmsDefaultSort = { field: 'alarmWeight', dir: 'desc' };
    }

    $scope.convertLogToScreenshot = function (logEntry) {
        if (!logEntry.ssUrl) {
            return null;
        }

        const time = logEntry.time_utc ? atHelperFunctions.convertTimezone(logEntry.time_utc) : logEntry.time;

        return {
            fullsize: logEntry.ssUrl,
            time: atHelperFunctions.formatDate(time, {
                span: false
            }),
            user: logEntry.user,
            computer: logEntry.computer,
            titlebar: logEntry.titleBar,
            url: logEntry.url,
            description: logEntry.description,
            executable: logEntry.executable,
            prod: logEntry.prod,
            appId: logEntry.appId,
            webId: logEntry.webId,
            isSafe: logEntry.isSafe,
            safeSearchObject: logEntry.safeSearchObject
        };
    };

    const alarmLogDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                $scope.isLoading = true;

                const args = generateParameters($scope.reportFilters, {});
                const data = options.data;

                alarmLogService
                    .getLog(args, {
                        params: data
                    })
                    .success(function (result) {
                        $scope.isLoading = false;

                        for (let index = 0; index < result.data.length; index++) {
                            result.data[index].index = index;
                        }

                        $rootScope.$broadcast('setGoogleExportParameters', {
                            columns: result.columns,
                            total: result.total,
                            calculateHere: true
                        });

                        $scope.screenshotViewerConfig = {
                            dataSource: alarmLogDataSource,
                            images: result.data.map($scope.convertLogToScreenshot)
                        };
                        cacheKey = result.cacheKey;

                        const resultLimit = 10000;
                        if (result.total >= resultLimit) {
                            $scope.topPager.options.messages.display = msg.get('itemsDisplay', 'entries', resultLimit);
                            $scope.topPager.refresh();

                            // Wait a cycle to ensure the tooltip-container is rendered
                            $timeout(function () {
                                const tooltip = angular.element('#tooltip-container');
                                tooltip.attr('i-tooltip', '');
                                tooltip.attr('placement', 'right');
                                tooltip.attr('top-offset', '4px');
                                tooltip.attr(
                                    'template',
                                    `'${msg.get('limitedTooltip', 'Results in table', resultLimit)}'`
                                );
                                const compiledElement = $compile(tooltip)($scope);
                                angular.element('#tooltip-container').replaceWith(compiledElement);
                            });
                        } else {
                            $scope.topPager.options.messages.display = msg.get('itemsDisplay', 'entries', 0);
                            $scope.topPager.refresh();
                        }
                        options.success(result);
                        $scope.hasData =
                            result.data || Object.values(data).filter((value) => value && value !== '').length > 0;

                        //pass data state to react wrapper to control slider
                        $rootScope.$broadcast('hasDataCheck', { hasData: result.data?.length > 0 });
                    })
                    .error(function (result) {
                        options.error(result);
                        $scope.isLoading = false;
                        if (result && result.message) {
                            $scope.$emit('showNotification', {
                                message: result.message,
                                color: 'danger'
                            });
                        }
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total',
            pagerTotal: 'sliderCount'
        },
        pageSize: pageSizeService.loadPageSize('alarmLog-master', 50),
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        sort: alarmsDefaultSort,
        entryLimit: 10000
    });

    $scope.alarmLogDataSource = alarmLogDataSource;

    const pagerMessages = {
        itemsPerPage: msg.get('itemsPerPage', 'alarms'),
        display: msg.get('itemsDisplay', 'entries', 0),
        empty: msg.get('noItemsToDisplay', 'alarms')
    };

    const defaultGridColumns = [
        {
            field: 'prod',
            title: msg.get('prod'),
            filterable: {
                cell: {
                    operator: 'eq',
                    template: function (args) {
                        return createProductivityColumnDropdown(args);
                    },
                    showOperators: false
                }
            },
            template: kendo.template(function (item) {
                return templateServiceFunctions.productivityTemplate(item);
            }),
            attributes: {
                class: 'text-center productivity'
            },
            width: '42px'
        },
        {
            field: 'screenshot',
            title: msg.get('screenshot'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa icon-at-screenshot text-master" title="' + msg.get('screenshot') + '"></i>',
            template: kendo.template(function (data) {
                if (data.screenshot > 0) {
                    return isSupportAdvanced
                        ? '<i class="fa ' +
                              (data.screenshotMultiple ? 'icon-at-multiple-screenshots-camera' : 'icon-at-screenshot') +
                              ' text-master not-allowed"></i>'
                        : '<a ng-click="showScreenshot(dataItem)"><i class="fa ' +
                              (data.screenshotMultiple ? 'icon-at-multiple-screenshots-camera' : 'icon-at-screenshot') +
                              ' text-master"></i></a>';
                }
                return '';
            }),
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !screenshotsAllowed || !hasAlarmsScreenshotsReportsRoleAccess
        },
        {
            field: 'popup',
            title: msg.get('popupAlarm'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa fa-comment-o text-master" title="' + msg.get('popupAlarm') + '"></i>',
            template: '# if (popup > 0) {# <i class="fa fa-comment-o text-master"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'email',
            title: msg.get('emailAlarm'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa fa-envelope text-master" title="' + msg.get('emailAlarm') + '"></i>',
            template: '# if (email > 0) {# <i class="fa fa-envelope text-master"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px'
        },
        {
            field: 'webhook',
            title: msg.get('webhookAlarm'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa fa-link text-master" title="' + msg.get('webhookAlarm') + '"></i>',
            template: '# if (webhook > 0) {# <i class="fa fa-link text-master"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px'
        },
        {
            field: 'terminate',
            title: msg.get('terminate'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa fa-ban text-master" title="' + msg.get('terminate') + '"></i>',
            template: '# if (terminate > 0) {# <i class="fa fa-ban text-master"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'alarmWeight',
            title: msg.get('riskLevel'),
            sortable: {
                initialDirection: 'desc'
            },
            hidden: false,
            filterable: false,
            headerTemplate:
                '<i class="fa icon-at-alarm-weight-risk text-master" title="' + msg.get('riskLevel') + '"></i>',
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'alarmName',
            title: msg.get('alarmName'),
            filterable: templateServiceFunctions.createFilter('alarmName', alarmLogDataSource),
            template: kendo.template(function (data) {
                return '' + browserServiceFunctions.htmlEscape(data.alarmName) + '';
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'time',
            title: msg.get('dateTime'),
            filterable: false,
            template: atHelperFunctions.compactDateTemplate,
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '143px'
        },
        {
            field: 'computer',
            title: msg.get('computer'),
            filterable: templateServiceFunctions.createFilter('computer', alarmLogDataSource),
            attributes: {
                class: 'text-nowrap  text-center'
            },
            width: '150px'
        },
        {
            field: 'primary_Domain',
            title: msg.get('primaryDomain'),
            hidden: true,
            filterable: false,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'ip',
            title: msg.get('privateIp'),
            hidden: true,
            filterable: false,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'publicIp',
            title: msg.get('publicIp'),
            filterable: templateServiceFunctions.createFilter('publicIp', alarmLogDataSource),
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'login_Domain',
            title: msg.get('logonDomain'),
            hidden: true,
            filterable: false,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'user',
            title: msg.get('user'),
            template: kendo.template(function (item) {
                if (isSystemUser(item)) {
                    return '<span class="at-deemphasize">' + item.user.toLowerCase() + '</span>';
                } else {
                    return item.user;
                }
            }),
            filterable: templateServiceFunctions.createFilter('user', alarmLogDataSource),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '150px'
        },
        {
            field: 'session',
            title: msg.get('session'),
            filterable: false,
            attributes: {
                class: 'text-center'
            },
            width: '60px'
        },
        {
            field: 'duration',
            title: msg.get('duration'),
            filterable: false,
            template: kendo.template(templateServiceFunctions.durationTemplate),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '100px'
        },
        {
            field: 'titleBar',
            title: msg.get('title'),
            filterable: templateServiceFunctions.createFilter('titleBar', alarmLogDataSource),
            template: kendo.template(function (item) {
                if (isPassive(item) || isSystemEvent(item)) {
                    return (
                        '<span class="at-deemphasize"><a ng-click="filter(\'titleBar\', dataItem.titleBar)">' +
                        item.titleBar.toLowerCase() +
                        '</a></span>'
                    );
                } else {
                    return '<a ng-click="filter(\'titleBar\', dataItem.titleBar)">{{dataItem.titleBar}}</a>';
                }
            }),
            width: '300px',
            removeColumn: !activityAllowed
        },
        {
            field: 'executable',
            title: msg.get('executable'),
            filterable: templateServiceFunctions.createFilter('executable', alarmLogDataSource),
            template: kendo.template(function (item) {
                if (isSystemEvent(item)) {
                    return (
                        '<span class="at-deemphasize"><a ng-click="filter(\'executable\', dataItem.executable)">' +
                        item.executable.toLowerCase() +
                        '</a></span>'
                    );
                } else {
                    return '<a ng-click="filter(\'executable\', dataItem.executable)">{{dataItem.executable}}</a>';
                }
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'description',
            title: msg.get('description'),
            hidden: true,
            filterable: templateServiceFunctions.createFilter('description', alarmLogDataSource),
            template: '<a ng-click="filter(\'description\', dataItem.description)">{{dataItem.description}}</a>',
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'url',
            title: msg.get(activityAllowed ? 'url' : 'website'),
            filterable: templateServiceFunctions.createFilter('url', alarmLogDataSource),
            template: templateServiceFunctions.getFaviconTemplate('url'),
            attributes: {
                class: 'text-nowrap'
            },
            width: '500px'
        }
    ];

    if ($scope.hasVideoAlarmsFeatureFlag) {
        defaultGridColumns.splice(2, 0, {
            field: 'video',
            title: msg.get('videos'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="pg-video text-master" title="' + msg.get('videos') + '"></i>',
            template: kendo.template(function (data) {
                if (data.video > 0) {
                    return '<a ng-click="showVideo(dataItem)"><i class="pg-video text-master"></i></a>';
                }
                return '';
            }),
            attributes: {
                class: 'text-center'
            },
            width: '40px'
        });
    }

    function getGridOptions() {
        const cols = atHelperFunctions.filterDefaultColumns(defaultGridColumns);
        return {
            autoBind: false,
            dataSource: alarmLogDataSource,
            filterable: {
                mode: 'row'
            },
            sortable: true,
            messages: pagerMessages,
            pageSizes: [10, 20, 30, 40, 50, 100, 500, 1000],
            buttonCount: 5,
            refresh: true,
            reorderable: true,
            resizable: true,
            columns: gridServiceFunctions.loadSavedGridOptions(angular.copy(cols), gridName),
            dataBound: function (e) {
                gridPropertiesService.bindFilterAutoComplete(e, true);
            },
            columnReorder: function () {
                $timeout(function () {
                    gridPropertiesService.hideLeftBorder();
                });
            }
        };
    }

    $scope.mainGridOptions = getGridOptions();

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        $scope.fromTimestamp = $scope.reportFilters?.fromTimestamp;
        $timeout(function () {
            if (!$scope.isSmallWindow && $scope.alarmLogGrid) {
                gridServiceFunctions.bindGridSave($scope.alarmLogGrid, gridName);

                if ($scope.alarmLogGrid.isBound) {
                    $scope.alarmLogGrid.isBound = false;
                }
            }

            const selectedUser = $state.params.user;
            const selectedComputer = $state.params.computer;
            const selectedAlarm = $state.params.alarm;
            const filter = [];

            if (selectedUser || selectedComputer || selectedAlarm || $scope.selectedAlarm) {
                if (selectedUser) {
                    filter.push({
                        field: 'user',
                        value: selectedUser,
                        operator: 'contains'
                    });
                }
                if (selectedComputer) {
                    filter.push({
                        field: 'computer',
                        value: selectedComputer,
                        operator: 'contains'
                    });
                }
                if (selectedAlarm) {
                    filter.push({
                        field: 'alarmName',
                        value: selectedAlarm,
                        operator: 'contains'
                    });
                }
                if ($scope.selectedAlarm) {
                    $scope.filter('alarmName', $scope.selectedAlarm);
                    return;
                }

                alarmLogDataSource.filter(filter);
            } else {
                pageSizeService.dataSourceReload(alarmLogDataSource);
            }
        });
    };

    $scope.filter = function (field, value) {
        alarmLogDataSource.filter({
            field: field,
            value: value,
            operator: 'contains'
        });
    };

    $scope.$on('restoreColumns', function () {
        if (!$scope.isSmallWindow) {
            $scope.alarmLogGrid.isBound = false;
            gridServiceFunctions.resetSavedGridOptions($scope.alarmLogGrid, gridName, getGridOptions);
        }
        pageSizeService.dataSourceReload(alarmLogDataSource);
        gridPropertiesService.hideLeftBorder();
    });

    $scope.$on('selectColumns', function () {
        const grid = $scope.getGrid();

        customUibModal.open({
            animation: false,
            template: gridColumnSelectorModalCtrlTemplate,
            controller: 'gridColumnSelectorModalCtrl',
            size: 'md',
            resolve: {
                grid: grid
            }
        });
    });

    $scope.getGrid = function () {
        return $scope.isSmallWindow ? null : $scope.alarmLogGrid;
    };

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: alarmLogDataSource,
        getCacheKey: function () {
            return cacheKey;
        },
        getSecondaryParameters: function () {
            return {
                filter: alarmLogDataSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: alarmLogDataSource.sort()
            };
        }
    });

    $scope.showScreenshot = function (dataItem) {
        $scope.$broadcast('showScreenshotEvent', {
            index: dataItem.index
        });
    };

    $scope.showVideo = function (dataItem) {
        dataItem.source = [
            {
                src: $sce.trustAsResourceUrl(dataItem.videoUrl),
                type: 'video/mp4'
            }
        ];
        $scope.videoDetails = angular.copy(dataItem);
    };

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('alarmLog-master');
        }
    });
}

angular.module('app').service('alarmLogService', AlarmLogService);

AlarmLogService.$inject = ['$http', 'envConfig'];

/**
 * Alarmlog will resolve the URI based on exports flags coming from post_login
 * This is a temporary flag for beta customers. This will be removed when alarm log go GA.
 * @param $http
 * @param envConfig
 * @constructor
 */
function AlarmLogService($http, envConfig) {
    this.getLog = function (parameters, data) {
        const url = envConfig.reportingServiceUrl() + '/reports/v1/alarmlog?';
        return $http.get(url + parameters, data);
    };

    this.getWeightedAlarms = function (params) {
        return $http.get(envConfig.apiUrl() + '/api/alarmlog/weighted?' + params);
    };
}
