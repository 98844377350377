'use strict';

// This widget extends the existing Kendo Grid to
// 1. Use our custom pager (see customPager.js).
// 2. Allow setting class templates for grid rows.
// It's registered as "kendo-custom-grid" directive.

var plg = (function (init) {
    function stringifyAttributes(attributes) {
        // jscs:disable disallowMultipleVarDecl
        var attr,
            result = ' ';
        // jscs:enable disallowMultipleVarDecl
        if (attributes) {
            if (typeof attributes === 'string') {
                return attributes;
            }
            for (attr in attributes) {
                result += attr + '="' + attributes[attr] + '"';
            }
        }
        return result;
    }
    function groupCells(count) {
        return new Array(count + 1).join('<td class="k-group-cell">&nbsp;</td>');
    }

    return kendo.ui.Grid.extend({
        options: {
            name: 'CustomGrid'
        },
        init: function (_element, _options) {
            init.call(this, _element, _options);
        },
        _pageable: function () {
            var that = this;
            var wrapper;
            var pageable = that.options.pageable;
            if (pageable) {
                wrapper = that.wrapper.children('div.k-grid-pager');
                if (!wrapper.length) {
                    wrapper = $('<div class="k-pager-wrap k-grid-pager"/>').appendTo(that.wrapper);
                }
                if (that.pager) {
                    that.pager.destroy();
                }
                if (typeof pageable === 'object' && pageable instanceof kendo.ui.Pager) {
                    that.pager = pageable;
                } else {
                    that.pager = new kendo.ui.CustomPager(
                        wrapper,
                        $.extend({}, pageable, { dataSource: that.dataSource })
                    );
                }
                that.pager.bind('pageChange', function (e) {
                    if (that.trigger('page', { page: e.index })) {
                        e.preventDefault();
                    }
                });
            }
        },
        _tmpl: function (rowTemplate, columns, alt, skipGroupCells) {
            // jscs:disable disallowMultipleVarDecl
            var that = this,
                settings = $.extend({}, kendo.Template, that.options.templateSettings),
                idx,
                length = columns.length,
                state = {
                    storage: {},
                    count: 0
                },
                column,
                hasDetails = that._hasDetails(),
                className = [],
                groups = that._groups(),
                navigatable = that.options.navigatable;
            // jscs:enable disallowMultipleVarDecl
            if (!rowTemplate) {
                rowTemplate = '<tr';
                if (alt) {
                    className.push('k-alt');
                }
                if (hasDetails) {
                    className.push('k-master-row');
                }
                // Our custom code to add row class template
                if (that.options.rowClass) {
                    className.push(that.options.rowClass);
                }
                // End of our custom code
                if (className.length) {
                    rowTemplate += ' class="' + className.join(' ') + '"';
                }
                if (length) {
                    rowTemplate += ' ' + kendo.attr('uid') + '="#=' + kendo.expr('uid', settings.paramName) + '#"';
                }
                rowTemplate += " role='row'>";
                if (groups > 0 && !skipGroupCells) {
                    rowTemplate += groupCells(groups);
                }
                if (hasDetails) {
                    rowTemplate +=
                        '<td class="k-hierarchy-cell"><a class="k-icon k-i-expand" href="\\#" tabindex="-1"></a></td>';
                }
                for (idx = 0; idx < length; idx++) {
                    column = columns[idx];
                    rowTemplate += '<td' + stringifyAttributes(column.attributes);
                    if (navigatable) {
                        rowTemplate += " aria-describedby='" + column.headerAttributes.id + "'";
                    }
                    rowTemplate += " role='gridcell'>";
                    rowTemplate += that._cellTmpl(column, state);
                    rowTemplate += '</td>';
                }
                rowTemplate += '</tr>';
            }
            rowTemplate = kendo.template(rowTemplate, settings);
            if (state.count > 0) {
                return $.proxy(rowTemplate, state.storage);
            }
            return rowTemplate;
        }
    });
})(kendo.ui.Grid.fn.init);
kendo.ui.plugin(plg);
