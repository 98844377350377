import React, { useEffect, useState } from 'react';
import { useLastUpdate, useTodaysActivityState } from '../../hooks';
import { checkAllValuesAreZero } from '../../utils';
import { useTodaysActivityCenterText } from '../../utils/todaysActivity.utils';
import {
  errorMsg,
  lastUpdateText,
  modalButtonLabel,
  noUsersMeetingCriteria,
  statuses,
  modalTitle,
  widgetTitle
} from '../../constants';
import { blue4, gray5 } from '../../../common/constants';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  TemplateShadow,
  BaseLoadingView,
  TeamsComparisonDonutChart,
  ErrorMsgDisplay,
  WidgetDialog,
  WidgetModal,
  WidgetModalList
} from '../templates';
import { UserIndividualIcon } from '../../../common/assets/Icons';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import { WhiteBackgroundTooltip } from '../../../common/components/Tooltip/TextWithTooltip.styles';
import {
  LastUpdatedSC,
  InfoOutlinedIcon,
  ModalHeader
} from '../../styles/templates.styles';
import {
  UserIconButton,
  UserIconContainer,
  UserIconContainerTitle
} from '../../styles/todaysActivity.styles';
import {
  IReportModalListItem,
  ITodaysActivityStatusProps,
  WidgetProps
} from '../../models';

export default function TodaysActivity(props: Readonly<WidgetProps>) {
  const { refreshTimestamp } = props;

  const [noResults, setNoResults] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [groupsList, setGroupsList] = useState<IReportModalListItem[]>([]);
  const [groupsModalTitle, setGroupsModalTitle] = useState<string>('');

  const { init, isLoading, isTileError, todaysActivity } =
    useTodaysActivityState();

  const theme = useTheme();
  const largerScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    init();
  }, [init, refreshTimestamp]);

  useEffect(() => {
    if (todaysActivity) {
      const hasNoResults = checkAllValuesAreZero([
        todaysActivity.active,
        todaysActivity.passive,
        todaysActivity.inactive
      ]);
      setNoResults(hasNoResults);
    }
  }, [todaysActivity]);

  const lastUpdate = useLastUpdate(todaysActivity?.date);

  const centerText = useTodaysActivityCenterText(
    noResults,
    todaysActivity?.activeToday,
    todaysActivity?.previous,
    todaysActivity?.todaysLabel
  );

  const inactiveUsers = todaysActivity?.counts?.inactive || 0;
  const activeUsers = todaysActivity?.counts?.active || 0;
  const passiveUsers = todaysActivity?.counts?.passive || 0;

  const noUsersCount = checkAllValuesAreZero([
    activeUsers,
    inactiveUsers,
    passiveUsers
  ]);

  const chartData = {
    labels: ['Active', 'Inactive'],
    datasets: [
      {
        data: noUsersCount ? [0, 1] : [activeUsers, inactiveUsers],
        backgroundColor: [blue4, gray5],
        borderWidth: 0
      }
    ]
  };

  const handleOpen = (item: Partial<ITodaysActivityStatusProps>) => {
    const groupsActivityByChosenStatus: IReportModalListItem[] =
      todaysActivity.groupsActivityByStatus[item.groupsUserCountLabel];

    setGroupsList(groupsActivityByChosenStatus);
    setGroupsModalTitle(item.statusType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TemplateShadow
      sx={{
        height: { xs: '470px', md: '450px' }
      }}
    >
      <WidgetLinkWrapper>
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
          <WidgetLinkTypography variant="h4">
            {widgetTitle.activity}
          </WidgetLinkTypography>
          <WhiteBackgroundTooltip
            title="Includes employees with more than 1 minute of activity today"
            enterTouchDelay={0}
          >
            <IconButton sx={{ position: 'absolute', top: '-5px' }}>
              <InfoOutlinedIcon />
            </IconButton>
          </WhiteBackgroundTooltip>
        </Box>
      </WidgetLinkWrapper>

      {!isLoading && !isTileError && (
        <>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={8} sx={{ textAlign: 'center' }}>
              <TeamsComparisonDonutChart
                chartData={chartData}
                centerText={centerText}
                noUsersCount={noUsersCount}
              />
              {todaysActivity?.groupsActivityWithPercentage?.length !== 0 && (
                <WidgetModal
                  buttonLabel={modalButtonLabel.todaysActivity}
                  id="28d8441c-ccc9-48f3-b01f-3355a5a00acc"
                  isDisabled={noResults}
                >
                  <ModalHeader variant="h6">
                    {modalTitle.todaysActivity}
                  </ModalHeader>
                  {!todaysActivity ||
                  todaysActivity?.groupsActivityWithPercentage?.length === 0 ? (
                    <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
                  ) : (
                    <WidgetModalList
                      data={todaysActivity?.groupsActivityWithPercentage}
                    />
                  )}
                </WidgetModal>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <UserIconContainerTitle>
                Live activity status
              </UserIconContainerTitle>
              <Stack
                direction={{ xs: 'row', sm: 'column' }}
                justifyContent="center"
                spacing={{ xs: 2, md: 0 }}
              >
                {statuses.map((status) => (
                  <UserIconButton
                    id={`organization-overview-${status.id}`}
                    key={`activity-status-${status.statusType}`}
                    onClick={() => handleOpen(status)}
                    disabled={noResults}
                  >
                    <UserIconContainer>
                      <Box sx={{ flex: '0 1 0' }}>
                        <UserIndividualIcon
                          width={largerScreen ? '54px' : '38px'}
                          height={largerScreen ? '54px' : '38px'}
                          style={{
                            color: noResults ? gray5 : status.iconColor
                          }}
                        />
                      </Box>
                      <Box sx={{ flex: '0 2 0', paddingLeft: '5px' }}>
                        {noResults ? (
                          <Typography className="userIconPercentage">
                            0%
                          </Typography>
                        ) : (
                          todaysActivity && (
                            <Typography className="userIconPercentage">{`${
                              todaysActivity[status.statusType?.toLowerCase()]
                            }%`}</Typography>
                          )
                        )}
                        <Typography>{status.statusType} now</Typography>
                      </Box>
                    </UserIconContainer>
                  </UserIconButton>
                ))}
              </Stack>
            </Grid>
          </Grid>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
          <WidgetDialog isOpen={open} onClose={handleClose} width="400px">
            <ModalHeader variant="h6">{groupsModalTitle} now</ModalHeader>
            {!groupsList || groupsList?.length === 0 ? (
              <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
            ) : (
              <WidgetModalList data={groupsList} />
            )}
          </WidgetDialog>
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
