import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const SelectTextFieldSC = styled(TextField)`
  & legend {
    display: none;
  }
  & fieldset {
    top: 0;
  }
  & .MuiInputLabel-root {
    top: -2px;
  }
`;
