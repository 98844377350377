const availableQuotes = {
  '1': 'a workplace with live plants can help in increasing productivity',
  '2': 'the average office chair with wheels travels almost 8 miles (12.5km) a year',
  '3': 'Leonardo Da Vinci is credited with writing the first r&eacute;sum&eacute;',
  '4': 'fun working environments can reduce employee turnover and burnouts',
  '5': 'Tuesday is the most productive day of the week',
  '6': 'listening to the right music while working helps people get things done faster',
  '7': 'morale improves massively with managers saying thank you more',
  '8': 'employees who control the layout and design of their workspace are healthier and happier',
  '9': 'the brain retains less than 5% of what is said during meetings',
  '10': 'Teacher, Actor and Astronaut are the top 3 dream jobs of children',
  '11': 'the majority of employees use 50% or less of their vacation days',
  '12': 'recognition is the biggest motivator for humans in the workplace',
  '13': 'having friends at work boosts employee satisfaction',
  '14': 'people are much happier and more likely to go to work on Fridays',
  '15': "a typist's fingers travel 12.6 miles during an average workday",
  '16': '"Stewardesses" is the longest word typed with only the left hand'
};

export const quotes = {
  default: [
    availableQuotes['1'],
    availableQuotes['2'],
    availableQuotes['3'],
    availableQuotes['4'],
    availableQuotes['5'],
    availableQuotes['6'],
    availableQuotes['7'],
    availableQuotes['8'],
    availableQuotes['9'],
    availableQuotes['10'],
    availableQuotes['11'],
    availableQuotes['12'],
    availableQuotes['13'],
    availableQuotes['14'],
    availableQuotes['15'],
    availableQuotes['16']
  ]
};
