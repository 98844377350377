import Skeleton from 'react-loading-skeleton';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  mediumFontWeight,
  primaryAqua,
  primaryBlue,
  smallFontSize
} from '../../common/constants';

export const UsersGridContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - 240px);
  min-height: 400px;
  margin: 10px 0px;
`;

export const HeaderBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

export const HeaderBarTopComponentsContainer = styled(Box)`
  display: flex;
  flex: 1;
`;

export const HeaderBarTopComponentsButtons = styled(Box)`
  display: flex;
  align-items: center;
  width: 40%;
`;

export const SearchBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 60%;

  .MuiAutocomplete-hasPopupIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
  .MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 69px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-endAdornment {
    right: 39px;
  }
`;
export const AdvancedFilterButtonContainer = styled(Box)`
  display: flex;
  position: absolute;
  right: 29px;
  span {
    .MuiSvgIcon-root {
      font-size: 1.25rem;
    }
  }
`;

export const HeaderBarActionButtons = styled(Box)`
  display: flex;
  width: 100%;
`;

export const HeaderBarLeftActionButtons = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const UserHeaderBarRightActionButtons = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const TotalsGrouper = styled(Box)`
  margin: 0px 10px;

  span {
    font-weight: ${mediumFontWeight};
  }
`;

export const SkullLoaderGridContainer = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SkeletonSC = styled(Skeleton)`
  height: 31px;
`;

export const SkullLoaderGridRowsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SkullLoaderSkeletonCheckboxContainer = styled(Box)`
  margin: 10px 20px;
  width: 36px;
`;

export const SkullLoaderSkeletonContainer = styled(Box)`
  flex: 1;
  margin: 10px 20px;
`;

export const GridRowActionButtonContainer = styled(Box)`
  button {
    border-radius: 50%;
    min-width: 32px;
  }
`;

export const LearnMoreLink = styled('a')`
  padding-top: 7px;
  display: block;
`;

export const InfoTooltip = styled(Box)`
  font-size: ${smallFontSize};
`;

export const LastLogRecordTableCell = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    color: ${primaryBlue};
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const usersViewStyles = {
  uploadFileProgressContainer: {
    height: '6px',
    width: '100%',
    borderRadius: '3px',
    background: '#ffffff',
    padding: '20px 0px 10px'
  },
  dispProgress: {
    height: '40px',
    width: '100%',
    background: '#ffffff',
    marginBottom: '20px'
  },
  uploadFileProgressBar: {
    marginTop: '10px !important',
    height: '100%',
    background: primaryAqua
  },
  dispResultBar: {
    height: '40px',
    width: '100%',
    marginBottom: '20px',
    marginTop: '-15px',
    background: '#ffffff'
  },
  domainName: {
    paddingLeft: '5px'
  }
};
