import { formatDate, getTimeAgo } from '../../common/helpers';
import React from 'react';
import { IGridColumn } from '../../common/models';
import { TimeAgo } from '../../common/components/TimeAgo';

export const useSelectedAgentColumns = (
  dateTimeFormat: string
): IGridColumn[] => {
  //IAgentSearch is dataItem
  return [
    {
      field: 'userName',
      headerName: 'Username',
      isSortable: false,
      type: 'string',
      width: '25%',
      template: (dataItem) => {
        return <>{dataItem ? dataItem.userName : ''}</>;
      }
    },
    {
      field: 'logonDomain',
      headerName: 'Logon Domain',
      type: 'string',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        return <>{dataItem ? dataItem.logonDomain : ''}</>;
      }
    },
    {
      field: 'firstLog',
      headerName: 'First Log',
      type: 'date',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        return (
          <>{dataItem ? formatDate(dataItem.firstLog, dateTimeFormat) : ''}</>
        );
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'date',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        if (dataItem) {
          const timeAgo = getTimeAgo(dataItem.lastLog);
          return (
            <TimeAgo
              lastLog={dataItem.lastLog}
              learnMoreLink="https://support.activtrak.com/"
              timeAgo={timeAgo}
              localDateTimeFormat={dateTimeFormat}
            />
          );
        }
        return <></>;
      }
    }
  ];
};
