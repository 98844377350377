'use strict';

angular.module('app').controller('insightsImpactEventsModal', InsightsImpactEventsModal);

InsightsImpactEventsModal.$inject = [
    '$scope',
    '$uibModalInstance',
    'modalData'
];

function InsightsImpactEventsModal(
    $scope,
    $uibModalInstance,
    modalData
) {
    $scope.createCohort = function () {
        $uibModalInstance.dismiss();
        modalData.addImpactEvent();
    }
    $scope.close = function () {
        $uibModalInstance.dismiss();
    }
}
