import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';

type IdentityActionsButtonProps = {
  readonly menuOptions: { label: string; disabled?: boolean }[];
  onChangeOption: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: any,
    index: number
  ) => void;
  disabled: boolean;
};

export function IdentityActionsButton(props: IdentityActionsButtonProps) {
  const { menuOptions, onChangeOption, disabled } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'identity-actions-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    event.stopPropagation();
    onChangeOption(event, menuOptions[index], index);
    handleClose();
  };

  const getMenuLabel = (option) => {
    const { label, template } = option;
    return template ? template(label) : label;
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClick} disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List component="nav">
          {menuOptions.map((option, index) => (
            <ListItemButton
              key={option.label}
              disabled={option.disabled}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {getMenuLabel(option)}
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
}
