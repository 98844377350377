import { Role } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';

export const appAcademy: IRoute = {
  name: 'app.help.academy',
  stateDefinition: {
    url: '/help/academy?direct&original_uri'
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [],
    read: [Role.Admin, Role.Configurator, Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.PaidFunctionality]
  }
};

export default appAcademy;
