import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { primaryColor } from '../../../common/constants';

interface ModalTooltipProps extends TooltipProps {
  maxHeight?: string;
  minWidth?: string;
}

export const ModalTooltip: React.FC<ModalTooltipProps> = ({
  minWidth,
  maxHeight,
  ...props
}) => {
  return (
    <Tooltip
      {...props}
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            minWidth: minWidth || 'auto',
            maxHeight: maxHeight || 'auto',
            backgroundColor: '#ffffff',
            color: primaryColor,
            textAlign: 'left',
            margin: '0px !important',
            padding: '16px',
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow:
              '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)'
          }
        }
      }}
    />
  );
};
