import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const ThumbsUpGuy = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 264 275';
  const iconHeight = height ?? '275px';
  const iconWidth = width ?? '264px';
  const title = props.title ?? 'Thumbs Up Guy';
  const className = props.className;

  let classList = 'at-thumbs-up-guy';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
      fillColor="none"
    >
      <path
        d="M234.085 208.551L234.08 208.675L240.25 273.922H195.224L189.388 233.849L186.819 272.895L140.581 272.381L145.492 221.118L149.669 197.357V197.351L150.296 193.776H232.857L232.965 195.07L234.085 208.551Z"
        fill="#2F2E41"
      />
      <path
        d="M115.095 246.376C119.864 247.245 124.836 241.875 126.201 234.381C126.799 231.101 126.606 227.958 125.8 225.387L126.532 221.068L138.802 162.957C138.802 162.957 161.397 117.759 161.396 109.912C161.396 102.065 154.86 98.2173 154.86 98.2173L146.016 98.3L118.282 166.037L113.287 216.093L112.479 222.96C110.819 225.082 109.53 227.955 108.933 231.235C107.567 238.729 110.326 245.507 115.095 246.376Z"
        fill="#FFB6B6"
      />
      <path
        d="M205.058 70.2165L196.838 51.2074L170.122 55.8312L168.324 78.1959L205.058 70.2165Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M205.058 70.2165L196.838 51.2074L170.122 55.8312L168.324 78.1959L205.058 70.2165Z"
        fill="black"
      />
      <path
        d="M263.115 109.777C263.115 109.777 244.106 74.328 240.51 70.7317C238.909 69.1308 236.392 69.1085 234.092 69.5751L207.629 60.4566L198.66 55.417L198.069 65.0048L168.623 70.29C168.786 64.443 169.611 62.219 169.611 62.219L158.822 74.8418L137.758 85.117L137.77 85.2095C136.224 85.7491 134.758 86.6724 133.648 88.1996C129.538 93.8509 118.235 162.695 118.235 162.695L142.382 167.832L145.668 147.21L148.033 165.777L149.832 200.456C149.832 200.456 182.712 228.713 198.639 218.437C214.565 208.162 234.088 208.676 234.088 208.676L234.575 192.602C234.922 192.047 235.116 191.722 235.116 191.722L234.602 134.703V119.53C238.516 124.983 243.239 129.673 248.73 131.869C266.712 139.062 263.115 109.777 263.115 109.777V109.777Z"
        fill="#E6E6E6"
      />
      <path
        d="M180.688 59.1763C194.262 59.1763 205.265 48.1726 205.265 34.5988C205.265 21.0251 194.262 10.0214 180.688 10.0214C167.114 10.0214 156.111 21.0251 156.111 34.5988C156.111 48.1726 167.114 59.1763 180.688 59.1763Z"
        fill="#FFB6B6"
      />
      <path
        d="M198.32 41.545C198.32 41.545 200.457 34.0649 205.266 34.5992C210.074 35.1334 211.143 30.8591 210.074 28.7219C209.006 26.5848 206.869 15.8989 206.869 15.8989C206.869 15.8989 207.937 8.41881 201.526 7.35023C195.114 6.28165 192.977 5.21306 191.908 3.07589C190.84 0.938718 174.811 -1.19845 169.468 2.00731C164.125 5.21306 159.851 11.3637 156.111 13.097C152.371 14.8303 149.165 18.0361 151.302 23.379C153.439 28.7219 156.539 39.1715 156.539 39.1715C156.539 39.1715 161.988 37.2706 163.057 40.4764C164.125 43.6821 159.851 39.4078 165.194 30.8591C170.537 22.3104 170.537 13.7617 179.085 16.9675C187.634 20.1732 197.251 19.1047 196.183 25.5162C195.114 31.9277 198.32 41.5449 198.32 41.5449V41.545Z"
        fill="#2F2E41"
      />
      <path
        d="M196.816 236.387H10.1587C4.88003 236.387 0.585693 232.092 0.585693 226.814V127.662C0.585693 122.384 4.88003 118.089 10.1587 118.089H196.816C202.094 118.089 206.389 122.384 206.389 127.662V226.814C206.389 232.092 202.094 236.387 196.816 236.387Z"
        fill="white"
      />
      <path
        d="M196.816 236.387H10.1587C4.88003 236.387 0.585693 232.092 0.585693 226.814V127.662C0.585693 122.384 4.88003 118.089 10.1587 118.089H196.816C202.094 118.089 206.389 122.384 206.389 127.662V226.814C206.389 232.092 202.094 236.387 196.816 236.387ZM10.1587 119.688C5.76169 119.688 2.18431 123.265 2.18431 127.662V226.814C2.18431 231.211 5.76169 234.788 10.1587 234.788H196.816C201.213 234.788 204.79 231.211 204.79 226.814V127.662C204.79 123.265 201.213 119.688 196.816 119.688H10.1587Z"
        fill="#3F3D56"
      />
      <path
        d="M182.409 130.078C183.733 130.078 184.807 129.004 184.807 127.68C184.807 126.356 183.733 125.282 182.409 125.282C181.084 125.282 180.011 126.356 180.011 127.68C180.011 129.004 181.084 130.078 182.409 130.078Z"
        fill="#3F3D56"
      />
      <path
        d="M188.804 130.078C190.128 130.078 191.202 129.004 191.202 127.68C191.202 126.356 190.128 125.282 188.804 125.282C187.48 125.282 186.406 126.356 186.406 127.68C186.406 129.004 187.48 130.078 188.804 130.078Z"
        fill="#3F3D56"
      />
      <path
        d="M195.198 130.078C196.522 130.078 197.596 129.004 197.596 127.68C197.596 126.356 196.522 125.282 195.198 125.282C193.873 125.282 192.8 126.356 192.8 127.68C192.8 129.004 193.873 130.078 195.198 130.078Z"
        fill="#3F3D56"
      />
      <path
        d="M14.1742 166.446C13.513 166.446 12.9752 166.984 12.9752 167.645C12.9752 167.968 13.0997 168.266 13.3257 168.486C13.5536 168.719 13.8522 168.844 14.1742 168.844H193.6C194.261 168.844 194.799 168.306 194.799 167.645C194.799 167.323 194.674 167.025 194.448 166.805C194.22 166.571 193.922 166.446 193.6 166.446H14.1742Z"
        fill="#E6E6E6"
      />
      <path
        d="M171.221 166.048V169.245H14.176C13.7364 169.245 13.3367 169.069 13.049 168.773C12.7532 168.485 12.5774 168.086 12.5774 167.646C12.5774 166.767 13.2968 166.048 14.176 166.048H171.221Z"
        fill="#03DAC5"
      />
      <path
        d="M191.604 160.052H175.618C173.855 160.052 172.42 158.619 172.42 156.855C172.42 155.092 173.855 153.658 175.618 153.658H191.604C193.367 153.658 194.801 155.092 194.801 156.855C194.801 158.619 193.367 160.052 191.604 160.052Z"
        fill="#E6E6E6"
      />
      <path
        d="M86.8894 143.268H15.3705C13.6075 143.268 12.1732 141.834 12.1732 140.071C12.1732 138.308 13.6075 136.874 15.3705 136.874H86.8894C88.6523 136.874 90.0866 138.308 90.0866 140.071C90.0866 141.834 88.6523 143.268 86.8894 143.268Z"
        fill="#E6E6E6"
      />
      <path
        d="M14.1742 202.416C13.513 202.416 12.9752 202.953 12.9752 203.615C12.9752 203.937 13.0997 204.235 13.3257 204.455C13.5536 204.689 13.8522 204.814 14.1742 204.814H193.6C194.261 204.814 194.799 204.276 194.799 203.615C194.799 203.292 194.674 202.994 194.448 202.774C194.22 202.541 193.922 202.416 193.6 202.416H14.1742Z"
        fill="#E6E6E6"
      />
      <path
        d="M109.674 202.016V205.213H14.176C13.7364 205.213 13.3367 205.038 13.049 204.742C12.7532 204.454 12.5774 204.054 12.5774 203.615C12.5774 202.736 13.2968 202.016 14.176 202.016H109.674Z"
        fill="#893FF2"
      />
      <path
        d="M191.604 196.02H175.618C173.855 196.02 172.42 194.586 172.42 192.823C172.42 191.06 173.855 189.626 175.618 189.626H191.604C193.367 189.626 194.801 191.06 194.801 192.823C194.801 194.586 193.367 196.02 191.604 196.02Z"
        fill="#E6E6E6"
      />
      <path
        d="M219.583 61.07C233.409 61.07 244.617 50.0982 244.617 36.5638C244.617 23.0294 233.409 12.0576 219.583 12.0576C205.758 12.0576 194.55 23.0294 194.55 36.5638C194.55 50.0982 205.758 61.07 219.583 61.07Z"
        fill="#03DAC5"
      />
      <path
        d="M228.079 24.2019C224.746 30.2705 221.414 36.3391 218.081 42.4078C215.957 38.6548 213.844 34.8951 211.715 31.1451C210.98 29.8499 208.979 31.0148 209.717 32.3132C212.185 36.6604 214.628 41.022 217.096 45.3691C217.518 46.1124 218.677 46.1297 219.095 45.3691C222.756 38.7028 226.417 32.0364 230.078 25.37C230.795 24.0637 228.797 22.8946 228.079 24.2019Z"
        fill="white"
      />
      <path
        d="M254.147 101.157L229.205 89.2255C229.205 89.2255 228.204 81.2398 223.526 78.3914C222.731 76.4944 221.816 74.4102 220.985 72.7589C218.93 68.6751 222.013 60.9688 219.444 60.9688C216.875 60.9688 213.078 69.0735 213.279 71.2439C213.433 72.91 214.81 75.8188 215.095 78.3651C211.962 79.5465 209.435 81.5714 207.774 83.1837C206.336 84.5789 205.802 86.6605 206.364 88.5836C207.992 94.1564 212.062 105.666 217.903 105.666C225.609 105.666 227.664 104.125 227.664 104.125C227.664 104.125 243.591 124.161 255.407 125.702C267.224 127.244 254.147 101.157 254.147 101.157V101.157Z"
        fill="#FFB6B6"
      />
    </SvgIconWrapper>
  );
};
