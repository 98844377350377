import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from '@mui/material';

type AssignCategoriesModalProps = {
  open: boolean;
  assignType: string;
  selectedCount: number;
  assignValue: string;
  onClose: () => void;
  onAssign: () => void;
};

export default function AssignCategoriesDialog(
  props: AssignCategoriesModalProps
) {
  const {
    open,
    assignType,
    selectedCount,
    assignValue,
    onClose = () => {},
    onAssign = () => {}
  } = props;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
    >
      <DialogTitle>
        Assign {assignType} to all {selectedCount} selections?
      </DialogTitle>
      <DialogContent>
        <p>
          All selected rows will be set to {assignValue}. The {assignType} will
          be reflected in reports and dashboards.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={onAssign} variant="contained">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
