import React from 'react';
import {
  formatDate,
  getDateTimeFormat,
  getParsedLocalStorageItem
} from '../../common/helpers';
import { GridColumnParams } from '../models/GridColumnParams';
import { IAccountSettings } from '../../common/models';
import { localStorageItems } from '../../common/constants';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { TextOverflow } from '../../common/styles/app.component.styles';

export const useComputerColumns = (props: GridColumnParams) => {
  const { onAliasChanged, isReadOnly } = props;
  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );
  const localDateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);
  return [
    {
      field: 'compid',
      fieldName: 'compid',
      headerName: 'compid',
      type: 'number',
      isSortable: true,
      hidden: true,
      width: '0%'
    },
    {
      field: 'primaryDomain',
      fieldName: 'Primary Domain',
      headerName: 'Primary Domain',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem.primaryDomain} placement="bottom">
            <TextOverflow>{dataItem.primaryDomain}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'computerName',
      fieldName: 'Computer',
      headerName: 'Computer',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem.computerName} placement="bottom">
            <TextOverflow>{dataItem.computerName}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'alias',
      headerName: 'Computer Alias',
      fieldName: 'Computer Alias',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return isReadOnly ? (
          <span>{dataItem.alias}</span>
        ) : (
          <TextField
            variant="outlined"
            defaultValue={dataItem.alias}
            name={dataItem.compId.toString()}
            onChange={onAliasChanged}
          />
        );
      }
    },
    {
      field: 'os',
      headerName: 'OS',
      type: 'string',
      isSortable: true,
      width: '5%',
      template: (dataItem) => {
        return <>{getOSIcon(dataItem.os)}</>;
      }
    },
    {
      field: 'version',
      headerName: 'Agent Version',
      type: 'number',
      isSortable: true,
      width: '10%'
    },
    {
      field: 'firstReported',
      headerName: 'First Log',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return <>{formatDate(dataItem.firstReported, localDateTimeFormat)}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return <>{formatDate(dataItem.lastLog, localDateTimeFormat)}</>;
      }
    }
  ];
};
