'use strict';

let _dataSource;
let _previousUid;
let _modelField;
let _callback;
let _skipDataReload;

const multiSelectBetween = (startUid, endUid, state) => {
    if (!_dataSource.view && typeof _dataSource.view !== 'function') {
        console.error('ActivTrak Error: Cannot select items due to the dataSource.view isn\'t defined or not a function.');
        return false;
    }

    let data = _dataSource.view();
    let updated = [];
    let startFound = false;
    let endFound = false;

    if (data.length === 0) {
        console.error('ActivTrak Error: Cannot select items due to the empty data.');
        return false;
    }

    data.some((row) => {
        endFound = endFound || row.uid === endUid;
        startFound = startFound || row.uid === startUid;

        if (startFound || endFound) {
            updated.push(row);
            row[_modelField] = state;
        }

        return startFound && endFound;
    });

    if (!_skipDataReload) {
        _dataSource.read({
            cache: data
        });
    }

    if (typeof _callback === 'function') {
        _callback(updated);
    }
};

const initialize = function (dataSource, modelField, callback, skipDataReload) {
    _dataSource = dataSource;
    _modelField = modelField;
    _callback = callback;
    _skipDataReload = skipDataReload;
};

const itemSelected = function (uid, state, shiftKey) {
    if (_previousUid && shiftKey) {
        multiSelectBetween(_previousUid, uid, state);
    }
    _previousUid = uid;
};

const checkLocal = function () {
    return {
        dataSource: _dataSource,
        previousUid: _previousUid,
        modelField: _modelField,
        callback: _callback,
        skipDataReload: _skipDataReload
    };
};

export const atMultiSelectCheckboxServiceFunctions = {
    initialize: initialize,
    itemSelected: itemSelected,
    multiSelectBetween: multiSelectBetween,
    checkLocal: checkLocal
};
