import React from 'react';
import { Button } from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { notificationMessage } from '../constants';
import { ISupportControlProps } from '../models/ISupportControl';
import { IAccountIdPayloadDto } from '../models/IPayloadDto';

export const VerifyAccountButton = (props: Partial<ISupportControlProps>) => {
  const { value, accountId, setDetailsModalClose, changeSetting } = props;

  const notificationService = useNotifications();

  const displayButtonText = () => {
    return value ? 'Verified' : 'Verify User';
  };

  const payload: IAccountIdPayloadDto = {
    accountId: accountId
  };

  const handleClick = () => {
    try {
      changeSetting(payload);
      notificationService.success(
        notificationMessage.accountVerifiedSuccess(accountId)
      );
    } catch (error) {
      notificationService.error(notificationMessage.verifyAccountAlert);
      console.error(notificationMessage.verifyAccountError, error);
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <Button
      variant="contained"
      disabled={value}
      onClick={() => handleClick()}
      name="verifyAccount"
      sx={{ marginBottom: '10px', maxWidth: '200px' }}
    >
      {displayButtonText()}
    </Button>
  );
};
