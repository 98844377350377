import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { TopUsersReportViewComponent } from '../../../../_reactivtrak/src/reports/top-users-report/views/TopUsersReport.view';
import { topusersTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedTopUsersReport', {
    template: topusersTemplate,
    controller: 'TopUsersCtrl'
});
angular
    .module('app')
    .component('topUsersReport', react2angular(TopUsersReportViewComponent, ['isOfflineMeetingsIntegrationEnabled']));
