import React from 'react';
import { evaluateResourceAccess } from '../../utils/api/utils.js';
import { Link, Typography } from '@mui/material';
import {
  TRIAL_DASHBOARD_TYPE,
  EXPIRED_DASHBOARD_TYPE
} from '../../utils/constants.js';

const DashboardEmptyMessage = ({ dashboardType, openDialog, profile }) => {
    const typeMessage = () => {
      let typeMessage = 'You have not been granted access to any accounts.';
      if (profile.mspParentAdmin || profile.enterpriseParentAdmin || profile.partnerParentAdmin) {
        switch (dashboardType) {
          case TRIAL_DASHBOARD_TYPE:
            typeMessage='There are no Active Trials in your account yet.';
            break;
          case EXPIRED_DASHBOARD_TYPE:
            typeMessage='There are no Expired Trials in your account yet.';
            break;
          default:
            typeMessage='There are no Paid Subscriptions in your account yet.';
        }
      }
      return typeMessage;
    };

    const renderMessage = () => {
      if (evaluateResourceAccess(profile, 'ADDCUSTOMER') || profile.mspParentAdmin) {
        return (
          <>
            <Typography variant="subtitle2" gutterBottom={true}>
              If you’d like to add a customer account, please click{' '}
              <Link href="#" onClick={openDialog}>
                Add Customers
              </Link>
              . After you submit the new account form, this will create a new
              ActivTrak Trial that you can access from the Active Trials sub-menu.
            </Typography>
          </>
        );
      }

      if (profile.enterpriseParentAdmin || profile.partnerParentAdmin) {
        return (
          <>
            <Typography variant="subtitle2" gutterBottom={true}>
              If you’d like to add a new account, please{' '}
              <Link href="/#/app/help/tickets">Contact Support</Link>. Our
              onboarding team can help you design the optimal account hierarchy
              for your organization.
            </Typography>
          </>
        );
      }

      return null;
    };

    return (
      <>
        <Typography variant="h5" gutterBottom={true}>
          Welcome to your Command Center portal.
        </Typography>
        <Typography variant="h5" gutterBottom={true}>
          {typeMessage()}
        </Typography>
        {renderMessage()}
      </>
    );
};

export default DashboardEmptyMessage;
