import { IRoute } from '../../../../../models/IRoute';

export const appSettingsCategories: IRoute = {
  name: 'app.settings.categories',
  stateDefinition: {
    url: '/settings/categories',
    redirectTo: 'app.settings.classification.tab'
  }
};

export default appSettingsCategories;
