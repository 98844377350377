import { styled } from '@mui/material/styles';
import { gray4, gray6 } from '../../constants';
import { IconButton } from '@mui/material';

export const AdvancedFilterContainer = styled('div')``;

export const AdvancedFilterButton = styled(IconButton)`
  left: -20px;
`;

export const ModalContainer = styled('div')`
  border: 1px solid ${gray4};
  position: absolute;
  background-color: #ffffff;
  z-index: 5;
  top: 40px;
  border-radius: 4px;
  right: -4px;
  width: 600px;
  max-width: 760px;
  padding: 20px;
`;

export const FormElementTitle = styled('div')`
  width: 25%;
  color: ${gray6};
`;

export const FormElementContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  .MuiFormControl-root {
    width: 75%;
  }
`;

export const ActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
