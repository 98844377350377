import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsAlarmlog: IRoute = {
  name: 'app.reports.alarmlog',
  stateDefinition: {
    url: '/alarmlog?alarm',
    params: { user: null, computer: null, alarm: null },
    template: '<alarm-log></alarm-log>',
    data: {
      pageTitle: 'Alarm Log',
      filter: { columns: true, export: true },
      exportText: 'export',
      reportName: 'alarmLogReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsLog,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.User],
    bundleFlags: [BundleFlag.AlarmLog]
  }
};

export default appReportsAlarmlog;
