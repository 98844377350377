import React, { useEffect } from 'react';
import { DateRangeSliderProps } from '../models/IDateRangePicker';
import { isValidTimestamp } from '../utils/reportFilterValidation.utils';
import { getUnixDateString, getUnixTimeString } from '../utils/dateRange.utils';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { timezoneService } from '../../../services';
import { useTimezoneState } from '../../../../timezone/services';
import { getAccountSetting } from '../../../helpers/accountSettings';

export const DateTimeSlider = (props: DateRangeSliderProps) => {
  const {
    min,
    max,
    fromTimestamp,
    isDisabled = false,
    setFilterValues
  } = props;

    const [sliderValue, setSliderValue] = React.useState<number | number[]>(
      min
    );

    useEffect(() => {
      const currentTimestamp = isValidTimestamp(fromTimestamp)
        ? fromTimestamp
        : min;
      setSliderValue(currentTimestamp);
    }, [fromTimestamp, min]);

  const service = timezoneService();
  const { getAccountTimezone, accountTimezone } = useTimezoneState({
    service
  });

  useEffect(() => {
    getAccountTimezone();
  }, [getAccountTimezone]);

  const isTimeFormat24Hour = accountTimezone?.isTimeFormat24Hour === 'true';
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');
  const dateFormat = getAccountSetting('dateFormat').toUpperCase();

  const handleSubmit = (event: React.SyntheticEvent | Event, value: number) => {
    if (isValidTimestamp(value)) {
      setFilterValues({ fromTimestamp: value });
    }
  };

  const handleChange = (event: Event, value: number | number[]) => {
    if (isValidTimestamp(value)) {
      setSliderValue(value);
    }
  };

  return (
    <>
      <Slider
        id="f8d80f7f-20bb-4bab-af91-7bdd4999d11a"
        aria-label="Select Date"
        track="inverted"
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => {
          const label = getUnixTimeString(
            value,
            currentIanaTimeZone,
            isTimeFormat24Hour,
            dateFormat
          );
          const split = label.split(' ');
          return (
            <div style={{ textAlign: 'center' }}>
              {split[0]}
              <br />
              {`${split[1]} ${split[2] || ''}`}
            </div>
          );
        }}
        getAriaValueText={(value) =>
          getUnixTimeString(
            value,
            currentIanaTimeZone,
            isTimeFormat24Hour,
            dateFormat
          )
        }
        shiftStep={60 * 60 * 1000}
        step={60 * 60 * 1000}
        min={min}
        max={max}
        marks
        value={sliderValue}
        onChangeCommitted={handleSubmit}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2">
          {getUnixDateString(min, currentIanaTimeZone, dateFormat)}
        </Typography>
        <Typography variant="body2">
          {getUnixDateString(max, currentIanaTimeZone, dateFormat)}
        </Typography>
      </Box>
    </>
  );
};
