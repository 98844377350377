import React, { useState, useCallback, useEffect } from 'react';
import { useObservableState } from 'observable-hooks';
import Grid from '@mui/material/Grid';
import { SortDirection } from '../../common/enums';
import { useClassification } from '../services';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import CategoryBulkActivities from './CategoryBulkActivities';
import AddCategoryButton from './AddCategoryButton';
import {
  AddNewCategoryButtonDiv,
  ClassificationsSettingsContainer
} from '../styles/Classifications.styles';
import { useSettingsColumns } from '../utils';
import { ICategory } from '../../common/models';
import { sorter } from '../../common/helpers';

export default function ClassificationSettings(props) {
  const { isDisabled } = props;
  const [sortedCategories, setSortedCategories] = useState<ICategory[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [sort, setSort] = useState({
    direction: SortDirection.Ascending,
    orderBy: 'name'
  });

  const { categoryService } = useClassification();

  const columns = useSettingsColumns({
    isDisabled: isDisabled
  });

  const categories = useObservableState(categoryService.getAll());

  // IsLoading
  useEffect(() => {
    if ((!categories || categories.length === 0) && !isLoading) {
      setIsLoading(true);
    } else if (categories && categories.length > 0 && isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    const sorted = sorter(categories, sort.direction, sort.orderBy);
    setSortedCategories(sorted);
  }, [categories, sort]);

  const handleSortOrder = (newOrderDirection, orderBy) => {
    setSort({ direction: newOrderDirection, orderBy: orderBy });
  };

  const handleSelectedItems = (currentSelectedItems: ICategory[]) => {
    const ids = currentSelectedItems.map((c) => c.id);
    categoryService.setSelected(ids);
  };

  const rowDisableCondition = useCallback((rowItem: ICategory) => {
    return rowItem.disabled;
  }, []);

  return (
    <ClassificationsSettingsContainer container>
      <Grid item xs={12}>
        <CategoryBulkActivities categories={sortedCategories} />
      </Grid>
      <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
        <CustomGrid
          isLoading={isLoading}
          data={sortedCategories}
          columns={columns}
          uniqueRowProp="id"
          initialSortField="name"
          initialSortDirection={SortDirection.Ascending}
          onSortOrder={handleSortOrder}
          hasBulkSelection
          onSelectAllClick={handleSelectedItems}
          rowDisableCondition={rowDisableCondition}
          featureDisabled={isDisabled}
        />
        <AddNewCategoryButtonDiv>
          <AddCategoryButton isDisabled={isDisabled} />
        </AddNewCategoryButtonDiv>
      </Grid>
    </ClassificationsSettingsContainer>
  );
}
