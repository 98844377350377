import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  buttonTextColor,
  gray10,
  gray3,
  gray6,
  gray7,
  secondaryFontSize
} from '../../common/constants';

export const useTopUserGroupStyles = {
  headerGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    flexWrap: 'wrap'
  },
  toggleButtonGroup: {
    alignItems: 'center'
  },
  toggleButton: {
    height: '4vh',
    fontWeight: 400,
    '&.MuiToggleButton-root': {
      textTransform: 'none',
      fontSize: secondaryFontSize,
      lineHeight: '18px',
      letterSpacing: '0.25px',
      color: gray6,
      backgroundColor: gray10,
      border: 'none'
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: gray7,
      color: '#FFF'
    },
    '&.MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: gray7,
      color: '#FFF',
      boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.30),0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)`
    },
    '&.MuiToggleButton-root:hover': {
      color: buttonTextColor,
      backgroundColor: gray3,
      boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.14),0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)`
    },
    '@media(min-width: 641px)': {
      width: '7vw'
    }
  },
  toggleButtonLeft: {
    borderRadius: '4px 0px 0px 4px'
  },
  toggleButtonRight: {
    borderRadius: '0px 4px 4px 0px'
  }
};

export const BarGrid = styled(Grid)`
  display: flex;
  &.MuiGrid-root {
    max-width: 95%;
  }
`;

export const BarsExpandCollapse = styled(Button)`
  span.MuiButton-label {
    font-size: 13px;
  }
  &.MuiButton-root:hover {
    background-color: white;
  }
`;

export const SortFilterText = styled(Typography)`
  font-size: ${secondaryFontSize} !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

//TODO: prefer to pass dynamic height based on how many bars are rendered
export const TopUsersContainer = styled(Box)`
  & canvas {
    max-height: 400px;
  }
`;
