import { react2angular } from 'react2angular';
import { UserIdentitiesComponent } from '../../_reactivtrak/src/identity/views/UserIdentities.view';
import { UserIdentityDetailComponent } from '../../_reactivtrak/src/identity/views/UserDetail.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsUsers', react2angular(UserIdentitiesComponent, []));

  angular
    .module('app')
    .component('settingsUsersDetail', react2angular(UserIdentityDetailComponent, []));

}
