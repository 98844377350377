import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const AliasIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '19px';
  const height = props.height ?? '18px';
  const title = props.title ?? 'Create Alias';
  const viewBox = props?.viewBox ?? '0 0 19 18';
  const fillColor = props?.fillColor ?? 'currentColor';
  const className = props.className;

  let classList = 'at-icon-alias';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      className={classList}
      height={height}
      width={width}
      title={title}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <g clipPath="url(#clip0_1093_2210)">
        <path
          d="M9.6428 0L9.1478 0.0225L12.0053 2.88L13.0178 1.875C15.4553 3.0525 17.2103 5.43 17.4803 8.25H18.6053C18.2228 3.63 14.3603 0 9.6428 0ZM9.6428 3C8.1953 3 7.0178 4.1775 7.0178 5.625C7.0178 7.0725 8.1953 8.25 9.6428 8.25C11.0903 8.25 12.2678 7.0725 12.2678 5.625C12.2678 4.1775 11.0903 3 9.6428 3ZM0.680298 9.75C1.0628 14.37 4.9253 18 9.6428 18L10.1378 17.9775L7.2803 15.12L6.2678 16.125C3.8303 14.955 2.0753 12.57 1.8053 9.75H0.680298ZM9.6428 9.75C6.7403 9.75 4.3928 10.9275 4.3928 12.375V13.5H14.8928V12.375C14.8928 10.9275 12.5453 9.75 9.6428 9.75Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1093_2210">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.642822)"
          />
        </clipPath>
      </defs>
    </SvgIconWrapper>
  );
};
