import { resetReportFilters } from '../../components/ReportFilters/hooks/reportFiltersStore';
import { userPermissionStore } from '../../hooks/userPermissionStore';
import { accountSettingsStore } from '../../../common/helpers/accountSettings/accountSettingsStore';
import { getExportStore } from '../../stores/exportStore';
import { resetEntitiesStore } from '../../hooks/entityStore';
import { resetPrivacySettingsStore } from '../../hooks/privacySettingsStore';
import { resetAccountPlanStore } from '../../stores/accountPlanStore';
import { resetStoreCache } from '../../utils/storeCacheHandler';
import { stopExportStatusCheck } from '../../utils/export.utils';

export const resetStores = (): void => {
  resetReportFilters();
  resetEntitiesStore();
  resetPrivacySettingsStore();
  resetAccountPlanStore();
  resetStoreCache();

  const exportStore = getExportStore();
  stopExportStatusCheck(exportStore.exportStatusInterval);

  const accountSettingsState = accountSettingsStore.getState();
  accountSettingsState.reset();

  const userPermissionState = userPermissionStore.getState();
  userPermissionState.reset();
};

export const logout = (params: Record<string, string> | null): void => {
  resetStores();

  // Force a reload to clear all state and check for new app versions
  const queryParams = params
    ? `?${new URLSearchParams(params).toString()}`
    : '';
  window.location.href = `#/login${queryParams}`;
  window.location.reload();
};
