import React from 'react';
import { IViewMoreProps } from '../../../dashboard/models';
import { ViewMoreButtonSC } from './Button.styles';

export const ViewMoreButton = (props: IViewMoreProps) => {
  const { onClick, endIcon, children } = props;
  return (
    <ViewMoreButtonSC onClick={onClick} endIcon={endIcon}>
      {children}
    </ViewMoreButtonSC>
  );
};
