import React from 'react';
import { SvgIconWrapperProps } from './SvgIconWrapperProps';

export const SvgIconWrapper = (props: SvgIconWrapperProps) => {
  const height = props.height ?? '24px';
  const width = props.width ?? '24px';
  const title = props.title ?? '';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const viewBox = props.viewBox ?? '0 0 24 24';
  const style = props.style ?? {};

  let classList = 'at-icon';
  if (className) classList += ` ${className}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      fill={fillColor}
      className={classList}
      focusable="false"
      aria-hidden="true"
      viewBox={viewBox}
      style={style}
    >
      <title>{title}</title>

      {props.children}
    </svg>
  );
};
