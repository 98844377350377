/* DEPRECATED */
import React, { Fragment } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TuneIcon from '@mui/icons-material/Tune';

import {
  AdvancedFilterButton,
  AdvancedFilterContainer,
  ModalContainer
} from './AdvancedFilter.styles';

type ComponentProps = {
  form: React.ReactNode;
  showModal: boolean;
  setShowModal: any;
};
type ModalComponentProps = {
  children: React.ReactNode;
  show: boolean;
  [x: string]: any;
};

const Modal = (props: ModalComponentProps) => {
  const { children, show, ...otherProps } = props;
  return (
    <Fragment>
      {show ? (
        <ModalContainer {...otherProps}>
          <div>{children}</div>
        </ModalContainer>
      ) : null}
    </Fragment>
  );
};

export const AdvancedFilter = (props: ComponentProps) => {
  const { form, showModal, setShowModal } = props;

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
      <AdvancedFilterContainer>
        <AdvancedFilterButton
          onClick={() => {
            setShowModal(!showModal);
          }}
          color="secondary"
        >
          <TuneIcon />
        </AdvancedFilterButton>

        <Modal show={showModal}>{form}</Modal>
      </AdvancedFilterContainer>
    </ClickAwayListener>
  );
};
