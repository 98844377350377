import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { WorkingHoursReportWrapper } from '../utils/WorkingHoursReportWrapper';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { WorkingHoursReportHeader } from '../components/WorkingHoursReportHeader';
import { WorkingHoursReportWrapperProps } from '../models/WorkingHoursTypes';

const WorkingHoursReportView = (props: WorkingHoursReportWrapperProps) => {
  const { isOfflineMeetingsIntegrationEnabled } = props;

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <WorkingHoursReportHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
      />
      <WorkingHoursReportWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
        isOfflineMeetingsIntegrationEnabled={
          isOfflineMeetingsIntegrationEnabled
        }
      />
    </>
  );
};

export const WorkingHoursReportViewComponent = (
  props: WorkingHoursReportWrapperProps
) => (
  <ReactivTrakComponentWrapper>
    <WorkingHoursReportView {...props} />
  </ReactivTrakComponentWrapper>
);
