export enum DashboardWidgetId {
  QuickActions = 'quickActions',
  UserHealth = 'userHealth',
  PendingClassifications = 'pendingClassifications',
  TopUsersGroups = 'topUsersGroups',
  TodaysUserActivity = 'todaysUserActivity',
  TodaysActivityChart = 'todaysActivityChart',
  TodaysProductivity = 'todaysProductivity',
  TodaysProductivityRatios = 'todaysProductivityRatios',
  Workload = 'workload',
  ProductivityGoal = 'productivityGoal',
  VirtualOfficeVisibilitySubheader = 'virtualOfficeVisibilitySubheader',
  OrganizationTrendsSubheader = 'organizationTrendsSubheader',
  VirtualTeamVisibilitySubheader = 'virtualTeamVisibilitySubheader',
  TeamTrendsSubheader = 'teamTrendsSubheader',
  WorkloadBalanceBarChart = 'workloadbarChart',
  TodaysTeamActivity = 'todaysActivity',
  TeamProductivityGoal = 'productivityGoalBarChart',
  TodaysTopCategories = 'todaysTopCategories',
  TodaysProductivityOutlook = 'todaysProductivityOutlook'
}
