// TODO: Remove once exports are refactored

angular.module('app').service('filterState', FilterState);

FilterState.$inject = [];

function FilterState() {
    let _groupBy;

    this.setGroupBy = function (groupBy) {
        _groupBy = groupBy;
    };
    this.getGroupBy = function () {
        return _groupBy;
    };
}
