import React from 'react';
import { getClasses } from './StatusCount.style';
import { Person, PersonOutlined } from '@mui/icons-material';
import ActiveStatus from '../../../models/ActiveStatus';
import { Tooltip } from '../../';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';

const StatusCount = (props) => {
    let { activeStatus, count, loading } = props;
    const classes = getClasses(activeStatus);

    const getTooltipTitle = () => {
        switch (activeStatus) {
            case ActiveStatus.Active:
                return 'Active Users';
            case ActiveStatus.Passive:
                return 'Passive Users';
            case ActiveStatus.Inactive:
                return 'Inactive Users';
            default:
                return 'Active Status Not Found';
        }
    };

    return (
        <Tooltip title={getTooltipTitle()}>
            <span style={classes.wrapper}>
                <SkeletonLoader loading={loading} variant="circular" height="30px" width="30px">
                    {activeStatus == ActiveStatus.Inactive ? (
                        <PersonOutlined sx={classes.icon} />
                    ) : (
                        <Person sx={classes.icon} />
                    )}
                    <div style={classes.count}>{count}</div>
                </SkeletonLoader>
            </span>
        </Tooltip>
    );
};

export default StatusCount;
