import { BundleFlag } from './BundleFlag';
import { Role } from './Roles';

export enum DashboardType {
  default = 'default',
  admin = 'admin',
  organization = 'organization',
  management = 'management'
}
export const DashboardList = ['admin', 'organization', 'management'];
export const DashboardListNg = [
  'app.dashboard',
  'app.dashboardCurrent.admin',
  'app.dashboardCurrent.organization',
  'app.dashboardCurrent.management'
];

export type DashboardOptionType = {
  name: string;
  id: DashboardType;
  roles: Role[];
  flag?: string;
};

export const dashboardOptions: DashboardOptionType[] = [
  {
    name: 'Activity Dashboard',
    id: DashboardType.default,
    roles: [
      Role.Admin,
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.User,
      Role.Configurator,
      Role.Viewer
    ]
  },
  {
    name: 'Admin Panel',
    id: DashboardType.admin,
    roles: [Role.Admin, Role.SuperAdmin, Role.SupportAdvanced],
    flag: BundleFlag.AdminPanelHomepage
  },
  {
    name: 'Organization Overview',
    id: DashboardType.organization,
    roles: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.User,
      Role.Viewer
    ],
    flag: BundleFlag.OrganizationOverviewHomepage
  },
  {
    name: 'Team Management',
    id: DashboardType.management,
    roles: [
      Role.Admin,
      Role.User,
      Role.Configurator,
      Role.Viewer,
      Role.SuperAdmin
    ],
    flag: BundleFlag.TeamManagementHomepage
  }
];

//this is to make es5 Angular project happy
export default {
  DashboardType,
  DashboardList,
  DashboardListNg,
  dashboardOptions
};
