import { Role } from '../../../../enums';
import { resetStores } from '../../../../helpers/authentication';
import { IRoute } from '../../../../models/IRoute';

export const supportPortal: IRoute = {
  name: 'supportportal',
  stateDefinition: {
    url: '/support',
    template:
      '<sidebar-menu-new></sidebar-menu-new><support-portal envconfig="envConfig"></support-portal>',
    controller: 'SupportPortalCtrl',
    data: { pageTitle: 'Support Portal' },
    resolve: {
      resetStores: [
        function () {
          resetStores();
          return true;
        }
      ]
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.SupportBasic,
      Role.SupportIntermediate
    ],
    read: []
  }
};

export default supportPortal;
