import { templateServiceFunctions } from '../serviceFunctions/templateServiceFunctions';

const _createChart = (
    chartType,
    { result, step, barMax, height, isMobile, chartService }
) => {
    const chartSettings = {
        series: result.data.map(function (item) {
            return {
                name: item.name,
                data: item.totals,
                stack: 'time',
                color: item.color,
                border: {
                    width: 0
                }
            };
        }),
        categories: result.dates,
        tooltipTemplate: kendo.template(
            chartType === 'area'
                ? templateServiceFunctions.chartTooltipTimeTemplateWithCategory
                : templateServiceFunctions.chartTooltipTimeTemplate
        ),
        labelTooltipTemplate: kendo.template(
            templateServiceFunctions.labelTooltipTemplate
        ),
        data: result.data.totals,
        colorScheme: 'neutral',
        legendVisible: false,
        rotation: -45,
        majorUnit: templateServiceFunctions.calculateTimeMajorUnit(barMax),
        height
    };

    if (step) {
        chartSettings.step = step > 2 || isMobile ? step : 2;
    }

    switch (chartType) {
        case 'area':
            return chartService.buildAreaChart(chartSettings);
        case 'column':
            return chartService.buildColumnChart(chartSettings);
        default:
            console.error(
                `ActivTrak Error: Unknown chart type requested. Chart Type: ${chartType}`
            );
            return chartService.buildColumnChart(chartSettings);
    }
};

class ReportChart {
    constructor({ chartService }) {
        this.chartService = chartService;
    }

    createChart({
        fullscreenChart,
        height,
        chartReadyCallback,
        isMobile,
        data
    }) {
        const reportChart = this;
        return {
            getData: function () {
                let promise = new Promise((resolve) => {
                    setTimeout(function () {
                        resolve(data);
                    });
                });

                promise.success = function (callback) {
                    promise.then(callback);
                    return promise;
                };

                promise.error = function (callback) {
                    promise.then(null, callback);
                    return promise;
                };

                return promise;
            },
            checkData: function (data) {
                return data && data.dates && data.dates.length > 0;
            },
            buildChart: function (result) {
                var columnsWidth = 32 * (result.dates.length + 1);
                var wrapperName = fullscreenChart
                    ? '#usageChartFullscreenWrapper'
                    : '#usageChartWrapper';
                var containerWidth = angular.element(wrapperName).width();

                var bars = [];

                if (result.data.length > 0) {
                    _.forEach(result.data[0].totals, function (elem, idx) {
                        var total = elem;
                        for (var i = 1; i < result.data.length; i++) {
                            total += result.data[i].totals[idx];
                        }
                        bars.push(total);
                    });
                }

                var barMax = Math.max.apply(null, bars) / 2.5;

                chartReadyCallback(true);

                if (columnsWidth > containerWidth) {
                    var labelsCount = containerWidth / 80;
                    var step = Math.round(result.dates.length / labelsCount);

                    return _createChart('area', {
                        result,
                        step,
                        barMax,
                        height,
                        isMobile,
                        chartService: reportChart.chartService
                    });
                }

                return _createChart('column', {
                    result,
                    barMax,
                    height,
                    isMobile,
                    chartService: reportChart.chartService
                });
            },
            preventResizeProcessing: false
        };
    }
}

export default ReportChart;
