'use strict';

var atComparatorServiceFunctions = require('_app/serviceFunctions/atComparatorServiceFunctions.js')
    .atComparatorServiceFunctions;

angular.module('app').service('atComparatorService', AtComparatorService);

AtComparatorService.$inject = [];

function AtComparatorService() {
    var service = this;

    angular.extend(service, atComparatorServiceFunctions);
}
