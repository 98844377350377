'use strict';

angular.module('app').service('trayConfigWizard', TrayConfigWizard);

function TrayConfigWizard() {
    var configureState = 'incomplete';
    var configurationError = null;

    this.trayConfigState = function () {
        return configureState;
    };
    this.trayConfigError = function () {
        return configurationError;
    };

    this.openConfigWizard = function (urlAddress) {
        var configWindow = window.open(urlAddress, '_blank', 'width=500,height=500,scrollbars=no');
        // Listen to popup messages
        configureState = 'incomplete';

        function onmessage(e) {
            if (e.data.type === 'tray.configPopup.error') {
                // Handle popup error message
                configurationError = e.data.err;
            }
            if (e.data.type === 'tray.configPopup.cancel') {
                configWindow.close();
            }
            if (e.data.type === 'tray.configPopup.finish') {
                // Handle popup finish message
                configureState = 'complete';
                configWindow.close();
            }
            if (e.data.type === 'tray.configPopup.validate') {
                // Return validation in progress
                configWindow.postMessage(
                    {
                        type: 'tray.configPopup.client.validation',
                        data: {
                            inProgress: true
                        }
                    },
                    '*'
                );
            }
            setTimeout(function () {
                // Add errors to all inputs
                var errors = e.data.data.visibleSlots.reduce(function (errors, externalId) {
                    console.log(`Visible ${externalId} value:`, e.data.data.slotValues[externalId]);
                    // Uncomment next line to set an error message
                    // errors[externalId] = 'Custom error message';
                    return errors;
                }, {});
                // Return validation
                configWindow.postMessage(
                    {
                        type: 'tray.configPopup.client.validation',
                        data: {
                            inProgress: false,
                            errors: errors
                        }
                    },
                    '*'
                );
            }, 2000);
        }

        window.addEventListener('message', onmessage);
        // Check if popup window has been closed before finishing the configuration.
        // We use a polling function due to the fact that some browsers may not
        // display prompts created in the beforeunload event handler.
        var CHECK_TIMEOUT = 1000;
        var checkWindow = function () {
            //TOTO update status to refresh list of solutions
            if (!configWindow || configWindow.closed) {
                // Handle popup closing
                window.removeEventListener('message', onmessage);
            } else {
                setTimeout(checkWindow, CHECK_TIMEOUT);
            }
        };
        checkWindow();
        return configWindow;
    };
}
