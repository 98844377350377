import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ViewMoreButton } from '../Button/ViewMoreButton';
import { IExpandableListProps } from '../../models/IExpandableList';

export const ExpandableList = (props: IExpandableListProps) => {
  const { showButton = false, children, onClick } = props;

  const [buttonLabel, setButtonLabel] = useState<string>('View more');
  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

  useEffect(() => {
    setButtonLabel(isShowingMore ? 'View fewer' : 'View more');
    onClick?.(isShowingMore);
  }, [isShowingMore, onClick]);

  const handleClick = useCallback(() => {
    setIsShowingMore(!isShowingMore);
  }, [isShowingMore]);

  return (
    <>
      {children}
      {showButton && (
        <Box textAlign="center">
          <ViewMoreButton
            onClick={handleClick}
            endIcon={
              isShowingMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />
            }
          >
            {buttonLabel}
          </ViewMoreButton>
        </Box>
      )}
    </>
  );
};
