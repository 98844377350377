import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  Star,
  StarBorder,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Delete
} from '@mui/icons-material';
import { gray1, gray20 } from '../../constants';

const INDENT_FACTOR = 15;

export const sidebarClasses = {
  emptyMessage: {
    color: gray1,
    background: '#686C76',
    width: '200px',
    margin: '10px auto',
    padding: '10px',
    borderRadius: '5px'
  },
  emptyMessageTitle: {
    color: gray1,
    fontSize: '14px',
    fontWeight: 700
  },
  emptyMessageBody: {
    color: gray1,
    fontSize: '14px',
    lineHeight: '19px',
    marginTop: '5px'
  },
  navigationItemIcon: {
    flex: '0 0 24px',
    marginLeft: '8px',
    height: '30px',
    position: 'absolute'
  }
};

export const DeleteFilled = styled(Delete)`
  margin-right: 28px;
`;

export const StarFilled = styled(Star)`
  margin-right: 28px;
`;

export const StarHollow = styled(StarBorder)`
  margin-right: 28px;
`;

export const MenuItemLabel = styled(Box)<{ level: number }>`
  margin-left: ${(props) =>
    props.level > 0 ? props.level * INDENT_FACTOR : 5}px !important;
  padding-left: 10px !important;
  color: ${gray20};
  flex: 1 1 auto;
`;

export const SlidingList = styled('ul')`
  position: relative;
  z-index: 0;
`;

export const ArrowUp = styled(KeyboardArrowUp)`
  color: ${gray20};
`;

export const ArrowDown = styled(KeyboardArrowDown)`
  color: ${gray20};
`;
