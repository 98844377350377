import { IAccountSettings } from '../models/IAccountSettings';
import moment from 'moment';

export const newFlexUsageEvent = (settings: IAccountSettings) => {
  const {
    flexUsageEnabled,
    usedLicenses,
    totalLicenses,
    flexUsageExpirationDate,
    account
  } = settings;
  if (!flexUsageEnabled || !window.Intercom) {
    return;
  }

  const intercomFlexUsageData = {
    UserCount: usedLicenses,
    LicenseCount: totalLicenses,
    AmountOver: usedLicenses - totalLicenses,
    GracePeriodExpiration: moment(flexUsageExpirationDate).format(
      'M/D/YYYY h:mm a'
    ),
    AccountId: account
  };

  window.Intercom(
    'trackEvent',
    'flex-usage-notification',
    intercomFlexUsageData
  );
};
