'use strict';

angular.module('chromebook', ['ui.router', 'ui.router.state.events', 'config', 'utils']);
angular.module('chromebook').controller('ChromeAgentInstallCtrl', ChromeAgentInstallCtrl);

ChromeAgentInstallCtrl.$inject = ['$scope', '$window', '$state', 'chromeAgentConfirmationService', 'loginService'];

function ChromeAgentInstallCtrl($scope, $window, $state, chromeAgentConfirmationService, loginService) {
    $scope.token = $state.params.token;
    $scope.email = $state.params.email;

    chromeAgentConfirmationService
        .confirm($scope.token, $scope.email)
        .success(function (result) {
            $window.location.href = result.url;
        })
        .error(function (result) {
            console.log('%c result: ', 'font-size:12px;background-color: #C5B37A;color:#fff;', result);
            // If logged in and email in url query does not match logged in email, logout user
            if (loginService.isLoggedIn() && $scope.email !== loginService.getUsername()) {
                loginService.logout();
            } else {
                console.log('going to home page');
                $state.go('app.dashboard', {
                    chromebookInstallError: result.error
                });
            }
        });
}

angular.module('chromebook').service('chromeAgentConfirmationService', ChromeAgentConfirmationService);

ChromeAgentConfirmationService.$inject = ['$http', 'loginServiceUrl'];

function ChromeAgentConfirmationService($http, loginServiceUrl) {
    this.confirm = function (token, email) {
        return $http.post(loginServiceUrl.apiUrl() + '/api/settings/chromebook/users/confirm', {
            secret: token,
            email: email
        });
    };
}
