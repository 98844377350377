import { useCallback } from 'react';
import { IStorageDto, IStorageServiceProps, IStorageState } from '../models';

export const useStorageState = ({
  storageService
}: IStorageServiceProps): IStorageState => {
  const { getTotalStorage } = storageService;

  const getStorageInfo = useCallback(
    async (token: string): Promise<IStorageDto> => {
      try {
        const storageDetails = await getTotalStorage(token);
        return storageDetails;
      } catch (error) {
        console.error(
          `ActivTrak Error: Unable to fetch storage information.`,
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    getStorageInfo
  };
};
