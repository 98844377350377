import { useCallback, useState } from 'react';
import { fetchData, postData, putData } from '../../common/helpers';
import { useNotifications } from '../../common/services/Notifications';
import { ICoreCategory, ICoreCategoriesState } from '../models';

export const useCoreCategoriesState = (): ICoreCategoriesState => {
  const notificationService = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coreCategories, setCoreCategories] = useState<ICoreCategory[]>(null);

  const fetchCoreCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchData<ICoreCategory[]>({
        path: '/api/insights/categories/core'
      });
      data.forEach((datum) => {
        let categoryNames = datum.categories.map((c) => c.category).join(', ');
        if (categoryNames.length > 80) {
          categoryNames = categoryNames.substring(0, 80) + '...';
        }
        datum.categoryNames = categoryNames;
      });
      setCoreCategories(data);
    } catch (error) {
      notificationService.error('Error fetching Time on Task configurations');
      console.log('Error fetching Time on Task configurations', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    fetchCoreCategories();
  }, [fetchCoreCategories]);

  const createCoreCategory = useCallback(async (payload: ICoreCategory) => {
    setIsLoading(true);
    try {
      await postData<void>({
        path: '/api/insights/categories/core',
        args: payload
      });
    } catch (error) {
      console.log(
        'ActivTrak Error: Unable to create Time On Task configuration',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const editCoreCategory = useCallback(async (payload: ICoreCategory) => {
    setIsLoading(true);
    try {
      await putData<void>({
        path: '/api/insights/categories/core',
        args: payload
      });
    } catch (error) {
      console.log(
        'ActivTrak Error: Unable to edit Time on Task configuration',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteCoreCategories = useCallback(async (payload: ICoreCategory[]) => {
    setIsLoading(true);
    const groupIds = payload.map((cc) => cc.groupId);
    try {
      await postData<void>({
        path: '/api/insights/categories/core/removelist',
        args: groupIds
      });
    } catch (error) {
      console.log(
        'ActivTrak Error: Unable delete Time on Task configurations',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    init,
    isLoading,
    coreCategories,
    createCoreCategory,
    editCoreCategory,
    deleteCoreCategories
  };
};
