import React, { useState } from 'react';
import { IUser } from '../models';
import { FormControlLabelSC, dntStyles as classes } from '../styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onMergeUsers?: () => void;
  selectedUsers: IUser[];
  mostRecentlyUpdatedUser: IUser;
};

export const MergeUsersConfirmationModal = (props: ComponentProps) => {
  const {
    open,
    onClose,
    onMergeUsers = () => {},
    selectedUsers,
    mostRecentlyUpdatedUser
  } = props;
  const [checked, setChecked] = useState(false);

  const close = () => {
    onClose();
    setChecked(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleMerge = async (): Promise<void> => {
    onMergeUsers();
    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth={'md'}>
      <DialogTitle id="merge-users-dialog-title">Merge Users</DialogTitle>
      <DialogContent id="merge-users-dialog-content">
        <label htmlFor={'merge-users-dialog-content'}>
          The destination user, <strong>{mostRecentlyUpdatedUser.user}</strong>,
          is the most recent active selected user. All data for the selected
          source users will be updated to match the destination user after the
          scheduled merge is completed.
        </label>
        <TableContainer sx={classes.tableContainer}>
          <Table stickyHeader size="small" sx={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={classes.tableHeader}>Logon Domain</TableCell>
                <TableCell sx={classes.tableHeader}>User</TableCell>
                <TableCell sx={classes.tableHeader}>Alias</TableCell>
                <TableCell sx={classes.tableHeader}>Record</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUsers.map((selectedUser: IUser) => (
                <TableRow key={selectedUser.id}>
                  <TableCell
                    sx={
                      selectedUser.id === mostRecentlyUpdatedUser.id
                        ? classes.cellDest
                        : classes.cellSource
                    }
                  >
                    {selectedUser.logonDomain}
                  </TableCell>
                  <TableCell
                    sx={
                      selectedUser.id === mostRecentlyUpdatedUser.id
                        ? classes.cellDest
                        : classes.cellSource
                    }
                  >
                    {selectedUser.user}
                  </TableCell>
                  <TableCell
                    sx={
                      selectedUser.id === mostRecentlyUpdatedUser.id
                        ? classes.cellDest
                        : classes.cellSource
                    }
                  >
                    {selectedUser.alias}
                  </TableCell>
                  <TableCell
                    sx={
                      selectedUser.id === mostRecentlyUpdatedUser.id
                        ? classes.cellDest
                        : classes.cellSource
                    }
                  >
                    {selectedUser.id === mostRecentlyUpdatedUser.id
                      ? 'Destination'
                      : 'Source'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <FormControlLabelSC
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChecked}
              />
            }
            label="I acknowledge that by merging users, any historical or future data generated by the source user
            will be associated with the destination user, and the source user's user entries will be removed.
            This action is permanent and cannot be reversed. "
          />
          <label
            htmlFor={'do-not-track-users-dialog-content'}
            style={classes.mergeTimingLabel}
          >
            *Scheduled merges will process overnight at 12am Central Standard
            Time. Data collection will be paused during that time. Once the
            merge is complete the log will be updated and resume data
            collection.
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleMerge}
          color="primary"
          variant="contained"
          disabled={!checked}
        >
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  );
};
