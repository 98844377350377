import { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { ICategory } from '../../common/models';
import DeleteCategoriesButton from './DeleteCategoriesButton';

interface ClassificationBulkActionsProps {
  categories: ICategory[];
}

export default function ClassificationBulkActions(
  props: ClassificationBulkActionsProps
) {
  const { categories } = props;

  const [state, setState] = useState({ isDisabled: true, selected: [] });

  useEffect(() => {
    const selected = filter(categories, (c) => c.selected);
    const isDisabled = !selected || selected.length === 0;
    setState({ isDisabled, selected });
  }, [categories]);

  return categories ? (
    <DeleteCategoriesButton
      isDisabled={state.isDisabled}
      selectedCategories={state.selected}
    />
  ) : null;
}
