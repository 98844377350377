'use strict';

angular.module('app').service('dashboardApiService', DashboardApiService);

DashboardApiService.$inject = ['$http', '$state', 'dashboardApiSampleService', 'envConfig'];

function DashboardApiService($http, $state, sample, envConfig) {
    function isSignup() {
        return $state.current.name === 'signup.modal' || $state.current.name === 'signup.google';
    }

    this.getApiUrl = function (subpage) {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/dashboard';

        if (subpage !== undefined) {
            url += '/' + subpage;
        }

        return url + '?';
    };

    this.getAlarms = function (parameters) {
        return isSignup()
            ? sample.getAlarms
            : $http.get(envConfig.apiUrl() + '/api/dashboard/alarms?' + parameters, { ignoreLoadingBar: true });
    };

    this.getQuickStats = function (parameters) {
        return isSignup()
            ? sample.getQuickStats
            : $http.get(this.getApiUrl('quickstats') + parameters, { ignoreLoadingBar: true });
    };

    this.getThumbnails = function (parameters) {
        return isSignup()
            ? sample.getThumbnails
            : $http.get(envConfig.apiUrl() + '/api/dashboard/thumbnails?' + parameters, { ignoreLoadingBar: true });
    };

    this.getApplications = function (parameters, options) {
        return isSignup()
            ? sample.getApplications
            : $http.get(this.getApiUrl('applications') + parameters + '&type=' + options, {
                  ignoreLoadingBar: true
              });
    };

    this.getSites = function (parameters, options) {
        return isSignup()
            ? sample.getSites
            : $http.get(this.getApiUrl('sites') + parameters + '&type=' + options, {
                  ignoreLoadingBar: true
              });
    };

    this.getCategories = function (parameters, options) {
        return isSignup()
            ? sample.getCategories
            : $http.get(this.getApiUrl('categories') + parameters + '&type=' + options, {
                  ignoreLoadingBar: true
              });
    };

    //This function was written this way due to some issue in the browser that refused to
    //generate the API call, threw no error, and just kept loading the widget. Tried numerous refactors,
    //this is the only way that works.
    this.getProductivity = function (parameters, options) {
        if (isSignup()) {
            return sample.getProductivity;
        }

        var url =
            envConfig.reportingServiceUrl() + '/reports/v1/dashboard/productivity?' + parameters + '&type=' + options;

        return $http.get(url, {
            ignoreLoadingBar: true
        });
    };

    this.getUsers = function (parameters, groupBy) {
        return isSignup()
            ? sample.getUsers
            : $http.get(envConfig.apiUrl() + '/api/topusers?' + parameters + '&groupBy=' + groupBy, {
                  ignoreLoadingBar: true
              });
    };

    //This should really be in the productivity service, as it also feeds the productivity report
    this.getAggregate = function (parameters) {
        return isSignup()
            ? sample.getAggregate
            : $http.get(envConfig.reportingServiceUrl() + '/reports/v1/productivity?' + parameters, {
                  ignoreLoadingBar: true
              });
    };

    this.getUncategorizedApplications = function (parameters) {
        return isSignup()
            ? sample.getApplications
            : $http.get(envConfig.apiUrl() + '/api/dashboard/applications/uncategorized?' + parameters, {
                  ignoreLoadingBar: true
              });
    };

    this.getUncategorizedSites = function (parameters) {
        return isSignup()
            ? sample.getSites
            : $http.get(envConfig.apiUrl() + '/api/dashboard/sites/uncategorized?' + parameters, {
                  ignoreLoadingBar: true
              });
    };
}
