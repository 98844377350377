import { useCallback, useState } from 'react';

import {
  ILandingPageSettingsState,
  LandingPageSettingsStateProps
} from '../models';

export const useLandingPageSettingsState = ({
  service,
  notificationService
}: LandingPageSettingsStateProps): ILandingPageSettingsState => {
  const { fetchLandingPageSetting, postLandingPageSetting } = service;

  const [setting, setSetting] = useState<string>('app.dashboard');
  const [isLoading, setLoading] = useState<boolean>(false);

  

  const getLandingPageSetting = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchLandingPageSetting();
      if (res?.data) {
        const data = res.data;
        setSetting(data);
      } else {
        throw new Error('No Data');
      }
    } catch (error) {
      notificationService.error('Unable to load landing page preference');
      console.error(
        'ActivTrak Error: Unable to load landing page preference',
        error
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  const init = useCallback(() => {
    getLandingPageSetting();
  }, [getLandingPageSetting]);

  const updateSetting = useCallback(
    async (val) => {
      setLoading(true);
      try {
        await postLandingPageSetting({
          LandingPage: val
        });
        notificationService.success(
          'Success! Landing Page Setting is updated!'
        );
        setSetting(val);
      } catch (error) {
        notificationService.error('Unable to save landing page preference');
        console.error(
          'ActivTrak Error: Unable to save landing page preference',
          error
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    setting,
    isLoading,
    init,
    updateSetting
  };
};
