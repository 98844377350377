import React from 'react';
import { GroupClassificationService } from './';
import { IGroupClassificationService } from '../../models';

const { createContext, useContext } = React;

interface IGroupClassificationProvider {
  groupClassificationService: IGroupClassificationService;
}

const GroupClassificationContext =
  createContext<IGroupClassificationProvider>(null);

type Props = {
  children: React.ReactNode;
  groupClassificationService?: IGroupClassificationService;
};

export const GroupClassificationsProvider = (props: Props) => {
  const { children } = props;

  const value = {
    groupClassificationService:
      props.groupClassificationService || new GroupClassificationService()
  };

  return (
    <GroupClassificationContext.Provider value={value}>
      {children}
    </GroupClassificationContext.Provider>
  );
};

export const useGroupClassifications = (): IGroupClassificationProvider => {
  return useContext(GroupClassificationContext);
};
