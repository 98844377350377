import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { TopApplicationsReportViewComponent } from '../../../../_reactivtrak/src/reports/top-applications-report/views/TopApplicationsReport.view.tsx';
import { topapplicationsTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedTopApplicationsReport', {
    template: topapplicationsTemplate,
    controller: 'TopApplicationsCtrl'
});

angular.module('app').component('topApplicationsReport', react2angular(TopApplicationsReportViewComponent, []));
