import moment from 'moment';
import { IAgentsListFilter } from '../models';

export const formatUserComplexName = (
  domain?: string,
  user?: string,
  alias?: string
): string => {
  let labelText = ``;
  if (domain) {
    labelText += `${domain}`;
  }
  if (user) {
    if (labelText.length) {
      labelText += `${String.fromCharCode(92)}`;
    }
    labelText += `${user}`;
  }
  if (alias) {
    if (labelText.length) {
      labelText += ` `;
    }
    labelText += `(${alias})`;
  }
  return labelText;
};

export const bulkFilterMenuItems: IAgentsListFilter[] = [
  {
    label: 'All Users',
    template: () => 'All Users',
    filter: 'limit',
    filterValue: ''
  },
  {
    label: 'Users active in last 30 days',
    template: () => 'Users active in last 30 days',
    filter: 'limit',
    filterValue: '30'
  }
];

export const filterUserAgentsByDateRange = (
  currentData: any[],
  numOfDays: number,
  currentIanaTimeZone: string
) => {
  if (numOfDays) {
    const startDate = moment()
      .tz(currentIanaTimeZone)
      .startOf('day')
      .subtract(numOfDays, 'days')
      .toISOString();

    return currentData.filter((data) =>
      moment(data.lastLog).tz(currentIanaTimeZone).isAfter(startDate, 'days')
    );
  }
};
