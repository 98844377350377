import { IBarChartCopyTypes, IBarStatementTypes } from '../models';

const prodBarStatements: IBarStatementTypes = {
  great: 'All users are achieving their goal.',
  okay: 'Goal achievement is lower than expected.'
};

export const prodBarChartWidgetCopy: IBarChartCopyTypes = {
  great: {
    top: 'Great! ',
    bottom: prodBarStatements.great
  },
  okay: {
    top: 'Room for improvement.',
    bottom: prodBarStatements.okay
  }
};

export const prodBarModalSubtitles: IBarStatementTypes = {
  great: prodBarStatements.great,
  okay: prodBarStatements.okay
};
