import { errorPrefix } from './constants';
import TimeMode from '../models/TimeMode';

export const userPulseSort = (userPulses, sort, timeMode) => {
  let sortField;
  try {
    switch (sort) {
      case 'highestProductivity':
        sortField =
          timeMode === TimeMode.Total
            ? 'productiveTotalRatio'
            : 'productiveActiveRatio';
        return _.orderBy(
          userPulses,
          [sortField, 'activeSort', 'name'],
          ['desc', 'desc', 'asc']
        );
      case 'firstSeen':
        return _.orderBy(
          userPulses,
          ['firstSeen', 'activeSort', 'name'],
          ['asc', 'desc', 'asc']
        );
      case 'lastSeen':
        return _.orderBy(
          userPulses,
          ['lastSeen', 'activeSort', 'name'],
          ['desc', 'desc', 'asc']
        );
      case 'productiveHours':
        return _.orderBy(
          userPulses,
          ['productiveTime', 'activeSort', 'name'],
          ['desc', 'desc', 'asc']
        );
      case 'totalHours':
        sortField = timeMode === TimeMode.Total ? 'totalTime' : 'activeTime';
        return _.orderBy(
          userPulses,
          [sortField, 'activeSort', 'name'],
          ['desc', 'desc', 'asc']
        );
      default:
        return _.orderBy(
          userPulses,
          (up) => up.name.toLowerCase(),
          ['name'],
          ['asc']
        );
    }
  } catch (e) {
    console.error(`${errorPrefix} userPulseSort`, e);
  }
};
