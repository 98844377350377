import { connect } from '_reactivtrak/src/_connect/connections';

angular
    .module('app')
    .constant('googleAppClientId', '385938211088-92f2cuj6p1frqdq2l978ah1j9q3q248a.apps.googleusercontent.com')
    .config(config);

config.$inject = [
    'localStorageServiceProvider',
    '$httpProvider',
    '$animateProvider',
    'cfpLoadingBarProvider',
    '$locationProvider',
    'agcLibraryLoaderProvider'
];

function config(
    localStorageServiceProvider,
    $httpProvider,
    $animateProvider,
    cfpLoadingBarProvider,
    $locationProvider,
    agcLibraryLoaderProvider
) {
    agcLibraryLoaderProvider.setLoader('Null');
    $locationProvider.hashPrefix('');

    localStorageServiceProvider.setPrefix('activTrak').setStorageType('localStorage').setNotify(false, false);
    $httpProvider.interceptors.push('authInterceptor');

    $animateProvider.classNameFilter(/^((?!(hide-without-animation)).)*$/);

    cfpLoadingBarProvider.includeSpinner = false;
}

// Invoke connections module - TODO: need a better name for "connections"
connect(angular);

// This is to redirect from the /classification abstract route - I know, it's not pretty... -Leo M.
angular.module('app').run([
    '$rootScope',
    '$state',
    function ($rootScope, $state) {
        $rootScope.$on('$locationChangeStart', function (event, toState) {
            if (toState.endsWith('/settings/classification')) {
                $state.go('app.settings.classification.tab', { activeTab: 'pending' });
            }
        });
    }
]);
