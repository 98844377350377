'use strict';

angular.module('app').directive('alarmVideoPlayer', AlarmVideoPlayer);

function AlarmVideoPlayer() {
    return {
        restrict: 'E',
        scope: {
            selectedVideoId: '=',
            videos: '=',
            toggleFullscreenDetails: '='
        },
        template: require('views/alarmVideo/alarmVideoPlayer.html'),

        controller: [
            '$scope',
            '$window',
            '$filter',
            'messagesService',
            'activityLogService',
            '$timeout',
            'alarmService',
            'localStorageService',
            'videosService',
            'FileSaver',
            'atHelperFunctions',
            '$http',
            'notificationService',
            function(
                $scope,
                $window,
                $filter,
                msg,
                activityLogService,
                $timeout,
                alarmService,
                localStorageService,
                videosService,
                FileSaver,
                atHelperFunctions,
                $http,
                notificationService
            ) {
                var currentVideoIndex = 0;
                var closingDetails = false;
                var playSpeed = 1;
                var lastRow;
                var hasScrolledLog = false;
                $scope.videoLoading = false;

                $scope.showDetails =
                    localStorageService.get('alarm-video-gallery-view-mode') ===
                    1;
                $scope.detailsButton = $scope.showDetails ? 'show' : 'hide';
                $scope.autoplay =
                    localStorageService.get('video-player-autoplay') || false;
                $scope.autoplayButton = $scope.autoplay ? 'Autoplay' : '';
                $scope.isLight =
                    localStorageService.get('video-player-is-light') || false;

                // Utilities
                $scope.getTimeStamp = function(t) {
                    if (!t) {
                        return;
                    }
                    return atHelperFunctions.getMomentTimeStamp(t);
                };

                $scope.getDuration = function(a, b) {
                    return atHelperFunctions.getDuration(a, b);
                };

                $scope.filterTime = function(t) {
                    return atHelperFunctions.filterTime(t, $filter);
                };

                $scope.activityLogGridHeight = function() {
                    return $window.innerHeight * 0.67 + 52;
                };

                $scope.closeDetails = function() {
                    closingDetails = true;
                    $scope.toggleFullscreenDetails();
                };

                // Player Controls
                $scope.videoPlayer = {
                    lastTimeUpdate: moment(),
                    config: {
                        plugins: {
                            controls: {
                                autoHide: false
                            },
                            playback: {
                                speeds: [
                                    '0.25',
                                    '0.5',
                                    '1',
                                    '1.5',
                                    '2',
                                    '3',
                                    '5',
                                    '10'
                                ]
                            }
                        }
                    },

                    stopVideo: function() {
                        if ($scope.playerApi) {
                            playSpeed = $scope.playerApi.playback;
                            $scope.playerApi.stop();
                            $scope.playerApi.clearMedia();
                        }
                    },

                    playVideo: function() {
                        if ($scope.playerApi) {
                            $scope.videoLoading = true;
                            $timeout(function() {
                                $scope.playerApi.play();
                                $scope.playerApi.setPlayback(playSpeed);
                            });
                        }
                    },

                    playerReady: function(api) {
                        $scope.playerApi = api;
                        $scope.playerApi.setVolume(0);
                        this.playVideo();
                    },

                    nextVideo: function() {
                        if ($scope.autoplay) {
                            currentVideoIndex++;
                            var next = _.find($scope.videos, function(v) {
                                return v.index === currentVideoIndex;
                            });
                            if (!next) {
                                $scope.autoplay = false;
                                $scope.autoplayButton = '';
                            } else {
                                $scope.reloadVideoDetails(next.videoId);
                            }
                        }
                    },

                    toggleAutoplay: function() {
                        $scope.autoplay = !$scope.autoplay;
                        localStorageService.set(
                            'video-player-autoplay',
                            $scope.autoplay
                        );
                        $scope.autoplayButton = $scope.autoplay
                            ? 'Autoplay'
                            : '';
                    },

                    updateVideoTime: function(current, skipTimeCheck) {
                        if (skipTimeCheck) {
                            lastRow = null;
                        }

                        if (
                            skipTimeCheck ||
                            (moment().diff(
                                this.lastTimeUpdate,
                                'milliseconds'
                            ) > 200 &&
                                !closingDetails)
                        ) {
                            this.lastTimeUpdate = moment();
                            $scope.videoLoading = false;
                            var currentRow;

                            activityLogDataSource.data().forEach(function(row) {
                                if (current >= row.skipTo && row.inVideo) {
                                    currentRow = row;
                                }
                            });

                            if (
                                !!currentRow &&
                                $scope.activityLogGrid &&
                                currentRow !== lastRow
                            ) {
                                lastRow = currentRow;
                                $scope.activityLogGrid.select(
                                    'tr[data-uid="' + currentRow.uid + '"]'
                                );

                                if (
                                    $scope.playerApi.currentState === 'play' &&
                                    !hasScrolledLog
                                ) {
                                    hasScrolledLog = true;
                                    $scope.activityLogGrid.content.animate(
                                        {
                                            scrollTop: $scope.activityLogGrid
                                                .select()
                                                .position().top
                                        },
                                        400
                                    );
                                }
                            } else if (
                                lastRow !== currentRow &&
                                $scope.activityLogGrid
                            ) {
                                lastRow = null;
                                $scope.activityLogGrid.clearSelection();
                            }
                        }
                    }
                };

                $scope.error = function() {
                    $scope.inError = true;
                };

                // Activity Log Controls
                $scope.activityRowClicked = function(row) {
                    if (!$scope.playerApi || !row) {
                        return;
                    }

                    if (!row.inVideo) {
                        $scope.videoPlayer.updateVideoTime(
                            $scope.playerApi.currentTime,
                            true
                        );
                        return;
                    }

                    if (!!lastRow && row.uid !== lastRow.uid) {
                        $scope.playerApi.seekTime(Math.max(row.skipTo, 0));
                        lastRow = row;
                    }
                };

                var activityLogDataSource = new kendo.data.CustomDataSource({
                    transport: {
                        read: function(options) {
                            if (
                                !!$scope.videoDetails &&
                                !!$scope.videoDetails.startTime
                            ) {
                                var from = $scope.videoDetails.startTime
                                    .clone()
                                    .add(-5, 'minutes')
                                    .format('YYYY-MM-DD HH:mm:ss');
                                var to = $scope.videoDetails.endTime
                                    .clone()
                                    .add(5, 'minutes')
                                    .format('YYYY-MM-DD HH:mm:ss');
                                var args =
                                    'from=' +
                                    encodeURIComponent(from) +
                                    '&to=' +
                                    encodeURIComponent(to) +
                                    '&user=' +
                                    encodeURIComponent(
                                        $scope.videoDetails.username
                                    );
                                var data = options.data;

                                var firstActivity;
                                activityLogService
                                    .getLog(args, {
                                        params: data
                                    })
                                    .then(
                                        function(result) {
                                            $scope.activityLogLoading = false;
                                            var res = result.data;
                                            if (!result.data) {
                                                options.success({
                                                    data: [],
                                                    total: 0,
                                                    pagerTotal: 0
                                                });
                                            }

                                            res.data.forEach(function(log) {
                                                var skipTo = $scope.getDuration(
                                                    $scope.videoDetails
                                                        .startTime,
                                                    moment(log.time)
                                                );
                                                log.inVideo =
                                                    skipTo >= 0 &&
                                                    skipTo <=
                                                        $scope.videoDetails
                                                            .duration;

                                                if (
                                                    (log.inVideo &&
                                                        skipTo === 0) ||
                                                    (!log.inVideo && skipTo < 0)
                                                ) {
                                                    firstActivity = log;
                                                }

                                                log.skipTo = Math.max(
                                                    skipTo,
                                                    0
                                                );
                                            });

                                            // Allows for activity that started before video, but is in video to be clickable.
                                            if (firstActivity) {
                                                firstActivity.inVideo = true;
                                            }

                                            options.success(res);
                                        },
                                        function() {
                                            // TODO Create user notification for loading error
                                            $scope.activityLogLoading = false;
                                            options.success({
                                                data: [],
                                                total: 0,
                                                pagerTotal: 0
                                            });
                                        }
                                    );
                            } else {
                                $scope.activityLogLoading = false;
                                options.success({
                                    data: [],
                                    total: 0,
                                    pagerTotal: 0
                                });
                            }
                        }
                    },
                    schema: {
                        data: 'data',
                        total: 'total',
                        pagerTotal: 'sliderCount'
                    },
                    pageSize: 100,
                    serverPaging: true,
                    serverSorting: true,
                    serverFiltering: true,
                    sort: {
                        field: 'time',
                        dir: 'asc'
                    },
                    entryLimit: 10000
                });
                $scope.activityLogDataSource = activityLogDataSource;

                $scope.activityLogGridOptions = {
                    dataSource: activityLogDataSource,
                    columns: [
                        {
                            title: 'Log Time',
                            field: 'time',
                            width: '120px',
                            template: kendo.template(function(item) {
                                var icon =
                                    item.logId === $scope.videoDetails.logId
                                        ? '<i class="fa fa-bell m-l-10">'
                                        : '';
                                return (
                                    atHelperFunctions.formatDate(item.time, {
                                        span: true,
                                        onlyTime: true
                                    }) + icon
                                );
                            }),
                            attributes: {
                                'ng-class': "{'at-pointer': dataItem.inVideo}"
                            }
                        },
                        {
                            title: 'Video Index',
                            field: 'skipTo',
                            width: '100px',
                            template: kendo.template(function(item) {
                                var string = '';
                                var estimate = '';

                                if (item.inVideo) {
                                    string = $scope.filterTime(
                                        item.skipTo * 1000
                                    );
                                    estimate = item.skipTo === 0 ? '~' : '';
                                }

                                return estimate + string;
                            }),
                            attributes: {
                                'ng-class':
                                    "['text-center', {'at-pointer': dataItem.inVideo}]"
                            }
                        },
                        {
                            field: 'titleBar',
                            title: 'Title',
                            attributes: {
                                'ng-class': "{'at-pointer': dataItem.inVideo}"
                            }
                        },
                        {
                            title: 'Application',
                            field: 'description',
                            attributes: {
                                class: 'text-nowrap',
                                'ng-class': "{'at-pointer': dataItem.inVideo}"
                            },
                            template: kendo.template(function(item) {
                                var application =
                                    item.description || item.executable;
                                return application ? application : '';
                            })
                        },
                        {
                            title: 'URL',
                            field: 'url',
                            attributes: {
                                class: 'text-nowrap',
                                'ng-class': "{'at-pointer': dataItem.inVideo}"
                            }
                        }
                    ],
                    scrollable: true,
                    selectable: 'row',
                    height: atHelperFunctions.getGridHeight(
                        $scope.activityLogGridHeight()
                    )
                };

                // Alarm Details Controls
                function isAutoAddedUsbCondition(isUsb, condition) {
                    return (
                        isUsb &&
                        ((condition.fieldName === 'Executable' &&
                            condition.value === 'SYSTEM EVENT') ||
                            (condition.fieldName === 'Titlebar' &&
                                condition.value === 'USB STORAGE') ||
                            (condition.fieldName === 'Description' &&
                                condition.value === 'insert') ||
                            (condition.fieldName === 'Description' &&
                                condition.value === 'Writing') ||
                            (condition.fieldName === 'Description' &&
                                condition.value === 'write'))
                    );
                }

                function formatAlarmConditions(alarmDetails) {
                    if (
                        !alarmDetails ||
                        !alarmDetails.conditions ||
                        typeof alarmDetails.conditions.forEach !== 'function'
                    ) {
                        return;
                    }

                    $scope.conditions = [];
                    var notInsert = false;
                    var notWrite = false;
                    var string = '';
                    alarmDetails.conditions.forEach(function(condition) {
                        string = [
                            '+',
                            condition.fieldName,
                            condition.opName.toLowerCase(),
                            "'" + condition.value + "'"
                        ].join(' ');
                        if (
                            !isAutoAddedUsbCondition(
                                alarmDetails.isUsb,
                                condition
                            )
                        ) {
                            $scope.conditions.push(string);
                        }
                        if (
                            alarmDetails.isUsb &&
                            condition.fieldName === 'Description'
                        ) {
                            notInsert =
                                notInsert ||
                                condition.value === 'write' ||
                                condition.value === 'Writing';
                            notWrite = notWrite || condition.value === 'insert';
                        }
                    });
                    if (alarmDetails.isUsb && !(notInsert && notWrite)) {
                        string =
                            '+ USB Storage is ' +
                            (notWrite
                                ? 'Inserted'
                                : notInsert
                                ? 'Written'
                                : 'Inserted or Written');
                        $scope.conditions.unshift(string);
                    }
                }

                $scope.getAlarmDetails = function(alarmId) {
                    alarmService.getAlarmDetails(alarmId).then(
                        function(alarm) {
                            $scope.alarmDetailsLoading = false;
                            if (alarm.data[0]) {
                                $scope.alarmDetails = alarm.data[0];
                                formatAlarmConditions($scope.alarmDetails);
                            } else {
                                $scope.alarmDetails = [];
                                $scope.conditions = [];
                            }
                        },
                        function() {
                            $scope.alarmDetails = [];
                            $scope.conditions = [];
                            $scope.alarmDetailsLoading = false;
                        }
                    );
                };

                // Related Videos Controls
                $scope.toggleDetails = function() {
                    $scope.showDetails = !$scope.showDetails;
                    $scope.detailsButton = $scope.showDetails ? 'show' : 'hide';
                    $scope.$emit('videoGalleryViewModeChanged', {
                        mode: $scope.showDetails ? 1 : 2
                    });
                };

                var videoThumbnailTemplate = require('views/alarmVideo/videoThumbnailTemplate.html');

                var videoSelectorDataSource = new kendo.data.CustomDataSource({
                    transport: {
                        read: function(options) {
                            $timeout(function() {
                                options.success($scope.videos);
                            });
                        }
                    }
                });
                $scope.videoSelectorDataSource = videoSelectorDataSource;

                $scope.videoSelectorGridOptions = {
                    dataSource: videoSelectorDataSource,
                    columns: [
                        {
                            template: videoThumbnailTemplate
                        }
                    ],
                    scrollable: true,
                    selectable: 'row',
                    dataBound: function(e) {
                        var grid = e.sender;
                        var data = grid._data;
                        if (data[0]) {
                            var firstRow = data[0];
                            var selectedVideo = _.find(data, function(v) {
                                return v.uid === $scope.videoDetails.uid;
                            });

                            if (!selectedVideo) {
                                selectedVideo = firstRow;
                            }

                            grid.select(
                                'tr[data-uid="' + selectedVideo.uid + '"]'
                            );
                        }
                        $scope.showingFullscreen = true;
                    }
                };

                $scope.reloadVideoDetails = function(videoId) {
                    if (
                        videoId &&
                        !$scope.videoLoading &&
                        videoId !== $scope.videoDetails.videoId
                    ) {
                        $scope.inError = false;
                        $timeout(function() {
                            $scope.videoPlayer.stopVideo();
                            $scope.setVideoDetails(videoId);
                            $scope.videoPlayer.playVideo();
                            $scope.activityLogDataSource.filter({
                                field: 'user',
                                operator: 'contains',
                                value: $scope.videoDetails.user
                            });
                            $scope.videoSelectorGrid.dataSource.read();
                        });
                    }
                };

                $scope.setVideoDetails = function(videoId) {
                    $scope.videoDetails = _.find($scope.videos, function(v) {
                        return v.videoId === videoId;
                    });
                    $scope.videoPlayer.config.sources =
                        $scope.videoDetails.source;
                    $scope.activityLogLoading = true;
                    $scope.alarmDetailsLoading = true;
                    hasScrolledLog = false;
                    currentVideoIndex = $scope.videoDetails.index;
                    $scope.getAlarmDetails($scope.videoDetails.alarmId);
                };

                $scope.getTypeLabel = function(type) {
                    return msg.get(type) || type;
                };

                $scope.setVideoDetails($scope.selectedVideoId);

                // Close details on back button instead of navigate back.
                $scope.$on('$locationChangeStart', function(event) {
                    event.preventDefault();
                    $scope.closeDetails();
                });

                /**********************************
                   Used to make panels responsive
                ***********************************/
                // Used to generate related video panel height based on content in other panels.
                var controlElement = angular.element(
                    document.querySelector('#vfs-controls')
                )[0];
                var videoDetailsElement = angular.element(
                    document.querySelector('#vfs-video-details')
                )[0];
                var alarmDetailsElement = angular.element(
                    document.querySelector('#vfs-alarm-details')
                )[0];
                $scope.rightColumnHeights = [];

                // Resize grid based on window height
                var resizeVideoSelectorGrid = function() {
                    if (!$scope.videoSelectorGrid) {
                        return;
                    }

                    var gridElement = angular.element(
                        $scope.videoSelectorGrid.element
                    );
                    var dataArea = gridElement.find('.k-grid-content');
                    var newHeight = $scope.relatedVideoHeight.height - 60;
                    var diff =
                        gridElement.innerHeight() - dataArea.innerHeight();
                    gridElement.height(newHeight);
                    dataArea.height(newHeight - diff);
                    $scope.videoSelectorGrid.dataSource.read();
                };

                $scope.setRelatedVideoHeight = function() {
                    // Offset for margins between panels.
                    var marginOffset = 137;

                    for (var element in $scope.rightColumnHeights) {
                        marginOffset += parseInt(
                            $scope.rightColumnHeights[element]
                        );
                    }

                    $scope.relatedVideoHeight = {
                        height: $window.innerHeight - marginOffset
                    };
                    resizeVideoSelectorGrid();
                };

                function createFileName(details) {
                    var username = atHelperFunctions.truncateString(
                        details.username.replace(/(\W+)/gi, '_'),
                        20
                    );
                    var alarmName = atHelperFunctions.truncateString(
                        details.alarmName.replace(/(\W+)/gi, '_'),
                        20
                    );
                    var timestamp = details.eventTime
                        .replace('/', '-')
                        .replace(':', '')
                        .replace(/(\W+)/gi, '_');

                    return (
                        username + '-' + alarmName + '-' + timestamp + '.mp4'
                    );
                }

                $scope.download = function() {
                    if ($scope.downloading) {
                        return;
                    }

                    $scope.downloading = true;
                    videosService
                        .downloadVideo($scope.videoDetails.sources[0].src)
                        .then(function(result) {
                            $scope.downloading = false;
                            FileSaver.saveAs(
                                result.data,
                                createFileName($scope.videoDetails)
                            );
                        })
                        .catch(function() {
                            $scope.downloading = false;
                            notificationService.showNotification(
                                msg.get('errorDownloadingVideo'),
                                'danger'
                            );
                        });
                };

                $scope.$watch(
                    function() {
                        return [
                            controlElement.offsetHeight,
                            videoDetailsElement.offsetHeight,
                            alarmDetailsElement.offsetHeight
                        ].join(',');
                    },
                    function(value) {
                        var size = value.split(',');
                        $scope.rightColumnHeights.controls = size[0];
                        $scope.rightColumnHeights.videoDetails = size[1];
                        $scope.rightColumnHeights.alarmDetails = size[2];
                        $scope.setRelatedVideoHeight();
                    }
                );

                $scope.$watch('isLight', function(value) {
                    localStorageService.set('video-player-is-light', value);
                });

                $scope.$on('atWindowResizing', function(event, windowSize) {
                    if (windowSize.newHeight !== windowSize.oldHeight) {
                        $scope.setRelatedVideoHeight();
                    }
                });

                /**********************************
                   Used to make panels responsive
                ***********************************/
            }
        ]
    };
}
