import React from 'react';
import BaseNoResultsProps from './BaseNoResults.props';
import searching from '../../../../../app/images/no_results.svg';
import { NoResultsContainerSC, NoResultsLabelSC } from './BaseNoResults.styles';

export const BaseNoResults = (props: BaseNoResultsProps) => {
  const { tips } = props;

  return (
    <NoResultsContainerSC>
      <img src={searching} alt="search completed" />
      <NoResultsLabelSC variant="body1">
        Uh oh! No results were found.
        <br />
        Refine the filters or search to find what you&apos;re looking for.
      </NoResultsLabelSC>
      {tips && <NoResultsLabelSC>{tips}</NoResultsLabelSC>}
    </NoResultsContainerSC>
  );
};
