import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

const Sorting = ({ anchorEl = null, handleMenuClick, handleMenuClose }) => {
  return (
    <React.Fragment>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        startIcon={<SortIcon />}
        color="inherit"
      >
        SORT
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose} id="mnuItem1">
          Company Name (A-Z)
        </MenuItem>
        <MenuItem onClick={handleMenuClose} id="mnuItem2">
          Company Name (Z-A)
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Sorting;
