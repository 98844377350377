import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const GoogleCalendarIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '20px';
  const height = props.height ?? '20px';
  const title = props.title ?? 'Google Calendar';
  const className = props.className;
  const viewBox = props.viewBox ?? '0 0 250 250';

  let classList = 'at-icon-google-calendar';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      width={width}
      height={height}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor="none"
    >
      <path fill="#FFF" d="M195.368 60.632H60.632v134.736h134.736z" />
      <path
        fill="#EA4335"
        d="M195.368 256 256 195.368l-30.316-5.172-30.316 5.172-5.533 27.73z"
      />
      <path
        fill="#188038"
        d="M0 195.368v40.421C0 246.956 9.044 256 20.21 256h40.422l6.225-30.316-6.225-30.316-33.033-5.172L0 195.368Z"
      />
      <path
        fill="#1967D2"
        d="M256 60.632V20.21C256 9.044 246.956 0 235.79 0h-40.422c-3.688 15.036-5.533 26.101-5.533 33.196 0 7.094 1.845 16.24 5.533 27.436 13.41 3.84 23.515 5.76 30.316 5.76 6.801 0 16.906-1.92 30.316-5.76Z"
      />
      <path fill="#FBBC04" d="M256 60.632h-60.632v134.736H256z" />
      <path fill="#34A853" d="M195.368 195.368H60.632V256h134.736z" />
      <path
        fill="#4285F4"
        d="M195.368 0H20.211C9.044 0 0 9.044 0 20.21v175.158h60.632V60.632h134.736V0Z"
      />
      <path
        fill="#4285F4"
        d="M88.27 165.154c-5.036-3.402-8.523-8.37-10.426-14.94l11.689-4.816c1.06 4.042 2.913 7.175 5.558 9.398 2.627 2.223 5.827 3.318 9.566 3.318 3.823 0 7.107-1.162 9.852-3.487 2.746-2.324 4.127-5.288 4.127-8.875 0-3.672-1.449-6.67-4.345-8.994-2.897-2.324-6.535-3.486-10.88-3.486h-6.754v-11.57h6.063c3.739 0 6.888-1.011 9.448-3.033 2.56-2.02 3.84-4.783 3.84-8.303 0-3.132-1.145-5.625-3.435-7.494-2.29-1.87-5.188-2.813-8.708-2.813-3.436 0-6.164.91-8.185 2.745a16.115 16.115 0 0 0-4.413 6.754l-11.57-4.817c1.532-4.345 4.345-8.185 8.471-11.503 4.127-3.318 9.398-4.985 15.798-4.985 4.733 0 8.994.91 12.767 2.745 3.772 1.836 6.736 4.379 8.875 7.613 2.14 3.25 3.2 6.888 3.2 10.93 0 4.126-.993 7.613-2.98 10.476-1.988 2.863-4.43 5.052-7.327 6.585v.69a22.248 22.248 0 0 1 9.398 7.327c2.442 3.284 3.672 7.208 3.672 11.79 0 4.58-1.163 8.673-3.487 12.26-2.324 3.588-5.54 6.417-9.617 8.472-4.092 2.055-8.69 3.1-13.793 3.1-5.912.016-11.369-1.685-16.405-5.087Zm71.797-58.005-12.833 9.28-6.417-9.734 23.023-16.607h8.825v78.333h-12.598V107.15Z"
      />
    </SvgIconWrapper>
  );
};
