import { useMemo } from "react";

export const useUIRouterParams = <TType>(): TType => {
  const $injector = useMemo(() => window.angular
    .element(document.getElementById('core-app'))
    .injector(), []);

  const $stateParams: any = $injector.get('$stateParams');
  return {...$stateParams} as TType;
};
