import { IGTEUsersDetails } from '../models';

export const CardDescriptionLink =
  'https://support.activtrak.com/hc/en-us/articles/25692183355291#globaltracking';

export const GTEUsersDetails: IGTEUsersDetails = {
  create: {
    title: 'Add Global Tracking Exclusion',
    description:
      'Enter the username to add as a global tracking exclusion. WARNING: This action will permanently delete all data associated with this username. Deleted data cannot be recovered.',
    descriptionSecondary:
      'Important: Enter the username carefully. An incorrect entry may affect tracking exemptions for the intended user',
    acknowledgment:
      'I understand this action will permanently delete all data for this user',
    button: 'Add username'
  },
  delete: {
    title: 'Remove username?',
    description:
      'Tracking will resume the next time the user generates activity',
    button: 'Remove username'
  },
  card: {
    title: 'Global Tracking Exclusions',
    description: `Global tracking exclusions prevent users from being tracked anywhere they log in, regardless of which domain is used. This is particularly useful for IT admins who regularly log into users' computers to troubleshoot but don't need to be tracked. For more information <a href="${CardDescriptionLink}" target="_blank" rel="noreferrer">click here</a>.`,
    infoIconText: 'Manage user-level tracking on the Users page.'
  }
};
