import React, { useCallback, useEffect, useState } from 'react';
import { localStorageItems } from '../../common/constants';
import { IUserDetail } from '../models';
import { ComputersProvider, UseUsersContext, UsersProvider } from '../services';
import { formatUserComplexName } from '../utils';
import {
  getDateTimeFormat,
  getParsedLocalStorageItem,
  formatDate
} from '../../common/helpers';
import { IAccountSettings } from '../../common/models';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DownloadProvider } from '../../common/services';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import authHelper from '../../common/helpers/authorization';
import { RoleAccessKeys } from '../../common/enums';
import Link from '../../common/components/Link';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  GroupContainer,
  GroupHeaderTitleContainer,
  ParamContainer,
  ViewHeaderNameContainer
} from '../styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const formatStorage = (data) => {
  if (data > 0 && data <= 1) {
    return '0.001';
  }
  const gb = data / 1000;
  const precision = gb > 1 ? 2 : 3;
  return gb.toFixed(precision);
};

export function UserView() {
  const history = useUIRouterHistory();
  const { usersService } = UseUsersContext();
  const [userDetail, setUserDetail] = useState<IUserDetail>({
    videoStorage: 0,
    screenShotStorage: 0,
    logCount: 0,
    screenShotCount: 0,
    videoCount: 0,
    userName: '',
    logonDomain: '',
    alias: ''
  });

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const dateFormat = getDateTimeFormat(accountSettings?.dateFormat);

  const { id } = useUIRouterParams<any>();

  const {
    videoStorage,
    screenShotStorage,
    logCount,
    screenShotCount,
    videoCount,
    userName,
    logonDomain,
    firstLog,
    lastLog,
    alias
  } = userDetail;
  const { screenshotsAllowed = false } = getPrivacySettings();

  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const userDetailResponse = await usersService.fetchUserDetail(id);
        setUserDetail(userDetailResponse);
      } catch (error) {
        //what should happen here?
      }
    };

    fetchUserDetail();
  }, [id, usersService]);

  const handleClickReturn = useCallback(() => {
    history.push('app.settings.useragents');
  }, [history]);

  return (
    <Box>
      <ViewHeaderNameContainer>
        <Button onClick={handleClickReturn}>
          <KeyboardBackspaceIcon />
        </Button>
        {formatUserComplexName(logonDomain, userName, alias)}
      </ViewHeaderNameContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>User Info</GroupHeaderTitleContainer>
        <ParamContainer>Logon Domain: {logonDomain}</ParamContainer>
        <ParamContainer>User: {userName}</ParamContainer>
        {alias ? <ParamContainer>Alias: {alias}</ParamContainer> : null}
      </GroupContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>Activity Details</GroupHeaderTitleContainer>
        <ParamContainer>
          First Log Record: {formatDate(firstLog, dateFormat)}
        </ParamContainer>
        <ParamContainer>
          Last Log Record: {formatDate(lastLog, dateFormat)}
        </ParamContainer>
        <ParamContainer>
          Total Storage:{' '}
          {formatStorage(
            (logCount * 120) / 1000 / 1000 + videoStorage + screenShotStorage
          )}{' '}
          GB
        </ParamContainer>
        {/* lcount, sstorage , vstorage */}
        <ParamContainer># of Log Records: {logCount}</ParamContainer>
        {screenshotsAllowed && hasAlarmsScreenshotsReportsRoleAccess && (
          <ParamContainer># of Screen Shots: {screenShotCount}</ParamContainer>
        )}
        {videoCount > 0 && (
          <ParamContainer># of Videos: {videoCount}</ParamContainer>
        )}
        <Link
          to={'app.reports.activitylog'}
          params={{
            user: alias || userName,
            dateFilter: 'today',
            userFilter: 'allusers'
          }}
        >
          View Activity Log
        </Link>
      </GroupContainer>
    </Box>
  );
}

export const UserViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <ComputersProvider>
          <UserView />
        </ComputersProvider>
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
