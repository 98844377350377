export enum GroupMix {
  User = 'User',
  Computer = 'Computer',
  Mixed = 'Mixed', //what the client sees
  Both = 'Both', //what the api needs
  Empty = 'Empty',
  None = 'None',
  Client = 'Client',
  Device = 'Device'
}
