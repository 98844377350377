import envService from '../../../../../_app/environmentService/environmentService';
import authorizationService from '../../helpers/authorization';
import { fetchData } from '../../helpers';
import { IRegionUrls } from '../../models/IAppLoad';
import {
  getTest,
  triggerDataLayerEvent
} from '../../../../../_app/serviceFunctions/abTestService.js';
import { groupsStore } from '../../hooks';
import {
  setAccountSettings,
  filterInitialAccountSettings
} from '../../helpers/accountSettings';
import { profileStore } from '../Profile/useProfileState';
import {
  ILiveReportSettingsDto,
  ITotalRealtimeSettingsDto
} from '../../models';
import {
  setUserBundleFlags,
  setUserFeatureFlags
} from '../../hooks/userPermissionStore';
import { initializeReportFilters } from '../../components/ReportFilters/hooks/reportFiltersStore';
import { FeatureFlag } from '../../enums/FeatureFlag';
import {
  getAllEntities,
  invalidateEntitiesCache
} from '../../hooks/entityStore';
import { setPrivacySettings } from '../../hooks/privacySettingsStore';
import { mapPrivacySettingsDtoToPrivacySettings } from '../../utils/privacySettingsStore.utils';
import { isAccountPlanInTrial } from '../../stores/accountPlanStore';
import { initializeStoreCacheHandler } from '../../utils/storeCacheHandler';
import { postLoginStore } from '../../stores/postLoginStore';
import { newFlexUsageEvent } from '../../analytics/flexUsageEvent';

const loadUrls = async () => {
  const urls: IRegionUrls = await fetchData<IRegionUrls>({
    path: '/account/urlsv2'
  });

  envService.setApiUrl(urls.apiURL);
  envService.setWebSocketsUrl(urls.wsURL);
  envService.setWebSocketsHttpsUrl(urls.wsURL);
  envService.setReportingServiceUrl(urls.reportingServiceUrl);
  envService.setWebApiUrl(urls.webApiUrl);
  envService.setIdentityServiceUrl(urls.identityServiceUrl);

  return urls.apiURL;
};

export const loadPostLogin = async (): Promise<any> => {
  return await fetchData<any>({
    path: '/api/v2/post_login'
  });
};

const getRealtimeSettings = async (): Promise<ITotalRealtimeSettingsDto> => {
  return await fetchData<ITotalRealtimeSettingsDto>({
    path: '/api/realtime/settings'
  });
};

const getTeamPulseSettings = async (): Promise<any> => {
  return await fetchData<any>({
    path: '/api/teampulse/settings'
  });
};

const getLiveReportSettings = async () => {
  return await fetchData<ILiveReportSettingsDto>({
    path: '/api/settings/live_report_settings'
  });
};

export const LoadApplication = async () => {


  await loadUrls();

  //initialize the Stores
  const groupsDefinitions = groupsStore.getState();

  const [
    accountSettings,
    realtimeSettings,
    teamPulseSettings,
    liveReportSettings
  ] = await Promise.all([
    postLoginStore.getState().fetch(),
    getRealtimeSettings(),
    getTeamPulseSettings(),
    getLiveReportSettings(),
    //pre-fetch
    initializeStoreCacheHandler(),
    groupsDefinitions.fetchFilters(),
    getAllEntities(),
    profileStore.getState().fetch()
  ]);

  const filteredSettings = filterInitialAccountSettings(
    accountSettings,
    realtimeSettings?.realtimeSettings,
    liveReportSettings
  );

  setUserBundleFlags(filteredSettings.bundleFlags);
  setUserFeatureFlags(filteredSettings.featureFlags);
  setAccountSettings(filteredSettings);

  // Map privacy settings if available
  // Else map private mode to privacy settings
  const privacySettings = mapPrivacySettingsDtoToPrivacySettings(
    filteredSettings?.privacySettings
  );
  setPrivacySettings(privacySettings);

  // Pre-fetch Insights groups to map against groupId
  // Depends on account settings and flags being set
  if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
    try {
      await groupsDefinitions.fetchInsightsFilters();
    } catch (error) {
      console.error('ActivTrak Error: unable to load Insights groups', error);
    }
  }

  // Depends on group store being initialized: groupFilters, entities, and insightsFilters being fetched
  initializeReportFilters();
  invalidateEntitiesCache();

  if (isAccountPlanInTrial()) {
    if (getTest('liveReportNavigationBVariant') === undefined) {
      triggerDataLayerEvent('optimize.activate.liveReportNavigation');
    }
  }

  // Depends on account settings being set
  newFlexUsageEvent(filteredSettings);

  return {
    accountSettings,
    realtimeSettings,
    teamPulseSettings
  };
};

export default {
  LoadApplication,
  loadPostLogin
};
