import React from 'react';
import { LoaderContainer } from '../../../common/components/Grid/CustomGrid.styles';
import { useDefaultSkeletonLoader } from '../../../common/components/Grid/CustomGrid.utils';

type ModalLoadingViewProps = {
  isLoading: boolean;
  isModalError: boolean;
  errorMsg: string | React.ReactNode;
  loaderHeight?: string;
};

const ModalLoadingView = (props: ModalLoadingViewProps) => {
  const { isLoading, isModalError, errorMsg, loaderHeight } = props;
  const defaultSkeletonLoader = useDefaultSkeletonLoader(2, false);

  return (
    <>
      {isModalError && errorMsg}

      {isLoading && !isModalError && (
        <LoaderContainer loaderHeight={loaderHeight}>
          {defaultSkeletonLoader}
        </LoaderContainer>
      )}
    </>
  );
};

export default ModalLoadingView;
