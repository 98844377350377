import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export const ArrowDownIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? '16px';
  const width = props.width ?? '16px';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="at-icon-arrow at-icon-arrow-down"
    >
      <title>Arrow Down</title>
      <g
        id="arrow-down"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="arrow-down-child">
          <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
          <path
            d="M18.3,14.29 L18.3,14.29 C17.91,13.9 17.28,13.9 16.89,14.29 L13,18.17 L13,3 C13,2.45 12.55,2 12,2 L12,2 C11.45,2 11,2.45 11,3 L11,18.18 L7.12,14.3 C6.73,13.91 6.1,13.91 5.71,14.3 L5.71,14.3 C5.32,14.69 5.32,15.32 5.71,15.71 L11.3,21.3 C11.69,21.69 12.32,21.69 12.71,21.3 L18.3,15.71 C18.68,15.32 18.68,14.68 18.3,14.29 Z"
            id="Path"
            fill={fillColor}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};
