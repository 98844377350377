import {
  IAccountSettingsDto,
  INewlyAddedAccountDto
} from '../../common/models';
import { INewlyAddedAccount } from '../models/IAccountData';
import { ITokenDto } from '../models/IApiResponseDto';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { IModalHeader, ISupportControlValues } from '../models/IRandomTypes';

export const createAccountCard = (
  data: INewlyAddedAccountDto,
  userToken: ITokenDto,
  payload: IAddAccountPayloadDto
): INewlyAddedAccount => {
  return {
    accountId: data.account,
    companyName: data.companyName,
    userCount: data.usedLicenses,
    associatedEmail: userToken.userName,
    tokenData: userToken,
    payload: payload
  };
};

export const createModalHeader = (
  accountInfo: Partial<IAccountSettingsDto>
): IModalHeader => {
  return {
    accountId: accountInfo.account,
    companyName: accountInfo.companyName || 'Company N/A',
    userCount: accountInfo.usedLicenses,
    totalCount: accountInfo.totalLicenses
  };
};

export const createSupportControlValues = (
  accountInfo: Partial<IAccountSettingsDto>
): ISupportControlValues => {
  return {
    dataCollection: accountInfo.dataCollection,
    verified: accountInfo.isVerified
  };
};
