import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TooltipLegend } from '.';
import { IStatusSubheaderProps } from '../../models';

export const StatusSubheader = (props: IStatusSubheaderProps) => {
  const {
    subheader,
    tooltipLegend,
    responsive,
    reverseResponsive,
    noResults,
    isMedScreenOrBelow
  } = props;

  const oneLineSubheader = (
    <>
      {`${subheader.top} ${subheader.bottom}`}
      {tooltipLegend && (
        <TooltipLegend
          legendItems={tooltipLegend.items}
          legendColors={tooltipLegend.colors}
          subtitle={tooltipLegend.subtitle}
        />
      )}
    </>
  );

  const twoLineSubheader = (
    <>
      {subheader.top}
      <br />
      <Stack display="inline">
        {subheader.bottom}
        {tooltipLegend && (
          <TooltipLegend
            legendItems={tooltipLegend.items}
            legendColors={tooltipLegend.colors}
          />
        )}
      </Stack>
    </>
  );

  const showSubheader = () => {
    if (responsive) {
      return isMedScreenOrBelow ? oneLineSubheader : twoLineSubheader;
    }

    if (reverseResponsive) {
      return isMedScreenOrBelow ? twoLineSubheader : oneLineSubheader;
    }

    return twoLineSubheader;
  };

  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'left',
        marginTop: noResults ? '-10px' : '0px',
        paddingLeft: '8px'
      }}
    >
      {showSubheader()}
    </Box>
  );
};
