'use strict';

angular.module('app').service('responseErrorService', ResponseErrorService);

ResponseErrorService.$inject = ['$q', '$window', '$injector'];

function ResponseErrorService($q, $window, $injector) {
    this.handleResponseError = function (response) {
        if (response.status === 401) {
            handle401Response();
        }
        return $q.reject(response);
    };

    var hidden;
    var visibilityChange;

    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    function locationReload() {
        var loginService = $injector.get('loginService');
        loginService.clearScope();
        $window.location.reload();
    }

    function handle401Response() {
        var $state = $injector.get('$state');

        // Do not reload if 401 returned while on login or touch page.
        if ($state.current.name === 'login' && !$state.transition) {
            return;
        }

        if (document[hidden]) {
            document.addEventListener(visibilityChange, function () {
                if (!document[hidden]) {
                    locationReload();
                }
            });
        } else {
            locationReload();
        }
    }
}
