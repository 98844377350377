import React from 'react';
import { primaryAqua } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const HybridIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 36;
  const width = props.width ?? 36;
  const title = props.title ?? 'Hybrid';
  const className = props.className;
  const fillColor = props.fillColor ?? primaryAqua;

  let classList = 'at-icon at-icon-hybrid';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 36`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M25.5001 22.5H28.5001V25.5H25.5001V22.5ZM25.5001 16.5H28.5001V19.5H25.5001V16.5ZM25.5001 10.5H28.5001V13.5H25.5001V10.5ZM20.6101 10.5L22.5001 11.76V10.5H20.6101Z"
        fill={fillColor}
      />
      <path
        d="M15 4.5V6.765L18 8.76V7.5H31.5V28.5H25.5V31.5H34.5V4.5H15Z"
        fill={fillColor}
      />
      <path
        d="M12.255 8.54999L22.5 15.375V31.5H1.5V15.72L12.255 8.54999ZM15 28.5H19.5V16.74L12.255 12.135L4.5 17.07V28.5H9V19.5H15V28.5Z"
        fill={fillColor}
      />
      <path
        opacity="0.3"
        d="M15 28.5H19.5V16.74L12.255 12.135L4.5 17.07V28.5H9V19.5H15V28.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
