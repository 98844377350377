/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
// ADD/UPDATE GROUP TYPE: https://activtrak.atlassian.net/wiki/spaces/DOC/pages/3448045613/Group+Types+-+Adding+Updating+a+Type
export enum GroupType {
  All = 'All',
  ActivTrakComputerGroup = 'ActivTrak Computer Group',
  ActivTrakMixedGroup = 'ActivTrak Mixed Group',
  ActivTrakUserGroup = 'ActivTrak User Group',
  ActivTrakEmptyGroup = 'ActivTrak Empty Group',
  ActiveDirectoryComputerGroup = 'Active Directory Computer Group',
  ActiveDirectoryMixedGroup = 'Active Directory Mixed Group',
  ActiveDirectoryUserGroup = 'Active Directory User Group',
  ActiveDirectoryEmptyGroup = 'Active Directory Empty Group',
  AzureAdComputerGroup = 'Azure AD Computer Group',
  AzureAdMixedGroup = 'Azure AD Mixed Group',
  AzureAdUserGroup = 'Azure AD User Group',
  AzureAdEmptyGroup = 'Azure AD Empty Group',
  HrDataConnectorComputerGroup = 'HR Data Connector Computer Group',
  HrDataConnectorMixedGroup = 'HR Data Connector Mixed Group',
  HrDataConnectorUserGroup = 'HR Data Connector User Group',
  HrDataConnectorEmptyGroup = 'HR Data Connector Empty Group',
  None = 'Empty',
  /* This is a fake filter that will force returning no results */
  NonExistent = 'Non-Existent',
  /* For Admin API */
  ActivTrakDeviceGroup = 'ActivTrak Computer Group',
  ActivTrakClientGroup = 'ActivTrak User Group',
  ActivTrakNoneGroup = 'ActivTrak Empty Group',
  ActiveDirectoryDeviceGroup = 'Active Directory Computer Group',
  ActiveDirectoryClientGroup = 'Active Directory User Group',
  ActiveDirectoryNoneGroup = 'Active Directory Empty Group',
  AzureADDeviceGroup = 'Azure AD Computer Group',
  AzureADClientGroup = 'Azure AD User Group',
  AzureADNoneGroup = 'Azure AD Empty Group',
  AzureADMixedGroup = 'Azure AD Mixed Group',
  HrDataConnectorDeviceGroup = 'HR Data Connector Computer Group',
  HrDataConnectorClientGroup = 'HR Data Connector User Group',
  HrDataConnectorNoneGroup = 'HR Data Connector Empty Group',
  HRDCDeviceGroup = 'HR Data Connector Computer Group',
  HRDCClientGroup = 'HR Data Connector User Group',
  HRDCNoneGroup = 'HR Data Connector Empty Group'
}
export enum GroupTypeBasic {
  All = 'All',
  ActivTrak = 'ActivTrak',
  ActiveDirectory = 'Active Directory',
  AzureAD = 'Azure AD',
  HRDC = 'HR Data Connector',
  None = 'Empty'
}

export const GroupTypeConstBasic = {
  0: GroupTypeBasic.ActiveDirectory,
  1: GroupTypeBasic.ActivTrak,
  2: GroupTypeBasic.AzureAD,
  3: GroupTypeBasic.HRDC,
  4: GroupTypeBasic.HRDC
};

export enum GroupTypeDto {
  AllUsers = 'AllUsers',
  AllComputers = 'AllComputers',
  ActivTrak = 'ActivTrak',
  ActiveDirectory = 'ActiveDirectory',
  AzureAd = 'AzureAd',
  HrDataConnector = 'HrDataConnector',
  HRDC = 'HRDC'
}
