import { useEffect, useState } from 'react';
import {
  SHORT_DATE_TIME_FORMAT,
  SHORT_REVERSED_DATE_TIME_FORMAT,
  localStorageItems
} from '../../common/constants';
import { useParsedLocalStorage } from '../../common/hooks';
import { formatDateWithTimezone } from '../../common/helpers';
import { getAccountSettings } from '../../common/helpers/accountSettings';

export const useLastUpdate = (date: string) => {
  const [formattedDate, setFormattedDate] = useState('');
  const storage = useParsedLocalStorage(
    localStorageItems.accountSettingsStorage
  );

  const accountSettings = getAccountSettings();

  const storedDateFormat = accountSettings?.dateFormat;

  const mainDateFormat =
    storedDateFormat === 'dd/MM/yyyy'
      ? SHORT_REVERSED_DATE_TIME_FORMAT
      : SHORT_DATE_TIME_FORMAT;

  useEffect(() => {
    if (date) {
      setFormattedDate(
        formatDateWithTimezone(date, storage?.storageItems, mainDateFormat)
      );
    }
  }, [date, storage?.storageItems, mainDateFormat]);

  return formattedDate;
};
