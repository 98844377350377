'use strict';

angular.module('app').directive('productivityChartLegend', function () {
    return {
        restrict: 'E',
        scope: {
            toggleBar: '=',
            productivityList: '=',
            barToggleClass: '=',
            isUndefined: '=',
            showDetails: '=',
        },
        template: require('views/widgets/productivityChartLegend.html'),
        controller: ProductivityChartLegendCtrl
    };
});

ProductivityChartLegendCtrl.$inject = ['$scope','localStorageService','AccountSettingsService','$location'];

function ProductivityChartLegendCtrl($scope, localStorageService, accountSettingsService, $location) {
    var productivityService = require('_app/productivity/productivityService');
    var itemClicked = function (item) {
        $scope.toggleBar(item.productivity);
    };

    function setItems() {
        var user = accountSettingsService.username;
        var view = localStorageService.get('productivity-view-' + user);
        var location = $location.path();
        $scope.items = productivityService.createProductivityLegendItems(
            itemClicked,
            $scope.productivityList,
            $scope.barToggleClass,
            view,
            location
        );
    }

    $scope.$watch('showDetails', setItems);
    $scope.$watch('productivityList', setItems, true);
    $scope.$watch('barToggleClass', setItems, true);
}
