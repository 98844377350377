import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export const WavingHandIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 59;
  const width = props.width ?? 61;
  const title = props.title ?? 'WavingHand';
  const className = props.className;

  let classList = 'at-icon at-icon-wavinghand';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 61 59`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M14.6 46.5C14.1 46.4 13.7 46.3 13.2 46.1C12.7 45.9 12.3 45.8 11.9 45.6C11 45.2 10.2 44.7 9.4 44.1C7.8 43 6.5 41.5 5.5 39.7C4.5 38 3.9 36 3.8 34.1C3.7 33.1 3.8 32.2 3.9 31.2C4 30.7 4.1 30.3 4.2 29.8C4.3 29.3 4.5 28.9 4.6 28.4L4.7 29.8C4.7 30.3 4.8 30.7 4.9 31.2C5 32.1 5.2 33 5.4 33.8C5.8 35.5 6.4 37.1 7.3 38.6C8.2 40.1 9.2 41.5 10.5 42.8C11.1 43.4 11.8 44 12.5 44.6C12.8 44.9 13.2 45.2 13.6 45.5L14.6 46.5Z"
        fill="#42ADE2"
      />
      <path
        d="M14.3 50.1C14 50.3 13.6 50.4 13.2 50.5C12.8 50.6 12.5 50.7 12.1 50.7C11.4 50.8 10.6 50.9 9.8 50.8C8.3 50.7 6.7 50.3 5.4 49.6C4 48.9 2.8 47.8 2 46.5C1.6 45.9 1.2 45.2 0.9 44.5C0.8 44.2 0.7 43.8 0.6 43.4C0.6 43.1 0.5 42.8 0.5 42.4C0.8 42.7 1 43 1.2 43.2C1.5 43.5 1.7 43.8 1.9 44C2.4 44.5 2.8 45 3.3 45.4C4.3 46.3 5.3 47.1 6.5 47.7C7.6 48.3 8.9 48.8 10.1 49.2C10.7 49.4 11.4 49.5 12.1 49.7C12.4 49.8 12.8 49.8 13.1 49.9C13.6 50 13.9 50 14.3 50.1Z"
        fill="#42ADE2"
      />
      <path
        d="M36.9 1.5C37.4 1.6 37.8 1.8 38.3 2C38.8 2.2 39.2 2.4 39.6 2.6C40.5 3.1 41.3 3.6 42.1 4.2C43.7 5.4 45 7 45.9 8.8C46.8 10.6 47.3 12.6 47.3 14.5C47.3 15.5 47.2 16.4 47 17.4C46.9 17.9 46.8 18.3 46.6 18.8C46.4 19.3 46.3 19.7 46.1 20.1L46 18.7C46 18.2 46 17.8 45.9 17.3L45.6 14.6C45.3 12.9 44.7 11.2 43.9 9.6C43.1 8 42.1 6.6 40.9 5.3C40.3 4.6 39.6 4 39 3.3C38.7 3 38.3 2.7 37.9 2.4L36.9 1.5Z"
        fill="#42ADE2"
      />
      <path
        d="M45.6 1.10001C46 1.20001 46.3 1.30001 46.6 1.50001C46.9 1.60001 47.3 1.80001 47.6 2.00001C48.2 2.40001 48.8 2.80001 49.4 3.30001C50.5 4.30001 51.4 5.50001 51.9 6.90001C52.5 8.30001 52.7 9.80001 52.5 11.3C52.4 12 52.2 12.7 52 13.4C51.9 13.7 51.7 14.1 51.6 14.4C51.4 14.7 51.3 15 51 15.3V13.3C51 12.6 50.9 12 50.9 11.4C50.7 10.1 50.5 8.90001 50 7.80001C49.5 6.60001 48.9 5.60001 48.2 4.50001C47.8 4.00001 47.4 3.40001 47 2.90001C46.8 2.60001 46.6 2.40001 46.3 2.10001C46.1 1.60001 45.8 1.30001 45.6 1.10001Z"
        fill="#42ADE2"
      />
      <path
        d="M8.5 16C6.5 16.9 5.79999 19.3 6.69999 21.3L19.3 47.6L26.3 44.3L13.7 17.9C12.8 15.9 10.5 15 8.5 16Z"
        fill="#FFDD67"
      />
      <path
        d="M41.6 36.9L49 33.4L34.6 3.40001C33.6 1.40001 31.2 0.500007 29.1 1.50001C27.1 2.50001 26.2 4.90001 27.2 7.00001L41.6 36.9Z"
        fill="#FFDD67"
      />
      <path
        d="M29.2 1.40001C29 1.50001 28.8 1.60001 28.6 1.80001C30.5 1.30001 32.5 2.20001 33.4 4.00001L47.8 34L49.1 33.4L34.7 3.40001C33.7 1.30001 31.3 0.400009 29.2 1.40001Z"
        fill="#EBA352"
      />
      <path
        d="M26.3 44.2L34.0001 40.5L19.3 9.90001C18.3 7.80001 15.7001 6.80001 13.6001 7.80001C11.5001 8.80001 10.6001 11.4 11.6001 13.5L26.3 44.2Z"
        fill="#FFDD67"
      />
      <path
        d="M13.6 7.90001C13.4 8.00001 13.2 8.10001 13 8.30001C14.9 7.80001 17.1 8.70001 18 10.6L27.1 29.7L29.3 31L19.3 10C18.3 7.80001 15.8 6.90001 13.6 7.90001Z"
        fill="#EBA352"
      />
      <path
        d="M32.8 38.1L40.5001 34.4L25.8 3.8C24.8 1.7 22.2001 0.699996 20.1001 1.8C18.0001 2.8 17.1001 5.4 18.1001 7.5L32.8 38.1Z"
        fill="#FFDD67"
      />
      <path
        d="M20.1 1.69999C19.9 1.79999 19.7 1.99999 19.5 2.09999C21.4 1.59999 23.6 2.49999 24.5 4.39999L34.8 26L37 27.3L25.8 3.79999C24.8 1.59999 22.2 0.699995 20.1 1.69999Z"
        fill="#EBA352"
      />
      <path
        d="M8.50002 16C8.30002 16.1 8.10002 16.2 7.90002 16.4C9.70002 15.9 11.6 16.8 12.4 18.6L19.9 34.3L22.1 35.6L13.7 18C12.8 15.9 10.5 15 8.50002 16Z"
        fill="#EBA352"
      />
      <path
        d="M59.3 13C56.6 10.9 52.2 13.2 50 20.4C48.5 25.4 48.3 26.9 45.1 28.4L43.3 24.7C43.3 24.7 14.9 38.4 16 40.6C16 40.6 19.4 51.2 25.2 56.1C33.8 63.5 53.9 55.6 54.8 36.5C55.3 25.4 62.2 15.3 59.3 13Z"
        fill="#FFDD67"
      />
      <path
        d="M59.3 13C58.8 12.6 58.2 12.4 57.6 12.3C57.7 12.4 57.9 12.4 58 12.5C61 14.8 57.9 20.1 56.2 24.9C54.8 28.7 53.6 32.6 53.8 36.4C54.6 53 37.9 60.9 27.9 57.9C37.7 62 55.9 54.2 55.1 36.9C54.9 33.1 56 29.4 57.5 25.4C59.1 20.6 62.2 15.3 59.3 13Z"
        fill="#EBA352"
      />
      <path
        d="M46 28C39.8 28.7 30.7 37.6 37.1 47.3C32.4 37.5 40.1 30.9 45 28.6C45.5 28.2 46 28 46 28Z"
        fill="#EBA352"
      />
    </svg>
  );
};
