import { useEffect, useMemo } from "react";
import { useInjector } from "../../../third-party/angular2react/useInjector";
import React from "react";

const QuickStatsWrapper = (props) => {
  const { reportFilters } = props;

  const $injector = useInjector();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scope: any = useMemo(
    () => Object.assign($injector.get('$rootScope').$new(true), {}),
    [$injector]
  );

  useEffect(() => {
    if (scope?.$$childHead?.bindWidget) {
      scope.$$childHead?.bindWidget(reportFilters);
    }
  }, [reportFilters, scope.$$childHead]);

  const element = useMemo(() => {
    const compile = (element: HTMLElement) => {
      if (!scope) {
        return;
      }

      const $compile = $injector.get('$compile');

      const template = $compile(element);
      template(scope);

      try {
        scope?.$digest();
      } catch (e) {
        /* */
      }
    };

    const element = React.createElement('quick-stats-header', {
      ref: compile
    });

    return element;
  }, [$injector, scope]);

  return element;
};

export const QuickStats = (props) => (
  <div style={{ flex: 'none' }}>
    <QuickStatsWrapper {...props} />
  </div>
);
