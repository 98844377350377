import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement
} from 'chart.js';
import authorizationService from '../../common/helpers/authorization';
import { BasicDashboardView } from './BasicDashboard.view';
import { DashboardType, Role } from '../../common/enums';
import { DashboardWidgetId } from '../enums';
import { IWidget } from '../models';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DashboardProvider } from '../services';
import {
  setReportFilters,
  useReportFilterStore
} from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { BundleFlag } from '../../common/enums/BundleFlag';
import envService from '../../../../_app/environmentService/environmentService';

export const ManagementDashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    PointElement,
    LineElement
  );

  const groupId: string[] = useReportFilterStore((s) => s.groupId);

  //when landing on this page, make sure all team filters are updated to use the one that is saved in the reportFiltersStore
  useEffect(() => {
    if (groupId) setReportFilters({ groupId });
  }, [groupId]);

  const isSuperAdmin: boolean = authorizationService?.hasAnyRole([
    Role.SuperAdmin
  ]);

  const hasTodaysActivityAccess = () => {
    const hasTeamPulse = authorizationService.hasRouteByName('app.teampulse');

    return isSuperAdmin || hasTeamPulse;
  };

  const hasProductivityAccess = () => {
    const hasProductivity = authorizationService.hasRouteByName(
      'app.reports.productivity'
    );
    return isSuperAdmin || hasProductivity;
  };

  const hasProductivityGoalAccess = () => {
    const hasActivityBreakdown = authorizationService.hasRouteByName(
      'app.reports.insightsActivityBreakdown'
    );

    return isSuperAdmin || hasActivityBreakdown;
  };

  const hasWorkloadBalanceAccess = () => {
    const hasWorkloadBalance = authorizationService.hasRouteByName(
      'app.reports.insightsWorkloadBalance'
    );

    return isSuperAdmin || hasWorkloadBalance;
  };

  const hasTopCategoriesAccess = () => {
    const hasTopCategories = authorizationService.hasRouteByName(
      'app.reports.topcategories'
    );

    return isSuperAdmin || hasTopCategories;
  };

  const hasAiSummaryBundleFlag = authorizationService.hasFeature(
    BundleFlag.TodaysProductivityOutlook
  );

  const managementWidgetList: IWidget[] = [
    {
      id: DashboardWidgetId.VirtualTeamVisibilitySubheader,
      widths: { xs: 12 }
    },
    {
      id: DashboardWidgetId.TodaysProductivityOutlook,
      widths: { xs: 12 },
      shouldExclude: !(
        hasProductivityAccess() &&
        hasAiSummaryBundleFlag &&
        envService.getEnvironmentName() !== 'prod'
      )
    },
    {
      id: DashboardWidgetId.TodaysUserActivity,
      widths: { xs: 4 },
      shouldExclude: !hasTodaysActivityAccess()
    },
    {
      id: DashboardWidgetId.TodaysProductivityRatios,
      widths: { xs: 4 },
      shouldExclude: !hasProductivityAccess()
    },
    {
      id: DashboardWidgetId.TodaysTopCategories,
      widths: { xs: 4 },
      shouldExclude: !hasTopCategoriesAccess()
    },
    {
      id: DashboardWidgetId.TeamTrendsSubheader,
      widths: { xs: 12 }
    },
    {
      id: DashboardWidgetId.WorkloadBalanceBarChart,
      widths: { xs: 6 },
      shouldExclude: !hasWorkloadBalanceAccess()
    },
    {
      id: DashboardWidgetId.TeamProductivityGoal,
      widths: { xs: 6 },
      shouldExclude: !hasProductivityGoalAccess()
    }
  ];
  const widgets = useRef(managementWidgetList);

  return (
    <BasicDashboardView
      dashboardType={DashboardType.management}
      showQuotes={false}
      showReportFilters={true}
      showRefreshBtn={true}
      widgets={widgets.current}
    />
  );
};

export const TeamManagementComponent = () => {
  return (
    <ReactivTrakComponentWrapper>
      <DashboardProvider>
        <ManagementDashboard />
      </DashboardProvider>
    </ReactivTrakComponentWrapper>
  );
};
