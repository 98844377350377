import React from 'react';
import Link from '@mui/material/Link';
import { gray0 } from '../../../common/constants';

export const MessageDisplay = ({ message, link }) => {
  return message && link ? (
    <>
      {message}{' '}
      <Link
        href={link}
        target="blank"
        sx={{
          color: `${gray0}!important`,
          textDecoration: 'underline !important'
        }}
      >
        Learn more.
      </Link>
    </>
  ) : (
    <></>
  );
};
