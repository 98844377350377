import React from 'react';
import { Link, Box } from '@mui/material';
import { notificationClasses } from './NotificationMessage.styles.jsx';
import { setStorageTab, storageModes } from '../../../utils/globalFilters.js';
import CustomMenu from '../../CustomMenu/CustomMenu.jsx';
import { useImpersonate } from '../../../../../_reactivtrak/src/common/hooks/useImpersonate';

const NotificationMessage = ({
  anchorEl,
  handleClose,
  notReported,
  accountId,
  ownerEmail,
  onError,
  isExpired
}) => {
  const [, impersonate] = useImpersonate(accountId);
  return (
    <CustomMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Box sx={notificationClasses.menuContainer} id="Agents">
        {notReported > 0 && (
          <Box>
            {notReported} {notReported === 1 ? 'agent' : 'agents'} not reporting
            (last 72 hrs){' '}
            <Link
              href={`#`}
              onClick={(e) => {
                e.preventDefault();
      
                setTimeout(async () => {
                  try {
                    await impersonate();
                    setStorageTab(storageModes.users, ownerEmail);
      
                    window.location.href = '/#/app/settings/useragents';
                  } catch (ex) {
                    onError(ex);
                  }
                });
              }}
              color="error"
              target="_blank"
            >
              View
            </Link>
          </Box>
        )}
        {isExpired && (
          <Box>
            Trial Expired.{' '}
            <Link
              href={`#`}
              onClick={(e) => {
                e.preventDefault();
      
                setTimeout(async () => {
                  try {
                    await impersonate();      
                    window.location.href = '/#/app/home';
                  } catch (ex) {
                    onError(ex);
                  }
                });
              }}
              color="error"
              target="_blank"
            >
              View
            </Link>
          </Box>
        )}
      </Box>
    </CustomMenu>
  );
};

export default NotificationMessage;
