'use strict';

angular.module('app').directive('otherConnection', otherConnection);
otherConnection.$inject = [];
function otherConnection() {
    return {
        restrict: 'EA',
        template: require('views/widgets/otherConnection.html'),
        scope: {
            connection: '='
        },
        controller: [
            '$scope',
            function($scope) {
                $scope.loadImage = $scope.$root.loadImage;
                $scope.analyticEventServiceFunctions = require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;
                $scope.clickGuide = function() {
                    $scope.analyticEventServiceFunctions.newEvent('Connect - Setup Guide',  {name: $scope.connection.title});
                };

          }
        ]
    };
}
