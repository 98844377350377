import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { isValidEmail } from '../../common/helpers';

type AddChromebookUserButtonProps = {
  onSubmit: (user) => Promise<void>;
  isDisabled: boolean;
};

export default function AddChromebookUserButton(
  props: AddChromebookUserButtonProps
) {
  const { onSubmit, isDisabled } = props;

  const [newCbUser, setNewCbUser] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleClose = () => {
    setNewCbUser('');
    setDialogOpen(false);
    setHasError(false);
  };

  const handleSubmit = async () => {
    if (isValidEmail(newCbUser)) {
      setIsSaveDisabled(true);
      await onSubmit(newCbUser);
      handleClose();
    }
  };

  const handleChange = (event) => {
    setIsSaveDisabled(false);
    const email = event.target?.value;
    setNewCbUser(email);

    if (!isValidEmail(email)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={() => setDialogOpen(true)}
        color="secondary"
        disabled={isDisabled}
        startIcon={<PersonAddIcon />}
      >
        Add Chromebook User
      </Button>
      <Dialog
        maxWidth="lg"
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          handleClose();
        }}
      >
        <DialogTitle>Add New Chromebook User</DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: '20px' }}>
            An invite will be sent to this email
          </Typography>
          <TextField
            type="email"
            label="User's Email"
            value={newCbUser}
            onChange={handleChange}
            error={hasError}
            helperText={hasError && 'Enter a valid email'}
            fullWidth
          />
        </DialogContent>

        <DialogActions style={{ paddingRight: '20px' }}>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSaveDisabled}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
