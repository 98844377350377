import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const DesktopMonitor = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 264 250';
  const iconHeight = height ?? '310px';
  const iconWidth = width ?? '264px';
  const title = props.title ?? 'Desktop Monitor';
  const className = props.className;

  let classList = 'at-desktop-monitor';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
      fillColor="none"
    >
      <rect x="0.746918" y="0.334351" width="276" height="224" fill="white" />
      <path
        d="M170.625 206.507L165.484 204.345L161.957 178.604H114.991L111.168 204.239L106.569 206.536C106.349 206.646 106.172 206.827 106.068 207.05C105.964 207.272 105.938 207.523 105.994 207.763C106.051 208.002 106.186 208.215 106.379 208.367C106.572 208.52 106.811 208.603 107.057 208.603H170.201C170.453 208.603 170.698 208.516 170.893 208.356C171.088 208.197 171.222 207.975 171.272 207.728C171.322 207.481 171.284 207.224 171.166 207.002C171.049 206.779 170.857 206.604 170.625 206.507V206.507Z"
        fill="#E6E6E6"
      />
      <path
        d="M254.086 182.029H23.3773C22.1668 182.026 21.0068 181.544 20.1519 180.688C19.2969 179.832 18.8168 178.673 18.8167 177.464V144.971H258.646V177.464C258.646 178.673 258.166 179.832 257.311 180.688C256.456 181.544 255.296 182.026 254.086 182.029V182.029Z"
        fill="#CCCCCC"
      />
      <path
        d="M258.888 160.328H18.6062V21.3004C18.6079 19.8413 19.1889 18.4426 20.2219 17.4109C21.2549 16.3792 22.6555 15.7989 24.1163 15.7973H253.377C254.838 15.7989 256.239 16.3792 257.272 17.4109C258.305 18.4426 258.886 19.8413 258.888 21.3004V160.328Z"
        fill="#A1A7B0"
      />
      <path
        d="M244.537 150.237H32.9572C31.831 150.236 30.7513 149.789 29.9549 148.993C29.1585 148.198 28.7106 147.12 28.7093 145.995V30.1305C28.7106 29.0056 29.1585 27.9272 29.9549 27.1318C30.7512 26.3364 31.831 25.889 32.9572 25.8877H244.537C245.663 25.889 246.743 26.3364 247.539 27.1318C248.335 27.9272 248.783 29.0056 248.785 30.1305V145.995C248.783 147.12 248.335 148.198 247.539 148.993C246.743 149.789 245.663 150.236 244.537 150.237V150.237Z"
        fill="white"
      />
      <path
        d="M245.625 150.237H34.0453C32.9191 150.236 31.8394 149.789 31.043 148.993C30.2466 148.198 29.7987 147.12 29.7974 145.995V30.1305C29.7987 29.0056 30.2466 27.9272 31.043 27.1318C31.8393 26.3364 32.9191 25.889 34.0453 25.8877H245.625C246.751 25.889 247.831 26.3364 248.627 27.1318C249.423 27.9272 249.871 29.0056 249.873 30.1305V145.995C249.871 147.12 249.423 148.198 248.627 148.993C247.831 149.789 246.751 150.236 245.625 150.237V150.237Z"
        fill="white"
      />
      <path
        d="M198.084 208.871H79.1951C79.0733 208.874 78.9541 208.836 78.856 208.764C78.7579 208.692 78.6865 208.589 78.6528 208.472C78.6318 208.393 78.6294 208.309 78.6457 208.229C78.662 208.148 78.6966 208.072 78.7469 208.007C78.7972 207.942 78.8617 207.889 78.9357 207.853C79.0096 207.816 79.0909 207.797 79.1733 207.797H198.04C198.169 207.793 198.295 207.832 198.398 207.908C198.502 207.984 198.576 208.093 198.61 208.217C198.627 208.296 198.627 208.377 198.609 208.456C198.59 208.534 198.555 208.607 198.504 208.67C198.454 208.733 198.39 208.783 198.317 208.818C198.245 208.853 198.165 208.871 198.084 208.871Z"
        fill="#CCCCCC"
      />
      <path
        d="M242.278 44.6195H191.193V83.5982H242.278V44.6195Z"
        fill="#F2F2F2"
      />
      <path
        d="M239.739 46.5563H193.731V81.6614H239.739V46.5563Z"
        fill="white"
      />
      <path
        d="M217.543 72.5956C217.549 72.5956 217.556 72.5955 217.562 72.5952C220.676 72.4325 223.611 71.0912 225.771 68.844C227.93 66.5968 229.152 63.6125 229.187 60.498C229.222 57.3835 228.067 54.3726 225.959 52.0776C223.851 49.7826 220.946 48.3759 217.836 48.1435C217.798 48.1405 217.759 48.1455 217.723 48.1581C217.686 48.1707 217.653 48.1907 217.624 48.2168C217.596 48.2425 217.573 48.2738 217.558 48.3086C217.542 48.3435 217.534 48.3812 217.534 48.4193L217.266 72.3126C217.265 72.3869 217.294 72.4584 217.346 72.5115C217.398 72.5645 217.469 72.5948 217.543 72.5956V72.5956Z"
        fill="#893FF2"
      />
      <path
        d="M207.923 52.0945C208.019 52.0956 208.11 52.1345 208.177 52.2026L216.413 60.6146C216.446 60.6482 216.472 60.6881 216.489 60.7319C216.507 60.7757 216.516 60.8225 216.515 60.8697L216.387 72.3028C216.386 72.3518 216.376 72.4003 216.356 72.4451C216.336 72.4899 216.307 72.5301 216.27 72.5631C216.234 72.5965 216.191 72.6222 216.144 72.6384C216.098 72.6546 216.048 72.661 215.999 72.6573C213.709 72.4862 211.512 71.6806 209.655 70.3312C207.799 68.9818 206.355 67.142 205.488 65.0185C204.621 62.895 204.363 60.572 204.745 58.3105C205.127 56.049 206.132 53.9386 207.649 52.2165C207.682 52.1794 207.722 52.1494 207.767 52.1284C207.812 52.1075 207.86 52.0959 207.91 52.0945C207.914 52.0944 207.919 52.0944 207.923 52.0945Z"
        fill="#FFD630"
      />
      <path
        d="M216.298 48.0458C216.39 48.047 216.478 48.0829 216.544 48.1464C216.58 48.1803 216.608 48.2212 216.627 48.2664C216.646 48.3117 216.656 48.3605 216.655 48.4096L216.539 58.7252C216.538 58.7962 216.516 58.8654 216.476 58.924C216.436 58.9826 216.379 59.028 216.313 59.0545C216.247 59.0809 216.175 59.0872 216.105 59.0726C216.036 59.058 215.972 59.023 215.922 58.9723L208.736 51.6318C208.701 51.5969 208.675 51.5554 208.657 51.5099C208.64 51.4644 208.632 51.4157 208.633 51.367C208.635 51.3182 208.647 51.2703 208.668 51.2262C208.689 51.1821 208.718 51.1427 208.755 51.1104C210.845 49.273 213.495 48.1934 216.275 48.0463C216.283 48.0459 216.29 48.0458 216.298 48.0458Z"
        fill="#FF6D6D"
      />
      <path
        d="M212.595 77.5502C212.591 77.9854 212.457 78.4094 212.21 78.7685C211.964 79.1276 211.617 79.4058 211.213 79.5678C210.808 79.7299 210.365 79.7685 209.938 79.6788C209.512 79.5891 209.122 79.3751 208.817 79.064C208.513 78.7528 208.307 78.3584 208.227 77.9306C208.147 77.5029 208.195 77.0609 208.367 76.6607C208.538 76.2606 208.824 75.9201 209.189 75.6824C209.554 75.4446 209.981 75.3204 210.417 75.3253C211.001 75.3325 211.559 75.5708 211.967 75.9879C212.375 76.405 212.601 76.9669 212.595 77.5502Z"
        fill="#893FF2"
      />
      <path
        d="M218.89 77.6207C218.885 78.0559 218.751 78.4798 218.505 78.839C218.259 79.1981 217.912 79.4762 217.507 79.6383C217.103 79.8003 216.659 79.8389 216.233 79.7492C215.806 79.6596 215.416 79.4456 215.112 79.1344C214.807 78.8233 214.602 78.4289 214.521 78.0011C214.441 77.5733 214.49 77.1314 214.661 76.7312C214.832 76.331 215.118 75.9905 215.483 75.7528C215.848 75.5151 216.276 75.3908 216.712 75.3957C217.296 75.4029 217.853 75.6412 218.261 76.0583C218.67 76.4754 218.896 77.0373 218.89 77.6207V77.6207Z"
        fill="#FF6D6D"
      />
      <path
        d="M225.184 77.6912C225.18 78.1263 225.046 78.5503 224.799 78.9094C224.553 79.2686 224.206 79.5467 223.802 79.7088C223.397 79.8708 222.954 79.9094 222.527 79.8197C222.101 79.73 221.711 79.5161 221.406 79.2049C221.101 78.8937 220.896 78.4993 220.816 78.0716C220.736 77.6438 220.784 77.2019 220.955 76.8017C221.127 76.4015 221.413 76.061 221.778 75.8233C222.143 75.5856 222.57 75.4613 223.006 75.4662C223.59 75.4734 224.148 75.7117 224.556 76.1288C224.964 76.5459 225.19 77.1078 225.184 77.6912Z"
        fill="#FFD630"
      />
      <path
        d="M241.74 89.7328H190.655V128.712H241.74V89.7328Z"
        fill="#F2F2F2"
      />
      <path
        d="M239.202 91.6696H193.194V126.775H239.202V91.6696Z"
        fill="white"
      />
      <path
        d="M201 103.208C202.055 103.208 202.91 102.353 202.91 101.3C202.91 100.246 202.055 99.3917 201 99.3917C199.945 99.3917 199.09 100.246 199.09 101.3C199.09 102.353 199.945 103.208 201 103.208Z"
        fill="#03DAC5"
      />
      <path
        d="M231.693 99.8149C231.863 99.8147 232.032 99.8479 232.189 99.9128C232.347 99.9777 232.49 100.073 232.61 100.193C232.731 100.313 232.827 100.456 232.892 100.613C232.957 100.77 232.991 100.939 232.991 101.109C232.991 101.279 232.957 101.447 232.892 101.604C232.827 101.762 232.731 101.904 232.61 102.025C232.49 102.145 232.347 102.24 232.189 102.305C232.032 102.37 231.863 102.403 231.693 102.403H211.602C211.258 102.403 210.929 102.266 210.686 102.024C210.443 101.781 210.306 101.452 210.306 101.109C210.306 100.766 210.443 100.437 210.686 100.194C210.929 99.9513 211.258 99.8149 211.602 99.8149H231.693ZM231.693 99.498H211.602C211.39 99.4977 211.18 99.5392 210.984 99.62C210.788 99.7008 210.61 99.8194 210.46 99.969C210.31 100.119 210.191 100.296 210.11 100.492C210.028 100.687 209.987 100.897 209.987 101.109C209.987 101.321 210.028 101.53 210.11 101.726C210.191 101.921 210.31 102.099 210.46 102.249C210.61 102.398 210.788 102.517 210.984 102.598C211.18 102.679 211.39 102.72 211.602 102.72H231.693C231.905 102.72 232.115 102.679 232.311 102.598C232.507 102.517 232.685 102.398 232.835 102.249C232.985 102.099 233.104 101.921 233.185 101.726C233.266 101.53 233.308 101.321 233.308 101.109C233.308 100.897 233.266 100.687 233.185 100.492C233.104 100.296 232.985 100.119 232.835 99.969C232.685 99.8194 232.507 99.7008 232.311 99.62C232.115 99.5392 231.905 99.4977 231.693 99.498V99.498Z"
        fill="#3F3D56"
      />
      <path
        d="M223.765 102.72H208.641C208.213 102.72 207.803 102.55 207.501 102.248C207.198 101.946 207.028 101.536 207.028 101.109C207.028 100.682 207.198 100.272 207.501 99.9698C207.803 99.6677 208.213 99.498 208.641 99.498H223.765C224.193 99.498 224.603 99.6677 224.906 99.9698C225.208 100.272 225.378 100.682 225.378 101.109C225.378 101.536 225.208 101.946 224.906 102.248C224.603 102.55 224.193 102.72 223.765 102.72V102.72Z"
        fill="#03DAC5"
      />
      <path
        d="M201 111.13C202.055 111.13 202.91 110.276 202.91 109.222C202.91 108.168 202.055 107.314 201 107.314C199.945 107.314 199.09 108.168 199.09 109.222C199.09 110.276 199.945 111.13 201 111.13Z"
        fill="#893FF2"
      />
      <path
        d="M231.693 107.737C231.863 107.737 232.032 107.77 232.19 107.835C232.347 107.9 232.49 107.995 232.611 108.116C232.731 108.236 232.827 108.379 232.892 108.536C232.957 108.693 232.991 108.861 232.991 109.031C232.991 109.201 232.957 109.37 232.892 109.527C232.827 109.684 232.731 109.827 232.611 109.947C232.49 110.067 232.347 110.162 232.19 110.227C232.032 110.292 231.863 110.326 231.693 110.325H211.602C211.258 110.325 210.929 110.189 210.686 109.946C210.443 109.704 210.306 109.375 210.306 109.031C210.306 108.688 210.443 108.359 210.686 108.116C210.929 107.874 211.258 107.737 211.602 107.737H231.693ZM231.693 107.421H211.602C211.174 107.421 210.764 107.59 210.461 107.892C210.159 108.194 209.989 108.604 209.989 109.031C209.989 109.459 210.159 109.868 210.461 110.17C210.764 110.472 211.174 110.642 211.602 110.642H231.693C232.121 110.642 232.531 110.472 232.834 110.17C233.136 109.868 233.306 109.459 233.306 109.031C233.306 108.604 233.136 108.194 232.834 107.892C232.531 107.59 232.121 107.421 231.693 107.421Z"
        fill="#3F3D56"
      />
      <path
        d="M229.477 110.642H208.641C208.213 110.642 207.803 110.472 207.501 110.17C207.198 109.868 207.028 109.459 207.028 109.031C207.028 108.604 207.198 108.194 207.501 107.892C207.803 107.59 208.213 107.421 208.641 107.421H229.477C229.904 107.421 230.315 107.59 230.617 107.892C230.92 108.194 231.09 108.604 231.09 109.031C231.09 109.459 230.92 109.868 230.617 110.17C230.315 110.472 229.904 110.642 229.477 110.642Z"
        fill="#893FF2"
      />
      <path
        d="M201 119.053C202.055 119.053 202.91 118.198 202.91 117.145C202.91 116.091 202.055 115.237 201 115.237C199.945 115.237 199.09 116.091 199.09 117.145C199.09 118.198 199.945 119.053 201 119.053Z"
        fill="#C49FF9"
      />
      <path
        d="M231.693 115.66C231.863 115.66 232.032 115.693 232.19 115.758C232.347 115.823 232.49 115.918 232.611 116.038C232.731 116.158 232.827 116.301 232.892 116.458C232.957 116.615 232.991 116.784 232.991 116.954C232.991 117.124 232.957 117.292 232.892 117.45C232.827 117.607 232.731 117.749 232.611 117.87C232.49 117.99 232.347 118.085 232.19 118.15C232.032 118.215 231.863 118.248 231.693 118.248H211.602C211.258 118.248 210.929 118.111 210.686 117.869C210.443 117.626 210.306 117.297 210.306 116.954C210.306 116.611 210.443 116.282 210.686 116.039C210.929 115.796 211.258 115.66 211.602 115.66H231.693ZM231.693 115.343H211.602C211.174 115.343 210.764 115.513 210.461 115.815C210.159 116.117 209.989 116.527 209.989 116.954C209.989 117.381 210.159 117.791 210.461 118.093C210.764 118.395 211.174 118.565 211.602 118.565H231.693C232.121 118.565 232.531 118.395 232.834 118.093C233.136 117.791 233.306 117.381 233.306 116.954C233.306 116.527 233.136 116.117 232.834 115.815C232.531 115.513 232.121 115.343 231.693 115.343Z"
        fill="#3F3D56"
      />
      <path
        d="M217.102 118.565H208.641C208.429 118.565 208.219 118.524 208.023 118.443C207.827 118.362 207.649 118.243 207.499 118.094C207.349 117.944 207.23 117.766 207.149 117.571C207.068 117.375 207.026 117.166 207.026 116.954C207.026 116.742 207.068 116.532 207.149 116.337C207.23 116.141 207.349 115.964 207.499 115.814C207.649 115.664 207.827 115.546 208.023 115.465C208.219 115.384 208.429 115.343 208.641 115.343H217.102C217.53 115.344 217.939 115.514 218.241 115.816C218.543 116.118 218.713 116.527 218.713 116.954C218.713 117.381 218.543 117.79 218.241 118.092C217.939 118.394 217.53 118.564 217.102 118.565V118.565Z"
        fill="#C49FF9"
      />
      <path
        d="M179.363 51.5158H36.6408V124.569H179.363V51.5158Z"
        fill="#F2F2F2"
      />
      <path
        d="M174.605 55.1458H41.3982V120.939H174.605V55.1458Z"
        fill="white"
      />
      <path
        d="M158.085 110.902H60.9794C60.8988 110.902 60.8215 110.87 60.7646 110.813C60.7076 110.756 60.6755 110.679 60.6755 110.598V64.1139C60.6755 64.0334 60.7076 63.9562 60.7645 63.8993C60.8215 63.8424 60.8988 63.8104 60.9794 63.8104C61.06 63.8104 61.1373 63.8424 61.1943 63.8993C61.2513 63.9562 61.2833 64.0334 61.2833 64.1139V110.295H158.085C158.166 110.295 158.243 110.327 158.3 110.384C158.357 110.441 158.389 110.518 158.389 110.598C158.389 110.679 158.357 110.756 158.3 110.813C158.243 110.87 158.166 110.902 158.085 110.902Z"
        fill="#3F3D56"
      />
      <path
        d="M74.5058 107.563H65.6737C65.4345 107.563 65.2052 107.468 65.0361 107.299C64.867 107.13 64.7719 106.901 64.7716 106.662V94.5024C64.7719 94.2635 64.867 94.0345 65.0361 93.8657C65.2052 93.6968 65.4345 93.6018 65.6737 93.6015H74.5058C74.745 93.6018 74.9743 93.6968 75.1434 93.8657C75.3125 94.0345 75.4076 94.2635 75.4079 94.5024V106.662C75.4076 106.901 75.3125 107.13 75.1434 107.299C74.9743 107.468 74.745 107.563 74.5058 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M90.6123 107.563H81.7802C81.541 107.563 81.3117 107.468 81.1426 107.299C80.9735 107.13 80.8784 106.901 80.8781 106.662V82.9688C80.8784 82.7299 80.9735 82.5009 81.1426 82.332C81.3117 82.1631 81.541 82.0681 81.7802 82.0679H90.6123C90.8515 82.0681 91.0808 82.1631 91.2499 82.332C91.419 82.5009 91.5141 82.7299 91.5144 82.9688V106.662C91.5141 106.901 91.419 107.13 91.2499 107.299C91.0808 107.468 90.8515 107.563 90.6123 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M106.719 107.563H97.8867C97.6475 107.563 97.4182 107.468 97.2491 107.299C97.08 107.13 96.9848 106.901 96.9846 106.662V94.5024C96.9848 94.2635 97.08 94.0345 97.2491 93.8657C97.4182 93.6968 97.6475 93.6018 97.8867 93.6015H106.719C106.958 93.6018 107.187 93.6967 107.356 93.8656C107.525 94.0345 107.621 94.2635 107.621 94.5024V106.662C107.621 106.901 107.525 107.13 107.356 107.299C107.187 107.468 106.958 107.563 106.719 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M122.825 107.563H113.993C113.759 107.568 113.533 107.481 113.364 107.319C113.195 107.158 113.097 106.937 113.091 106.703V78.3752C113.097 78.1418 113.195 77.9203 113.364 77.759C113.533 77.5978 113.759 77.5101 113.993 77.5151H122.825C123.059 77.5101 123.285 77.5978 123.454 77.759C123.623 77.9203 123.722 78.1418 123.727 78.3752V106.703C123.722 106.937 123.623 107.158 123.454 107.319C123.285 107.481 123.059 107.568 122.825 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M138.932 107.563H130.1C129.86 107.563 129.631 107.468 129.462 107.299C129.293 107.13 129.198 106.901 129.198 106.662V70.2211C129.198 69.9823 129.293 69.7533 129.462 69.5844C129.631 69.4155 129.86 69.3205 130.1 69.3202H138.932C139.171 69.3205 139.4 69.4155 139.569 69.5844C139.738 69.7533 139.834 69.9823 139.834 70.2211V106.662C139.834 106.901 139.738 107.13 139.569 107.299C139.4 107.468 139.171 107.563 138.932 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M154.889 107.563H146.057C145.818 107.563 145.589 107.468 145.42 107.299C145.251 107.13 145.155 106.901 145.155 106.662V70.2211C145.155 69.9823 145.251 69.7533 145.42 69.5844C145.589 69.4155 145.818 69.3205 146.057 69.3202H154.889C155.129 69.3205 155.358 69.4155 155.527 69.5844C155.696 69.7533 155.791 69.9823 155.792 70.2211V106.662C155.791 106.901 155.696 107.13 155.527 107.299C155.358 107.468 155.129 107.563 154.889 107.563V107.563Z"
        fill="#03DAC5"
      />
      <path
        d="M68.9983 89.9593C70.0054 89.9593 70.8217 89.144 70.8217 88.1382C70.8217 87.1325 70.0054 86.3171 68.9983 86.3171C67.9913 86.3171 67.175 87.1325 67.175 88.1382C67.175 89.144 67.9913 89.9593 68.9983 89.9593Z"
        fill="#03DAC5"
      />
      <path
        d="M85.1993 78.1222C86.2064 78.1222 87.0227 77.3068 87.0227 76.3011C87.0227 75.2953 86.2064 74.4799 85.1993 74.4799C84.1923 74.4799 83.3759 75.2953 83.3759 76.3011C83.3759 77.3068 84.1923 78.1222 85.1993 78.1222Z"
        fill="#03DAC5"
      />
      <path
        d="M101.254 89.9593C102.261 89.9593 103.078 89.144 103.078 88.1382C103.078 87.1325 102.261 86.3171 101.254 86.3171C100.247 86.3171 99.4308 87.1325 99.4308 88.1382C99.4308 89.144 100.247 89.9593 101.254 89.9593Z"
        fill="#03DAC5"
      />
      <path
        d="M117.464 72.3554C118.471 72.3554 119.287 71.54 119.287 70.5343C119.287 69.5285 118.471 68.7132 117.464 68.7132C116.457 68.7132 115.641 69.5285 115.641 70.5343C115.641 71.54 116.457 72.3554 117.464 72.3554Z"
        fill="#03DAC5"
      />
      <path
        d="M134.516 65.1615C135.511 65.1615 136.317 64.3558 136.317 63.3619C136.317 62.368 135.511 61.5624 134.516 61.5624C133.521 61.5624 132.714 62.368 132.714 63.3619C132.714 64.3558 133.521 65.1615 134.516 65.1615Z"
        fill="#03DAC5"
      />
      <path
        d="M150.473 61.2989C151.48 61.2989 152.297 60.4835 152.297 59.4778C152.297 58.472 151.48 57.6567 150.473 57.6567C149.466 57.6567 148.65 58.472 148.65 59.4778C148.65 60.4835 149.466 61.2989 150.473 61.2989Z"
        fill="#03DAC5"
      />
      <path
        d="M101.432 88.5461L85.2871 76.4685L69.3626 88.381L68.9984 87.8954L85.2871 75.7099L101.355 87.7301L117.322 70.2786L117.386 70.2526L134.288 62.9054L134.516 63.4684L117.678 70.7899L101.432 88.5461Z"
        fill="#3F3D56"
      />
      <mask
        id="mask0"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="133"
        y="40"
        width="72"
        height="51"
      >
        <path
          d="M163.484 76.8586L153.726 59.215L134.314 63.5343L134.181 62.9419L154.038 58.5233L163.75 76.0831L185.476 66.7315L185.545 66.7339L203.974 66.9786L203.95 67.5853L185.59 67.3435L163.484 76.8586Z"
          fill="#3F3D56"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="144.372"
          y="38.3112"
          width="14.3792"
          height="29.7524"
          transform="rotate(24.2548 144.372 38.3112)"
          fill="#3F3D56"
        />
      </g>
    </SvgIconWrapper>
  );
};
