import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appAccountProfile: IRoute = {
  name: 'app.account.profile',
  stateDefinition: {
    url: '/account/profile',
    template: '<account-profile></account-profile>',
    data: { pageTitle: 'ActivTrak Profile' }
  },
  access: {
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.User,
      Role.Viewer
    ],
    read: [Role.SupportBasic]
  }
};

export default appAccountProfile;
