'use strict';

angular.module('app').service('topWebsitesService', TopWebsitesService);

TopWebsitesService.$inject = ['$http', '$q', 'envConfig'];

function TopWebsitesService($http, $q, envConfig) {
    this.getApiUrl = function (subpage, id) {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/topwebsites';

        if (subpage !== undefined) {
            if (id !== undefined) {
                url += '/' + id;
            }
            url += '/' + subpage;
        }

        return url + '?';
    };

    this.getWebsites = function (parameters) {
        return $http.get(this.getApiUrl() + parameters);
    };

    this.getWebsitesAndTotals = function (parameters) {
        return $http.get(this.getApiUrl('sitesandtotals') + parameters);
    };

    this.getTitles = function (id, parameters) {
        return $http.get(this.getApiUrl('titles', id) + parameters);
    };

    this.getSubpages = function (id, parameters) {
        return $http.get(this.getApiUrl('subpages', id) + parameters);
    };

    this.getUsage = function (id, parameters) {
        return $http.get(this.getApiUrl('usage', id) + parameters);
    };

    this.getTotals = function (parameters) {
        return $http.get(this.getApiUrl('totals') + parameters);
    };

    this.getActivityTimeline = function (id, parameters, data) {
        // This fixes a "bug" with a request when Id is undefined.
        // This should be corrected with the caller not requesting this
        if (id === undefined) {
            var q = $q.defer();
            q.resolve({ data: { data: [], total: 0 } });
            return q.promise;
        }

        return $http.get(this.getApiUrl('timeline', id) + parameters, data);
    };
}
