import React, { useEffect, useRef, useState } from 'react';
import { concat, debounce, some } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  heavyFontWeight,
  regularFontWeight,
  smallFontSize
} from '../../common/constants';
import { IGroup } from '../../common/models';
import { GroupSearchType } from '../../common/enums';
import { MemberType } from '../enums';
import { useGroup } from '../services';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  ListItem,
  TextField,
  Divider,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

const classes = {
  wrapper: {
    width: '100%',
    textAlign: 'left'
  }
};

interface IOption {
  id: number;
  type: 'member' | 'group';
  label: string;
  searchField?: GroupSearchType;
}

type GroupSearchProps = {
  groups: IGroup[];
  onSubmit: (type?: GroupSearchType, value?: number | string) => void;
};

const MIN_CHARS = 2;

export default function GroupsSearch(props: GroupSearchProps) {
  const { groups, onSubmit } = props;

  const [filter, setFilter] = useState<string>();

  const history = useUIRouterHistory();
  const { groupState } = useGroup();
  const { allGroupMembers, getGroupMembers } = groupState;

  useEffect(() => {
    getGroupMembers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceSetFilter = useRef(
    debounce((value) => {
      if (value === '') {
        value = undefined;
      }
      setFilter(value);
    }, 400)
  ).current;

  const handleOnChange = (
    _: React.ChangeEvent<object>,
    value: string | IOption,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'createOption') {
      value = value as string;
      setFilter(value);
      onSubmit(GroupSearchType.Search, value);
    } else if (reason === 'clear') {
      onSubmit(undefined);
    } else if (reason === 'selectOption') {
      value = value as IOption;
      if (value.type === 'group') {
        handleGroupSelect(value.id);
      } else if (value.type === 'member') {
        handleSearchGroupsByMemberId(value);
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debounceSetFilter(value);
  };

  const handleGroupSelect = (id: number) =>
    history.push('app.settings.groups_id', { groupId: id });

  const handleSearchGroupsByMemberId = (member: IOption) => {
    setFilter(member.label);
    onSubmit(member.searchField, member.id);
  };

  const getOptions = (): IOption[] => {
    if (!filter || filter.length < MIN_CHARS) {
      return [];
    }

    const groupOptions: IOption[] = groups
      .filter((g) =>
        g.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
      .map((g, index) => ({
        id: g.id,
        key: `group-option-${index}`,
        label: g.name,
        type: 'group'
      }));

    const memberOptions: IOption[] = allGroupMembers
      .filter(
        (m) =>
          m.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          m.alias?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          m.domain?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
      .map((m, index) => ({
        id: m.id,
        key: `member-option-${index}`,
        label: m.name,
        type: 'member',
        searchField:
          m.type === MemberType.User
            ? GroupSearchType.User
            : GroupSearchType.Computer
      }));

    return concat(groupOptions, memberOptions);
  };

  const options = getOptions();

  return !groups ? (
    <></>
  ) : (
    <Box sx={classes.wrapper}>
      <Autocomplete
        freeSolo
        options={options}
        getOptionLabel={(option: IOption | string) =>
          typeof option === 'object' ? option.label : option
        }
        groupBy={(option: IOption) => option.type}
        onChange={handleOnChange}
        onInputChange={handleSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Search by Group Name, User, or Computer Name, etc...'}
            margin="normal"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, option: IOption, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          let fontSize: string, onClick: () => void;

          if (option.type === 'group') {
            onClick = () => handleGroupSelect(option.id);
          } else if (option.type === 'member') {
            fontSize = smallFontSize;
            onClick = () => handleSearchGroupsByMemberId(option);
          }

          return (
            <ListItem onClick={onClick} {...props} key={props.id}>
              <Box fontSize={fontSize}>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight
                        ? heavyFontWeight
                        : regularFontWeight
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Box>
            </ListItem>
          );
        }}
        renderGroup={(params) => {
          return (
            <>
              {params.group === 'member' &&
                some(options, (o) => o.type === 'group') && <Divider />}
              {params.children}
            </>
          );
        }}
      ></Autocomplete>
    </Box>
  );
}
