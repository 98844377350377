'use strict';
import atDropdownButtonTemplate from '../../../../app/views/widgets/atDropdownButton.html?raw';

angular.module('app').directive('atDropdownButton', atDropdownButton);

function atDropdownButton() {
    return {
        restrict: 'E',
        scope: {
            options: '=?atOptions',
            model: '=atModel'
        },
        template: atDropdownButtonTemplate,
        controller: atDropdownButtonCtrl
    };
}

atDropdownButtonCtrl.$inject = ['$scope', '$timeout', 'atHelperFunctions'];

function atDropdownButtonCtrl($scope, $timeout, atHelperFunctions) {
    // If dynamicLabel option enabled, the label will display the value as the label once a selection made.
    $scope.dynamicLabel = false;
    $scope.id = atHelperFunctions.generateGuid();

    $scope.handleSelection = function (selection) {
        // Do nothing if active option is selected
        if (selection.text === $scope.model) {
            return;
        }

        if ($scope.model !== undefined) {
            $scope.model = selection.text;

            if ($scope.dynamicLabel) {
                $scope.label = selection.value;
            }
        }

        if (typeof $scope.options.onChange === 'function') {
            $scope.options.onChange(selection);
        }
    };

    $scope.setDropdownPosition = function () {
        var selectionList = angular.element('#' + $scope.id + '-wrapper');
        var button = angular.element('#' + $scope.id);

        if ($scope.options && !$scope.options.position) {
            var isOnScreen = atHelperFunctions.isElementOnScreen(selectionList, false, true);

            if (!isOnScreen) {
                $scope.options.position = 'right';
                // console.log('Setting right position', $scope.options);
            }
        }
        // Do nothing if position is not 'right'
        // Or if element not found
        if (($scope.options && $scope.options.position !== 'right') || !button || !button[0]) {
            return;
        }

        // Get Button Size
        var buttonWidth = button.innerWidth() || 0;

        // Create style object
        $scope.rightStyle = { right: 'calc(100% - ' + buttonWidth + 'px)' };
    };

    function setScope(options) {
        switch (options.type) {
            case 'sort':
                $scope.label = 'Sort';
                $scope.icon = 'sort';
                $scope.iconClass = 'material-icons';
                break;
            case 'filter':
                $scope.label = 'Filter';
                $scope.icon = 'filter_list';
                $scope.iconClass = 'material-icons';
                break;
            case 'settings':
                $scope.label = '';
                $scope.icon = 'settings';
                $scope.iconClass = 'material-icons';
                break;
            case 'custom':
                $scope.label = options.label;
                $scope.icon = options.iconText;
                $scope.iconClass = options.iconClass;
                break;
            default:
                console.error('ActivTrak Error: Invalid dropdown button configuration.');
        }

        $scope.selectionList = _.filter($scope.options.selectionList, function (listItem) {
            return !listItem.disabled;
        });

        $scope.emptyList = !$scope.selectionList || $scope.selectionList.length === 0;

        if (options.dynamicLabel && !$scope.emptyList) {
            $scope.dynamicLabel = options.dynamicLabel;
            var index = _.findIndex(options.selectionList, {
                text: $scope.model
            });
            if (index >= 0) {
                $scope.label = options.selectionList[index].value;
            } else {
                $scope.label = options.selectionList[0].value;
            }
        }

        $timeout($scope.presetDropdownPosition);
    }

    $scope.presetDropdownPosition = function () {
        var dropdownControl = angular.element('#' + $scope.id + '-dropdown');
        var selectionListOptions = angular.element('#' + $scope.id + '-options');
        selectionListOptions.addClass('hide-list');
        dropdownControl.addClass('open');
        $timeout(function () {
            $scope.setDropdownPosition();
            dropdownControl.removeClass('open');
            selectionListOptions.removeClass('hide-list');
        });
    };

    $timeout($scope.presetDropdownPosition, 2000);

    $scope.$watch('options', function (options) {
        if (!options) {
            return;
        }
        setScope(options);
    });
}
