import React from 'react';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material';
import Notifications from '../../common/components/Notifications/Notifications';
import { NotificationProvider } from '../../common/services/Notifications';
import { DownloadProvider } from '../../common/services';
import { AuthorizationProvider } from '../../common/services/Authorization';
import { AgentActivationView } from './AgentActivation.view';
import { getTheme } from '../../common/styles/theme';
import { MUIStylesContainer } from '../../common/styles/app.component.mui.styles';

export const AgentActivation = () => {
  const theme = getTheme();

  return (
    <ThemeProviderV5 theme={theme}>
      <AuthorizationProvider>
        <NotificationProvider>
          <MUIStylesContainer>
            <Notifications />
            <DownloadProvider>
              <AgentActivationView />
            </DownloadProvider>
          </MUIStylesContainer>
        </NotificationProvider>
      </AuthorizationProvider>
    </ThemeProviderV5>
  );
};
