import {
  OptimizeIcon,
  TeamLevelIcon,
  HybridIcon,
  IndividualIcon,
  EmployeeExpIcon,
  MonitorIcon,
  TechInvestIcon
} from '../../common/assets/Icons';

const AccountWizardIcons = {
  HybridIcon,
  TeamLevelIcon,
  IndividualIcon,
  EmployeeExpIcon,
  MonitorIcon,
  OptimizeIcon,
  TechInvestIcon
};

export default AccountWizardIcons;
