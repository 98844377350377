export enum ReportFilterName {
  GroupId = 'groupId',
  Users = 'users',
  Dates = 'dates',
  FromTimestamp = 'fromTimestamp',
  Productivity = 'productivity',
  TimelineFromHour = 'timelineFromHour',
  TimelineToHour = 'timelineToHour',
  TimelineStrict = 'timelineStrict',
  TimelineInterval = 'timelineInterval',
  TimelineSort = 'timelineSort'
}
