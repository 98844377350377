import moment from 'moment';

export const convertTimeStringToSeconds = (timeString: string) => {
  const timeParts = timeString.split(':');
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  let time = isNaN(hours) ? 0 : hours * 60 * 60;
  time += isNaN(minutes) ? 0 : minutes * 60;
  time += isNaN(seconds) ? 0 : seconds;

  return time;
};

const calculateDurations = (duration) => {
  if (typeof duration === 'string') {
    duration = convertTimeStringToSeconds(duration);
  }

  return {
    hours: Math.floor(duration / 3600),
    minutes: Math.floor((duration % 3600) / 60),
    seconds: duration % 60
  };
};

export const getTimeStrFromSeconds = (secondsDuration: number): string => {
  secondsDuration = Number(secondsDuration);
  const h = Math.floor(secondsDuration / 3600);
  const m = Math.floor((secondsDuration % 3600) / 60);
  const s = Math.floor((secondsDuration % 3600) % 60);

  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? ` ${m}m ` : '';
  const sDisplay = s > 0 ? ` ${s}s` : '';

  const final = hDisplay + mDisplay + sDisplay;
  return final.trim();
};

export const durationFormat = (
  time,
  removeSeconds: boolean,
  config = { spaceBetweenDigits: true, singleDigitLeadingZero: false }
) => {
  let duration;

  if (typeof time === 'number' || typeof time === 'string') {
    // Calculate durations based on seconds
    duration = calculateDurations(time);
  } else {
    // Return original value, does not match expectations
    return time;
  }

  if (removeSeconds && duration.seconds > 29) {
    if (duration.minutes === 59) {
      duration.hours++;
      duration.minutes = 0;
    } else {
      duration.minutes++;
    }
  }

  const singleDigitHandler = (time) => {
    if (time < 10) return config.singleDigitLeadingZero ? '0' + time : time;

    return time;
  };

  const hoursLabel = duration.hours > 0 ? duration.hours.toFixed(0) + 'h' : '';
  let minutesLabel =
    duration.minutes > 0 || duration.hours > 0 || removeSeconds
      ? (hoursLabel !== '' && config.spaceBetweenDigits ? ' ' : '') +
        singleDigitHandler(duration.minutes.toFixed(0)) +
        'm'
      : '';
  const secondsLabel = removeSeconds
    ? ''
    : (minutesLabel !== '' && config.spaceBetweenDigits ? ' ' : '') +
      singleDigitHandler(duration.seconds.toFixed(0)) +
      's';
  if (
    removeSeconds &&
    duration.minutes === 0 &&
    duration.hours === 0 &&
    duration.seconds !== 0
  ) {
    minutesLabel = '< 1m';
  }

  return hoursLabel + minutesLabel + secondsLabel;
};

export const formatTimeStamp = (timeStamp: string, dateFormat?: string) => {
  const format = dateFormat || 'YYYY-MM-DD HH:mm:ss';
  return timeStamp === null ? 'N/A' : moment(timeStamp).utc().format(format);
};

export const formatDuration = (
  time: string | number,
  removeSeconds?: boolean
) => {
  let duration;

  if (typeof time === 'number' || typeof time === 'string') {
    // Calculate durations based on seconds
    duration = calculateDurations(time);
  } else {
    // Return original value, does not match expectations
    return time;
  }

  if (removeSeconds && duration.seconds > 29) {
    if (duration.minutes === 59) {
      duration.hours++;
      duration.minutes = 0;
    } else {
      duration.minutes++;
    }
  }

  const hoursLabel = duration.hours > 0 ? duration.hours.toFixed(0) + 'h' : '';
  let minutesLabel =
    duration.minutes > 0 || duration.hours > 0 || removeSeconds
      ? (hoursLabel !== '' ? ' ' : '') + duration.minutes.toFixed(0) + 'm'
      : '';
  const secondsLabel = removeSeconds
    ? ''
    : (minutesLabel !== '' ? ' ' : '') + duration.seconds.toFixed(0) + 's';
  if (
    removeSeconds &&
    duration.minutes === 0 &&
    duration.hours === 0 &&
    duration.seconds !== 0
  ) {
    minutesLabel = '< 1m';
  }

  return hoursLabel + minutesLabel + secondsLabel;
};
