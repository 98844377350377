import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { ActivityLogViewComponent } from '../../../../_reactivtrak/src/reports/activity-log/views/ActivityLogReport.view.tsx';
import { activitylogTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

/// This wraps the legacy report in a new component
/// using the wrapped- prefix to mark wrapped components
angular.module('app').component('wrappedActivityLog', { template: activitylogTemplate, controller: 'ActivityLogCtrl'});


/// This now replaces the route level component
/// This is referenced in the component section of the route definition
angular.module('app').component('activityLog', react2angular(ActivityLogViewComponent, ['activityTimeSettings']));
