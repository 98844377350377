export const computerTextFields = [
  { name: 'windowsDomain', label: 'Primary Domain', default: '' },
  { name: 'computer', label: 'Computer', default: '' },
  { name: 'alias', label: 'Alias', default: '' },
  { name: 'agentVersion', label: 'Agent Version', default: '' }
];

export const userTextFields = [
  { name: 'logonDomain', label: 'Logon Domain', default: '' },
  { name: 'user', label: 'User', default: '' },
  { name: 'alias', label: 'Alias', default: '' }
];
