import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { IGroupFilterDto, IGroupStore } from '../models';
import { fetchData, fetchReportingData } from '../helpers';
import { mapToTeamFilter } from '../components/ReportFilters/utils/teamFilter.utils';
import { commonApis } from '../constants';

const groupsStore = createStore<IGroupStore>((set, get) => ({
  groupFilters: [],
  insightsFilters: [],
  fetchFilters: async () => {
    try {
      const response = await fetchReportingData<IGroupFilterDto[]>({
        path: commonApis.groups.allGroupsFilters
      });
      const mapped = response.map((item: IGroupFilterDto) =>
        mapToTeamFilter(item)
      );
      set({ groupFilters: mapped });
      return mapped;
    } catch (error) {
      console.error('ActivTrak Error: cannot load group filters');
    }
  },
  getGroupFilterById: (id) => {
    const currentGroups = get().groupFilters;
    return currentGroups.find((item) => item.id == id);
  },
  fetchInsightsFilters: async () => {
    try {
      const response = await fetchData<any>({
        path: commonApis.insights.groups
      });
      set({ insightsFilters: response });
    } catch (error) {
      console.error('ActivTrak Error: cannot load Insights group filters');
    }
  }
}));

const useGroupsStore = (selector) => useStore<any, any>(groupsStore, selector);

export { groupsStore, useGroupsStore };
