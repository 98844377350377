const InfoPanelVariant = Object.freeze({
  /** Two lines of centered text. First is header of metric followed by description */
  Header: 1,
  /** Single line of text, bold metric followed by description */
  Condensed: 2,
  /** Two lines. Line 1 is Name on left Metric on right. Description underneath. */
  CondensedDetail: 3,
  /** Like condensed detail but with children elements in col2 */
  CondensedCustom: 4,
  Stacked: 5,
  Inline: 6,
  Custom: 7
});

export default InfoPanelVariant;
