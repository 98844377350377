'use strict';

angular.module('app').controller('CartCtrl', CartCtrl);

CartCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$http',
    '$sce',
    'envConfig',
    'accountApiHelper',
    'analyticsService',
    'AccountSettingsService',
    'notificationService'
];

function CartCtrl(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $http,
    $sce,
    envConfig,
    accountApiHelper,
    analyticsService,
    AccountSettingsService,
    notificationService
) {
    var inError = false;
    function loadError() {
        notificationService.showNotification('Error loading upgrade page.', 'danger');
        inError = true;
        $state.go('app.dashboard', $stateParams);
    }

    $http
        .get(envConfig.apiUrl() + '/api/cart/upgrade')
        .success(function (response) {
            if (response.url) {
                const addonParam = $stateParams.addon ? '&addon=' + $stateParams.addon : '';
                $scope.embeddedUrl = $sce.trustAsResourceUrl(response.url + addonParam);
            } else {
                loadError();
            }
        })
        .catch(function () {
            loadError();
        });

    $scope.$on('$destroy', function () {
        if (!inError) {
            accountApiHelper.loadSettings().then(function () {
                analyticsService.identify();
                $rootScope.isSubscriptionCancelled = AccountSettingsService.isSubscriptionCancelled;
            });
        }
    });
}

//Listen to message from iframe...
var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

// Listen to message from child window
eventer(
    messageEvent,
    function (e) {
        var key = e.message ? 'message' : 'data';
        var data = e[key];
        if (data === 'go-to-dashboard') {
            window.location.href = '#/app/home';
        }
    },
    false
);
