'use strict';

angular.module('app')
    .service('gridPropertiesService', GridPropertiesService);

GridPropertiesService.$inject = ['$filter', '$window'];

function GridPropertiesService($filter, $window) {

    function fixWidths(gridColumns) {
        var visibleColumns = $filter('filter')(gridColumns, function (column) {
            return !column.hidden;
        });
        angular.forEach(visibleColumns, function (col, i) {
            var minWidth = col.activTrakMinWidth;
            if (!minWidth) {
                return false;
            }
            var width = Math.round($('th').eq(i).outerWidth());

            if (minWidth !== 'auto' && width <= minWidth) {
                $('.k-grid-header col').eq(i).width(minWidth);
                $('.k-grid-content col').eq(i).width(minWidth);
            } else {
                $('.k-grid-header col').eq(i).width('auto');
                $('.k-grid-content col').eq(i).width('auto');
            }
        });
    }

    this.applyMinColWidths = function (gridColumns) {
        fixWidths(gridColumns);

        angular.element($window).bind('resize', function () {
            if (this.resizeTimeOut) {
                clearTimeout(this.resizeTimeOut);
            }
            this.resizeTimeOut = setTimeout(function () {
                fixWidths(gridColumns);
            }, 500);
        });
    };

    this.bindFilterAutoComplete = function (e, preventOnEmpty) {
        if (!e.sender.isBound) {
            var autocompleteInputs = angular.element('[data-role="autocomplete"]');
            angular.forEach(autocompleteInputs, function (i) {
                var $i = angular.element(i);
                var $close = $i.parent().find('.k-i-close');
                $i.data('kendoAutoComplete').bind('filtering', function (e) {
                    var filter = e.filter;

                    $close.hide();

                    if (!filter.value || !filter.value.replace(/\s/g, '').length) {
                        $close.attr('style', '');

                        if (preventOnEmpty) {
                            e.preventDefault();
                        }
                    }
                });
            });
            autocompleteInputs.bind('blur', function (e) {
                var $close = angular.element(e.target).parent().find('.k-i-close');
                $close.attr('style', '');
            });

            e.sender.isBound = true;
        }
    };

    this.hideLeftBorder = function () {
        var gridHeader = angular.element('.k-grid-header th.k-header:not([style*="display"]):not([style*="none"])');
        var gridFilter = angular.element('.k-filter-row>th:not([style*="display"]):not([style*="none"])');

        gridHeader.css('border-left-width', '1px');
        gridHeader.first().css('border-left-width', 0);
        gridFilter.css('border-left-width', '1px');
        gridFilter.first().css('border-left-width', 0);
    };
}
