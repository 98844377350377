import React from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import { useUIRouterHistory } from '../../hooks/useUIRouterHistory';

/**
 * Primary UI component for button linking
 */

interface LinkButtonProps extends ButtonProps {
  to: string;
  params?: object;
  onClick?: () => void;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { onClick: onClk, to, params, ...rest } = props;

  const history = useUIRouterHistory();

  const onClick = () => {
    if (onClk) onClk();
    history.push(to, params);
  };

  return <MUIButton {...rest} onClick={onClick} />;
};

export default LinkButton;
