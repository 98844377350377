'use strict';

angular.module('app')
    .directive('maintenanceMode', maintenanceMode);

function maintenanceMode() {
    return {
        restrict: 'E',
        scope: false,
        controller: maintenanceModeCtrl
    };
}

maintenanceModeCtrl.$inject = ['customUibModal', 'AccountSettingsService', 'messagesService'];

function maintenanceModeCtrl(customUibModal, AccountSettingsService, msg) {
    if (!AccountSettingsService.maintenanceMode) {
        return;
    }

    customUibModal.open({
        animation: false,
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        template:
            '<div class="modal-header p-t-40 m-b-15">' +
                '<h5 class="modal-title all-caps">' + msg.get('accountMaintenance') + '</h5>' +
            '</div>' +
            '<div class="modal-body">' +
                '<img class="block center-margin" alt="ActivTrak logo" width="240" ng-src="/images/activtrak-logo.png" />' +
                '<p class="text-center m-t-25">' + msg.get('sorryForTheInconvenience') + '</p>' +
                '<p class="text-center">' + msg.get('agentsContinuingRecordingData') + '</p>' +
                '<p class="text-center">' + msg.get('activTrakTeam') + '</p>' +
            '</div>' +
            '<div class="modal-footer">' + msg.get('activTrakSite') + '</div>'
    });
}
