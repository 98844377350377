import * as React from 'react';

import { gray7 } from '../../common/constants';
import Link from '../../common/components/Link';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type ComponentProps = {
  message: string;
  hyperlinkText: string;
  url: string;
  onClose: () => void;
  open: boolean;
};

export default function SnackbarWithUrl(props: ComponentProps) {
  const { open, message, hyperlinkText, url, onClose } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={20000}
        onClose={handleClose}
        message={message}
        sx={{ top: 60 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert elevation={6} variant="filled" {...props} severity="success">
            {message}
            <Box sx={{ paddingTop: '8px' }}>
              <Link href={url} underline="always">
                <Typography
                  sx={{ color: gray7, textDecoration: 'underline !important' }}
                >
                  {hyperlinkText}
                </Typography>
              </Link>
            </Box>
          </Alert>
        </div>
      </Snackbar>
    </>
  );
}
