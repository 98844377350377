import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const HelpIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Help';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM13.2 20.4H10.8V18H13.2V20.4ZM15.684 11.1L14.604 12.204C14.004 12.816 13.572 13.368 13.356 14.232C13.26 14.616 13.2 15.048 13.2 15.6H10.8V15C10.8 14.448 10.896 13.92 11.064 13.428C11.304 12.732 11.7 12.108 12.204 11.604L13.692 10.092C14.244 9.564 14.508 8.772 14.352 7.932C14.196 7.068 13.524 6.336 12.684 6.096C11.352 5.724 10.116 6.48 9.72 7.62C9.576 8.064 9.204 8.4 8.736 8.4H8.376C7.68 8.4 7.2 7.728 7.392 7.056C7.908 5.292 9.408 3.948 11.268 3.66C13.092 3.372 14.832 4.32 15.912 5.82C17.328 7.776 16.908 9.876 15.684 11.1Z"
      />
    </SvgIconWrapper>
  );
};
