import {
  aqua7,
  primaryPurple,
  orange5,
  red3,
  gray9,
  gray5,
  gray16,
  gray0,
  blue6,
  red2,
  red5
} from '../../common/constants';

export const WorkloadColors = {
  optimal: aqua7,
  high: red3,
  low: orange5,
  varied: primaryPurple
};

export const WORKLOAD_PIE_CHART_COLORS = [
  WorkloadColors.optimal,
  WorkloadColors.high,
  WorkloadColors.low,
  WorkloadColors.varied
];

export const PRODUCTIVITY_PIE_CHART_COLORS = [blue6, red2];
export const ZERO_PIE_CHART_COLORS = [gray9, gray5, gray16];

export const PRODUCTIVITY_BAR_CHART_COLORS = [blue6, red5];

export const zeroStateColor = { checkboxColor: gray5, checkmarkColor: gray0 };
