import { useCallback, useState } from 'react';
import { ISubheaderHoursDto, IVirtualOfficeVisibilityState } from '../models';
import { ApiRoutes } from '../constants';
import { fetchReportingData } from '../../common/helpers';

export const useVirtualOfficeVisibilityState =
  (): IVirtualOfficeVisibilityState => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [virtualOfficeHours, setVirtualOfficeHours] =
      useState<ISubheaderHoursDto>();
    const [isTileError, setIsTileError] = useState<boolean>(false);

    const init = useCallback(async () => {
      setIsLoading(true);
      setIsTileError(false);

      try {
        const response = await fetchReportingData<ISubheaderHoursDto>({
          path: ApiRoutes.reports.fetchVirtualOfficeHours
        });
        setVirtualOfficeHours(response);
      } catch (error) {
        setIsTileError(true);

        console.error(
          'ActivTrak Error: Unable to load virtual hours visibility',
          error
        );
      } finally {
        setIsLoading(false);
      }
    }, []);

    return {
      isLoading,
      isTileError,
      virtualOfficeHours,
      init
    };
  };
