import React, { useState } from 'react';
import { ActionButton } from '../../styles/QuickActionsButton.styles';
import { AgentActivationModal } from '../../../dashboard/components/AgentActivationModal';
import { buttonTextColor } from '../../../common/constants';
import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';

type QuickActionsButtonProps = {
  id: string;
  name: string;
  icon: React.ReactNode;
  clickAction: string;
  clickParams?: object;
};

export const QuickActionsButton = (props: QuickActionsButtonProps) => {
  const { name, icon, clickAction, clickParams, id } = props;
  const [open, setOpen] = useState<boolean>(false);
  const history = useUIRouterHistory();

  const handleButtonClick = (clickAction, clickParams) => {
    if (clickAction === 'modalOpen') {
      setOpen(true);
    } else {
      history.push(clickAction, clickParams);
    }
  };

  return (
    <>
      <ActionButton
        sx={{
          px: 2,
          color: buttonTextColor
        }}
        startIcon={icon}
        onClick={() => handleButtonClick(clickAction, clickParams)}
        id={id}
      >
        {name}
      </ActionButton>
      <AgentActivationModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
