import { useEffect, useState } from 'react';

import { localStorageItems } from '../constants';
import { useParsedLocalStorage } from './';
import { useUIRouterParams } from './useUIRouterParams';

export const useGetUsername = () => {
  const [userName, setUserName] = useState<string>();

  const { accountId } = useUIRouterParams<any>();
  const child = useParsedLocalStorage(
    localStorageItems.childAccount + accountId
  ).storageItems;

  const globalsStorage = useParsedLocalStorage(
    localStorageItems.globalSettingsStorage
  ).storageItems;

  useEffect(() => {
    //try child account token
    if (child?.username) {
      return setUserName(child?.username);
    }

    //else get it from globals
    setUserName(globalsStorage?.currentUser?.username);
  }, [child?.username, globalsStorage?.currentUser?.username]);

  return userName;
};
