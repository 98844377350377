import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type ComponentProps = {
  open: boolean;
  closeModal: () => void;
  removeFavorite: () => void;
};

export const InvalidFavoriteModal = (props: ComponentProps) => {
  const { open, closeModal, removeFavorite } = props;

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Invalid Favorite</DialogTitle>
      <DialogContent>
        This favorite is no longer available. Would you like to remove it?
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>No</Button>
        <Button
          onClick={removeFavorite}
          autoFocus
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
