import List from '@mui/material/List';
import React from 'react';
import { presetGroups } from '../constants/dateRanges';
import { PresetGroupListProps, PresetLabel } from '../models/IDateRangePicker';
import {
  createDatesObject,
  getAllDateRangeList,
  getPresetDateRangeList
} from '../utils/dateRange.utils';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

export const PresetGroupList = (props: PresetGroupListProps) => {
  const {
    dateRange,
    onSubmitFilter,
    handleClose,
    ignoreRestrictions = false
  } = props;

  const handlePresetChange = (option: PresetLabel) => {
    onSubmitFilter({
      dates: createDatesObject(option.value),
      fromTimestamp: null
    });
    handleClose();
  };

  const presetDateRanges = ignoreRestrictions
    ? getAllDateRangeList()
    : getPresetDateRangeList();

  return (
    <List>
      {Object.values(presetGroups).map((group) => {
        return group.map((item: PresetLabel, itemIndex) => {
          if (presetDateRanges.find((preset) => preset.value === item.value)) {
            return (
              <React.Fragment
                key={`preset-date-range-${item.value}-${itemIndex}`}
              >
                {itemIndex === 0 && <Divider />}
                <ListItemButton
                  key={`preset-date-range-${item?.value
                    ?.toLowerCase()
                    .replaceAll('_', '-')}`}
                  id={`97443f5a-d9bd-43b7-a9c2-468267e843d3-${item?.value
                    ?.toLowerCase()
                    .replaceAll('_', '-')}`}
                  selected={dateRange === item.value}
                  onClick={() => handlePresetChange(item)}
                >
                  {item.label}
                </ListItemButton>
              </React.Fragment>
            );
          }
        });
      })}
    </List>
  );
};
