'use strict';

angular.module('app').directive('realtimeGridTab', RealtimeGridTab);

function RealtimeGridTab() {
    return {
        restrict: 'E',
        template: require('views/widgets/realtimeGridTab.html'),
        controller: RealtimeGridTabCtrl
    };
}

RealtimeGridTabCtrl.$inject = [
    '$scope',
    '$window',
    '$timeout',
    'realtimeService',
    'atHelperFunctions',
    'browserServiceFunctions',
    'gridPropertiesService',
    'realtimeScreenshotService',
    'localStorageService',
    'AccountSettingsService',
    'customUibModal'
];

function RealtimeGridTabCtrl(
    $scope,
    $window,
    $timeout,
    realtimeService,
    atHelperFunctions,
    browserServiceFunctions,
    gridPropertiesService,
    realtimeScreenshotService,
    localStorageService,
    AccountSettingsService,
    customUibModal
) {
    $scope.mainGridHeight = function () {
        return 320;
    };

    var gridServiceFunctions = require('_app/serviceFunctions/gridServiceFunctions')
        .gridServiceFunctions;

    /* START Highlight new rows */
    var newItemIds;

    function onDataBinding(arg) {
        // Find new rows - compare using time
        newItemIds = [];

        angular.forEach(arg.items, function (item) {
            if (item.hasUpdated) {
                newItemIds.push(item.uid);
            }
        });

        if ($scope.realTimeGrid) {
            gridServiceFunctions.bindGridSave(
                $scope.realTimeGrid,
                'realtime-grid'
            );
        }
    }

    function toggleScrollbar(e) {
        if (!$scope.showTiles) {
            var gridDataTable = e.sender && e.sender.table;
            var gridDataArea =
                gridDataTable && gridDataTable.closest('.k-grid-content');
            var gridVerticalScroll =
                e.sender && e.sender.element.find('.k-scrollbar-vertical');
            if (
                gridVerticalScroll &&
                gridDataTable &&
                gridDataTable[0] &&
                gridDataArea &&
                gridDataArea[0]
            ) {
                gridVerticalScroll.toggleClass(
                    'hide',
                    gridDataTable[0].offsetHeight < gridDataArea[0].offsetHeight
                );
            }
            if ($scope.realTimeGrid) {
                kendo.resize($scope.realTimeGrid);
            }
        }
    }

    $scope.setShowThumbnails = function (value) {
        $scope.showThumbnails = value;
        $scope.updateGridOptions();
        realtimeScreenshotService.reset();
        realtimeService.pause($scope.pollingPaused);
        localStorageService.set(
            'showRealtimeThumbnails-' + AccountSettingsService.username,
            $scope.showThumbnails
        );
    };

    $scope.setShowPassive = function (value) {
        $scope.showPassive = value;

        if (value) {
            $scope.realTimeGrid.dataSource.filter({});
        } else {
            $scope.realTimeGrid.dataSource.filter({
                field: 'isIdle',
                operator: 'eq',
                value: false
            });
        }
        realtimeScreenshotService.reset();
        realtimeService.pause($scope.pollingPaused);
    };

    $scope.updateGridOptions = function () {
        $scope.realTimeGrid.setOptions(createGridOptions());
    };

    function onDataBound(e) {
        if (!newItemIds) {
            return;
        }
        toggleScrollbar(e);

        var grid = $('.k-grid');

        angular.forEach(newItemIds, function (id) {
            grid.find('tr[data-uid="' + id + '"]').addClass('at-highlight');
        });

        gridPropertiesService.bindFilterAutoComplete(e, true);
    }
    /* END Highlight new rows */

    function createGridOptions() {
        return {
            autoBind: false,
            dataSource: realtimeService.dataSource,
            sortable: true,
            // resizable: true,
            reorderable: !(
                browserServiceFunctions.isMobileAgent() &&
                $window.innerWidth < 768
            ),
            dataBinding: onDataBinding,
            dataBound: onDataBound,
            columnReorder: function () {
                $timeout(function () {
                    gridPropertiesService.hideLeftBorder();
                });
            },
            filterable: {
                mode: 'row'
            },
            rowClass: $scope.showThumbnails
                ? ' realtime-row-with-thumbnails'
                : '',
            columns: gridServiceFunctions.loadSavedGridOptions(
                realtimeService.createColumns(
                    $scope.showThumbnails,
                    $scope.allowThumbnails
                ),
                'realtime-grid'
            ),
            scrollable: {
                virtual: true
            },
            height: atHelperFunctions.getGridHeight($scope.mainGridHeight())
        };
    }

    $scope.realTimeGridOptions = createGridOptions();

    $scope.$on('selectColumns', function () {
        var grid = $scope.realTimeGrid;

        customUibModal.open({
            animation: false,
            template: require('views/widgets/gridColumnSelectorModal.html'),
            controller: 'gridColumnSelectorModalCtrl',
            size: 'md',
            resolve: {
                grid: grid
            }
        });
    });

    $scope.$on('restoreColumns', function () {
        gridServiceFunctions.resetSavedGridOptions(
            $scope.realTimeGrid,
            'realtime-grid',
            createGridOptions
        );
    });
}
