import React from 'react';
import Typography from '@mui/material/Typography';
import { IBasicModalContentProps } from '../../models';
import { TextOverflow } from '../../../common/styles/app.component.styles';
import { ModalContentBox, ListItemBox } from '../../styles/templates.styles';
import Link from '../../../common/components/Link';

export const ModalContent = (props: IBasicModalContentProps) => {
  const { list, includeTMLinks } = props;

  return (
    <ModalContentBox>
      {list?.map((item) => (
        <ListItemBox key={`${item.value}-${item.name?.replace(/\s+/g, '')}`}>
          {includeTMLinks ? (
            <Link
              to={'app.dashboardCurrent.management'}
              params={{
                groupId: item.id,
                fromNavigation: true
              }}
            >
              <TextOverflow>{item.name}</TextOverflow>
            </Link>
          ) : (
            <TextOverflow>{item.name}</TextOverflow>
          )}

          <Typography
            sx={{
              color: `${item.valueColor ? item.valueColor : 'inherit'}`,
              pl: 1
            }}
          >
            {item.value}
          </Typography>
        </ListItemBox>
      ))}
    </ModalContentBox>
  );
};
