import { Role, RoleAccessKeys } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';
import { integrationsTemplate as angularJsTemplate } from '../templates';

export const appIntegrations: IRoute = {
  name: 'app.integrations',
  stateDefinition: {
    url: '/integrations/integrate',
    template: angularJsTemplate,
    controller: 'IntegrationsCtrl',
    data: { pageTitle: 'Integrate' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Integrations,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin, Role.User],
    read: []
  }
};

export default appIntegrations;
