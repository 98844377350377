export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_HOUR_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DATE_HOUR_MIN_FORMAT = 'MM/DD/YYYY HH:mm';
export const DATE_HOUR_LOCAL_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const TIME_FORMAT = 'h:mm a';
export const SHORT_DATE = `MM/DD`;
export const SHORT_REVERSED_DATE = 'DD/MM';
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE} ${TIME_FORMAT}`;
export const SHORT_REVERSED_DATE_TIME_FORMAT = `${SHORT_REVERSED_DATE} ${TIME_FORMAT}`;
export const API_DATE_FORMAT = 'YYYY-MM-DD';
