import theme from '../../../common/theme/theme';

const fontSize = '14px !important';

export const tmInfoTipClasses = {
    button: {
        margin: 0,
        marginTop: '3px',
        marginBottom: '3px',
        padding: '1px',
        minWidth: 0,
        color: 'black'
    },
    infoTipContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    item: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: '3px',
        paddingLeft: theme.spacing(1),
        fontSize,
        '& a': {
            color: 'inherit'
        }
    },
    subItem: {
        padding: '3px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5),
        fontSize
    },
    clickableItem: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer'
        }
    },
    none: {
        color: theme.palette.grey.A100
    },
    bold: {
        fontWeight: 600
    }
};
