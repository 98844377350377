import React from 'react';
import { Typography } from '@mui/material';
import { getClasses } from './InlineInfo.style';
import SkeletonLoader from '../../../SkeletonLoader/SkeletonLoader';

const InlineInfo = (props) => {
    const { heading, subheading, rootClass, headingClass, align = 'center', loading } = props;
    const classes = getClasses(align);

    return (
        <div style={{ ...rootClass, ...classes.root }}>
            <Typography sx={{ ...headingClass, ...classes.content }} align={align} noWrap={true}>
                <SkeletonLoader loading={loading} className={classes.skeleton} height="14px" width="40%">
                    {heading}
                    {subheading ? `   ${subheading}` : ''}
                </SkeletonLoader>
            </Typography>
        </div>
    );
};

export default InlineInfo;
