/**

  ######  #######    #     # ####### #######    ######  ####### ######  #       ###  #####     #    ####### #######
 #     # #     #    ##    # #     #    #       #     # #       #     # #        #  #     #   # #      #    #
 #     # #     #    # #   # #     #    #       #     # #       #     # #        #  #        #   #     #    #
 #     # #     #    #  #  # #     #    #       ######  #####   ######  #        #  #       #     #    #    #####
 #     # #     #    #   # # #     #    #       #   #   #       #       #        #  #       #######    #    #
 #     # #     #    #    ## #     #    #       #    #  #       #       #        #  #     # #     #    #    #
 ######  #######    #     # #######    #       #     # ####### #       ####### ###  #####  #     #    #    #######

 This is a 3-rd party plugin that has been temporarily adopted due to lack of maintenance from the author

 Please DO NOT use any of this code as base/sample for other developments

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { constants } from 'smooth-dnd';
const { wrapperClass } = constants;

export interface DraggableProps {
  render?: () => React.ReactElement;
  className?: string;
  children: React.ReactNode;
}

class Draggable extends Component<DraggableProps> {
  public static propsTypes = {
    render: PropTypes.func,
    className: PropTypes.string
  };

  render() {
    if (this.props.render) {
      return React.cloneElement(this.props.render(), {
        className: wrapperClass
      });
    }

    return (
      <div {...this.props} className={wrapperClass}>
        {this.props.children}
      </div>
    );
  }
}

export default Draggable;
