import { react2angular } from 'react2angular';
import { ApiKeyComponent } from '../../_reactivtrak/src/api-keys/views/ApiKey.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('integrationsApikeys', react2angular(ApiKeyComponent, []));

}
