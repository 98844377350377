import { react2angular } from '../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { ActivityDashboardViewComponent } from '../../../_reactivtrak/src/dashboard/views/ActivityDashboard.view.tsx';
import { dashboardTemplate } from '../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedActivityDashboard', {
    template: dashboardTemplate,
    controller: 'DashboardCtrl'
});
angular.module('app').component('activityDashboard', react2angular(ActivityDashboardViewComponent, []));

