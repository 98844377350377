import React from 'react';
import { TemplateShadow } from '../templates';
import ClassificationsWidget from './ClassificationsTable';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WidgetLink from '../../../common/components/WidgetLink';
import { useClassificationStores } from '../../../classifications/services';
import { usePendingClassificationWidgetStyles as classes } from '../../styles/PendingClassifications.styles';
import { useWidgetTemplateStyles as templateClasses } from '../../styles/templates.styles';

export default function PendingClassifications() {
  const { classificationStore } = useClassificationStores();
  const { activitiesState } = classificationStore;
  const { totalActivities } = activitiesState;

  return (
    <TemplateShadow sx={{ minHeight: '312px' }}>
      <Grid item xs={12} sx={templateClasses.spacedContentHeader}>
        <WidgetLink
          redirectTo={'app.settings.classification.tab'}
          redirectParams={{ activeTab: 'pending' }}
          text="Pending Classifications"
          id="a2bbbba8-bd3b-11ed-afa1-0242ac120002"
        />
        <Typography variant="body1" sx={classes.totalText}>
          {totalActivities > 0 && `Total Pending: ${totalActivities}`}
        </Typography>
      </Grid>
      <ClassificationsWidget />
    </TemplateShadow>
  );
}
