import {
  mapToAgentHealth,
  mapToAgentSettings,
  mapToComputerDetail,
  mapToComputers,
  mapDeviceV1ToComputers,
  mapDeviceV1ToScheduledActionDto
} from '../utils';
import {
  IComputersService,
  IComputer,
  IFetchAllComputers,
  IAllAgentHealth,
  IAgentLogSettings,
  IAgentSettings,
  IHealthCheck,
  IAgentCommand
} from '../models';
import { IActivTrakRequestConfig, HttpResponse } from '../../common/models';
import {
  getDefaultConfig,
  BaseClient,
  getWebApiConfig
} from '../../common/config';
import {
  gray3,
  notifError,
  notifSuccess,
  notifWarn
} from '../../common/constants';

export class ComputersService extends BaseClient implements IComputersService {
  protected webApiConfig: IActivTrakRequestConfig;
  protected softDeletesEnabled: boolean;

  protected _statusTypes = {
    healthy: 'Healthy',
    degraded: 'Degraded',
    failed: 'Failed'
  };

  protected _statusColors = {
    lightGreen: notifSuccess,
    red: notifError,
    grey: gray3,
    yellow: notifWarn
  };

  constructor(softDeletesEnabled: boolean) {
    super();
    this.softDeletesEnabled = softDeletesEnabled;
  }

  public fetchAllComputers = () => {
    if (this.softDeletesEnabled) {
      return this.apiService
        .get(`/admin/v1/devices?includeRestorable=True`, getWebApiConfig())
        .then((response: any) => {
          const newResponse: IFetchAllComputers = {
            computers: mapDeviceV1ToComputers(response.data)
          };
          return newResponse;
        });
    } else {
      return this.apiService
        .get(`/api/settings/storage/computer`, getDefaultConfig())
        .then((response: any) => {
          const { ustorage } = response.data;
          const newResponse: IFetchAllComputers = {
            computers: mapToComputers(ustorage)
          };
          return newResponse;
        });
    }
  };

  // TODO: Leave on FEAPI for now - transfer to agent services later
  public sendAgentCommand = async (
    computerId: number,
    command: string
  ): Promise<HttpResponse<unknown>> => {
    const response = await this.apiService.post(`api/agent/cmd/` + computerId, {
      ...getDefaultConfig(),
      ...{ data: command }
    });
    return response;
  };

  // TODO: transfer to agent admin endpoints later, leave on FEAPI for now
  public fetchComputerAgentCommands = (computerId: number) =>
    this.apiService
      .get(`/api/agent/cmd/` + computerId, getDefaultConfig())
      .then((agentCmdResponse: any) => {
        const responseAgentCommand: IAgentCommand[] = agentCmdResponse.data;
        return responseAgentCommand;
      });

  // TODO: transfer to agent admin endpoints later, leave on FEAPI for now
  public fetchComputerAgentHealth = () =>
    this.apiService
      .get(`/api/agent/health`, getDefaultConfig())
      .then((agentHealthResponse: any) => {
        const responseDataHealth: IAllAgentHealth = agentHealthResponse.data;
        const allAgentHealth: IAllAgentHealth = {
          computers: mapToAgentHealth(responseDataHealth.computers)
        };
        return allAgentHealth;
      });

  // TODO: transfer to agent admin endpoints later, leave on FEAPI for now
  public getAgentSettings = async (computerId): Promise<IAgentSettings> => {
    const response = await this.apiService.get(
      `/api/agent/logging/` + computerId,
      getDefaultConfig()
    );

    return mapToAgentSettings(response.data);
  };

  // TODO: transfer to agent admin endpoints later, leave on FEAPI for now
  public setAgentSettings = async (
    computerId,
    settings: IAgentLogSettings[]
  ): Promise<HttpResponse<unknown>> => {
    const response = await this.apiService.post(
      `/api/agent/logging/` + computerId,
      {
        ...getDefaultConfig(),
        ...{ data: settings }
      }
    );
    return response;
  };

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  public async fetchComputerDetail(computerId: number): Promise<any> {
    const response = await this.apiService.get(
      `/api/settings/storage/computerdetail/${computerId}`,
      getDefaultConfig()
    );
    return mapToComputerDetail(response.data);
  }

  public async deleteComputers(computerIds: number[]): Promise<void> {
    if (this.softDeletesEnabled) {
      await this.apiService.delete(`/admin/v1/devices`, {
        ...getWebApiConfig(),
        ...{ data: { DeviceIds: computerIds } }
      });
    } else {
      await this.apiService.put(`/api/settings/delete-multiple-computers`, {
        ...getDefaultConfig(),
        ...{ data: { ComputerIds: computerIds } }
      });
    }
  }

  public fetchScheduledDeletes = () => {
    return this.apiService
      .get(`/admin/v1/devices?includeRestorable=True`, getWebApiConfig())
      .then((response: any) => {
        return mapDeviceV1ToScheduledActionDto(response.data);
      });
  };

  public async deleteScheduledDelete(computerId: number): Promise<void> {
    await this.apiService.put(`/admin/v1/devices/restore`, {
      ...getWebApiConfig(),
      ...{ data: { DeviceIds: [computerId] } }
    });
  }

  public async deleteAllScheduledDeletes(): Promise<void> {
    await this.apiService.put(`/admin/v1/devices/restore`, getWebApiConfig());
  }

  public async uninstallComputers(computers: IComputer[]): Promise<void> {
    if (this.softDeletesEnabled) {
      await this.apiService.post(`/admin/v1/devices/uninstall`, {
        ...getWebApiConfig(),
        ...{ data: { DeviceIds: computers.map((c) => c.id) } }
      });
    } else {
      const payload = computers.map((computerItem) => {
        const {
          id: compid,
          windowsDomain: windowsdomain,
          alias,
          computer,
          agentVersion: agentversion
        } = computerItem;
        return { compid, windowsdomain, alias, computer, agentversion };
      });

      await this.apiService.post(`/api/settings/uninstall-computer`, {
        ...getDefaultConfig(),
        ...{ data: payload }
      });
    }
  }

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  downloadCSV(): Promise<HttpResponse<string>> {
    return new Promise((resolve, reject) => {
      this.apiService
        //long term we want to switch to web api, but not ready yet
        //.get('/computers/bulk', {
        //  ...this.webApiConfig,
        .get('/api/settings/storage/computer-report-csv', {
          ...getDefaultConfig(),
          responseType: 'blob'
        })
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  public getGlobalStatus = (status) => {
    const _statusTypes = {
      healthy: 'Healthy',
      degraded: 'Degraded',
      failed: 'Failed'
    };

    let globalStatus = _statusTypes.healthy;
    const systemStatus = status.users.find((user) => user.name === 'SYSTEM');
    globalStatus = this.getHealthCheckStatus(systemStatus?.healthChecks);
    return globalStatus;
  };

  public getHealthCheckStatus = (healthChecks: IHealthCheck[]) => {
    let overallStatus = this._statusTypes.healthy;
    healthChecks?.forEach((s) => {
      if (s.status === this._statusTypes.failed) {
        overallStatus = this._statusTypes.failed;
      } else if (
        s.status === this._statusTypes.degraded &&
        overallStatus !== this._statusTypes.failed
      ) {
        overallStatus = this._statusTypes.degraded;
      }
    });
    return overallStatus;
  };

  public getHealthStatusColor = (status) => {
    switch (status) {
      case 'Healthy':
        return this._statusColors.lightGreen;
      case 'Failed':
        return this._statusColors.red;
      case 'Degraded':
        return this._statusColors.yellow;
      default:
        return this._statusColors.grey;
    }
  };
}

export default ComputersService;
