import { react2angular } from 'react2angular';
import { ScreenshotsLatestReportViewComponent } from '../../../../_reactivtrak/src/reports/screenshots-report/views/ScreenshotsLatestReport.view';
import { screenshotsLatestTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedScreenshotsLatest', {
    template: screenshotsLatestTemplate,
    controller: 'ScreenshotsLatestCtrl'
});

angular.module('app').component('screenshotsLatest', react2angular(ScreenshotsLatestReportViewComponent, []));
