import React, { useEffect, useState } from 'react';
import CategoryDropdown from './CategoryDropdown';
import ProductivityDropdown from './ProductivityDropdown';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { ICategory, IClassificationActivity } from '../../common/models';
import { classificationClasses } from '../styles/Classifications.styles';
import { useNotifications } from '../../common/services/Notifications';
import {
  classifiedActivityItemsHeader,
  pendingActivityItemsHeader
} from '../constants';
import { useClassificationStores } from '../services';
import { SkeletonLoader } from '../../common/components/SkeletonLoader';

type ActivityItemsProps = {
  activity: IClassificationActivity;
  categories: ICategory[];
  isDisabled?: boolean;
};

export default function ActivityItems(props: ActivityItemsProps) {
  const { activity, categories, isDisabled } = props;

  const [currentActivity, setCurrentActivity] =
    useState<IClassificationActivity>(activity);
  const [productivity, setProductivity] = useState<number>(
    currentActivity?.productivity
  );
  const [categoryId, setCategoryId] = useState<string>(
    currentActivity?.categoryId
  );

  const { classificationStore } = useClassificationStores();
  const notificationService = useNotifications();

  const { updateActivities, setDisabledActivities } = classificationStore;

  const itemType =
    categoryId && productivity
      ? classifiedActivityItemsHeader
      : pendingActivityItemsHeader;

  //Disable reset if BOTH items require a selection
  const resetButtonDisabled = !categoryId && productivity === 0;

  //If an activity switches via navigation buttons, this handles updating the dropdown values
  useEffect(() => {
    setCurrentActivity(activity);
    setProductivity(activity?.productivity);
    setCategoryId(activity?.categoryId);
  }, [activity, setCurrentActivity]);

  const handleUpdateActivityChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    activity: IClassificationActivity,
    dropdownType: 'Category' | 'Productivity'
  ) => {
    const { value, name } = event.target;
    if (name) {
      const params = { [name]: value };
      //need to update both regular and local values
      //categoryId & localCategoryId
      //productivity & localProductivity
      if (name === 'localCategoryId') {
        params.categoryId = value;
      } else {
        params.productivity = value;
      }

      const categoryId = params.categoryId ?? activity.categoryId;
      params.categoryName =
        categories.find((c) => c.id == categoryId)?.name ?? '';

      setDisabledActivities([activity], true);
      const updatedActivity = [{ ...activity, ...params }];
      try {
        await updateActivities(updatedActivity);
        notificationService.success(`Success. ${dropdownType} updated.`);
      } catch (error) {
        //error notification already happens in global updateActivities, but we need to make sure the local values do not change
      }
    }
  };

  const onProductivityChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    try {
      await handleUpdateActivityChange(event, activity, 'Productivity');
      setProductivity(Number.parseInt(value));
    } catch (error) {
      //error notification already happens in global updateActivities, but we need to make sure the local values do not change
    }
  };

  const onCategoryChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    try {
      await handleUpdateActivityChange(event, activity, 'Category');
      setCategoryId(value);
    } catch (error) {
      //error notification already happens in global updateActivities, but we need to make sure the local values do not change
    }
  };

  const handleReset = async () => {
    //if item was originally auto-classified, use those values
    //else remove category and productivity
    const resetProductivity = activity?.defaultProductivity ?? 0;
    const resetCategoryId = activity?.defaultCategoryId;

    //need to update both regular and local values
    //categoryId & localCategoryId
    //productivity & localProductivity
    const resetActivity = [
      {
        ...activity,
        ...{
          localProductivity: 0,
          productivity: resetProductivity,
          localCategoryId: null,
          categoryName:
            categories.find((c) => c.id == resetCategoryId)?.name ?? '',
          categoryId: resetCategoryId
        }
      }
    ];
    try {
      setDisabledActivities([activity], true);
      await updateActivities(resetActivity);
      //At this point the activity is updated.
      //Change the state to reflect the reset changes.
      setProductivity(resetProductivity);
      setCategoryId(resetCategoryId);
    } catch (error) {
      //error notification already happens in global updateActivities, but we need to make sure the local values do not change
    }
  };

  return (
    <Card
      elevation={3}
      sx={classificationClasses.cardMarginTop}
      data-testid="activity-details-card"
    >
      <CardContent>
        <div
          style={{
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '23px',
            letterSpacing: '0.15px',
            textAlign: 'left'
          }}
        >
          {itemType}
        </div>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            {categories?.length && activity ? (
              <CategoryDropdown
                className={classificationClasses.extendedDropdown}
                categoryId={categoryId}
                categories={categories}
                variant={'outlined'}
                onChange={onCategoryChange}
                isDisabled={activity?.disabled || isDisabled}
              />
            ) : (
              <SkeletonLoader height={30} />
            )}
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            {activity ? (
              <ProductivityDropdown
                title={'Select Productivity Status'}
                className={classificationClasses.extendedDropdown}
                productivityStatus={productivity}
                variant={'outlined'}
                onChange={onProductivityChange}
                categoryId={categoryId}
                isDisabled={activity?.disabled || isDisabled}
              />
            ) : (
              <SkeletonLoader height={30} />
            )}
          </Grid>
          {activity && (
            <Grid item xs={12}>
              <Button
                data-testid={'reset-button'}
                disableRipple
                sx={classificationClasses.resetButton}
                disabled={resetButtonDisabled || isDisabled}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
