import React from 'react';
import { IDialogOrphanedAgentProps } from '../models/IDialogOrphanedAgentProps';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const DialogOrphanedAgent: React.FC<IDialogOrphanedAgentProps> = (
  verifyDeleteProps
) => {
  const { dialogOpen, closeDialog } = verifyDeleteProps;

  return (
    <Dialog
      maxWidth="lg"
      open={dialogOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        closeDialog();
      }}
    >
      <DialogTitle>This Agent is not associated with a user. </DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: '20px' }}>
          Please create a new user for this agent, or add the agent to an
          existing user.
        </Typography>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" autoFocus onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
