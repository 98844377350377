'use strict';

angular.module('app').directive('productivityIcon', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            productivity: '=productivity'
        },
        template: require('views/reports/productivity/productivityIcon.html'),
        controller: controller
    };
});

controller.$inject = [
    '$scope',
    'iconResolverServiceFunctions'
];

function controller(
    $scope,
    iconResolverServiceFunctions
) {
    function setIcon() {
        if (typeof $scope.productivity !== 'number') {
            return;
        }

        $scope.icon = iconResolverServiceFunctions.getProductivityIcon(
            $scope.productivity
        );
    }

    $scope.$watch('productivity', setIcon);
}
