import { useCallback, useEffect, useState } from 'react';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import {
  deleteIdentityData,
  fetchIdentityData,
  getParsedLocalStorageItem,
  patchIdentityData
} from '../../common/helpers';
import { IUserIdentityDto } from '../models/IUserIdentityDto';
import {
  mapToIdentity,
  mapToIdentityCreateDto
} from '../utils/IdentityMappers';
import {
  IUserAgent,
  IUserIdentity,
  IValueValidation,
  IValueValidationPrimary
} from '../models/IUserIdentity';
import { NotificationType } from '../../common/enums';
import {
  getMoveAgentArgs,
  validateValueChange
} from '../utils/DetailEmailEmployeeIdUtils';
import { IEmailUpdateValue } from '../models/IEmailUpdateValue';
import { localStorageItems } from '../../common/constants';
import { IFieldUpdateDto } from '../models/IFieldEditDto';
import {
  CreateDetailErrorMessage,
  SetUserIdentityErrorMessage
} from '../utils/ErrorMessages';
import { IAgentSearch } from '../models/IAgentSearch';
import { IIdentityCommonState } from '../models/IIdentityCommonState';

export type DetailStateProps = {
  commonState: IIdentityCommonState;
};

export const useIdentityDetailState = (
  props: DetailStateProps
): IIdentityDetailState => {
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [selectedIdentity, setSelectedIdentity] = useState<IUserIdentity>(null);
  const [allEmailsSaved, setAllEmailsSaved] = useState<boolean>(true);
  const [detailNotification, setDetailNotification] =
    useState<NotificationType>(undefined);
  const [neverShowConfirmationDialog, setNeverShowConfirmationDialog] =
    useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<IValueValidation>(undefined);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const { commonState } = props;

  const getUserDetail = useCallback(async (detailId): Promise<void> => {
    setIsLoadingDetail(true);
    try {
      const data = await fetchIdentityData<IUserIdentityDto>({
        path: `/identity/v1/entities/${detailId}`
      });

      const tempSelected = mapToIdentity(data);
      setSelectedIdentity(tempSelected);
    } catch (error) {
      setDetailNotification({
        msg: 'Unable to load Identity detail',
        type: 'error'
      });
      console.error('ActivTrak Error: Unable to load Identity detail', error);
    } finally {
      setIsLoadingDetail(false);
    }
    //todo: add error message
  }, []);

  useEffect(() => {
    fetchNeverShowSetting();

    //allow a single email to be added at a time
    if (selectedIdentity?.emails) {
      for (const emailId of selectedIdentity.emails) {
        if (!emailId.originalValue) {
          setAllEmailsSaved(false);
          return;
        }
      }
    }
    setAllEmailsSaved(true);
  }, [selectedIdentity]);

  const onEmailValueChange = async (event: any, dataItem: IValueValidation) => {
    if (dataItem.value == event.target.value) {
      return; //no changes
    }
    //cache values to see if something changed
    const { validationFailed, failedExplanation } = dataItem;
    validateValueChange(dataItem, event.target.value, selectedIdentity);

    const passedValidation = !dataItem.validationFailed;
    //if no error message, don't show red warning
    if (dataItem.validationFailed && dataItem.failedExplanation == ' ') {
      dataItem.validationFailed = false;
    }
    dataItem.value = event.target.value;
    const changesMade =
      validationFailed != dataItem.validationFailed ||
      failedExplanation != dataItem.failedExplanation;
    if (!passedValidation) {
      if (changesMade) {
        setTimeout(rebindIdentityUser, 50);
      }
      return;
    }
    const newValue: IEmailUpdateValue = {
      value: event.target.value,
      id: dataItem.originalValue
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [newIdentity, error] = await emailValueUpdateOrDelete(
      newValue,
      selectedIdentity,
      false
    );
    if (error) {
      rebindIdentityUser(); //show any error messages in email objects
    }
  };

  const handleKeyPressEmail = (event, dataItem: IValueValidation) => {
    if (event.key === 'Enter') {
      onEmailValueChange(event, dataItem);
    }
  };

  const fetchNeverShowSetting = () => {
    const neverShowDelete: boolean = getParsedLocalStorageItem(
      localStorageItems.deleteUserEmailModal
    );
    if (neverShowDelete && typeof neverShowDelete == 'boolean') {
      setNeverShowConfirmationDialog(neverShowDelete);
    }
  };

  const emailValueUpdateOrDelete = async (
    updatedValue: IEmailUpdateValue,
    userIdentity: IUserIdentity,
    deleteIt: boolean
  ): Promise<[IUserIdentity, any]> => {
    const editDto: IFieldUpdateDto = {
      firstName: null,
      middleName: null,
      lastName: null,
      displayName: null,
      email: updatedValue,
      employeeId: null
    };
    const [newIdentity, error] = deleteIt
      ? await onDeleteUserEmail(updatedValue?.value, userIdentity)
      : await onSaveUserDetail(editDto, userIdentity, null);
    return [newIdentity, error];
  };

  const patchIdentityDataPathArgs = async (
    entityIdToReturn: number,
    path: string,
    args?: object
  ): Promise<[any, IUserIdentity]> => {
    try {
      const newIdentityDto = await patchIdentityData<IUserIdentityDto>({
        path: path,
        args: args
      });

      const tempSelected = mapToIdentity(newIdentityDto);
      if (tempSelected?.id == entityIdToReturn) {
        setSelectedIdentity(tempSelected);
      } else {
        getUserDetail(entityIdToReturn);
      }
      return [null, tempSelected];
    } catch (error) {
      return [error, null];
    }
  };

  const onAddNewAgent = async (selectedAgents: IAgentSearch[]) => {
    if (selectedAgents && selectedAgents.length > 0) {
      const agentToMove = selectedAgents[0];
      const moveAgentArgs = getMoveAgentArgs(
        agentToMove.userId,
        selectedIdentity,
        selectedIdentity.tracked
      );
      const path = `/identity/v1/entities/${agentToMove.entityId}/revision/${agentToMove.revision}`;
      const [error] = await patchIdentityDataPathArgs(
        selectedIdentity.id,
        path,
        moveAgentArgs
      );
      if (!error) {
        setDetailNotification({
          msg:
            'Success!  The agent ' +
            agentToMove.singleDescription +
            ' has been added to ' +
            selectedIdentity.singleDescription,
          type: 'success'
        });
        return null; //selected Identity was set
      } else {
        const message = CreateDetailErrorMessage(error);
        setDetailNotification({ msg: message, type: 'error' });
        console.error(message, error);
      }
    }
  };

  const onSaveUserDetail = async (
    details: IFieldUpdateDto,
    userIdentity: IUserIdentity,
    agentSelected: IAgentSearch
  ): Promise<[IUserIdentity, any]> => {
    let args = { ...details };
    if (agentSelected) {
      args = mapToIdentityCreateDto(details, agentSelected);
    }
    const [error, newIdentity] = await patchIdentityDataPathArgs(
      userIdentity.id,
      `/identity/v1/entities/${userIdentity.id}/revision/${userIdentity.revision}`,
      args
    );
    if (!error) {
      setDetailNotification({ msg: 'Saved', type: 'success' });
      return [newIdentity, null]; //selected Identity was set
    } else {
      const message = CreateDetailErrorMessage(error);
      if (SetUserIdentityErrorMessage(message, userIdentity, details)) {
        return [userIdentity, error];
      } else {
        setDetailNotification({ msg: message, type: 'error' });
        console.error(message, error);
        return [userIdentity, error];
      }
    }
  };

  const moveAgentToUser = async (
    selectedAgent: IUserAgent,
    destination: IUserIdentity,
    source: IUserIdentity,
    showSnackBar: boolean
  ) => {
    if (selectedAgent) {
      const moveAgentArgs = getMoveAgentArgs(
        selectedAgent.userId,
        destination,
        destination.tracked
      );
      const path = `/identity/v1/entities/${source.id}/revision/${source.revision}`;
      const [error] = await patchIdentityDataPathArgs(
        source.id,
        path,
        moveAgentArgs
      );
      if (!error) {
        if (showSnackBar) {
          commonState.showSnackBarWithResults(
            destination,
            selectedAgent.singleDescription,
            false
          );
        } else {
          setDetailNotification({ msg: 'Saved', type: 'success' });
        }
        return null; //selected Identity was set
      } else {
        const message = CreateDetailErrorMessage(error);
        setDetailNotification({ msg: message, type: 'error' });
        console.error(message, error);
      }
    }
  };

  const onAddSelectedIdentityEmail = () => {
    if (!selectedIdentity.emails) {
      selectedIdentity.emails = [];
    }
    selectedIdentity.emails.push({
      value: '',
      originalValue: null,
      validationFailed: false,
      failedExplanation: ' ',
      primary: false
    });
    rebindIdentityUser();
  };

  const deleteLocalEmailId = (dataItem: IValueValidationPrimary) => {
    const index = selectedIdentity.emails.indexOf(dataItem, 0);
    if (index > -1) {
      selectedIdentity.emails.splice(index, 1);
      rebindIdentityUser();
    }
  };

  const deleteRemoteEmailId = async (dataItem: IValueValidation) => {
    const itemToDelete: IEmailUpdateValue = {
      value: dataItem.value,
      id: dataItem.value
    };
    return await onDeleteUserEmail(itemToDelete?.value, selectedIdentity);
  };

  const onEmailDelete = async (event, dataItem: IValueValidationPrimary) => {
    if (!dataItem.originalValue) {
      //this item hasn't been persisted, just remove from the ui
      deleteLocalEmailId(dataItem);
      return;
    }

    if (!neverShowConfirmationDialog) {
      setItemToDelete(dataItem);
      setShowConfirmationDialog(true);
    } else {
      onDeleteWithConfirmation(dataItem, false);
    }
  };

  const onCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const onEmailMakePrimary = async (
    userIdentity: IUserIdentity,
    email: string
  ) => {
    try {
      const url = `/identity/v1/entities/${userIdentity?.id}/revision/${userIdentity?.revision}`;
      const newIdentityDto = await patchIdentityData<IUserIdentityDto>({
        path: url,
        args: { Email: { id: email, primary: true } }
      });
      setDetailNotification({
        msg: 'Primary email updated',
        type: 'success'
      });

      const newIdentity = mapToIdentity(newIdentityDto);
      if (newIdentity) {
        setSelectedIdentity(newIdentity);
      }
    } catch (ex) {
      console.error(`ActivTrak Error: ${ex}`);
      setDetailNotification({
        msg: 'Unable to update primary email',
        type: 'error'
      });
    }
  };

  const onDeleteWithConfirmation = async (
    dataItem: IValueValidation,
    neverShowAgain: boolean
  ) => {
    setShowConfirmationDialog(false);
    setNeverShowConfirmationDialog(neverShowAgain);
    await deleteRemoteEmailId(dataItem);
  };

  const rebindIdentityUser = () => {
    const newIdentity = { ...selectedIdentity };
    setSelectedIdentity(newIdentity);
  };

  const onDeleteUserEmail = async (
    email: string,
    userIdentity: IUserIdentity
  ): Promise<[IUserIdentity, any]> => {
    if (!email) {
      return;
    }
    try {
      const urlencodedEmail = encodeURIComponent(email);
      const url = `/identity/v1/entities/${userIdentity?.id}/emails/${urlencodedEmail}?revision=${userIdentity?.revision}`;
      const data = await deleteIdentityData<IUserIdentityDto>({
        path: url,
        args: null
      });

      const tempSelected = mapToIdentity(data);
      setSelectedIdentity(tempSelected);
      setDetailNotification({ msg: 'Deleted', type: 'success' });
      return [tempSelected, null];
    } catch (error) {
      setDetailNotification({ msg: 'Unable to delete', type: 'error' });
      console.error('ActivTrak Error: Unable to delete', error);
      return [null, error];
    }
  };

  return {
    isLoadingDetail,
    getUserDetail,
    selectedIdentity,
    allEmailsSaved,
    handleKeyPressEmail,
    onAddSelectedIdentityEmail,
    onEmailDelete,
    onEmailValueChange,
    onDeleteUserEmail,
    onDeleteWithConfirmation,
    onEmailMakePrimary,
    onSaveUserDetail,
    itemToDelete,
    showConfirmationDialog,
    onCloseConfirmationDialog,
    detailNotification,
    setDetailNotification,
    onAddNewAgent,
    moveAgentToUser,
    commonState
  };
};
