import React, { createContext, useContext } from 'react';
import AlarmsService, { IAlarmsService } from './AlarmsService';
const AlarmsContext = createContext<IAlarmsProvider>(null);

interface IAlarmsProvider {
  alarmsService: IAlarmsService;
}

type Props = {
  children: React.ReactNode;
  alarmsService?: IAlarmsService;
};

export const AlarmsProvider = (props: Props) => {
  const { children, alarmsService } = props;
  const value = {
    alarmsService: alarmsService || new AlarmsService()
  };

  return (
    <AlarmsContext.Provider value={value}>{children}</AlarmsContext.Provider>
  );
};

export const useAlarms = (): IAlarmsProvider => {
  return useContext(AlarmsContext);
};
