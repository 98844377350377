export const skeletonCards = Array.from(Array(18), (_value, index) => {
  return {
    accountId: index.toString(),
    companyName: '',
    userCount: 0,
    alarmCount: 0,
    notReportedCount: 0,
    auditAlarmCount: 0,
    ownerEmail: ''
  };
});
