import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const LatestUpdatesIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'LatestUpdates';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M23.723 11.2244L21.6305 8.73309L21.923 5.43093C21.9792 4.84336 21.5968 4.30279 21.0568 4.17352L17.9518 3.43318L16.3319 0.577576C16.0394 0.0722629 15.4431 -0.139264 14.9256 0.0957657L12.0007 1.42368L9.087 0.119269C8.56951 -0.115761 7.96203 0.0957653 7.68078 0.601078L6.04957 3.44493L2.95588 4.17352C2.40464 4.30279 2.02215 4.83161 2.0784 5.43093L2.37089 8.72134L0.278432 11.2244C-0.0928106 11.671 -0.0928106 12.329 0.278432 12.7756L2.37089 15.2669L2.0784 18.5808C2.02215 19.1684 2.40464 19.709 2.94463 19.8382L6.04957 20.5786L7.66953 23.4224C7.96203 23.9277 8.55827 24.1393 9.087 23.9042L12.0007 22.5763L14.9144 23.8807C15.4319 24.1158 16.0394 23.9042 16.3206 23.3989L17.9406 20.5433L21.0455 19.803C21.5968 19.6737 21.968 19.1331 21.9117 18.5456L21.6193 15.2434L23.7117 12.7521C24.0942 12.329 24.0942 11.671 23.723 11.2244ZM13.1257 17.8757H10.8757V15.5254H13.1257V17.8757ZM12.0007 13.1751C11.382 13.1751 10.8757 12.6463 10.8757 12V7.29941C10.8757 6.65308 11.382 6.12427 12.0007 6.12427C12.6194 6.12427 13.1257 6.65308 13.1257 7.29941V12C13.1257 12.6463 12.6194 13.1751 12.0007 13.1751Z"
      />
    </SvgIconWrapper>
  );
};
