import React from 'react';
import { Link as MUILink } from '@mui/material';
import { useUIRouterHistory } from '../../hooks/useUIRouterHistory';

/**
 * Primary UI component for linking
 */

const Link: React.FC<any> = (props) => {
  const { onClick: onClk, to, params } = props;
  const history = useUIRouterHistory();

  const onClick = () => {
    if (onClk) onClk();
    history.push(to, params);
  };

  return <MUILink sx={{ cursor: 'pointer' }} {...props} onClick={onClick} />;
};

export default Link;
