import React from 'react';
import { AiFillChrome, AiFillApple, AiFillWindows } from 'react-icons/ai';
import { OperationalSystemsType } from '../../enums';

export const getOSIcon = (os) => {
  if (OperationalSystemsType.Apple === os) {
    return <AiFillApple />;
  } else if (OperationalSystemsType.Chrome === os) {
    return <AiFillChrome />;
  } else {
    return <AiFillWindows />;
  }
};
