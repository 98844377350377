import React from 'react';
import Grid from '@mui/material/Grid';

import {
  EmailDetailHeader,
  ButtonDelete,
  EmailOrIdBox,
  BoxAddEmail,
  EmailPrimaryHeaderBox
} from '../styles/UserDetail.view.styles';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { SkeletonLoader } from '../../common/components/SkeletonLoader/SkeletonLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { gray16 } from '../../common/constants';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { DialogVerifyDeleteEmailId } from './DialogVerifyDeleteEmailId';

type ComponentProps = {
  identityState: IIdentityCommonState;
  identityDetailState: IIdentityDetailState;
  isReadOnly: boolean;
};

export const DetailEmails = (props: ComponentProps) => {
  const { isReadOnly, identityDetailState } = props;
  const {
    selectedIdentity,
    allEmailsSaved,
    showConfirmationDialog,
    itemToDelete,
    onCloseConfirmationDialog,
    handleKeyPressEmail,
    onAddSelectedIdentityEmail,
    onEmailDelete,
    onEmailValueChange,
    onDeleteWithConfirmation,
    onEmailMakePrimary
  } = identityDetailState;

  const { isLoadingDetail } = props.identityDetailState;

  return (
    <>
      <EmailDetailHeader>Emails associated with User</EmailDetailHeader>
      <Grid container spacing={1}>
        {isLoadingDetail && (
          <Grid item xs={12}>
            <SkeletonLoader height={90} />
          </Grid>
        )}
        {!isLoadingDetail &&
          selectedIdentity?.emails?.map((dataItem, index) => (
            <Grid item xs={12} key={'email-id-grid-' + index + dataItem.value}>
              {index === 0 && dataItem.primary && (
                <EmailPrimaryHeaderBox>Primary ID</EmailPrimaryHeaderBox>
              )}
              {index === 1 &&
                selectedIdentity.emails.length > 0 &&
                selectedIdentity.emails[0].primary && (
                  <EmailPrimaryHeaderBox>
                    Additional Emails
                  </EmailPrimaryHeaderBox>
                )}
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <EmailOrIdBox>
                    <TextField
                      fullWidth
                      defaultValue={dataItem?.value || ''}
                      label={'Email'}
                      inputProps={{ maxLength: 255 }}
                      onClick={(e) => e.stopPropagation()}
                      error={dataItem?.validationFailed}
                      helperText={dataItem?.failedExplanation}
                      onBlur={(e) => onEmailValueChange(e, dataItem)}
                      onKeyPressCapture={(e) =>
                        handleKeyPressEmail(e, dataItem)
                      }
                      disabled={isReadOnly}
                    />
                  </EmailOrIdBox>
                </Grid>
                <Grid item xs={1}>
                  {!dataItem.primary && (
                    <ButtonDelete
                      disabled={isReadOnly}
                      onClick={(e) => onEmailDelete(e, dataItem)}
                    >
                      <DeleteIcon width="20px" sx={{ color: gray16 }} />
                    </ButtonDelete>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {!dataItem.primary && dataItem.originalValue && (
                    <Button
                      onClick={() =>
                        onEmailMakePrimary(
                          selectedIdentity,
                          dataItem?.value || ''
                        )
                      }
                      color="secondary"
                      sx={{ marginTop: '5px' }}
                      disabled={isReadOnly}
                    >
                      Make primary
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        {!isLoadingDetail && (
          <Grid item xs={12}>
            <BoxAddEmail>
              <Button
                onClick={onAddSelectedIdentityEmail}
                color="secondary"
                disabled={isReadOnly || !allEmailsSaved}
                startIcon={<AddIcon />}
              >
                ADD NEW EMAIL
              </Button>
            </BoxAddEmail>
          </Grid>
        )}
      </Grid>
      <DialogVerifyDeleteEmailId
        onDelete={onDeleteWithConfirmation}
        dialogOpen={showConfirmationDialog}
        closeDialog={onCloseConfirmationDialog}
        itemToDelete={itemToDelete}
      />
    </>
  );
};
