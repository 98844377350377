import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormErrorProps, ITicketPayload } from '../models';
import { LinearWithLabel } from '../../common/components/Progress/LinearWithLabel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField
} from '@mui/material';
import { Modal } from '../enums';
import { UploadAttachments } from './UploadAttachments';

type CreateTicketModalProps = {
  isOpen: boolean;
  percentUploaded: number;
  newTicket: ITicketPayload;
  setNewTicket: Dispatch<SetStateAction<ITicketPayload>>;
  onOpenModal: (modal: Modal) => void;
  onClose: () => void;
  onSubmit: () => void;
};

export const CreateTicketModal = (props: CreateTicketModalProps) => {
  const {
    onSubmit,
    onClose,
    isOpen,
    percentUploaded,
    newTicket,
    setNewTicket,
    onOpenModal
  } = props;

  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<FormErrorProps>({
    subject: null,
    body: null
  });

  //listen to attachment changes and update newTicket
  useEffect(() => {
    setNewTicket((prevState) => ({
      ...prevState,
      attachments: currentAttachments[0]
    }));
  }, [currentAttachments, setNewTicket]);

  //show the progress bar while saving the ticket and close modal when complete
  useEffect(() => {
    if (percentUploaded === 100) onClose();
  }, [onClose, percentUploaded]);

  const additionalInstructions = useMemo(() => {
    return (
      <Typography sx={{ marginTop: '10px' }}>
        <em>
          Only 1 file with a maximum size of 7MB can be uploaded per ticket
        </em>
      </Typography>
    );
  }, []);

  const checkFormStatus = useCallback(() => {
    if (newTicket.subject && newTicket.body) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [newTicket.subject, newTicket.body]);

  useEffect(() => {
    checkFormStatus();
  }, [checkFormStatus]);

  const handleChange = (event) => {
    const name = event.target?.name;
    const value = event.target?.value;

    if (value === '') {
      setErrors((prevState) => ({
        ...prevState,
        ...{ [name]: 'This field is required' }
      }));
      setNewTicket((prevState) => ({
        ...prevState,
        ...{ [name]: '' }
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        ...{ [name]: null }
      }));
      setNewTicket((prevState) => ({
        ...prevState,
        ...{ [name]: value }
      }));
    }
  };

  const handleSubmit = () => {
    setIsSaveDisabled(true);
    onSubmit();
  };

  const handleCancel = () => {
    //if no required changes are made, close the modal
    if (!newTicket.subject && !newTicket.body) return onClose();

    //open the confirmation modal
    onOpenModal(Modal.ConfirmCancelTicket);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleCancel();
      }}
      aria-labelledby="create-ticket-title"
      aria-describedby="create-ticket-description"
    >
      <DialogTitle id="create-ticket-title">Create a ticket</DialogTitle>
      <DialogContent id="create-ticket-description">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="subject"
              label="Subject"
              value={newTicket.subject}
              onChange={(e) => handleChange(e)}
              error={Boolean(errors.subject)}
              helperText={errors.subject}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '15px' }}>
              Please enter the details of your request. A member of our support
              staff will respond as soon as possible.
            </Typography>
            <TextField
              name="body"
              label="Description"
              value={newTicket.body}
              multiline
              maxRows={4}
              onChange={(e) => handleChange(e)}
              error={Boolean(errors.body)}
              helperText={errors.body}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <UploadAttachments
              onAttach={setCurrentAttachments}
              additionalInstructions={additionalInstructions}
            />
          </Grid>
          {percentUploaded > 0 && (
            <Grid item xs={12}>
              <LinearWithLabel value={percentUploaded} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSaveDisabled}
          variant="contained"
          color="primary"
        >
          Create ticket
        </Button>
      </DialogActions>
    </Dialog>
  );
};
