/* TODO: fix the static element accesibility warnings... */
// eslint-disable jsx-a11y/no-static-element-interactions
// eslint-disable jsx-a11y/anchor-is-valid */

import React, { useMemo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { favoritesService } from '../../services/Navigation/FavoritesService';

import {
  FavoritesIcon,
  AlarmsIcon,
  DashboardIcon,
  InsightsIcon,
  LiveReportsIcon,
  IntegrationsIcon,
  SettingsIcon,
  LatestUpdatesIcon,
  HelpIcon,
  LogoutIcon,
  TeamPulseIcon,
  ImpactIcon,
  CoachIcon,
  PlaygroundIcon
} from '../../assets/Icons';

import { useFavoritesState, useNavigationState } from './useSideBarState';

import activtrakLogo from '../../../../../app/images/at-logo-drk-theme.svg';

import {
  useRouteStore,
  useSideBarPinnedStore,
  isExcludedPage,
  findItemById,
  findItemByName
} from './navigationStore';
import { toggleFavorite, useFavoritesStore } from './favoritesStore';

import { InvalidFavoriteModal } from './InvalidFavoriteModal';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { setCoachingNotificationLastSeenWeek } from '../../services/Navigation/Coaching';

import {
  MenuItemLabel,
  StarHollow,
  StarFilled,
  SlidingList,
  ArrowUp,
  ArrowDown,
  DeleteFilled,
  sidebarClasses
} from './SideBar.styles';
import { gray19 } from '../../constants';

const SideBar = (props: any) => {
  const [invalidFavoriteItem, setInvalidFavoriteItem] = useState(null);
  const [showInvalidFavoriteModal, setShowInvalidFavoriteModal] =
    useState(false);

  const isFavoriteNavigationEnabled = useFavoritesStore(
    (s) => s.isFavoriteNavigationEnabled
  );

  const { navigate, canNavigate } = props;

  const handleStarToggle = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item: any
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const found = findItemById(item.id);
    toggleFavorite(found, 'navigation');
  };

  const handleRemoveInvalidFavorite = () => {
    setShowInvalidFavoriteModal(false);
    if (invalidFavoriteItem !== null) {
      toggleFavorite(invalidFavoriteItem, 'invalid');
    }
  };

  //TODO: This could be defined in a shared module
  const hideOnLargeScreenStyle: SxProps = {
    display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' }
  };

  const FavoritesStar = (props) => {
    const { favorited } = props;

    return favorited ? (
      <StarFilled
        className="pull-right icon-star"
        fontSize="small"
        onClick={(e) => props.onClick(e)}
      />
    ) : (
      <StarHollow
        className="pull-right icon-star"
        fontSize="small"
        onClick={(e) => props.onClick(e)}
      />
    );
  };

  const BetaIcon = (item) => {
    return item?.isBeta ? (
      <div
        className="at-menu-item-beta"
        style={{
          ['marginTop']: '2px',
          ['marginLeft' as any]: `${item.level === 0 ? '-2px' : '2px'}`
        }}
      >
        <div className="label-content">BETA</div>
      </div>
    ) : (
      <></>
    );
  };

  const ExpandIcon = (props) => {
    const { subMenuOpen } = props;

    return subMenuOpen ? (
      <ArrowUp fontSize="medium"></ArrowUp>
    ) : (
      <ArrowDown fontSize="medium"></ArrowDown>
    );
  };

  const IconResolver = (props: any) => {
    const { icon } = props;

    if (!icon || Object.keys(icon).length === 0) {
      return <></>;
    }

    const iconsMap = {
      dashboard: DashboardIcon,
      notifications: AlarmsIcon,
      'icon-at-coaching': CoachIcon,
      'icon-at-analytics': InsightsIcon,
      'icon-at-live-reports': LiveReportsIcon,
      'icon-at-integrations': IntegrationsIcon,
      settings: SettingsIcon,
      new_releases: LatestUpdatesIcon,
      help: HelpIcon,
      'icon-at-logout': LogoutIcon,
      view_day: TeamPulseIcon,
      compare: ImpactIcon,
      labs: PlaygroundIcon
    };

    const ResolvedIcon = iconsMap[icon.value];

    return (
      (
        <ResolvedIcon
          style={sidebarClasses.navigationItemIcon}
          height={24}
          width={24}
          fillColor={gray19}
        />
      ) || <></>
    );
  };

  const NavigationItem = (props) => {
    const { item, index } = props;

    const [subMenuOpen, setSubMenuOpen] = useState(item?.submenuOpen ?? false);
    const [isActive, setIsActive] = useState(false);
    const [showStar, setShowStar] = useState(false);

    const route = useRouteStore((s) => s.route);

    const isInFavorites = useFavoritesStore((s) => s.isInFavorites);
    const favorites = useFavoritesStore((s) => s.favorites);

    useEffect(() => {
      const shouldShowStar = isInFavorites({ id: item.id });
      setShowStar(() => shouldShowStar);
    }, [isInFavorites, item.id, favorites]);

    useEffect(() => {
      setIsActive(() => {
        const active = route.name === item.action?.options?.ref;
        //If coach item click, set coach notification to read if exists
        if (
          active &&
          route.name === 'app.coaching' &&
          item.hasCoachingNotifications
        ) {
          item.hasCoachingNotifications = false;
          setCoachingNotificationLastSeenWeek();
        }
        return active;
      });
    }, [route.name, item]);

    const handleNavigate = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      item: any
    ): void => {
      e.preventDefault();
      navigate(item);
    };

    const handleToggle = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      item: any
    ): void => {
      e.preventDefault();

      setSubMenuOpen(() => {
        return !subMenuOpen;
      });

      setIsActive(() => {
        const active = route.name === item.action?.options?.ref;
        return active;
      });
    };

    return (
      <li id={`id_${item.id}`} key={index}>
        <div className="at-menu-item-container">
          <div
            className={`at-menu-item-row ${
              subMenuOpen ? 'expanded' : 'collapsed'

              // TODO: check activeSubmenu - does not seem to be working
            } ${item.activeSubmenu ? 'active-submenu' : ''}`}
          >
            <a
              className={`at-flex-container at-menu-item at-pointer ${
                isActive ? 'focus' : ''
              }`}
              onClick={(e) => {
                const navigate = ['internal', 'external', 'event'];
                navigate.includes(item.action.type)
                  ? handleNavigate(e, item)
                  : handleToggle(e, item);
              }}
            >
              <div className="at-flex-child at-menu-item-icon">
                {/* {item.icon.type === 'material' && (
                  <i className="material-icons">{item.icon.value}</i>
                )}
                {
                  //TODO: Custom icons won't fit the material-icons model and the CSS loading issue persists
                  // Need to figure out a better way to handle this.
                  item.icon.type !== 'material' && (
                    <i className={item.icon.value}></i>
                  )
                } */}
                {<IconResolver icon={item.icon} />}

                {item.hasCoachingNotifications && (
                  <div className="at-menu-item-coaching-notification"></div>
                )}

                <BetaIcon {...item} />
              </div>
              <MenuItemLabel level={item.level} className="at-flex-child">
                {item.label}
                {!item.submenus &&
                  !isExcludedPage(item.label) &&
                  isFavoriteNavigationEnabled && (
                    <FavoritesStar
                      id={`nav_star_${item.id}`}
                      favorited={showStar}
                      onClick={(e) => handleStarToggle(e, item)}
                    />
                  )}
              </MenuItemLabel>

              {item.submenus && (
                <div className="at-flex-child at-menu-item-dropdown-control at-menu-item-icon">
                  <ExpandIcon subMenuOpen={subMenuOpen} />
                </div>
              )}
            </a>
          </div>

          {/* submenu */}
          {item.submenus && subMenuOpen && (
            <ul className="at-submenu-items">
              {item.submenus.map((subitem, subindex) => {
                return (
                  <NavigationItem
                    key={subindex}
                    item={subitem}
                    index={subindex}
                  />
                );
              })}
            </ul>
          )}
        </div>
      </li>
    );
  };

  const HomeComponent = (props) => {
    const { home } = props;
    if (home) {
      return <NavigationItem item={home} index={0} />;
    }
    return <></>;
  };

  const FavoritesComponent = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [mappedFavorites, setMappedFavorites] = useState([]);
    const service = useMemo(() => {
      return favoritesService();
    }, []);

    const {
      favorites,
      setFavorites,
      findItemById,
      favoritesMenuOpen,
      setFavoritesMenuOpen
    } = useFavoritesState();

    const resolveFavorite = (favorite:any) => {
      const found = findItemById(favorite.id) ?? findItemByName(favorite.name);

      if (found === undefined) {
        return { invalid:true, ...favorite };
      }

      return found;
    }

    useEffect(() => {
      const getFavorites = async () => {
        try {
          const result = await service.getFavorites();
          setFavorites(result);
        } catch (error) {
          console.error(error);
          setFavorites([]);
        } finally {
          setIsLoaded(true);
        }
      };
      getFavorites();
    }, [service, setFavorites]);

    useEffect(() => {
      const mapped = favorites
        .map((f) => resolveFavorite(f))
        .filter((f) => !!f);

      setMappedFavorites(mapped);
    }, [favorites, findItemById]);

    const isExternal = (item: any) => item?.action?.type === 'external';

    const handleFavoriteNavigate = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      mappedFavorite: any
    ): void => {
      e.preventDefault();
      const favoriteName = mappedFavorite?.action?.options?.ref;

      if (favoriteName === 'app.downloads') {
        navigate({
          action: {
            options: {
              ref: 'app.downloads'
            }
          }
        });
        return;
      }

      const item = mappedFavorite; //findItemById(favorite.id);

      const found = favorites.find(
        (f) => f.id === mappedFavorite.id || f.name === favoriteName
      );

      if (!isExternal(item) && !canNavigate(favoriteName)) {
        setShowInvalidFavoriteModal(true);
        setInvalidFavoriteItem(found);
        return;
      }

      setInvalidFavoriteItem(null);
      setShowInvalidFavoriteModal(false);

      navigate(item);
    };

    return (
      <li id={`favorites_menu_5306cdb8-1bd4-412c-b121-383135e65642`}>
        <div className="at-menu-item-container">
          <div
            className={`at-menu-item-row ${
              favoritesMenuOpen ? 'expanded' : 'collapsed'
            }`}
          >
            <a
              className="at-flex-container at-menu-item at-pointer"
              onClick={() => setFavoritesMenuOpen(!favoritesMenuOpen)}
            >
              <div className="at-flex-child at-menu-item-icon">
                <FavoritesIcon
                  style={sidebarClasses.navigationItemIcon}
                  height={24}
                  width={24}
                  fillColor={gray19}
                />
              </div>
              <div className="at-flex-child at-menu-item-label">
                Favorites{' '}
                {favorites?.length > 0 ? ` (${favorites.length})` : ''}
              </div>

              {isLoaded && favorites && (
                <div className="at-flex-child at-menu-item-dropdown-control at-menu-item-icon">
                  <ExpandIcon subMenuOpen={favoritesMenuOpen} />
                </div>
              )}
            </a>
          </div>
          {isLoaded && favorites && favoritesMenuOpen && favorites.length == 0 && (
            <div className="at-menu-item-submenu slide-down">
              <div className="at-menu-item-container">
                <div style={sidebarClasses.emptyMessage}>
                  <Typography sx={sidebarClasses.emptyMessageTitle}>
                    Set up your favorites
                  </Typography>
                  <Typography sx={sidebarClasses.emptyMessageBody}>
                    Click on the star icon in either the navigation or the top
                    of the page.
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {isLoaded && favorites && favoritesMenuOpen && favorites?.length > 0 && (
            <div
              className="at-menu-item-submenu slide-down"
              style={{ ['--h' as any]: favorites.length * 51 }}
            >
              <ul className="at-submenu-items">
                {mappedFavorites.map((favorite, subindex) => {
                  return (
                    <li id={`favorites_${favorite.id}`} key={subindex}>
                      <div className="at-menu-item-container">
                        <div className="at-menu-item-row">
                          <a
                            className="at-flex-container at-menu-item at-pointer"
                            onClick={(e) => handleFavoriteNavigate(e, favorite)}
                          >
                            {/* this is to use the same space as the rest of the items */}
                            <div className="at-flex-child at-menu-item-icon">
                              <i></i>
                              <BetaIcon {...favorite} />
                            </div>

                            <MenuItemLabel
                              level={1}
                              className={`at-flex-child ${
                                favorite.invalid ? 'at-flex-child-invalid' : ''
                              }`}
                            >
                              {favorite.label}

                              {favorite.invalid ? (
                                <DeleteFilled
                                  className="pull-right icon-star"
                                  fontSize="small"
                                  style={{ cursor: 'pointer'}}
                                />
                              ) : (
                                <FavoritesStar
                                  id={`nav_star_${favorite.id}`}
                                  favorited={true}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    toggleFavorite(favorite, 'navigation');
                                  }}
                                />
                              )}
                            </MenuItemLabel>
                          </a>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </li>
    );
  };

  const SideBarComponent = () => {
    const { menu } = useNavigationState();

    const isSideBarPinned = useSideBarPinnedStore((s) => s.isSideBarPinned);
    const setIsSideBarPinned = useSideBarPinnedStore(
      (s) => s.setIsSideBarPinned
    );

    const home = menu.find((item) => item.label === 'Home');

    const togglePinSideBar = () => {
      setIsSideBarPinned(!isSideBarPinned);
    };

    useEffect(() => {
      if (isSideBarPinned) {
        window.document.body.classList.add('menu-open');
        window.document.body.classList.add('menu-pin');
      } else if (window.document.body.classList.contains('menu-open')) {
        window.document.body.classList.remove('menu-open');
        window.document.body.classList.remove('menu-pin');
      }
    }, [isSideBarPinned]);

    return (
      <div className="at-sidebar-menu-wrapper">
        <Box
          sx={{ ...hideOnLargeScreenStyle }}
          className="at-mobile-sidebar-background"
          onClick={() => {
            if (isSideBarPinned) {
              setIsSideBarPinned(!isSideBarPinned);
            }
          }}
        ></Box>
        <nav className="at-sidebar-menu">
          <div className="at-sidebar-header">
            <div className="at-menu-log-wrapper">
              <img
                src={activtrakLogo}
                alt="ActivTrak logo"
                style={{ padding: 25, width: '100%' }}
              />
            </div>
            <div className="at-menu-pin-wrapper">
              <div
                id={`sidebar_pin_4f439f25-c688-4d5b-be52-e12ca5f536af`}
                className="at-menu-pin"
                onClick={togglePinSideBar}
              >
                <div
                  className={`hamburger hamburger--minus js-hamburger ${
                    isSideBarPinned ? 'is-active' : ''
                  }`}
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="at-sidebar-menu-list p-b-50">
            <SlidingList className="at-sidebar-menu-items">
              {/* Home is Fixed */}
              <HomeComponent home={home}></HomeComponent>

              {isFavoriteNavigationEnabled && <FavoritesComponent />}
            </SlidingList>
            <SlidingList className="at-sidebar-menu-items">
              {menu
                .filter((item) => item.label !== 'Home')
                .map((item, index) => {
                  return (
                    <NavigationItem key={index} item={item} index={index} />
                  );
                })}
            </SlidingList>
          </div>
        </nav>
      </div>
    );
  };

  return (
    <>
      <InvalidFavoriteModal
        open={showInvalidFavoriteModal}
        removeFavorite={() => handleRemoveInvalidFavorite()}
        closeModal={() => setShowInvalidFavoriteModal(false)}
      />

      <SideBarComponent />
    </>
  );
};

export { SideBar };
