'use strict';

angular.module('app')
    .directive('faqSectionWidget', FaqSectionWidget);

    function FaqSectionWidget() {
    return {
        restrict: 'E',
        scope: {
            faqList: '=atFaqList'
        },
        template: require('views/widgets/faqSectionWidget.html'),
        controller: FaqSectionWidgetCtrl
    };
}

FaqSectionWidgetCtrl.$inject = ['$scope'];

function FaqSectionWidgetCtrl($scope) {
    var faqObjects = [];

    $scope.registerFaq = function(faq) {
        faqObjects.push(faq);
    };

    $scope.showAnswer = function(faq) {
        faqObjects.forEach(function(obj) {
            if (obj.id !== faq.id) {
                obj.hideAnswer();
            }
        });
    };
}
