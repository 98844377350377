import React, { useEffect, useMemo } from 'react';
import { useInjector } from '../../common/third-party/angular2react/useInjector';
import { ActivityDashboardProps } from '../models/ActivityDashboardTypes';

export const PrintActivityDashboardWrapper = (
  props: ActivityDashboardProps
) => {
  const { reportFilters } = props;

  const $injector = useInjector();

  const scope: any = useMemo(
    () => Object.assign($injector.get('$rootScope').$new(true), {}),
    [$injector]
  );

  useEffect(() => {
    return () => {
      scope?.$destroy();
    };
  }, [scope]);

  useEffect(() => {
    if (scope?.$$childHead?.bindReport) {
      scope.$$childHead?.bindReport(reportFilters);
    }
  }, [reportFilters, scope.$$childHead]);

  const element = useMemo(() => {
    const compile = (element: HTMLElement) => {
      if (!scope) {
        return;
      }

      const $compile = $injector.get('$compile');
      const template = $compile(element);
      template(scope);

      try {
        scope?.$digest();
      } catch (e) {
        /* */
      }
    };

    const element = React.createElement('print-dashboard-component', {
      ref: compile
    });

    return element;
  }, [$injector, scope]);

  return element;
};
