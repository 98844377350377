import {
  mediumFontWeight,
  gray4
} from '../../../../_reactivtrak/src/common/constants';

export const enterpriseClasses = {
  table: {
    minWidth: 700,
    border: `1px solid ${gray4} !important`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  header: {
    fontWeight: mediumFontWeight,
    lineHeight: '22px',
    verticalAlign: 'middle',
    padding: '6px 0px !important',
    whiteSpace: 'nowrap'
  },
  tableHead: {
    height: '60px'
  },
  tableRow: {
    boxShadow: 'none !important',
    cursor: 'default !important'
  },
  invisible: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  disabled: {
    opacity: 0.33
  },
  enabled: {
    opacity: 1
  },
  loader: {
    position: 'absolute',
    left: '11%',
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 171
  },
  launchIcon: {
    color: '#47B1F7',
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer',
    fontSize: '18px !important'
  }
};
