import React from 'react';
import { primaryBlue } from '../../constants';
import { SvgIconProps } from './SvgIconProps';

export const EmployeeExpIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const height = props.height ?? 36;
  const width = props.width ?? 36;
  const title = props.title ?? 'EmployeeExp';
  const className = props.className;
  const fillColor = props.fillColor ?? primaryBlue;

  let classList = 'at-icon at-icon-employeeexp';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 36 36`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M16.5 3C19.4138 3.00019 22.2281 4.06032 24.4178 5.98265C26.6076 7.90498 28.0233 10.5582 28.401 13.4475L31.776 18.756C31.998 19.1055 31.953 19.626 31.4385 19.848L28.5 21.105V25.5C28.5 26.2956 28.1839 27.0587 27.6213 27.6213C27.0587 28.1839 26.2956 28.5 25.5 28.5H22.5015L22.5 33H8.99996V27.459C8.99996 25.689 8.34596 24.0135 7.13396 22.5015C5.72059 20.7368 4.83452 18.6087 4.57784 16.3624C4.32115 14.116 4.70429 11.8429 5.68312 9.80485C6.66194 7.76678 8.19663 6.0467 10.1104 4.84277C12.0241 3.63884 14.239 3.00006 16.5 3ZM16.5 6C14.8043 5.99993 13.1432 6.47887 11.7078 7.38165C10.2725 8.28442 9.12143 9.57431 8.38717 11.1027C7.65291 12.6311 7.36535 14.3359 7.55763 16.0206C7.7499 17.7052 8.41419 19.3013 9.47396 20.625C11.115 22.671 12 25.0005 12 27.459V30H19.5L19.503 25.5H25.5V19.128L27.825 18.132L25.5105 14.4945L25.425 13.8315C25.141 11.6654 24.079 9.67642 22.4368 8.2355C20.7947 6.79458 18.6846 6.00003 16.5 6ZM15.705 11.6445L16.5 12.4395L17.295 11.6445C17.5371 11.3937 17.8267 11.1937 18.1469 11.0561C18.4671 10.9184 18.8116 10.8459 19.1601 10.8428C19.5087 10.8397 19.8543 10.9061 20.177 11.038C20.4996 11.1699 20.7927 11.3648 21.0392 11.6112C21.2858 11.8576 21.4807 12.1507 21.6128 12.4733C21.7448 12.7958 21.8113 13.1415 21.8084 13.49C21.8054 13.8386 21.7331 14.183 21.5955 14.5033C21.458 14.8236 21.2581 15.1133 21.0075 15.3555L16.5 19.8645L11.9925 15.3555C11.7418 15.1133 11.5419 14.8236 11.4044 14.5033C11.2669 14.183 11.1945 13.8386 11.1916 13.49C11.1886 13.1415 11.2551 12.7958 11.3871 12.4733C11.5192 12.1507 11.7142 11.8576 11.9607 11.6112C12.2072 11.3648 12.5003 11.1699 12.8229 11.038C13.1456 10.9061 13.4913 10.8397 13.8398 10.8428C14.1883 10.8459 14.5328 10.9184 14.853 11.0561C15.1732 11.1937 15.4629 11.3937 15.705 11.6445Z"
        fill={fillColor}
      />
    </svg>
  );
};
