import React from 'react';
import { ClassificationService } from './';
import { IClassificationService } from '../models';

import CategoryService, { ICategoryService } from './CategoryService';
import ActivityDetailService, {
  IActivityDetailService
} from './ActivityDetailService';
import { IGroupsState } from '../../common/models';
import { groupsService, useGroupsState } from '../../common/services/Groups';

const { createContext, useContext } = React;
const ClassificationContext = createContext<IClassificationProvider>(null);

interface IClassificationProvider {
  activityDetailService: IActivityDetailService;
  classificationService: IClassificationService;
  categoryService: ICategoryService;
  groupsState: IGroupsState;
}

type Props = {
  children: React.ReactNode;
  classificationService?: IClassificationService;
  categoryService?: ICategoryService;
};

export const ClassificationProvider = (props: Props) => {
  const { children } = props;

  const classificationService =
    props.classificationService || new ClassificationService();
  const categoryService = props.categoryService || new CategoryService();
  const activityDetailService = new ActivityDetailService();
  const service = groupsService();
  const groupsState = useGroupsState({ service });

  const value = {
    activityDetailService,
    classificationService,
    categoryService,
    groupsState
  };

  return (
    <ClassificationContext.Provider value={value}>
      {children}
    </ClassificationContext.Provider>
  );
};

export const useClassification = (): IClassificationProvider => {
  return useContext(ClassificationContext);
};
