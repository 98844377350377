import React from 'react';
import { isIgnoreCategoryId } from '../../common/helpers';
import DeleteCategoryButton from '../components/DeleteCategoryButton';
import EditCategoryTextField from '../components/EditCategoryTextField';
import IgnoreCategoryRowItem from '../components/IgnoreCategoryRowItem';
import { ClassificationSettingsColumns } from '../models/ClassificationSettingsColumns';

export const useSettingsColumns = (props: ClassificationSettingsColumns) => {
  const { isDisabled } = props;
  return [
    {
      field: 'name',
      fieldName: 'category',
      headerName: 'Category Name',
      type: 'string',
      isSortable: true,
      width: '35%',
      template: (dataItem) => {
        if (isIgnoreCategoryId(dataItem.id)) {
          return <IgnoreCategoryRowItem />;
        }
        return <EditCategoryTextField {...dataItem} isDisabled={isDisabled} />;
      }
    },
    {
      field: 'websiteCount',
      fieldName: 'Websites',
      headerName: 'Websites',
      type: 'number',
      isSortable: true,
      width: '25%',
      align: 'right',
      template: (dataItem) => {
        return dataItem.websiteCount;
      }
    },
    {
      field: 'appCount',
      fieldName: 'Apps',
      headerName: 'Apps',
      type: 'number',
      isSortable: true,
      width: '25%',
      align: 'right',
      template: (dataItem) => {
        return dataItem.appCount;
      }
    },
    {
      type: 'any',
      isSortable: false,
      width: '15%',
      align: 'center',
      template: (dataItem) => {
        if (isIgnoreCategoryId(dataItem.id)) {
          return <></>;
        }
        return (
          <DeleteCategoryButton
            categoryActivityDetail={dataItem}
            isDisabled={isDisabled}
          />
        );
      }
    }
  ];
};
