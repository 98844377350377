'use strict';

angular.module('app')
    .directive('gridFilterPanel', function () {
        return {
            restrict: 'E',
            scope: {
                filterData: '=',
                filter: '=',
                applyFilter: '<',
                filterDisabled: '='
            },
            template: require('views/widgets/gridFilterPanel.html'),
            link: function ($scope) {
                $scope.buttonFields = {};

                function createButtons() {
                    if ($scope.filterData && $scope.filterData.fields) {
                        var filter;

                        $scope.filterData.fields.forEach(function(data) {
                            if (data.type === 'buttons') {
                                filter = $scope.filter[data.name];
                                $scope.buttonFields[data.name] = (filter === null || typeof filter === 'undefined') ? 'all' : filter;
                            }
                        });
                    }
                }

                $scope.setFilter = function(filter) {
                    if ($scope.filterDisabled) {
                        return;
                    }

                    if (!filter) {
                        $scope.applyFilter({});
                        return;
                    }

                    for (var key in filter) {
                        if (filter[key] === '') {
                            delete filter[key];
                        }
                    }

                    $scope.applyFilter(filter);
                };

                $scope.clearFilter = function (key) {
                    if ($scope.filterDisabled) {
                        return;
                    }


                    delete $scope.filter[key];

                    if (key in $scope.buttonFields) {
                        $scope.buttonFields[key] = 'all';
                    }

                    if ($scope.filterData.appliedFilter && ($scope.filterData.appliedFilter[key] !== null || typeof $scope.filterData.appliedFilter[key] !== 'undefined')) {
                        delete $scope.filterData.appliedFilter[key];
                        $scope.applyFilter($scope.filterData.appliedFilter);
                    }
                };

                $scope.updateSelectionButtons = function(key, value) {
                    if (value === 'all') {
                        delete $scope.filter[key];
                    }
                    else {
                        $scope.filter[key] = value;
                    }
                };

                $scope.clearAllFilters = function () {
                    if ($scope.filterDisabled) {
                        return;
                    }

                    $scope.filter = {};
                    $scope.applyFilter({});
                };

                $scope.$watch('filter', function() {
                    createButtons();
                }, true);
            }
        };
    });
