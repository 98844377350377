import React from 'react';
import { Box, CardContent, Card, Typography } from '@mui/material';

const classes = {
  card: {
    '& div': {
      paddingBottom: '1.5em'
    },
    '& div:last-child': {
      paddingBottom: 0
    }
  }
};

type GroupDetailsProps = {
  children: React.ReactNode;
  title: string;
};

export default function GroupCard(props: GroupDetailsProps) {
  const { children, title } = props;

  return (
    <Card elevation={3} data-testid="group-details-card">
      <CardContent sx={classes.card}>
        <Typography variant="h6">{title}</Typography>
        <Box>{children}</Box>
      </CardContent>
    </Card>
  );
}
