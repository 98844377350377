import React from 'react';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme.js';
import DashboardLayout from './DashboardLayout.jsx';

export default function Root() {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <DashboardLayout />
      </ThemeProvider>
    </HashRouter>
  );
}
