import React from 'react';
import { SearchBar, ISearchBarProps } from '../../common/components/SearchBar';

export default function TicketsSearchBar(props: ISearchBarProps) {
  return (
    <SearchBar
      {...props}
      placeholder={props.filter || 'Search by Ticket #, Subject or Description'}
    />
  );
}
