import { apiService } from '../../common/helpers';
import { getDefaultConfig } from '../../common/config';
import {
  ILandingPageSettingPost,
  ILandingPageSettingsService
} from '../models';
import { HttpResponse } from '../../common/models';

export const LandingPageSettingsService = (): ILandingPageSettingsService => {
  return {
    postLandingPageSetting: async (
      payload: ILandingPageSettingPost
    ): Promise<HttpResponse<object>> => {
      return await apiService.post<HttpResponse<object>>(
        '/api/settings/landingpage',
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
    },
    fetchLandingPageSetting: async (): Promise<HttpResponse<string>> => {
      return await apiService.get<string>('/api/settings/landingpage', {
        ...getDefaultConfig()
      });
    }
  };
};
