import { SortDirection } from '../../common/enums';
import {
  deleteAdminData,
  fetchAdminData,
  postAdminData
} from '../../common/helpers';
import { GTEUserDTO, GTEUserPayload } from '../models';

const CLIENTS_DNT_BASE_URL = 'admin/v1/clients/donottrack';

export const fetchGTEUsersDNT = async (): Promise<{
  records: GTEUserDTO[];
}> => {
  const response = await fetchAdminData<null>({
    path: CLIENTS_DNT_BASE_URL,
    params: { onlyGlobalTrackingExclusions: true }
  });

  return Promise.resolve(response);
};

export const addGTEUserDNT = async (
  payload: GTEUserPayload
): Promise<string> => {
  const response = await postAdminData<null>({
    path: CLIENTS_DNT_BASE_URL,
    args: { Users: [{ ...payload }] }
  });

  return Promise.resolve(response);
};

export const deleteGTEUserDNT = async (
  payload: GTEUserPayload
): Promise<string> => {
  const response = await deleteAdminData<null>({
    path: CLIENTS_DNT_BASE_URL,
    args: { Users: [{ ...payload }] }
  });

  return Promise.resolve(response);
};

export const getSortDirection = (
  currentDirection: SortDirection
): SortDirection => {
  return currentDirection === SortDirection.None ||
    currentDirection === SortDirection.Descending
    ? SortDirection.Ascending
    : SortDirection.Descending;
};
