import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { AuditLogWrapper } from '../utils/auditLogWrapper';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { AuditLogHeader } from '../components/AuditLogHeader';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { useAuthorization } from '../../../common/services/Authorization';

const AuditLogView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

    const { refreshTimestamp, handleRefresh } = useRefreshState();

    const authorization = useAuthorization();
    const isPaid = authorization.hasFeature(BundleFlag.PaidFunctionality);

    useEffect(() => {
      if (refreshTimestamp) {
        setIsRefreshing(true);
        setTimeout(() => {
          setIsRefreshing(false);
        }, 5000);
      }
    }, [refreshTimestamp]);

    return (
      <>
        {isPaid && (
          <AuditLogHeader
            reportFilters={reportFilters}
            onRefresh={handleRefresh}
            isRefreshing={isRefreshing}
            refreshTimestamp={refreshTimestamp}
          />
        )}
        <AuditLogWrapper
          reportFilters={reportFilters}
          refreshTimestamp={refreshTimestamp}
        />
      </>
    );
};

export default AuditLogView;

export const AuditLogViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <AuditLogView />
  </ReactivTrakComponentWrapper>
);
