import {
  IFilterAccess,
  IRoleAccessViewModel,
  IRoleAccessService
} from '../../common/models';
import { IRoleAccess } from '../../common/services/GetRoles';
import { find } from 'lodash';
import {
  GetFilterDataInitialData,
  keyMap,
  roleTypes
} from '../utils';
import { getDefaultConfig, BaseClient } from '../../common/config';

export class RoleAccessService
  extends BaseClient
  implements IRoleAccessService
{
  async getAllFilters(): Promise<IFilterAccess[]> {
    return new Promise((resolve) => {
      this.apiService
        .get(`/api/settings/roledatefilter`, getDefaultConfig())
        .then((response: any) => {
          return resolve(response.data);
        });
    });
  }

  async set(roleAccess: IRoleAccess[]) {
    await this.apiService.post(`/api/settings/roleaccess`, {
      ...getDefaultConfig(),
      data: roleAccess
    });
  }
  public setFilters = async (roleAccess: IFilterAccess[]) => {
    await this.apiService
      .post(`/api/settings/roledatefilter`, {
        ...getDefaultConfig(),
        data: roleAccess
      })
      .then((response: any) => {
        return response.data;
      });
  };

  public setDefault = () =>
    this.apiService
      .post(`/api/settings/roleaccess/default`, getDefaultConfig())
      .then((response: any) => {
        return response;
      });

  public getPrivateMode = async (): Promise<boolean> => {
    let response: boolean = null;
    await this.apiService
      .get<boolean>(`/api/settings/privatemode`, getDefaultConfig())
      .then((r) => (response = r.status == 200 ? r.data : response))
      .catch((reason) => {
        // Set default value
        response = false;
        console.error(reason?.data?.error);
      });
    return response;
  };

  public setPrivateMode = async (isPrivateMode: boolean) =>
    await this.apiService.post(`/api/settings/privatemode`, {
      ...getDefaultConfig(),
      data: isPrivateMode
    });

  public mapFilterAccess(
    entities: IFilterAccess[],
    dataRetentionTerm: number | null
  ): IRoleAccessViewModel[] {
    const accessRows = GetFilterDataInitialData(dataRetentionTerm);
    const newAccessRows = accessRows.map(function (target) {
      const origin = find(entities, function (item) {
        return (
          item.filter.replace(/[ -]/g, '').toLowerCase() ===
          target.resource.replace(/[ -]/g, '').toLowerCase()
        );
      });

      if (origin != undefined) {
        Object.keys(target).map(function (keyName) {
          const item = { ...target[keyName] };

          //Disabled items by default are excluded
          if (item['disabled'] !== undefined && !item['disabled']) {
            if (keyName === keyMap.AdminCheck) {
              item.checked = origin.roles.some((role) => {
                return role.toLowerCase() === roleTypes.Administrator;
              });
            }
            if (keyName === keyMap.ConfigCheck) {
              item.checked = origin.roles.some((role) => {
                return role.toLowerCase() === roleTypes.Configurator;
              });
            }

            if (keyName === keyMap.PowerCheck) {
              item.checked = origin.roles.some((role) => {
                return role.toLowerCase() === roleTypes.User;
              });
            }

            if (keyName === keyMap.ViewerCheck) {
              item.checked = origin.roles.some((role) => {
                return role.toLowerCase() === roleTypes.Teamviewer;
              });
            }
            target[keyName] = item;
          }
        });
      }
      return target;
    });
    return newAccessRows;
  }
}
export default RoleAccessService;
