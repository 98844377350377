import { react2angular } from 'react2angular';
import { SideBar } from '../../_reactivtrak/src/common/components/Navigation/SideBar';
import { favoritesStore } from '../../_reactivtrak/src/common/components/Navigation/favoritesStore';
import {
    setNavigationItems_angular,
    setNavigationRoute_angular,
    setSidebarResetFunction_angular
} from '../../_reactivtrak/src/common/components/Navigation/navigationStore';
import {
    hasCoachingOpportunities,
    setCoachingNotificationLastSeenWeek
} from '../../_reactivtrak/src/common/services/Navigation/Coaching';
import { latestUpdatesServiceFunctions } from '_app/serviceFunctions/latestUpdatesServiceFunctions';
import { isAccountPlanInTrial } from '../../_reactivtrak/src/common/stores/accountPlanStore';

export const connect = (angular) => {
    //SideBar Component
    angular.module('app').component('sidebarnew', react2angular(SideBar, ['navigate', 'canNavigate']));

    //TODO: Rename to sidebarService
    angular.module('app').service('navigationStore', [
        'authorizationService',
        '$state',
        'AccountSettingsService',
        'localStorageService',
        'navigationService',
        function (authorizationService, $state, AccountSettingsService, localStorageService, navigationService) {
            function createSidebarSettings(menuType) {
                return {
                    menuType: menuType,
                    ruleFunctions: {
                        hasRouteByName: authorizationService.hasRouteByName,
                        hasFeature: authorizationService.hasFeature,
                        hasRole: authorizationService.hasRole,
                        hasRoleAccess: authorizationService.hasRoleAccess,
                        isTrial: isAccountPlanInTrial()
                    },
                    currentState: {
                        stateName: $state.current.name,
                        modelId: $state.params.modelId,
                        pageId: $state.params.pageId,
                        subPageId: $state.params.subPageId
                    },
                    localStorage: {
                        service: localStorageService,
                        username: AccountSettingsService.username
                    },
                    localStorageService
                };
            }

            const checkLatestUpdates = () => {
                return new Promise((resolve) => {
                    latestUpdatesServiceFunctions.setLatestUpdatesCallback(function (newUpdateCount) {
                        if (newUpdateCount) {
                            resolve(newUpdateCount > 0);
                        }
                    });
                    latestUpdatesServiceFunctions.getUserLastSeen();
                });
            };

            const updateMenuItems = (value) => {
                const menuItems = value
                    ? value.map(function (item) {
                          return item;
                      })
                    : [];
                setNavigationItems_angular({ menuItems: menuItems });
            };

            this.createSidebar = (menuType) => {
                setSidebarResetFunction_angular({ sidebarReset: () => this.createSidebar(menuType) });
                const settings = createSidebarSettings(menuType);
                const sidebar = navigationService.createSidebar(settings);

                // Disable Favorites for Support roles
                const favoritesEnabled = !authorizationService.hasRole([
                    authorizationService.roles.supportPortal,
                    authorizationService.roles.supportBasic,
                    authorizationService.roles.supportAdvanced
                ]);
                favoritesStore.setState({ isFavoriteNavigationEnabled: favoritesEnabled });

                //On page load, display coach notification dot if exists`
                if (authorizationService.hasRouteByName('app.coaching')) {
                    hasCoachingOpportunities()
                        .then((result) => {
                            if (result) {
                                if ($state.current.name === 'app.coaching') {
                                    //If page loads on coach, no need for notification; set seen week
                                    setCoachingNotificationLastSeenWeek();
                                } else {
                                    const coachingMenuItem = sidebar.menu?.value?.find(
                                        (item) => item.action?.options?.ref === 'app.coaching'
                                    );

                                    if (coachingMenuItem) {
                                        coachingMenuItem.hasCoachingNotifications = true;
                                        updateMenuItems(sidebar.menu.value);
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.error('Error retrieving coach opportunities: ' + error);
                        });
                }

                sidebar.menu.subscribe(async (value) => {
                    if (!value || value.length === 0) return;
                    updateMenuItems(value);
                    try {
                        const hasLatest = await checkLatestUpdates();
                        if (hasLatest) {
                            const latestMenuItem =
                                value.find((item) => item.action?.options?.ref === 'app.help.latestUpdates') || {};
                            if (latestMenuItem) latestMenuItem.hasCoachingNotifications = true;
                        }
                    } finally {
                        updateMenuItems(value);
                    }
                });

                return sidebar;
            };

            //TODO: Find a better name for this...
            this.setNavigationState = function (state) {
                setNavigationRoute_angular({ route: state });
            };
        }
    ]);
};
