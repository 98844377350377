import {
  mapToUserDetail,
  mapToDoNotTrackDtos,
  mapToUsers,
  mapClientV1ToUsers,
  mapToMergeDto,
  mapToScheduledMergeDto,
  mapToScheduledActionDto,
  mapToDoNotTrackV1Dtos,
  mapClientV1ToScheduledActionDto, 
} from '../utils';
import {
  IFetchAllUsers,
  IUser,
  IUserDetail,
  IUserMerge,
  IUsersService
} from '../models';
import { 
  HttpResponse
} from '../../common/models';
import {
  getDefaultConfig, 
  BaseClient, 
  getWebApiConfig
} from '../../common/config';

export class UsersService extends BaseClient implements IUsersService {
  protected softDeletesEnabled: boolean;

  constructor(softDeletesEnabled: boolean) {
    super();
    
    this.softDeletesEnabled = softDeletesEnabled;
  }
  
  public fetchAllUsers = () => {
    if (this.softDeletesEnabled) {
      return this.apiService.get(`/admin/v1/clients?includeRestorable=True`, getWebApiConfig())
        .then((response: any) => {
          const newResponse: IFetchAllUsers = {
            users: mapClientV1ToUsers(response.data)
          };
          return newResponse;
        });
    } else {
      return this.apiService.get(`/api/settings/storage/user`, getDefaultConfig())
        .then((response: any) => {
          const {ustorage} = response.data;
          const newResponse: IFetchAllUsers = {
            users: mapToUsers(ustorage)
          };
          return newResponse;
        });
    }
  }

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  public fetchScheduledUserMerges = () =>
    this.apiService
      .get(`/api/settings/storage/scheduled-merges`, getDefaultConfig())
      .then((response: any) => {
        return mapToScheduledMergeDto(response.data);
      });

  public fetchScheduledDeletes = () => {
    return this.apiService
      .get(`/admin/v1/clients?includeRestorable=True`, getWebApiConfig())
      .then((response: any) => {
        return mapClientV1ToScheduledActionDto(response.data);
      });
  }
  
  public async deleteScheduledDelete(userId: number): Promise<void> {
    await this.apiService.put(`/admin/v1/clients/restore`, {
      ...getWebApiConfig(),
      ...{ data: { ClientIds: [userId] } }
    });
  }
  
  public async deleteAllScheduledDeletes(): Promise<void> {
    await this.apiService
      .put(`/admin/v1/clients/restore`, getWebApiConfig());
  }

  // TODO: Remove when soft deletes are enabled for all accounts
  public fetchScheduledActions = () => {
    return this.apiService
      .get(`/api/settings/storage/scheduled-deletes`, getDefaultConfig())
      .then((response: any) => {
        return mapToScheduledActionDto(response.data);
      });
  } 

  // TODO: Remove when soft deletes are enabled for all accounts
  public async deleteScheduledAction(actionToDelete: number): Promise<void> {
    await this.apiService.put(`/api/settings/storage/scheduled-deletes`, {
      ...getDefaultConfig(),
      ...{ data: actionToDelete }
    });
  }

  // TODO: Remove when soft deletes are enabled for all accounts
  public async deleteAllScheduledActions(): Promise<void> {
    await this.apiService
      .delete(`/api/settings/storage/scheduled-deletes`, getDefaultConfig());
  }

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  public async deleteUserMerge(mergeToDelete: IUserMerge): Promise<void> {
    await this.apiService.put(`/api/settings/scheduled_merge`, {
      ...getDefaultConfig(),
      ...{ data: mergeToDelete }
    });
  }

  public async deleteUsers(userIds: number[]): Promise<void> {
    if (this.softDeletesEnabled) {
      await this.apiService.delete(`/admin/v1/clients`, {
        ...getWebApiConfig(),
        ...{ data: { ClientIds: userIds } }
      });
    } else {
      await this.apiService.put(`/api/settings/delete-multiple-users`, {
        ...getDefaultConfig(),
        ...{ data: { UserIds: userIds } }
      });
    }
  }

  public async doNotTrackUsers(users: IUser[]): Promise<void> {
    if (this.softDeletesEnabled) {
      const dntUserDtos = mapToDoNotTrackV1Dtos(users);
      await this.apiService.post(`/admin/v1/clients/donottrack`, {
        ...getWebApiConfig(),
        ...{ data: { Users: dntUserDtos } }
      });
    } else {
      const dntUserDtos = mapToDoNotTrackDtos(users);
      await this.apiService.post(`/api/settings/donottrack`, {
        ...getDefaultConfig(),
        ...{ data: dntUserDtos }
      });
    }
  }
  
  public async deleteDoNotTrackUsers(users: IUser[]): Promise<void> {
    const dntUserDtos = mapToDoNotTrackV1Dtos(users);
    await this.apiService.delete(`/admin/v1/clients/donottrack`, {
      ...getWebApiConfig(),
      ...{ data: { Users: dntUserDtos } }
    });
  }

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  public async mergeUsers(usersToMerge: IUser[]): Promise<void> {
    const mergeUserDtos = mapToMergeDto(usersToMerge);
    await this.apiService.post(`/api/settings/merge-users`, {
      ...getDefaultConfig(),
      ...{ data: mergeUserDtos }
    });
  }

  // TODO: transfer to admin endpoints later, leave on FEAPI for now
  public async fetchUserDetail(userId: number): Promise<IUserDetail> {
    const response = await this.apiService.get(
      `/api/settings/storage/userdetail/${userId}`,
      getDefaultConfig()
    );
    return mapToUserDetail(response.data);
  }

  downloadCSV(): Promise<HttpResponse<string>> {
    return new Promise((resolve, reject) => {
      this.apiService
        //long term we want to switch to web api, but not ready yet
        //.get('/users/bulk', {
        //  ...this.webApiConfig,
        .get('/api/settings/storage/user-report-csv', {
          ...getDefaultConfig(),
          responseType: 'blob'
        })
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}

export default UsersService;
