'use strict';

angular
    .module('app')
    .directive('alarmDetailsEmailNotification', AlarmDetailsEmailNotification);

function AlarmDetailsEmailNotification() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsEmailNotification.html'),
        controller: AlarmDetailsEmailNotificationCtrl
    };
}

AlarmDetailsEmailNotificationCtrl.$inject = ['$scope', '$timeout'];

function AlarmDetailsEmailNotificationCtrl($scope, $timeout) {
    var hideMacrosTimeout = null;
    var buttonBlurTimeout = null;
    var focusOnSubjectMacros = false;
    var focusOnBodyMacros = false;
    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    $scope.macroButtonFocus = function() {
        // console.log('Email Macro Focus');
        $scope.macroButtonPressed = true;
    };

    $scope.appendMacro = function(field, fieldId, source) {
        // console.log('Email Append Macro');
        // Cancel hiding macro buttons if a macro button is pressed before timeout period ends.
        if (hideMacrosTimeout !== null) {
            $timeout.cancel(hideMacrosTimeout);
            hideMacrosTimeout = null;
        }

        if (fieldId === 'emailSubjectField') {
            $scope.alarm.emailsubject = $scope.options.appendMacro(
                field,
                '#' + fieldId,
                source
            );
        } else if (fieldId === 'emailBodyField') {
            $scope.alarm.emailbody = $scope.options.appendMacro(
                field,
                '#' + fieldId,
                source
            );
        }

        // If button blur timeout is set cancel it and prep to start again
        if (buttonBlurTimeout !== null) {
            $timeout.cancel(buttonBlurTimeout);
            buttonBlurTimeout = null;
        }

        // Start button blur timeout. Used to determine if a macro button was the reason for a text field to loose focus.
        buttonBlurTimeout = $timeout(function() {
            $scope.macroButtonPressed = false;
            buttonBlurTimeout = null;
        }, 50);
    };

    $scope.showMacros = function(type) {
        // console.log('Email Show Macros');
        if (type === 'subject') {
            $scope.showSubjectMacroButtons = true;
            focusOnSubjectMacros = true;
        } else if (type === 'body') {
            $scope.showBodyMacroButtons = true;
            focusOnBodyMacros = true;
        }
    };

    $scope.hideMacros = function(type) {
        // console.log('Email Hide Macros');
        // Wait 10 ms to allow macro button function to set variables.
        $timeout(function() {
            if (!$scope.macroButtonPressed) {
                if (type === 'subject') {
                    focusOnSubjectMacros = false;
                } else if (type === 'body') {
                    focusOnBodyMacros = false;
                }

                // Wait 100 ms to ensure lost focus was intentional.
                hideMacrosTimeout = $timeout(function() {
                    if (!focusOnSubjectMacros) {
                        $scope.showSubjectMacroButtons = false;
                    } else if (!focusOnBodyMacros) {
                        $scope.showBodyMacroButtons = false;
                    }
                }, 100);
            }
        }, 10);
    };

    function isComponentValid() {
        // console.log('Email Messages Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload(payload) {
        // console.log('Email Messages Update Payload', payload);
        payload.emailbounced =
            payload.emailbounced && $scope.oldEmail === payload.emailto;
        return true;
    }

    function alarmUpdated() {
        // console.log('Email Messages Alarm Updated');
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.emailNotification,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);
    $scope.oldEmail = $scope.alarm.emailto;
}
