'use strict';

const { inMemoryStore } = require('activtrak-ui-utilities');

angular.module('app').directive('teamPulseWrapper', TeamPulseWrapper);

function TeamPulseWrapper() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions'
        },
        template: require('views/widgets/teamPulseWrapper.html'),
        controller: TeamPulseWrapperCtrl
    };
}

TeamPulseWrapperCtrl.$inject = [
    '$scope',
    '$timeout',
    'AccountSettingsService',
    'authorizationService'
];

function TeamPulseWrapperCtrl(
    $scope,
    $timeout,
    accountSettingsService,
    authorizationService
) {
    
    var teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');

    teamPulseDataControl.isReport = $scope.options.isReport;
    teamPulseDataControl.timeFormat = accountSettingsService.timeFormat;
    teamPulseDataControl.containerHeight = $scope.options.containerHeight;
    teamPulseDataControl.isViewer = authorizationService.hasRole([
        authorizationService.roles.viewer
    ]);

    $('#tp-app').removeClass('hide');
    $timeout(function () {
        $('#tp-app').appendTo('#tp-app-wrapper');
    });

    $scope.$on('$destroy', function () {
        $('#tp-app').appendTo('#tp-app-holder');
        $('#tp-app').addClass('hide');
    });
}
