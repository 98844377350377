import React from 'react';
import { IconButton } from '@mui/material';
import { gray5 } from '../../constants';
import { IInfoButtonProps } from './Button.props';
import { InfoOutlinedIcon } from '../../styles/app.component.styles';
import { WhiteBackgroundTooltip } from '../Tooltip/TextWithTooltip.styles';

export const InfoButton = (props: IInfoButtonProps) => {
  const { tooltipText } = props;

  return (
    <WhiteBackgroundTooltip
      placement="top"
      title={tooltipText}
      enterTouchDelay={0}
    >
      <IconButton sx={{ color: `${gray5}` }}>
        <InfoOutlinedIcon />
      </IconButton>
    </WhiteBackgroundTooltip>
  );
};
