'use strict';

angular
    .module('app')
    .directive('realtimeThumbnail', function () {
        return {
            restrict: 'E',
            scope: {
                row: '=',
                onClick: '=',
                fixedWidth: '='
            },
            template: require('views/widgets/realtimeThumbnail.html'),
            controller: 'RealtimeThumbnailCtrl'
        };
    })
    .controller('RealtimeThumbnailCtrl', RealtimeThumbnailCtrl);

RealtimeThumbnailCtrl.$inject = [
    '$scope',
    '$element',
    '$timeout',
    'realtimeScreenshotService'
];

function RealtimeThumbnailCtrl(
    $scope,
    $element,
    $timeout,
    realtimeScreenshotService
) {
    if ($scope.fixedWidth) {
        $scope.width = $scope.fixedWidth;
        $scope.height = $scope.width * 0.5625;
    }

    function updateImage(imageUrl, error) {
        if (imageUrl) {
            $scope.imageUrl = imageUrl.replace(/(\r\n|\n|\r)/gm, '');
        }

        $scope.error = error;
        $scope.inError =
            error === 'Cannot Retrieve Screenshot' ||
            error === 'Could not find Agent.';
        $scope.isDelayed = !!error;
        $scope.isLoading = !(
            $scope.imageUrl ||
            $scope.inError ||
            $scope.isDelayed
        );

        // if ($scope.isDelayed) {
        //     console.log('Image Delayed', $scope.row.username, $scope.isDelayed, $scope.inError, error);
        // }

        // console.log('Update Image', $scope.row.username, $scope.isLoading, $scope.inError, error);
        // console.log('ImageURL', $scope.imageUrl && $scope.imageUrl.substring(0,20));
    }

    function registerContainer() {
        if (!$scope.row || !$scope.row.username || !$scope.width) {
            return;
        }

        var registerResult = realtimeScreenshotService.registerImageContainer(
            $scope.row,
            { width: $scope.width },
            updateImage
        );
        updateImage(registerResult.imageUrl, registerResult.error);
    }

    function updateThumbnailSize() {
        var parentWidth = $element.parent()[0].offsetWidth;
        if (parentWidth > 0 && !$scope.fixedWidth) {
            $scope.width = Math.round(parentWidth) - 20;
            $scope.height = Math.round($scope.width * 0.5625);
        }
        registerContainer();
    }
    $timeout(updateThumbnailSize, 100);

    $scope.$on('atWindowResizing', function () {
        updateThumbnailSize();
    });

    $scope.$watch(
        'row',
        function () {
            updateThumbnailSize();
        },
        true
    );
}
