import { ProductivityColors } from '../../common/constants';
import {
  ITopUsersGroupsDto,
  TopGroupUsersDataSets,
  TopGroupUsersBar,
  TopUsersGroupFilterSort
} from '../models/ITopUsersGroupsTypes';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { getStringDurationFormat } from './widget.utils';

export const processGraphBarData = (
  barData: ITopUsersGroupsDto[]
): TopGroupUsersBar => {
  const graphBarData: {
    labels?: string[];
    productiveActivities?: number[];
    unproductiveActivities?: number[];
    undefinedActivities?: number[];
    totals?: number[];
    productivePassive?: number[];
    unproductivePassive?: number[];
    undefinedPassive?: number[];
  } = {
    labels: [],
    productiveActivities: [],
    unproductiveActivities: [],
    undefinedActivities: [],
    totals: [],
    productivePassive: [],
    unproductivePassive: [],
    undefinedPassive: []
  };
  const stackLabels = ['Productive', 'Unproductive', 'Undefined'];
  let productiveTotal = 0;
  let unproductiveTotal = 0;
  let undefinedTotal = 0;

  const processBarDataPoint = (bar: ITopUsersGroupsDto, label: string) => {
    const stackKey = label.toLowerCase();
    const indexOfLabel = stackLabels.indexOf(label);
    switch (stackKey) {
      case 'productive':
        if (bar.productive == null && indexOfLabel >= 0) {
          stackLabels.splice(indexOfLabel, 1);
        } else {
          productiveTotal = bar.productive + bar.productivePassive;

          graphBarData.productiveActivities.push(productiveTotal || 0);
          graphBarData.productivePassive.push(bar.productivePassive);
        }
        break;
      case 'unproductive':
        if (bar.unproductive == null && indexOfLabel >= 0) {
          stackLabels.splice(indexOfLabel, 1);
        } else {
          unproductiveTotal = bar.unproductive + bar.unproductivePassive;

          graphBarData.unproductiveActivities.push(unproductiveTotal || 0);
          graphBarData.unproductivePassive.push(bar.unproductivePassive);
        }
        break;
      case 'undefined':
        if (
          bar.undefined == null &&
          bar.undefinedActive == null &&
          indexOfLabel >= 0
        ) {
          stackLabels.splice(indexOfLabel, 1);
        } else {
          undefinedTotal = bar.undefined
            ? bar.undefined + bar.undefinedPassive
            : bar.undefinedActive + bar.undefinedPassive;

          graphBarData.undefinedActivities.push(undefinedTotal || 0);
          graphBarData.undefinedPassive.push(bar.undefinedPassive);
        }
        break;
    }
  };

  barData.forEach((bar) => {
    graphBarData.labels.push(bar.user);
    processBarDataPoint(bar, 'Productive');
    processBarDataPoint(bar, 'Unproductive');
    processBarDataPoint(bar, 'Undefined');

    graphBarData.totals.push(bar.total);
  });

  const populateDataSets = () => {
    const data: TopGroupUsersDataSets[] = [];

    stackLabels.map((label) => {
      if (label === 'Productive') {
        data.push({
          axis: 'y',
          label: label,
          data: graphBarData.productiveActivities,
          backgroundColor: ProductivityColors[1],
          totals: graphBarData.totals,
          passiveTimes: graphBarData.productivePassive
        });
      } else if (label === 'Unproductive') {
        data.push({
          axis: 'y',
          label: label,
          data: graphBarData.unproductiveActivities,
          backgroundColor: ProductivityColors[-1],
          totals: graphBarData.totals,
          passiveTimes: graphBarData.unproductivePassive
        });
      } else if (label === 'Undefined') {
        data.push({
          axis: 'y',
          label: label,
          data: graphBarData.undefinedActivities,
          backgroundColor: ProductivityColors[0],
          totals: graphBarData.totals,
          passiveTimes: graphBarData.undefinedPassive
        });
      }
    });

    return data;
  };

  if (graphBarData.labels.length) {
    const columnsConfig: TopGroupUsersBar = {
      labels: graphBarData.labels,
      datasets: populateDataSets()
    };
    return columnsConfig;
  }
};

export const sortAndCreateBarData = (
  barData: ITopUsersGroupsDto[],
  sortMode: TopUsersGroupFilterSort,
  filterMode: TopUsersGroupFilterSort[],
  setSortMode: Dispatch<SetStateAction<TopUsersGroupFilterSort>>
): TopGroupUsersBar => {
  const clonedSet = barData.map((row) => _.cloneDeep(row));
  const orderBars = (data, key, secondKey?) => {
    if (secondKey) {
      return data.sort(
        (a, b) => b[key] + b[secondKey] - (a[key] + a[secondKey])
      );
    } else {
      return data.sort((a, b) => b[key] - a[key]);
    }
  };

  let sortedBarData;
  const refreshTotals = (data) => {
    return data.map((bar) => {
      const undefinedTotal = bar.undefined
        ? bar.undefined + bar.undefinedPassive
        : bar.undefinedActive
        ? bar.undefinedActive + bar.undefinedPassive
        : 0;
      const productiveTotal = bar.productive
        ? bar.productive + bar.productivePassive
        : 0;
      const unProductiveTotal = bar.unproductive
        ? bar.unproductive + bar.unproductivePassive
        : 0;
      bar.total = productiveTotal + unProductiveTotal + undefinedTotal;
      return bar;
    });
  };

  const barDataUpdatedTotals = refreshTotals(clonedSet);

  switch (true) {
    case sortMode === TopUsersGroupFilterSort.productive &&
      filterMode.includes(TopUsersGroupFilterSort.productive):
      sortedBarData = orderBars(
        barDataUpdatedTotals,
        'productive',
        'productivePassive'
      );
      break;
    case sortMode === TopUsersGroupFilterSort.unproductive &&
      filterMode.includes(TopUsersGroupFilterSort.unproductive):
      sortedBarData = orderBars(
        barDataUpdatedTotals,
        'unproductive',
        'unproductivePassive'
      );
      break;
    case sortMode === TopUsersGroupFilterSort.undefined &&
      filterMode.includes(TopUsersGroupFilterSort.undefined):
      clonedSet[0].undefined
        ? (sortedBarData = orderBars(
            barDataUpdatedTotals,
            'undefined',
            'undefinedPassive'
          ))
        : (sortedBarData = orderBars(
            barDataUpdatedTotals,
            'undefinedActive',
            'undefinedPassive'
          ));
      break;
    default: {
      sortedBarData = orderBars(barDataUpdatedTotals, 'total');
      if (sortMode !== TopUsersGroupFilterSort.total)
        setSortMode(TopUsersGroupFilterSort.total);
      break;
    }
  }
  return processGraphBarData(sortedBarData);
};

export const filterBarData = (
  barData: ITopUsersGroupsDto[],
  filterMode: TopUsersGroupFilterSort[]
): ITopUsersGroupsDto[] => {
  const filteredSet = barData.map((row) => _.cloneDeep(row));

  filteredSet.map((row) => {
    if (!filterMode.includes(TopUsersGroupFilterSort.productive)) {
      row.productive = null;
    }

    if (!filterMode.includes(TopUsersGroupFilterSort.unproductive)) {
      row.unproductive = null;
    }

    if (!filterMode.includes(TopUsersGroupFilterSort.undefined)) {
      row.undefined ? (row.undefined = null) : (row.undefinedActive = null);
    }
  });

  return filteredSet;
};

export const processSingleBarData = (singleBarData, relativeIndex?: number) => {
  const labels = [];
  const productiveActivities: number[] = [];
  const unproductiveActivities: number[] = [];
  const undefinedActivities: number[] = [];

  labels.push(singleBarData.user);
  productiveActivities.push(
    singleBarData.productive + singleBarData.productivePassive
  );
  unproductiveActivities.push(
    singleBarData.unproductive + singleBarData.unproductivePassive
  );

  // Groups API call has different undefined shape
  singleBarData.undefined = singleBarData.undefined
    ? singleBarData.undefined + singleBarData.undefinedPassive
    : singleBarData.undefinedActive + singleBarData.undefinedPassive;

  // Fix to when pushing 0 getting NaN
  undefinedActivities.push(singleBarData.undefined || 0);

  const totalTime =
    productiveActivities[0] +
    unproductiveActivities[0] +
    undefinedActivities[0];

  const calculateTotalTimeSingleBar = () => {
    return getStringDurationFormat(totalTime);
  };

  const calculatePassiveTime = () => {
    return {
      Productive: getStringDurationFormat(
        singleBarData.productivePassive,
        false
      ),
      Unproductive: getStringDurationFormat(
        singleBarData.unproductivePassive,
        false
      ),
      Undefined: getStringDurationFormat(singleBarData.undefinedPassive, false)
    };
  };

  const calculateBarPercentages = () => {
    const totalPercent = (value) =>
      value > 0 ? ((value / totalTime) * 100).toFixed(1) : 0;

    return {
      Productive: `${totalPercent(productiveActivities[0])}`,
      Unproductive: `${totalPercent(unproductiveActivities[0])}`,
      Undefined: `${totalPercent(undefinedActivities[0])}`
    };
  };

  const dataSets = [
    {
      axis: 'y',
      label: 'Productive',
      data: productiveActivities,
      backgroundColor: ProductivityColors[1],
      relativeIndex: relativeIndex ?? null
    },
    {
      axis: 'y',
      label: 'Unproductive',
      data: unproductiveActivities,
      backgroundColor: ProductivityColors[-1],
      relativeIndex: relativeIndex ?? null
    },
    {
      axis: 'y',
      label: 'Undefined',
      data: undefinedActivities,
      backgroundColor: ProductivityColors[0],
      relativeIndex: relativeIndex ?? null
    }
  ];

  const columnsConfig = {
    labels: labels,
    datasets: dataSets,
    totalTimeSingleBar: calculateTotalTimeSingleBar(),
    passiveSeries: calculatePassiveTime(),
    barPercentages: calculateBarPercentages()
  };
  return columnsConfig;
};
