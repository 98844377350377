import { react2angular } from 'react2angular';
import { SubscriptionManagerViewComponent } from '../../_reactivtrak/src/insights/views/SubscriptionManager.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('reportsSubscriptions', react2angular(SubscriptionManagerViewComponent, []));
}
