const constructor = (httpService, envConfig, authorizationService, localStorageService) => {
    let _teamPulseSettings = {};

    const showTeamPulse = () => {
        return authorizationService.hasFeature('isTeamPulseEnabled');
    };

    const loadSettings = (settings) => {
        _teamPulseSettings = settings;
        return _teamPulseSettings;
    };

    const getCanChangeDashboardView = () => {
        return (
            authorizationService.hasFeature('isTeamPulseEnabled') &&
            _teamPulseSettings &&
            _teamPulseSettings.canChangeDashboardView
        );
    };

    return {
        showTeamPulse,
        getCanChangeDashboardView,
        loadSettings
    };
};

export { constructor };
