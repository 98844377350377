import React, { useState, useEffect } from 'react';
import { Grid, InputLabel, FilterOptionsState } from '@mui/material';
import { TagBar } from '../../common/components/TagBar/TagBar';
import { AdvancedSearchFilter } from '../../common/components/AdvancedFilter/AdvancedSearchFilter';
import CategoryDropdown from './CategoryDropdown';
import {
  AdvancedFilterButtonContainer,
  AdvancedFilterClearButton,
  SearchBarContainer
} from '../styles/Classifications.styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useClassificationStores } from '../services';
import { ClassificationAutoComplete } from './ClassificationAutoComplete';

type ClassificationSearchProps = {
  autoCompleteOptions?: any[];
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  categories?: any[];
  isClassified: boolean;
  activeTab: string;
};

const ClassificationSearch = (props: ClassificationSearchProps) => {
  const {
    autoCompleteOptions,
    filterOptions,
    categories,
    isClassified,
    activeTab
  } = props;

  const [terms, setTerms] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [tabInputData, setTabInputData] = useState([]);
  const [hasInput, setHasInput] = useState(false);

  const { classificationStore } = useClassificationStores();
  const {
    activitiesState,
    setCurrentSearchValue,
    setCategories: setFilterCategories
  } = classificationStore;
  const { currentSearchValue } = activitiesState;

  const handleOnKeyPress = (event: any) => {
    if (currentSearchValue && event.key === 'Enter') {
      const updatedTerms = [...terms, currentSearchValue];
      setTerms(updatedTerms);
    }
    const value = event.target.value;
    const term = value && value.trim().toLowerCase();
    if (term && event.key === 'Enter') {
      setCurrentSearchValue(term);
    } else {
      setCurrentSearchValue('');
    }
  };

  const handleClickChange = (event: any, value: any) => {
    const trimmedValue =
      value && typeof value === 'string' ? value.trim() : value;

    if (trimmedValue) {
      let term = '';
      if (trimmedValue.inputValue) {
        term = trimmedValue.inputValue;
      } else if (typeof trimmedValue === 'string') {
        term = trimmedValue;
      } else {
        term = trimmedValue.name;
      }
      setCurrentSearchValue(term);
    } else {
      setCurrentSearchValue('');
    }
  };

  const handleCategorySearch = () => {
    const selectedCategory = categories.find((c) => c.id === categoryId);
    const tabData = [...tabInputData];
    tabData.push({
      field: selectedCategory.id,
      fieldName: 'Category',
      fieldValue: selectedCategory.name
    });
    setCategoryId(null);
    setTabInputData(tabData);

    const newCategoryIds = tabData?.map((t) => t.field);
    setFilterCategories(newCategoryIds);
  };

  const handleInputChange = (event, value: string) => {
    const hasInputValue = value !== '';
    setHasInput(hasInputValue);
  };

  const handleCategorySearchClear = () => {
    setCurrentSearchValue('');
    setFilterCategories([]);
  };

  useEffect(() => {
    if (activeTab !== 'classified') {
      setTabInputData([]);
      handleCategorySearchClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      {tabInputData?.length ? (
        <TagBar tabBarData={tabInputData} />
      ) : (
        <ClassificationAutoComplete
          options={autoCompleteOptions}
          currentValue={currentSearchValue}
          onChange={handleClickChange}
          onInputChange={handleInputChange}
          onKeyPress={handleOnKeyPress}
          filterOptions={filterOptions}
        />
      )}

      {currentSearchValue !== '' ? null : (
        <SearchBarContainer>
          {isClassified && !hasInput ? (
            <AdvancedFilterButtonContainer>
              {tabInputData.length ? (
                <AdvancedFilterClearButton
                  onClick={() => {
                    setTabInputData([]);
                    handleCategorySearchClear();
                  }}
                  color="secondary"
                >
                  <ClearIcon />
                </AdvancedFilterClearButton>
              ) : null}
              <AdvancedSearchFilter
                onSubmit={() => handleCategorySearch()}
                onClear={() => {
                  setTabInputData([]);
                  setTerms([]);
                }}
                popoverWidth="375px"
              >
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                  <Grid item xs={3}>
                    <InputLabel htmlFor="category" sx={{ mt: 2 }}>
                      Category
                    </InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <CategoryDropdown
                      id="category"
                      categoryId={categoryId}
                      onChange={(e) => setCategoryId(e.target?.value)}
                      categories={categories}
                      isDisabled={false}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </AdvancedSearchFilter>
            </AdvancedFilterButtonContainer>
          ) : null}
        </SearchBarContainer>
      )}
    </>
  );
};

export default ClassificationSearch;
