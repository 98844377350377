import { http } from '_app/http';

const createDataSource = (path, mapClass, envConfig, showNotification) => {
    return new kendo.data.DataSource({
        transport: {
            read: async function (options) {
                try {
                    const results = await http.get(envConfig.apiUrl() + path);
                    const data =
                        _.map(results.data, (d) => new mapClass(d)) || [];
                    options.success(
                        data.sort((a, b) => a.text.localeCompare(b.text))
                    );
                } catch (e) {
                    showNotification(
                        'Error loading dashboard filter options.',
                        'danger'
                    );
                    options.error([]);
                }
            }
        }
    });
};

export { createDataSource };
