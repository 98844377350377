import theme from '../../../theme/theme';

export const cardHeadClasses = {
  container: {
    display: 'flex'
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noName: {
    width: '100%',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'grey'
  },
  textHolder: {
    fontWeight: 'bold',
    fontSize: 'inherit',
    flexGrow: 1
  },
  input: {
    padding: 0
  },
  accountId: {
    color: '#888888',
    fontWeight: 500
  },
  textCreate: {
    fontSize: 24,
    cursor: 'pointer'
  },
  divBorder: {
    padding: theme.spacing(),
    paddingLeft: theme.spacing(3)
  },
  divInput: {
    width: '100%'
  }
};
