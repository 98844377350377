import {
  restrictedRoutes,
  restrictions
} from '../../constants/restrictedRoutes';

export const isRouteRestricted = (
  url: string,
  isChildAccount?: boolean
): boolean => {
  const foundRoute = restrictedRoutes.find((route) => {
    return url.includes(route.routeUrl);
  });

  if (!foundRoute) {
    return false;
  }

  let routeRestricted = false;

  foundRoute.restrictions.forEach((restriction) => {
    // Check if route is restricted to child accounts
    if (
      restriction === restrictions.childAccountRestriction &&
      isChildAccount
    ) {
      routeRestricted = true;
    }

    // Check if route is restricted to small screen size
    if (restriction > window.innerWidth) {
      routeRestricted = true;
    }

    // Break out of loop if restriction found
    return !routeRestricted;
  });

  return routeRestricted;
};
