import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { errorMsg } from '../../constants';

export const SubheaderErrorDisplay = () => {
  return (
    <Typography>
      {errorMsg}
      <Typography component="span">
        &nbsp;If this error persists&nbsp;
        <Link href="mailto:support@activtrak.com">contact Support.</Link>
      </Typography>
    </Typography>
  );
};
