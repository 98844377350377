import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { WebhookLogReportWrapper } from '../utils/WebhookLogReportWrapper';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { WebhookLogReportHeader } from '../components/WebhookLogReportHeader';
import { useAuthorization } from '../../../common/services/Authorization';
import { BundleFlag } from '../../../common/enums/BundleFlag';

const WebhookLogReportView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  const authorization = useAuthorization();
  const isPaid = authorization.hasFeature(BundleFlag.PaidFunctionality);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      {isPaid && (
        <WebhookLogReportHeader
          reportFilters={reportFilters}
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
          refreshTimestamp={refreshTimestamp}
        />
      )}
      <WebhookLogReportWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
      />
    </>
  );
};

// export default WebhookLogReportView;

export const WebhookLogReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <WebhookLogReportView />
  </ReactivTrakComponentWrapper>
);
