import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import AddCategoryDialog from './AddCategoryDialog';

type CategoryButtonProps = {
  title?: string;
  resetDialog?: () => void;
  isDisabled?: boolean;
};

export default function AddCategoryButton(props: CategoryButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const { resetDialog, isDisabled } = props;

  const title = props.title ?? 'ADD NEW CATEGORY';

  const handleAddOpen = (event) => {
    setOpenDialog(true);
    event.stopPropagation();
  };

  const handleAddClose = (event) => {
    setOpenDialog(false);
    if (event.target.textContent == 'Create') {
      setNewCategoryName('');
      if (resetDialog) resetDialog();
    }
  };

  return (
    <Box display="flex" alignItems="left" onClick={(e) => e.stopPropagation()}>
      <Button
        onClick={(e) => handleAddOpen(e)}
        disabled={isDisabled}
        color="secondary"
        startIcon={<AddIcon />}
      >
        {title}
      </Button>
      <AddCategoryDialog
        open={openDialog}
        onClose={(e) => handleAddClose(e)}
        setNewCategoryName={setNewCategoryName}
        newCategoryName={newCategoryName}
      />
    </Box>
  );
}
