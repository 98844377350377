import quickStatsHeaderTemplate from 'views/widgets/dashboard/quickStatsHeader.html?raw';
import { getPresetLabel } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/dateRange.utils';
import { generateParameters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import { DateRanges } from '../../../../../_reactivtrak/src/common/components/ReportFilters/constants/dateRanges';
import lodash from 'lodash';

angular.module('app').directive('quickStatsHeader', function () {
    return {
        restrict: 'E',
        template: quickStatsHeaderTemplate,
        controller: quickStatsHeaderCtrl,
        scope: {}
    };
});

quickStatsHeaderCtrl.$inject = ['$scope', '$rootScope', '$state', 'dashboardApiService', 'templateServiceFunctions'];

function quickStatsHeaderCtrl($scope, $rootScope, $state, dashboardApiService, templateServiceFunctions) {
    $scope.productiveTemplate =
        "Productive Time<br/>(<span class=\"clickable at-gray-link\" onclick=\"openReport('web', 'Productive')\">Website</span> and <span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('apps', 'Productive')\">App</span>)";
    $scope.unproductiveTemplate =
        "Unproductive Time<br/>(<span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('web', 'Unproductive')\">Website</span> and <span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('apps', 'Unproductive')\">App</span>)";
    $scope.undefinedTemplate =
        "Undefined Time<br/>(<span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('web', 'Undefined')\">Website</span> and <span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('apps', 'Undefined')\">App</span>)";
    $scope.allTemplate =
        "All Time<br/>(<span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('web', 'All')\">Website</span> and <span class=\"clickable at-gray-link\" onclick=\"angular.element('[ui-view]').scope().openReport('apps', 'All')\">App</span>)";

    const bindWidget = function (reportFilters) {
        const { dates } = reportFilters;

        $scope.loading = true;
        $scope.inError = false;

        $scope.rangeLabel = dates.range === DateRanges.Custom ? 'Custom' : getPresetLabel(dates.range);

        // Preset object for loading graphics
        $scope.quickStatsHeader = {
            activities: {
                productive: '00h 00m',
                unproductive: '00h 00m',
                undefined: '00h 00m',
                all: '00h 00m'
            }
        };

        function formatTime(durations) {
            let time = 0;
            lodash.forEach(durations, function (duration) {
                if (duration) {
                    time += templateServiceFunctions.convertTimeStringToSeconds(duration);
                }
            });

            return templateServiceFunctions.friendlyViewTimeFormat(time, true);
        }

        const queryParameters = generateParameters(reportFilters, {});

        dashboardApiService
            .getQuickStats(queryParameters)
            .then(function (result) {
                const data = result.data;
                $scope.loading = false;
                $scope.quickStatsHeader = {
                    activities: {
                        productive: formatTime([
                            data.webProductive,
                            data.appProductive,
                            data.webProductivePassive,
                            data.appProductivePassive
                        ]),
                        unproductive: formatTime([
                            data.webUnproductive,
                            data.appUnproductive,
                            data.webUnproductivePassive,
                            data.appUnproductivePassive
                        ]),
                        undefined: formatTime([
                            data.webUndefined,
                            data.appUndefined,
                            data.webUndefinedPassive,
                            data.appUndefinedPassive
                        ]),
                        all: formatTime([data.webTotal, data.appTotal])
                    }
                };
            })
            .catch(function (e) {
                console.error(e);
                $scope.inError = true;
            });
    };

    $scope.bindWidget = bindWidget;

    $rootScope.openReport = function (report) {
        if (report === 'web') {
            $state.go('app.reports.topwebsites');
        } else {
            $state.go('app.reports.topapplications');
        }
    };
}
