import {
  OAUTH_ACCESS_TYPE,
  OAUTH_POPUP_HEIGHT,
  OAUTH_POPUP_WIDTH,
  OAUTH_PROMPT,

  OAUTH_RESPONSE_TYPE
} from '../constants/Oauth';
import { IClientOAuthConfig } from "../models/IClientOAuthConfig";

const objectToQuery = (object: Record<string, string>) => {
  return new URLSearchParams(object).toString();
};

export const openPopup = (clientConfig: IClientOAuthConfig, state: string) => {
  const query = objectToQuery({
    response_type: OAUTH_RESPONSE_TYPE,
    client_id: clientConfig.clientId,
    redirect_uri:`${clientConfig.redirectUri}`,
    access_type: OAUTH_ACCESS_TYPE,
    prompt: OAUTH_PROMPT,
    state: state,
    scope: clientConfig.scope
  });
  const url = `${clientConfig.authorizeUrl}?${query}`;
  const top = window.outerHeight / 2 + window.screenY - OAUTH_POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - OAUTH_POPUP_WIDTH / 2;
  const features = `height=${OAUTH_POPUP_HEIGHT},width=${OAUTH_POPUP_WIDTH},top=${top},left=${left}`;
  return window.open(url, 'OAuthPopup', features);
};

export const closeOAuthPopup = (
  popupRef: React.MutableRefObject<Window | null | undefined>
) => {
  popupRef.current?.close();
};

export const cleanup = (
  intervalRef: React.MutableRefObject<
    string | number | NodeJS.Timeout | undefined
  >,
  popupRef: React.MutableRefObject<Window | null | undefined>
) => {
  clearInterval(intervalRef.current);
  if (popupRef.current && typeof popupRef.current.close === 'function')
    closeOAuthPopup(popupRef);
};
