import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { gray5, gray7 } from '../../common/constants';
import { GTEUserAction, IGTEUsersColumnHeaderProps } from '../models';
import { IGridColumn } from '../../common/models';
import { GTEUsersDetails } from '../constants';

export const useGTEUsersColumns = ({
  handleOpenSetUsername,
  isSupport
}: IGTEUsersColumnHeaderProps): IGridColumn[] => {
  return [
    {
      field: 'username',
      headerName: 'Username',
      type: 'string',
      width: '40%',
      isSortable: false,
      align: 'left'
    },
    {
      field: 'logondomain',
      headerName: 'Logon Domain',
      type: 'string',
      width: '40%',
      isSortable: false,
      align: 'left',
      template: () => 'Any'
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: '20%',
      isSortable: false,
      align: 'right',
      template: (row) => (
        <Tooltip title={GTEUsersDetails.delete.button}>
          <IconButton
            onClick={(e) =>
              handleOpenSetUsername(e, row?.username, GTEUserAction.Delete)
            }
            disabled={isSupport}
          >
            <DeleteIcon sx={{ color: isSupport ? gray5 : gray7 }} />
          </IconButton>
        </Tooltip>
      )
    }
  ];
};
