// Use with caution. This was originally created to feed just the React router.
// It is now used in React to define redirect links and this will need to be refactored
// when we update the app routing.
export const Routes = {
  DashboardLegacy: '/home',
  DashboardAdmin: '/homepage/admin',
  DashboardOrganization: '/homepage/organization',
  DashboardManagement: '/homepage/management',
  Groups: '/settings/groups',
  Group: (id: number | string) => `/settings/groups/${id}`,
  ComputerAgents: '/settings/computeragents',
  UserAgents: '/settings/useragents',
  RoleAccess: '/settings/roleaccess',
  Aliases: '/settings/aliases',
  Schedule: '/settings/schedule/new',
  InstallAgentFile: '/settings/configuration',
  CreateImportGroup: '/settings/groups',
  Digests: '/settings/digest',
  AccessUsers: '/account/access',
  ProfileSettings: '/account/profile',
  Alarms: '/#/app/alarms',
  Alarm: (id: number) => `/#/app/alarms/${id}`,
  CreateAlarms: '/alarms',
  ClassificationsTab: (activeTab: string) =>
    `/settings/classification/${activeTab}`,
  Classification: (type: string, id: number | string) =>
    `/settings/classification/${type}/${id}`,
  Classifications: '/settings/classification',
  AccountConfiguration: '/settings/configuration',
  Timezone: '/settings/timezone',
  InsightsSubscriptions: '/reports/subscriptions',
  BenchmarksAndGoals: '/reports/insights/benchmarksandgoals',
  ActivityLog: (params: any) => {
    let urlParams = '?';
    const paramKeys = Object.keys(params);
    paramKeys.forEach((key) => {
      urlParams += `${key}=${params[key]}&&`;
    });
    return `/#/app/reports/activitylog${urlParams}`;
  },
  TopCategories: '/#/app/reports/topcategories',
  AccountWizard: `/account/wizard`,
  ComputerAlias: '/settings/computerAlias',
  UserAlias: '/settings/aliases',
  ChromebookUsers: '/account/chromebookUsers',
  ApiKey: '/integrations/apikeys',
  AgentActivation: `/agentactivation`,
  ChartsDemo: '/charts',
  IconsDemo: '/icons',
  TopUsers: '/reports/topusers',
  TopUser: (user: number | string) => `/reports/topusers?user=${user}`,
  TopUserByGroup: (groupName: string) => `/reports/topusers?name=${groupName}`,
  TeamPulse: '/teampulse',
  ExecutiveSummary:
    '/reports/insights/executivesummary?modelId=activinsights&pageId=executive_summary',
  Identities: '/settings/users',
  IdentityDetail: (id: number | string) => `/settings/users/${id}`,
  Upgrade: '/account/upgrade',
  SupportTickets: '/help/tickets',
  LatestUpdates: '/help/latestupdates',
  ActivTrakProfile: '/account/profile',
  Resources: 'https://www.activtrak.com/resources/',
  HelpCenter: 'https://support.activtrak.com/hc/en-us',
  ActivTrakStatus: 'https://status.activtrak.com/',
  CommandCenterDashboard: '/#/cc/dashboard',
  SupportPortal: '/#/support',
  Blocking: '/blocking',
  DoNotTrack: '/settings/doNotTrack',
  Schedules: '/settings/scheduling',
  WorkingHoursReport: '/reports/workingHours',
  BaseRoute: '/#/app'
};

export const AnchorTags = {
  GTEUsers: '#gte_users'
};
