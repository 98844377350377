import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { customInputClasses } from './CustomInput.styles.js';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CustomInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('This field is required');

  useEffect(() => {
    if (props.error && props.type === 'email') {
      setErrorMessage('Enter a valid email');
    }
  }, [props.error, props.type]);

  const validateRequired = (event) => {
    const input = event.target.value.trim();
    const phonePattern = new RegExp('^[0-9+\\(\\)#\\.\\s\\/ext-]+$');
    const emailPattern = new RegExp(
      '^(?:(?:[^<>()[\\]\\\\.,;:\\s@"=]+(?:\\.[^<>()[\\]\\\\.,;:\\s@"=]+)*)|(".+"))@(?:(\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(?:(?:[a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    );
    let error = false;
    if (props.required && !input) {
      error = true;
      setErrorMessage('This field is required');
    }
    if (props.type === 'phone' && !phonePattern.test(input)) {
      error = true;
      setErrorMessage('Must be a valid phone number');
    }
    if (props.type === 'email' && !emailPattern.test(input)) {
      error = true;
      setErrorMessage('Enter a valid email');
    }
    if (props.type === 'password' && input.length && input.length < 8) {
      error = true;
      setErrorMessage('Minimum of 8 characters');
    }
    props.onBlur(event, error);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderInputType = () => {
    if (props.type === 'password') {
      return (
        <TextField
          type={showPassword ? 'text' : 'password'}
          value={props.value}
          onChange={props.onChange}
          onBlur={validateRequired}
          name="password"
          variant="outlined"
          label="Password"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  sx={customInputClasses.visibilityButton}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      );
    }

    return (
      <TextField
        {...props}
        onBlur={validateRequired}
        onChange={props.onChange}
        variant="outlined"
        inputProps={{
          form: {
            autocomplete: 'off'
          }
        }}
        fullWidth
      />
    );
  };
  return (
    <FormControl
      sx={customInputClasses.textField}
      error={props.error}
      autoComplete="off"
      variant="outlined"
    >
      {renderInputType()}
      {props.required && (
        <Typography
          component="span"
          className={props.error ? 'required-error' : ''}
          sx={customInputClasses.required}
        ></Typography>
      )}
      {props.error && props.displayerrors === 1 && (
        <FormHelperText sx={customInputClasses.error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomInput.defaultProps = {
  displayerrors: 1
};

export default CustomInput;
