import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { SupportAgent } from '@mui/icons-material';
import { LoadingContent } from '../styles/supportPortal.styles';

export const RedirectingModal = () => {
  return (
    <LoadingContent>
      <SupportAgent sx={{ paddingBottom: 2, fontSize: '75px' }} />
      <Typography variant="h4" pb={2}>
        Connecting to Customer Account...
      </Typography>
      <CircularProgress />
    </LoadingContent>
  );
};
