import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Card, IconButton, Typography } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const AddAccountContainer = styled(Card)`
  max-width: 215px;
  height: 100px;
  text-align: center;
`;

export const AccountInfoContainer = styled(Card)`
  text-align: center;
  width: 215px;
  height: 240px;
`;

export const LongTextToolTip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none'
  }
});

export const LongLabel = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled(Box)`
  margin-top: 2px;
  margin-right: 5px;
`;

export const CardActionsButtonGroup = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
`;

export const ButtonOutline = styled(IconButton)`
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const LoadingContent = styled(Box)`
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const TabPanel = styled(Box)`
  flex-grow: 1;
  display: flex;
  & .MuiTabs-root {
    overflow: visible;
  }
`;

export const TabContentDiv = styled(Box)`
  overflow: auto;
`;

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));
