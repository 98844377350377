import { react2angular } from 'react2angular';
import { DigestViewComponent } from '../../_reactivtrak/src/digest/views/Digest.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsDigest', react2angular(DigestViewComponent, []));

}
