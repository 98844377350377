import { apiService } from '../../common/helpers';
import {
  IPerformanceOptimizationService,
  ILiveReportSettingsDto
} from '../models';
import { getDefaultConfig } from '../../common/config';
import { HttpResponse } from '../../common/models';

export const PerformanceOptimizationService =
  (): IPerformanceOptimizationService => {
    return {
      postLiveReportSettings: async (
        payload: boolean
      ): Promise<HttpResponse<any>> => {
        return apiService.post<HttpResponse<object>>(
          '/api/settings/live_report_settings',
          {
            ...getDefaultConfig(),
            data: payload
          }
        );
      },
      fetchLiveReportSettings: async () => {
        const response = await apiService.get<ILiveReportSettingsDto>(
          '/api/settings/live_report_settings',
          {
            ...getDefaultConfig(),
            params: { ignoreLoadingBar: true }
          }
        );
        return response?.data;
      }
    };
  };
