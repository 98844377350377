'use strict';

angular.module('app').directive('verificationOptions', function () {
    return {
        restrict: 'E',
        scope: {
            isModal: '@',
            owner: '@'
        },
        template: require('views/widgets/verificationOptions.html'),

        controller: [
            '$scope',
            'messagesService',
            'verificationApiService',
            'AccountSettingsService',
            'notificationService',
            function ($scope, msg, verificationApiService, AccountSettingsService, notificationService) {
                $scope.email = AccountSettingsService.username || $scope.owner;

                $scope.sendVerificationEmail = function (e) {
                    e.preventDefault();
                    var payload = {};
                    payload.email = $scope.email;

                    verificationApiService
                        .sendEmail(payload)
                        .then(function () {
                            if (!$scope.isModal) {
                                $scope.$emit('showNotification', {
                                    message: msg.get('verificationEmailSent'),
                                    color: 'success'
                                });
                            } else {
                                //$uibModalInstance.close();
                                $scope.$emit('dismiss-verification-modal');
                            }
                        })
                        .catch(function (response) {
                            if (response.status === 429) {
                                $scope.$emit('dismiss-verification-modal');
                            } else {
                                notificationService.showNotification(
                                    'ActivTrak Error: An error occurred trying to confirm with the Verification Service',
                                    'danger'
                                );
                                $scope.$emit('dismiss-verification-modal');
                            }
                        });
                };
            }
        ]
    };
});
