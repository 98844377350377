import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import {
  getParsedLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from '../../common/helpers';
import { AddAccountButton, CardsContainer } from '../components';
import {
  checkStoredAccounts,
  getBackupObjectString,
  getBackupToken,
  hasBackupObject
} from '../utils';
import { supportPortalLocalStorage } from '../constants';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { INewlyAddedAccount } from '../models/IAccountData';

const SupportPortalView = () => {
  const [payload, setPayload] = useState<IAddAccountPayloadDto>(null);

  const localStorageAccounts: INewlyAddedAccount[] = getParsedLocalStorageItem(
    supportPortalLocalStorage.accounts
  );

  const [savedAccounts, setSavedAccounts] = useState<INewlyAddedAccount[]>(
    checkStoredAccounts(localStorageAccounts)
  );
  const [actionType, setActionType] = useState<string>('');

  useEffect(() => {
    // get backup token and replace globals with backup
    if (hasBackupObject()) {
      setLocalStorageItem(
        supportPortalLocalStorage.globals,
        getBackupObjectString()
      );
      userServiceFunctions.setUserToken(getBackupToken());
      removeLocalStorageItem(supportPortalLocalStorage.backup);
    }
  }, []);

  return (
    <Grid container p={4} ml={30} spacing={3}>
      <Grid item xs={12} sm>
        <Stack spacing={2}>
          <AddAccountButton
            setPayload={setPayload}
            savedAccounts={savedAccounts}
            setActionType={setActionType}
          />
          <CardsContainer
            payload={payload}
            setSavedAccounts={setSavedAccounts}
            savedAccounts={savedAccounts}
            actionType={actionType}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export const SupportPortal = () => (
  <ReactivTrakComponentWrapper>
    <SupportPortalView />
  </ReactivTrakComponentWrapper>
);
