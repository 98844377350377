import { useCallback, useState } from 'react';
import { ApiRoutes } from '../constants';
import {
  buildReportsApiRequest,
  checkAllValuesAreZero,
  mapToUsersTodaysProductivity
} from '../utils';
import {
  ITodaysProductivityGoalUsersState,
  ITodaysProductivityUsers,
  IReportModalListItem,
  ITodaysProductivityUsersDto
} from '../models';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useTodaysProductivityGoalUsersState =
  (): ITodaysProductivityGoalUsersState => {
    const [todaysGoalStats, setTodaysGoalStats] =
      useState<ITodaysProductivityUsers>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTileError, setIsTileError] = useState<boolean>(false);
    useState<IReportModalListItem[]>();
    const [hasNoResults, setHasNoResults] = useState<boolean>();

    const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
      setIsLoading(true);
      setIsTileError(false);

      try {
        const response =
          await buildReportsApiRequest<ITodaysProductivityUsersDto>({
            path: ApiRoutes.reports.fetchTodaysProductivityUsers,
            params: { groupId: reportFilters.groupId[0] }
          });
        const mapped = mapToUsersTodaysProductivity(response);

        setTodaysGoalStats(mapped);

        const isZeroState = checkAllValuesAreZero([
          mapped.achieved,
          mapped.goal,
          mapped.previous,
          mapped.total
        ]);

        setHasNoResults(isZeroState);
      } catch (error) {
        console.error(
          `ActivTrak Error: Today's Productivity Goal Tile: ${error}`,
          error
        );
        setIsTileError(true);
      } finally {
        setIsLoading(false);
      }
    }, []);

    return {
      init,
      todaysGoalStats,
      isLoading,
      isTileError,
      hasNoResults
    };
  };
