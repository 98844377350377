/*
PIE CHART
https://react-chartjs-2.js.org/components/pie
https://www.chartjs.org/docs/latest/charts/doughnut.html#pie
*/
import React, { useEffect, useState } from 'react';
import 'chart.js/auto'; //https://www.chartjs.org/docs/latest/getting-started/integration.html
import { Pie } from 'react-chartjs-2';
import ChartsDemoService from '../services/ChartDemoServices';
import { processPieData } from '../utils';

export default function GooglePieChart() {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const apiParams = {
          from: '2022-06-08',
          to: '2022-06-13',
          userId: '-1',
          userType: 'AllUsers',
          mode: 'users',
          range: 'Custom_Date_Range',
          type: 'All'
        };

        const chartsDemoService = new ChartsDemoService();
        const response = await chartsDemoService.getTopApplications(apiParams);

        if (response) {
          const filteredData = processPieData(response);
          const options = {
            plugins: {
              legend: {
                position: 'right'
              }
            }
          };

          setChartData(filteredData);
          setChartOptions(options);
        }
      } catch (error) {
        console.error(error);
      }
    };

    //retrigger api when params are updated
    if (!chartData) {
      getData();
    }
  }, [chartData]);

  return (
    <>
      {!chartData && chartOptions && <div>Loading Chart</div>}
      {!chartData?.length && <div>No data available</div>}
      {chartData?.length && <Pie data={chartData} options={chartOptions} />}
    </>
  );
}
