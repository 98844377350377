import { IActivityDetail, IActivityDetailDto } from '../models';
import { mapToActivityType, getActivityUniqueId } from '../../common/helpers';

export function mapToActivityDetail(a: IActivityDetailDto): IActivityDetail {
  const type = mapToActivityType(a.type);
  return {
    id: a.activityId,
    uniqueId: getActivityUniqueId(a.activityId, type),
    type: type,
    titles: a.titles,
    users: a.users,
    subpages: a.subpages,
    totalUsers: a.totalUsers,
    dateAdded: a.dateAdded
  };
}
