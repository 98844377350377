import { Routes } from './routesUrls';
import { IRestrictions, IRestrictedRoute } from '../models';

export const restrictions: IRestrictions = {
  smallScreenBreakpoint: 993,
  childAccountRestriction: -3
};

export const restrictedRoutes: IRestrictedRoute[] = [
  {
    routeUrl: Routes.Upgrade,
    restrictions: [restrictions.childAccountRestriction]
  },
  {
    routeUrl: Routes.Blocking,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.Groups,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.UserAgents,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.ComputerAgents,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.UserAlias,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.ComputerAlias,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.DoNotTrack,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.Schedules,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.AccessUsers,
    restrictions: [restrictions.smallScreenBreakpoint]
  },
  {
    routeUrl: Routes.WorkingHoursReport,
    restrictions: [restrictions.smallScreenBreakpoint]
  }
];
