import React, { useState } from 'react';
import { FormatListBulleted } from '@mui/icons-material';
import { DetailsModal } from './';
import { ButtonOutline } from '../styles/supportPortal.styles';
import { IDetailsButtonProps } from '../models/IComponentProps';

export const DetailsButton = (props: IDetailsButtonProps) => {
  const { accountCredentials } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <ButtonOutline onClick={() => setOpenModal(true)}>
        <FormatListBulleted />
      </ButtonOutline>
      <DetailsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        accountCredentials={accountCredentials}
      />
    </>
  );
};
