import theme from '../../../../common/theme/theme';

const getNameStyle = (activeStatus, type) => {
    let color = theme.palette.common.black;

    if (activeStatus === 'inactive') {
        color = theme.palette.info.lightest;
    }

    return {
        width: '100%',
        fontSize: `${theme.atCustom.fontSize.title}px !important`,
        fontWeight: 600,
        marginLeft: type === 'user' ? theme.spacing(1) : 0,
        marginTop: '5px',
        color,
        '& a': { color: `${color} !important`, cursor: 'pointer' }
    };
};

const getIconStyles = (activeStatus) => {
    let color;
    switch (activeStatus) {
        case 'active':
            color = theme.palette.secondary.main;
            break;
        case 'passive':
            color = theme.palette.passive.main;
            break;
        default:
            color = theme.palette.activTrak.loading;
    }

    return {
        fontSize: `${theme.atCustom.fontSize.titleIcon}px`,
        fontWeight: 600,
        display: 'inline-block',
        marginTop: '6px',
        color
    };
};

export const getClasses = (activeStatus, type) => ({
    root: {
        minHeight: 31,
        textAlign: 'left'
    },
    content: {
        display: 'flex'
    },
    name: getNameStyle(activeStatus, type),
    icon: getIconStyles(activeStatus),
    grow: {
        flexGrow: 1
    },
    buttonIcon: {
        fontSize: `${theme.atCustom.fontSize.infoIcon}px`
    },
    ratio: {
        marginTop: '5px',
        color: theme.palette.productive.main,
        fontSize: `${theme.atCustom.fontSize.title}px`,
        fontWeight: 600,
        '& a': { color: `${theme.palette.productive.main}` }
    },
    skeletonWrapper: {
        width: '100%'
    },
    skeletonIcon: {
        marginTop: 5
    }
});
