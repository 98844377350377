'use strict';

// TODO: Rename to sidebarSetup
import favoriteNavigationSetup from '../favoriteNavigationSetup.js';

angular.module('app').controller('SupportPortalCtrl', SupportPortalCtrl);

SupportPortalCtrl.$inject = ['$scope', '$state', '$injector', '$rootScope'];

function SupportPortalCtrl($scope, $state, $injector, $rootScope) {
    favoriteNavigationSetup($scope, $state, $injector, $rootScope);
    $rootScope.sidebar.showSidebar();
}
