import React from 'react';
import {
  fetchExportStatus,
  hasExportsCompleted,
  hasExportsError,
  hasExportsInProgress
} from '../stores/exportStore';
import { hideModal, showModal } from '../stores/globalModalStore';
import { ExportCanceledModalContent } from '../../_global/modals/components/ExportCanceledModalContent';
import { ExportErrorModalContent } from '../../_global/modals/components/ExportErrorModalContent';
import { SaveExportModalContent } from '../../_global/modals/components/SaveExportModalContent';
import { ExportInitiatedModalContent } from '../../_global/modals/components/ExportInitiatedModalContent';
import {
  cancelExport,
  createExports,
  fetchExportLink
} from './exportStore.utils';
import { STATUS_POLLING_INTERVAL } from '../constants/exportStoreDefault';
import { SaveExportType } from '../../_global/modals/enums/SaveExportType';

export const startExportStatusCheck = async (exportStore) => {
  clearInterval(exportStore.exportStatusInterval);

  exportStore.exportStatusInterval = setInterval(async () => {
    const exportStore = await fetchExportStatus();
    if (!hasExportsInProgress()) {
      stopExportStatusCheck(exportStore.exportStatusInterval);
    }

    if (hasExportsCompleted()) {
      // USED AS PLACEHOLDER - WILL DELETE IN LATER TICKET
      const sampleContent = SaveExportType.Google;

      showModal(<SaveExportModalContent saveType={sampleContent} />);
    }

    if (hasExportsError()) {
      showModal(<ExportErrorModalContent />);
    }

    // USED FOR TESTING STORE - WILL BE REMOVED IN LATER TICKET
    console.log('Export store:', exportStore);
  }, STATUS_POLLING_INTERVAL);
};

export const stopExportStatusCheck = (interval) => {
  clearTimeout(interval);
};

export const cancelCurrentExport = async (interval) => {
  stopExportStatusCheck(interval);
  await cancelExport();

  showModal(<ExportCanceledModalContent />);
};

export const openExportLink = async (id) => {
  const link = await fetchExportLink(id);
  link.href = link;
  link.target = '_blank';
  link.click(); // opens in new tab
};

export const createExport = async (exportOptions) => {
  await createExports(exportOptions);

  const exportStore = await fetchExportStatus();
  startExportStatusCheck(exportStore);
  showModal(<ExportInitiatedModalContent />);
};

export const closeExportModal = () => {
  hideModal();
};

export const saveExport = () => {
  // Open save dialog
};

export const goToGoogleDrive = () => {
  // Open Google Drive
};
