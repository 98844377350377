import { INotificationService } from '../../common/services/Notifications';
import { notificationMessage } from '../constants';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { IError } from '../models/IRandomTypes';

export const handleDuplicateAccount = (
  notificationService: INotificationService,
  newAccountId: string
) => {
  notificationService.error(notificationMessage.duplicateAccountAlert);
  console.error(notificationMessage.duplicateAccountError(newAccountId));
};

export const handleFetchError = (
  error: IError,
  payload: IAddAccountPayloadDto
) => {
  let errorMessage: string;
  if (
    error?.response?.status === 400 &&
    error?.response?.data === 'Account not found'
  ) {
    errorMessage = `${error.response.data} for ${
      payload.accountId || payload.username
    }.`;
  }
  console.error(errorMessage);
};
