'use strict';

angular.module('app')
    .controller('DownloadsCtrl', DownloadsCtrl);

DownloadsCtrl.$inject = ['$scope', 'downloadService'];

function DownloadsCtrl($scope, downloadService) {

    downloadService.getDownloads()
        .success(function (data) {
            $scope.files = data;
        });
}

angular.module('app')
    .service('downloadService', DownloadService);

DownloadService.$inject = ['$http', 'envConfig'];

function DownloadService($http, envConfig) {
    this.getDownloads = function () {
        return $http.get(envConfig.apiUrl() + '/api/downloads');
    };
}
