import React from 'react';
import { isEmpty, convertSecondsToHoursString } from '../utilities/helpers';
import { hasRole } from '../utilities/userRoles';

class TopCategory {
  constructor({ topCategories, type }) {
    const _generateFilteredCategories = (categories) => {
      return (
        categories &&
        categories
          .filter((category) => category.name) // Remove empty categories
          .sort((a, b) => b.duration - a.duration) // Sort categories by duration
      );
    };

    const _generateTopCategory = (filteredCategories) => {
      return isEmpty(filteredCategories) ? null : filteredCategories[0];
    };

    const _generateCategoryName = (topCategory) => {
      return topCategory && topCategory.name;
    };

    const _generateTopCategoryLink = (topCategoryName) => {
      const isSupportAdvanced = hasRole('SupportAdvanced');

      return topCategoryName ? (
        isSupportAdvanced ? (
          <p id="id_4ce65b21-5e3c-46a8-a933-cb43c2a1963c">{topCategoryName}</p>
        ) : (
          <a
            id="id_4ce65b21-5e3c-46a8-a933-cb43c2a1963c"
            href={`/#/app/reports/topcategories?category=${encodeURIComponent(topCategoryName)}&tab=users`}
          >
            {topCategoryName}
          </a>
        )
      ) : (
        'No Category'
      );
    };

    this.type = type;
    this.filteredCategories = _generateFilteredCategories(topCategories);
    this.topCategory = _generateTopCategory(this.filteredCategories);
    this.name = _generateCategoryName(this.topCategory);
    this.link = _generateTopCategoryLink(this.name);
    this.duration = this.topCategory ? this.topCategory.duration : 0;
    this.durationLabel = convertSecondsToHoursString(this.duration, 'hrs');
  }
}

export default TopCategory;
