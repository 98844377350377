import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { insightsReportTemplate as angularJsTemplate } from '../../templates';

export const appReportsInsightsActivityBreakdown: IRoute = {
  name: 'app.reports.insightsActivityBreakdown',
  stateDefinition: {
    url: '/insights/activitybreakdown?modelId&pageId&subPageId&tempFilters',
    params: {
      modelId: null,
      pageId: null,
      subPageId: null,
      tempFilters: null,
      insightsRange: null
    },
    template: angularJsTemplate,
    controller: 'insightsReportCtrl',
    data: { pageTitle: 'Insights - Activity Breakdown' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.ActivityBreakdown,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.ActivityBreakdownInsightsReport]
  }
};

export default appReportsInsightsActivityBreakdown;
