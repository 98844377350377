import React, { useCallback, useEffect, useState } from 'react';
import WidgetLink from '../../../common/components/WidgetLink';
import TopUsersGroupsChart from './TopUsersGroupsChart';
import TopUsersGroupsHeader from './TopUsersGroupsHeader';
import { useTopUsersGroupsState } from '../../hooks';
import { TopGroupUsersBar } from '../../models/ITopUsersGroupsTypes';
import { LoaderContainer } from '../../../common/components/Grid/CustomGrid.styles';
import { useDefaultSkeletonLoader } from '../../../common/components/Grid/CustomGrid.utils';
import TemplateShadow from '../templates/TemplateShadow';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  NoResultsWidgetBox,
  useWidgetTemplateStyles as templateClasses
} from '../../styles/templates.styles';
import { BarsExpandCollapse } from '../../styles/TopUsersGroups.styles';

export default function TopUsersGroups() {
  const [collapsedData, setCollapsedData] = useState<boolean>();
  const [currentChartData, setCurrentChartData] =
    useState<TopGroupUsersBar | null>(null);

  const defaultSkeletonLoader = useDefaultSkeletonLoader(1, false);

  const widgetState = useTopUsersGroupsState();
  const {
    init,
    getData,
    widgetMode,
    setWidgetMode,
    noResults,
    setNoResults,
    truncatedData,
    chartData,
    sortMode,
    setSortMode,
    filterMode,
    setFilterMode,
    isWidgetStateLoading
  } = widgetState;

  const handleExpandCollapse = useCallback(
    (action: string) => {
      if (action === 'expand') {
        setCollapsedData(false);
        setCurrentChartData(chartData);
      } else if (action === 'collapse') {
        setCollapsedData(true);
        setCurrentChartData(truncatedData);
      }
    },
    [chartData, truncatedData]
  );

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWidgetStateLoading) {
      if (noResults && !chartData) {
        setCurrentChartData(null);
      } else if (truncatedData) {
        handleExpandCollapse('collapse');
        setNoResults(false);
      } else if (chartData) {
        setCurrentChartData(chartData);
        setNoResults(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    truncatedData,
    isWidgetStateLoading,
    chartData,
    noResults,
    filterMode,
    sortMode
  ]);

  useEffect(() => {
    if (!isWidgetStateLoading) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetMode, filterMode, sortMode]);

  return (
    <TemplateShadow>
      <Grid item xs={12} sx={templateClasses.singleContentHeader}>
        <WidgetLink
          text="Today's Top Users and Groups"
          redirectTo={'app.reports.topusers'}
          redirectParams={{
            groupBy: widgetMode,
            dateFilter: 'today',
            userFilter: 'allusers'
          }}
          id="e07ff13e-bd3b-11ed-afa1-0242ac120002"
        />
      </Grid>
      {filterMode && (
        <TopUsersGroupsHeader
          noResults={noResults}
          isWidgetStateLoading={isWidgetStateLoading}
          widgetMode={widgetMode}
          onChangeWidget={setWidgetMode}
          sortMode={sortMode}
          onChangeSort={setSortMode}
          filterMode={filterMode}
          onChangeFilter={setFilterMode}
          defaultSkeletonLoader={defaultSkeletonLoader}
        />
      )}
      {isWidgetStateLoading && !currentChartData && (
        <LoaderContainer loaderHeight={'65vh'}>
          {defaultSkeletonLoader}
        </LoaderContainer>
      )}
      {(noResults ||
        (widgetMode === 'groups' && !currentChartData?.labels.length)) && (
        <NoResultsWidgetBox>No data to display</NoResultsWidgetBox>
      )}
      {!noResults && currentChartData && (
        <TopUsersGroupsChart
          chartData={currentChartData}
          widgetMode={widgetMode}
          filterMode={filterMode}
        />
      )}

      {!noResults && truncatedData && collapsedData && (
        <Box textAlign="center">
          <BarsExpandCollapse onClick={() => handleExpandCollapse('expand')}>
            Show More
          </BarsExpandCollapse>
        </Box>
      )}

      {!noResults && truncatedData && !collapsedData && (
        <Box textAlign="center">
          <BarsExpandCollapse onClick={() => handleExpandCollapse('collapse')}>
            Show Less
          </BarsExpandCollapse>
        </Box>
      )}
    </TemplateShadow>
  );
}
