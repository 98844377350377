import { Role } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsComputeragents: IRoute = {
  name: 'app.settings.computeragents',
  stateDefinition: {
    url: '/settings/computeragents',
    template: '<settings-computer-agents></settings-computer-agents>',
    data: { pageTitle: 'Computer Agents' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsComputeragents;
