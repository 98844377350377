import React from 'react';
import { SearchBar, ISearchBarProps } from '../../common/components/SearchBar';

export default function DigestSearchBar(props: ISearchBarProps) {
  return (
    <SearchBar
      {...props}
      placeholder={props.filter || 'Search by ActivTrak ID or Name'}
    />
  );
}
