import { Status } from '../../common/enums/StatusType';
import { ITodaysActivityStatusProps } from '../models';
import { blue4, blue7, gray5 } from '../../common/constants';

export const statuses: Partial<ITodaysActivityStatusProps>[] = [
  {
    statusType: Status.Active,
    iconColor: blue4,
    activityStatus: 1,
    groupsUserCountLabel: 'activeUsersCount',
    id: '08a810ba-caec-420f-b0da-7f603a629096'
  },
  {
    statusType: Status.Passive,
    iconColor: blue7,
    activityStatus: 2,
    groupsUserCountLabel: 'passiveUsersCount',
    id: 'e52f861f-44ae-4a4f-a43e-3e3a98b7673d'
  },
  {
    statusType: Status.Inactive,
    iconColor: gray5,
    activityStatus: 3,
    groupsUserCountLabel: 'inactiveUsersCount',
    id: '01af9b2e-851f-4ecb-9b45-40a087f59e94'
  }
];
