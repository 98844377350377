import { IAgentSearch } from '../models/IAgentSearch';
import { IFieldUpdateDto } from '../models/IFieldEditDto';
import {
  IValueValidation,
  IIdentityResults,
  IUserAgent,
  IUserIdentity,
  IValueValidationPrimary
} from '../models/IUserIdentity';
import {
  IIdentityResultsDto,
  IUserAgentDto,
  IUserIdentityCreateDto,
  IUserIdentityDto
} from '../models/IUserIdentityDto';
import { IValueId, IValuePrimary } from '../models/IValueId';

export function mapToIdentityResults(
  identityResultsDto: IIdentityResultsDto
): IIdentityResults {
  return {
    cursor: identityResultsDto.cursor,
    results: identityResultsDto.results.map(mapToIdentity)
  };
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function emptyIfFalse(val: string) {
  if (val) return val;
  return '';
}

function mapNameValues(identityDto: IUserIdentityDto): string {
  if (identityDto) {
    const { firstName, middleName, lastName } = identityDto;
    const hasAnyNameValue =
      (firstName || middleName || lastName) &&
      (firstName?.value.length > 0 ||
        middleName?.value.length > 0 ||
        lastName?.value.length > 0);
    let displayNameFirst = null;
    const middleNameSpace = middleName?.value ? ' ' : '';
    if (hasAnyNameValue) {
      displayNameFirst =
        emptyIfFalse(firstName?.value) +
        ' ' +
        emptyIfFalse(middleName?.value) +
        middleNameSpace +
        emptyIfFalse(lastName?.value);
    }

    return displayNameFirst;
  }
}

function mapAgentValues(identityDto: IUserIdentityDto): any {
  const mappedAgents = identityDto.agents
    ?.map((agent) => mapToAgent(agent))
    .filter((x) => x !== null);
  const validLastLogs = mappedAgents?.map((agent) =>
    agent.lastLog ? agent.lastLog : null
  );
  const agentsWithUserNames = mappedAgents?.filter((x) => x?.userName);
  const maxDate =
    validLastLogs?.length > 0 ? Math.max.apply(null, validLastLogs) : null;
  const csvUsers = agentsWithUserNames
    ?.map((agent) => agent.userName)
    .filter(onlyUnique)
    .join(', ');
  const firstUserId =
    identityDto.agents?.length > 0 ? identityDto.agents[0].userId : 0;
  return {
    csvUsers,
    maxDate,
    mappedAgents,
    firstUserId
  };
}

function getEmailDisplay(identityDto: IUserIdentityDto): string {
  if (!identityDto.emails) {
    return null;
  }
  let emailValue = null;

  identityDto.emails.forEach((email) => {
    if (email.primary) {
      emailValue = email.value;
    }
  });

  if (!emailValue) {
    identityDto.emails.forEach((email) => {
      if (!emailValue) {
        emailValue = email.value;
      }
    });
  }

  return emailValue;
}

export function mapToIdentity(identityDto: IUserIdentityDto): IUserIdentity {
  if (identityDto) {
    const { firstName, middleName, lastName, displayName } = identityDto;
    const displayNameFirst = mapNameValues(identityDto);
    const { csvUsers, maxDate, mappedAgents, firstUserId } =
      mapAgentValues(identityDto);
    const emailsDisplay = getEmailDisplay(identityDto);

    return {
      firstUserId: firstUserId,
      csvUsers: csvUsers,
      maxLastLog: maxDate,
      emails:
        identityDto.emails?.map((email) =>
          mapToValueValidationPrimary(email)
        ) ?? [],
      employeeId: mapToValueValidation(identityDto.employeeId),
      id: identityDto.id,
      agents: mappedAgents ?? [],
      userCount: mappedAgents?.length ? mappedAgents.length : 0,
      emailsDisplay: emailsDisplay,
      nameColumnDisplay: displayNameFirst,
      firstName: mapToValueValidation(firstName),
      middleName: mapToValueValidation(middleName),
      lastName: mapToValueValidation(lastName),
      displayName: mapToValueValidation(displayName),
      revision: identityDto.revision,
      selected: false,
      tracked: identityDto.tracked,
      singleDescription:
        displayName?.value ??
        emailsDisplay ??
        displayNameFirst ??
        identityDto?.id?.toString() ??
        ''
    };
  }
  return null;
}

function mapToValueValidation(valueId: IValueId): IValueValidation {
  if (valueId) {
    return {
      value: valueId.value,
      originalValue: valueId.value,
      failedExplanation: ' ',
      validationFailed: false
    };
  }
  return null;
}

function mapToValueValidationPrimary(
  valueId: IValuePrimary
): IValueValidationPrimary {
  if (valueId) {
    return {
      value: valueId.value,
      originalValue: valueId.value,
      failedExplanation: ' ',
      validationFailed: false,
      primary: valueId.primary
    };
  }
  return null;
}

export function mapToAgent(agent: IUserAgentDto): IUserAgent {
  return {
    userId: agent.userId,
    lastLog: agent.lastLog ? new Date(agent.lastLog) : null, //+'Z') : null,
    firstLog: agent.firstLog ? new Date(agent.firstLog) : null, //+'Z') : null,
    alias: agent.alias,
    userName: agent.userName,
    logonDomain: agent.logonDomain,
    revision: null,
    selected: false,
    singleDescription: agent.alias ?? agent.userName ?? agent.userId.toString(),
    tracked: agent.tracked
  };
}

export function mapToIdentityCreateDto(
  identity: IFieldUpdateDto,
  userAgent: IAgentSearch
): IUserIdentityCreateDto {
  return {
    firstName: identity.firstName,
    middleName: identity.middleName,
    lastName: identity.lastName,
    displayName: identity.displayName,
    employeeId: identity.employeeId,
    email: identity.email,
    userid: userAgent?.userId ? { value: userAgent?.userId?.toString() } : null,
    entity: userAgent?.entityId
      ? { value: userAgent?.entityId?.toString() }
      : null,
    revision: userAgent?.revision
      ? { value: userAgent?.revision?.toString() }
      : null
  };
}
