class Activity {
  constructor(data) {
    this.id = data.activityId;
    this.name = data.name;
    this.duration = data.duration;
    this.type = data.activityType;
  }
}

export default Activity;
