import theme from '../../theme/theme';

export const customerCardClasses = {
  customerCard: {
    textAlign: 'center',
    position: 'relative'
  },
  activeAlarms: {
    paddingBottom: theme.spacing(4)
  },
  cardActions: {
    position: 'absolute',
    bottom: 4,
    right: 4
  }
};
