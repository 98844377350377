import { apiService } from '../../common/helpers';
import { IPassiveTimeSettingsService } from '../models/IPassiveTimeSettingsService';
import { getDefaultConfig } from '../../common/config';
import { IPassiveTimeSettings } from '../models';
import { HttpResponse } from '../../common/models';

export const PassiveTimeSettingsService = (): IPassiveTimeSettingsService => {
  return {
    postPassiveSettings: async (
      payload: IPassiveTimeSettings
    ): Promise<HttpResponse<object>> => {
      return await apiService.post<object>('/api/settings/passivetime', {
        ...getDefaultConfig(),
        data: payload
      });
    },
    fetchPassiveSettings: async (): Promise<
      HttpResponse<IPassiveTimeSettings>
    > => {
      return await apiService.get<IPassiveTimeSettings>(
        '/api/settings/passivetime',
        {
          ...getDefaultConfig()
        }
      );
    }
  };
};
