import { connect as agConnect } from '../../../appReact/connections/agConnections.js';
import { connect as sidebarConnect } from '../../../appReact/connections/sideBarConnections.js';
import { connect as headerConnect } from '../../../appReact/connections/headerConnections.js';

import { connect as dashboardConnect } from '../../../appReact/connections/dashboardConnections.js';
import { connect as classificationConnect } from '../../../appReact/connections/classificationConnections.js';
import { connect as settingsGroupsConnect } from '../../../appReact/connections/settingsGroupsConnections.js';
import { connect as userAliasConnect } from '../../../appReact/connections/settingsUserAliasConnections.js';
import { connect as computerAliasConnect } from '../../../appReact/connections/settingsComputerAliasesConnections.js';
import { connect as digestSettingsConnect } from '../../../appReact/connections/settingsDigestConnections.js';

import { connect as userIdentitySettingsConnect } from '../../../appReact/connections/settingsUserIdentityConnections.js';
import { connect as userSettingsConnect } from '../../../appReact/connections/settingsUsersConnections.js';
import { connect as computerSettingsConnect } from '../../../appReact/connections/settingsComputersConnections.js';

import { connect as accountConfigurationConnect } from '../../../appReact/connections/accountConfigurationConnections.js';
import { connect as insightSubscriptions } from '../../../appReact/connections/insightSubscriptionsConnections.js';
import { connect as settingsTimezoneConnect } from '../../../appReact/connections/settingsTimezoneConnections.js';
import { connect as accountWizardSettingsConnect } from '../../../appReact/connections/accountWizardSettingsConnections.js';
import { connect as profileSettingsConnect } from '../../../appReact/connections/settingsProfileConnections.js';
import { connect as chromebookUsersSettingsConnect } from '../../../appReact/connections/settingChromebookUsersConnections.js';
import { connect as supportTicketsConnect } from '../../../appReact/connections/supportTicketsConnections.js';
import { connect as integrationsApiKeyConnect } from '../../../appReact/connections/integrationsApiKeyConnections.js';
import { connect as integrationsConnect } from '../../../appReact/connections/integrationsConnections.js';
import { connect as demoConnect } from '../../../appReact/connections/demoConnections.js';

import { connect as routerConnect } from '../../../appReact/connections/routerConnections.js';
import { connect as insightsConnect } from '../../../appReact/connections/insightsConnections.js';

export const connect = (angular) => {
  angular.element(document).ready(function () {
    routerConnect(angular);
    agConnect(angular);
    sidebarConnect(angular);
    headerConnect(angular);
    dashboardConnect(angular);
    classificationConnect(angular);
    userAliasConnect(angular);
    settingsGroupsConnect(angular);
    computerAliasConnect(angular);
    digestSettingsConnect(angular);
    userIdentitySettingsConnect(angular);
    userSettingsConnect(angular);
    computerSettingsConnect(angular);
    accountConfigurationConnect(angular);
    insightSubscriptions(angular);
    settingsTimezoneConnect(angular);
    accountWizardSettingsConnect(angular);
    profileSettingsConnect(angular);
    chromebookUsersSettingsConnect(angular);
    supportTicketsConnect(angular);
    integrationsApiKeyConnect(angular);
    integrationsConnect(angular);
    demoConnect(angular);
    insightsConnect(angular);
  });
};
