import { IDigestSetting, IDigestSettingDto } from '../../common/models';

export const filterDigests = (
  digests: IDigestSetting[],
  filter: string
): IDigestSetting[] => {
  if (!digests || !filter || filter === '') {
    return digests;
  }

  const includes = (main: string, sub: string): boolean => {
    if (!sub) {
      return true;
    }
    if (!main) {
      return false;
    }
    return main.toLowerCase().includes(sub.toLowerCase());
  };

  return digests.filter(
    (digest) =>
      includes(digest.username, filter) || includes(digest.name, filter)
  );
};

export const getFullName = (firstName: string, lastName: string): string => {
  let name = firstName;
  if (!name) {
    name = lastName;
  } else {
    name += lastName ? ' ' + lastName : '';
  }

  return name ? name : '';
};

export const mapToDigestSetting = (item: IDigestSettingDto): IDigestSetting => {
  return {
    userId: item.userId,
    username: item.username,
    name: getFullName(item.firstName, item.lastName),
    digest: item.digest
  };
};
