import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { rowType } from '../../../common/enums';
import { IRoleAccessViewModel } from '../../../common/models';
import { RoleResourceTitle } from '../RoleResourceTitle';
import { roleTableClasses } from '../../styles/RolesTable.styles';
import { RolesTableCell } from './RolesTableCell';

type rowProps = {
  row: IRoleAccessViewModel;
  handleChange: any;
  busy: boolean;
  showLimitedDesc: boolean;
  collapsibleRows?: boolean;
  open?: boolean;
};

const BaseRenderCheckRow: React.FC<rowProps> = ({
  row,
  handleChange,
  busy,
  showLimitedDesc
}) => {
  return (
    <>
      <TableCell colSpan={2}>
        <RoleResourceTitle
          resource={row.resource}
          subItem={row.itemType == rowType.SubItem}
          secondaryText={row.secondaryText}
          premiumFeature={row?.premiumFeature}
        />
      </TableCell>
      <RolesTableCell
        cellType="adminCheck"
        row={row}
        showLimitedDesc={showLimitedDesc}
        handleChange={handleChange}
        busy={busy}
      />
      <RolesTableCell
        cellType="configCheck"
        row={row}
        showLimitedDesc={showLimitedDesc}
        handleChange={handleChange}
        busy={busy}
      />
      <RolesTableCell
        cellType="powerCheck"
        row={row}
        showLimitedDesc={showLimitedDesc}
        handleChange={handleChange}
        busy={busy}
      />
      <RolesTableCell
        cellType="viewerCheck"
        row={row}
        showLimitedDesc={showLimitedDesc}
        handleChange={handleChange}
        busy={busy}
      />
    </>
  );
};

export const RolesTableCheckRow: React.FC<rowProps> = ({
  row,
  handleChange,
  busy,
  showLimitedDesc
}) => {
  return (
    <React.Fragment>
      <TableRow sx={roleTableClasses.tableRow}>
        <BaseRenderCheckRow
          row={row}
          busy={busy}
          handleChange={handleChange}
          showLimitedDesc={showLimitedDesc}
        />
      </TableRow>
    </React.Fragment>
  );
};
