import viewTemplate from 'views/widgets/dashboard/quickStats.html?raw';

angular.module('app').directive('quickStatsPrint', function () {
    return {
        restrict: 'E',
        template: viewTemplate,
        controller: [
            '$scope',
            'dashboardApiService',
            'atDashboardWidgetService',
            'templateServiceFunctions',
            function ($scope, dashboardApiService, atDashboardWidgetService, templateServiceFunctions) {
                $scope.quickStatsLoading = true;
                const bindWidget = function () {
                    $scope.quickStatsLoading = true;
                    dashboardApiService
                        .getQuickStats($scope.parameters)
                        .then(function (result) {
                            const data = result.data;
                            $scope.quickStatsLoading = false;
                            $scope.quickstats = {
                                web: {
                                    productive: templateServiceFunctions.friendlyViewTimeFormat(
                                        data.webProductive,
                                        true
                                    ),
                                    unproductive: templateServiceFunctions.friendlyViewTimeFormat(
                                        data.webUnproductive,
                                        true
                                    ),
                                    undefined: templateServiceFunctions.friendlyViewTimeFormat(data.webUndefined, true),
                                    all: templateServiceFunctions.friendlyViewTimeFormat(data.webTotal, true)
                                },
                                apps: {
                                    productive: templateServiceFunctions.friendlyViewTimeFormat(
                                        data.appProductive,
                                        true
                                    ),
                                    unproductive: templateServiceFunctions.friendlyViewTimeFormat(
                                        data.appUnproductive,
                                        true
                                    ),
                                    undefined: templateServiceFunctions.friendlyViewTimeFormat(data.appUndefined, true),
                                    all: templateServiceFunctions.friendlyViewTimeFormat(data.appTotal, true)
                                },
                                all: {
                                    productive: templateServiceFunctions.friendlyViewTimeFormat(
                                        addDurations(data.webProductive, data.appProductive),
                                        true
                                    ),
                                    unproductive: templateServiceFunctions.friendlyViewTimeFormat(
                                        addDurations(data.webUnproductive, data.appUnproductive),
                                        true
                                    ),
                                    undefined: templateServiceFunctions.friendlyViewTimeFormat(
                                        addDurations(data.webUndefined, data.appUndefined),
                                        true
                                    ),
                                    all: templateServiceFunctions.friendlyViewTimeFormat(
                                        addDurations(data.webTotal, data.appTotal),
                                        true
                                    )
                                }
                            };
                        })
                        .catch(function () {
                            $scope.quickStatsLoading = false;
                        });
                };

                function addDurations(d1, d2) {
                    const d1Split = d1.split(':');
                    const d2Split = d2.split(':');

                    let minutes = parseInt(d1Split[1]) + parseInt(d2Split[1]);
                    let hours = parseInt(d1Split[0]) + parseInt(d2Split[0]);
                    hours += Math.floor(minutes / 60);
                    minutes = minutes % 60;

                    return hours + ':' + minutes;
                }

                atDashboardWidgetService.registerWidget('quickStats', 0, bindWidget);
            }
        ]
    };
});
