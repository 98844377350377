'use strict';

angular
    .module('app')
    .controller('gridColumnSelectorModalCtrl', gridColumnSelectorModalCtrl);

gridColumnSelectorModalCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'grid',
    'gridPropertiesService'
];

function gridColumnSelectorModalCtrl(
    $scope,
    $uibModalInstance,
    grid,
    gridPropertiesService
) {
    $scope.columns = [];

    angular.forEach(grid.columns, function (item) {
        if(item.title) {
            $scope.columns.push({
                checked: !item.hidden,
                title: item.title.replace(/<br>|<br\/>|<br \/>/g, ' '),
                field: item.field
            });
        }
    });

    function setGridWidthFull() {
        angular.element('.k-grid-header table').prop('style').width = '100%';
        angular.element('.k-grid-content table').prop('style').width = '100%';
    }

    function checkLastCheckRemaining() {
        $scope.columnCount = _.sumBy($scope.columns, function (x) {
            return x.checked ? 1 : 0;
        });
    }
    checkLastCheckRemaining();

    $scope.allColumns = $scope.columnCount === $scope.columns.length;

    function onColumnChanged() {
        gridPropertiesService.hideLeftBorder();
        setGridWidthFull();
        checkLastCheckRemaining();
    }

    $scope.columnChange = function (item) {
        if (item.checked) {
            grid.showColumn(item.field);
        } else {
            grid.hideColumn(item.field);
        }

        onColumnChanged();
    };

    $scope.allColumnsChange = function () {
        if ($scope.allColumns) {
            $scope.columns.forEach(function (column) {
                if (!column.checked) {
                    column.checked = true;
                    grid.showColumn(column.field);
                }
            });
        } else {
            $scope.columns
                .filter(function (column) {
                    return column.checked;
                })
                .slice(1)
                .forEach(function (column) {
                    column.checked = false;
                    grid.hideColumn(column.field);
                });
        }

        onColumnChanged();
    };

    $scope.ok = function () {
        setGridWidthFull();
        $uibModalInstance.close($scope.columns);
    };
}
