import React, { useState, useEffect } from 'react';
import { take } from 'lodash';
import { useAlarms, IAlarm, AlarmType } from '../../common/services/Alarms';
import GroupCard from './GroupCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UsbIcon from '@mui/icons-material/Usb';
import { useNotifications } from '../../common/services/Notifications';
import { Routes } from '../../common/constants';
import Box from '@mui/material/Box';

type GroupAlarmProps = {
  groupId: number;
};

export default function GroupAlarms(props: GroupAlarmProps) {
  const { groupId } = props;
  const [alarms, setAlarms] = useState<IAlarm[]>([]);
  const { alarmsService } = useAlarms();
  const notificationService = useNotifications();
  const maxAlarms = 2;

  useEffect(() => {
    async function fetch() {
      try {
        if (groupId) {
          const alarms = await alarmsService.getAllAlarms(groupId);
          setAlarms(alarms);
        }
      } catch (error) {
        notificationService.error('Failed to get group alarms.');
      }
    }
    fetch();
  }, [alarmsService, notificationService, groupId]);

  return alarms.length <= 0 ? (
    <></>
  ) : (
    <GroupCard title={`Group Alarms (${alarms.length})`}>
      <Box display="flex" flexDirection="column">
        {take(alarms, maxAlarms).map((alarm) => (
          <Box
            key={alarm.id}
            display="inline-flex"
            component="span"
            paddingBottom="8px"
          >
            <Box paddingRight="5px" component="span">
              <AlarmIcon type={alarm.type} />
            </Box>{' '}
            <a href={Routes.Alarm(alarm.id)}>{alarm.name}</a>
          </Box>
        ))}
      </Box>
      <div>
        <a href={Routes.Alarms}>See all</a>
      </div>
    </GroupCard>
  );
}

export const IconNames = {
  notification: 'notification-icon',
  visibility: 'visibility-icon',
  usb: 'usb-icon'
};

type AlarmIconProps = {
  type: AlarmType;
};

function AlarmIcon(props: AlarmIconProps) {
  const { type } = props;
  const fontSize = 'small';

  switch (type) {
    case AlarmType.Activity:
      return (
        <NotificationsIcon
          fontSize={fontSize}
          titleAccess={IconNames.notification}
        />
      );
    case AlarmType.Audit:
      return (
        <VisibilityIcon
          fontSize={fontSize}
          titleAccess={IconNames.visibility}
        />
      );
    case AlarmType.Usb:
      return <UsbIcon fontSize={fontSize} titleAccess={IconNames.usb} />;
    default:
      return <></>;
  }
}
