import { heading3FontSize } from '../../common/constants';

export const quickActionsWidgetStyles = {
  btnDiv: {
    display: 'flex',
    overflowX: 'scroll',
    gap: '10px',
    padding: '9px 16px 15px 2px',
    marginTop: '-10px'
  },
  modalContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px'
    }
  },
  agentModal: {
    textAlign: 'center',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '1000px',
      maxWidth: '1000px',
      maxHeight: '900px'
    },
    maxHeight: '720px',
    '& .makeStyles-card-191': {
      width: '85%',
      '& .makeStyles-img-192': {
        '& svg': {
          height: '60%',
          width: '60%'
        }
      }
    }
  },
  agentModalHeader: {
    marginTop: '20px',
    fontSize: `${heading3FontSize} !important`
  },
  editModal: {
    width: '371px',
    marginLeft: '10px'
  },
  listItem: {
    marginBottom: '0px'
  }
};
