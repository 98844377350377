import React, { Fragment } from 'react';
import { ISchedule } from '../models';
import {
  DialogActions,
  Button,
  DialogContent,
  Checkbox,
  Box,
  Grid,
  Dialog,
  Typography,
  DialogTitle
} from '@mui/material';
import { subscriptionClasses } from '../styles/SubscriptionManager.view.styles';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  schedule: ISchedule;
};

export const ViewSubscriptionModal = (props: ComponentProps) => {
  const { open, onClose, schedule } = props;

  const close = () => {
    onClose();
  };

  //Nested attributes and special meanings/translations
  //99% replicated from insights angular subscription work
  const parseAttributes = (schedule) => {
    const emails = [];
    let format, emailBody;

    schedule?.emails?.forEach((email) => {
      const destination = JSON.parse(email);
      emails.push(destination.address);
      format = destination.format === 'wysiwyg_png' ? 'PNG' : 'PDF';
      emailBody = destination.message;
    });

    const includeLink =
      emailBody?.indexOf('Access Dashboard in ActivTrak:') !== -1;

    const weeklyOptions = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    let recurrence: string, day: string;
    const cronSections = schedule?.crontab?.split(' ') || [];
    switch (true) {
      case cronSections[2] !== '*':
        recurrence = 'Monthly';
        day = cronSections[2];
        break;
      case cronSections[4] !== '*':
        recurrence = 'Weekly';
        day = weeklyOptions[cronSections[4]];
        break;
      default:
        recurrence = 'Daily';
        day = 'Monday';
    }

    let time: string;
    switch (cronSections[1]) {
      case '8':
        time = 'Morning (8am - 12pm)';
        break;
      case '12':
        time = 'Afternoon (12pm - 6pm)';
        break;
      case '18':
        time = 'Evening (6pm -10pm)';
        break;
      case '22':
        time = 'Night (10pm -12am)';
        break;
      default:
        time = 'Morning (8am - 12pm)';
    }

    const decodedString = decodeURI(schedule?.filter.substr(1))
      .replace(/^&/g, '')
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/%5E/g, '^')
      .replace(/\+/g, ' ')
      .replace(/%2C/g, ',')
      .replace(/%23/g, '#')
      .replace(/%24/g, '$')
      .replace(/%2F/g, '/')
      .replace(/%40/g, '@')
      .replace(/%26/g, '&');

    let filtersObj = {};
    try {
      filtersObj = decodedString && JSON.parse(`{"${decodedString}"}`);
    } catch (e) {
      console.error('ActivTrak Error: Error parsing filters: ' + e);
    }

    return {
      emails: emails,
      format: format,
      includeLink: includeLink,
      recurrence: recurrence,
      day: day,
      time: time,
      filtersObj: filtersObj
    };
  };

  let sched;
  if (open) {
    sched = parseAttributes(schedule);
  }

  const handleFiltersDisplay = (key, filters) => {
    const showMax = 5;
    if (key.toLowerCase() === 'team' && filters[key].length) {
      const teams = filters[key].split(',');
      if (teams.length > showMax) {
        return (
          <Box>
            <strong>{key}:</strong> {teams.slice(0, showMax).join(', ')}...
            <strong>+{teams.length - showMax}</strong>
          </Box>
        );
      }
    }
    return (
      <Box>
        <strong>{key}:</strong>{' '}
        {filters[key].length ? filters[key] : 'Any Value'}
      </Box>
    );
  };

  const handleRecipientsDisplay = (emails) => {
    const showMax = 5;
    if (emails.length) {
      if (emails.length > showMax) {
        return (
          <Box>
            {emails.slice(0, showMax).join(', ')}...
            <strong>+{emails.length - showMax}</strong>
          </Box>
        );
      }
      return <Box>{emails.join(', ')}</Box>;
    }
    return <></>;
  };

  return (
    open && (
      <Dialog open={open} onClose={close} maxWidth="md">
        <DialogTitle>View Subscription</DialogTitle>
        <DialogContent>
          {schedule && sched ? (
            <Box sx={{ width: '450px' }}>
              <Box>{schedule.name}</Box>
              <Box sx={subscriptionClasses.subtitleText}>Recipients</Box>
              {handleRecipientsDisplay(sched.emails)}
              <Box>
                <Grid container sx={subscriptionClasses.subtitleText}>
                  <Grid item sm={8}>
                    Recurrence
                  </Grid>
                  <Grid item sm={4}>
                    Day
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={8}>
                    {sched.recurrence}
                  </Grid>
                  <Grid item sm={4}>
                    {sched.day}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container sx={subscriptionClasses.subtitleText}>
                  <Grid item sm={8}>
                    Time
                  </Grid>
                  <Grid item sm={4}>
                    Format
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={8}>
                    {sched.time}
                  </Grid>
                  <Grid item sm={4}>
                    {sched.format}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Checkbox
                  color="primary"
                  checked={sched.includeLink}
                  disabled={true}
                  sx={{ paddingLeft: 0 }}
                />
                Include link to Dashboard in email
              </Box>
              <Typography sx={subscriptionClasses.subtitleText}>
                Filters
              </Typography>
              {Object.keys(sched.filtersObj).map((key, index) => {
                return (
                  <Fragment key={`insights-filter-${index}`}>
                    {handleFiltersDisplay(key, sched.filtersObj)}
                  </Fragment>
                );
              })}
            </Box>
          ) : (
            <Typography>No subscription information available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id="id_e8dfc07b-13d0-4930-90ec-8e16bb6fc830"
            onClick={close}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};
