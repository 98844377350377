import { Menu } from '@mui/material';
import React from 'react';

const CustomMenu = (props) => {
  return (
    <Menu
      elevation={8}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      classes={{
        paper: {
          marginLeft: -6,
          marginTop: 12
        }
      }}
      {...props}
    />
  );
};

export default CustomMenu;
