import _ from 'lodash';

const isEmpty = (value) => {
  if (typeof value === 'boolean') {
    return false;
  }

  if (Array.isArray(value)) {
    let notEmpty = value !== [];

    if (notEmpty) {
      notEmpty = false;
      value.forEach((v) => (notEmpty = notEmpty || !isEmpty(v)));
    }

    return !notEmpty;
  }

  if (typeof value === 'object') {
    return _.isEmpty(value);
  }

  return !value || value.toString().length === 0 || !value.toString().trim();
};

const generateGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const isLocalHost = () => {
  return (
    true ||
    Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    )
  );
};

/**
 * Converts seconds to an hours string with fixed decimal set to 1.
 * Optional label and precision inputs to control output string
 * Ex. 25920 => 7.2 hrs
 * @param {number|String} input
 * @param {String} label
 * @param {number} precision
 * @returns {String}
 */
const convertSecondsToHoursString = (input, label = 'hrs', precision = 1) => {
  let seconds;
  try {
    seconds = parseInt(input, 10);
  } catch (e) {
    console.error(
      'ActivTrak Error: Unable to create hours string. Cannot parse input.',
      input,
      e
    );

    return `0 ${label}`;
  }

  // Verify precision is a positive number, if not set to default of 1
  const fractionDigits =
    seconds === 0 ? 0 : isNaN(precision) || precision < 0 ? 1 : precision;
  const hours = (seconds / 60 / 60).toFixed(fractionDigits);

  return `${hours} ${label}`;
};

const getRatio = (value, precision) => {
  let ratio = !value || isNaN(value) ? 0 : value;
  return ratio.toFixed(precision);
};

export {
  isEmpty,
  generateGuid,
  isLocalHost,
  convertSecondsToHoursString,
  getRatio
};
