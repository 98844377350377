import React, { useEffect, useState } from 'react';
import { ClassificationsProps } from '../models';
import { ListFilter } from '../../common/components/Filter/ListFilter';
import { TotalCount } from '../../common/components/Totals/TotalCount';
import { BaseNoResults } from '../../common/components/NoResults/BaseNoResults';
import { useClassificationColumns, bulkClassificationFilters } from '../utils';
import { SortDirection } from '../../common/enums';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import ClassificationBulkActivities from './ClassificationBulkActivities';
import ClassificationsMoreMenu from './ClassificationsMoreMenu';
import CompletedClassifications from './CompletedClassifications';
import { classificationClasses } from '../styles/Classifications.styles';
import { useClassificationStores } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { Grid, Box } from '@mui/material';

export default function PendingClassifications(props: ClassificationsProps) {
  const {
    getApiProps,
    defaultFilter,
    categories,
    handleClickRowGrid,
    activitiesState,
    selectedItems,
    onActivitiesFilter,
    onUpdateActivityChange,
    onBulkUpdateActivityChange,
    onSelectedItems,
    onSortOrder,
    isDisabled
  } = props;

  const [successMsg, setSuccessMsg] = useState<string>(null);

  const columns = useClassificationColumns({
    categories,
    onChange: onUpdateActivityChange,
    isTruncated: true,
    isDisabled: isDisabled
  });
  const notificationService = useNotifications();

  //CLASSIFICATION STORE SETUP
  const { classificationStore } = useClassificationStores();
  const { getRecentActivities } = classificationStore;

  const { activities, totalActivities, isLoading, isSearching } =
    activitiesState;

  const isCompletelyClassified =
    !isLoading && !isSearching && !activities?.length;
  const hasNoResults = !isLoading && isSearching && !activities?.length;

  useEffect(() => {
    const get = async () => {
      await getRecentActivities(categories, getApiProps);

      if (successMsg) {
        notificationService.success(successMsg);
        setSuccessMsg(null);
      }
    };

    if (!categories || categories.length === 0) {
      return;
    }

    if (getApiProps) {
      get();
    }
    //keeping "successMsg" out of the dependencies because it causes an infinite loop when setting/nullifying inside this useEffect. this feature was a quick fix, as other systems would take longer to develop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecentActivities, getApiProps, categories, notificationService]);

  const bulkFilterMenuItems = [
    bulkClassificationFilters.allActivities,
    bulkClassificationFilters.pendingCategorization,
    bulkClassificationFilters.pendingProductivity
  ];

  const isBulkActivitiesDisabled = !selectedItems || selectedItems.length <= 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container sx={classificationClasses.header}>
          <Grid item xs={12} sm={2} md={4} lg={5}>
            <ClassificationBulkActivities
              onBulkUpdateActivityChange={onBulkUpdateActivityChange}
              categories={categories}
              isDisabled={isBulkActivitiesDisabled}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={7}
            sx={classificationClasses.activityColsHeaderRight}
          >
            <TotalCount
              label="total pending"
              count={totalActivities}
              testId="pending-count"
            />
            <ListFilter
              listItems={bulkFilterMenuItems}
              onClick={onActivitiesFilter}
              testId="pending-search-filters"
              defaultSelection={defaultFilter?.label}
            />
            <ClassificationsMoreMenu onChange={setSuccessMsg} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box height="65vh">
          {isCompletelyClassified ? (
            <CompletedClassifications />
          ) : hasNoResults ? (
            <BaseNoResults tips="Have you seen this message more than once? Try searching in the &ldquo;Classified&rdquo; tab instead." />
          ) : (
            <CustomGrid
              height={'58vh'}
              isVirtualized={true}
              isLoading={isLoading}
              data={activities}
              columns={columns}
              uniqueRowProp="uniqueId"
              initialSortField="duration"
              initialSortDirection={SortDirection.Descending}
              hasBulkSelection
              bulkSelectFilterOptions={bulkFilterMenuItems}
              onSelectAllClick={onSelectedItems}
              onClickRow={handleClickRowGrid}
              onSortOrder={onSortOrder}
              featureDisabled={isDisabled}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
