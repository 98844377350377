import {
  gray4,
  heavyFontWeight,
  mediumFontWeight
} from '../../common/constants';

const border = `1px solid ${gray4}`;

export const groupsTableClasses = {
  header: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%'
  },
  headerRight: {
    textAlign: 'right',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '& > span': {
      paddingRight: '15px'
    }
  },
  groupsContainer: {
    maxWidth: 9000
  },
  tableContainer: {
    border: border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  tableHeader: {
    fontWeight: mediumFontWeight,
    height: '56px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableCheckbox: {
    marginLeft: 8
  },
  bulkDeleteButtonContainer: {
    display: 'inline-flex'
  },
  gridFilterControls: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline'
  },
  groupName: {
    alignItems: 'center',
    display: 'flex'
  },
  tableRow: {
    padding: '1em',
    margin: 'inherit',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      position: 'relative',
      alignItems: 'center',
      paddingRight: '32px',
      '& .at-icon-arrow': {
        marginRight: '5px',
        position: 'absolute',
        left: '-20px'
      }
    },
    '& > div:last-child': {
      paddingRight: 0
    },
    '& div:nth-of-type(1)': {
      width: '12%'
    },
    '& div:nth-of-type(2)': {
      width: '34%',
      display: 'flex'
    },
    '& div:nth-of-type(3)': {
      width: '24%',
      display: 'flex'
    },
    '& div:nth-of-type(4)': {
      width: '24%',
      display: 'flex'
    },
    '& div:nth-of-type(5)': {
      width: '6%'
    },
    '&.selected': {
      backgroundColor: 'rgba(3, 218, 197, 0.1) !important'
    },
    borderBottom: border
  },
  alignRight: {
    textAlign: 'right'
  },
  icon: {
    marginRight: '0.2em',
    fontSize: '1.5em'
  },
  dataRow: {
    cursor: 'pointer',
    height: '52px',
    '&:hover': {
      boxShadow:
        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)'
    }
  },
  count: {
    fontWeight: heavyFontWeight,
    paddingRight: 10
  },
  columnCheckbox: {
    marginLeft: 7
  },
  preview: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  sortRow: {
    cursor: 'pointer'
  }
};
