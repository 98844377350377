'use strict';

angular
    .module('app')
    .controller('deleteUserMessageModalController', DeleteUserMessageModalController);

DeleteUserMessageModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    'messageData',
    '$sce',
    '$state'
];

function DeleteUserMessageModalController($scope, $uibModalInstance, messageData, $sce, $state) {
    // Message Title and Body accept either a string or a trusted html object.
    $scope.messageTitle =
        typeof messageData.messageTitle === 'string'
            ? $sce.trustAsHtml(messageData.messageTitle)
            : messageData.messageTitle;
    $scope.messageBody =
        typeof messageData.messageBody === 'string'
            ? $sce.trustAsHtml(messageData.messageBody)
            : messageData.messageBody;

    // Labels are used for confirm and cancel buttons
    $scope.confirmLabel = messageData.confirmLabel;
    $scope.cancelLabel = messageData.cancelLabel || 'Cancel';
    $scope.twoStepConfirmLabel =
        messageData.twoStepConfirmLabel || 'Confirm Action';
    $scope.isInsightsEnabled = messageData.isInsightsEnabled || false;
    $scope.subscriptionsBody = messageData.subscriptionsBody || '';
    $scope.linkLabel = messageData.linkLabel || '';
    $scope.linkTarget = messageData.linkTarget || '';

    // Modal type
    $scope.twoStepConfirm = messageData.twoStepConfirm;
    $scope.alert = messageData.alert;

    $scope.confirmClicked = function () {
        $uibModalInstance.close(false);

        if (
            messageData.callbacks &&
            typeof messageData.callbacks.confirm === 'function'
        ) {
            messageData.callbacks.confirm(false);
        }
    };

    $scope.cancelClicked = function () {
        $uibModalInstance.dismiss();
    };

    $scope.linkTargetClicked = function () {
        $uibModalInstance.dismiss();
        $state.go($scope.linkTarget);
    }
}
