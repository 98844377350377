import { mediumFontWeight, gray4, gray5 } from '../../common/constants';

const border = `1px solid ${gray4}`;

export const groupMemberClasses = {
  bold: {
    fontWeight: mediumFontWeight
  },
  tableContainer: {
    maxHeight: '55vh',
    width: '100%',
    border: border,
    minWidth: '50vw'
  },
  tableHeader: {
    height: 56,
    fontWeight: mediumFontWeight
  },
  tableRow: {
    padding: '1em',
    margin: 'inherit',
    display: 'flex',
    alignItems: 'center',
    '& div:nth-of-type(1)': {
      width: '10%'
    },
    '& div:nth-of-type(2)': {
      width: '45%'
    },
    '& div:nth-of-type(3)': {
      width: '45%'
    },
    borderBottom: border,
    '&.selected': {
      backgroundColor: 'rgba(3, 218, 197, 0.1) !important'
    }
  },
  memberName: {
    paddingLeft: '0.5em'
  },
  dataRow: {
    cursor: 'pointer',
    height: '52px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  disabled: {
    color: `${gray5} !important`
  }
};
