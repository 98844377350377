import React, { MouseEvent, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Typography
} from '@mui/material';
import { activationClasses } from '../styles/AgentActivationCard.styles';
import { OpenFileBox, WindowsIcon } from '../../common/assets/Icons';
import AppleIcon from '@mui/icons-material/Apple';
import { gray11, MINUTES_IN_72_HOURS } from '../../common/constants';
import { notifications } from '../constants/notifications';
import { useNotifications } from '../../common/services/Notifications';
import { AgentDownloadService } from '../../common/services';
import { newEvent } from '../../common/analytics/eventHeap';
import { AgentOs } from '../../common/enums/AgentOs';

export const CopyAgentLinkCard = () => {
  const [agentOs, setAgentOs] = useState<AgentOs>();
  const [copyBtnName, setCopyBtnName] = useState<string>('Copy Link');

  const service = AgentDownloadService();
  const notificationService = useNotifications();

  const handleOsButtonClick = (event: MouseEvent<HTMLElement>, os: AgentOs) => {
    if (os) setAgentOs(os);
  };

  const handleCopyLinkClick = () => {
    if (!agentOs) {
      console.error(
        'ActivTrak Error: Missing OS selection for agent download link'
      );
      return;
    }

    setCopyBtnName('Generating Link...');

    newEvent('Click - Copy Link', { platform: agentOs });

    service
      .fetchAgentLink(agentOs, MINUTES_IN_72_HOURS)
      .then((response) => {
        const { url } = response.data;
        navigator.clipboard.writeText(url);
        notificationService.success(notifications.linkCopiedSuccess);
        setCopyBtnName('Copy Link');
      })
      .catch((error) => {
        console.error(
          'ActivTrak Error: Failed to generate agent download link',
          error
        );
        notificationService.error(notifications.downloadError);
      });
  };

  const linkDiv = {
    ...activationClasses.hidden,
    ...activationClasses.selectOsDiv
  };
  const macBtn = { ...activationClasses.macOsBtn, ...activationClasses.osBtn };

  return (
    <Grid item xs={6} mb={2}>
      <Card sx={activationClasses.card}>
        <CardMedia title="Files in box" sx={activationClasses.img}>
          <OpenFileBox width="80%" height="80%" />
        </CardMedia>
        <CardContent>
          <Typography sx={activationClasses.cardTitle} fontWeight={500}>
            Share the installation file
          </Typography>
          <Box className="selectOsDiv" sx={linkDiv}>
            <Typography
              sx={activationClasses.selectOsStatement}
              fontWeight="400"
            >
              Select an operating system
            </Typography>
            <ToggleButtonGroup
              value={agentOs}
              onChange={handleOsButtonClick}
              exclusive
              sx={{ mb: 2 }}
            >
              <ToggleButton value={AgentOs.Mac} sx={macBtn}>
                <span style={activationClasses.lowercase}>mac</span>
                OS
                <AppleIcon sx={activationClasses.osIcon} />
              </ToggleButton>
              <ToggleButton
                value={AgentOs.Windows}
                sx={{
                  ...activationClasses.osBtn,
                  ...{
                    textTransform: 'capitalize',
                    '&:hover': {
                      '& $osIcon': {
                        fill: gray11
                      }
                    }
                  }
                }}
              >
                Windows
                <WindowsIcon style={activationClasses.osIcon} />
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="body1" sx={activationClasses.expirationNote}>
              Links expires 72 hours after creation
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            sx={activationClasses.btn}
            disabled={!agentOs}
            onClick={() => handleCopyLinkClick()}
          >
            {copyBtnName}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
