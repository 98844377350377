import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const UserGroupIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const title = props.title ?? 'User Group';
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';
  const style = props.style ?? {};

  let classList = 'at-icon-user-group';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      fillColor={fillColor}
      title={title}
      className={classList}
      viewBox={viewBox}
    >
      <path
        d="M16.314 13.34c1.252.85 2.129 2 2.129 3.535v2.74h2.74a.916.916 0 0 0 .914-.913v-1.827c0-1.991-3.261-3.17-5.783-3.535ZM9.308 12.308a3.654 3.654 0 1 0 0-7.308 3.654 3.654 0 0 0 0 7.308ZM14.789 12.308a3.653 3.653 0 0 0 3.654-3.654A3.653 3.653 0 0 0 14.789 5c-.43 0-.831.091-1.215.22a5.462 5.462 0 0 1 0 6.869c.384.127.786.219 1.215.219ZM9.308 13.221C6.868 13.221 2 14.445 2 16.875v1.827c0 .503.411.914.913.914h12.79a.916.916 0 0 0 .913-.914v-1.827c0-2.43-4.87-3.654-7.308-3.654Z"
        style={style}
      />
    </SvgIconWrapper>
  );
};
