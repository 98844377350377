import { apiService } from '../../common/helpers';
import { getDefaultConfig } from '../../common/config';
import {
  IChromebookUserDto,
  IChromebookUserPost,
  IChromebookUsersService
} from '../models';

export const chromebookUsersService = (): IChromebookUsersService => {
  return {
    fetchChromebookUsers: async (): Promise<IChromebookUserDto[]> => {
      const response = await apiService.get<IChromebookUserDto[]>(
        '/api/settings/chromebook/users',
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },
    addCbromebookUser: async (payload: IChromebookUserPost): Promise<any> => {
      const response = await apiService.post<any>(
        '/api/settings/chromebook/users',
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
      return response;
    },
    inviteCbromebookUser: async (
      payload: IChromebookUserPost
    ): Promise<any> => {
      const response = await apiService.post<any>(
        '/api/settings/chromebook/users/invite',
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
      return response?.data;
    },
    deleteCbromebookUsers: async (payload: string[]): Promise<any> => {
        const response = await apiService.post<any>(
          '/api/settings/chromebook/users/delete',
          {
            ...getDefaultConfig(),
            data: payload
          }
        );
      return response?.data;
    },
    confirmChromebookUser: async (payload): Promise<any> => {
        const response = await apiService.post<any>(
        '/api/settings/chromebook/users/confirm',
        {
            ...getDefaultConfig(),
            data: payload
        }
        );
        return response;
    }
  };
};
