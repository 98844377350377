import {
  SHORT_DATE,
  SHORT_REVERSED_DATE,
  blue6,
  red5,
  gray7
} from '../../common/constants';
import { SortDirection } from '../../common/enums';
import {
  convertToPercent,
  formatDateWithTimezone,
  getAbbreviatedWeekday,
  getFullWeekday,
  getWeekEnd,
  sorter
} from '../../common/helpers';
import { getAccountSettings } from '../../common/helpers/accountSettings';
import { prodBarChartWidgetCopy, prodBarModalSubtitles } from '../constants';
import {
  IBarChartCopyLayout,
  IGoalDay,
  IGoalDaysDto,
  IProdUsersChartData,
  IProdUsersGoalDto,
  IProdUsersGoalTrend,
  IProdUsersTrends,
  IReportModalListItem,
  ITodaysProductivity,
  ITodaysProductivityDto,
  ITodaysProductivityUsersDto,
  IUserAndGoal,
  ProductivityGoalLabel,
  ProductivityGoalLevel
} from '../models';
import {
  mapGroupsWithLinks,
  mapUserProductivityAchievementModal
} from './teams.utils';

const getProductivityDays = (
  dayData: IGoalDaysDto[]
): { dynamicDays: IGoalDay[]; staticDays: IGoalDay[] } => {
  const dynamicDays = [];
  const staticDays = [];

  dayData?.forEach((item) => {
    const label = getAbbreviatedWeekday(new Date(item.date)) || 'N/A';

    dynamicDays.push({
      goal: item.goal,
      dayLabel: label
    });
    staticDays.push({
      goal: 100,
      dayLabel: label
    });
  });

  return {
    dynamicDays,
    staticDays
  };
};

export const mapToTodaysProductivity = (
  data: ITodaysProductivityDto,
  timezone: string
): ITodaysProductivity => {
  const dayData = getProductivityDays(data?.days);

  return {
    previous: data?.previous,
    goal: data?.goal,
    date: data?.date,
    todaysLabel:
      (timezone && getFullWeekday(new Date(data.date), timezone)) || 'today',
    days: dayData.dynamicDays,
    staticLineDays: dayData.staticDays,
    teams: mapGroupsWithLinks(data?.teams)
  };
};

export const mapToUsersTodaysProductivity = (
  data: ITodaysProductivityUsersDto
) => {
  const accountSettings = getAccountSettings();
  const timezone = accountSettings?.currentIanaTimeZone;

  return {
    previous: data?.goalPreviousPercentage,
    goal: data?.goalTodayPercentage,
    date: data?.lastUpdated,
    achieved: data?.userAchieved,
    total: data?.userTotal,
    todaysLabel:
      (timezone && getFullWeekday(new Date(data?.lastUpdated), timezone)) ||
      'today',
    userGoals: mapUsersGoals(data?.userGoals)
  };
};

const mapUsersGoals = (users: IUserAndGoal[]): IReportModalListItem[] => {
  return users?.map((item) => {
    return {
      name: item.name,
      value: `${item.percentage}%`
    };
  });
};

export const getProdUsersTrendsByWeek = (
  trends: IProdUsersGoalTrend[]
): IProdUsersChartData => {
  const achieved = [];
  const missing = [];
  const dateLabels = [];
  const weeklyUsers = [];

  const accountSettings = getAccountSettings();
  const dateFormat = accountSettings?.dateFormat;

  const mainDateFormat =
    dateFormat === 'dd/MM/yyyy' ? SHORT_REVERSED_DATE : SHORT_DATE;

  trends?.forEach((item) => {
    const weekStart = formatDateWithTimezone(
      item.date,
      accountSettings,
      mainDateFormat
    );
    const weekEnd = getWeekEnd(item.date, accountSettings, mainDateFormat);
    dateLabels.push(weekStart);

    if (item.users?.length) {
      weeklyUsers.push({
        weekOf: `Week of ${weekStart} - ${weekEnd}`,
        users: mapUserProductivityAchievementModal(item.users)
      });
    }

    //get the initial percentages
    let convertedAchieved =
      item.total > 0 ? convertToPercent(item.achieved, item.total) : 0;
    let convertedMissing =
      item.total > 0 ? convertToPercent(item.missing, item.total) : 0;

    //total all the values in the stacked bar
    const total = convertedAchieved + convertedMissing;
    if (total) {
      const diff = 100 - total;
      //if total !== 100 (diff !== 0), update largest value in the set with the diff
      if (diff !== 0) {
        //put entire bar's values into one array and sort to find the largest value
        const tmpBarTotal = [
          { type: ProductivityGoalLevel.Achieving, value: convertedAchieved },
          {
            type: ProductivityGoalLevel.Missing,
            value: convertedMissing
          }
        ];
        const sorted = sorter(tmpBarTotal, SortDirection.Descending, 'value');
        const largestValue = sorted[0];

        if (largestValue.type === ProductivityGoalLevel.Missing)
          convertedMissing += diff;
        else convertedAchieved += diff;
      }
    }

    //push the final values
    achieved.push(convertedAchieved);
    missing.push(convertedMissing);
  });

  return {
    labels: dateLabels,
    datasets: [
      {
        label: ProductivityGoalLabel.Achieving,
        data: achieved,
        backgroundColor: blue6,
        hoverBackgroundColor: blue6,
        checkmarkColor: gray7,
        borderWidth: 0
      },
      {
        label: ProductivityGoalLabel.Missing,
        data: missing,
        backgroundColor: red5,
        hoverBackgroundColor: red5,
        borderWidth: 0
      }
    ],
    weeklyUsers
  };
};

export const findSum = (data: any[], type: string): number => {
  return data
    .map((item) => item[type])
    .reduce((acc, cur) => {
      return acc + cur;
    }, 0);
};

export const mapProdUsersTrendsData = (
  data: IProdUsersGoalDto
): IProdUsersTrends => {
  if (data) {
    const achievedUsers = findSum(data?.trends, 'achieved');
    const totalUsers = findSum(data?.trends, 'total');

    const header =
      totalUsers > 0 ? convertToPercent(achievedUsers, totalUsers) : 0;

    const finalData = getProdUsersTrendsByWeek(data?.trends);

    return {
      chartData: finalData,
      percentageHeader: parseInt(header.toFixed()),
      lastUpdated: data.lastUpdated
    };
  }
};

export const displayWidgetCopy = (header: number): IBarChartCopyLayout => {
  if (header < 100) {
    return prodBarChartWidgetCopy.okay;
  }
  return prodBarChartWidgetCopy.great;
};

export const displayModalSubtitle = (header: number): string => {
  if (header < 100) {
    return prodBarModalSubtitles.okay;
  }
  return prodBarModalSubtitles.great;
};
