import { useCallback, useMemo, useRef, useState } from 'react';
import { NotificationType, SortDirection } from '../../common/enums';
import { AliasStateProps } from '../models/AliasStateProps';
import { IUserAlias } from '../models/IUserAlias';
import { IUserAliasState } from '../models/IUserAliasState';
import {
  filterAndSortAliases,
  getAutoCompleteFilteredAliases,
  mapToUserAlias,
  mapToUserAliasDto
} from '../utils/Alias.Utils';

export const useUserAliasState = (props: AliasStateProps): IUserAliasState => {
  const { aliasService } = props;
  const { getUserAliases, saveUserAliases } = aliasService;

  const [aliases, setAliases] = useState<IUserAlias[]>([]);
  const aliasesChanged = useRef([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [aliasNotification, setAliasNotification] =
    useState<NotificationType>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>(undefined);
  const [gridOrderBy, setGridOrderBy] = useState<string>();
  const [gridOrderDirection, setGridOrderDirection] = useState<SortDirection>();
  const [searchCriteria, setSearchCriteria] = useState<any[]>([]);
  const searchBarFilterParams = ['alias', 'logon_Domain', 'user'];

  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setGridOrderBy(newOrderBy);
    setGridOrderDirection(newOrderDirection);
  }, []);

  const onApplyClick = async () => {
    if (aliasesChanged.current?.length > 0) {
      try {
        aliasesChanged.current?.forEach((aliasChanged) => {
          const foundAlias = aliases.find(
            (alias) => alias.userid == aliasChanged.userid
          );
          foundAlias.alias = aliasChanged.alias;
        });
        setIsSaving(true);
        setIsLoading(true);
        await saveUserAliases(aliasesChanged.current);
        setAliasNotification({ msg: 'Aliases were saved', type: 'success' });
        resetAliasesAndChanges();
      } catch (error) {
        let errorMessage = 'Error occurred when saving alias';
        if ((error.status === 400 || error.status === 500) &&
          (error.message || error.data.message || error.data.error)
        ) {
            errorMessage = error.message || error.data.message || error.data.error;
        }
        setAliasNotification({
          msg: errorMessage,
          type: 'error'
        });
      }
      aliasesChanged.current = [];
      setIsSaving(false);
      setIsLoading(false);
    } else {
      setAliasNotification({ msg: 'No aliases to save', type: 'error' });
    }
  };

  const resetAliasesAndChanges = () => {
    aliasesChanged.current = [];
    //will cause page refresh
    setAliases([...aliases]);
  }

  const onCancelClick = async () => {
    aliasesChanged.current = [];
    await getAlias();
  };

  const getAlias = useCallback(
    async (): Promise<void> => {
      setIsLoading(true);
      try {
        const data = await getUserAliases();
        const alias: IUserAlias[] = data.map(mapToUserAlias);
        setAliases(alias);
      } catch (error) {
        setAliasNotification({ msg: 'Unable to load Alias', type: 'error' });
        console.error('ActivTrak Error: Unable to load Alias', error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAliasChange = (idChanged: number, newAlias: string) => {
    if (!Array.isArray(aliasesChanged.current)) {
      //happens in unit tests
      aliasesChanged.current = [];
    }
    let changedAlias = aliasesChanged.current?.find(
      (alias) => alias.userid == idChanged
    );
    const userAlias = aliases?.find((alias) => alias.userid == idChanged);

    if (!changedAlias) {
      changedAlias = mapToUserAliasDto(userAlias);
      changedAlias.alias = newAlias;
      aliasesChanged.current.push(changedAlias);
    } else {
      changedAlias.alias = newAlias;
    }
    if (userAlias)
    {
      userAlias.alias = newAlias;
    }
  };

  const init = useCallback(async (): Promise<void> => {
    if (!aliases) {
      getAlias();
    }
  }, [getAlias, aliases]);

  const filteredAliases = useMemo<IUserAlias[]>(
    (): IUserAlias[] => {
      return filterAndSortAliases(
        aliases,
        searchTerm,
        gridOrderBy,
        gridOrderDirection,
        searchCriteria,
        searchBarFilterParams
      ) as IUserAlias[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aliases, searchTerm, gridOrderDirection, gridOrderBy, searchCriteria]
  );

  const getFilteredUserAliasesForAutocomplete = (
    dataStructure: any[]
  ): any[] => {
    return getAutoCompleteFilteredAliases(
      dataStructure,
      aliases,
      searchBarFilterParams,
      searchTerm
    );
  };

  return {
    aliases,
    filteredAliases,
    isLoading,
    getAlias,
    init, //initial load if Alias array doesn't exist
    onSortOrder,
    setSearchTerm,
    searchTerm,
    handleAliasChange,
    onApplyClick,
    aliasNotification,
    setAliasNotification,
    onCancelClick,
    getFilteredUserAliasesForAutocomplete,
    searchCriteria,
    setSearchCriteria,
    aliasesChanged,
    isSaving
  };
};
