'use strict';

var iconResolverServiceFunctions = require('_app/serviceFunctions/iconResolverServiceFunctions')
    .iconResolverServiceFunctions;

angular
    .module('app')
    .service('iconResolverServiceFunctions', IconResolverServiceFunctions);

IconResolverServiceFunctions.$inject = [];

function IconResolverServiceFunctions() {
    var service = this;

    angular.extend(service, iconResolverServiceFunctions);
}
