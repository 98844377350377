export const IntegrationRoutes = {
  details: '/integrations/v2/details',
  plans: '/integrations/v2/plans',
  states: '/integrations/v2/states',
  createInstance:
    '/integrations/v2/Configurations/:integrationCode',
  configurations: '/integrations/v2/Configurations',
  syncInstance: '/integrations/v2/sync/:instanceId',
  deleteInstance: '/integrations/v2/Configurations/:instanceId',
  solutions: '/integrations/v1/solutions/:userId',
  trayuser: '/integrations/v1/userid',
  pauseTrayInstance: '/integrations/v1/instance/:instanceId/disable/:integrationCode/',
  resumeTrayInstance: '/integrations/v1/instance/:instanceId/enable/:integrationCode/',
  refreshTrayInstance: '/integrations/v1/instance/:instanceId/run/:integrationCode/',
  deleteTrayInstance: '/integrations/v1/instance/:instanceId/:integrationCode'
};
