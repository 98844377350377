import React from 'react';
import { IGroup } from '../../common/models';
import { GroupTypeLabel } from '../../common/components/GroupType/GroupTypeLabel';
import Box from '@mui/material/Box';
import GroupCard from './GroupCard';

type GroupDetailsProps = {
  group: IGroup;
};

const classes = {
  groupLabel: {
    '& div': {
      display: 'inline-block'
    }
  }
};

export default function GroupDetails(props: GroupDetailsProps) {
  const { group } = props;

  return (
    <GroupCard title="Group Details">
      <Box>Total Member Count ({group?.userCount + group?.computerCount})</Box>
      <Box>Group Status: Active</Box>
      <Box sx={classes.groupLabel}>
        Group Type:{' '}
        <GroupTypeLabel
          iconSource={group?.type}
          tooltipChild={group?.mix}
          tooltipContent={`${group?.type} ${group?.mix} Group`}
        />
      </Box>
    </GroupCard>
  );
}
