import Filter from '../models/Filter';

export const groupPulseFilter = (groupPulsesInput, filter, hideEmptyGroups) => {
  let groupPulses = groupPulsesInput;

  if (!groupPulses) {
    return groupPulses;
  }

  if (hideEmptyGroups) {
    groupPulses = groupPulses.filter((gp) => !gp.isEmpty());
  }

  switch (filter) {
    case Filter.ActiveToday:
      return groupPulses.filter((gp) => gp.isActiveToday());
    case Filter.InactiveToday:
      return groupPulses.filter((gp) => gp.isInactiveToday());
    case Filter.All:
    default:
      return groupPulses;
  }
};
