import { IRoleAccess, IGetRolesService } from './';
import { getDefaultConfig, BaseClient } from '../../config';
export class GetRolesService extends BaseClient implements IGetRolesService {
         async getAll(): Promise<IRoleAccess[]> {
           return new Promise((resolve) => {
             this.apiService
               .get(`/api/settings/roleaccess`, getDefaultConfig())
               .then((response: any) => {
                 return resolve(response.data);
               });
           });
         }
       }
