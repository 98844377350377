import React from 'react';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { gray7, primaryAqua, purple2 } from '../../../common/constants';

export const BarLineChart = (props) => {
  const { chartData, additionalFloatingDataText } = props;

  const floatingLabels = {
    id: 'floatingBarValues',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { top }
      } = chart;

      for (let i = 0; i < chart.config.data.datasets[1].data.length; i++) {
        const xPosition = chart.getDatasetMeta(1).data[i].x;
        const yPosition = top + 10;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.fillText(
          chart.config.data.datasets[1].data[i] +
            (additionalFloatingDataText ? additionalFloatingDataText : ''),
          xPosition,
          yPosition
        );
      }
    }
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.5,
    events: [],
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          color: gray7
        }
      },
      y: {
        grace: '15%',
        display: false
      }
    },
    elements: {
      bar: {
        backgroundColor: primaryAqua
      },
      line: {
        borderColor: purple2,
        borderWidth: 2
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={options}
      plugins={[ChartDataLabels, floatingLabels]}
    />
  );
};
