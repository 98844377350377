import React, { useRef } from 'react';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { Role } from '../../common/enums';
import { useAuthorization } from '../../common/services/Authorization';
import { IntegrationCards } from '../components/IntegrationCards';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IntegrationProvider } from '../components/IntegrationProvider';


export const IntegrationsView = () => {
  const authorizationService = useAuthorization();

  const isAdmin = useRef<boolean>(
    authorizationService.hasAnyRole([Role.Admin])
  );

  return (
    <Box sx={{ p: 2 }}>
      {!isAdmin.current && (
        <Grid item xs={12}>
          <Typography sx={{ mb: 2 }}>
            Integrate is only available to admin users.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Integrate With ActivTrak
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
          Unlock additional insights into individual and team productivity by
          connecting your business applications to ActivTrak!
            </Typography>
            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                All Integrations
              </Typography>
            </Box>
        <IntegrationCards />
      </Grid>
    </Box>
  );
};

export const IntegrationsComponent = () => (
  <ReactivTrakComponentWrapper>
    <IntegrationProvider>
      <IntegrationsView />
    </IntegrationProvider>
  </ReactivTrakComponentWrapper>
);
