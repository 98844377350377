'use strict';

angular.module('app').directive('newPassword', [
    'envConfig',
    '$location',
    'loginService',
    function (envConfig, $location, loginService) {
        return {
            restrict: 'E',
            template: require('views/widgets/newPassword.html'),
            scope: {
                password: '=',
                confirmPassword: '=',
                fetchSettings: '=?'
            },
            require: '^form',
            link: function (scope, element, attrs, formCtrl) {
                scope.fetchSettings = angular.isDefined(scope.fetchSettings) ? (scope.fetchSettings === 'true') : true;
                var httpService = require('_app/http').http;
                var credentialAPIService = require('_app/serviceFunctions/credentialAPIService').credentialAPIService(
                    httpService,
                    envConfig
                );

                scope.form = formCtrl;

                scope.getCredentialSettings = function () {
                    credentialAPIService
                        .getCredentialSettings()
                        .then(function (response) {
                            scope.setPasswordSettings(response.data);
                        })
                        .catch(function (e) {
                            console.error(
                                'newPassword.credentialAPIService.getCredentialSettings()',
                                e
                            );
                            scope.setPasswordSettings({
                                password: { advancedSecurity: false }
                            });
                        });
                };

                scope.setPasswordSettings = function (credentials) {
                    // HARDCODED: Duplicating AdvancedSecurity logic from backend
                    if (
                        credentials &&
                        credentials.password &&
                        credentials.password.advancedSecurity
                    ) {
                        scope.setAdvanced();
                    } else {
                        scope.setDefault();
                    }
                };

                scope.setDefault = function () {
                    scope.minlength = 8;
                    scope.minupper = 1;
                    scope.mindigit = 0;
                    scope.minspecial = 1;
                };

                scope.setAdvanced = function () {
                    scope.minlength = 16;
                    scope.minupper = 1;
                    scope.mindigit = 1;
                    scope.minspecial = 2;
                };

                (function () {
                    scope.setDefault();

                    var as = $location.search()['as'];

                    if (as !== undefined) {
                        if (as == 'True') {
                            scope.setAdvanced();
                        }
                    } else if (scope.fetchSettings && loginService.isLoggedIn()) {
                        scope.getCredentialSettings();
                    }
                })();
            }
        };
    }
]);
