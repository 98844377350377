import { Role } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appDevelopmentDesignId: IRoute = {
  name: 'app.development.design.id',
  stateDefinition: {
    url: '/{demoId}',
    data: { pageTitle: 'Development Playground' },
    params: {
      demoId: 'buttons'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: Object.values(Role), // All roles have access to design examples
    read: [],
    customFunction: () => {
      const isEphEnvironment = window.location.href.includes('ephdev');

      return isEphEnvironment || import.meta.env.DEV;
    }
  }
};

export default appDevelopmentDesignId;
