import { FilterDropdown, DashboardFilterList } from '_app/insights/models';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';

class FilterDropdownControl {
    constructor(selections, dashboard, envConfig, showNotification) {
        const that = this;

        const createEvent = () => {
            var event = {
                type: 'dashboard:filters:update',
                filters: this.dashboardFilterList.getEventFilter()
            };

            that.dashboard.postEvent(event);
        };

        const filtersUpdated = (updatedFilter, sendEvent = true) => { 
            that.setCanRun();
            this.dashboardFilterList.updateFilters(updatedFilter);
            if (sendEvent) {
                createEvent();
            }
        };

        this.dashboardFilterList = new DashboardFilterList();
        this.canRun = false;
        this.dashboard = dashboard;
        dashboard.dashboardFilterList = this.dashboardFilterList;
        const filters = that.dashboard.decodeFilters();
        this.dropdowns = _.map(selections, function (selection) {
            const filter = filters && filters[selection.label];
            return new FilterDropdown(
                selection,
                filter || selection.defaultField,
                dashboard.modelId,
                dashboard.pageId,
                filtersUpdated,
                envConfig,
                showNotification
            );
        });
    }

    setCanRun() {
        this.canRun = true;
        if(this.dropdowns.length > Object.keys(this.dashboardFilterList.filters.value).length +1) {
            this.canRun = false;
            return;
        } 
        
        _.forEach(
            this.dropdowns,
            (dropdown) => (this.canRun = this.canRun && !dropdown.restrictRun())
        );
    }

    refreshDashboard() {
        if (!this.canRun) {
            return;
        }

        this.dashboard.postEvent({ type: 'dashboard:run' });
    }
}

export default FilterDropdownControl;
