import { Role, RoleAccessKeys } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { PrivacySetting } from '../../../../../enums/PrivacySetting';
import { IRoute } from '../../../../../models/IRoute';

export const appVideosHistory: IRoute = {
  name: 'app.videos.history',
  stateDefinition: {
    url: '/videos?computer&user&title&url&executable&description',
    params: { computer: null, alarmName: null, user: null },
    template: '<videos-report></videos-report>',
    data: {
      pageTitle: 'Videos',
      filter: { hasFeatureVideo: true, configure: true, export: true },
      exportText: 'export',
      reportName: 'videosReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsVideos,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User],
    featureFlags: [FeatureFlag.VideoPlayback],
    privacySettings: [PrivacySetting.Screenshots]
  }
};

export default appVideosHistory;
