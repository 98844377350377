export enum IntegrationCodes {
  GoogleCalendar = 'uid_gcal',
  OutlookCalendar = 'uid_ocal',
  AzureAD = 'uid_azad'
}

// Reference: https://learn.microsoft.com/en-us/dotnet/api/system.typecode?view=net-8.0
export enum IntegrationTypeCodes {
  Empty = 0,
  String = 18,
  Boolean = 3
}
