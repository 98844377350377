import { DashboardWidgetId } from '../enums';

import NullWidget from '../components/templates/NullWidget';
import QuickActions from '../components/QuickActions/QuickActions';
import OrganizationTrendsSubheader from '../components/Subheaders/OrganizationTrendsSubheader';
import PendingClassifications from '../components/Classifications/PendingClassifications';
import ProductivityGoal from '../components/ProductivityGoal/ProductivityGoal';
import TodaysUserActivity from '../components/TodaysActivity/TodaysUserActivity';
import TodaysActivityChart from '../components/TodaysActivity/TodaysActivityChart';
import TodaysProductivity from '../components/TodaysProductivity/TodaysProductivity';
import TodaysProductivityRatios from '../components/TodaysProductivity/TodaysProductivityRatios';
import TopUsersGroups from '../components/TopUsersGroups/TopUsersGroups';
import UserHealth from '../components/UserHealth/UserHealth';
import VirtualOfficeVisibilitySubheader from '../components/Subheaders/VirtualOfficeVisibilitySubheader';
import VirtualTeamVisibilitySubheader from '../components/Subheaders/VirtualTeamVisibilitySubheader';
import Workload from '../components/Workload/Workload';
import WorkloadBarChart from '../components/Workload/WorkloadBarChart';
import TeamTrendsSubheader from '../components/Subheaders/TeamTrendsSubheader';
import TeamProductivityGoal from '../components/ProductivityGoal/ProductivityGoalBarChart';
import TodaysTopCategories from '../components/TopCategories/TopCategories';
import { TodaysProductivityOutlook } from '../components/TodaysProductivityOutlook/TodaysProductivityOutlook';

const loadWidget = (id: DashboardWidgetId) => {
  switch (id) {
    case DashboardWidgetId.QuickActions:
      return () => QuickActions;
    case DashboardWidgetId.OrganizationTrendsSubheader:
      return () => OrganizationTrendsSubheader;
    case DashboardWidgetId.PendingClassifications:
      return () => PendingClassifications;
    case DashboardWidgetId.ProductivityGoal:
      return () => ProductivityGoal;
    case DashboardWidgetId.TodaysUserActivity:
      return () => TodaysUserActivity;
    case DashboardWidgetId.TodaysActivityChart:
      return () => TodaysActivityChart;
    case DashboardWidgetId.TodaysProductivity:
      return () => TodaysProductivity;
    case DashboardWidgetId.TodaysProductivityRatios:
      return () => TodaysProductivityRatios;
    case DashboardWidgetId.TopUsersGroups:
      return () => TopUsersGroups;
    case DashboardWidgetId.UserHealth:
      return () => UserHealth;
    case DashboardWidgetId.VirtualOfficeVisibilitySubheader:
      return () => VirtualOfficeVisibilitySubheader;
    case DashboardWidgetId.VirtualTeamVisibilitySubheader:
      return () => VirtualTeamVisibilitySubheader;
    case DashboardWidgetId.Workload:
      return () => Workload;
    case DashboardWidgetId.WorkloadBalanceBarChart:
      return () => WorkloadBarChart;
    case DashboardWidgetId.TeamTrendsSubheader:
      return () => TeamTrendsSubheader;
    case DashboardWidgetId.TeamProductivityGoal:
      return () => TeamProductivityGoal;
    case DashboardWidgetId.TodaysTopCategories:
      return () => TodaysTopCategories;
    case DashboardWidgetId.TodaysProductivityOutlook:
      return () => TodaysProductivityOutlook;

    default:
      return () => NullWidget;
  }
};

export default loadWidget;
