import { atHelperFunctions } from '../serviceFunctions/atHelperFunctions';
import { FeatureFlag } from '_reactivtrak/src/common/enums/FeatureFlag';

const _submitMarketoForm = (id, type, service) => {
    if (!id || !type) {
        return;
    }

    window.MktoForms2.loadForm(service.marketoConfig.formUrl, service.marketoConfig.munchkinId, id, (form) => {
        service.configureForm(form, service.fields, service.getHardcodedMktoHiddenFields(), function () {
            service.showNotification(
                `${type} request received. A member of our sales team will reach out to you shortly.`,
                'success'
            );

            return false;
        });

        window.MktoForms2.lightbox(form).show();
    });
};

const _formatTimeDateStrings = (dateTime) => {
    if (!dateTime) {
        return {};
    }

    const timeString = atHelperFunctions.formatDate(dateTime, {
        onlyTime: true,
        span: false,
        noSeconds: true
    });
    const dateString = atHelperFunctions.formatDate(dateTime, {
        onlyDate: true,
        span: false
    });

    return { timeString, dateString };
};

class insightsPromoService {
    /**
     * Insights promo page service
     * @param {*} marketoConfig Marketo configuration object
     * @param {*} envConfig Environment configuration service
     * @param {*} accountSettings Account settings object
     * @param {*} accountApiHelper Account API Helper service
     * @param {*} notificationService Notification service
     * @param {*} utmService UTM service
     * @param {*} authorizationService Authorization service
     */
    constructor(
        marketoConfig,
        { apiUrl },
        { phone, firstName, lastName, username, companyName, insightsSetting = {} },
        accountApiHelper,
        { showNotification },
        { configureForm, getHardcodedMktoHiddenFields },
        authorizationService
    ) {
        this.marketoConfig = marketoConfig;
        this.getApiUrl = apiUrl;
        this.accountApiHelper = accountApiHelper;
        this.fields = {
            Phone: phone,
            FirstName: firstName,
            LastName: lastName,
            Email: username,
            Company: companyName
        };
        this.showNotification = showNotification;
        this.configureForm = configureForm;
        this.getHardcodedMktoHiddenFields = getHardcodedMktoHiddenFields;
        this.insightsSetting = insightsSetting;
        this.insightsAvailable = false;
        this.loading = true;
        this.authorizationService = authorizationService;
    }

    async getInsightsSettings() {
        const accountSettings = await this.accountApiHelper.loadSettings();
        const insightsAvailable = this.authorizationService.hasFeature(FeatureFlag.InsightsAvailable);

        this.insightsAvailable = insightsAvailable;
        this.insightsSetting = accountSettings.insightsSetting;

        return {
            enabled: this.insightsAvailable,
            onBoardRequestTime: _formatTimeDateStrings(this.insightsSetting.onBoardRequestTime),
            inProgress: this.insightsSetting.inProgress
        };
    }

    async updateNavigation() {
        await this.accountApiHelper.loadSettings();
    }

    requestForm(id, type) {
        _submitMarketoForm(this.marketoConfig.insights[id], type, this);
    }

    requestFormCoaching(id, type) {
        _submitMarketoForm(this.marketoConfig.coaching[id], type, this);
    }

    requestFormImpact(id, type) {
        _submitMarketoForm(this.marketoConfig.impact[id], type, this);
    }
}

export default insightsPromoService;
