import version from '../../../../version.json';
import {
  datadogLogs,
  LogsInitConfiguration,
  StatusType
} from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { activTrakConfig } from '../../../../_reactivtrak/src/common/helpers/activTrakConfig';
import { DataDogConfig } from '../helpers/activTrakConfig/IActivTrakConfig';

type DataDogConfigurations = {
  commonConfig?: Partial<LogsInitConfiguration> & Partial<RumInitConfiguration>;
  dataDogConfig?: DataDogConfig;
};

const getConfigs = (): DataDogConfigurations => {
  if (!activTrakConfig || !version) {
    console.error(
      'ActivTrak Error: Unable to initialize logging due to missing config or version.'
    );
    return {};
  }

  const dataDogConfig = activTrakConfig.datadog;

  if (!dataDogConfig) {
    return {};
  }

  return {
    commonConfig: {
      clientToken: dataDogConfig.token,
      site: dataDogConfig.site,
      service: dataDogConfig.service,
      env: dataDogConfig.environment,
      version: version?.version,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      trackSessionAcrossSubdomains: true,
      silentMultipleInit: true
    },
    dataDogConfig
  };
};

export const initializeDataDogLogging = (): void => {
  if (!activTrakConfig?.name || import.meta.env.DEV) {
    return;
  }
  const { commonConfig, dataDogConfig } = getConfigs();

  if (!commonConfig || !dataDogConfig) {
    console.warn(
      'ActivTrak Warn: Skipping DataDog Logging initialization due to missing config values'
    );
    return;
  }

  datadogLogs.init({
    ...(commonConfig as LogsInitConfiguration),
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all'
  });
  datadogLogs.logger.setLevel(dataDogConfig.logLevel as StatusType);
};

export const initializeDataDogRum = (): void => {
  if (!activTrakConfig?.name || import.meta.env.DEV) {
    return;
  }
  const { commonConfig, dataDogConfig } = getConfigs();

  if (!commonConfig || !dataDogConfig) {
    console.warn(
      'ActivTrak Warn: Skipping DataDog RUM initialization due to missing config values'
    );
    return;
  }

  datadogRum.init({
    ...(commonConfig as RumInitConfiguration),
    applicationId: '8b4263fe-3ea8-45a9-a0e5-8509c36e375b',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    trackUserInteractions: true
  });
};

export const initializeDataDog = (): void => {
  initializeDataDogLogging();
};

export const addDataDogContext = (
  accountId: string,
  userId = 'unknown'
): void => {
  datadogRum.setGlobalContextProperty('Acct', accountId);
  datadogRum.setGlobalContextProperty('username', userId);
  datadogRum.setUserProperty('Acct', accountId);
  datadogRum.setUserProperty('username', userId);
  datadogLogs.setGlobalContextProperty('Acct', accountId);
  datadogLogs.setGlobalContextProperty('username', userId);
  datadogLogs.setUserProperty('Acct', accountId);
  datadogLogs.setUserProperty('username', userId);
};

export const startDataDogRumSessionRecording = (
  bundleFlag = false,
  envName = 'local'
): void => {
  if (!bundleFlag && envName !== 'brainforestprod') {
    return;
  }

  initializeDataDogRum();
  datadogRum.startSessionReplayRecording();
};

export const stopDataDogRumSessionRecording = (): void => {
  datadogRum.stopSessionReplayRecording();
};

export const removeDataDogContext = (): void => {
  datadogRum.clearGlobalContext();
  datadogRum.clearUser();
  datadogLogs.clearGlobalContext();
  datadogLogs.clearUser();
};
