import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export const SuccessIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? 62;
  const width = props.width ?? 63;
  const title = props.title ?? 'Success';
  const className = props.className;

  let classList = 'at-icon at-icon-success';

  if (className) classList += ` ${className}`;

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 63 62`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classList}
      fill="none"
    >
      <title>{title}</title>

      <path
        d="M46.8191 43.456L5.13963 61.5916C2.42275 62.6729 -0.277249 59.9961 0.780376 57.2701L18.994 14.9346"
        fill="#FFB636"
      />
      <path
        d="M0.780387 57.2701L2.22789 53.9056C2.35201 53.7164 2.47576 53.5269 2.60001 53.3377C9.79964 42.3698 17.1048 31.4688 24.6594 20.7418L33.13 29.4243C26.7388 33.812 5.98626 55.2579 1.63939 60.9464C0.693137 60.076 0.233262 58.6804 0.780387 57.2701Z"
        fill="#FFD469"
      />
      <path
        d="M37.5478 24.5543C45.2795 32.286 49.5084 40.5928 46.9933 43.108C44.4781 45.6231 36.1714 41.3943 28.4395 33.6625C20.7076 25.9308 16.4789 17.624 18.994 15.1088C21.5091 12.5935 29.8159 16.8224 37.5478 24.5543Z"
        fill="#A06C33"
      />
      <path
        d="M18.9501 39.9465C18.9501 40.698 18.3409 41.3071 17.5895 41.3071C16.8381 41.3071 16.2289 40.698 16.2289 39.9465C16.2289 39.195 16.838 38.5859 17.5895 38.5859C18.341 38.5859 18.9501 39.195 18.9501 39.9465Z"
        fill="#F7F9AA"
      />
      <path
        d="M62.2483 25.645C62.2483 26.7235 61.374 27.5978 60.2955 27.5978C59.217 27.5978 58.3428 26.7235 58.3428 25.645C58.3428 24.5665 59.217 23.6923 60.2955 23.6923C61.374 23.6923 62.2483 24.5665 62.2483 25.645ZM49.2079 51.6478C48.1294 51.6478 47.2551 52.522 47.2551 53.6005C47.2551 54.679 48.1294 55.5533 49.2079 55.5533C50.2864 55.5533 51.1606 54.679 51.1606 53.6005C51.1606 52.522 50.2863 51.6478 49.2079 51.6478Z"
        fill="#FFB636"
      />
      <path
        d="M43.9936 8.9404C43.8261 10.0735 43.094 11.0744 41.9848 11.6864C41.2191 12.1089 40.3186 12.3285 39.384 12.3285C39.0862 12.3284 38.7889 12.3064 38.4943 12.2628C37.8333 12.165 37.1717 12.2648 36.6793 12.5364C36.4586 12.6581 36.0794 12.9245 36.0177 13.3411C35.9562 13.7578 36.2422 14.1225 36.4181 14.3029C36.8004 14.6948 37.3837 14.9755 38.0242 15.0804C38.0418 15.0823 38.0593 15.0829 38.077 15.0855C40.7793 15.485 42.7096 17.6255 42.3796 19.8571C42.2121 20.9904 41.48 21.9913 40.3707 22.6033C39.605 23.0258 38.7046 23.2454 37.77 23.2454C37.4722 23.2453 37.1749 23.2233 36.8803 23.1797C36.2195 23.0818 35.5576 23.1815 35.0652 23.4533C34.8445 23.575 34.4652 23.8414 34.4036 24.258C34.3049 24.9267 35.1259 25.8094 36.4624 26.0069C37.1895 26.1144 37.6918 26.791 37.5843 27.5183C37.4866 28.1795 36.9183 28.6548 36.2694 28.6548C36.2045 28.6548 36.139 28.65 36.073 28.6403C33.3706 28.2408 31.4405 26.1002 31.7704 23.8687C31.9379 22.7354 32.67 21.7345 33.7792 21.1225C34.7861 20.567 36.0257 20.3623 37.2697 20.5462C37.9307 20.6438 38.5922 20.5441 39.0847 20.2725C39.3055 20.1508 39.6847 19.8844 39.7463 19.4678C39.8435 18.81 39.0492 17.9464 37.751 17.7305C37.7297 17.7284 37.7083 17.7266 37.687 17.7235C36.4431 17.5395 35.3156 16.985 34.5126 16.1619C33.628 15.2551 33.2167 14.0851 33.3844 12.9518C33.5519 11.8186 34.284 10.8178 35.3931 10.2058C36.4 9.65027 37.6396 9.44552 38.8836 9.6294C39.5443 9.7269 40.2062 9.6274 40.6986 9.35577C40.9193 9.23402 41.2986 8.96764 41.3602 8.55102C41.4217 8.13439 41.1357 7.76964 40.9598 7.58927C40.5671 7.18677 39.9627 6.89977 39.3015 6.80214C38.5743 6.69464 38.072 6.01802 38.1795 5.29077C38.2868 4.56352 38.9637 4.06177 39.6908 4.16864C40.9347 4.35264 42.0622 4.90714 42.8652 5.73027C43.75 6.63714 44.1612 7.80714 43.9936 8.9404Z"
        fill="#BEA4FF"
      />
      <path
        d="M9.7164 18.9611C9.7164 20.1832 8.72565 21.174 7.50352 21.174C6.2814 21.174 5.29065 20.1832 5.29065 18.9611C5.29065 17.739 6.2814 16.7482 7.50352 16.7482C8.72565 16.7482 9.7164 17.7389 9.7164 18.9611ZM49.272 16.4045C47.86 16.4045 46.7155 17.5491 46.7155 18.961C46.7155 20.3729 47.8601 21.5175 49.272 21.5175C50.6839 21.5175 51.8285 20.3729 51.8285 18.961C51.8285 17.5491 50.684 16.4045 49.272 16.4045ZM26.4411 48.4874C25.0291 48.4874 23.8846 49.632 23.8846 51.0439C23.8846 52.4557 25.0293 53.6004 26.4411 53.6004C27.8531 53.6004 28.9976 52.4557 28.9976 51.0439C28.9976 49.632 27.8531 48.4874 26.4411 48.4874ZM60.354 36.7816C60.7406 36.033 60.4471 35.1126 59.6985 34.726C59.4 34.572 59.0438 34.3675 58.6313 34.1309C55.0071 32.0517 47.4664 27.7256 38.5873 34.8945C37.9316 35.4237 37.8294 36.3844 38.3586 37.0399C38.8878 37.6955 39.8483 37.798 40.504 37.2685C47.7584 31.4115 53.6143 34.7704 57.1129 36.7775C57.5383 37.0215 57.94 37.252 58.2983 37.437C58.4763 37.5289 58.6707 37.5849 58.8703 37.6018C59.07 37.6186 59.271 37.5959 59.4619 37.5351C59.6528 37.4742 59.8298 37.3764 59.9829 37.2471C60.136 37.1178 60.2621 36.9596 60.354 36.7816Z"
        fill="#FF6E83"
      />
      <path
        d="M53.8543 6.84703C53.8543 7.59853 53.245 8.20765 52.4936 8.20765C51.7421 8.20765 51.133 7.59853 51.133 6.84703C51.133 6.09553 51.7421 5.4864 52.4936 5.4864C53.2451 5.48628 53.8543 6.09553 53.8543 6.84703ZM5.2905 0.486779C4.539 0.486779 3.92987 1.0959 3.92987 1.8474C3.92987 2.5989 4.539 3.20803 5.2905 3.20803C6.042 3.20803 6.65125 2.5989 6.65125 1.8474C6.65125 1.0959 6.042 0.486779 5.2905 0.486779ZM26.6256 18.2439C26.7795 17.9421 26.9692 17.5985 27.1701 17.2348C28.1894 15.3884 29.5851 12.8599 29.6691 9.9149C29.77 6.3769 27.9274 3.16465 24.1926 0.367404C23.5442 -0.118221 22.6252 0.0139041 22.14 0.662029C21.6545 1.31028 21.7864 2.22928 22.4346 2.71465C25.4034 4.93815 26.8109 7.26603 26.7377 9.83128C26.674 12.0653 25.5256 14.1458 24.6027 15.8174C24.3901 16.2028 24.1891 16.5666 24.0136 16.9108C23.6455 17.6321 23.9319 18.5153 24.6531 18.8834C24.859 18.9889 25.0871 19.044 25.3185 19.044C25.589 19.0442 25.8542 18.9695 26.0849 18.8283C26.3156 18.6871 26.5027 18.4849 26.6256 18.2439Z"
        fill="#59CAFC"
      />
    </svg>
  );
};
