import {
  gray1,
  gray5,
  gray7,
  secondaryFontSize,
  smallFontSize
} from '../../common/constants';

// NOTE: Do not directly modify base_configs but rather extend them in chart itself.

export const BARCHART_BASE_CONFIG = {
  options: {
    indexAxis: 'y' as const,
    responsive: true,
    borderSkipped: false
  },
  legend: {
    display: false
  },
  tooltip: {
    enabled: true,
    caretSize: 0,
    backgroundColor: gray1,
    titleColor: gray7,
    bodyColor: gray7,
    footerColor: gray7,
    borderColor: gray5,
    borderWidth: 1,
    cornerRadius: 0,
    footerFont: {
      style: 'italic',
      weight: '400'
    },
    position: 'nearest'
  },
  dataLabels: {
    display: true,
    color: gray7,
    anchor: 'end',
    align: 'end',
    font: {
      weight: '400',
      style: 'normal',
      size: secondaryFontSize,
      lineHeight: '18px',
      letterSpacing: '0.25px'
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: gray7
      }
    }
  },
  onHover: (event, chartElement) => {
    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  }
};

export const DONUT_BASE_CONFIG = {
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
      boxWidth: 30,
      font: {
        size: secondaryFontSize
      },
      padding: 20
    },
    onClick: () => ''
  },
  tooltip: {
    bodyColor: gray7,
    footerColor: gray7,
    borderWidth: 0,
    cornerRadius: 0,
    caretSize: 0,
    padding: {
      top: 8,
      bottom: 8,
      left: 16,
      right: 16
    },
    position: 'nearest'
  }
};

export const PIE_BASE_CONFIG = {
  layout: {
    padding: 35
  },
  datalabels: {
    display: true,
    color: gray7,
    align: 'end',
    anchor: 'end',
    offset: 0,
    borderWidth: 1,
    font: {
      weight: 'normal',
      size: smallFontSize
    }
  }
};
