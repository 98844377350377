import moment from 'moment';
import authorization from '../../_reactivtrak/src/common/helpers/authorization';
import lodash from 'lodash';

let _userToken;
let _loginService;
let _httpService;
let _envConfig;
let _userLastSeenList = [];

const findClaims = (claimTypeFragment, claims) => {
    const results = lodash.find(claims, (claim) => {
        return claim.Type.includes(claimTypeFragment);
    });
    return results;
};

const filterClaims = (claimTypeFragment, claims) => {
    const results = lodash.filter(claims, (claim) => {
        return claim.Type.includes(claimTypeFragment);
    });
    return results;
};

// TODO: Mapout and check all unit tests and dependcies before increasing flexiblity
const _getClaimValue = (claimTypeFragment) => {
    const claims = getUserTokenClaims();
    const claim = findClaims(claimTypeFragment, claims);
    return claim && claim.Value;
};

const _getClaimsValue = (claimTypeFragment) => {
    const claims = getUserTokenClaims();
    const results = filterClaims(claimTypeFragment, claims);
    return results;
};

let getUserLastSeenApiCall = (tag) => {
    return _httpService.get(_envConfig.apiUrl() + '/api/userlastseen/' + tag);
};

let setUserLastSeenApiCall = (tag) => {
    return _httpService.post(_envConfig.apiUrl() + '/api/userlastseen/' + tag);
};

const initialize = (loginService, httpService, envConfig) => {
    _loginService = loginService;
    _httpService = httpService;
    _envConfig = envConfig;
};

// TODO: Mapout and check all unit tests and dependcies before updating with decodeToken
const setUserToken = (token) => {
    if (!token || typeof token !== 'string') {
        return 'Token not given';
    }

    let base64Url = token.split('.')[1];
    if (!base64Url) {
        console.error('ActivTrak Error: Cannot set user token due to invalid token string split.');
        return 'Invalid token string split';
    }

    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload;

    try {
        jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        _userToken = {
            encoded: token,
            decoded: JSON.parse(jsonPayload)
        };
    } catch (error) {
        console.error('ActivTrak Error: Cannot set user token due to invalid Json Object. ' + error);
        _loginService?.clearScope();
        return 'Invalid json object';
    }

    authorization?.setAuthorizations();
};

//Todo: Replace all Token Decoding with this function and update Unit Tests
const decodeToken = (token) => {
    if (!token || typeof token !== 'string') {
        return false;
    }

    let base64Url = token.split('.')[1];
    if (!base64Url) {
        console.error('ActivTrak Error: Cannot set user token due to invalid token string split.');
        return false;
    }

    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload;

    try {
        jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('ActivTrak Error: Cannot set user token due to invalid Json Object. ' + error);
        _loginService?.clearScope();
        return false;
    }
};

const getUserToken = () => {
    return _userToken;
};

const getEncodedUserToken = () => {
    return _userToken ? _userToken.encoded : '';
};

const getUserTokenClaims = () => {
    return _userToken && _userToken.decoded && _userToken.decoded.Claims;
};

const clearUserToken = () => {
    _userToken = undefined;
};

let _promises = [];

const getUserLastSeen = (tag, options) => {
    _promises[tag] =
        _promises[tag] && !_promises[tag].done && !(options && options.force)
            ? _promises[tag]
            : new Promise((resolve, reject) => {
                  let userLastSeenItem = lodash.find(_userLastSeenList, (i) => i.tag === tag);
                  if (userLastSeenItem && userLastSeenItem.lastSeen) {
                      resolve(userLastSeenItem.lastSeen);
                  } else {
                      updateUserLastSeen(tag)
                          .then((lastSeen) => {
                              _promises[tag].done = true;
                              resolve(lastSeen);
                          })
                          .catch((e) => {
                              _promises[tag].done = true;
                              reject(e);
                          });
                  }
              });

    return _promises[tag];
};

const updateUserLastSeenList = (tag, userLastSeen) => {
    if (userLastSeen) {
        let userLastSeenItem = lodash.find(_userLastSeenList, (i) => i.tag === tag) || {};
        let pushToList = !(userLastSeenItem && userLastSeenItem.tag);

        userLastSeenItem.tag = tag;
        userLastSeenItem.lastSeen = userLastSeen;

        if (pushToList) {
            _userLastSeenList.push(userLastSeenItem);
        }

        return userLastSeenItem.lastSeen;
    } else {
        return;
    }
};

const setUserLastSeen = (tag) => {
    return new Promise((resolve, reject) => {
        setUserLastSeenApiCall(tag)
            .then(() => {
                updateUserLastSeen(tag).then((results) => {
                    resolve(results);
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const updateUserLastSeen = (tag) => {
    return new Promise((resolve, reject) => {
        getUserLastSeenApiCall(tag)
            .then((results) => {
                resolve(updateUserLastSeenList(tag, results && results.data && results.data.userLastSeen));
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const getUserName = () => {
    return _getClaimValue('identity/claims/name');
};

const getAccountId = () => {
    return _userToken && _userToken.decoded && _userToken.decoded.AccountId;
};

const getTokenExpiration = () => {
    if (_userToken && _userToken.decoded && _userToken.decoded.exp) {
        return moment.unix(_userToken.decoded.exp);
    }

    return moment.unix(0);
};

const getIsImposter = () => {
    const imposterValue = _getClaimValue('imposter');
    return imposterValue !== undefined;
};
let enableAccountWizard = () => {
    return _httpService.post(_envConfig.apiUrl() + '/api/settings/accountwizard/enable');
};

const getCCImposter = () => {
    const isSupport = localStorage.getItem('activTrak.support.backup');
    if (!isSupport) {
        return false;
    }
    try {
        const token = JSON.parse(JSON.parse(isSupport))?.currentUser?.token;
        if (!token || typeof token !== 'string') {
            return false;
        }
        const cleanToken = decodeToken(token);
        if (!cleanToken) {
            return false;
        }
        const claims = cleanToken?.Claims;
        if (!claims) {
            return false;
        }
        // TODO: Replace with seperate _getClaimsValue
        const claim = findClaims('imposter', claims);
        return claim !== undefined;
    } catch (error) {
        console.error(`ActivTrak Error:`, error);
        return false;
    }
};

const getIsMSPAccount = () => {
    return _getClaimValue('channeltype')?.includes('MSP') ?? false;
};

const getIsCCEnterprise = () => {
    const roleClaims = _getClaimsValue('identity/claims/role');
    const ccEnterpriseAccess = lodash.find(roleClaims, (claim) => {
        return claim.Value == 'CCEnterpriseAccess';
    });
    return ccEnterpriseAccess !== undefined;
};

export const userServiceFunctions = {
    initialize,
    setUserToken,
    getUserToken,
    getUserTokenClaims,
    getUserName,
    getAccountId,
    getTokenExpiration,
    getIsImposter,
    getCCImposter,
    clearUserToken,
    getEncodedUserToken,
    getUserLastSeen,
    setUserLastSeen,
    updateUserLastSeen,
    enableAccountWizard,
    getIsMSPAccount,
    getIsCCEnterprise
};
