import React, { useCallback, useEffect, useState } from 'react';
import { ticketsService, useTicketsState } from '../services';
import { useNotifications } from '../../common/services/Notifications';

import { defaultTicketState, statusFilters } from '../constants';
import { ITicket, ITicketFilter, ITicketPayload } from '../models';
import { Modal } from '../enums';
import { getFollowUpTicketDescription, useTicketsColumns } from '../utils';
import { SortDirection } from '../../common/enums';
import { Button, Grid } from '@mui/material';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import TicketsSearchBar from '../components/TicketsSearchBar';
import { GridActionButton } from '../../common/styles/app.component.styles';
import {
  NoResultsContainerSC,
  NoResultsLabelSC
} from '../../common/components/NoResults/BaseNoResults.styles';
import searching from '../../../../app/images/no_results.svg';
import { ListFilter } from '../../common/components/Filter/ListFilter';
import { TotalCount } from '../../common/components/Totals/TotalCount';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RefreshTooltipSC } from '../styles';
import { Refresh } from '@mui/icons-material';
import { CreateTicketModal } from '../components/CreateTicketModal';
import { ConfirmCancelTicket } from '../components/ConfirmCancelTicket';
import { CreateCommentModal } from '../components/CreateCommentModal';
import { TicketDetailsModal } from '../components/TicketDetailsModal';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { useUIRouterQueryString } from '../../common/hooks/useUIRouterQueryString';

const SupportTicketsView = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [currentFilter, setCurrentFilter] = useState<ITicketFilter>();
  const [currentModal, setCurrentModal] = useState<Modal>();
  const [newTicket, setNewTicket] =
    useState<ITicketPayload>(defaultTicketState);

  const service = ticketsService();
  const ticketsState = useTicketsState({ service });
  const notificationService = useNotifications();
  
  const urlQuery = useUIRouterQueryString();

  const {
    tickets,
    currentTicket,
    currentTicketComments,
    ticketsNotification,
    isLoading,
    isCommentsLoading,
    percentUploaded,
    init,
    getTickets,
    setTicketsNotification,
    searchTickets,
    filterTickets,
    setSortedTickets,
    setCurrentTicket,
    addTicket,
    addTicketComment,
    getTicketComments
  } = ticketsState;

  const columns = useTicketsColumns();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const ticketId = urlQuery.get('id');

    if (tickets?.length && ticketId) {
      const id = parseInt(ticketId);
      const selected = tickets.find((ticket) => ticket.id === id);
      if (selected) {
        setCurrentTicket(selected);
        setCurrentModal(Modal.ViewTicket);
      }
    }
  }, [setCurrentTicket, tickets, urlQuery]);

  useEffect(() => {
    searchTickets(searchQuery);
  }, [searchQuery, searchTickets]);

  useEffect(() => {
    filterTickets(currentFilter);
  }, [currentFilter, filterTickets]);

  useEffect(() => {
    if (ticketsNotification?.msg && ticketsNotification?.type) {
      notificationService[ticketsNotification.type](ticketsNotification.msg);
      setTicketsNotification(undefined);
    }
  }, [
    notificationService,
    ticketsNotification?.msg,
    ticketsNotification?.type,
    setTicketsNotification
  ]);

  const handleClickRowGrid = useCallback(
    (selected: ITicket) => {
      setCurrentTicket(selected);
      setCurrentModal(Modal.ViewTicket);
    },
    [setCurrentTicket]
  );

  const handleCancel = () => {
    setCurrentModal(undefined);
    setNewTicket(defaultTicketState);
  };

  const handleAddTicket = () => {
    addTicket(newTicket);
    handleCancel();
  };

  const handleFollowUpTicket = () => {
    const description = getFollowUpTicketDescription(currentTicket);

    const newTicketState = { ...defaultTicketState, body: description };
    setNewTicket(newTicketState);
    setCurrentModal(Modal.CreateTicket);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={4}
          xl={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Button
            variant="contained"
            onClick={() => setCurrentModal(Modal.CreateTicket)}
          >
            Create ticket
          </Button>
        </Grid>
        <Grid item xs={12} md={8} xl={6}>
          <TicketsSearchBar filter={searchQuery} setFilter={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <GridActionButton onClick={getTickets} startIcon={<Refresh />}>
                Refresh
              </GridActionButton>{' '}
              <RefreshTooltipSC
                title="Tickets list might take some time to show changes"
                placement="right"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ verticalAlign: 'middle' }}
                />
              </RefreshTooltipSC>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <TotalCount label="total tickets" count={tickets?.length} />
              <ListFilter
                listItems={statusFilters}
                onClick={setCurrentFilter}
                defaultSelection={currentFilter?.label}
              />
            </Grid>
            <Grid item xs={12}>
              {!isLoading && !tickets?.length ? (
                <NoResultsContainerSC>
                  <img src={searching} alt="search completed" />
                  <NoResultsLabelSC variant="body1">
                    Uh oh! No results were found.
                  </NoResultsLabelSC>
                  <NoResultsLabelSC variant="body1">
                    Try changing the filters or add a new ticket.
                  </NoResultsLabelSC>
                </NoResultsContainerSC>
              ) : (
                <CustomGrid
                  height={'65vh'}
                  isVirtualized={true}
                  isLoading={isLoading}
                  data={tickets}
                  columns={columns}
                  initialSortField="updatedAt"
                  initialSortDirection={SortDirection.Descending}
                  onSortOrder={setSortedTickets}
                  onClickRow={handleClickRowGrid}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* re-mount the modals when needed to re-instantiate functionality */}
      {currentModal === Modal.CreateTicket && (
        <CreateTicketModal
          isOpen={currentModal === Modal.CreateTicket}
          onClose={handleCancel}
          onSubmit={handleAddTicket}
          onOpenModal={setCurrentModal}
          percentUploaded={percentUploaded}
          newTicket={newTicket}
          setNewTicket={setNewTicket}
        ></CreateTicketModal>
      )}
      {currentModal === Modal.ConfirmCancelTicket && (
        <ConfirmCancelTicket
          isOpen={currentModal === Modal.ConfirmCancelTicket}
          onContinue={() => setCurrentModal(Modal.CreateTicket)}
          percentUploaded={percentUploaded}
          onCancelChanges={handleCancel}
        />
      )}
      {currentModal === Modal.ViewTicket && (
        <TicketDetailsModal
          isOpen={currentModal === Modal.ViewTicket}
          isLoading={isCommentsLoading}
          currentTicket={currentTicket}
          currentTicketComments={currentTicketComments}
          onOpenModal={setCurrentModal}
          onClose={() => setCurrentModal(undefined)}
          onFollowUpTicket={handleFollowUpTicket}
          onLoad={getTicketComments}
        ></TicketDetailsModal>
      )}
      {currentModal === Modal.CreateComment && (
        <CreateCommentModal
          isOpen={currentModal === Modal.CreateComment}
          onClose={() => setCurrentModal(Modal.ViewTicket)}
          onSubmit={addTicketComment}
          ticketId={currentTicket?.id}
          percentUploaded={percentUploaded}
        ></CreateCommentModal>
      )}
    </>
  );
};

export default SupportTicketsView;

export const SupportTicketsViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <SupportTicketsView />
  </ReactivTrakComponentWrapper>
);
