import { defer, of, zip, interval, iif } from 'rxjs';
import { apiService } from 'activtrak-ui-utilities';
import { map, repeat, tap, share, delayWhen, mergeMap, catchError } from 'rxjs/operators';
import moment from 'moment';
import { pollSettings } from '../pollSettings';
import GroupPulse from '../../models/GroupPulse';
import RealTime from '../../models/RealTime';

let lastPoll;
let skipDelay;
let refreshData;
let groupPulses;
let _settings = {};

class GroupPulseService {
    constructor() {
        if (!GroupPulseService.instance) {
            GroupPulseService.instance = this;
        }

        return GroupPulseService.instance;
    }

    _pollReady() {
        return lastPoll ? moment.utc().diff(lastPoll) >= pollSettings.teamPulse.pollDelay : true;
    }

    _getGroupPulses(url) {
        if (!refreshData && !this._pollReady()) {
            return of(groupPulses);
        }

        return defer((_) =>
            apiService.get(url, {
                authorizationRequired: true
            })
        ).pipe(
            map((result) => result.data.map((gp) => new GroupPulse(gp))),
            catchError((error) => {
                return of([]);
            }),
            tap((_) => {
                lastPoll = moment.utc();
            })
        );
    }

    _getRealTime(url, params) {
        return defer((_) =>
            apiService.get(`${url}/api/realtime?${params}`, {
                authorizationRequired: true
            })
        ).pipe(
            map((result) => result.data.data.map((rt) => new RealTime(rt))),
            catchError((error) => {
                return of(null);
            })
        );
    }

    _mergeGroupPulse(gps, realTimes) {
        if (!realTimes) {
            return gps;
        }
        return gps.map((gp) => {
            var userIds = gp.userPulses.map((up) => parseInt(up.userId));
            var rtUsers = realTimes.filter((rt) => userIds.includes(parseInt(rt.userId)));
            gp.inactiveCount = userIds.length - rtUsers.length;
            gp.activeCount = rtUsers.filter((rt) => rt.isActive()).length;
            gp.passiveCount = rtUsers.filter((rt) => rt.isPassive()).length;
            return gp;
        });
    }

    setSkipDelay() {
        skipDelay = true;
    }

    setRefreshData() {
        refreshData = true;
    }

    setPausePolling(value) {
        _settings.pollingPaused = value;
    }

    isPaused() {
        return _settings.pollingPaused;
    }

    pollGroupPulses(settings) {
        _settings = settings;

        let resolvedUrl = `${settings.url}/api/teampulse/groups?${_settings.params}`;

        if (_settings.reportingUrl) {
            resolvedUrl = _settings.isWidget
                ? `${_settings.reportingUrl}/reports/v1/teampulse/groups/widget?${_settings.params}`
                : `${_settings.reportingUrl}/reports/v1/teampulse/groups?${_settings.params}`;
        }

        // Do not call group pulse API if filter is set to single user or computer
        const skipUserTypes = ['user', 'computer', 'alias'];
        const userType = new URLSearchParams(_settings.params).get('userType') || '';
        const shouldMakeInitialCall = !_.includes(skipUserTypes, userType.toLowerCase());
        const initialGroupPulse = shouldMakeInitialCall ? this._getGroupPulses(resolvedUrl, _settings.params) : of([]);

        return of({}).pipe(
            share(),
            delayWhen((_) => (skipDelay || refreshData ? interval(0) : interval(pollSettings.realtime.pollDelay))),
            mergeMap((_) =>
                iif(
                    () => _settings.pollingPaused,
                    of(groupPulses),
                    zip(initialGroupPulse, this._getRealTime(_settings.websocketHttpsUrl, _settings.params)).pipe(
                        map(([gps, rts]) => this._mergeGroupPulse(gps, rts)),
                        tap((gps) => (groupPulses = gps))
                    )
                )
            ),
            tap((_) => {
                skipDelay = false;
                refreshData = false;
            }),
            repeat()
        );
    }
}

const instance = new GroupPulseService();
Object.freeze(instance);

export { instance as GroupPulseService };
