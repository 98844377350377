import React, { Dispatch, SetStateAction, useState } from 'react';
import { NotificationType } from '../../common/enums';

const { createContext, useContext } = React;

export interface IDashboardProvider {
  dashboardNotification: NotificationType;
  setDashboardNotification: Dispatch<SetStateAction<NotificationType>>;
}

const DashboardContext = createContext<IDashboardProvider>(null);

type Props = {
  children: React.ReactNode;
};

export const DashboardProvider = (props: Props) => {
  const { children } = props;

  const [dashboardNotification, setDashboardNotification] =
    useState<NotificationType>();

  const value = {
    dashboardNotification,
    setDashboardNotification
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = (): IDashboardProvider => {
  return useContext(DashboardContext);
};

export default DashboardContext;
