import React, { createRef, useState } from 'react';
import { MenuItem, Box, Select } from '@mui/material';
import { ICategory } from '../../common/models';
import { SearchBar } from '../../common/components/SearchBar';
import AddCategoryButton from './AddCategoryButton';
import {
  NoResultsReturnedContainer,
  classificationClasses
} from '../styles/Classifications.styles';
import { SelectNoOutline } from '../../common/components/Select/SelectNoOutline';

type CategoryProps = {
  id?: string;
  categoryId?: string;
  categories: ICategory[];
  open?: boolean;
  title?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  className?: object;
  isVisible?: boolean;
  isDisabled?: boolean;
  onChange?: (event?: any) => void;
};

const CategoryDropdown = (props: CategoryProps) => {
  const {
    id,
    categoryId,
    categories,
    open,
    title,
    variant,
    className,
    isVisible,
    isDisabled,
    onChange
  } = props;

  const [filtered, setFiltered] = useState<ICategory[]>(categories);
  const textInputRef = createRef<any>();

  const dropdownTitle = title || 'Select Category';
  const selectClassName = className ? className : {};
  let menuItemClass = className
    ? className
    : classificationClasses.categoryMenuItem;
  menuItemClass = { ...menuItemClass, ...{ width: 'auto' } };
  const searchBarMenuItemClass = {
    ...menuItemClass,
    ...classificationClasses.categoryMenuItem
  };
  const [searchValue, setSearchFieldValue] = useState<any>('');

  const displayDropdown = isVisible ?? true;

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //Lowercase everything to match different cases
    const input = event.target.value.toLowerCase();
    const matches = categories.filter(
      (x) => x.name.toLowerCase().indexOf(input) > -1
    );
    setSearchFieldValue(event.target.value);

    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const resetDropdown = () => {
    setFiltered(categories);
    setSearchFieldValue('');
  };

  const SelectComponent = variant === 'standard' ? SelectNoOutline : Select;

  return displayDropdown ? (
    <SelectComponent
      id={id}
      defaultValue={categoryId || '0'}
      value={categoryId || '0'}
      onChange={(e) => onChange(e)}
      onClose={resetDropdown}
      onClick={(e) => e.stopPropagation()}
      open={open}
      inputProps={{
        name: 'localCategoryId',
        id: 'select-category'
      }}
      sx={{ ...classificationClasses.categorySelect, ...selectClassName }}
      variant={variant}
      disabled={isDisabled}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '400px',
            maxWidth: '250px'
          }
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
    >
      <MenuItem
        sx={searchBarMenuItemClass}
        aria-label="Search"
        value="0"
        //Prevent jumping to category that starts with the character on Keydown
        onKeyDown={(e) => e.stopPropagation()}
      >
        <SearchBar
          fieldRef={textInputRef}
          //Prevent closing of Select
          onClick={(e) => e.stopPropagation()}
          handleDropDownSearchChange={(e) => handleDropDownSearchChange(e)}
          searchValue={searchValue}
        />
      </MenuItem>
      {/* If there is a category already assigned to this,
            don't show this option to the user so they do not click it */}
      {!categoryId ? (
        <MenuItem
          aria-label={dropdownTitle}
          value="0"
          sx={menuItemClass}
          disabled
        >
          {dropdownTitle}
        </MenuItem>
      ) : null}
      {filtered?.length > 0 ? (
        filtered &&
        filtered.map((option) => (
          <MenuItem sx={menuItemClass} key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <Box>
          <NoResultsReturnedContainer onClick={(e) => e.stopPropagation()}>
            No Results Returned
          </NoResultsReturnedContainer>
          <AddCategoryButton
            title={'NEW CATEGORY'}
            resetDialog={resetDropdown}
          />
        </Box>
      )}
    </SelectComponent>
  ) : (
    <></>
  );
};

export default CategoryDropdown;
