import React from 'react';
import {
  WidgetLinkIcon,
  WidgetLinkStyled,
  WidgetLinkTypography
} from './WidgetLink.styles';
import { InfoOutlinedIcon } from '../../styles/app.component.styles';
import { WhiteBackgroundTooltip } from '../Tooltip/TextWithTooltip.styles';
import { WidgetLinkProps } from './WidgetLink.props';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';

const WidgetLink: React.FC<WidgetLinkProps> = ({
  redirectTo,
  redirectParams,
  text,
  infoIconText,
  textVariant,
  id
}) => {
  return (
    <WidgetLinkStyled
      to={redirectTo}
      params={redirectParams}
      id={id}
      underline="none"
    >
      {text && (
        <WidgetLinkTypography variant={textVariant || 'h4'}>
          {text}
        </WidgetLinkTypography>
      )}
      {infoIconText && (
        <WhiteBackgroundTooltip title={infoIconText} enterTouchDelay={0}>
          <IconButton sx={{ padding: '0px 0px 0px 3px !important' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </WhiteBackgroundTooltip>
      )}
      <WidgetLinkIcon>
        <LaunchIcon />
      </WidgetLinkIcon>
    </WidgetLinkStyled>
  );
};

export default WidgetLink;
