import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const WindowsIcon = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 26 25';
  const iconHeight = height ?? '25px';
  const iconWidth = width ?? '26px';
  const title = props.title ?? 'Windows Icon';
  const className = props.className;
  const style = props.style ?? {};
  const fillColor = props.fillColor ?? 'currentColor';

  let classList = 'at-windows-icon';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.70874 4.16741L10.9255 2.83576L10.93 12.2672L0.718251 12.3229L0.70874 4.16741ZM10.9206 13.354L10.9286 22.7939L0.716808 21.4502L0.716235 13.2909L10.9206 13.354ZM12.1591 2.6616L25.7056 0.76944V12.1474L12.1591 12.2503V2.6616ZM25.7087 13.4427L25.7056 24.7694L12.1591 22.9396L12.1401 13.4216L25.7087 13.4427Z"
        fill={fillColor}
      />
    </SvgIconWrapper>
  );
};
