import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { ChartCheckboxLegendHorizontal } from '../../common/components/Chart/components/ChartCheckboxLegendHorizontal';
import { Box } from '@mui/material';
import { green2, primaryColor, red2, yellow2 } from '../../common/constants';
import { CheckboxLabel } from '../../common/components/Chart/models/ChartCheckboxLegend.props';

export default function CheckboxLegendBar() {
  const [originalChartData, setOriginalChartData] = useState<ChartData>({
    labels: [],
    datasets: []
  });
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: []
  });
  const [currentSelections, setCurrentSelections] = useState<
    (string | number)[]
  >([]);

  useEffect(() => {
    const data = {
      labels: ['06/19', '06/26', '07/03', '07/10', '07/17'],
      datasets: [
        {
          label: 'Underutilized',
          data: [20, 0, 45, 13, 40],
          backgroundColor: [yellow2],
          borderWidth: 0
        },
        {
          label: 'Healthy',
          data: [70, 100, 20, 85, 10],
          backgroundColor: [green2],
          borderWidth: 0
        },
        {
          label: 'Overutilized',
          data: [10, 0, 35, 2, 50],
          backgroundColor: [red2],
          borderWidth: 0
        }
      ]
    };
    setChartData(data);
    setOriginalChartData(data);
  }, []);

  const legendLabels = useMemo(() => {
    return chartData?.datasets?.map((item, i) => {
      return { name: item.label, value: i };
    });
  }, [chartData?.datasets]);

  useEffect(() => {
    if (!currentSelections?.length && legendLabels?.length) {
      //set initial selected bar pieces
      const selected = legendLabels.map((item: CheckboxLabel) => item.value);
      setCurrentSelections(selected);
    }
  }, [currentSelections?.length, legendLabels]);

  const updateChart = useCallback(
    (newSelections) => {
      //start with the original set of data
      //need to deep clone, otherwise the original set of data will also be updated
      const newData = _.cloneDeep(originalChartData);

      //if the data index is not in array, set data to null
      newData.datasets.forEach((item, i) => {
        if (!newSelections.includes(i)) newData.datasets[i].data = null;
      });
      setChartData(newData);
    },
    [originalChartData]
  );

  const chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    datasets: {
      bar: {
        barPercentage: 0.9,
        categoryPercentage: 0.9
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        align: 'center',
        anchor: 'center',
        // color: primaryColor,
        color: function (context) {
          return context.dataset.label === 'Overutilized'
            ? 'white'
            : primaryColor;
        },
        font: {
          size: 10,
          weight: 'bold'
        },
        formatter: (value, context) => {
          return value && context.dataset.label !== 'Healthy'
            ? value + '%'
            : null;
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          minRotation: 45,
          padding: -2
        }
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false
        },
        ticks: {
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
          padding: 5
        }
      }
    },
    responsive: true
  };

  const colors = chartData?.datasets?.map((item) => item.backgroundColor[0]);

  return (
    <>
      <Box sx={{ width: 255, height: 300 }}>
        <Chart
          type="bar"
          data={chartData}
          options={chartOptions}
          plugins={[ChartDataLabels as any]}
        />
      </Box>
      {legendLabels?.length > 0 && colors?.length > 0 && (
        <Box>
          <ChartCheckboxLegendHorizontal
            labels={legendLabels}
            colors={colors}
            currentSelections={currentSelections}
            onClick={updateChart}
          />
        </Box>
      )}
    </>
  );
}
