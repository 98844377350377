import { newEvent } from '../../common/analytics/eventHeap';

export const getOs = () => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const os = isMac ? 'mac' : 'win';

  newEvent('Click - Platform Selected', { platform: os });

  return os;
};

export const downloadAgent = (url: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
