import * as React from 'react';
import IconButton from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import authorizationService from '../../common/helpers/authorization';
import { gray0, gray2, buttonTextColor } from '../../common/constants';
import { QUICK_LINKS } from '../constants';
import { StyledMenu } from '../styles/supportPortal.styles';
import { IQuickLinksButtonProps } from '../models/IComponentProps';
import { IQuickLink } from '../models/IConstants';
import {
  IGeneralMouseEvent,
  IListItemMouseEvent
} from '../models/IRandomTypes';

export const QuickLinksButton = (props: IQuickLinksButtonProps) => {
  const { handleViewClick } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const viewableQuickLinks: IQuickLink[] = QUICK_LINKS.filter((link) =>
    authorizationService.hasRouteByName(`app.${link.pathName}`)
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: IGeneralMouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: IListItemMouseEvent, path: string) => {
    handleViewClick(event, path);
    handleClose();
  };

  return (
    <>
      <IconButton
        variant="contained"
        disableElevation
        onClick={handleClick}
        sx={{
          color: buttonTextColor,
          backgroundColor: gray0,
          maxWidth: '30px',
          '&.MuiButtonBase-root:hover, &.MuiButtonBase-root:focus': {
            backgroundColor: gray2
          }
        }}
      >
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {viewableQuickLinks.map((link) => (
          <MenuItem
            onClick={(e) => handleMenuItemClick(e, link.route)}
            disableRipple
            key={link.route}
          >
            {link.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
