import { createStore } from 'zustand';
import { fetchExports } from '../utils/exportStore.utils';
import { EXPORT_STORE_DEFAULT } from '../constants/exportStoreDefault';
import { IExportStore } from '../models/IExport';

export const exportStore = createStore<IExportStore>(() => ({
  ...EXPORT_STORE_DEFAULT
}));

/**
 * Fetches the export from the API and sets it in the export store.
 */
export const fetchExportStatus = async (): Promise<IExportStore> => {
  try {
    const response = await fetchExports();

    const current = exportStore.getState();
    exportStore.setState({ ...current, exports: response });
  } catch (error) {
    console.error('ActivTrak Error: Error fetching export status', error);
  }

  return exportStore.getState();
};

/**
 * Returns the current export store state.
 */
export const getExportStore = (): IExportStore => exportStore.getState();

export const hasExportsInProgress = (): boolean =>
  exportStore.getState().exports.inProgress.length > 0;

export const hasExportsCompleted = (): boolean =>
  exportStore.getState().exports.completed.length > 0;

export const hasExportsError = (): boolean =>
  exportStore.getState().exports.inError.length > 0;

export const setExportStatusInterval = (interval: number): void => {
  const current = exportStore.getState();

  exportStore.setState({ ...current, exportStatusInterval: interval });
};

/**
 * Resets the export store to its default state.
 */
export const resetExportStore = (): void =>
  exportStore.setState(EXPORT_STORE_DEFAULT);
