import React from 'react';
import CustomerCard from '../CustomerCard/CustomerCard.jsx';
import { ccTableViewClasses } from './TableView.styles.js';
import { Box } from '@mui/material';

const TableView = ({
  customers = [],
  loading,
  onError,
  updateCompanyName,
  extLoaded
}) => {
  const renderCards = () => {
    return customers.map((customer) => {
      return (
        <CustomerCard
          key={customer.accountId}
          {...customer}
          loading={loading}
          onError={onError}
          updateCompanyName={updateCompanyName}
          extLoaded={extLoaded}
        />
      );
    });
  };

  return <Box sx={ccTableViewClasses.cardContainer}>{renderCards()}</Box>;
};

export default TableView;
