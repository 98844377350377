import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appDashboardCurrentAdmin: IRoute = {
  name: 'app.dashboardCurrent.admin',
  stateDefinition: {
    url: '/homepage/admin',
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      updated: false,
      errorMessages: null,
      fromNavigation: false
    },
    template: '<homepage-admin></homepage-admin>',
    data: { pageTitle: 'Home - Admin Panel' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.DashboardsAdmin,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced],
    bundleFlags: [BundleFlag.AdminPanelHomepage],
    excludedFeatureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appDashboardCurrentAdmin;
