import { useEffect, useMemo, useState } from 'react';

import { setEnvConfig } from '../../config';
import envService from '../../../../../_app/environmentService/environmentService.js';
import { IEnvConfig } from '../../models';
import { useMenuItemStore, find, useRouteStore, findItemById } from './navigationStore';

import {
  useFavoritesStore,
  addToFavorites,
  removeFromFavorites,
  subscribe as subscribeToStore,
  unsubscribe as unsubscribeFromStore
} from './favoritesStore';

export interface ISideBarState {
  favorites: any[];
  favoritesMenuOpen: boolean;
  isFavoriteNavigationEnabled: boolean;

  setFavorites: (favorites: any[]) => void;
  setFavoritesMenuOpen: (open: boolean) => void;

  isInFavorites: (item: any) => boolean;
  removeFromFavorites: (item: any) => void;
  addToFavorites: (item: any) => void;
  findItemById: (id: string) => any;
}

export const useNavigationState = () => {

  const items = useMenuItemStore(state => state.menuItems);
  const route = useRouteStore(s => s.route);

  const menu = useMemo(() => {
    //open menus
    const active = find(items, item => item.action?.options?.ref === route.name);
    if (active?.parentId) {
      //let parent = findItemById(active.parentId);
      let parent = find(items, item => item.id === active.parentId);
      while (parent) {
        parent.submenuOpen = true;
        if (parent.parentId) {
          parent = find(items, item => item.id === parent.parentId);
        } else {
          break;
        }
      }
    }

    return items;
  }, [items, route.name]);

  return {
    menu
  }

}

export const useFavoritesState = (): ISideBarState => {
  

  const [favoritesMenuOpen, setFavoritesMenuOpen] = useState(true);


  const {
    favorites,
    setFavorites,
    isInFavorites,
    isFavoriteNavigationEnabled
  } = useFavoritesStore(s => s);

  

  //Opens/Closes the favorites section based on the number of items
  useEffect(() => {
    subscribeToStore((event: string, args: any[]) => {
      if (
        (event === 'start:addToFavorites' && args.length === 0) ||
        (event === 'start:removeFromFavorites' && args.length === 1)
      ) {
        setFavoritesMenuOpen(true);
      }
    });

    return () => {
      unsubscribeFromStore();
    };
  });

  const envConfig: IEnvConfig = {
    environmentService: envService,
    baseUrl: '/#/app'
  };
  setEnvConfig(envConfig);

  return {
    favorites,
    setFavorites,
    isInFavorites,
    removeFromFavorites,
    addToFavorites,
    findItemById,
    favoritesMenuOpen,
    setFavoritesMenuOpen,
    isFavoriteNavigationEnabled
  };
};
