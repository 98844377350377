'use strict';
import { applicationRoles } from '_app/serviceFunctions/constantsServiceFunctions';
import authService from '../../_reactivtrak/src/common/helpers/authorization';

const constructor = (broadcastService) => {
    if (!broadcastService || typeof broadcastService !== 'function') {
        console.error(
            'ActivTrak Error: Cannot set broadcastService local variable due to invalid input.',
            broadcastService
        );
        return;
    }

    const roles = {
        user: applicationRoles.user.key,
        admin: applicationRoles.admin.key,
        viewer: applicationRoles.viewer.key,
        configurator: applicationRoles.configurator.key,
        supportBasic: applicationRoles.supportBasic.key,
        supportIntermediate: applicationRoles.supportIntermediate.key,
        supportAdvanced: applicationRoles.supportAdvanced.key,
        superAdmin: applicationRoles.superAdmin.key,
        multiAccount: applicationRoles.multiAccount.key,
        ccAccess: applicationRoles.ccAccess.key,
        supportPortal: applicationRoles.supportPortal.key
    };

    const redirectToAuthorizedHome = (appState, toStateName) => {
        return authService.redirectToAuthorizedHome(appState, toStateName);
    };

    //////
    // Service functions
    //////

    //Not creating a 'get' method since these values will be read in other apps
    //This app uses hasFeature()

    const clearAuthorizations = () => {
        return authService.clearAuthorizations();
    };

    const hasFeature = (featureName) => {
        return authService.hasFeature(featureName);
    };

    /// key can be string or numeric
    const getRole = (key) => {
        return authService.getRole(key);
    };

    const hasRole = (rolesToCheck) => {
        return authService.hasRole(rolesToCheck);
    };
    //added to match functions used in React
    const hasAnyRole = (roles) => {
        return authService.hasAnyRole(roles);
    };
    const hasRoleAccess = (roleAccess) => {
        return authService.hasRoleAccess(roleAccess);
    };

    const getUserType = () => {
        return authService.getUserType();
    };

    const getParentAccount = () => {
        return authService.getParentAccount();
    };

    const isMspChildAccount = () => {
        return authService.isMspChildAccount();
    };

    const getAuthorizationLevel = (routeName, featureName) => {
        return authService.getAuthorizationLevel(routeName, featureName);
    };

    const hasAuthorizationLevel = (levels, routeName, featureName) => {
        return authService.hasAuthorizationLevel(levels, routeName, featureName);
    };

    const hasRouteByName = (routeName) => {
        return authService.hasRouteByName(routeName);
    };

    const isRouteRestricted = (routeName) => authService.isRouteRestricted(routeName);

    const getAuthorizations = () => {
        return authService.getAuthorizations();
    };

    return {
        applicationRoles,
        roles,
        clearAuthorizations,
        hasFeature,
        getRole,
        hasRole,
        hasAnyRole,
        hasRoleAccess,
        getUserType,
        getParentAccount,
        isMspChildAccount,
        getAuthorizationLevel,
        hasAuthorizationLevel,
        hasRouteByName,
        getAuthorizations,
        redirectToAuthorizedHome,
        isRouteRestricted
    };
};

export { constructor };
