import React from 'react';
import { InfoButton } from '../../../common/components/Button';
import { showModal } from '../../../common/stores/globalModalStore';
import { LocationPromoModal } from '../../../_global/modals/components/LocationPromoModal';
import { LocationTooltipText } from '../components/TooltipText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const showLocationPromoModal = () => {
  const modalContent = React.createElement(LocationPromoModal);

  showModal(modalContent);
};

export const LocationHeader = (props: { hasLocationData: boolean }) => {
  const { hasLocationData } = props;

  const infoButton = hasLocationData && (
    <InfoButton tooltipText={<LocationTooltipText />} />
  );
  const modalButton = !hasLocationData && (
    <Button
      id="8b666e7b-e4b6-4c43-b258-7c70c4e84368"
      variant="contained"
      onClick={showLocationPromoModal}
      size="small"
    >
      Enable Now
    </Button>
  );
  return (
    <>
      <Typography>Location{infoButton}</Typography>
      {modalButton}
    </>
  );
};
