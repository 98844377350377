'use strict';

var environmentService = require('_app/environmentService/environmentService.js').default;

angular.module('config', []).service('envConfig', envConfig);

// @deprecated
// Legacy envConfig used by AngularJS files
// All other files should use environmentService.js
function envConfig() {
    this.configureApiUrl = function (url) {
        environmentService.setApiUrl(url);
    };

    this.configureWebSocketsUrl = function (url) {
        environmentService.setWebSocketsUrl(url);
    };

    this.configureWebSocketsHttpsUrl = function (url) {
        environmentService.setWebSocketsHttpsUrl(url);
    };

    this.configureReportingServiceUrl = function (url) {
        environmentService.setReportingServiceUrl(url);
    };

    this.configureIdentityServiceUrl = function (url) {
        environmentService.setIdentityServiceUrl(url);
    };

    this.configureWebApiUrl = function (url) {
        environmentService.setWebApiUrl(url);
    };

    this.adminApiUrl = function () {
        return environmentService.getAdminApiUrl();
    };

    this.apiUrl = function () {
        return environmentService.getApiUrl();
    };

    this.reportingServiceUrl = function () {
        return environmentService.getReportingServiceUrl();
    };

    this.identityServiceUrl = function () {
        return environmentService.getIdentityServiceUrl();
    };

    this.accountsApiUrl = function () {
        return environmentService.getAccountsApiUrl();
    };

    this.websocketUrl = function () {
        return environmentService.getWebsocketUrl();
    };

    this.websocketHttpsUrl = function () {
        return environmentService.getWebsocketHttpsUrl();
    };
}

// @deprecated
// Legacy envConfig used by AngularJS login process
// All other files should use environmentService.js
angular.module('config').service('loginServiceUrl', loginServiceUrl);
function loginServiceUrl() {
    this.apiUrl = function () {
        return environmentService.getDefaultUrl();
    };

    this.authorizationServiceUrl = function () {
        return environmentService.getAuthorizationServiceUrl();
    };

    this.ssoAuthorizationUrl = function () {
        return environmentService.getSsoAuthorizationUrl();
    };
}

// @deprecated
// Legacy envConfig used by AngularJS files
// All other files should use environmentService.js
angular.module('config').service('envService', envService);
function envService() {
    this.isProduction = function () {
        return environmentService.getEnvironmentName() === 'prod';
    };

    this.get = function () {
        return environmentService.getEnvironmentName();
    };

    this.getMarketoConfig = function () {
        return environmentService.getMarketoConfig();
    };

    this.ssoAuthorizationUrl = function () {
        return environmentService.getSsoAuthorizationUrl();
    };

    this.lookerUrl = function () {
        return environmentService.getLookerUrl();
    };

    this.signUpAppUrl = function () {
        return environmentService.getSignUpAppUrl();
    };
}
