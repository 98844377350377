import { getPrivacySettings } from '../../../../_reactivtrak/src/common/hooks/privacySettingsStore';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { alarmObjectVerifierFunctions } from '../../../../_app/serviceFunctions/alarmObjectVerifierFunctions';
import createNewAlarmModalControllerTemplate from 'views/modals/createNewAlarmModal.html?raw';
import messageModalControllerTemplate from 'views/modals/messageModal.html?raw';
import lodash from 'lodash';

angular.module('app').controller('AlarmsCtrl', AlarmsCtrl);

AlarmsCtrl.$inject = [
    '$scope',
    '$location',
    '$state',
    'customUibModal',
    '$timeout',
    'alarmService',
    'gridPropertiesService',
    'messagesService',
    'notificationService',
    'authorizationService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions'
];

function AlarmsCtrl(
    $scope,
    $location,
    $state,
    customUibModal,
    $timeout,
    alarmService,
    gridPropertiesService,
    msg,
    notificationService,
    authorizationService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions
) {
    var { screenshotsAllowed, alarmActionsAllowed } = getPrivacySettings();

    $scope.alarmsGridHeight = function () {
        return 255;
    };

    $scope.isSmallWindow = window.innerWidth < 992;

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.grid-tooltip',
        position: 'top'
    });

    $scope.createNewAlarm = function () {
        customUibModal.open({
            animation: false,
            template: createNewAlarmModalControllerTemplate,
            controller: 'createNewAlarmModalController'
        });
    };

    $scope.htmlEscape = function (string) {
        return browserServiceFunctions.htmlEscape(string);
    };

    $scope.hasFeature = function () {
        return authorizationService.getAuthorizationLevel('app.alarms.settings') === 'edit';
    };

    $scope.filter = {};

    $scope.viewLog = function (alarm) {
        $state.go('app.reports.alarmlog', {
            alarm: alarm.name
        });
    };

    var alarmsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                alarmService.getAlarms().success(function (alarmsResult) {
                    alarmsResult = alarmsResult.map(function (item) {
                        item.name = item.name === null ? '' : item.name;
                        item.emailtostr = item.emailto === null ? '' : item.emailto.join(',');

                        item.editable = true;
                        // Disable advanced alarm types if they are active and plan is not advanced
                        if (item.type === 'Audit' || item.type === 'Usb') {
                            var isPaidFunctionalityEnabled =
                                authorizationService.hasFeature('isPaidFunctionalityEnabled');
                            item.active = item.active && isPaidFunctionalityEnabled;
                            item.editable = isPaidFunctionalityEnabled;
                        }

                        item.screenshotStr =
                            (item.screenshot ? (item.screenshotmultiple ? 'Multi' : 'Screenshot') : '') +
                            (item.video ? 'Video' : '') +
                            (!item.screenshot && !item.screenshotmultiple && !item.video ? 'None' : '');
                        item.name = browserServiceFunctions.htmlDecode(item.name);
                        item.conditions.forEach(function (condition) {
                            condition.value = browserServiceFunctions.htmlDecode(condition.value);
                        });
                        item.popuptext = browserServiceFunctions.htmlDecode(item.popuptext);
                        item.emailbody = browserServiceFunctions.htmlDecode(item.emailbody);
                        item.emailsubject = browserServiceFunctions.htmlDecode(item.emailsubject);
                        return item;
                    });
                    options.success(alarmsResult);
                });
            }
        },
        pageSize: 2000
    });

    $scope.alarmsDataSource = alarmsDataSource;

    $scope.navigate = function (path) {
        $location.path(path);
        $scope.$apply();
    };

    $scope.emailBounced = function (dataItem) {
        return dataItem.emailbounced;
    };

    $scope.alarmActivity = {};
    $scope.alarmActivityRequests = {};

    $scope.alarmBlocking = function () {
        var result = false;

        for (var prop in $scope.alarmActivityRequests) {
            if ($scope.alarmActivityRequests) {
                result = $scope.alarmActivityRequests[prop] || result;
            }
        }
        return result;
    };

    function formatSafeSearchConditions(text) {
        var strings = text.split(' AND ');
        var likelihoodMap = {
            3: 'possibly',
            4: 'likely',
            5: 'very likely'
        };
        for (var idx in strings) {
            var string = strings[idx];
            var index = string.indexOf('SafeSearch');
            if (index !== -1) {
                var firstSpaceIndex = string.indexOf(' ');
                var lastSpaceIndex = string.lastIndexOf(' ');
                var type = string.slice(index + 10, firstSpaceIndex);
                var likelihood = parseInt(string.slice(lastSpaceIndex, string.length));
                strings[idx] = '(Screenshot ' + likelihoodMap[likelihood] + ' contains "' + type + '" material)';
            }
        }
        return strings.join(' AND ');
    }

    var disabledClass = $scope.hasFeature() ? '' : ' disabled';
    var gridColumns = [
        {
            field: 'active',
            title: msg.get('status'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            width: '90px',
            attributes: {
                class: 'text-center'
            },
            template: kendo.template(function () {
                return (
                    '<label class="switch' +
                    disabledClass +
                    '">' +
                    '<input type="checkbox" ng-disabled="alarmBlocking()" ng-click="saveAlarm($event, dataItem)" ng-model="dataItem.active" />' +
                    '<i></i>' +
                    '</label>'
                );
            })
        },
        {
            field: 'type',
            title: msg.get('type'),
            filterable: {
                cell: {
                    operator: 'eq',
                    template: function (args) {
                        var filters = [
                            {
                                type: null,
                                name: 'All'
                            },
                            {
                                type: 'Activity',
                                name: 'Activity'
                            },
                            {
                                type: 'Audit',
                                name: 'Security Audit'
                            }
                        ];

                        args.element.attr('class', 'at-alarm-type-dropdown').kendoDropDownList({
                            dataSource: filters,
                            dataTextField: 'name',
                            dataValueField: 'type',
                            valuePrimitive: true,
                            valueTemplate: templateServiceFunctions.alarmTypeFilterTemplate
                        });
                    },
                    showOperators: false
                }
            },
            sortable: {
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '70px',
            template:
                "#if (type === 'Usb') {#" +
                '<i class="fa fa-usb" at-tooltip="' +
                msg.get('usbAlarm').replace(' ', '&nbsp;') +
                '" tooltip-placement="bottom"></i>' +
                "#} else if (type === 'Activity') {#" +
                '<i class="fa fa-bell" at-tooltip="' +
                msg.get('activityAlarm').replace(' ', '&nbsp;') +
                '" tooltip-placement="bottom"></i>' +
                "#} else if (type === 'SafeSearch') {#" +
                '<i class="fa fa-lg icon-at-safe-search" at-tooltip="' +
                msg.get('safeSearchAlarm').replace(' ', '&nbsp;') +
                '" tooltip-placement="bottom"></i>' +
                '#} else {#' +
                '<i class="fa fa-eye" at-tooltip="' +
                msg.get('auditAlarm').replace(' ', '&nbsp;') +
                '" tooltip-placement="bottom"></i>' +
                '#}#'
        },
        {
            field: 'name',
            filterable: templateServiceFunctions.createFilter('name', alarmsDataSource),
            title: msg.get('name'),
            sortable: {
                initialDirection: 'asc'
            },
            template: kendo.template(function () {
                return '<a class="clickable" ng-click="viewLog(dataItem)">{{dataItem.name}}</a>';
            }),
            attributes: {
                class: 'grid-tooltip text-nowrap'
            },
            width: '155px'
        },
        {
            field: 'conditionstext',
            filterable: templateServiceFunctions.createFilter('conditionstext', alarmsDataSource),
            title: msg.get('conditions'),
            template: kendo.template(function (item) {
                var text =
                    item.type === 'SafeSearch' ? formatSafeSearchConditions(item.conditionstext) : item.conditionstext;
                return (
                    '<span>' +
                    text
                        .replace(
                            '(IP Address',
                            item.type === 'Audit' ? '(' + msg.get('IpAddressPublic') : '(' + msg.get('IpAddressPrivate')
                        )
                        .replace('(User', item.type === 'Audit' ? '(' + msg.get('atUser') : '(' + msg.get('User')) +
                    '</span>'
                );
            }),
            attributes: {
                class: 'grid-tooltip text-nowrap'
            },
            activTrakMinWidth: 160
        },
        {
            field: 'groupNames',
            filterable: templateServiceFunctions.createFilter('groupNames', alarmsDataSource),
            title: msg.get('groups'),
            attributes: {
                class: 'grid-tooltip text-nowrap'
            },
            activTrakMinWidth: 160
        },
        {
            field: 'weight',
            title: msg.get('alarmWeightRisk') + ' ' + msg.get('alarmWeightLevel'),
            sortable: {
                compare: function (a, b) {
                    if (a.type === 'Audit') {
                        return -1;
                    }
                    if (b.type === 'Audit') {
                        return 1;
                    }

                    return a.weight - b.weight;
                },
                initialDirection: 'desc'
            },
            filterable: false,
            template:
                "#if (type !== 'Audit') {#" +
                '<div>{{dataItem.weight}}</div>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#',
            width: '60px',
            attributes: {
                class: 'text-center'
            },
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'screenshotStr',
            filterable: {
                cell: {
                    operator: 'contains',
                    template: function (args) {
                        var filters = [
                            {
                                type: null,
                                name: 'All'
                            },
                            {
                                type: 'None',
                                name: 'None'
                            },
                            {
                                type: 'Screenshot',
                                name: 'Screenshot'
                            },
                            {
                                type: 'Multi',
                                name: 'Multiple screenshots'
                            }
                        ];

                        if (authorizationService.hasFeature(FeatureFlag.VideoPlayback)) {
                            filters.push({
                                type: 'Video',
                                name: 'Video'
                            });
                        }

                        args.element.attr('class', 'at-alarm-screenshot-dropdown').kendoDropDownList({
                            dataSource: filters,
                            dataTextField: 'name',
                            dataValueField: 'type',
                            valuePrimitive: true,
                            valueTemplate: templateServiceFunctions.alarmScreenshotsFilterTemplate
                        });
                    },
                    showOperators: false
                }
            },
            title: msg.get('screenCaptures'),
            sortable: {
                compare: function (a, b) {
                    if (a.video === b.video && !a.video) {
                        if (a.screenshot === b.screenshot && a.screenshot) {
                            return a.screenshotmultiple - b.screenshotmultiple;
                        }
                        return a.screenshot - b.screenshot;
                    }
                    return a.video - b.video;
                },
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '100px',
            template:
                '#if (screenshot === true ) {#' +
                '#if (screenshotmultiple === true) {#' +
                '<i class="fa fa-lg icon-at-multiple-screenshots-camera" at-tooltip="Every {{dataItem.screenshotsec}} seconds" tooltip-placement="bottom"></i>' +
                '#} else {#' +
                '<i class="fa fa-lg icon-at-screenshot"></i>' +
                '#}#' +
                '#} else if (video === true) {#' +
                '<i class="pg-video"></i>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#',
            removeColumn: !screenshotsAllowed
        },
        {
            field: 'email',
            filterable: false,
            title: msg.get('email') + ' ' + msg.get('notifications'),
            sortable: {
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '100px',
            template:
                '#if (email === true ) {#' +
                '<i class="fa fa-envelope-o" at-tooltip="To: &quot;{{dataItem.emailto ? dataItem.emailto.join(\', \') : \'-\'}}&quot;<br/>Subject: &quot;{{htmlEscape(dataItem.emailsubject)}}&quot;<br/>Body: &quot;{{htmlEscape(dataItem.emailbody)}}&quot;" tooltip-placement="bottom"></i>' +
                '<span ng-show="emailBounced(dataItem)" class="text-danger"> bounced<span>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#'
        },
        {
            field: 'popup',
            filterable: false,
            title: msg.get('display') + ' ' + msg.get('popup'),
            sortable: {
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '70px',
            template:
                '#if (popup === true) {#' +
                '<i class="fa fa-comment-o" at-tooltip="Popup message: &quot;{{dataItem.popuptext}}&quot;" tooltip-placement="bottom"></i>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'terminate',
            filterable: false,
            title: msg.get('terminate') + ' ' + msg.get('application'),
            sortable: {
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '90px',
            template:
                '#if (terminate === true ) {#' +
                '<i class="fa fa-ban"></i>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'webhook',
            filterable: false,
            title: msg.get('webhook') + ' ' + msg.get('notifications'),
            sortable: {
                initialDirection: 'desc'
            },
            attributes: {
                class: 'text-center'
            },
            width: '100px',
            template:
                '#if (webhook === true ) {#' +
                '<i class="fa fa-link" at-tooltip="URL: &quot;{{htmlEscape(dataItem.webhookurl)}}&quot;" tooltip-placement="bottom"></i>' +
                '#} else {#' +
                '<i class="fa fa-ellipsis-h"></i>' +
                '#}#'
        }
    ];

    var mobileGridColumn = [
        {
            sortable: false,
            filterable: false,
            width: '100%',
            attributes: {
                'ng-class': '{active: dataItem.active}'
            },
            template: kendo.template(function (dataItem) {
                return (
                    '<div class="k-listView title-v-align-top" style="padding: 0;">' +
                    '<div class="mobile-grid-header">' +
                    "<i class=\"m-r-10 fa fa-lg\" ng-class=\"{'fa-usb': dataItem.type === 'Usb', 'fa-bell': dataItem.type === 'Activity', 'icon-at-safe-search': dataItem.type === 'SafeSearch', 'fa-eye': dataItem.type === 'Audit'}\"></i>" +
                    '<div class="mobile-title">{{dataItem.name}}</div>' +
                    '</div>' +
                    '<div class="at-grid-gray">' +
                    '<table>' +
                    '<thead><tr><th style="width: 100px;"></th><th></th></tr></thead>' +
                    '<tbody>' +
                    '<tr><td>Active</td><td><label class="switch m-b-0-i m-t-m5-i" ng-class="{\'disabled\': !hasFeature()}">' +
                    '<input type="checkbox" ng-disabled="alarmBlocking()" ng-click="saveAlarm($event, dataItem)" ng-model="dataItem.active" /><i></i>' +
                    '</label></td></tr>' +
                    '<tr><td>Actions:</td><td>' +
                    "<i class=\"m-r-5 fa fa-lg\" ng-class=\"{hide: !(dataItem.screenshot || dataItem.video), 'icon-at-multiple-screenshots-camera': dataItem.screenshot && dataItem.screenshotmultiple, 'icon-at-screenshot': dataItem.screenshot && !dataItem.screenshotmultiple, 'pg-video': !dataItem.screenshot && dataItem.video}\"></i>" +
                    '<i class="m-r-5 fa fa-comment-o" ng-class="{hide: !dataItem.popup}"></i>' +
                    '<i class="m-r-5 fa fa-envelope-o" ng-class="{hide: !dataItem.email, \'text-danger\': emailBounced(dataItem)}"></i><span ng-class="{hide: !(dataItem.email && emailBounced(dataItem))}" class="text-danger m-r-5">(bounced)</span>' +
                    '<i class="m-r-5 fa fa-link" ng-class="{hide: !dataItem.webhook}"></i>' +
                    '<i class="m-r-5 fa fa-ban" ng-class="{hide: !dataItem.terminate}"></i>' +
                    '</td></tr>' +
                    '<tr><td>Risk Level:</td><td>{{dataItem.weight}}</td></tr>' +
                    '<tr><td>Conditions:</td></tr>' +
                    '<tr><td colspan="2" rowspan="2" class="conditions">{{dataItem.conditionstext}}</td></tr>' +
                    '</tbody>' +
                    '</table>' +
                    '</div>' +
                    '<div class="alarm-mobile-buttons" ng-show="hasFeature() && dataItem.editable">' +
                    ($scope.hasFeature() && !dataItem.default
                        ? '<a class="btn btn-complete btn-xs m-r-5" ng-click="editAlarm($event, dataItem)"><i class="fa fa-edit"></i></a>'
                        : '') +
                    ($scope.hasFeature() && !dataItem.default
                        ? '<a class="btn btn-success btn-xs m-r-5" ng-click="duplicateAlarm($event, dataItem)"><i class="fa fa-clone"></i></a>'
                        : '') +
                    ($scope.hasFeature() && !dataItem.default
                        ? '<a class="btn btn-danger btn-xs" ng-click="deleteAlarm($event, dataItem)"><i class="fa fa-trash"></i></a>'
                        : '') +
                    '</div>' +
                    '</div>'
                );
            })
        }
    ];

    $scope.editAlarm = function (event, dataItem) {
        event.preventDefault();

        $state.go('app.alarms.details', {
            id: dataItem.alarmid,
            returnState: 'app.alarms.settings',
            returnFilter: $scope.filter
        });
    };

    $scope.duplicateAlarm = function (event, dataItem) {
        event.preventDefault();

        $state.go('app.alarms.details', {
            id: dataItem.alarmid,
            duplicate: incrementDuplicateName(dataItem.name),
            returnState: 'app.alarms.settings',
            returnFilter: $scope.filter
        });
    };

    $scope.deleteAlarm = function (event, dataItem) {
        event.preventDefault();

        var modal = customUibModal.open({
            animation: false,
            template: messageModalControllerTemplate,
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageBody: msg.get('deleteAlarm', null, dataItem.name),
                    confirmLabel: msg.get('delete')
                }
            }
        });

        modal.result.then(function () {
            alarmService.deleteAlarm(dataItem.alarmid).success(function () {
                alarmsDataSource.read();
                $scope.$emit('showNotification', {
                    message: msg.get('alarmDeleted'),
                    color: 'success'
                });
            });
        });
    };

    function incrementDuplicateName(name) {
        if (atHelperFunctions.isEmpty(name)) {
            return;
        }

        name = name.trim();
        var closeIndex = name.lastIndexOf(')');
        if (closeIndex !== -1) {
            var openIndex = name.lastIndexOf('(');
            var count = name.substring(openIndex + 1, closeIndex);
            count = parseInt(count);
            if (isNaN(count)) {
                count = 0;
            }
            count++;
            name = name.substring(0, openIndex + 1);
            name += count + ')';
        } else {
            name += ' (1)';
        }

        if (isNameUnique(name)) {
            return name;
        } else {
            return incrementDuplicateName(name);
        }
    }

    function isNameUnique(name) {
        return !lodash.find(alarmsDataSource.data(), function (a) {
            return a.name === name;
        });
    }

    function buttonTemplate(color, icon, click, text, rightMargin) {
        return (
            '<a role="button" class="' +
            (rightMargin ? 'm-r-10 ' : '') +
            'btn ' +
            color +
            ' btn-animated from-top fa ' +
            icon +
            '" href="\\#" ng-click="' +
            click +
            '($event, dataItem)"><span>' +
            text +
            '</span></a>'
        );
    }

    if ($scope.hasFeature()) {
        gridColumns.push({
            command: [
                {
                    template: buttonTemplate('btn-complete', 'fa-edit', 'editAlarm', msg.get('edit'), true),
                    visible: function (dataItem) {
                        return !dataItem.default && dataItem.editable;
                    }
                },
                {
                    template: buttonTemplate('btn-success', 'fa-clone', 'duplicateAlarm', msg.get('duplicate'), true),
                    visible: function (dataItem) {
                        return !dataItem.default && dataItem.editable;
                    }
                },
                {
                    template: buttonTemplate('btn-danger', 'fa-trash', 'deleteAlarm', msg.get('delete')),
                    visible: function (dataItem) {
                        return !dataItem.default && dataItem.editable;
                    }
                }
            ],
            width: '300px'
        });
    }

    var finalGridColumns = atHelperFunctions.filterDefaultColumns(gridColumns);

    $scope.alarmsGridOptions = {
        dataSource: alarmsDataSource,
        sortable: true,
        filterable: {
            mode: 'row'
        },
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.alarmsGridHeight()),
        columns: finalGridColumns,
        dataBinding: function () {
            $scope.alarmActivity = {};
            $scope.alarmActivityRequests = {};
            setFilter(alarmsDataSource.filter());

            angular.forEach(this.dataSource.view(), function (item) {
                $scope.alarmActivityRequests[item.uid] = !$scope.hasFeature();
            });
        },
        dataBound: function (s) {
            var that = s.sender ? s.sender : s;

            angular.forEach(this.dataSource.view(), function (item) {
                that.tbody
                    .find('tr[data-uid=' + item.uid + ']')
                    .removeClass('k-alt')
                    .addClass(item.active ? 'bg-white' : 'bg-master-light');

                if (item.default) {
                    that.tbody.find('tr[data-uid=' + item.uid + '] td a.k-grid-delete').addClass('hidden');
                }
            });

            that.tbody.find('.btn').removeClass('k-button k-button-icontext').wrapInner('<span></span>');

            gridPropertiesService.applyMinColWidths(finalGridColumns);
        },
        filter: function (e) {
            if (e.filter !== null) {
                $scope.filter[e.field] = e.filter.filters[0].value;
            }
        }
    };

    $scope.mobileAlarmsGridOptions = {
        dataSource: alarmsDataSource,
        sortable: true,
        filterable: {
            mode: 'row'
        },
        selectable: null,
        columns: mobileGridColumn,
        dataBinding: function () {
            $scope.alarmsGridOptions.dataBinding();
        },
        dataBound: function (e) {
            var grid = e.sender;
            var data = grid._data;
            var height = 0;

            if ($scope.isSmallWindow) {
                $timeout(function () {
                    data.forEach(function (row) {
                        var tr = grid.element.find('tr[data-uid="' + row.uid + '"]');
                        height += tr.height();
                    });
                    grid.element.find('.k-scrollbar.k-scrollbar-vertical>div').height(height);
                });
            }

            $scope.alarmsGridOptions.dataBound(this);
            angular.element(window).resize();
        }
    };

    var alarmTypes = [
        {
            buttonLabel: msg.get('activity'),
            value: 'Activity'
        },
        {
            buttonLabel: msg.get('audit'),
            value: 'Audit'
        },
        {
            buttonLabel: msg.get('Usb'),
            value: 'Usb'
        }
    ];

    var screenCaptureFilterOptions = [
        {
            buttonLabel: msg.get('none'),
            value: 'None'
        },
        {
            buttonLabel: msg.get('screenshot'),
            value: 'Screenshot'
        },
        {
            buttonLabel: msg.get('multipleScreenshots'),
            value: 'Multi'
        }
    ];

    if (authorizationService.hasFeature(FeatureFlag.VideoPlayback)) {
        screenCaptureFilterOptions.push({
            buttonLabel: msg.get('video'),
            value: 'Video'
        });
    }

    var advancedFilterAlarmFields = [
        {
            label: msg.get('status'),
            name: 'active',
            type: 'buttons',
            filterOptions: [
                {
                    buttonLabel: msg.get('active'),
                    value: true
                },
                {
                    buttonLabel: msg.get('inactive'),
                    value: false
                }
            ]
        },
        {
            label: msg.get('alarmType'),
            name: 'type',
            type: 'buttons',
            filterOptions: alarmTypes
        },
        {
            label: msg.get('name'),
            name: 'name'
        },
        {
            label: msg.get('conditions'),
            name: 'conditionstext'
        },
        {
            label: msg.get('screenCaptures'),
            name: 'screenshotStr',
            type: 'buttons',
            filterOptions: screenCaptureFilterOptions,
            removeColumn: !screenshotsAllowed
        },
        {
            label: msg.get('email'),
            tooltip: 'Filters by email to, subject, and body fields',
            name: 'emailtostr,emailsubject,emailbody'
        },
        {
            label: msg.get('popup'),
            name: 'popuptext',
            removeColumn: !alarmActionsAllowed
        },
        {
            label: msg.get('webhook') + ' ' + msg.get('notifications'),
            tooltip: 'Filters by external notification url',
            name: 'webhookurl'
        }
    ];

    $scope.filterData = {
        panelTitle: msg.get('advancedFilterAlarms'),
        fields: atHelperFunctions.filterDefaultColumns(advancedFilterAlarmFields),
        appliedFilter: {}
    };

    var processFiled = function (field, filter, processedFilters) {
        if (typeof filter[field] === 'string') {
            if (filter[field]) {
                if (field.indexOf(',') > 0) {
                    var orFilter = {
                        logic: 'or',
                        filters: []
                    };

                    field.split(',').forEach(function (fieldPart) {
                        orFilter.filters.push({
                            field: fieldPart,
                            operator: 'contains',
                            value: filter[field]
                        });
                    });

                    processedFilters.filters.push(orFilter);
                } else {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'contains',
                        value: filter[field]
                    });
                }
            } else if (filter[field] === '') {
                processedFilters.filters.push({
                    field: field,
                    operator: 'eq',
                    value: filter[field]
                });
            }
        }

        if (typeof filter[field] === 'boolean') {
            processedFilters.filters.push({
                field: field,
                operator: 'eq',
                value: filter[field]
            });
        }
    };

    // Bind Advanced filters to column filters.
    function setFilter(sourceFilter) {
        if (sourceFilter && sourceFilter.filters) {
            sourceFilter.filters.forEach(function (filter) {
                $scope.filter[filter.field] = filter.value;
            });
        } else {
            $scope.filter = {};
        }
    }

    $scope.applyFilter = function (filter) {
        if (filter) {
            var processedFilters = {
                logic: 'and',
                filters: []
            };

            for (var field in filter) {
                processFiled(field, filter, processedFilters);
            }

            $scope.filterData.appliedFilter = {};
            angular.copy(filter, $scope.filterData.appliedFilter);
            $scope.alarmsDataSource.filter(processedFilters);
        }
    };

    $scope.saveAlarm = function (e, alarm) {
        var activeState = e.target.checked;

        if (activeState) {
            // Do not allow turning on audit or usb alarms for non-advanced accounts
            if (
                (alarm.type === 'Audit' || alarm.type === 'Usb') &&
                !authorizationService.hasFeature('isPaidFunctionalityEnabled')
            ) {
                var message =
                    (alarm.type === 'Audit' ? 'Audit' : 'USB') + ' alarms are only available with paid plans only.';
                var upgradeMessage = authorizationService.hasRouteByName('app.account.upgrade')
                    ? '<a href="#/app/account/upgrade"><strong> Click here to upgrade.</strong></a>'
                    : '';
                notificationService.showNotification(message + upgradeMessage, 'info', 0, true);
                alarm.active = false;
                e.target.checked = false;
                return;
            }

            // Do not allow turning on ill-defined alarm
            alarm.editor = {};

            // Determine Usb alarm settings
            if (alarm.type === 'Usb') {
                alarm.editor.usbAlarm = {
                    inserted: true,
                    written: true
                };

                angular.forEach(alarm.conditions, function (condition) {
                    if (condition.fieldName === 'Description') {
                        if (condition.value === 'insert') {
                            alarm.editor.usbAlarm.written = false;
                        } else if (condition.value === 'write' || condition.value === 'Writing') {
                            // TODO remove support for 'write' when all databases are updated
                            alarm.editor.usbAlarm.inserted = false;
                        }
                    }
                });
            }

            // Allow blank names
            alarm.editor.allowBlankName = true;

            // Determine if alarm is valid
            var invalidReasons = alarmObjectVerifierFunctions.verifyAlarm(alarm);

            // Show message if alarm is invalid
            if (!atHelperFunctions.isEmpty(invalidReasons)) {
                var reasonMessage =
                    'Unable to activate this alarm because it is not fully defined due to the following reasons:';
                invalidReasons.forEach(function (reason, index) {
                    reasonMessage += (index > 0 ? ', ' : ' ') + '"' + reason + '"';
                });
                reasonMessage +=
                    '. <a href="#/app/alarms/' +
                    alarm.alarmid +
                    '"><strong>  Click here to edit the alarm.</strong></a>';
                notificationService.showNotification(reasonMessage, 'danger', 0, true);
                alarm.active = false;
                e.target.checked = false;
                return;
            }
        }

        // Ensure object's active state matches element's checked state.
        // Needed due to Angular's scope not being updated before function is called.
        alarm.active = activeState;

        // Do not allow alarm state to change while alarm is saving.
        $scope.alarmActivityRequests[alarm.uid] = true;

        // Save alarm
        alarmService
            .saveAlarm(alarm)
            .success(function (result) {
                if (activeState) {
                    $scope.$emit('showNotification', {
                        message: msg.get('alarmActivated'),
                        color: 'success'
                    });
                } else {
                    $scope.$emit('showNotification', {
                        message: msg.get('alarmDeactivated'),
                        color: 'success'
                    });
                }
                alarm.alarmid = result.id;
                $scope.alarmActivityRequests[alarm.uid] = false;
            })
            .error(function (result) {
                if (result.errors) {
                    result.errors.map(function (error) {
                        $scope.$emit('showNotification', {
                            message: error,
                            color: 'danger'
                        });
                    });
                } else {
                    var error = result.error ? result.error : msg.get('itemUpdatingError');
                    $scope.$emit('showNotification', {
                        message: error,
                        color: 'danger'
                    });
                }

                // Revert alarm active state due to error.
                e.target.checked = !activeState;
                alarm.active = !activeState;
                $scope.alarmActivityRequests[alarm.uid] = !$scope.hasFeature();
            });
    };

    $scope.$on('atWindowResized', function () {
        var isSmallWindowPrev = $scope.isSmallWindow;

        $scope.isSmallWindow = window.innerWidth < 992;

        if (isSmallWindowPrev !== $scope.isSmallWindow) {
            alarmsDataSource.read();
        }
    });

    if ($state.params.filter) {
        $scope.applyFilter($state.params.filter);
    }

    if ($state.params.wasSaved) {
        $timeout(function () {
            notificationService.showNotification(msg.get('changesSaved'), 'success');
        }, 500);
    }

    if ($state.params.newalarm) {
        $scope.createNewAlarm();
    }
}
