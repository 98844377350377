import { Dispatch, SetStateAction } from 'react';
import { NotificationType, SortDirection } from '../../common/enums';

export enum GTEUserAction {
  Create = 1,
  Delete = 2
}
export interface GTEUserPayload {
  logondomain: string;
  username: string;
}

export interface GTEUserDTO {
  logondomain: string;
  username: string;
  relatedid: string;
  hidden: boolean;
  source: number;
  timeadded: string;
}

export interface IGTEUsersState {
  gteUsers: GTEUserDTO[];
  init: () => Promise<GTEUserDTO[]>;
  isLoading: boolean;
  gteUserNotification?: NotificationType;
  setGTEUserNotification: (notification: NotificationType) => void;
  addGTEUser: (payload: GTEUserPayload) => Promise<void>;
  deleteGTEUser: (payload: GTEUserPayload) => Promise<void>;
  selectedUsername: string;
  setSelectedUsername: Dispatch<SetStateAction<string>>;
  apiRequestError: boolean;
  isAcknowledged: boolean;
  setIsAcknowledged: Dispatch<SetStateAction<boolean>>;
  resetFields: () => void;
  sortDirection: SortDirection;
  setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  sortGTEUsers: (users: GTEUserDTO[], sortDirection: SortDirection) => void;
}

export interface IGTEUsersDetails {
  create: {
    title: string;
    description: string;
    descriptionSecondary?: string;
    acknowledgment?: string;
    cancel?: string;
    button: string;
  };
  delete: {
    title: string;
    description: string;
    descriptionSecondary?: string;
    acknowledgment?: string;
    cancel?: string;
    button: string;
  };
  card: {
    title: string;
    description: string;
    descriptionSecondary?: string;
    infoIconText?: string;
  };
}

export interface IGTEUsersColumnHeaderProps {
  handleOpenSetUsername: (
    event: React.MouseEvent<HTMLButtonElement>,
    username: string,
    action: GTEUserAction
  ) => void;
  isSupport: boolean;
}
