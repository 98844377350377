import React from 'react';
import { useNotifications } from '../../common/services/Notifications';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { copyToClipboard } from '../../common/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface ICopyToClipboardButtonProps {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}

export const CopyToClipboardButton: React.FC<ICopyToClipboardButtonProps> = (
  props
) => {
  const notificationService = useNotifications();

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton
        onClick={() =>
          copyToClipboard(
            props.text,
            notificationService,
            props.successMessage,
            props.errorMessage
          )
        }
        color="secondary"
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
