import { getPageTitle_angular, setPageTitle_angular } from '../../_reactivtrak/src/common/components/Navigation/navigationStore';

export const connect = (angular) => {

  angular.module('app').directive('headerWrapper', [
    function () {
      return {
        restrict: 'E',
        template:
          '<headernew logout="logout" open-support-modal="openSupportModal" hide-navigation="hideNavigation"></headernew>',
        controller: headerWrapperCtrl
      };
    }
  ]);

  headerWrapperCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$transitions',
    'loginService',
    'atHelperFunctions',
    'customUibModal'
  ];

  function headerWrapperCtrl(
    $rootScope,
    $scope,
    $state,
    $window,
    $transitions,
    loginService,
    atHelperFunctions,
    customUibModal
  ) {
    $scope.logout = function () {
      loginService.logout();
    };

    $scope.openSupportModal = function () {
      customUibModal.open({
        animation: false,
        template: require('views/modals/supportModal.html'),
        controller: 'SupportModalCtrl',
        windowClass: 'centered-modal off-wht-bg-modal',
        size: 'lg'
      });
    };

    var setTitle = function () {
      if ($state.current && $state.current.data) {
        var title;
        if (!!$state.current.data.shortTitle && $state.current.data.shortTitle.maxSize > $window.innerWidth) {
          title = $state.current.data.shortTitle.title;
        } else {
          title = $state.current.data.pageTitle;
        }

        atHelperFunctions.updateTitle($rootScope, $state);
        if (getPageTitle_angular() !== title) {
          setPageTitle_angular({ pageTitle: title });
        }
      }
    };
    setTitle();

    $scope.hideNavigation = function () {
      var hideNavigation = false;
      var urlsToHideNavigation = ['wizard?firstVisit=true'];

      urlsToHideNavigation.map(function (url) {
        if (window.location.hash.includes(url)) {
          hideNavigation = true;
          $rootScope.sidebar.hideSidebar(url);
        }
      });
      return hideNavigation;
    };

    $rootScope.$on('atWindowResized', setTitle);

    $scope.$watch(
      function () {
        return $state.current.data;
      },
      function () {
        setTitle();
      },
      true
    );

    $transitions.onSuccess({}, function () {
      setTitle();
    });

    $scope.$on('$stateChangeSuccess', function () {
      setTitle();
    });
  }

}