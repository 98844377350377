'use strict';

angular.module('app').directive('atInput', atInput);

function atInput() {
    return {
        restrict: 'E',
        scope: {
            inputError: '=?atInError',
            options: '=atOptions',
            model: '=atModel',
            disabled: '=atDisabled'
        },
        template: require('views/widgets/atInput.html'),
        controller: atInputCtrl
    };
}

atInputCtrl.$inject = ['$scope', '$timeout'];

function atInputCtrl($scope, $timeout) {
    var fieldErrorTimeout;

    function setShowReset() {
        $scope.showReset =
            $scope.options.canReset &&
            $scope.options.initialValue.toString() !== $scope.model.toString();
    }

    function createFieldError(error) {
        $scope.inputError = error;

        // Remove error message after 3 seconds
        $timeout.cancel(fieldErrorTimeout);
        fieldErrorTimeout = $timeout(function () {
            $scope.inputError = null;
        }, 3000);
    }

    // Validate value does not exceed max on input change to keep input
    // char length from looking bad with fixed width input.
    function validateMax(value) {
        try {
            value = parseInt(value);
        } catch (e) {
            value = undefined;
        }

        var max = $scope.options.max;

        if (value > max) {
            $scope.model = max;
            createFieldError(
                'Cannot exceed ' + max + ' minute' + (max === 1 ? '' : 's')
            );
        } else if (value <= 0) {
            validateMin(value);
        } else {
            $scope.inputError = null;
        }
    }

    // Validate value is not below minimum on input blur to allow for larger
    // number input such as '10' which requires a value of '1' during input
    function validateMin(value) {
        try {
            value = parseInt(value);
        } catch (e) {
            value = undefined;
        }

        var min = $scope.options.min;

        if (isNaN(value)) {
            $scope.model = $scope.options.defaultValue || min;
            createFieldError('Cannot be blank');
        } else if (value < min) {
            $scope.model = min;
            createFieldError(
                'Must be ' +
                    min +
                    ' minute' +
                    (min === 1 ? '' : 's') +
                    ' or greater'
            );
        } else {
            $scope.inputError = null;
        }
    }

    $scope.onKeydown = function (e) {
        if ($scope.options && typeof $scope.options.onKeydown === 'function') {
            $scope.options.onKeydown(e);
        } else {
            var key = e.keyCode ? e.keyCode : e.which;
            if (
                key === 13 &&
                $scope.options &&
                typeof $scope.options.onSubmit === 'function'
            ) {
                $scope.options.onSubmit();
            } else if (
                $scope.options.inputType === 'number' &&
                !(
                    [8, 9, 46, 110].indexOf(key) !== -1 ||
                    (key == 65 && (e.ctrlKey || e.metaKey)) ||
                    (key >= 35 && key <= 40) ||
                    (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
                    (key >= 96 && key <= 105)
                )
            ) {
                e.preventDefault();
            }
        }
    };

    $scope.onChange = function (value) {
        if (!$scope.options) {
            return;
        }
        setShowReset();

        if ($scope.options.inputType === 'number') {
            validateMax(value);
        }

        if (typeof $scope.options.onChange === 'function') {
            $scope.options.onChange(
                $scope.model,
                $scope.options.onChangeParams
            );
        }
    };

    $scope.onFocus = function () {
        if (!$scope.options) {
            return;
        }

        if (typeof $scope.options.onFocus === 'function') {
            $scope.options.onFocus($scope.options.onFocusParams);
        }
    };

    $scope.onBlur = function (value) {
        if (!$scope.options) {
            return;
        }

        if ($scope.options.inputType === 'number') {
            validateMin(value);
        }

        if (typeof $scope.options.onBlur === 'function') {
            $scope.options.onBlur($scope.model, $scope.options.onBlurParams);
        }
    };

    $scope.resetInput = function () {
        if ($scope.disabled) {
            return;
        }

        $scope.model = $scope.options.initialValue;
        $scope.onChange($scope.model);
    };

    $scope.$watch('options', function (options) {
        if (!options) {
            return;
        }

        $scope.id = 'id_' + options.id;
        $scope.autocomplete = options.allowAutocomplete ? 'on' : 'off';
        setShowReset();
    });
}
