import React, { useMemo } from 'react';
import { Checkbox } from '@mui/material';
import CheckboxProps from './Checkbox.props';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

//ONLY re-render the checkbox if 'checked', 'disabled' or 'indeterminate' updates
//'onClick' should already be memoized with useCallback since it won't change
const CheckboxMemoized: React.FC<CheckboxProps> = ({
  color = 'primary',
  indeterminateIcon = <IndeterminateCheckBoxIcon />,
  disabled,
  checked,
  indeterminate,
  sx = {},
  onClick,
  ...props
}) => {
  const styles = {
    ...{
      cursor: disabled ? 'not-allowed' : 'pointer'
    },
    ...sx
  };
  return useMemo(() => {
    return (
      <Checkbox
        color={color}
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        sx={styles}
        {...props}
      />
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, checked, indeterminate, onClick]);
};

export default CheckboxMemoized;
