class dashboardEventLog {
    constructor() {
        this.allEvents = [];
    }

    addEvent(event) {
        this.allEvents.push(event);
    }

    getPageEvents(subtypeFilter) {
        return _.filter(this.allEvents, (event) =>
            event.isPageEvent(subtypeFilter)
        );
    }

    getDashboardEvents(subtypeFilter) {
        return _.filter(this.allEvents, (event) =>
            event.isDashboardEvent(subtypeFilter)
        );
    }

    getLastPageEvent(subtypeFilter) {
        const filteredEvents = this.getPageEvents(subtypeFilter);
        return filteredEvents[filteredEvents.length - 1];
    }

    getLastDashboardEvent(subtypeFilter) {
        const filteredEvents = this.getDashboardEvents(subtypeFilter);
        return filteredEvents[filteredEvents.length - 1];
    }
}

export default dashboardEventLog;
