import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { TextField, InputAdornment } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { isEmpty } from '../../utilities';

const SearchList = (props) => {
    const { items } = props;
    const [results, setResults] = useState([]);
    const [search, setSearch] = useState('');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        if (isEmpty(search)) {
            setResults(items.map((item) => item.node));
        } else {
            const filteredItems = _.filter(items, (item) => _.includes(item.label.toLowerCase(), search.toLowerCase()));
            setResults(filteredItems.map((item) => item.node));
        }
    }, [search, items]);

    return (
        <Fragment>
            <TextField
                sx={{
                    margin: (theme) => theme.spacing(1),
                    '& legend': { display: 'none' },
                    '& input, & .MuiInputAdornment-root': { marginTop: '-4px', marginBottom: '2px' }
                }}
                variant="outlined"
                onChange={handleSearchChange}
                color="secondary"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" disablePointerEvents={true}>
                            <SearchOutlined />
                        </InputAdornment>
                    )
                }}
            />
            {results}
        </Fragment>
    );
};

export default SearchList;
