import { react2angular } from 'react2angular';
import { TeamPulseReportViewComponent } from '../../../../_reactivtrak/src/reports/team-pulse-report/views/TeamPulseReport.view';
import { teamPulseTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedTeamPulseReport', {
    template: teamPulseTemplate,
    controller: 'TeamPulseCtrl'
});

// This wraps TeamPulseReactViewComponent in <team-pulse-report> referenced in app.teampulse.ts
angular.module('app').component('teamPulseReport', react2angular(TeamPulseReportViewComponent, []));
