import { Role } from '../../../../../enums';
import { PlanType } from '../../../../../enums/PlanType';
import { IRoute } from '../../../../../models/IRoute';
import { isAccountPlanTypeAndInTrial } from '../../../../../stores/accountPlanStore';

export const appAccountWizard: IRoute = {
  name: 'app.account.wizard',
  stateDefinition: {
    url: '/account/wizard?roleAccess&firstVisit',
    template: '<account-wizard></account-wizard>',
    data: { pageTitle: 'Guided Account Setup' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic],
    customFunction: () => isAccountPlanTypeAndInTrial(PlanType.Professional)
  }
};

export default appAccountWizard;
