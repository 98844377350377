import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import { activationClasses } from '../styles/AgentActivationCard.styles';
import { DesktopMonitor } from '../../common/assets/Icons';
import { notifications } from '../constants/notifications';
import { useNotifications } from '../../common/services/Notifications';
import { AgentDownloadService } from '../../common/services';
import { getOs, downloadAgent } from '../utils/agentActivation.utils';
import { newEvent } from '../../common/analytics/eventHeap';

export const DownloadAgentCard = () => {
  const [downloadBtnName, setDownloadBtnName] =
    useState<string>('Download Agent');

  const service = AgentDownloadService();
  const notificationService = useNotifications();

  const downloadClicked = () => {
    setDownloadBtnName('Downloading Agent...');
    const os = getOs();

    newEvent('Click - Download Agent', { platform: os });

    service
      .fetchAgentLink(os)
      .then((response) => {
        const { url } = response.data;
        downloadAgent(url);

        notificationService.success(notifications.downloadSuccess);
        setDownloadBtnName('Download Agent');
      })
      .catch(() => {
        notificationService.error(notifications.downloadError);
      });

    newEvent('Click - Copy Link', { platform: os });
  };

  const styledBlurb = {
    ...activationClasses.hidden,
    ...activationClasses.cardBlurb
  };

  return (
    <Grid item xs={6} mb={2}>
      <Card sx={activationClasses.card}>
        <CardMedia title="Desktop Monitor" sx={activationClasses.img}>
          <DesktopMonitor width="80%" height="80%" />
        </CardMedia>
        <CardContent>
          <Typography fontWeight={500} sx={activationClasses.cardTitle}>
            Install on your device
          </Typography>
          <Typography className="cardBlurb" sx={styledBlurb}>
            Modifying the name of this installer will prevent the agent from
            being properly installed on any computer. For assistance, contact
            Support.{' '}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            sx={activationClasses.btn}
            onClick={() => downloadClicked()}
          >
            {downloadBtnName}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
