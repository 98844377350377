'use strict';

angular.module('app').directive('iTooltip', [
    'tooltipService',
    function (tooltipService) {
        return {
            restrict: 'EA',
            scope: {
                title: '@',
                placement: '@',
                iconClass: '@',
                template: '=',
                topOffset: '@',
                materialIcon: '@'
            },
            template: require('views/widgets/iTooltip.html'),
            link: function (scope) {
                function refreshContent(newValue, oldValue) {
                    if (newValue != oldValue) {
                        scope.kTooltip.options.content = scope.template;
                        scope.kTooltip.refresh();
                    }
                }
                scope.$watch('template', refreshContent);
                scope.iconClass = scope.iconClass || 'fs-14';
                scope.materialIcon = scope.materialIcon || 'info_outlined';
                scope.tooltipOptions = {
                    position: scope.placement || 'bottom',
                    content: scope.title,
                    animation: {
                        open: {
                            effects: 'fade:in'
                        },
                        close: {
                            effects: 'fade:out'
                        }
                    },
                    show: function (e) {
                        angular.element('.show-tooltip-foreground').removeClass('show-tooltip-foreground');

                        tooltipService.addTooltipMargin(this, 2);
                        tooltipService.fixTooltipCallout(this);
                        e.sender.popup.wrapper.addClass('show-tooltip-foreground');

                        e.sender.refresh();
                    }
                };

                if (scope.template) {
                    scope.tooltipOptions.content = scope.template;
                }
            }
        };
    }
]);
