import React, { useEffect, useState } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { ActiveTimeSettingsService } from '../services';
import { useActiveTimeSettingsState } from '../hooks';
import { DefaultWidgetProps } from '../models';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import {
  ACTIVE_CLIPPING_MIN,
  ACTIVE_CLIPPING_MAX,
  defaultActiveTimeSettings
} from '../constants';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Button,
  IconButton
} from '@mui/material';
import { IActiveTimeSettings } from '../models/IActiveTimeSettings';
import { PassiveTimeInput } from './PassiveTimeInput';

export const ActiveTimeSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const [newActiveTimeSettings, setNewActiveTimeSetting] =
    useState<IActiveTimeSettings>({
      activeMaxTime: defaultActiveTimeSettings.activeMaxTime,
      activeIndefinitely: defaultActiveTimeSettings.activeIndefinitely
    });
  const [hasError, setHasError] = useState<boolean>(false);

  const service = ActiveTimeSettingsService();
  const notificationService = useNotifications();

  const { activeTimeSettings, isLoading, init, setActiveSettings } =
    useActiveTimeSettingsState({
      service,
      notificationService
    });

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setNewActiveTimeSetting(activeTimeSettings);
  }, [activeTimeSettings]);

  const handleUpdate = () => {
    if (
      JSON.stringify(activeTimeSettings) !==
      JSON.stringify(newActiveTimeSettings)
    )
      setActiveSettings(newActiveTimeSettings);
  };

  const handleChange = (name, value) => {
    if (name)
      setNewActiveTimeSetting((prevState) => ({
        ...prevState,
        [name]: value
      }));
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    handleChange(name, checked);
  };

  return (
    <ConfigurationCardWrapper
      title={
        <span>
          Active Time Settings
          <Tooltip
            title={
              <span>
                Limits over-reporting caused by applications that block computer
                sleep or other causes
              </span>
            }
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </span>
      }
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={
            hasError ||
            isSupport ||
            isLoading ||
            JSON.stringify(activeTimeSettings) ===
              JSON.stringify(newActiveTimeSettings)
          }
          onClick={handleUpdate}
        >
          Save
        </Button>
      }
    >
      <Box>
        Cap the duration of any single activity to no more than
        <PassiveTimeInput
          name="activeMaxTime"
          max={ACTIVE_CLIPPING_MAX}
          min={ACTIVE_CLIPPING_MIN}
          initialValue={newActiveTimeSettings?.activeMaxTime}
          onChange={handleChange}
          isSupport={isSupport}
          setErrorStatus={setHasError}
          isDisabled={newActiveTimeSettings?.activeIndefinitely}
        />{' '}
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="activeIndefinitely"
              checked={newActiveTimeSettings?.activeIndefinitely}
              color="primary"
              onChange={handleSwitch}
              disabled={isSupport}
            />
          }
          label="Track active time indefinitely."
        />
      </FormGroup>
    </ConfigurationCardWrapper>
  );
};
