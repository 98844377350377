import React from 'react';
import { currentActivityClasses } from './CardCurrentActivity.style';
import { InfoPanel, InfoPanelVariant } from '../../Panels';
import { Tooltip } from '../../';

const CardCurrentActivity = (props) => {
    const { rootClass = {}, contentClass = {}, activeStatus, realtime, loading, isReport, isViewer } = props;
    const { url, title, description, executable } = realtime || {};

    let headingText;
    let headingLink;
    const baseUrl = '/#/app/reports/activitylog?';

    if (url) {
        headingText = url;
        headingLink = `${baseUrl}site=${encodeURIComponent(url)}`;
    } else if (description) {
        headingText = description;
        headingLink = `${baseUrl}app=${encodeURIComponent(description)}`;
    } else if (executable) {
        headingText = executable;
        headingLink = `${baseUrl}exec=${encodeURIComponent(executable)}`;
    } else {
        headingText = title;
    }

    const heading = headingText ? (
        <Tooltip title={headingText}>
            <>
                {!isViewer && (
                    <a
                        id={
                            isReport
                                ? 'id_e7f19c9a-78e7-467e-b559-a60301117d4c'
                                : 'id_a9356635-8cc7-4cb0-b15c-628267ee86ad'
                        }
                        href={headingLink}
                    >
                        {headingText}
                    </a>
                )}
                {isViewer && <span>{headingText}</span>}
            </>
        </Tooltip>
    ) : (
        'Not Active'
    );

    const subheading =
        title && activeStatus !== 'passive' && !isViewer ? (
            <Tooltip title={title}>
                <a
                    id={
                        isReport ? 'id_06cff7e4-dbc1-478e-8d25-d8a796cc84b6' : 'id_d7e61440-4d9f-4591-9b76-5b88f01045e1'
                    }
                    href={`${baseUrl}title=${encodeURIComponent(title)}`}
                >
                    {title}
                </a>
            </Tooltip>
        ) : (
            title
        );

    return (
        <div style={{ ...rootClass, ...currentActivityClasses.root }}>
            <div style={{ ...contentClass, ...currentActivityClasses.content }}>
                <InfoPanel
                    heading={heading}
                    headingClass={headingText ? {} : currentActivityClasses.none}
                    subheading={subheading}
                    largeHeading={true}
                    loading={loading}
                    variant={InfoPanelVariant.Stacked}
                />
            </div>
        </div>
    );
};

export default CardCurrentActivity;
