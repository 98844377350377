import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { UseUsersContext, UseComputersContext } from '../services';
import { IScheduledAction, IUser } from '../models';
import { useNotifications } from '../../common/services/Notifications';

import { sdmStyles as classes } from '../styles';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { ScheduledDeleteModes } from '../enums';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

type ComponentProps = {
  mode: ScheduledDeleteModes;
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const ScheduledDeletesModal = forwardRef(
  (props: ComponentProps, ref) => {
    useImperativeHandle(ref, () => ({
      loadScheduledActions() {
        reloadScheduledActions();
      }
    }));

    const fetchScheduledDeletes = () => {
      if (softDeletesEnabled) {
        // Soft delete restores are handled by the clients & devices endpoints separately
        switch (props.mode) {
          case ScheduledDeleteModes.Computers:
            return computersService.fetchScheduledDeletes();
          case ScheduledDeleteModes.Users:
            return usersService.fetchScheduledDeletes();
        }
      }

      // Scheduled action management is handled through UsersService for both users & computers
      // TODO: Remove when soft deletes are enabled for all accounts
      return usersService.fetchScheduledActions();
    };

    const deleteScheduledDelete = async (scheduledAction: IScheduledAction) => {
      if (softDeletesEnabled) {
        // Soft delete restores are handled by the clients & devices endpoints separately
        switch (props.mode) {
          case ScheduledDeleteModes.Computers:
            return await computersService.deleteScheduledDelete(
              scheduledAction.id
            );
          case ScheduledDeleteModes.Users:
            if (scheduledAction.dntid) {
              await deleteDoNotTrackScheduledUsers([scheduledAction]);
            }
            return await usersService.deleteScheduledDelete(scheduledAction.id);
        }
      }

      // Scheduled action management is handled through UsersService for both users & computers
      // TODO: Remove when soft deletes are enabled for all accounts
      return await usersService.deleteScheduledAction(scheduledAction.id);
    };

    const deleteAllScheduledDeletes = async () => {
      if (softDeletesEnabled) {
        // Soft delete restores are handled by the clients & devices endpoints separately
        switch (props.mode) {
          case ScheduledDeleteModes.Computers:
            return await computersService.deleteAllScheduledDeletes();
          case ScheduledDeleteModes.Users:
            // For users that have also been added to DNT, remove the DNT entries
            await deleteDoNotTrackScheduledUsers(
              scheduledActions.filter((sa) => sa.dntid)
            );
            return await usersService.deleteAllScheduledDeletes();
        }
      }

      // Scheduled action management is handled through UsersService for both users & computers
      // TODO: Remove when soft deletes are enabled for all accounts
      return await usersService.deleteAllScheduledActions();
    };

    const deleteDoNotTrackScheduledUsers = async (
      scheduledUserDeleteActions: IScheduledAction[]
    ) => {
      if (softDeletesEnabled && scheduledUserDeleteActions.length) {
        await usersService.deleteDoNotTrackUsers(
          scheduledUserDeleteActions.map((sa) => {
            return {
              id: sa.typeid,
              logonDomain: sa.primary,
              user: sa.secondary
            } as IUser;
          })
        );
      }

      // Do nothing to do not track if soft deletes are not enabled
      return Promise.resolve();
    };

    const reloadScheduledActions = () => {
      setScheduledActions([]);
      setViewIsLoading(true);
      fetchScheduledDeletes()
        .then((response: IScheduledAction[]) => {
          setScheduledActions(response);
        })
        .catch(() => {
          //what should happen here?
        })
        .finally(() => {
          setViewIsLoading(false);
        });
    };

    const { open, onClose, onCancel } = props;
    const { usersService } = UseUsersContext();
    const { computersService } = UseComputersContext();
    const [scheduledActions, setScheduledActions] =
      useState<IScheduledAction[]>();
    const notificationService = useNotifications();
    const [viewIsLoading, setViewIsLoading] = useState(false);
    const authorization = useAuthorization();
    const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);

    const close = () => {
      onClose();
    };

    const onDeleteClick = async (scheduledAction: IScheduledAction) => {
      try {
        await deleteScheduledDelete(scheduledAction);
        notificationService.success(`Success! Scheduled delete is cancelled.`);
        reloadScheduledActions();
        onCancel();
      } catch (response) {
        notificationService.error(response.data.error);
      }
    };

    const onDeleteAllClick = async () => {
      try {
        await deleteAllScheduledDeletes();
        notificationService.success(`Success! Scheduled delete is cancelled.`);
        reloadScheduledActions();
        onCancel();
      } catch (response) {
        notificationService.error(response.data.error);
      }
    };

    return (
      <Dialog open={open} onClose={close} maxWidth={'md'}>
        <DialogTitle id="scheduled-users-dialog-title">
          Scheduled Deletes
        </DialogTitle>
        <DialogContent id="scheduled-users-dialog-content">
          <TableContainer sx={classes.tableContainer}>
            <Table stickyHeader size="small" sx={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell sx={classes.tableHeader}>Id</TableCell>
                  <TableCell sx={classes.tableHeader}>Type</TableCell>
                  <TableCell sx={classes.tableHeader}>Context</TableCell>
                  <TableCell sx={classes.tableHeader}>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scheduledActions?.map((scheduledAction: IScheduledAction) => (
                  <TableRow key={scheduledAction.id}>
                    <TableCell>{scheduledAction.typeid}</TableCell>
                    <TableCell>{scheduledAction.typename}</TableCell>
                    <TableCell>{scheduledAction.primary}</TableCell>
                    <TableCell>{scheduledAction.secondary}</TableCell>
                    <TableCell>
                      {scheduledAction.dntid == null && (
                        <Button
                          onClick={() => onDeleteClick(scheduledAction)}
                          variant="outlined"
                        >
                          CANCEL DELETE
                        </Button>
                      )}
                      {scheduledAction.dntid != null &&
                        (softDeletesEnabled ? (
                          <Button
                            onClick={() => onDeleteClick(scheduledAction)}
                            variant="outlined"
                          >
                            CANCEL DELETE & DO NOT TRACK
                          </Button>
                        ) : (
                          <span>
                            Use{' '}
                            <a href="/#/app/settings/doNotTrack">
                              Do Not Track
                            </a>{' '}
                            to cancel
                          </span>
                        ))}
                    </TableCell>
                  </TableRow>
                ))}
                {(scheduledActions === undefined ||
                  scheduledActions?.length === 0 ||
                  viewIsLoading) && (
                  <TableRow>
                    <TableCell colSpan={4} sx={classes.emptyCell}>
                      {viewIsLoading
                        ? 'Loading ...'
                        : 'No pending deletes found'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={classes.deleteDiv}>
            <Button onClick={() => onDeleteAllClick()} variant="outlined">
              Cancel All
            </Button>
          </div>
          <div>
            <label
              htmlFor={'scheduled-users-dialog-content'}
              style={classes.timingLabel}
            >
              *Please allow up to 12 hours for deletes to complete. User and
              Computer data will be reflected in other reports until this action
              is complete.
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
