import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsDigest: IRoute = {
  name: 'app.settings.digest',
  stateDefinition: {
    url: '/settings/digest',
    template: '<settings-digest></settings-digest>',
    data: { pageTitle: 'Digest' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsDigest;
