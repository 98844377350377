import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import {
  aqua3,
  aqua4,
  buttonTextColor,
  disabledTextColor,
  gray14,
  gray16,
  linkHoverColor,
  primaryAqua,
  primaryBlue
} from '../../../constants';

const tooltipColorBg = '#fff';
const dayWidth = '37px';
const dayColor = '#333';
const dayColorHover = '#2196f3';
const monthWidth = `calc(${dayWidth} * 7)`; // 7 days
const monthHeaderColor = '#333';
const monthWeekdayColor = '#9e9e9e';
const monthWeekNumberColor = '#9e9e9e';
const isTodayColor = '#f44336';
const isInRangeColor = '#bbdefb';
const isLockedColor = '#9e9e9e';
const isStartColor = '#fff';
const isStartColorBg = '#2196f3';
const isEndColor = '#fff';
const isEndColorBg = '#2196f3';
const buttonResetColor = '#909090';
const buttonResetColorHover = '#2196f3';
const buttonPrevMonthColor = '#9e9e9e';
const buttonNextMonthColor = '#9e9e9e';
const buttonPrevMonthColorHover = '#2196f3';
const buttonNextMonthColorHover = '#2196f3';
const highlightedDayColor = '#333';
const highlightedDayColorBg = '#ffeb3b';

export const LitepickerContainer = styled(Box)({
  '.show-week-numbers': {
    width: `calc(${dayWidth} * 8)`
  },

  '.litepicker': {
    fontSize: '14px',
    display: 'none',

    button: {
      fontWeight: 500,
      border: 'none',
      background: 'none'
    },

    '.container__main': {
      display: 'flex'
    },

    '.container__months': {
      display: 'flex',
      flexWrap: 'wrap',
      width: monthWidth,
      boxSizing: 'content-box',

      '&.columns-2': {
        width: `calc((${monthWidth} * 2) + 20px)`
      },

      '&.columns-3': {
        width: `calc((${monthWidth} * 3) + 30px)`
      },

      '&.columns-4': {
        width: `calc((${monthWidth} * 4) + 40px)`
      },
      ' &.split-view': {
        '.month-item-header': {
          '.button-previous-month, .button-next-month': {
            visibility: 'visible'
          }
        }
      },
      '.month-item': {
        padding: '0 0 5px',
        width: monthWidth,
        boxSizing: 'content-box',

        '.month-item-header': {
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 500,
          padding: '0 5px 10px',
          textAlign: 'center',
          alignItems: 'center',
          color: monthHeaderColor,
          div: {
            flex: 1,

            '> .month-item-name': {
              marginRight: '5px',
              padding: '5px',
              borderColor: gray16,
              borderRadius: '4px'
            },

            '> .month-item-year': {
              padding: '5px',
              borderColor: gray16,
              borderRadius: '4px'
            }
          },

          '.reset-button': {
            color: buttonResetColor,

            '> svg': {
              fill: buttonResetColor
            },

            '*': {
              pointerEvents: 'none'
            },

            '&:hover': {
              color: buttonResetColorHover,

              '> svg': {
                fill: buttonResetColorHover
              }
            }
          },

          '.button-previous-month, .button-next-month': {
            visibility: 'hidden',
            textDecoration: 'none',
            padding: '3px 5px',
            borderRadius: '3px',
            transition: 'color 0.3s, border 0.3s',
            cursor: 'pointer',

            '*': {
              pointerEvents: 'none'
            }
          },

          '.button-previous-month': {
            color: buttonPrevMonthColor,

            '> svg, > img': {
              fill: buttonPrevMonthColor
            },

            '&:hover': {
              color: buttonPrevMonthColorHover,

              '> svg': {
                fill: buttonPrevMonthColorHover
              }
            }
          },

          '.button-next-month': {
            color: buttonNextMonthColor,

            '> svg, > img': {
              fill: buttonNextMonthColor
            },

            '&:hover': {
              color: buttonNextMonthColorHover,

              '> svg': {
                fill: buttonNextMonthColorHover
              }
            }
          }
        },

        '.month-item-weekdays-row': {
          display: 'flex',
          justifySelf: 'center',
          justifyContent: 'flex-start',
          color: monthWeekdayColor,

          '> div': {
            padding: '5px 0',
            fontSize: '85%',
            flex: 1,
            width: dayWidth,
            textAlign: 'center'
          }
        },

        '&:first-of-type': {
          '.button-previous-month': {
            visibility: 'visible'
          }
        },

        '&:last-of-type': {
          '.button-next-month': {
            visibility: 'visible'
          }
        },

        '&.no-previous-month': {
          '.button-previous-month': {
            visibility: 'hidden'
          }
        },

        '&.no-next-month': {
          '.button-next-month': {
            visibility: 'hidden'
          }
        }
      },

      '.container__days': {
        display: 'flex',
        flexWrap: 'wrap',
        justifySelf: 'center',
        justifyContent: 'flex-start',
        textAlign: 'center',
        boxSizing: 'content-box',

        '> div, > a': {
          padding: '5px 0',
          width: dayWidth
        },

        '.day-item': {
          color: dayColor,
          textAlign: 'center',
          textDecoration: 'none',
          borderRadius: '3px',
          transition: 'color 0.3s, border 0.3s',
          cursor: 'pointer',

          '&:hover': {
            color: dayColorHover,
            boxShadow: `inset 0 0 0 1px ${dayColorHover}`
          },

          '&.is-today': {
            color: isTodayColor
          },

          '&.is-locked': {
            color: isLockedColor,

            '&:hover': {
              color: isLockedColor,
              boxShadow: 'none',
              cursor: 'default'
            }
          },

          '&.is-in-range': {
            backgroundColor: isInRangeColor,
            borderRadius: 0
          },

          '&.is-start-date': {
            color: isStartColor,
            backgroundColor: isStartColorBg,
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            '&.is-flipped': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px'
            }
          },

          '&.is-end-date': {
            color: isEndColor,
            backgroundColor: isEndColorBg,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',

            '&.is-flipped': {
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }
          },

          '&.is-start-date.is-end-date': {
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px'
          },

          '&.is-highlighted': {
            color: highlightedDayColor,
            backgroundColor: highlightedDayColorBg
          }
        },

        '.week-number': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: monthWeekNumberColor,
          fontSize: '85%'
        }
      }
    },

    '& .container__footer': {
      textAlign: 'right',

      '& .preview-date-range': {
        display: 'none'
      },

      '& .button-cancel': {
        color: primaryBlue,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        '&:hover': {
          color: linkHoverColor,
          backgroundColor: '#ffffff'
        },
        '&:focus': {
          backgroundColor: '#ffffff'
        },
        border: 0,
        padding: '6px 14px',

        '*': {
          pointerEvents: 'none'
        }
      },

      '& .button-apply': {
        color: buttonTextColor,
        backgroundColor: primaryAqua,
        borderColor: primaryAqua,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: aqua3,
          boxShadow: 'none'
        },
        '&:focus': {
          backgroundColor: aqua4
        },
        border: 0,
        padding: '6px 14px',
        borderRadius: '3px',

        '&:disabled': {
          color: disabledTextColor,
          borderColor: gray14,
          backgroundColor: gray14,
          opacity: 1
        },

        '*': {
          pointerEvents: 'none'
        }
      }
    },

    '& .container__tooltip': {
      position: 'absolute',
      marginTop: '-4px',
      padding: '4px 8px',
      borderRadius: '4px',
      backgroundColor: tooltipColorBg,
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
      whiteSpace: 'nowrap',
      fontSize: '11px',
      pointerEvents: 'none',
      visibility: 'hidden',

      '&:before': {
        position: 'absolute',
        bottom: '-5px',
        left: 'calc(50% - 5px)',
        borderTop: '5px solid rgba(0, 0, 0, 0.12)',
        borderRight: '5px solid transparent',
        borderLeft: '5px solid transparent',
        content: '""'
      },

      '&:after': {
        position: 'absolute',
        bottom: '-4px',
        left: 'calc(50% - 4px)',
        borderTop: `4px solid ${tooltipColorBg}`,
        borderRight: '4px solid transparent',
        borderLeft: '4px solid transparent',
        content: '""'
      }
    }
  }
});
