'use strict';

angular.module('app')
    .controller('msaAcceptanceModalController', MsaAcceptanceModalController);

MsaAcceptanceModalController.$inject = ['$scope', '$uibModalInstance'];

function MsaAcceptanceModalController($scope, $uibModalInstance) {

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.accept = function () {
        $uibModalInstance.close();
    }
}
