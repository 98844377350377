import React, { useEffect, useMemo, useState } from 'react';
import {
  IComputer,
  IAllAgentHealth,
  IComputerAgentHealth,
  IAgentCommand,
  IAgentCommandDto
} from '../models';
import { formatDate } from '../../common/helpers';
import { UseComputersContext } from '../services';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNotifications } from '../../common/services/Notifications';
import { CommandGrid } from './CommandGrid';
import { AgentUserChecks } from './AgentUserChecks';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import { TabPanel } from '../../common/components/Tabs/TabPanel';

import {
  AgentHealthModalHeader,
  HeaderLeft,
  HeaderRight,
  OSVersionContainer,
  ParamContainer,
  ParamTitle
} from '../styles';
import { mapToAgentCommand } from '../utils';
import { DiagnosticsModal } from './DiagnosticsModal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogActions from '@mui/material/DialogActions';

type ComponentProps = {
  open: boolean;
  selectedComputer: IComputer;
  onClose: () => void;
  dateTimeFormat: string;
};

enum CommandType {
  Restart,
  Diagnostics
}

const defaultLogSettings = {
  off: [
    { name: 'Activtrak', level: 'OFF' },
    { name: 'ActivtrakLib', level: 'OFF' },
    { name: 'Svctcom', level: 'OFF' },
    { name: 'Scthost', level: 'OFF' },
    { name: 'ActivTrak.UserManagement.UserProxy', level: 'CRITICAL' },
    { name: 'ActivTrak.Database.SqliteConnection', level: 'ERROR' },
    { name: 'ActivTrak.Alarms.UserAlarmManager', level: 'ERROR' },
    { name: 'ActivTrak.Alarm.AlarmResponse.FromString', level: 'CRITICAL' },
    { name: 'ActivTrak.Alarms.AlarmGenerator', level: 'CRITICAL' }
  ],
  on: [
    { name: 'Activtrak', level: 'TRACE' },
    { name: 'ActivtrakLib', level: 'TRACE' },
    { name: 'Svctcom', level: 'TRACE' },
    { name: 'Scthost', level: 'TRACE' }
  ]
};

export const AgentHealthModal = (props: ComponentProps) => {
  const { open, selectedComputer, onClose, dateTimeFormat } = props;

  const [loggingEnabled, setLoggingEnabled] = useState<boolean>(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationService = useNotifications();
  const [agentCommands, setAgentCommands] = useState<IAgentCommand[]>(null);
  const [tab, setTab] = useState<number>(0);
  const [openDiagnostics, setOpenDiagnostics] = useState(false);
  const [diagnosticsAgentCommand, setDiagnosticsAgentCommand] =
    useState<any>(null);

  const { computersService } = UseComputersContext();

  useEffect(() => {
    async function getLoggedOn() {
      let isAgentLoggingOn = false;
      if (selectedComputer) {
        const agentSettings = await computersService.getAgentSettings(
          selectedComputer?.id
        );
        agentSettings?.logSettings?.forEach((element) => {
          if (
            element &&
            element.level !== 'OFF' &&
            element.level !== '0' &&
            element.name
          ) {
            isAgentLoggingOn = [
              'activtrak',
              'activtraklib',
              'svctcom',
              'scthost'
            ].includes(element.name.toLowerCase());
          }
        });
        setLoggingEnabled(isAgentLoggingOn);
      }
      return isAgentLoggingOn;
    }

    getLoggedOn();
  }, [selectedComputer, computersService]);

  const onOpenDiagnostics = (agentCommand: any) => {
    if (agentCommand) {
      setDiagnosticsAgentCommand(agentCommand);
      setOpenDiagnostics(true);
    }
  };

  const onCloseDiagnostics = () => {
    setDiagnosticsAgentCommand(null);
    setOpenDiagnostics(false);
  };

  const systemAgentHealth = useMemo(() => {
    return selectedComputer?.healthStatus?.users?.find(
      (user) => user.name === 'SYSTEM'
    );
    //refreshCount is an unnecessary dependency. if it's being used to auto-update 'systemAgentHealth', find another way
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComputer, refreshCount]);

  const userAgentsHealth = useMemo(() => {
    return selectedComputer?.healthStatus?.users?.filter(
      (user) => user.name !== 'SYSTEM'
    );
    //refreshCount is an unnecessary dependency. if it's being used to auto-update 'userAgentsHealth', find another way
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComputer, refreshCount]);

  const isCommandPending: boolean = useMemo(() => {
    if (!agentCommands) return true;

    return agentCommands?.some((cmd) => cmd.status === null);
  }, [agentCommands]);

  const close = () => {
    onClose();
    setTab(0);
    setAgentCommands(null);
  };

  const onStatusRefresh = () => {
    setIsLoading(true);
    refreshAgentCommands();
    computersService
      .fetchComputerAgentHealth()
      .then((response: IAllAgentHealth) => {
        appendComputerData(response.computers);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onRestartAgent = () => {
    issueCommand(CommandType.Restart);
  };

  const onRunDiagnostics = () => {
    issueCommand(CommandType.Diagnostics);
  };

  const issueCommand = (command: CommandType) => {
    computersService
      .sendAgentCommand(selectedComputer.id, CommandType[command])
      .then(() => {
        refreshAgentCommands();
      });
  };

  function appendComputerData(healthStatuses: IComputerAgentHealth[]) {
    // Append computer data with health status
    if (healthStatuses) {
      healthStatuses.forEach(function (healthStatus) {
        if (
          selectedComputer &&
          selectedComputer?.computer === healthStatus.name
        ) {
          selectedComputer.healthStatus = healthStatus;

          selectedComputer.globalStatus = computersService.getGlobalStatus(
            selectedComputer.healthStatus
          );
          setRefreshCount(refreshCount + 1);
        }
      });
    }
  }

  const toggleAgentLogging = async () => {
    const newLogging = loggingEnabled
      ? defaultLogSettings.off
      : defaultLogSettings.on;
    computersService
      .setAgentSettings(selectedComputer.id, newLogging)
      .then((response) => {
        if (response.status == 200) {
          notificationService.success('Successfully updated agent logging');
        } else {
          notificationService.error('Error updating agent logging.');
        }
      });
    setLoggingEnabled(!loggingEnabled);
  };

  const refreshAgentCommands = () => {
    computersService
      .fetchComputerAgentCommands(selectedComputer.id)
      .then((response: IAgentCommandDto[]) => {
        setAgentCommands(mapToAgentCommand(response));
      });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (newValue == 1) {
      refreshAgentCommands();
    }
  };

  function tabProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`
    };
  }

  return (
    <Dialog open={open} onClose={close} maxWidth={'xl'} fullWidth={true}>
      <DialogTitle id="agent-health-dialog-title">
        Agent Health Status
      </DialogTitle>
      <DialogContent id="agent-health-dialog-content">
        <AgentHealthModalHeader>
          <HeaderLeft>
            <ParamContainer>
              <ParamTitle>Computer:</ParamTitle> {selectedComputer?.computer}{' '}
              {selectedComputer?.windowsDomain
                ? '(' + selectedComputer.windowsDomain + ')'
                : ''}
            </ParamContainer>
            <OSVersionContainer>
              <ParamTitle>Version:</ParamTitle>{' '}
              {getOSIcon(selectedComputer?.os)}{' '}
              <span>{selectedComputer?.agentVersion}</span>
            </OSVersionContainer>
            <ParamContainer>
              <ParamTitle>Last Contact:</ParamTitle>{' '}
              {systemAgentHealth && (
                <span>
                  {formatDate(systemAgentHealth?.time, dateTimeFormat)}
                </span>
              )}
              {!systemAgentHealth && <span>None</span>}
            </ParamContainer>
          </HeaderLeft>
          <HeaderRight>
            <Checkbox
              checked={loggingEnabled}
              onClick={toggleAgentLogging}
              color="primary"
              title="Agent Logging"
            />
            <span> Agent Logging</span>
            <br />

            <Button onClick={onStatusRefresh} startIcon={<RefreshIcon />}>
              Refresh
            </Button>
            <br />
            <Button
              onClick={onRestartAgent}
              disabled={isCommandPending || tab == 0}
            >
              Restart Agent
            </Button>
            <Button
              onClick={onRunDiagnostics}
              disabled={isCommandPending || tab == 0}
            >
              Run Diagnostics
            </Button>
          </HeaderRight>
        </AgentHealthModalHeader>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="agent health tab"
            >
              <Tab label="Checks" {...tabProps(0)} />
              <Tab label="Command History" {...tabProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <AgentUserChecks
              isLoading={isLoading}
              systemAgentHealth={systemAgentHealth}
              userAgentsHealth={userAgentsHealth}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <CommandGrid
              agentCommandHistory={agentCommands}
              dateTimeFormat={dateTimeFormat}
              openDiagnosticsModal={onOpenDiagnostics}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
      <DiagnosticsModal
        open={openDiagnostics}
        onClose={onCloseDiagnostics}
        agentCommand={diagnosticsAgentCommand}
        computer={selectedComputer?.computer}
        domain={selectedComputer?.windowsDomain}
        dateTimeFormat={dateTimeFormat}
      />
    </Dialog>
  );
};
