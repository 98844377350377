export const ApiRoutes = {
  admin: {
    fetchUserHealth: '/admin/v1/clients/health'
  },
  reports: {
    fetchProductivity: '/reports/v1/dashboard/productivity',
    fetchProductivityGroups: '/reports/v1/topusers',
    fetchApplications: '/reports/v1/dashboard/applications',
    fetchTodaysActivity: '/reports/v1/home/activity',
    fetchTodaysUserActivity: '/reports/v1/home/activity/users',
    fetchTodaysUserActivityDetails: '/reports/v1/home/activity/users/details',
    fetchTodaysProductivity: '/reports/v1/home/productivity',
    fetchTodaysProductivityUsers: '/reports/v1/home/productivity/users',
    fetchVirtualOfficeHours: '/reports/v1/home/office',
    fetchOrganizationTrendsHours: '/reports/v1/home/trend',
    fetchWorkload: '/reports/v1/home/workload',
    fetchWorkloadByWeek: '/reports/v1/home/workload/users',
    fetchProductivityGoal: '/reports/v1/home/goal',
    fetchProductivityGoalUsersTrends: '/reports/v1/home/goal/users',
    fetchTopCategories: '/reports/v1/home/category',
    fetchTopCategoryActivities: '/reports/v1/home/category/:id',
    fetchTodaysProductivityOutlook: '/reports/v1/home/summary',
    fetchTodaysProductivityOutlookFeedback:
      '/reports/v1/home/summary/:runId/feedback'
  }
};
