'use strict';

angular.module('app')
    .directive('scrollToElement', ['$timeout', scrollToElement]);

function scrollToElement($timeout) {
    return function(scope, elem, attr) {
        var targetSelector = scope.$eval(attr.scrollToElement);

        if (!targetSelector) {
            return;
        }

        var scrollWhenLoadingIsFinished = function() {
            var loadingIsFinished = !angular.element('body').hasClass('pace-running');
            if (loadingIsFinished) {
                var target = angular.element(elem).find(targetSelector);
                if (!target.length) {
                    return;
                }
                angular.element('html, body').animate({
                    scrollTop: (target.offset().top - angular.element('.header').height())
                }, 500, 'swing', function() {
                    scope.$apply();
                });
            } else {
                $timeout(scrollWhenLoadingIsFinished, 250);
            }
        };
        $timeout(scrollWhenLoadingIsFinished);
    };
}
