import { analyticEventServiceFunctions } from './analyticEventServiceFunctions';

/**
 * Creates the needed HTML to load the video
 * @param {Number} id Video Id
 */
const createVideoHtml = (id) => {
    const embedContainerStart = `<div class="wistia_embed wistia_async_${id} videoFoam=true" style="height: 100%;position: relative;width: 100%">`;
    const videoContainerStart =
        '<div class="wistia_swatch" style="height: 100%;left: 0;opacity: 0;overflow: hidden;position: absolute;top: 0;transition: opacity 200ms;width: 100%;">';
    return `${embedContainerStart}${videoContainerStart}</div></div>`;
};

/**
 * Loads a script with given source
 * @param {String} source
 */
const _loadScript = (source) => {
    var node = window.document.createElement('script');
    node.src = source;
    node.type = 'text/javascript';
    window.document.getElementsByTagName('head')[0].appendChild(node);
};

/**
 * Convert secondsWatchedVector into quartersWatched Arrays
 * @param {*} player Embedded video player
 */
const _getQuartersWatched = (player) => {
    if (!(player && player.video)) {
        return false;
    }

    var secondsWatchedVector = player.video.secondsWatchedVector();
    player.quartersWatched = [true, true, true, true];
    player.quarterPartialWatched = [false, false, false, false];

    _.forEach(secondsWatchedVector, (secondWatched, key) => {
        const quarter = Math.ceil(
            ((key + 1) / secondsWatchedVector.length) * 4
        );

        player.quartersWatched[quarter - 1] =
            player.quartersWatched[quarter - 1] && Boolean(secondWatched);
        player.quarterPartialWatched[quarter - 1] =
            player.quarterPartialWatched[quarter - 1] || Boolean(secondWatched);
    });

    return true;
};

/**
 * Converts the quarters watched array into a true/false based on the percent watched desired
 * @param {Array} quartersWatched Array of quarters watched
 * @param {Number} quartersToInclude How many quarters to include in calculation
 */
const _convertQuartersToPercentWatched = (
    quartersWatched,
    quartersToInclude
) => {
    let watched = true;

    for (let i = 0; i < quartersToInclude; i++) {
        watched = watched && quartersWatched[i];
    }

    return watched;
};

/**
 * Adds a timestamp for the first time the video player opens and closes full screen
 * @param {*} player Embedded video player
 */
const _updateFullScreen = (player) => {
    const heightDifference = Math.abs(
        player.video.height() - window.innerHeight
    );
    const isFullScreen = heightDifference / window.innerHeight < 0.05;

    if (
        player.timestamp &&
        !player.ended &&
        isFullScreen &&
        !player.fullscreenOpened
    ) {
        player.fullscreenOpened = player.timestamp;
    } else if (
        player.timestamp &&
        !player.ended &&
        !isFullScreen &&
        player.fullscreenOpened &&
        !player.fullscreenClosed
    ) {
        player.fullscreenClosed = player.timestamp;
    }
};

/**
 * Binds the Wistia events to drive setting the embedded video player statistics
 * @param {*} wistia Wistia API
 * @param {*} player Embedded Video Player
 */
const _videoPlayerLoaded = (wistia, player) => {
    if (!wistia || typeof wistia.push !== 'function') {
        console.error(
            `ActivTrak Error: Embedded video player failed to load due to ill-defined service.\nService: `,
            wistia
        );
        return;
    }

    wistia.push({
        id: player.id,
        onReady: function (video) {
            player.video = video;
            player.video.bind('play', () => (player.started = true));
            player.video.bind('pause', () => (player.paused = true));
            player.video.bind('seek', () => (player.seeked = true));
            player.video.bind('end', () => (player.ended = true));
            player.video.bind('heightchange', () => _updateFullScreen(player));
            player.video.bind('timechange', (t) => (player.timestamp = t));
            player.video.bind('volumechange', (volume, isMuted) => {
                player.muted = isMuted;
                player.volume = Math.round(volume * 100);
            });
        }
    });
};

/**
 * Used to create an embedded video based on given video id and name
 * @param {Number} id Video Id
 * @param {String} name Video Event Name
 */
class embeddedVideoPlayerService {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.videoHtml = createVideoHtml(id);
        this.ended = false;
        this.started = false;
        this.paused = false;
        this.seeked = false;
        this.muted = false;
        this.quartersWatched = [false, false, false, false];
        this.timestamp;
        this.fullscreenOpened = 0;
        this.fullscreenClosed = 0;
        this.volume = 100;

        const videoPlayerLoaded = (wistia) => {
            _videoPlayerLoaded(wistia, this);
        };

        _loadScript(`https://fast.wistia.com/embed/medias/${this.id}.jsonp`);
        _loadScript('https://fast.wistia.com/assets/external/E-v1.js');

        // Listen for wistia library to be loaded
        Object.defineProperty(window, '_wq', {
            configurable: true,
            set(value) {
                Object.defineProperty(window, '_wq', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value
                });
                videoPlayerLoaded(window._wq);
            }
        });
    }

    /**
     * Create new event with video statistics
     */
    createEvent() {
        if (!_getQuartersWatched(this)) {
            return;
        }

        const eventObject = {
            'Video Id': this.id,
            'Video Started': this.started,
            'Video Ended': this.ended,
            'Video Paused': this.paused,
            'Video Seeked': this.seeked,
            'Video Muted': this.muted,
            'Video Volume (%)': this.volume,
            'Full screen Opened (sec)': this.fullscreenOpened,
            'Full screen Closed (sec)': this.fullscreenClosed,
            'Video 25% Watched': _convertQuartersToPercentWatched(
                this.quartersWatched,
                1
            ),
            'Video 50% Watched': _convertQuartersToPercentWatched(
                this.quartersWatched,
                2
            ),
            'Video 75% Watched': _convertQuartersToPercentWatched(
                this.quartersWatched,
                3
            ),
            'Video 100% Watched': _convertQuartersToPercentWatched(
                this.quartersWatched,
                4
            ),
            'Video Q1 Fully Watched': this.quartersWatched[0],
            'Video Q2 Fully Watched': this.quartersWatched[1],
            'Video Q3 Fully Watched': this.quartersWatched[2],
            'Video Q4 Fully Watched': this.quartersWatched[3],
            'Video Q1 Partially Watched': this.quarterPartialWatched[0],
            'Video Q2 Partially Watched': this.quarterPartialWatched[1],
            'Video Q3 Partially Watched': this.quarterPartialWatched[2],
            'Video Q4 Partially Watched': this.quarterPartialWatched[3]
        };

        // Trigger Event
        analyticEventServiceFunctions.newEvent(
            `Embedded Video - ${this.name}`,
            eventObject
        );
    }
}

export default embeddedVideoPlayerService;
