'use strict';

angular.module('app')
    .directive('alphanumeric', function alphanumeric() {
        return {
            restrict: 'EA',
            link: function ($scope, element) {
                element.bind('input', function () {
                    $(this).val($(this).val().replace(/[^a-zA-Z0-9]/g, ''));
                });
            }
        };
    });
