import React from 'react';
import { unescape } from 'lodash';
import { GroupIconSource } from '../../assets/Icons';
import { GroupTypeLabelProps } from './GroupTypeLabel.props';
import { GroupTypeLabelSC, TooltipSC } from './GroupTypeLabel.styles';

export const GroupTypeLabel = (props: GroupTypeLabelProps) => {
  const { iconSource, label, tooltipContent, tooltipChild } = props;

  return (
    <GroupTypeLabelSC>
      <span>{iconSource && <GroupIconSource iconSource={iconSource} />}</span>
      {label && (
        <div style={{ display: 'inline-block' }}>{unescape(label)}</div>
      )}
      {tooltipContent && tooltipChild && (
        <TooltipSC title={tooltipContent}>
          <div>{tooltipChild}</div>
        </TooltipSC>
      )}
    </GroupTypeLabelSC>
  );
};
