import React from 'react';
import { Typography } from '@mui/material';
import { getClasses } from './StackedInfo.style';
import SkeletonLoader from '../../../SkeletonLoader/SkeletonLoader';

const StackedInfo = (props) => {
    const { heading, headingClass, subheading, largeHeading, loading } = props;
    const classes = getClasses(largeHeading);

    return (
        <div>
            <Typography noWrap={true} sx={{ ...headingClass, ...classes.heading }} component="div">
                <SkeletonLoader loading={loading} className={classes.skeletonHeading} height="14px" width="35%">
                    {heading || <span>&nbsp;</span>}
                </SkeletonLoader>
            </Typography>
            <Typography noWrap={true} sx={classes.subheading}>
                <SkeletonLoader loading={loading} className={classes.skeletonSubheading} height="14px" width="60%">
                    {subheading || <span>&nbsp;</span>}
                </SkeletonLoader>
            </Typography>
        </div>
    );
};

export default StackedInfo;
