'use strict';

angular.module('app').directive('atCustomCombobox', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            dataItem: '=?atItem',
            dropdown: '=?atDropdown',
            dropdownOptions: '=atDropdownOptions',
            onChange: '=atOnChange',
            dropdownId: '=?atDropdownId',
            dropdownModelField: '=?atDropdownModelField',
            dropdownDisabled: '=?atDropdownDisabled',
            dropdownHidden: '=?atDropdownHidden',
            dataTextField: '=?atDataTextField',
            dataValueField: '=?atDataValueField',
            clearDataFunction: '=atClearDataFunction',
            customFunctionCalls: '=atCustomFunctionCalls',
            triggerSetInputModel: '=?atTriggerSetInputModel'
        },
        template: require('views/widgets/atCustomCombobox.html'),
        controller: atCustomComboboxController
    };
});

atCustomComboboxController.$inject = ['$scope'];

function atCustomComboboxController($scope) {
    $scope.clearButton = $scope.dropdownOptions.clearButton;
    $scope.dropdownOptions.clearButton = false;
    $scope.onChange = $scope.onChange || $scope.dropdownOptions.change;

    if ($scope.dropdownOptions.dataSource) {
        $scope.dropdownOptions.dataSource.bind('requestEnd', function () {
            setInputModel();
        });
    }

    $scope.triggerSetInputModel = function () {
        setInputModel();
    };

    function setInputModel() {
        var data =
            $scope.dropdownOptions.dataSource &&
                typeof $scope.dropdownOptions.dataSource.data === 'function'
                ? $scope.dropdownOptions.dataSource.data()
                : $scope.dropdownOptions.dataSource;
        if (!data || data.length < 1) {
            return;
        }
        var selected = _.find(data, function (option) {
            return (
                option[$scope.dropdownOptions.dataValueField] ===
                $scope.dataItem[$scope.dropdownModelField]
            );
        });
        $scope.inputModel =
            (selected &&
                angular.copy(selected[$scope.dropdownOptions.dataTextField])) ||
            $scope.dropdownOptions.placeholder;
    }

    $scope.setShowDropdown = function (state) {
        if (!state) {
            setInputModel();
        }
        $scope.showDropdown = state && !$scope.dropdownDisabled;
    };

    $scope.customFunctionCall = function (index, params) {
        $scope.setShowDropdown(false);
        if (typeof $scope.customFunctionCalls[index] === 'function') {
            $scope.customFunctionCalls[index](params);
        }
    };

    $scope.$watch('dataItem', setInputModel, true);
}
