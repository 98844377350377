import { react2angular } from 'react2angular';
import { SupportTicketsViewComponent } from '../../_reactivtrak/src/support-tickets/views/SupportTickets.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('supportTickets', react2angular(SupportTicketsViewComponent, []));

}
