'use strict';

angular
    .module('app')
    .directive(
        'alarmDetailsWebhookNotification',
        AlarmDetailsWebhookNotification
    );

function AlarmDetailsWebhookNotification() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsWebhookNotification.html'),
        controller: AlarmDetailsWebhookNotificationCtrl
    };
}

AlarmDetailsWebhookNotificationCtrl.$inject = ['$scope', 'messagesService', 'authorizationService'];

function AlarmDetailsWebhookNotificationCtrl($scope, msg, authorizationService) {
    $scope.paidPlanAlertMessage = msg.get('webhookPaidPlanAvailableOnly');
    $scope.isNotificationsEnabled = authorizationService.hasFeature('isNotificationsEnabled');
    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    $scope.selectedWebhookUrlType = null;

    var webhookUrlProviders = [
        {
            text: 'Slack',
            value: 'slack',
            image: require('images/icon-slack.svg')
        },
        {
            text: 'Microsoft Teams',
            value: 'msteams',
            image: require('images/icon-microsoft-teams.svg')
        },
        {
            text: 'Custom Webhook URL',
            value: 'default',
            icon: ['fa', 'fa-globe']
        }
    ];

    $scope.getWebhookUrlLabel = function(provider) {
        var webhookUrlProvider = _.find(webhookUrlProviders, function(p) {
            return p.value === provider && provider !== 'default';
        });

        return webhookUrlProvider
            ? webhookUrlProvider.text + ' Webhook URL'
            : 'Custom Webhook URL';
    };

    $scope.webhookDestinations = {
        dataSource: webhookUrlProviders,
        dataTextField: 'text',
        dataValueField: 'value',
        valuePrimitive: true,
        optionLabel: 'Select External Destination',
        valueTemplate: require('views/alarmDetails/components/alarmDetailsWebhookProviderTemplate.html'),
        template: require('views/alarmDetails/components/alarmDetailsWebhookProviderTemplate.html'),
        select: function(e) {
            e.sender.element.blur();
        }
    };

    $scope.webhookTitleTooltipTemplate = function() {
        return msg.get('addingWebhook');
    };

    function isComponentValid() {
        // console.log('Webhook Notification Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload() {
        // console.log('Webhook Notification Update Payload', payload);
        return true;
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.webhookNotification,
        isValid: isComponentValid,
        updatePayload: updatePayload
    };

    $scope.options.register($scope.component);
}
