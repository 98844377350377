import { Typography, styled } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

export const LinkIcon = styled(LaunchIcon)`
  vertical-align: text-bottom;
  margin-left: 5px;
  font-size: 18px !important;
`;

export const LinkWithIconTitle = styled(Typography)`
  display: inline-block;
  color: inherit;
`;
