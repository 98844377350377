import React, { useMemo } from 'react';
import { BarLineChart } from '../templates';
import { ITodaysProductivity } from '../../models';

type TodaysProductivityChartProps = {
  data: ITodaysProductivity;
  noResults: boolean;
};

export const TodaysProductivityChart = (
  props: TodaysProductivityChartProps
) => {
  const { data, noResults } = props;

  const chartData = useMemo(() => {
    if (data) {
      return {
        datasets: [
          {
            type: 'line' as const,
            data: data?.staticLineDays?.map((item) => item.goal),
            pointRadius: 0,
            datalabels: {
              display: false
            }
          },
          {
            type: 'bar' as const,
            data: data?.days?.map((item) => item.goal),
            datalabels: {
              display: false
            }
          }
        ],
        labels: noResults
          ? ['F', 'Sa', 'Su', 'M', 'T', 'W', 'Th']
          : data?.days?.map((item) => item.dayLabel)
      };
    }
  }, [data, noResults]);

  return chartData ? (
    <BarLineChart chartData={chartData} additionalFloatingDataText="%" />
  ) : (
    <></>
  );
};
