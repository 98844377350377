import React, { Fragment, useState } from 'react';
import { CardTitle, CardCurrentActivity } from './';
import {
  CardTopCategory,
  CardTopActivity,
  CardTotalTime,
  CardProductiveTime,
  InfoPanelVariant
} from '../Panels';
import { userCardClasses } from './UserCard.style';
import TeamPulseCard from '../TeamPulseCard/TeamPulseCard';
import ReportMode from '../../models/ReportMode';

const UserCard = (props) => {
    const {
        isReport,
        cardId,
        teamPulseDataHandler,
        teamPulseDataControl,
        teamPulseDataPoller,
        timeMode,
        filterMode,
        filterModes
    } = props;

    let cardDataObj = {};
    const [cardData, setCardData] = useState(
        Object.assign(cardDataObj, props, teamPulseDataHandler.getCardData(cardId))
    );

    const isActive = cardData.activeStatus !== 'inactive';

    const handleCardUpdate = ({ data }) => {
        // Removed due to edge compatibility
        // setCardData({ ...props, ...data });
        cardDataObj = {};
        setCardData(Object.assign(cardDataObj, props, data));
    };
    teamPulseDataHandler.subscribeToRealtimeUpdate({
        id: cardId,
        callback: handleCardUpdate
    });

    return (
        <TeamPulseCard isReport={isReport} active={isActive}>
            <CardTitle
                teamPulseDataPoller={teamPulseDataPoller}
                teamPulseDataControl={teamPulseDataControl}
                rootClass={userCardClasses.titleTopMargins}
                contentClass={userCardClasses.sidePaddings}
                {...cardData}
                timeMode={timeMode}
                type="user"
                reportMode={ReportMode.Users}
            />
            <hr style={userCardClasses.hr} />
            <CardCurrentActivity
                rootClass={userCardClasses.topPaddings}
                contentClass={userCardClasses.sidePaddings}
                {...cardData}
            />
            {isReport && (
                <Fragment>
                    <CardProductiveTime
                        {...cardData}
                        timeMode={timeMode}
                        filterMode={filterMode}
                        filterModes={filterModes}
                        type="user"
                        reportMode={ReportMode.Users}
                    />
                    <CardTotalTime
                        rootClass={userCardClasses.topPaddings}
                        contentClass={userCardClasses.sidePaddings}
                        {...cardData}
                        timeMode={timeMode}
                        filterMode={filterMode}
                        filterModes={filterModes}
                        reportMode={ReportMode.Users}
                    />
                    <hr style={userCardClasses.hr} />
                    <CardTopCategory
                        rootClass={userCardClasses.topPaddings}
                        contentClass={userCardClasses.sidePaddings}
                        {...cardData}
                        type="user"
                        variant={InfoPanelVariant.Stacked}
                    />
                    <hr style={userCardClasses.hr} />
                    <CardTopActivity
                        rootClass={userCardClasses.topPaddings}
                        contentClass={userCardClasses.sidePaddings}
                        teamPulseDataPoller={teamPulseDataPoller}
                        teamPulseDataControl={teamPulseDataControl}
                        {...cardData}
                        type="user"
                        variant={InfoPanelVariant.Stacked}
                    />
                </Fragment>
            )}
        </TeamPulseCard>
    );
};

export default UserCard;
