import React from 'react';
import { Box, Typography } from '@mui/material';
import { ITabContentProps } from '../../models/IRandomTypes';

export const TabContent = (props: ITabContentProps) => {
  const { children, value, index, ...other } = props;

  const renderTreeView: boolean = value === index && index !== 6;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      <Box
        sx={{
          height: '300px',
          width: '700px',
          overflow: 'auto',
          overflowWrap: 'break-word'
        }}
      >
        {renderTreeView ? (
          <Typography component="span">{children}</Typography>
        ) : (
          children
        )}
      </Box>
    </div>
  );
};
