import React, { useCallback, useEffect, useState } from 'react';
import { localStorageItems } from '../../common/constants';
import { IComputerDetail } from '../models';
import {
  ComputersProvider,
  UseComputersContext,
  UsersProvider
} from '../services';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { formatComputerComplexName } from '../utils';
import {
  getDateTimeFormat,
  getParsedLocalStorageItem,
  formatDate
} from '../../common/helpers';
import { IAccountSettings } from '../../common/models';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DownloadProvider } from '../../common/services';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import authHelper from '../../common/helpers/authorization';
import { RoleAccessKeys } from '../../common/enums';
import Link from '../../common/components/Link';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  GroupContainer,
  GroupHeaderTitleContainer,
  OSVersionContainer,
  ParamContainer,
  ViewHeaderNameContainer
} from '../styles';

const formatStorage = (data) => {
  if (data > 0 && data <= 1) {
    return '0.001';
  }
  const gb = data / 1000;
  const precision = gb > 1 ? 2 : 3;
  return gb.toFixed(precision);
};

export function ComputerView() {
  const history = useUIRouterHistory();

  const { computersService } = UseComputersContext();
  const { screenshotsAllowed = false } = getPrivacySettings();
  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );
  const [computerDetail, setComputerDetail] = useState<IComputerDetail>({
    videoStorage: 0,
    screenShotStorage: 0,
    logCount: 0,
    screenShotCount: 0,
    videoCount: 0,
    windowsDomain: '',
    computerName: '',
    alias: '',
    agentVersion: ''
  });

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const dateTimeFormat = getDateTimeFormat(accountSettings?.dateFormat);
  const { id } = useUIRouterParams<any>();

  const {
    videoStorage,
    screenShotStorage,
    logCount,
    screenShotCount,
    videoCount,
    windowsDomain,
    computerName,
    alias,
    agentVersion,
    firstLog,
    lastLog,
    os
  } = computerDetail;

  useEffect(() => {
    const fetchComputerDetail = async () => {
      try {
        const computerDetailResponse =
          await computersService.fetchComputerDetail(id);
        setComputerDetail(computerDetailResponse);
      } catch (error) {
        //what should happen here?
      }
    };

    fetchComputerDetail();
  }, [computersService, id]);

  const handleClickReturn = useCallback(() => {
    history.push('app.settings.computeragents');
  }, [history]);

  return (
    <Box>
      <ViewHeaderNameContainer>
        <Button onClick={handleClickReturn}>
          <KeyboardBackspaceIcon />
        </Button>
        {formatComputerComplexName(windowsDomain, computerName, alias)}
      </ViewHeaderNameContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>Agent Info</GroupHeaderTitleContainer>
        <ParamContainer>Primary Domain: {windowsDomain}</ParamContainer>
        <ParamContainer>Computer: {computerName}</ParamContainer>
        {alias ? <ParamContainer>Alias: {alias}</ParamContainer> : null}
        <OSVersionContainer>
          {getOSIcon(os)} <span>{agentVersion}</span>
        </OSVersionContainer>
      </GroupContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>Activity Details</GroupHeaderTitleContainer>
        <ParamContainer>
          First Log Record: {formatDate(firstLog, dateTimeFormat)}
        </ParamContainer>
        <ParamContainer>
          Last Log Record: {formatDate(lastLog, dateTimeFormat)}
        </ParamContainer>
        <ParamContainer>
          Total Storage:{' '}
          {formatStorage(
            (logCount * 120) / 1000 / 1000 + videoStorage + screenShotStorage
          )}{' '}
          GB
        </ParamContainer>
        {/* lcount, sstorage , vstorage */}
        <ParamContainer># of Log Records: {logCount}</ParamContainer>
        {screenshotsAllowed && hasAlarmsScreenshotsReportsRoleAccess && (
          <ParamContainer># of Screen Shots: {screenShotCount}</ParamContainer>
        )}
        {videoCount > 0 && (
          <ParamContainer># of Videos: {videoCount}</ParamContainer>
        )}
        <Link
          to={'app.reports.activitylog'}
          params={{
            computer: alias || computerName,
            dateFilter: 'today',
            userFilter: 'allusers'
          }}
        >
          View Activity Log
        </Link>
      </GroupContainer>
    </Box>
  );
}

export const ComputerViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <ComputersProvider>
          <ComputerView />
        </ComputersProvider>
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
