import * as definedRoutes from './routeDefinitions';
import { IRoute } from '../../models/IRoute';

const routes = {
  ...definedRoutes
};

export const getRoutes = (): Record<string, IRoute> => routes;
export const getRouteDefinitions = (): IRoute[] => Object.values(definedRoutes);
export const getRouteByName = (name: string) =>
  Object.values(routes).find((route) => route.name === name);
export const getRouteByUrl = (url: string) =>
  Object.values(routes).find((route) =>
    url?.split('?')[0]?.endsWith(route.stateDefinition?.url?.split('?')[0])
  );
