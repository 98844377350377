import { useCallback, useState } from 'react';

import { IIntegration } from '../models/IIntegrationTypes';
import { IIntegrationState } from '../models/IIntegrationState';
import { NotificationType } from '../../common/enums';
import { mapIntegrations } from '../utils/integration.utils';
import {
  fetchConfiguration,
  fetchDetail,
  fetchPlan,
  fetchState,
  fetchTraySolution,
  fetchTrayUser
} from '../utils/integrationInstance.utils';

export const useIntegrationState = (): IIntegrationState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [integrationsNotification, setIntegrationsNotification] =
    useState<NotificationType>();
  const [integrationData, setIntegrationData] =
    useState<Array<IIntegration>>(null);

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const trayUser = await fetchTrayUser();
      const [
        responseDetails,
        responsePlans,
        responseStates,
        responseConfigs,
        solutions
      ] = await Promise.all([
        fetchDetail(),
        fetchPlan(),
        fetchState(),
        fetchConfiguration(),
        fetchTraySolution(trayUser.userId)
      ]);
      const integrations = mapIntegrations(
        responseDetails,
        responsePlans,
        responseStates,
        responseConfigs,
        solutions
      );
      setIntegrationData(integrations);
    } catch (error) {
      console.error(`ActivTrak Error: Loading Integrations: ${error}`, error);
      setIntegrationsNotification({
        msg: 'Unable to load Integrations.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    integrationData,
    init,
    isLoading,
    integrationsNotification,
    setIntegrationsNotification
  };
};
