'use strict';

var plg = (function (init, _initPopup, _show) {
    return kendo.ui.Tooltip.extend({
        options: {
            name: 'CustomTooltip'
        },
        init: function (_element, _options) {
            init.call(this, _element, _options);
        },
        _initPopup: function() {
            _initPopup.call(this);
            this.popup.element.addClass('zero_opacity');

            var popup = this.popup;
            this.bind('show', function () {
                popup.element.addClass('zero_opacity');

                if (this.options.beforeShow) {
                    this.options.beforeShow(popup);
                    this.popup.element.removeClass('zero_opacity');
                }
            });
        },
        _show: function(_target) {
            if (this.popup) {
                this.popup.element.addClass('zero_opacity');
            }
            _show.call(this, _target);
        }
    });

})(kendo.ui.Tooltip.fn.init, kendo.ui.Tooltip.fn._initPopup, kendo.ui.Tooltip.fn._show);
kendo.ui.plugin(plg);
