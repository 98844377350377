import { apiService } from '../../common/helpers';
import { IRealTimeSettingsService, IRealTimeSettingsDto } from '../models';
import { getDefaultConfig } from '../../common/config';
import { HttpResponse } from '../../common/models';

export const RealTimeSettingsService = (): IRealTimeSettingsService => {
  return {
    postRealTimeSettings: async (payload: IRealTimeSettingsDto) => {
      return apiService.post<HttpResponse<object>>('/api/realtime/settings', {
        ...getDefaultConfig(),
        data: payload
      });
    }
  };
};
