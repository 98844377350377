'use strict';

angular.module('app').service('msaService', MsaService);

MsaService.$inject = ['$http', 'customUibModal', 'loginService', 'envConfig'];

function MsaService($http, customUibModal, loginService, envConfig) {
    var service = this;

    this.setMSA = function () {
        return $http.post(envConfig.apiUrl() + '/api/setmsa');
    };

    this.showAcceptanceModal = function () {
        var modal = customUibModal.open({
            animation: false,
            template: require('views/modals/msaAcceptanceModal.html'),
            controller: 'msaAcceptanceModalController',
            windowClass: 'centered-modal',
            keyboard: false,
            backdrop: 'static'
        });

        modal.result.then(
            function () {
                service.setMSA();
            },
            function () {
                loginService.logout({
                    logoutReason: 'You must accept the MSA in order to use your account.'
                });
            }
        );
    };
}
