'use strict';

angular.module('app').service('integrationsService', IntegrationsService);

IntegrationsService.$inject = ['$http','$q', 'envConfig'];

function IntegrationsService($http,$q, envConfig) {
    this.getSolutionTag = function() {
        return $http.get(`${envConfig.adminApiUrl()}/integrations/v1/solutiontag`).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.getUserId = function() {
        return $http.get(`${envConfig.adminApiUrl()}/integrations/v1/userid`).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.getIntegrations = function() {
        return $http.get(`${envConfig.adminApiUrl()}/integrations/v1/records`, {
            ignoreLoadingBar: false
        });
    };

    this.getSolutions = function(userId) {
        return $http.get(`${envConfig.adminApiUrl()}/integrations/v1/solutions/${userId}`, {
            ignoreLoadingBar: false
        });
    };

    this.getConfigWizard = function(trayUserId,solutionId, instanceName, solutionTag, configId, solutionTitle){
        var requestData = {TrayUserId: trayUserId, SolutionId:solutionId, InstanceName: instanceName, Tags: [solutionTag], Configs:[{externalID: configId}],  SolutionTitle: solutionTitle };
        return $http({
            url: `${envConfig.adminApiUrl()}/integrations/v1/instance`,
            method: 'POST',
            dataType: "json",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(requestData)
        }).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.disableSolution = function(instanceId, integrationCode) {
        return $http({
            url: `${envConfig.adminApiUrl()}/integrations/v1/instance/${instanceId}/disable/${integrationCode}/`,
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            }
        }).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.runSolution = function(instanceId, integrationCode) {
        return $http({
            url: `${envConfig.adminApiUrl()}/integrations/v1/instance/${instanceId}/run/${integrationCode}/`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.enableSolution = function(instanceId, integrationCode) {
        return $http({
            url: `${envConfig.adminApiUrl()}/integrations/v1/instance/${instanceId}/enable/${integrationCode}/`,
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            }
        }).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.deleteSolution = function(instanceId, integrationCode) {
        return $http({
            url:  `${envConfig.adminApiUrl()}/integrations/v1/instance/${instanceId}/${integrationCode}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            }
        }).then(
            function (result) {
                return result.data;
            },
            function (result) {
                return $q.reject(result);
            }
        );
    };

    this.getSummary = function() {
        return $http.get(`${envConfig.adminApiUrl()}/integrations/v1/summary`, {
            ignoreLoadingBar: false
        });
    };
}
