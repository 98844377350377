import { IRoute } from '../../../../../../models/IRoute';

export const appDevelopmentPlayground: IRoute = {
  name: 'app.development.playground',
  stateDefinition: {
    url: '/playground',
    abstract: true,
    template: '<developer-playground></developer-playground>'
  }
};

export default appDevelopmentPlayground;
