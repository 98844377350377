import { IRoute } from '../../../../models/IRoute';

export const install: IRoute = {
  name: 'install',
  stateDefinition: {
    url: '/chromebook/install?token&email',
    controller: 'ChromeAgentInstallCtrl'
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default install;
