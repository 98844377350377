import React, { useEffect, useState } from 'react';
import { autoUpdateSettingsService } from '../services/AutoUpdateSettingsService';
import { useNotifications } from '../../common/services/Notifications';
import { useAutoUpdateSettingsState } from '../hooks';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import { Button, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { useAutoUpdateSettingsColumns } from '../utils';
import { AutoUpdateSettingsModal } from './AutoUpdateSettingsModal';
import Select from '@mui/material/Select';

type AutoUpdateSettingsCardProps = {
  isSuperAdmin: boolean;
};

export const AutoUpdateSettingsCard = (props: AutoUpdateSettingsCardProps) => {
  const { isSuperAdmin } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const notificationService = useNotifications();

  const service = autoUpdateSettingsService();
  const autoSettingsState = useAutoUpdateSettingsState({
    service,
    notificationService
  });

  const {
    agentVersions,
    winVersions,
    newWinVersion,
    winWarning,
    macVersions,
    newMacVersion,
    macWarning,
    filter,
    isLoading,
    init,
    handleDaysFilter,
    handleAgentUpdate,
    getAllTargets,
    resetData,
    handleVersionChange,
    validateAgentUpdate,
    winError,
    macError
  } = autoSettingsState;

  const columns = useAutoUpdateSettingsColumns();

  useEffect(() => {
    init();
  }, [init]);

  const onDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const days = e.target?.value;
    handleDaysFilter(days);
  };

  return (
    <>
      <ConfigurationCardWrapper title={'Auto-Update Settings'}>
        <Typography sx={{ marginBottom: '10px' }}>
          View which agent version your devices are targeting and how many are
          currently updated to this version.
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <Select
                size="small"
                value={filter}
                onChange={onDaysChange}
                sx={{ '& .MuiInputBase-root': { height: '32px' } }}
              >
                <MenuItem value="0">All Computers</MenuItem>
                <MenuItem value="7">Active Last 7 Days</MenuItem>
                <MenuItem value="30">Active Last 30 Days</MenuItem>
                <MenuItem value="90">Active Last 90 Days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CustomGrid
              height={'65vh'}
              isLoading={isLoading}
              data={agentVersions}
              columns={columns}
            />
          </Grid>
          {isSuperAdmin && (
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                Edit Settings
              </Button>
            </Grid>
          )}
        </Grid>
      </ConfigurationCardWrapper>
      <AutoUpdateSettingsModal
        agentVersions={agentVersions}
        winVersions={winVersions}
        macVersions={macVersions}
        isOpen={isOpen}
        onLoad={getAllTargets}
        onClose={() => setIsOpen(false)}
        onChange={handleVersionChange}
        onSubmit={handleAgentUpdate}
        resetData={resetData}
        newWinVersion={newWinVersion}
        winWarning={winWarning}
        newMacVersion={newMacVersion}
        macWarning={macWarning}
        validateAgentUpdate={validateAgentUpdate}
        winError={winError}
        macError={macError}
      />
    </>
  );
};
