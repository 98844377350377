import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsComputerAlias: IRoute = {
  name: 'app.settings.computerAlias',
  stateDefinition: {
    url: '/settings/computerAlias?time&type',
    template: '<settings-computer-aliases></settings-computer-aliases>',
    data: { pageTitle: 'Computer Aliases' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsComputerAlias;
