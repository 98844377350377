import React from 'react';
import {
  HeaderButtonSC,
  HeaderButtonSecondarySC
} from '../styles/Header.styles';

export const HeaderButton = (props) => {
  const { route, children, color, sx, id } = props;
  return color === 'secondary' ? (
    <HeaderButtonSecondarySC
      id={id}
      variant="contained"
      href={route}
      sx={sx}
      size="large"
      onClick={props.onClick}
    >
      {children}
    </HeaderButtonSecondarySC>
  ) : (
    <HeaderButtonSC
      id={id}
      variant="contained"
      href={route}
      sx={sx}
      size="large"
      onClick={props.onClick}
    >
      {children}
    </HeaderButtonSC>
  );
};
