import { GraphColors } from '../../common/constants';

export const processPieData = (pieData) => {
  const labels = [];
  const data = [];
  const bkgrdColors = [];

  pieData.forEach((item, i) => {
    labels.push(item.description);
    data.push(item.seconds);
    const currentColor = GraphColors[i];
    bkgrdColors.push(currentColor);
  });

  const pieConfig = {
    labels: labels,
    datasets: [
      {
        label: 'Top Applications',
        data: data,
        backgroundColor: bkgrdColors,
        borderWidth: 0,
        hoverOffset: 4
      }
    ]
  };
  return pieConfig;
};
