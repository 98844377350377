'use strict';

angular.module('app')
    .service('timelineService', TimelineService);

TimelineService.$inject = ['templateServiceFunctions'];

function TimelineService(templateServiceFunctions) {

    this.getIntervalUnit = function (fromDateStr, toDateStr) {
        var fromDate = moment(fromDateStr);
        var toDate = moment(toDateStr);
        var rangeInDays = toDate.diff(fromDate, 'days');
        if (rangeInDays > 60) {
            return 'Week';
        } else if (rangeInDays > 7) {
            return 'Day';
        } else {
            return 'Hour';
        }
    };

    this.getMaxDuration = function (data) {
        var max = 0;
        angular.forEach(data, function (item) {
            if (item.duration > max) {
                max = item.duration;
            }
        });
        return max;
    };

    this.assignLevels = function (data, max) {
        var level1Max = max / 4;
        angular.forEach(data, function (item) {
            item.level = Math.ceil(item.duration / level1Max);
        });
    };

    this.calculateUserTotals = function (data) {
        var userTotals = {};
        angular.forEach(data, function (item) {
            if (userTotals['u' + item.user]) {
                userTotals['u' + item.user] += item.duration;
            } else {
                userTotals['u' + item.user] = item.duration;
            }
        });
        angular.forEach(data, function (item) {
            item.user += ' (' + templateServiceFunctions.friendlyViewTimeFormat(userTotals['u' + item.user]) + ')';
        });
    };
}
