import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsClassification: IRoute = {
  name: 'app.settings.classification',
  stateDefinition: {
    url: '/settings/classification',
    abstract: true,
    template: '<settings-classification></settings-classification>'
  }
};

export default appSettingsClassification;
