import InsightsPromoService from '../../../../_app/insights/insightsPromoService';
import { INSIGHTS_UPGRADE_PLAN_LABEL } from '../../../../_reactivtrak/src/common/constants/plans';

angular.module('app').controller('impactPromoCtrl', ImpactPromoCtrl);

ImpactPromoCtrl.$inject = [
    '$scope',
    'accountApiHelper',
    'authorizationService',
    'envService',
    'envConfig',
    'accountSettings',
    'notificationService',
    'utmService'
];

function ImpactPromoCtrl(
    $scope,
    accountApiHelper,
    authorizationService,
    envService,
    envConfig,
    accountSettings,
    notificationService,
    utmService
) {
    var insightsPromoService = new InsightsPromoService(
        envService.getMarketoConfig(),
        envConfig,
        accountSettings,
        accountApiHelper,
        notificationService,
        utmService,
        authorizationService
    );

    $scope.requestDemo = function () {
        insightsPromoService.requestFormImpact('demoRequestFormId', 'Demo');
    };

    $scope.requestTrial = function () {
        insightsPromoService.requestFormImpact('trialRequestFormId', 'Trial');
    };

    $scope.planLabel = INSIGHTS_UPGRADE_PLAN_LABEL;
}
