import { useCallback, useState } from 'react';
import { ISubheaderHoursDto, IVirtualTeamVisibilityState } from '../models';
import { ApiRoutes } from '../constants';
import { buildReportsApiRequest } from '../utils';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useVirtualTeamsVisibilityState =
  (): IVirtualTeamVisibilityState => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [virtualTeamHours, setVirtualTeamHours] =
      useState<ISubheaderHoursDto>();
    const [isTileError, setIsTileError] = useState<boolean>(false);

    const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
      setIsLoading(true);
      setIsTileError(false);

      try {
        const response = await buildReportsApiRequest<ISubheaderHoursDto>({
          path: ApiRoutes.reports.fetchVirtualOfficeHours,
          params: { groupId: reportFilters.groupId[0] }
        });
        setVirtualTeamHours(response);
      } catch (error) {
        setIsTileError(true);

        console.error(
          'ActivTrak Error: Unable to load virtual hours visibility',
          error
        );
      } finally {
        setIsLoading(false);
      }
    }, []);

    return {
      isLoading,
      isTileError,
      virtualTeamHours,
      init
    };
  };
