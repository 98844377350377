import { userServiceFunctions } from '../../../_app/serviceFunctions/userServiceFunctions';
import _ from 'lodash';

const getUserRoles = () => {
  const claims = userServiceFunctions.getUserTokenClaims();
  if (!claims || typeof claims.forEach !== 'function') {
    console.error(`ActivTrak Error: Cannot generate user roles due to missing claims. ${claims}`);
    return;
  }

  let roles = [];
  claims.forEach((claim) => {
    if (_.includes(claim.Type, 'claims/role')) {
      roles.push(claim.Value);
    }
  });

  return roles;
};

export const hasRole = (role) => {
  const userRoles = getUserRoles();
  return _.includes(userRoles, role);
};
