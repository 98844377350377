import { styled } from '@mui/material/styles';
import { TextField, Autocomplete } from '@mui/material';

export const AutocompleteSearchBarContainer = styled('div')`
  display: flex;
  width: 100%;
`;

export const AutocompleteSC = styled(Autocomplete)`
  width: 100%;
`;

export const TextFieldSC = styled(TextField)`
  & fieldset {
    border: 0px;
  }

  & .Mui-focused {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
`;
