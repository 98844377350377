'use strict';

angular.module('app').directive('classificationsWidget', ClassificationsWidget);

function ClassificationsWidget() {
    return {
        restrict: 'E',
        template: require('views/widgets/dashboard/classificationsWidget.html')
    };
}
