import theme from '../../theme/theme';

export const addCustomerClasses = {
  dialog: {
    '& .MuiDialog-paper': {
      alignItems: 'center',
      borderRadius: 0,
      paddingTop: 4,
      '& > div': {
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '50%'
        }
      },
      '& .btn-cancel': {
        borderRadius: '100px',
        color: theme.palette.text.disabled,
        fontWeight: 600,
        textTransform: 'unset'
      },
      '& .btn-close': {
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing()
      },
      '& .btn-submit': {
        color: theme.palette.common.white,
        borderRadius: '100px',
        marginBottom: theme.spacing(),
        textTransform: 'unset',
        '&.disabled': {
          cursor: 'not-allowed',
          backgroundColor: theme.palette.primary.light
        }
      },
      '& .MuiDialogActions-root': {
        flexDirection: 'column',
        padding: '8px 24px 24px',
        '&> :not(:first-of-type)': {
          margin: 0
        }
      },
      '& .MuiTypography-h6': {
        fontSize: '1.25rem',
        color: theme.palette.text.disabled,
        textAlign: 'center',
        fontWeight: 600
      }
    }
  },
  error: {
    height: theme.spacing(3)
  },
  multipleTextField: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiFormControl-root:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  },
  loading: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(20),
    justifyContent: 'space-evenly',
    margin: '0 auto',
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  privacy: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiButtonBase-root': {
      padding: 0,
      margin: '0 8px 0 -4px'
    },
    '& .MuiTypography-body2': {
      color: theme.palette.text.disabled
    }
  },
  signUpError: {
    margin: '10px 0',
    marginTop: -3
  },
  successLink: {
    '&.MuiLink-root:hover': {
      color: theme.palette.common.white
    }
  },
  consentLink: {
    fontWeight: 400
  },
  passwordError: {
    color: theme.palette.error.main,
    '& span': {
      display: 'block',
      marginBottom: '5px',
      '&:last-child': {
        marginBottom: '10px'
      }
    }
  }
};
