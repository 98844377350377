'use strict';

angular.module('app').controller('SettingsNewGroupsCtrl', SettingsNewGroupsCtrl);

SettingsNewGroupsCtrl.$inject = ['$scope', '$timeout'];

function SettingsNewGroupsCtrl($scope, $timeout) {

    $('#user-management-app').removeClass('hide');
    $timeout(function () {
        $('#user-management-app').appendTo('#user-management-app-wrapper');
    });

    $scope.$on('$destroy', function () {
        $('#user-management-app').appendTo('#user-management-app-holder');
        $('#user-management-app').addClass('hide');
    });
}
