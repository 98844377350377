import React from 'react';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

// TODO: Temp... there willl be more logic in here...
export const UIRouter = (props) => {
  return (<>{props.children}</>);
};
// TODO: Temp... there willl be more logic in here...
export const UIRoute = (props) => {

  const { state } = useUIRouterHistory();
  const active = state?.name === props.route;
  return (
    <>
      {active && props.children}
    </>
  );
};
