'use strict';

angular.module('app')
    .controller('showAllMessageModalController', ShowAllMessageModalController);

ShowAllMessageModalController.$inject = ['$scope', '$uibModalInstance'];

function ShowAllMessageModalController($scope, $uibModalInstance) {
    $scope.doNotShowAgain = false;

    $scope.acceptClicked = function () {
        $uibModalInstance.close($scope.doNotShowAgain);
    };

    $scope.cancelClicked = function () {
        $uibModalInstance.dismiss();
    };
}
