import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import lodash from 'lodash';
import ssRedactionSettingsModalControllerTemplate from 'views/modals/ssRedactionSettingsModal.html?raw';

angular.module('app').controller('ScreenshotsRedactionCtrl', ScreenshotsRedactionCtrl);

ScreenshotsRedactionCtrl.$inject = [
    '$scope',
    '$state',
    'messagesService',
    'screenshotsRedactionService',
    'accountSettings',
    'accountApiHelper',
    'chartService',
    '$window',
    'constantsService',
    'redactionTypesService',
    'customUibModal',
    '$timeout',
    'authorizationService',
    'notificationService',
    'atHelperFunctions'
];

function ScreenshotsRedactionCtrl(
    $scope,
    $state,
    msg,
    service,
    accountSettings,
    accountApiHelper,
    chartService,
    $window,
    constant,
    redactionTypesService,
    customUibModal,
    $timeout,
    authorizationService,
    notificationService,
    atHelperFunctions
) {
    $scope.isDirty = false;
    $scope.annualStorageLimitTooltip = msg.get('annualDLPStorageLimitTooltip');
    var font = constant.get('fontString', 'body', 13);
    var currentMonth = parseInt(moment().format('MM'));
    var currentYear = parseInt(moment().format('YYYY'));
    var redactionSettings;
    var canBeSaved = false;

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(
            levels,
            'app.screenshots.redaction',
            BundleFlag.ScreenshotRedaction
        );
    };

    $scope.goToUpgrade = function () {
        $state.go('app.account.upgrade');
    };

    var infoTypeRowTemplate = function (item) {
        if (item.isRegionRow) {
            return '<div>{{dataItem.region}}</div>';
        }
        var tooltipWidth = Math.min(420, $window.innerWidth - 20);
        item.tooltip =
            '<div style="max-width: ' +
            tooltipWidth +
            'px;">' +
            (item.displayName ? '<strong>' + item.displayName + '</strong><br/>' : '') +
            (item.description ? item.description + '<br/>(' + item.name + ')' : item.name) +
            '</div>';
        return (
            '<div><div class="inline at-row-item at-ellipsis" at-tooltip tooltip-content="dataItem.tooltip" tooltip-placement="top" class="type-row"' +
            "\" ng-style=\"{'max-width': (columnWidth - 70) + 'px'}\">{{dataItem.displayName}}</div></div>"
        );
    };

    var monthKey = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    var redactionDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                canBeSaved = false;
                service.getPreference().success(function (data) {
                    $scope.preference = data.redactionEnabled && accountSettings.redactionFeatureFlag;
                    canBeSaved = accountSettings.redactionFeatureFlag;
                    redactionSettings = data;

                    var sortedData = redactionTypesService
                        .getInfoTypeDefinitions(data.redactionItem.names)
                        .sortByRegion()
                        .injectRegionRows();

                    options.success(sortedData.infoTypes);
                });
            }
        }
    });

    var findMonth = function (data, loopIndex, monthIndex) {
        return lodash.find(data, function (r) {
            return r.month === monthIndex && r.year === (loopIndex > 0 ? currentYear : currentYear - 1);
        });
    };

    var maxUsage = 0;

    var formatData = function (data) {
        data.forEach(function (item) {
            var timestamp = moment(angular.copy(item.month));
            item.month = parseInt(timestamp.format('MM'));
            item.year = parseInt(timestamp.format('YYYY'));
            maxUsage = Math.max(item.usage, maxUsage);
        });

        var formattedData = [];

        data.sort(function (a, b) {
            var ret = a.year - b.year;
            if (ret !== 0) {
                return ret;
            }
            return a.month - b.month;
        });

        var i;
        var k;

        for (i = currentMonth - 11, k = 0; i < currentMonth + 1; i++, k++) {
            var j = i;

            if (i <= 0) {
                j = 12 + i;
            }

            var d = findMonth(data, i, j);

            if (!d) {
                d = {
                    month: j,
                    year: i > 0 ? currentYear : currentYear - 1,
                    usage: 0
                };
            }

            d.name = monthKey[j - 1];
            d.slot = k;
            formattedData.push(d);
        }

        return formattedData;
    };

    var getDateValue = function (data) {
        var resetTime = moment(angular.copy($scope.redactionInfo.redactionRenewDate));
        var month = resetTime.format('MMMM');
        var year = parseInt(resetTime.format('YYYY'));
        var slotItem = lodash.find(data, function (d) {
            return d.year === year && d.name === month;
        });

        if (slotItem) {
            var totalDays = resetTime.daysInMonth();
            var resetDay = parseInt(resetTime.format('D'));
            var percent = (resetDay / totalDays) * 0.4;
            return slotItem.slot + percent - 0.2;
        }

        return -1;
    };

    var chartData;
    $scope.dlpUsageChartConfig = {
        getData: function () {
            if (chartData === undefined) {
                chartData = service.getStats(); // createPromise(fakeData);
            }
            return chartData;
        },
        checkData: function (data) {
            return !!data;
        },
        buildChart: function (result) {
            var data = formatData(result);
            return chartService.buildColumnChart({
                seriesDefaults: {
                    type: 'line',
                    opacity: 1
                },
                series: [
                    {
                        field: 'usage',
                        categoryField: 'name',
                        color: constant.get('color', 'COLOR-PRIMARY'),
                        tooltip: {
                            visible: true,
                            template: function (item) {
                                return atHelperFunctions.formatBytes(item.value);
                            },
                            background: constant.get('color', 'COLOR-INFO'),
                            color: '#f0f0f0'
                        }
                    }
                ],
                labelTooltipTemplate: function (item) {
                    return atHelperFunctions.formatBytes(item.value, null, maxUsage);
                },
                data: data,
                valueAxisName: 'valueAxis',
                valueAxisMax: maxUsage * 1.1,
                categoryAxisName: 'categoryAxis',
                categoryAxisNotes: {
                    line: {
                        width: 3,
                        length: 327,
                        color: '#48B0F7'
                    },
                    icon: {
                        border: {
                            width: 0
                        }
                    },
                    data: [
                        {
                            value: getDateValue(data)
                        }
                    ]
                },
                colorScheme: 'dlp',
                legendVisible: false,
                font: font,
                rotation: -45
            });
        },
        preventResizeProcessing: false
    };

    $scope.redactedItemsOptions = {
        autoBind: false,
        dataSource: redactionDataSource,
        columns: [
            {
                field: 'displayName',
                template: kendo.template(infoTypeRowTemplate),
                attributes: {
                    'ng-class': "{ 'region-row': dataItem.isRegionRow, 'type-row': !dataItem.isRegionRow }"
                }
            }
        ],
        noRecords: {
            template:
                '<div class="m-t-10"><strong>No Info Types Selected</strong><br/>Info types can be selected in the screenshot redaction settings.</div>'
        },
        scrollable: true
    };

    $scope.openSettingsModal = function () {
        if (!canBeSaved) {
            var upgradeMessage = authorizationService.hasRouteByName('upgrade')
                ? '<a href="#/app/account/upgrade"><strong> Click here to upgrade.</strong></a>'
                : '';
            notificationService.showNotification(
                'Screenshot Redaction is available as an Add-On to the Advanced Premium Plan.' + upgradeMessage,
                'info',
                0,
                true
            );
            return;
        }

        var modal = customUibModal.open({
            animation: false,
            template: ssRedactionSettingsModalControllerTemplate,
            controller: 'ssRedactionSettingsModalController',
            windowClass: 'app-modal-1200 centered-modal',
            resolve: {
                redactionSettings: redactionSettings
            }
        });

        modal.result.then(function () {
            accountApiHelper.loadSettings();
            accountApiHelper.getRedactionInfo();
            redactionDataSource.read();
        });
    };

    $scope.faqList = [
        {
            question: 'How is this billed?',
            answer: 'Add-on features, including Screenshot Redaction, are only available to users that have Advanced annual plans. The cost of this add-on is in addition to the cost of your plan. Screenshot redaction costs $9/month per 1 GB of storage.'
        },
        {
            question: 'Does screenshot redaction include HIPAA and COPPA compliance?',
            answer: 'Although ActivTrak is already HIPAA and COPPA compliant, screenshot redaction helps make sure the sensitive information related to these regulations is protected.'
        },
        {
            question: 'What info is redacted?',
            answer:
                '<ul><li>American Bankers CUSIP ID</li>' +
                '<li>Credit Card Numbers</li>' +
                '<li>Date of Birth</li>' +
                '<li>Email Address</li>' +
                '<li>IBAN Code</li>' +
                '<li>IP Address</li>' +
                '<li>Phone Number</li>' +
                '<li>SWIFT Code</li>' +
                '<li>US Adoption Taxpayer Identification Number</li>' +
                '<li>US Bank Routing MICR</li>' +
                '<li>US DEA Number</li>' +
                "<li>US Driver's License Number</li>" +
                '<li>US Employer Identification Number</li>' +
                '<li>US Healthcare NPI</li>' +
                '<li>US Individual Taxpayer Identification Number</li>' +
                '<li>US Passport</li>' +
                '<li>US Preparer Taxpayer Identification Number</li>' +
                '<li>US Social Security Number</li>' +
                '<li>And many others, see this <a href="https://cloud.google.com/dlp/docs/infotypes-reference" target="_blank">link</a> for a comprehensive list of InfoType Detectors used by Google’s Cloud Data Loss Prevention</li></ul>'
        },
        {
            question: 'Where are the screenshots stored?',
            answer: 'The screenshots are stored on the monitored system until they are sent to your data center of choice.'
        },
        {
            question: 'Are screenshots shown before the information is redacted?',
            answer: 'No, the original screenshot with the information showing is never stored in our servers.'
        },
        {
            question: 'How quickly does this happen?',
            answer: 'As soon as the screenshot is taken, the AI detects and redacts sensitive information.'
        },
        {
            question: 'Can redaction be purchased on a per-user level?',
            answer: 'No, screenshot redaction can only be purchased for the entire account.'
        }
    ];

    $scope.pointList = [
        {
            url: 'icon-1_circle_70x70.svg',
            text: "Keeping your employees' personally identifiable information (PII) safe is a top priority. Screenshot redaction helps you do just that. Screenshot redaction employs AI to identify sensitive data in screenshots and redact that information before anyone has the chance to see it/ This feature can be a crucial piece in hardening your team's security and making sure your employees' PII is safe."
        },
        {
            url: 'icon-2_circle_70x70.svg.svg',
            text: 'ActivTrak scans screenshots for sensitive material such as credit card numbers, routing numbers, and social security numbers. The original screenshot is never stored so the information cannot be compromised. Assure your employees their PII is safe with screenshot redaction.'
        }
    ];

    accountApiHelper.getRedactionInfo();

    $scope.$on('RedactionInfoSet', function (e, info) {
        $scope.redactionInfo = info;
    });

    $timeout(function () {
        redactionTypesService.initialize().then(function () {
            redactionDataSource.read();
        });
    });
}

angular.module('app').service('screenshotsRedactionService', ScreenshotsRedactionService);

ScreenshotsRedactionService.$inject = ['$http', 'envConfig'];

function ScreenshotsRedactionService($http, envConfig) {
    this.getPreference = function () {
        return $http.get(envConfig.apiUrl() + '/api/screenshots/redaction', {
            ignoreLoadingBar: true
        });
    };

    this.getStats = function () {
        return $http.get(envConfig.apiUrl() + '/api/screenshots/redaction/stats', {
            ignoreLoadingBar: true
        });
    };

    this.getInfoTypes = function () {
        return $http.get(envConfig.apiUrl() + '/api/screenshots/redaction/list', {
            ignoreLoadingBar: true
        });
    };

    this.setPreference = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/screenshots/redaction', payload);
    };
}
