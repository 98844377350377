import { Role } from '../../../../enums';
import { FeatureFlag } from '../../../../enums/FeatureFlag';
import { IRoute } from '../../../../models/IRoute';
import { agentactivationTemplate as angularJsTemplate } from '../templates';

export const appAgentactivation: IRoute = {
  name: 'app.agentactivation',
  stateDefinition: {
    url: '/agentactivation',
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      updated: false,
      errorMessages: null
    },
    template: angularJsTemplate,
    controller: 'AgentActivation',
    data: { pageTitle: 'Activation', filter: { hide: true } }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [],
    read: [Role.Admin, Role.Configurator, Role.SuperAdmin, Role.SupportBasic],
    featureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appAgentactivation;
