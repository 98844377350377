import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsInsightsSubscriptionMgmt: IRoute = {
  name: 'app.reports.insightsSubscriptionMgmt',
  stateDefinition: {
    url: '/subscriptions',
    template: '<reports-subscriptions></reports-subscriptions>',
    data: { pageTitle: 'Subscription Management' }
  },
  access: {
    redirectTo: 'app.reports.insightsPromo',
    roleAccessKey: RoleAccessKeys.Subscriptions,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic],
    bundleFlags: [BundleFlag.EmailSubscriptionsInsightsConfiguration],
    featureFlags: [FeatureFlag.InsightsAvailable]
  }
};

export default appReportsInsightsSubscriptionMgmt;
