import { IReportModalListItem } from './';

export interface IWorkloadDto {
  optimal: number;
  high: number;
  low: number;
  varied: number;
  previous: number;
  date: string;
  teamsModalCount: number;
  teams: IWorkloadTeamDto[];
  statusFilter: WorkloadStatusProps[];
}

export interface IWorkloadTeamDto {
  groupId: number;
  groupName: string;
  overutilizedMembers: number;
  underutilizedMembers: number;
  healthyMembers: number;
  totalMembers: number;
}

export interface IWorkloadTeamsDto {
  teams: IWorkloadTeamDto[];
}

export enum WorkloadLabel {
  Optimal = 'Optimal',
  High = 'High',
  Low = 'Low',
  Varied = 'Varied'
}

export const WORKLOAD_LABELS: WorkloadLabel[] = [
  WorkloadLabel.Optimal,
  WorkloadLabel.High,
  WorkloadLabel.Low,
  WorkloadLabel.Varied
];

export interface IWorkloadState {
  init: () => Promise<void>;
  workloadData: IWorkloadDto | null;
  isLoading: boolean;
  isTileError: boolean;
  workloadTeams: IReportModalListItem[];
}

export type WorkloadStatusProps = {
  status: WorkloadLabel;
};

export type IWorkloadChartDataSets = {
  data: any;
  backgroundColor: (() => string) | string[];
  hoverBackgroundColor: (() => string) | string[];
  noResults: boolean | number[];
  borderWidth: number;
};

export type IWorkloadChartProps = {
  labels: string[];
  datasets: IWorkloadChartDataSets[];
};
