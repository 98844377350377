import { useCallback, useState } from 'react';
import _ from 'lodash';
import { ApiRoutes } from '../constants';
import {
  ITopCategories,
  ITopCategoriesDto,
  ITopCategoriesState,
  ITopCategoryActivities,
  ITopCategoryActivitiesDto
} from '../models';
import {
  buildReportsApiRequest,
  mapTopCategories,
  mapTopCategoryActivities
} from '../utils';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';

export const useTopCategoriesState = (): ITopCategoriesState => {
  const [originalTopCategories, setOriginalTopCategories] =
    useState<ITopCategoriesDto>();
  const [topCategories, setTopCategories] = useState<ITopCategories>({
    chartData: {
      labels: [],
      datasets: []
    }
  });
  const [currentCategoryActivities, setCurrentCategoryActivities] =
    useState<ITopCategoryActivities>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActivitiesLoading, setIsActivitiesLoading] =
    useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [isModalError, setIsModalError] = useState<boolean>(false);
  const [currentSelections, setCurrentSelections] = useState<
    (string | number)[]
  >([]);

  const init = useCallback(async (reportFilters: Partial<IReportFilters>) => {
    setIsLoading(true);
    setIsTileError(false);

    try {
      const response = await buildReportsApiRequest<ITopCategoriesDto>({
        path: ApiRoutes.reports.fetchTopCategories,
        params: { groupId: reportFilters.groupId[0] }
      });
      //store the original data + DTO model for use in recalculating percents with chart legend toggling
      setOriginalTopCategories(response);

      const mapped = mapTopCategories(response);
      setTopCategories(mapped);

      const selectedCategories = mapped.chartData.datasets[0].data?.map(
        (item, i) => i
      );
      setCurrentSelections(selectedCategories);
    } catch (error) {
      setIsTileError(true);

      console.error('ActivTrak Error: Unable to load top categories', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCategoryActivities = useCallback(
    async (reportFilters: Partial<IReportFilters>, catId): Promise<void> => {
      setIsModalError(false);
      setIsActivitiesLoading(true);

      if (catId) {
        const url = ApiRoutes.reports.fetchTopCategoryActivities.replace(
          ':id',
          catId
        );

        try {
          const response =
            await buildReportsApiRequest<ITopCategoryActivitiesDto>({
              path: url,
              params: { groupId: reportFilters.groupId[0] }
            });
          const mapped = mapTopCategoryActivities(response);
          setCurrentCategoryActivities(mapped);
        } catch (error) {
          console.error(
            `ActivTrak Error: Unable to fetch category activities: ${error}`,
            error
          );
          setIsModalError(true);
        } finally {
          setIsActivitiesLoading(false);
        }
      }
    },
    []
  );

  const updateChart = useCallback(
    (selectedCategoryIds: (string | number)[]) => {
      //start with the original set of data
      //need to deep clone, otherwise the original set of data will also be updated
      const newData: ITopCategoriesDto = _.cloneDeep(originalTopCategories);
      if (newData) {
        for (let i = 0; i < newData.categories.length; i++) {
          if (!selectedCategoryIds.includes(i)) {
            newData.categories[i].duration = 0;
          }
        }
        //then re-map the topCategories state using new duration values
        const mapped = mapTopCategories(newData);
        setTopCategories(mapped);
      }
    },
    [originalTopCategories]
  );

  return {
    topCategories,
    currentCategoryActivities,
    currentSelections,
    isLoading,
    isActivitiesLoading,
    isTileError,
    init,
    getCategoryActivities,
    updateChart,
    setCurrentSelections,
    isModalError
  };
};
