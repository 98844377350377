import { INotificationService } from '../services/Notifications';

const notifySuccess = (notificationService: INotificationService, successMessage?: string) => {
    notificationService?.success(successMessage || 'Copied to clipboard');
}

const notifyError = (notificationService: INotificationService, errorMessage?: string) => {
    notificationService?.error(errorMessage || 'Failed to copy to clipboard');
}

export const copyToClipboard = (text: string, notificationService?: INotificationService, successMessage?: string, errorMessage?: string) => {
    if (!navigator.clipboard) {
        fallbackCopyToClipboard(text, notificationService, successMessage, errorMessage);
        return;
    }

    navigator.clipboard.writeText(text).then(
        () => notifySuccess(notificationService, successMessage),
        () => notifyError(notificationService, errorMessage)
    );
}

const fallbackCopyToClipboard = (text : string, notificationService?: INotificationService, successMessage?: string, errorMessage?: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        if (successful) {
            notifySuccess(notificationService, successMessage);
        } else {
            notifyError(notificationService, errorMessage);
        }
    } catch (err) {
        notifyError(notificationService, errorMessage);
    }

    document.body.removeChild(textArea);
}
