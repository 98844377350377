import { flatMapDeep } from 'lodash';

const getFlattenedObjectItems = (menuItem) => {
  let currentMenuItem = menuItem;
  if (!currentMenuItem.children || !currentMenuItem.children.length) {
    return currentMenuItem;
  }
  //grab only the item's children
  const currentMenuItemChildren = currentMenuItem.children;
  //negate the parent (not allowed to delete item)
  currentMenuItem = null;

  return [
    currentMenuItem,
    flatMapDeep(currentMenuItemChildren, getFlattenedObjectItems)
  ];
};

export const getFlattenedObjectArray = (arr) => {
  return flatMapDeep(arr, getFlattenedObjectItems);
};
