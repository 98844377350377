import React, { useEffect, useState } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { PassiveTimeSettingsService } from '../services';
import { usePassiveTimeSettingsState } from '../hooks';
import { DefaultWidgetProps, IPassiveTimeSettings } from '../models';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import {
  PASSIVE_END_TIME_MAX,
  PASSIVE_END_TIME_MIN,
  PASSIVE_START_TIME_MAX,
  PASSIVE_START_TIME_MIN
} from '../constants';
import { PassiveTimeInput } from './PassiveTimeInput';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Button,
  IconButton
} from '@mui/material';

export const PassiveTimeSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const [newPassiveTimeSettings, setNewPassiveTimeSetting] =
    useState<IPassiveTimeSettings>({
      passiveStartTime: 3,
      passiveEndTime: 5,
      passiveIndefinitely: false
    });
  const [hasError, setHasError] = useState<boolean>(false);

  const service = PassiveTimeSettingsService();
  const notificationService = useNotifications();

  const { passiveTimeSettings, isLoading, init, setPassiveSettings } =
    usePassiveTimeSettingsState({
      service,
      notificationService
    });

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setNewPassiveTimeSetting(passiveTimeSettings);
  }, [passiveTimeSettings]);

  const handleUpdate = () => {
    if (
      JSON.stringify(passiveTimeSettings) !==
      JSON.stringify(newPassiveTimeSettings)
    )
      setPassiveSettings(newPassiveTimeSettings);
  };

  const handleChange = (name, value) => {
    if (name)
      setNewPassiveTimeSetting((prevState) => ({
        ...prevState,
        [name]: value
      }));
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    handleChange(name, checked);
  };

  return (
    <ConfigurationCardWrapper
      title={
        <span>
          Passive Time Settings
          <Tooltip
            title={
              <span>
                Tip: 95% of passive time records are less than 10 minutes in
                duration.{' '}
                <a
                  href="https://support.activtrak.com/hc/en-us/articles/360026990372-Passive-Time-in-ActivTrak"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </span>
            }
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </span>
      }
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={
            hasError ||
            isSupport ||
            isLoading ||
            JSON.stringify(passiveTimeSettings) ===
              JSON.stringify(newPassiveTimeSettings)
          }
          onClick={handleUpdate}
        >
          Save
        </Button>
      }
    >
      <Box>
        Start collecting passive time when the user has not interacted with
        their computer for more than
        <PassiveTimeInput
          name="passiveStartTime"
          max={PASSIVE_START_TIME_MAX}
          min={PASSIVE_START_TIME_MIN}
          initialValue={newPassiveTimeSettings?.passiveStartTime}
          onChange={handleChange}
          isSupport={isSupport}
          setErrorStatus={setHasError}
        />{' '}
      </Box>
      <Box sx={{ margin: '20px 0' }}>
        Stop collecting after
        <PassiveTimeInput
          name="passiveEndTime"
          max={PASSIVE_END_TIME_MAX}
          min={PASSIVE_END_TIME_MIN}
          initialValue={newPassiveTimeSettings?.passiveEndTime}
          onChange={handleChange}
          isSupport={isSupport}
          setErrorStatus={setHasError}
          isDisabled={newPassiveTimeSettings?.passiveIndefinitely}
          endingText="of passive time"
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="passiveIndefinitely"
              checked={newPassiveTimeSettings?.passiveIndefinitely}
              color="primary"
              onChange={handleSwitch}
              disabled={isSupport}
            />
          }
          label="Track passive time indefinitely."
        />
      </FormGroup>
    </ConfigurationCardWrapper>
  );
};
