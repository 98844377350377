import moment from 'moment';
import envService from '../../../../../_app/environmentService/environmentService';
import { localStorageItems } from '../../constants';
import {
  IAccountSettings,
  IAccountSettingsDto,
  ILiveReportSettingsDto,
  IRealtimeSettingsDto
} from '../../models';
import { getDifferencesBetweenObjects } from '../filterByQuery';
import {
  getParsedLocalStorageItem,
  setLocalStorageItem
} from '../localStorage';
import { createFeatureFlags } from '../../utils/featureFlagCreation.utils';

const getAccountSettingsLocalStorage = () => {
  const localStorage_accountSettings: IAccountSettings =
    getParsedLocalStorageItem(localStorageItems.accountSettingsStorage);
  return localStorage_accountSettings || {};
};

export const setAccountSettingsLocalStorage = (
  newSettings: Partial<IAccountSettings>
) => {
  const localStorage_accountSettings =
    getAccountSettingsLocalStorage() as IAccountSettings;
  const accountSettings = {
    ...localStorage_accountSettings,
    ...newSettings
  };
  const stringifiedSettings = JSON.stringify(accountSettings);

  setLocalStorageItem(
    localStorageItems.accountSettingsStorage,
    stringifiedSettings
  );
};

export const filterInitialAccountSettings = (
  settings: IAccountSettingsDto,
  realtimeSettings: IRealtimeSettingsDto,
  liveReportSettings: ILiveReportSettingsDto
): IAccountSettings => {
  //only store settings we currently care about
  const filteredSettings = {
    account: settings.account,
    accountWizard: settings.accountWizard,
    agentDownloadSettings: settings.agentDownloadSettings,
    agentsInstalled: settings.agentsInstalled,
    autoUpdate: settings.autoUpdate,
    bundleFlags: settings.bundleFlags,
    companyName: settings.companyName,
    creator: settings.creator,
    currentIanaTimeZone: settings.currentIanaTimeZone || moment.tz.guess(),
    currentTimeZone: settings.currentTimeZone,
    dataCollection: settings.dataCollection,
    databaseName: settings.databaseName,
    dateFormat: settings.dateFormat,
    defaultGroup: settings.defaultGroup,
    delayedDelete: settings.delayedDelete,
    featureFlags: createFeatureFlags(settings, realtimeSettings),
    filterSettings: settings.filterSettings,
    firstName: settings.firstName,
    flexUsageEnabled: settings.flexUsageEnabled,
    flexUsageExpirationDate: settings.flexUsageExpirationDate,
    hasFailedPayment: settings.hasFailedPayment,
    insightsEnabled: settings.insightsEnabled,
    insightsSetting: settings.insightsSetting,
    intercommHash: settings.intercommHash,
    isDataConsoleEnabled: settings.isDataConsoleEnabled,
    isExcludeTodaysDateInFilterEnabled:
      liveReportSettings?.liveReportExcludeTodaysDate ?? false,
    isSubscriptionCancelled: settings.isSubscriptionCancelled,
    isVerified: settings.isVerified,
    landingPage: settings.landingPage,
    lastName: settings.lastName,
    macAutoupdate: settings.macAutoupdate,
    maintenanceMode: settings.maintenanceMode,
    maxStorage: settings.maxStorage,
    msaAcceptanceRequired: settings.msaAcceptanceRequired,
    overLimitOrOverStorage: settings.overLimitOrOverStorage,
    parentAccountId: settings.parentAccountId,
    phone: settings.phone,
    privacySettings: settings.privacySettings,
    realtimeSettings,
    redactionFeatureFlag: settings.redactionFeatureFlag,
    roleAccess: settings.roleAccess,
    roleDateFilters: settings.roleDateFilters,
    roles: settings.roles,
    safeSearchFeatureFlag: settings.safeSearchFeatureFlag,
    settings: {
      dataRetentionSettings: settings.settings.dataRetentionSettings,
      supportTypeSettings: settings.settings.supportTypeSettings
    },
    showTeamPulseScreenViews: settings.showTeamPulseScreenViews,
    showViewableAccountsColumn: settings.showViewableAccountsColumn,
    state: settings.state,
    subscriptionType: settings.subscriptionType,
    timeFormat: settings.timeFormat,
    totalLicenses: settings.totalLicenses,
    unixCreationTime: settings.unixCreationTime,
    updateBillingInfoUrl: settings.updateBillingInfoUrl,
    usedLicenses: settings.usedLicenses,
    userId: settings.userId,
    userMergeEnabled: settings.userMergeEnabled,
    username: settings.username,
    videoAlarmsActive: settings.videoAlarmsActive,
    videoAlarmsLimit: settings.videoAlarmsLimit,
    viewableGroupsCount: settings.viewableGroupsCount
  };

  if (envService.getEnvironmentName() !== 'prod') {
    const diffClientAndDto = getDifferencesBetweenObjects(
      filteredSettings,
      settings
    );
    console.info(
      '%c Difference in Account Settings between client and DTO models. realtimeSettings is added to client. The rest are removed from client.',
      'font-size:12px;background-color: #5cd5ed;color:#000;',
      diffClientAndDto
    );
  }

  return filteredSettings;
};
