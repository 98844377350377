import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export default function ArrowLeftIcon(props: SvgIconProps) {
  const height = props.height ?? 7;
  const width = props.width ?? 16;
  const className = props.className;
  const fillColor = props.fillColor ?? 'currentColor';

  let classList = 'at-icon at-icon-arrow at-icon-arrow-right';

  if (className) classList += ` ${className}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 8"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classList}
    >
      <path
        d="M12.01 2.99996H1C0.45 2.99996 0 3.44996 0 3.99996C0 4.54996 0.45 4.99996 1 4.99996H12.01V6.78996C12.01 7.23996 12.55 7.45996 12.86 7.13996L15.64 4.34996C15.83 4.14996 15.83 3.83996 15.64 3.63996L12.86 0.849956C12.55 0.529956 12.01 0.759956 12.01 1.19996V2.99996Z"
        fill={fillColor}
      />
    </svg>
  );
}
