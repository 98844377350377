'use strict';

angular.module('app').service('screenshotsService', ScreenshotsService);

ScreenshotsService.$inject = ['$http', '$filter', '$q', '$window', 'envConfig'];

/**
 * Screenshots will resolve the URI based on screenshots flags coming from post_login
 * This is a temporary flag for beta customers. This will be removed when screenshots go GA.
 * @param $http
 * @param envConfig
 * @constructor
 */

function ScreenshotsService($http, $filter, $q, $window, envConfig) {
    this.getScreenshots = function (parameters, data) {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/screenshots?';
        return $http.get(url + parameters, data);
    };
    this.downloadScreenshot = function(url) {
        return $http.get(url, { responseType: 'blob' });
    };
    this.getLatestScreenshotsPerUser = function(parameters, data) {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/screenshots/latest?';
        return $http.get(url + parameters, data);
    };
    this.getAllScreenshotsCount = function() {
        var url = envConfig.reportingServiceUrl() + '/reports/v1/screenshots/count';
        return $http.get(url);
    };
    this.deleteAll = function() {
        return $http.delete(envConfig.apiUrl() + '/api/screenshots');
    };
    this.deleteSelected = function (selected) {
        return $http.post(envConfig.apiUrl() + '/api/screenshots/delete', selected);
    };
    this.getScreenshotWizardAlarms = function() {
        var deferred = $q.defer();
        $http.get(envConfig.apiUrl() + '/api/alarms').success(function(result) {
            var alarms = $filter('filter')(result, {
                screenshot: true,
                popup: false,
                email: false,
                terminate: false
            });
            var wizardAlarms = findScreenshotWizardAlarms(alarms);
            deferred.resolve(wizardAlarms);
        });
        return deferred.promise;
    };
    this.deleteAlarm = function(id) {
        return $http.delete(envConfig.apiUrl() + '/api/alarms/' + id);
    };
    this.createAlarm = function(alarm) {
        return $http.post(envConfig.apiUrl() + '/api/alarms', alarm);
    };
    this.getAlarm = function(id) {
        return $http.get(envConfig.apiUrl() + '/api/alarms/' + id);
    };

    this.getScreenshotsRowSize = function() {
        var width = $window.innerWidth;
        // these correspond with the column settings in _screenshots.scss
        if (width < 550) {
            return 1;
        } else if (width >= 2650) {
            return 9;
        } else {
            return Math.ceil((width - 250) / 300);
        }
    };

    this.getClosestMultipleOfRowSize = function(size, screenshotsPerRow) {
        var rowCount = Math.round(size / screenshotsPerRow);
        return rowCount * screenshotsPerRow;
    };

    this.getPageSizes = function(screenshotsPerRow) {
        var self = this;
        return [16, 32, 64, 128, 256, 512].map(function(item) {
            return (
                '' + self.getClosestMultipleOfRowSize(item, screenshotsPerRow)
            );
        });
    };
}

/**
 * Filters out all alarms which are not compatible with the configure screenshot tool
 */
function findScreenshotWizardAlarms(alarms) {
    var defaultScreenshotAlarm;

    // find default screenshot alarm
    for (var i = 0; i < alarms.length; i++) {
        if (alarms[i].default) {
            defaultScreenshotAlarm = alarms[i];
            break;
        }
    }

    var wizardAlarms = alarms
        .filter(function(item) {
            return (
                !item.default &&
                item.type === 'Activity' &&
                item.conditions.length > 1 &&
                item.conditions.length < 4 &&
                !hasDuplicateFields(item.conditions) &&
                areConditionsCompatible(item.conditions)
            );
        })
        .map(fixConditionsOrder);

    if (defaultScreenshotAlarm !== undefined) {
        wizardAlarms.unshift(defaultScreenshotAlarm);
    }
    return wizardAlarms;
}

/**
 * Checks if the conditions of an alarm are compatible with the screenshot configuration page
 */
function areConditionsCompatible(conditions) {
    var hasTitlebar = false;
    var hasDuration = false;
    for (var i = 0; i < conditions.length; i++) {
        if (
            conditions[i].casesensitive ||
            ['Titlebar', 'Computer', 'User', 'DurationSeconds'].indexOf(
                conditions[i].field
            ) < 0 ||
            (conditions[i].field === 'Titlebar' &&
                conditions[i].op !== 'Contains') ||
            (conditions[i].field === 'Computer' &&
                conditions[i].op !== 'EqualTo') ||
            (conditions[i].field === 'User' &&
                conditions[i].op !== 'EqualTo') ||
            (conditions[i].field === 'DurationSeconds' &&
                conditions[i].op !== 'GreaterThan')
        ) {
            return false;
        }
        if (conditions[i].field === 'Titlebar') {
            hasTitlebar = true;
        }
        if (conditions[i].field === 'DurationSeconds') {
            hasDuration = true;
        }
    }
    if (!hasTitlebar || !hasDuration) {
        return false;
    }
    return true;
}

/**
 * Checks if there are multiple conditions for an alarm applying to the same field (e.g. titlebar, user, computer).
 * The screenshot config tool does not support alarms with multiple conditions applying to the same field.
 */
function hasDuplicateFields(conditions) {
    for (var i = 0; i < conditions.length; i++) {
        for (var j = 0; j < conditions.length; j++) {
            if (i === j) {
                continue;
            } else if (conditions[i].field === conditions[j].field) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Reorders the conditions of an (screenshot-config-compatible) alarm as duration first, titlebar second and user
 * or computer third. This is necessary for the Kendo grid templates to work.˜
 */
function fixConditionsOrder(alarm) {
    var orderedConditions = [];
    for (var i = 0; i < alarm.conditions.length; i++) {
        switch (alarm.conditions[i].field) {
            case 'DurationSeconds':
                orderedConditions[0] = alarm.conditions[i];
                break;
            case 'Titlebar':
                orderedConditions[1] = alarm.conditions[i];
                break;
            default:
                orderedConditions[2] = alarm.conditions[i];
        }
    }
    alarm.conditions = orderedConditions;
    return alarm;
}
