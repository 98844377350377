import React from 'react';
import Typography from '@mui/material/Typography';
import { gray11 } from '../../common/constants';

export const SupportFooter = () => {
  return (
    <Typography sx={{ color: gray11 }}>
      For alternate installation methods, including remote and silent installs,{' '}
      <a
        href="https://support.activtrak.com/hc/en-us/articles/360039249211-Deploying-Agent-Guide"
        rel="noreferrer"
        target="_blank"
      >
        click here
      </a>
      .
    </Typography>
  );
};
