import { IRoute } from '../../../../models/IRoute';
import { loginTemplate as angularJsTemplate } from '../templates';

export const loginNext: IRoute = {
  name: 'login.next',
  stateDefinition: {
    url: '/login?next={url}',
    template: angularJsTemplate,
    controller: 'LoginController as vm'
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default loginNext;
