import { ITabLabel } from '../models/IConstants';

export const TAB_LABELS: ITabLabel[] = [
  {
    label: 'Snapshot',
    index: 0
  },
  {
    label: 'Account Details',
    index: 1
  },
  {
    label: 'User',
    index: 2
  },
  {
    label: 'Subscriptions',
    index: 3
  },
  {
    label: 'Account Settings',
    index: 4
  },
  {
    label: 'Bundle Flags',
    index: 5
  },
  {
    label: 'Support Tools',
    index: 6
  }
];
