import React from 'react';
import { IconButton, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type TextFieldContainerProps = {
  showSearch?: boolean;
  setShowSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  textValue: string;
  isHideable: boolean;
};

const TextFieldContainer = ({
  showSearch,
  setShowSearch,
  textValue,
  isHideable
}: TextFieldContainerProps) => (
  <TextField
    select
    SelectProps={{
      IconComponent: () => null
    }}
    disabled={!isHideable}
    defaultValue={textValue}
    variant="outlined"
    fullWidth
    onClick={setShowSearch ? () => setShowSearch(!showSearch) : undefined}
    InputProps={{
      readOnly: true,
      endAdornment: isHideable ? (
        <IconButton>
          {!showSearch ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      ) : (
        <IconButton disabled>
          <KeyboardArrowDown />
        </IconButton>
      )
    }}
  >
    <MenuItem key={textValue} value={textValue}>
      {textValue}
    </MenuItem>
  </TextField>
);

export default TextFieldContainer;
