import React from 'react';
import { getClasses } from './InfoPanel.style';
import InfoPanelVariant from './InfoPanelVariant';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { StackedInfo, InlineInfo } from '../';

const InfoPanel = (props) => {
    let {
        name,
        description,
        metric,
        type,
        rootClass,
        loading,
        variant,
        heading,
        headingClass,
        subheading,
        largeHeading,
        align
    } = props;
    const classes = getClasses(type);
    switch (variant) {
        case InfoPanelVariant.Stacked:
            return (
                <StackedInfo
                    heading={heading}
                    headingClass={headingClass}
                    subheading={subheading}
                    largeHeading={largeHeading}
                    loading={loading}
                />
            );
        case InfoPanelVariant.Inline:
            return (
                <InlineInfo
                    heading={heading}
                    subheading={subheading}
                    rootClass={rootClass}
                    headingClass={headingClass}
                    largeHeading={largeHeading}
                    align={align}
                    loading={loading}
                />
            );
        case InfoPanelVariant.Custom:
            return (
                <SkeletonLoader loading={loading} width="80%">
                    {props.children}
                </SkeletonLoader>
            );
        default:
            return (
                <div style={{ ...rootClass, ...classes.wrapper }}>
                    <div style={classes.col1}>
                        <div style={classes.name}>
                            <SkeletonLoader loading={loading} width="100%">
                                {name}
                            </SkeletonLoader>
                        </div>
                        <div style={classes.description}>
                            <SkeletonLoader loading={loading} width="100%">
                                {description}
                            </SkeletonLoader>
                        </div>
                    </div>
                    {type === InfoPanelVariant.CondensedDetail && (
                        <div style={classes.col2}>
                            <div style={classes.metric}>
                                <SkeletonLoader loading={loading} width="100%">
                                    {metric}
                                </SkeletonLoader>
                            </div>
                        </div>
                    )}
                    {type === InfoPanelVariant.CondensedCustom && (
                        <div style={classes.col2}>
                            <SkeletonLoader loading={loading} width="80%">
                                {props.children}
                            </SkeletonLoader>
                        </div>
                    )}
                </div>
            );
    }
};

export default InfoPanel;
