'use strict';

import ProductivityStatus from '../constants/productivityStatus';
import { escape } from 'lodash';

//NOTE: This will build an angularJS directive - attribute values will be expressed as quoted strings
//e.g. icon-type="'some-icon-type'"
//this is angularJs' syntax for static string values.
const buildDirective = (name, attributes) => {
    var directive = document.createElement(name);
    for (var key of Object.keys(attributes)) {
        if (attributes[key]) {
            directive.setAttribute(key, `'${escape(attributes[key])}'`);
        }
    }
    return directive;
};

function getIconToolTip(userType, groupType, mode) {
    if (
        userType &&
        mode &&
        (userType.toUpperCase() === 'AllUsers'.toUpperCase() ||
            userType.toUpperCase() === 'AllComputers'.toUpperCase()) &&
        mode.toUpperCase() === 'users'.toUpperCase()
    ) {
        return 'All Users';
    }

    if (
        userType &&
        mode &&
        (userType.toUpperCase() === 'AllUsers'.toUpperCase() ||
            userType.toUpperCase() === 'AllComputers'.toUpperCase()) &&
        mode.toUpperCase() === 'computers'.toUpperCase()
    ) {
        return 'All Computers';
    }

    if (
        (userType && userType.toUpperCase() === 'User'.toUpperCase()) ||
        userType.toUpperCase() === 'Alias'.toUpperCase()
    ) {
        return 'User';
    }

    if (userType && userType.toUpperCase() === 'Computer'.toUpperCase()) {
        return 'Computer';
    }

    if (userType && groupType && mode && userType.toUpperCase() === 'Group'.toUpperCase()) {
        if (groupType.toUpperCase() === 'User'.toUpperCase()) {
            return 'ActivTrak User Group';
        }

        if (groupType.toUpperCase() === 'Computer'.toUpperCase()) {
            return 'ActivTrak Computer Group';
        }

        if (groupType.toUpperCase() === 'Both'.toUpperCase()) {
            return 'ActivTrak User & Computer Group';
        }
    }

    if (userType && groupType && mode && userType.toUpperCase() === 'AdGroup'.toUpperCase()) {
        if (groupType.toUpperCase() === 'User'.toUpperCase()) {
            return 'Active Directory User Group';
        }

        if (groupType.toUpperCase() === 'Computer'.toUpperCase()) {
            return 'Active Directory Computer Group';
        }

        if (groupType.toUpperCase() === 'Both'.toUpperCase()) {
            return 'Active Directory User & Computer Group';
        }
    }

    console.error(
        'ActivTrak Error: Unknown userType, groupType, mode combination when generating Icon Tooltip.',
        userType,
        groupType,
        mode
    );
    return '';
}

const getProductivityIcon = function (productivity, includeTooltip = false, className = 'icon-lg') {
    const unproductiveIcon = 'remove_circle';
    let themeClass;
    let icon;
    let tooltip;

    const showActiveLabel = true;

    switch (productivity) {
        case ProductivityStatus.Undefined:
            themeClass = 'text-undefined';
            icon = 'help';
            tooltip = ProductivityStatus.getName(ProductivityStatus.Undefined, showActiveLabel, true);
            break;
        case ProductivityStatus.UndefinedPassive:
            themeClass = 'text-passive';
            icon = 'help';
            tooltip = ProductivityStatus.getName(ProductivityStatus.UndefinedPassive, showActiveLabel, true);
            break;
        case ProductivityStatus.Productive:
            themeClass = 'text-productive';
            icon = 'check_circle';
            tooltip = ProductivityStatus.getName(ProductivityStatus.Productive, showActiveLabel, true);
            break;
        case ProductivityStatus.ProductivePassive:
            themeClass = 'text-productive-passive';
            icon = 'check_circle';
            tooltip = ProductivityStatus.getName(ProductivityStatus.ProductivePassive, showActiveLabel, true);
            break;
        case ProductivityStatus.Unproductive:
            themeClass = 'text-unproductive';
            icon = unproductiveIcon;
            tooltip = ProductivityStatus.getName(ProductivityStatus.Unproductive, showActiveLabel, true);
            break;
        case ProductivityStatus.UnproductivePassive:
            themeClass = 'text-unproductive-passive';
            icon = unproductiveIcon;
            tooltip = ProductivityStatus.getName(ProductivityStatus.UnproductivePassive, showActiveLabel, true);
            break;
        case ProductivityStatus.OfflineMeetings:
            themeClass = 'text-offline-meetings';
            icon = 'cloud_circle';
            tooltip = ProductivityStatus.getName(ProductivityStatus.OfflineMeetings, showActiveLabel, true);
            break;
        case -5:
            return `<i class="productivity-icon ${className} icon-at-mixed-productivity"></i>`;
        default:
            console.error(
                'ActivTrak Error: Unknown productivity type when generating productivity icon.',
                productivity
            );
            return '';
    }

    tooltip = includeTooltip ? ` at-tooltip="${tooltip}"` : '';
    return `<i class="productivity-icon material-icons ${className} ${themeClass}"${tooltip}>${icon}</i>`;
};

const getProductivityTag = function (productivity, includeTooltip = false) {
    const productivityName = ProductivityStatus.getName(productivity, true, true);
    const productivityTheme = `label-${ProductivityStatus.getClass(productivity)}`;
    const tooltip = includeTooltip ? ` at-tooltip="${productivityName}"` : '';

    return `<span class="at-tag ${productivityTheme}"${tooltip}>${productivityName}</span>`;
};

const _reactUserGroupTypeIconAppAccess = function (type, mix) {
    const requiredAttributeCheck = (attribute, type) => {
        if (!attribute || typeof attribute !== type) {
            console.error('ActivTrak Error: Cannot generate icon due to missing attribute.');
            return '';
        }
    };

    requiredAttributeCheck(mix, 'string');
    requiredAttributeCheck(type?.toString(), 'string');

    type = typeof type === 'string' ? type?.toUpperCase() : type;
    mix = mix?.toUpperCase();

    const iconRenderer = (iconType, iconSource) => {
        requiredAttributeCheck(iconType, 'string');
        return `<span class="viewable-group-icon text-master"><group-icon-source class="viewable-group-icon" icon-type="\'${iconType}'\" icon-source="\'${iconSource}'\"></group-icon-source></span>`;
    };

    switch (type) {
        case 'ALLUSERS':
            return iconRenderer('allusers', 'allusers');
        case 'ALLCOMPUTERS':
            return iconRenderer('mix', 'Computer');
        default:
            switch (mix) {
                case 'COMPUTER':
                    return iconRenderer('mix', 'Computer', 'Computer Group');
                case 'BOTH':
                    return iconRenderer('mix', 'Mixed', 'Mixed Group');
                case 'USER':
                    return iconRenderer('mix', 'User', 'User Group');
                default:
                    console.error('ActivTrak Error: There has been an error in generating the icon.');
                    return '';
            }
    }
};

// App access now using _reactUserGroupTypeIconAppAccess
const getGroupTypeIcon = function (type, mix) {
    if (type === 'AllUsers') {
        return '<i at-tooltip="All Users" tooltip-placement="right" class="fa fa-lg icon-at-users text-master"></i>';
    }
    if (type === 'AllComputers') {
        return '<i at-tooltip="All Computers" tooltip-placement="right" class="fa fa-lg icon-at-desktops text-master"></i>';
    }

    let icon;

    if (type === 2 || type === 'ActivTrak') {
        icon =
            '<i at-tooltip="ActivTrak User Group" tooltip-placement="right" class="fa fa-lg icon-at-group-activtrak text-master"></i>';

        if (mix === 'Computer') {
            icon =
                '<i at-tooltip="ActivTrak Computer Group" tooltip-placement="right" class="fa fa-lg icon-at-group-activtrak-computer text-master"></i>';
        }

        if (mix === 'Both') {
            icon =
                '<i at-tooltip="ActivTrak Mixed Group" tooltip-placement="right" class="fa fa-lg icon-at-group-activtrak-both text-master"></i>';
        }

        return icon;
    }

    if (type === 3 || type === 'ActiveDirectory') {
        icon =
            '<i at-tooltip="Active Directory User Group" tooltip-placement="right" class="fa fa-lg icon-at-group-active-directory text-master"></i>';

        if (mix === 'Computer') {
            icon =
                '<i at-tooltip="Active Directory Computer Group" tooltip-placement="right" class="fa fa-lg icon-at-group-active-directory-computer text-master"></i>';
        }

        if (mix === 'Both') {
            icon =
                '<i at-tooltip="Active Directory Mixed Group" tooltip-placement="right" class="fa fa-lg icon-at-group-active-directory-both text-master"></i>';
        }

        return icon;
    }

    console.error('ActivTrak Error: Unknown type when generating group type icon.', type, mix);
    return '';
};

const _reactUserGroupTypeIcon = function (userType, groupType, mode, userName, attributesOnly = false) {
    const requiredAttributeCheck = (attribute) => {
        if (!attribute || typeof attribute !== 'string') {
            console.error('ActivTrak Error: Cannot generate icon due to missing string attribute.');
            return '';
        }
    };

    requiredAttributeCheck(userType);

    const userTypeData = userType && userType.toUpperCase();
    const iconGroupType = (groupType && groupType.toUpperCase()) || 'USER';
    const iconMode = (mode && mode.toUpperCase()) || 'USERS';

    const typeRenderer = (iconSource) => {
        if (attributesOnly) {
            return {
                iconSource,
                label: userName
            };
        } else {
            requiredAttributeCheck(iconSource);
            return buildDirective('group-type-label', {
                'icon-source': iconSource,
                label: userName
            }).outerHTML;
        }
    };

    if (['ALLCOMPUTERS', 'ALLUSERS'].includes(userTypeData)) {
        if (iconMode === 'USERS') {
            return typeRenderer('allusers');
        }
        if (iconMode === 'COMPUTERS') {
            return typeRenderer('Computer');
        }
    }

    if (iconGroupType === 'COMPUTER' && ['ADGROUP', 'GROUP'].includes(userTypeData)) {
        return typeRenderer('Computer');
    }

    if (iconGroupType === 'BOTH') {
        return typeRenderer('Mixed');
    }

    if (iconGroupType === 'USER') {
        if (['ALIAS', 'USER'].includes(userTypeData)) {
            return typeRenderer('individual');
        }
        if (['ADGROUP', 'GROUP'].includes(userTypeData)) {
            return typeRenderer('User');
        }
        if (userTypeData === 'COMPUTER') {
            return typeRenderer('individualcomputer');
        }
    }

    console.error('ActivTrak Error: There has been an error in generating the icon.');
    return '';
};

const getUserTypeIconCss = function (userType, groupType, mode) {
    groupType = groupType && typeof groupType === 'string' ? groupType : 'User';
    mode = mode && typeof mode === 'string' ? mode : 'users';

    if (!userType || typeof userType !== 'string') {
        console.error('ActivTrak Error: Cannot generate user type icon css due to missing userType string.');
        return '';
    }

    if (
        (userType.toUpperCase() === 'AllUsers'.toUpperCase() ||
            userType.toUpperCase() === 'AllComputers'.toUpperCase()) &&
        mode.toUpperCase() === 'users'.toUpperCase()
    ) {
        return 'fa icon-at-users text-master';
    }

    if (
        (userType.toUpperCase() === 'AllUsers'.toUpperCase() ||
            userType.toUpperCase() === 'AllComputers'.toUpperCase()) &&
        mode.toUpperCase() === 'computers'.toUpperCase()
    ) {
        return 'icon-at-desktops text-master';
    }

    if (userType.toUpperCase() === 'User'.toUpperCase() || userType.toUpperCase() === 'Alias'.toUpperCase()) {
        return 'fa fa-lg icon-at-user text-master';
    }

    if (userType.toUpperCase() === 'Computer'.toUpperCase()) {
        return 'fa fa-desktop text-master';
    }

    if (userType.toUpperCase() === 'Group'.toUpperCase()) {
        if (groupType.toUpperCase() === 'User'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-activtrak text-master';
        }

        if (groupType.toUpperCase() === 'Computer'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-activtrak-computer text-master';
        }

        if (groupType.toUpperCase() === 'Both'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-activtrak-both text-master';
        }
    }

    if (userType.toUpperCase() === 'AdGroup'.toUpperCase()) {
        if (groupType.toUpperCase() === 'User'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-active-directory text-master';
        }

        if (groupType.toUpperCase() === 'Computer'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-active-directory-computer text-master';
        }

        if (groupType.toUpperCase() === 'Both'.toUpperCase()) {
            return 'fa fa-lg icon-at-group-active-directory-both text-master';
        }
    }

    console.error(
        'ActivTrak Error: Unknown userType, groupType, mode combination when generating user type icon css.',
        userType,
        groupType,
        mode
    );
    return '';
};

const getUserTypeIcon = function (userType, groupType, mode) {
    groupType = groupType || 'User';
    mode = mode || 'users';
    let css = getUserTypeIconCss(userType, groupType, mode);
    if (css === '') {
        return '';
    } else {
        let tooltip = getIconToolTip(userType, groupType, mode);
        return '<i at-tooltip="' + tooltip + '" tooltip-placement="right" class="at-m-l-1 ' + css + '"></i>';
    }
};

export const iconResolverServiceFunctions = {
    getIconToolTip,
    getProductivityIcon,
    getProductivityTag,
    getGroupTypeIcon,
    getUserTypeIconCss,
    getUserTypeIcon,
    _reactUserGroupTypeIcon,
    _reactUserGroupTypeIconAppAccess
};
