import { getDefaultConfig } from '../../common/config';
import { apiService } from '../../common/helpers';
import { ITicketCommentDto, ITicketDto } from '../models';

export const ticketsService = () => {
  return {
    fetchTickets: async (): Promise<ITicketDto[]> => {
      const response = await apiService.get<ITicketDto[]>(
        '/api/supporttickets/user',
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },
    postTicket: async (payload: FormData, progressConfig): Promise<any> => {
      return await apiService.post<any>('/api/supporttickets/new', {
        ...getDefaultConfig(),
        ...progressConfig,
        data: payload,
        headers: { 'content-type': 'multipart/form-data' }
      });
    },
    fetchTicketComments: async (id: number): Promise<ITicketCommentDto[]> => {
      const response = await apiService.get<ITicketCommentDto[]>(
        `/api/supporttickets/ticket/${id}/comments`,
        {
          ...getDefaultConfig()
        }
      );
      return response?.data;
    },
    postTicketComment: async (
      ticketId: number,
      payload: FormData,
      progressConfig
    ): Promise<any> => {
      return await apiService.post<any>(
        `/api/supporttickets/ticket/${ticketId}/comments`,
        {
          ...getDefaultConfig(),
          ...progressConfig,
          data: payload,
          headers: { 'content-type': 'multipart/form-data' }
        }
      );
    }
  };
};
