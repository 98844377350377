import { FeatureFlag } from '../../../_reactivtrak/src/common/enums/FeatureFlag';
import lodash from 'lodash';
import supportModalCtrlTemplate from 'views/modals/supportModal.html?raw';
import { getAccountPlanStore } from '../../../_reactivtrak/src/common/stores/accountPlanStore';

angular
    .module('app')
    .directive('atHeaderHiddenViewTooltip', [
        '$timeout',
        '$state',
        '$window',
        'messagesService',
        'envConfig',
        'tooltipService',
        'dataCenterService',
        'constantsService',
        'customUibModal',
        'AccountSettingsService',
        'authorizationService',
        'templateServiceFunctions',
        atHeaderHiddenViewTooltip
    ]);

function atHeaderHiddenViewTooltip(
    $timeout,
    $state,
    $window,
    msg,
    envConfig,
    tooltipService,
    dataCenterService,
    constant,
    customUibModal,
    AccountSettingsService,
    authorizationService,
    templateServiceFunctions
) {
    return {
        link: function ($scope, element, attr) {
            var showAccountSettings = false;

            // eslint-disable-next-line no-undef
            var version = __VERSION__;
            $window.version = version;

            // eslint-disable-next-line no-undef
            var commitHash = __COMMIT_HASH__;
            $window.commitHash = commitHash;

            function timeFormat(offset) {
                return Math.floor(offset / 60) + ':' + (new Array(2).join('0') + (Math.abs(offset) % 60)).slice(-2);
            }

            function getTimezone() {
                return (
                    AccountSettingsService.currentTimeZone +
                    ' (UTC' +
                    (
                        '-' +
                        timeFormat(moment.tz.zone(AccountSettingsService.currentIanaTimeZone).utcOffset(new Date()))
                    ).replace('--', '+') +
                    ')'
                );
            }

            function getColor(value) {
                return typeof value === 'boolean'
                    ? value
                        ? constant.get('color', 'COLOR-SUCCESS-LIGHT')
                        : constant.get('color', 'COLOR-DANGER-LIGHT')
                    : constant.get('color', 'COLOR-COMPLETE-LIGHT');
            }

            function createSection(section) {
                var sec = '<div><div class="text-uppercase"><strong>' + section.title + '</strong></div>';
                section.fields.forEach(function (f) {
                    sec += createElement(f);
                });
                sec += '</div>';
                return sec;
            }

            function createElement(field) {
                var value = typeof field.value === 'function' ? field.value() : field.value;

                if (field.subParam) {
                    value = value[field.subParam];
                }

                return (
                    '<div class="p-l-10">' +
                    field.label +
                    ': <span style="color: ' +
                    getColor(value) +
                    ';">' +
                    value +
                    '</span></div>'
                );
            }

            function mapBundleFlags(bundleFlags) {
                return lodash.map(bundleFlags, function (value, key) {
                    return { label: key, value: value };
                });
            }

            var sections = [];

            function loadSections() {
                if (!AccountSettingsService) {
                    return;
                }

                sections = [
                    {
                        title: 'Account Flags',
                        fields: [
                            {
                                label: 'Paid Account',
                                value: authorizationService.hasFeature('isPaidFunctionalityEnabled')
                            },
                            {
                                label: 'Failed Payment',
                                value: AccountSettingsService.hasFailedPayment
                            },
                            {
                                label: 'Plan Cancelled',
                                value: AccountSettingsService.isSubscriptionCancelled
                            },
                            {
                                label: 'Verified',
                                value: AccountSettingsService.isVerified
                            },
                            {
                                label: 'Auto Update',
                                value: AccountSettingsService.autoUpdate
                            },
                            {
                                label: 'Mac Auto Update',
                                value: AccountSettingsService.macAutoupdate
                            },
                            {
                                label: 'Data Collection',
                                value: AccountSettingsService.dataCollection
                            },
                            {
                                label: 'MSA Acceptance Required',
                                value: AccountSettingsService.msaAcceptanceRequired
                            },
                            {
                                label: 'Screen View',
                                value: AccountSettingsService.realtimeSettings.screenshotFeatureFlag
                            },
                            {
                                label: 'State',
                                value: AccountSettingsService.state
                            },
                            {
                                label: 'Command Center',
                                value: $scope.hasCommandCenter
                            }
                        ]
                    },
                    {
                        title: 'Feature Flags',
                        fields: [
                            {
                                label: 'Screenshot Redaction Enabled',
                                value: AccountSettingsService.redactionFeatureFlag
                            },
                            {
                                label: 'Screenshot Flagging Enabled',
                                value: AccountSettingsService.safeSearchFeatureFlag
                            },
                            {
                                label: 'ActivInsights Enabled',
                                value: authorizationService.hasFeature(FeatureFlag.InsightsEnabled)
                            },
                            {
                                label: 'ActivInsights Available',
                                value: authorizationService.hasFeature(FeatureFlag.InsightsAvailable)
                            },
                            {
                                label: 'Video Alarms Enabled',
                                value: authorizationService.hasFeature(FeatureFlag.VideoPlayback)
                            },
                            {
                                label: 'Video Alarms Limit',
                                value: function () {
                                    return AccountSettingsService.videoAlarmsLimit === 0
                                        ? 'Unlimited'
                                        : AccountSettingsService.videoAlarmsLimit;
                                }
                            },
                            {
                                label: 'Video Alarms Active',
                                value: AccountSettingsService.videoAlarmsActive
                            },
                            {
                                label: 'Delay Delete',
                                value: AccountSettingsService.delayedDelete
                            },
                            {
                                label: 'User Merge',
                                value: AccountSettingsService.userMergeEnabled
                            },
                            {
                                label: 'Exclude Todays Date In Filter',
                                value: authorizationService.hasFeature(FeatureFlag.ExcludeTodaysDateInFilter)
                            }
                        ]
                    },
                    {
                        title: 'Data Flags',
                        fields: [
                            {
                                label: 'Data Connect Enabled',
                                value: AccountSettingsService.isDataConsoleEnabled
                            },
                            {
                                label: 'Preaggregation Enabled',
                                value: AccountSettingsService.isPreaggregationEnabled
                            },
                            {
                                label: 'Time Zone',
                                value: getTimezone
                            }
                        ]
                    },
                    {
                        title: 'Region Info',
                        fields: [
                            {
                                label: 'Region',
                                value: dataCenterService.region,
                                subParam: 'name'
                            },
                            {
                                label: 'Database',
                                value: AccountSettingsService.databaseName
                            },
                            {
                                label: 'API Type',
                                value: dataCenterService.regionType
                            },
                            {
                                label: 'API Url',
                                value: envConfig.apiUrl
                            }
                        ]
                    },
                    {
                        title: 'App Info',
                        fields: [
                            {
                                label: 'Version',
                                value: version + '_' + commitHash
                            }
                        ]
                    },
                    {
                        title: 'Bundle Flags',
                        fields: mapBundleFlags(
                            templateServiceFunctions.sortAlphabetically(AccountSettingsService.bundleFlags)
                        )
                    }
                ];

                return sections;
            }

            $scope.userCountClicked = function (e) {
                if ((e.metaKey || e.ctrlKey) && e.altKey) {
                    loadSections();
                    customUibModal.open({
                        animation: false,
                        template: supportModalCtrlTemplate,
                        controller: 'SupportModalCtrl',
                        windowClass: 'centered-modal off-wht-bg-modal',
                        size: 'lg',
                        resolve: {
                            modalSettings: {
                                sections: sections,
                                refresh: loadSections
                            }
                        }
                    });
                } else if ($scope.hasRoute('app.settings.useragents')) {
                    $state.go('app.settings.useragents');
                }
            };

            element.kendoTooltip({
                content: function () {
                    loadSections();
                    if (attr.atHeaderHiddenViewTooltip) {
                        return attr.atHeaderHiddenViewTooltip;
                    }

                    if (!showAccountSettings) {
                        const { planTypePeriodLabel } = getAccountPlanStore();
                        return msg.get(
                            'planTooltip',
                            AccountSettingsService.usedLicenses,
                            AccountSettingsService.totalLicenses,
                            planTypePeriodLabel,
                            authorizationService.isMspChildAccount()
                        );
                    }

                    var html = '<div class="text-left">';
                    sections.forEach(function (s, idx) {
                        html += createSection(s);
                        if (idx !== sections.length - 1) {
                            html += '<br/>';
                        }
                    });

                    html += '</div>';

                    return html;
                },
                position: 'bottom',
                animation: {
                    open: {
                        effects: 'fadeIn'
                    }
                },
                show: function (e) {
                    e.sender.popup.wrapper.addClass('bring-to-top');
                    tooltipService.addTooltipMargin(this, 2);
                    tooltipService.fixTooltipCallout(this);
                    this.refresh();
                }
            });

            $scope.onPlanHover = function ($event) {
                if ($event.shiftKey) {
                    $timeout(function () {
                        showAccountSettings = true;
                        var tooltip = element.getKendoTooltip();
                        if (tooltip && typeof tooltip.refresh === 'function') {
                            tooltip.refresh();
                        }
                    }, 2000);
                } else {
                    showAccountSettings = false;
                }
            };

            $scope.impersonatingTooltip = `Impersonating ${$scope.getUsername()}`;
        }
    };
}
