import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const AlarmsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Alarms';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M11.8462 24C13.2 24 14.3077 22.8923 14.3077 21.5385H9.38462C9.38462 22.8923 10.48 24 11.8462 24ZM19.2308 16.6154V10.4615C19.2308 6.68308 17.2123 3.52 13.6923 2.68308V1.84615C13.6923 0.824615 12.8677 0 11.8462 0C10.8246 0 10 0.824615 10 1.84615V2.68308C6.46769 3.52 4.46154 6.67077 4.46154 10.4615V16.6154L2 19.0769V20.3077H21.6923V19.0769L19.2308 16.6154Z"
      />
    </SvgIconWrapper>
  );
};
