import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

export const FormContainer = styled(Paper)`
  padding: 20px;
`;

export const TextSection = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SubmitContainer = styled(Box)`
  text-align: right;
  margin-top: 24px;
`;
