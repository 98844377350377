'use strict';

angular.module('app').service('navigationService', NavigationService);

NavigationService.$inject = [];

function NavigationService() {
    var navigationServiceFunctions = require('_app/navigation/navigationServiceFunctions.js');
    var service = this;
    angular.extend(service, navigationServiceFunctions);
}
