'use strict';

angular.module('app').directive('minupper', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            // re-validate on ngModel change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });

            var validate = function () {
                var password = ngModel.$viewValue;
                var minUpper = parseInt(attrs.minupper);

                ngModel.$setValidity(
                    'minupper',
                    minUpper &&
                        password &&
                        password.replace(/[^A-Z]/g, '').length >= minUpper
                );
            };
        }
    };
});
