import InsightsPromoService from '../../../../_app/insights/insightsPromoService';
import { INSIGHTS_UPGRADE_PLAN_LABEL } from '../../../../_reactivtrak/src/common/constants/plans';

angular.module('app').controller('insightsPromoReportCtrl', InsightsPromoReportCtrl);

InsightsPromoReportCtrl.$inject = [
    '$scope',
    'accountApiHelper',
    'authorizationService',
    'envService',
    'envConfig',
    'accountSettings',
    'notificationService',
    'utmService'
];

function InsightsPromoReportCtrl(
    $scope,
    accountApiHelper,
    authorizationService,
    envService,
    envConfig,
    accountSettings,
    notificationService,
    utmService
) {
    var insightsPromoService = new InsightsPromoService(
        envService.getMarketoConfig(),
        envConfig,
        accountSettings,
        accountApiHelper,
        notificationService,
        utmService,
        authorizationService
    );

    $scope.requestQuote = function () {
        insightsPromoService.requestForm('quoteRequestFormId', 'Quote');
    };

    $scope.planLabel = INSIGHTS_UPGRADE_PLAN_LABEL;
}
