import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Tooltip,
  Typography
} from '@mui/material';
import { IAttachment, ITicket, ITicketComment } from '../models';
import { Modal, Status } from '../enums';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CommentContainerSC,
  CommentDateSC,
  CommentsContainerSC,
  CommentsLoaderContainerSC,
  RefreshTooltipSC
} from '../styles';

type TicketDetailsModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  currentTicket: ITicket;
  currentTicketComments: ITicketComment[];
  onClose: any;
  onOpenModal: (modal: Modal) => void;
  onFollowUpTicket: () => void;
  onLoad: (id: number) => void;
};

export const TicketDetailsModal = (props: TicketDetailsModalProps) => {
  const {
    onOpenModal,
    onClose,
    onLoad,
    onFollowUpTicket,
    isOpen,
    isLoading,
    currentTicket,
    currentTicketComments
  } = props;

  const canAddComments =
    currentTicket?.status !== Status.Closed &&
    currentTicket?.status !== Status.Solved;

  useEffect(() => {
    if (currentTicket?.id) {
      onLoad(currentTicket.id);
    }
  }, [currentTicket?.id, onLoad]);

  const getAttachmentThumbnail = useCallback((attachment: IAttachment) => {
    const button = (
      <Button
        key={`link-${attachment.id}`}
        href={attachment.contentUrl}
        target="_blank"
        sx={{ padding: 0 }}
      >
        <AttachFileOutlinedIcon fontSize="small" />
      </Button>
    );
    if (attachment.thumbnails.length > 0) {
      return (
        <Tooltip
          key={`tooltip-${attachment.id}`}
          placement="right-start"
          enterDelay={0}
          title={
            <>
              <img
                src={attachment.thumbnails[0].contentUrl}
                alt={attachment.fileName}
              />
            </>
          }
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        onClose();
      }}
      aria-labelledby="ticket-details-title"
      aria-describedby="ticket-details-description"
    >
      <DialogTitle id="ticket-details-title">
        Ticket # {currentTicket?.id}
      </DialogTitle>
      <DialogContent id="ticket-details-description">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography>
              <strong>Updated</strong>
            </Typography>
            <Typography>{currentTicket?.updatedAtDisplay}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>
              <strong>Created</strong>
            </Typography>
            <Typography>{currentTicket?.createdAtDisplay}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>
              <strong>Status</strong>
            </Typography>
            <Typography>{currentTicket?.status}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Subject</strong>
            </Typography>
            <Typography>{currentTicket?.subject}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Description</strong>
            </Typography>
            <Typography>{currentTicket?.description}</Typography>
          </Grid>
          {currentTicket?.attachments?.length > 0 && (
            <Grid item xs={12}>
              <Typography>
                <strong>Attachments</strong>
              </Typography>
              {currentTicket?.attachments?.map((attachment) => (
                <Link
                  key={`attachment-${attachment.id}`}
                  href={attachment.contentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.thumbnails.length > 0 && (
                    <img
                      src={attachment.thumbnails[0].contentUrl}
                      alt={attachment.fileName}
                    />
                  )}
                  <Typography>{attachment.fileName}</Typography>
                </Link>
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              <strong>Comments</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {canAddComments && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => onOpenModal(Modal.CreateComment)}
              >
                Add comment
              </Button>
            )}
            {!canAddComments && (
              <Button
                variant="contained"
                color="primary"
                onClick={onFollowUpTicket}
              >
                Add follow-Up ticket
              </Button>
            )}
            <Button
              onClick={() => onLoad(currentTicket?.id)}
              sx={{ marginLeft: '20px' }}
            >
              Refresh comments
            </Button>
            <RefreshTooltipSC
              title="Comments list might take some time to show changes"
              placement="right"
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{ verticalAlign: 'middle' }}
              />
            </RefreshTooltipSC>
          </Grid>
          {isLoading && (
            <Grid item xs={12}>
              <CommentsLoaderContainerSC>
                <CircularProgress />
              </CommentsLoaderContainerSC>
            </Grid>
          )}
          {!isLoading && currentTicketComments?.length > 0 && (
            <Grid item xs={12}>
              <CommentsContainerSC>
                {currentTicketComments?.map((comment) => (
                  <CommentContainerSC key={`comment-${comment.id}`}>
                    {comment.body && comment.body !== '______' && (
                      <Typography>{comment.body}</Typography>
                    )}
                    <Box sx={{ marginTop: '10px' }}>
                      <CommentDateSC>{comment.createdAtDisplay}</CommentDateSC>

                      {comment.attachments?.map(getAttachmentThumbnail)}
                    </Box>
                  </CommentContainerSC>
                ))}
              </CommentsContainerSC>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
