'use strict';

angular.module('app').service('dashboardApiSampleService', [
    function () {
        // jscs:disable disallowQuotedKeysInObjects
        var data = {
            alarms: {
                sscount1: 120,
                emailcount1: 22,
                popupcount1: 41,
                termcount1: 5
            },
            quickstats: {
                webProductive: '25:10',
                webUnproductive: '3:17',
                webUndefined: '10:04',
                webTotal: '38:31',
                appProductive: '22:02',
                appUnproductive: '5:31',
                appUndefined: '12:07',
                appTotal: '39:40'
            },
            thumbnails: [],
            applications: [
                {
                    description: 'Notepad',
                    executable: 'NOTEPAD.EXE',
                    seconds: 875178.0,
                    duration: '243: 06: 18',
                    id: 126,
                    productivity: 0
                },
                {
                    description: 'Microsoft Visual Studio 2017',
                    executable: 'devenv.exe',
                    seconds: 244305.0,
                    duration: '67: 51: 45',
                    id: 735,
                    productivity: 0
                },
                {
                    description: 'Microsoft Visual Studio 2015',
                    executable: 'devenv.exe',
                    seconds: 176800.0,
                    duration: '49: 06: 40',
                    id: 464,
                    productivity: 0
                },
                {
                    description: 'SourceTree',
                    executable: 'SourceTree.exe',
                    seconds: 88563.0,
                    duration: '24: 36: 03',
                    id: 503,
                    productivity: 0
                },
                {
                    description: 'loginwindow',
                    executable: 'com.apple.loginwindow',
                    seconds: 84245.0,
                    duration: '23: 24: 05',
                    id: 367,
                    productivity: 0
                },
                {
                    description: 'HeidiSQL 9.4.0.5125 64 Bit',
                    executable: 'heidisql.exe',
                    seconds: 74375.0,
                    duration: '20: 39: 35',
                    id: 619,
                    productivity: 0
                },
                {
                    description: 'VMware Workstation',
                    executable: 'vmware.exe',
                    seconds: 74283.0,
                    duration: '20: 38: 03',
                    id: 405,
                    productivity: 0
                },
                {
                    description: 'Remote Desktop Connection',
                    executable: 'mstsc.exe',
                    seconds: 52397.0,
                    duration: '14: 33: 17',
                    id: 120,
                    productivity: 0
                },
                {
                    description: 'Windows Explorer',
                    executable: 'Explorer.EXE',
                    seconds: 50739.0,
                    duration: '14: 05: 39',
                    id: 112,
                    productivity: 0
                },
                {
                    description: 'Chrome',
                    executable: 'chrome',
                    seconds: 37371.0,
                    duration: '10: 22: 51',
                    id: 678,
                    productivity: 0
                },
                {
                    description: 'Finder',
                    executable: 'com.apple.finder',
                    seconds: 27731.0,
                    duration: '07: 42: 11',
                    id: 362,
                    productivity: 0
                },
                {
                    description: 'Code',
                    executable: 'com.microsoft.VSCode',
                    seconds: 22309.0,
                    duration: '06: 11: 49',
                    id: 668,
                    productivity: 0
                },
                {
                    description: 'ScreenSaverEngine',
                    executable: 'com.apple.ScreenSaver.Engine',
                    seconds: 17946.0,
                    duration: '04: 59: 06',
                    id: 611,
                    productivity: 0
                },
                {
                    description: 'Xcode',
                    executable: 'com.apple.dt.Xcode',
                    seconds: 13790.0,
                    duration: '03: 49: 50',
                    id: 359,
                    productivity: 0
                },
                {
                    description: 'Other',
                    executable: null,
                    seconds: 220250.0,
                    duration: '61: 10: 50',
                    id: 0,
                    productivity: 0
                }
            ],
            sites: [
                {
                    url: 'https://app.activtrak.com',
                    seconds: 109163.0,
                    duration: '30: 19: 23',
                    id: 4142,
                    productivity: 0
                },
                {
                    url: 'https://slack.com',
                    seconds: 45890.0,
                    duration: '12: 44: 50',
                    id: 4359,
                    productivity: 0
                },
                {
                    url: 'https://app.intercom.io',
                    seconds: 42487.0,
                    duration: '11: 48: 07',
                    id: 4106,
                    productivity: 0
                },
                {
                    url: 'https://atlassian.net',
                    seconds: 38004.0,
                    duration: '10: 33: 24',
                    id: 3903,
                    productivity: 0
                },
                {
                    url: 'http://chrome.google.com',
                    seconds: 36772.0,
                    duration: '10: 12: 52',
                    id: 4260,
                    productivity: 0
                },
                {
                    url: 'chrome://extensions',
                    seconds: 26862.0,
                    duration: '07: 27: 42',
                    id: 2590,
                    productivity: 0
                },
                {
                    url: 'https://image.google.com',
                    seconds: 26120.0,
                    duration: '07: 15: 20',
                    id: 4272,
                    productivity: 0
                },
                {
                    url: 'chrome://',
                    seconds: 21002.0,
                    duration: '05: 50: 02',
                    id: 2855,
                    productivity: 0
                },
                {
                    url: 'https://mail.google.com',
                    seconds: 20322.0,
                    duration: '05: 38: 42',
                    id: 982,
                    productivity: 0
                },
                {
                    url: 'https://story.google.com',
                    seconds: 19632.0,
                    duration: '05: 27: 12',
                    id: 4271,
                    productivity: 0
                },
                {
                    url: 'https://facebook.com',
                    seconds: 16521.0,
                    duration: '04: 35: 21',
                    id: 1398,
                    productivity: 0
                },
                {
                    url: 'https://zendesk.com',
                    seconds: 13848.0,
                    duration: '03: 50: 48',
                    id: 1487,
                    productivity: 0
                },
                {
                    url: 'https://www.google.ru',
                    seconds: 11917.0,
                    duration: '03: 18: 37',
                    id: 4279,
                    productivity: 0
                },
                {
                    url: 'https://activtrak.com',
                    seconds: 11562.0,
                    duration: '03: 12: 42',
                    id: 4393,
                    productivity: 0
                },
                {
                    url: 'Other',
                    seconds: 301222.0,
                    duration: '83: 40: 22',
                    id: 0,
                    productivity: 0
                }
            ],
            categories: [
                { category: 'Banking', total: 388497.0 },
                { category: 'Books', total: 286740.0 },
                { category: 'Reference', total: 177741.0 },
                { category: 'Adult', total: 128870.0 },
                { category: 'Email', total: 122510.0 },
                { category: 'Games', total: 119422.0 },
                { category: 'News', total: 63435.0 },
                { category: 'Sports', total: 49035.0 },
                { category: 'Entertainment', total: 44277.0 },
                { category: 'Uncategorized', total: 216073.0 }
            ],
            productivity: [
                {
                    user: 'James',
                    productive: 868451.0,
                    unproductive: 458.0,
                    idle: 0.0,
                    other: 0.0,
                    total: 868909.0
                },
                {
                    user: 'John',
                    productive: 628524.0,
                    unproductive: 120884.0,
                    idle: 30000.0,
                    other: 23793.0,
                    total: 803201.0
                },
                {
                    user: 'Robert',
                    productive: 158654.0,
                    unproductive: 38757.0,
                    idle: 0.0,
                    other: 9831.0,
                    total: 207242.0
                },
                {
                    user: 'Michael',
                    productive: 133257.0,
                    unproductive: 54908.0,
                    idle: 0.0,
                    other: 0.0,
                    total: 188165.0
                },
                {
                    user: 'William',
                    productive: 71681.0,
                    unproductive: 54202.0,
                    idle: 0.0,
                    other: 4770.0,
                    total: 130653.0
                },
                {
                    user: 'David',
                    productive: 73142.0,
                    unproductive: 19679.0,
                    idle: 0.0,
                    other: 19683.0,
                    total: 112504.0
                },
                {
                    user: 'Richard',
                    productive: 71592.0,
                    unproductive: 9935.0,
                    idle: 0.0,
                    other: 65.0,
                    total: 81592.0
                },
                {
                    user: 'Charles',
                    productive: 69451.0,
                    unproductive: 10362.0,
                    idle: 0.0,
                    other: 348.0,
                    total: 80161.0
                },
                {
                    user: 'Joseph',
                    productive: 13101.0,
                    unproductive: 29957.0,
                    idle: 0.0,
                    other: 19.0,
                    total: 43077.0
                },
                {
                    user: 'Thomas',
                    productive: 22916.0,
                    unproductive: 7881.0,
                    idle: 0.0,
                    other: 6166.0,
                    total: 36963.0
                },
                {
                    user: 'Christopher',
                    productive: 19279.0,
                    unproductive: 13766.0,
                    idle: 0.0,
                    other: 761.0,
                    total: 33806.0
                },
                {
                    user: 'Daniel',
                    productive: 12143.0,
                    unproductive: 2037.0,
                    idle: 0.0,
                    other: 6753.0,
                    total: 20933.0
                },
                {
                    user: 'Paul',
                    productive: 4861.0,
                    unproductive: 12699.0,
                    idle: 0.0,
                    other: 2644.0,
                    total: 20204.0
                },
                {
                    user: 'Mark',
                    productive: 16205.0,
                    unproductive: 2621.0,
                    idle: 0.0,
                    other: 3.0,
                    total: 18829.0
                },
                {
                    user: 'Donald',
                    productive: 1451.0,
                    unproductive: 11247.0,
                    idle: 0.0,
                    other: 5269.0,
                    total: 17967.0
                }
            ],
            groups: {
                totalActivity: 2775357.0,
                rows: [
                    {
                        user: 'Production',
                        userType: 'Group',
                        userId: '137',
                        web: 103.0,
                        app: 0.0,
                        total: 103.0,
                        productive: 670.0,
                        unproductive: 360.0,
                        other: 40.0,
                        idle: 20.0
                    },
                    {
                        user: 'Development',
                        userType: 'Group',
                        userId: '159',
                        web: 0.0,
                        app: 413.0,
                        total: 413.0,
                        productive: 407.0,
                        unproductive: 6.0,
                        other: 0.0,
                        idle: 4681.0
                    },
                    {
                        user: 'Purchasing',
                        userType: 'Group',
                        userId: '167',
                        web: 641.0,
                        app: 17.0,
                        total: 658.0,
                        productive: 624.0,
                        unproductive: 34.0,
                        other: 0.0,
                        idle: 590.0
                    },
                    {
                        user: 'Marketing',
                        userType: 'Group',
                        userId: '136',
                        web: 0.0,
                        app: 2579.0,
                        total: 2579.0,
                        productive: 979.0,
                        unproductive: 0.0,
                        other: 0.0,
                        idle: 1635.0
                    },
                    {
                        user: 'HRM',
                        userType: 'AdGroup',
                        userId: '80',
                        web: 3824.0,
                        app: 2983.0,
                        total: 6807.0,
                        productive: 1363.0,
                        unproductive: 434.0,
                        other: 10.0,
                        idle: 1518.0
                    }
                ],
                error: null
            },
            aggregate: {
                dates: [
                    '01/01/2017\n07/01/2017',
                    '08/01/2017\n14/01/2017',
                    '15/01/2017\n21/01/2017',
                    '22/01/2017\n28/01/2017',
                    '29/01/2017\n04/02/2017',
                    '05/02/2017\n11/02/2017',
                    '12/02/2017\n18/02/2017',
                    '19/02/2017\n25/02/2017',
                    '26/02/2017\n04/03/2017',
                    '05/03/2017\n11/03/2017',
                    '12/03/2017\n18/03/2017',
                    '19/03/2017\n25/03/2017',
                    '26/03/2017\n01/04/2017',
                    '02/04/2017\n08/04/2017',
                    '09/04/2017\n15/04/2017',
                    '16/04/2017\n22/04/2017',
                    '23/04/2017\n29/04/2017',
                    '30/04/2017\n06/05/2017',
                    '07/05/2017\n13/05/2017',
                    '14/05/2017\n20/05/2017',
                    '21/05/2017\n27/05/2017',
                    '28/05/2017\n03/06/2017',
                    '04/06/2017\n10/06/2017',
                    '11/06/2017\n17/06/2017',
                    '18/06/2017\n24/06/2017',
                    '25/06/2017\n01/07/2017',
                    '02/07/2017\n08/07/2017',
                    '09/07/2017\n15/07/2017',
                    '16/07/2017\n22/07/2017',
                    '23/07/2017\n29/07/2017',
                    '30/07/2017\n05/08/2017',
                    '06/08/2017\n12/08/2017',
                    '13/08/2017\n19/08/2017',
                    '20/08/2017\n26/08/2017',
                    '27/08/2017\n02/09/2017',
                    '03/09/2017\n09/09/2017',
                    '10/09/2017\n16/09/2017',
                    '17/09/2017\n23/09/2017',
                    '24/09/2017\n30/09/2017',
                    '01/10/2017\n07/10/2017',
                    '08/10/2017\n14/10/2017',
                    '15/10/2017\n21/10/2017',
                    '22/10/2017\n28/10/2017',
                    '29/10/2017\n04/11/2017',
                    '05/11/2017\n11/11/2017',
                    '12/11/2017\n18/11/2017',
                    '19/11/2017\n25/11/2017',
                    '26/11/2017\n02/12/2017'
                ],
                data: [
                    {
                        productivity: 'productive',
                        totals: [
                            17327, 34705, 37302, 24778, 15159, 8353, 41427, 100543, 70775, 25549, 34000, 21985, 12060,
                            15318, 40890, 37769, 23649, 29668, 52650, 52685, 675690, 255007, 77597, 193, 1240, 9488,
                            12381, 15377, 41685, 35348, 42735, 39507, 60711, 60224, 34331, 35260, 25779, 2114, 5536,
                            62435, 6106, 2262, 3914, 3750, 19045, 17996, 26644, 5334
                        ]
                    },
                    {
                        productivity: 'unproductive',
                        totals: [
                            8495, 26068, 6670, 4315, 33356, 3180, 13540, 17387, 16541, 5870, 12759, 11071, 16666, 14208,
                            15859, 12397, 11108, 3286, 15821, 13096, 5849, 4761, 5864, 864, 1347, 2974, 4306, 2621,
                            6661, 2365, 9860, 10639, 28110, 8061, 8482, 10152, 8142, 959, 3271, 16506, 1629, 1159, 363,
                            3564, 5083, 3878, 6771, 3048
                        ]
                    },
                    {
                        productivity: 'undefined',
                        totals: [
                            878, 1012, 135, 1441, 1937, 343, 325, 800, 2422, 1100, 648, 74, 110, 149, 1497, 124, 1888,
                            3520, 738, 1985, 1515, 79, 90, 7, 411, 875, 188, 209, 3137, 105, 2722, 4145, 4355, 9144,
                            1773, 3424, 2704, 160, 1958, 23382, 1840, 10, 1243, 40, 2855, 5938, 3456, 1452
                        ]
                    },
                    {
                        productivity: 'idle',
                        totals: [
                            39649, 101797, 88513, 52908, 11780, 5201, 4808, 55391, 97197, 261295, 36945, 76674, 22625,
                            98170, 93097, 84159, 66645, 108428, 131656, 79931, 65443, 43599, 38495, 347807, 5392, 26498,
                            35380, 174487, 226873, 78611, 58994, 278953, 123464, 39731, 64794, 70832, 498723, 3826,
                            13119, 117355, 59861, 1116, 3427, 92571, 45904, 47147, 61204, 40768
                        ]
                    }
                ]
            }
        };

        // jscs:disable disallowQuotedKeysInObjects

        // Use when widget uses .success() structure
        function buildPromise(data) {
            return {
                success: function (callback) {
                    callback(data);
                    return this;
                },
                error: function () {}
            };
        }

        // Use when widget uses .then() structure
        function buildThenPromise(data) {
            return new Promise(function (resolve) {
                resolve({ data: data });
            });
        }

        this.getAlarms = buildPromise(data.alarms);

        this.getQuickStats = buildThenPromise(data.quickstats);

        this.getThumbnails = buildPromise(data.thumbnails);

        this.getApplications = buildPromise(data.applications);

        this.getSites = buildPromise(data.sites);

        this.getCategories = buildPromise(data.categories);

        this.getProductivity = buildPromise(data.productivity);

        this.getAggregate = buildPromise(data.aggregate);

        this.getUsers = buildPromise(data.groups);
    }
]);
