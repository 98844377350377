import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { IGroupMember } from '../models';
import { IGroup } from '../../common/models';
import RemoveMemberIcon from '../../common/assets/Icons/RemoveMemberIcon';
import { BulkRemoveMemberButtonSC } from '../styles/Group.styles';

type Prop = {
  members: IGroupMember[];
  group: IGroup;
  disabled: boolean;
  onDelete: (members: IGroupMember[]) => void;
};

export default function GroupBulkRemoveMemberButton(props: Prop) {
  const { disabled, group, members, onDelete } = props;
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const disable = disabled || (members?.length ?? 0) === 0;

  const handleRemoveClick = () => {
    setOpenConfirmModal(true);
  };

  const close = () => {
    setOpenConfirmModal(false);
  };

  const handleDelete = async () => {
    await onDelete(members);
    close();
  };

  return (
    <>
      <BulkRemoveMemberButtonSC
        disabled={disable}
        onClick={handleRemoveClick}
        color="secondary"
      >
        <RemoveMemberIcon disabled={disable} />
        &nbsp;Remove Member
      </BulkRemoveMemberButtonSC>
      <Dialog
        open={openConfirmModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          close();
        }}
      >
        <DialogTitle>Confirm Group Member Deletion</DialogTitle>
        <DialogContent id="delete-groupmembers-dialog-content">
          <Box>
            {members?.length === 1 ? (
              <>
                Are you sure you want to remove{' '}
                <strong>{members[0]?.name}</strong> from the{' '}
                <strong>{group?.name}</strong> group?
              </>
            ) : (
              <>
                Are you sure you want to remove these{' '}
                <strong>{members?.length}</strong> group members from the{' '}
                <strong>{group?.name}</strong> group?
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={close} autoFocus>
            No
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
