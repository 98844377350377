import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ChangeEvent, useState } from 'react';
import { IDntDeleteState } from '../models/IDntDeleteState';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { IUserIdentity } from '../models/IUserIdentity';
type ComponentProps = {
  dntState: IDntDeleteState;
  commonState: IIdentityCommonState;
  handleIdentityGridChange: (
    oldUser: IUserIdentity,
    newUser: IUserIdentity
  ) => void;
};

export const DeleteUser = (props: ComponentProps) => {
  const { dntState, commonState, handleIdentityGridChange } = props;
  const [agreeToDelete, setAgreeToDelete] = useState<boolean>(false);

  const resetAndCloseModal = () => {
    setAgreeToDelete(false);
    dntState.setOpenDeleteModal(false);
  };

  const onStopTrackingDeleteData = () => {
    if (agreeToDelete) {
      dntState
        .deleteUser(dntState.userToModify)
        .then(([error, newIdentity]) => {
          if (!error) {
            commonState.setIdentityNotification({
              msg: 'Data deleted successfully',
              type: 'success'
            });
            handleIdentityGridChange(dntState.userToModify, newIdentity);
          } else {
            commonState.setIdentityNotification({
              msg: 'Error occurred while deleting data',
              type: 'error'
            });
          }
        });
    }
    resetAndCloseModal();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setAgreeToDelete(e.target.checked);
  };

  return (
    <Dialog
      open={dntState.openDeleteModal}
      onClose={resetAndCloseModal}
      maxWidth="sm"
    >
      <DialogTitle>Delete historical Data?</DialogTitle>
      <DialogContent id="dnt-dialog-content" sx={{ pb: '2' }}>
        <Box>
          Please confirm you want to permanently delete all activity data for
          this user
        </Box>
        <Box sx={{ padding: '16px 0px 8px 0px' }}>
          Deleting the user's activity data will:
          <ul>
            <li>
              Permanently erase all historical data collected for the user's
              assigned agents. This is irreversible.
            </li>
            <li>
              Resume data collection if the user logs in again, unless their
              assigned agents are first uninstalled.
            </li>
          </ul>
        </Box>
        <Box sx={{ fontWeight: '500', paddingBottom: '8px' }}>
          This action cannot be undone!
        </Box>
        <Box>
          <FormControlLabel
            value="StopTracking"
            control={
              <Checkbox
                checked={agreeToDelete}
                onChange={handleChange}
                value="StopTracking"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'StopTracking' }}
              />
            }
            label="I understand this action will permanently delete the user's historical data."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndCloseModal}>
          Cancel
        </Button>
        <Button
          onClick={onStopTrackingDeleteData}
          variant="contained"
          color="primary"
          disabled={!agreeToDelete}
        >
          Delete historical Data
        </Button>
      </DialogActions>
    </Dialog>
  );
};
