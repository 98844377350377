import React from 'react';
import Typography from '@mui/material/Typography';
import { activationViewClasses } from '../styles/AgentActivationView.styles';

export const ActivationHeader = () => {
  return (
    <>
      <Typography
        variant="h1"
        fontWeight={500}
        sx={activationViewClasses.header}
      >
        Deploy your first agent to get started!
      </Typography>
      <Typography sx={activationViewClasses.subHeading} fontWeight={400}>
        Start collecting activity data and unlock access to reports and
        dashboards
      </Typography>
    </>
  );
};
