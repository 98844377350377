import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export const ArrowUpIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? '16px';
  const width = props.width ?? '16px';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 10 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="at-icon-arrow at-icon-arrow-up"
    >
      <title>Arrow Up</title>
      <g
        id="arrow-up"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="arrow-up-child" transform="translate(-569.000000, -21.000000)">
          <g id="Group" transform="translate(565.800246, 20.000000)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M3.80666667,6.46666667 L3.80666667,6.46666667 C4.06666667,6.72666667 4.48666667,6.72666667 4.74666667,6.46666667 L7.33333333,3.88666667 L7.33333333,14 C7.33333333,14.3666667 7.63333333,14.6666667 8,14.6666667 L8,14.6666667 C8.36666667,14.6666667 8.66666667,14.3666667 8.66666667,14 L8.66666667,3.88666667 L11.2533333,6.47333333 C11.5133333,6.73333333 11.9333333,6.73333333 12.1933333,6.47333333 L12.1933333,6.47333333 C12.4533333,6.21333333 12.4533333,5.79333333 12.1933333,5.53333333 L8.46666667,1.8 C8.20666667,1.54 7.78666667,1.54 7.52666667,1.8 L3.80666667,5.52666667 C3.54666667,5.78666667 3.54666667,6.21333333 3.80666667,6.46666667 Z"
              id="Path"
              fill={fillColor}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
