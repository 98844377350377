import { react2angular } from 'react2angular';
import { ClassificationsWrapper } from '../../_reactivtrak/src/classifications/views/Classifications.view';
import { ClassificationsViewWidget } from '../../_reactivtrak/src/classifications/pending.component';

export const connect = (angular) => {
  angular
    .module('app')
    .component('settingsClassification', react2angular(ClassificationsWrapper, []));

  angular.module('app').component('settingsClassificationWidget', react2angular(ClassificationsViewWidget, []));
};
