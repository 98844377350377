import React, { useEffect } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { StorageInformationService } from '../services/StorageInformationService';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import Typography from '@mui/material/Typography';
import { useStorageInformationState } from '../hooks/useStorageInformationState';

export const StorageInformationCard = () => {
  const service = StorageInformationService();
  const notificationService = useNotifications();
  const settingState = useStorageInformationState({
    service,
    notificationService
  });
  const { information, init } = settingState;

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ConfigurationCardWrapper title="Storage Information">
      <Typography sx={{ marginBottom: '16px' }}>
        View aggregate account storage information for your account.
      </Typography>
      <Typography sx={{ marginBottom: '16px' }}>
        Total Storage Used:&nbsp;
        <strong>{information?.totalStorage}</strong>
      </Typography>
      <Typography sx={{ marginBottom: '16px' }}>
        Total Logs:&nbsp;
        <strong>{information?.totalLog}</strong>
      </Typography>
      <Typography sx={{ marginBottom: '16px' }}>
        Total Screenshots:&nbsp;
        <strong>{information?.totalScreenshot}</strong>
      </Typography>
      <Typography sx={{ marginBottom: '16px' }}>
        Total Videos:&nbsp;
        <strong>{information?.totalVideo}</strong>
      </Typography>
    </ConfigurationCardWrapper>
  );
};
