export const formatComputerComplexName = (
  windowsDomain?: string,
  computer?: string,
  alias?: string
): string => {
  let labelText = ``;
  if (windowsDomain) {
    labelText += `${windowsDomain}`;
  }
  if (computer) {
    if (labelText.length) {
      labelText += `${String.fromCharCode(92)}`;
    }
    labelText += `${computer}`;
  }
  if (alias) {
    if (labelText.length) {
      labelText += ` `;
    }
    labelText += `(${alias})`;
  }
  return labelText;
};
