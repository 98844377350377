import theme from '../../theme/theme';

export const ccTableViewClasses = {
  cardContainer: {
    display: 'grid',
    gap: `${theme.spacing(2)} ${theme.spacing()}`,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(48%, 1fr))`
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${theme.cardMinWidth}, 1fr))`
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(
        23
      )}, 1fr))`
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))'
    }
  }
};
