import { react2angular } from '../../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { AlarmLogViewComponent } from '../../../../_reactivtrak/src/reports/alarms/views/AlarmLog.view.tsx';
import { alarmlogTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

/// This wraps the legacy report in a new component
/// using the wrapped- prefix to mark wrapped components
angular.module('app').component('wrappedAlarmLog', {
    template: alarmlogTemplate,
    controller: 'AlarmLogCtrl'
});

/// This now replaces the route level component
/// This is referenced in the component section of the route definition
angular.module('app').component('alarmLog', react2angular(AlarmLogViewComponent, []));
