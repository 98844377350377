import {
  IPrivacySettings,
  IPrivacyFeatureSettings
} from '../models/IPrivacySettings';

const BASIC_PRIVACY_DETAILS: IPrivacySettings = {
  screenshotsAllowed: false,
  activityAllowed: false,
  alarmActionsAllowed: false
};

const ADVANCED_PRIVACY_DETAILS: IPrivacySettings = {
  screenshotsAllowed: false,
  activityAllowed: true,
  alarmActionsAllowed: false
};

const FULL_PRIVACY_DETAILS: IPrivacySettings = {
  screenshotsAllowed: true,
  activityAllowed: true,
  alarmActionsAllowed: true
};

export const PRIVACY_SETTINGS_BY_LEVEL = {
  ['2:0:basicdetails']: BASIC_PRIVACY_DETAILS,
  ['2:1:advanceddetails']: ADVANCED_PRIVACY_DETAILS,
  ['2:2:fulldetails']: FULL_PRIVACY_DETAILS
};

export const BASIC_PRIVACY_SETTINGS: IPrivacyFeatureSettings = {
  screenshots: {
    enabled: false
  },
  activity: {
    enabled: false
  },
  alarms: {
    enabled: false
  }
};

export const ADVANCED_PRIVACY_SETTINGS: IPrivacyFeatureSettings = {
  screenshots: {
    enabled: false
  },
  activity: {
    enabled: true
  },
  alarms: {
    enabled: false
  }
};

export const FULL_PRIVACY_SETTINGS: IPrivacyFeatureSettings = {
  screenshots: {
    enabled: true
  },
  activity: {
    enabled: true
  },
  alarms: {
    enabled: true
  }
};
