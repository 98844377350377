'use strict';

angular.module('app').controller('changeGroupsModalController', ChangeGroupsModalController);

ChangeGroupsModalController.$inject = [
  '$scope',
  '$timeout',
  '$uibModalInstance',
  'modalData',
  'accessApiService',
  'notificationService',
  'messagesService',
  'authorizationService',
  'atHelperFunctions'
];

function ChangeGroupsModalController(
  $scope,
  $timeout,
  $uibModalInstance,
  modalData,
  accessApiService,
  notificationService,
  msg,
  authorizationService,
  atHelperFunctions
) {
  var planType = require('../../../../_reactivtrak/src/common/constants/plans').INSIGHTS_UPGRADE_PLAN_LABEL;

  $scope.dismiss = function () {
    $uibModalInstance.dismiss();
  };

  $scope.currentUserRole = authorizationService.getRole(modalData.currentUser.userRole);
  $scope.isAdmin =
      $scope.currentUserRole.key === authorizationService.applicationRoles.admin.key ||
      $scope.currentUserRole.key === authorizationService.applicationRoles.configurator.key;
  $scope.viewableGroupsGridHeight = 530;
  $scope.isActivInsights = modalData.isActivInsights;
  $scope.isNewUser = modalData.isNewUser;
  $scope.hasViewLevel = modalData.hasViewLevel;
  $scope.currentUser = modalData.currentUser;
  $scope.currentUserName = modalData.currentUserName;
  $scope.planLabel = planType;
  $scope.insightsEnabled = authorizationService.hasFeature('isInsightsEnabled');

  var accessDataSource = modalData.accessDataSource;

  $scope.getModalDataSource = function () {
      return modalData.availableGroups.filter(function (el) {
          if (el.groupid === -1) {
              el.name = msg.get('allUsersAndGroups') + ' (current & future)';
          }
          return el.display;
      });
  };

  $scope.handleSelectionOnInit = function () {
      if ($scope.isAdmin) {
          angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
              if (item.groupid === -1) {
                  item.selected = true;
                  if ($scope.isActivInsights) {
                      item.goalSelected = true;
                  }
              } else {
                  item.selected = false;
                  if ($scope.isActivInsights) {
                      item.goalSelected = false;
                  }
              }
          });
          return;
      }

      var allUsersAndGroups = $scope.viewableGroupsGrid.dataSource.data().find(function (item) {
          return item.groupid === -1;
      });

      if (allUsersAndGroups.selected || allUsersAndGroups.goalSelected) {
          return;
      }

      angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
          if (item.groupid === -2 || item.groupid === -3) {
              item.selectedUndetermined = !$scope.isAllGroupsSelected('selected');
              item.goalSelectedUndetermined = !$scope.isAllGroupsSelected('goalSelected');
              item.selected = $scope.isAnyGroupSelected('selected');
              item.goalSelected = $scope.isAnyGroupSelected('goalSelected');
          }
      });
  };

  $scope.isAnyGroupSelected = function (selectionAccessor) {
      return $scope.viewableGroupsGrid.dataSource.data().some(function (group) {
          return group[selectionAccessor];
      });
  };

  $scope.noGroupSelected = function (group, selectionAccessor) {
      if ($scope.isAllUsersAndGroups(group)) {
          return true;
      }

      return $scope.viewableGroupsGrid.dataSource.data().every(function (group) {
          if ($scope.isAllUsersAndGroups(group)) {
              return true;
          }
          return !group[selectionAccessor];
      });
  };

  $scope.isAllGroupsSelected = function (selectionAccessor) {
      return $scope.viewableGroupsGrid.dataSource.data().every(function (group) {
          if ($scope.isAllUsersAndGroups(group)) {
              return true;
          }
          return group[selectionAccessor];
      });
  };

  $scope.isAllGroupsViewableForeverSelected = function () {
      return $scope.viewableGroupsGrid.dataSource.data().some(function (group) {
          return group.groupid === -1 && group.selected;
      });
  };

  $scope.selectAllGroups = function (group, selectionAccessor) {
      if (group.groupid === -1) {
          angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
              if (item.groupid !== group.groupid) {
                  item[selectionAccessor] = group.selected ? false : item[selectionAccessor];
                  item.goalSelected = group.goalSelected ? false : item.goalSelected;
              }
          });
          return;
      }

      var foreverAllGroupsAreViewable = $scope.isAllGroupsViewableForeverSelected();

      angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
          if (selectionAccessor === 'goalSelected') {
              item.goalSelected = item.selected || foreverAllGroupsAreViewable;
          } else {
              item[selectionAccessor] = true;
          }

          if ($scope.isAllUsersAndGroups(item)) {
              if (item.groupid !== group.groupid) {
                  item[selectionAccessor] = false;
              }
              item[`${selectionAccessor}Undetermined`] = false;
          }
      });
  };

  $scope.unselectGroupFields = function (group, selectionAccessor) {
      angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
          if (item.groupid === -2 || item.groupid === -3) {
              item[`${selectionAccessor}Undetermined`] = true;
          }
          if (item.groupid === group.groupid) {
              item[selectionAccessor] = false;
          }
      });

      if ($scope.noGroupSelected(group, selectionAccessor)) {
          angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
              if (item.groupid === -2 || item.groupid === -3) {
                  item[`${selectionAccessor}Undetermined`] = false;
              }
              item[selectionAccessor] = false;
              item.goalSelected = false;
          });
      }
  };

  $scope.groupSelected = function (group, selectionAccessor) {
      if (!group[selectionAccessor]) {
          if (selectionAccessor === 'selected') {
              $scope.unselectGroupFields(group, 'goalSelected');
          }
          $scope.unselectGroupFields(group, selectionAccessor);
          return;
      }

      if ($scope.isAllUsersAndGroups(group)) {
          $scope.selectAllGroups(group, selectionAccessor);
          return;
      }

      if ($scope.isAllGroupsSelected(selectionAccessor)) {
          angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
              if (item.groupid === -2 || item.groupid === -3) {
                  item[`${selectionAccessor}Undetermined`] = false;
              }
          });
          return;
      }

      angular.forEach($scope.viewableGroupsGrid.dataSource.data(), function (item) {
          if (item.groupid === -2 || item.groupid === -3) {
              item[selectionAccessor] = true;
              item[`${selectionAccessor}Undetermined`] = true;
          }
          if (item.groupid === -1) {
              item[selectionAccessor] = false;
              if (selectionAccessor === 'selected') {
                  item.goalSelected = false;
              }
          }

          if (selectionAccessor === 'selected') {
              item.goalSelected = ($scope.isAllGroupsSelected(selectionAccessor) || item.selected) && item.goalSelected;
          }
      });
  };

  $scope.isViewFieldSelected = function (item) {
      return !$scope.viewableGroupsGrid.dataSource.data().some(function (group) {
          if (group.groupid === -1) {
              return group.selected;
          }
          return group.selected && item.groupid === group.groupid;
      });
  };

  $scope.isAllUsersAndGroups = function (group) {
      return group.groupid === -1 || group.groupid === -2 || group.groupid === -3;
  };

  $scope.saveUpdateUserGroups = function () {
      if (!$scope.isAnyGroupSelected('selected') || $scope.isAdmin || !$scope.hasViewLevel('edit')) {
          return;
      }

      var groups = $scope.viewableGroupsGrid.dataSource.data().reduce(function (acc, val) {
          if ((val.selected || val.goalSelected) && val.groupid !== -3) {
              acc.push(val);
          }
          return acc;
      }, []);

      var payload = {
          userName: $scope.currentUser.user,
          groups: groups
      };

      accessApiService
          .saveGroups(payload)
          .success(function () {
              $scope.dismiss();
              accessDataSource.read();
              notificationService.showNotification(msg.get('userGroupsUpdated'), 'success');
          })
          .error(function () {
              $scope.dismiss();
              notificationService.showNotification(msg.get('userUpdatingError'), 'danger');
          });
  };

  $scope.viewableGroupsGridOptions = {
      dataSource: $scope.getModalDataSource(),
      sortable: false,
      resizable: false,
      scrollable: false,
      height: atHelperFunctions.getGridHeight($scope.viewableGroupsGridHeight),
      columns: [
          {
              field: ' ',
              template: kendo.template(function (data) {
                  return data.icon;
              })
          },
          {
              field: 'selected',
              title: 'View',
              template: `
          <div class="checkbox m-t-6-i" ng-class="{'semi': dataItem.selectedUndetermined}">
            <input type="checkbox" id="selectbox-{{dataItem.uid}}" ng-disabled="isAdmin || !hasViewLevel('edit')" ng-model="dataItem.selected" ng-change="groupSelected(dataItem, 'selected')" />
            <label for="selectbox-{{dataItem.uid}}"></label>
          </div>
        `,
              attributes: {
                  class: 'text-center'
              },
              width: '110px'
          },
          {
              field: 'goalSelected',
              title: 'Set Goals',
              template: `
          <div class="checkbox m-t-6-i" ng-class="{'semi': dataItem.goalSelectedUndetermined}">
            <input type="checkbox" id="goal-selectbox-{{dataItem.uid}}" ng-disabled="isAdmin || !hasViewLevel('edit') || isViewFieldSelected(dataItem) || !isActivInsights" ng-model="dataItem.goalSelected" ng-change="groupSelected(dataItem, 'goalSelected')" />
            <label for="goal-selectbox-{{dataItem.uid}}"></label>
          </div>
          `,
              attributes: {
                  class: 'text-center'
              },
              width: '110px'
          }
      ],
      dataBound: function () {
          $timeout(function () {
              $scope.viewableGroupsGrid.resizeGrid();
              $scope.handleSelectionOnInit();
          });
      }
  };
}
