import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsAliases: IRoute = {
  name: 'app.settings.aliases',
  stateDefinition: {
    url: '/settings/aliases',
    template: '<settings-aliases></settings-aliases>',
    data: { pageTitle: 'Aliases' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsAliases;
