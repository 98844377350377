import { react2angular } from 'react2angular';
import { GroupsComponent } from '../../_reactivtrak/src/groups/views/Groups.view';
import { GroupComponent } from '../../_reactivtrak/src/groups/views/Group.view';

export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsGroups', react2angular(GroupsComponent, []));

    angular
    .module('app')
    .component('settingsGroupsDetail', react2angular(GroupComponent, []));


}