import React from 'react';
import { Box, CardContent, Divider, Typography } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import {
  CompanyNameDisplay,
  DetailsButton,
  EmailDisplay,
  RedirectButtonGroup
} from './';
import { IAccountCredentials } from '../models/IAccountData';
import { IAccountCardProps } from '../models/IComponentProps';
import {
  AccountInfoContainer,
  ButtonOutline,
  CardActionsButtonGroup
} from '../styles/supportPortal.styles';

export const AccountCard = (props: IAccountCardProps) => {
  const { account, onRemove } = props;
  const {
    accountId,
    associatedEmail,
    companyName,
    payload,
    tokenData,
    userCount
  } = account;

  const accountCredentials: IAccountCredentials = {
    email: associatedEmail,
    id: accountId,
    tokenData: tokenData,
    isImpersonating: payload.impersonate
  };

  return (
    <AccountInfoContainer>
      <CardContent>
        <Box>
          <CompanyNameDisplay companyName={companyName} />
          <Typography variant="h6">Acct#{accountId}</Typography>
          <Typography py={2}>Users: {userCount}</Typography>
          <EmailDisplay
            impersonating={payload.impersonate}
            email={associatedEmail}
          />
        </Box>
      </CardContent>
      <Divider variant="middle" />
      <CardActionsButtonGroup component="div">
        <ButtonOutline onClick={() => onRemove(accountId)}>
          <DeleteForever />
        </ButtonOutline>
        <DetailsButton accountCredentials={accountCredentials} />
        <RedirectButtonGroup accountId={accountId} />
      </CardActionsButtonGroup>
    </AccountInfoContainer>
  );
};
