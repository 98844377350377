class menuAction {
    constructor({ type, options = {} } = {}) {
        this.type = type;
        this.options = options;
    }

    /**
     * Return true if menu action is an internal type
     */
    isInternalAction() {
        return this.type === 'internal';
    }

    /**
     * Return true if menu action is an external type
     */
    isExternalAction() {
        return this.type === 'external';
    }

    /**
     * Return true if menu action is an event type
     */
    isEventAction() {
        return this.type === 'event';
    }

    /**
     * Return true if menu action is a toggle submenu type
     */
    isToggleSubmenuAction() {
        return this.type === 'toggleSubmenu';
    }

    /**
     * Return true if menu action matches current state name
     * and optional id
     *
     * @param {Object} currentState
     */
    isActiveMenu({ stateName, modelId = null, pageId = null } = {}) {
        if (
            !stateName ||
            typeof stateName !== 'string' ||
            (pageId !== null && typeof pageId !== 'string' && isNaN(pageId))
        ) {
            console.error(
                'ActivTrak Error: Cannot determine if menu item is active due to bad inputs.',
                stateName,
                pageId
            );
            return false;
        }

        // Only internal pages can be considered active.
        // The state name and page id must match the action options.
        // Page id is optional and should be skipped if not
        // part of the action options.
        return (
            (this.isInternalAction() &&
                this.isActiveState({ stateName, modelId, pageId })) ||
            (this.isToggleSubmenuAction() && this.keepOpen(stateName))
        );
    }

    isActiveState({ stateName, modelId, pageId }) {
        // console.log('hasMenuItem isActiveState', stateName);
        return (
            this.options.ref === stateName &&
            ((modelId === null && !this.options.modelId) ||
                this.options.modelId === (modelId && modelId.toString())) &&
            ((pageId === null && !this.options.pageId) ||
                this.options.pageId === (pageId && pageId.toString()))
        );
    }

    getPageCategory() {
        const startIndex = this.options.pageName.indexOf('::') < 0;
        const endIndex = this.options.pageName.indexOf('__' < 0);
        if (!this.hasPageName() || startIndex < 0 || endIndex < 0) {
            return undefined;
        }

        return this.options.pageName.substring(startIndex, endIndex);
    }

    getPageName() {
        return this.options.pageName;
    }

    hasPageName() {
        return typeof this.options.pageName === 'string';
    }

    getSubNavigation() {
        return this.options.subNavigation;
    }

    keepOpen(stateName) {
        return _.includes(this.options.keepOpenStates, stateName);
    }

    isFallbackDashboard() {
        return this.options.fallbackDashboard;
    }

    getGoalSettings() {
        return this.options.goalSettings;
    }

    getTag() {
        return this.options.tag;
    }

    getDefaultSubPage() {
        return  this.options.initialSubPageId || this.options.pageId;
    }

    getRouteName() {
        return this.options.ref;
    }
}

export default menuAction;
