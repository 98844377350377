import React, { useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { GenericValidationMessages } from '../constants/IntegrationConfigurationValidationMessages';
import { validGuid } from '../../common/helpers';

export const ConfigureBaseForm = ({
  tenantId,
  clientId,
  clientSecret,
  onTenantIdChange,
  onClientIdChange,
  onClientSecretChange,
  tenantIdError,
  setTenantIdError,
  clientIdError,
  setClientIdError
}) => {
  const [showSecretValue, setShowSecretValue] = useState(false);

  const handleClickShowSecretValue = () => {
    setShowSecretValue((prev) => !prev);
  };

  const handleTenantIdChangeWithValidation = (e) => {
    const value = e.target.value;
    onTenantIdChange(e);
    setTenantIdError(value.length > 1 && !validGuid(value));
  };

  const handleClientIdChangeWithValidation = (e) => {
    const value = e.target.value;
    onClientIdChange(e);
    setClientIdError(value.length > 1 && !validGuid(value));
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="body1">
        Please enter the service account credentials to enable the integration.
      </Typography>
      <Box>
        <TextField
          label="Tenant ID"
          required
          variant="outlined"
          onChange={handleTenantIdChangeWithValidation}
          value={tenantId}
          fullWidth
          sx={{ mb: 3 }}
          color="primary"
          error={tenantIdError}
          helperText={
            tenantIdError ? GenericValidationMessages.InvalidGUID : ''
          }
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label="Client ID"
          required
          variant="outlined"
          onChange={handleClientIdChangeWithValidation}
          value={clientId}
          fullWidth
          sx={{ mb: 3 }}
          color="primary"
          error={clientIdError}
          helperText={
            clientIdError ? GenericValidationMessages.InvalidGUID : ''
          }
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          label="Client Secret Value"
          name="clientSecret"
          type={showSecretValue ? 'text' : 'password'}
          required
          variant="outlined"
          onChange={onClientSecretChange}
          value={clientSecret}
          fullWidth
          sx={{ mb: 3 }}
          color="primary"
          autoComplete="new-password"
          inputProps={{ maxLength: 100 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowSecretValue} edge="end">
                  {showSecretValue ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  );
};
