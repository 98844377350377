'use strict';

angular.module('app').service('videosService', VideosService);

VideosService.$inject = ['$http', '$filter', '$q', '$window', 'envConfig'];

function VideosService($http, $filter, $q, $window, envConfig) {
    var service = this;

    service.getVideos = function(parameters, data) {
        return $http.get(envConfig.apiUrl() + '/api/video?' + parameters, data);
    };

    service.downloadVideo = function(url) {
        return $http.get(url, { responseType: 'blob' });
    };

    service.getVideoSettings = function() {
        return $http.get(envConfig.apiUrl() + '/api/video/video_settings');
    };

    //You should probably call this with the modified result from get video settings...
    service.setVideoSettings = function(video_settings) {
        return $http.post(
            envConfig.apiUrl() + '/api/video/video_settings',
            video_settings
        );
    };

    service.deleteAll = function() {
        return $http.delete(envConfig.apiUrl() + '/api/videos');
    };

    // Temporary call until full video settings system is implemented.  Currently prerecord is all that is used on front-end
    service.setVideoPrerecord = function(prerecord) {
        var deferred = $q.defer();
        service.getVideoSettings().then(function(response) {
            var settings = response.data;
            settings.preRecord = prerecord;
            service
                .setVideoSettings(settings)
                .then(function(result) {
                    deferred.resolve(result);
                })
                .catch(function(error) {
                    deferred.reject(error);
                });
        });
        return deferred.promise;
    };

    service.getVideosWizardAlarms = function() {
        var deferred = $q.defer();
        $http.get(envConfig.apiUrl() + '/api/alarms').success(function(result) {
            var alarms = $filter('filter')(result, {
                video: true,
                popup: false,
                email: false,
                terminate: false
            });
            var wizardAlarms = findVideoWizardAlarms(alarms);
            deferred.resolve(wizardAlarms);
        });
        return deferred.promise;
    };

    service.getVideosRowSize = function() {
        var width = $window.innerWidth;
        // these correspond with the column settings in _alarmVideos.scss
        if (width < 850) {
            return 1;
        } else if (width < 1450) {
            return 2;
        } else if (width < 1750) {
            return 3;
        } else {
            return 4;
        }
    };

    service.getClosestMultipleOfRowSize = function(size, videosPerRow) {
        var rowCount = Math.round(size / videosPerRow);
        return rowCount * videosPerRow;
    };

    service.getPageSizes = function(videosPerRow) {
        var self = this;
        return [16, 32, 64, 128, 256, 512].map(function(item) {
            return '' + self.getClosestMultipleOfRowSize(item, videosPerRow);
        });
    };
}

/**
 * Filters out all alarms which are not compatible with the configure video tool
 */
function findVideoWizardAlarms(alarms) {
    var defaultVideoAlarm;

    // find default video alarm
    for (var i = 0; i < alarms.length; i++) {
        if (alarms[i].default) {
            defaultVideoAlarm = alarms[i];
            break;
        }
    }

    var wizardAlarms = alarms
        .filter(function(item) {
            return (
                !item.default &&
                item.type === 'Activity' &&
                item.conditions.length > 0 &&
                item.conditions.length < 4 &&
                !hasDuplicateFields(item.conditions) &&
                areConditionsCompatible(item.conditions)
            );
        })
        .map(fixConditionsOrder);

    if (defaultVideoAlarm !== undefined) {
        wizardAlarms.unshift(defaultVideoAlarm);
    }
    return wizardAlarms;
}

/**
 * Checks if the conditions of an alarm are compatible with the video configuration page
 */
function areConditionsCompatible(conditions) {
    var hasTitlebar = false;
    var hasComputerOrUser = false;

    for (var i = 0; i < conditions.length; i++) {
        if (
            conditions[i].casesensitive ||
            ['Titlebar', 'Computer', 'User', 'DurationSeconds'].indexOf(
                conditions[i].field
            ) < 0 ||
            (conditions[i].field === 'Titlebar' &&
                conditions[i].op !== 'Contains') ||
            (conditions[i].field === 'Computer' &&
                conditions[i].op !== 'EqualTo') ||
            (conditions[i].field === 'User' && conditions[i].op !== 'EqualTo')
        ) {
            return false;
        }

        if (conditions[i].field === 'Titlebar') {
            hasTitlebar = true;
        }

        if (
            conditions[i].field === 'Computer' ||
            conditions[i].field === 'User'
        ) {
            hasComputerOrUser = true;
        }
    }

    return hasTitlebar && hasComputerOrUser;
}

/**
 * Checks if there are multiple conditions for an alarm applying to the same field (e.g. titlebar, user, computer).
 * The video config tool does not support alarms with multiple conditions applying to the same field.
 */
function hasDuplicateFields(conditions) {
    for (var i = 0; i < conditions.length; i++) {
        for (var j = 0; j < conditions.length; j++) {
            if (i === j) {
                continue;
            } else if (conditions[i].field === conditions[j].field) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Reorders the conditions of an (video-config-compatible) alarm as duration first, titlebar second and user
 * or computer third. This is necessary for the Kendo grid templates to work.˜
 */
function fixConditionsOrder(alarm) {
    var orderedConditions = [];
    for (var i = 0; i < alarm.conditions.length; i++) {
        switch (alarm.conditions[i].field) {
            case 'Titlebar':
                orderedConditions[0] = alarm.conditions[i];
                break;
            default:
                orderedConditions[1] = alarm.conditions[i];
        }
    }
    alarm.conditions = orderedConditions;
    return alarm;
}
