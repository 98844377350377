import * as React from 'react';
import { Typography, Box } from '@mui/material';

export const AboutIntegrationGetStarted = ({ children }) => {
  return (
    <>
      <Typography variant="h6">Get Started</Typography>
      <Box>{children}</Box>
    </>
  );
};
