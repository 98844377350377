import { IQuickLink } from '../models/IConstants';

export const QUICK_LINKS: IQuickLink[] = [
  {
    label: 'User Agents',
    route: '/settings/useragents',
    pathName: 'settings.useragents'
  },
  {
    label: 'Computer Agents',
    route: '/settings/computeragents',
    pathName: 'settings.computeragents'
  },
  {
    label: 'Alarm Log',
    route: '/reports/alarmlog',
    pathName: 'reports.alarmlog'
  },
  {
    label: 'Alarm Configuration',
    route: '/alarms',
    pathName: 'alarms.settings'
  },
  {
    label: 'Audit Log',
    route: '/account/audit',
    pathName: 'account.audit'
  },
  {
    label: 'Activity Log',
    route: '/reports/activitylog',
    pathName: 'reports.activitylog'
  },
  {
    label: 'Dashboard',
    route: '/home',
    pathName: 'dashboard'
  }
];
