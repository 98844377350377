'use strict';

angular.module('app').directive('atInsightsLoading', atInsightsLoading);

function atInsightsLoading() {
    return {
        restrict: 'E',
        scope: {},
        template: require('views/reports/insights/atInsightsLoading.html'),
        controller: atInsightsLoadingCtrl
    };
}

atInsightsLoadingCtrl.$inject = [];

function atInsightsLoadingCtrl() {}
