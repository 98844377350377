export enum EntityType {
  User = 'User',
  UserAlias = 'Alias',
  Computer = 'Computer',
  Group = 'Group'
}

export enum EntitySource {
  AllUsers = 'AllUsers',
  ActivTrak = 'Group',
  AdGroup = 'AdGroup'
}

export enum EntityMix {
  User = 'User',
  Computer = 'Computer',
  Both = 'Both'
}
