import React, { useRef } from 'react';
import { debounce } from 'lodash';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export interface ISearchBarProps {
  filter?: string;
  setFilter?: (value: string) => void;
}

interface SearchBarProps extends ISearchBarProps {
  placeholder?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleDropDownSearchChange?: (event: any) => void;
  fieldRef?: React.RefObject<any>;
  searchValue?: string;
  onKeyPressCapture?: (event) => void;
}

export function SearchBar(props: SearchBarProps) {
  const filterRef = useRef<any>(null);
  const {
    filter,
    setFilter,
    placeholder,
    onClick,
    handleDropDownSearchChange,
    fieldRef,
    searchValue,
    onKeyPressCapture
  } = props;

  const debounceSetFilter = useRef(
    debounce((value) => setFilter(value), 400)
  ).current;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debounceSetFilter(value);
  };

  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      margin="normal"
      onChange={handleDropDownSearchChange ?? handleSearchChange}
      value={searchValue}
      onKeyPressCapture={onKeyPressCapture}
      inputRef={fieldRef ?? filterRef}
      InputProps={{
        onClick,
        startAdornment: <SearchIcon />,
        endAdornment: filter && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                filterRef.current.value = null;
                setFilter(null);
              }}
              sx={{ color: 'inherit' }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
