import React, { createRef, useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { IGroup } from '../../common/models';
import { classificationClasses } from '../styles/Classifications.styles';
import { SearchBar } from '../../common/components/SearchBar';

type GroupDropdownProps = {
  groupId?: string;
  groups: IGroup[];
  title?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  className?: object;
  onChange?: (event?: any) => void;
  isDisabled?: boolean;
};

const GroupDropdown = (props: GroupDropdownProps) => {
  const { groupId, groups, title, variant, className, onChange, isDisabled } =
    props;

  const dropdownTitle = title || 'Select Group';

  const selectClassName = className ? className : {};
  let menuItemClass = className
    ? className
    : classificationClasses.categoryMenuItem;
  menuItemClass = { ...menuItemClass, ...{ width: 'auto' } };
  const searchInputClass = classificationClasses.searchInput;

  const [filtered, setFiltered] = useState<IGroup[]>(groups);
  const textInputRef = createRef<any>();

  useEffect(() => {
    setFiltered(groups);
  }, [groups]);

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //Lowercase everything to match different cases
    const input = event.target.value.toLowerCase();
    const matches = groups.filter(
      (x) => x.name.toLowerCase().indexOf(input) > -1
    );

    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  return (
    <Select
      defaultValue={groupId || '0'}
      value={groupId || '0'}
      onChange={onChange}
      inputProps={{
        name: 'groupid',
        id: 'select-group'
      }}
      sx={selectClassName}
      variant={variant}
      disabled={isDisabled}
    >
      <MenuItem
        aria-label="Search"
        value="search"
        sx={searchInputClass}
        //Prevent jumping to category that starts with the character on Keydown
        onKeyDown={(e) => e.stopPropagation()}
      >
        <SearchBar
          fieldRef={textInputRef}
          //Prevent closing of Select
          onClick={(e) => e.stopPropagation()}
          handleDropDownSearchChange={(e) => handleDropDownSearchChange(e)}
        />
      </MenuItem>
      <MenuItem aria-label={dropdownTitle} value="0" sx={menuItemClass}>
        {dropdownTitle}
      </MenuItem>
      {filtered &&
        filtered.map((option) => (
          <MenuItem sx={menuItemClass} key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export default GroupDropdown;
