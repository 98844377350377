import { apiService } from 'activtrak-ui-utilities';
import { teamPulseDataMap } from '../../models/teamPulseDataMap';
import { realtimeDataMap } from '../../models/realtimeDataMap';

let _promises = {
    teamPulse: {},
    teamPulseGroups: {},
    realtime: {}
};
let _parameters = '';

// get team pulse initial card data
export async function getTeamPulseData({ filterMode, url, reportingUrl, isWidget, parameters }) {
    var newParams = parameters !== _parameters;
    _parameters = parameters;

    var resolvedUrl = `${url}/api/dashboard/teampulse?${parameters}`;

    if (reportingUrl) {
        resolvedUrl = isWidget
            ? `${reportingUrl}/reports/v1/teampulse/users/widget?${parameters}`
            : `${reportingUrl}/reports/v1/teampulse/users?${parameters}`;
    }

    if (!_promises.teamPulse.inProgress || newParams) {
        _promises.teamPulse = new Promise(async (resolve, reject) => {
            try {
                const result = await apiService.get(resolvedUrl, {
                    authorizationRequired: true
                });
                _promises.teamPulse.inProgress = false;

                if (parameters !== _parameters) {
                    reject('params updated');
                }

                const data = (result && result.data) || [];
                resolve(teamPulseDataMap(data, filterMode));
            } catch (e) {
                reject(e);
            }
        });
        _promises.teamPulse.inProgress = true;
    }
    return _promises.teamPulse;
}

// get real-time data
export async function getRealtimeData({ filterMode, url, parameters }) {
    if (!_promises.realtime.inProgress) {
        _promises.realtime = new Promise(async (resolve, reject) => {
            try {
                const result = await apiService.get(`${url}/api/realtime?${parameters}`, {
                    authorizationRequired: true
                });
                _promises.realtime.inProgress = false;

                const data = (result && result.data) || [];
                resolve(realtimeDataMap(data, filterMode));
            } catch (e) {
                reject(e);
            }
        });
        _promises.realtime.inProgress = true;
    }
    return _promises.realtime;
}
