import React from 'react';
import { StyledPaper } from '../../styles/templates.styles';

type TemplateShadowProps = {
  children: React.ReactNode;
  sx?: object;
};

export default function TemplateShadow(props: TemplateShadowProps) {
  const { children, sx } = props;
  return (
    <StyledPaper elevation={3} sx={sx}>
      {children}
    </StyledPaper>
  );
}
