'use strict';

angular.module('app')
    .directive('safeSearchConditions', SafeSearchConditions);

function SafeSearchConditions() {
    return {
        restrict: 'E',
        scope: {
            safeSearchConditions: '=conditions'
        },
        template: require('views/widgets/alarms/safeSearchConditions.html'),
        controller: SafeSearchConditionsCtrl
    };
}

SafeSearchConditionsCtrl.$inject = ['$scope'];

function SafeSearchConditionsCtrl($scope) {

    $scope.availableCategories = ['Adult', 'Violence', 'Medical', 'Racy'];

    $scope.likelihoodMap = {
        3: 'possibly',
        4: 'likely',
        5: 'very likely'
    };

    function updateAvailableCategories() {
        $scope.conditions.forEach(function(condition) {
            var index = $scope.availableCategories.indexOf(condition.category);
            if (index !== -1) {
                $scope.availableCategories.splice(index, 1);
            }
        });
    }

    function updateConditions() {
        $scope.conditions = [];
        $scope.availableCategories = ['Adult', 'Violence', 'Medical', 'Racy'];


        if ($scope.safeSearchConditions) {
            $scope.safeSearchConditions.forEach(function(condition) {
                if (condition.value >= 3 && condition.value <= 5) {
                    var con = {
                        category: condition.fieldName.replace('SafeSearch', ''),
                        likelihood: condition.value,
                        isEditing: false
                    };

                    $scope.conditions.push(con);
                }
            });
        }

        if ($scope.conditions.length === 0) {
            $scope.addCategory();
        }
        else {
            updateAvailableCategories();
        }
    }

    $scope.deleteCondition = function(condition) {
        _.remove($scope.safeSearchConditions, function(c) { return c.fieldName === 'SafeSearch' + condition.category; });
        updateConditions();
    };

    $scope.completeCondition = function(condition, oldCategory) {

        if (condition) {
            var con = $scope.safeSearchConditions.find(function(c) { return c.fieldName === 'SafeSearch' + condition.category; });

            if (oldCategory !== condition.category) {
                _.remove($scope.safeSearchConditions, function(c) { return c.fieldName === 'SafeSearch' + oldCategory; });
            }

            if (con) {
                con.value = condition.likelihood;
            }
            else {
                con = {
                    casesensitive: false,
                    field: 'SafeSearch' + condition.category,
                    fieldName: 'SafeSearch' + condition.category,
                    op: 'GreaterThanOrEqualsTo',
                    opName: 'Greater Than Or Equals To',
                    value: condition.likelihood
                };
                $scope.safeSearchConditions.push(con);
            }
        }

        updateConditions();
    };

    $scope.conditionInEdit = function() {
        var isEditing = false;
        $scope.conditions.forEach(function(condition) {
            isEditing = isEditing || condition.isEditing;
        });
        return isEditing;
    };

    $scope.showAddButton = function() {
        return false; // Forced the hiding of the button to only allow a single category definition.  Original Code: !($scope.conditionInEdit() || $scope.availableCategories.length === 0);
    };

    $scope.addCategory = function() {
        var condition = {
            category: $scope.availableCategories[0],
            likelihood: 3,
            isEditing: true
        };

        $scope.conditions.push(condition);
    };

    $scope.$watch(function() { return $scope.alarm.editor.safeSearchConditions; }, function() {
        updateConditions();
    });
}
