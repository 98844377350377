import { authenticatedUserService } from 'activtrak-ui-utilities';

export function handleLogOut() {
  try {
    authenticatedUserService.clearGlobals();
    window.localStorage.removeItem('activTrak.msp.backup');
  } catch (e) {
    throw new Error('Could not perform logout action, please try again later');
  }
}
