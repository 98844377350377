import React from 'react';
import { IUserIdentity } from '../models/IUserIdentity';
import {
  PressEnterSearch,
  PressEnterSearchDiv
} from '../styles/UserList.view.styles';
import { IValueId } from '../models/IValueId';
import { MAX_RESULTS } from '../constants/IdentitySearchTypes';
import { IAgentSearch } from '../models/IAgentSearch';

export const GetUniqueSearchEmail = (
  newDataMap: any,
  options: IUserIdentity[],
  searchTermLowerCase: string
) => {
  for (let i = 0; options.length > i; i++) {
    const currentIdentity = options[i];
    for (let j = 0; currentIdentity?.emails?.length > j; j++) {
      const { value } = currentIdentity.emails[j];
      if (value?.toLowerCase().indexOf(searchTermLowerCase) > -1) {
        newDataMap['all:' + value] = {
          id: currentIdentity.id,
          label: value,
          searchVal: value,
          typeName: 'all'
        };
      }
    }
  }
};

export const GetUniqueSearchNames = (
  newDataMap: any,
  options: IUserIdentity[],
  searchTermLowerCase: string
) => {
  for (let i = 0; options.length > i; i++) {
    const currentIdentity = options[i];
    const { firstName, middleName, lastName, displayName, employeeId } =
      currentIdentity;
    GetUniqueNameComponent(
      newDataMap,
      firstName,
      currentIdentity,
      searchTermLowerCase
    );
    GetUniqueNameComponent(
      newDataMap,
      middleName,
      currentIdentity,
      searchTermLowerCase
    );
    GetUniqueNameComponent(
      newDataMap,
      lastName,
      currentIdentity,
      searchTermLowerCase
    );
    GetUniqueNameComponent(
      newDataMap,
      displayName,
      currentIdentity,
      searchTermLowerCase
    );
    GetUniqueNameComponent(
      newDataMap,
      employeeId,
      currentIdentity,
      searchTermLowerCase
    );
  }
};

const GetUniqueNameComponent = (
  newDataMap: any,
  nameComponent: IValueId,
  currentIdentity: IUserIdentity,
  searchTermLowerCase: string
) => {
  if (
    nameComponent &&
    nameComponent.value &&
    nameComponent.value.length > 0 &&
    nameComponent.value.toLowerCase().indexOf(searchTermLowerCase) > -1
  ) {
    newDataMap['all:' + nameComponent.value] = {
      id: currentIdentity.id,
      typeName: 'all',
      label: nameComponent.value,
      searchVal: nameComponent.value
    };
  }
};

export const GetUniqueSearchUserAliasesAndNames = (
  newDataMap: any,
  options: IUserIdentity[],
  searchTermLowerCase: string
) => {
  for (let i = 0; options.length > i; i++) {
    const currentIdentity = options[i];
    for (let k = 0; currentIdentity?.agents?.length > k; k++) {
      const alias = currentIdentity.agents[k]?.alias;
      if (
        alias &&
        alias.length > 0 &&
        alias.toLowerCase().indexOf(searchTermLowerCase) > -1
      ) {
        newDataMap['all:' + alias] = {
          id: currentIdentity.id,
          typeName: 'all',
          label: alias,
          searchVal: alias
        };
      }
      const userName = currentIdentity.agents[k]?.userName;
      if (
        userName &&
        userName.length > 0 &&
        userName.toLowerCase().indexOf(searchTermLowerCase) > -1
      ) {
        newDataMap['all:' + userName] = {
          id: currentIdentity.id,
          typeName: 'all',
          label: userName,
          searchVal: userName
        };
      }
    }
  }
};

export const AddFurtherSearchSuggestion = (
  userIdentities: any[],
  tempSearchTerm: string
) => {
  if (
    userIdentities &&
    userIdentities.length > 0 &&
    tempSearchTerm &&
    tempSearchTerm.length > 0
  ) {
    userIdentities.push({
      id: 0,
      typeName: 'Global',
      label: FurtherSearchSuggestionLabel(tempSearchTerm),
      searchVal: tempSearchTerm
    });
  }
};

const FurtherSearchSuggestionLabel = (inputValue: string) => {
  return (
    <PressEnterSearchDiv>
      Search &ldquo;{inputValue}&rdquo; across all users{' '}
      <PressEnterSearch>Press Enter</PressEnterSearch>
    </PressEnterSearchDiv>
  );
};

export const setSingleAgentSelected = (
  agentRowItem: IAgentSearch,
  agents: IAgentSearch[]
) => {
  //unselect all
  agents.forEach((agent) => {
    if (agent.selected) {
      agent.selected = false;
    }
  });
  //only set this one as the selected row
  agentRowItem.selected = true;
};

export const nullIfEmpty = (value: string) => {
  return value?.length == 0 ? null : value;
};

const getUniqueSearchValues = (
  options: IUserIdentity[],
  tempSearchTerm: string
) => {
  //should return unique array of label and typeName list
  const newDataMap = {};
  const searchTermLowerCase = tempSearchTerm?.toLowerCase() || '';

  GetUniqueSearchEmail(newDataMap, options, searchTermLowerCase);
  GetUniqueSearchUserAliasesAndNames(newDataMap, options, searchTermLowerCase);
  GetUniqueSearchNames(newDataMap, options, searchTermLowerCase);

  let keys = Object.keys(newDataMap).map(function (key) {
    return newDataMap[key];
  });
  if (keys.length > MAX_RESULTS) {
    //api returns 10 identities, we only show 10 search results
    keys = keys.slice(0, MAX_RESULTS);
  }
  AddFurtherSearchSuggestion(keys, tempSearchTerm);
  return keys;
};

export const filterOptions = (options: any[], tempSearchTerm: string) => {
  const uniqueValues = getUniqueSearchValues(options, tempSearchTerm);
  return uniqueValues;
};
