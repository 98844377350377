import { styled } from '@mui/material/styles';
import { mediumFontWeight, primaryFontSize } from '../../common/constants';
import { Box, CardContent } from '@mui/material';

export const ViewHeaderNameContainer = styled(Box)`
  font-weight: ${mediumFontWeight};
`;

export const TitleBox = styled(Box)`
  font-size: 16px;
  padding: 20px 5px 5px 5px;
`;
export const DescriptionBox = styled(Box)`
  font-size: ${primaryFontSize};
  padding: 5px 20px 5px 20px;
  margin: 5px;
  font-weight: 400;
`;
export const StateBox = styled(Box)`
  font-size: 10px important!;
  padding: 5px;
  margin: 5px;
  font-weight: 400;
`;

export const IntegrationCardHeader = styled(Box)`
  margin: 10px 0px;
  padding-top: 0;
  text-align: center !important;
  position: relative;
  min-height: 180px;
`;

export const IntegrationCardContent = styled(CardContent)`
  text-align: center !important;
  height: calc(100% - 50px);
`;

export const Kebab = styled(Box)`
  cursor: pointer;
  right: 0;
  position: absolute;
  height: 50px;
  top: 0;
  display: block;
`;
