import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  ActionsContainer,
  FormElementContainer,
  FormElementTitle
} from '../../common/components/AdvancedFilter/AdvancedFilter.styles';
import { userTextFields } from '../enums/AdvancedSearch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

type ComponentProps = {
  modalData: any[];
  onSubmit: (formData) => void;
};

export const UserAdvancedSearchForm = (props: ComponentProps) => {
  const { register, handleSubmit, reset } = useForm();
  const { modalData, onSubmit } = props;

  const inputRefs = {
    logonDomain: useRef(null),
    user: useRef(null),
    alias: useRef(null)
  };

  useEffect(() => {
    const initialValue = {};
    modalData.forEach((input) => {
      if (inputRefs[input.field]) {
        initialValue[input.field] = input.fieldValue;
        inputRefs[input.field].current.value = input.fieldValue;
      }
    });
    reset(initialValue);
    // inputRefs should be added, but needs to be properly memoized before added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, reset]);

  const resetForm = () => {
    const resetValue = {};
    userTextFields.forEach((field) => {
      const { name } = field;
      inputRefs[name].current.value = '';
      resetValue[name] = '';
    });

    reset(resetValue);
  };

  return (
    <>
      {userTextFields.map((field, index) => {
        const { name, label } = field;
        return (
          <FormElementContainer key={index}>
            <FormElementTitle>{label}</FormElementTitle>
            <TextField
              inputRef={inputRefs[name]}
              {...register(name)}
              variant="standard"
            />
          </FormElementContainer>
        );
      })}
      <ActionsContainer>
        <Button onClick={() => resetForm()}>Clear</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </Button>
      </ActionsContainer>
    </>
  );
};
