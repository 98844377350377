import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DownloadAgentCard } from '../../agent-activation/components/DownloadAgentCard';
import { CopyAgentLinkCard } from '../../agent-activation/components/CopyAgentLinkCard';
import { SupportFooter } from '../../agent-activation/components/SupportFooter';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { quickActionsWidgetStyles as classes } from '../styles/QuickActions.styles';

type AgentActivationModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AgentActivationModal = (props: AgentActivationModalProps) => {
  const { open, onClose } = props;
  const modalStyles = { ...classes.modalContainer, ...classes.agentModal };

  return (
    <Dialog open={open} onClose={onClose} sx={modalStyles}>
      <DialogTitle sx={classes.agentModalHeader}>
        Install or Share the Agent file to collect activity data
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid container>
            <DownloadAgentCard />
            <CopyAgentLinkCard />
          </Grid>
          <SupportFooter />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
