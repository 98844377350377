import { react2angular } from 'react2angular';
import { TimezoneComponent } from '../../_reactivtrak/src/timezone/views/Timezone.view';


export const connect = (angular) => { 

  angular
    .module('app')
    .component('settingsTimezone', react2angular(TimezoneComponent, []));

}
