import React from 'react';
import { IGroupMembersColumnHeader } from '../models';
import DeleteGroupMemberButton from '../components/DeleteGroupMemberButton';
import Box from '@mui/material/Box';
import MemberTypeIcon from '../components/MemberTypeIcon';
import { groupMemberClasses } from '../styles/AddGroupMembers.styles';
import { formatGroupMember } from './Groups.utils';

export const useGroupMembersColumns = (props: IGroupMembersColumnHeader) => {
  const { disabled, readonly, deletable } = props;

  return [
    {
      field: 'domain',
      fieldName: 'Domain',
      headerName: 'Domain',
      type: 'string',
      isSortable: true,
      width: '35%',
      template: (dataItem) => {
        return formatGroupMember(dataItem.domain, null, 25);
      }
    },
    {
      field: 'name',
      fieldName: 'Member Name',
      headerName: 'Member Name',
      type: 'string',
      isSortable: true,
      width: '35%',
      template: (dataItem) => {
        return (
          <>
            <MemberTypeIcon type={dataItem.type} />
            <Box sx={groupMemberClasses.memberName}>
              {formatGroupMember(dataItem.name, dataItem.alias)}
            </Box>
          </>
        );
      }
    },
    {
      type: 'any',
      isSortable: false,
      width: '15%',
      align: 'center',
      template: (dataItem) => {
        return !readonly && deletable ? (
          <DeleteGroupMemberButton disabled={disabled} groupMember={dataItem} />
        ) : (
          <></>
        );
      }
    }
  ];
};
