class DashboardFilter {
    constructor({ label, value, text, id, defaultValue }) {
        this.label = label;
        this.value = value;
        this.text = text || value;
        this.id = id;
        this.defaultValue = defaultValue;
    }

    getEventFilter() {
        let filter = {};
        filter[this.label] = this.value || "";
        return filter;
    }

    getDisplayFilter() {
        let filter = {};
        filter[this.label] = this.text || "";
        return filter;
    }

    getApiFilter() {
        let filter = {};

        switch (this.label) {
            case 'Metric':
                filter.metricId = this.id;
                break;
            case 'Team':
                filter.groupId = this.id || null;
                break;
        }

        return filter;
    }
}

export default DashboardFilter;
