import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const InsightsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Insights';
  const className = props.className;
  const style = props.style ?? {};
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        style={style}
        d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM6.66667 18.6667C5.93333 18.6667 5.33333 18.0667 5.33333 17.3333V13.3333C5.33333 12.6 5.93333 12 6.66667 12C7.4 12 8 12.6 8 13.3333V17.3333C8 18.0667 7.4 18.6667 6.66667 18.6667ZM12 18.6667C11.2667 18.6667 10.6667 18.0667 10.6667 17.3333V16C10.6667 15.2667 11.2667 14.6667 12 14.6667C12.7333 14.6667 13.3333 15.2667 13.3333 16V17.3333C13.3333 18.0667 12.7333 18.6667 12 18.6667ZM12 12C11.2667 12 10.6667 11.4 10.6667 10.6667C10.6667 9.93333 11.2667 9.33333 12 9.33333C12.7333 9.33333 13.3333 9.93333 13.3333 10.6667C13.3333 11.4 12.7333 12 12 12ZM17.3333 18.6667C16.6 18.6667 16 18.0667 16 17.3333V6.66667C16 5.93333 16.6 5.33333 17.3333 5.33333C18.0667 5.33333 18.6667 5.93333 18.6667 6.66667V17.3333C18.6667 18.0667 18.0667 18.6667 17.3333 18.6667Z"
      />
    </SvgIconWrapper>
  );
};
