import React from 'react';
import { IAgentCommand } from '../models';
import { CommandsContainer } from '../styles';
import { formatDateOrNull } from '../../common/helpers';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { IGridColumn } from '../../common/models';
import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

type ComponentProps = {
  agentCommandHistory: IAgentCommand[];
  dateTimeFormat: string;
  openDiagnosticsModal(agentCommand: any): void;
};

export const CommandGrid = (props: ComponentProps) => {
  const { agentCommandHistory, dateTimeFormat, openDiagnosticsModal } = props;

  const commandColumn = (agentCommand: any) => {
    return (
      <Tooltip title={agentCommand.codeFormatted} placement="bottom">
        <TextOverflow>{agentCommand.codeFormatted}</TextOverflow>
      </Tooltip>
    );
  };

  const statusColumn = (agentCommand: any) => {
    return (
      <span>
        {agentCommand.status?.outcome
          ? agentCommand.status?.outcome
          : 'Pending'}
      </span>
    );
  };

  const messageColumn = (agentCommand: any) => {
    return (
      <TextOverflow>
        {agentCommand.status?.parsedStatusMessage ? (
          <Button
            onClick={() => openDiagnosticsModal(agentCommand)}
            sx={{ fontWeight: 'normal', letterSpacing: '0.5px' }}
          >
            {agentCommand.status?.message}{' '}
          </Button>
        ) : (
          <>{agentCommand.status?.message}</>
        )}
      </TextOverflow>
    );
  };

  const createdColumn = (agentCommand: any) => {
    return (
      <span>{formatDateOrNull(agentCommand.creationTime, dateTimeFormat)}</span>
    );
  };

  const statusUpdatedColumn = (agentCommand: any) => {
    return (
      <span>
        {formatDateOrNull(agentCommand.status?.receivedTime, dateTimeFormat)}
      </span>
    );
  };

  const aliasColumn = (agentCommand: any) => {
    return (
      <span>
        {formatDateOrNull(agentCommand.status?.executionTime, dateTimeFormat)}
      </span>
    );
  };

  const getCommandGridColumns = (): IGridColumn[] => {
    return [
      {
        field: 'code',
        fieldName: 'code',
        headerName: 'Type',
        type: 'string',
        width: '20%',
        template: commandColumn
      },
      {
        headerName: 'Status',
        align: 'center',
        type: 'string',
        width: '7%',
        template: statusColumn
      },
      {
        headerName: 'Message',
        align: 'center',
        type: 'string',
        width: '25%',
        template: messageColumn
      },
      {
        headerName: `Created`,
        align: 'center',
        type: 'string',
        width: '16%',
        template: createdColumn
      },
      {
        headerName: 'Status Updated',
        align: 'center',
        type: 'string',
        width: '16%',
        template: statusUpdatedColumn
      },
      {
        field: 'alias',
        align: 'center',
        headerName: 'Agent Execution',
        type: 'string',
        width: '16%',
        template: aliasColumn
      }
    ];
  };

  return (
    <CommandsContainer>
      <CustomGrid
        isVirtualized={false}
        data={agentCommandHistory}
        columns={getCommandGridColumns()}
        uniqueRowProp="uniqueRowId"
      />
    </CommandsContainer>
  );
};
