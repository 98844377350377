import { generateParameters } from '_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import { getReportFilters } from '../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import lodash from 'lodash';

const constructor = (
    httpService,
    envConfig,
    msg,
    templateServiceFunctions,
    localStorageService,
    authorizationService
) => {
    let _settings = {
        pollDelay: 5000,
        pollDelayAfterError: 10000,
        currentDelay: 5000
    };
    let _poller = {
        pollTimer: null,
        started: false,
        paused: false,
        errored: false
    };
    let _subscribers = [];
    let _agentStats = {
        totalActiveAgents: 0,
        totalReportingAgents: 0,
        totalFilteredAgents: 0,
        isFiltered: false
    };
    let _passiveLabels = {
        title: 'passive',
        lockedTitle: 'passive - locked'
    };
    let _previousUpdates = [];

    let dataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                httpService
                    .get(`${envConfig.websocketHttpsUrl()}/api/realtime?${generateParameters(getReportFilters(), {})}`)
                    .then(function (result) {
                        handleRealtimeResult(options, result.data);
                        options.success(result.data);
                    })
                    .catch(function (result) {
                        options.error(result);
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    const hasItemUpdated = (item) => {
        let currentUpdate = moment(item.time, 'M/D/YYYY h:mm:ss A');
        let previousUpdate = _previousUpdates[item.userId];
        _previousUpdates[item.userId] = currentUpdate;

        return previousUpdate && currentUpdate.isAfter(previousUpdate);
    };

    const isPassive = (item) => {
        return (
            item &&
            item.title &&
            (item.title.toLowerCase() === _passiveLabels.title ||
                item.title.toLowerCase() === _passiveLabels.lockedTitle)
        );
    };

    const isSystemEvent = (item) => {
        return item && item.exec && item.exec.toLowerCase() === 'system event';
    };

    const isSystemUser = (item) => {
        return item && item.user && item.user.toLowerCase() === 'system';
    };

    const removeScreenshotColumn = (gridName) => {
        const grid = localStorageService.get(gridName);
        if (grid) {
            try {
                const gridOptions = JSON.parse(grid);
                lodash.remove(gridOptions.columns, function (c) {
                    return c.title === msg.get('screenshot');
                });
                localStorageService.set(gridName, JSON.stringify(gridOptions));
            } catch (err) {
                console.error('ActivTrak Error: Invalid JSON for removeScreenshotColumn', grid);
            }
        }
    };

    const createColumns = (showThumbnails, allowThumbnails) => {
        const columns = [
            {
                field: 'time',
                title: msg.get('date'),
                width: 200,
                filterable: false,
                hidden: true
            },
            {
                field: 'os',
                title: msg.get('os'),
                filterable: false,
                attributes: {
                    class: 'text-center'
                },
                template:
                    '#switch (os) {' +
                    'case "Win":# <i class="fa fa-windows" title="Windows"></i> #break;' +
                    'case "Mac OS":# <i class="fa fa-apple" title="macOS"></i> #break;' +
                    'case "Cros":# <i class="fa fa-chrome" title="Chrome OS"></i> #break;' +
                    'default: # <i class="fa fa-question" title="#: os #"></i> #break;' +
                    '}#',
                width: 40
            },
            {
                field: 'device',
                title: msg.get('computer'),
                filterable: templateServiceFunctions.createFilter('device'),
                width: 200,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'devicealias',
                title: msg.get('computerAlias'),
                filterable: templateServiceFunctions.createFilter('devicealias'),
                width: 200,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'user',
                title: msg.get('user'),
                filterable: templateServiceFunctions.createFilter('user'),
                template: kendo.template(function (item) {
                    if (isSystemUser(item)) {
                        return (
                            '<span class="at-deemphasize">' +
                            '<a class="at-pointer" ng-click="itemClicked(dataItem, \'user\')">' +
                            item.user.toLowerCase() +
                            '</a>' +
                            '</span>'
                        );
                    } else {
                        return '<a class="at-pointer" ng-click="itemClicked(dataItem, \'user\')">{{dataItem.user}}</a>';
                    }
                }),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 120,
                hidden: authorizationService.hasRole(authorizationService.roles.viewer)
            },
            {
                field: 'ldom',
                title: msg.get('logonDomain'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('ldom'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 120
            },
            {
                field: 'pdom',
                title: msg.get('primaryDomain'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('pdom'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'title',
                title: msg.get('title'),
                filterable: templateServiceFunctions.createFilter('title'),
                template: kendo.template(function (item) {
                    const title = (item.title ?? '').toLowerCase();
                    if (isPassive(item) || isSystemEvent(item)) {
                        return (
                            '<span class="at-deemphasize">' +
                            '<a class="at-pointer" ng-click="itemClicked(dataItem, \'title\')">' +
                            title +
                            '</a>' +
                            '</a></span>'
                        );
                    } else {
                        return '<a class="at-pointer" ng-click="itemClicked(dataItem, \'title\')">{{dataItem.title}}</a>';
                    }
                }),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 300,
                hidden: authorizationService.hasRole(authorizationService.roles.viewer)
            },
            {
                field: 'desc',
                title: msg.get('description'),
                hidden: true,
                filterable: false,
                width: 250,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'exec',
                title: msg.get('executable'),
                template: kendo.template(function (item) {
                    if (isSystemEvent(item)) {
                        return '<span class="at-deemphasize"' + item.exec.toLowerCase() + '</span>';
                    } else {
                        return item.exec || '';
                    }
                }),
                filterable: templateServiceFunctions.createFilter('exec'),
                width: 150,
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'osvn',
                title: msg.get('osVersion'),
                hidden: true,
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'ip',
                title: msg.get('privateIp'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('ip'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'publicip',
                title: msg.get('publicIp'),
                hidden: true,
                filterable: templateServiceFunctions.createFilter('publicip'),
                attributes: {
                    class: 'text-nowrap'
                },
                width: 150
            },
            {
                field: 'snid',
                title: msg.get('snid'),
                hidden: true,
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                width: 100
            },
            {
                field: 'url',
                title: msg.get('url'),
                filterable: templateServiceFunctions.createFilter('url'),
                template: function (item) {
                    item.urlLabel = item.url;
                    item.urlOptions = {
                        internalLink: {
                            visible: true,
                            stateName: 'app.reports.activitylog',
                            stateParams: {
                                user: item.user,
                                site: item.url
                            },
                            icon: 'fa fa-info-circle'
                        },
                        externalLink: {
                            visible: true,
                            url: item.url,
                            icon: 'fa fa-external-link'
                        },
                        copyLink: {
                            visible: true,
                            icon: 'fa fa-copy'
                        }
                    };

                    return (
                        '<a class="at-pointer" ng-click="itemClicked(dataItem, \'url\')">' +
                        templateServiceFunctions.getFaviconTemplateAsString(item.url, true) +
                        '</a>'
                    ); //at-url url-options="dataItem.urlOptions" url-label="dataItem.urlLabel"
                },
                attributes: {
                    class: 'text-nowrap'
                },
                width: 350,
                hidden: authorizationService.hasRole(authorizationService.roles.viewer)
            }
        ];

        if (allowThumbnails) {
            columns.splice(0, 0, {
                title: msg.get('screenshot'),
                filterable: false,
                headerTemplate: '<i class="icon-at-screenshot text-master" title="' + msg.get('screenshot') + '"></i>',
                template: kendo.template(function () {
                    if (showThumbnails) {
                        return '<realtime-thumbnail row="dataItem" on-click="showScreenshot" fixed-width="225"></realtime-thumbnail>';
                    }

                    return '<a ng-click="showScreenshot(dataItem)"><i class="icon-at-screenshot realtime-ss-button text-master"></i></a>';
                }),
                attributes: {
                    class: 'text-center'
                },
                width: showThumbnails ? 240 : 40,
                ignoreSavedWidth: true
            });
        } else {
            removeScreenshotColumn('realtime-grid');
        }

        return columns;
    };

    const setAgentStats = (total, idle) => {
        _agentStats.totalActiveAgents = total - idle;
        _agentStats.totalReportingAgents = total;
        const agents = dataSource.view();
        _agentStats.totalFilteredAgents = agents ? agents.length : _agentStats.totalReportingAgents;
        const filters = dataSource.filter();
        _agentStats.isFiltered = Boolean(filters && filters.filters.length > 0);
    };

    const handleRealtimeResult = (options, result) => {
        result.data = lodash.sortBy(result.data, (i) => {
            return i.user.toLowerCase();
        });
        _settings.currentDelay = _settings.pollDelay;
        let idleCount = 0;
        lodash.forEach(result.data, (row) => {
            // HACK: If we start storing title names other than 'Passive' and 'Passive - Locked' this will break.
            // Need to recieve active state from realtime.
            row.isIdle = Boolean(
                typeof row.title === 'string' &&
                    (row.title.toLowerCase() === _passiveLabels.title ||
                        row.title.toLowerCase() === _passiveLabels.lockedTitle)
            );
            idleCount += row.isIdle ? 1 : 0;
            row.hasUpdated = hasItemUpdated(row);
        });
        setAgentStats(result.data.length, idleCount);
        options.success(result);
    };

    const _poll = () => {
        if (_poller.pollTimer) {
            clearTimeout(_poller.pollTimer);
        }

        if (!_poller.started || _poller.paused) {
            return;
        }

        const setPoll = () => {
            // console.log('setPoll')
            _poller.pollTimer = setTimeout(() => {
                _poll();
            }, _settings.currentDelay);
        };

        dataSource
            .read()
            .then(() => {
                lodash.forEach(_subscribers, (subscriber) => {
                    if (typeof subscriber.callback === 'function') {
                        subscriber.callback(dataSource.view());
                    }
                });
                setPoll();
            })
            .catch((e) => {
                if (e.status > 399 && e.status < 500) {
                    // TODO: Handle 400 error
                    // poll400ErrorHandler();
                } else {
                    _settings.currentDelay = _settings.pollDelayAfterError;
                    setPoll();
                }
            });
    };

    const subscribe = (id, callback) => {
        let existingSubscriber = lodash.find(_subscribers, (subscriber) => subscriber.id === id);
        if (existingSubscriber) {
            existingSubscriber.callback = callback;
        } else {
            _subscribers.push({ id, callback });
        }
    };

    const unsubscribe = (id) => {
        lodash.remove(_subscribers, (subscriber) => {
            return subscriber.id === id;
        });

        // Stop polling if no subscribers
        if (_subscribers.length === 0) {
            _stop();
        }
    };

    const start = (id, callback) => {
        if (id) {
            subscribe(id, callback);
        }
        _poller.started = true;
        _poller.paused = false;
        _poll();
    };

    const pause = (value) => {
        _poller.paused = value;

        if (!_poller.paused) {
            _poll();
        }
    };

    const _stop = () => {
        _poller = {
            pollTimer: null,
            started: false,
            paused: false,
            errored: false
        };
    };

    const settings = (value) => {
        if (value) {
            // Only set values that are defined.
            let incomingSettings = lodash.pickBy(value, lodash.identity);
            _settings = Object.assign(_settings, incomingSettings);

            // Set current delay if poller is not in error state
            if (!_poller.errored) {
                _settings.currentDelay = _settings.pollDelay;
            }
        }

        return _settings;
    };

    const agentStats = () => {
        return _agentStats;
    };

    return {
        dataSource,
        createColumns,
        subscribe,
        unsubscribe,
        start,
        pause,
        settings,
        agentStats
    };
};

export { constructor };
