'use strict';

angular.module('app').directive('connectionTemplate', connectionTemplate);
connectionTemplate.$inject = [];
function connectionTemplate() {
    return {
        restrict: 'EA',
        template: require('views/widgets/connectionTemplate.html'),
        scope: {
            connection: '='
        },
        controller: [
            '$scope',
            '$window',
            function($scope, $window) {
                $scope.loadImage = $scope.$root.loadImage;
                $scope.analyticEventServiceFunctions = require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;
                $scope.install = function() {
                    $scope.analyticEventServiceFunctions.newEvent('Connect - Install',  {name: $scope.connection.title});
                    $window.open($scope.connection.installUrl, '_blank');
                };

                $scope.download = function() {
                    $scope.analyticEventServiceFunctions.newEvent('Connect - Download',  {name: $scope.connection.title});
                    $window.open($scope.connection.downloadUrl, '_blank');
                };

                $scope.copyStarterTemplate = function() {
                    $scope.analyticEventServiceFunctions.newEvent('Connect - copyStarterTemplate',  {name: $scope.connection.title});
                    $window.open($scope.connection.starterTemplateUrl, '_blank');
                };
                $scope.clickGuide = function() {
                    $scope.analyticEventServiceFunctions.newEvent('Connect - Setup Guide',  {name: $scope.connection.title});
                };
            }
        ]
    };
}
