import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  validateStepsOptions,
  Wizard
} from '../../common/components/Wizard/Wizard';
import { useAccountWizardContext } from '../services';
import { useWizardContext } from '../../common/components/Wizard/WizardProvider';
import { AccountWizardHandOff } from './Steps/AccountWizardHandOff';
import { AccountWizardStep1 } from './Steps/AccountWizardStep1';
import { AccountWizardStep2 } from './Steps/AccountWizardStep2';
import { AccountWizardStep3 } from './Steps/AccountWizardStep3';

export default function AccountWizardForm() {
  const {
    formValues,
    dispatchForm,
    controlFlowHandler,
    wizardName,
    accountWizardService,
    firstVisit,
    skipStepTwo
  } = useAccountWizardContext();
  const { setModalState, setActiveStep, resetWizard, activeStep } =
    useWizardContext();
  const styleOverrides = {
    maxWidth: '1000px',
    centered: true,
    additionalStyles: {
      positioning: 'relative',
      top: '-70px'
    }
  };

  const makeAdditionalStyles = {
    stepOneButtons: {
      [`& #click-forward-step-1-${wizardName}, #click-cancel-step-1-${wizardName}`]:
        {
          position: 'relative',
          top: '-70px'
        }
    }
  };

  const validateStep = (currentStep, type: validateStepsOptions) => {
    let invalidStep: boolean;
    switch (type) {
      case 'isDisabled':
        // Upon certain selections, step 2 is skipped showing step 3 so need to check state if it includes selections
        if (currentStep === 1 && skipStepTwo) {
          invalidStep = !formValues.PageSelection.some((sel) =>
            sel.charAt(0).includes('2')
          );
        }
        // Step 2 edge case: State includes '1:0' but '1:0' has been hidden, so it will disable and require user to make selection.
        else if (
          currentStep === 1 &&
          !controlFlowHandler(currentStep, '1:0') &&
          formValues.PageSelection.join(',').includes('1:0')
        ) {
          invalidStep = true;
        } else {
          // Required steps should be populated in state.
          invalidStep = !formValues.PageSelection.some((sel) =>
            sel.charAt(0).includes(currentStep)
          );
        }
        return !!invalidStep;
      case 'skipped':
        // If addSkipButton is enabled and user clicked skipped, this would cleanup state.
        if (optionalSteps.includes(currentStep)) {
          const filterSkippedValues = formValues.PageSelection.filter(
            (sel) => !sel.charAt(0).includes(currentStep)
          );

          dispatchForm({
            type: 'pageSelectionCleanup',
            payload: filterSkippedValues
          });
        }
        break;
      case 'modal':
        if (
          controlFlowHandler(1, '1:2') &&
          formValues.PageSelection.some((sel) =>
            sel.substring(0, 3).includes('1:1')
          )
        ) {
          setModalState(true);
          return true;
        } else {
          setActiveStep(currentStep + 1);
          return false;
        }
    }
  };

  const btnLabels = {
    previousStep: '← Back to previous step',
    nextStep: 'Next Step →',
    finishStep: 'Continue',
    altLabel: ''
  };

  // steps are zero based
  const optionalSteps = [];

  const formValidator = (
    activeStep,
    type: validateStepsOptions
  ): boolean | JSX.Element => {
    return validateStep(activeStep, type);
  };

  useEffect(() => {
    // Resets wizard if relaunching
    if (activeStep !== 0) {
      resetWizard();
      dispatchForm({
        type: 'pageSelectionCleanup',
        payload: []
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ ...makeAdditionalStyles.stepOneButtons }}>
      {firstVisit ? (
        <Wizard
          steps={3}
          skippableSteps={optionalSteps}
          formWizard={true}
          buttonLabels={btnLabels}
          stepValidator={formValidator}
          addSkipButton={false}
          modal={{ trigger: 1 }}
          wizardName={wizardName}
          styleOverrides={styleOverrides}
        >
          <AccountWizardStep1 />
          <AccountWizardStep2 />
          <AccountWizardStep3 />
          <AccountWizardHandOff />
        </Wizard>
      ) : (
        <Wizard
          steps={3}
          skippableSteps={optionalSteps}
          formWizard={true}
          buttonLabels={btnLabels}
          stepValidator={formValidator}
          addSkipButton={false}
          modal={{ trigger: 1 }}
          cancelBtn={{
            label: 'No thanks, take me back to Role Access',
            redirect: 'app.settings.roleaccess',
            color: 'wizardSecondaryColor',
            onClick: () => accountWizardService.cancelWizard(true, firstVisit)
          }}
          wizardName={wizardName}
          styleOverrides={styleOverrides}
        >
          <AccountWizardStep1 />
          <AccountWizardStep2 />
          <AccountWizardStep3 />
          <AccountWizardHandOff />
        </Wizard>
      )}
    </Box>
  );
}
