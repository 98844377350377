'use strict';

angular.module('app').directive('alarmDetailsRiskLevel', AlarmDetailsRiskLevel);

function AlarmDetailsRiskLevel() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsRiskLevel.html'),
        controller: AlarmDetailsRiskLevelCtrl
    };
}

AlarmDetailsRiskLevelCtrl.$inject = ['$scope', '$window', 'messagesService', 'authorizationService'];

function AlarmDetailsRiskLevelCtrl($scope, $window, msg, authorizationService) {
    $scope.paidPlanAlertMessage = msg.get(
        'riskLevelPaidPlanAvailableOnly'
    );
    var alarmObjectVerifierFunctions = require('_app/serviceFunctions/alarmObjectVerifierFunctions')
        .alarmObjectVerifierFunctions;

    $scope.minorLabel = msg.get('minor');
    $scope.majorLabel = msg.get('major');
    $scope.isRiskLevelReportEnabled = authorizationService.hasFeature('isRiskLevelReportEnabled');

    var weightDescription = {
        0: ' - ' + msg.get('ignore'),
        1: ' - ' + $scope.minorLabel,
        2: '',
        3: ' - ' + msg.get('normal'),
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: ' - ' + $scope.majorLabel
    };

    $scope.weightSliderOptions = {
        min: 1,
        max: 10,
        smallStep: 1,
        largeStep: 1,
        showButtons: true,
        tickPlacement: 'both',
        dragHandleTitle: msg.get('weightSliderHint'),
        decreaseButtonTitle: msg.get('weightSliderDecrease'),
        increaseButtonTitle: msg.get('weightSliderIncrease'),
        tooltip: {
            template: function(item) {
                return item.value + weightDescription[item.value];
            }
        }
    };
    $scope.weightTooltipTemplate = msg.get('weightTooltip');

    function isComponentValid() {
        // console.log('Risk Level Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule(
                $scope.alarm,
                $scope.component.type
            )
        };
    }

    function updatePayload(payload) {
        // console.log('Risk Level Update Payload', payload);
        if (!payload.weightEnabled || payload.type === 'Audit') {
            payload.weight = 0;
        }
        return true;
    }

    function alarmUpdated() {
        // console.log('Risk Level Alarm Updated');
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.riskLevel,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);

    if ($scope.alarm.weight === 0) {
        $scope.alarm.weight = 3;
        $scope.alarm.weightEnabled = false;
    } else {
        $scope.alarm.weightEnabled = true;
    }

    angular.element($window).bind('resize', function() {
        if ($scope.weightSlider) {
            $scope.weightSlider.resize();
        }
    });

    $scope.$on('sidebarToggledDelay', function() {
        if ($scope.weightSlider) {
            $scope.weightSlider.resize();
        }
    });
}
