import { useCallback, useState } from 'react';
import { fetchData } from '../../common/helpers';
import { useNotifications } from '../../common/services/Notifications';
import { IInsightsCategory } from '../models';
import { ICategoriesState } from '../models/ICategoriesState';

export const useCategoriesState = (): ICategoriesState => {
  const notificationService = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<IInsightsCategory[]>([]);

  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchData<IInsightsCategory[]>({
        path: '/api/insights/categories'
      });
      setCategories(data);
    } catch (error) {
      notificationService.error('Error fetching categories');
      console.log(`Error fetching  categories:`, error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    fetchCategories();
  }, [fetchCategories]);

  return {
    init,
    isLoading,
    categories
  };
};
