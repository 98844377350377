'use strict';

angular.module('app').directive('categoriesControls', categoriesControls);

function categoriesControls() {
    return {
        restrict: 'E',
        scope: {
            categoriesDataSourceReload: '=',
            selectedFilterOptionId: '=',
            selectFilterOption: '='
        },
        template: require('views/widgets/categoriesControls.html'),
        controller: CategoriesControlsCtrl
    };
}

CategoriesControlsCtrl.$inject = ['$scope', 'customUibModal', 'messagesService', 'widgetsApiService'];

function CategoriesControlsCtrl($scope, customUibModal, msg, widgetsApiService) {
    var categoryServiceFunctions = require('_app/classification/categoryServiceFunctions').categoryServiceFunctions;
    $scope.showAddCategory = false;
    $scope.showControls = true;

    $scope.isProtectedCategory = function (id) {
        return categoryServiceFunctions.isProtectedCategory(id);
    };

    $scope.addCategory = function (e) {
        e.preventDefault();
        $scope.showControls = false;
        $scope.showAddCategory = true;
    };

    $scope.cancelAddCategory = function () {
        $scope.newCategoryName = '';
        $scope.showAddCategory = false;
        $scope.showControls = true;
    };

    $scope.submitAddCategory = function () {
        if (!$scope.newCategoryName) {
            return;
        }

        var payload = {
            name: $scope.newCategoryName
        };
        widgetsApiService
            .addCategory(payload)
            .success(function () {
                $scope.$emit('showNotification', {
                    message: msg.get('categoryCreated'),
                    color: 'success'
                });
                $scope.categoriesDataSourceReload();
            })
            .error(function (result) {
                $scope.$emit('showNotification', {
                    message: result.error,
                    color: 'danger'
                });
            });

        $scope.showControls = true;
        $scope.showAddCategory = false;
        $scope.newCategoryName = '';
    };

    $scope.removeCategory = function (e) {
        e.preventDefault();
        var modal = customUibModal.open({
            animation: false,
            template: require('views/modals/messageModal.html'),
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageBody: msg.get('deleteCategory'),
                    confirmLabel: msg.get('remove')
                }
            }
        });

        modal.result.then(function () {
            var selectFilterFunction = $scope.selectFilterOption;
            widgetsApiService
                .deleteCategory($scope.selectedFilterOptionId)
                .success(function () {
                    $scope.$emit('showNotification', {
                        message: msg.get('categoryRemoved'),
                        color: 'success'
                    });
                    selectFilterFunction(0);
                    $scope.categoriesDataSourceReload();
                })
                .error(function (result) {
                    $scope.$emit('showNotification', {
                        message: result.error,
                        color: 'danger'
                    });
                });
        });
    };
}
