'use strict';

angular.module('app').controller('ssRedactionSettingsModalController', SsRedactionSettingsModalController);

SsRedactionSettingsModalController.$inject = [
    '$scope',
    '$timeout',
    '$uibModalInstance',
    '$window',
    'redactionSettings',
    'redactionTypesService',
    'messagesService',
    'screenshotsRedactionService',
    'atHelperFunctions'
];

function SsRedactionSettingsModalController(
    $scope,
    $timeout,
    $uibModalInstance,
    $window,
    redactionSettings,
    redactionTypesService,
    msg,
    screenshotsRedactionService,
    atHelperFunctions
) {
    $scope.enableDLPTooltip = msg.get('enableDLPTooltip');

    var infoTypeLimit = 18;
    var selectedInfoTypes = [];
    var scrollToSelected = false;
    var isUpdating = false;
    var isDirty = false;
    var remainingLimit = 0;

    function addRemoveButtonTemplate(item) {
        var redacted = isRedacted(item.name);
        return (
            '<span ' +
            'class="btn btn-xs pull-right at-btn-in-row' +
            (redacted ? ' btn-danger' : ' btn-success') +
            (remainingLimit <= 0 && !redacted ? ' hide" ' : '" ') +
            'ng-click="changeRedactionItem(dataItem)" ng-class="{ hide: !redactionEnabled }">' +
            '<i class="fa' +
            (redacted ? ' fa-minus' : ' fa-plus') +
            '"></i>' +
            '</span>'
        );
    }

    function infoTypeRowTemplate(item) {
        if (item.isRegionRow) {
            return '<div>{{dataItem.region}}</div>';
        }
        var tootltipWidth = Math.min(420, $window.innerWidth - 20);
        item.tooltip =
            '<div style="max-width: ' +
            tootltipWidth +
            'px;">' +
            (item.displayName ? '<strong>' + item.displayName + '</strong><br/>' : '') +
            (item.description ? item.description + '<br/>(' + item.name + ')' : item.name) +
            '</div>';
        return (
            '<div><div class="inline at-row-item at-ellipsis" at-tooltip tooltip-content="dataItem.tooltip" tooltip-placement="top" class="type-row"' +
            "\" ng-style=\"{'max-width': (columnWidth - 70) + 'px'}\">{{dataItem.displayName}}</div>" +
            ($scope.redactionEnabled ? addRemoveButtonTemplate(item) : '') +
            '</div>'
        );
    }

    function noSelectedRecordsTemplate() {
        return (
            '<div class="m-t-10"><strong>No redaction info type selected.</strong><br/>Use the controls to the right to add info types.</div>' +
            '<div ng-class="{ hide: !redactionEnabled }">You need at least one info type selected if screenshot redaction is enabled.</div>'
        );
    }

    var regionDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                isUpdating = true;
                var data = redactionTypesService.getRegions();
                options.success(data);
            }
        }
    });

    var availableTypesDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                isUpdating = true;

                var data = redactionTypesService.getInfoTypes().sortByRegion().injectRegionRows();

                options.success(data.infoTypes);
            }
        }
    });

    var selectedTypesDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                var data = redactionTypesService
                    .getInfoTypeDefinitions(selectedInfoTypes)
                    .sortByRegion()
                    .injectRegionRows();

                $scope.updateValues();
                options.success(data.infoTypes);
            }
        }
    });

    function isRedacted(name) {
        var infoType = _.find(selectedInfoTypes, function (selectedName) {
            return selectedName === name;
        });
        return !!infoType;
    }

    function initializeModal() {
        $scope.selectedRegion = 'Global';
        scrollToSelected = true;
        infoTypeLimit = redactionSettings.redactionItem.limit || 18;
        selectedInfoTypes = angular.copy(redactionSettings.redactionItem.names);
        $scope.redactionEnabled = redactionSettings.redactionEnabled;
        selectedTypesDataSource.read();
        availableTypesDataSource.read();
    }

    function updateDirty() {
        // If current selection length doesn't match original selection length, then dirty
        // or if enabled has changed, then dirty
        var dirty = false;
        if (
            selectedInfoTypes.length !== redactionSettings.redactionItem.names.length ||
            redactionSettings.redactionEnabled.toString() !== $scope.redactionEnabled.toString()
        ) {
            dirty = true;
        } else {
            // If lengths are the same, then make sure all items in original selection can be found in current selection.
            redactionSettings.redactionItem.names.forEach(function (name) {
                var infoItem = _.find(selectedInfoTypes, function (selectedName) {
                    return selectedName === name;
                });
                if (!infoItem) {
                    dirty = true;
                }
            });
        }

        // No changes found
        isDirty = dirty;
    }

    function updateDisableSaveReason() {
        var disableReason = null;

        if (!isDirty) {
            disableReason = 'No changes have been made';
        } else if (remainingLimit < 0) {
            disableReason = 'The number of selected info types is over the limit of ' + infoTypeLimit;
        } else if (selectedInfoTypes.length < 1 && $scope.redactionEnabled) {
            disableReason = 'At least one info type must be selected when screenshot redaction is enabled';
        }

        $scope.disableSaveReason = disableReason;
    }

    // Define grid heights
    $scope.availableTypesGridHeight = function () {
        return 367;
    };

    $scope.selectedTypesGridHeight = function () {
        return 295;
    };

    $scope.updateValues = function () {
        // Calculate remaining limit
        remainingLimit = infoTypeLimit - selectedInfoTypes.length;

        // Update counts
        $scope.selectedCount = selectedInfoTypes.length;
        $scope.selectedLimit = infoTypeLimit;

        updateDirty();
        updateDisableSaveReason();
        $scope.availableTypesGrid.refresh();
    };

    $scope.regionDropdownOptions = {
        dataSource: regionDataSource,
        valuePrimitive: true,
        select: function (e) {
            scrollToSelected = true;
            $scope.selectedRegion = e.dataItem;
            $scope.availableTypesGrid.refresh();
        }
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss();
    };

    $scope.saveSettings = function () {
        var payload = {
            redaction: $scope.redactionEnabled,
            redactionItem: {
                names: selectedInfoTypes,
                limit: infoTypeLimit
            }
        };
        screenshotsRedactionService.setPreference(payload).success(function () {
            $scope.$emit('showNotification', {
                message: msg.get('changesSaved'),
                color: 'success'
            });
            $uibModalInstance.close();
        });
    };

    $scope.availableTypesGridOptions = {
        autoBind: false,
        dataSource: availableTypesDataSource,
        columns: [
            {
                field: 'displayName',
                template: kendo.template(infoTypeRowTemplate),
                attributes: {
                    'ng-class': "{ 'region-row': dataItem.isRegionRow, 'type-row': !dataItem.isRegionRow }"
                }
            }
        ],
        scrollable: true,
        height: atHelperFunctions.getGridHeight($scope.availableTypesGridHeight()),
        change: function () {
            // Scroll to selected region
            if (scrollToSelected) {
                var scrollContentOffset = this.element.find('tbody').offset().top;
                var selectContentOffset = this.select().offset().top;
                var distance = selectContentOffset - scrollContentOffset;

                this.element.find('.k-grid-content').animate(
                    {
                        scrollTop: distance
                    },
                    400
                );
            }
            this.element.find('.k-state-selected').toggleClass('k-state-selected');
        },
        dataBound: function (e) {
            // Select region based on select region dropdown
            var grid = e.sender;
            var dataItems = grid.dataSource.view();
            var firstRow;
            var selectedRow;

            dataItems.forEach(function (row, key) {
                if (key === 0) {
                    firstRow = row;
                } else if ($scope.selectedRegion === row.region && row.isRegionRow) {
                    selectedRow = row;
                }
            });

            if (!(!!selectedRow && !!selectedRow.uid)) {
                selectedRow = firstRow;
                $scope.selectedWebsite = selectedRow;
            }

            if (!!selectedRow && !!selectedRow.uid) {
                grid.select('tr[data-uid="' + selectedRow.uid + '"]');
            }
            scrollToSelected = false;
            isUpdating = false;

            $scope.columnWidth = atHelperFunctions.getColumnWidth(grid);
        }
    };

    $scope.selectedTypesGridOptions = {
        autoBind: false,
        dataSource: selectedTypesDataSource,
        columns: [
            {
                field: 'displayName',
                template: kendo.template(infoTypeRowTemplate),
                attributes: {
                    'ng-class': "{ 'region-row': dataItem.isRegionRow, 'type-row': !dataItem.isRegionRow }"
                }
            }
        ],
        scrollable: true,
        height: atHelperFunctions.getGridHeight($scope.selectedTypesGridHeight()),
        noRecords: {
            template: kendo.template(noSelectedRecordsTemplate)
        },
        dataBound: function () {
            isUpdating = false;
        }
    };

    $scope.changeRedactionItem = function (item) {
        if (isUpdating) {
            return;
        }

        isUpdating = true;

        var redacted = isRedacted(item.name);
        if (redacted) {
            // Remove from list
            _.remove(selectedInfoTypes, function (name) {
                return name === item.name;
            });
        } else {
            // Add to list
            selectedInfoTypes.push(item.name);
        }

        $scope.selectedRegion = item.region;
        selectedTypesDataSource.read();
        availableTypesDataSource.read();
    };

    $timeout(initializeModal);
}
