import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { heavyFontWeight, primaryColor } from '../../common/constants';

export const ActionButton = styled(Button)`
  color: ${primaryColor};
  flex-shrink: 0;
  flex-basis: auto;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  height: 36px;
  border-radius: 4px;
  padding-left: 18px !important;
  padding-right: 18px !important;
  letter-spacing: 1.25px !important;
  font-weight: ${heavyFontWeight} !important;
  &:hover {
    color: white !important;
    background-color: ${primaryColor} !important;
  }
`;
