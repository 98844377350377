import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IBarChartCopyProps } from '../../models';

export const BarChartCopyDisplay = (props: IBarChartCopyProps) => {
  const { widgetCopy } = props;

  return (
    <Box mt={2} pb={3}>
      <Typography>{widgetCopy.top}</Typography>
      <Typography mt={1}> {widgetCopy.bottom} </Typography>
    </Box>
  );
};
