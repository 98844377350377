import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ProductivityIcons,
  ProductivityName,
  productivityStatuses
} from '../constants/productivityName';
import { ProductivityFilterProps } from '../models/IProductivityFilter';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { ProductivityFilterSC } from '../styles';

export const ProductivityFilter = (props: ProductivityFilterProps) => {
  const { productivity, onSubmitFilter } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonValue, setButtonValue] = useState<string>();

  useEffect(() => {
    const currentOption = productivity || ProductivityName.All;
    setButtonValue(
      productivityStatuses.find((item) => item.value === currentOption)?.label
    );
  }, [productivity]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (value: ProductivityName) => {
      onSubmitFilter({
        productivity: value
      });
      handleClose();
    },
    [handleClose, onSubmitFilter]
  );

  const getOptions = useMemo(() => {
    return productivityStatuses.map((option) => {
      const status = ProductivityIcons[option.value];
      const IconComponent = status.icon;
      const color = status.color;
      return (
        <MenuItem
          key={option.value}
          value={option.value}
          selected={option.value === productivity}
          onClick={() => handleSubmit(option.value)}
        >
          {IconComponent && (
            <ListItemIcon>
              <IconComponent fontSize="small" sx={{ color: color }} />
            </ListItemIcon>
          )}
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
      );
    });
  }, [handleSubmit, productivity]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <ProductivityFilterSC
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={getOptions}
        >
          <Button
            id="a5832398-cfbc-48d3-9546-336d6d0ff829"
            variant="outlined"
            onClick={() => setIsOpen(!isOpen)}
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            {buttonValue}
          </Button>
        </ProductivityFilterSC>
      </div>
    </ClickAwayListener>
  );
};
