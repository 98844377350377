const productivityMap = (productivity) => {
    switch (productivity) {
        case 1:
            return {
                lineOne: 'Productive',
                lineTwo: 'Active',
                themeClass: 'bg-productive'
            };
        case 2:
            return {
                lineOne: 'Productive',
                lineTwo: 'Passive',
                themeClass: 'bg-productivePassive'
            };
        case -1:
            return {
                lineOne: 'Unproductive',
                lineTwo: 'Active',
                themeClass: 'bg-unproductive'
            };
        case -2:
            return {
                lineOne: 'Unproductive',
                lineTwo: 'Passive',
                themeClass: 'bg-unproductivePassive'
            };
        case -3:
            return {
                lineOne: 'Undefined',
                lineTwo: 'Passive',
                themeClass: 'bg-passive'
            };
        case 9:
            return {
                lineOne: 'Offline',
                lineTwo: 'Meetings',
                themeClass: 'bg-offline-meetings'
            };
        default:
            return {
                lineOne: 'Undefined',
                lineTwo: 'Active',
                themeClass: 'bg-undefined'
            };
    }
};

const _generateLineOne = ({ productivity, legacy }, view) => {
    if (productivity === -3 && legacy) {
        return 'Passive';
    }
    if (productivity === 9 && view === 'summaryView') {
        return productivityMap(productivity).lineOne + ' ' + productivityMap(productivity).lineTwo;
    }

    return productivityMap(productivity).lineOne;
};

const _generateTooltip = ({ inactive, lineOne, lineTwo }) => {
    return (inactive ? 'Show ' : 'Hide ') + lineOne + ' ' + lineTwo;
};

class ProductivityLegendItem {
    constructor(productivity, onClick, inactive = false, view = 'summaryView', location = '') {
        this.productivity = productivity;
        this.themeClass = productivityMap(productivity).themeClass;
        this.lineOne = _generateLineOne(this, view);
        this.lineTwo = '';
        if (view === 'detailedView') {
            if (location !== '/app/home') {
                this.lineTwo = productivityMap(productivity).lineTwo;
            }
        }
        this.inactive = inactive;
        this.tooltip = _generateTooltip(this);
        this.onClick = () => onClick(this);
    }
}

export default ProductivityLegendItem;
