import { formatDate, getTimeAgo } from '../../common/helpers';
import React from 'react';
import { IGridColumn } from '../../common/models';
import { TimeAgo } from '../../common/components/TimeAgo';

import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';

export const useAddAgentColumns = (
  dateFormat: string,
  dateTimeFormat: string
): IGridColumn[] => {
  //IAgentSearch is dataItem
  return [
    {
      field: 'userName',
      headerName: 'Username',
      isSortable: true,
      type: 'string',
      width: '18%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem?.userName ?? ' '} placement="bottom-start">
            <TextOverflow>{dataItem?.userName ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'logonDomain',
      headerName: 'Logon Domain',
      type: 'string',
      isSortable: true,
      width: '18%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem?.logonDomain ?? ''} placement="bottom-start">
            <TextOverflow>{dataItem?.logonDomain ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'firstLog',
      headerName: 'First Log',
      type: 'date',
      isSortable: true,
      width: '10%',

      template: (dataItem) => {
        return <>{formatDate(dataItem?.firstLog, dateFormat) ?? ''}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'date',
      isSortable: true,
      width: '18%',
      template: (dataItem) => {
        if (dataItem) {
          const timeAgo = getTimeAgo(dataItem.lastLog);
          return (
            <TimeAgo
              lastLog={dataItem.lastLog}
              learnMoreLink="https://support.activtrak.com/"
              timeAgo={timeAgo}
              localDateTimeFormat={dateTimeFormat}
            />
          );
        }
        return <></>;
      }
    },
    {
      field: 'displayName',
      headerName: 'Display Name',
      type: 'string',
      isSortable: true,
      width: '18%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem?.displayName ?? ''} placement="bottom-start">
            <TextOverflow>{dataItem?.displayName ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'primaryId',
      headerName: 'Primary ID',
      type: 'string',
      isSortable: true,
      width: '18%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem?.email ?? ''} placement="bottom-start">
            <TextOverflow>{dataItem?.email ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    }
  ];
};
