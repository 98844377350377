import theme from '../../../theme/theme';
import { gray12 } from '../../../theme/styleGuide';

export const userActionsClasses = {
  avatar: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.contrastText,
    border: `2px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: theme.spacing(),
    '&.MuiAvatar-root': {
      backgroundColor: gray12
    }
  },
  link: {
    color: theme.palette.background.paper,
    textDecoration: 'none'
  },
  userActionsMenu: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&Item': {
      color: 'white'
    }
  }
};
