'use strict';
import angularJsTemplate from '../../../views/widgets/atSkeletonLoading.html?raw';

angular.module('app').directive('atSkeletonLoading', ['$timeout', atSkeletonLoading]);

function atSkeletonLoading($timeout) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            isLoading: '<'
        },
        template: angularJsTemplate,
        link: ($scope, element) => {
            $scope.vm = {};
            const rowHeight = 43;
            const getElementHeight = () => {
                $timeout(() => {
                    const height = element.parent().height();
                    const rowCount = Math.floor(height / rowHeight);
                    if (isNaN(rowCount) || rowCount <= 0) return;
                    $scope.marginTop = (height - rowCount * rowHeight) / 2;
                    $scope.vm.count = $scope.isLoading ? new Array(rowCount) : [];
                });
            };

            getElementHeight();

            $scope.$watch('isLoading', () => {
                getElementHeight();
            });
        }
    };
}
