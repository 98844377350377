'use strict';

angular.module('app')
    .directive('verificationNotification', function () {
        return {
            restrict: 'E',
            scope: false,
            template: require('views/widgets/verificationNotification.html'),

            controller: ['$scope', '$state', function ($scope, $state) {
                if ($state.params.verifySuccess) {
                    $scope.verifySuccess = true;
                } else if ($state.params.verifyError) {
                    $scope.verifyError = true;
                }
            }]
        };
    });
