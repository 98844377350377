import { IAddButton } from '../models/IRandomTypes';

export const ADD_BUTTONS: IAddButton[] = [
  {
    label: 'Add',
    actionType: 'add'
  },
  {
    label: 'Add and View',
    actionType: 'redirect'
  }
];
