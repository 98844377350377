import theme from '../../../../../common/theme/theme';

export const getClasses = (largeHeading) => ({
    heading: {
        '& a': { color: 'inherit !important' },
        textAlign: 'left',
        fontSize: `${largeHeading ? theme.atCustom.fontSize.title : theme.atCustom.fontSize.heading}px !important`,
        fontWeight: 600
    },
    subheading: {
        '& a': { color: 'inherit !important' },
        textAlign: 'left',
        fontSize: `${theme.atCustom.fontSize.heading}px !important`
    },
    placeholder: {
        background: theme.palette.activTrak.loading,
        color: theme.palette.activTrak.loading,
        display: 'inline-block',
        width: 100,
        height: 18,
        borderRadius: 3
    },
    placeholderWide: {
        width: 150
    },
    skeletonHeading: {
        marginTop: 1,
        marginBottom: theme.spacing(1)
    },
    skeletonSubheading: {
        marginTop: 2
    }
});
