import React from 'react';
import { IMember } from '../models';
import { MemberType } from '../enums';
import { GroupType } from '../../common/enums';
import { truncate } from 'lodash';
import Tooltip from '@mui/material/Tooltip';

export const formatGroupMember = (
  groupMemberName: string,
  alias?: string,
  truncatedLength = 20
) => {
  const cellSpan = (groupName: string) => {
    return (
      <span>
        {alias ? (
          <>
            {groupName}
            <br></br>({truncate(alias, { length: 18 })})
          </>
        ) : (
          groupName
        )}
      </span>
    );
  };

  if (groupMemberName.length > 20) {
    const truncatedString = truncate(groupMemberName, {
      length: truncatedLength
    }).trim();

    return (
      <Tooltip title={groupMemberName}>{cellSpan(truncatedString)}</Tooltip>
    );
  } else {
    return cellSpan(groupMemberName);
  }
};

export function getUserTypeCounts(members: IMember[]): {
  userCount: number;
  computerCount: number;
} {
  const userCount = members.filter((m) => m.type === MemberType.User).length;
  const computerCount = members.filter(
    (m) => m.type === MemberType.Computer
  ).length;

  return { userCount, computerCount };
}

/**
 * Performs case invariant filter on given member's domain, name, and alias.
 *
 * @param members
 * @param filter
 */
export function filterMembers<T extends IMember>(members: T[], filter): T[] {
  if (!members || !filter || filter === '') {
    return members;
  }

  const includes = (main: string, sub: string): boolean => {
    if (!sub) {
      return true;
    }
    if (!main) {
      return false;
    }
    return main.toLowerCase().includes(sub.toLowerCase());
  };

  return members.filter(
    (member) =>
      includes(member.domain, filter) ||
      includes(member.name, filter) ||
      includes(member.alias, filter)
  );
}

export function isNotATGroupType(type: GroupType) {
  return (
    type !== GroupType.ActivTrakComputerGroup &&
    type !== GroupType.ActivTrakEmptyGroup &&
    type !== GroupType.ActivTrakMixedGroup &&
    type !== GroupType.ActivTrakUserGroup
  );
}

export const bulkMembersFilters = {
  allMembers: {
    label: MemberType.All,
    template: () => MemberType.All,
    filter: 'all',
    filterValue: ''
  },
  users: {
    label: MemberType.User,
    template: () => MemberType.User,
    filter: 'type',
    filterValue: 'User'
  },
  computers: {
    label: MemberType.Computer,
    template: () => MemberType.Computer,
    filter: 'type',
    filterValue: 'Computer'
  }
};
