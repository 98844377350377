import { AxiosResponse } from 'axios';
import { apiService } from '../../helpers';
import { getDefaultConfig, getWebApiConfig } from '../../config';
import {
  IGroupsService,
  IGroup,
  IAddGroupResponseDto
} from '../../models';
import { IGroupSettings } from '../../../groups/models';
import { IGroupsSummaryDto } from '../../models/IGroupsSummaryDto';

export const groupsService = (): IGroupsService => {
  return {
    fetchItems: async (params?: any): Promise<IGroupsSummaryDto> => {
      try {
        const response = await apiService.get<IGroupsSummaryDto>(
          '/admin/v1/groups/summary',
          {
            ...getWebApiConfig(),
            params: params
          }
        );
        return response?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    fetchSettings: async (): Promise<IGroupSettings> => {
      try {
        const response = await apiService.get<IGroupSettings>(
          '/api/settings/groupsettings',
          {
            ...getDefaultConfig()
          }
        );
        return response?.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    updateSettings: async (groupSettings: IGroupSettings): Promise<any> => {
      try {
        const response = await apiService.post<AxiosResponse>(
          '/api/settings/groupsettings',
          {
            ...getDefaultConfig(),
            data: groupSettings
          }
        );
        return response;
      } catch (error) {
        throw error?.data?.error;
      }
    },
    forceUpdateSettings: async (
      groupSettings: IGroupSettings
    ): Promise<any> => {
      try {
        const response = await apiService.post<AxiosResponse>(
          '/api/settings/groupsettings/force',
          {
            ...getDefaultConfig(),
            data: groupSettings
          }
        );
        return response;
      } catch (error) {
        throw error.data?.error;
      }
    },
    createItem: async (data): Promise<IAddGroupResponseDto> => {
      try {
        const response = await apiService.post<IAddGroupResponseDto>(
          '/admin/v1/groups',
          {
            ...getWebApiConfig(),
            data: data
          }
        );

        return response.data;
      } catch (error) {
        throw error?.data?.error;
      }
    },
    updateItem: async (group: IGroup): Promise<AxiosResponse> => {
      try {
        const response = await apiService.put<AxiosResponse>(
          `/admin/v1/groups/${group.id}`,
          {
            ...getWebApiConfig(),
            data: {
              name: group.name
            }
          }
        );
        return response;
      } catch (error) {
        throw error?.data?.messages || new Error(error);
      }
    },
    deleteItem: async (selectedGroups: IGroup[]): Promise<AxiosResponse> => {
      try {
        return await apiService.delete<AxiosResponse>('/api/settings/groups', {
          ...getDefaultConfig(),
          ...{ data: selectedGroups.map((g) => g.id) }
        });
      } catch (error) {
        throw error?.data?.error;
      }
    },
    forceDeleteItem: async (
      selectedGroups: IGroup[]
    ): Promise<AxiosResponse> => {
      try {
        const response = await apiService.delete<AxiosResponse>(
          `/admin/v1/groups`,
          {
            ...getWebApiConfig(),
            data: {
              parentIds: selectedGroups.map((g) => g.id)
            }
          }
        );
        return response;
      } catch (error) {
        throw error?.data?.messages.join('\n');
      }
    },
    uploadCsv: async (file: File): Promise<number> => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await apiService.post<number>(
          '/admin/v1/groups/members/bulk',
          {
            ...getWebApiConfig(),
            data: formData,
            headers: {
              'Content-Type': undefined
            }
          }
        );

        return response?.status;
      } catch (error) {
        throw error?.data?.messages.join('\n');
      }
    },
    downloadCsv: async (): Promise<AxiosResponse> => {
      try {
        const response = await apiService.get<AxiosResponse>(
          '/admin/v1/groups/members/bulk',
          {
            ...getWebApiConfig(),
            responseType: 'blob'
          }
        );
        return response;
      } catch (error) {
        throw new Error(error);
      }
    }
  };
};
