import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';

angular.module('app').controller('createNewAlarmModalController', CreateNewAlarmModalController);

CreateNewAlarmModalController.$inject = ['$scope', '$state', '$uibModalInstance', 'messagesService'];

function CreateNewAlarmModalController($scope, $state, $uibModalInstance, msg) {
    var { screenshotsAllowed, alarmActionsAllowed } = getPrivacySettings();
    $scope.simpleAlarmTooltip = msg.get('simpleAlarmTooltip', screenshotsAllowed, alarmActionsAllowed);
    $scope.usbAlarmTooltip = msg.get('usbAlarmTooltip');
    $scope.auditAlarmTooltip = msg.get('auditAlarmTooltip');
    $scope.safeSearchAlarmTooltip = msg.get('safeSearchAlarmTooltip');
    $scope.isMouseOut = false;

    $scope.hideForMobile = function () {
        return window.outerWidth >= 992;
    };

    $scope.create = function (type) {
        if (!$scope.alarmName) {
            return;
        }

        $scope.dismiss();
        $state.go('app.alarms.details', { id: 'new', type: type, name: $scope.alarmName });
    };

    $scope.dismiss = function () {
        $scope.isCanceling = true;
        $uibModalInstance.dismiss();
    };
}
