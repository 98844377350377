'use strict';

angular.module('app').directive('aggregationsButtons', aggregationsButtons);

function aggregationsButtons() {
    return {
        restrict: 'E',
        scope: {
            aggregatePeriod: '=',
            chartType: '=',
            view: '=',
            chartTypeHandler: '=',
            viewChangeHandler: '=',
            dropdownAlignment: '@',
            disabled: '<atDisabled'
        },
        template: require('views/widgets/aggregationsButtons.html'),
        transclude: true,
        controller: AggregationsButtonsCtrl
    };
}

AggregationsButtonsCtrl.$inject = ['$scope', '$state'];

function AggregationsButtonsCtrl($scope, $state) {
    $scope.state = $state;

    $scope.periodTabOptions = {
        tabs: [
            {
                heapId: 'id_f71313ca-2025-4079-941e-e1e0e92dca82',
                text: 'Day',
                value: 'Day'
            },
            {
                heapId: 'id_9d30c0c7-e3b1-4db6-b51e-a7cd7fee17f3',
                text: 'Week',
                value: 'Week'
            },
            {
                heapId: 'id_a67c4795-d384-461a-8314-c5910216b0d8',
                text: 'Month',
                value: 'Month'
            },
            {
                heapId: 'id_774b94c3-015b-4006-a244-ccde47712595',
                text: 'Year',
                value: 'Year'
            }
        ]
    };

    $scope.viewToggleOptions = {
        labels: [
            {
                heapId: 'id_fb0be56f-ae9b-4745-804c-7c3003f9e79a',
                text: 'summaryView',
                value: 'Summary View'
            },
            {
                heapId: 'id_cbfe0c91-729f-445b-91ab-7f913a602b77',
                text: 'detailedView',
                value: 'Detailed View'
            }
        ],
        onChange: $scope.viewChangeHandler
    };
}
