import { styled } from '@mui/material/styles';
import { gray4, primaryFontSize } from '../../common/constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const useUserHealthStyles = {
  circularBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  circularProgress: {
    '&.MuiCircularProgress-colorPrimary': {
      color: `${gray4}!important`
    }
  }
};

export const UserHealthCanvasBox = styled(Box)`
  height: 312px;
  padding-bottom: 10px;
  padding-top: 25px;
`;

export const UserHealthModalSubtitle = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 35px;
    margin-bottom: 20px;
  }
`;

export const UserHealthModalBody = styled(Typography)`
  font-size: ${primaryFontSize} !important;
`;
