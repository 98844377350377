import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appSettingsComputeragents_id: IRoute = {
  name: 'app.settings.computeragents_id',
  stateDefinition: {
    url: '/settings/computeragents/{id}',
    template:
      '<settings-computer-agents-detail></settings-computer-agents-detail>',
    data: { pageTitle: 'Computer Agent' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appSettingsComputeragents_id;
