import React, { useEffect } from 'react';
import { useAccountWizardContext } from '../../services';
import { useWizardContext } from '../../../common/components/Wizard/WizardProvider';
import { AccountWizardHandOff } from './AccountWizardHandOff';
import { AccountWizardStep3Content } from './AccountWizardStep3Content';

export const AccountWizardStep3 = () => {
  const { controlFlowHandler } = useAccountWizardContext();
  const { setHideNavigation } = useWizardContext();

  useEffect(() => {
    if (!controlFlowHandler(2)) {
      setHideNavigation(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return controlFlowHandler(2) ? (
    <AccountWizardStep3Content />
  ) : (
    <>
      <AccountWizardHandOff wizardStep={true} noPadding={true} />
    </>
  );
};
