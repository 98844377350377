import { useCallback, useState } from 'react';
import {
  IActiveTimeSettings,
  IActiveTimeSettingsState,
  ActiveTimeSettingsStateProps
} from '../models';
import { defaultActiveTimeSettings } from '../constants';
import { processDurationResponse } from '../utils/activeTime.utils';

export const useActiveTimeSettingsState = ({
  service,
  notificationService
}: ActiveTimeSettingsStateProps): IActiveTimeSettingsState => {
  const { putDuration, fetchDuration, deleteDuration, postDuration } = service;

  const [activeTimeSettings, setActiveTimeSetting] =
    useState<IActiveTimeSettings>(defaultActiveTimeSettings);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getActiveSettings = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchDuration();
      const dbSettings = processDurationResponse(res);
      if (
        dbSettings &&
        JSON.stringify(dbSettings) !== JSON.stringify(activeTimeSettings)
      ) {
        setActiveTimeSetting(dbSettings);
      }
    } catch (error) {
      notificationService.error('Unable to load Active Time Settings');
      console.error(
        'ActivTrak Error: Unable to load Active Time Settings',
        error
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTimeSettings]);

  const init = useCallback(() => {
    getActiveSettings();
  }, [getActiveSettings]);

  const setActiveSettings = async (payload: IActiveTimeSettings) => {
    setLoading(true);
    try {
      if (
        activeTimeSettings.activeIndefinitely &&
        !payload.activeIndefinitely
      ) {
        await postDuration(payload.activeMaxTime);
      } else if (payload.activeIndefinitely) {
        await deleteDuration();
      } else {
        await putDuration(payload.activeMaxTime);
      }

      notificationService.success('Success! Active Time Setting is updated!');
      setActiveTimeSetting(payload);
    } catch (error) {
      notificationService.error('Unable to update Active Time Settings');
      console.error(
        'ActivTrak Error: Unable to update Active Time Settings',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return { activeTimeSettings, isLoading, init, setActiveSettings };
};
