import React, { useState, useEffect, useCallback } from 'react';
import { IAccountTimezone, ITimezoneDto } from '../../common/services';
import { Box, Button, MenuItem, Typography, Select } from '@mui/material';
import { FormContainer, TextSection, SubmitContainer } from '../styles';

type AccountTimezoneFormProps = {
  allTimezones: ITimezoneDto[];
  accountTimezone: IAccountTimezone;
  isFormDisabled: boolean;
  onSubmit: (newAccountTimezone: IAccountTimezone) => Promise<void>;
};

export const AccountTimezoneForm = (props: AccountTimezoneFormProps) => {
  const { allTimezones, accountTimezone, isFormDisabled, onSubmit } = props;

  const [newAccountTimezone, setNewAccountTimezone] =
    useState<IAccountTimezone>({
      isDateFormatDDMM: 'false',
      isTimeFormat24Hour: 'false',
      timeZone: ''
    });
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (accountTimezone) {
      setNewAccountTimezone((prevState) => ({
        ...prevState,
        ...accountTimezone
      }));
      setIsSaveDisabled(true);
    }
  }, [accountTimezone]);

  const handleTimezoneChange = useCallback((event) => {
    const name = event.target.name;
    const value = event.target.value;

    setIsSaveDisabled(false);
    setNewAccountTimezone((prevState) => ({
      ...prevState,
      ...{ [name]: value }
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(newAccountTimezone);
  }, [newAccountTimezone, onSubmit]);

  return (
    <FormContainer elevation={3}>
      <Typography variant="h6">Account Time Zone</Typography>
      <TextSection>
        Select a preferred time zone for viewing your data. All data are
        recorded in UTC, but they can be translated into a time zone of your
        choosing for presentation in your reports. If you and your agents are in
        the same time zone, you will want to select that time zone. The time
        zone setting applies for all the users using the account.
      </TextSection>
      {allTimezones?.length && (
        <Box>
          <Select
            labelId="account-timezone"
            label="Time Zone"
            value={newAccountTimezone.timeZone}
            onChange={handleTimezoneChange}
            onClick={(e) => e.stopPropagation()}
            name="timeZone"
            variant="outlined"
            disabled={isFormDisabled}
            // Fix to Breaking Top Border (CORE-2464)
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              width: '100%'
            }}
          >
            {allTimezones.map((tz) => (
              <MenuItem key={tz.id} value={tz.id}>
                {tz.displayName}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <TextSection>
        Select the date format you wish to use when viewing your data:
      </TextSection>
      <Box>
        <Select
          labelId="account-date-format"
          label="Date Format"
          value={newAccountTimezone.isDateFormatDDMM}
          onChange={handleTimezoneChange}
          onClick={(e) => e.stopPropagation()}
          name="isDateFormatDDMM"
          variant="outlined"
          disabled={isFormDisabled}
          // Fix to Breaking Top Border (CORE-2464)
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            width: '100%'
          }}
        >
          <MenuItem value="false">MM/DD/YYYY</MenuItem>
          <MenuItem value="true">DD/MM/YYYY</MenuItem>
        </Select>
      </Box>
      <TextSection>
        Select the time format you wish to use when viewing your data:
      </TextSection>
      <Box>
        <Select
          labelId="account-time-format"
          label="Time Format"
          value={newAccountTimezone.isTimeFormat24Hour}
          onChange={handleTimezoneChange}
          onClick={(e) => e.stopPropagation()}
          name="isTimeFormat24Hour"
          variant="outlined"
          disabled={isFormDisabled}
          // Fix to Breaking Top Border (CORE-2464)
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            width: '100%'
          }}
        >
          <MenuItem value="false">12-hour</MenuItem>
          <MenuItem value="true">24-hour</MenuItem>
        </Select>
      </Box>
      <SubmitContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </SubmitContainer>
    </FormContainer>
  );
};
