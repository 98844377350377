import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItemLink from './ListItemLink/ListItemLink.jsx';
import {
  Box,
  Collapse,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  Add,
  Dashboard,
  ExpandLess,
  ExpandMore,
  Help,
  Person
} from '@mui/icons-material';
import { ccSidebarClasses } from './Sidebar.styles.js';
import svg from './logo_ActivTrak_CC.svg';
import {
  CC_TYPE,
  EXPIRED_DASHBOARD_TITLE,
  PAID_DASHBOARD_TITLE,
  TRIAL_DASHBOARD_TITLE,
  ENTERPRISE_DASHBOARD_TITLE
} from '../../utils/constants.js';
import { evaluateResourceAccess } from '../../utils/api/utils.js';

const DASHBOARD = '/cc/paid-subscriptions';
const TRIAL_DASHBOARD = '/cc/trial-subscriptions';
const EXPIRED_TRIAL_DASHBOARD = '/cc/expired-trial-subscriptions';
const ENTERPRISE = '/cc/enterprise';

const Sidebar = ({
  profile,
  openDialog,
  commandCenterType,
  accountTypeCount
}) => {
  const [helpOpened, setHelp] = React.useState(false);
  const [isDashboardOpened, setDashboardOpened] = React.useState(true);

  // eslint-disable-next-line no-undef
  const resourcesUrl = `${import.meta.env.VITE_ACTIVTRAK_URL}/resources/`;
  // eslint-disable-next-line no-undef
  const supportUrl = import.meta.env.VITE_ACTIVTRAK_SUPPORT_URL;

  const onHelpChanged = () => {
    setHelp(!helpOpened);
  };

  const onDashboardChanged = () => {
    setDashboardOpened(!isDashboardOpened);
  };

  const reloadPage = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  const getAccountTypeCount = (accountType) => {
    const count = accountTypeCount[accountType] ?? 0;
    return `(${count})`;
  };

  const renderNavLinks = () => {
    switch (commandCenterType) {
      case CC_TYPE.Standard:
        return (
          <>
            <NavLink to={DASHBOARD}>
              <ListItem sx={ccSidebarClasses.link}>
                <ListItemText
                  inset
                  primary={PAID_DASHBOARD_TITLE}
                  secondary={getAccountTypeCount('paid')}
                />
              </ListItem>
            </NavLink>
            <NavLink to={TRIAL_DASHBOARD}>
              <ListItem sx={ccSidebarClasses.link}>
                <ListItemText
                  inset
                  primary={TRIAL_DASHBOARD_TITLE}
                  secondary={getAccountTypeCount('activeTrial')}
                />
              </ListItem>
            </NavLink>
            <NavLink to={EXPIRED_TRIAL_DASHBOARD}>
              <ListItem sx={ccSidebarClasses.link}>
                <ListItemText
                  inset
                  primary={EXPIRED_DASHBOARD_TITLE}
                  secondary={getAccountTypeCount('expiredTrial')}
                />
              </ListItem>
            </NavLink>
          </>
        );
      case CC_TYPE.Enterprise:
        return (
          <>
            <NavLink to={ENTERPRISE}>
              <ListItem sx={ccSidebarClasses.link}>
                <ListItemText inset primary={ENTERPRISE_DASHBOARD_TITLE} />
              </ListItem>
            </NavLink>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderDashboardSubMenu = () => {
    return (
      <>
        <ListItem onClick={onDashboardChanged} sx={ccSidebarClasses.link}>
          <ListItemIcon sx={ccSidebarClasses.icon}>
            <Dashboard color="inherit" />
          </ListItemIcon>
          <ListItemText primary="DASHBOARD" />
          {isDashboardOpened ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isDashboardOpened} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="dashboard-submenu">
            {renderNavLinks()}
          </List>
        </Collapse>
      </>
    );
  };

  const renderSidebarMenuListItems = () => {
    return (
      <>
        {renderDashboardSubMenu()}
        {evaluateResourceAccess(profile, 'ADDCUSTOMER') && (
          <ListItem sx={ccSidebarClasses.link} onClick={openDialog}>
            <ListItemIcon sx={ccSidebarClasses.icon}>
              <Add color="inherit" />
            </ListItemIcon>
            <ListItemText primary="ADD CUSTOMERS" />
          </ListItem>
        )}
        {evaluateResourceAccess(profile, 'CCACCOUNTUSERSLINK') && (
          <ListItemLink
            href="/#/app/account/access"
            target="_self"
            sx={ccSidebarClasses.link}
          >
            <ListItemIcon sx={ccSidebarClasses.icon}>
              <Person color="inherit" />
            </ListItemIcon>
            <ListItemText primary="ACCOUNT USERS" />
          </ListItemLink>
        )}
        {renderHelpSubmenu()}
      </>
    );
  };
  const renderHelpSubmenu = () => {
    return (
      <>
        <ListItem onClick={onHelpChanged} sx={ccSidebarClasses.link}>
          <ListItemIcon sx={ccSidebarClasses.icon}>
            <Help color="inherit" />
          </ListItemIcon>
          <ListItemText primary="HELP" />
          {helpOpened ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={helpOpened} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemLink
              button
              href="/#/app/help/latestupdates"
              sx={ccSidebarClasses.link}
            >
              <ListItemText inset primary="Latest Updates" />
            </ListItemLink>
            <ListItemLink
              button
              href={resourcesUrl}
              target="_blank"
              sx={ccSidebarClasses.link}
            >
              <ListItemText inset primary="Resources" />
            </ListItemLink>
            <ListItemLink
              button
              href={supportUrl}
              target="_blank"
              sx={ccSidebarClasses.link}
            >
              <ListItemText inset primary="Help Center" />
            </ListItemLink>
            <ListItemLink
              button
              sx={ccSidebarClasses.link}
              href="/#/app/help/tickets"
            >
              <ListItemText inset primary="Support Tickets" />
            </ListItemLink>
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <Drawer
      variant="permanent"
      sx={(theme) => ({
        '&.MuiDrawer-root': {
          width: theme.drawerWidth,
          flexShrink: 0
        },
        '& .MuiDrawer-paper': {
          width: theme.drawerWidth,
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.info.main,
          boxShadow: theme.shadows[10]
        }
      })}
      anchor="left"
      elevation={8}
    >
      <Box sx={ccSidebarClasses.toolbar}>
        <Link
          href="#"
          onClick={(e) => {
            reloadPage(e);
          }}
        >
          <img
            style={ccSidebarClasses.image}
            src={svg}
            alt="logo_activtrak_cc"
          />
        </Link>
      </Box>
      <List component="nav" sx={ccSidebarClasses.menu} disablePadding>
        {renderSidebarMenuListItems()}
      </List>
    </Drawer>
  );
};

export default Sidebar;
