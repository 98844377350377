import Sort from '../models/Sort';

export const groupPulseSort = (groupPulses, sort, useTotalTime) => {
  if (!groupPulses) {
    return groupPulses;
  }

  const timeField = useTotalTime ? 'avgTotalTime' : 'avgActiveTime';
  const ratioField = useTotalTime
    ? 'productiveTotalRatio'
    : 'productiveActiveRatio';

  switch (sort) {
    case Sort.HighestProductivity:
      return groupPulses.sort((a, b) =>
        a[ratioField] < b[ratioField] ? 1 : -1
      );
    case Sort.AverageProductiveHours:
      return groupPulses.sort((a, b) => (a[timeField] < b[timeField] ? 1 : -1));
    case Sort.GroupName:
      return groupPulses.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    default:
      return groupPulses;
  }
};
