import { BehaviorSubject } from 'rxjs';
import { inMemoryStore } from 'activtrak-ui-utilities';

let _datastores = inMemoryStore.get('datastores') || {};

/**
 * Sets given datastore.
 *
 * If the datastore exists, updates the data if overwrite
 * is true (default). Else if the datastore does not exist,
 * a new BehaviorSubject is created with the given data.
 *
 * @param {string} id
 * @param {any} data
 * @param {boolean} overwrite
 */
export const set = (id, data, overwrite = true) => {
    if (_datastores[id] && (overwrite || _datastores[id].value === undefined)) {
        _datastores[id].next(data);
    } else if (!_datastores[id]) {
        _datastores[id] = new BehaviorSubject(data);
    }

    inMemoryStore.set('datastores', _datastores);
    return _datastores[id];
};

/**
 * Deletes given datastore by setting data to undefined
 *
 * @param {string} id
 */
export const remove = (id) => {
    if (!_datastores[id]) {
        return;
    }

    set(id);
};

/**
 * Returns given datastore.
 *
 * If datastore does not exists, it creates one
 * before returning.
 *
 * @param {string} id
 */
export const get = (id) => {
    return _datastores[id] || set(id);
};

/**
 * Resets the datastore. Used during logout to clear data.
 */
export const reset = () => {
    const _cleanUpSubject = (subject) => {
        if (!subject) {
            return;
        }

        if (subject instanceof BehaviorSubject) {
            subject.next();
            subject.complete();
        }
    };

    _.forEach(_datastores, _cleanUpSubject);
    _datastores = {};
    inMemoryStore.set('datastores', _datastores);
};

export default {
    set,
    remove,
    get,
    reset
};
