import { atHelperFunctions } from '../serviceFunctions/atHelperFunctions';
import menuItem from './menuItem';
import sidebar from './sidebar';

// Create menu arch type
/**
 * Create menu arch type from given menu definition
 *
 * @param {Object} menuDefinition
 * @param {String} parentId
 * @param {number} index
 * @param {number} level
 */
const _createMenuArchetype = (menuDefinition, availableMenus, parentId, index = -1, level = 0) => {
    return _.filter(menuDefinition, (s) => {
        const menuExists = Boolean(availableMenus[s.menu]);
        if (!menuExists) {
            console.error('ActivTrak Error: Unknown menu name requested:', s.menu);
        }

        return menuExists;
    }).map((m) => {
        const availableMenu = availableMenus[m.menu];
        index++;

        return new menuItem({
            menu: availableMenu,
            submenus: m.submenus
                ? _createMenuArchetype(m.submenus, availableMenus, availableMenu.id, index, level + 1)
                : undefined,
            parentId,
            index,
            level
        });
    });
};

const _loadMenu = async (filename) => {
    return new Promise((resolve, reject) => {
        try {
            const menu = require(`./navigation-files/${filename}`);
            resolve(menu);
        } catch (err) {
            reject(error);
        }
    });
};

const _getMenuArchTypes = async ({ menuType, notificationService }) => {
    return new Promise((resolve, reject) => {
        // Load available menus
        _loadMenu('availableMenus.json').then((result) => {
            const _availableMenus = {};
            _.forEach(result, (m, key) => (_availableMenus[key] = m));

            const getMenu = (filename, hadError) => {
                const defaultMenu = 'defaultMenu.json';

                const getMenuFile = (type) => {
                    switch (type) {
                        case menuTypes.SupportPortal:
                            return 'supportPortalMenu.json';
                        case menuTypes.CoreApp:
                        default:
                            return defaultMenu;
                    }
                };

                const requestFilename = filename || getMenuFile(menuType);

                _loadMenu(requestFilename).then(
                    (menu) => {
                        resolve(_createMenuArchetype(menu.selections, _availableMenus));
                    },
                    (error) => {
                        // Fail loading menus due to default menu also erroring.
                        if (requestFilename === defaultMenu) {
                            notificationService.showNotification(
                                'Error loading navigation possibly due to your network setup. ActivTrak requires *.activtrak.com and *.activtrak.net to be allowed in firewall rules.',
                                'danger',
                                0
                            );
                            reject(error);
                            return;
                        }

                        // On first error pass null for filename. This will attempt to load using filename from menuType
                        // On second error, pass defaultMenu
                        if (hadError) {
                            console.error(
                                `ActivTrak Error: Failed to load requested menu "${requestFilename}", reverting to default menu.\n${error}`
                            );

                            getMenu(defaultMenu, true);
                        } else {
                            console.error(
                                `ActivTrak Error: Failed to load requested menu "${requestFilename}", reverting to plan defined menu.\n${error}`
                            );

                            getMenu(null, true);
                        }
                    }
                );
            };

            getMenu(null, false);
        });
    });
};

/**
 * Return filtered menu object.
 * Filters menu items based on if they pass
 * defined rules for each menu item.
 *
 * @param {Object} menuItems
 * @param {Object} ruleFunctions
 */
const _getMenu = (menuItems, ruleFunctions) => {
    if (!menuItems) {
        console.error('ActivTrak Error: Unable to get menu items due to missing arch type selections.', menuItems);
        return;
    }

    return _.filter(menuItems, (item) => {
        let hadSubmenus = false;

        // Filter submenus if present
        if (item.submenus) {
            hadSubmenus = true;
            item.submenus = _getMenu(item.submenus, ruleFunctions);
        }

        // Show menu if rules pass and if submenu
        // has at least one item showing
        // This ensures we don't display empty submenus
        // due to all children being removed by the filtering
        return item.show(ruleFunctions) && !(atHelperFunctions.isEmpty(item.submenus) && hadSubmenus);
    });
};

/**
 * Return filtered menu object.
 * Filters menu items based on if they fail
 * defined rules for each menu item.
 *
 * @param {Object} menuItems
 * @param {Object} ruleFunctions
 */
const _getRestrictedMenuItems = (menuItems, ruleFunctions, restrictedItems) => {
    if (!menuItems) {
        console.error('ActivTrak Error: Unable to get menu items due to missing arch type selections.', menuItems);
        return;
    }

    _.forEach(menuItems, (item) => {
        if (item.submenus) {
            restrictedItems = _getRestrictedMenuItems(item.submenus, ruleFunctions, restrictedItems);
        }

        if (!item.show(ruleFunctions)) {
            restrictedItems.push(item);
        }
    });

    return restrictedItems;
};

/**
 * Create sidebar object with filtered menu items.
 * Get menu items using given plan type.
 *
 * @param {Object} settings
 */
const createSidebar = (settings = {}) => {
    if (!settings.menuType) {
        console.error('ActivTrak Error: Unable to create sidebar due to missing menu type.');
    }

    const _sidebar = new sidebar(settings);

    _getMenuArchTypes(settings).then((menuArchType) => {
        // calling restricted items first because _getMenu filters menuArchType
        _sidebar.restrictedMenu.next(_getRestrictedMenuItems(menuArchType, settings.ruleFunctions, []));
        _sidebar.menu.next(_getMenu(menuArchType, settings.ruleFunctions));
    });

    return _sidebar;
};

const menuTypes = {
    SupportPortal: 'SupportPortal',
    CoreApp: 'CoreApp'
};

export { createSidebar, menuTypes };
