import React, { useEffect, useState } from 'react';
import { IUserIdentity } from '../models/IUserIdentity';
import {
  SEARCH_TYPE_ALL,
  defaultSearch
} from '../constants/IdentitySearchTypes';
import { UserIdentityAutocomplete } from './UserIdentityAutocomplete';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { IUserSearchState } from '../models/IUserSearchState';
import { filterOptions } from '../utils/SearchUtils';

type ComponentProps = {
  gridFilter: string;
  onSetGridFilter: (newFilter: string) => void;
  commonState: IIdentityCommonState;
  userSearchState: IUserSearchState;
};

const UserIdentitySearch = (props: ComponentProps) => {
  const { userSearchState } = props;
  const { searchTerm, onSetSearchTerm, getIdentityList } = userSearchState;

  const [searchIdentities, setSearchIdentities] = useState<IUserIdentity[]>([]);
  const [tempSearchTerm, setTempSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //go get the search list when our search term changes
  useEffect(() => {
    const getList = async () => {
      try {
        if (tempSearchTerm && tempSearchTerm.length > 0) {
          setIsLoading(true);
          const searchProps = { ...defaultSearch };
          searchProps.searchTerm = tempSearchTerm;
          searchProps.pageSize = '10';
          const identityResults = await getIdentityList(searchProps);
          const filtered = filterOptions(
            identityResults.results,
            tempSearchTerm
          );
          setSearchIdentities(filtered);
        }
      } finally {
        setIsLoading(false);
      }
    };
    getList();
  }, [getIdentityList, tempSearchTerm]);

  const handleInputChange = (event, value: string) => {
    if (!event && (!value || value.length == 0)) {
      //not sure why we get this event.  happens after clicking two enter keys
      return;
    }
    setTempSearchTerm(value);
  };

  //enter key updates main grid
  const onSearchKeysPressed = (e) => {
    if ((e.keyCode || e.which) == 13) {
      const currentValue = e.target.value;
      setTempSearchTerm(currentValue);
      onSetSearchTerm(currentValue, SEARCH_TYPE_ALL);
    }
  };

  const handleClickChange = (event: any, clickedVal: any) => {
    const value = clickedVal?.searchVal;
    const searchType = clickedVal?.typeName;
    const trimmedValue =
      value && typeof value === 'string' ? value.trim() : value;

    if (trimmedValue) {
      onSetSearchTerm(trimmedValue, searchType.toLowerCase());
    } else {
      onSetSearchTerm('', SEARCH_TYPE_ALL);
    }
  };

  return (
    <UserIdentityAutocomplete
      options={searchIdentities}
      currentValue={searchTerm}
      onChange={handleClickChange}
      onInputChange={handleInputChange}
      onKeyPress={onSearchKeysPressed}
      isLoading={isLoading}
    />
  );
};

export default UserIdentitySearch;
