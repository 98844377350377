import React, { useState } from 'react';
import { WhiteBackgroundTooltip } from './TextWithTooltip.styles';
import { TextWithTooltipProps } from './TextWithTooltip.props';
import { Theme, useMediaQuery } from '@mui/material';

export const TextWithTooltip = (props: TextWithTooltipProps) => {
  const { text, truncateNeeded } = props;

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const largerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const medScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('md', 'xl')
  );

  const truncateLegend = medScreen && truncateNeeded;

  const handleMouseEnter = (e: any) => {
    if (largerScreen) {
      const spanWidth = e.target.getBoundingClientRect().width;
      const containerWidth =
        e.target.parentElement.getBoundingClientRect().width;

      if (spanWidth > containerWidth) {
        setTooltipOpen(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: truncateLegend ? '50px' : '100px'
      }}
    >
      <WhiteBackgroundTooltip
        title={text}
        open={tooltipOpen}
        onClose={() => {
          setTooltipOpen(false);
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top-start"
      >
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {text}
        </span>
      </WhiteBackgroundTooltip>
    </div>
  );
};
