import React from 'react';
import { InfoButton } from '../../../common/components/Button';
import { showModal } from '../../../common/stores/globalModalStore';
import { OfflineMeetingsPromoModal } from '../../../_global/modals/components/OfflineMeetingsPromoModal';
import { OfflineMeetingsTooltipText } from '../components/TooltipText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const showOfflineMeetingsPromoModal = () => {
  const modalContent = React.createElement(OfflineMeetingsPromoModal);

  showModal(modalContent);
};

export const OnlineMeetingsHeader = (props: {
  hasOfflineMeetings: boolean;
}) => {
  const { hasOfflineMeetings } = props;

  const infoButton = hasOfflineMeetings && (
    <InfoButton
      tooltipText={
        <OfflineMeetingsTooltipText
          isIntegratedCalendarEnabled={true} //TODO: get this value from store
        />
      }
    />
  );
  const modalButton = !hasOfflineMeetings && (
    <Button
      id="29c35d21-8954-4698-8ef6-3821f690b912"
      variant="contained"
      onClick={showOfflineMeetingsPromoModal}
      size="small"
    >
      Enable Now
    </Button>
  );
  return (
    <>
      <Typography>Offline Meetings{infoButton}</Typography>
      {modalButton}
    </>
  );
};
