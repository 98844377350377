import React, { useEffect, useState } from 'react';
import { IAgent } from '../models';
import { StatusBox } from '../styles/accountConfiguration.styles';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlinedIcon } from '../../common/styles/app.component.styles';
import IconButton from '@mui/material/IconButton';

export const useAutoUpdateSettingsColumns = () => {
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const cols = [
      {
        field: 'os',
        fieldName: 'OS',
        headerName: 'OS',
        type: 'string',
        align: 'center',
        width: '10%',
        template: (dataItem: IAgent) => (
          <i className={'fa ' + dataItem.osIcon} title={dataItem.osDisplay} />
        )
      },
      {
        field: 'version',
        fieldName: 'Version',
        headerName: 'Version',
        type: 'string',
        align: 'center',
        width: '25%',
        template: (dataItem: IAgent) => (
          <>
            <span style={{ fontWeight: 500 }}>{dataItem.version}</span>
            <span
              className="agent-label"
              style={{ paddingLeft: '5px', fontWeight: 300 }}
            >
              ({dataItem.typeDisplay})
            </span>
          </>
        )
      },
      {
        field: 'countText',
        fieldName: 'Agent Count',
        headerName: 'Agent Count',
        type: 'string',
        align: 'center',
        width: '25%',
        template: (dataItem: IAgent) => dataItem.countText
      },
      {
        field: 'statusColor',
        fieldName: 'Status',
        headerName: 'Status',
        type: 'string',
        align: 'center',
        width: '40%',
        template: (dataItem: IAgent) => (
          <StatusBox>
            <i className={'fa fa-circle ' + dataItem.statusColor}></i>
            <span style={{ display: 'inline', marginLeft: '5px' }}>
              {dataItem.statusText}
              {dataItem.statusTooltip && (
                <Tooltip title={dataItem.statusTooltip}>
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </span>
          </StatusBox>
        )
      }
    ];

    setColumns(cols);
  }, []);

  return columns;
};
