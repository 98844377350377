import React from 'react';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { useReportFilterStore } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { PrintActivityDashboardWrapper } from '../utils/printActivityDashboardWrapper';

const PrintActivityDashboardView = () => {
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  return <PrintActivityDashboardWrapper reportFilters={reportFilters} />;
};

export const PrintActivityDashboardViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <PrintActivityDashboardView />
  </ReactivTrakComponentWrapper>
);
