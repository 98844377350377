import React, { useState } from 'react';
import { isEmpty, some } from 'lodash';
import { useGroup } from '../services';
import { useGroups } from '../../common/services/Groups';
import { useNotifications } from '../../common/services/Notifications';
import { gray4, mediumFontWeight } from '../../common/constants';
import { AddGroupMembersDialogContent } from './AddGroupMembersDialogContent';
import { Dialog, DialogTitle, DialogActions, Box, Button } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const classes = {
  bold: {
    fontWeight: mediumFontWeight
  },
  table: {
    border: `1px solid ${gray4}`
  },
  tableContainer: {
    maxHeight: '55vh'
  },
  tableHeader: {
    height: '56px',
    fontWeight: mediumFontWeight
  },
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: '55vw',
      minHeight: '60vh'
    }
  }
};

export default function AddGroupMembersButton(props) {
  const { allGroupMembers } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);

  const notificationService = useNotifications();

  const { groupState } = useGroup();
  const { group, addGroupMembers } = groupState;
  const { groupsState } = useGroups();
  const { getGroups } = groupsState;

  const hasNewMembers = () => {
    return (
      allGroupMembers &&
      some(
        allGroupMembers.filter(
          (sgm) => sgm.selected && !sgm.alreadySelected && sgm.groupId === null
        )
      )
    );
  };

  const handleAddClick = async () => {
    setAdding(true);
    const newMembers = allGroupMembers.filter(
      (sgm) => sgm.selected && !sgm.alreadySelected && sgm.groupId === null
    );

    if (isEmpty(newMembers)) {
      notificationService.error('Failed to add new members to group.');
      return;
    }

    await addGroupMembers(group.id, newMembers);

    getGroups();
    setDialogOpen(false);
    setAdding(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Button
        onClick={() => setDialogOpen(true)}
        color="secondary"
        startIcon={<PersonAddIcon />}
      >
        Add Member
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        sx={classes.dialog}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <PersonAddIcon />
            <Box fontWeight="500" paddingLeft="0.5em">
              Add Member
            </Box>
          </Box>
        </DialogTitle>
        <AddGroupMembersDialogContent allGroupMembers={allGroupMembers} />

        <DialogActions style={{ paddingRight: '20px' }}>
          <Button autoFocus onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleAddClick}
            disabled={adding || !hasNewMembers()}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
