export const PASSIVE_START_TIME_MIN = 1;
export const PASSIVE_START_TIME_MAX = 10;
export const PASSIVE_END_TIME_MIN = 0;
export const PASSIVE_END_TIME_MAX = 30;

export const defaultPassiveTimeSettings = {
  passiveStartTime: 3,
  passiveEndTime: 5,
  passiveIndefinitely: false
};
