import { Dispatch, SetStateAction } from 'react';

export type TopUsersGroupParams = {
  from: string;
  to: string;
  userId: string;
  userType: string;
  mode: string;
  range: string;
  type?: string;
  groupBy?: string;
};

export type TopGroupUsersLabels = 'Productive' | 'Unproductive' | 'Undefined';

export type TopGroupUsersDataSets = {
  axis: 'x' | 'y';
  label: TopGroupUsersLabels;
  data: number[];
  backgroundColor: string;
  relativeIndex?: number | null;
  totals?: number[] | null;
  passiveTimes?: number[] | null;
};

export type TopGroupUsersBar = {
  labels: string | string[];
  datasets: TopGroupUsersDataSets[];
};

export interface ITopUsersGroupsDto {
  user: string;
  productive: number;
  unproductive: number;
  undefined?: number;
  undefinedActive?: number;
  productivePassive: number;
  unproductivePassive: number;
  undefinedPassive: number;
  idle: number;
  total: number;
}

export interface ITopUsersGroupsOriginalDto {
  totalActivity: number;
  rows: ITopUsersGroupsDto[];
  error: string;
}

export enum TopUsersGroupFilterSort {
  productive,
  unproductive,
  undefined,
  total
}

export type TopUsersGroupsChartProps = {
  chartData: TopGroupUsersBar;
  widgetMode: WidgetMode;
  filterMode: TopUsersGroupFilterSort[];
  isWidgetStateLoading?: boolean;
};

export type WidgetMode = 'users' | 'groups';

export type WidgetInitConfig = {
  widgetMode: WidgetMode;
  filterMode: TopUsersGroupFilterSort[];
  sortMode: TopUsersGroupFilterSort;
};

export type TopUsersGroupsHeaderProps = {
  noResults: boolean;
  isWidgetStateLoading?: boolean;
  widgetMode: WidgetMode;
  onChangeWidget: Dispatch<SetStateAction<WidgetMode>>;
  filterMode: TopUsersGroupFilterSort[];
  onChangeFilter: Dispatch<SetStateAction<TopUsersGroupFilterSort[]>>;
  sortMode: TopUsersGroupFilterSort;
  onChangeSort: Dispatch<SetStateAction<TopUsersGroupFilterSort>>;
  defaultSkeletonLoader: any;
};
