import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { gray7 } from '../../common/constants';
import ChartsDemoService from '../services/ChartDemoServices';
import { processBarData } from '../utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function TopUsersBarProgress() {
  const [chartData, setChartData] = useState(null);
  const progressBar = {
    id: 'progressBar',
    beforeDatasetsDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { left },
        scales: { y }
      } = chart;

      ctx.save();

      const fontSizeLabel = 14;
      data.datasets[0].data.forEach((dataPoint, i) => {
        ctx.font = `${fontSizeLabel}px sans-serif`;
        ctx.fillStyle = 'rgba(68, 68, 68, 1)';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(
          data.labels[i],
          left,
          y.getPixelForValue(i) - fontSizeLabel - 5
        );

        //  Below would show a data point on the right of label

        //   ctx.font = `${fontSizeLabel} Roboto'`;
        //   ctx.fillStyle = 'rgba(102, 102, 102, 1)';
        //   ctx.textAlign = 'right';
        //   ctx.textBaseline = 'middle';
        //   ctx.fillText(dataPoint, right, y.getPixelForValue(i) - fontSizeLabel - 5);
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const apiParams = {
          from: '2022-06-07',
          to: '2022-06-12',
          userId: '-1',
          userType: 'AllUsers',
          mode: 'users',
          range: 'Custom_Date_Range',
          type: 'Total'
        };

        const chartsDemoService = new ChartsDemoService();
        const response: any = await chartsDemoService.getProductivity(
          apiParams
        );

        if (response) {
          const filteredData = processBarData(response);
          setChartData(filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    //retrigger api when params are updated
    if (!chartData) getData();
  }, [chartData]);

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 1,
    categoryPercentage: 0.5,
    layout: {
      padding: {
        right: 75,
        top: 2
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        color: gray7,
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          if (context.datasetIndex === 2) {
            return 'XXhXm';
          } else {
            return '';
          }
        },
        font: {
          weight: '400',
          style: 'normal',
          size: '14px',
          lineHeight: '16px',
          letterSpacing: '0.25px'
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }
    }
  };

  return (
    <>
      {!chartData && <div>Loading Chart</div>}
      {!chartData?.length && <div>No data available</div>}
      {chartData?.length && (
        <>
          <Bar
            data={chartData}
            options={options as any}
            plugins={[ChartDataLabels, progressBar]}
          />
        </>
      )}
    </>
  );
}
