'use strict';

angular.module('app')
    .directive('videosSubpage', videosSubpage);

function videosSubpage() {
    return {
        template: require('views/alarmVideo/videosSubpage.html'),
        controller: VideosSubpageCtrl
    };
}

VideosSubpageCtrl.$inject = ['$scope', '$rootScope', '$state', '$filter', 'localStorageService', 'messagesService', '$timeout', 'atHelperFunctions', 'templateServiceFunctions', 'browserServiceFunctions'];

function VideosSubpageCtrl($scope, $rootScope, $state, $filter, localStorageService, msg, $timeout, atHelperFunctions, templateServiceFunctions, browserServiceFunctions) {

    $scope.filter = angular.copy($state.params);
    $scope.loading = false;
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();
    $scope.video = [];

    $scope.config = {
        preload: 'none',
        plugins: {
            controls: {
                autoHide: true,
                autoHideTime: 3000
            }
        }
    };

    $scope.hideFullScreenButton = function () {
        return !(atHelperFunctions.isSmallWindow() || ($scope.playerApi && !!$scope.playerApi.isFullScreen));
    };

    $scope.toggleFullscreenDetails = function (video) {
        if (!!video) {
            $scope.setVideo(video);
        }

        $scope.fullscreenVideo = !$scope.fullscreenVideo;

        if (!!$scope.playerApi) {
            $scope.playerApi.stop();
        }

        if (!$scope.fullscreenVideo) {
            $scope.setVideo({
                videoId: ''
            });
        }
    };

    $scope.playerReady = function (api) {
        $scope.playerApi = api;
        $scope.playerApi.setVolume(0);
        if (!$scope.fullscreenVideo) {
            $timeout(function () {
                $scope.playerApi.play();
            });
        }
    };

    $scope.setVideo = function (video) {
        if (video.videoId !== $scope.video.videoId) {
            $scope.video = video;
        }
    };

    $scope.showVideo = function (id) {
        return !!id && $scope.video.videoId === id && !$scope.fullscreenVideo;
    };

    $scope.applyFilter = function (filter, shouldReplaceFilter) {
        if (shouldReplaceFilter) {
            $scope.filter = filter;
        }
        if (!!filter) {
            var processedFilters = {
                logic: 'and',
                filters: []
            };
            for (var field in filter) {
                if (!!filter[field] && typeof filter[field] === 'string') {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'contains',
                        value: filter[field]
                    });

                }
            }
            $scope.filterData.appliedFilter = {};
            angular.copy(filter, $scope.filterData.appliedFilter);
            $scope.source.filter(processedFilters);
        }
    };

    $scope.mode = localStorageService.get('alarm-video-gallery-view-mode') || 1;
    $scope.newestFirst = localStorageService.get('alarm-video-newest-first') || false;

    $scope.$on('videoGalleryViewModeChanged', function (event, data) {
        $scope.mode = data.mode;
        localStorageService.set('alarm-video-gallery-view-mode', data.mode);
    });

    $scope.switchViewMode = function (mode) {
        $scope.mode = mode;
        localStorageService.set('alarm-video-gallery-view-mode', mode);
    };

    $scope.getTimeStamp = function (t) {
        return atHelperFunctions.getMomentTimeStamp(t);
    };

    $scope.filterTime = function (t) {
        return atHelperFunctions.filterTime(t, $filter);
    };

    $scope.showUser = function (v) {
        if (!v.username) {
            return false;
        }
        return !!v.username && ($scope.filterMode === 'users' || $scope.mode === 1);
    };

    $scope.showComputer = function (v) {
        return v.computer && ($scope.filterMode === 'computers' || $scope.mode === 1);
    };

    var applySortToSource = function () {
        $scope.source.sort({
            field: 'time',
            dir: $scope.newestFirst ? 'desc' : 'asc'
        });
    };

    $scope.switchSort = function () {
        $scope.newestFirst = !$scope.newestFirst;
        localStorageService.set('alarm-video-newest-first', $scope.newestFirst);
        applySortToSource();
    };

    $scope.showImage = function (index) {
        $scope.$broadcast('showScreenshotEvent', {
            index: index
        });
    };

    $scope.openProductivity = function (e, item) {
        e.preventDefault();
        if (!$scope.hasViewLevel('edit') || browserServiceFunctions.isMobileAgent()) {
            return;
        }
        var stateParams = $scope.getProductivityStateParams(item);
        if (stateParams.item === null) {
            return;
        }
        $state.go('app.settings.productivity', stateParams);
    };

    $scope.$watch('fullscreenVideo', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $rootScope.app.layout.fullscreenView = newValue;
        }
    });

    $scope.filterData = {
        panelTitle: msg.get('filterVideos'),
        fields: [{
                label: msg.get('alarmName'),
                name: 'alarmName'
            },
            {
                label: msg.get('title'),
                name: 'title'
            },
            {
                label: msg.get('description'),
                name: 'description'
            },
            {
                label: msg.get('executable'),
                name: 'executable'
            },
            {
                label: msg.get('url'),
                name: 'url'
            },
            {
                label: msg.get('computer'),
                name: 'computer'
            },
            {
                label: msg.get('user'),
                name: 'user'
            }
        ],
        appliedFilter: angular.copy($state.params)
    };
    $scope.applyFilter($scope.filter, true);

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: '.overflow-ellipsis'
    });

    $scope.$on('forgetCurrentVideo', function () {
        $scope.video = [];
    });

    // Close details on back button instead of navigate back.
    $scope.$on('$locationChangeStart', function (event) {
        if ($scope.playerApi && $scope.playerApi.isFullScreen) {
            event.preventDefault();
        }
    });
}
