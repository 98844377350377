import React from 'react';
import { Box } from '@mui/material';
import { StyledBox } from '../StyledBoxes';
import {
  SimpleStepWizardContainer,
  StyledHeader,
  StyledSubtitle
} from '../../styles';
import stepsData from '../../constants/stepsData.json';

export const AccountWizardStep3Content = () => {
  const inputIds = [
    '2:0:basicdetails',
    '2:1:advanceddetails',
    '2:2:fulldetails'
  ];
  const textData = stepsData.step3;

  return (
    <Box>
      <StyledHeader>{textData.title}</StyledHeader>
      <StyledSubtitle $paddingBottom="10px" noPadding>
        {textData.subtitle}
      </StyledSubtitle>
      <SimpleStepWizardContainer sx={{ gap: '10px', paddingTop: '15px' }}>
        <StyledBox
          noStyle={true}
          radioTextWeight="500"
          textArea={textData.styledBoxes}
          inputId={inputIds}
          type="radio"
          name="dataAccessLevel"
          bypassControlFlow={true}
        />
      </SimpleStepWizardContainer>
    </Box>
  );
};
