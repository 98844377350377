'use strict';

angular.module('app')
    .service('tooltipService', TooltipService);

//TooltipService.$inject = [];

function TooltipService() {

    this.addTooltipMargin = function (that, margin) {
        var position = {
            top: 'top',
            bottom: 'top',
            left: 'left',
            right: 'left'
        }[that.options.position];

        if ((that.options.position === 'top' && !that.popup.flipped) || (that.options.position === 'left' && !that.popup.flipped) || (that.options.position === 'bottom' && that.popup.flipped) || (that.options.position === 'right' && that.popup.flipped)) {
            that.popup.wrapper.css(position, (parseInt(that.popup.wrapper.css(position)) - margin));
        } else {
            that.popup.wrapper.css(position, (parseInt(that.popup.wrapper.css(position)) + margin));
        }
    };

    this.fixTooltipCallout = function (that) {
        var calloutDirections = {
            bottom: 'n',
            top: 's',
            left: 'e',
            right: 'w',
            bottomFlipped: 's',
            topFlipped: 'n',
            leftFlipped: 'w',
            rightFlipped: 'e',
            center: 'n'
        };

        if (that.options.callout) {
            that.arrow.removeClass('k-callout-n')
                .removeClass('k-callout-s')
                .removeClass('k-callout-e')
                .removeClass('k-callout-w');
            var direction = that.options.position + (that.popup.flipped ? 'Flipped' : '');
            that.arrow.addClass('k-callout-' + calloutDirections[direction]);
        }
    };
}
