angular.module('app').service('atkHttpDecorator', [
    '$http',
    function ($http) {
        return {
            init: function () {
                //rewrite get
                var oldGet = $http.get;
                var oldPost = $http.post;
                var oldDelete = $http.delete;
                var oldPut = $http.put;


                //Taken from AngularJS source
                $http.get = function (url, config) {

                    var promise = oldGet(url, config);

                    promise.success = function (fn) {

                        promise.then(function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    promise.error = function (fn) {

                        promise.then(null, function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    return promise;
                };

                $http.post = function (url, data, config) {

                    var promise = oldPost(url, data, config);

                    promise.success = function (fn) {

                        promise.then(function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    promise.error = function (fn) {

                        promise.then(null, function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    return promise;
                };

                $http.delete = function (url, data, config) {

                    var promise = oldDelete(url, data, config);

                    promise.success = function (fn) {

                        promise.then(function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    promise.error = function (fn) {

                        promise.then(null, function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    return promise;
                };

                $http.put = function (url, data, config) {

                    var promise = oldPut(url, data, config);

                    promise.success = function (fn) {

                        promise.then(function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    promise.error = function (fn) {

                        promise.then(null, function (response) {
                            fn(
                                response.data,
                                response.status,
                                response.headers,
                                config
                            );
                        });
                        return promise;
                    };

                    return promise;
                };
            }
        };
    }
]);
