import { FeatureFlag } from '../../../_reactivtrak/src/common/enums/FeatureFlag';
import lodash from 'lodash';

/*
Real-time Screenshot Service
Purpose: Handle the processing of the real-time screenshot websocket requests
*/

angular.module('app').service('realtimeScreenshotService', RealtimeScreenshotService);

RealtimeScreenshotService.$inject = [
    '$timeout',
    '$rootScope',
    'AccountSettingsService',
    'websocketService',
    'authorizationService'
];

function RealtimeScreenshotService(
    $timeout,
    $rootScope,
    AccountSettingsService,
    websocketService,
    authorizationService
) {
    var service = this;
    var _pollDelay;
    var _messageTimeout;
    var _messageDelay;
    var _lastSeenThreshold;
    var _realTimeScreenshotEnabled;
    var _maxReconnectDelay;
    var _imageContainers = []; // Object for thumbnail containers

    function createContainer(item, containerSize, imageCallback, type) {
        var container = {
            type: type,
            error: null,
            item: {
                id: item.userId,
                accountId: AccountSettingsService.account,
                computerName: item.device,
                computerAlias: item.devicealias,
                primaryDomain: item.pdom,
                loginDomain: item.ldom,
                username: item.username || item.user,
                alternativeLoginDomain: item.altldom,
                alternativeUsername: item.altusername,
                userAlias: item.user,
                extendedAltNames: item.extendedaltnames,
                imageUrl: null,
                imageCallback: imageCallback,
                messageIds: [],
                width: containerSize && containerSize.width,
                height: containerSize && containerSize.height
            }
        };

        function messageHandler(message) {
            if (!message || !lodash.includes(container.item.messageIds, message.Id)) {
                return;
            }

            lodash.remove(container.item.messageIds, function (i) {
                return i === message.Id;
            });
            container.error = message.Error;
            message.Item = container.item;
            // console.log('Message Handler', container, message);

            if (typeof container.item.imageCallback === 'function') {
                container.item.messageDelayTracker = container.item.messageIds.length > 0 ? moment() : null;
                container.item.lastMessageReceived = moment();

                if (container.error) {
                    container.item.imageCallback(null, container.error);
                    // console.warn('Error retrieving thumbnail.', container.item.username, container.error);
                    updateErrorDelay();
                    return;
                }

                var imageUrl = message.Result && message.Result.Screenshot && message.Result.Screenshot.Data;

                if (!imageUrl) {
                    if (message.Result.Screenshot.Title === 'Not in Chrome window') {
                        container.error = message.Result.Screenshot.Title;
                    } else {
                        container.error = 'No Image URL Returned.';
                    }

                    container.item.imageCallback(null, container.error);
                    // console.warn('Error retrieving thumbnail.', container.item.username, container.error);
                    updateErrorDelay();
                    return;
                }

                container.error = null;
                container.errorDelay = null;

                if (imageUrl && imageUrl.indexOf('base64') < 0) {
                    imageUrl = 'data:image/jpg;base64,' + imageUrl;
                }
                container.item.imageUrl = imageUrl || container.item.imageUrl;
                if (container.type === 'viewer') {
                    container.item.imageCallback(message);
                } else {
                    container.item.imageCallback(imageUrl);
                    container.continuePolling();
                }
            }
        }

        function updateErrorDelay() {
            if (container.errorDelay) {
                container.errorDelay = Math.min(container.errorDelay * 2, _maxReconnectDelay);
            } else {
                container.errorDelay = _pollDelay * 2;
            }
            // console.log('Update Error Delay', container.errorDelay);
            container.continuePolling();
        }

        function checkAwaitingMessage() {
            if (
                container.item.messageDelayTracker &&
                moment().diff(container.item.messageDelayTracker, 'milliseconds') > _messageTimeout
            ) {
                // console.warn('Waiting too long for image.', container.item.id, moment().diff(container.item.messageDelayTracker, 'seconds'));
                // console.log('Time since first image request:', container.item.id,  moment().diff(container.item.firstMessageRequest, 'seconds'), container.item.firstMessageRequest.format());
                // console.log('Last Message Received:', container.item.id,  moment().diff(container.item.lastMessageReceived, 'seconds'), container.item.lastMessageReceived && container.item.lastMessageReceived.format());
                container.item.messageDelayTracker = null;
            } else if (
                container.item.messageDelayTracker &&
                moment().diff(container.item.messageDelayTracker, 'milliseconds') > _messageDelay
            ) {
                // console.log('Image Request Delayed', container.item.id, moment().diff(container.item.messageDelayTracker, 'seconds'));
                container.error = 'delayed';
                container.item.imageCallback(null, container.error);
            }
            return !!container.item.messageDelayTracker;
        }

        container.requestScreenshot = function () {
            if (checkAwaitingMessage()) {
                // console.log('Request Screenshot Denied - Awaiting Previous Message', container.item.id);
                return;
            }
            // console.log('Request Screenshot', container.item.id);

            var params = {
                AgentId: {
                    AccountId: container.item.accountId,
                    ComputerName: container.item.computerName,
                    PrimaryDomain: container.item.primaryDomain,
                    LoginDomain: container.item.loginDomain,
                    UserName: container.item.username,
                    AlternativeLoginDomain: container.item.alternativeLoginDomain,
                    AlternativeUsername: container.item.alternativeUsername,
                    ExtendedAltNames: container.item.extendedAltNames
                }
            };

            if (container.item.width || container.item.height) {
                params.Screenshot = {};
                if (container.item.width) {
                    params.Screenshot.Width = container.item.width;
                }
                if (container.item.height) {
                    params.Screenshot.Height = container.item.height;
                }
            }

            websocketService
                .sendCommand('GetScreenshotCommand', params, messageHandler)
                .then(function (messageId) {
                    container.item.messageIds.push(messageId);
                    container.item.messageDelayTracker = moment();
                    container.item.firstMessageRequest = container.item.firstMessageRequest || moment();
                })
                .catch(function () {
                    container.error = 'Cannot Retrieve Screenshot';
                    if (typeof container.item.imageCallback === 'function') {
                        container.item.imageCallback(null, container.error);
                    }
                });
        };

        container.startPolling = function () {
            // console.log('Start Polling', container.item.id);
            container.stopPollingCalled = false;
            container.lastPoll = moment();
            container.lastSeen = moment();
            container.requestScreenshot();
        };

        container.validatePolling = function () {
            // console.log('Validate Polling', container.item.id);
            container.lastSeen = moment();
            container.continuePolling();
        };

        container.continuePolling = function () {
            var pollDelayDiff = moment().diff(container.lastPoll, 'milliseconds');
            var pollDelay = container.errorDelay || _pollDelay;

            $timeout.cancel(container.continueDelay);

            if (pollDelayDiff < pollDelay) {
                // console.log('Continue Polling - Delay', container.item.id, pollDelayDiff, pollDelay);
                container.continueDelay = $timeout(function () {
                    container.continuePolling();
                }, pollDelayDiff);
                return;
            }

            if (moment().diff(container.lastSeen, 'milliseconds') > _lastSeenThreshold) {
                // console.log('Continue Polling - Agent Not Seen', moment().diff(container.lastSeen, 'milliseconds'));
                container.stopPollingCalled = true;
            } else {
                // console.log('Continue Polling - Agent Seen', moment().diff(container.lastSeen, 'milliseconds'));
                container.stopPollingCalled = false;
            }

            if (!container.stopPollingCalled) {
                // console.log('Continue Polling - Request Screenshot', container.item.id);
                container.lastPoll = moment();
                container.requestScreenshot();
            }
        };

        container.stopPolling = function () {
            // console.log('Stop Polling', container.item.id);
            container.stopPollingCalled = true;
        };

        // console.log('Created Container', container);
        return container;
    }

    // Find container by userId
    function findContainer(id) {
        var container = _imageContainers.find(function (c) {
            return c.item.id === id;
        });
        return container;
    }

    // Pause Thumbnail Polling
    function pauseThumbnailPolling() {
        _imageContainers.forEach(function (container) {
            container.stopPolling();
        });
    }

    // Continue Thumbnail Polling
    function continueThumbnailPolling() {
        _imageContainers.forEach(function (container) {
            container.startPolling();
        });
    }

    function setDefaults() {
        _imageContainers = [];
        _pollDelay =
            (AccountSettingsService.realtimeSettings && AccountSettingsService.realtimeSettings.thumbnailPollDelay) ||
            1000;
        _messageTimeout =
            (AccountSettingsService.realtimeSettings &&
                AccountSettingsService.realtimeSettings.messageTimeoutThreshold) ||
            10000;
        _messageDelay =
            (AccountSettingsService.realtimeSettings &&
                AccountSettingsService.realtimeSettings.messageDelayThreshold) ||
            5000;
        _lastSeenThreshold =
            (AccountSettingsService.realtimeSettings && AccountSettingsService.realtimeSettings.lastSeenThreshold) ||
            2000;
        _realTimeScreenshotEnabled = authorizationService.hasFeature(FeatureFlag.RealtimeScreenShots);
        _maxReconnectDelay = _pollDelay * 8;
    }

    // Initialize the websocket service
    service.initialize = function () {
        setDefaults();

        if (!_realTimeScreenshotEnabled) {
            // console.log('RTSS Disabled, can\'t initialize');
            return;
        }

        // console.log('Real-time Settings Initialized', AccountSettingsService.realtimeSettings);
        return websocketService.connect();
    };

    service.reset = function () {
        setDefaults();
    };

    service.destroy = function () {
        websocketService.close();
    };

    // Request fullscreen viewer image
    service.requestViewerImage = function (item, screenSize, imageCallback) {
        if (!_realTimeScreenshotEnabled) {
            imageCallback(null, 'Real-time Screenshots Disabled');
        }

        var container = createContainer(item, screenSize, imageCallback, 'viewer');
        container.requestScreenshot();
        pauseThumbnailPolling();
    };

    // Cancel fullscreen viewer image
    service.cancelViewerImage = function () {
        continueThumbnailPolling();
    };

    // Register thumbnail container
    // Create new container if it doesn't exist
    // Connect thumbnail to container if it does exist
    service.registerImageContainer = function (row, containerSize, imageCallback) {
        if (!_realTimeScreenshotEnabled) {
            return {
                imageUrl: null,
                error: 'Real-time Screenshots Disabled'
            };
        }

        if (!row || !row.userId) {
            return {
                imageUrl: null,
                error: 'No row data given'
            };
        }

        var container = findContainer(row.userId);
        // console.log('Register Container', container, row.username, row.userId);
        if (!container) {
            container = createContainer(row, containerSize, imageCallback, 'thumbnail');
            _imageContainers.push(container);
            container.startPolling();
        } else {
            container.item.imageCallback = imageCallback;
            container.item.width = containerSize && containerSize.width;
            container.item.height = containerSize && containerSize.height;
            container.validatePolling();
        }

        return {
            imageUrl: container.item.imageUrl,
            error: container.error
        };
    };

    $rootScope.$on('RealtimeSettingsSet', function (e, settings) {
        _pollDelay = (settings && settings.thumbnailPollDelay) || 1000;
        _messageTimeout = (settings && settings.messageTimeoutThreshold) || 10000;
        _messageDelay = (settings && settings.messageDelayThreshold) || 5000;
        _lastSeenThreshold = (settings && settings.lastSeenThreshold) || 2000;
        _realTimeScreenshotEnabled = authorizationService.hasFeature(FeatureFlag.RealtimeScreenShots);
        // console.log('Real-time Settings Set', settings);
    });
}
