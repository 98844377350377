export const lpClassNames = {
  showWeekNumbers: 'show-week-numbers',
  litepicker: 'litepicker',
  containerMain: 'container__main',
  containerMonths: 'container__months',
  columns2: 'columns-2',
  columns3: 'columns-3',
  columns4: 'columns-4',
  splitView: 'split-view',
  monthItemHeader: 'month-item-header',
  buttonPreviousMonth: 'button-previous-month',
  buttonNextMonth: 'button-next-month',
  monthItem: 'month-item',
  monthItemName: 'month-item-name',
  monthItemYear: 'month-item-year',
  resetButton: 'reset-button',
  monthItemWeekdaysRow: 'month-item-weekdays-row',
  noPreviousMonth: 'no-previous-month',
  noNextMonth: 'no-next-month',
  containerDays: 'container__days',
  dayItem: 'day-item',
  isToday: 'is-today',
  isLocked: 'is-locked',
  isInRange: 'is-in-range',
  isStartDate: 'is-start-date',
  isFlipped: 'is-flipped',
  isEndDate: 'is-end-date',
  isHighlighted: 'is-highlighted',
  weekNumber: 'week-number',
  containerFooter: 'container__footer',
  previewDateRange: 'preview-date-range',
  buttonCancel: 'button-cancel',
  buttonApply: 'button-apply',
  containerTooltip: 'container__tooltip'
};
