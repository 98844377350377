import theme from '../../theme/theme';

export const layoutClasses = {
  root: {
    display: 'flex',
    height: '100vh',
    '::-webkit-scrollbar': {
      width: 5,
      height: 5
    },
    '::-webkit-scrollbar-track': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.1)'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.1)'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,0.3)'
    },
    '::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0,0,0,.4)'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(11),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  actionItem: {
    alignItems: 'center',
    display: 'flex',
    height: theme.spacing(3),
    padding: `0 ${theme.spacing(1)}`,
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.text.disabled}`
    },
    '&:last-child': {
      paddingRight: 0
    }
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center'
  },
  dashboardHeader: {
    marginBottom: theme.spacing(2)
  },
  link: {
    '&:hover': {
      fontWeight: '500',
      color: theme.palette.common.black
    }
  },
  blueLink: {
    '&:hover': {
      fontWeight: '500'
    }
  }
};
