import React, { useCallback } from 'react';
import { GroupType, GroupTypeBasic } from '../../common/enums';
import { IGroup } from '../../common/models';
import { GroupTypeLabel } from '../../common/components/GroupType';
import { typeMenuOptions, mixMenuOptions } from '../constants/groupMenuTypes';

export const useGroupTypesMenu = () => {
  const getFilterIndex = (groups: IGroup[], filter: GroupType) => {
    return groups.findIndex((group) => group.filter === filter);
  };

  const getDynamicGroupsMenu = useCallback((groups: IGroup[]) => {
    const groupsMenu = [];

    //find all groups that match type. if length > 0, find existence of each mix within that filtered array
    for (let i = 0; i < typeMenuOptions?.length; i++) {
      const type = typeMenuOptions[i];

      const filteredGroups: IGroup[] = groups?.filter(
        (group) => group.type === type.label
      );

      if (filteredGroups?.length) {
        const subMenu = {
          label: type.label,
          template: () => (
            <GroupTypeLabel iconSource={type.label} label={type.label} />
          ),
          children: []
        };
        for (let j = 0; j < mixMenuOptions?.length; j++) {
          const mix = mixMenuOptions[j];
          const filterValue = GroupType[`${type.filterBase}${mix}Group`];

          const currentFilterIndex = getFilterIndex(
            filteredGroups,
            filterValue
          );

          if (currentFilterIndex > -1) {
            subMenu.children.push({
              label: `${mix} Group`,
              template: () => `${mix} Group`,
              filter: 'filter',
              filterValue: filterValue,
              selected: false
            });
          }
        }
        if (subMenu.children.length) groupsMenu.push(subMenu);
      }
    }

    return groupsMenu;
  }, []);

  const getGroupTypeMenu = useCallback(
    (groups: IGroup[]) => {
      const dynamicMenu: any[] = [
        {
          label: GroupTypeBasic.All,
          template: () => GroupTypeBasic.All,
          filter: 'all',
          filterValue: GroupType.All,
          selected: false,
          handleNone: false
        }
      ];

      const typesMenu = getDynamicGroupsMenu(groups);

      return [...dynamicMenu, ...typesMenu];
    },
    [getDynamicGroupsMenu]
  );

  return { getGroupTypeMenu };
};
