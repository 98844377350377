import React from 'react';
import { DialogContent, TextField, Typography } from '@mui/material';

type CreateGroupDialogContentProps = {
  groupName: string;
  setGroupName: (name: string) => void;
};

export default function CreateGroupDialogContent(
  props: CreateGroupDialogContentProps
) {
  const { groupName, setGroupName } = props;

  const handleGroupNameChange = (event) => setGroupName(event.target.value);

  return (
    <DialogContent>
      <Typography sx={{ marginBottom: '16px' }}>
        Create groups specific to your organization to analyze data through the
        live reports, ActivConnect and Insights by team or role.
      </Typography>
      <div>
        <TextField
          autoFocus
          id="name"
          label="Group Name"
          variant="outlined"
          value={groupName || ''}
          onChange={handleGroupNameChange}
          fullWidth
        />
      </div>
    </DialogContent>
  );
}
