'use strict';

angular.module('app')
    .service('verificationApiService', VerificationApiService);

VerificationApiService.$inject = ['$http', 'loginServiceUrl'];

function VerificationApiService($http, loginServiceUrl) {
    this.sendEmail = function (payload) {
        return $http.post(loginServiceUrl.apiUrl() + '/account/verify/send/', payload);
    };

    this.verifyByToken = function (token) {
        return $http.post(loginServiceUrl.apiUrl() + '/account/verify?vtoken=' + token, {});
    };
}
