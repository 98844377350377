import { localStorageItems } from '../../../constants';
import { GroupMix } from '../../../enums';
import {
  decodeFilters,
  encodeFilters,
  generateUserLocalStorageKey,
  getLocalStorageItem,
  getParsedLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from '../../../helpers';
import { getAccountSettings } from '../../../helpers/accountSettings';
import { groupsStore } from '../../../hooks';
import { IDefaultGroupDto, IGroupFilter } from '../../../models';
import { DateRanges } from '../constants/dateRanges';
import { DEFAULT_REPORT_FILTERS } from '../constants/defaultReportFilters';
import {
  Hour,
  IReportFilters,
  ILegacyReportFilters,
  IUsersFilter
} from '../models/IReportFilters';
import { isDateFilterValid } from './reportFilterValidation.utils';
import { createDatesObject } from './dateRange.utils';
import moment from 'moment';

const flattenLegacyReportFilters = (
  legacyFilters: ILegacyReportFilters
): IReportFilters => {
  const convertHourStringToHourNumber = (
    timeString: string,
    defaultValue: Hour
  ): Hour => {
    const timelineToHour = new Date(timeString).getHours();

    if (isNaN(timelineToHour) || timelineToHour < 0 || timelineToHour > 23) {
      return defaultValue;
    }
    return timelineToHour as Hour;
  };

  const legacyRange = legacyFilters.range ?? DEFAULT_REPORT_FILTERS.dates.range;
  const legacyFromDate =
    legacyRange === DateRanges.Custom
      ? new Date(legacyFilters.reportDates.from).toISOString().split('T')[0]
      : null;
  const legacyToDate =
    legacyRange === DateRanges.Custom
      ? new Date(legacyFilters.reportDates.to).toISOString().split('T')[0]
      : null;
  const legacyMomentFromDate = legacyFromDate ? moment(legacyFromDate) : null;
  const legacyMomentToDate = legacyToDate ? moment(legacyToDate) : null;

  const flattenedReportFilters: IReportFilters = {
    groupId: legacyFilters.groupId || DEFAULT_REPORT_FILTERS.groupId,
    users: legacyFilters.users || DEFAULT_REPORT_FILTERS.users,
    dates: createDatesObject(
      legacyRange,
      legacyMomentToDate,
      legacyMomentFromDate
    ),
    productivity:
      legacyFilters.productivity || DEFAULT_REPORT_FILTERS.productivity,
    timelineFromHour: convertHourStringToHourNumber(
      legacyFilters.from,
      DEFAULT_REPORT_FILTERS.timelineFromHour
    ),
    timelineToHour: convertHourStringToHourNumber(
      legacyFilters.to,
      DEFAULT_REPORT_FILTERS.timelineToHour
    ),
    timelineStrict:
      legacyFilters.strict || DEFAULT_REPORT_FILTERS.timelineStrict,
    timelineInterval:
      legacyFilters.interval || DEFAULT_REPORT_FILTERS.timelineInterval,
    timelineSort: legacyFilters.sort || DEFAULT_REPORT_FILTERS.timelineSort,
    fromTimestamp: DEFAULT_REPORT_FILTERS.fromTimestamp
  };

  return flattenedReportFilters;
};

const checkForLegacyReportFilterLocalStorage = ():
  | IReportFilters
  | undefined => {
  const localStorageKey = generateUserLocalStorageKey(
    localStorageItems.reportFiltersStorage
  );
  const legacyReportFilters =
    getParsedLocalStorageItem<ILegacyReportFilters>(localStorageKey);
  if (legacyReportFilters && Object.keys(legacyReportFilters).length > 0) {
    removeLocalStorageItem(localStorageKey);
    return flattenLegacyReportFilters(legacyReportFilters);
  }
};

export const getReportFiltersLocalStorage = (): IReportFilters => {
  try {
    const convertedLegacyReportFilters =
      checkForLegacyReportFilterLocalStorage();
    if (convertedLegacyReportFilters) {
      console.warn(
        'ActivTrak Warning: Legacy report filters found and converted.'
      );
      return convertedLegacyReportFilters;
    }
  } catch (error) {
    console.error(
      'ActivTrak Error: Error converting legacy report filters',
      error
    );
  }

  const localStorageKey = generateUserLocalStorageKey(
    localStorageItems.reportFiltersStorageV2
  );
  const reportFilters =
    getParsedLocalStorageItem<IReportFilters>(localStorageKey);

  const range =
    reportFilters?.dates?.range ?? DEFAULT_REPORT_FILTERS.dates.range;
  const fromDateMoment = reportFilters?.dates?._fromDate
    ? moment(reportFilters?.dates?._fromDate)
    : null;
  const toDateMoment = reportFilters?.dates?._toDate
    ? moment(reportFilters?.dates?._toDate)
    : null;
  const dates = createDatesObject(range, fromDateMoment, toDateMoment);

  return { ...reportFilters, dates };
};

export const setReportFiltersLocalStorage = (filters: IReportFilters) => {
  const localStorageKey = generateUserLocalStorageKey(
    localStorageItems.reportFiltersStorageV2
  );
  setLocalStorageItem(localStorageKey, JSON.stringify(filters));

  //UPDATE INSIGHTS FILTERS WITH NEW GROUP VALUE
  if (filters?.groupId?.length) {
    const groupId = filters?.groupId[0];
    const group = groupsStore.getState().getGroupFilterById(groupId);
    if (group?.name) setInsightsFiltersLocalStorage(group?.name);
  }
};

export const setInsightsFiltersLocalStorage = async (groupName: string) => {
  const accountSettings = getAccountSettings();
  const groupsDefinitions = groupsStore.getState();
  const insightsFilters = groupsDefinitions?.insightsFilters;
  const user = accountSettings.username;
  if (user && groupName && insightsFilters?.length) {
    const storedFilters = getInsightsFiltersLocalStorage(user);
    const matchedInsights = insightsFilters.find(
      (item) =>
        item.name?.replace(/^"(.*)"$/, '$1').toLowerCase() ===
        groupName?.replace(/^"(.*)"$/, '$1').toLowerCase()
    );
    if (matchedInsights) {
      //set insights localStorage
      const filters = {
        ...storedFilters,
        Team: groupName
      };
      setLocalStorageItem(
        localStorageItems.insightsFiltersStorage + user,
        `"${encodeFilters(filters)}"`
      );
    }
  }
};

//NEED SPECIALLY FORMATTED LOCALSTORAGE FOR LOOKER
const getInsightsFiltersLocalStorage = (userName: string) => {
  const accountSettings = getAccountSettings();
  const user = userName || accountSettings.username;

  if (user) {
    const localStorage_insightsFilters =
      getLocalStorageItem(localStorageItems.insightsFiltersStorage + user) ||
      '';

    if (localStorage_insightsFilters) {
      const trimmed = localStorage_insightsFilters.replace(
        /^"(.+(?="$))"$/,
        '$1'
      );

      return decodeFilters(trimmed);
    } else return {};
  } else {
    return {};
  }
};

const getDefaultUsers = (groups: IGroupFilter[]): IUsersFilter => {
  if (
    Array.isArray(groups) &&
    groups.filter(function (u) {
      return u.mix === GroupMix.User;
    }).length <= 0 &&
    groups.filter(function (u) {
      return u.mix === GroupMix.Computer;
    }).length > 0
  ) {
    return {
      userId: -1,
      userType: 'AllUsers',
      name: 'All Computers',
      groupType: 'AllComputers',
      filterMode: 'computers'
    };
  }
  return {
    userId: -1,
    userType: 'AllUsers',
    name: 'All Users',
    filterMode: 'users',
    groupType: 'User'
  };
};

// Create the default state for the user and group filters based on the account settings
// Default rules:
// 1. If a default group is provided via post_login, use that group
// 2. If no default group is provided, use the first group in the list of groups
// 3. If no groups are available, use the legacy user list to determine if the default filter should be "All Users" or "All Computers"
//    a. If there are no legacy users but there are legacy computers, default to "All Computers"
//    b. If there are no legacy users and no legacy computers, default to "All Users"
export const createDefaultGroupFilters = (
  defaultGroup: IDefaultGroupDto,
  groupFilters: IGroupFilter[]
): Partial<IReportFilters> => {
  const groupId = groupFilters?.length && groupFilters[0].id;
  if (defaultGroup) {
    // If a default group is provided via post_login, use that group
    const filterMode = (
      ['User', 'Computer'].includes(defaultGroup.groupType)
        ? defaultGroup.groupType.toLowerCase() + 's'
        : 'users'
    ) as 'computers' | 'users';

    return {
      groupId: [defaultGroup.userId],
      users: [
        {
          userId: defaultGroup.userId,
          userType: defaultGroup.userType,
          name: defaultGroup.userName,
          groupType: defaultGroup.groupType,
          filterMode: filterMode
        }
      ]
    };
  } else if (groupId) {
    // If no default group is provided, use the first group in the list of groups
    const group = groupsStore.getState().getGroupFilterById(groupId);
    const groupName: string = group?.name;

    return {
      groupId: [groupId],
      users: [
        {
          userId: groupId,
          userType: 'Group',
          name: groupName,
          filterMode: 'users'
        }
      ]
    };
  } else {
    // If no groups are available, use the legacy user list to determine if the default filter should be "All Users" or "All Computers"
    const users = getDefaultUsers(groupFilters);
    return {
      groupId: [users.userId.toString()],
      users: [users]
    };
  }
};

// Starting with the defined DateRanges enum, find the first date range that is included in the roleDateFilters groups.
// This will be the default date range for the user based on the order the enum is defined and returns the first match
// that the user has access to based on the RBAC settings for the date range filter.
export const createDefaultDateRangeFilters = (): Partial<IReportFilters> => {
  const defaultDateRange = Object.values(DateRanges).find((dateRangeValue) => {
    return isDateFilterValid({
      dates: createDatesObject(dateRangeValue)
    });
  });

  return {
    dates: createDatesObject(defaultDateRange)
  };
};
