import { useCallback, useState } from 'react';
import {
  IOfflineMeetingSettings,
  IOfflineMeetingSettingsState,
} from '../models';
import { fetchData, postData } from "../../common/helpers";
import { useNotifications } from "../../common/services/Notifications";

export const useOfflineMeetingSettingsState = (): IOfflineMeetingSettingsState => {
  const notificationService = useNotifications();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [offlineMeetingSettings, setOfflineMeetingSettings ] =
    useState<IOfflineMeetingSettings>();

  const fetchOfflineMeetingSettings = useCallback(async () => {
    try {
      const settings = await fetchData<IOfflineMeetingSettings>({
        path: '/api/insights/accountsettings'
      });
      if (settings) {
        setOfflineMeetingSettings(settings);
      }
    } catch (error) {
      //Suppress - does not have integrated calendar
    }
  }, []);

  const init = useCallback(() => {
    fetchOfflineMeetingSettings();
  }, [fetchOfflineMeetingSettings]);

  const setOfflineMeetingAttendees = useCallback(async (payload: IOfflineMeetingSettings) => {
    setLoading(true);
    try {
      await postData<boolean>({
        path: '/api/insights/accountsettings/meetingminattendees',
        args: new Number(payload.meetingMinAttendees)
      })
      notificationService.success('Success! Offline meeting minimum number of attendees updated!');
      setOfflineMeetingSettings(payload);
    } catch (error) {
      notificationService.error('Unable to update offline meeting minimum number of attendees');
      console.error(
        'ActivTrak Error: Unable to update offline meeting minimum number of attendees',
        error
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOfflineMeetingDuration = useCallback(async (payload: IOfflineMeetingSettings) => {
    setLoading(true);
    try {
      await postData<boolean>({
        path: '/api/insights/accountsettings/meetingmaxlength',
        args: new Number(payload.meetingMaxLength)
      })
      notificationService.success('Success! Offline meeting maximum meeting time updated!');
      setOfflineMeetingSettings(payload);
    } catch (error) {
      notificationService.error('Unable to update offline meeting maximum meeting time');
      console.error(
        'ActivTrak Error: Unable to update offline meeting maximum meeting time',
        error
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    offlineMeetingSettings,
    fetchOfflineMeetingSettings,
    init,
    isLoading,
    setOfflineMeetingAttendees,
    setOfflineMeetingDuration
  };
};
