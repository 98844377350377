import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  InfoTooltip,
  LastLogRecordTableCell,
  LastLogSpan,
  LearnMoreLink
} from './TimeAgo.styles';
import { formatDate } from '../../helpers';
import InfoIcon from '@mui/icons-material/Info';
import TimeAgoProps from './TimeAgo.props';

export const TimeAgo: React.FC<TimeAgoProps> = ({ ...props }) => {
  const { timeAgo, lastLog, localDateTimeFormat, learnMoreLink } = props;

  return (
    <>
      {timeAgo == 'Future' && (
        <Tooltip
          title={
            <React.Fragment>
              <InfoTooltip>
                System time is in the future -{' '}
                {formatDate(lastLog, localDateTimeFormat)}
                <LearnMoreLink
                  target="_blank"
                  rel="noreferrer"
                  href={learnMoreLink}
                >
                  Learn more
                </LearnMoreLink>
              </InfoTooltip>
            </React.Fragment>
          }
          placement="bottom"
        >
          <LastLogRecordTableCell>
            {timeAgo}
            <InfoIcon fontSize="inherit"></InfoIcon>
          </LastLogRecordTableCell>
        </Tooltip>
      )}
      {timeAgo !== 'Future' && (
        <Tooltip
          title={
            <InfoTooltip>
              {formatDate(lastLog, localDateTimeFormat)}
            </InfoTooltip>
          }
          placement="bottom"
        >
          <LastLogSpan>{timeAgo}</LastLogSpan>
        </Tooltip>
      )}
    </>
  );
};
