'use strict';

var _tpsFunctions = require('_app/serviceFunctions/teamPulseServiceFunctions.js');

angular.module('app').service('teamPulseService', TeamPulseService);

TeamPulseService.$inject = [
    'envConfig',
    'authorizationService',
    'localStorageService'
];

function TeamPulseService(
    envConfig,
    authorizationService,
    localStorageService
) {
    var httpService = require('_app/http').http;

    var teamPulseServiceFunctions = _tpsFunctions.constructor(
        httpService,
        envConfig,
        authorizationService,
        localStorageService
    );

    var service = this;

    angular.extend(service, teamPulseServiceFunctions);
}
