import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const LogoutIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps
) => {
  const width = props.width ?? '24px';
  const height = props.height ?? '24px';
  const title = props.title ?? 'Logout';
  const className = props.className;
  const viewBox = props?.viewBox ?? '0 0 30 30';
  const fillColor = props?.fillColor ?? 'currentColor'; //'currentColor' = inherited color

  let classList = 'at-icon-activtrak';

  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={height}
      width={width}
      title={title}
      className={classList}
      viewBox={viewBox}
      fillColor={fillColor}
    >
      <path
        d="M23.5453 10.7957L18.4674 5.10034C17.8611 4.42029 16.9011 4.42029 16.2948 5.10034C15.6884 5.78038 15.6884 6.85713 16.2948 7.53717L18.7453 10.2857H7.93265C7.07371 10.2857 6.3916 11.0508 6.3916 12.0142C6.3916 12.9776 7.07371 13.7426 7.93265 13.7426H18.7453L16.2948 16.4911C15.6884 17.1712 15.6884 18.2479 16.2948 18.928C16.5979 19.268 16.9769 19.438 17.3811 19.438C17.7853 19.438 18.1642 19.268 18.4674 18.928L23.5453 13.2326C24.1516 12.5525 24.1516 11.4758 23.5453 10.7957Z"
      />
      <path
        d="M7.22526 20.5714H3.08211V3.45691H7.25053C8.10947 3.45691 8.79158 2.69185 8.79158 1.72845C8.79158 0.765053 8.10947 0 7.25053 0H1.54105C0.682105 0 0 0.765053 0 1.72845V22.2715C0 23.2349 0.682105 24 1.54105 24H7.25053C8.10947 24 8.79158 23.2349 8.79158 22.2715C8.76632 21.3365 8.08421 20.5714 7.22526 20.5714Z"
      />
    </SvgIconWrapper>
  );
};
