import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { getTheme } from '../../../styles/theme';
import ReportFilters from '../components/ReportFilters';
import { useReportFilterStore } from '../hooks/reportFiltersStore';

//this makes filters usable thru react2angular
const ReportFiltersView = () => {
  const theme = getTheme();
  const reportFilters = useReportFilterStore((s) => s);

  return (
    <ThemeProvider theme={theme}>
      <ReportFilters reportFilters={reportFilters} />
    </ThemeProvider>
  );
};
export default ReportFiltersView;
