import React, { useCallback } from 'react';
import {
  AccountCircle,
  People,
  ManageAccounts,
  LibraryBooks,
  Info,
  Support,
  Campaign,
  Badge,
  Circle,
  SupportAgent,
  ArrowCircleUp,
  Domain,
  Computer
} from '@mui/icons-material';
import {
  IconStyles,
  TypographySubHeaderSC,
  ListItemSC,
  StatusIconStylesSC
} from '../styles/Header.styles';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Routes } from '../../../../constants/routesUrls';
import { IIcon } from '../models/IIcon';
import { addBaseRoute } from '../../../../helpers';

export const HeaderMenuIcons = (props) => {
  const {
    handleCloseUserMenu,
    openSupportModal,
    isSupportOrSuperAdmin,
    showCommandCenterButton,
    showUpgradeButton,
    showSupportPortalButton,
    hideOnLargeScreenStyle,
    hideAccountConfiguration,
    userLicense,
    accountSettings,
    status
  } = props;

  const supportOrHelp = useCallback(() => {
    return isSupportOrSuperAdmin
      ? support.map(generateLink)
      : help.map(generateLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icons: IIcon[] = [
    {
      id: '7bc98ebe-da0d-457d-b829-431161d54d21',
      icon: <AccountCircle fontSize="small" />,
      value: `Account: ${accountSettings.account}`,
      href: addBaseRoute(Routes.AccessUsers)
    },
    {
      id: 'dbf00f49-5092-46aa-86f9-6fe212f0333d',
      icon: <Badge fontSize="small" />,
      value: 'ActivTrak Profile',
      href: addBaseRoute(Routes.ActivTrakProfile)
    },
    {
      id: 'b512cd8b-efc1-491c-bdb1-a997fb96a416',
      icon: <People fontSize="small" />,
      value: userLicense,
      href: addBaseRoute(Routes.UserAgents)
    },
    {
      id: '044f61d5-3e94-46ee-8072-f9f2715d94c7',
      icon: <ManageAccounts fontSize="small" />,
      value: 'Account Configuration',
      href: addBaseRoute(Routes.InstallAgentFile)
    }
  ];

  if (isSupportOrSuperAdmin) {
    icons.push({
      id: '4cec421d-e339-40cf-bbf7-b45c048cbcb1',
      icon: <SupportAgent fontSize="small" />,
      value: 'Support Modal',
      onClick: openSupportModal
    });
  }

  if (showUpgradeButton) {
    {
      icons.splice(1, 0, {
        id: 'daaa04ab-13b3-46ba-807c-8eb3e5831296',
        icon: <ArrowCircleUp />,
        value: 'Upgrade',
        href: addBaseRoute(Routes.Upgrade),
        hideOnLargeScreen: true
      });
    }
  }

  if (showCommandCenterButton) {
    icons.push({
      id: 'da9bec36-c321-401f-af49-0e1aafe5c3df',
      icon: <Domain fontSize="small" />,
      value: 'Command Center',
      href: Routes.CommandCenterDashboard,
      hideOnLargeScreen: true
    });
  }

  if (showSupportPortalButton) {
    icons.push({
      id: 'b0d9da5f-4b70-460d-a101-e9a4b8db1484',
      icon: <SupportAgent fontSize="small" />,
      value: 'Support Portal',
      href: Routes.SupportPortal,
      hideOnLargeScreen: true
    });
  }

  const support = [
    {
      id: 'af74dbc2-7bf6-47c4-a6b6-e1579b19d7eb',
      icon: <People fontSize="small" />,
      value: `User Agents`,
      href: addBaseRoute(Routes.UserAgents)
    },
    {
      id: 'ea8d6f06-3ecf-4e33-a39a-5219b4e386d2',
      icon: <Computer fontSize="small" />,
      value: `Computer Agents`,
      href: addBaseRoute(Routes.ComputerAgents)
    },
    {
      id: '74896c6e-ab40-4b74-91c8-3cc44eada87c',
      icon: <AccountCircle fontSize="small" />,
      value: `App Access`,
      href: addBaseRoute(Routes.AccessUsers)
    }
  ];

  if (hideAccountConfiguration) {
    const index = icons.findIndex(
      (icon) => icon.value === 'Account Configuration'
    );

    if (index !== -1) {
      icons.splice(index, 1);
    }
  }

  const help: IIcon[] = [
    {
      id: 'f8f5d5ed-6d08-4885-ba2a-32cdd5907667',
      icon: <LibraryBooks fontSize="small" />,
      value: 'Resources',
      hrefTargetBlank: Routes.Resources
    },
    {
      id: '9c745d00-5a8b-4fb1-a456-9aaea4fd9827',
      icon: <Info fontSize="small" />,
      value: 'Help Center',
      hrefTargetBlank: Routes.HelpCenter
    },
    {
      id: '235820aa-029e-44d8-b38b-de897eaa6cf0',
      icon: <Support fontSize="small" />,
      value: 'Support Tickets',
      href: addBaseRoute(Routes.SupportTickets)
    },
    {
      id: 'f0ffa43a-6870-402b-a8a1-6e057c9555ec',
      icon: <Campaign fontSize="small" />,
      value: 'Latest Updates',
      href: addBaseRoute(Routes.LatestUpdates)
    },
    {
      id: '9fc78b74-773c-4c44-a4d2-c5f7613c90f1',
      icon: <Circle fontSize="small" />,
      value: 'ActivTrak Status',
      hrefTargetBlank: Routes.ActivTrakStatus,
      showStatusIcon: true
    }
  ];

  const generateLink = (item: IIcon) => {
    const {
      id,
      value,
      href,
      hrefTargetBlank,
      onClick,
      icon,
      hideOnLargeScreen,
      showStatusIcon
    } = item;
    const target: string = hrefTargetBlank ? '_blank' : undefined;
    const sx: SxProps = hideOnLargeScreen ? hideOnLargeScreenStyle : undefined;

    const handleClick = (onClickFunction?: () => void) => {
      if (onClickFunction) {
        onClickFunction();
      }

      handleCloseUserMenu();
    };

    return (
      <Grid key={`{menu-item-${id}`} sx={sx}>
        <ListItemSC
          id={id}
          key={`{menu-label-${id}`}
          onClick={() => {
            handleClick(onClick);
          }}
          target={target}
          href={href || hrefTargetBlank}
          rel="noreferrer"
          component="a"
        >
          {showStatusIcon ? (
            <StatusIconStylesSC status={status}>{icon}</StatusIconStylesSC>
          ) : (
            <IconStyles>{icon}</IconStyles>
          )}
          {value}
        </ListItemSC>
      </Grid>
    );
  };

  return (
    <>
      <TypographySubHeaderSC variant="subtitle2">
        Account Information
      </TypographySubHeaderSC>
      {icons.map(generateLink)}
      <TypographySubHeaderSC variant="subtitle2">
        {isSupportOrSuperAdmin ? 'Support' : 'Help'}
      </TypographySubHeaderSC>
      {supportOrHelp()}
    </>
  );
};
