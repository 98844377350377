import React, { useState } from 'react';
import { MemberType } from '../enums';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { gray2, gray8 } from '../../common/constants';

type Prop = {
  defaultType?: MemberType;
  exclusive?: boolean;
  onChange?: (selected: MemberType) => void;
};

const classes = {
  root: {
    '& button': {
      border: 'none !important'
    },
    '& button:not(.Mui-selected)': {
      backgroundColor: `${gray2} !important;`
    },
    '& .Mui-selected': {
      color: 'white !important',
      backgroundColor: `${gray8} !important`
    }
  }
};

export default function MemberTypeToggle(props: Prop) {
  const { defaultType, onChange } = props;

  const [selected, setSelected] = useState<MemberType>(
    defaultType ?? MemberType.User
  );

  const handleChange = (type?: MemberType) => {
    setSelected(type);
    if (onChange !== null) onChange(type);
  };

  return (
    <ToggleButtonGroup
      exclusive={props.exclusive ?? false}
      sx={classes.root}
      value={selected}
      size="small"
      onChange={(e, type) => handleChange(type)}
    >
      <ToggleButton value={MemberType.User}>Users</ToggleButton>
      <ToggleButton value={MemberType.Computer}>Computers</ToggleButton>
    </ToggleButtonGroup>
  );
}
