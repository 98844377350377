import viewTemplate from 'views/widgets/realtimeThumbnailTab.html?raw';
import { getReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';

angular.module('app').directive('realtimeThumbnailTab', RealtimeThumbnailTab);

function RealtimeThumbnailTab() {
    return {
        restrict: 'E',
        template: viewTemplate,
        controller: RealtimeThumbnailTabCtrl
    };
}

RealtimeThumbnailTabCtrl.$inject = ['$scope', 'realtimeService'];

function RealtimeThumbnailTabCtrl($scope, realtimeService) {
    realtimeService.subscribe('screenView', createRealtimeTileList);

    function createRealtimeTileList(data) {
        $scope.realtimeTileList = {};
        const { users } = getReportFilters();
        const user = users.length ? users[0] : {};
        const userIdField = user.filterMode === 'computers' ? 'computerId' : 'userId';
        data.forEach(function (row) {
            const id = row[userIdField];
            $scope.realtimeTileList[id] = row;
        });
    }
    $scope.$on('$destroy', function () {
        realtimeService.unsubscribe('screenView');
    });
}
