import { generateGuid } from '../utilities/helpers';
class User {
    constructor(u = {}) {
        // Ensure duration is a number >= 0
        const _generateDuration = (value) => {
            let duration;

            try {
                duration = parseInt(value, 10);
            } catch (e) {
                duration = 0;
            }

            return isNaN(duration) || duration < 0 ? 0 : duration;
        };

        this.name = u.username || u.computerName;
        this.username = u.username;
        this.computerName = u.computerName;
        this.id = u.userId || u.computerId || generateGuid();
        this.totalDuration = _generateDuration(u.totalTime);
        this.activeDuration = _generateDuration(u.activeTime);
        this.totalRatio = u.productiveTotalRatio;
        this.activeRatio = u.productiveActiveRatio;
    }

    getDuration(useTotalTime) {
        return useTotalTime ? this.totalDuration : this.activeDuration;
    }

    getRatio(useTotalTime) {
        return useTotalTime ? this.totalRatio : this.activeRatio;
    }
}

export default User;
