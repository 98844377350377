import { Role, RoleAccessKeys } from '../../../../enums';
import { IRoute } from '../../../../models/IRoute';

export const appPrintDashboard: IRoute = {
  name: 'app.print-dashboard',
  stateDefinition: {
    url: '/print',
    template: '<print-dashboard></print-dashboard>',
    data: { pageTitle: 'Print Dashboard', filter: {} }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.DashboardsActivity,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User]
  }
};

export default appPrintDashboard;
