import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';
import { UserCard, GroupCard } from '../../components';
import { useBreakpoint } from '../../../common/hooks';
import { FixedSizeList } from 'react-window';
import { isEmpty } from '../../utilities/helpers';
import moment from 'moment';
import { userViewClasses } from './UserView.style';
import { useSubscription } from 'observable-hooks';
import NoDataView from '../../../common/components/NoDataView/NoDataView';

const UserView = (props) => {
    const { teamPulseDataControl, teamPulseDataHandler, teamPulseDataPoller } = props;

    const [isReport, setIsReport] = useState();
    useSubscription(teamPulseDataControl.isReport, setIsReport);
    const [timeFormat, setTimeFormat] = useState();
    useSubscription(teamPulseDataControl.timeFormat, setTimeFormat);
    const [containerHeight, setContainerHeight] = useState(200);
    useSubscription(teamPulseDataControl.containerHeight, setContainerHeight);
    const [timeMode, setTimeMode] = useState();
    useSubscription(teamPulseDataControl.timeMode, setTimeMode);
    const [filterMode, setFilterMode] = useState();
    useSubscription(teamPulseDataControl.filterMode, setFilterMode);
    const [sort, setSort] = useState();
    useSubscription(teamPulseDataControl.sort, setSort);
    const [filter, setFilter] = useState();
    useSubscription(teamPulseDataControl.filter, setFilter);
    const [refreshData, setRefreshData] = useState();
    useSubscription(teamPulseDataControl.refreshData, setRefreshData);
    const [selectedCardIds, setSelectedCardIs] = useState();
    useSubscription(teamPulseDataControl.selectedCardIds, setSelectedCardIs);

    const [cardData, setCardData] = useState({ time: moment.utc(), data: [] });
    const [showLoading, setShowLoading] = useState(true);

    const breakPoint = useBreakpoint();
    const itemSize = () => (isReport ? 317 : 104); // Height of card plus 15px bottom padding
    const listRef = useRef();
    const scrollToSelected = () => {
        // Wait for listRef to populate
        setTimeout(() => {
            if (listRef.current && typeof listRef.current.scrollToItem === 'function') {
                // Find index of selected card
                let selectedCardIndex = _.findIndex(cardData.data, (card) =>
                    card.id.split(',').some((id) => selectedCardIds.split(',').indexOf(id) >= 0)
                );

                // Find row card is on
                let rowIndex = Math.floor(selectedCardIndex / getColumnCount());

                // If rowIndex >= 0 then scroll to row and clear selectedItem
                // Else, wait for valid match before clearing selectedItem
                if (rowIndex >= 0 && rowIndex <= getRowCount()) {
                    listRef.current.scrollTo(itemSize() * rowIndex);
                    teamPulseDataControl.selectedCardIds = null;
                }
            }
        });
    };

    // Update data handler with incoming data.
    const handleDataUpdate = useCallback(
        (type, updateData) => {
            const teamPulseData = type === 'teamPulse' ? updateData : null;
            const realtimeData = type === 'realtime' ? updateData : null;
            const updateTime = type !== 'realtime' ? moment.utc() : null;
            let { time, data, refreshRequired } = teamPulseDataHandler.processData(
                teamPulseData,
                realtimeData,
                teamPulseDataControl.reportModes.users,
                timeMode,
                updateTime
            );

            // only refresh full list if needed
            if (refreshRequired) {
                setCardData({ time, data });
                if (showLoading) {
                    setShowLoading(false);
                }
            }
        },
        [showLoading, teamPulseDataControl.reportModes.users, teamPulseDataHandler, timeMode]
    );

    // Initial setup
    useEffect(() => {
        const handleTeamPulseUpdate = (teamPulseData) => {
            handleDataUpdate('teamPulse', teamPulseData);
        };

        const handleRealtimeUpdate = (realtimeData) => {
            handleDataUpdate('realtime', realtimeData);
        };
        teamPulseDataPoller.teamPulseSubscribe({
            id: 'teamPulseList',
            callback: handleTeamPulseUpdate
        });
        teamPulseDataPoller.realtimeSubscribe({
            id: 'teamPulseList',
            callback: handleRealtimeUpdate
        });
    }, [handleDataUpdate, teamPulseDataPoller]);

    useEffect(() => {
        teamPulseDataPoller.setFilterMode(filterMode);
    }, [filterMode, teamPulseDataPoller]);

    useEffect(() => {
        teamPulseDataHandler.setFilter(filter);
        handleDataUpdate();
    }, [filter, handleDataUpdate, teamPulseDataHandler]);

    useEffect(() => {
        teamPulseDataHandler.setSort(sort);
        handleDataUpdate();
    }, [sort, handleDataUpdate, teamPulseDataHandler]);

    useEffect(() => {
        if (refreshData) {
            setShowLoading(true);
            teamPulseDataPoller.pollTeamPulse();
            setRefreshData(false);
            teamPulseDataPoller.startPolling();
        }
    }, [refreshData, teamPulseDataPoller]);

    useEffect(() => {
        return () => teamPulseDataPoller.stopPolling();
    }, []);

    // TODO: move all card generation to its own file
    const generateUserCard = (userIndex) => {
        let card = cardData.data[userIndex];
        if (!card) {
            return;
        }

        return (
            <Grid key={card.id} item xs={12} sm={6} md={4} lg={2}>
                <UserCard
                    teamPulseDataHandler={teamPulseDataHandler}
                    teamPulseDataPoller={teamPulseDataPoller}
                    teamPulseDataControl={teamPulseDataControl}
                    pollingPaused={teamPulseDataControl.pollingPaused}
                    cardId={card.id}
                    isReport={isReport}
                    isViewer={teamPulseDataControl.isViewer}
                    timeFormat={timeFormat}
                    timeMode={timeMode}
                    filterMode={filterMode}
                    filterModes={teamPulseDataControl.filterModes}
                    loading={showLoading}
                    cardSize={itemSize() - 10}
                />
            </Grid>
        );
    };

    const generateRow = (rowIndex) => {
        let row = [];
        const start = rowIndex * getColumnCount();
        const end = start + getColumnCount() - 1;
        for (let userIndex = start; userIndex <= end; userIndex = userIndex + 1) {
            let card = generateUserCard(userIndex);
            if (card) {
                row.push(card);
            }
        }

        return (
            <Grid container spacing={1} direction="row">
                {row}
            </Grid>
        );
    };

    const getColumnCount = () => {
        switch (breakPoint) {
            case 'xs':
                return 1;
            case 'sm':
                return 2;
            case 'md':
                return 3;
            default:
                return 6;
        }
    };

    const getRowCount = () => {
        return Math.ceil(cardData.data.length / getColumnCount());
    };

    const Row = ({ index, style }) => (
        <div style={style}>
            <div style={userViewClasses.row}>{generateRow(index)}</div>
        </div>
    );

    if (showLoading && (!cardData || isEmpty(cardData.data))) {
        // Create fake data to fill rows with empty cards while loading
        let data = [];
        for (let i = 0; i < getColumnCount() * 3; i = i + 1) {
            data.push({
                id: `${i}`
            });
        }
        setCardData({ time: moment.utc(), data, fakeData: true });
    } else if (!showLoading && cardData && cardData.fakeData) {
        // Clear fake card data if not loading.
        setCardData({ time: moment.utc(), data: [] });
    }

    const hasData = Boolean(cardData && !cardData.fakeData && cardData.data && cardData.data.length);

    // Use list items rendering as trigger for scrolling to selected
    const handleItemsRendered = () => {
        if (selectedCardIds) {
            scrollToSelected();
        }
    };

    const hasResults = teamPulseDataHandler.hasResults();

    return showLoading || hasData ? (
        <FixedSizeList
            height={containerHeight - 10} // Container height minus root padding
            ref={listRef}
            itemCount={getRowCount()}
            itemSize={itemSize()}
            onItemsRendered={handleItemsRendered}
            width={'100%'}
            sx={userViewClasses.list}
        >
            {Row}
        </FixedSizeList>
    ) : (
        <NoDataView hasResults={hasResults} />
    );
};

export default UserView;
