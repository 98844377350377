'use strict';

angular
    .module('app')
    .controller(
        'addBlockedDomainModalController',
        AddBlockedDomainModalController
    );

AddBlockedDomainModalController.$inject = [
    '$scope',
    '$rootScope',
    '$window',
    '$uibModalInstance',
    'modalData',
    '$timeout',
    'messagesService',
    'categoriesService',
    'atHelperFunctions',
    'templateServiceFunctions'
];

var categoryServiceFunctions = require('_app/classification/categoryServiceFunctions').categoryServiceFunctions;

function AddBlockedDomainModalController(
    $scope,
    $rootScope,
    $window,
    $uibModalInstance,
    modalData,
    $timeout,
    msg,
    categoriesService,
    atHelperFunctions,
    templateServiceFunctions
) {
    
    var includedGroups = [];
    var removedGroups = [];
    var parsedDomain;
    var totalDomains = 0;
    var defaultPageSize = 150;
    var pageSize = 150;
    $scope.resultsFiltered = false;
    var lastCategoryFilter;
    $scope.page = 1;
    $scope.errorMessage = null;
    $scope.selectedDomains = [];
    $scope.selectAllDomains = false;
    var erroredUrl;

    var addToSelectedDomains = function(row) {
        if (!$scope.selectedDomains[row.domain]) {
            $scope.selectedDomains[row.domain] = row;
            $scope.selectedDomains.length++;
        }
    };

    var deleteFromSelectedDomains = function(domainName) {
        if ($scope.selectedDomains[domainName]) {
            delete $scope.selectedDomains[domainName];
            $scope.selectedDomains.length--;
        }
    };

    var selectAllLimit = pageSize;

    $scope.isCheckboxBlocked = function() {
        return (
            !$scope.resultsFiltered ||
            domainDataSource.data().length >= selectAllLimit
        );
    };

    $scope.selectAllTooltipContent = function() {
        return $scope.isCheckboxBlocked()
            ? '"Select All" is disabled. The filter isn\'t applied or the result contains more than ' +
                  selectAllLimit +
                  ' entries.'
            : 'Select All';
    };

    var domainGridHeight = 260;
    $scope.domainGridHeight = function() {
        return domainGridHeight;
    };

    $scope.categoryUpdate = function(e) {
        var categoryName = e.sender.text();
        if (categoryName !== lastCategoryFilter) {
            lastCategoryFilter = categoryName;
            $scope.updateFilter(
                {
                    categoryName: categoryName
                },
                'category'
            );
        }
    };

    var minimizeString = function(maxSize, value) {
        if (typeof value === 'string') {
            if (value.length > maxSize) {
                value = value.substring(0, maxSize - 1) + '…';
            }
        }
        return value;
    };

    var groupColumnTemplate = function(item) {
        return (
            '<div class="checkbox"><input type="checkbox" id="selectbox-group-column-{{dataItem.uid}}" ng-model="dataItem.blockedModel"></input>' +
            '<label for="selectbox-group-column-{{dataItem.uid}}">' +
            templateServiceFunctions.groupTypeIconAndNameTemplateAsString(
                item
            ) +
            '</label></div>'
        );
    };

    var domainColumnTemplate = function(item) {
        item.removeTT = {
            position: 'left',
            content: 'Delete Domain',
            animation: {
                open: {
                    effects: 'fade:in'
                },
                close: {
                    effects: 'fade:out'
                }
            }
        };
        return (
            '<div class="checkbox"><input type="checkbox" id="selectbox-domain-column-{{dataItem.uid}}" ng-model="dataItem.checkedModel"></input>' +
            '<label for="selectbox-domain-column-{{dataItem.uid}}">' +
            templateServiceFunctions.getFaviconTemplateAsString(
                minimizeString(46, item.domain),
                true
            ) +
            '</label>' +
            '<i kendo-tooltip k-options="dataItem.removeTT" class="at-pointer at-m-t-3 fa fa-trash-o fa-lg pull-right-position" ng-click="removeDomain(dataItem)"></i></div>'
        );
    };

    var updateIncluded = function(group, isBlocked) {
        if (isBlocked) {
            if (group.name === 'All Computers') {
                includedGroups.forEach(function(incGroup) {
                    if (incGroup.name !== 'All Computers') {
                        var groupFound = false;
                        removedGroups.forEach(function(removedGroup) {
                            if (removedGroup.name === incGroup.name) {
                                groupFound = true;
                            }
                        });
                        if (!groupFound) {
                            incGroup.isUpdated = !!incGroup.beganChecked;
                            removedGroups.push(incGroup);
                        }
                    }
                });
                includedGroups = [];
                group.isUpdated = !group.beganChecked;
                includedGroups.push(group);
            } else {
                var dupCheck = false;
                var allCompIdx;
                angular.forEach(includedGroups, function(incGroup, idx) {
                    if (incGroup.name === 'All Computers') {
                        allCompIdx = idx;
                    } else if (group.name === incGroup.name) {
                        dupCheck = true;
                    }
                });

                if (allCompIdx >= 0) {
                    var allComp = includedGroups.splice(allCompIdx, 1)[0];
                    allComp.isUpdated = !!allComp.beganChecked;
                    removedGroups.push(allComp);
                }

                if (!dupCheck) {
                    group.isUpdated = !group.beganChecked;
                    includedGroups.push(group);
                }

                removedGroups.forEach(function(removedGroup, idx) {
                    if (removedGroup.name === group.name) {
                        removedGroups.splice(idx, 1);
                    }
                });
            }
        } else {
            angular.forEach(includedGroups, function(incGroup, idx) {
                if (group.name === incGroup.name) {
                    includedGroups.splice(idx, 1);
                    group.isUpdated = !!group.beganChecked;
                    removedGroups.push(group);
                }
            });
        }
        $scope.enableApply = includedGroups.length > 0;
        updateDataSource();
    };

    var categoriesDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function(options) {
                categoriesService.getCategories().success(function(result) {
                    options.success(
                        categoryServiceFunctions.sortCategories(
                            _.filter(result, function(c) {
                                return !categoryServiceFunctions.isProtectedCategory(
                                    c.id
                                );
                            })
                        )
                    );
                });
            }
        }
    });

    $scope.categoryListOptions = {
        placeholder: 'Filter by Category…',
        dataTextField: 'name',
        dataValueField: 'id',
        dataSource: categoriesDataSource,
        valuePrimitive: false
    };

    var updateDataSource = function() {
        groupDataSource.data().forEach(function(item) {
            item.blocked = false;
            angular.forEach(includedGroups, function(group) {
                if (group.name === item.name) {
                    item.blocked = true;
                }
            });
            item.blockedModel = item.blocked;
        });
    };

    var groupDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function(options) {
                var groups = modalData.groupDataSource.data();
                var groupAdded = false;
                groups.forEach(function(group) {
                    var isBlocked = false;
                    if (!$scope.selectAllDomains) {
                        var firstSelected;
                        for (var domain in $scope.selectedDomains) {
                            firstSelected = domain;
                            break;
                        }
                        modalData.blockedDomainDataSource
                            .data()
                            .forEach(function(domain) {
                                if (domain.domain === firstSelected) {
                                    domain.groups.forEach(function(grp) {
                                        if (grp.name === group.name) {
                                            isBlocked = true;
                                        }
                                    });
                                }
                            });
                        if (
                            !!modalData.domainChanges &&
                            !!modalData.domainChanges[firstSelected]
                        ) {
                            var groupFound = false;
                            modalData.domainChanges[
                                firstSelected
                            ].groups.forEach(function(grp) {
                                if (grp.name === group.name) {
                                    groupFound = true;
                                    isBlocked = true;
                                }
                            });
                            if (!groupFound) {
                                isBlocked = false;
                            }
                        }
                    }
                    group.blocked = isBlocked;
                    groupAdded = groupAdded || isBlocked;
                    if (group.blocked) {
                        group.isUpdated = false;
                        group.beganChecked = true;
                        includedGroups.push(group);
                    }
                    group.blockedModel = group.blocked;
                });
                if (!groupAdded) {
                    groups.forEach(function(group) {
                        if (group.name === 'All Computers') {
                            group.blocked = true;
                            group.blockedModel = true;
                            group.isUpdated = true;
                            group.beganChecked = false;
                            includedGroups.push(group);
                        }
                    });
                }
                $timeout(function() {
                    options.success(groups);
                });
            }
        },
        pageSize: 300
    });

    var domainDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function(options) {
                modalData
                    .domainDataSource({
                        params: options.data
                    })
                    .success(function(result) {
                        var ret = [];
                        ret.data = [];
                        ret.total = result.total;
                        if (ret.total > 10000 && !$scope.manuallyPaged) {
                            options.success({ data: [], total: 0 });
                            defaultPageSize = 50;
                            pageSize = 50;
                            $scope.manuallyPaged = true;
                            $scope.domainGrid.setOptions(
                                createDomainGridOptions()
                            );
                            domainDataSource.pageSize(pageSize);
                        }
                        totalDomains =
                            totalDomains < result.total
                                ? result.total
                                : totalDomains;
                        var isNotActivTrakDomain = function( domainName ){ return !domainName.toLowerCase().includes('activtrak'); };
                        result.data.filter(isNotActivTrakDomain).forEach(function(domain) {
                            var dom = {
                                domain: domain
                            };
                            dom.checked = !!$scope.selectedDomains[domain];
                            dom.checkedModel = dom.checked;
                            ret.data.push(dom);
                        });
                        $scope.resultsFiltered = !!options.data.filter;
                        $scope.allResultsReturned =
                            result.total <= ret.data.length;
                        options.success(ret);
                    });
            }
        },
        pageSize: defaultPageSize,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    $scope.includedGroupsRowClicked = function(e, row) {
        row.blocked = !row.blocked;
        updateIncluded(row, row.blocked);

        $timeout(function() {
            row.blockedModel = row.blocked;
            e.sender.element
                .find('.k-state-selected')
                .toggleClass('k-state-selected');
        }, 100);
    };

    $scope.toggleSelectAllDomains = function() {
        if ($scope.resultsFiltered && !$scope.allResultsReturned) {
            pageSize = totalDomains;
            domainDataSource.pageSize(pageSize);
            domainDataSource.read().done(function() {
                $scope.selectAllDomains = true;
                $scope.toggleSelectAllDomains();
            });
        } else {
            pageSize = Math.min(totalDomains, defaultPageSize);
            domainDataSource.pageSize(pageSize);
            domainDataSource.data().forEach(function(item) {
                item.checked = $scope.selectAllDomains;
                $scope.domainCheckboxChanged(item);
            });
        }
    };

    $scope.includedGroupsGridOptions = {
        dataSource: groupDataSource,
        columns: [
            {
                field: 'name',
                title: msg.get('group'),
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function(args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Groups…')
                                .attr(
                                    'ng-change',
                                    "updateFilter(this, 'group')"
                                )
                                .attr('ng-model', 'filter');
                        }
                    }
                },
                template: groupColumnTemplate,
                attributes: {
                    class: 'text-nowrap grid-tooltip'
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        height: atHelperFunctions.getGridHeight(),
        scrollable: {
            virtual: true
        },
        selectable: 'row',
        dataBound: function(e) {
            var filterCell = e.sender.element.find('.k-filtercell input');
            filterCell.attr('placeholder', 'Filter Groups…');
        }
    };

    $scope.domainRowClicked = function(e, row) {
        row.checked = !row.checked;
        $scope.errorMessage = null;

        $scope.domainCheckboxChanged(row);

        if (e) {
            $timeout(function() {
                e.sender.element
                    .find('.k-state-selected')
                    .toggleClass('k-state-selected');
            }, 100);
        }
    };

    $scope.domainCheckboxChanged = function(row) {
        if (row.checked) {
            if (!$scope.selectAllDomains) {
                if (
                    !!modalData.domainChanges &&
                    !!modalData.domainChanges[row.domain]
                ) {
                    row.groups = modalData.domainChanges[row.domain].groups;
                }
            }
            addToSelectedDomains(row);
        } else {
            deleteFromSelectedDomains(row.domain);
        }

        $timeout(function() {
            row.checkedModel = row.checked;
        }, 100);
    };

    function createDomainGridOptions() {
        var scrollable = $scope.manuallyPaged ? true : { virtual: true };
        domainGridHeight = $scope.manuallyPaged ? 312 : 260;

        return {
            autoBind: !$scope.manuallyPaged,
            dataSource: domainDataSource,
            columns: [
                {
                    field: 'domain',
                    title: msg.get('group'),
                    template: domainColumnTemplate,
                    attributes: {
                        class: 'text-nowrap grid-tooltip'
                    }
                }
            ],
            sortable: true,
            height: atHelperFunctions.getGridHeight(domainGridHeight),
            scrollable: scrollable,
            selectable: 'row'
        };
    }

    $scope.pagerOptions = {
        autoBind: false,
        dataSource: domainDataSource,
        pageSizes: false,
        responsive: false,
        input: true,
        numeric: false,
        buttonCount: 0,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'websites'),
            display: msg.get('itemsDisplay', 'websites'),
            empty: msg.get('noItemsToDisplay', 'websites')
        }
    };

    $scope.domainGridOptions = createDomainGridOptions();

    $scope.cancel = function() {
        $uibModalInstance.dismiss();
    };

    $scope.applyIncludedGroups = function() {
        var incGroups = [];
        var remGroups = [];
        includedGroups.forEach(function(group) {
            if (group.isUpdated) {
                incGroups.push(group);
            }
        });
        removedGroups.forEach(function(group) {
            if (group.isUpdated) {
                remGroups.push(group);
            }
        });
        $uibModalInstance.close({
            selectedDomains: $scope.selectedDomains,
            includedGroups: incGroups,
            removedGroups: remGroups,
            isBulkSelect: $scope.selectAllDomains
        });
    };

    $scope.changePage = function(page) {
        $scope.page = page;
        includedGroups = [];
        removedGroups = [];
        groupDataSource.read();
    };

    var updateTimeout;
    $scope.updateFilterDelay = function(e, type) {
        $timeout.cancel(updateTimeout);
        updateTimeout = $timeout(function() {
            $scope.updateFilter(e, type);
        }, 500);
    };

    $scope.updateFilter = function(e, type) {
        var dataSource;
        var filter = [];
        var newFilter = [];

        if (type === 'group') {
            dataSource = groupDataSource;
            newFilter =
                !!e.filter && e.filter !== ''
                    ? {
                          field: 'name',
                          operator: 'contains',
                          value: e.filter
                      }
                    : undefined;
        } else if (type === 'category') {
            dataSource = domainDataSource;
            newFilter =
                !!e.categoryName && e.categoryName !== ''
                    ? {
                          field: 'category',
                          operator: 'contains',
                          value: e.categoryName
                      }
                    : undefined;
        } else {
            dataSource = domainDataSource;
            newFilter =
                !!e.domainName && e.domainName !== ''
                    ? {
                          field: 'domain',
                          operator: 'contains',
                          value: e.domainName
                      }
                    : undefined;
        }

        var currentFilters = dataSource.filter();
        if (!!currentFilters && !!currentFilters.filters) {
            currentFilters.filters.forEach(function(f) {
                if (
                    (f.field === 'category' && type === 'domain') ||
                    (f.field === 'domain' && type === 'category')
                ) {
                    filter.push(f);
                }
            });
        }

        if (newFilter) {
            filter.push(newFilter);
        }

        filter = filter || {};

        dataSource.filter(filter);
    };

    $scope.addDomain = function(filter) {
        if (!filter.domainName) {
            return;
        }

        parsedDomain =
            filter.domainName[filter.domainName.length - 1] === '/'
                ? filter.domainName.substring(0, filter.domainName.length - 1)
                : filter.domainName;

        if (erroredUrl !== parsedDomain) {
            $scope.errorMessage = null;
            // clearSelection();
            modalData
                .saveDomain({
                    name: parsedDomain
                })
                .success(function() {
                    var domain = {
                        checked: true,
                        domain: parsedDomain
                    };
                    $scope.domainCheckboxChanged(domain);
                    $scope.errorMessage = null;
                    $scope.domainGrid.dataSource.read();
                })
                .error(function(result) {
                    if (
                        result.error.indexOf(
                            'Website already exists in database.'
                        ) > -1
                    ) {
                        parsedDomain = result.error.slice(
                            result.error.lastIndexOf('Parameter name: ') + 16
                        );
                        parsedDomain =
                            parsedDomain.indexOf('//') > -1
                                ? parsedDomain.slice(
                                      parsedDomain.indexOf('//') + 2
                                  )
                                : parsedDomain;
                        parsedDomain =
                            parsedDomain.indexOf('www') > -1
                                ? parsedDomain.slice(
                                      parsedDomain.indexOf('www') + 4
                                  )
                                : parsedDomain;
                        $scope.selectedDomains[parsedDomain] = {
                            domain: parsedDomain,
                            checked: true
                        };
                        $scope.domainName = parsedDomain;
                        $scope.updateFilter(
                            {
                                domainName: parsedDomain
                            },
                            'domain'
                        );
                    } else {
                        erroredUrl = parsedDomain;
                        $scope.errorMessage = result.error;
                    }
                });
        }
    };

    $scope.removeDomain = function(row) {
        modalData
            .deleteDomain(row.domain)
            .success(function() {
                $scope.errorMessage = null;
                domainDataSource.read();
            })
            .error(function(result) {
                erroredUrl = parsedDomain;
                if (!row.checked) {
                    $scope.domainRowClicked(null, row);
                }
                if (
                    result.error ===
                    'Website exists in log and cannot be removed.'
                ) {
                    $scope.errorMessage = msg.get('cannotRemoveDomain');
                } else {
                    $scope.errorMessage = result.error;
                }
            });
    };
}
