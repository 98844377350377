import React from 'react';
import { useIntegrationState } from '../hooks';
import { IIntegrationState } from '../models/IIntegrationState';
const { createContext, useContext } = React;

interface IIntegrationProvider {
  integrationState: IIntegrationState;
}

const IntegrationsContext = createContext<IIntegrationProvider>(null);

type Props = {
  children: React.ReactNode;
};

export const IntegrationProvider = (props: Props) => {
  const { children } = props;

  const integrationState = useIntegrationState();

  const value = {
    integrationState: integrationState
  };

  return (
    <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>
  );
};

export const useIntegrationContextProvider = (): IIntegrationProvider => {
  return useContext(IntegrationsContext);
};

