import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { secondaryFontSize } from '../../../common/constants';
import { ILegendOverlayProps } from '../../models';
import { WhiteBackgroundTooltip } from '../../../common/components/Tooltip/TextWithTooltip.styles';
import {
  InfoOutlinedIcon,
  InfoPanelTooltip
} from '../../styles/templates.styles';

export const TooltipLegend = (props: ILegendOverlayProps) => {
  const { legendItems, legendColors, subtitle } = props;

  return (
    <WhiteBackgroundTooltip
      title={
        <Box style={{ padding: '10px' }}>
          {subtitle && <Typography sx={{ mb: 1 }}>{subtitle}</Typography>}
          {legendItems.map((item, index) => (
            <Typography
              key={item}
              style={{
                margin: 0,
                whiteSpace: 'nowrap',
                fontSize: secondaryFontSize
              }}
              variant="body1"
            >
              <span
                style={{
                  height: '10px',
                  width: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '10px',
                  backgroundColor: legendColors[index]
                }}
              ></span>
              {item}
            </Typography>
          ))}
        </Box>
      }
      enterTouchDelay={0}
    >
      <InfoPanelTooltip>
        <InfoOutlinedIcon />
      </InfoPanelTooltip>
    </WhiteBackgroundTooltip>
  );
};
