'use strict';

angular.module('app')
    .controller('addMembersModalController', AddMembersModalController);

AddMembersModalController.$inject = ['$scope', '$uibModalInstance', 'modalData', '$timeout', 'messagesService', 'atHelperFunctions', 'templateServiceFunctions', 'browserServiceFunctions'];

function AddMembersModalController($scope, $uibModalInstance, modalData, $timeout, msg, atHelperFunctions, templateServiceFunctions, browserServiceFunctions) {


    $scope.windowsDomainHeader = msg.get('logonPrimaryDomain');
    $scope.nameHeader = modalData.nameHeader || msg.get('memberName');
    $scope.saveAddingUsers = modalData.saveAddingUsers;
    $scope.forBlocking = !!modalData.forBlocking;

    var memberNameTemplate = function (item) {
        return templateServiceFunctions.userTypeTemplateAsString(item) + (!!item.alias ? ' (' + browserServiceFunctions.htmlEscape(item.alias) + ')' : '');
    };

    $scope.addMembersOptions = {
        dataSource: modalData.gridDataSource,
        columns: [{
            field: 'selected',
            title: '<div class="checkbox"><input type="checkbox" id="selectbox-add-members-header" ng-click="selectAll($event)" ng-model="allRows.selected" /><label for="selectbox-add-members-header"></label></div>',
            template: '<div class="checkbox"><input type="checkbox" id="selectbox-add-members-{{dataItem.uid}}" ng-model="dataItem.selected" ng-click="memberCheckClicked($event, dataItem)" /><label for="selectbox-add-members-{{dataItem.uid}}"></label></div>',
            headerAttributes: {
                class: 'select'
            },
            attributes: {
                class: 'select text-overflow-clip'
            },
            filterable: false,
            width: '35px'
        }, {
            field: 'windowsdomain',
            title: msg.get('logonPrimaryDomain'),
            filterable: false,
        }, {
            field: 'name',
            title: msg.get('memberName'),
            template: memberNameTemplate,
            filterable: false,
        }],
        filterable: false,
        height: atHelperFunctions.getGridHeight(320),
        scrollable: {
            virtual: true
        },
        autoBind: false,
        selectable: 'row'
    };

    $scope.isSavingDisabled = function () {
        return modalData.gridDataSource.data().map(function (item) {
            return item.selected;
        }).indexOf(true) < 0;
    };

    $scope.memberRowClicked = function (e, row) {
        row.selected = !row.selected;
        $timeout(function () {
            e.sender.element.find('.k-state-selected').toggleClass('k-state-selected');
        }, 100);
    };

    $scope.memberCheckClicked = function (e, row) {
        e.target.checked = row.selected;
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.updateFilter = function (e) {
        $scope.addMembersGrid.dataSource.filter({
            field: 'searchString',
            operator: 'contains',
            value: e.filter
        });
    };

    $scope.selectAll = function (e) {
        angular.forEach($scope.addMembersGrid.dataSource.view(), function (item) {
            item.selected = e.target.checked === true;
        });
    };
}
