import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNotifications, INotification } from '../../services/Notifications';
import { useSubscription } from 'observable-hooks';

export default function Notifications() {
  const notificationService = useNotifications();

  const [notification, setNotification] = useState<INotification>(null);
  useSubscription(notificationService.getNotification(), setNotification);

  return (
    notification && (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          top: 60
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          role="alert"
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    )
  );
}
