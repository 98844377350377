import { IRoute } from '../../../../models/IRoute';

export const signupLogin: IRoute = {
  name: 'signup-login',
  stateDefinition: {
    url: '/signup-login?key&username&accountId',
    template: '',
    controller: 'SignupLoginCtrl',
    data: { pageTitle: 'Sign Up' }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default signupLogin;
