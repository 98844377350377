'use strict';

angular.module('app').directive('atTooltip', [
    '$document',
    'tooltipService',
    'atHelperFunctions',
    function atTooltip($document, tooltipService, atHelperFunctions) {
        return {
            scope: {
                tooltipContent: '=',
                tooltipCustomCondition: '=',
                tooltipContentFunction: '='
            },
            link: function ($scope, element, attr) {
                function createTooltip(createNew) {
                    var tooltip = element.getKendoTooltip();
                    if (!!tooltip) {
                        tooltip.destroy();
                    }

                    var content = $scope.tooltipContent || attr.atTooltip;
                    if ($scope.tooltipContentFunction && typeof $scope.tooltipContentFunction.get === 'function') {
                        content = $scope.tooltipContentFunction.get($scope.tooltipContentFunction.params) || content;
                    }

                    if (atHelperFunctions.isEmpty(content)) {
                        return;
                    }

                    if (createNew) {
                        element.kendoTooltip({
                            content: content,
                            position: attr.tooltipPlacement || 'bottom',
                            animation: {
                                open: {
                                    effects: 'fadeIn'
                                }
                            },
                            show: function () {
                                var that = this;
                                var tooltips = $document.find('[data-role=tooltip]');
                                // Move tooltip
                                tooltipService.addTooltipMargin(that, 2);
                                tooltipService.fixTooltipCallout(that);

                                // Close other tooltips
                                tooltips.each(function () {
                                    var tooltip = angular.element(this).getKendoTooltip();
                                    if (tooltip && tooltip !== that) {
                                        tooltip.hide();
                                        if (angular.element(this).getKendoTooltip().content) {
                                            angular
                                                .element(angular.element(this).getKendoTooltip().content[0].parentNode)
                                                .removeClass('force-visibility');
                                        }
                                    }
                                });

                                // Something in Kendo is setting visibility to hidden and not resetting it.
                                var parentNode = angular.element(that.content[0].parentNode);
                                parentNode.addClass('force-visibility');

                                // Due to z-index differences between Kendo, Pages, and Bootstrap, need to force the z-index for tooltips
                                var parentContainer = angular.element(
                                    atHelperFunctions.getClosest(that.content[0], '.k-animation-container')
                                );
                                var zIndex = parseInt(parentContainer.css('z-index'));
                                parentContainer[0].style.setProperty(
                                    'z-index',
                                    isNaN(zIndex) ? 10000 : zIndex + 10000,
                                    'important'
                                );
                            }
                        });
                    } else {
                        if (tooltip && typeof tooltip.refresh === 'function') {
                            tooltip.refresh();
                        }
                    }
                }

                $scope.$watch('tooltipContent', function () {
                    createTooltip($scope.tooltipCustomCondition ? $scope.tooltipCustomCondition(element) : true);
                });

                $scope.$on('$destroy', function () {
                    var tooltip = element.getKendoTooltip();
                    if (!!tooltip) {
                        tooltip.destroy();
                    }
                });
            }
        };
    }
]);
