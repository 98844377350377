import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { IInsightsCategory, ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import { useCategoriesState } from '../../hooks/useCategoriesState';
import CategoryCheckboxList from './CategoryCheckboxList';
import {
  CommonPaddingBox,
  DialogContentStyled,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import TextFieldContainer from './TextFieldContainer';
import BackButtonBox from './BackButtonBox';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onBackToStep1: () => void;
  onGoToStep3: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step2Dialog = (props: ComponentProps) => {
  const { open, onClose, onBackToStep1, onGoToStep3, coreCategoryRef } = props;

  const { init, categories } = useCategoriesState();

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<IInsightsCategory[]>();
  const textInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (open && !filtered) {
      setFiltered(categories);
    }
  }, [categories, filtered, open]);

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.toLowerCase();
    const matches = categories.filter(
      (x) => x.category.toLowerCase().indexOf(input) > -1
    );
    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const goToStep3 = () => {
    coreCategoryRef.current.categories = checkedItems.map((item) => {
      return { categoryId: item, category: '' };
    });
    setCheckedItems([]);
    onGoToStep3();
  };

  const close = () => {
    setCheckedItems([]);
    setShowSearch(false);
    setFiltered(null);
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <BackButtonBox onClick={onBackToStep1} />
        <CommonPaddingBox width={570} maxHeight={560}>
          <DialogTitle>
            Step 2 of 3: Define Your Team's Core Categories
          </DialogTitle>
          <DialogContentStyled>
            <Typography sx={TotCss.instructions}>
              Select the categories that are directly associated with your
              team’s role and responsibilities. This is where you expect team
              members to spend the majority of their time.
            </Typography>
            <TextFieldContainer
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              textValue={'Categories'}
              isHideable={true}
            />
            <StyledBoxContainer hidden={!showSearch}>
              <StyledSearchBarContainer>
                <SearchBar
                  placeholder={'Search Categories'}
                  fieldRef={textInputRef}
                  onClick={(e) => e.stopPropagation()}
                  handleDropDownSearchChange={(e) =>
                    handleDropDownSearchChange(e)
                  }
                />
              </StyledSearchBarContainer>
              <CategoryCheckboxList
                filtered={filtered}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            </StyledBoxContainer>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={goToStep3}
              disabled={checkedItems.length === 0}
              confirmText={'Go to step 3 of 3'}
            />
          </DialogActions>
        </CommonPaddingBox>
      </Dialog>
    )
  );
};
