import GroupTypes from './GroupTypes';
import GroupMixes from './GroupMixes';

class GroupProperties {
  constructor(groupType, groupMix) {
    this.groupType = GroupTypes[groupType];
    this.groupMix = GroupMixes[groupMix];
  }

  generateTypeLabel() {
    switch (this.groupType) {
      case GroupTypes.ActivTrak:
        return 'ActivTrak';
      case GroupTypes.ActiveDirectory:
        return 'Active Directory';
      default:
        return null;
    }
  }

  generateMixLabel() {
    switch (this.groupMix) {
      case GroupMixes.User:
        return 'User';
      case GroupMixes.Computer:
        return 'Computer';
      case GroupMixes.Both:
        return 'Mixed';
      default:
        return null;
    }
  }

  generateIcon() {
    let label;
    switch (this.groupType) {
      case GroupTypes.ActivTrak:
        label = 'activTrak';
        break;
      case GroupTypes.ActiveDirectory:
        label = 'activeDirectory';
        break;
      default:
        label = 'activTrak';
    }

    return `${label}${this.generateMixLabel() || 'User'}Group`;
  }
}

export default GroupProperties;
