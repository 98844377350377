import React from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { RealTimeSettingsService } from '../services/RealTimeSettingsService';
import { DefaultWidgetProps } from '../models';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import {
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography
} from '@mui/material';
import { useRealTimeSettingsState } from '../hooks/useRealTimeSettingsState';

export const RealTimeSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const notificationService = useNotifications();
  const service = RealTimeSettingsService();
  const settingsState = useRealTimeSettingsState({
    service,
    notificationService
  });
  const { screenshotFeatureFlag, isLoading, updateRealTimeSettings } =
    settingsState;

  const handleUpdate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    updateRealTimeSettings(newCheckedValue);
  };

  return (
    <ConfigurationCardWrapper title="Real-Time Settings">
      <Typography sx={{ marginBottom: '16px' }}>
        Real-time monitoring provides a live data stream of the active window on
        any monitored device screen. Data is displayed in individual rows with
        information on the open website or application, how long the user has
        been there, and every other data point the software captures.
      </Typography>
      <Link href="#/app/teampulse">
        Click here to view the Real-time Report
      </Link>
      <Typography sx={{ marginBottom: '24px', marginTop: '16px' }}>
        In addition to the activity data, real-time thumbnails display an image
        of the monitored user&apos;s screen. Accounts have the ability to
        disable showing these thumbnails to protect the monitored user&apos;s
        private data and protect sensitive information from being accidentally
        shared.
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              disabled={isSupport || isLoading}
              color="primary"
              onChange={handleUpdate}
            />
          }
          label={
            <Typography sx={{ marginLeft: '10px' }}>
              Enable Real-time Thumbnails
            </Typography>
          }
          checked={screenshotFeatureFlag}
        />
      </FormGroup>
    </ConfigurationCardWrapper>
  );
};
