import { styled } from '@mui/material/styles';
import {
  heavyFontWeight,
  primaryAqua,
  primaryBlue
} from '../../common/constants';

export const UserTypeIcon = styled('div')`
  margin: 0px 10px;
  width: 12%;
  i {
    font-size: 1.5em;
    margin-right: 0.2em;
  }
`;

export const UserContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Count = styled('div')`
  font-weight: ${heavyFontWeight};
  padding-right: 10px;
`;

export const Preview = styled('div')`
  overflow: 'hidden';
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
`;

export const groupViewsClasses = {
  uploadFileProgressContainer: {
    height: '6px',
    width: '100%',
    borderRadius: '3px',
    background: '#ffffff',
    padding: '20px 0px 10px'
  },
  uploadFileProgressBar: {
    height: '100%',
    width: '100%',
    background: primaryAqua
  },
  dispResultBar: {
    height: '40px',
    width: '100%',
    marginBottom: '20px',
    marginTop: '-15px',
    background: '#ffffff'
  },
  dispProgress: {
    height: '40px',
    width: '100%',
    background: '#ffffff',
    marginBottom: '20px'
  },
  confAlert: {
    '& .MuiAlert-icon': {
      color: '#000'
    },
    backgroundColor: primaryBlue,
    border: `0px ${primaryBlue}`
  }
};
