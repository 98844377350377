import React, { Fragment } from 'react';
import { Skeleton } from '@mui/material';

const SkeletonLoader = (props) => {
    const {
        loading,
        children,
        className,
        animation = 'wave',
        height = '18px',
        width = '18px',
        variant = 'rect'
    } = props;

    return (
        <Fragment>
            {loading ? (
                <Skeleton sx={className} animation={animation} height={height} width={width} variant={variant} />
            ) : (
                children
            )}
        </Fragment>
    );
};

export default SkeletonLoader;
