import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import version from '../../../version.json';

angular.module('app').factory('authInterceptor', authInterceptor);

authInterceptor.$inject = ['responseErrorService', 'atHelperFunctions', '$injector'];

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function authInterceptor(responseErrorService, atHelperFunctions, $injector) {
    return {
        request: function (config) {
            config.guid = atHelperFunctions.generateGuid();
            const token = userServiceFunctions.getEncodedUserToken();
            if (token) {
                config.headers.Authorization = token;
            }

            if (
                config.url.indexOf('.html') === -1 &&
                (config.url.indexOf('/api/') >= 0 ||
                    config.url.indexOf('/account/') >= 0 ||
                    config.url.indexOf('/download/invoice/') >= 0)
            ) {
                config.url =
                    config.url +
                    (config.url.indexOf('?') !== -1 ? '&' : '?') +
                    'cachetimestamp=' +
                    new Date().getTime();
            }

            config.withCredentials = true;
            config.headers['X-AT-Version'] = (version && version.version) || 'x.x.x';

            //TEMP: Impersonation attempt
            const accountId = getUrlParameter('accountId');
            if (accountId) {
                //get token
                const child = window.localStorage.getItem('activTrak.child-' + accountId);
                if (child) {
                    const childProfile = JSON.parse(child);
                    if (childProfile.token) {
                        config.headers.Authorization = childProfile.token;
                    }
                }
            }

            return config;
        },
        response: function (response) {
            const loginService = $injector.get('loginService');
            if (response.headers('Authorization') && loginService.isLoggedIn()) {
                // Update token
                loginService.setUserToken(response.headers('Authorization'));
            }

            return response;
        },
        responseError: function (response) {
            return responseErrorService.handleResponseError(response);
        }
    };
}
