import React from 'react';
import ResetRoleAccessDialog from '../components/ResetRoleAccessDialog';
import { RoleAccessDateFilter } from '../components/RoleAccessDateFilter';
import { RoleAccessSettings } from '../components/RoleAccessSettings';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { RoleAccessProvider } from '../services';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { Box, Button } from '@mui/material';

export default function RoleAccessView() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [resetDefaultFilters, setResetDefaultFilters] = React.useState(false);

  const authorizationService = useAuthorization();

  const handleClose = async () => {
    setDialogOpen(false);
  };

  const resetToDefault = () => {
    setDialogOpen(true);
  };

  const isSuperAdminOrAdmin = authorizationService.hasAnyRole([
    Role.Admin,
    Role.SuperAdmin
  ]);

  const allowedRoles = authorizationService.hasAnyRole([
    Role.Admin,
    Role.SuperAdmin,
    Role.SupportAdvanced
  ]);

  return (
    <Box style={{ marginLeft: 15, marginRight: 15 }}>
      {allowedRoles && (
        <Box display="flex" flexDirection="row-reverse">
          <Button
            variant="contained"
            disabled={!isSuperAdminOrAdmin}
            onClick={resetToDefault}
            sx={{ right: '10px' }}
          >
            Reset to Default
          </Button>
          <ResetRoleAccessDialog
            open={dialogOpen}
            onClose={handleClose}
            setResetDefaultFilters={setResetDefaultFilters}
          />
        </Box>
      )}
      <Box className="panel-body">
        <RoleAccessSettings />
        <br />
        <br />
        <br />
        <RoleAccessDateFilter
          resetDefault={resetDefaultFilters}
          setResetDefault={setResetDefaultFilters}
        />
      </Box>
    </Box>
  );
}

export const RoleAccessComponent = () => {
  return (
    <ReactivTrakComponentWrapper>
      <RoleAccessProvider>
        <RoleAccessView />
      </RoleAccessProvider>
    </ReactivTrakComponentWrapper>
  );
};
