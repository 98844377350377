import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { agentInstallApiService } from '_app/serviceFunctions/agentInstallApiService';
import { initializeDataDog } from '../../_reactivtrak/src/common/config/dataDog';
import { getAccountSettings, setAccountSettings } from '../../_reactivtrak/src/common/helpers/accountSettings';
import { http as httpService } from '_app/http';

import exportProgressBarTemplate from '../views/widgets/exportProgressBar.html?raw';
import downloadButtonTemplate from '../views/widgets/downloadButton.html?raw';
import rzSliderTemplate from '../views/widgets/rzSliderTemplate.html?raw';

angular.module('app').run([
    '$rootScope',
    '$window',
    '$state',
    '$templateCache',
    '$timeout',
    'atkHttpDecorator',
    'atHelperFunctions',
    'envService',
    'loginService',
    'envConfig',
    'authorizationService',
    'activTrakAcademyService',
    function (
        $rootScope,
        $window,
        $state,
        $templateCache,
        $timeout,
        atkHttpDecorator,
        atHelperFunctions,
        envService,
        loginService,
        envConfig,
        authorizationService,
        activTrakAcademyService
    ) {
        initializeDataDog();
        // Initialize UTM script
        if (typeof $window.UTMscripInit === 'function') {
            $window.UTMscripInit();
        }

        // eslint-disable-next-line no-console
        console.log('angularjs version ==>', angular.version);
        // eslint-disable-next-line no-console
        console.log(
            'ActivTrak Version (vite):',
            // eslint-disable-next-line no-undef
            __VERSION__,
            ' Commit:',
            // eslint-disable-next-line no-undef
            __COMMIT_HASH__,
            ' @ ' + envService.get()
        );

        //Must be first method to run:
        atkHttpDecorator.init();

        userServiceFunctions.initialize(loginService, httpService, envConfig);

        agentInstallApiService.initialize(httpService, envConfig);

        $rootScope.$on('$locationChangeStart', function () {
            // If globals are not set, load them.
            if (!loginService.getGlobals()) {
                loginService.loadGlobals();
            }

            loginService.validate();
        });

        $rootScope.$on('$locationChangeSuccess', function () {
            $window.scrollTo(0, 0);
        });

        $rootScope.$on('$viewContentLoaded', function () {
            atHelperFunctions.updateTitle($rootScope, $state);
        });

        angular.element($window).bind('scroll', function () {
            var headerHeight = this.innerWidth > 991 ? 60 : 48;
            $rootScope.windowScrolledDown = this.pageYOffset >= headerHeight;
            $rootScope.$applyAsync();
        });

        var fullScreenTimer;
        angular.element($window).bind('resize', function () {
            if (typeof fullScreenTimer !== 'undefined') {
                clearTimeout(fullScreenTimer);
            }
            fullScreenTimer = setTimeout(function () {
                $rootScope.windowFullScreen =
                    $window.fullScreen ||
                    ($window.innerWidth === screen.width && $window.innerHeight === screen.height);
                $rootScope.$applyAsync();
            }, 2000);
        });

        var body = $('body');
        $rootScope.$watch(
            function () {
                return body.hasClass('menu-pin');
            },
            function (newValue) {
                $rootScope.app.layout.menuPin = newValue;
            }
        );

        // If global object is not set, load them.
        if (!loginService.isLoggedIn()) {
            loginService.loadGlobals();
        }

        var redirectToAuthorizedHome = function (toState, toParams) {
            var accountSettings = getAccountSettings();
            if ((accountSettings && accountSettings.bundleFlags) || toState.name === 'app.loading') {
                authorizationService.redirectToAuthorizedHome($state, toState.name);
            } else {
                $state.go('app.loading', { toState: toState, toParams: toParams });
            }
        };

        // TODO: refactor state change authorization checks
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
            if (toState.redirectTo) {
                // If the to state has a redirect state defined go to that state instead
                console.warn(
                    `ActivTrak Warn: Redirecting from '${toState.name}' to '${toState.redirectTo}' due to a defined redirect.`
                );
                return;
            } else if (
                // If the to state is the support portal and the user is authorized to view it, allow the state change
                toState.name === 'supportportal' &&
                (authorizationService.hasRole([authorizationService.roles.supportPortal]) ||
                    userServiceFunctions.getIsImposter())
            ) {
                return;
            } else if (toState.name === 'app.help.academy' && getAccountSettings()?.account) {
                event.preventDefault();
                activTrakAcademyService.redirectToAcademy(toParams, false);
            } else if (!authorizationService.hasRouteByName(toState.name)) {
                event.preventDefault();
                if (fromState.name !== 'login' && getAccountSettings()?.account) {
                    console.error(
                        'ActivTrak Error: Unauthorized route change.  toStateName:',
                        toState.name,
                        '; fromStateName:',
                        fromState.name,
                        ';'
                    );
                }
                redirectToAuthorizedHome(toState, toParams);
            }
        });

        // Bind window resizing
        var resizeDelay;
        var oldWindowSize = {
            innerWidth: 0,
            innerHeight: 0
        };
        angular.element($window).bind('resize', function () {
            $timeout.cancel(resizeDelay);

            var windowSize = {
                newWidth: $window.innerWidth,
                newHeight: $window.innerHeight,
                oldWidth: oldWindowSize.innerWidth,
                oldHeight: oldWindowSize.innerHeight
            };

            $rootScope.$broadcast('atWindowResizing', angular.copy(windowSize));
            $rootScope.$broadcast('authorizationServiceUpdated');

            resizeDelay = $timeout(function () {
                $rootScope.$broadcast('atWindowResized', angular.copy(windowSize));
                oldWindowSize.innerWidth = $window.innerWidth;
                oldWindowSize.innerHeight = $window.innerHeight;
            }, 500);
        });

        var roleAccessUpdatePromise;
        // TODO: Update this to update the userPermissionStore when roleAccess is added
        angular.element($window).bind('roleAccessUpdated', function (roleAccessUpdatedEvent) {
            setAccountSettings({ roleAccess: roleAccessUpdatedEvent.detail.roleAccess });
            $timeout.cancel(roleAccessUpdatePromise);
            roleAccessUpdatePromise = $timeout(function () {
                $rootScope.$broadcast('AccountSettingsChanged');
            }, 500);
        });

        var roleDateFiltersUpdatePromise;
        angular.element($window).bind('roleDateFiltersUpdated', function () {
            $timeout.cancel(roleDateFiltersUpdatePromise);
            roleDateFiltersUpdatePromise = $timeout(function () {
                $rootScope.$broadcast('roleDateFiltersRefresh');
            }, 500);
        });

        // this enables ng-include
        $templateCache.put('exportProgressBar.html', exportProgressBarTemplate);
        $templateCache.put('downloadButton.html', downloadButtonTemplate);
        $templateCache.put('rzSliderTemplate.html', rzSliderTemplate);
    }
]);

angular.module('app').run([
    '$rootScope',
    '$state',
    '$stateParams',
    'messagesService',
    function ($rootScope, $state, $stateParams, msg) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        $state.defaultErrorHandler(function (error) {
            // Ignore errors from the login page
            // These are covered in login.js
            // Ignore transition superseded error messages
            // These come from requesting a transition when one is already in progress.
            if (
                $state.current.name === 'login' ||
                error?.message?.indexOf('transition has been') > -1 ||
                error?.message?.indexOf('transition is invalid') > -1
            ) {
                return;
            }

            console.error(error?.detail ?? error);
        });

        // App globals
        $rootScope.app = {
            name: msg.get('appName'),
            title: msg.get('appName'),
            layout: {
                menuPin: false,
                menuBehind: false,
                // theme: 'pages/css/pages.min.css',
                screenshotView: false
            }
        };
        $rootScope.isAccountCreationMode = false;
    }
]);

if (import.meta.env.VITE_ENABLE_UI_ROUTER_TRACE === "true" &&
  import.meta.env.VITE_NAME === "dev") {
  angular.module('app').run([
    '$trace',
    ($trace) => $trace.enable()
  ]);
}
