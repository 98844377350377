import { errorPrefix } from './constants';
import Filter from '../models/Filter';

export const userPulseFilter = (userPulses, filter) => {
  try {
    switch (filter) {
      case Filter.ActiveNow:
        return _.filter(userPulses, (item) => item.activeStatus === 'active');
      case Filter.ActiveToday:
        return _.filter(userPulses, (item) => item.firstSeen);
      case Filter.PassiveNow:
        return _.filter(userPulses, (item) => item.activeStatus === 'passive');
      case Filter.InactiveNow:
        return _.filter(userPulses, (item) => item.activeStatus === 'inactive');
      case Filter.InactiveToday:
        return _.filter(userPulses, (item) => !item.firstSeen);
      default:
        return userPulses;
    }
  } catch (e) {
    console.error(`${errorPrefix} userPulseFilter`, e);
  }
};
