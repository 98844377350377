import { useCallback, useMemo, useRef, useState } from 'react';
import { NotificationType, SortDirection } from '../../common/enums';
import { AliasStateProps } from '../models/AliasStateProps';
import { IComputerAlias } from '../models/IComputerAlias';
import { IComputerAliasDtoSave } from '../models/IComputerAliasDto';
import { IComputerAliasState } from '../models/IComputerAliasState';
import {
  filterAndSortAliases,
  getAutoCompleteFilteredAliases,
  mapToComputerAlias
} from '../utils/Alias.Utils';

export const useComputerAliasState = (
  props: AliasStateProps
): IComputerAliasState => {
  const { aliasService } = props;
  const { getComputerAliases, saveComputerAliases } = aliasService;
  const [aliases, setAliases] = useState<IComputerAlias[]>([]);
  const aliasesChanged = useRef<IComputerAliasDtoSave[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [aliasNotification, setAliasNotification] =
    useState<NotificationType>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>(undefined);
  const [gridOrderBy, setGridOrderBy] = useState();
  const [gridOrderDirection, setGridOrderDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [searchCriteria, setSearchCriteria] = useState<any[]>([]);
  const searchBarFilterParams = ['alias', 'primaryDomain', 'computerName'];

  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setGridOrderBy(newOrderBy);
    setGridOrderDirection(newOrderDirection);
  }, []);

  const onApplyClick = async () => {
    if (aliasesChanged?.current?.length > 0) {
      try {
        aliasesChanged?.current?.forEach((aliasChanged) => {
          const foundAlias = aliases.find(
            (alias) => alias.compId == aliasChanged.compId
          );
          foundAlias.alias = aliasChanged.alias;
        });
        setIsSaving(true);
        setIsLoading(true);
        await saveComputerAliases(aliasesChanged.current);
        setAliasNotification({ msg: 'Aliases were saved', type: 'success' });
        resetAliasesAndChanges();
      } catch (error) {
        let errorMessage = 'Error occurred when saving alias';

        if ((error.status === 400 || error.status === 500) &&
          (error.message || error.data.message || error.data.error)
        ) {
            errorMessage = error.message || error.data.message || error.data.error;

            if (errorMessage === 'Aliases ​​duplicated') {
                errorMessage = "Cannot save duplicate computer alias.  Computer aliases must be unique."
            }
        }
        
        setAliasNotification({
          msg: errorMessage,
          type: 'error'
        });
      }
      aliasesChanged.current = [];
      setIsSaving(false);
      setIsLoading(false);
    } else {
      setAliasNotification({ msg: 'No aliases to save', type: 'error' });
    }
  };

  const resetAliasesAndChanges = () => {
    aliasesChanged.current = [];
    //will cause page refresh
    setAliases([...aliases]);
  }

  const onCancelClick = async () => {
    aliasesChanged.current = [];
    await getAlias();
  };

  const getAlias = useCallback(
    async (): Promise<void> => {
      setIsLoading(true);
      try {
        const data = await getComputerAliases();
        const alias: IComputerAlias[] = data.map(mapToComputerAlias);
        setAliases(alias);
      } catch (error) {
        setAliasNotification({ msg: 'Unable to load Alias', type: 'error' });
        console.error('ActivTrak Error: Unable to load Alias', error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAliasChange = (computerIdChanged: number, newAlias: string) => {
    if (!Array.isArray(aliasesChanged.current)) {
      //happens in unit tests
      aliasesChanged.current = [];
    }
    let changedAlias = aliasesChanged.current?.find(
      (alias) => alias.compId == computerIdChanged
    );
    const computerAlias = aliases?.find(
      (alias) => alias.compId == computerIdChanged
    );

    if (!changedAlias) {
      changedAlias = {
        compId: computerAlias?.compId,
        acknowledged: computerAlias?.acknowledged,
        alias: newAlias
      };
      aliasesChanged.current.push(changedAlias);
    } else {
      changedAlias.alias = newAlias;
    }
    if (computerAlias)
    {
      computerAlias.alias = newAlias;
    }
  };

  const init = useCallback(async (): Promise<void> => {
    if (!aliases) {
      await getAlias();
    }
  }, [getAlias, aliases]);

  const filteredAliases = useMemo<IComputerAlias[]>(
    (): IComputerAlias[] => {
      return filterAndSortAliases(
        aliases,
        searchTerm,
        gridOrderBy,
        gridOrderDirection,
        searchCriteria,
        searchBarFilterParams
      ) as IComputerAlias[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aliases, searchTerm, gridOrderDirection, gridOrderBy, searchCriteria]
  );

  const getFilteredComputerAliasesForAutocomplete = (
    dataStructure: any[]
  ): any[] => {
    return getAutoCompleteFilteredAliases(
      dataStructure,
      aliases,
      searchBarFilterParams,
      searchTerm
    );
  };

  return {
    aliases,
    filteredAliases,
    isLoading,
    getAlias,
    init, //initial load if Alias array doesn't exist
    onSortOrder,
    setSearchTerm,
    searchTerm,
    handleAliasChange,
    onApplyClick,
    aliasNotification,
    setAliasNotification,
    onCancelClick,
    getFilteredComputerAliasesForAutocomplete,
    searchCriteria,
    setSearchCriteria,
    aliasesChanged,
    isSaving
  };
};
