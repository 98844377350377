'use strict';

angular.module('app').directive('atActionButton', atActionButton);

function atActionButton() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions',
            model: '=atModel'
        },
        template: require('views/widgets/atActionButton.html'),
        controller: atActionButtonCtrl
    };
}

atActionButtonCtrl.$inject = ['$scope'];

function atActionButtonCtrl($scope) {
    function setTooltipContent() {
        $scope.tooltipContent =
            ($scope.model && $scope.options.activeTooltip) ||
            $scope.options.tooltip;
    }

    $scope.handleClick = function () {
        if (typeof $scope.options.onClick === 'function') {
            $scope.options.onClick($scope.options.clickParams);
        }
    };

    $scope.$watch('model', setTooltipContent);
}
