import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const AlarmIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const title = props.title ?? 'Create Alarm';
  const className = props.className;
  const width = props.width ?? '18px';
  const height = props.height ?? '18px';
  const fillColor = props.fillColor ?? 'currentColor';
  const viewBox = props?.viewBox ?? '0 0 18 18';

  let classList = 'at-icon-alarm';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      title={title}
      className={classList}
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        d="M6.19983 15.2831C6.19983 16.0206 6.79652 16.6173 7.534 16.6173C8.27148 16.6173 8.86817 16.0206 8.86817 15.2831H6.19983Z"
        fill={fillColor}
      />
      <path
        d="M12.6513 0.752919C13.1651 0.785029 13.5504 1.00177 13.8073 1.40315L16.7694 6.58087C17.0102 7.11068 16.9861 7.59233 16.6972 8.02582C16.4322 8.42318 16.0797 8.63167 15.6394 8.65129L12.2265 8.65125L12.227 12.5946L13.5679 13.9355V14.6059H1.5V13.9355L2.84088 12.5946V8.57194C2.84088 6.38836 4.33643 4.55332 6.35409 4.03411L6.52829 3.99285V3.20843C6.52829 2.65197 6.97748 2.20278 7.53395 2.20278C8.05768 2.20278 8.48639 2.60068 8.53501 3.11142L8.5396 3.20843V3.99285C8.93677 4.07886 9.31532 4.21546 9.66815 4.39558C10.1229 3.61143 10.692 2.62975 11.3749 1.45131C11.7121 0.953606 12.1375 0.720809 12.6513 0.752919ZM11.9532 1.92429L9.21997 6.64663C8.94048 7.12939 9.28894 7.73556 9.84792 7.73556H15.3144C15.8734 7.73556 16.2218 7.12939 15.9423 6.64663L13.2091 1.92429C12.9296 1.44153 12.2327 1.44153 11.9532 1.92429Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5611 4.96405C12.3336 4.96405 12.1473 4.77784 12.1473 4.55025V3.72264C12.1473 3.49505 12.3336 3.30884 12.5611 3.30884C12.7887 3.30884 12.9749 3.49505 12.9749 3.72264V4.55025C12.9749 4.77784 12.7887 4.96405 12.5611 4.96405Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9749 6.61923H12.1473V5.79163H12.9749V6.61923Z"
        fill={fillColor}
      />
    </SvgIconWrapper>
  );
};
