import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { NewIcon } from '../../common/assets/Icons';

interface INewStatusIconProps {
  dateCreated: string;
}

export const NewStatusIcon: React.FC<INewStatusIconProps> = (
  props
) => {
  const dateExp = new Date(props.dateCreated);
  const today = new Date();
  const msDay = 86400000;
  const dayDiff = (dateExp.valueOf() - today.valueOf()) / msDay;

  return dayDiff > 0 && dayDiff <= 1 ? (
    <Tooltip title="New API Key created.">
      <IconButton>
        <NewIcon/>
      </IconButton>
    </Tooltip>
  ) :  (
     <>
     </>
  );
};
