import React from 'react';
import { IUserAgentHealth } from '../models';
import { UseComputersContext } from '../services';
import {
  AgentSystemChecks,
  ChecksContainer,
  HealthStatusCircle,
  ParamContainerBold,
  UserSystemChecks
} from '../styles';

type ComponentProps = {
  isLoading: boolean;
  systemAgentHealth: IUserAgentHealth;
  userAgentsHealth: IUserAgentHealth[];
};

export const AgentUserChecks = (props: ComponentProps) => {
  const { isLoading, systemAgentHealth, userAgentsHealth } = props;
  const { computersService } = UseComputersContext();

  return (
    <ChecksContainer>
      <AgentSystemChecks>
        <ParamContainerBold>Agent System Checks</ParamContainerBold>
        <ul>
          {isLoading && <li>Loading ...</li>}
          {!isLoading &&
            systemAgentHealth?.healthChecks?.map((systemCheck, index) => (
              <li key={`agent-check-${index}`}>
                <HealthStatusCircle
                  color={computersService.getHealthStatusColor(
                    systemCheck.status
                  )}
                />
                &nbsp;{systemCheck.status} {systemCheck.name}{' '}
                {systemCheck.message}
              </li>
            ))}
          {!isLoading && !systemAgentHealth?.healthChecks && (
            <li>No system checks reported</li>
          )}
        </ul>
      </AgentSystemChecks>
      <UserSystemChecks>
        <ParamContainerBold>User Checks</ParamContainerBold>
        <ul>
          {isLoading && <li>Loading ...</li>}
          {!isLoading &&
            userAgentsHealth?.map((userAgentHealthItem, index) => (
              <li key={`user-check-${index}`}>
                <HealthStatusCircle
                  color={computersService.getHealthStatusColor(
                    computersService.getHealthCheckStatus(
                      userAgentHealthItem?.healthChecks
                    )
                  )}
                />
                &nbsp;
                {userAgentHealthItem.name}
                <ul>
                  {userAgentHealthItem?.healthChecks?.map(
                    (userCheck, index) => (
                      <li key={`health-check-${index}`}>
                        <HealthStatusCircle
                          color={computersService.getHealthStatusColor(
                            userCheck.status
                          )}
                        />
                        &nbsp;{userCheck.status} {userCheck.name}{' '}
                        {userCheck.message}
                      </li>
                    )
                  )}
                </ul>
              </li>
            ))}
          {!isLoading &&
            (!userAgentsHealth || userAgentsHealth.length == 0) && (
              <li>No user checks reported</li>
            )}
        </ul>
      </UserSystemChecks>
    </ChecksContainer>
  );
};
