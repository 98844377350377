'use strict';

angular.module('app').filter('duration', function () {
        function padToTwo(n) {
            return ('0' + n).slice(-2);
        }

        return function (sec) {
            var s = sec % 60;
            var min = Math.floor(sec / 60);
            var m = min % 60;
            var h = Math.floor(min / 60);
            return h + ':' + padToTwo(m) + ':' + padToTwo(s);
        };
    })
    .filter('locale', function () {
        return function (number) {
            // console.log('Filtering', number.toLocaleString());
            return number.toLocaleString();
        };
    });
