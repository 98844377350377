import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { LoadingContent } from '../styles/supportPortal.styles';

export const LoadingModal = () => {
  return (
    <LoadingContent>
      <Typography variant="h4" pb={2}>
        Fetching account details...
      </Typography>
      <CircularProgress />
    </LoadingContent>
  );
};
