import React from 'react';
import { useClassificationStores } from '../services';

type TotalProgressProps = {
  currentActivityIndex: number;
};

export default function TotalProgress(props: TotalProgressProps) {
  const { currentActivityIndex } = props;
  const { classificationStore } = useClassificationStores();
  const { activitiesState } = classificationStore;
  const { isClassified, activities } = activitiesState;

  return currentActivityIndex ? (
    <div
      style={{
        textAlign: 'right',
        fontSize: '16px',
        lineHeight: '24px'
      }}
      data-testid="total-activities-progress"
    >
      <strong>#{currentActivityIndex}</strong> of{' '}
      <strong>{activities?.length}</strong>{' '}
      {isClassified ? 'classified' : 'pending'} Classifications
    </div>
  ) : (
    <></>
  );
}
