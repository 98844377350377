import React, { createContext, useContext } from 'react';
import { IInsightsService } from '../models';
import InsightsService from './InsightsService';
const InsightsContext = createContext<IInsightsProvider>(null);

interface IInsightsProvider {
  insightsService: IInsightsService;
}

type Props = {
  children: React.ReactNode;
  insightsService?: IInsightsService;
};

export const InsightsProvider = (props: Props) => {
  const { children, insightsService } = props;
  const value = {
    insightsService: insightsService || new InsightsService()
  };

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};

export const UseInsightsContext = (): IInsightsProvider => {
  return useContext(InsightsContext);
};
