import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';

export const appAccountChromebookusers: IRoute = {
  name: 'app.account.chromebookusers',
  stateDefinition: {
    url: '/account/chromebookUsers',
    template: '<chromebook-users></chromebook-users>',
    data: { pageTitle: 'Chromebook Users' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic]
  }
};

export default appAccountChromebookusers;
