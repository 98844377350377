import { react2angular } from 'react2angular';
import { ScreenshotsReportViewComponent } from '../../../../_reactivtrak/src/reports/screenshots-report/views/ScreenshotsReport.view';
import { screenshotsTemplate } from '../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedScreenshotsReport', {
    template: screenshotsTemplate,
    controller: 'ScreenshotsCtrl'
});

angular.module('app').component('screenshotsReport', react2angular(ScreenshotsReportViewComponent, []));
