import React from 'react';
import { Box, Typography } from '@mui/material';

import { AboutIntegrationRequirement } from './AboutIntegrationRequirement';
import { AboutIntegrationTemplates } from './AboutIntegrationTemplates';
import { AboutIntegrationGetStarted } from './AboutIntegrationGetStarted';
import { AboutModalProps } from '../models';

export const AboutOutlookCalendarModal = (props: AboutModalProps) => {
  const { description, exampleImage, exampleAltText } = props;
  return (
    <>
      {description && <Typography sx={{ mb: 2 }}>{description}</Typography>}
      {exampleImage &&
        <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
          <img height="200px" src={exampleImage} alt={exampleAltText} />
        </Box>}
      <AboutIntegrationRequirement>
        <ul>
          <li>
            A current{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/360027324491"
              target="_blank"
              rel="noopener noreferrer"
            >
              ActivTrak paid plan subscription
            </a>
          </li>
          <li>
            At least one deployed ActivTrak Agent starting to collect activity
            data
          </li>
          <li>An active Azure AD instance instance</li>
          <li>A Microsoft 365 Azure Cloud setup</li>
          <li>
            Administrative role access to your organization&apos;s Microsoft 365
            account
          </li>
        </ul>
      </AboutIntegrationRequirement>
      <AboutIntegrationTemplates>None</AboutIntegrationTemplates>
      <AboutIntegrationGetStarted>
        <ul>
          <li>
            Review the{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/14157396964123"
              target="_blank"
              rel="noopener noreferrer"
            >
              Offline Meeting Time article
            </a>{' '}
            in our Help Center to understand how calendar data will appear in
            your account
          </li>
          <li>
            Follow the instructions in our{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/17461433216411"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setup Guide
            </a>{' '}
            to get started.
          </li>
        </ul>
      </AboutIntegrationGetStarted>
    </>
  );
};
