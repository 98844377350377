import React from 'react';
import { ActivityType } from '../../../common/enums';
import ActivityTopSubpages from './ActivityTopSubpages';
import ActivityTopTitles from './ActivityTopTitles';
import ActivityTopUsers from './ActivityTopUsers';
import { IActivityDetail } from '../../models';

interface IActivityReportPanel {
  label: string;
  element: JSX.Element;
  isVisiblePrivateMode: boolean;
  isVisibleApplication: boolean;
}

export const useActivityReportPanels = (
  activityDetail: IActivityDetail,
  isActivityDetailLoading: boolean,
  activityAllowed: boolean
): IActivityReportPanel[] => {
  const panels: IActivityReportPanel[] = [
    {
      label: 'Top Users',
      element: (
        <ActivityTopUsers
          users={activityDetail?.users}
          isActivityDetailLoading={isActivityDetailLoading}
        />
      ),
      isVisiblePrivateMode: true,
      isVisibleApplication: true
    },
    {
      label: 'Top Titles',
      element: (
        <ActivityTopTitles
          titles={activityDetail?.titles}
          isActivityDetailLoading={isActivityDetailLoading}
        />
      ),
      isVisiblePrivateMode: false,
      isVisibleApplication: true
    },
    {
      label: 'Top Subpages',
      element: (
        <ActivityTopSubpages
          subpages={activityDetail?.subpages}
          isActivityDetailLoading={isActivityDetailLoading}
        />
      ),
      isVisiblePrivateMode: false,
      isVisibleApplication: false
    }
  ];

  return panels.filter((panel) => {
    const isApplicationType = activityDetail?.type === ActivityType.Application;
    return (
      (activityAllowed || panel.isVisiblePrivateMode) &&
      (!isApplicationType || panel.isVisibleApplication)
    );
  });
};
