import { OperationalSystemsType } from '../../common/enums';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import { IComputersViewState } from '../models';
import { ComputersViewTabs } from '../enums';

export const COMPUTERS_INITIAL_VIEW_STATE: IComputersViewState = {
  reportingComputers: [],
  notReportingComputers: [],
  dropDownButtonOptions: Object.keys(OperationalSystemsType).map((os) => {
    return { label: os, template: (os: string) => getOSIcon(os) };
  }),
  dropDownSettingsButtonOptions: [{ label: 'Passive Time Settings' }],
  searchTerm: '',
  searchBarFilterParams: ['windowsDomain', 'computer', 'alias'],
  viewIsLoading: false,
  filterButtonSelection: 0,
  computerViewTab: ComputersViewTabs.Reporting,
  computersViewTabOptions: [
    { label: 'REPORTING', type: ComputersViewTabs.Reporting },
    { label: 'NOT REPORTING', type: ComputersViewTabs.NotReporting }
  ]
};
