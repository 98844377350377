import { IActivityDto, IActivityDtoApiResponse } from '../../common/models';
import {
  IGroupMemberAdminDto,
  IGroupMemberAdminDtoApiResponse,
  IGroupMembersDtoApiResponse,
  IGroupWithMembersDto,
  IMemberDto,
  IMemberDtoApiResponse
} from '../models';

export const mapIMemberDtoDefinition = (
  response: IMemberDtoApiResponse
): IMemberDto => {
  return {
    id: response?.id,
    name: response.name,
    alias: response?.alias,
    domain: response?.domain
  };
};

export const mapIGroupWithMembersDefinition = (
  response: IGroupMembersDtoApiResponse
): IGroupWithMembersDto => {
  return {
    groupId: response?.groupId,
    groupName: response?.groupName,
    mix: response?.mix,
    groupType: response?.groupType,
    groupTypeName: response?.groupTypeName,
    clients: response?.clients,
    devices: response?.devices
  };
};

export const mapIGroupMemberAdminDtoDefinition = (
  adminDto: IGroupMemberAdminDtoApiResponse
): IGroupMemberAdminDto => {
  return {
    memberId: adminDto?.memberId,
    memberName: adminDto?.memberName,
    memberAlias: adminDto?.memberAlias,
    memberDomain: adminDto?.memberDomain,
    memberType: adminDto?.memberType,
    memberTypeName: adminDto?.memberTypeName
  };
};

export const mapIActivityDtoDefinition = (
  activityResponse: IActivityDtoApiResponse
): IActivityDto => {
  return {
    id: activityResponse?.id,
    name: activityResponse?.name,
    type: activityResponse?.type,
    description: activityResponse?.description,
    executable: activityResponse?.executable
  };
};
