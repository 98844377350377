import React from 'react';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { StyledBackBox } from '../../styles/TimeOnTask.view.styles';

type BackButtonBoxProps = {
  onClick: () => void;
};

const BackButtonBox = ({ onClick }: BackButtonBoxProps) => (
  <StyledBackBox>
    <Button onClick={onClick}>
      <ChevronLeftIcon /> Back
    </Button>
  </StyledBackBox>
);

export default BackButtonBox;
