// access this page via '/app/icons'
import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import {
  ActiveDirectoryIcon,
  ActivTrakIcon,
  AddGroupIcon,
  AddMembersIcon,
  AlarmIcon,
  AlarmsIcon,
  AliasIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  AzureAdIcon,
  CalendarIcon,
  CoachIcon,
  ComputerGroupIcon,
  ComputerIcon,
  DashboardIcon,
  DesktopMonitor,
  DragHandleIcon,
  EmployeeExpIcon,
  ExpiringIcon,
  FavoritesIcon,
  GoalIcon,
  GoogleCalendarIcon,
  GoogleWorkspaceIcon,
  HelpIcon,
  HybridIcon,
  ImpactIcon,
  IndividualIcon,
  InsightsIcon,
  IntegrationsIcon,
  LatestUpdatesIcon,
  LiveReportsIcon,
  LogoutIcon,
  MixedGroupIcon,
  MonitorIcon,
  NoAPIKeysIcon,
  OpenFileBox,
  OptimizeIcon,
  OutlookCalendarIcon,
  RemoveMemberIcon,
  SalesforceIcon,
  SettingsIcon,
  SuccessIcon,
  TeamLevelIcon,
  TeamPulseIcon,
  TechInvestIcon,
  UploadIcon,
  UserAllIcon,
  UserGroupIcon,
  UserIndividualIcon,
  WavingHandIcon,
  WindowsIcon,
  WorldIcon,
  HRDataConnector,
  WindowsIconColor
} from './';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';

const classes = {
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    marginLeft: 10
  }
};

//NOTE: keep these alphabetical
const icons = [
  { name: 'ActiveDirectoryIcon', component: ActiveDirectoryIcon },
  { name: 'ActivTrakIcon', component: ActivTrakIcon },
  { name: 'AddGroupIcon', component: AddGroupIcon },
  { name: 'AddMembersIcon', component: AddMembersIcon },
  { name: 'AlarmIcon', component: AlarmIcon },
  { name: 'AliasIcon', component: AliasIcon },
  { name: 'ArrowDownIcon', component: ArrowDownIcon },
  { name: 'ArrowLeftIcon', component: ArrowLeftIcon },
  { name: 'ArrowRightIcon', component: ArrowRightIcon },
  { name: 'ArrowUpIcon', component: ArrowUpIcon },
  { name: 'AzureAdIcon', component: AzureAdIcon },
  { name: 'CalendarIcon', component: CalendarIcon },
  { name: 'ComputerGroupIcon', component: ComputerGroupIcon },
  { name: 'ComputerIcon', component: ComputerIcon },
  { name: 'DesktopMonitor', component: DesktopMonitor },
  { name: 'DragHandleIcon', component: DragHandleIcon },
  { name: 'EmployeeExpIcon', component: EmployeeExpIcon },
  { name: 'ExpiringIcon', component: ExpiringIcon },
  { name: 'FavoritesIcon', component: FavoritesIcon },
  { name: 'GoalIcon', component: GoalIcon },
  { name: 'GoogleCalendarIcon', component: GoogleCalendarIcon },
  { name: 'GoogleWorkspaceIcon', component: GoogleWorkspaceIcon },
  { name: 'HybridIcon', component: HybridIcon },
  { name: 'IndividualIcon', component: IndividualIcon },
  { name: 'MixedGroupIcon', component: MixedGroupIcon },
  { name: 'MonitorIcon', component: MonitorIcon },
  { name: 'NoAPIKeysIcon', component: NoAPIKeysIcon },
  { name: 'OpenFileBox', component: OpenFileBox },
  { name: 'OptimizeIcon', component: OptimizeIcon },
  { name: 'OutlookCalendarIcon', component: OutlookCalendarIcon },
  { name: 'RemoveMemberIcon', component: RemoveMemberIcon },
  { name: 'SalesforceIcon', component: SalesforceIcon },
  { name: 'SuccessIcon', component: SuccessIcon },
  { name: 'TeamLevelIcon', component: TeamLevelIcon },
  { name: 'TechInvestIcon', component: TechInvestIcon },
  { name: 'UploadIcon', component: UploadIcon },
  { name: 'UserAllIcon', component: UserAllIcon },
  { name: 'UserGroupIcon', component: UserGroupIcon },
  { name: 'UserIndividualIcon', component: UserIndividualIcon },
  { name: 'WavingHandIcon', component: WavingHandIcon },
  { name: 'WindowsIcon', component: WindowsIcon },
  { name: 'WindowsIconColor', component: WindowsIconColor },
  { name: 'WorldIcon', component: WorldIcon },
  { name: 'HRDataConnector', component: HRDataConnector },
  { name: 'AlarmsIcon', component: AlarmsIcon },
  { name: 'CoachIcon', component: CoachIcon },
  { name: 'DashboardIcon', component: DashboardIcon },
  { name: 'HelpIcon', component: HelpIcon },
  { name: 'ImpactIcon', component: ImpactIcon },
  { name: 'InsightsIcon', component: InsightsIcon },
  { name: 'IntegrationsIcon', component: IntegrationsIcon },
  { name: 'LatestUpdatesIcon', component: LatestUpdatesIcon },
  { name: 'LiveReportsIcon', component: LiveReportsIcon },
  { name: 'LogoutIcon', component: LogoutIcon },
  { name: 'SettingsIcon', component: SettingsIcon },
  { name: 'TeamPulseIcon', component: TeamPulseIcon }
];

export default function IconsDemo() {
  const getList = useMemo(() => {
    return icons.map((item, i) => {
      const TheIcon = item.component;
      return (
        <Grid item xs={4} key={`icon-${i}`} sx={classes.row}>
          <TheIcon />{' '}
          <span style={classes.name}>{item.name || 'Unknown Name'}</span>
        </Grid>
      );
    });
  }, []);

  return (
    <Grid container spacing={3}>
      {getList}
    </Grid>
  );
}

export const IconsDemoComponent = () => (
  <ReactivTrakComponentWrapper>
    <IconsDemo />
  </ReactivTrakComponentWrapper>
);
