import React, { useState } from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';
import { TagBar } from '../../common/components/TagBar/TagBar';
import { AdvancedSearchFilter } from '../../common/components/AdvancedFilter/AdvancedSearchFilter';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar/AutocompleteSearchBar';
import {
  AdvancedFilterButtonContainer,
  AdvancedFilterClearButton,
  SearchBarContainer
} from '../styles/Users.view.styles';
import ClearIcon from '@mui/icons-material/Clear';
import { IUserAliasState } from '../models/IUserAliasState';
import { AliasSearchProps } from '../models/AliasSearchProps';

const AliasSearch = (props: AliasSearchProps<IUserAliasState>) => {
  const { gridColumns, aliasState } = props;

  const {
    setSearchTerm,
    getFilteredUserAliasesForAutocomplete,
    searchTerm,
    searchCriteria,
    setSearchCriteria
  } = aliasState;

  const [terms, setTerms] = useState([]);
  const [domainSearch, setDomainSearch] = useState<string>();
  const [userSearch, setUserSearch] = useState<string>();
  const [aliasSearch, setAliasSearch] = useState<string>();

  const handleOnKeyPress = (event: any) => {
    if (searchTerm && event.key === 'Enter') {
      const updatedTerms = [...terms, searchTerm];
      setTerms(updatedTerms);
    }
    const value = event.target.value;
    const term = value && value.trim().toLowerCase();
    if (term && event.key === 'Enter') {
      setSearchTerm(term);
    } else {
      setSearchTerm('');
    }
  };

  const handleChangeAutocompleteSearchBar = (value) => {
    setSearchTerm(value);
  };

  const handleAdvancedSearchClear = () => {
    setDomainSearch(undefined);
    setUserSearch(undefined);
    setAliasSearch(undefined);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    handleAdvancedSearchClear();
  };

  const handleDomainChange = (e) => {
    setDomainSearch(e.target.value);
  };

  const handleUserChange = (e) => {
    setUserSearch(e.target.value);
  };

  const handleAliasSearchChange = (e) => {
    setAliasSearch(e.target.value);
  };

  const handleAdvancedSearch = () => {
    const tabData = [];
    if (domainSearch) {
      tabData.push({
        fieldName: 'Logon Domain',
        field: 'logon_Domain',
        fieldValue: domainSearch
      });
    }
    if (userSearch) {
      tabData.push({
        fieldName: 'User',
        field: 'user',
        fieldValue: userSearch
      });
    }
    if (aliasSearch) {
      tabData.push({
        fieldName: 'Alias',
        field: 'alias',
        fieldValue: aliasSearch
      });
    }
    setSearchCriteria(tabData);
    handleAdvancedSearchClear();
  };

  return (
    <>
      <SearchBarContainer>
        {searchCriteria.length ? (
          <TagBar tabBarData={searchCriteria} />
        ) : (
          <AutocompleteSearchBar
            autocompleteOptions={getFilteredUserAliasesForAutocomplete(
              gridColumns
            )}
            searchLabelPlaceholder="Search for domain, user, or alias"
            onChange={handleChangeAutocompleteSearchBar}
            onKeyPress={handleOnKeyPress}
          />
        )}
        {searchTerm ? null : (
          <AdvancedFilterButtonContainer>
            {searchCriteria.length ? (
              <AdvancedFilterClearButton
                onClick={() => {
                  setSearchCriteria([]);
                  handleSearchClear();
                }}
              >
                <ClearIcon />
              </AdvancedFilterClearButton>
            ) : null}
            <AdvancedSearchFilter
              onSubmit={() => {
                handleAdvancedSearch();
              }}
              onClear={() => {
                setSearchCriteria([]);
                setTerms([]);
                handleAdvancedSearchClear();
              }}
              popoverWidth="300px"
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    htmlFor="domainAdvancedSearch"
                    style={{ marginTop: 3 }}
                  >
                    Domain
                  </InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="domainAdvancedSearch"
                    variant="standard"
                    onChange={handleDomainChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel
                    htmlFor="userAdvancedSearch"
                    style={{ marginTop: 3 }}
                  >
                    User
                  </InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="userAdvancedSearch"
                    variant="standard"
                    onChange={handleUserChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel
                    htmlFor="userAliasSearch"
                    style={{ marginTop: 3 }}
                  >
                    Alias
                  </InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="userAliasSearch"
                    variant="standard"
                    onChange={handleAliasSearchChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AdvancedSearchFilter>
          </AdvancedFilterButtonContainer>
        )}
      </SearchBarContainer>
    </>
  );
};

export default AliasSearch;
