import { styled } from '@mui/material/styles';
import { heavyFontWeight } from '../../common/constants';

export const subscriptionClasses = {
  subtitleText: {
    fontSize: '18px',
    fontWeight: heavyFontWeight,
    lineHeight: '23.44px',
    textAlign: 'left' as const,
    letterSpacing: '0.15px',
    marginTop: '30px'
  }
};

export const GridRowActionButtonContainer = styled('div')`
  button {
    border-radius: 50%;
    min-width: 32px;
  }
`;
