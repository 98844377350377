import React, { useEffect } from 'react';
import { useOrganizationTrendsState } from '../../hooks';
import { noTeamsReportedLast30days } from '../../constants';
import { DashboardSubheader } from '../templates';
import Box from '@mui/material/Box';
import authorizationService from '../../../common/helpers/authorization';

export default function OrganizationTrendsSubheader() {
  const { orgTrendsHours, isTileError, isLoading, init } =
    useOrganizationTrendsState();

  useEffect(() => {
    init();
  }, [init]);

  const hasinsightsExecutiveSummaryAccess = authorizationService.hasRouteByName(
    'app.reports.insightsExecutiveSummary'
  );

  return (
    <Box mt={2}>
      <DashboardSubheader
        topLineSubheader="Organization Trends (Last 30 Days)"
        url={
          hasinsightsExecutiveSummaryAccess
            ? 'app.reports.insightsExecutiveSummary'
            : ''
        }
        params={{ modelId: 'activinsights', pageId: 'executive_summary' }}
        hasTileError={isTileError}
        isLoading={isLoading}
        zeroDataMsg={noTeamsReportedLast30days}
        zeroDataLink="https://support.activtrak.com/hc/en-us/articles/18821721976475"
        {...orgTrendsHours}
      />
    </Box>
  );
}
