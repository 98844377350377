import { IInsightsService, IManagerSchedule, ISchedule } from '../models';
import { IActivTrakRequestConfig } from '../../common/models';
import { getDefaultConfig, BaseClient } from '../../common/config';
import { mapToSchedule } from '../utils';
import { humanReadableLookmlDashboardId } from '../../common/helpers';

export class InsightsService extends BaseClient implements IInsightsService {
  protected webApiConfig: IActivTrakRequestConfig;

  constructor() {
    super();
  }

  public async getAllSchedules(): Promise<IManagerSchedule[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(`/api/insights/schedules`, getDefaultConfig())
        .then((response: any) => {
          const data = response.data;
          data.forEach((datum) => {
            datum.dashboardId = humanReadableLookmlDashboardId(
              datum.dashboardId
            );
          });
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public async getAllUsers(): Promise<IManagerSchedule[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(`/api/insights/schedules/users`, getDefaultConfig())
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public async getScheduleById(
    managerSchedule: IManagerSchedule
  ): Promise<ISchedule> {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(
          `/api/insights/schedule/` + managerSchedule.subscriptionId,
          getDefaultConfig()
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public async reassignSchedule(
    subscriptionId: string,
    toOwnerId: string
  ): Promise<IManagerSchedule> {
    const params = {
      schedulePlanId: subscriptionId,
      targetOwnerId: toOwnerId
    };
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`/api/insights/schedules/reassign`, {
          ...getDefaultConfig(),
          params: params
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public async deleteSchedules(
    managerSchedules: IManagerSchedule[]
  ): Promise<ISchedule[]> {
    const schedules = mapToSchedule(managerSchedules);

    return new Promise((resolve, reject) => {
      this.apiService
        .post(`/api/insights/schedules/delete`, {
          ...getDefaultConfig(),
          ...{ data: schedules }
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

export default InsightsService;
