export const realtimeDataMap = (realtimeData, filterMode) => {
  const idField = filterMode === 'computers' ? 'computerId' : 'userId';
  const nameField = filterMode === 'computers' ? 'device' : 'username';
  return realtimeData.data.map((item) => {
    return {
      description: item.desc,
      executable: item.exec,
      time: item.time,
      title: item.title,
      url: item.url,
      id: item[idField] || -1,
      name: item[nameField] || item.user
    };
  });
};
