import { apiService } from '../../common/helpers';
import { getDefaultConfig } from '../../common/config';
import { apiRoutes } from '../constants';
import { ISupportPortalService } from '../models/ISupportPortalService';
import {
  IAccountIdPayloadDto,
  IAddAccountPayloadDto,
  ISetDataCollectionPayloadDto,
  IUnlockUserPayloadDto
} from '../models/IPayloadDto';
import { IAccountSnapshotDto, ITokenDto } from '../models/IApiResponseDto';

export const SupportPortalService = (): ISupportPortalService => {
  return {
    getToken: async (payload: IAddAccountPayloadDto): Promise<ITokenDto> => {
      const response = await apiService.post<ITokenDto>(
        apiRoutes.accountSelect,
        {
          ...getDefaultConfig(),
          data: payload
        }
      );
      return response?.data;
    },
    getAccountSnapshot: async (
      token: string,
      payload: IAccountIdPayloadDto
    ): Promise<IAccountSnapshotDto> => {
      const response = await apiService.get<IAccountSnapshotDto>(
        apiRoutes.getAccountSnapshot,
        {
          ...getDefaultConfig(),
          headers: { Authorization: token },
          data: payload
        }
      );
      return response?.data;
    },
    setDataCollection: async (
      payload: ISetDataCollectionPayloadDto,
      token: string
    ): Promise<void> => {
      await apiService.post(apiRoutes.setDataCollection, {
        ...getDefaultConfig(),
        headers: {
          Authorization: token
        },
        data: payload
      });
    },
    unlockUser: async (payload: IUnlockUserPayloadDto): Promise<void> => {
      await apiService.post(apiRoutes.unlockUser, {
        ...getDefaultConfig(),
        data: payload,
        authorizationRequired: true
      });
    },
    verifyAccount: async (payload: IAccountIdPayloadDto): Promise<void> => {
      await apiService.post(apiRoutes.verifyAccount, {
        ...getDefaultConfig(),
        data: payload,
        authorizationRequired: true
      });
    }
  };
};
