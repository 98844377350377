import React from 'react';
import Typography from '@mui/material/Typography';

export const highlightNumbers = (text: string) => {
  const numberRegex = /(\d+(\.\d+)*%?)/g;
  const matches = [...text.matchAll(numberRegex)];

  if (matches.length === 0) {
    return <>{text}</>;
  }

  const highlightedParts = matches.reduce((accumulatedParts, match, index) => {
    const numericValue = match[0];
    const startIndex = match.index!;
    const previousEndIndex =
      index === 0
        ? 0
        : matches[index - 1].index! + matches[index - 1][0].length;

    accumulatedParts.push(text.slice(previousEndIndex, startIndex));

    accumulatedParts.push(
      <Typography
        key={`${numericValue}-${startIndex}`}
        fontWeight={700}
        component="span"
      >
        {numericValue}
      </Typography>
    );

    if (index === matches.length - 1) {
      accumulatedParts.push(text.slice(startIndex + numericValue.length));
    }

    return accumulatedParts;
  }, [] as (string | JSX.Element)[]);

  return <Typography variant="body1">{highlightedParts}</Typography>;
};
