import _ from 'lodash';
import { generateGuid, getDashboardSettings } from '../helpers';
import { getDashboardAccessHeapType } from '../../dashboard/utils';
import { DashboardType } from '../enums';

const _delayedEvents = [];

const sendEvent = (event) => {
  // console.log('sendEvent', event);
  removeEvent(event);

  if (!event.data.adminDashboardTestVariant) {
    const dashboardSettings = getDashboardSettings();
    const dashboard = dashboardSettings?.dashboardSelection;

    const testingVariant = getDashboardAccessHeapType(
      dashboard as DashboardType
    );
    event.data.adminDashboardTestVariant = testingVariant;
  }

  if (window.heap) {
    window.heap.track(event.name, event.data);
  }
};

const removeEvent = (event) => {
  if (!event || !event.id) {
    return;
  }

  // console.log('removeEvent', event);
  if (event && event.timeout) {
    clearTimeout(event.timeout);
  }
  _.remove(_delayedEvents, (e) => e.id === event.id && e.name === event.name);
};

const delayEvent = (event) => {
  // console.log('delayEvent', event);
  const existingEvent = _.find(
    _delayedEvents,
    (e) => e.id === event.id && e.name === event.name
  );
  if (existingEvent) {
    removeEvent(existingEvent);
  }

  event.timeout = setTimeout(sendEvent, event.delay, event);
  _delayedEvents.push(event);
};

export const processDelayedEvents = () => {
  // console.log('processDelayedEvents', _delayedEvents);
  _delayedEvents.forEach((e) => sendEvent(e));
};

export const newEvent = (
  eventName,
  metadata,
  eventDelay = false,
  eventId = null
) => {
  // console.log('newEvent', eventName, metadata, eventDelay, eventId);
  if (eventDelay) {
    eventId = eventId || generateGuid();
    delayEvent({
      name: eventName,
      id: eventId,
      data: metadata,
      delay: eventDelay
    });
  } else {
    sendEvent({
      name: eventName,
      data: metadata
    });
  }
};
