import { useStore } from 'zustand';
import { StoreApi, createStore } from 'zustand/vanilla';
import { IPrivacySettings } from '../models/IPrivacySettings';
import { sidebarReset } from '../components/Navigation/navigationStore';

const defaultState: IPrivacySettings = {
  screenshotsAllowed: false,
  activityAllowed: false,
  alarmActionsAllowed: false
};

const privacySettingsStore = createStore<IPrivacySettings>(() => defaultState);

export const getPrivacySettings = () => privacySettingsStore.getState();

export const setPrivacySettings = (
  privacySettings: Partial<IPrivacySettings>
) => {
  const currentState = privacySettingsStore.getState();
  const sanitizedSettings = Object.entries(privacySettings).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'boolean') {
        acc[key] = value;
      }
      return acc;
    },
    {} as Partial<IPrivacySettings>
  );

  privacySettingsStore.setState({
    ...currentState,
    ...sanitizedSettings
  });

  sidebarReset();
};

export const resetPrivacySettingsStore = () =>
  privacySettingsStore.setState(defaultState);

const usePrivacySettingsStore = (selector) =>
  useStore<StoreApi<IPrivacySettings>, IPrivacySettings>(
    privacySettingsStore,
    selector
  );

export { privacySettingsStore, usePrivacySettingsStore };
