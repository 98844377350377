import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { gray4, primaryAqua } from '../../constants';

export const TagBarContainer = styled('div')`
  overflow: scroll;
  align-items: center;
  width: 100%;
  padding: 7px 5px 2px;
  border: 1px solid ${gray4};
  border-radius: 4px;
`;

export const ChipContainer = styled('div')`
  display: flex;
  list-style: none;
`;

export const ChipSC = styled(Chip)`
  margin: 0px 3px;
  height: 22px !important;
  border-radius: 4px !important;
  background-color: ${primaryAqua} !important;
  svg {
    height: 20px !important;
  }
`;

export const ModalContainer = styled('div')`
  display: block;
  background-color: transparent;
`;

export const FormElementContainer = styled('div')`
  display: flex;
`;
