import React, { useEffect, useState } from 'react';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';

import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { TopUsersReportHeader } from '../components/TopUsersReportHeader';
import { TopUsersReportWrapper } from '../utils/topUsersReportWrapper';
import { TopUsersReportWrapperProps } from '../models/TopUsersTypes';

const TopUsersReportView = (props: TopUsersReportWrapperProps) => {
  const { isOfflineMeetingsIntegrationEnabled } = props;

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <TopUsersReportHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
      />
      <TopUsersReportWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
        isOfflineMeetingsIntegrationEnabled={
          isOfflineMeetingsIntegrationEnabled
        }
      />
    </>
  );
};

export const TopUsersReportViewComponent = (
  props: TopUsersReportWrapperProps
) => (
  <ReactivTrakComponentWrapper>
    <TopUsersReportView {...props} />
  </ReactivTrakComponentWrapper>
);
