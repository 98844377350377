import React, { useState } from 'react';
import { gray6 } from '../../common/constants';
import { IGroup } from '../../common/models';
import { useNotifications } from '../../common/services/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, Typography, Button } from '@mui/material';

type GroupNameHeaderProps = {
  group: IGroup;
  readonly: boolean;
  onSubmit: (group: IGroup) => Promise<any>;
  onSuccess: () => void;
};

const classes = {
  textField: {
    height: '48px',
    borderRadius: '4px',
    borderColor: gray6,
    '& legend': { display: 'none' }
  }
};

export default function GroupNameHeader(props: GroupNameHeaderProps) {
  const {
    group,
    readonly,
    onSubmit = () => {
      /**/
    },
    onSuccess = () => {
      /**/
    }
  } = props;

  const [editing, setEditing] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>();

  const notificationService = useNotifications();

  const handleEditNameClick = () => {
    if (newName) {
      setNewName(undefined);
    }
    setEditing(true);
  };

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const updateName = async () => {
    const oldName = group?.name;
    const hasNameChanged = oldName !== newName;
    if (newName && hasNameChanged) {
      group.name = newName;
      try {
        await onSubmit(group);
        onSuccess();
      } catch (error) {
        group.name = oldName;
        const errorMessage =
          error.message !== 'Error'
            ? error?.message?.split('.')[0]
            : 'Group name already exists. Please use a different name.';
        notificationService.error(errorMessage);
      } finally {
        setEditing(false);
      }
    } else {
      setEditing(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      updateName();
    }
  };

  return (
    <>
      {!editing ? (
        !readonly ? (
          <Button
            onClick={handleEditNameClick}
            color="secondary"
            endIcon={<EditIcon />}
          >
            {group?.name}
          </Button>
        ) : (
          <Typography variant="h5">{group?.name}</Typography>
        )
      ) : (
        <>
          <TextField
            InputProps={{
              sx: classes.textField
            }}
            autoFocus
            variant="outlined"
            defaultValue={group?.name}
            onChange={handleNameChange}
            onKeyPressCapture={handleKeyPress}
            onBlur={updateName}
          ></TextField>
        </>
      )}
    </>
  );
}
