import { useCallback, useState } from 'react';
import { fetchReportingData } from '../../../common/helpers';
import {
  IWorkingHoursReportUserDto,
  IWorkingHoursReportUsersDto
} from '../models/WorkingHoursTypes';

export const useWorkingHoursReportState = () => {
  const [data, setData] = useState<IWorkingHoursReportUserDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const init = useCallback(async (reportFiltersStr: string): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await fetchReportingData<IWorkingHoursReportUsersDto>({
        path: '/reports/v1/workinghours?' + reportFiltersStr
      });

      setData(response?.data);
    } catch (error) {
      console.error(
        'ActivTrak Error: Working Hours data failed to load' + error
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, init };
};
