export function handleRequestSort(data, event) {
  const currentTarger = !event ? 'mnuItem1' : event.currentTarget.id;
  const dataSorted = data.sort((a, b) => {
    const companyA = a.companyName
      ? '' + a.companyName.trim().toLowerCase()
      : '';
    const companyB = b.companyName
      ? '' + b.companyName.trim().toLowerCase()
      : '';
    return currentTarger === 'mnuItem1'
      ? companyA > companyB
        ? 1
        : companyA === companyB
        ? 0
        : -1
      : companyA > companyB
      ? -1
      : companyA === companyB
      ? 0
      : 1;
  });
  return dataSorted;
}
