import React from 'react';
import { SvgIconProps } from './SvgIconProps';

export default function UploadIcon(props: SvgIconProps) {
  const height = props.height ?? '20px';
  const width = props.width ?? '20px';
  const title = props.title ?? '';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="at-icon at-icon-upload"
    >
      <title>{title}</title>
      <g id="MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="groups_create_option1"
          transform="translate(-906.000000, -383.000000)"
        >
          <g id="startModal" transform="translate(618.000000, 305.500000)">
            <g
              id="file_upload-24px"
              transform="translate(288.050000, 77.653906)"
            >
              <rect
                id="Rectangle"
                x="0"
                y="0"
                width="19.3845708"
                height="19.3845708"
              ></rect>
              <path
                d="M5.71539922,10.6047889 L6.82637659,10.6047889 L6.82637659,14.0984284 C6.82637659,14.4827288 7.14080415,14.7971564 7.5251045,14.7971564 L10.3200161,14.7971564 C10.7043165,14.7971564 11.018744,14.4827288 11.018744,14.0984284 L11.018744,10.6047889 L12.1297214,10.6047889 C12.7515892,10.6047889 13.0660168,9.85016277 12.6258182,9.40996419 L9.41865712,6.2028031 C9.14615324,5.93029921 8.70595466,5.93029921 8.43345078,6.2028031 L5.22628968,9.40996419 C4.7860911,9.85016277 5.09353138,10.6047889 5.71539922,10.6047889 Z M4.03845224,16.8933401 C4.03845224,17.2776404 4.3528798,17.592068 4.73718015,17.592068 L13.121915,17.592068 C13.5062154,17.592068 13.8206429,17.2776404 13.8206429,16.8933401 C13.8206429,16.5090397 13.5062154,16.1946122 13.121915,16.1946122 L4.73718015,16.1946122 C4.3528798,16.1946122 4.03845224,16.5090397 4.03845224,16.8933401 Z"
                id="Shape"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
