import React from 'react';
import { LoaderContainer } from '../../../common/components/Grid/CustomGrid.styles';
import { useDefaultSkeletonLoader } from '../../../common/components/Grid/CustomGrid.utils';

type BaseLoadingViewProps = {
  isLoading: boolean;
  isTileError: boolean;
  errorMsg: string | React.ReactNode;
};

const BaseLoadingView = (props: BaseLoadingViewProps) => {
  const { isLoading, isTileError, errorMsg } = props;
  const defaultSkeletonLoader = useDefaultSkeletonLoader(2, false);
  return (
    <>
      {isTileError && errorMsg}

      {isLoading && (
        <LoaderContainer loaderHeight={'90%'}>
          {defaultSkeletonLoader}
        </LoaderContainer>
      )}
    </>
  );
};

export default BaseLoadingView;
