import React from 'react';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { Box, styled } from '@mui/material';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { Science } from '@mui/icons-material';
import { PLAYGROUNDS } from '../constants/playgrounds';
import { primaryColor } from '../../common/constants/styleGuide';

const StyledContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
});

export default function Playground() {
  const playgrounds = PLAYGROUNDS;
  const demoId = window.location.hash.split('/').pop();
  let initialId = playgrounds.findIndex(
    (playground) => playground.id === demoId
  );

  if (initialId === -1) {
    window.history.replaceState({}, '', '#/app/development/playground/example');
    initialId = 0;
  }

  const [playgroundOption, setPlaygroundOption] = React.useState(initialId);

  return (
    <>
      <StyledContainer
        sx={{ borderBottom: `1px solid ${primaryColor}`, pb: 2, mb: 4 }}
      >
        <DropdownOptionsButton
          id="4ec4ce20-587f-437a-87c5-ecfca2af662f"
          icon={<Science />}
          dropDownOptions={playgrounds}
          indexOption={playgroundOption}
          setIndexOption={setPlaygroundOption}
          onChangeOption={(e, option) => {
            const { id } = option;
            if (id) {
              window.history.replaceState(
                {},
                '',
                `#/app/development/playground/${id}`
              );
            }
          }}
          disableSelectedItem
          disabled={false}
        />
      </StyledContainer>
      {playgrounds[playgroundOption]?.jsx}
    </>
  );
}

export const PlaygroundComponent = () => (
  <ReactivTrakComponentWrapper>
    <Playground />
  </ReactivTrakComponentWrapper>
);
