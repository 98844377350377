import { createStore } from 'zustand';
import { GLOBAL_MODAL_STORE_DEFAULT } from '../constants/globalModalStoreDefault';
import { IGlobalModalStore } from '../models/IGlobalModal';

export const globalModalStore = createStore<IGlobalModalStore>(() => ({
  ...GLOBAL_MODAL_STORE_DEFAULT
}));

/**
 * Returns the current global modal store state.
 */
export const getGlobalModalStore = (): IGlobalModalStore =>
  globalModalStore.getState();

export const setGlobalModalStore = (newModalState: IGlobalModalStore): void => {
  const current = globalModalStore.getState();
  globalModalStore.setState({ ...current, ...newModalState });
};

export const showModal = (content) => {
  setGlobalModalStore({ isModalOpen: true, content });
};

export const hideModal = () => {
  resetGlobalModalStore();
};

/**
 * Resets the global modal store to its default state.
 */
export const resetGlobalModalStore = (): void =>
  globalModalStore.setState(GLOBAL_MODAL_STORE_DEFAULT);
