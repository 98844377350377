import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { RoleResourceTitle } from '../RoleResourceTitle';
import { roleTableClasses } from '../../styles/RolesTable.styles';
import { RolesTableCheckRow } from './RolesTableCheckRow';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { rowType } from '../../../common/enums';

type RolesTableProps = {
  row: any;
  handleChange: () => void;
  busy: boolean;
  showLimitedDesc: boolean;
  subItems: any[];
};

export const RolesTableContainerCollapsible = ({
  row,
  handleChange,
  busy,
  showLimitedDesc,
  subItems
}: RolesTableProps) => {
  const [open, setOpen] = React.useState(false);
  const displayCollapsedRows = () => {
    if (open) {
      return (
        <>
          {subItems.map((row) => (
            <RolesTableCheckRow
              key={row.resource}
              row={row}
              busy={busy}
              handleChange={handleChange}
              showLimitedDesc={showLimitedDesc}
              collapsibleRows={true}
              open={open}
            />
          ))}
        </>
      );
    }
  };

  return (
    <>
      <TableRow sx={roleTableClasses.tableRow}>
        <TableCell colSpan={2}>
          <RoleResourceTitle
            resource={row.resource}
            subItem={row.itemType == rowType.SubItem}
            secondaryText={row.secondaryText}
            premiumFeature={row?.premiumFeature}
          />
        </TableCell>
        <TableCell colSpan={1} width="16%" align="center">
          <Box display="flex" p={0} bgcolor="background.paper" height={20}>
            <Box p={0} width="46%"></Box>
          </Box>
        </TableCell>
        <TableCell colSpan={1} width="16%" align="center">
          <Box display="flex" p={0} bgcolor="background.paper" height={20}>
            <Box p={0} width="46%"></Box>
          </Box>
        </TableCell>
        <TableCell colSpan={1} width="16%" align="center">
          <Box display="flex" p={0} bgcolor="background.paper" height={20}>
            <Box p={0} width="46%"></Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box flexDirection="row-reverse" display="flex" alignItems="center">
            <IconButton size="medium" onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {displayCollapsedRows()}
    </>
  );
};
