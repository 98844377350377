import React, { Fragment } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { classificationClasses } from '../styles/Classifications.styles';

export default function IgnoreCategoryRowItem() {
  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        <Button sx={classificationClasses.root} color="secondary">
          <Box sx={classificationClasses.editableCategoryName}>Ignore</Box>
          <Tooltip
            title={
              <>
                Activities assigned to the Ignore category will not be included
                in any reports other than the Activity Log.&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.activtrak.com/hc/en-us/articles/360050558732-How-to-Use-the-Ignore-Category"
                >
                  Learn more.
                </a>
              </>
            }
            placement="bottom"
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Button>
      </Box>
    </Fragment>
  );
}
