import theme from '../../../../common/theme/theme';

export const cardTotalTimeClasses = {
    content: {
        '& a': {
            color: 'inherit !important'
        },
        fontSize: `${theme.atCustom.fontSize.title}px !important`
    },
    bold: {
        fontWeight: 600
    },
    skeleton: {
        margin: '0 auto'
    },
    disabled: {
        color: '#bdbdbd !important',
        cursor: 'not-allowed !important'
    }
};
