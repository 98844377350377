import { styled } from '@mui/material/styles';
import { primaryColor } from '../../common/constants';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const HeaderBarRightActionButtons = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
`;

export const PressEnterSearchDiv = styled('div')`
  width: 100%;
`;

export const PressEnterSearch = styled('span')`
  color: ${primaryColor};
  opacity: 0.6;
  float: right;
`;

export const IdentityGridContainer = styled('div')`
  .orphaned-agent-row {
    background-color: #f2f2f2;
  }
`;

export const FooterButton = styled(Button)`
  padding-top: 8px !important;
  height: 34px;
`;

export const identityHeaderRight = {
  textAlign: 'right',
  display: 'inline-flex',
  justifyContent: 'flex-end',
  '& > span': {
    paddingRight: '15px'
  },
  paddingBottom: '8px'
};
