import { styled } from '@mui/material/styles';
import { FormControlLabel, Button } from '@mui/material';
import { gray2 } from '../../common/constants';

export const groupClasses = {
  loader: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '90px',
    marginBottom: '500px',
    display: 'block'
  },
  button: {
    '&:hover': {
      background: gray2
    }
  },
  deleteGroupWrapper: {
    textAlign: 'right'
  },
  groupHeader: {
    marginBottom: '20px'
  }
};

export const FormControlLabelSC = styled(FormControlLabel)`
  margin: 10px 0px;
`;

export const BulkRemoveMemberButtonSC = styled(Button)`
  float: left;
  &.MuiButton-text {
    padding-top: 15px;
  }
`;
