'use strict';

angular.module('app').directive('minspecial', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            // re-validate on ngModel change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });

            var validate = function () {
                var password = ngModel.$viewValue;
                var minspecial = parseInt(attrs.minspecial);

                ngModel.$setValidity(
                    'minspecial',
                    minspecial &&
                        password &&
                        password.replace(
                            /[^!"#$%&'()*+,-.\/:;<=>?@\[\]\\^_`{|}~]/g,
                            ''
                        ).length >= minspecial
                );
            };
        }
    };
});
