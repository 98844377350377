import React, { useEffect, useState } from 'react';
import { IQuickAction } from '../../common/models/IQuickAction';
import { Role, RoleAccessKeys } from '../../common/enums';
import {
  AddMembersIcon,
  AlarmIcon,
  AliasIcon,
  CalendarIcon,
  GoalIcon,
  UserAllIcon
} from '../../common/assets/Icons';
import { CloudDownload } from '@mui/icons-material';
import { FeatureFlag } from '../../common/enums/FeatureFlag';

export const usePopulateQuickActionsOptions = (): IQuickAction[] => {
  const [options, setOptions] = useState<IQuickAction[]>();

  useEffect(() => {
    const menuItems = [
      {
        id: '5e01bfb8-93d5-4fd4-987c-8417b95fe131',
        name: 'Install/Share Agent File',
        icon: <CloudDownload />,
        roleAccess: RoleAccessKeys.Settings,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: true,
        clickAction: 'modalOpen'
      },
      {
        id: '847395fc-8928-415c-904e-30ec62188d30',
        name: 'Invite Team Members',
        icon: <AddMembersIcon />,
        roleAccess: RoleAccessKeys.Settings,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: true,
        clickAction: 'app.account.access',
        clickParams: { newmember: true }
      },
      {
        id: 'a44535fb-0bee-4a53-96d1-a484b0dfa495',
        name: 'Create/Import a Group',
        icon: (
          <UserAllIcon
            height="18px"
            width="18px"
            viewBox="0 0 24 24"
            title="Create/Import a Group"
          />
        ),
        roleAccess: RoleAccessKeys.Settings,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: true,
        clickAction: 'app.settings.groups',
        clickParams: { openCreateGroupModal: true }
      },
      {
        id: '3f9f0feb-3d34-426f-b869-302e49e77a1b',
        name: 'Create an Alarm',
        icon: <AlarmIcon />,
        roleAccess: RoleAccessKeys.AlarmsConfiguration,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: true,
        clickAction: 'app.alarms.settings',
        clickParams: { newalarm: true }
      },
      {
        id: '26073988-d408-41b5-953b-d404aaac1c16',
        name: 'Create a Goal',
        icon: <GoalIcon />,
        roleAccess: RoleAccessKeys.BenchmarkAndGoals,
        featureAccess: FeatureFlag.InsightsEnabled,
        selected: true,
        clickAction: 'app.reports.insightsBenchmarksAndGoals',
        clickParams: {
          modelId: 'activinsights',
          pageId: 'benchmarks_and_goals',
          subPageId: 'team'
        }
      },
      {
        id: '53937a7f-fd25-499e-a97a-04211d3172f2',
        name: 'Create an Alias',
        icon: <AliasIcon />,
        roleAccess: RoleAccessKeys.Settings,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: true,
        clickAction: 'app.settings.aliases'
      },
      {
        id: 'd289d6fc-2dad-4a67-89e4-1338423db7bf',
        name: 'Create a Schedule',
        icon: <CalendarIcon />,
        roleAccess: RoleAccessKeys.Settings,
        supportRoles: [Role.SupportAdvanced, Role.SuperAdmin],
        selected: false,
        clickAction: 'app.settings.schedule',
        clickParams: { id: 'new' }
      }
    ];
    setOptions(menuItems);
  }, []);

  return options;
};
