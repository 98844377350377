import React, { useEffect } from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import { useAuthorization } from '../../common/services/Authorization';
import { RolesTable } from './RolesTable/RolesTable';
import { Role } from '../../common/enums';
import { useRoleAccessInitialData } from '../utils';
import { roleAccessSettingsStyles } from '../styles';
import { PlanType } from '../../common/enums/PlanType';
import { isAccountPlanTypeAndInTrial } from '../../common/stores/accountPlanStore';

export const RoleAccessSettings = () => {
  const authorizationService = useAuthorization();
  const { rolesAccess, busy, handleChange, getSettings } =
    useRoleAccessInitialData();

  const isSuperAdminOrAdmin: boolean = authorizationService.hasAnyRole([
    Role.Admin,
    Role.SuperAdmin
  ]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <>
      {' '}
      <RolesTable
        busy={busy}
        rows={rolesAccess}
        showLimitedDesc={true}
        handleChange={handleChange}
      />
      <Box
        display="flex"
        p={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={roleAccessSettingsStyles.footer}
      >
        <Box p={1} flexGrow={1}>
          {isAccountPlanTypeAndInTrial(PlanType.Professional) ? (
            <Box display="inline-flex" alignItems="center">
              <Typography>Customize app experience: &nbsp;</Typography>
              {isSuperAdminOrAdmin ? (
                <Link href="#/app/account/wizard?roleAccess=true">
                  Launch Guided Account Setup
                </Link>
              ) : (
                <Typography
                  sx={[roleAccessSettingsStyles.disabledWizardLauncher]}
                >
                  Launch Guided Account Setup
                </Typography>
              )}
            </Box>
          ) : null}
        </Box>
        <Box display="flex" p={1} sx={roleAccessSettingsStyles.checkboxes}>
          <Box p={1}>
            <Checkbox checked={true} color="primary" disabled />
            Always Access
          </Box>
          <Box p={1}>
            <Checkbox checked={true} color="primary" />
            Access
          </Box>
          <Box p={1}>
            <Checkbox checked={false} color="primary" />
            No Access
          </Box>
          <Box p={1}>
            <Checkbox checked={false} color="primary" disabled />
            Access Not Available
          </Box>
        </Box>
      </Box>
      <Box
        sx={[
          roleAccessSettingsStyles.footer,
          roleAccessSettingsStyles.footerInfo
        ]}
      >
        <Link
          href="https://support.activtrak.com/hc/en-us/articles/360037449852-Roles-Permissions-for-Access-to-Your-ActivTrak-Account"
          target="_blank"
          rel="noreferrer"
          sx={roleAccessSettingsStyles.learnMoreLink}
        >
          Learn more
        </Link>{' '}
        about Role Types and Access Permissions.
      </Box>
    </>
  );
};
