import React, { ChangeEvent, useState } from 'react';
import { IDntDeleteState } from '../models/IDntDeleteState';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { IUserIdentity } from '../models/IUserIdentity';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';

type ComponentProps = {
  dntState: IDntDeleteState;
  openDoNotTrackDeleteModal: boolean;
  commonState: IIdentityCommonState;
  handleIdentityGridChange: (
    oldUser: IUserIdentity,
    newUser: IUserIdentity
  ) => void;
};

export const DoNotTrackModal = (props: ComponentProps) => {
  const {
    dntState,
    openDoNotTrackDeleteModal,
    commonState,
    handleIdentityGridChange
  } = props;
  const [selectedValue, setSelectedValue] = useState<string>('');

  const resetAndCloseModal = () => {
    setSelectedValue('');
    dntState.setOpenDoNotTrackDeleteModal(false);
  };

  const onStopTracking = () => {
    dntState.setTracking(false).then(([error, newIdentity]) => {
      if (!error) {
        commonState.setIdentityNotification({
          msg: 'Tracking stopped successfully',
          type: 'success'
        });
        dntState.userToModify.tracked = false;
        handleIdentityGridChange(dntState.userToModify, newIdentity);
      } else {
        commonState.setIdentityNotification({
          msg: 'Error occurred while stopping tracking',
          type: 'error'
        });
      }
    });
    resetAndCloseModal();
  };

  const onStopTrackingDeleteData = () => {
    dntState.doNotTrackAndDelete().then(([error, newIdentity]) => {
      if (!error) {
        commonState.setIdentityNotification({
          msg: 'Tracking stopped and data deleted successfully',
          type: 'success'
        });
        dntState.userToModify.tracked = false;
        handleIdentityGridChange(dntState.userToModify, newIdentity);
      } else {
        commonState.setIdentityNotification({
          msg: 'Error occurred while deleting user. Data not deleted.',
          type: 'error'
        });
      }
    });
    resetAndCloseModal();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue(event.target.value);
  };

  return (
    <Dialog
      open={openDoNotTrackDeleteModal}
      onClose={resetAndCloseModal}
      maxWidth="xs"
    >
      <DialogTitle>Stop tracking this user?</DialogTitle>
      <DialogContent id="dnt-dialog-content" sx={{ paddingBottom: '0px' }}>
        <Box>
          Data collection will cease immediately for the user and all associated
          agents*. Tracking can be resumed at any time.
        </Box>
        <Box sx={{ padding: '16px 0px 8px 0px' }}>
          Please choose from the following options:
        </Box>
        <Box>
          <Box
            sx={{
              border: '1px solid #ccc;',
              borderRadius: '5px',
              padding: '8px 16px 0px 16px'
            }}
          >
            <FormControlLabel
              value="StopTracking"
              control={
                <Radio
                  checked={selectedValue === 'StopTracking'}
                  onChange={handleChange}
                  value="StopTracking"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'StopTracking' }}
                  size="small"
                />
              }
              label="Stop tracking"
            />
            {selectedValue === 'StopTracking' && (
              <Box sx={{ paddingBottom: '16px' }}>
                <ul>
                  <li> Immediately stops data collection</li>
                  <li> Retains access to historical reports</li>
                  <li> Excludes user from future reports</li>
                </ul>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={onStopTracking}
                    variant="contained"
                    color="primary"
                  >
                    Stop Tracking
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              border: '1px solid #ccc;',
              borderRadius: '5px',
              marginTop: '16px',
              padding: '8px 16px 0px 16px'
            }}
          >
            <Tooltip
              title={<span>The user has no activity data to delete</span>}
              placement="bottom-start"
              disableFocusListener={
                dntState?.userToModify?.agents?.length !== 0
              }
              disableHoverListener={
                dntState?.userToModify?.agents?.length !== 0
              }
              disableInteractive={dntState?.userToModify?.agents?.length !== 0}
              disableTouchListener={
                dntState?.userToModify?.agents?.length !== 0
              }
            >
              <FormControlLabel
                value="StopTrackingAndDelete"
                control={
                  <Radio
                    checked={selectedValue === 'StopTrackingAndDelete'}
                    onChange={handleChange}
                    value="StopTrackingAndDelete"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'StopTrackingAndDelete' }}
                    size="small"
                  />
                }
                label="Stop tracking and delete historical data"
                disabled={dntState?.userToModify?.agents?.length === 0}
              />
            </Tooltip>
            {selectedValue === 'StopTrackingAndDelete' && (
              <Box sx={{ paddingBottom: '16px' }}>
                <ul>
                  <li> Immediately stops data collection.</li>
                  <li> Excludes user from future reports.</li>
                  <li>
                    Permanently erase historical data and exclude from reports.
                  </li>
                </ul>
                <Box sx={{ padding: '8px 8px 24px 24px', fontWeight: '500' }}>
                  Historical data deletion cannot be undone!
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={onStopTrackingDeleteData}
                    variant="contained"
                    color="primary"
                  >
                    Stop Tracking and Delete Data
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ padding: '8px 0px 8px 0px', fontSize: '10px' }}>
            *Any agent that you want to continue tracking activity data will
            need to be reassigned to a new or existing user.
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndCloseModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
