'use strict';

var browserServiceFunctions = require('_app/serviceFunctions/browserServiceFunctions')
    .browserServiceFunctions;

angular
    .module('app')
    .service('browserServiceFunctions', BrowserServiceFunctions);

BrowserServiceFunctions.$inject = [];

function BrowserServiceFunctions() {
    var service = this;

    angular.extend(service, browserServiceFunctions);
}
