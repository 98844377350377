import {
  ProductivityPieChartLegendPercentages as prodPieChartPercent,
  ProductivityBarChartLegendPercentages as prodBarChartPercent,
  WorkloadPieChartLegendPercentages as workloadPercent
} from './legendPercentages';

export const productivityPieChartLegendItems: string[] = [
  prodPieChartPercent.Achieving,
  prodPieChartPercent.Missing
];

export const productivityBarChartLegendItems: string[] = [
  prodBarChartPercent.Achieving,
  prodBarChartPercent.Missing
];

export const workloadPieChartLegendItems: string[] = [
  workloadPercent.Optimal,
  workloadPercent.High,
  workloadPercent.Low,
  workloadPercent.Varied
];
