//Temporary AngularJs controller for updated sidebar navigation
angular.module('app').directive('sidebarMenuNew', [

  function () {

      $(window.document.body).addClass('menu-pin');
      $(window.document.body).addClass('menu-open');

      return {
          restrict: 'E',
          template: '<sidebarnew navigate="navigate" can-navigate="canNavigate"></sidebarnew>',
      };
  }
]);