import React from 'react';
import { NestedCheckboxFilterProps } from './NestedCheckboxFilter.props';
import { NestedList } from './NestedList';
import { Button, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export const NestedCheckboxFilter = (props: NestedCheckboxFilterProps) => {
  const { filterOptions, handleNone = false, onSubmitFilters } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleSubmit = (filters) => {
      onSubmitFilters(filters);
      handleClose();
    };

    return (
      <>
        <Button
          aria-controls="nested-menu-list"
          aria-haspopup="true"
          onClick={handleMenuClick}
          color="secondary"
        >
          <FilterListIcon />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <NestedList
            filterOptions={filterOptions}
            onSubmit={handleSubmit}
            handleNone={handleNone}
          />
        </Popover>
      </>
    );
};
