import React from 'react';
import {
  AutocompleteWithIconsProps,
  IAutocompleteSuggestion
} from './IAutocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectTextFieldSC } from '../Select/SelectTextFieldInlineLabel.styles';

export default function AutocompleteWithIcons(
  props: AutocompleteWithIconsProps
) {
  const { suggestions, currentValue, onChange } = props;

  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, option) => {
    if (option) {
      onChange(option);
    }
  };

  return (
    <Autocomplete
      id="autocomplete-with-icons"
      disableCloseOnSelect
      options={suggestions}
      value={currentValue}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event?.type === 'blur' || reason === 'clear') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setInputValue(value);
        }
      }}
      getOptionLabel={(option: IAutocompleteSuggestion) =>
        option.label ? option.label : ''
      }
      isOptionEqualToValue={(option: IAutocompleteSuggestion, selection) => {
        return option.value === selection;
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={props.id} id={option.id}>
          <Typography component="span" sx={{ display: 'inline-block', pr: 1 }}>
            {option.icon}
          </Typography>
          <Typography component="span">{option.label}</Typography>
        </Box>
      )}
      style={{ width: 350 }}
      noOptionsText="Sorry, no matching results found. Please refine your search criteria."
      renderInput={(params) => (
        <SelectTextFieldSC {...params} placeholder="Search Text" />
      )}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} disablePortal={true} sx={{ ...style, margin: 0 }} />
      )}
    />
  );
}
