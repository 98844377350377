'use strict';

angular.module('app').directive('mindigit', function() {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function(scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            // re-validate on ngModel change
            scope.$watch(attrs.ngModel, function() {
                validate();
            });

            var validate = function() {
                var password = ngModel.$viewValue;
                var mindigit = parseInt(attrs.mindigit);

                ngModel.$setValidity(
                    'mindigit',
                    password &&
                        password.replace(/[^0-9]/g, '').length >= mindigit
                );
            };
        }
    };
});
