interface IWorkingHoursPromoText {
  title: string;
  blurb: string;
  type: string;
  link: string;
}

export const locationPromoText: IWorkingHoursPromoText = {
  title: `View Employee Productivity by Location`,
  blurb: `Ensure policy compliance and assess productivity by location by automatically detecting where employees work each day.`,
  type: 'Location Insights',
  link: 'https://support.activtrak.com/hc/en-us/articles/8298409034267-The-Location-Insights-Dashboard'
};

export const offlineMeetingsPromoText: IWorkingHoursPromoText = {
  title: `Combine In-Person and Digital Activity Data`,
  blurb: `Fill in the gaps by syncing your Outlook or Google Calendar with ActivTrak.`,
  type: 'Calendar Integration',
  link: 'https://support.activtrak.com/hc/en-us/articles/14157396964123-Offline-Meetings'
};
