import { LoadApplication } from '../../../_reactivtrak/src/common/services/Application/AppLoad';
import { getPrivacySettings } from '../../../_reactivtrak/src/common/hooks/privacySettingsStore';

angular.module('app').service('AccountSettingsService', AccountSettingsService);

AccountSettingsService.$inject = ['$rootScope', 'envConfig', 'atHelperFunctions'];

function AccountSettingsService($rootScope, envConfig, atHelperFunctions) {
    var service = this;

    function initialize(model) {
        for (var prop in model) {
            service[prop] = model[prop];
        }

        if (!!model.apiUrl && model.apiUrl !== '') {
            envConfig.configureApiUrl(model.apiUrl);
        }

        if (!!model.wssUrl && model.wssUrl !== '') {
            envConfig.configureWebSocketsUrl(model.wssUrl);
            envConfig.configureWebSocketsHttpsUrl(model.wssUrl);
        }

        if (!!model.reportingServiceUrl && model.reportingServiceUrl !== '') {
            envConfig.configureReportingServiceUrl(model.reportingServiceUrl);
        }

        if (!!model.identityServiceUrl && model.identityServiceUrl !== '') {
            envConfig.configureIdentityServiceUrl(model.identityServiceUrl);
        }

        if (!!model.webApiUrl && model.webApiUrl !== '') {
            envConfig.configureWebApiUrl(model.webApiUrl);
        }

        atHelperFunctions.setDateTimeFormats(service.dateFormat, service.timeFormat, service.currentIanaTimeZone);
        $rootScope.$broadcast('AccountSettingsInitialized');
    }

    this.resetAccountSettings = function () {
        service.username = '';
        service.totalLicenses = 3;
        service.redactionInfo = null;
        service.safeSearchInfo = null;
        service.realtimeSettings = null;
        service.safeSearchThresholds = null;
        service.websocketSettings = null;
        service.user = {};
        service.subscriptions = {};
        service.account = {};
        service.bundleFlags = {};
    };

    // TODO: Remove this from account settings into its own store
    this.setRedactionInfo = function (info) {
        service.redactionInfo = info;
        $rootScope.$broadcast('RedactionInfoSet', info);
    };

    // TODO: Remove this from account settings into its own store
    this.setSafeSearchInfo = function (info) {
        service.safeSearchInfo = info;
        $rootScope.$broadcast('SafeSearchInfoSet', info);
    };

    // TODO: Remove this from account settings into its own store
    this.setRealtimeSettings = function (settings) {
        if (!settings) {
            return;
        }

        service.realtimeSettings = settings.realtimeSettings;
        service.websocketSettings = settings.websocketSettings;
        $rootScope.$broadcast('RealtimeSettingsSet', settings.realtimeSettings);
        $rootScope.$broadcast('WebsocketSettingsSet', settings.websocketSettings);
    };

    // TODO: Remove this from account settings into its own store
    this.setSafeSearchThresholds = function (thresholds) {
        service.safeSearchThresholds = thresholds;
        $rootScope.$broadcast('SafeSearchThresholdsSet', thresholds);
    };

    this.datetimeFormat = function () {
        return service.dateFormat.toUpperCase() + ' ' + service.timeFormat;
    };

    this.datetimeMomentFormat = function () {
        return service.dateFormat.toUpperCase() + ' ' + service.timeFormat.replace('tt', 'A');
    };

    this.loadSettings = function (settings) {
        initialize(settings);
    };
}

angular.module('app').factory('accountApiHelper', accountApiHelper);
accountApiHelper.$inject = [
    '$http',
    '$state',
    '$q',
    '$location',
    'envConfig',
    'AccountSettingsService',
    'messagesService',
    'atHelperFunctions',
    'authorizationService',
    'loginService',
    'teamPulseService',
    'accessApiService'
];

function accountApiHelper(
    $http,
    $state,
    $q,
    $location,
    envConfig,
    AccountSettingsService,
    msg,
    atHelperFunctions,
    authorizationService,
    loginService,
    teamPulseService,
    accessApiService
) {
    var loadSettings = function () {
        var deferred = $q.defer();
        var service = this;

        function errorHandler(error) {
            // Ignore 401 status since it is handled in responseErrorService.js
            if (error.status !== 401) {
                var errorCode = error && error.data && error.data.code;
                var params = {
                    apiFailure: true
                };

                if (errorCode && errorCode.indexOf('IN_MAINTENANCE_MODE') !== -1) {
                    params = {
                        maintenanceModeReason: error.data.message
                    };
                } else if (errorCode && errorCode.indexOf('MSA_ACCEPTANCE_NEEDED') !== -1) {
                    params = {
                        logoutReason: error.data.message
                    };
                }

                // Clean global environment
                loginService.clearScope();
                deferred.reject(error);

                if ($state.current.name !== 'login') {
                    loginService.logout(params);
                } else {
                    loginService.clearLastLogin();
                }
            }
        }

        function completeLoad(model) {
            AccountSettingsService.loadSettings(model);
            var { screenshotsAllowed = false } = getPrivacySettings();

            // These load independent of the app loading
            // Resolve does not wait for these functions
            if (screenshotsAllowed) {
                service.getRedactionInfo();
                service.getSafeSearchInfo();
                service.getSafeSearchThresholds();
            }
            deferred.resolve(AccountSettingsService);
        }

        LoadApplication()
            .then(function (appSettings) {
                atHelperFunctions
                    .removeUtmFromUrl()
                    .then(function () {
                        if (
                            authorizationService.hasRole(authorizationService.roles.user) &&
                            appSettings.accountSettings.msaAcceptanceRequired
                        ) {
                            errorHandler({
                                status: -1,
                                data: {
                                    code: 'MSA_ACCEPTANCE_NEEDED',
                                    message:
                                        'Account Locked.  An admin of your ActivTrak account needs to finish the upgrade process.'
                                }
                            });
                        }
                        accessApiService
                            .setFilterDateLimits()
                            .then(function () {
                                teamPulseService.loadSettings(appSettings.teamPulseSettings);
                                AccountSettingsService.setRealtimeSettings(appSettings.realtimeSettings);
                                completeLoad(appSettings.accountSettings);
                            })
                            .catch(errorHandler);
                    })
                    .catch(errorHandler);
            })
            .catch(errorHandler);
        return deferred.promise;
    };

    return {
        loadSettings: loadSettings,
        getRedactionInfo: function () {
            if (
                authorizationService.hasRole([
                    authorizationService.roles.admin,
                    authorizationService.roles.superAdmin,
                    authorizationService.roles.supportBasic
                ]) &&
                AccountSettingsService.redactionFeatureFlag
            ) {
                $http
                    .get(envConfig.apiUrl() + '/api/screenshots/redaction/info', { ignoreLoadingBar: true })
                    .then(function (result) {
                        if (result && result.data && result.data.data) {
                            var info = result.data.data;
                            info.redactionMonthlyLimit = info.redactionQuotaLimit / 12;
                            info.redactionMonthlyLimitLabel = atHelperFunctions.formatBytes(info.redactionMonthlyLimit);
                            info.redactionQuotaUsageLabel = atHelperFunctions.formatBytes(info.redactionQuotaUsage);
                            info.redactionQuotaLimitLabel = atHelperFunctions.formatBytes(info.redactionQuotaLimit);
                            info.redactionQuotaRemainingLabel = atHelperFunctions.formatBytes(
                                info.redactionQuotaRemaining
                            );
                            info.redactionRenewDateLabel = moment(info.redactionRenewDate).format(
                                AccountSettingsService.dateFormat.toUpperCase()
                            );

                            AccountSettingsService.setRedactionInfo(info);
                        }
                    });
            }
        },
        getSafeSearchInfo: function () {
            if (
                authorizationService.hasRole([
                    authorizationService.roles.admin,
                    authorizationService.roles.superAdmin,
                    authorizationService.roles.supportBasic
                ]) &&
                AccountSettingsService.safeSearchFeatureFlag
            ) {
                var isOverriden = window?.localStorage?.getItem('activTrak.screenshotSafeSearchV2Override');
                var url = isOverriden
                    ? envConfig.reportingServiceUrl() + '/reports/v1/screenshots/safeSearch/info'
                    : envConfig.apiUrl() + '/api/screenshots/safesearch/info';
                $http.get(url, { ignoreLoadingBar: true }).then(function (result) {
                    if (result && result.data) {
                        var info = result.data;
                        info.monthlyLimit = info.quotaLimit / 12;
                        info.monthlyLimitLabel = atHelperFunctions.formatUnits(
                            info.monthlyLimit,
                            null,
                            null,
                            ' ' + msg.get('screenshotsLowercase'),
                            null,
                            999999
                        );
                        info.quotaUsageLabel = atHelperFunctions.formatUnits(
                            info.quotaUsage,
                            null,
                            null,
                            ' ' + msg.get('screenshotsLowercase'),
                            null,
                            999999
                        );
                        info.quotaLimitLabel = atHelperFunctions.formatUnits(
                            info.quotaLimit,
                            null,
                            null,
                            ' ' + msg.get('screenshotsLowercase'),
                            null,
                            999999
                        );
                        info.quotaRemainingLabel = atHelperFunctions.formatUnits(
                            info.quotaRemaining,
                            null,
                            null,
                            ' ' + msg.get('screenshotsLowercase'),
                            null,
                            999999
                        );
                        info.renewDateLabel = moment(info.renewDate).format(
                            AccountSettingsService.dateFormat.toUpperCase()
                        );

                        AccountSettingsService.setSafeSearchInfo(info);
                    }
                });
            }
        },
        getSafeSearchThresholds: function () {
            if (AccountSettingsService.safeSearchFeatureFlag) {
                $http
                    .get(envConfig.apiUrl() + '/api/screenshots/safesearch/threshold', { ignoreLoadingBar: true })
                    .then(function (result) {
                        if (result && result.data) {
                            AccountSettingsService.setSafeSearchThresholds({
                                adult: result.data.adultThreshhold,
                                medical: result.data.medicalThreshhold,
                                racy: result.data.racyThreshhold,
                                violence: result.data.violenceThreshhold
                            });
                        }
                    });
            }
        }
    };
}
