export enum Role {
  SuperAdmin = 'superadmin',
  SupportAdvanced = 'supportadvanced',
  SupportIntermediate = 'supportintermediate',
  SupportBasic = 'supportbasic',
  Admin = 'admin',
  Configurator = 'configurator',
  User = 'user',
  Viewer = 'teamviewer',
  MultiAccount = 'multiaccount',
  CommandCenter = 'ccaccess',
  CommandCenterEnterprise = 'ccenterpriseaccess',
  SupportPortal = 'supportportal'
}

export enum SupportRole {
  SupportBasic = Role.SupportBasic,
  SupportIntermediate = Role.SupportIntermediate,
  SupportAdvanced = Role.SupportAdvanced,
  SuperAdmin = Role.SuperAdmin
}

export enum RoleName {
  SuperAdmin = 'Super Admin',
  SupportAdvanced = 'Support Advanced',
  SupportIntermediate = 'Support Intermediate',
  SupportBasic = 'Support Basic',
  Admin = 'Admin',
  Configurator = 'Configurator',
  User = 'Power User',
  Viewer = 'Viewer',
  MultiAccount = 'Multi Account',
  CommandCenter = 'CC Access',
  SupportPortal = 'Support Portal Access',
  Creator = 'Creator',
  Unknown = 'Unknown'
}

export enum ChannelType {
  cc_child = 'child',
  cc_parent = 'parent'
}

export enum ClaimType {
  Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  ChannelType = 'channeltype',
  Imposter = 'http://activtrak.com/imposter'
}
