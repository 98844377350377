import React from 'react';
import { ITodaysActivityStatusProps } from '../../models';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UserIndividualIcon } from '../../../common/assets/Icons';

export default function TodaysUserActivityStatus(
  props: ITodaysActivityStatusProps
) {
  const { statusType, iconColor, values } = props;

  return (
    <Stack alignItems="center" direction="column">
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <UserIndividualIcon
          width="40px"
          height="40px"
          style={{ color: iconColor }}
        />
        {values && (
          <Typography sx={{ fontSize: '24px !important' }}>{`${
            values[statusType.toLowerCase()]
          }`}</Typography>
        )}
      </Box>
      <Typography>{statusType} Now</Typography>
    </Stack>
  );
}
